import { OffsetLimitPagination, Organization } from 'types';
import { CapitolaClient } from './base';

export type OrganizationQuery = OffsetLimitPagination;

export class OrganizationClient extends CapitolaClient<Organization> {
  public get resourceName(): string {
    return 'organization';
  }
}
