import DOMPurify from 'dompurify';
import { Box } from '@common-components';

interface HtmlTextProps {
  html?: string;
  hideOverflow?: boolean;
}

export default function HtmlText({ html, hideOverflow = false }: HtmlTextProps) {
  const overflowStyle = hideOverflow
    ? { display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical', overflow: 'hidden' }
    : {};

  if (!html) {
    return null;
  }

  return (
    <Box
      sx={{
        ...overflowStyle,
        '*:first-child': {
          margin: 0,
        },
      }}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
    />
  );
}
