import { useCallback } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/utils';
import { useValidateEmailAddresses } from 'hooks/api/contact';
import { messages } from 'i18n';
import { EmailValidationResult } from 'types';
import { scrollToElement } from 'utils';

interface UseEmailAddressFieldValidationProps<T extends FieldValues> {
  methods: UseFormReturn<T, object>;
  scrollToFirstError?: boolean;
}

export function useEmailFormFieldValidation<T extends FieldValues>({
  methods,
  scrollToFirstError = false,
}: UseEmailAddressFieldValidationProps<T>) {
  const { validateEmails } = useValidateEmailAddresses();

  const handleInvalidEmails = useCallback(
    (validationResults: EmailValidationResult[], fieldNameGetter?: (email: string) => FieldPath<T>) => {
      const invalidEmails = validationResults.filter((result) => !result.isValid).map(({ email }) => email);

      const getFieldName = (email: string) => (fieldNameGetter ? fieldNameGetter(email) : ('email' as FieldPath<T>));

      invalidEmails.forEach((invalidEmail) => {
        const fieldName = getFieldName(invalidEmail);

        methods.setError(fieldName, {
          type: 'validate',
          message: messages.formMessages.undeliverableEmail,
        });
      });

      if (scrollToFirstError) {
        scrollToElement(getFieldName(invalidEmails[0]), { block: 'start' });
      }
    },
    [methods, scrollToFirstError],
  );

  const validateEmailAddresses = useCallback(
    async (emails: string[], fieldNameGetter?: (email: string) => FieldPath<T>) => {
      const validationResults = await validateEmails(emails);

      // In case of a server error, we don't want to block the user from submitting the form.
      if (validationResults.length === 0) {
        return true;
      }

      if (validationResults.every((result) => result.isValid)) {
        return true;
      }

      handleInvalidEmails(validationResults, fieldNameGetter);

      return false;
    },
    [handleInvalidEmails, validateEmails],
  );

  return { validateEmailAddresses };
}
