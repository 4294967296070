import { FormControl, InputBase, MenuItem, Select, SelectChangeEvent } from '@common-components';

export interface SelectFieldProps {
  choices: Record<string, string>;
  label: string;
  value: string | null;
  onChange: (value: string | null) => void;
}

export default function SelectField({ choices, label, value, onChange }: SelectFieldProps) {
  function handleChange(event: SelectChangeEvent) {
    const eventValue = event.target.value;
    onChange(eventValue || null);
  }

  return (
    <FormControl variant="outlined">
      <Select displayEmpty id={label} input={<InputBase />} onChange={handleChange} value={value || ''}>
        <MenuItem value="">
          <em>{label}</em>
        </MenuItem>
        {Object.entries(choices).map(([_value, _label]) => (
          <MenuItem key={_value} value={_value}>
            {_label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
