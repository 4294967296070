import { Fragment } from 'react';
import { GridSelectionModel, Stack, Typography } from '@common-components';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { sortedStatusList } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingReport/market-status-config-for-report';
import { MarketingReportMarketRequest } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingReport/utils';
import MarketingReportDataGrid from './MarketingReportDataGrid';

interface MarketingReportContentProps extends InnerPageProps {
  marketRequestsByStatus: Record<number, Array<MarketingReportMarketRequest>>;
  showLoader: () => void;
  hideLoader: () => void;
  marketIdInEditMode: string;
  selectionModels: GridSelectionModel[];
  setSelectionModel: (index: number, selectionModel: GridSelectionModel) => void;
  setMarketIdInEditMode: (id: string) => void;
  isInformationRequestsReport: boolean;
}

export default function MarketingReportContent({
  marketRequestsByStatus,
  showLoader,
  hideLoader,
  marketIdInEditMode,
  selectionModels,
  setSelectionModel,
  setMarketIdInEditMode,
  isInformationRequestsReport,
}: MarketingReportContentProps) {
  return (
    <Stack>
      {sortedStatusList.map((status) =>
        marketRequestsByStatus[status.sortPriority] ? (
          <Fragment key={status.sortPriority}>
            {!isInformationRequestsReport && <Typography variant="body1">{status.text}</Typography>}
            <MarketingReportDataGrid
              showLoader={showLoader}
              hideLoader={hideLoader}
              marketRequests={marketRequestsByStatus[status.sortPriority]}
              selectionModel={selectionModels[status.sortPriority]}
              setSelectionModel={(newValue) => setSelectionModel(status.sortPriority, newValue)}
              marketIdInEditMode={marketIdInEditMode}
              setMarketIdInEditMode={setMarketIdInEditMode}
              key={status.text}
              isInformationRequestsReport={isInformationRequestsReport}
            />
          </Fragment>
        ) : null,
      )}
    </Stack>
  );
}
