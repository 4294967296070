import { Stack } from '@common-components';
import { AIFeature } from 'enums';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import { AIContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { CompareQuotesLocationState } from 'broker/pages/SubmissionWorkspacePage/dialogs/CompareQuotesModal/types';
import QuotesSideBySide from './QuotesSideBySide/QuotesSideBySide';

interface ContentProps extends BaseRoutedDialogContentProps {
  locationState: CompareQuotesLocationState;
}

export default function Content({ onClose, locationState: { quotes, layer } }: ContentProps) {
  return (
    <AIContextProvider feature={AIFeature.QuoteCompare}>
      <Stack sx={{ flex: 1, overflowY: 'auto' }}>
        <QuotesSideBySide
          layer={layer}
          quotes={quotes}
          onCancelClicked={onClose}
          onProceedClicked={onClose}
          isSubmitting={false}
        />
      </Stack>
    </AIContextProvider>
  );
}
