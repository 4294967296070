import { AIFeature } from 'enums';
import { InsuranceProduct } from 'types';
import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import { SingleAIFeatureContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/SingleAIFeatureContextProvider';
import AcknowledgmentEmailContent from './AcknowledgmentEmailContent';

export interface AcknowledgmentEmailProps {
  onProductsSelected: (products: InsuranceProduct[]) => Promise<void>;
}

export default function AcknowledgmentEmail({ onProductsSelected }: AcknowledgmentEmailProps) {
  return (
    <WithRouteLocationState routeKey={RouteKey.AcknowledgmentEmail} isStateRequired={false}>
      {(locationState) => (
        <SingleAIFeatureContextProvider feature={AIFeature.SubmissionAcknowledgment}>
          <AcknowledgmentEmailContent onProductsSelected={onProductsSelected} locationState={locationState} />
        </SingleAIFeatureContextProvider>
      )}
    </WithRouteLocationState>
  );
}
