import { OrganizationClient } from 'clients';
import { QueryKeys } from 'enums';
import { Organization, OrganizationCreate, OrganizationSearch } from 'types';
import { UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useQueryRetrieve, useQuerySearch } from './query/useQuery';

export function useSearchOrganization(props?: UseQueryBaseSearchOptions<Organization, OrganizationSearch>) {
  const { filter, queryOptions, enabled, sorter } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.Organizations,
    clientClass: OrganizationClient,
    filter,
    enabled,
    sorter,
    queryOptions: {
      // Default organization search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useRetrieveOrganization(props: UseQueryBaseRetrieveOptions<Organization>) {
  const { id, queryOptions, enabled } = props;
  return useQueryRetrieve({
    queryKey: QueryKeys.Organizations,
    clientClass: OrganizationClient,
    id,
    enabled,
    queryOptions: {
      // Default organization retrieve query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateOrganization() {
  const { create: createOrganization, update: updateOrganization } = useMutate<
    Organization,
    Organization,
    OrganizationCreate,
    OrganizationClient
  >({
    queryKey: QueryKeys.Organizations,
    clientClass: OrganizationClient,
    updateOptions: { throwOnError: true, passthroughStatusCodes: [409] },
  });

  return { createOrganization, updateOrganization };
}
