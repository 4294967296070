import { MENTION_PREFIX } from '@common/email-variables';
import { filter } from 'lodash';
import { Box, Stack } from '@common-components';
import { isDefined } from 'utils';
import { useCopySmartReportHandler } from 'broker/pages/SubmissionWorkspacePage/hooks/useCopySmartReportHandler';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { smartObjectsConfig } from 'broker/utils/email-utils/SmartVariable/smart-objects-config';
import { SmartReportSnippet } from './SmartReportSnippet';

export const SmartReportsList = () => {
  const { submission } = useSubmissionsWorkspace();

  const proposalContent = submission?.proposal?.body ?? '';

  const smartObjectsInProposal = filter(
    Object.keys(smartObjectsConfig).filter(
      (smartObjectKey) =>
        smartObjectsConfig[smartObjectKey].isSmartReport &&
        proposalContent.includes(`${MENTION_PREFIX}${smartObjectKey}`),
    ),
    isDefined,
  );

  const { handleCopy } = useCopySmartReportHandler();

  return (
    <Box sx={{ overflowY: 'auto' }} height={1} bgcolor="grey.100">
      <Stack bgcolor="grey.100" px={2} py={3} gap={2}>
        {Object.values(smartObjectsConfig)
          .filter((smartObjectValue) => smartObjectValue.isSmartReport)
          .map((config) => (
            <SmartReportSnippet
              key={config.key}
              isInProposal={smartObjectsInProposal.includes(config.key)}
              smartObjectConfig={config}
              onCopyClicked={handleCopy}
            />
          ))}
      </Stack>
    </Box>
  );
};
