import { useMemo } from 'react';
import { ExtendedContact } from 'types';
import { FilterElementProps } from 'utils';
import { didContactPassedFilter } from 'broker/components/UserMarkets/components/utils';

interface ContactsFilterProps {
  allContacts: ExtendedContact[];
  coverageLineFilters: FilterElementProps[];
  isAllCoverageLineFilters: boolean;
  searchInput: string;
}

export default function useContactsSorter({
  allContacts,
  searchInput,
  coverageLineFilters,
  isAllCoverageLineFilters,
}: ContactsFilterProps) {
  return useMemo(() => {
    const mappedContacts = allContacts.map((contact) => ({
      ...contact,
      passedFilter: didContactPassedFilter({ searchInput, contact, coverageLineFilters, isAllCoverageLineFilters }),
    }));

    mappedContacts.sort((a, b) => Number(b.passedFilter) - Number(a.passedFilter));

    return mappedContacts;
  }, [allContacts, coverageLineFilters, isAllCoverageLineFilters, searchInput]);
}
