import { useState } from 'react';
import { Contact, Submission } from 'types';
import {
  convertContactToRecipient,
  createRecipientGroupFromSubmissionCustomer,
  RecipientGroup,
} from 'broker/components/Emails/recipient-utils';

export const useProposalContacts = (submission: Submission) => {
  const [newContacts, setNewContacts] = useState<Contact[]>([]);

  const [recipientGroup, setRecipientGroup] = useState<RecipientGroup>(() =>
    createRecipientGroupFromSubmissionCustomer(submission),
  );

  const addContact = (contact: Contact | null) => {
    if (contact) {
      setRecipientGroup((prev) => ({
        ...prev,
        recipients: [...prev.recipients, convertContactToRecipient(contact)],
      }));
    }
  };

  const toggleContact = (contact: Contact | null, postToggle?: () => void) => {
    if (!contact) {
      return;
    }

    setRecipientGroup((prevRecipientGroup) => {
      const oldContacts = prevRecipientGroup.recipients;
      const contactSelected = oldContacts.some((oldContact) => oldContact.id === contact.id);

      // Disallow empty recipient group
      if (contactSelected && oldContacts.length === 1) {
        return prevRecipientGroup;
      }

      const nextContacts = contactSelected
        ? oldContacts.filter((oldContact) => oldContact.id !== contact.id)
        : [...oldContacts, contact];

      return {
        ...prevRecipientGroup,
        contacts: nextContacts,
      };
    });

    postToggle?.();
  };

  const onClearNewContacts = () => {
    setNewContacts([]);
  };

  return {
    recipientGroup,
    addContact,
    toggleContact,
    newContacts,
    onClearNewContacts,
  };
};
