// eslint-disable-next-line import/no-unresolved
import { Content, PageOrientation, TDocumentDefinitions } from 'pdfmake/interfaces';

interface PdfContentProps {
  base64Svg: string;
  content: Content;
  pageOrientation?: PageOrientation;
}

export const getPdfContentWithTemplate = ({
  base64Svg,
  content,
  pageOrientation = 'portrait',
}: PdfContentProps): TDocumentDefinitions => ({
  // left, top, right, bottom
  pageMargins: [65, 70, 65, 30],
  defaultStyle: {
    fontSize: 11,
    lineHeight: 1.2,
  },
  pageOrientation,
  header(_currentPage: any, _pageCount: any, pageSize: any) {
    return [
      {
        // if you specify both width and height - image will be stretched
        image: `data:image/png;base64,${base64Svg}`,
        width: pageSize.width,
        // width: 150,
      },
    ];
  },
  content,
});
