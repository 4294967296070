import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import Content, { MarketsModalProps } from './Content';

export default function AddMarkets(props: Omit<MarketsModalProps, 'locationState'>) {
  return (
    <WithRouteLocationState routeKey={RouteKey.AddMarkets}>
      {(locationState) => <Content {...props} locationState={locationState} />}
    </WithRouteLocationState>
  );
}
