import { Dialog } from 'components/Dialog';
import { DirtyDialogGuard } from 'broker/components/DirtyContentGuard';
import Content from './Content';

interface SettingsDialogProps {
  closeSettingsDialog: () => void;
  isSettingsDialogOpen: boolean;
}

export default function SettingsDialog({ closeSettingsDialog, isSettingsDialogOpen }: SettingsDialogProps) {
  return (
    <DirtyDialogGuard onClose={closeSettingsDialog}>
      {({ closeAttempt, setIsContentDirty, isContentDirty, closeWithoutPrompt }) => (
        <Dialog open={isSettingsDialogOpen} onClose={closeAttempt}>
          <Content
            closeAttempt={closeAttempt}
            closeWithoutPrompt={closeWithoutPrompt}
            setIsContentDirty={setIsContentDirty}
            isContentDirty={isContentDirty}
          />
        </Dialog>
      )}
    </DirtyDialogGuard>
  );
}
