import { useCallback } from 'react';
import { Button, Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { SelectedProductsMap } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/types';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';

interface FooterProps {
  innerPageProps: InnerPageProps;
  selectedProducts: SelectedProductsMap;
}

export function Footer({ innerPageProps, selectedProducts }: FooterProps) {
  const { onClose } = innerPageProps;
  const navigate = useNavigate();

  const { acknowledgmentEmailUrl } = useWorkspaceUrls();

  const onSubmitClick = useCallback(async () => {
    const selectedProductIds = Object.values(selectedProducts).map((product) => product.id);
    navigate(acknowledgmentEmailUrl, {
      routeKey: RouteKey.AcknowledgmentEmail,
      state: { selectedProductIds },
    });
  }, [acknowledgmentEmailUrl, navigate, selectedProducts]);

  return (
    <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
      <Stack direction="row" overflow="hidden">
        <Typography variant="body2" noWrap>
          {messages.addProductsPage.footer.getSelectedText(Object.keys(selectedProducts).length)}
        </Typography>
      </Stack>
      <Stack direction="row" gap={1}>
        <Button variant="outlined" onClick={onClose}>
          {messages.buttons.back}
        </Button>
        <Button variant="contained" onClick={onSubmitClick}>
          {messages.selectProductsPage.submit}
        </Button>
      </Stack>
    </Stack>
  );
}
