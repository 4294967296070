import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { Backdrop, Box, Divider, Stack, SvgIcon, Typography } from '@common-components';
import { useBoolean, useFormProvider, useHotjar, useMutateUserMarket, useToast } from 'hooks';
import { messages } from 'i18n';
import { UserMarket } from 'types';
import { HotjarEvents } from 'utils/hotjar-events';
import FormEditor from 'components/hookFormComponents/FormEditor';
import { DirtyActionsGuardAttributes, withDirtyActionsGuard } from 'broker/components/DirtyContentGuard';
import TextSkeleton from 'broker/components/skeletons/TextSkeleton';
import MarketInfoSectionHeader from './MarketInfoSectionHeader';

interface MarketInfoEditableSectionProps {
  Icon: typeof SvgIcon;
  userMarketId: string;
  editProp: keyof UserMarket;
  title: string;
  initValue: string;
  isLoading: boolean;
}
export const schema = yup.object().shape({
  text: yup.string().trim().nullable(),
});

function MarketInfoEditableSection({
  userMarketId,
  initValue,
  editProp,
  title,
  Icon,
  isLoading,
  setIsContentDirty,
  isContentDirty,
  closeAttemptWithAction,
  closeWithoutPromptWithAction,
}: MarketInfoEditableSectionProps & DirtyActionsGuardAttributes) {
  const { updateUserMarket } = useMutateUserMarket();

  const [isEdit, { on: startEdit, off: stopEdit }] = useBoolean(false);
  const { showToast } = useToast();
  const hotjar = useHotjar();

  const onCancel = () => {
    closeAttemptWithAction(stopEdit);
  };

  const onSubmit: SubmitHandler<Pick<MarketInfoEditableSectionProps, 'editProp'>> = async (data) => {
    await updateUserMarket.mutateAsync({ id: userMarketId, data: data as Partial<UserMarket> });
    showToast('success', { message: `${title} ${messages.general.savedSuccessfully}` });
    closeWithoutPromptWithAction(stopEdit);
  };

  const { methods } = useFormProvider<Pick<MarketInfoEditableSectionProps, 'editProp'>>({
    schema,
    setIsDirty: setIsContentDirty,
    defaultValues: { [editProp]: initValue },
  });

  const onEditClick = () => {
    methods.reset({ [editProp]: initValue });
    startEdit();

    if (editProp === 'riskAppetite') {
      hotjar.event(HotjarEvents.EditMarketRiskAppetite);
    }
    if (editProp === 'memo') {
      hotjar.event(HotjarEvents.EditMarketMemo);
    }
  };

  const getContent = () => {
    if (isEdit) {
      return (
        <Box height={200}>
          <FormEditor name={editProp} autoFocus sxOverride={{ border: 'none' }} />
        </Box>
      );
    }

    return (
      <Box px={2} py={2} pb={5} height={201} onClick={onEditClick}>
        {/*   this is simulate the text area input paddings */}
        <Box height={1} overflow="hidden">
          <Typography
            component="div"
            overflow="auto"
            variant="body2"
            height={1}
            color="common.black"
            lineHeight={2}
            pr={1}
          >
            <TextSkeleton isLoading={isLoading}>
              <Box dangerouslySetInnerHTML={{ __html: initValue }} />
            </TextSkeleton>
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <FormProvider {...methods}>
      <Backdrop invisible open={isEdit} sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }} onClick={onCancel} />
      <Stack
        zIndex={isEdit ? 'drawer' : 'inherit'}
        border={1}
        borderColor={isEdit ? 'primary.main' : 'divider'}
        borderRadius={2}
      >
        <MarketInfoSectionHeader
          title={title}
          Icon={Icon}
          isContentDirty={isContentDirty}
          isEdit={isEdit}
          isSubmitting={methods.formState.isSubmitting}
          isLoading={isLoading}
          onCancel={onCancel}
          onEdit={onEditClick}
          onSubmit={methods.handleSubmit(onSubmit)}
        />

        <Divider />

        {getContent()}
      </Stack>
    </FormProvider>
  );
}

export default withDirtyActionsGuard(MarketInfoEditableSection);
