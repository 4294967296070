import { get, merge } from 'lodash';
import { memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormControl, FormHelperText, Stack, SxProps, Theme } from '@common-components';
import { Info as InfoIcon } from '@icons';
import { messages } from 'i18n';
import { FormControlHelperTextMarginBottom, FormControlPaddingBottom } from 'themes';
import { Editor } from 'components/Editor/Editor';
import InputLabel from './InputLabel';
import { FormHelperTextStyles, FormWYSIWYGStyles } from './styles';

export interface FormEditorProps {
  name: string;
  label?: string;
  optional?: boolean;
  disabled?: boolean;
  placeholder?: string;
  sxOverride?: SxProps<Theme>;
  autoFocus?: boolean;
  hideOptionalLabel?: boolean;
  borderColor?: string;
  withSourceImageInfo?: boolean;
  paddingBottom?: number;
  excludeToolbarProps?: string[];
}

const FormEditor = memo(
  ({
    name,
    label = '',
    optional,
    disabled,
    placeholder = messages.general.inputPlaceholder,
    sxOverride = {},
    autoFocus = false,
    hideOptionalLabel,
    borderColor,
    withSourceImageInfo = false,
    excludeToolbarProps,
    paddingBottom = FormControlPaddingBottom,
  }: FormEditorProps) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();

    const [isFocus, setIsFocus] = useState(false);
    const [isHover, setIsHover] = useState(false);

    const errorMessage = get(errors, name)?.message;

    return (
      <FormControl
        fullWidth
        sx={{ pb: paddingBottom, mb: errorMessage ? FormControlHelperTextMarginBottom : 0, height: '100%' }}
      >
        <InputLabel
          label={label}
          error={!!errorMessage}
          optional={optional}
          htmlFor={name}
          hideOptionalLabel={hideOptionalLabel}
        />
        <Controller
          defaultValue=""
          control={control}
          name={name}
          render={({ field: { onChange, value, onBlur } }) => (
            <Box
              className="cap-form-editor"
              display="flex"
              flexDirection="column"
              height="100%"
              sx={merge(FormWYSIWYGStyles(!!errorMessage, isFocus, isHover, disabled, borderColor), sxOverride)}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <Editor
                autoFocus={autoFocus}
                editorContent={value}
                setEditorContent={onChange}
                disabled={disabled}
                placeholder={placeholder}
                onFocus={() => setIsFocus(true)}
                onBlur={() => {
                  setIsFocus(false);
                  onBlur();
                }}
                excludeToolbarProps={excludeToolbarProps}
              />
            </Box>
          )}
        />
        {(errorMessage || withSourceImageInfo) && (
          <FormHelperText sx={FormHelperTextStyles} error={!!errorMessage}>
            {errorMessage ?? (
              <Stack component="span" direction="row" alignItems="center" gap={0.5}>
                <InfoIcon sx={{ fontSize: 16 }} />
                {messages.emailEditor.sourceImageInfo}
              </Stack>
            )}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

export default FormEditor;
