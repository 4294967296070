import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Container, Grid, Stack, Typography } from '@common-components';
import { ContentCopy as ContentCopyIcon, Info as InfoIcon } from '@icons';
import { useBoolean, useCurrentUser, useSearchUserTeam } from 'hooks';
import { messages } from 'i18n';
import { User } from 'types';
import { copyToClipboard } from 'utils';
import SettingsDialog from 'broker/dialogs/SettingsDialog';
import InviteDialog from './inviteDialog';
import MyTeamHeader from './MyTeamHeader';
import TeammateCard from './TeammateCard';

export default function MyTeamPage() {
  const [isInviteDialogOpen, { off: closeInviteDialog, on: openInviteDialog }] = useBoolean(false);
  const { me } = useCurrentUser();
  const [isSettingsDialogOpen, { off: closeSettingsDialog, on: openSettingsDialog }] = useBoolean(false);
  const teamInboundEmail = me?.endUser ? me?.endUser.team.inboundEmail : me?.team.inboundEmail;
  const teamName = me?.endUser?.team.name ?? me?.team.name;
  const { items: users } = useSearchUserTeam({ filter: { enabled: true } });

  const [teammatesList, setTeammatesList] = useState<Array<User>>([]);
  useEffect(() => {
    async function getTeammatesList() {
      if (users && me) {
        // Set the card of the current user to be first
        setTeammatesList(sortBy(users, (user) => (user.id === me.id ? 0 : 1)));
      }
    }
    getTeammatesList();
  }, [me, users]);

  async function copyEmail(text: string) {
    await copyToClipboard(text.toLocaleLowerCase());
  }

  return (
    me && (
      <Container maxWidth="xl" sx={{ height: 1, py: 3, display: 'flex', flexDirection: 'column' }}>
        <Stack flex={1}>
          <Stack gap={2} pb={4}>
            <MyTeamHeader teamName={teamName!} onOpenDialog={openInviteDialog} />
          </Stack>
          <Grid container spacing={4} pb={1}>
            {teammatesList.map((user) => (
              <Grid item key={user.id} xs={12} sm={12} md={6} lg={4} xl={3}>
                <TeammateCard user={user} isMe={user.id === me.id} onClick={openSettingsDialog} />
              </Grid>
            ))}
          </Grid>
        </Stack>
        {teamInboundEmail && (
          <Stack direction="row" gap={0.5} alignItems="center">
            <InfoIcon fontSize="small" />
            <Typography variant="caption">{messages.myTeamPage.teamInboundEmailInfo}</Typography>
            <Typography variant="caption" fontWeight="bold" textTransform="lowercase">
              {teamInboundEmail}
            </Typography>
            <Button
              color="primary"
              onClick={() => copyEmail(teamInboundEmail)}
              size="small"
              startIcon={<ContentCopyIcon />}
            >
              {messages.buttons.copy}
            </Button>
          </Stack>
        )}

        <InviteDialog isInviteDialogOpen={isInviteDialogOpen} closeInviteDialog={closeInviteDialog} />

        <SettingsDialog closeSettingsDialog={closeSettingsDialog} isSettingsDialogOpen={isSettingsDialogOpen} />
      </Container>
    )
  );
}
