import { groupBy } from 'lodash';
import { List, Stack } from '@common-components';
import { Settings, Support } from '@icons';
import SupportDialog from 'dialogs/SupportDialog';
import { UserRole } from 'enums';
import { useBoolean, useCurrentUser } from 'hooks';
import { messages } from 'i18n';
import { RouteDefinitionMap } from 'types';
import LoginControls from 'components/layout/header/LoginControls';
// TODO: reduce the dependency of the settings dialog on the broker components so it can be moved to the common dialogs
// eslint-disable-next-line import/no-restricted-paths
import SettingsDialog from 'broker/dialogs/SettingsDialog';
import SideBarMenuButtonItem from './SideBarButtonMenuItem';
import SideBarMenuGroup from './SideBarMenuGroup';
import { ButtonMenuItem } from './utils';

interface SideBarMenuProps {
  routes: RouteDefinitionMap;
}

export default function SideBarMenu({ routes }: SideBarMenuProps) {
  const [isSupportDialogOpen, { off: closeSupportDialog, on: openSupportDialog }] = useBoolean(false);
  const [isSettingsDialogOpen, { off: closeSettingsDialog, on: openSettingsDialog }] = useBoolean(false);
  const menuItemsMap = Object.values(routes).filter((route) => !!route.menuItem);

  const { me } = useCurrentUser();
  const { label } = messages.SupportModal;
  const supportButton: ButtonMenuItem = { label, Icon: Support };
  const settingsButton: ButtonMenuItem = { label: 'Settings', Icon: Settings };

  const menuGroups = groupBy(menuItemsMap, ({ roles }) => roles);

  const listItems = Object.values(menuGroups).map((menuGroup, index) => (
    <SideBarMenuGroup
      /* eslint-disable-next-line react/no-array-index-key */
      key={index}
      menuItems={menuGroup.map((menuItem) => ({ ...menuItem, menuItem: menuItem.menuItem! }))}
    />
  ));

  return (
    <>
      <Stack justifyContent="space-between" flex={1}>
        <List sx={{ width: 1 }} className="cap-nav-bar-top-list">
          {listItems}
        </List>
        <List className="nav-bar-bottom-list">
          <SideBarMenuButtonItem menuItem={settingsButton} handleClick={openSettingsDialog} />
          {me?.role !== UserRole.Admin && (
            <SideBarMenuButtonItem menuItem={supportButton} handleClick={openSupportDialog} />
          )}
          <Stack alignItems="center">
            <LoginControls />
          </Stack>
        </List>
      </Stack>
      {me && <SettingsDialog closeSettingsDialog={closeSettingsDialog} isSettingsDialogOpen={isSettingsDialogOpen} />}
      <SupportDialog isSupportDialogOpen={isSupportDialogOpen} closeSupportDialog={closeSupportDialog} />
    </>
  );
}
