import { notificationTypeConfig, NotificationTypeConfigProps } from '@common/config';
import { Send, SvgIconComponent } from '@icons';
import { NotificationType } from 'enums';
import { messages } from 'i18n';
import { activeTabUrlParamValues, BrokerUrlParams } from 'broker/broker-routes';
import { EmailItemType } from 'broker/components/Emails/types';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { ReplyMode } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EmailReplyInnerPage/types';
import { useNavigateToMarketingUpdate } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingUpdate/useNavigateToMarketingUpdate';
import useEmailAction from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Email/useEmailAction';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';

// adding workspace specific attribute per notification type (such as navigating to a workspace route once task is clicked on)
interface TaskItemConfig extends Partial<NotificationTypeConfigProps> {
  type: string;
  cta: {
    text: string;
    onClick: () => void;
    startIcon?: SvgIconComponent;
  };
}

interface UseGetNotificationTypeConfigProps {
  notificationType: NotificationType;
  entityId?: string;
}

export function useGetTaskItemConfig({
  entityId,
  notificationType,
}: UseGetNotificationTypeConfigProps): TaskItemConfig {
  const { navigateToEmailReplyEditor } = useEmailAction();
  const navigate = useNavigate();
  const { proposalUrl } = useWorkspaceUrls();
  const { navigateToMarketingUpdate } = useNavigateToMarketingUpdate();
  const notificationsMessages = messages.notifications;

  const additionalNotificationTypeConfigProps: {
    [key in NotificationType]: Omit<TaskItemConfig, 'type'>;
  } = {
    [NotificationType.ShareAcknowledgment]: {
      cta: {
        text: notificationsMessages.cta.acknowledgement,
        onClick: () => {},
      },
    },
    [NotificationType.ShareMarketingUpdate]: {
      cta: {
        text: notificationsMessages.cta.marketingUpdate,
        onClick: navigateToMarketingUpdate,
      },
    },
    [NotificationType.ShareProposal]: {
      cta: {
        text: notificationsMessages.cta.proposal,
        onClick: () => {
          navigate(proposalUrl, {
            routeKey: RouteKey.WorkspaceTab,
            state: undefined,
          });
        },
      },
    },
    [NotificationType.UnrepliedEmail]: {
      cta: {
        text: notificationsMessages.cta.unrepliedEmail,
        startIcon: Send,
        onClick: () => {
          if (entityId) {
            navigateToEmailReplyEditor(
              { emailId: entityId, emailType: EmailItemType.SubmissionOutbound },
              ReplyMode.FollowUp,
              undefined,
              `?${BrokerUrlParams.ACTIVE_TAB}=${activeTabUrlParamValues.FILES}`,
            );
          }
        },
      },
    },
  };
  return {
    type: notificationTypeConfig[notificationType].text,
    isSOP: notificationTypeConfig[notificationType].isSOP,
    ...additionalNotificationTypeConfigProps[notificationType],
  };
}
