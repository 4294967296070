import { isEmpty } from 'lodash';
import { Divider, Stack, Typography } from '@common-components';
import { AlarmOff } from '@icons';
import { NotificationStatus } from 'enums';
import { Notification } from 'types';
import { statusConfig } from './config';

import TaskItem from './TaskItem/TaskItem';

export interface TasksProps {
  notifications: Notification[];
  notificationStatus: NotificationStatus;
  isDisabled?: boolean;
}
export default function TasksList({ notifications, isDisabled, notificationStatus }: TasksProps) {
  if (isEmpty(notifications)) {
    return (
      <Stack height={1} alignItems="center" justifyContent="center" gap={1}>
        <AlarmOff
          sx={{
            color: 'typography.nonEssential',
          }}
        />
        <Typography variant="body2" color="typography.nonEssential">
          {statusConfig[notificationStatus].emptyStateText}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack divider={<Divider />}>
      {notifications
        .sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime())
        .map((task) => (
          <TaskItem isDisabled={isDisabled} key={task.id} {...task} />
        ))}
    </Stack>
  );
}
