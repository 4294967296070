import { SubmissionMarketRequestStatus } from 'enums';
import { messages } from 'i18n';

export interface SubmissionMarketRequestStatusConfigProps {
  text: string;
}

type SubmissionMarketRequestStatusConfig = Record<
  SubmissionMarketRequestStatus,
  SubmissionMarketRequestStatusConfigProps
>;

const { statuses } = messages.submissionWorkspace.tabs.marketing;

export const submissionMarketRequestStatusConfig: SubmissionMarketRequestStatusConfig = {
  [SubmissionMarketRequestStatus.ReadyToMarket]: {
    text: statuses[SubmissionMarketRequestStatus.ReadyToMarket],
  },
  [SubmissionMarketRequestStatus.AwaitingQuote]: {
    text: statuses[SubmissionMarketRequestStatus.AwaitingQuote],
  },
  [SubmissionMarketRequestStatus.QuoteReady]: {
    text: statuses[SubmissionMarketRequestStatus.QuoteReady],
  },
  [SubmissionMarketRequestStatus.AwaitingBinder]: {
    text: statuses[SubmissionMarketRequestStatus.AwaitingBinder],
  },
  [SubmissionMarketRequestStatus.Bound]: {
    text: statuses[SubmissionMarketRequestStatus.Bound],
  },
  [SubmissionMarketRequestStatus.AwaitingPolicy]: {
    text: statuses[SubmissionMarketRequestStatus.AwaitingPolicy],
  },
  [SubmissionMarketRequestStatus.PolicyIssued]: {
    text: statuses[SubmissionMarketRequestStatus.PolicyIssued],
  },
  [SubmissionMarketRequestStatus.Declined]: {
    text: statuses[SubmissionMarketRequestStatus.Declined],
  },
  [SubmissionMarketRequestStatus.QuoteNotAccepted]: {
    text: statuses[SubmissionMarketRequestStatus.QuoteNotAccepted],
  },
  [SubmissionMarketRequestStatus.PendingCustomer]: {
    text: statuses[SubmissionMarketRequestStatus.PendingCustomer],
  },
};
