export enum EmailIntegrationProvider {
  Gmail = 'gmail',
  Yahoo = 'yahoo',
  Exchange = 'exchange',
  Outlook = 'outlook',
  Imap = 'imap',
  Icloud = 'icloud',
  Hotmail = 'hotmail',
  Aol = 'aol',
  Office365 = 'office365',
}
