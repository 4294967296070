import { useState } from 'react';
import { Box, Card, Grid, IconButton, InputLabel, Stack } from '@common-components';
import { Save as SaveIcon } from '@icons';
import { OrganizationType } from 'enums';
import { messages } from 'i18n';
import { Organization } from 'types';
import { Editor } from 'components/Editor/Editor';
import EmailIntegrationSettings from './EmailIntegrationSettings';
import WhitelistedDomainList from './WhitelistedDomainList';

const { letterheadTitle } = messages.admin.userOrganizationConfiguration;

interface UserOrganizationConfigurationProps {
  organization: Organization;
  setField: (fieldName: string, value: string | object) => void;
}

export default function UserOrganizationConfiguration({ organization, setField }: UserOrganizationConfigurationProps) {
  const [letterheadContent, setLetterheadContent] = useState<string | undefined>(organization.letterhead);
  const saveLetterheadDisabled = letterheadContent === organization.letterhead;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card sx={{ p: 2 }}>
          <InputLabel sx={{ mb: 1, fontSize: 12 }}>{letterheadTitle}</InputLabel>
          <Stack
            height={(theme) => theme.spacing(35)}
            flexDirection="column"
            sx={{
              borderRadius: 2,
              backgroundColor: 'common.white',
              boxShadow: 1,
              overflow: 'auto',
              position: 'relative',
            }}
          >
            <Editor editorContent={letterheadContent} setEditorContent={setLetterheadContent} />
            <Box position="absolute" bottom={8} right={8}>
              <IconButton
                icon={SaveIcon}
                color="secondary"
                variant="text"
                onClick={() => setField('letterhead', letterheadContent ?? '')}
                disabled={saveLetterheadDisabled}
              />
            </Box>
          </Stack>
        </Card>
      </Grid>
      {organization.type !== OrganizationType.Capitola && (
        <Grid item xs={3}>
          <Stack gap={1}>
            <WhitelistedDomainList organization={organization} />
            <EmailIntegrationSettings organization={organization} setField={setField} />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}
