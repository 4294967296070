import { HeraldCategoryType, HeraldFormInputType, HeraldSchemaFormat } from 'clients/types';
import { PartialSubmission } from 'types';
import {
  DynamicFormQuestionSource,
  heraldDueDateParameterIds,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';

interface FlowStaticQuestionConfigProps extends Omit<HeraldNormalizedParameter, 'value'> {
  getValue: (submission: PartialSubmission) => any;
}

export enum FlowStaticQuestionsIds {
  NeedByDate = 'flow_need_by_date',
}

export const flowStaticQuestionsConfig: FlowStaticQuestionConfigProps[] = [
  {
    getValue: (submission: PartialSubmission) => submission?.needByDate,
    parameter_id: FlowStaticQuestionsIds.NeedByDate,
    fieldLabel: 'Need By Date',
    section: 'Coverages',
    creates_array: false,
    affects_conditions: false,
    input_type: HeraldFormInputType.Date,
    heraldCategory: HeraldCategoryType.CoverageValues,
    isRequired: true,
    source: DynamicFormQuestionSource.Flow,
    schema: {
      title: '',
      type: 'string',
      format: HeraldSchemaFormat.Date,
    },
    showAfterOneOfTheseQuestions: heraldDueDateParameterIds,
  },
];
