export enum SubmissionStep {
  ReviewAgent = 'ReviewAgent',
  PreForm = 'PreForm',
  DynamicForm = 'DynamicForm',
  StaticForm = 'StaticForm',
  RiskAssessment = 'RiskAssessment',
}

export interface NewSubmissionEditLocationState {
  preForm: boolean;
}
