import { Route, Routes } from 'react-router-dom-latest';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import RoutedDialog from 'broker/components/RoutedDialog';
import { RouteKey } from 'broker/enums';
import Content, { ContentProps } from './components/Content';

interface CreateCustomerContactModalProps extends ContentProps {
  nestedPath?: string;
  hideBackdrop?: boolean;
}

export default function CreateCustomerContactModal({
  nestedPath = '',
  hideBackdrop,
  ...contentProps
}: CreateCustomerContactModalProps) {
  return (
    <Routes>
      <Route
        path={`${nestedPath}${BROKER_NESTED_ROUTES.MODALS.CREATE_CUSTOMER_CONTACT}`}
        element={
          <RoutedDialog
            routeKey={RouteKey.CreateCustomerContact}
            isLocationStateRequired={false}
            ariaLabelId={BROKER_NESTED_ROUTES.MODALS.CREATE_CUSTOMER_CONTACT}
            hideBackdrop={hideBackdrop}
          >
            {(props) => <Content {...props} {...contentProps} />}
          </RoutedDialog>
        }
      />
    </Routes>
  );
}
