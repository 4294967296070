export enum EmailEditorPageMode {
  SendRfq = 'SendRfq',
  SendCustomer = 'SendCustomer',
  SendMarket = 'SendMarket',
  MissingInformationRequest = 'MissingInformationRequest',
  UserNotification = 'UserNotification',
  EscalationPolicy = 'EscalationPolicy',
  CannotPursue = 'CannotPursue',
  PositiveExpectation = 'PositiveExpectation',
  NewQuote = 'NewQuote',
  MarketingReport = 'MarketingReport',
  InformationRequestsReport = 'InformationRequestsReport',
  ConceptualTower = 'ConceptualTower',
  Default = 'Default',
  LayerBinder = 'LayerBinder',
  LayerPolicy = 'LayerPolicy',
}
