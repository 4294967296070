import { Button } from '@common-components';
import { AttachFile } from '@icons';
import { InformationTooltipType } from 'contexts/information-tooltip/types';
import { useInformationTooltip } from 'hooks';
import { messages } from 'i18n';
import { activeTabUrlParamValues, BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';

interface AttachFilesButtonProps {
  informationTooltipType: InformationTooltipType;
}

export const AttachFilesButton = ({ informationTooltipType }: AttachFilesButtonProps) => {
  const { showInformationTooltip } = useInformationTooltip(informationTooltipType);
  const updateQueryParam = useUpdateQueryParam();

  return (
    <Button
      onClick={() => {
        updateQueryParam({
          addParams: [{ queryParam: BrokerUrlParams.ACTIVE_TAB, value: activeTabUrlParamValues.FILES }],
          removeParams: [BrokerUrlParams.SELECTED_FILE, BrokerUrlParams.SELECTED_EMAIL],
        });
        showInformationTooltip();
      }}
      startIcon={<AttachFile />}
      size="small"
    >
      {messages.emailEditor.attachFiles}
    </Button>
  );
};
