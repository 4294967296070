import moment from 'moment';
import { MouseEvent } from 'react';
import { Box, IconButton, NewMenu, Stack, Theme, Typography } from '@common-components';
import {
  AttachFile as AttachFileIcon,
  ContentCopy as ContentCopyIcon,
  MailOutline as MailOutlineIcon,
  MoreVert,
  ReplyOutlined as ReplyOutlinedIcon,
  SendOutlined as SendOutlinedIcon,
} from '@icons';
import { useCurrentUser, useHotjar, useOptionsMenu } from 'hooks';
import { useMutateInboundEmailMessage } from 'hooks/api/inboundEmailMessage';
import { messages } from 'i18n';
import { EmailTemplate, SyncedSystemType } from 'types';
import {
  getDisplayDate,
  getDisplayDateWithHoursAmPm,
  getFileSyncStatus,
  getTimeDisplay,
  syncBadgeTooltip,
} from 'utils';
import { HotjarEvents } from 'utils/hotjar-events';
import IconBadge from 'components/IconBadge';
import ListItemDescription from 'components/ListItemDescription';
import { MenuItemProps } from 'components/menu';
import SyncFileButtons from 'broker/components/common/SyncFileButtons';
import { EmailItemType, isOutboundType } from 'broker/components/Emails/types';
import { fileSyncConfig } from 'broker/components/FilesExplorer/utils';
import { EmailEditorPageMode } from 'broker/enums';
import { ReplyMode } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EmailReplyInnerPage/types';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import EmailMenus from './EmailMenus';
import { useCustomerTemplatesReplyMenu } from './useCustomerTemplatesReplyMenu';
import useEmailAction from './useEmailAction';
import { EmailListItemAttributes, isInboundCustomerEmail } from './utils';

interface EmailListItemProps {
  item: EmailListItemAttributes;
  onEmailSelected: (selectedEmail: EmailListItemAttributes) => void;
  indentation?: number;
  searchWord?: string;
  hideEmailActions?: boolean;
  templates?: EmailTemplate[];
  isTemplatesLoading?: boolean;
}

export const HOVER_STYLES = {
  transition: (theme: Theme) =>
    theme.transitions.create(['background-color', 'box-shadow', 'border-color', 'color'], {
      duration: theme.transitions.duration.short,
    }),
  '&:hover': {
    backgroundColor: 'blue.50',
  },
};
export const itemStyles = (indentation = 0) => ({
  cursor: 'pointer',
  py: 3,
  pl: 4 + indentation,
  pr: 2,
  minWidth: 0,
});

export default function EmailListItem({
  item,
  onEmailSelected,
  indentation = 0,
  searchWord = '',
  hideEmailActions = false,
  templates = [],
  isTemplatesLoading = false,
}: EmailListItemProps) {
  const { assignEmailToSubmission } = useMutateInboundEmailMessage();
  const { reply, followUp, duplicate, assign } = messages.submissionWorkspace.emailsTab.menuOptions;
  const { navigateToEmailReplyEditor } = useEmailAction();
  const { partialSubmission, markets: submissionMarkets } = useSubmissionsWorkspace();
  const hotjar = useHotjar();
  const optionsMenuState = useOptionsMenu();
  const { endUser } = useCurrentUser();

  const emailType = item.type;
  const isOutboxEmail = isOutboundType(emailType);
  const showReplyMenu = !!partialSubmission && isInboundCustomerEmail(item, partialSubmission);

  const onPreviewClicked = () => onEmailSelected(item);

  const handleClickOnCard = (e: MouseEvent) => {
    // Only trigger a preview if the "whitespace" of the card was clicked
    if (e.target === e.currentTarget) {
      onPreviewClicked();
    }
  };

  const handleDuplicate = () => {
    hotjar.event(HotjarEvents.EmailDuplicate);
    navigateToEmailReplyEditor({ emailId: item.id, emailType }, ReplyMode.Duplicate);
  };

  const handleReply = (mode?: EmailEditorPageMode) => {
    hotjar.event(HotjarEvents.EmailReply);
    navigateToEmailReplyEditor({ emailId: item.id, emailType }, ReplyMode.FollowUp, mode);
  };

  const assignToSubmission = async (inboundEmailMessageId: string) => {
    await assignEmailToSubmission.mutateAsync({
      submissionId: partialSubmission!.id,
      inboundEmailMessageId,
    });
  };

  const syncedSystem = endUser?.organization.syncedSystems?.[SyncedSystemType.EmailsStorage];

  const titleMail = isOutboxEmail ? item.recipientName : item.senderEmail;

  const fileSyncStatus = getFileSyncStatus(item);

  const customerTemplatesReplyMenu = useCustomerTemplatesReplyMenu({
    handleReplyTemplateClick: handleReply,
    templates,
  });

  const getMenuOptions = () => {
    const menuOptions: MenuItemProps[] = [];
    if (emailType !== EmailItemType.UnassignedInbound && !hideEmailActions) {
      menuOptions.push({
        label: isOutboxEmail ? followUp : reply,
        endAdornment: ReplyOutlinedIcon,
        // on customer inbound emails, we open the reply menu so we don't want to trigger the reply action on click
        onClick: showReplyMenu ? undefined : handleReply,
        subMenu: showReplyMenu ? customerTemplatesReplyMenu : undefined,
      });
    }
    if (emailType === EmailItemType.UnassignedInbound) {
      menuOptions.push({
        label: assign,
        onClick: () => assignToSubmission(item.id),
        endAdornment: ReplyOutlinedIcon,
      });
    }
    if (isOutboxEmail && !hideEmailActions) {
      menuOptions.push({
        label: duplicate,
        onClick: handleDuplicate,
        endAdornment: ContentCopyIcon,
      });
    }
    // Commented out because the downloadEmail is not available in Nylas v3 API
    // menuOptions.push({
    //   label: download,
    //   onClick: () => downloadEmail(item.boxFileId!),
    //   endAdornment: FileDownloadIcon,
    //   disabled: !item.boxFileId,
    //   disabledTooltipContent: currentlyNotAvailable,
    // });
    return menuOptions;
  };

  const dateAsMoment = moment(item.date);
  const isToday = dateAsMoment.isSame(moment(), 'day');
  const displayDate = isToday ? getTimeDisplay(item.date) : getDisplayDate(item.date);

  return (
    <Stack
      onClick={handleClickOnCard}
      columnGap={0.25}
      sx={{
        ...HOVER_STYLES,
        ...itemStyles(indentation),
      }}
    >
      {!!partialSubmission && (
        <EmailMenus email={item} emailType={emailType} submission={partialSubmission} markets={submissionMarkets} />
      )}
      <Stack direction="row" flex={1} sx={{ minWidth: 0 }} alignItems="center">
        <Stack direction="row" flex={1} alignItems="center" gap={2} sx={{ minWidth: 0 }}>
          <Box position="relative" onClick={onPreviewClicked}>
            {isOutboxEmail ? <SendOutlinedIcon /> : <MailOutlineIcon />}
            {fileSyncStatus && (
              <Box position="absolute" bottom={-3} right={-7}>
                <IconBadge
                  {...fileSyncConfig[fileSyncStatus]}
                  tooltipContent={syncBadgeTooltip(item, syncedSystem)}
                  size="extraSmall"
                />
              </Box>
            )}
          </Box>

          <Stack direction="column" gap={1} minWidth={0} flex={1} onClick={handleClickOnCard} pt={1}>
            <Box onClick={onPreviewClicked}>
              <ListItemDescription
                title={titleMail ?? ''}
                titlePrefix={`${
                  isOutboxEmail
                    ? messages.submissionWorkspace.emailsTab.sentTo
                    : messages.submissionWorkspace.emailsTab.from
                }: `}
                titleTooltip={titleMail ?? ''}
                subtitle={item.subject}
                subtitleTooltip={item.subject}
                searchTerm={searchWord}
                itemType="secondary"
              />
              <Typography
                color="typography.nonEssential"
                variant="overline"
                title={getDisplayDateWithHoursAmPm(item.date)}
              >
                {displayDate}
              </Typography>
            </Box>
          </Stack>
          {item.hasAttachments && (
            <Stack sx={{ cursor: 'pointer' }} onClick={onPreviewClicked}>
              <IconButton icon={AttachFileIcon} color="secondary" variant="text" size="small" />
            </Stack>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" gap={0.25}>
          <SyncFileButtons
            syncStatus={fileSyncStatus}
            syncSystem={SyncedSystemType.EmailsStorage}
            id={item.boxFileId}
            displayText={item.subject}
            submissionId={partialSubmission!.id}
          />
          <IconButton
            icon={MoreVert}
            color="secondary"
            variant="text"
            size="small"
            onClick={optionsMenuState.openMenu}
            loading={isTemplatesLoading}
          />
          <NewMenu
            subMenuPlacement="left"
            optionsMenuState={optionsMenuState}
            menuItems={[
              {
                key: 'email-actions',
                items: getMenuOptions(),
              },
            ]}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
