import { ChangeEvent, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@common-components';
import { SubmissionTerminationReason, SubmissionTerminationStatus } from 'enums';
import { useBoolean, useMutateSubmission, useToast } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import { submissionTerminationReasonConfig } from 'broker/configuration-mappers/submission-termination-reason';

interface TerminateSubmissionDialogProps {
  showTerminationConfirmation: boolean;
  closeTerminationConfirmation: () => void;
  targetStatus: SubmissionTerminationStatus;
  submission: PartialSubmission;
  onUpdateSuccess?: () => Promise<void>;
}

export default function TerminateSubmissionDialog({
  submission,
  showTerminationConfirmation,
  closeTerminationConfirmation,
  targetStatus,
  onUpdateSuccess,
}: TerminateSubmissionDialogProps) {
  const { showToast } = useToast();
  const [terminationReason, setTerminationReason] = useState<SubmissionTerminationReason>();
  const [terminationReasonDetails, setTerminationReasonDetails] = useState<string>('');
  const [isTerminating, { on: startTerminating, off: stopTerminating }] = useBoolean(false);
  const submissionApi = useMutateSubmission({ cancelInvalidation: true });

  const setReasonOnChange = (_event: ChangeEvent<HTMLInputElement>, value: string) => {
    setTerminationReason(value as SubmissionTerminationReason);
    setTerminationReasonDetails('');
  };
  const terminateSubmission = async () => {
    startTerminating();
    await submissionApi.updateSubmission.mutateAsync({
      id: submission.id,
      data: {
        status: targetStatus,
        archiveReason: { reason: terminationReason!, details: terminationReasonDetails },
      },
    });
    await onUpdateSuccess?.();
    stopTerminating();
    closeTerminationConfirmation();
    showToast('success', { message: messages.submissionWorkspace.termination.successMessage(targetStatus) });
  };
  return (
    <Dialog open={showTerminationConfirmation} onClose={closeTerminationConfirmation} alertDialog>
      <DialogHeader
        id="terminate-dialog-header"
        title={messages.submissionWorkspace.termination.confirmationDialog.title(targetStatus)}
        onClose={closeTerminationConfirmation}
      />
      <DialogContent>
        <Typography variant="body1" color="textPrimary" sx={{ mb: 1 }}>
          {messages.submissionWorkspace.termination.confirmationDialog.message(targetStatus)}
        </Typography>
        <RadioGroup name="controlled-radio-group" value={terminationReason} onChange={setReasonOnChange}>
          {Object.entries(submissionTerminationReasonConfig)
            .filter(([, { status }]) => !status || status === targetStatus)
            .map(([reason, { message }]) => (
              <Stack key={reason}>
                <FormControlLabel value={reason} control={<Radio />} label={message} />
                {terminationReason === reason && (
                  <TextField
                    value={terminationReasonDetails}
                    onChange={(e) => setTerminationReasonDetails(e.target.value)}
                    fullWidth
                    placeholder={message}
                    margin="dense"
                    variant="outlined"
                  />
                )}
              </Stack>
            ))}
        </RadioGroup>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: messages.submissionWorkspace.termination.confirmationDialog.confirmLabel(targetStatus),
          onClick: terminateSubmission,
          loading: isTerminating,
          disabled: !terminationReason,
        }}
        cancelButton={{ children: messages.buttons.cancel, onClick: closeTerminationConfirmation }}
      />
    </Dialog>
  );
}
