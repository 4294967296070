import { FormArrayFieldProps } from 'components/hookFormComponents/FormArrayFields/FormArrayFields';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { subjectivitiesFormArrayFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/subjectivitiesConfig';
import { SubjectivitiesFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';

export default function SubjectivitiesArrayFields({ getFieldName, disabled }: FormArrayFieldProps) {
  const subjectivityFieldName = getFieldName(subjectivitiesFormArrayFieldsConfig[SubjectivitiesFieldsNames.Value].name);
  const subjectivityFieldId = getFieldName(subjectivitiesFormArrayFieldsConfig[SubjectivitiesFieldsNames.Value].id);

  return (
    <FormTextField
      name={subjectivityFieldName}
      id={subjectivityFieldId}
      disabled={disabled}
      multiline
      minRows={6}
      maxRows={10}
      optional
    />
  );
}
