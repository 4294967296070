import { useCallback, useRef } from 'react';

export function useRefState<T>(initialValue: T): [() => T, (setValue: T) => void] {
  const valueRef = useRef(initialValue);

  const setValue = useCallback((newValue: T) => {
    valueRef.current = newValue;
  }, []);

  const getValue = useCallback(() => valueRef.current, []);

  return [getValue, setValue];
}
