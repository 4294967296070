import { BoxItem } from 'box-ui-elements/es';
import * as yup from 'yup';
import { Check, Sync } from '@icons';
import { BoxTemplateLabels, FileSyncStatus } from 'enums';
import { messages } from 'i18n';
import { SubmissionFileMetadata } from 'types';
import { customYupValidateFileName } from 'utils';
import { getFileMetadataField } from 'utils/files-utils';
import { IconBadgeProps } from 'components/IconBadge';
import { ExtendedBoxItem } from './types';

export function shouldApplySearchInputFilter(input: string) {
  return input.length < 2;
}

export const getFileIsHidden = (file: BoxItem) => getFileMetadataField(file, BoxTemplateLabels.IsHidden) === 'True';

export const getFileMarketIds = (file: BoxItem): string[] | undefined => {
  const marketIds = getFileMetadataField(file, BoxTemplateLabels.MarketId);
  return marketIds ? marketIds.split(',') : undefined;
};

export const getFileDate = (lastModifiedString: string) => {
  const today = new Date();
  const lastModified = new Date(lastModifiedString);

  if (
    lastModified.getFullYear() === today.getFullYear() &&
    lastModified.getMonth() === today.getMonth() &&
    lastModified.getDate() === today.getDate()
  ) {
    return messages.fileExplorer.today;
  }

  return lastModified.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
  });
};

export const fileLastModified = (fileInfo: BoxItem) => {
  const fileDate = getFileDate(fileInfo.modified_at!);
  return `${messages.fileExplorer.lastModified} ${fileDate}`;
};

export const fileCreatedBy = (fileInfo: BoxItem) => fileInfo.created_by!.name;
export const fileModifiedBy = (fileInfo: BoxItem) => fileInfo.modified_by!.name;

// File Rename Form

export interface FileRenameFormData {
  name: string;
}

export enum FileRenameFormFieldsNames {
  Name = 'name',
}

export const fileRenameSchema = yup.object().shape({
  name: yup
    .string()
    .default('')
    .test('customYupValidateFileName', 'customYupValidateFileName', customYupValidateFileName()),
});

export interface FileRenameFormFieldsConfigProps {
  label: string;
  name: FileRenameFormFieldsNames;
  id: string;
}

type FileRenameFormFieldsConfig = {
  [formField in FileRenameFormFieldsNames]: FileRenameFormFieldsConfigProps;
};

export const fileRenameFormFieldsConfig: FileRenameFormFieldsConfig = {
  [FileRenameFormFieldsNames.Name]: {
    label: messages.fileExplorer.formFields.name.label,
    name: FileRenameFormFieldsNames.Name,
    id: FileRenameFormFieldsNames.Name,
  },
};

export const getFileWithSyncMetadata = (
  fileItem: BoxItem,
  additionalFileMetadata?: SubmissionFileMetadata[],
): ExtendedBoxItem => {
  const ownAdditionalFileMetadata = additionalFileMetadata?.find(
    (submissionFileMetadata) => submissionFileMetadata.fileId === fileItem.id,
  );
  return {
    ...fileItem,
    syncMetadata: ownAdditionalFileMetadata?.metadata,
  };
};

export const fileSyncConfig: Record<FileSyncStatus, Omit<IconBadgeProps, 'tooltipText'>> = {
  [FileSyncStatus.Synced]: {
    IconComponent: Check,
    bgcolor: 'grey.100',
  },
  [FileSyncStatus.Syncing]: {
    IconComponent: Sync,
    bgcolor: 'warning.main',
  },
};
