// enum for prompt result feature related to submission extraction: has its own enum as it also has a specific config
export enum SubmissionExtractionPromptResultFeature {
    SubmissionInsuredName = 'SubmissionInsuredName',
    SubmissionCoverageLines = 'SubmissionCoverageLines',
    SubmissionApplicantPrimaryAddress = 'SubmissionApplicantPrimaryAddress',
    SubmissionApplicantLocation = 'SubmissionApplicantLocation',
    SubmissionApplicantMailingAddress = 'SubmissionApplicantMailingAddress',
    SubmissionTotalPayroll = 'SubmissionTotalPayroll',
    SubmissionTotalAnnualRevenue = 'SubmissionTotalAnnualRevenue',
    SubmissionTotalAssets = 'SubmissionTotalAssets',
    SubmissionYearsOfOperation = 'SubmissionYearsOfOperation',
    SubmissionLegalEntity = 'SubmissionLegalEntity',
    SubmissionApplicationDomainName = 'SubmissionApplicationDomainName',
    SubmissionIndustryClassification = 'SubmissionIndustryClassification',
    SubmissionOrganizationsCorporateStructure = 'SubmissionOrganizationsCorporateStructure',
    SubmissionPhoneNumber = 'SubmissionPhoneNumber',
    SubmissionPartTimeEmployeesNumber = 'SubmissionPartTimeEmployeesNumber',
    SubmissionFullTimeEmployeesNumber = 'SubmissionFullTimeEmployeesNumber',
    SubmissionEmployeesNumber = 'SubmissionEmployeesNumber',
    SubmissionHasDomain = 'SubmissionHasDomain',
    SubmissionPlEffectiveDate = 'SubmissionPlEffectiveDate',
    SubmissionGlEffectiveDate = 'SubmissionGlEffectiveDate',
    SubmissionCyberEffectiveDate = 'SubmissionCyberEffectiveDate',
    SubmissionMlEffectiveDate = 'SubmissionMlEffectiveDate',
    SubmissionEffectiveDate = 'SubmissionEffectiveDate',
    SubmissionNeedByDate = 'SubmissionNeedByDate',
    SubmissionCyberLossRunIndicator = 'SubmissionCyberLossRunIndicator',
    SubmissionPLLossRunIndicator = 'SubmissionPLLossRunIndicator',
    SubmissionDataApplicantContactName = 'SubmissionDataApplicantContactName',
    SubmissionDataApplicantEmailAddress = 'SubmissionDataApplicantEmailAddress',
}

export enum GeneralPromptResultFeature {
    QuoteInsight = 'QuoteInsight',
    AcknowledgmentEmail = 'AcknowledgmentEmail',
    QuotePremium = 'QuotePremium',
    QuoteExpiresAt = 'QuoteExpiresAt',
    QuoteTriaPremium = 'QuoteTriaPremium',
    QuoteAggregateLimit = 'QuoteAggregateLimit',
    PolicyExpirationDate = 'PolicyExpirationDate',
    QuoteCarrierFees = 'QuoteCarrierFees',
    RiskAssessment = 'RiskAssessment',
    MarketingUpdateEmail = 'MarketingUpdateEmail',
}

// usage for the enum as value
export const PromptResultFeature = {
    ...GeneralPromptResultFeature,
    ...SubmissionExtractionPromptResultFeature,
} as const;

// usage for the type of the merged enum
export type PromptResultFeatureType = (typeof PromptResultFeature)[keyof typeof PromptResultFeature];
