import { useCallback, useState } from 'react';

export enum RowsPerPage {
  Five = 5,
  Ten = 10,
  Twenty = 20,
  Fifty = 50,
}

interface PaginationProps {
  defaultRowsPerPage?: RowsPerPage;
}

export function usePagination(paginationProps?: PaginationProps) {
  const { defaultRowsPerPage = RowsPerPage.Fifty } = paginationProps || {};
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const paginationReset = useCallback(() => {
    setPage(1);
  }, []);

  return {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    paginationReset,
  };
}
