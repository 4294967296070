import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import Content from './Content';

export default function ExpiringTower() {
  const { markets } = useSubmissionsWorkspace();
  if (!markets) {
    return null;
  }

  return (
    <WithRouteLocationState routeKey={RouteKey.ExpiringTower} isStateRequired={false}>
      {(locationState) => <Content submissionMarkets={markets} locationState={locationState} />}
    </WithRouteLocationState>
  );
}
