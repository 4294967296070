import { useState } from 'react';

export enum DraftEditorMode {
  Preview = 'Preview',
  EditForAll = 'EditForAll',
  EditForMarket = 'EditForMarket',
}

export default function useDraftEditorMode(initialMode: DraftEditorMode) {
  const [draftEditorMode, setDraftEditorMode] = useState<DraftEditorMode>(initialMode);

  return { draftEditorMode, setDraftEditorMode };
}
