import { SxProps, Theme, Typography } from '@common-components';
import { uniteStyles } from 'utils';

interface FieldLabelProps {
  text: string;
  variant?: 'body2' | 'body2Bold';
  sx?: SxProps<Theme>;
}
export const FieldLabel = ({ text, variant = 'body2', sx }: FieldLabelProps) => (
  <Typography variant={variant} sx={uniteStyles({ mr: 1 }, sx)} color="text.secondary">
    {`${text}:`}
  </Typography>
);
