/* eslint-disable react/jsx-props-no-spreading */

import SideBarMenuItem, { SideBarMenuItemProps } from './SideBarMenuItem';

interface SideBarMenuGroupProps {
  menuItems: SideBarMenuItemProps[];
}

export default function SideBarMenuGroup({ menuItems }: SideBarMenuGroupProps) {
  return (
    <>
      {menuItems.map((menuItem: SideBarMenuItemProps) => (
        <SideBarMenuItem key={menuItem.menuItem.path} {...menuItem} />
      ))}
    </>
  );
}
