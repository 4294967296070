import _ from 'lodash';
import { ChangeEvent, FormEvent, useState } from 'react';
import { Button, Link, Stack, Typography } from '@common-components';
import { useBulkCreateMarkets } from 'hooks';
import { BulkCreateError, Market } from 'types';
import { Dialog, DialogContent, DialogHeader } from 'components/Dialog';

interface MarketsBulkUploadDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function MarketsBulkUploadDialog({ isOpen, onClose }: MarketsBulkUploadDialogProps) {
  const { bulkCreateMarkets } = useBulkCreateMarkets();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [updatedMarketRows, setUpdatedMarketRows] = useState<Array<Market> | null>(null);
  const [errors, setErrors] = useState<BulkCreateError[]>([]);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    const response = await bulkCreateMarkets.mutateAsync({
      data: {
        file: selectedFile!,
      },
    });
    if (response) {
      setUpdatedMarketRows(response.created);

      setErrors(response.errors);
    }
    setIsSubmitting(false);
    setSelectedFile(null);
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedMarketRows(null);
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const closeDialog = () => {
    setIsSubmitting(false);
    setSelectedFile(null);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      aria-labelledby="markets-bulk-upload"
      fullScreen
      sx={{ height: 1 }}
      muiPaperProps={{ sx: { bgcolor: 'common.white' } }}
    >
      <DialogHeader title="Upload Markets From CSV" id="marketsBulkUpload" onClose={closeDialog} />

      <DialogContent sx={{ bgcolor: 'grey.100', height: 1 }}>
        <form onSubmit={onSubmit}>
          <Stack mt={3} direction="column" gap={5}>
            <input type="file" onChange={handleFileSelect} />
            <Link
              href="https://docs.google.com/spreadsheets/d/1-RfOPk7ZxeU7X7kuyBUuQlQqm1LRUrWp/edit?usp=sharing&ouid=117662899460173828708&rtpof=true&sd=true"
              target="_blank"
            >
              Example File
            </Link>
            <Button loading={isSubmitting} onClick={onSubmit} disabled={!selectedFile} variant="contained">
              Upload File
            </Button>
          </Stack>
        </form>
        {updatedMarketRows !== null && (
          <Stack mt={3} gap={1}>
            <Typography color="green.400" variant="subtitle1">{`File uploaded successfully (${
              _.uniqBy(updatedMarketRows, 'organizationName').length
            } Markets , ${updatedMarketRows.length} marketIssuingCompany)`}</Typography>

            <Typography color="red" variant="subtitle1">{`${errors.length} rows failed due to errors`}</Typography>
            {errors.length > 0 && (
              <>
                <Typography variant="h5" pt={5}>
                  Errors:
                </Typography>
                <Stack mt={3} gap={1}>
                  {errors.map((error) => (
                    <Typography key={error.rowNumber} variant="body2">
                      Line {error.rowNumber}: {error.error}
                    </Typography>
                  ))}
                </Stack>
              </>
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}
