import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Divider, Stack } from '@common-components';
import { HeraldApplicationResponse } from 'clients/types';
import { RowsPerPage, useGenerateSuggestedProductsContent } from 'hooks';
import { messages } from 'i18n';
import { InsuranceProduct, SubmissionMarket } from 'types';
import NoItemsFound from 'broker/components/common/NoItemsFound';
import TablePagination from 'broker/components/common/TablePagination';
import { AIButtonWithPopup } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIButtonWithPopup';
import { SelectedProductsMap } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/types';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { useCreateAIThread } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { ProductsTableView } from './ProductsTableView';

interface ProductsProps {
  items: InsuranceProduct[];
  submissionMarkets: SubmissionMarket[];
  selectedProducts: SelectedProductsMap;
  onProductSelected: (product: InsuranceProduct) => void;
  onProductRemoved: (productId: string) => void;
  isLoadingProducts: boolean;
  sortedAndFilteredItems: InsuranceProduct[];
  search: string;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<RowsPerPage>>;
  heraldApplicationResponse?: HeraldApplicationResponse;
  header?: ReactNode;
}

export function Products({
  items,
  submissionMarkets,
  selectedProducts,
  onProductSelected,
  onProductRemoved,
  sortedAndFilteredItems,
  isLoadingProducts,
  search,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  header,
  heraldApplicationResponse,
}: ProductsProps) {
  const showItems = isLoadingProducts || items.length > 0;

  const handleChange = (value: number) => {
    setPage(value);
  };

  const { submission } = useSubmissionsWorkspace();
  const { generateSuggestedProductsContent } = useGenerateSuggestedProductsContent();

  const { isLoading: isAIContentLoading, isContentStreaming } = useAIContext();

  useCreateAIThread(() => generateSuggestedProductsContent({ submissionId: submission!.id }), false);

  const productsSlice = sortedAndFilteredItems.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage);

  return (
    <Stack height={1}>
      <AIButtonWithPopup
        popupTitle={messages.submissionWorkspace.recommendedProducts}
        tooltipText={
          isAIContentLoading && !isContentStreaming
            ? messages.submissionWorkspace.generatingProducts
            : messages.submissionWorkspace.recommendationsReady
        }
      />
      <Stack flex={1}>
        {header}
        {showItems && (
          <ProductsTableView
            quoteExits={heraldApplicationResponse?.quote_exits}
            products={productsSlice}
            submissionMarkets={submissionMarkets}
            selectedProducts={selectedProducts}
            onProductSelected={onProductSelected}
            onProductRemoved={onProductRemoved}
            isLoading={isLoadingProducts}
            searchInput={search}
            bottomTableContent={
              <Stack>
                <Divider />
                <TablePagination
                  from={page * rowsPerPage - rowsPerPage + 1}
                  to={page * rowsPerPage}
                  outOf={sortedAndFilteredItems.length}
                  handleChange={handleChange}
                  count={Math.ceil(sortedAndFilteredItems.length / rowsPerPage)}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  overrideContainerStyle={{ borderRadius: 0, bgcolor: 'common.white' }}
                />
              </Stack>
            }
          />
        )}
        {!showItems && <NoItemsFound title={messages.marketsPage.emptyState} />}
      </Stack>
    </Stack>
  );
}
