import { HeraldQuoteMetadata } from '@common/types';
import { ErrorCallback } from 'contexts';
import { Client, NormalizedErrorResponseBody } from './base';
import {
  HeraldApiError,
  HeraldApplicationResponse,
  HeraldCreateApplication,
  HeraldErrorResponse,
  HeraldIndexEntry,
  HeraldIndexEntryResponse,
  HeraldSubmissionResponse,
  HeraldUpdateApplication,
} from './types';

const HERALD_BASE_URL = process.env.REACT_APP_HERALD_BASE_URL;

export interface HeraldClientOptions {
  getToken: () => Promise<string | undefined>;
  onError: ErrorCallback;
}

const MAX_INDEX_ENTRIES_RESULTS = 20;

/**
 * A client for performing calls to Herald API directly, using access token.
 */
export class HeraldApiClient extends Client {
  private heraldResource!: string;

  public get resourceName(): string {
    return this.heraldResource;
  }

  constructor({ getToken, onError }: HeraldClientOptions) {
    if (HERALD_BASE_URL === undefined) {
      throw new Error('REACT_APP_HERALD_BASE_URL environment variable must be defined');
    }
    super({
      baseUrl: HERALD_BASE_URL,
      getBearerToken: getToken,
      onError,
    });
  }

  protected normalizeErrorResponse(errorResponseBody: any): NormalizedErrorResponseBody {
    const heraldError = errorResponseBody as HeraldErrorResponse;
    return {
      message: `Herald API - ${heraldError.errors[0]?.message}`,
      extraLogInfo: { heraldErrors: heraldError.errors },
    };
  }

  public async getApplicationById(appId: string): Promise<HeraldApplicationResponse> {
    this.heraldResource = 'applications';
    const url = this.buildUrl(appId);
    const response = await this.fetch(
      url,
      {
        method: 'GET',
      },
      { throwOnError: true },
    );

    return response as HeraldApplicationResponse;
  }

  public async createApplication(heraldCreateApplication: HeraldCreateApplication): Promise<HeraldApplicationResponse> {
    this.heraldResource = 'applications';
    const url = this.buildUrl();
    const response = await this.fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify(heraldCreateApplication),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      {
        throwOnError: true,
      },
    );

    return response as HeraldApplicationResponse;
  }

  public async updateApplication(
    applicationId: string,
    data: HeraldUpdateApplication,
  ): Promise<HeraldApplicationResponse | null> {
    this.heraldResource = 'applications';
    const url = this.buildUrl(applicationId);
    const response = await this.fetch(
      url,
      {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      {
        throwOnError: true,
        passthroughStatusCodes: [400],
      },
    );

    if (response && typeof response === 'object' && 'errors' in response) {
      // eslint-disable-next-line no-throw-literal
      throw response as HeraldApiError;
    }

    if (!response) {
      return null;
    }

    return response as HeraldApplicationResponse;
  }

  private appendIndexEntrySearchParams(rawIndustryValue: string) {
    const query: { naics_2017?: string; search?: string } = {};
    // The industry rawValue *can* contain both the NAICS code and the description
    // Extract the NAICS code and the description from the raw value
    // Example: "541511 - Custom Computer Programming Services"
    const rawValue = rawIndustryValue.trim();

    const naicsMatch = rawValue.match(/\d{2,6}/);
    if (naicsMatch) {
      query.naics_2017 = encodeURIComponent(naicsMatch[0]);
    }

    const descriptionMatch = rawValue.match(/[a-zA-Z]+/g);
    if (descriptionMatch) {
      // In the case of multiple terms, each term is captured as a single item in the match result
      // ['Custom', 'Computer', 'Programming', 'Services']
      const filteredSearchTerms = ['naics'];
      const description = descriptionMatch
        .filter((term) => !filteredSearchTerms.includes(term.toLowerCase()))
        .join(' ');
      if (description !== '') {
        query.search = encodeURIComponent(description);
      }
    }
    return query;
  }

  public async retrieveIndustryClassificationByHeraldId(heraldId: string): Promise<HeraldIndexEntry> {
    this.heraldResource = 'classifications/naics_index_entries';

    const url = this.buildUrl(heraldId);

    const response = await this.fetch(
      url,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      { throwOnError: true },
    );

    return (response as { classification: HeraldIndexEntry }).classification;
  }

  public async searchIndexEntries(value: string): Promise<HeraldIndexEntryResponse> {
    this.heraldResource = 'classifications';

    const url = this.buildUrl('naics_index_entries', {
      ...this.appendIndexEntrySearchParams(value),
      limit: MAX_INDEX_ENTRIES_RESULTS,
    });

    const response = await this.fetch(
      url,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      { throwOnError: true },
    );

    return response as HeraldIndexEntryResponse;
  }

  public async createSubmission(applicationId: string): Promise<HeraldSubmissionResponse> {
    this.heraldResource = 'submissions';
    const url = this.buildUrl();
    const response = await this.fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify({
          producer_id: process.env.REACT_APP_HERALD_PRODUCER_ID,
          application: {
            id: applicationId,
          },
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      {
        throwOnError: true,
      },
    );

    return response as HeraldSubmissionResponse;
  }

  public async getQuoteById(quoteId: string): Promise<HeraldQuoteMetadata> {
    this.heraldResource = 'quotes';
    const url = this.buildUrl(quoteId);
    const response = await this.fetch(
      url,
      {
        method: 'GET',
      },
      { throwOnError: true },
    );

    return (response as { quote: HeraldQuoteMetadata }).quote;
  }
}
