import {
  useInformationTooltipActions,
  useInformationTooltipState,
} from 'contexts/information-tooltip/informationTooltipProvider';
import { InformationTooltipType } from 'contexts/information-tooltip/types';

export function useInformationTooltip(informationTooltip: InformationTooltipType) {
  const informationTooltipState = useInformationTooltipState();
  const { setInformationTooltip } = useInformationTooltipActions();

  // short delay to wait for toolbox to open after animation and only then to place to the tooltip near the toolbox
  const showInformationTooltip = () => {
    setTimeout(() => setInformationTooltip({ type: informationTooltip, isShown: true }), 500);
  };

  const hideInformationTooltip = () => {
    setInformationTooltip({ type: informationTooltip, isShown: false });
  };

  return {
    shouldShow: informationTooltipState[informationTooltip],
    showInformationTooltip,
    hideInformationTooltip,
  };
}
