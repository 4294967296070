import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { logger } from 'utils';
import { useEnvironment } from './useEnvironment';
import { useMount } from './useMount';

export const useLoggerInitialization = (beforeSend: () => void) => {
  const environment = useEnvironment();

  useMount(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment,
        beforeSend: (event) => {
          beforeSend();
          return event;
        },
      });
    }

    if (process.env.REACT_APP_GIT_HASH) {
      logger.addCustomVariable('gitHash', process.env.REACT_APP_GIT_HASH);
    }
    if (process.env.REACT_APP_BUILD_NUMBER) {
      logger.addCustomVariable('buildNumber', process.env.REACT_APP_BUILD_NUMBER);
    }
  });
};
