import { useState } from 'react';
import { DataGrid, Link, Paper, Stack, Typography } from '@common-components';
import { EmailTemplateClient } from 'clients';
import { useBoolean, useMount } from 'hooks';
// eslint-disable-next-line import/no-restricted-paths
import { useClient } from 'hooks/useClient';
import { messages } from 'i18n';
import { DefaultEmailTemplate } from 'types';
import DefaultTemplateDialog from 'admin/components/DefaultTemplateDialog';

export default function DefaultEmailTemplatesPage() {
  const emailTemplatesClient = useClient(EmailTemplateClient);

  const [loading, { on: startLoading, off: stopLoading }] = useBoolean(false);
  const [rows, setRows] = useState<DefaultEmailTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<DefaultEmailTemplate | undefined>();

  const { title, subtitle, tableHeaders } = messages.admin.defaultTemplates.listView;

  useMount(() => {
    startLoading();
    emailTemplatesClient.getDefaultTemplates().then((response) => {
      setRows((response?.templates || []).map((template, index) => ({ ...template, id: index })));
      stopLoading();
    });
  });

  const columns = [
    {
      field: 'name',
      headerName: tableHeaders.name,
      flex: 0.1,
      renderCell: ({ row }: { row: DefaultEmailTemplate }) => (
        <Link onClick={() => setSelectedTemplate(row)} underline="hover" sx={{ cursor: 'pointer' }}>
          <Typography variant="body2" noWrap>
            {row.template.name}
          </Typography>
        </Link>
      ),
      sortable: false,
    },
    {
      field: 'type',
      headerName: tableHeaders.type,
      flex: 0.1,
      renderCell: ({ row }: { row: DefaultEmailTemplate }) => (
        <Typography variant="body2" noWrap>
          {row.template.type}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: 'roles',
      headerName: tableHeaders.roles,
      flex: 0.1,
      renderCell: ({ row }: { row: DefaultEmailTemplate }) => (
        <Typography variant="body2" noWrap>
          {row.roles.join(', ')}
        </Typography>
      ),
      sortable: false,
    },
  ];

  return (
    <Paper sx={{ mt: 0 }}>
      <Stack p={2} gap={1}>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body1">{subtitle}</Typography>
      </Stack>
      <DataGrid
        autoHeight
        columns={columns}
        disableSelectionOnClick
        loading={loading}
        rowCount={rows.length}
        rows={rows}
      />
      <DefaultTemplateDialog
        isOpen={!!selectedTemplate}
        onClose={() => setSelectedTemplate(undefined)}
        defaultTemplate={selectedTemplate}
      />
    </Paper>
  );
}
