import { ExtractedAttribute } from '@common/types';
import { isEqual } from 'lodash';
import { PromptResultFeature } from 'enums';
import { SuggestedValueProps } from 'hooks';
import { Suggestion } from 'types';
import { FormStateBaseProps } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';

function buildSuggestedValueForArrayProps(extractedAttributes: ExtractedAttribute<string[]>): SuggestedValueProps {
  function setFormArrayFieldSuggestedValue(value: string[], oldValue?: string[]): string[] {
    return oldValue ? [...value, ...oldValue] : [...value];
  }
  function isEqualToFormValue(suggestion: Suggestion, formValues?: string[]): boolean {
    return !!formValues && isEqual(suggestion.value, formValues);
  }

  function isCurrentFormArrayFieldEmpty(value?: FormStateBaseProps[]): any {
    return !value || value.length === 0;
  }
  return {
    suggestion: extractedAttributes,
    setSuggestedValue: setFormArrayFieldSuggestedValue,
    isFormFieldEmpty: isCurrentFormArrayFieldEmpty,
    isEqualToFormValue,
    promptResultFeature: PromptResultFeature.SubmissionCoverageLines,
  };
}

export function extractValidCoverageLinesSuggestion(
  extractedAttribute: ExtractedAttribute<string[]>,
  options: string[],
): SuggestedValueProps | undefined {
  const foundOptions = options.filter((option) =>
    extractedAttribute.value?.some((extractedValue) => extractedValue.toLowerCase() === option.toLowerCase()),
  );
  if (foundOptions.length > 0) {
    return buildSuggestedValueForArrayProps({
      value: foundOptions,
      reason: extractedAttribute.reason,
      citations: extractedAttribute.citations,
    });
  }
  return undefined;
}
