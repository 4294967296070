// eslint-disable-next-line no-restricted-imports
import { Button as MuiButton } from '@mui/material';
import { debounce } from 'lodash';
import { ElementType, MouseEvent, ReactNode, useCallback } from 'react';
import { InfoOutlined } from '@icons';
// eslint-disable-next-line import/no-internal-modules
import { useHotjar } from 'hooks/useHotjar';
import { baseInputComponentsProps } from 'themes/sizes';
import { HotjarEvents } from 'utils/hotjar-events';
import { alpha, Box, CircularProgress, darken, Theme, Typography } from 'components/mui-index';
import Tooltip from './Tooltip';
import { Color, Size, TooltipWrapperProps, Variant } from './types';

export interface ButtonProps extends TooltipWrapperProps {
  /** The text to display in the button */
  children: ReactNode;
  /** The variant to use */
  variant?: Variant;
  /** The size of the button */
  size?: Size;
  /** The color of the button */
  color?: Color | 'inherit';
  /** The icon to display at the start of the button */
  startIcon?: ReactNode;
  /** The icon to display at the end of the button */
  endIcon?: ReactNode;
  /** The function to call when the button is clicked */
  onClick?: ((event?: MouseEvent<HTMLButtonElement>) => void) | ((event: MouseEvent<HTMLButtonElement>) => void);
  /** The function to call when the mouse is down on the button */
  onMouseDown?: ((event?: MouseEvent<HTMLButtonElement>) => void) | ((event: MouseEvent<HTMLButtonElement>) => void);
  /** Whether the button is loading */
  loading?: boolean;
  /** Whether the button is disabled */
  disabled?: boolean;
  /** Whether the button should take up the full width of its container */
  fullWidth?: boolean;
  /** The hotjar event to report when the button is clicked */
  hotjarEvent?: HotjarEvents;
  /** The component used for the root node. Either a string to use a HTML element or a component. */
  component?: ElementType;
}

const circularProgressColor: Record<Variant, string> = {
  contained: 'common.white',
  outlined: 'grey.400',
  text: 'grey.400',
};

const variantColorProps: Record<Variant, Record<Color | 'inherit', any>> = {
  contained: {
    inherit: {
      color: 'inherit',
      backgroundColor: 'inherit',
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(darken(theme.palette.common.black, 0.1), 0.1),
      },
      '&[disabled]': {
        color: 'text.disabled',
        bgcolor: 'blueGrey.200',
      },
    },
    primary: {},
    secondary: {
      color: 'text.primary',
      bgcolor: 'blueGrey.100',
      '&[disabled]': {
        color: 'text.disabled',
        bgcolor: 'blueGrey.200',
      },
      '&:hover': {
        bgcolor: 'blueGrey.200',
      },
    },
    error: {},
    info: {
      color: 'common.white',
      bgcolor: 'purple.700',
      '&[disabled]': {
        color: 'text.disabled',
        bgcolor: 'blueGrey.200',
      },
      '&:hover': {
        bgcolor: 'purple.800',
      },
    },
    success: {},
    warning: {},
  },
  outlined: {
    inherit: {},
    primary: {},
    error: {},
    info: {},
    success: {},
    warning: {},
    secondary: {
      color: 'text.primary',
      border: 1,
      borderColor: 'blueGrey.200',
      '&[disabled]': {
        color: 'text.disabled',
        borderColor: 'blueGrey.400',
        bgcolor: 'blueGrey.200',
      },
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.common.black, 0.06),
        borderColor: 'blueGrey.800',
      },
    },
  },
  text: {
    inherit: {},
    primary: {},
    error: {},
    info: {},
    success: {},
    warning: {},
    secondary: {
      color: 'text.primary',
      bgcolor: 'transparent',
      '&[disabled]': {
        color: 'text.disabled',
      },
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.common.black, 0.06),
      },
    },
  },
};

export default function Button({
  children,
  variant = 'text',
  onClick,
  size = 'medium',
  color = 'primary',
  loading = false,
  disabled = false,
  fullWidth = false,
  startIcon,
  tooltipContent,
  disabledTooltipContent,
  tooltipPlacement = 'top',
  hotjarEvent,
  onMouseDown,
  isTooltipOpen,
  component = 'button',
  disableTooltipPadding = false,
  tooltipDarkMode = false,
  endIcon = null,
  ...props
}: ButtonProps) {
  const isDisabled = loading || disabled;
  const tooltipContentToUse = disabled ? disabledTooltipContent : tooltipContent;
  const hotjar = useHotjar();

  const clickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      if (hotjarEvent) {
        hotjar.event(hotjarEvent);
      }
      onClick(e);
    }
  };

  const mouseDownHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (onMouseDown) {
      if (hotjarEvent) {
        hotjar.event(hotjarEvent);
      }
      onMouseDown(e);
    }
  };

  // prevent double clicks
  // we're debouncing to queue up multiple clicks and only fire the first one
  const debouncedClickHandler = debounce(clickHandler, 500, { leading: true, trailing: false });
  const debouncedMouseDownHandler = debounce(mouseDownHandler, 500, { leading: true, trailing: false });
  // persist the debounce timeout across renders
  const stableClickHandler = useCallback(debouncedClickHandler, [debouncedClickHandler]);
  const stableMouseDownHandler = useCallback(debouncedMouseDownHandler, [debouncedMouseDownHandler]);

  return (
    <Tooltip
      tooltipContent={tooltipContentToUse ?? ''}
      placement={tooltipPlacement}
      isOpen={isTooltipOpen}
      disablePadding={disableTooltipPadding}
      darkMode={tooltipDarkMode}
      arrow
      {...props}
    >
      <Box display="inline-flex" overflow="hidden" width={fullWidth ? 1 : 'auto'}>
        <MuiButton
          startIcon={
            loading ? <CircularProgress size={12} sx={{ color: circularProgressColor[variant] }} /> : startIcon
          }
          endIcon={disabled && disabledTooltipContent ? <InfoOutlined /> : endIcon}
          disableElevation
          onClick={stableClickHandler}
          onMouseDown={stableMouseDownHandler}
          variant={variant}
          fullWidth={fullWidth}
          disabled={isDisabled}
          size={size}
          color={color}
          component={component}
          sx={{
            ...baseInputComponentsProps[variant][size],
            ...variantColorProps[variant][color],
          }}
        >
          <Typography variant="button" noWrap>
            {children}
          </Typography>
        </MuiButton>
      </Box>
    </Tooltip>
  );
}
