import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { LoaderOverlay, Stack, Typography } from '@common-components';
import { FormMode } from 'enums';
import { useMount, useMutatePdf } from 'hooks';
import { useMutateBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { logger } from 'utils';
import Banner, { BannerMode } from 'broker/components/Banner';
import ContentPreview from 'broker/components/box/ContentPreview';
import useSetFilesExplorerViewMode from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/components/useSetFilesExplorerViewMode';
import { QuoteFormCreate } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/types';
import { FlowGenerationStatus } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/types';
import { EncryptedFileError, useGenerateAndUploadFlowPDF } from './pdf-creator/flow-quote-pdf-creator-util';

interface FlowQuoteProps {
  mode: FormMode;
  submission: PartialSubmission;
  marketQuoteFileIds: string[];
  marketName: string;
  flowQuoteIds: string[];
  setFlowQuoteIds: (flowQuoteId: string[]) => void;
  flowGenerationStatus: FlowGenerationStatus;
  setFlowGenerationStatus: (flowGenerationStatus: FlowGenerationStatus) => void;
  isDirty: boolean;
}

export default function FlowQuote({
  mode,
  submission,
  marketQuoteFileIds,
  marketName,
  setFlowQuoteIds,
  flowQuoteIds,
  flowGenerationStatus,
  setFlowGenerationStatus,
  isDirty,
}: FlowQuoteProps) {
  const quoteMethods = useFormContext();
  const { deleteFile } = useMutateBoxItems();
  // undefined means error
  const values = quoteMethods.getValues() as QuoteFormCreate;
  const generateAndUploadFlowPDF = useGenerateAndUploadFlowPDF();
  const hasError = flowGenerationStatus === FlowGenerationStatus.Error;
  const [errorMessage, setErrorMessage] = useState('');
  const { decryptPdf } = useMutatePdf();
  useSetFilesExplorerViewMode(true, mode);

  useEffect(() => {
    if (errorMessage && !hasError) {
      setErrorMessage('');
    }
  }, [errorMessage, hasError]);

  const removeDecryptionFromFile = async (fileDetails: { fileId: string; fileName: string }) => {
    logger.log('info', {
      message: 'Encrypted File - Attempting to Decrypt',
      ...fileDetails,
    });
    await decryptPdf.mutateAsync({
      boxItemId: fileDetails.fileId,
      folderId: submission.boxFolderId,
    });
    logger.log('info', { message: 'Successfully decrypted file', ...fileDetails });
  };

  const createFlowQuote = async () => {
    if (mode === FormMode.create || (mode === FormMode.edit && (isDirty || isEmpty(flowQuoteIds)))) {
      setFlowGenerationStatus(FlowGenerationStatus.Loading);
      if (mode === FormMode.create) {
        // Delete the flow quote if it exists - can happen if you go back to the previous step and then come back (in this case agreed with product that we will create flow quote pdf again, as user may have changed data in the form)
        // for update we won't delete the file as it can have been used in the submission for other things, and also if the user chooses not to update after the flow quote regenerates,  the previous flow quote are used in that quote and if we delete them from box it will be a problem
        if (flowQuoteIds.length > 0) {
          await Promise.all(flowQuoteIds.map((flowQuoteId) => deleteFile.mutateAsync({ fileId: flowQuoteId })));
          setFlowQuoteIds([]);
        }
      }
      generateAndUploadFlowPDF(submission, values, marketQuoteFileIds, marketName)
        .then((flowQuoteBoxItem) => {
          if (flowQuoteBoxItem) {
            setFlowQuoteIds([flowQuoteBoxItem.id]);
            setFlowGenerationStatus(FlowGenerationStatus.Idle);
          } else {
            logger.log('warning', { message: 'generateAndUploadFlowPDF has ended with no boxItem', flowQuoteBoxItem });
            setFlowGenerationStatus(FlowGenerationStatus.Error);
          }
        })
        .catch(async (e) => {
          if (e instanceof EncryptedFileError) {
            const fileDetails = { fileId: e.fileId, fileName: e.fileName };
            try {
              await removeDecryptionFromFile(fileDetails);
              await createFlowQuote();
            } catch (_e) {
              logger.log('error', { message: 'Failed to decrypt file', ...fileDetails });
              setFlowGenerationStatus(FlowGenerationStatus.Error);
              setErrorMessage(messages.addQuotePage.flowQuoteError.getEncryptedFileError(fileDetails.fileName));
              throw _e;
            }
          } else {
            setFlowGenerationStatus(FlowGenerationStatus.Error);
            throw e;
          }
        });
    }
  };

  // Execute the PDF download on component mount
  useMount(() => {
    createFlowQuote();
  });

  const getContent = () => {
    if (hasError) {
      return (
        <Stack mt={2}>
          <Banner
            title={messages.addQuotePage.flowQuoteError.title}
            subtitle={errorMessage || messages.addQuotePage.flowQuoteError.defaultDescription}
            mode={BannerMode.Error}
          />
        </Stack>
      );
    }
    if (flowGenerationStatus === FlowGenerationStatus.Loading) {
      return <LoaderOverlay hideOverlay />;
    }
    return flowQuoteIds ? <ContentPreview fileId={flowQuoteIds[0]} hasHeader={false} sx={{ height: 1 }} /> : null;
  };

  return (
    <Stack height={1} py={4} px={12}>
      <Stack ml={hasError ? 0 : 2} mb={hasError ? 1 : 3}>
        <Typography variant="body1Bold">{messages.addQuotePage.flowQuoteTitle}</Typography>
      </Stack>
      {getContent()}
    </Stack>
  );
}
