import { Box, Stack, Typography } from '@common-components';
import { DOT_SEPARATOR } from 'utils';

interface BulletContentProps {
  prefix?: string;
  suffix?: string;
  bold?: string;
}
export default function BulletContent({ prefix, suffix, bold }: BulletContentProps) {
  return (
    <Stack direction="row" gap={0.5}>
      <Typography variant="body2" color="text.secondary">
        {DOT_SEPARATOR}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {prefix}{' '}
        {bold && (
          <Box component="span" typography="body2Bold" color="text.primary">
            {bold}
          </Box>
        )}{' '}
        {suffix}
      </Typography>
    </Stack>
  );
}
