import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom-latest';
import { useRetrieveUser, useSearchTeam } from 'hooks';
import { messages } from 'i18n';
import { User } from 'types';
import { userFullName } from 'utils';
import { DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import FormSkeleton from 'broker/components/skeletons/FormSkeleton';
import FormContent from './FormContent';
import { AddAgentToAgencyDialogLocationState } from './types';
import { FormMode, UrlParam } from './utils';

export interface ContentProps extends BaseRoutedDialogContentProps {
  onAgentCreated?: (user: User) => void;
  locationState?: AddAgentToAgencyDialogLocationState;
}

export default function Content({
  onClose,
  locationState,
  onAgentCreated,
  setDialogIsDirty,
  closeWithoutPrompt,
}: ContentProps) {
  const [searchParams] = useSearchParams();

  const id = searchParams.get(UrlParam.Id) ?? '';

  const state = locationState;

  const { data, isLoading } = useRetrieveUser({ id });

  const { items: teams } = useSearchTeam({ filter: { organizationId: state!.organizationId! } });

  const mode = id ? FormMode.Update : FormMode.Create;

  const title =
    mode === FormMode.Update
      ? messages.agenciesPage.agentForm.title[mode](data ? userFullName(data) : '')
      : messages.agenciesPage.agentForm.title[mode];

  const defaultValues = useMemo(
    () =>
      ({
        firstName: data?.firstName,
        lastName: data?.lastName,
        phoneNumber: data?.phoneNumber,
        email: data?.email,
        title: data?.title,
      } as Partial<User>),
    [data],
  );

  const teamId = data?.team.id || teams[0]?.id;

  return (
    <>
      <DialogHeader id="agent-dialog" onClose={onClose} title={title} />

      {!id || (data && !isLoading) ? (
        <FormContent
          teamId={teamId}
          organizationId={state?.organizationId!}
          organizationName={state?.organizationName!}
          onAgentCreated={onAgentCreated}
          mode={mode}
          setDialogIsDirty={setDialogIsDirty}
          onClose={onClose}
          defaultValues={defaultValues}
          closeWithoutPrompt={closeWithoutPrompt}
          id={id}
        />
      ) : (
        <>
          <DialogContent>
            <FormSkeleton />
          </DialogContent>
          <DialogFooter showSkeleton />
        </>
      )}
    </>
  );
}
