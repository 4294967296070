import { ReactComponent as LogoIcon } from 'assets/images/brand/flow-text-logo.svg';
import { uniteStyles } from 'utils';
import { SvgIcon } from 'components/mui-index';

interface CapitolaTextLogoProps {
  size?: number;
}

export default function FlowTextLogo({ size = 1 }: CapitolaTextLogoProps) {
  return (
    <SvgIcon
      sx={uniteStyles({ fontSize: 28, zoom: size, width: 86, height: 28 })}
      component={LogoIcon}
      viewBox="0 0 86 28"
    />
  );
}
