import { Chip, Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import { reasons } from './config';

interface SubmissionFormSpeedBumpDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  reasons: string[]; // Change to array
  setReasons: (value: string[]) => void; // Update to handle array
  notAnsweredCount: number;
}

export function SubmissionFormSpeedBumpDialog({
  isOpen,
  onClose,
  onConfirm,
  reasons: selectedReasons,
  setReasons,
  notAnsweredCount,
}: SubmissionFormSpeedBumpDialogProps) {
  const handleReasonToggle = (reason: string) => {
    if (selectedReasons.includes(reason)) {
      setReasons(selectedReasons.filter((r) => r !== reason));
    } else {
      setReasons([...selectedReasons, reason]);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} alertDialog alertDialogMaxWidth={600}>
      <DialogHeader
        id="submission-status-change-dialog-header"
        title={messages.incompleteSubmissionDialog.dialogTitle}
        onClose={onClose}
      />
      <DialogContent>
        <Typography variant="body1" color="textPrimary" mb={2}>
          {messages.incompleteSubmissionDialog.dialogContent.incompleteText(notAnsweredCount)}
        </Typography>
        <Typography variant="body1Bold" color="textPrimary" mb={2}>
          {messages.incompleteSubmissionDialog.dialogContent.skip}
        </Typography>
        <Stack direction="row" flexWrap="wrap" gap={1} mb={4}>
          {reasons.map((r) => (
            <Chip
              rounded
              key={r}
              label={r}
              onClick={() => handleReasonToggle(r)}
              size="large"
              variant="contained"
              color={selectedReasons.includes(r) ? 'primary' : 'secondary'}
            />
          ))}
        </Stack>

        <Typography variant="body1" color="textSecondary" mb={2}>
          {messages.incompleteSubmissionDialog.dialogContent.helperText}
        </Typography>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: messages.incompleteSubmissionDialog.buttons.back,
          onClick: onClose,
        }}
        cancelButton={{
          children: messages.incompleteSubmissionDialog.buttons.submit,
          onClick: onConfirm,
          disabled: selectedReasons.length === 0,
        }}
      />
    </Dialog>
  );
}
