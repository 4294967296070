import { submissionStatusConfig } from 'broker/configuration-mappers/submission-status-config';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export function useIsAcknowledgmentFlow() {
  const { partialSubmission } = useSubmissionsWorkspace();
  const isAcknowledgmentFlow = !!(
    partialSubmission?.status && submissionStatusConfig[partialSubmission?.status].preflight
  );
  return {
    isAcknowledgmentFlow,
  };
}
