import { BoxItem } from 'box-ui-elements/es';
import { isEmpty } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { Typography } from '@common-components';
import { messages } from 'i18n';
import { ConfirmationDialogGuard, ConfirmationDialogGuardAttributes } from 'broker/components/ConfirmationDialogGuard';
import {
  ATTACHMENTS_SIZE_LIMIT_MB,
  isTotalAttachmentsSizeReached,
} from 'broker/components/EmailEditor/utils/file-utils';

interface NoAttachmentsGuardProps {
  attachedFiles: BoxItem[];
  onSend: () => void;
  isCustomerEmail: boolean;
  children: (props: ConfirmationDialogGuardAttributes) => ReactElement;
}
export const NoAttachmentsGuard = ({ attachedFiles, onSend, isCustomerEmail, children }: NoAttachmentsGuardProps) => {
  const [attachedFilesMessage, setAttachedFilesMessage] = useState<string>();
  const [attachedFilesTitle, setAttachedFilesTitle] = useState<string>(
    messages.emailEditor.sendMessagesGuards.noAttachmentsTitle,
  );

  useEffect(() => {
    if (isEmpty(attachedFiles)) {
      setAttachedFilesMessage(
        isCustomerEmail
          ? messages.emailEditor.sendMessagesGuards.noAttachmentsCustomers
          : messages.emailEditor.sendMessagesGuards.noAttachmentsUnderwriters,
      );
    } else if (isTotalAttachmentsSizeReached(attachedFiles)) {
      setAttachedFilesTitle(messages.emailEditor.sendMessagesGuards.filesSizeAlertTitle);
      setAttachedFilesMessage(
        messages.emailEditor.sendMessagesGuards.attachmentsLimitSendingConfirmationMessage(ATTACHMENTS_SIZE_LIMIT_MB),
      );
    } else {
      setAttachedFilesMessage('');
    }
  }, [attachedFiles, isCustomerEmail]);

  return (
    <ConfirmationDialogGuard
      title={attachedFilesTitle}
      message={<Typography variant="body1">{attachedFilesMessage}</Typography>}
      action={onSend}
      showConfirmation={attachedFilesMessage !== ''}
      proceedButton={{ children: messages.emailEditor.sendMessagesGuards.sendAnyway }}
    >
      {({ guardedAction }) => children({ guardedAction })}
    </ConfirmationDialogGuard>
  );
};
