import { Address, ExtractedAttribute } from '@common/types';
import { parseTimezoneLessDate } from '@common/utils/date-utils';
import { isEmpty, round, toNumber } from 'lodash';
import { date, number } from 'yup';

export function getValidNumberSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  integerSchemaType = true,
): Pick<ExtractedAttribute<number>, 'value' | 'reason' | 'citations'> | undefined {
  if (extractedAttribute && number().isValidSync(extractedAttribute.value)) {
    const numberValue = toNumber(extractedAttribute.value);
    return {
      reason: extractedAttribute.reason,
      citations: extractedAttribute.citations,
      value: integerSchemaType ? round(numberValue) : numberValue,
    };
  }
  return undefined;
}

export function getValidDateSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
): Pick<ExtractedAttribute<string>, 'value' | 'reason' | 'citations'> | undefined {
  if (extractedAttribute?.value && date().isValidSync(extractedAttribute.value)) {
    return {
      value: parseTimezoneLessDate(extractedAttribute.value)?.toISOString(),
      citations: extractedAttribute.citations,
      reason: extractedAttribute.reason,
    };
  }
  return undefined;
}

export function getValidOptionSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  options: string[],
): Pick<ExtractedAttribute<string>, 'value' | 'reason' | 'citations'> | undefined {
  const foundOption = options.find((option) => option.toLowerCase() === extractedAttribute?.value?.toLowerCase());
  if (foundOption) {
    return {
      value: foundOption,
      citations: extractedAttribute.citations,
      reason: extractedAttribute.reason,
    };
  }
  return undefined;
}

export function getValidNumericStringSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
): Pick<ExtractedAttribute<string>, 'value' | 'reason' | 'citations'> | undefined {
  if (extractedAttribute && number().isValidSync(extractedAttribute.value)) {
    return {
      value: extractedAttribute.value,
      citations: extractedAttribute.citations,
      reason: extractedAttribute.reason,
    };
  }
  return undefined;
}

export function getValidAddressSuggestion(
  extractedAttribute: ExtractedAttribute<Address>,
): Pick<ExtractedAttribute<Address>, 'value' | 'reason' | 'citations'> | undefined {
  const addressValue = extractedAttribute.value;
  if (
    addressValue &&
    !isEmpty(addressValue.line1) &&
    !isEmpty(addressValue.city) &&
    !isEmpty(addressValue.postal_code) &&
    !isEmpty(addressValue.state)
  ) {
    return {
      reason: extractedAttribute.reason,
      citations: extractedAttribute.citations,
      value: {
        line1: addressValue.line1,
        line2: addressValue.line2,
        city: addressValue.city,
        state: addressValue.state,
        postal_code: addressValue.postal_code,
        // country_code has only 1 option in herald form, no need to extract it (if the rest of the address is valid, the country is valid)
        country_code: 'USA',
      },
    };
  }

  return undefined;
}
