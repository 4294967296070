// config.ts
export const stepConfig = {
  error: {
    active: { bg: 'error.main', icon: 'common.white' },
    inactive: { bg: 'grey.100', icon: 'error.main' },
  },
  required: {
    active: { bg: 'warning.main', icon: 'common.white' },
    inactive: { bg: 'grey.100', icon: 'warning.main' },
  },
  completed: {
    active: { bg: 'primary.main', icon: 'common.white' },
    inactive: { bg: 'grey.100', icon: 'primary.main' },
  },
  default: {
    active: { bg: 'primary.main', icon: 'common.white' },
    inactive: { bg: 'grey.100', icon: 'primary.main' },
  },
};

export const stepIconSize = 22;
export const lineSize = 6;
export const lineColor = 'grey.100';
