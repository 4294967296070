import { Button, Stack } from '@common-components';
import { messages } from 'i18n';
import { Editor } from 'components/Editor/Editor';
import { useGenerateTable } from './useGenerateTable';

interface ComposeProps {
  editorContent: string | undefined;
  setEditorContent: (value: string) => void;
  setIsDirty: (value: boolean) => void;
}

export default function Compose({ editorContent, setEditorContent, setIsDirty }: ComposeProps) {
  const htmlTable = useGenerateTable();

  const handleEditorChange = (value: string) => {
    setEditorContent(value);
    setIsDirty(true);
  };

  const handleRegenerateTable = () => {
    setEditorContent(htmlTable);
  };

  return (
    <Stack px={2} height={1}>
      <Stack mb={1} alignItems="end">
        <Button size="small" onClick={handleRegenerateTable} variant="text">
          {messages.marketingUpdate.generateTable.regenerate}
        </Button>
      </Stack>
      <Stack
        height={1}
        sx={{
          borderRadius: 2,
          border: 1,
          borderColor: 'divider',
          backgroundColor: 'common.white',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Editor editorContent={editorContent} setEditorContent={handleEditorChange} />
      </Stack>
    </Stack>
  );
}
