import { isBoolean } from 'lodash';
import { Fragment } from 'react';
import { KeyboardArrowRight } from '@icons';
// eslint-disable-next-line import/no-internal-modules
import { useHotjar } from 'hooks/useHotjar';
import { messages } from 'i18n';
import Button from 'components/Button';
import { Divider, Stack, Typography } from 'components/mui-index';
import { NestedMenuItem } from './NestedMenuItem';
import { NewMenuItem } from './NewMenuItem';
import { MenuGroup } from './types';
import { calculateItemsLimitMenuContainerHeight, itemHorizontalPadding } from './utils';

interface MenuContentProps {
  menuItems: MenuGroup[];
  isMenuOpen: boolean;
  closeMenu: () => void;
  subMenuPlacement?: 'left' | 'right';
  searchValue?: string;
}

export default function MenuContent({
  menuItems,
  isMenuOpen,
  closeMenu,
  subMenuPlacement,
  searchValue,
}: MenuContentProps) {
  // filtered empty groups menu items if all of their items are hidden
  const filteredMenuItems = menuItems.filter((menuItem) => menuItem.items.some((item) => !item.hidden));
  const { event: reportHotjarEvent } = useHotjar();
  return (
    <>
      {filteredMenuItems.map(({ key, subheader, header, items, selectionType, itemsLimit, moreActions }, index) => (
        <Fragment key={key || header}>
          {index > 0 && <Divider sx={{ my: 1 }} />}
          {header && !subheader && (
            <Typography
              component="div"
              px={itemHorizontalPadding}
              py={1}
              variant="caption2Bold"
              color="typography.nonEssential"
            >
              {header}
            </Typography>
          )}
          {header && subheader && (
            <Stack>
              <Typography component="div" px={itemHorizontalPadding} py={1} pb={0} variant="body2Bold">
                {header}
              </Typography>
              <Typography component="div" px={itemHorizontalPadding} variant="caption" color="grey.600">
                {subheader}
              </Typography>
              <Divider sx={{ mt: 2, mb: 1 }} />
            </Stack>
          )}

          {items.length === 0 && searchValue && (
            <Stack height={120} padding={2} direction="row" alignItems="center" justifyContent="center">
              <Typography>{messages.general.noItemsFound}</Typography>
            </Stack>
          )}

          {items.length > 0 && (
            <Stack
              sx={{
                height: (theme) => (itemsLimit ? calculateItemsLimitMenuContainerHeight(itemsLimit, theme) : undefined),
                overflowY: itemsLimit ? 'scroll' : undefined,
              }}
            >
              {items
                .filter((item) => !item.hidden)
                .map(
                  (
                    {
                      subMenu,
                      onClick,
                      label,
                      startAdornment,
                      endAdornment,
                      isSelected,
                      disabled,
                      color,
                      textColor,
                      hotjarEvent,
                      ...rest
                    },
                    itemIndex,
                  ) => {
                    if (subMenu) {
                      return (
                        <NestedMenuItem
                          key={label}
                          endAdornment={KeyboardArrowRight}
                          startAdornment={startAdornment}
                          label={label}
                          parentMenuOpen={isMenuOpen}
                          color={color}
                          menuPosition={subMenuPlacement}
                          {...rest}
                        >
                          <MenuContent menuItems={subMenu} isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
                        </NestedMenuItem>
                      );
                    }
                    return (
                      <NewMenuItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={label + itemIndex}
                        onClick={() => {
                          if (disabled) {
                            return;
                          }
                          if (!isBoolean(isSelected)) {
                            closeMenu();
                          }
                          if (onClick) {
                            if (hotjarEvent) {
                              reportHotjarEvent(hotjarEvent);
                            }
                            onClick();
                          }
                        }}
                        label={label}
                        color={color}
                        textColor={textColor}
                        isSelected={isSelected}
                        disabled={disabled}
                        endAdornment={endAdornment}
                        startAdornment={startAdornment}
                        selectionType={selectionType}
                        {...rest}
                      />
                    );
                  },
                )}
            </Stack>
          )}

          {moreActions && (
            <>
              <Divider sx={{ my: 1 }} />
              <Stack width={1} pr={1} direction="row" justifyContent={moreActions.justifyContent || 'flex-start'}>
                {moreActions.items.map((action) =>
                  !action.variant ? (
                    <NewMenuItem
                      key={action.text}
                      label={action.text}
                      onClick={action.onClick}
                      disabled={!!action.disabled}
                      variant="body2Bold"
                    />
                  ) : (
                    <Button
                      key={action.text}
                      onClick={action.onClick}
                      disabled={!!action.disabled}
                      variant={action.variant || 'text'}
                    >
                      {action.text}
                    </Button>
                  ),
                )}
              </Stack>
            </>
          )}
        </Fragment>
      ))}
    </>
  );
}
