import { useState } from 'react';
import { useParams } from 'react-router-dom-latest';
import { Box, Button, Card, Grid, InputLabel, Stack } from '@common-components';
import { UserStatus } from 'enums';
import { useMutateUser, useRetrieveUser } from 'hooks';
import { messages } from 'i18n';
import CardTextField from 'components/CardTextField';
import DetailCard from 'components/DetailCard';
import { Editor } from 'components/Editor/Editor';
import ADMIN_ROUTES from 'admin/admin-routes';
import CardSelect from 'admin/components/CardSelect';

export default function UserPage() {
  const { id: userId } = useParams<{ id: string }>();
  const { data: user } = useRetrieveUser({ id: userId!, enabled: !!userId });

  const { updateUser } = useMutateUser();

  const [signatureContent, setSignatureContent] = useState<string | undefined>();
  const saveSignatureDisabled = signatureContent === user?.signature;

  async function setField(fieldName: string, value: string | boolean) {
    await updateUser.mutateAsync({
      id: user!.id,
      data: {
        [fieldName]: value,
      },
    });
  }

  if (!user) {
    return null;
  }

  return (
    <Grid sx={{ mt: 0 }} container wrap="wrap" spacing={1}>
      <Grid item xs={12}>
        <DetailCard avatar={user.email.charAt(0).toUpperCase()} title={user.email}>
          <Grid sx={{ mt: 0 }} container wrap="wrap" spacing={1}>
            <Grid item xs={12} sm={6} lg={4}>
              <Card sx={{ p: 1 }}>
                <CardTextField label="Email" value={user.email} />
                <CardTextField
                  label="First Name"
                  value={user.firstName}
                  onChange={(value) => setField('firstName', value)}
                />
                <CardTextField
                  label="Last Name"
                  value={user.lastName}
                  onChange={(value) => setField('lastName', value)}
                />
                <CardTextField
                  label="Phone Number"
                  value={user.phoneNumber ?? ''}
                  onChange={(value) => setField('phoneNumber', value)}
                />
                <CardTextField
                  label="Organization"
                  to={ADMIN_ROUTES.getOrganizationById(user.organization.id)}
                  value={user.organization.name}
                />
                <CardTextField label="Team" to={ADMIN_ROUTES.getTeamById(user.team.id)} value={user.team.name} />
                <CardTextField label="Role" value={user.role} />
                <CardSelect
                  onChange={(value) => setField('status', value)}
                  label="Status"
                  items={UserStatus}
                  value={user.status}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <CardTextField
                label="What I Do"
                value={user.title ?? ''}
                onChange={(value) => setField('title', value)}
              />
              <CardTextField label="Note" value={user.note ?? ''} onChange={(value) => setField('note', value)} />
              <Card sx={{ p: 1 }}>
                <InputLabel sx={{ mb: 1, fontSize: 12 }}>Signature</InputLabel>
                <Stack
                  height={(theme) => theme.spacing(48.3)}
                  flexDirection="column"
                  sx={{
                    borderRadius: 2,
                    backgroundColor: 'common.white',
                    boxShadow: 1,
                    overflow: 'auto',
                  }}
                >
                  <Editor editorContent={signatureContent ?? user?.signature} setEditorContent={setSignatureContent} />
                </Stack>
                <Stack justifyContent="flex-end" direction="row">
                  <Box mt={4}>
                    <Button
                      variant="contained"
                      onClick={() => setField('signature', signatureContent ?? '')}
                      disabled={saveSignatureDisabled}
                    >
                      {messages.buttons.saveSignature}
                    </Button>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </DetailCard>
      </Grid>
    </Grid>
  );
}
