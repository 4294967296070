import { emailLabelConfig } from '@common/config/email-label-config';
import { useState } from 'react';
import { InfoOutlined as InfoOutlinedIcon, LabelOutlined as LabelOutlinedIcon } from '@icons';
import { CAPITOLA_SITE_ROLES, SubmissionEmailLabel } from 'enums';
import { useHotjar } from 'hooks';
import { useMutateSubmissionInboundEmail } from 'hooks/api/submissionInboundEmail';
import { messages } from 'i18n';
import { labelCompare } from 'utils/comparators';
import { HotjarEvents } from 'utils/hotjar-events';
import LabelsMenu, { LabelsMenuAnchorType, Option } from 'broker/components/LabelsMenu';
import useSingleFieldOptimisticUpdate from 'broker/hooks/useSingleFieldOptimisticUpdate';
import useGetSubmissionRelatedEmails from './useGetSubmissionRelatedEmails';
import { EmailListItemAttributes } from './utils';

interface InboundEmailLabelsMenuProps {
  submissionId?: string;
  email: EmailListItemAttributes;
  selectableLabels: SubmissionEmailLabel[];
}

export default function InboundEmailLabelsMenu({ submissionId, email, selectableLabels }: InboundEmailLabelsMenuProps) {
  const hotjar = useHotjar();
  const [isLabelsMenuOpen, setIsLabelsMenuOpen] = useState(false);
  const { updateSubmissionInboundEmail } = useMutateSubmissionInboundEmail();

  const { submissionEmailsList } = useGetSubmissionRelatedEmails({
    submissionId,
  });

  const isSingleLabelInUse = (label: SubmissionEmailLabel) => {
    // Condition not relevant for the label that is selected for the current email
    if (label === email.label) {
      return false;
    }

    if (emailLabelConfig[label].isSingle) {
      return !!submissionEmailsList.find((submissionEmail) => submissionEmail.label === label);
    }

    return false;
  };

  const updateSubmissionInboundLabel = async (label: SubmissionEmailLabel | null) =>
    updateSubmissionInboundEmail.mutateAsync({
      id: email.submissionInboundEmailId!,
      data: { label },
    });

  const { value: selectedLabel, onChange: onLabelChange } = useSingleFieldOptimisticUpdate<SubmissionEmailLabel | null>(
    {
      serverValue: email.label as SubmissionEmailLabel,
      apiUpdate: updateSubmissionInboundLabel,
    },
  );

  function getLabelOptions(): Option[] {
    const disabledProps = {
      disabled: true,
      disabledTooltipContent: messages.submissionWorkspace.emailsTab.labelInUse,
      endAdornment: InfoOutlinedIcon,
    };

    return selectableLabels
      .map((selectableLabel) => ({
        label: emailLabelConfig[selectableLabel].text,
        value: selectableLabel,
        ...(isSingleLabelInUse(selectableLabel) ? disabledProps : {}),
      }))
      .sort(labelCompare);
  }
  const isAutoLabeled =
    (selectedLabel && email.classifiedByUser && CAPITOLA_SITE_ROLES.includes(email.classifiedByUser.role)) || false;

  const getLabelMenuType = () => {
    if (isAutoLabeled) {
      return LabelsMenuAnchorType.EditLabelCoPilot;
    }

    if (selectedLabel) {
      return LabelsMenuAnchorType.EditLabel;
    }

    return LabelsMenuAnchorType.AddLabel;
  };

  function ownOnLabelChanged(label?: string) {
    hotjar.event(HotjarEvents.EmailLabeled);
    setIsLabelsMenuOpen(false);

    const newLabel = label ? (label as SubmissionEmailLabel) : null;
    return onLabelChange(newLabel);
  }

  // Make sure we don't render this component if there is no way for the update call to succeed.
  if (!email.submissionInboundEmailId) {
    return null;
  }

  return (
    <LabelsMenu
      options={getLabelOptions()}
      selectedLabels={
        selectedLabel
          ? [
              {
                label: emailLabelConfig[selectedLabel].text,
                value: selectedLabel,
              },
            ]
          : undefined
      }
      permanentLabel={isAutoLabeled && emailLabelConfig[selectedLabel!].autoLabelReadonly}
      anchorType={getLabelMenuType()}
      isOpenOverride={isLabelsMenuOpen}
      onClose={() => setIsLabelsMenuOpen(false)}
      onLabelChanged={ownOnLabelChanged}
      onClearLabel={ownOnLabelChanged}
      icon={LabelOutlinedIcon}
      addLabelText={messages.submissionWorkspace.emailsTab.addLabelButton}
    />
  );
}
