import {
  HomeWork,
  ImportantDevices,
  LocationCity,
  MarkunreadMailboxOutlined,
  PeopleAltOutlined,
  ViewQuilt,
  Work,
} from '@icons';
import { BROKER_SITE_ROLES, PORTAL_SITE_ROLES, UserRole } from 'enums';
import { messages } from 'i18n';
import { RouteDefinitionMap } from 'types';
import { BROKER_NESTED_ROUTES, BROKER_ROUTES } from './broker-routes';
import SubmissionAutonomousCreationModal from './dialogs/SubmissionAutonomousCreation';
import AgenciesPage from './pages/AgenciesPage';
import BackOfficePage from './pages/BackOfficePage';
import HomePage from './pages/HomePage';
import MarketsPage from './pages/MarketsPage';
import MyTeamPage from './pages/MyTeamPage';
import RetailerSubmissionsPage from './pages/RetailerSubmissionsPage';
import SubmissionsBoxPage from './pages/SubmissionsBoxPage';
import SubmissionWorkspacePage, {
  CompareQuotesModal,
  MarketingReport,
  PortalWorkspacePage,
  QuoteReview,
  QuoteStatusModal,
} from './pages/SubmissionWorkspacePage';

import TemplatesPage from './pages/TemplatesPage';
import Templates from './pages/TemplatesPage/Templates';

const brokerSiteRoles = [...BROKER_SITE_ROLES, UserRole.BackOffice];
const portalSiteRoles = [...PORTAL_SITE_ROLES, UserRole.BackOffice];

const brokerRoutes: RouteDefinitionMap = {
  [`/${BROKER_ROUTES.BACK_OFFICE}`]: {
    Component: BackOfficePage,
    roles: [UserRole.BackOffice],
    menuItem: {
      Icon: ImportantDevices,
      label: messages.menu.backOffice,
      divider: true,
      path: `/${BROKER_ROUTES.BACK_OFFICE}`,
    },
  },
  [`/${BROKER_ROUTES.SUBMISSION_BOX}`]: {
    Component: SubmissionsBoxPage,
    roles: brokerSiteRoles,
    menuItem: {
      Icon: MarkunreadMailboxOutlined,
      label: messages.menu.submissionBox,
      divider: true,
      path: `/${BROKER_ROUTES.SUBMISSION_BOX}`,
    },
  },
  [`/${BROKER_ROUTES.PORTAL}`]: {
    Component: RetailerSubmissionsPage,
    roles: portalSiteRoles,
  },
  [`/${BROKER_ROUTES.HOME}`]: {
    Component: HomePage,
    roles: brokerSiteRoles,
    menuItem: {
      Icon: HomeWork,
      label: messages.menu.submissions,
      divider: true,
      path: `/${BROKER_ROUTES.HOME}`,
    },
    isHomePage: true,
    nestedRoute: {
      [`${BROKER_NESTED_ROUTES.HOME.SUBMISSION_AUTONOMOUS_CREATION}/*`]: {
        Component: SubmissionAutonomousCreationModal,
        roles: brokerSiteRoles,
      },
    },
  },
  [`/${BROKER_ROUTES.MARKETS}/*`]: {
    Component: MarketsPage,
    roles: brokerSiteRoles,
    menuItem: {
      Icon: Work,
      label: messages.menu.markets,
      path: `/${BROKER_ROUTES.MARKETS}`,
    },
  },
  [`/${BROKER_ROUTES.TEMPLATES}`]: {
    Component: TemplatesPage,
    roles: brokerSiteRoles,
    menuItem: {
      Icon: ViewQuilt,
      label: messages.menu.templates,
      path: `/${BROKER_ROUTES.TEMPLATES}`,
    },
    nestedRoute: {
      ':templateId': {
        Component: Templates,
        roles: brokerSiteRoles,
      },
    },
  },
  [`/${BROKER_ROUTES.TEAM}`]: {
    Component: MyTeamPage,
    roles: brokerSiteRoles,
    menuItem: {
      Icon: PeopleAltOutlined,
      label: messages.menu.myTeam,
      path: `/${BROKER_ROUTES.TEAM}`,
    },
  },
  [`/${BROKER_ROUTES.AGENCIES}/*`]: {
    Component: AgenciesPage,
    roles: brokerSiteRoles,
    menuItem: {
      Icon: LocationCity,
      label: messages.menu.agencies,
      path: `/${BROKER_ROUTES.AGENCIES}`,
    },
  },
  [`/${BROKER_ROUTES.SUBMISSION}/*`]: {
    Component: SubmissionWorkspacePage,
    nestedRoute: {
      [`:tab/${BROKER_NESTED_ROUTES.WORKSPACE.COMPARE_QUOTES}/*`]: {
        Component: CompareQuotesModal,
        roles: brokerSiteRoles,
      },
      [`:tab/${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE_STATUS}/*`]: {
        Component: QuoteStatusModal,
        roles: brokerSiteRoles,
      },
      [`:tab/${BROKER_NESTED_ROUTES.WORKSPACE.SUBMISSION_AUTONOMOUS_CREATION}/*`]: {
        Component: SubmissionAutonomousCreationModal,
        roles: brokerSiteRoles,
      },
    },
    roles: brokerSiteRoles,
  },
  [`/${BROKER_ROUTES.RETAILER_SUBMISSION}/*`]: {
    Component: PortalWorkspacePage,
    nestedRoute: {
      [`${BROKER_NESTED_ROUTES.WORKSPACE.MARKETING_REPORT}/*`]: {
        Component: MarketingReport,
        roles: portalSiteRoles,
      },
      [`${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE}/*`]: {
        Component: QuoteReview,
        roles: portalSiteRoles,
      },
    },
    roles: portalSiteRoles,
  },
};

export default brokerRoutes;
