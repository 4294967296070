import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Container, DialogContent, Divider, Stack, Typography } from '@common-components';
import { InformationTooltipType } from 'contexts/information-tooltip/types';
import { ActivityType, BoxTemplateLabels, EmailTemplateType, SubmissionEmailLabel } from 'enums';
import { useBoolean, useBoxClient, useCurrentUser, useInformationTooltip, useToast } from 'hooks';
import { useMutateSubmissionEmail } from 'hooks/api/submissionEmail';
import { messages } from 'i18n';
import { Contact, EmailMessageBatch, Layer, Quote, Submission, SubmissionMarket } from 'types';
import { BrokerUrlParams } from 'broker/broker-routes';
import { DirtyRouteGuard } from 'broker/components/DirtyContentGuard';
import { EmailTemplateContext } from 'broker/components/EmailEditor/store/types';
import { convertContactToRecipient } from 'broker/components/Emails/recipient-utils';
import SendingMessagesScreen, { EmailStatus } from 'broker/components/Emails/SendingMessagesScreen';
import { Recipient } from 'broker/components/Emails/types';
import useAttachedFilesState from 'broker/components/Emails/useAttachedFilesState';
import { useCcState } from 'broker/components/Emails/useCcState';
import { useUpdateQueryParam } from 'broker/hooks';
import useLoadFilesSelection from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Files/useLoadFilesSelection';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { TOOLBOX_TABS } from 'broker/pages/SubmissionWorkspacePage/types';
import { revertSmartObjectToVariable } from 'broker/utils/email-utils/SmartVariable/utils';
import { ProposalActionButtons } from './ProposalActionButtons';
import { ProposalContent } from './ProposalContent';
import ProposalEditorFooter from './ProposalEditorFooter';
import { TemplateVariablesResolver } from './template-variables-resolver';
import { useProposalActions } from './useProposalActions';
import { useProposalContacts } from './useProposalContacts';
import { useProposalFields } from './useProposalFields';

export const Proposal = ({
  submission,
  markets,
  quotes,
  layers,
}: {
  submission: Submission;
  markets: SubmissionMarket[];
  quotes: Quote[];
  layers: Layer[];
}) => {
  // local state of the unResolvedTemplateBody that is optimistic updated before the submission is api updated
  // once focusing out from the editor and choosing a file attachment we want the re-resolving of the proposal template to work with the local data
  // refactoring of optimistic update is part of task #CAP-2353
  const [unResolvedTemplateBody, setUnResolvedTemplateBody] = useState(submission.proposal?.body);
  const { me } = useCurrentUser();
  const { createSubmissionEmail } = useMutateSubmissionEmail();

  const { showInformationTooltip } = useInformationTooltip(InformationTooltipType.ProposalAttachments);
  const { recipientGroup, toggleContact, newContacts, onClearNewContacts, addContact } =
    useProposalContacts(submission);
  const { attachedFiles } = useAttachedFilesState({ preSelectedFileIds: submission.proposal?.attachedFiles ?? [] });
  const { showToast } = useToast();

  const [isAttachedFilesDirty, setIsAttachedFilesDirty] = useState(false);

  useEffect(() => {
    setUnResolvedTemplateBody(submission.proposal?.body);
  }, [submission]);

  useLoadFilesSelection({
    setIsDirty: setIsAttachedFilesDirty,
    preSelectedFileIds: submission.proposal?.attachedFiles,
  });

  const emailTemplateType = EmailTemplateType.NewBusinessProposal;

  const templateVariablesResolver = useMemo(() => {
    const templateContextData: EmailTemplateContext = {
      submission,
      extendedMarketRequests: [],
      submissionMarkets: markets,
      quotes,
      user: me!,
      recipient: recipientGroup,
      attachedFiles,
      layers,
      selectedTemplate: emailTemplateType,
    };

    return new TemplateVariablesResolver(templateContextData);
  }, [submission, markets, quotes, me, recipientGroup, attachedFiles, layers, emailTemplateType]);
  const {
    proposalContent,
    setProposalContent,
    proposalSubject,
    setProposalSubject,
    isDirty: isFieldsDirty,
    setIsDirty,
    proposalInitialValue,
  } = useProposalFields(submission, templateVariablesResolver, emailTemplateType, unResolvedTemplateBody);

  const isDirty = isFieldsDirty || isAttachedFilesDirty;

  const [proposalSendingStatus, setProposalSendingStatus] = useState<EmailStatus>(EmailStatus.Editing);

  const { availableTeammates, selectedTeammates, toggleTeammateSelection } = useCcState([]);

  const updateQueryParam = useUpdateQueryParam();

  const { sendProposal, saveProposal, isSaving, resolvedMessages } = useProposalActions(
    submission,
    recipientGroup,
    proposalContent,
    proposalSubject,
    attachedFiles,
    templateVariablesResolver,
    setProposalSendingStatus,
    selectedTeammates,
  );

  const handleSaveProposal = async (newRecipient?: Recipient | null) => {
    try {
      setUnResolvedTemplateBody(revertSmartObjectToVariable(proposalContent));
      await saveProposal(newRecipient);
      setIsDirty(false);
    } catch (e) {
      setUnResolvedTemplateBody(unResolvedTemplateBody);
    }
  };

  useEffect(() => {
    if (proposalSendingStatus === EmailStatus.Sent) {
      showToast('success', {
        message: messages.submissionWorkspace.customerSection.proposalSent,
      });
      setProposalSendingStatus(EmailStatus.Editing);
      setIsDirty(false);
    }
  }, [proposalSendingStatus, setIsDirty, submission, showToast]);

  async function addSubmissionEmails(sentEmails?: EmailMessageBatch) {
    const recipientOrganizationId = recipientGroup.id;
    const label = SubmissionEmailLabel.Proposal;

    return Promise.all(
      sentEmails?.messages.map((message) =>
        createSubmissionEmail.mutateAsync({
          data: {
            emailMessageId: message.id,
            unresolvedContent: proposalContent,
            submissionId: submission.id,
            recipientOrganizationId,
            label,
            addTask: false,
          },
        }),
      ) ?? [],
    );
  }

  const boxClient = useBoxClient();
  const { logSubmissionActivity } = useSubmissionsWorkspace();
  const onSendClick = async () => {
    await sendProposal(async (sentEmails) => {
      await Promise.all(
        attachedFiles.map((file) =>
          boxClient.addFileMetadata(file.id, { [BoxTemplateLabels.IsVisibleToRetailer]: 'True' }),
        ),
      );

      await Promise.all([
        addSubmissionEmails(sentEmails),
        logSubmissionActivity({ activityType: ActivityType.ProposalSent }),
      ])
        // Do nothing these are side effects of sending the email
        // We trigger an error message if these calls fail as part of the base client, but no need to fail the entire flow
        .catch();
    });
  };

  const isSendDisabled: boolean = !proposalContent || !proposalSubject || isEmpty(recipientGroup.recipients);

  const [isPreviewMode, { toggle: togglePreviewMode }] = useBoolean(false);

  const onContactCreated = async (contact: Contact | null) => {
    // Save contact to submission
    await handleSaveProposal(contact && convertContactToRecipient(contact));
    addContact(contact);
  };

  const onToggleContact = (contact: Contact) => {
    toggleContact(contact, () => setIsDirty(true));
  };

  const beforeNavigation = async () => {
    await handleSaveProposal();
    return true;
  };

  if (proposalSendingStatus !== EmailStatus.Editing) {
    return (
      <DialogContent>
        <Container sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <SendingMessagesScreen recipientGroups={[recipientGroup]} emailStatus={proposalSendingStatus} />
        </Container>
      </DialogContent>
    );
  }
  return (
    <DirtyRouteGuard beforeNavigation={beforeNavigation} isDirty={isDirty}>
      <Stack flex={1} height={1} width={1}>
        <Stack px={3} pt={2}>
          <Typography variant="caption" color="grey.900">
            {messages.submissionWorkspace.customerSection.lastSaved(submission?.proposal?.lastSaved)}
          </Typography>
        </Stack>
        <ProposalContent
          recipientGroup={recipientGroup}
          templateContextData={templateVariablesResolver.templateContext}
          proposalInitialValue={proposalInitialValue}
          availableContacts={submission.contacts}
          submission={submission}
          onToggleContact={onToggleContact}
          onContactCreated={onContactCreated}
          newContacts={newContacts}
          handleSaveProposal={handleSaveProposal}
          onClearNewContacts={onClearNewContacts}
          content={proposalContent}
          setContent={setProposalContent}
          subject={proposalSubject}
          setSubject={setProposalSubject}
          isPreviewMode={isPreviewMode}
          variablesResolver={templateVariablesResolver}
          setIsDirty={setIsDirty}
          footer={<ProposalEditorFooter proposalContent={proposalContent} />}
          openAttachFiles={() => {
            updateQueryParam({
              addParams: [{ queryParam: BrokerUrlParams.ACTIVE_TAB, value: TOOLBOX_TABS.FILES }],
              removeParams: [BrokerUrlParams.SELECTED_FILE, BrokerUrlParams.SELECTED_EMAIL],
            });
            showInformationTooltip();
          }}
          attachedFiles={attachedFiles}
          teammatesList={availableTeammates}
          toggleTeammate={toggleTeammateSelection}
          selectedTeammates={selectedTeammates}
          emailTemplateType={emailTemplateType}
        />
        <Divider sx={{ mb: 2, mt: 3 }} />
        <ProposalActionButtons
          isPreviewMode={isPreviewMode}
          togglePreviewMode={togglePreviewMode}
          saveProposal={handleSaveProposal}
          sendProposal={onSendClick}
          isSaving={isSaving}
          isDirty={isDirty}
          attachedFiles={attachedFiles}
          recipientGroup={recipientGroup}
          isSendDisabled={isSendDisabled}
          emailTemplateType={emailTemplateType}
          isCopilotEnabled={submission.isCopilotEnabled}
          resolvedMessages={resolvedMessages}
        />
      </Stack>
    </DirtyRouteGuard>
  );
};
