import { Box, BoxProps, SxProps, Theme, Typography } from '@common-components';
import TextSkeleton from 'broker/components/skeletons/TextSkeleton';

interface TitleProps extends BoxProps {
  titleText: string;
  isLoading?: boolean;
  sx: SxProps<Theme>;
}

export default function Title({ titleText, isLoading = false, sx, ...props }: TitleProps) {
  return (
    <Box sx={{ lineHeight: 1, width: 1, py: 1.3 }} {...props}>
      <Typography variant="body2" noWrap sx={sx}>
        <TextSkeleton width={150} isLoading={isLoading}>
          {titleText}
        </TextSkeleton>
      </Typography>
    </Box>
  );
}
