import { BoxItem } from 'box-ui-elements/es';
import { compact } from 'lodash';
import { useMemo, useState } from 'react';
import { FileType } from 'enums';
import { useBoxClient, useDeepCompareEffect } from 'hooks';
// The below eslint-disable are due to the fact that the email editor highly depends on the submission workspace page,
// but some parts of it might be relevant to use elsewhere. This should be addressed in CAP-3264
// eslint-disable-next-line import/no-restricted-paths
import useLoadFilesSelection from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Files/useLoadFilesSelection';
// eslint-disable-next-line import/no-restricted-paths
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

interface UseAttachedFilesStateProps {
  preSelectedFileIds: string[];
  highlightFileTypes?: FileType[];
}

// This hook captures pre-selected files to attach and encapsulates the creation of share links for selected files.
// The actual state of what files are attached is managed by the useUiStoreState/useUiStoreActions hooks.
export default function useAttachedFilesState({ preSelectedFileIds, highlightFileTypes }: UseAttachedFilesStateProps) {
  const [filesWithShareLinks, setFilesWithShareLinks] = useState<BoxItem[]>([]);
  const {
    filesExplorer: { selectedFiles },
  } = useUiStoreState();

  const { setFilesExplorer } = useUiStoreActions();

  const removeFile = (file: BoxItem) => {
    const remainingFiles = selectedFiles.filter((fileItem) => fileItem.id !== file.id);

    setFilesExplorer({ selectedFiles: remainingFiles });
  };

  const boxClient = useBoxClient();
  const { isLoading } = useLoadFilesSelection({ preSelectedFileIds, highlightFileTypes });

  // Create share links for selected files if missing
  useDeepCompareEffect(() => {
    const getShareLinksForSelectedFiles = async () => {
      const selectedFilesWithShareLinks = await boxClient.createShareLinks(selectedFiles);
      setFilesWithShareLinks(selectedFilesWithShareLinks);
    };

    if (selectedFiles.length > 0 && !isLoading) {
      getShareLinksForSelectedFiles();
    }
  }, [selectedFiles, isLoading]);

  const attachedFiles = useMemo(
    () =>
      compact(
        selectedFiles.map((file) => filesWithShareLinks.find((fileWithShareLink) => fileWithShareLink.id === file.id)),
      ),
    [filesWithShareLinks, selectedFiles],
  );

  return {
    // The files that are attached are the intersection of the selected files and the files with share links
    // The FilesExplorer selected files is the source of truth for what files are attached, but we'll only return
    // files that we managed to get share links for
    attachedFiles,
    removeFile,
  };
}
