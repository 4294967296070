import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { List } from '@common-components';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import { EmailTemplate } from 'types';
import ListSkeleton from 'broker/components/skeletons/ListSkeleton';
import SidebarSection from './SidebarSection';
import { leftBarWidth, ProposalTypes } from './utils';

export interface TemplatesSideBarProps {
  selectedTemplate: EmailTemplate | undefined;
  onChange: (template: EmailTemplate) => void;
  templates: EmailTemplate[];
  isTemplatesLoading: boolean;
}

export default function TemplatesSideBar({
  selectedTemplate,
  onChange,
  templates,
  isTemplatesLoading,
}: TemplatesSideBarProps) {
  const [isEmailsOpen, { off: closeEmails, on: openEmails, toggle: toggleEmail }] = useBoolean(false);
  const [isProposalOpen, { on: openProposal, toggle: toggleProposals }] = useBoolean(false);
  const [proposalTemplates, setProposalTemplates] = useState<EmailTemplate[]>([]);
  const [emailsTemplates, setEmailsTemplates] = useState<EmailTemplate[]>([]);

  const handelToggleCollapsElement = () => {
    toggleEmail();
    toggleProposals();
    if (isEmailsOpen) {
      onChange(proposalTemplates[0]);
    } else {
      onChange(emailsTemplates[0]);
    }
  };

  useEffect(() => {
    const templatesByType = groupBy(templates, (template) => template.type in ProposalTypes);
    setProposalTemplates(templatesByType.true ?? []);
    setEmailsTemplates(templatesByType.false ?? []);
  }, [templates]);

  useEffect(() => {
    // in case of landing in taplates on spesific template we want to collaps will open properly
    if (selectedTemplate) {
      if (selectedTemplate.type in ProposalTypes) {
        closeEmails();
        openProposal();
      } else {
        openEmails();
      }
    }
  }, [emailsTemplates, openEmails, closeEmails, openProposal, selectedTemplate]);

  return (
    <List
      sx={{
        position: 'relative',
        zIndex: 1,
        width: (theme) => theme.spacing(leftBarWidth),
        boxShadow: 2,
        backgroundColor: 'common.white',
        overflow: 'auto',
        pt: 0,
      }}
      component="nav"
    >
      {isTemplatesLoading ? (
        <ListSkeleton />
      ) : (
        <>
          <SidebarSection
            handleClick={handelToggleCollapsElement}
            isOtherSectionOpen={isEmailsOpen}
            isOpen={isProposalOpen}
            templates={proposalTemplates}
            onChange={onChange}
            selectedTemplate={selectedTemplate}
            title={messages.templatesPage.proposal}
          />

          <SidebarSection
            handleClick={handelToggleCollapsElement}
            isOtherSectionOpen={isProposalOpen}
            isOpen={isEmailsOpen}
            templates={emailsTemplates}
            onChange={onChange}
            selectedTemplate={selectedTemplate}
            title={messages.templatesPage.emails}
            top={58}
          />
        </>
      )}
    </List>
  );
}
