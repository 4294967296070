import * as yup from 'yup';
import { messages } from 'i18n';
import { FormFieldsConfig, PreFormFieldsNames } from './types';

const { requiredField } = messages.formMessages;

export const formFieldsConfig: FormFieldsConfig = {
  [PreFormFieldsNames.CoverageLines]: {
    label: messages.submissionModal.coverageLines,
    name: PreFormFieldsNames.CoverageLines,
    id: PreFormFieldsNames.CoverageLines,
    placeholder: messages.submissionModal.placeholder.coverageLines,
  },
};

export const formValidationShape = () =>
  yup.object().shape({
    coverageLines: yup.array().default([]).min(1, requiredField).required(requiredField),
  });
