import { compact, flatMap } from 'lodash';
import { CoverageLine, OrganizationType } from 'enums';
import { messages } from 'i18n';
import { ExtendedContact } from 'types';
import { contactFullName, FilterElementProps } from 'utils';

export function getMarketNameWithType(marketName: string, organizationType: OrganizationType) {
  if (organizationType === OrganizationType.Broker) {
    return `${marketName} (${messages.marketsPage.marketType.wholesaler})`;
  }
  return marketName;
}

export function getContactAndCoverageLinesFilterMatch({
  contact,
  coverageLineFilters,
  isAllCoverageLineFilters,
}: {
  contact: ExtendedContact;
  coverageLineFilters: FilterElementProps[];
  isAllCoverageLineFilters: boolean;
}) {
  return !isAllCoverageLineFilters
    ? compact(
        flatMap(coverageLineFilters, (filter) =>
          filter.checked
            ? contact.products.filter((contactProduct) =>
                contactProduct.coverageLines.some(
                  (insuranceProductCoverageLine) =>
                    insuranceProductCoverageLine.coverageLine === (filter.key as CoverageLine),
                ),
              )
            : undefined,
        ),
      ).map((product) => product.name)
    : [];
}

export function didContactPassedFilter({
  contact,
  searchInput,
  coverageLineFilters,
  isAllCoverageLineFilters,
}: {
  contact: ExtendedContact;
  coverageLineFilters: FilterElementProps[];
  isAllCoverageLineFilters: boolean;
  searchInput: string;
}) {
  if (isAllCoverageLineFilters && !searchInput) {
    return undefined;
  }
  const passedCoverageLineFilter =
    getContactAndCoverageLinesFilterMatch({
      contact,
      coverageLineFilters,
      isAllCoverageLineFilters,
    }).length > 0;

  const contactTerms = [contactFullName(contact), contact.firstName, contact.lastName, contact.email].filter(Boolean);

  const passedContactTermsFilter =
    !!searchInput && contactTerms.some((term) => term?.toLowerCase().includes(searchInput.toLowerCase()));

  return passedContactTermsFilter || passedCoverageLineFilter;
}
