import { Button, IconButton, Stack, SvgIcon, Typography } from '@common-components';
import { BorderColorOutlined as BorderColorOutlinedIcon } from '@icons';
import { messages } from 'i18n';

interface MarketInfoSectionHeaderProps {
  title: string;
  Icon: typeof SvgIcon;
  isContentDirty: boolean;
  isEdit: boolean;
  isSubmitting: boolean;
  isLoading: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSubmit: () => void;
}

export default function MarketInfoSectionHeader({
  title,
  Icon,
  isEdit,
  isContentDirty,
  isSubmitting,
  isLoading,
  onEdit,
  onCancel,
  onSubmit,
}: MarketInfoSectionHeaderProps) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" p={2} height={66}>
      <Stack direction="row" gap={1} alignItems="center">
        <Icon sx={{ fontSize: 18 }} />
        <Typography color="common.black" variant="body2Bold" flex={1} noWrap>
          {title}
        </Typography>
      </Stack>
      {isEdit ? (
        <Stack direction="row" gap={1}>
          <Button onClick={onCancel} size="small" variant="outlined">
            {messages.buttons.cancel}
          </Button>
          <Button
            variant="contained"
            disabled={!isContentDirty || isSubmitting || isLoading}
            loading={isSubmitting}
            size="small"
            onClick={onSubmit}
          >
            {messages.buttons.save}
          </Button>
        </Stack>
      ) : (
        <IconButton
          icon={BorderColorOutlinedIcon}
          color="secondary"
          variant="text"
          edge="end"
          disabled={isLoading}
          onClick={onEdit}
          tooltipContent={messages.buttons.edit}
        />
      )}
    </Stack>
  );
}
