import { DownloadOutlined as DownloadIcon } from '@icons';
import { FileType } from 'enums';
import { useBoolean, useHotjar, useToast } from 'hooks';
import { messages } from 'i18n';
import { InboundEmailMessageSearchItem, PartialSubmission } from 'types';
import { getTimezoneLessDisplayDate } from 'utils';
import { downloadFile } from 'utils/files-utils';
import { HotjarEvents } from 'utils/hotjar-events';
import { useDownloadFiles } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Email/useDownloadFiles';
import useDownloadFilesByType from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Email/useDownloadFilesByType';

export interface DownloadMenuProps {
  submission: PartialSubmission;
  submissionCreationEmail?: InboundEmailMessageSearchItem;
}

const {
  allEmailsAndFiles,
  customerEmailsOutgoing,
  marketingEmailsOutgoing,
  incomingEmails,
  noFilesToDownload,
  marketingEmailsFolder,
  customerEmailsFolder,
} = messages.submissionWorkspace.downloadFilesAndEmails;

export function useDownloadMenu({ submission, submissionCreationEmail }: DownloadMenuProps) {
  const { showToast } = useToast();
  const hotjar = useHotjar();
  const [isDownloading, { on: showLoader, off: hideLoader }] = useBoolean(false);
  const getDownloadLink = useDownloadFiles({ organizationBoxFolderId: submission.boxFolderId });
  const getDownloadLinkByType = useDownloadFilesByType({ organizationBoxFolderId: submission.boxFolderId });

  const downloadFiles = async () => {
    showLoader();
    const { insuredName, coverageLines, dueDate } = submission;
    const link =
      insuredName && coverageLines && dueDate
        ? `${insuredName}_${coverageLines.join('_').toLowerCase()}_${getTimezoneLessDisplayDate(dueDate)}`
        : submissionCreationEmail?.subject;
    const downloadUrl = await getDownloadLink(link);
    if (downloadUrl) {
      downloadFile(downloadUrl);
    } else {
      showToast('warning', { message: noFilesToDownload });
    }
    hideLoader();
    hotjar.event(HotjarEvents.DownloadAllEmailsAndFiles);
  };

  const downloadFileByType = async (fileType: string, downloadFileName: string) => {
    const emailsDownloadUrl = await getDownloadLinkByType(fileType, downloadFileName);
    if (emailsDownloadUrl) {
      downloadFile(emailsDownloadUrl);
    } else {
      showToast('warning', { message: noFilesToDownload });
    }
  };

  const downloadEmails = async (fileType: string, downloadFileName: string) => {
    showLoader();
    await downloadFileByType(fileType, downloadFileName);
    hideLoader();
  };

  const downloadMenuItems = [
    {
      key: 'download',
      items: [
        {
          label: allEmailsAndFiles,
          endAdornment: DownloadIcon,
          onClick: downloadFiles,
          hotjarEvent: HotjarEvents.DownloadAllEmailsAndFiles,
        },
        {
          label: marketingEmailsOutgoing,
          endAdornment: DownloadIcon,
          onClick: () => downloadEmails(FileType.MarketingEmail, marketingEmailsFolder),
          hotjarEvent: HotjarEvents.DownloadMarketEmails,
        },
        {
          label: customerEmailsOutgoing,
          endAdornment: DownloadIcon,
          onClick: () => downloadEmails(FileType.CustomerEmail, customerEmailsFolder),
          hotjarEvent: HotjarEvents.DownloadCustomerEmails,
        },
        {
          label: incomingEmails,
          endAdornment: DownloadIcon,
          onClick: () => downloadEmails(FileType.InboundEmail, incomingEmails),
        },
      ],
    },
  ];

  return { downloadMenuItems, isDownloading };
}
