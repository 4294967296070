import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, LoaderOverlay, Stack, Typography } from '@common-components';
import { FileType, FormMode } from 'enums';
import { messages } from 'i18n';
import { getFileType } from 'utils';
import FormEditor from 'components/hookFormComponents/FormEditor';
import { AIButtonWithPopup } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIButtonWithPopup';
import { insightsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/insightsFieldsConfig';
import { InsightsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import MissingLabelAlertBanner, { AlertBannerMode } from './MissingLabelAlertBanner';

interface QuoteInsightsProps {
  mode: FormMode;
  hasMarketQuoteFileLabel: boolean;
}

export default function QuoteInsights({ mode, hasMarketQuoteFileLabel }: QuoteInsightsProps) {
  const { getValues } = useFormContext();
  const {
    filesExplorer: { selectedFiles },
  } = useUiStoreState();

  const hasPolicySpecimenFileLabel = useMemo(
    () => selectedFiles.some((file) => getFileType(file) === FileType.PolicySpecimen),
    [selectedFiles],
  );

  const alertBannerState = useMemo(() => {
    if (!hasMarketQuoteFileLabel) {
      return AlertBannerMode.MissingMarketQuoteFileLabel;
    }
    if (!hasPolicySpecimenFileLabel) {
      return AlertBannerMode.MissingPolicySpecimenFileLabel;
    }
    return undefined;
  }, [hasMarketQuoteFileLabel, hasPolicySpecimenFileLabel]);

  const isEmptyQuoteInsights = isEmpty(getValues(insightsFormFieldsConfig[InsightsFieldsNames.Insights].name));
  const { isLoading: isQuoteInsightsLoading, isContentStreaming: isQuoteInsightsStreaming } = useAIContext();

  const showQuoteInsightsLoader = isEmptyQuoteInsights && (isQuoteInsightsLoading || isQuoteInsightsStreaming);

  const isDisabled = mode === FormMode.view || showQuoteInsightsLoader;

  return (
    <Stack pt={4} px={6} height={1}>
      <Typography variant="body2Bold" component="div" mb={1}>
        {messages.addQuotePage.quoteInsights.title}
      </Typography>
      <Stack mt={alertBannerState ? 0 : 3} height={1}>
        {alertBannerState && <MissingLabelAlertBanner mode={alertBannerState} />}
        <Box position="relative" height={1}>
          <FormEditor
            disabled={isDisabled}
            {...insightsFormFieldsConfig[InsightsFieldsNames.Insights]}
            excludeToolbarProps={['fontfamily']}
          />
          {showQuoteInsightsLoader && <LoaderOverlay text="Generating Insights..." />}
        </Box>
      </Stack>
      {mode !== FormMode.view && (
        <AIButtonWithPopup
          popupTitle={messages.addQuotePage.quoteInsights.popupTitle}
          tooltipText={messages.addQuotePage.quoteInsights.generatingQuoteInsights}
        />
      )}
    </Stack>
  );
}
