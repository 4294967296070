import { ReactElement, useEffect } from 'react';
import DirtyPageDialog from 'broker/dialogs/DirtyPageDialog';
import { useDirtyContentGuard } from './useDirtyContentGuard';

export interface DirtyActionsGuardAttributes {
  closeAttemptWithAction: (action: () => void) => void;
  closeWithoutPromptWithAction: (action: () => void) => void;
  setIsContentDirty: (state: boolean) => void;
  isContentDirty: boolean;
}

interface DirtyActionsGuardProps {
  isDirty?: boolean;
  children: (props: DirtyActionsGuardAttributes) => ReactElement;
}

/**
 * Guard that allows dynamic actions to be executed when the user attempts discard changes
 * */
export const DirtyActionsGuard = ({ isDirty = false, children }: DirtyActionsGuardProps) => {
  const {
    setIsContentDirty,
    isContentDirty,
    isDirtyDialogOpen,
    closeDirtyDialog,
    closeWithoutPrompt,
    setOnClose,
    closeAttempt,
  } = useDirtyContentGuard();

  // Allow managing dirty state from outside the guard
  useEffect(() => {
    setIsContentDirty(isDirty);
  }, [isDirty, setIsContentDirty]);

  const closeAttemptWithAction = (onClose: () => void) => {
    setOnClose(onClose);
    closeAttempt();
  };

  const closeWithoutPromptWithAction = (onClose: () => void) => {
    setOnClose(onClose);
    closeWithoutPrompt();
  };

  return (
    <>
      {children({
        isContentDirty,
        setIsContentDirty,
        closeAttemptWithAction,
        closeWithoutPromptWithAction,
      })}
      <DirtyPageDialog onContinueEditing={closeDirtyDialog} onDiscard={closeWithoutPrompt} open={isDirtyDialogOpen} />
    </>
  );
};
