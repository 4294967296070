import theme from 'themes';
import { uniteStyles } from 'utils';
import { Dialog as MuiDialog, DialogProps as MuiDialogProps, PaperProps, useMediaQuery } from 'components/mui-index';

export interface DialogProps extends MuiDialogProps {
  muiPaperProps?: PaperProps;
  alertDialog?: boolean;
  hideBackdrop?: boolean;
  allowCloseByBackdrop?: boolean;
  onClose: () => void; // Make onClose mandatory to make sure we always allow closing with ESC
  dialogMaxWidth?: number;
  alertDialogMaxWidth?: number;
}

export default function Dialog({
  children,
  fullScreen: ownFullScreen,
  muiPaperProps,
  alertDialog,
  hideBackdrop,
  allowCloseByBackdrop,
  dialogMaxWidth = 728,
  alertDialogMaxWidth = 444,
  ...props
}: DialogProps) {
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const maxHeight = alertDialog ? 'inherit' : 800;
  const localMaxWidth = alertDialog ? alertDialogMaxWidth : dialogMaxWidth;

  const height = alertDialog ? 'auto' : '87vh';

  const blockCloseByBackdrop = !alertDialog && !allowCloseByBackdrop;

  return (
    <MuiDialog
      fullScreen={fullScreen || ownFullScreen}
      fullWidth
      disableRestoreFocus
      disableEnforceFocus
      hideBackdrop={hideBackdrop}
      PaperProps={{
        elevation: hideBackdrop ? 0 : 24,
        ...muiPaperProps,
        sx: uniteStyles(
          {
            [theme.breakpoints.up('sm')]: {
              maxWidth: ownFullScreen ? 1 : localMaxWidth,
              maxHeight: ownFullScreen ? 1 : maxHeight,
              height: ownFullScreen ? 1 : height,
            },
          },
          muiPaperProps?.sx,
        ),
      }}
      // conditionally prevent the dialog from closing when the user clicks outside of it (e.g. on the background)
      // based on the following conditions: (1) the dialog is not an alert dialog, (2) the dialog is not a prompt dialog, (3) the dialog is not allowed to close by backdrop
      {...(blockCloseByBackdrop && {
        onClick: (e) => e.stopPropagation(),
        BackdropProps: { sx: { pointerEvents: 'none' } },
      })}
      {...props}
    >
      {children}
    </MuiDialog>
  );
}
