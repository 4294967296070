import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { NO_TOKEN_SKIP_FETCH } from 'clients';
import { AuthenticationState } from 'contexts';

export const useAuthenticate = (): AuthenticationState => {
  const { getIdTokenClaims, isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  // This is the bearer token we pass to the server on each api call to identify the user
  const getIdToken = useMemo(
    () => async () => {
      try {
        // Make sure the access token is refreshed
        await getAccessTokenSilently();
      } catch (e) {
        const { error } = e as OAuthError;

        // If silent login fails we need to redirect the user to the login page
        if (error === 'login_required' || error === 'consent_required') {
          await loginWithRedirect();

          // Return indication that there is no token and no point in using it.
          // This is done to allow usages to stop the flow and wait for the redirect to take place
          return NO_TOKEN_SKIP_FETCH;
        }

        throw e;
      }

      // Get the refreshed id token, that also contains the user email, needed by the server
      const { __raw: idToken } = (await getIdTokenClaims()) ?? {};

      return idToken ?? NO_TOKEN_SKIP_FETCH;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getIdTokenClaims, getAccessTokenSilently],
  );

  return useMemo(() => ({ isAuthenticated, isLoading, getIdToken }), [isAuthenticated, isLoading, getIdToken]);
};
