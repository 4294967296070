import { CoverageLine } from 'enums';

export type ManagementLiabilityCoverageLines =
  | CoverageLine.DirectorsAndOfficersLiability
  | CoverageLine.PublicDnO
  | CoverageLine.PrivateDnO
  | CoverageLine.NonProfitDnO
  | CoverageLine.EPL
  | CoverageLine.Fiduciary
  | CoverageLine.KidnapRansom
  | CoverageLine.Crime;

export function isManagementLiabilityCoverage(line: CoverageLine) {
  return [
    CoverageLine.DirectorsAndOfficersLiability,
    CoverageLine.PublicDnO,
    CoverageLine.PrivateDnO,
    CoverageLine.NonProfitDnO,
    CoverageLine.EPL,
    CoverageLine.Fiduciary,
    CoverageLine.KidnapRansom,
    CoverageLine.Crime,
  ].includes(line);
}

enum HeraldManagementLiabilityCoverageLine {
  DirectorsAndOfficersLiability = 'Directors & Officers Liability',
  EPL = 'Employment Practices Liability',
  Fiduciary = 'Fiduciary Liability',
  KidnapRansom = 'Kidnap & Ransom',
  Crime = 'Crime',
}

export const managementLiabilityCoverageLineToHeraldCoveragesConfig: Record<
  ManagementLiabilityCoverageLines,
  HeraldManagementLiabilityCoverageLine
> = {
  [CoverageLine.DirectorsAndOfficersLiability]: HeraldManagementLiabilityCoverageLine.DirectorsAndOfficersLiability,
  [CoverageLine.PublicDnO]: HeraldManagementLiabilityCoverageLine.DirectorsAndOfficersLiability,
  [CoverageLine.PrivateDnO]: HeraldManagementLiabilityCoverageLine.DirectorsAndOfficersLiability,
  [CoverageLine.NonProfitDnO]: HeraldManagementLiabilityCoverageLine.DirectorsAndOfficersLiability,
  [CoverageLine.EPL]: HeraldManagementLiabilityCoverageLine.EPL,
  [CoverageLine.Fiduciary]: HeraldManagementLiabilityCoverageLine.Fiduciary,
  [CoverageLine.KidnapRansom]: HeraldManagementLiabilityCoverageLine.KidnapRansom,
  [CoverageLine.Crime]: HeraldManagementLiabilityCoverageLine.Crime,
};
