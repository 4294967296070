import EmailEditorAddContactToMarketModal from './EmailEditorAddContactToMarketModal';
import EmailEditorConceptualTower from './EmailEditorConceptualTower';
import EmailEditorCreateCustomerContactModal from './EmailEditorCreateCustomerContactModal';
import EmailEditorMarketingReportModal from './EmailEditorMarketingReportModal';
import EmailEditorMarketsModal from './EmailEditorMarketsModal';
import EmailEditorQuoteModal from './EmailEditorQuoteModal';

export default () => (
  <>
    <EmailEditorAddContactToMarketModal />
    <EmailEditorCreateCustomerContactModal />
    <EmailEditorMarketsModal />
    <EmailEditorMarketingReportModal />
    {/* The quote modal is needed for the quote smart variable that allows editing a quote from the email editor */}
    <EmailEditorQuoteModal />
    <EmailEditorConceptualTower />
  </>
);
