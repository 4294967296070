import { Box, Button, IconButton, Popover, Stack, Theme, Typography } from '@common-components';
import { Close } from '@icons';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';

const PopupWidth = 312;

export function RegenerateButton() {
  const { isLoading, isContentStreaming, regenerateAIThread } = useAIContext();
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useOptionsMenu();
  const { title, description, areYouSure, yesRegenerate } = messages.regeneratePopup;
  const { regenerate, cancel } = messages.buttons;

  return (
    <Box bgcolor="green.300" color="primary.text" borderRadius={1}>
      <Button
        color="inherit"
        variant="contained"
        size="small"
        onClick={openMenu}
        disabled={isLoading || isContentStreaming}
      >
        {regenerate}
      </Button>
      <Popover
        autoFocus={false}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            px: 2,
            py: 1,
            width: PopupWidth,
          },
        }}
        sx={{ zIndex: (theme: Theme) => theme.zIndex.tooltip + 1 }}
      >
        <Stack gap={1}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography color="text.primary" variant="h5">
              {title}
            </Typography>
            <IconButton icon={Close} onClick={closeMenu} variant="text" color="secondary" />
          </Stack>
          <Stack mb={1} gap={1}>
            <Typography color="text.primary" variant="body2">
              {description}
            </Typography>
            <Typography color="text.primary" variant="body2">
              {areYouSure}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="end">
            <Button onClick={closeMenu} size="small">
              {cancel}
            </Button>
            <Button
              onClick={() => {
                regenerateAIThread();
                closeMenu();
              }}
              variant="contained"
              size="small"
            >
              {yesRegenerate}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </Box>
  );
}
