import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';

export default function FormFieldTitle({ title }: { title: string }) {
  return (
    <Stack width={1} direction="row" alignItems="baseline" justifyContent="space-between">
      <Typography variant="caption" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {messages.general.optional}
      </Typography>
    </Stack>
  );
}
