// eslint-disable-next-line no-restricted-imports
import { Close as CloseIcon } from '@mui/icons-material';
// eslint-disable-next-line no-restricted-imports
import { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip/Tooltip';
import { ReactNode, useEffect, useState } from 'react';
import { Box, ClickAwayListener, IconButton, Stack, Typography } from '@common-components';
import { StyledTooltip } from './StyledInformationTooltip';

interface InformationTooltipProps {
  children?: ReactNode;
  isOpen: boolean;
  title: string;
  message: string;
  secondaryMessage?: string;
  placement?: MuiTooltipProps['placement'];
  width?: number;
  height?: number;
  action?: ({ close }: { close: () => void }) => ReactNode;
  onClose?: () => void;
  type?: 'light' | 'dark';
}

export default function InformationTooltip({
  children,
  isOpen,
  title,
  message,
  secondaryMessage,
  placement = 'bottom',
  type = 'dark',
  width,
  action,
  onClose,
}: InformationTooltipProps) {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    if (open) {
      setOpen(false);
      onClose?.();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <StyledTooltip
          arrow
          open={open}
          width={width}
          placement={placement}
          type={type}
          title={
            <Stack alignItems="stretch" sx={{ position: 'relative' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography color="inherit" variant="h5">
                  {title}
                </Typography>
                <IconButton
                  edge="end"
                  icon={CloseIcon}
                  color="inherit"
                  aria-label="Close ToolTip"
                  onClick={handleClose}
                />
              </Stack>
              <Typography color="inherit" variant="caption">
                {message}
              </Typography>
              <Typography color="inherit" variant="caption2">
                {secondaryMessage}
              </Typography>

              {action?.({ close: handleClose })}
            </Stack>
          }
        >
          <Box height={1}>{children}</Box>
        </StyledTooltip>
      </Box>
    </ClickAwayListener>
  );
}
