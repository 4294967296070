import { useEffect, useMemo, useState } from 'react';
import useEditorContentState from './useEditorContentState';
import { replaceVariablesWithMentionTag } from './utils/mentions';

export default function useEmailContentState(emailSubject: string, emailBody: string) {
  const [subject, setSubject] = useState(emailSubject);

  // Running the variables parsing once and using it as the initial value.
  // Later we have a useEffect to handle changes in the emailBody.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialEditorContent = useMemo(() => replaceVariablesWithMentionTag(emailBody), []);

  const {
    editorContent: body,
    setEditorContent: setBody,
    isDirty: isEditorContentDirty,
    setIsDirty: setEditorContentDirty,
  } = useEditorContentState(initialEditorContent);

  useEffect(() => {
    setSubject(emailSubject);
  }, [emailSubject]);

  useEffect(() => {
    setBody(replaceVariablesWithMentionTag(emailBody));
    setEditorContentDirty(false);
    // No need to reset the subject and body on variableKeys change. We don't expect the keys to change after the first render
    // and want to avoid deep compare of this array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailBody, setEditorContentDirty]);

  const onSetSubject = (newSubject: string) => {
    setSubject(newSubject);
    setEditorContentDirty(true);
  };

  const resetContent = () => {
    setSubject(emailSubject);
    setBody(replaceVariablesWithMentionTag(emailBody));
  };

  return {
    body,
    setBody,
    subject,
    setSubject: onSetSubject,
    resetContent,
    isDirty: isEditorContentDirty,
    setIsDirty: setEditorContentDirty,
  };
}
