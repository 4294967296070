import { useEffect } from 'react';
import { RouteKey } from 'broker/enums';
import { useLocation, useNavigateBack } from 'broker/hooks';
import { LocationStateEntries } from 'broker/types';

export const useRouteLocationState = <TRouteKey extends RouteKey>(routeKey: TRouteKey, isStateRequired?: boolean) => {
  const location = useLocation();
  const { state } = location;
  const goToLastRouteNavigation = useNavigateBack();

  const isLocationStateMissing = isStateRequired && !state?.[routeKey];

  useEffect(() => {
    if (isLocationStateMissing) {
      goToLastRouteNavigation();
    }
  }, [goToLastRouteNavigation, isLocationStateMissing]);

  if (isLocationStateMissing) {
    return null;
  }

  return state?.[routeKey] as LocationStateEntries[TRouteKey];
};
