import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom-latest';
import { LoaderOverlay } from '@common-components';
import { UserStatus } from 'enums';
import { useMatchNavigation } from 'hooks';
import { RouteKey } from 'broker/enums';
import { useNavigateToStep } from 'broker/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUpdateHeraldApplicationWithSubmissionCoverageLines } from 'broker/pages/SubmissionWorkspacePage/utils';
import { CoverageLineChanges } from 'broker/pages/SubmissionWorkspacePage/utils/types';
import PreForm from './Screens/PreForm';
import ReviewAgentForm from './Screens/ReviewAgentForm';
import RiskAssessmentStep from './Screens/RiskAssessmentStep';
import SubmissionDynamicForm from './Screens/SubmissionDynamicForm';
import SubmissionStaticForm from './Screens/SubmissionStaticForm';
import { NewSubmissionEditLocationState, SubmissionStep } from './types';
import useSetNextStep from './useSetNextStep';
import { getSubmissionFormStep } from './utils';

interface EditSubmissionContentProps {
  locationState?: NewSubmissionEditLocationState;
}

export default function EditSubmissionContent({ locationState }: EditSubmissionContentProps) {
  const step = useMatchNavigation(SubmissionStep);
  const navigateToStep = useNavigateToStep();
  const [firstVisit, setFirstVisit] = useState<boolean | undefined>(undefined);
  const { partialSubmission } = useSubmissionsWorkspace();

  const setStep = useCallback(
    (stepItem: SubmissionStep, replace = false) => {
      navigateToStep(stepItem, { routeKey: RouteKey.SubmissionEdit, replace });
    },
    [navigateToStep],
  );

  const setNextStep = useSetNextStep(partialSubmission, setStep);

  const updateHeraldApplicationWithSubmissionCoverageLines = useUpdateHeraldApplicationWithSubmissionCoverageLines();

  useEffect(() => {
    // If we got here without a selected step, calculate it based on the data
    if (!step && partialSubmission) {
      if (locationState?.preForm) {
        setStep(SubmissionStep.PreForm, true);
      } else if (partialSubmission.user?.status === UserStatus.PendingReview) {
        setStep(SubmissionStep.ReviewAgent, true);
      } else if (partialSubmission.coverageLines?.length) {
        setStep(getSubmissionFormStep(partialSubmission), true);
      } else {
        setStep(SubmissionStep.PreForm, true);
      }
    }
  }, [locationState?.preForm, partialSubmission, setStep, step]);

  useEffect(() => {
    if (firstVisit === undefined && partialSubmission) {
      setFirstVisit(isEmpty(partialSubmission?.coverageLines));
    }
  }, [firstVisit, partialSubmission]);

  const onPreFormSent = async (coverageLinesChanges: CoverageLineChanges) => {
    const heraldProducts = await updateHeraldApplicationWithSubmissionCoverageLines(coverageLinesChanges);
    // if heraldProducts is undefined and not an array it means that there was a certain error in process and user should stay on this screen and see the error and decide what to do (send again/close screen)
    if (Array.isArray(heraldProducts)) {
      setStep(heraldProducts.length > 0 ? SubmissionStep.DynamicForm : SubmissionStep.StaticForm);
    }
  };

  return !partialSubmission ? (
    <LoaderOverlay />
  ) : (
    <Routes>
      <Route
        path={`${SubmissionStep.StaticForm}/*`}
        element={
          <SubmissionStaticForm submission={partialSubmission!} setNextStep={setNextStep} firstVisit={!!firstVisit} />
        }
      />
      <Route
        path={`${SubmissionStep.DynamicForm}/*`}
        element={
          <SubmissionDynamicForm submission={partialSubmission!} setNextStep={setNextStep} firstVisit={!!firstVisit} />
        }
      />
      <Route
        path={`${SubmissionStep.ReviewAgent}/*`}
        element={<ReviewAgentForm submission={partialSubmission!} setNextStep={setNextStep} />}
      />
      <Route
        path={`${SubmissionStep.PreForm}/*`}
        element={
          <PreForm submission={partialSubmission!} afterSubmissionUpdated={onPreFormSent} firstVisit={!!firstVisit} />
        }
      />
      <Route
        path={`${SubmissionStep.RiskAssessment}/*`}
        element={<RiskAssessmentStep submission={partialSubmission!} setNextStep={setNextStep} />}
      />
    </Routes>
  );
}
