import { To } from 'react-router-dom-latest';
import { useMount } from 'hooks';
import { NavigateOptions, useNavigate, useNavigateToStep } from 'broker/hooks';

interface NavigateProps {
  to: To;
  navigateOptions: NavigateOptions;
  isStep: boolean;
}

export default function Navigate({ to, navigateOptions, isStep }: NavigateProps) {
  const navigate = useNavigate();
  const navigateToStep = useNavigateToStep();
  useMount(() => {
    if (isStep) {
      navigateToStep(to, navigateOptions);
    } else {
      navigate(to, navigateOptions);
    }
  });
  return null;
}
