import { ReactNode, useCallback, useMemo, useState } from 'react';
import {
  EmailTemplateType,
  FileType,
  isMarketOrganization,
  MarketRequestType,
  OrganizationType,
  SubmissionEmailLabel,
} from 'enums';
import { useBoolean, useCurrentUser, useRefState, useSearchUserMarket } from 'hooks';
import { Contact, ExtendedMarketRequest, User, UserMarket } from 'types';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import { PostEmailInformation, RecipientsGroupsAvailableRecipients } from 'broker/components/EmailEditor/types';
import useActiveDraft from 'broker/components/EmailEditor/useActiveDraft';
import useDraftEditing from 'broker/components/EmailEditor/useDraftEditing';
import useDraftPreview from 'broker/components/EmailEditor/useDraftPreview';
import useDraftTemplates from 'broker/components/EmailEditor/useDraftTemplates';
import useEmailRecipientsState from 'broker/components/EmailEditor/useEmailRecipientsState';
import { usePrepareAndSendEmail } from 'broker/components/EmailEditor/usePrepareAndSendEmail';
import useResolveDraft from 'broker/components/EmailEditor/useResolveDraft';
import useResolveVariable from 'broker/components/EmailEditor/useResolveVariable';
import useTemplates from 'broker/components/EmailEditor/useTemplates';
import { createRecipientsGroupsAvailableRecipients } from 'broker/components/EmailEditor/utils/create-recipients-groups-available-recipients';
import { isAnyTemplateInstanceMissingData } from 'broker/components/EmailEditor/utils/template-instance-utils';
import {
  convertContactToRecipient,
  isExternalRecipientGroup,
  RecipientGroup,
  RecipientGroups,
} from 'broker/components/Emails/recipient-utils';
import useAttachedFilesState from 'broker/components/Emails/useAttachedFilesState';
import { useCcState } from 'broker/components/Emails/useCcState';
import useDraftEditorMode from 'broker/components/Emails/useDraftEditorMode';
import { useGetAllTeamMembersAsRecipients } from 'broker/components/Emails/useGetAllTeamMembersAsRecipients';
import useAddContactToMarketModal from 'broker/dialogs/useAddContactToMarketModal';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { submissionMarketsToExtendedMarketRequests } from 'broker/utils/submission-utills';
import { EmailEditorActionsContext, EmailEditorStateContext } from './email-editor-context';
import {
  EmailEditorActions,
  EmailEditorState,
  EmailTemplateContext,
  EmailType,
  PreSelectedMarket,
  PRIMARY_MARKETING_TEMPLATE_FILE_TYPES_TO_HIGHLIGHT,
  SubmissionsWorkspaceActions,
  SubmissionsWorkspaceState,
  XS_MARKETING_TEMPLATE_FILE_TYPES_TO_HIGHLIGHT,
} from './types';
import { getInitialEditorMode } from './utils';

export interface EmailEditorProps {
  postEmailsSent?: (postEmailInformation: PostEmailInformation) => Promise<void> | void;
  preSelectedTemplateType?: EmailTemplateType;
  preSelectedRecipients?: RecipientGroup[];
  preSelectedFileIds?: string[];
  emailLabel?: SubmissionEmailLabel;
  templateContextMetaData?: Omit<
    EmailTemplateContext,
    'recipient' | 'submission' | 'submissionMarkets' | 'user' | 'quotes' | 'layers' | 'extendedMarketRequests'
  >;
  isAnimating?: boolean;
  repliedThreadSubject?: string;
  repliedThreadBody?: string;
  ccTeammatesInit?: User[];
  marketRequestType?: MarketRequestType;
  isSingleRecipientMode?: boolean;
  inReplyToMessageId?: string;
  onBehalfOf?: string;
}

function EmailEditorStoreProvider(
  props: EmailEditorProps & {
    submissionState: SubmissionsWorkspaceState;
    submissionActions: SubmissionsWorkspaceActions;
  } & { children: ReactNode },
) {
  const {
    marketRequestType,
    preSelectedTemplateType,
    emailLabel,
    preSelectedRecipients,
    preSelectedFileIds = [],
    templateContextMetaData = {},
    postEmailsSent,
    isAnimating,
    repliedThreadSubject,
    repliedThreadBody,
    ccTeammatesInit = [],
    isSingleRecipientMode,
    children,
    submissionState,
    submissionActions,
    inReplyToMessageId,
    onBehalfOf,
  } = props;

  const { me } = useCurrentUser();

  const { submission, markets, quotes, layers } = submissionState;
  const { addSubmissionMarkets, updateSubmission, reFetchWorkspace, addSubmissionMarketRequests } = submissionActions;

  // TODO: Delete after integration
  const [isPreviewMode, { toggle: togglePreviewMode }] = useBoolean(false);
  const [addTask, { toggle: toggleAddTask }] = useBoolean(true);
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
  const [getWasEmailsSent, setEmailsSent] = useRefState(false);
  const [arePreviewActionsVisible, { on: showPreviewActions, off: hidePreviewActions }] = useBoolean(false);

  let highlightFileTypes: FileType[] = [];
  if (preSelectedTemplateType === EmailTemplateType.PrimaryMarketing) {
    highlightFileTypes = PRIMARY_MARKETING_TEMPLATE_FILE_TYPES_TO_HIGHLIGHT;
  } else if (preSelectedTemplateType === EmailTemplateType.ExcessMarketing) {
    highlightFileTypes = XS_MARKETING_TEMPLATE_FILE_TYPES_TO_HIGHLIGHT;
  }

  const { attachedFiles, removeFile } = useAttachedFilesState({ preSelectedFileIds, highlightFileTypes });
  const navigate = useNavigate();

  function getEmailRecipientType(): EmailType {
    if (
      preSelectedRecipients?.length === 1 &&
      preSelectedRecipients[0].recipients.length === 1 &&
      preSelectedRecipients[0].recipients[0].id === me?.endUser?.id
    ) {
      return EmailType.UserNotification;
    }

    if (preSelectedRecipients?.length === 1 && preSelectedRecipients[0].type === OrganizationType.Retailer) {
      return EmailType.Retailer;
    }

    if (preSelectedRecipients?.length === 1 && isExternalRecipientGroup(preSelectedRecipients[0])) {
      return EmailType.ExternalUser;
    }

    return EmailType.Market;
  }

  const emailRecipientType = getEmailRecipientType();

  const ownIsSingleRecipientMode =
    isSingleRecipientMode ||
    [EmailType.Retailer, EmailType.UserNotification, EmailType.ExternalUser].includes(emailRecipientType);

  const extendedMarketRequests: ExtendedMarketRequest[] = useMemo(
    () => (submission ? submissionMarketsToExtendedMarketRequests(markets, submission) : []),
    [markets, submission],
  );

  const templateContextData: Omit<EmailTemplateContext, 'recipient'> = useMemo(
    () => ({
      ...templateContextMetaData,
      submission: submission!,
      extendedMarketRequests,
      submissionMarkets: markets,
      user: me!,
      quotes,
      attachedFiles,
      layers,
      selectedTemplate: preSelectedTemplateType,
    }),
    [
      templateContextMetaData,
      submission,
      extendedMarketRequests,
      markets,
      me,
      quotes,
      attachedFiles,
      layers,
      preSelectedTemplateType,
    ],
  );
  const { resolveForEditForAll, resolveForPreview, resolveForNoRecipientPreview, resolveForSend } = useResolveDraft({
    templateContextData,
    attachedFiles,
  });

  const { templates, templatesLoading } = useTemplates({
    preSelectedTemplateType,
    shouldFetch: !!preSelectedTemplateType,
    repliedThreadSubject,
    repliedThreadBody,
  });

  const { availableTeammates, selectedTeammates, toggleTeammateSelection } = useCcState(ccTeammatesInit);

  const { items: userMarkets, isLoading: userMarketsLoading } = useSearchUserMarket({
    enabled: !!submission,
    filter: {},
  });

  const retailerTeamId = submission?.team?.id;
  const shouldFetchRetailerTeamMembers = !!retailerTeamId && emailRecipientType === EmailType.Retailer;
  const { isLoading: isLoadingTeamMembers, allTeamMembersAsRecipients: retailerTeamMembers } =
    useGetAllTeamMembersAsRecipients(shouldFetchRetailerTeamMembers, retailerTeamId);

  const isPending = templatesLoading || userMarketsLoading || isLoadingTeamMembers;
  const {
    recipientGroups,
    onAddRecipientGroups,
    onToggleRecipient,
    onAddRecipient,
    onRemoveRecipientGroup,
    newRecipients,
    onClearNewRecipients,
    selectedRecipientGroupId,
    setSelectedRecipientGroupId,
  } = useEmailRecipientsState({
    preSelectedRecipients,
    userMarkets,
    submissionMarkets: markets,
  });

  const selectedRecipientGroup = selectedRecipientGroupId ? recipientGroups[selectedRecipientGroupId] : undefined;

  const recipientGroupsArray = useMemo(() => Object.values(recipientGroups), [recipientGroups]);
  const {
    recipientGroupToDraft,
    templateInstances,
    setRecipientGroupDraft,
    setRecipientGroupChosenTemplate,
    setRecipientGroupDetachedTemplateInstance,
    setTemplateInstance,
  } = useDraftTemplates(
    recipientGroupsArray,
    templates,
    resolveForPreview,
    preSelectedFileIds,
    ownIsSingleRecipientMode,
    templateContextData,
  );

  const { activeDraft, activeTemplateId, activeTemplateInstance, isActiveTemplateInstanceDetached } = useActiveDraft({
    recipientGroupToDraft,
    templateInstances,
    templates,
    selectedRecipientGroupId,
  });

  const { draftEditorMode, setDraftEditorMode } = useDraftEditorMode(
    getInitialEditorMode(ownIsSingleRecipientMode, !!preSelectedTemplateType),
  );

  const { resolveVariable } = useResolveVariable(templateContextData, draftEditorMode, selectedRecipientGroup);

  const isAnyEmailEmpty = isAnyTemplateInstanceMissingData(
    recipientGroupToDraft,
    templateInstances,
    recipientGroupsArray,
  );

  const {
    subject: previewSubject,
    body: previewBody,
    setSubject: setPreviewSubject,
    setBody: setPreviewBody,
  } = useDraftPreview({
    recipientGroups,
    selectedRecipientGroupId,
    activeTemplateInstance,
    resolveForPreview,
    resolveForNoRecipientPreview,
  });

  // NOTE: If there is no 'selectedRecipientGroupId', use the first template.
  const {
    subject: editingSubject,
    body: editingBody,
    from: editingFrom,
    isEditingContentDirty,
    setSubject: setEditingSubject,
    setBody: setEditingBody,
    setFrom: setEditingFrom,
    setIsEditingContentDirty,
    onEditForAllSave,
    onEditForAllCancel,
    onResetToTemplate,
    resetDirtyState,
  } = useDraftEditing({
    selectedRecipientGroupId,
    activeTemplateId,
    activeTemplateInstance,
    isActiveTemplateInstanceDetached,
    recipientGroupToDraft,
    setRecipientGroupDetachedTemplateInstance,
    setRecipientGroupDraft,
    setTemplateInstance,
    setDraftEditorMode,
    resolveForEditForAll,
  });

  const { addContactToMarketOpen, closeAddContactToMarketModal, addContactToMarketMetaData, onOpenNewContact } =
    useAddContactToMarketModal();

  const recipientsGroupsAvailableRecipients: RecipientsGroupsAvailableRecipients = isPending
    ? {}
    : createRecipientsGroupsAvailableRecipients(
        recipientGroupsArray,
        userMarkets,
        markets,
        preSelectedRecipients,
        retailerTeamMembers,
      );

  const { sendEmail, resolvedMessages } = usePrepareAndSendEmail({
    postEmailsSent,
    preSelectedTemplateType,
    emailLabel,
    emailRecipientType,
    recipientGroups,
    recipientGroupsArray,
    cc: selectedTeammates,
    userMarkets,
    setIsEditingContentDirty,
    templateInstances,
    recipientGroupToDraft,
    templateContextData,
    attachedFiles,
    resolveForSend,
    setIsSubmittingEmail,
    isSubmittingEmail,
    setEmailsSent,
    marketRequestType,
    submissionState,
    submissionActions,
    inReplyToMessageId,
    onBehalfOf,
    previewBody,
    previewSubject,
    addTask,
  });

  const onAddCustomerContact = () => {
    navigate(BROKER_NESTED_ROUTES.MODALS.CREATE_CUSTOMER_CONTACT, {
      routeKey: RouteKey.CreateCustomerContact,
      state: { selectedOrganization: preSelectedRecipients?.[0].id },
    });
  };

  const onCustomerContactCreated = async (newContact: Contact) => {
    const newSubmissionContacts = submission?.contacts?.map((contact) => contact.id) || [];

    newSubmissionContacts.push(newContact.id);

    await updateSubmission(submission!.id, {
      contactIds: newSubmissionContacts,
    });

    onAddRecipient(convertContactToRecipient(newContact), newContact.organizationId);
  };

  const onAddMarketContact = (userMarketId: string) => {
    const contactUserMarket = userMarkets.find((userMarket) => userMarket.id === userMarketId)!;
    onOpenNewContact({ userMarket: contactUserMarket });
  };

  const preSelectedMarkets = useMemo(
    () =>
      Object.values(recipientGroups)
        .filter((group) => isMarketOrganization(group.type))
        .map(
          (group) =>
            ({
              id: group.id,
              contactIds: group.recipients.map((recipient) => recipient.id),
            } as PreSelectedMarket),
        ),
    [recipientGroups],
  );

  const onAddMarkets = useCallback((addedMarkets: UserMarket[], replace?: boolean) => {
    const addedRecipientGroups: RecipientGroups = {};

    addedMarkets.forEach((market: UserMarket) => {
      const { contacts, marketName, id } = market;

      if (contacts && contacts.length > 0) {
        addedRecipientGroups[id] = {
          id,
          name: marketName,
          type: market.organizationType,
          recipients: contacts.map((contact) => convertContactToRecipient(contact)),
        };
      }
    });
    onAddRecipientGroups(addedRecipientGroups, replace);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const state: EmailEditorState = {
    submissionsWorkspace: {
      submission,
      markets,
      quotes,
      layers,
    },
    templates: { templates, templatesLoading, templateContextData },
    activeDraft,
    recipients: {
      recipientGroups,
      recipientGroupsArray,
      newRecipients,
      recipientsGroupsAvailableRecipients,
      preSelectedRecipientGroups: preSelectedRecipients,
      selectedRecipientGroupId,
    },
    draftTemplates: {
      recipientGroupToDraft,
      templateInstances,
    },
    draftPreview: {
      subject: previewSubject,
      body: previewBody,
    },
    draftEditing: {
      subject: editingSubject,
      body: editingBody,
      from: editingFrom,
      isEditingContentDirty,
      isActiveTemplateInstanceDetached,
    },
    customerContacts: {
      addContactToMarketMetadata: addContactToMarketMetaData,
      isAddContactToMarketOpen: addContactToMarketOpen,
    },
    cc: { availableTeammates, selectedTeammates },
    attachedFiles: { attachedFiles },
    emailType: emailRecipientType,
    marketRequestType,
    preSelectedMarkets,
    draftEditorMode,
    isPreviewMode,
    isPending,
    isAnimating,
    isAnyEmailEmpty,
    preSelectedTemplateType,
    isSubmittingEmail,
    getWasEmailsSent,
    areEditButtonsVisible: arePreviewActionsVisible,
    isSingleRecipientMode: ownIsSingleRecipientMode,
    resolvedMessages,
    addTask,
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const actions: EmailEditorActions = {
    submissionsWorkspace: {
      addSubmissionMarkets,
      updateSubmission,
      reFetchWorkspace,
      addSubmissionMarketRequests,
    },
    customerContacts: {
      closeAddContactToMarketModal,
      openAddNewContactToMarketDialog: onOpenNewContact,
    },
    recipients: {
      addRecipientGroups: onAddRecipientGroups,
      toggleRecipient: onToggleRecipient,
      addRecipient: onAddRecipient,
      removeRecipientGroup: onRemoveRecipientGroup,
      clearNewRecipients: onClearNewRecipients,
      setSelectedRecipientGroupId,
    },
    draftTemplates: {
      setRecipientGroupChosenTemplate,
      setRecipientGroupDetachedTemplateInstance,
      setTemplateInstance,
    },
    draftPreview: {
      setSubject: setPreviewSubject,
      setBody: setPreviewBody,
    },
    draftEditing: {
      setSubject: setEditingSubject,
      setBody: setEditingBody,
      setFrom: setEditingFrom,
      setIsEditingContentDirty,
      onEditForAllSave,
      onEditForAllCancel,
      resetDirtyState,
      onResetToTemplate,
    },
    cc: { toggleTeammateSelection },
    resolveVariable,
    addCustomerContact: onAddCustomerContact,
    addMarketContact: onAddMarketContact,
    customerContactCreated: onCustomerContactCreated,
    addMarkets: onAddMarkets,
    setDraftEditorMode,
    togglePreviewMode,
    showPreviewActions,
    hidePreviewActions,
    sendEmail,
    attachedFiles: {
      removeFile,
    },
    toggleAddTask,
  };

  return (
    <EmailEditorStateContext.Provider value={state}>
      <EmailEditorActionsContext.Provider value={actions}>{children}</EmailEditorActionsContext.Provider>
    </EmailEditorStateContext.Provider>
  );
}

export { EmailEditorStoreProvider };
