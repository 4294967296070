import { MouseEvent } from 'react';
import { IconButton, InputAdornment } from '@common-components';
import { Edit as EditIcon, Save as SaveIcon } from '@icons';

export interface EditAdornmentProps {
  editing: boolean;
  onEdit: () => void;
  onSave?: () => void;
}

export default function EditAdornment({ editing, onEdit, onSave }: EditAdornmentProps) {
  if (onSave === undefined) {
    return null;
  }

  const Icon = editing ? SaveIcon : EditIcon;
  const label = editing ? 'save' : 'edit';
  const onClick = editing ? onSave : onEdit;

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label={label}
        color="secondary"
        variant="text"
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onClick();
        }}
        edge="end"
        icon={Icon}
      />
    </InputAdornment>
  );
}
