import { MouseEvent } from 'react';
import { EditorEvent } from 'tinymce';
import { useRefFunction } from 'hooks';
import { classnameExtractingEventHandler } from 'components/Editor/classname-extractor-event-handler';
import { CAPITOLA_TINY_CLASS_PREFIX } from 'components/Editor/consts';
import { EmailTemplateContext } from 'broker/components/EmailEditor/store/types';
import { useNavigate } from 'broker/hooks';
import { smartObjectsConfig } from './SmartVariable/smart-objects-config';

interface GetHandleSmartObjectEditProps {
  templateContextData: Omit<EmailTemplateContext, 'recipient'>;
}

export function useSmartObjectEditHandler({ templateContextData }: GetHandleSmartObjectEditProps) {
  const navigate = useNavigate();

  const handleEditorClick = useRefFunction(async (clickedClassname: string) => {
    const clickedSmartObject = Object.values(smartObjectsConfig).find(
      (smartObject) => clickedClassname === `${CAPITOLA_TINY_CLASS_PREFIX}-${smartObject.key.toLowerCase()}`,
    );
    if (!clickedSmartObject) {
      return;
    }
    navigate(clickedSmartObject.navigationRoute, clickedSmartObject.navigationOptions(templateContextData));
  });

  return (e: EditorEvent<MouseEvent>) => {
    classnameExtractingEventHandler(handleEditorClick)(e);
  };
}
