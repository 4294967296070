import { AIFeature } from 'enums';
import { useCompareQuotes } from 'hooks';
import { useCreateAIThread } from 'broker/pages/SubmissionWorkspacePage/hooks';

interface UseComparisonProps {
  quoteId: string;
  uploadedFileIds: string[];
  aiFeature: AIFeature;
}

export const useComparison = ({ quoteId, uploadedFileIds, aiFeature }: UseComparisonProps) => {
  const { compareQuoteBinder, compareBinderPolicy, compareQuotePolicy } = useCompareQuotes();

  useCreateAIThread(() => {
    if (aiFeature === AIFeature.QuoteBinderCompare) {
      return compareQuoteBinder({
        quoteId,
        binderIds: uploadedFileIds,
      });
    }
    if (aiFeature === AIFeature.BinderPolicyCompare) {
      return compareBinderPolicy({
        quoteId,
        policyIds: uploadedFileIds,
      });
    }
    // aiFeature === AIFeature.QuotePolicyCompare
    return compareQuotePolicy({
      quoteId,
      policyIds: uploadedFileIds,
    });
  });
};
