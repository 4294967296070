import { createSearchParams } from 'react-router-dom-latest';
import { Chip } from '@common-components';
import { Attachment as AttachmentIcon } from '@icons';
import { QuoteFileIdType } from 'enums';
import { Quote } from 'types';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import { quoteTypeToLabel, UrlParams } from './QuoteReview/utils';

interface QuoteButtonProps {
  quote: Quote;
  quoteType: QuoteFileIdType;
}

export default function QuoteButton({ quote, quoteType }: QuoteButtonProps) {
  const { viewPortalQuoteUrl } = useWorkspaceUrls();
  const navigate = useNavigate();

  return (
    <Chip
      label={quoteTypeToLabel[quoteType]}
      variant="outlined"
      color="secondary"
      startIcon={AttachmentIcon}
      rounded
      onClick={() => {
        navigate(
          {
            pathname: viewPortalQuoteUrl,
            search: `${createSearchParams([[UrlParams.Id, quote.id]])}`,
          },
          {
            routeKey: RouteKey.Quote,
            state: undefined,
          },
        );
      }}
    />
  );
}
