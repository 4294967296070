import { useParams } from 'react-router-dom-latest';
import { Grid } from '@common-components';
import { useRetrieveLayer } from 'hooks';
import CardTextField from 'components/CardTextField';
import DetailCard from 'components/DetailCard';

export default function LayerPage() {
  const { id } = useParams<{ id: string }>();
  const { data: layer } = useRetrieveLayer({ id: id!, enabled: !!id });

  if (!layer) {
    return null;
  }

  return (
    <Grid sx={{ mt: 0 }} container wrap="wrap" spacing={3}>
      <Grid item xs={12} sm={6} lg={4}>
        <DetailCard avatar={layer.id.charAt(0).toUpperCase()} title={layer.id}>
          <CardTextField label="status" value={layer.status.toString()} />
          <CardTextField label="attachment point" value={layer.attachmentPoint?.toString() || 'primary'} />
        </DetailCard>
      </Grid>
    </Grid>
  );
}
