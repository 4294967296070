export interface BackendConfig {
  baseUrl: string;
  envName: string | undefined;
  domain: string | undefined;
}

export function loadBackendConfig(): BackendConfig {
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
  const envName = process.env.REACT_APP_ENV_NAME;
  const domain = process.env.REACT_APP_BACKEND_DOMAIN;

  if (baseUrl === undefined) {
    throw new Error('REACT_APP_BACKEND_BASE_URL environment variable must be defined');
  }

  return {
    baseUrl,
    envName,
    domain,
  };
}
