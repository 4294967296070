import htmlToPdfmake from 'html-to-pdfmake';

function cleanHtml(htmlString: string) {
  // Create a temporary DOM element to parse the HTML
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Remove id attributes
  doc.querySelectorAll('[id]').forEach((element) => {
    element.removeAttribute('id');
  });

  // Process style attributes
  doc.querySelectorAll('[style]').forEach((element) => {
    const styleAttribute = element.getAttribute('style');
    if (styleAttribute) {
      const styles = styleAttribute.split(';');
      const filteredStyles = styles.filter((style) => {
        const [property, value] = style.trim().split(':');
        if (property === 'font-size') {
          return /^\s*\d+px\s*$/.test(value); // Keep only if it's in the form of '16px'
        }
        return true;
      });
      element.setAttribute('style', filteredStyles.join('; '));
    }
  });

  // Return the cleaned HTML string
  return doc.body.innerHTML;
}

export const htmlToPdfMakeWrapper = (html: string) =>
  htmlToPdfmake(cleanHtml(html), {
    defaultStyles: {
      h1: { fontSize: 20, bold: true, marginBottom: 5 },
      h2: { fontSize: 18, bold: true, marginBottom: 5 },
      h3: { fontSize: 14, bold: true, marginBottom: 5 },
      h4: { fontSize: 14, bold: true, marginBottom: 5 },
      h5: { fontSize: 12, bold: true, marginBottom: 5 },
      h6: { fontSize: 10, bold: true, marginBottom: 5 },
    },
    ignoreStyles: ['font-family'],
    removeExtraBlanks: true,
  });
