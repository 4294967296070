import { useCallback, useEffect, useRef } from 'react';
import { EventEmitter } from './event-emitter';

export interface ServerPushEvent {
  event: string;
  data: { message: string; context: string; messageGuid: string };
}
export type ServerPushEventHandler = (data: ServerPushEvent) => void;

export const serverEventEmitter = new EventEmitter<ServerPushEvent>();

/**
 * Hook to listen to server push notifications.
 * @example
 *   useServerPushNotifications(({ event, data }: ServerEvent) => {
 *     if (event === 'my-event') {
 *         handleTheEvent(data);
 *     }
 *   }, 'my-context');
 * 'my-context' is the context of the event you want to listen to (usually feature).
 * 'my-event' is the event you want to listen to within the context.
 */
export const useServerPushNotifications = (listener: ServerPushEventHandler, context: string) => {
  const listenerRef = useRef(listener);

  // Update the ref whenever the listener changes
  useEffect(() => {
    listenerRef.current = listener;
  }, [listener]);

  useEffect(() => {
    const eventListener = (data: ServerPushEvent) => {
      if (context !== data.data.context) {
        return;
      }

      listenerRef.current(data);
    };

    serverEventEmitter.addListener(eventListener);
    return () => {
      serverEventEmitter.removeListener(eventListener);
    };
  }, [context]);

  return useCallback(() => {
    serverEventEmitter.removeListener(listenerRef.current);
  }, []);
};
