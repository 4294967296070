import { SnackbarProvider } from 'notistack';
import { ReactNode } from 'react';
import { Box, Theme } from '@common-components';

const styles = {
  // This is a hack since notistack doesn't support styling using styled()
  '& .SnackbarContainer-root': {
    width: '445px',
    paddingLeft: (theme: Theme) => theme.spacing(9),
    '& .MuiCollapse-root': {
      width: '100%',
      maxWidth: (theme: Theme) => `${theme.breakpoints.values.lg}px`,
    },
  },
  '& .SnackbarContainer-right': {
    right: '-3px!important',
  },
};

const ToastProvider = ({ children }: { children?: ReactNode }) => (
  <Box sx={styles}>
    <SnackbarProvider>{children}</SnackbarProvider>
  </Box>
);
export default ToastProvider;
