import { Stack, SvgIcon, Typography } from '@common-components';
import { InfoOutlined } from '@icons';

interface InfoBannerProps {
  sections: {
    title: string;
    content: string;
  }[];
  icon?: typeof SvgIcon;
}

export default function InfoBanner({ sections, icon = InfoOutlined }: InfoBannerProps) {
  const Icon = icon;
  return (
    <Stack width={1} bgcolor="blueGrey.50" borderRadius={2} p={2} gap={1}>
      <Stack direction="row" gap={1}>
        <Icon color="primary" />
        <Stack gap={3}>
          {sections.map(({ title, content }) => (
            <Stack key={title}>
              <Typography variant="body2Bold">{title}</Typography>
              <Typography variant="body2">{content}</Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
