export enum UserRole {
  Admin = 'Admin',
  Broker = 'Broker',
  CapitolaBroker = 'CapitolaBroker',
  Retailer = 'Retailer',
  // NB: carrier users are not supported in the frontend yet.
  // Carrier = 'Carrier',
  Machine = 'Machine',
  BI = 'BI',
  BackOffice = 'BackOffice',
}

export const ALL_USER_ROLES: UserRole[] = Object.keys(UserRole).map((key) => UserRole[key as keyof typeof UserRole]);

export const BROKER_SITE_ROLES: UserRole[] = [UserRole.CapitolaBroker];
export const CAPITOLA_SITE_ROLES: UserRole[] = [UserRole.Admin, UserRole.BackOffice, UserRole.Machine];
export const PORTAL_SITE_ROLES: UserRole[] = [UserRole.Retailer, UserRole.Broker];
