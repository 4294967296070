import { emailLabelConfig } from '@common/config/email-label-config';
import { useState } from 'react';
import { InfoOutlined as InfoOutlinedIcon, LabelOutlined as LabelOutlinedIcon } from '@icons';
import { SubmissionEmailLabel } from 'enums';
import { useHotjar } from 'hooks';
import { useMutateSubmissionEmail } from 'hooks/api/submissionEmail';
import { messages } from 'i18n';
import { labelCompare } from 'utils/comparators';
import { HotjarEvents } from 'utils/hotjar-events';
import LabelsMenu, { LabelsMenuAnchorType } from 'broker/components/LabelsMenu';
import useSingleFieldOptimisticUpdate from 'broker/hooks/useSingleFieldOptimisticUpdate';
import { EmailListItemAttributes } from './utils';

interface EmailLabelsMenuProps {
  email: EmailListItemAttributes;
  selectableLabels: SubmissionEmailLabel[];
}

export default function OutboundEmailLabelsMenu({ email, selectableLabels }: EmailLabelsMenuProps) {
  const hotjar = useHotjar();
  const [isLabelsMenuOpen, setIsLabelsMenuOpen] = useState(false);
  const { updateSubmissionEmail } = useMutateSubmissionEmail();

  const updateSubmissionLabel = async (label: SubmissionEmailLabel | null) =>
    updateSubmissionEmail.mutateAsync({
      id: email.id,
      data: { label },
    });

  const { value: selectedLabel, onChange: onLabelChange } = useSingleFieldOptimisticUpdate<SubmissionEmailLabel | null>(
    {
      serverValue: email.label as SubmissionEmailLabel,
      apiUpdate: updateSubmissionLabel,
    },
  );

  function getLabelOptions() {
    const disabledProps = {
      disabled: true,
      disabledTooltipContent: messages.submissionWorkspace.emailsTab.inboundLabelOnly,
      endAdornment: InfoOutlinedIcon,
    };

    return selectableLabels
      .map((selectableLabel) => ({
        label: emailLabelConfig[selectableLabel].text,
        value: selectableLabel,
        ...(emailLabelConfig[selectableLabel].isInboundOnly && { ...disabledProps }),
      }))
      .sort(labelCompare);
  }

  const getLabelMenuType = () => {
    if (selectedLabel) {
      return LabelsMenuAnchorType.EditLabel;
    }
    return LabelsMenuAnchorType.AddLabel;
  };

  function ownOnLabelChanged(label?: string) {
    hotjar.event(HotjarEvents.EmailLabeled);
    setIsLabelsMenuOpen(false);

    const newLabel = label ? (label as SubmissionEmailLabel) : null;
    return onLabelChange(newLabel);
  }

  return (
    <LabelsMenu
      options={getLabelOptions()}
      selectedLabels={
        selectedLabel
          ? [
              {
                label: emailLabelConfig[selectedLabel].text,
                value: selectedLabel,
              },
            ]
          : undefined
      }
      anchorType={getLabelMenuType()}
      isOpenOverride={isLabelsMenuOpen}
      onClose={() => setIsLabelsMenuOpen(false)}
      onLabelChanged={ownOnLabelChanged}
      onClearLabel={ownOnLabelChanged}
      icon={LabelOutlinedIcon}
      addLabelText={messages.submissionWorkspace.emailsTab.addLabelButton}
    />
  );
}
