import { IconButton, SvgIcon, Tooltip } from '@common-components';
import { QueueOutlined as QueueIcon } from '@icons';

export interface CreateButtonProps {
  Icon?: typeof SvgIcon;
  onClick: () => void;
  title?: string;
}

export default function CreateButton({ Icon = QueueIcon, onClick, title = 'Create' }: CreateButtonProps) {
  return (
    <Tooltip tooltipContent={title}>
      <IconButton icon={Icon} aria-label={title} onClick={onClick} color="secondary" variant="text" />
    </Tooltip>
  );
}
