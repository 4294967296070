import { useMemo } from 'react';
import { InsuranceProduct, SelectProduct, SubmissionMarket } from 'types';

interface UseGetSelectProductsProps {
  insuranceProducts: InsuranceProduct[];
  submissionMarkets: SubmissionMarket[];
}

export default function useGetSelectProducts({ submissionMarkets, insuranceProducts }: UseGetSelectProductsProps) {
  const selectProducts: SelectProduct[] = useMemo(
    () =>
      insuranceProducts.map((insuranceProduct) => {
        const inSubmission = submissionMarkets.some((submissionMarketItem) =>
          submissionMarketItem.marketRequests.some(
            (marketRequestItem) => marketRequestItem.insuranceProductId === insuranceProduct.id,
          ),
        );
        return {
          ...insuranceProduct,
          inSubmission,
        };
      }),
    [insuranceProducts, submissionMarkets],
  );
  return selectProducts;
}
