import { useContext, useMemo } from 'react';
import { CapitolaClient, ClientOptions } from 'clients';

import { useConfig } from 'config';
import { ErrorCallback, ErrorContext, useAuthenticationContext } from 'contexts';

interface AdditionalOptions {
  onError?: ErrorCallback;
}

export function useClient<T, C extends CapitolaClient<T>>(
  ClientCtor: { new (options: ClientOptions): C },
  additionalOptions?: AdditionalOptions,
) {
  const config = useConfig();
  const { baseUrl } = config.backend;

  const { onError } = useContext(ErrorContext);
  // useClient is used in components that are rendered only if user is logged in
  const { getIdToken } = useAuthenticationContext();

  // NB: We need to memoize the client because it will be used a useEffect() input.
  return useMemo(
    () =>
      new ClientCtor({
        baseUrl,
        getBearerToken: getIdToken,
        onError: additionalOptions?.onError || onError,
      }),
    [ClientCtor, baseUrl, getIdToken, additionalOptions?.onError, onError],
  );
}
