import { useState } from 'react';
import { createSearchParams } from 'react-router-dom-latest';
import { BoxSkeleton, Divider, Stack } from '@common-components';
import { useInvalidateUserMarketCache } from 'hooks';
import { demoData } from 'utils';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import DataGridNoResults from 'broker/components/common/QuotesAndPolicies/DataGridNoResults';
import { MarketOnClick, MarketsViewProps } from 'broker/components/UserMarkets/types';
import AddContactToMarketModal from 'broker/dialogs/AddContactToMarketModal';
import useAddContactToMarketModal from 'broker/dialogs/useAddContactToMarketModal';
import { UrlParam } from 'broker/dialogs/UserMarketDialog/utils';
import { RouteKey } from 'broker/enums';
import MainScrollArea from 'broker/hoc/MainScrollArea';
import { useNavigate } from 'broker/hooks';
import MarketsTableHeader from './MarketsTableHeader';
import MarketsTableItem from './MarketsTableItem';

export default function MarketsTableView({
  coverageLineFilters,
  isAllCoverageLineFilters,
  extendedMarkets,
  submissionMarkets,
  selectionMode,
  isLoading,
  searchInput,
  selectedMarkets,
  marketsIdsInSubmission,
  onSelectMarket,
  onSelectionClick,
  handleContactClicked,
  disableSelection,
  checkedLabels = [],
  incumbentMarketIds,
  selectNewContact,
  bottomTableContent,
  isCompact = false,
}: MarketsViewProps) {
  const navigate = useNavigate();

  const openEditModal: MarketOnClick = ({ id, scrollTo }) => {
    navigate(
      {
        pathname: BROKER_NESTED_ROUTES.MARKETS.MARKET,
        search: `${createSearchParams([[UrlParam.Id, id]])}`,
      },
      {
        routeKey: RouteKey.UserMarket,
        state: { scrollTo },
      },
    );
  };

  const [expandedMarketId, setExpandedMarketId] = useState('');
  const invalidateUserMarketCache = useInvalidateUserMarketCache();

  const { addContactToMarketOpen, closeAddContactToMarketModal, addContactToMarketMetaData, onOpenNewContact } =
    useAddContactToMarketModal();

  const onExpandClicked = (marketId: string) => {
    setExpandedMarketId((prev) => {
      if (prev === marketId) {
        return '';
      }
      return marketId;
    });
  };

  const items =
    extendedMarkets.length > 0 ? (
      extendedMarkets.map((market) => (
        <MarketsTableItem
          isMarketExpanded={expandedMarketId === market.id}
          onExpandClicked={onExpandClicked}
          coverageLineFilters={coverageLineFilters}
          isAllCoverageLineFilters={isAllCoverageLineFilters}
          marketsIdsInSubmission={marketsIdsInSubmission}
          extendedMarket={market}
          submissionMarkets={submissionMarkets}
          isLoading={isLoading}
          markets={extendedMarkets}
          selectedMarkets={selectedMarkets}
          selectedContacts={selectedMarkets?.[market.id]?.contacts}
          key={market.id}
          onClick={openEditModal}
          selectionMode={selectionMode}
          onSelectMarket={onSelectMarket}
          onSelectionClick={onSelectionClick}
          searchInput={searchInput}
          checkedLabels={checkedLabels}
          handleContactClicked={handleContactClicked}
          onOpenNewContact={onOpenNewContact}
          disableSelection={disableSelection}
          isIncumbent={incumbentMarketIds?.includes(market.id)}
          isCompact={isCompact}
        />
      ))
    ) : (
      <DataGridNoResults />
    );

  const skeleton = (
    <Stack gap={2} p={2}>
      {demoData(10).map((row, index) => (
        <Stack gap={2} key={row.id}>
          <BoxSkeleton isLoading sx={{ width: 1, height: 45 }} index={index} />
          <Divider />
        </Stack>
      ))}
    </Stack>
  );
  return (
    <>
      <Stack overflow="hidden" flex="1 1 0">
        <MainScrollArea id="marketsScrollArea" flex={1} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
          <MarketsTableHeader selectMode={selectionMode} />
          {isLoading ? skeleton : items}
          {bottomTableContent}
        </MainScrollArea>
      </Stack>

      {addContactToMarketMetaData && (
        <AddContactToMarketModal
          isOpen={addContactToMarketOpen}
          onContactAdded={selectNewContact}
          onContactUpdated={() => {
            invalidateUserMarketCache();
          }}
          onClose={closeAddContactToMarketModal}
          metaData={addContactToMarketMetaData}
        />
      )}
    </>
  );
}
