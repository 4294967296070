import { BROKER_SITE_ROLES, UserRole } from 'enums';
import { LoggedInUser } from 'types';

export enum Features {
  CreateSubmissionManually = 'CreateSubmissionManually',
  EndUserIndicator = 'EndUserIndicator',
  SendEmailToUser = 'SendEmailToUser',
  EmailIntegration = 'EmailIntegration',
  SyncFilesIntegration = 'SyncFilesIntegration',
  ApproveSyncFilesIntegration = 'ApproveSyncFilesIntegration',
  SendArfRequest = 'SendArfRequest',
  ArfSent = 'ArfSent',
  EditHeraldData = 'EditHeraldData',
  EditBoxData = 'EditBoxData',
  EditSubmission = 'EditSubmission',
}
export interface FeaturesDisplayConfigProps {
  roles: UserRole[];
}

type FeaturesDisplayConfig = {
  [feature in Features]: FeaturesDisplayConfigProps;
};

const FeatureConfig: FeaturesDisplayConfig = {
  [Features.EndUserIndicator]: {
    roles: [UserRole.BackOffice],
  },
  [Features.SendEmailToUser]: {
    roles: [UserRole.BackOffice],
  },
  [Features.SyncFilesIntegration]: {
    roles: [...BROKER_SITE_ROLES],
  },
  [Features.ApproveSyncFilesIntegration]: {
    roles: [UserRole.BackOffice],
  },
  [Features.EmailIntegration]: {
    roles: [...BROKER_SITE_ROLES],
  },
  [Features.SendArfRequest]: {
    roles: [...BROKER_SITE_ROLES],
  },
  [Features.ArfSent]: {
    roles: [UserRole.BackOffice],
  },
  [Features.CreateSubmissionManually]: {
    roles: [...BROKER_SITE_ROLES],
  },
  [Features.EditHeraldData]: {
    roles: [UserRole.CapitolaBroker],
  },
  [Features.EditBoxData]: {
    roles: [UserRole.CapitolaBroker],
  },
  [Features.EditSubmission]: {
    roles: [UserRole.CapitolaBroker],
  },
};

export function isFeatureEnabled(feature: Features, me: LoggedInUser): boolean {
  return FeatureConfig[feature].roles.includes(me.role);
}
