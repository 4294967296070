import { OffsetLimitPagination, SubmissionEmail, SubmissionEmailSearchItem } from 'types';
import { CapitolaClient } from './base';

export type SubmissionQuery = OffsetLimitPagination;

export default class SubmissionEmailClient extends CapitolaClient<SubmissionEmail, SubmissionEmailSearchItem> {
  public get resourceName(): string {
    return 'submission-email';
  }
}
