import { generatePath } from 'react-router-dom-latest';

export const HOME = 'broker';

export const PORTAL = 'portal';

export const SUBMISSION_BOX = 'broker/submission-box';
export const MARKETS = 'broker/markets';
export const AGENCIES = 'broker/agencies';
export const TEMPLATES = 'broker/templates';
export const TEAM = 'broker/team';
export const SUBMISSION = 'broker/submission/:id';

export const RETAILER_SUBMISSION = 'portal/submission/:id';

export const BACK_OFFICE = 'backoffice';

export const EDIT_SUBMISSION = 'edit-submission';

export const SUBMISSION_AUTONOMOUS_CREATION = 'submission-autonomous-creation';
export const MARKET = 'market';

export const AGENCY = 'agency';

export const AGENT = 'agent';
export const SINGLE_MARKET = 'single-market';

export const PRE_MARKETING = 'pre-marketing';
export const MARKETING = 'marketing';
export const CUSTOMER = 'customer';

export const PROPOSAL = 'proposal';

export const QUOTE = 'quote';
export const BINDER = 'binder';
export const POLICY = 'policy';
export const QUOTE_FORM_STEP = 'form';
export const COMPARE_QUOTES = 'compare-quotes';
export const QUOTE_STATUS = 'quote-status';
export const EMAIL_EDITOR = 'email-editor';
export const CONCEPTUAL_TOWER = 'conceptual-tower';
export const EXPIRING_TOWER = 'expiring-tower';
export const MARKETING_REPORT = 'marketing-report';
export const QUOTE_STATUS_REQUEST = 'quote-status-request';
export const ADD_MARKETS = 'add-markets';
export const ADD_PRODUCTS = 'add-products';
export const SELECT_PRODUCTS = 'select-products';
export const ACKNOWLEDGMENT_EMAIL = 'acknowledgment-email';
export const EMAIL_REPLY = 'email-reply';
export const CREATE_CUSTOMER_CONTACT = 'create-customer-contact';
export const QUOTE_COMPARISON_INSIGHTS = 'quote-comparison-insights';
export const COMPARE_QUOTES_SIDE_BY_SIDE = 'compare-quotes-side-by-side';
export const MARKETING_UPDATE = 'marketing-update';
export const MARKETING_UPDATE_EMAIL = 'marketing-update-email';

export const BROKER_ROUTES = {
  HOME,
  SUBMISSION_BOX,
  MARKETS,
  AGENCIES,
  TEMPLATES,
  TEAM,
  SUBMISSION,
  RETAILER_SUBMISSION,
  BACK_OFFICE,
  PORTAL,
};

export const BROKER_NESTED_ROUTES = {
  HOME: {
    SUBMISSION_AUTONOMOUS_CREATION,
  },
  MARKETS: {
    MARKET,
  },
  AGENCIES: {
    AGENCY,
    AGENT,
  },
  WORKSPACE: {
    EDIT_SUBMISSION,
    QUOTE,
    BINDER,
    POLICY,
    COMPARE_QUOTES,
    QUOTE_STATUS,
    EMAIL_EDITOR,
    CONCEPTUAL_TOWER,
    MARKETING_REPORT,
    QUOTE_STATUS_REQUEST,
    ADD_MARKETS,
    ADD_PRODUCTS,
    EMAIL_REPLY,
    MARKET,
    SINGLE_MARKET,
    EXPIRING_TOWER,
    SUBMISSION_AUTONOMOUS_CREATION,
    SELECT_PRODUCTS,
    ACKNOWLEDGMENT_EMAIL,
    QUOTE_COMPARISON_INSIGHTS,
    COMPARE_QUOTES_SIDE_BY_SIDE,
    PROPOSAL,
    MARKETING_UPDATE,
    MARKETING_UPDATE_EMAIL,
  },
  MODALS: {
    CREATE_CUSTOMER_CONTACT,
  },
};

export const WORKSPACE_TABS_ROUTES = {
  PRE_MARKETING,
  MARKETING,
  CUSTOMER,
} as const;

type WorkspaceTabsRoutesKeys = keyof typeof WORKSPACE_TABS_ROUTES;
export type WorkspaceTabsRoutesValues = (typeof WORKSPACE_TABS_ROUTES)[WorkspaceTabsRoutesKeys];

export enum BrokerUrlParams {
  ACTIVE_TAB = 'activeTab',
  SELECTED_EMAIL = 'selectedEmail',
  SELECTED_FILE = 'selectedFile',
  ACTIVE_PRE_MARKETING_SECTION = 'activePreMarketingSection',
}

export enum activeTabUrlParamValues {
  PRE_MARKETING_FILES = 'PreMarketingFiles',
  FILES_SELECTION = 'FilesSelection',
  FILES = 'Files',
  FILES_SELECTION_ON_EMAIL_COMPOSE = 'FilesSelectionOnEmailCompose',
  EMAIL = 'Email',
  MARKET_ACTIVITY = 'MarketActivity',
  TOWER = 'Tower',
  CONTACTS = 'Contacts',
  MARKET_INFO = 'MarketInfo',
  MARKET_FILES = 'MarketFiles',
  EMAIL_WITHOUT_EMAIL_ACTIONS = 'EmailWithoutEmailActions',
  TASKS = 'Tasks',
}

export const generateSubmissionPath = (id?: string, tab?: WorkspaceTabsRoutesValues) => {
  let link = `/${BROKER_ROUTES.SUBMISSION}`;
  if (tab) {
    link += `/${tab}`;
  }

  return generatePath(link, { id });
};

export const generatePortalSubmissionPath = (id?: string) =>
  generatePath(`/${BROKER_ROUTES.RETAILER_SUBMISSION}`, { id });

export enum QuoteFormRoutes {
  Upload = 'upload',
  Details = 'details',
  Insights = 'insights',
  FlowQuote = 'flow-quote',
}

export enum QuoteComparisonInsightsRoutes {
  Compose = 'compose',
  Upload = 'upload',
}

export enum MarketingUpdateRoutes {
  Compose = 'compose',
  Upload = 'upload',
}
