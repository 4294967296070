import { Box, Button, FormControlLabel, FormGroup, Stack, Switch, switchClasses, Typography } from '@common-components';
import { AirplanemodeActive } from '@icons';
import { useBoolean, useToast } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission, SubmissionMarket } from 'types';
import { ConfirmationDialog } from 'components/Dialog';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export default function AutonomousBanner({
  submission,
  submissionMarkets,
}: {
  submission: PartialSubmission;
  submissionMarkets: SubmissionMarket[];
}) {
  const [showConfirmation, { on: openConfirmation, off: closeConfirmation }] = useBoolean(false);

  const navigate = useNavigate();

  const { autonomousSettingsUrl } = useWorkspaceUrls();

  const { showToast } = useToast();

  const { updateSubmission, updateSubmissionMarket } = useSubmissionsWorkspace();

  const [isDisabling, { on: startDisabling, off: stopDisabling }] = useBoolean(false);

  const handleCopilotDisable = async () => {
    startDisabling();
    await Promise.all([
      updateSubmission(submission.id, { isCopilotEnabled: false }),
      ...submissionMarkets.map((submissionMarket) =>
        updateSubmissionMarket(submissionMarket.id, { copilotEnrolled: false }),
      ),
    ]);
    stopDisabling();
    showToast('success', { message: messages.autonomousFlow.banner.deactivated(submission.insuredName || '') });
    closeConfirmation();
  };

  return (
    <Stack direction="row" alignItems="center" px={2} gap={1} height={52} bgcolor="green.300" mt={1}>
      <Stack direction="row" alignItems="flex-start" gap={1} flex={1}>
        <AirplanemodeActive fontSize="small" />
        <Stack overflow="hidden">
          <Typography noWrap variant="body2">
            {messages.autonomousFlow.banner.title}
          </Typography>
          <Typography noWrap variant="caption" color="textSecondary" component="div">
            {messages.autonomousFlow.banner.description}
          </Typography>
        </Stack>
      </Stack>
      <Button
        color="inherit"
        variant="outlined"
        size="small"
        onClick={() => {
          navigate(autonomousSettingsUrl, {
            routeKey: RouteKey.SubmissionAutonomousCreation,
            state: { submission },
          });
        }}
      >
        {messages.autonomousFlow.banner.cta}
      </Button>
      <FormGroup>
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              sx={{
                [`& .${switchClasses.switchBase}.${switchClasses.checked} + .${switchClasses.track}`]: {
                  bgcolor: 'green.600',
                },
              }}
              checkedIcon={
                <Box
                  sx={{
                    bgcolor: 'green.800',
                    borderRadius: '50%',
                    width: 20,
                    height: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AirplanemodeActive sx={{ color: 'common.white', fontSize: 16 }} />
                </Box>
              }
              checked
              onChange={openConfirmation}
            />
          }
          label={<Typography variant="body2">{messages.autonomousFlow.banner.toggleLabel}</Typography>}
        />
      </FormGroup>
      <ConfirmationDialog
        title={messages.autonomousFlow.banner.confirmationDialog.title}
        message={messages.autonomousFlow.banner.confirmationDialog.description(
          `${submission.assignee?.firstName} ${submission.assignee?.lastName}`,
        )}
        isOpen={showConfirmation}
        onCancel={closeConfirmation}
        onSubmit={handleCopilotDisable}
        cancelButton={{ children: messages.buttons.cancel, color: 'secondary' }}
        proceedButton={{
          loading: isDisabling,
          color: 'error',
          children: messages.autonomousFlow.banner.confirmationDialog.confirm,
        }}
      />
    </Stack>
  );
}
