import { Grid } from '@common-components';
import FormFieldSkeleton from './FormFieldSkeleton';

export default function FormSkeleton() {
  return (
    <Grid spacing={3} container>
      <Grid item xs={6}>
        <FormFieldSkeleton index={0} />
      </Grid>
      <Grid item xs={6}>
        <FormFieldSkeleton index={0} />
      </Grid>
      <Grid item xs={12}>
        <FormFieldSkeleton index={1} />
      </Grid>
      <Grid item xs={6}>
        <FormFieldSkeleton index={2} />
      </Grid>
      <Grid item xs={6}>
        <FormFieldSkeleton index={2} />
      </Grid>
    </Grid>
  );
}
