import { useMemo } from 'react';
import { EmailTemplateType } from 'enums';
import { useCurrentUser, useSearchEmailTemplate } from 'hooks';
import { messages } from 'i18n';
import { EmailTemplate } from 'types';
import { EmailTemplateConfig } from 'broker/configuration-mappers/email-template-config';

interface UseTemplatesProps {
  preSelectedTemplateType?: EmailTemplateType;
  shouldFetch?: boolean;
  repliedThreadSubject?: string;
  repliedThreadBody?: string;
}

export default function useTemplates({
  preSelectedTemplateType,
  shouldFetch = true,
  repliedThreadSubject,
  repliedThreadBody,
}: UseTemplatesProps) {
  const { me } = useCurrentUser();

  const isBackOfficeType = preSelectedTemplateType && EmailTemplateConfig[preSelectedTemplateType].backOfficeTemplate;

  const { items: templateList, isInitialLoading: templatesLoading } = useSearchEmailTemplate({
    enabled: shouldFetch,
    filter: preSelectedTemplateType
      ? { type: preSelectedTemplateType, userId: isBackOfficeType ? me?.id : me?.endUser?.id }
      : {},
  });

  const templates = useMemo(() => {
    if (!preSelectedTemplateType || !templateList) {
      return [];
    }

    return templateList.map((template) => {
      if (template.type === preSelectedTemplateType) {
        return {
          ...template,
          body: repliedThreadBody ? `${template.body}<br>${repliedThreadBody}` : template.body,
          subject: repliedThreadSubject || template.subject,
        };
      }
      return template;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateList, repliedThreadBody, repliedThreadSubject]);

  // Flows that don’t have a domain specific template (e.g. Compose) rely on this, by default ,empty template.
  // Duplicate email also uses this template by passing the subject and body of the originating email as the initial values for the new email.
  const noFetchTemplates = useMemo(
    () => [
      {
        id: 'FlexTemplate',
        name: messages.emailEditor.newEmailTemplateName,
        subject: repliedThreadSubject || '',
        body: repliedThreadBody || '',
      } as EmailTemplate,
    ],
    [repliedThreadBody, repliedThreadSubject],
  );

  if (!shouldFetch) {
    return {
      templates: noFetchTemplates,
      templatesLoading: false,
    };
  }

  return {
    templates,
    templatesLoading,
  };
}
