// todo: herald binder and policy add
import { HeraldStatusDetailsItem } from '@common/types';
import { HeraldStatus } from 'enums';
import { MakeFieldsRequired } from 'utils';

export enum HeraldFormType {
  Quote = 'quote',
}

export enum HeraldFormInputType {
  SelectOne = 'select_one',
  SelectMany = 'select_many',
  AgreeTo = 'agree_to',
  ShortText = 'short_text',
  LongText = 'long_text',
  Number = 'number',
  Integer = 'integer',
  Currency = 'currency',
  Date = 'date',
  Address = 'address',
  ClaimEvent = 'claim_event',
  Phone = 'phone',
  Email = 'email',
  Domain = 'domain',
  File = 'file',
  Year = 'year',
}

export interface QuoteExits {
  product_id: string;
  status: string;
  status_details: HeraldStatusDetailsItem[];
}

export interface HeraldApplicationResponse {
  quote_exits?: QuoteExits[];
  application: HeraldApplication;
}

export interface HeraldSubmissionResponse {
  submission: HeraldSubmission;
}

export enum HeraldSchemaFormat {
  Email = 'email',
  Date = 'date',
  Hostname = 'hostname',
}

export interface HeraldParameterBaseProps {
  value?: any;
  section: string;
  parameter_text: {
    applicant_facing_text: string;
    agent_facing_text: string;
    applicant_agree_to_text?: string;
  };
  relevant_products: string[];

  instance?: string;
  creates_array: boolean;

  affects_conditions: boolean;
  required_for: HeraldFormType[];
  input_type: HeraldFormInputType;
  schema: {
    title: string;
    type:
      | 'string'
      | 'integer'
      | 'number'
      | 'object'
      // array below is related to multi select field
      | 'array';

    // for string

    format: HeraldSchemaFormat;
    // date string 'YYY-MM-DD'
    min_date?: string;
    max_date?: string;

    // regex string - e.g "^\\d{10}$",
    pattern?: string;

    min_length?: number;
    max_length?: number;

    enum?: string[];

    // end for string

    // for integer
    minimum?: number;
    maximum?: number;

    // for 'array'
    items?: {
      type: string;
      enum: string[];
    };
    // for 'array' - 0, this means that "None of the above" is one of the options (as the very last enum value). Herald has validation to remove any additional values if "None of the above"
    min_items?: number;

    // for object
    properties?: {
      [propName in string]: HeraldParameterBaseProps['schema'];
    };

    // for object - once properties is defined we will have in required all the keys of properties that are required
    required?: string[];
  };
}

export interface HeraldRiskParameterProps extends HeraldParameterBaseProps {
  risk_parameter_id: string;
  child_risk_values?: HeraldRiskParameterProps[];
}

export interface HeraldCoverageParameterProps extends HeraldParameterBaseProps {
  coverage_parameter_id: string;
  child_coverage_values?: HeraldCoverageParameterProps[];
}

export enum HeraldCategoryType {
  RiskValues = 'risk_values',
  CoverageValues = 'coverage_values',
}

export interface HeraldApplication {
  id: string;
  status: HeraldStatus;
  products: string[];
  risk_values: HeraldRiskParameterProps[];
  coverage_values: HeraldCoverageParameterProps[];
}

export type RiskValuesUpdate = Pick<HeraldRiskParameterProps, 'risk_parameter_id' | 'instance' | 'value'> & {
  child_risk_values?: RiskValuesUpdate[];
};

export type CoverageValuesUpdate = Pick<
  HeraldCoverageParameterProps,
  'coverage_parameter_id' | 'instance' | 'value'
> & {
  child_coverage_values?: CoverageValuesUpdate[];
};

export type HeraldCreateApplication = MakeFieldsRequired<HeraldUpdateApplication, 'products'>;

export interface HeraldSubmission {
  id: string;
  quote_previews: { quote_id: string; product_id: string }[];
  application: HeraldApplication;
}

export interface HeraldUpdateApplication {
  products?: string[];
  risk_values?: RiskValuesUpdate[];
  coverage_values?: CoverageValuesUpdate[];
}

export interface HeraldApiError extends Error {
  errors?: HeraldApiInvalidFormatItem[];
}

interface HeraldApiInvalidFormatItem {
  code: 'invalid_format';
  display_message: string;
  // risk_values[0].value || risk_values[0].value.date || risk_values[13].child_risk_values[0].value
  path: string;
}

export interface HeraldIndexEntry {
  id: string;
  description: string; // index entry description
  naics_2017_6_digit: string;
  naics_2017_6_digit_description: string;
}

export interface HeraldIndexEntryResponse {
  classifications: HeraldIndexEntry[];
}

export enum HeraldFormParameterId {
  SelectedCoverages = 'cvg_0e4f_ml_selected_coverages',
}

// Herald Errors: https://docs.heraldapi.com/docs/heraldapi/3ede7bd5dcc45-error
export interface HeraldErrorResponse {
  errors: HeraldError[];
}

export interface HeraldError {
  id: string;
  code: string;
  message: string;
  display_message?: string;
}
