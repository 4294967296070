import { ChangeEvent, FormEvent, useState } from 'react';
import { Button, Link, Stack, Typography } from '@common-components';
import { useBulkCreateSubmissions } from 'hooks';
import { messages } from 'i18n';
import { BulkCreateError, Submission, Team } from 'types';
import { Dialog, DialogContent, DialogHeader } from 'components/Dialog';
import InternalLink from 'components/InternalLink';
import adminRoutes from 'admin/admin-routes';

interface SubmissionsBulkUploadDialogProps {
  isOpen: boolean;
  onClose: () => void;
  team: Team;
}

export default function SubmissionsBulkUploadDialog({ isOpen, onClose, team }: SubmissionsBulkUploadDialogProps) {
  const { bulkCreateSubmissions } = useBulkCreateSubmissions();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [updatedSubmissionRows, setUpdatedSubmissionRows] = useState<Array<Submission>>([]);
  const [errors, setErrors] = useState<BulkCreateError[]>([]);

  const { header, uploadFileButton, exampleFile, successMessage, submissionsLink, errorsTitle, errorMessage } =
    messages.admin.submissionBulkUploadDialog;

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    const response = await bulkCreateSubmissions.mutateAsync({
      data: {
        file: selectedFile!,
      },
    });
    if (response) {
      setUpdatedSubmissionRows(response.created);

      setErrors(response.errors);
    }
    setIsSubmitting(false);
    setSelectedFile(null);
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedSubmissionRows([]);
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const closeDialog = () => {
    setIsSubmitting(false);
    setSelectedFile(null);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      aria-labelledby="submissions-bulk-upload"
      fullScreen
      sx={{ height: 1 }}
      muiPaperProps={{ sx: { bgcolor: 'common.white' } }}
    >
      <DialogHeader title={header} id="submissionsBulkUpload" onClose={closeDialog} />

      <DialogContent sx={{ bgcolor: 'grey.100', height: 1 }}>
        <Typography variant="h5">{team.name}</Typography>
        <form onSubmit={onSubmit}>
          <Stack mt={3} direction="column" gap={5}>
            <input type="file" onChange={handleFileSelect} />
            <Link
              href="https://docs.google.com/spreadsheets/d/1lDaqIJFsAZFcqicn3YMCtGZUafcijpKIAubFl5dyKss/edit?usp=sharing"
              target="_blank"
            >
              {exampleFile}
            </Link>
            <Button loading={isSubmitting} onClick={onSubmit} disabled={!selectedFile} variant="contained">
              {uploadFileButton}
            </Button>
          </Stack>
        </form>
        <Stack mt={3} gap={1}>
          <Typography color="green.400" variant="subtitle1">
            {successMessage(updatedSubmissionRows.length)}
          </Typography>

          <Typography color="red" variant="subtitle1">{`${errors.length} submissions failed due to errors`}</Typography>
          <InternalLink to={adminRoutes.SUBMISSION}>{submissionsLink}</InternalLink>
          {errors.length > 0 && (
            <>
              <Typography variant="h5" pt={5}>
                {errorsTitle}
              </Typography>
              <Stack mt={3} gap={1}>
                {errors.map((error) => (
                  <Typography key={error.rowNumber} variant="body2">
                    {errorMessage(error)}
                  </Typography>
                ))}
              </Stack>
            </>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
