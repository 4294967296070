import styled from '@emotion/styled';
import { DataGrid, DataGridProps, gridClasses } from '@common-components';

export const StyledDataGrid = styled(DataGrid)<DataGridProps>(({ theme }: any) => ({
  border: '1px solid transparent',

  [`& .${gridClasses.cell}`]: {
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    whiteSpace: 'normal',
  },

  [`& .${gridClasses.cell}:focus-within, & .${gridClasses.cell}]:focus`]: {
    outline: 'none !important',
  },
  [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.columnHeader}:focus`]: {
    outline: 'none !important',
  },
  [`& .${gridClasses.columnSeparator}`]: {
    visibility: 'hidden',
  },
  [`& .${gridClasses.columnHeaderTitleContainer}`]: {
    padding: 0,
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    ...theme.typography.captionBold,
    padding: 0,
    letterSpacing: theme.typography.caption.letterSpacing,
  },

  // cant find renderingZone prop in DataGridProps
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important',
  },

  [`& .${gridClasses.row}`]: {
    maxHeight: 'none !important',
  },
  [`& .${gridClasses.row}:hover`]: {
    maxHeight: 'none !important',
    backgroundColor: theme.palette.common.white,
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.white,
    },
  },

  [`& .${gridClasses.columnHeader} ,& .${gridClasses.cell}`]: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}));

export default StyledDataGrid;
