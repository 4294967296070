import { useState } from 'react';
import { createSearchParams, ParamKeyValuePair } from 'react-router-dom-latest';
import { Box, LoaderOverlay } from '@common-components';
import { SubmissionMethod } from 'enums';
import { ActivityLog, ExtendedMarketRequest, SubmissionMarket } from 'types';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import MarketRequestItem from 'broker/pages/SubmissionWorkspacePage/components/common/MarketRequestItem';
import { BulkActionsMode } from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/types';
import { SelectedMarketRequests } from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/useSelectionMarketRequests';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';

interface MarketsListProps {
  marketRequests: ExtendedMarketRequest[];
  showSelectionCheck?: boolean;
  multipleSelection?: boolean;
  selectedMarketRequests: SelectedMarketRequests;
  onMarketRequestSelected: (marketRequestId: string) => void;
  searchWord?: string;
  activities: ActivityLog[];
  selectedSubmitMethod?: SubmissionMethod;
  bulkActionMode?: BulkActionsMode;
}

export default function MarketRequestsList({
  marketRequests,
  showSelectionCheck = true,
  multipleSelection = true,
  selectedMarketRequests,
  onMarketRequestSelected,
  searchWord,
  activities,
  selectedSubmitMethod,
  bulkActionMode,
}: MarketsListProps) {
  const { singleMarketUrl } = useWorkspaceUrls();
  const navigate = useNavigate();
  const [isSubmittingMarketRequest, setIsSubmittingMarketRequest] = useState(false);
  const handleMarketClicked = (market: SubmissionMarket) => {
    const searchParams: ParamKeyValuePair[] = [['id', market.id]];
    navigate(
      {
        pathname: singleMarketUrl,
        search: `${createSearchParams(searchParams)}`,
      },
      { routeKey: RouteKey.SingleMarket, state: undefined },
    );
  };

  return (
    <>
      {isSubmittingMarketRequest && <LoaderOverlay />}
      <Box>
        {marketRequests.map((marketRequest) => (
          <MarketRequestItem
            isSelected={!!selectedMarketRequests[marketRequest.id]?.selected}
            onSelected={onMarketRequestSelected}
            showSelectionCheck={showSelectionCheck}
            multipleSelection={multipleSelection}
            key={marketRequest.id}
            marketRequest={marketRequest}
            activities={activities.filter((a) => a.submissionMarketId === marketRequest.submissionMarketId)}
            mainAction={handleMarketClicked}
            searchWord={searchWord}
            selectedSubmitMethod={selectedSubmitMethod}
            setIsSubmittingMarketRequest={setIsSubmittingMarketRequest}
            bulkActionMode={bulkActionMode}
          />
        ))}
      </Box>
    </>
  );
}
