import { HeraldClient } from 'clients';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
import { useImperativeQuery } from './query/useQuery';

// We use react-query's cache to store the Herald token for us, and to automatically refresh it before it expires.
// Client tokens are valid for 24 hours, to be on the safe side we will refresh the token every hour.
// This behavior can be customized in the future as we move forward with the implementation.
const GET_TOKEN_QUERY_OPTIONS = {
  staleTime: 60 * 60 * 1000, // 1 hour
  cacheTime: 61 * 60 * 1000, // 1 hour and 1 minute
};

export function useGetHeraldAccessToken() {
  const heraldClient = useClient(HeraldClient);

  // This uses `queryClient.fetchQuery` which will get the token from cache if it's not stale,
  // otherwise it will call the queryFn to refresh it
  const getOrCreateAccessToken = useImperativeQuery({
    queryFn: async () => heraldClient.createAccessToken(),
    queryKey: QueryKeys.HeraldAccessToken,
    queryOptions: GET_TOKEN_QUERY_OPTIONS,
  });

  return {
    getOrCreateAccessToken,
  };
}
