import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import Content from './Content';

export default function EmailReplyInnerPage() {
  return (
    <WithRouteLocationState routeKey={RouteKey.EmailReply} isStateRequired>
      {(locationState) => <Content locationState={locationState} />}
    </WithRouteLocationState>
  );
}
