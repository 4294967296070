import { DropResult } from '@hello-pangea/dnd';
import { BoxItem } from 'box-ui-elements/es';
import { isEmpty } from 'lodash';
import { Box, Button, Stack, Typography } from '@common-components';
import { AttachFile } from '@icons';
import { InformationTooltipType } from 'contexts/information-tooltip/types';
import { FormMode, QuoteFileIdType } from 'enums';
import { useInformationTooltip } from 'hooks';
import { messages } from 'i18n';
import { activeTabUrlParamValues, BrokerUrlParams, QuoteFormRoutes } from 'broker/broker-routes';
import { SelectedFilesList } from 'broker/components/common/SelectedFilesList';
import { useUpdateQueryParam } from 'broker/hooks';
import useSetFilesExplorerViewMode from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/components/useSetFilesExplorerViewMode';
import { TOOLBOX_TABS } from 'broker/pages/SubmissionWorkspacePage/types';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import { quoteFilesSectionConfig } from './quote-files-config';

interface QuoteFilesProps {
  fileType: QuoteFileIdType;
  mode: FormMode;
  currentStep: QuoteFormRoutes;
  onContinueToDetails?: () => void;
  isScanning?: boolean;
  showAddMore?: boolean;
  showFilesCounter?: boolean;
  submitDisabled?: boolean;
}

export const QuoteFiles = ({
  fileType,
  currentStep,
  onContinueToDetails,
  isScanning,
  mode,
  showFilesCounter = false,
  submitDisabled,
}: QuoteFilesProps) => {
  const {
    filesExplorer: { selectedFiles },
  } = useUiStoreState();
  const { setFilesExplorer } = useUiStoreActions();
  useSetFilesExplorerViewMode(false, mode);

  const onRemoveFile = (file: BoxItem) => {
    const remainingFiles = selectedFiles.filter((fileItem) => fileItem.id !== file.id);

    setFilesExplorer({ selectedFiles: remainingFiles });
  };

  const isMarketQuoteViewMode = fileType === QuoteFileIdType.MarketQuote && mode === FormMode.view;

  const onChangeFilesOrder = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const [reorderedFile] = selectedFiles.splice(result.source.index, 1);
    selectedFiles.splice(result.destination.index, 0, reorderedFile);

    setFilesExplorer({ selectedFiles: [...selectedFiles], isSelectedFilesDirty: true });
  };

  const { showInformationTooltip } = useInformationTooltip(InformationTooltipType.QuoteFiles);
  const updateQueryParam = useUpdateQueryParam();

  const handleClickAttachments = () => {
    updateQueryParam({
      addParams: [{ queryParam: BrokerUrlParams.ACTIVE_TAB, value: TOOLBOX_TABS.FILES }],
      removeParams: [BrokerUrlParams.SELECTED_FILE, BrokerUrlParams.SELECTED_EMAIL],
    });
    showInformationTooltip();
  };

  const handleClickAttachment = (file: BoxItem) => {
    updateQueryParam({
      addParams: [
        { queryParam: BrokerUrlParams.ACTIVE_TAB, value: activeTabUrlParamValues.FILES },
        {
          queryParam: BrokerUrlParams.SELECTED_FILE,
          value: file.id,
        },
      ],
      removeParams: [BrokerUrlParams.SELECTED_FILE, BrokerUrlParams.SELECTED_EMAIL],
    });
  };

  if (isEmpty(selectedFiles)) {
    if (isMarketQuoteViewMode) {
      return null;
    }
    return (
      <Box sx={{ backgroundColor: 'blue.50', mb: 3 }}>
        <Button startIcon={<AttachFile />} onClick={handleClickAttachments} variant="outlined" size="large" fullWidth>
          {quoteFilesSectionConfig[fileType].attachFileButton}
        </Button>
      </Box>
    );
  }

  return (
    <Stack gap={2}>
      <SelectedFilesList
        onFileClicked={handleClickAttachment}
        columns={1}
        disableRemove={isScanning || mode === FormMode.view}
        selectedFiles={selectedFiles}
        onRemoveFile={onRemoveFile}
        onDragCallback={onChangeFilesOrder}
        showFilesCounter={showFilesCounter}
      />
      {!isMarketQuoteViewMode && (
        <Stack direction="row" gap={2}>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="captionBold">{selectedFiles.length + 1}.</Typography>
            <Button
              variant="outlined"
              disabled={isScanning}
              startIcon={<AttachFile />}
              onClick={handleClickAttachments}
            >
              {quoteFilesSectionConfig[fileType].attachFileButton}
            </Button>
          </Stack>
        </Stack>
      )}
      {!isScanning && currentStep === QuoteFormRoutes.Upload && !isEmpty(selectedFiles) && (
        <Stack alignItems="center">
          <Button
            disabled={submitDisabled}
            onClick={() => {
              onContinueToDetails?.();
            }}
            variant="contained"
          >
            {messages.buttons.continue}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
