import { BoxItem } from 'box-ui-elements/es';
import { BoxTemplateLabels } from 'enums';
import { useMutateBoxItems } from 'hooks/api/box';
import { logger } from 'utils';

const ATTACHMENT_ARCHIVES_FOLDER = 'AttachmentArchives';

interface UseArchiveProps {
  boxFolderId: string;
}

export default function useArchive({ boxFolderId }: UseArchiveProps) {
  const { createFolder, uploadFile, createShareLink, createZip, addFileMetadata } = useMutateBoxItems();

  const createArchive = async (attachedFiles: BoxItem[], archiveName: string) => {
    try {
      const archiveDownloadUrl = await createZip.mutateAsync({ attachedFiles });

      const archiveResponse = await fetch(archiveDownloadUrl!);

      const archiveBlob = await archiveResponse.blob();

      const archivesFolderId = await createFolder.mutateAsync({
        parentFolderId: boxFolderId,
        folderName: ATTACHMENT_ARCHIVES_FOLDER,
      });

      const archiveFile = await uploadFile.mutateAsync({
        parentFolderId: archivesFolderId!,
        fileName: `${archiveName}.zip`,
        file: archiveBlob,
        retryWithDate: true,
      });

      if (archiveFile) {
        await addFileMetadata.mutateAsync({
          fileId: archiveFile.id,
          metadata: { [BoxTemplateLabels.IsHidden]: 'True' },
        });
      }

      return await createShareLink.mutateAsync({ fileId: archiveFile?.id! });
    } catch (error) {
      logger.log('error', error as Error);
      return null;
    }
  };

  return createArchive;
}
