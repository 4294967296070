import { BoxItem } from 'box-ui-elements/es';
import { ElementType } from 'react';
import { Checkbox, IconButton, NewMenu, Stack, SvgIcon, svgIconClasses, Typography } from '@common-components';
import { Close as CloseIcon, DeleteOutline as DeleteIcon, FileDownload as DownloadIcon, MoreVert } from '@icons';
import { BoxTemplateLabels } from 'enums';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { MenuItemProps } from 'components/menu';
import FileLabelsMenu from 'broker/components/FilesExplorer/components/Content/FilesListSection/FileCard/FileLabelsMenu';
import { ExtendedBoxItem, FilesExplorerProps, GetFileSecondaryActions } from 'broker/components/FilesExplorer/types';

export interface ContainedFileViewerModalHeaderProps
  extends Pick<FilesExplorerProps, 'selectionMode' | 'onFileSelected' | 'selectedFiles' | 'labelsMetadata'> {
  file: BoxItem;
  onDeleteClicked: (modalProps: unknown) => void;
  onDownloadClicked: (item: BoxItem) => void;
  readonly?: boolean;
  getFileSecondaryActions?: GetFileSecondaryActions;
  title: string;
  icon?: ElementType;
  onClose: () => void;
  options?: MenuItemProps[];
  hideHeaderMenuActions?: boolean;
  onLabelUpdate?: (item: ExtendedBoxItem, labelFieldName: BoxTemplateLabels, fileType?: string) => Promise<void>;
  viewOnlyMode?: boolean;
}

export default function ContainedFileViewerModalHeader({
  title,
  icon,
  onClose,
  file,
  onDeleteClicked,
  onDownloadClicked,
  readonly,
  getFileSecondaryActions,
  hideHeaderMenuActions,
  selectionMode,
  onFileSelected,
  selectedFiles,
  labelsMetadata,
  onLabelUpdate,
  viewOnlyMode,
}: ContainedFileViewerModalHeaderProps) {
  const fileSecondaryOptions = getFileSecondaryActions?.(file) || [];
  const optionsMenuState = useOptionsMenu();

  return (
    <Stack alignItems="center" overflow="hidden">
      {!hideHeaderMenuActions && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            height={62}
            minHeight={62}
            alignItems="center"
            width={1}
            px={3}
            pt={3}
          >
            <IconButton
              icon={CloseIcon}
              color="secondary"
              variant="contained"
              size="small"
              aria-label="Close Dialog"
              onClick={onClose}
            />
            <Stack direction="row">
              {!readonly && (
                <IconButton
                  icon={DeleteIcon}
                  size="small"
                  variant="text"
                  color="secondary"
                  tooltipContent={messages.fileExplorer.iconTooltips.deleteFile}
                  onClick={() => onDeleteClicked({})}
                />
              )}
              <IconButton
                icon={DownloadIcon}
                size="small"
                variant="text"
                color="secondary"
                tooltipContent={messages.fileExplorer.iconTooltips.downloadFile}
                onClick={() => onDownloadClicked(file)}
                edge="end"
              />
              {fileSecondaryOptions.length ? (
                <>
                  <IconButton
                    icon={MoreVert}
                    onClick={optionsMenuState.openMenu}
                    size="small"
                    variant="text"
                    color="secondary"
                    edge="end"
                  />
                  <NewMenu
                    optionsMenuState={optionsMenuState}
                    menuItems={[
                      {
                        key: 'edit',
                        items: fileSecondaryOptions,
                      },
                    ]}
                  />
                </>
              ) : null}
            </Stack>
          </Stack>
          <Stack
            height={84}
            px={3}
            minHeight={84}
            width={1}
            direction="row"
            gap={1}
            overflow="hidden"
            alignItems="center"
          >
            {selectionMode && (
              <Stack pl={0.5} width={28}>
                <Checkbox
                  disabled={viewOnlyMode}
                  size="small"
                  disableRipple
                  edge="start"
                  checked={selectedFiles && !!selectedFiles.find((currSelectedFile) => currSelectedFile.id === file.id)}
                  onChange={() => onFileSelected?.(file)}
                />
              </Stack>
            )}
            <Stack gap={0.5} overflow="hidden">
              <Stack gap={0.5} direction="row" maxWidth={1}>
                {onLabelUpdate &&
                  labelsMetadata?.map((labelMetadata) => (
                    <FileLabelsMenu
                      key={labelMetadata.metadataFieldName}
                      onLabelUpdate={onLabelUpdate}
                      file={file}
                      readonly={readonly}
                      isOpenOverride={false}
                      labelMetadata={labelMetadata}
                      addLabelText={labelMetadata.addLabelText}
                      icon={labelMetadata.icon}
                      showTooltip
                    />
                  ))}
              </Stack>
              <Stack direction="row" height={1} alignItems="center" gap={2} maxWidth={1}>
                {icon && (
                  <SvgIcon component={icon} viewBox="0 0 28 24" sx={{ [`&.${svgIconClasses.root}`]: { height: 30 } }} />
                )}

                <Typography title={title} noWrap color="inherit" variant="subtitle1">
                  {title}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
}
