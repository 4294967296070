import { compact, isString, orderBy } from 'lodash';
import moment from 'moment/moment';
import { FileDownloadOutlined as FileDownloadIcon } from '@icons';
import { UserRole } from 'enums';
import { useCurrentUser } from 'hooks';
import { messages } from 'i18n';
import { ActivityLog } from 'types';
import { downloadBlob, getCoverageLinesText, getTimeDisplay, userFullName } from 'utils';
import { submissionActivityTypeConfig } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/SubmissionActivity/submission-activity-type-config';
import { SubmissionLevelActivities } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/SubmissionActivity/types';
import { filterYesterdayActivities } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/SubmissionActivity/utils';
import { useGetSubmissionCreationEmail } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { generateBlobPdf } from './pdfBuilder';

export function useShareActivityLog() {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { submissionCreationEmail } = useGetSubmissionCreationEmail();
  const { me } = useCurrentUser();

  const handleDownloadClick = (activitiesToDownload: ActivityLog[], marketName?: string) => {
    const insuredName = partialSubmission?.insuredName || submissionCreationEmail?.subject || '';
    const title = compact([insuredName, marketName]).join(' - ');

    const blobPdf = generateBlobPdf({
      title,
      subtitle: partialSubmission?.coverageLines ? getCoverageLinesText(partialSubmission.coverageLines) : '',
      rows: orderBy(activitiesToDownload, 'createdAt', 'desc').map((activity) => {
        const displayTextConfig =
          submissionActivityTypeConfig[activity.activityType as SubmissionLevelActivities]?.displayText;
        return {
          date: moment(activity.createdAt).format('MMM D, YYYY'),
          time: getTimeDisplay(activity.createdAt),
          activity: isString(displayTextConfig)
            ? displayTextConfig
            : displayTextConfig?.(activity) ?? activity.activityType,
          actor: activity.actor ? userFullName(activity.actor) : '',
        };
      }),
    });

    downloadBlob(
      blobPdf,
      `${title.replace(/[\\/:*?"<>|]/g, '')}-${messages.activityLogTable.filePrefixName}${moment().format(
        'DD-MM-YYYY',
      )}.pdf`,
    );
  };

  const getShareOptions = (activities: ActivityLog[], marketName?: string) =>
    compact([
      {
        label: messages.submissionWorkspace.menus.shareReport.options.download,
        startAdornment: FileDownloadIcon,
        onClick: () => handleDownloadClick(activities, marketName),
      },
      me?.role === UserRole.BackOffice && {
        label: messages.submissionWorkspace.menus.shareReport.downloadYesterdayActivity,
        startAdornment: FileDownloadIcon,
        onClick: () => handleDownloadClick(filterYesterdayActivities(activities), marketName),
      },
    ]);

  return { getShareOptions };
}
