import { IconButton, NewMenu } from '@common-components';
import { MoreVert } from '@icons';
import { SubmissionStatus, SubmissionTerminationStatus } from 'enums';
import { useConditionallyRenderedModal, useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { InboundEmailMessageSearchItem, PartialSubmission } from 'types';
import TerminateSubmissionDialog from 'broker/dialogs/TerminateSubmissionDialog';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useDownloadMenu } from './useDownloadMenu';

type TerminationDialogProps = {
  targetStatus: SubmissionTerminationStatus;
};

export interface SubmissionHeaderMenuProps {
  submission: PartialSubmission;
  submissionCreationEmail?: InboundEmailMessageSearchItem;
}

export function SubmissionHeaderMenu({ submission, submissionCreationEmail }: SubmissionHeaderMenuProps) {
  const optionsMenuState = useOptionsMenu();
  const { downloadMenuItems, isDownloading } = useDownloadMenu({ submission, submissionCreationEmail });
  const { reFetchWorkspace } = useSubmissionsWorkspace();

  const {
    openModal: openTerminationDialog,
    closeModal: closeTerminationDialog,
    modalState: terminationDialogState,
  } = useConditionallyRenderedModal<TerminationDialogProps>({});

  const onArchiveClick = () => {
    openTerminationDialog({ targetStatus: SubmissionStatus.Archived });
  };

  const onCloseSubmissionClick = () => {
    openTerminationDialog({ targetStatus: SubmissionStatus.Closed });
  };

  return (
    <>
      <IconButton
        icon={MoreVert}
        onClick={optionsMenuState.openMenu}
        color="secondary"
        variant="text"
        loading={isDownloading}
      />
      <NewMenu
        optionsMenuState={optionsMenuState}
        subMenuPlacement="left"
        menuItems={[
          {
            key: 'submission-header-menu',
            items: [
              {
                label: messages.submissionWorkspace.termination.closeMenuLabel,
                disabled: submission.status === SubmissionStatus.Closed,
                onClick: onCloseSubmissionClick,
              },

              {
                label: messages.submissionWorkspace.termination.archiveMenuLabel,
                disabled: submission.status === SubmissionStatus.Archived,
                onClick: onArchiveClick,
              },
              {
                label: messages.submissionWorkspace.downloadFilesAndEmails.menuLabel,
                subMenu: downloadMenuItems,
              },
            ],
          },
        ]}
      />
      {terminationDialogState.shouldRender && (
        <TerminateSubmissionDialog
          showTerminationConfirmation={terminationDialogState.isOpen}
          closeTerminationConfirmation={closeTerminationDialog}
          submission={submission}
          targetStatus={terminationDialogState.modalProps!.targetStatus}
          onUpdateSuccess={reFetchWorkspace}
        />
      )}
    </>
  );
}
