import { messages } from 'i18n';
import Chip from './Chip';
import { Stack } from './mui-index';

interface FormFieldRequiredLabelProps {
  shiftLeft?: number;
  shiftTop?: number;
}
export function FormFieldRequiredLabel({ shiftLeft = 0, shiftTop = 0 }: FormFieldRequiredLabelProps) {
  return (
    <Stack
      direction="row"
      p={2}
      alignItems="center"
      justifyContent="space-between"
      position="absolute"
      right={shiftLeft}
      top={shiftTop}
      bottom={0}
      borderRadius={1}
      sx={{
        pointerEvents: 'none',
      }}
    >
      <Chip label={messages.general.required} size="small" color="warning.light" textColor="grey.600" />
    </Stack>
  );
}
