import { useMatchNavigation } from 'hooks';
import RoutedDialog from 'broker/components/RoutedDialog';
import { RouteKey } from 'broker/enums';
import ModalContent from './ModalContent';
import { StepsRoutes } from './types';

export default function SubmissionAutonomousCreationModal() {
  const activeStep = useMatchNavigation(StepsRoutes, StepsRoutes.Basic);

  return (
    <RoutedDialog
      routeKey={RouteKey.SubmissionAutonomousCreation}
      isLocationStateRequired
      ariaLabelId="submission-autonomous-creation-modal"
      fullScreen={activeStep === StepsRoutes.Advanced}
    >
      {(props) => <ModalContent {...props} activeStep={activeStep} />}
    </RoutedDialog>
  );
}
