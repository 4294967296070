import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import { serverEventEmitter } from './utils';

export const WebsocketSubscriberIdSessionStorageKey = 'WebsocketSubscriberId';

export const useServerStreaming = () => {
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    const generatedGuid = uuidv4();
    sessionStorage.setItem(WebsocketSubscriberIdSessionStorageKey, generatedGuid);

    const socket = io(process.env.REACT_APP_BACKEND_BASE_URL!, {
      query: { subscriberId: generatedGuid },
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 10000, // This is the max delay between reconnection attempts since there's a backoff strategy after unsuccessful reconnection
    });

    socket.on('server-push', async (data: any) => {
      serverEventEmitter.emit(data);
    });

    // Debounced function to delay setting isOnline to false
    const debouncedSetOffline = debounce(() => {
      setIsOnline(false);
    }, 10000); // 10 seconds delay

    // WebSocket connection established
    socket.on('connect', () => {
      // Cancel any pending debounced action
      debouncedSetOffline.cancel();
      setIsOnline(true); // Set status to online
    });

    // WebSocket connection lost
    socket.on('disconnect', () => {
      // Debounce the offline status update
      debouncedSetOffline();
    });

    const cleanup = () => {
      localStorage.removeItem(WebsocketSubscriberIdSessionStorageKey);
      socket.disconnect();
      debouncedSetOffline.cancel(); // Cleanup the debounced function
    };

    window.addEventListener('beforeunload', cleanup);

    return () => {
      cleanup();
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);

  return isOnline;
};
