import { BoxItem, SharedLink } from 'box-ui-elements/es';
import { useState } from 'react';
import { Submission, User } from 'types';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';
import { EmailStatus } from 'broker/components/Emails/SendingMessagesScreen';
import { Recipient } from 'broker/components/Emails/types';
import { useSendEmail } from 'broker/components/Emails/useSendEmail';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { appendAttachments } from 'broker/utils/email-utils/email-resolver-utils/email-resolving-utils';
import { revertSmartObjectToVariable } from 'broker/utils/email-utils/SmartVariable/utils';
import { TemplateVariablesResolver } from './template-variables-resolver';

export const useProposalActions = (
  submission: Submission,
  recipientGroup: RecipientGroup,
  proposalContent: string,
  proposalSubject: string,
  attachedFiles: BoxItem[],
  templateVariablesResolver: TemplateVariablesResolver,
  setProposalSendingStatus: (status: EmailStatus) => void,
  selectedTeammates: User[],
) => {
  const { updateSubmission } = useSubmissionsWorkspace();

  const [isSaving, setIsSaving] = useState(false);

  const saveProposal = async (newRecipient?: Recipient | null) => {
    setIsSaving(true);

    const newProposalRecipients = recipientGroup.recipients.map((recipient) => recipient.id);
    const newSubmissionRecipients = submission.user?.id ? [submission.user.id] : [];

    if (newRecipient) {
      newProposalRecipients.push(newRecipient.id);
      newSubmissionRecipients.push(newRecipient.id);
    }

    await updateSubmission(submission!.id, {
      proposal: {
        body: revertSmartObjectToVariable(proposalContent),
        subject: proposalSubject,
        lastSaved: new Date(),
        contactIds: newProposalRecipients,
        attachedFiles: attachedFiles.map((file) => file.id),
      },
    });

    setIsSaving(false);
  };

  const resolveMessage = (attachmentsArchive: SharedLink | null, sendAttachmentsAsLinks: boolean) => {
    let resolvedContent = templateVariablesResolver.resolveContentVariables(proposalContent, true);
    if (sendAttachmentsAsLinks) {
      resolvedContent = appendAttachments(resolvedContent, attachedFiles, attachmentsArchive);
    }

    const resolvedSubject = templateVariablesResolver.resolveSubjectVariables(proposalSubject);
    return { subject: resolvedSubject, body: resolvedContent };
  };

  const { sendEmail, resolvedMessages } = useSendEmail(
    proposalSubject,
    attachedFiles,
    { [recipientGroup.id]: recipientGroup },
    resolveMessage,
    setProposalSendingStatus,
    templateVariablesResolver.templateContext,
    selectedTeammates,
  );

  return { saveProposal, sendProposal: sendEmail, isSaving, resolvedMessages };
};
