import { ReactElement } from 'react';
import { useCurrentUser } from 'hooks';
import { Features, isFeatureEnabled } from 'utils/features-config';

interface FeatureWrapProps {
  children: ReactElement | null;
  feature: Features;
}

export const FeatureGate = ({ children, feature }: FeatureWrapProps) => {
  const { me } = useCurrentUser();
  if (!me) {
    return null;
  }
  return isFeatureEnabled(feature, me) ? children : null;
};
