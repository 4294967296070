import { StepIconProps } from '@common-components';

export type FormStepIconProps = StepIconProps & {
  label: string;
  subLabel?: string;
  isCompactStepper: boolean;
  hasRequired?: boolean;
};

export enum MainAreaDimensionsState {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
}
