import { LoaderOverlay } from '@common-components';
import EmailEditor from 'broker/components/EmailEditor';
import EmailEditorFooter from 'broker/components/EmailEditor/EmailEditorFooter';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import SubmissionEmailEditor from 'broker/pages/SubmissionWorkspacePage/components/SubmissionEmailEditor';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { EmailEditorPageLocationState } from './types';
import useGetEmailEditorProps from './useGetEmailEditorProps';

interface ContentProps {
  locationState: EmailEditorPageLocationState;
}

export default function Content({ locationState }: ContentProps) {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { title, subtitle, isLoading, ...emailEditorProps } = useGetEmailEditorProps(
    locationState,
    partialSubmission || undefined,
  );

  if (isLoading) {
    return <LoaderOverlay />;
  }
  return (
    <SubmissionEmailEditor emailEditorProps={emailEditorProps}>
      <InnerPageLayout
        className="cap-email-editor-page"
        title={title}
        subTitle={subtitle}
        footer={(innerPageProps) => <EmailEditorFooter {...innerPageProps} />}
        sx={{ p: 0 }}
      >
        {() => <EmailEditor />}
      </InnerPageLayout>
    </SubmissionEmailEditor>
  );
}
