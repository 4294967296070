import { MENTION_PREFIX } from '@common/email-variables';
import { useRef } from 'react';
import { useHover } from 'usehooks-ts';
import { Box, Card, CardContent, Fade, Icon, Stack, svgIconClasses, Typography } from '@common-components';
import { Check, ContentCopy, EditOutlined } from '@icons';
import { useDraggableElement } from 'hooks';
import { truncateMultiLineText } from 'utils';
import { useNavigate } from 'broker/hooks';
import { SmartObjectProps } from 'broker/utils/email-utils/SmartVariable/smart-objects-config';
import images from './images';

export const SmartReportSnippet = ({
  isInProposal,
  smartObjectConfig,
  onCopyClicked,
}: {
  isInProposal: boolean;
  smartObjectConfig: SmartObjectProps;
  onCopyClicked: (smartVariable: string) => void;
}) => {
  const navigate = useNavigate();

  const ref = useRef(null);
  const isHovering = useHover(ref);

  const handleEditClick = async () => {
    navigate(smartObjectConfig.navigationRoute, smartObjectConfig.navigationOptions());
  };

  function handleCopyClicked() {
    return onCopyClicked(smartObjectConfig.key);
  }

  const { elementProps, isDragged } = useDraggableElement(`${MENTION_PREFIX}${smartObjectConfig.key}`);

  return (
    <Card
      elevation={isHovering ? 4 : 1}
      sx={{
        border: (theme) =>
          isInProposal ? `1px solid ${theme.palette.primary.main}` : `1px dashed ${theme.palette.purple[500]}`,
        color: 'text.secondary',
        position: 'relative',
        cursor: 'grab',
        '&:hover': { border: 1, borderColor: 'primary.main', color: 'text.primary' },
      }}
      ref={ref}
      {...elementProps}
    >
      <CardContent sx={{ padding: 0, '&:last-child': { padding: 0 } }}>
        {isInProposal && (
          <Box
            display="flex"
            justifyContent="center"
            position="absolute"
            width={20}
            height={20}
            borderRadius={5}
            right={8}
            top={8}
            bgcolor="primary.main"
            color="common.white"
            alignItems="center"
          >
            <Icon sx={{ [`&.${svgIconClasses.root}`]: { width: 12, height: 12 } }} component={Check} />
          </Box>
        )}
        <Box
          component="img"
          src={images[smartObjectConfig.key]}
          maxWidth="100%"
          draggable={false}
          boxShadow="0px 1px 2px 0px rgba(3, 28, 23, 0.10), 0px 1px 1px 0px rgba(3, 28, 23, 0.06)"
        />
        <Stack direction="row" pt={1} pb={1.5} px={2} borderColor="blueGrey.200" alignItems="center" gap={1}>
          <Stack flex={1}>
            <Typography variant="body2Bold">{smartObjectConfig.label}</Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ ...truncateMultiLineText(1) }}
              title={smartObjectConfig.description}
            >
              {smartObjectConfig.description}
            </Typography>
          </Stack>
          <Fade in={isHovering}>
            <EditOutlined fontSize="small" onClick={handleEditClick} sx={{ cursor: 'pointer' }} />
          </Fade>
          <Fade in={isHovering}>
            <ContentCopy fontSize="small" onClick={handleCopyClicked} sx={{ cursor: 'pointer' }} />
          </Fade>
        </Stack>
        {isDragged && <Box position="absolute" top={0} left={0} width="100%" height="100%" bgcolor="grey.200" />}
      </CardContent>
    </Card>
  );
};
