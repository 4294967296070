import { get } from 'lodash';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
  Stack,
} from '@common-components';
import { messages } from 'i18n';
import { FormControlHelperTextMarginBottom, FormControlPaddingBottom } from 'themes';
import InputLabel from './InputLabel';
import { FormHelperTextStyles, SelectMenuPropsStyles, SelectStyles } from './styles';

interface FormSelectProps extends SelectProps {
  name: string;
  label?: string;
  id: string;
  isLoading?: boolean;
  placeholder?: string;
  validateOnChange?: boolean;
  isDisabled?: boolean;
  optional?: boolean;
  onChangeCallback?: (value: any) => void;
}

const FormSelect = memo(
  ({
    name,
    label = 'Select',
    id,
    children,
    fullWidth = true,
    defaultValue,
    isLoading,
    placeholder = messages.general.selectPlaceholder,
    optional = false,
    isDisabled = false,
    validateOnChange = false,
    onChangeCallback,
    size = 'small',
    ...props
  }: FormSelectProps) => {
    const labelId = `${name}-label`;
    const {
      control,
      formState: { errors },
      trigger,
    } = useFormContext();

    const errorMessage = get(errors, name)?.message;

    return (
      <FormControl
        sx={{
          pb: FormControlPaddingBottom,
          mb: errorMessage ? FormControlHelperTextMarginBottom : 0,
          height: 1,
          justifyContent: 'space-between',
        }}
        fullWidth={fullWidth}
      >
        <InputLabel id={labelId} error={!!errorMessage} label={label} optional={optional} htmlFor={name} />
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || ''}
          render={({ field: { onChange, ...fieldProps } }) => (
            <Select
              size={size}
              displayEmpty
              sx={SelectStyles}
              disabled={isDisabled}
              labelId={labelId}
              label={label}
              id={id}
              error={!!errorMessage}
              fullWidth={fullWidth}
              onChange={(event) => {
                onChange(event.target.value);
                if (errorMessage || validateOnChange) {
                  trigger(name);
                }
                onChangeCallback?.(event.target.value);
              }}
              MenuProps={SelectMenuPropsStyles(isLoading)}
              {...props}
              {...fieldProps}
            >
              {isLoading ? (
                <MenuItem key="loader" disabled value={messages.general.loading}>
                  <Stack alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
                    <CircularProgress size={40} />
                  </Stack>
                </MenuItem>
              ) : (
                [
                  <MenuItem key={placeholder} disabled value="" sx={{ display: 'none' }}>
                    <span style={{ opacity: '0.5' }}>{placeholder}</span>
                  </MenuItem>,
                  children,
                ]
              )}
            </Select>
          )}
        />
        {errorMessage && (
          <FormHelperText error sx={FormHelperTextStyles}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

export default FormSelect;
