import { isNil } from 'lodash';
import { useState } from 'react';
import { PromptResultFeature, SubmissionEmailLabel, SubmissionStatus } from 'enums';
import {
  useBoolean,
  useCurrentUser,
  useGenerateAcknowledgmentContent,
  useMutatePromptResult,
  useSearchInsuranceProduct,
} from 'hooks';
import { messages } from 'i18n';
import theme from 'themes';
import { InsuranceProduct } from 'types';
import { logger } from 'utils';
import { PostEmailInformation } from 'broker/components/EmailEditor/types';
import { canUserReplyToEmail } from 'broker/components/EmailEditor/utils/can-user-reply-to-email';
import { createRecipientGroupFromSubmissionCustomer } from 'broker/components/Emails/recipient-utils';
import SubmissionEmailEditorWithAI from 'broker/pages/SubmissionWorkspacePage/components/SubmissionEmailEditorWithAI';
import { useGetSubmissionCreationEmail } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import AcknowledgmentEmailInnerPageLayout from './AcknowledgmentEmailInnerPageLayout';
import { AcknowledgmentLocationState } from './types';

export interface AcknowledgmentEmailContentProps {
  locationState?: AcknowledgmentLocationState;
  onProductsSelected: (products: InsuranceProduct[]) => Promise<void>;
}

export default function AcknowledgmentEmailContent({
  locationState,
  onProductsSelected,
}: AcknowledgmentEmailContentProps) {
  const { partialSubmission, updateSubmission } = useSubmissionsWorkspace();
  const [isDirty, { set: setIsDirty }] = useBoolean(true);

  const { items: allInsuranceProducts, isLoading: isLoadingProducts } = useSearchInsuranceProduct();
  const { submissionCreationEmail, isLoading: isLoadingCreationEmail } = useGetSubmissionCreationEmail();
  const { endUser } = useCurrentUser();
  const selectedProductIds = locationState ? locationState.selectedProductIds : [];
  const { generateAcknowledgmentContent } = useGenerateAcknowledgmentContent();
  const [aiGeneratedBodyContent, setAiGeneratedBodyContent] = useState<string | undefined>(undefined);
  const { createPromptResult } = useMutatePromptResult();

  const isLoading = isLoadingProducts || isLoadingCreationEmail || !partialSubmission;

  const aiInputData = {
    submissionId: partialSubmission!.id,
    selectedProductIds,
  };

  const decorateGeneratedContent = (generatedContent: string) =>
    `<span style="font-size: 20px"><span style="color: ${theme.palette.purple[700]}">Thank you for your submission! </span>🎉</span><br><br>Hey @CUSTOMER-FIRST-NAMES,<br><br><div>${generatedContent}</div><br><br><div>@SIGNATURE</div>`;

  const { title } = messages.acknowledgmentFlow;

  const subject = submissionCreationEmail
    ? messages.sentEmailDialog.getReSubject(submissionCreationEmail.subject)
    : `@INSURED-NAME - Acknowledgment email for your submission`;

  const isCurrentUserParticipantOfThread =
    endUser && canUserReplyToEmail(endUser.email, submissionCreationEmail?.sender, submissionCreationEmail?.recipient);

  // If the user isn't a participant in the submission creation email thread (= it was sent to another broker or directly to the submission box)
  // they can't reply directly to that email (nylas will throw).
  // In this case we want `sendEmailMessage` (lambda) to check if the original recipient was the submission box and if so, use the submission box nylas user as sender on behalf
  // of the user, allowing the ack email to be connected to the original thread in the retailer email client.
  // We use the onBehalfOf field to achieve this.
  const shouldUseOnBehalfOf = !!submissionCreationEmail?.externalId && !isCurrentUserParticipantOfThread;

  return (
    <SubmissionEmailEditorWithAI
      isDirty={isDirty}
      contentDecorator={decorateGeneratedContent}
      initialBodyContent={partialSubmission!.draftAckEmailBody}
      isLoading={isLoading}
      title={title}
      aiThread={{
        createThread: () => generateAcknowledgmentContent(aiInputData),
        creationCondition: !!partialSubmission && isNil(partialSubmission.draftAckEmailBody),
        callback: (content) => {
          setAiGeneratedBodyContent(content);
        },
      }}
      emailEditorProps={(bodyContent) => ({
        preSelectedRecipients: [createRecipientGroupFromSubmissionCustomer(partialSubmission!)],
        emailLabel: SubmissionEmailLabel.Acknowledgment,
        repliedThreadBody: bodyContent,
        repliedThreadSubject: subject,
        inReplyToMessageId: submissionCreationEmail?.externalId,
        onBehalfOf: shouldUseOnBehalfOf ? endUser?.id : undefined,
        postEmailsSent: async ({ draftPreview }: PostEmailInformation) => {
          try {
            // Clear the draft ack email body and selected products after sending the email
            await updateSubmission(partialSubmission!.id, {
              status: SubmissionStatus.New,
              draftAckEmailBody: null,
              draftAckSelectedProducts: null,
            });

            if (locationState) {
              const selectedProducts = allInsuranceProducts.filter((insuranceProduct) =>
                locationState.selectedProductIds.includes(insuranceProduct.id),
              );
              // Avoid success toast if no products are selected
              if (selectedProducts.length > 0) {
                await onProductsSelected(selectedProducts);
              }
            }

            if (aiGeneratedBodyContent !== undefined) {
              // run api in the background to create a prompt result (no need to wait for it)
              createPromptResult.mutateAsync({
                data: {
                  result: aiGeneratedBodyContent,
                  input: aiInputData,
                  modifiedResult: draftPreview.body,
                  feature: PromptResultFeature.AcknowledgmentEmail,
                  submissionId: partialSubmission!.id,
                },
              });
            }
          } catch (error) {
            logger.log('error', error as Error);
          }
        },
      })}
    >
      <AcknowledgmentEmailInnerPageLayout setIsDirty={setIsDirty} />
    </SubmissionEmailEditorWithAI>
  );
}
