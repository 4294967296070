import { Chip, LoaderOverlay, NewMenu, Stack } from '@common-components';
import { useOptionsMenu } from 'hooks';
import { useSearchBoxItems } from 'hooks/api/box';

export interface PreviewFilesMenuProps {
  fileId: string;
  collectionFileIds: string[];
  boxFolderId: string;
  selectedFileId: string;
  setSelectedFileId: (fileId: string) => void;
}

export function PreviewFilesMenu({
  fileId,
  collectionFileIds,
  boxFolderId,
  selectedFileId,
  setSelectedFileId,
}: PreviewFilesMenuProps) {
  const optionsMenuState = useOptionsMenu();

  const showDropdown = collectionFileIds && collectionFileIds.length > 1;

  const { items, isLoading: boxItemsLoading } = useSearchBoxItems({
    filter: { chunkSize: 20, folderId: boxFolderId },
  });

  const fileIds = collectionFileIds ? [...collectionFileIds, fileId] : [fileId];
  const fileNamesMap = fileIds.reduce((acc, id) => {
    const file = items.find((item) => item.id === id);
    acc[id] = file?.name ?? '';
    return acc;
  }, {} as Record<string, string>);

  if (boxItemsLoading) {
    return <LoaderOverlay />;
  }

  return (
    <Stack width={1} mb={0.5} sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Chip
        label={fileNamesMap[selectedFileId]}
        size="small"
        color="secondary"
        variant="outlined"
        dropdown={showDropdown}
        onClick={showDropdown ? optionsMenuState.openMenu : undefined}
      />
      <NewMenu
        optionsMenuState={optionsMenuState}
        menuItems={[
          {
            key: 'content-preview-files-menu',
            items: collectionFileIds?.map((item) => ({
              label: fileNamesMap[item],
              onClick: () => setSelectedFileId(item),
              disabled: item === selectedFileId,
            })),
          },
        ]}
      />
    </Stack>
  );
}
