import { useMemo, useState } from 'react';
import { useSearchSubmission } from 'hooks/api/submission';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { getCoverageLinesText } from 'utils';

export default function useSubmissionsFilter(): {
  filteredItems: PartialSubmission[];
  setQuery: (query: string) => void;
  count: number;
  isLoading: boolean;
} {
  const { items, count, isLoading } = useSearchSubmission();
  const [query, setQuery] = useState<string>('');

  const filteredSubmissions = useMemo(
    () =>
      items.filter((submission) => {
        if (query.length < 2) {
          return true;
        }

        const submissionTerms = [
          submission.id,
          submission.team?.name,
          submission.organizationName,
          submission.assignee?.email ?? messages.submissionsView.unassigned,
          submission.insuredName,
          submission.status,
          getCoverageLinesText(submission.coverageLines),
        ].filter(Boolean);

        return submissionTerms.some((term) => term?.toLowerCase().includes(query.toLowerCase()));
      }),
    [items, query],
  );

  return {
    setQuery,
    filteredItems: filteredSubmissions,
    count,
    isLoading,
  };
}
