import { isNull } from 'lodash';
import { LocalStorage } from 'enums';
import { getFileWithSyncMetadata } from 'broker/components/FilesExplorer/utils';
import { ActionTypes, UiStore } from './types';
import { getMarketRequestFiltersList } from './utils';

const uiStoreReducer = (state: UiStore, action: ActionTypes) => {
  const checkShowHistory = (tab: string, submissionId: string) => {
    const localStorageData = localStorage.getItem(LocalStorage.AutoTabIndication);
    if (isNull(localStorageData)) {
      localStorage.setItem(LocalStorage.AutoTabIndication, JSON.stringify({ [submissionId]: [tab] }));
      return false;
    }
    const parsedLocalStorageData = JSON.parse(localStorageData);
    if (parsedLocalStorageData[submissionId]?.includes(tab)) {
      return true;
    }
    if (!parsedLocalStorageData[submissionId]) {
      parsedLocalStorageData[submissionId] = [];
    }
    parsedLocalStorageData[submissionId].push(tab);

    localStorage.setItem(LocalStorage.AutoTabIndication, JSON.stringify(parsedLocalStorageData));
    return false;
  };

  switch (action.type) {
    case 'TOOLBOX_OVERLAY_SET_ACTIVE_ITEM':
      return {
        ...state,
        toolboxOverlay: {
          selectedEmailView: action.payload.selectedEmailView,
        },
      };

    case 'SET_MARKETING_SEARCH':
      return {
        ...state,
        marketingFilters: {
          ...state.marketingFilters,
          search: action.payload.search,
        },
      };

    case 'SET_FILES_EXPLORER':
      return {
        ...state,
        filesExplorer: {
          ...state.filesExplorer,
          ...action.payload,
        },
      };

    case 'SYNC_SELECTED_FILES':
      return {
        ...state,
        filesExplorer: {
          ...state.filesExplorer,
          selectedFiles: state.filesExplorer.selectedFiles.map((file) => {
            const freshBoxItem = action.payload.updatedBoxItems.find((item) => item.id === file.id);
            // Add syncMetadata to on top of the BoxItem
            return freshBoxItem ? getFileWithSyncMetadata(freshBoxItem, action.payload.submissionFileMetadata) : file;
          }),
        },
      };

    case 'SET_MARKETING_SORT_TYPE':
      return {
        ...state,
        marketingFilters: {
          ...state.marketingFilters,
          sortType: action.payload.sortType,
        },
      };

    case 'SET_MARKETING_FILTERS': {
      const filters = getMarketRequestFiltersList(state.marketingFilters.filters);
      return {
        ...state,
        marketingFilters: {
          ...state.marketingFilters,
          filters,
        },
      };
    }

    case 'SET_TOOLBOX_STATE': {
      return {
        ...state,
        toolboxState: {
          ...state.toolboxState,
          ...action.payload,
        },
      };
    }

    case 'SET_SELECTED_PRODUCTS_SUBMIT_API': {
      return {
        ...state,
        selectedProductsSubmitApi: action.payload,
      };
    }

    case 'CLEAR_SELECTED_PRODUCTS_SUBMIT_API': {
      return {
        ...state,
        selectedProductsSubmitApi: [],
      };
    }

    case 'SET_START_MARKETING_INDICATOR': {
      const { submissionId, showStartMarketingIndicator } = action.payload;
      const isShowed = checkShowHistory(LocalStorage.StartMarketingIndicator, submissionId);
      return {
        ...state,
        startMarketingIndicator: {
          ...state.startMarketingIndicator,
          showStartMarketingIndicator: !isShowed && showStartMarketingIndicator,
        },
      };
    }

    case 'SET_SUGGESTED_SUBMISSION_STATUS': {
      return {
        ...state,
        suggestedSubmissionStatus: action.payload.suggestedSubmissionStatus,
      };
    }

    case 'REGISTER_INNER_PAGE': {
      return {
        ...state,
        innerPageState: [...state.innerPageState, action.payload],
      };
    }

    case 'UNREGISTER_INNER_PAGE': {
      return {
        ...state,
        innerPageState: state.innerPageState.filter((pageId) => pageId !== action.payload),
      };
    }

    case 'SET_PASTE_SMART_OBJECT_INDICATOR': {
      return {
        ...state,
        pasteSmartObjectIndicator: action.payload,
      };
    }

    case 'HANDLE_MARKETING_FILTERS_CHANGE': {
      const existingFilters = [...state.marketingFilters.filters];
      const newFilters = existingFilters.map((item) => ({
        ...item,
        checked: item.key === action.payload.key ? action.payload.checked : item.checked,
      }));
      // isAllFilters is true if all filters are checked or unchecked
      const isAllFilters = newFilters.every((item) => item.checked) || newFilters.every((item) => !item.checked);

      return {
        ...state,
        marketingFilters: {
          ...state.marketingFilters,
          filters: newFilters,
          isAllFilters,
        },
      };
    }

    case 'SET_PAGE_IS_ALL_FILTERS': {
      if (action.payload.isAllFilters) {
        const newFilters = state.marketingFilters.filters.map((item) => ({
          ...item,
          checked: false,
        }));
        return {
          ...state,
          marketingFilters: {
            ...state.marketingFilters,
            filters: newFilters,
            isAllFilters: true,
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default uiStoreReducer;
