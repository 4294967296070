import { BoxItem } from 'box-ui-elements/es';
import { PartialSubmission, Proposal } from 'types';

export enum ProposalFlowStep {
  Files = 'Files',
  Email = 'Email',
}

export interface StepProps {
  activeStep: ProposalFlowStep;
  onStepChange(step: ProposalFlowStep, proposalData: UpdateProposalProps): Promise<void>;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  handleSaveProposal: (props: UpdateProposalProps) => Promise<void>;
  submission: PartialSubmission;
  selectedFiles: BoxItem[];
}

export type UpdateProposalProps = Pick<Proposal, 'subject' | 'body' | 'contactIds' | 'attachedFiles'>;
