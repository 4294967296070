import { keyBy, pick } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom-latest';
import {
  ActionDialog,
  Box,
  CircularProgress,
  DialogSelect,
  DialogTextField,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from '@common-components';
import { OrganizationType, UserRole } from 'enums';
import { getUserRole } from 'enums/type-mappers/user-role-config';
import {
  useBulkCreateContacts,
  useBulkCreateSubmissions,
  useMutateUser,
  useSearchOrganization,
  useSearchTeam,
  useToast,
} from 'hooks';
import { messages } from 'i18n';
import { BulkCreateResponse, Contact, Organization, Submission, Team } from 'types';
import { isIntegrationEnabled } from 'utils/email-integration-utils';
import { Editor } from 'components/Editor/Editor';
import ADMIN_ROUTES from 'admin/admin-routes';

enum UserCreateSteps {
  Init,
  Loading,
  UserCreated,
}
export interface UserCreateDialogProps {
  organization?: Organization;
  team?: Team;
  open: boolean;
  onClose: () => void;
}

const {
  loadingMessage,
  userCreatedSuccessfully,
  itemsUploadedSuccessfully,
  uploadFailed,
  errorMessage,
  enterNewUser,
  invalidIntegrationEmailAddress,
} = messages.admin.userCreatedDialog;

function chooseRoles(organization: Organization | null): Record<string, UserRole> {
  switch (organization?.type) {
    case OrganizationType.Capitola:
      return pick(UserRole, [
        UserRole.Admin,
        UserRole.Machine,
        UserRole.BI,
        UserRole.BackOffice,
        UserRole.CapitolaBroker,
      ]);
    case OrganizationType.Retailer:
      return pick(UserRole, ['Retailer']);
    case OrganizationType.Carrier:
    case OrganizationType.Broker:
    default:
      break;
  }

  return {};
}

export default function UserCreateDialog({ organization, team, open, onClose }: UserCreateDialogProps) {
  const { bulkCreateContacts } = useBulkCreateContacts();
  const { bulkCreateSubmissions } = useBulkCreateSubmissions();
  const { showToast } = useToast();

  const [activeStep, setActiveStep] = useState<UserCreateSteps>(UserCreateSteps.Init);
  const [email, setEmail] = useState<string | null>(null);
  const [emailValid, setEmailValid] = useState(true);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [role, setRole] = useState<UserRole | null>(null);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [note, setNote] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [allowedRoles, setAllowedRoles] = useState<Record<string, UserRole>>({});
  const { items: organizations } = useSearchOrganization();
  const { items: teams } = useSearchTeam({
    filter: { organizationId: selectedOrganization?.id },
    enabled: !team && !!selectedOrganization,
  });

  const enabled =
    !!email &&
    !!role &&
    !!firstName &&
    !!lastName &&
    !!selectedTeam &&
    activeStep !== UserCreateSteps.Loading &&
    emailValid;
  const [signature, setSignature] = useState<string | undefined>();
  const [selectedContactsFile, setSelectedContactsFile] = useState<File | null>(null);
  const [selectedSubmissionsFile, setSelectedSubmissionsFile] = useState<File | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [uploadedContactsResponse, setUploadedContactsResponse] = useState<BulkCreateResponse<Contact> | null>(null);
  const [uploadedSubmissionsResponse, setUploadedSubmissionsResponse] = useState<BulkCreateResponse<Submission> | null>(
    null,
  );
  const navigate = useNavigate();

  const { createUser } = useMutateUser();

  useEffect(() => {
    setSelectedOrganization(organization || null);
  }, [organization, setSelectedOrganization]);

  useEffect(() => {
    setSelectedTeam(team || null);
  }, [team, setSelectedTeam]);

  useEffect(() => {
    const organizationRoles = chooseRoles(selectedOrganization);

    setAllowedRoles(
      Object.fromEntries(
        Object.keys(organizationRoles).map((key) => [getUserRole(key as UserRole), organizationRoles[key]]),
      ),
    );
  }, [selectedOrganization]);

  const onCreate = async () => {
    // At this point we know that all the fields are not null, so we can use '!'.
    const userOrganization =
      selectedOrganization ?? organizations.find(({ id }) => id === selectedTeam!.organizationId);

    if (isIntegrationEnabled({ role: role!, organization: userOrganization! }) && email?.includes('+')) {
      showToast('error', { message: invalidIntegrationEmailAddress });
      return;
    }

    setActiveStep(UserCreateSteps.Loading);
    const user = await createUser.mutateAsync({
      data: {
        organizationId: userOrganization!.id,
        teamId: selectedTeam!.id,
        email: email!,
        firstName: firstName!,
        lastName: lastName!,
        role: role!,
        title: title ?? undefined,
        note: note ?? undefined,
        phoneNumber: phoneNumber ?? undefined,
        signature,
      },
    });

    if (user) {
      setUserId(user.id);
      if (selectedContactsFile) {
        const uploadedContacts = await bulkCreateContacts.mutateAsync({
          data: {
            file: selectedContactsFile!,
            teamId: user.team.id,
          },
        });
        setUploadedContactsResponse(uploadedContacts);

        if (selectedSubmissionsFile && uploadedContacts) {
          const uploadedSubmissions = await bulkCreateSubmissions.mutateAsync({
            data: {
              file: selectedSubmissionsFile!,
            },
          });
          setUploadedSubmissionsResponse(uploadedSubmissions);
        }
      }
    }
    setActiveStep(UserCreateSteps.UserCreated);
  };

  const setOrganization = (newOrganization: Organization | null) => {
    setSelectedOrganization(newOrganization ?? null);
    setRole(null);
  };

  const setTeam = (newTeam: Team | null) => {
    setSelectedTeam(newTeam ?? null);
  };

  const handleContactsFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedContactsFile(file);
    }
  };

  const handleSubmissionsFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedSubmissionsFile(file);
    }
  };

  function validateEmail(emailToCheck: string) {
    return /^[\w.%+-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+$/.test(emailToCheck);
  }

  const renderStep = () => {
    switch (activeStep) {
      case UserCreateSteps.Init: {
        return (
          <>
            <Grid container columnSpacing={1}>
              <Grid item xs={12}>
                <DialogTextField
                  placeholder="Email"
                  setValue={(newValue) => {
                    setEmail(newValue);
                    setEmailValid(validateEmail(newValue));
                  }}
                  value={email}
                  autoFocus
                  isValid={emailValid}
                />
              </Grid>
              <Grid item xs={6}>
                <DialogTextField placeholder="First Name" setValue={setFirstName} value={firstName} />
              </Grid>
              <Grid item xs={6}>
                <DialogTextField placeholder="Last Name" setValue={setLastName} value={lastName} />
              </Grid>
              <Grid item xs={6}>
                <DialogTextField placeholder="Phone Number" setValue={setPhoneNumber} value={phoneNumber} />
              </Grid>
              <Grid item xs={6}>
                <DialogTextField placeholder="Job Title" setValue={setTitle} value={title} />
              </Grid>
              <Grid item xs={6}>
                <DialogTextField placeholder="What I Do" setValue={setNote} value={note} />
              </Grid>
              <Grid item xs={6}>
                {!organization && !team && (
                  <DialogSelect
                    options={keyBy(organizations, (org) => org.name)}
                    placeholder="Organization"
                    setValue={setOrganization}
                    value={selectedOrganization?.name || null}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {!team && (
                  <DialogSelect
                    options={keyBy(teams, (t) => t.name)}
                    placeholder="Team"
                    setValue={setTeam}
                    value={selectedTeam?.name || null}
                    disabled={!selectedOrganization}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <DialogSelect
                  options={allowedRoles}
                  placeholder="Role"
                  setValue={setRole}
                  value={getUserRole(role as UserRole)}
                />
              </Grid>
            </Grid>
            <InputLabel sx={{ mt: 2 }}>Signature</InputLabel>
            <Stack
              height={(theme) => theme.spacing(30)}
              direction="column"
              sx={{
                borderRadius: 2,
                backgroundColor: 'common.white',
                boxShadow: 1,
                overflow: 'auto',
              }}
            >
              <Editor editorContent={signature} setEditorContent={setSignature} />
            </Stack>
            <Stack direction="row" m={2} gap={4}>
              <Stack key="uploadContacts" direction="column">
                <Typography sx={{ mb: 1, fontSize: 12 }}>Upload contacts</Typography>
                <input type="file" onChange={handleContactsFileSelect} />
              </Stack>
              <Stack key="uploadSubmissions" direction="column">
                <Typography sx={{ mb: 1, fontSize: 12 }}>Upload submissions</Typography>
                <input type="file" onChange={handleSubmissionsFileSelect} disabled={!selectedContactsFile} />
              </Stack>
            </Stack>
          </>
        );
      }
      case UserCreateSteps.Loading: {
        return (
          <Stack sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
            {loadingMessage}
            <CircularProgress />
          </Stack>
        );
      }
      case UserCreateSteps.UserCreated: {
        return (
          <>
            <Typography variant="h6">{!!userId && userCreatedSuccessfully}</Typography>
            {uploadedContactsResponse && (
              <Box mt={2}>
                <Typography color="green.400" variant="subtitle1">
                  {itemsUploadedSuccessfully(uploadedContactsResponse.created.length, 'Contacts')}
                </Typography>
                <Typography color="red" variant="subtitle1">
                  {uploadFailed(uploadedContactsResponse.errors.length, 'Contacts')}
                </Typography>
                <Typography color="red" variant="subtitle1">
                  {uploadedContactsResponse.errors.map((error) => (
                    <Typography key={error.rowNumber} variant="body2">
                      {errorMessage(error)}
                    </Typography>
                  ))}
                </Typography>
              </Box>
            )}
            {uploadedSubmissionsResponse && (
              <Box mt={2}>
                <Typography color="green.400" variant="subtitle1">
                  {itemsUploadedSuccessfully(uploadedSubmissionsResponse.created.length, 'Submissions')}
                </Typography>
                <Typography color="red" variant="subtitle1">
                  {uploadFailed(uploadedSubmissionsResponse.errors.length, 'Submissions')}
                </Typography>
                <Typography color="red" variant="subtitle1">
                  {uploadedSubmissionsResponse.errors.map((error) => (
                    <Typography key={error.rowNumber} variant="body2">
                      {errorMessage(error)}
                    </Typography>
                  ))}
                </Typography>
              </Box>
            )}
          </>
        );
      }
      default:
        return null;
    }
  };

  return (
    <ActionDialog
      actionLabel={activeStep === UserCreateSteps.Init ? 'Create' : enterNewUser}
      enabled={enabled}
      open={open}
      onClose={onClose}
      onAction={activeStep === UserCreateSteps.Init ? onCreate : () => navigate(ADMIN_ROUTES.getUserById(userId ?? ''))}
      title="Create User"
    >
      {renderStep()}
    </ActionDialog>
  );
}
