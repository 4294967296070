import { Transition } from 'history';
import { useState } from 'react';
import { BROKER_NESTED_ROUTES, QuoteComparisonInsightsRoutes } from 'broker/broker-routes';
import { DirtyRouteGuard } from 'broker/components/DirtyContentGuard';
import { useRouteLocationState } from 'broker/components/useRouteLocationState';
import { RouteKey } from 'broker/enums';
import QuoteComparisonInsightsContent from './QuoteComparisonInsightsContent';

export default function QuoteComparisonInsights() {
  const [isDirty, setIsDirty] = useState(false);

  const locationState = useRouteLocationState(RouteKey.QuoteComparisonInsights, true);
  if (!locationState) {
    return null;
  }

  const beforeNavigation = async (transition: Transition) =>
    [...Object.values(QuoteComparisonInsightsRoutes), BROKER_NESTED_ROUTES.WORKSPACE.QUOTE_COMPARISON_INSIGHTS].some(
      (substring) => transition.location.pathname.includes(substring),
    );

  return (
    <DirtyRouteGuard isDirty={isDirty} beforeNavigation={beforeNavigation}>
      <QuoteComparisonInsightsContent locationState={locationState} setIsDirty={setIsDirty} isDirty={isDirty} />
    </DirtyRouteGuard>
  );
}
