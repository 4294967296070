import { useNavigate } from 'react-router-dom-latest';
import { AppBar, Button, Stack, Typography } from '@common-components';
import { useCurrentUser, useSetEndUser } from 'hooks';
import { messages } from 'i18n';
import { BROKER_ROUTES } from 'broker/broker-routes';

export const EndUserIndicator = () => {
  const { me } = useCurrentUser();
  const navigate = useNavigate();

  const { isFetching: isClearing } = useSetEndUser();

  const onClear = async () => {
    navigate(BROKER_ROUTES.BACK_OFFICE, { state: { shouldClearEndUser: true } });
  };
  return (
    <AppBar
      position="sticky"
      sx={{
        border: 1,
        borderColor: '#f72585',
        bgcolor: '#ffb9d3ed',
        alignItems: 'center',
        pl: 1,
        zIndex: 1500,
      }}
    >
      <Stack direction="row" gap={1} alignItems="center" py={0.5}>
        <Typography py={0.5} my={0.5} variant="body1" color="text.primary">
          {messages.endUserIndicator.indicatorText(me?.endUser?.email)}
        </Typography>
        {me?.endUser && (
          <Button onClick={onClear} loading={isClearing}>
            {messages.endUserIndicator.signOut}
          </Button>
        )}
      </Stack>
    </AppBar>
  );
};
