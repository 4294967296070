import { useMemo } from 'react';
import { ContactRole } from 'enums';
import { useSearchContact } from 'hooks';

export default function useGetContacts() {
  const { items: retailers, isLoading: retailersIsLoading } = useSearchContact({
    filter: { role: ContactRole.Retailer },
  });

  const { items: insured, isLoading: insuredIsLoading } = useSearchContact({
    filter: { role: ContactRole.Insured },
  });

  const contacts = useMemo(() => retailers.concat(insured), [insured, retailers]);

  const isContactsLoading = retailersIsLoading || insuredIsLoading;

  return {
    contacts,
    isContactsLoading,
  };
}
