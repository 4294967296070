import { BoxTemplateLabels, FileType, QuoteFileIdType } from 'enums';
import { getFileMetadataField } from 'utils/files-utils';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

export const useFileWithMetadata = (quoteFileIdType: QuoteFileIdType, findFileTypeLabel = false) => {
  const { filesExplorer } = useUiStoreState();
  let fileType: FileType | undefined;
  if (findFileTypeLabel) {
    if (quoteFileIdType === QuoteFileIdType.MarketQuote) {
      fileType = FileType.MarketQuote;
    } else if (quoteFileIdType === QuoteFileIdType.Policy) {
      fileType = FileType.Policy;
    }
  }

  return filesExplorer.selectedFiles.find((file) => {
    if (fileType && getFileMetadataField(file, BoxTemplateLabels.FileType) !== fileType) {
      return false;
    }
    if (quoteFileIdType === QuoteFileIdType.MarketQuote) {
      return !!file?.syncMetadata?.extractedData;
    }
    if (quoteFileIdType === QuoteFileIdType.Policy) {
      return !!file?.syncMetadata?.policyExtractedData;
    }
    return false;
  });
};
