import { compact, isEmpty, keyBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Button, Stack, Tooltip, Typography } from '@common-components';
import { Add as AddIcon } from '@icons';
import { CoverageLine } from 'enums';
import { useBoolean, useHotjar, useSearchUserMarket } from 'hooks';
import { messages } from 'i18n';
import { UserMarket } from 'types';
import { marketRequestTypeConfig } from 'utils';
import { stringCompare } from 'utils/comparators';
import { HotjarEvents } from 'utils/hotjar-events';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import { PreSelectedMarket } from 'broker/components/EmailEditor/store/types';
import { convertUserMarketToRecipientGroup } from 'broker/components/Emails/recipient-utils';
import { SelectedMarketsMap } from 'broker/components/UserMarkets/types';
import { EmailEditorPageMode, RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { AddMarketsLocationState } from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/components/Header/utils';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useIsToolboxOpen from 'broker/pages/SubmissionWorkspacePage/hooks/useIsToolboxOpen';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import UserMarkets from './components/UserMarkets';
import HeaderBottomSideContent from './HeaderBottomSideContent';
import useGetUserMarketsFilter from './useGetUserMarketsFilter';
import useMarketsState from './useMarketsState';

export interface MarketsModalProps {
  preferredCoverageLines: CoverageLine[];
  preSelectedMarkets?: PreSelectedMarket[];
  marketsIdsInSubmission: string[];
  submitToMarkets?: boolean;
  disableSelection?: boolean;
  onMarketsSelected: (markets: UserMarket[], replace?: boolean) => Promise<void> | void;
  preserveRouteState?: boolean;
  locationState: AddMarketsLocationState;
}

export type ContentProps = Omit<MarketsModalProps, 'preserveRouteState'>;

export default function Content({
  preferredCoverageLines,
  preSelectedMarkets,
  marketsIdsInSubmission,
  onMarketsSelected: ownOnMarketsSelected,
  submitToMarkets = false,
  disableSelection,
  locationState: { marketRequestType, layer },
}: ContentProps) {
  const { markets: submissionMarkets } = useSubmissionsWorkspace();

  const isToolboxOpened = useIsToolboxOpen();

  const { emailEditorUrl } = useWorkspaceUrls();
  const hotjar = useHotjar();
  const navigate = useNavigate();
  const {
    items: markets,
    isLoading: isLoadingMarkets,
    isFetching: isFetchingMarkets,
  } = useSearchUserMarket({
    filter: {},
  });

  const preSelectedMarketsSet = useRef(false);
  const [isSelectingMarkets, { on: startSelectingMarkets, off: stopSelectingMarkets }] = useBoolean(false);
  const { selectedMarkets, onMarketsSelected, onMarketRemoved, selectNewContact, setSelectedMarkets } =
    useMarketsState();

  const primaryIncumbentMarketIds = useMemo(
    () =>
      submissionMarkets
        .filter((market) => market.incumbentInfo.length > 0 && market.incumbentInfo[0].isPrimary)
        .map((market) => market.userMarketId),
    [submissionMarkets],
  );

  const xsIncumbentMarketIds = useMemo(
    () =>
      submissionMarkets
        .filter((market) => market.incumbentInfo.length > 0 && !market.incumbentInfo[0].isPrimary)
        .map((market) => market.userMarketId),
    [submissionMarkets],
  );

  const userMarketsFilter = useGetUserMarketsFilter({
    markets,
    preferredCoverageLines,
    isFetchingMarkets,
    isLoadingMarkets,
    marketsIdsInSubmission,
    primaryIncumbentMarketIds,
    xsIncumbentMarketIds,
    marketRequestType,
  });

  useEffect(() => {
    if (markets.length > 0 && !preSelectedMarketsSet.current) {
      preSelectedMarketsSet.current = true;
      const selectedMarketsFilled = preSelectedMarkets?.map((preSelectedMarket) => {
        if (isEmpty(preSelectedMarket.contactIds)) {
          return null;
        }

        const userMarket = markets.find((market) => market.id === preSelectedMarket.id);

        if (!userMarket) {
          return null;
        }

        return {
          ...userMarket,
          contacts: compact(
            preSelectedMarket.contactIds.map((contactId) =>
              userMarket.contacts?.find((userMarketContact) => userMarketContact.id === contactId),
            ),
          ),
        };
      });

      onMarketsSelected(compact(selectedMarketsFilled), true);
    } else {
      setSelectedMarkets((prevSelectedMarkets) => {
        const marketIdToMarket = keyBy(markets, (market) => market.id);
        const newSelectedMarkets = Object.keys(prevSelectedMarkets).map((selectedMarketId) => ({
          ...marketIdToMarket[selectedMarketId],
          contacts: marketIdToMarket[selectedMarketId].contacts.filter((contact) =>
            prevSelectedMarkets[selectedMarketId].contacts.find((selectedContact) => selectedContact.id === contact.id),
          ),
        }));
        return newSelectedMarkets.reduce(
          (map: SelectedMarketsMap, market: UserMarket) => ({
            ...map,
            [market.id]: market,
          }),
          {},
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markets]);

  // TODO: we can probably simplify this by making sure we don't have entries with empty contacts list
  // would probably also prevent bugs..
  const selectedMarketsCount = useMemo(
    () => Object.values(selectedMarkets).filter((market) => !!market.contacts.length).length,
    [selectedMarkets],
  );

  const selectedMarketNames = useMemo(
    () =>
      Object.values(selectedMarkets)
        .filter((market) => !!market.contacts.length)
        .map((market) => market.marketName)
        .sort(stringCompare),
    [selectedMarkets],
  );

  const isProceedDisabled =
    isLoadingMarkets || isSelectingMarkets || (isEmpty(preSelectedMarkets) && selectedMarketsCount === 0);

  const onUpdateMarketsClick = useCallback(
    async ({ onClose }: InnerPageProps) => {
      startSelectingMarkets();
      const selectedMarketsValues = Object.values(selectedMarkets).map((selectedMarket) => ({
        ...selectedMarket,
        marketRequestType,
      }));

      try {
        await ownOnMarketsSelected(selectedMarketsValues, true);
        onClose();
      } catch (e) {
        stopSelectingMarkets();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ownOnMarketsSelected, selectedMarkets],
  );

  const submitToMarket = () => {
    hotjar.event(HotjarEvents.SubmitToMarket);
    navigate(emailEditorUrl, {
      routeKey: RouteKey.EmailEditor,
      state: {
        marketRequestType,
        mode: EmailEditorPageMode.SendRfq,
        layer,
        recipientGroups: Object.values(selectedMarkets).map((selectedMarket) =>
          convertUserMarketToRecipientGroup(selectedMarket),
        ),
      },
      replace: true,
    });
  };

  const onCreateMarket = () => {
    navigate(`${BROKER_NESTED_ROUTES.WORKSPACE.MARKET}`, {
      routeKey: RouteKey.UserMarket,
      state: undefined,
    });
  };

  return (
    <InnerPageLayout
      className="cap-add-markets"
      title={messages.addMarketsPage.title}
      subTitle={
        marketRequestType
          ? marketRequestTypeConfig[marketRequestType].addMarketsPageSubTitle
          : messages.addMarketsPage.subtitle.general
      }
      sx={{ paddingX: 0, paddingTop: 1, paddingBottom: 0 }}
      headerBottomContent={() => (
        <HeaderBottomSideContent isToolboxOpened={isToolboxOpened} userMarketsFilter={userMarketsFilter} />
      )}
      headerSideContent={() => (
        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1}>
          <Button startIcon={<AddIcon />} variant="text" onClick={onCreateMarket}>
            {messages.marketsPage.header.createMarket}
          </Button>
        </Stack>
      )}
      footer={(innerPageProps: InnerPageProps) => (
        <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
          <Stack direction="row" width="35%" overflow="hidden">
            <Tooltip tooltipContent={selectedMarketNames.join(', ')}>
              <Typography variant="body2" noWrap>
                {messages.marketsPage.getSelectedText(selectedMarketsCount)} {selectedMarketsCount ? ':' : ''}{' '}
                <Typography variant="body2Bold">{selectedMarketNames.join(', ')}</Typography>
              </Typography>
            </Tooltip>
          </Stack>

          <Stack direction="row" gap={1}>
            <Button variant="outlined" onClick={innerPageProps.onClose}>
              {messages.buttons.cancel}
            </Button>
            {submitToMarkets ? (
              <>
                <Button
                  variant="outlined"
                  onClick={() => onUpdateMarketsClick(innerPageProps)}
                  disabled={isProceedDisabled}
                  loading={isSelectingMarkets}
                >
                  {messages.buttons.addToWorkspace}
                </Button>
                <Button variant="contained" onClick={submitToMarket} disabled={isProceedDisabled}>
                  {messages.buttons.submitMarkets(selectedMarketsCount)}
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                onClick={() => onUpdateMarketsClick(innerPageProps)}
                disabled={isProceedDisabled}
                loading={isSelectingMarkets}
              >
                {isEmpty(preSelectedMarkets)
                  ? messages.buttons.addMarkets(selectedMarketsCount)
                  : messages.buttons.updateMarkets(selectedMarketsCount)}
              </Button>
            )}
          </Stack>
        </Stack>
      )}
    >
      {() => (
        <UserMarkets
          items={markets}
          isLoading={isLoadingMarkets}
          submissionMarkets={submissionMarkets}
          {...userMarketsFilter}
          selectedMarkets={selectedMarkets}
          onMarketsSelected={onMarketsSelected}
          selectNewContact={selectNewContact}
          onCreateMarket={onCreateMarket}
          onMarketRemoved={onMarketRemoved}
          selectedMarketsCount={selectedMarketsCount}
          disableSelection={disableSelection}
          incumbentMarketIds={[...primaryIncumbentMarketIds, ...xsIncumbentMarketIds]}
        />
      )}
    </InnerPageLayout>
  );
}
