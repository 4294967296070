import { Stack, Typography } from '@common-components';
import { marketRequestConfig } from './config';

export default function MarketRequestTableHeader() {
  return (
    <Stack
      bgcolor="common.white"
      position="sticky"
      top={0}
      zIndex={1}
      direction="row"
      width={1}
      px={3}
      py={2}
      gap={1.5}
      borderBottom={1}
      borderColor="divider"
    >
      {Object.values(marketRequestConfig).map((column) => (
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          flex={column.flex}
          minWidth={column.width}
          maxWidth={column.maxWidth}
          key={column.key}
          align={column.align}
        >
          {column.title}
        </Typography>
      ))}
    </Stack>
  );
}
