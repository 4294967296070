import { useState } from 'react';
import { Clear as ClearIcon, Search as SearchIcon } from '@icons';
// eslint-disable-next-line import/no-internal-modules
import { useDebouncedEffect } from 'hooks/useDebouncedEffect';
import { uniteStyles } from 'utils';
import { Box, InputAdornment, SxProps, TextFieldProps, Theme } from 'components/mui-index';
import IconButton from './IconButton';
import { TextField } from './TextField';

interface SearchBarProps extends Omit<TextFieldProps, 'placeholder' | 'value' | 'onChange'> {
  placeholder: string;
  id?: string;
  value?: string;
  height?: number;
  onChange: (term: string) => void;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  searchIconStyle?: object;
  showClear?: boolean;
  clearSx?: SxProps<Theme>;
}

export const SearchBar = ({
  value,
  height = 40,
  onChange,
  id,
  placeholder,
  variant,
  searchIconStyle,
  showClear = true,
  sx,
  clearSx,
  ...props
}: SearchBarProps) => {
  const handleChange = (newValue: string) => {
    onChange(newValue);
  };

  return (
    <TextField
      placeholder={placeholder}
      size="small"
      id={id}
      value={value}
      onChange={(e) => {
        handleChange(e.target.value);
      }}
      variant={variant}
      sx={uniteStyles(
        {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'primary',
            },
          },
          '& .MuiInput-underline:before': { borderBottomColor: 'primary' },
        },
        sx,
      )}
      InputProps={{
        sx: {
          typography: 'body2',
          height,
          bgcolor: 'common.white',
          borderColor: 'common.black',
          '& input': { textOverflow: 'ellipsis' },
          '& .MuiOutlinedInput-notchedOutline': { '&:hover': { borderColor: 'blueGrey.800' }, borderColor: 'divider' },
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ ...searchIconStyle }} />
          </InputAdornment>
        ),
        endAdornment: showClear ? (
          <Box sx={uniteStyles({ visibility: value === '' ? 'hidden' : 'initial' }, clearSx)}>
            <IconButton
              icon={ClearIcon}
              variant="text"
              color="secondary"
              size="small"
              onClick={() => handleChange('')}
            />
          </Box>
        ) : undefined,
      }}
      {...props}
    />
  );
};

export default function DebounceSearchBar({ value, onChange, id, placeholder, ...props }: SearchBarProps) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useDebouncedEffect(() => onChange(debouncedValue ?? ''), 200, [debouncedValue]);

  return <SearchBar value={debouncedValue} placeholder={placeholder} id={id} onChange={setDebouncedValue} {...props} />;
}
