import { compact } from 'lodash';
import { UseFormReturn } from 'react-hook-form';
import { OrganizationType } from 'enums';
import { useBoolean, useMutateMarket, useMutateOrganization, useMutateUserMarket, useToast } from 'hooks';
import { messages } from 'i18n';
import { dialogTransitionDurationValue } from 'themes';
import { UserMarket, UserMarketCreate } from 'types';
import { UserMarketFormProps } from './types';
import { useUpdateUserMarketContacts } from './useUpdateUserMarketContacts';
import { FormMode } from './utils';

interface ResponseAsError {
  message: string;
  statusCode: number;
}

interface SubmitUserMarketProps {
  id: string | null;
  newMarketOrganizationType?: OrganizationType;
  mode: FormMode;
  defaultValues: Partial<UserMarketFormProps>;
  methods: UseFormReturn<Partial<UserMarketFormProps>, object>;
  onMarketCreated?: (market: UserMarket) => void;
  closeWithoutPrompt: () => void;
}

export function useSubmitUserMarket({
  id,
  mode,
  defaultValues,
  methods,
  onMarketCreated,
  closeWithoutPrompt,
  newMarketOrganizationType,
}: SubmitUserMarketProps) {
  const [isSubmitting, { on: startSubmit, off: stopSubmit }] = useBoolean();

  const { createUserMarket, updateUserMarket } = useMutateUserMarket();
  const { createMarketByName } = useMutateMarket();
  const { updateOrganization } = useMutateOrganization();
  const { areContactEmailsValid, updateContacts } = useUpdateUserMarketContacts({ defaultValues, methods });

  const { showDelayedToast, showToast } = useToast();

  const afterSubmit = (data: UserMarket | null) => {
    showDelayedToast(
      'success',
      {
        message:
          mode === FormMode.Update
            ? messages.userMarketModal.userMarketUpdated(data?.marketName ?? '')
            : messages.userMarketModal.userMarketAdded(data?.marketName ?? ''),
      },
      dialogTransitionDurationValue,
    );
    if (data) {
      onMarketCreated?.(data);
    }

    closeWithoutPrompt();
  };

  async function createNewUserMarket(userMarket: UserMarketCreate, marketName: string) {
    try {
      const response = await createUserMarket.mutateAsync({ data: userMarket });
      const responseAsError = { ...response } as ResponseAsError;

      if (responseAsError?.statusCode === 409) {
        showToast('warning', { message: messages.userMarketModal.userMarketAlreadyExist(marketName) });
      } else {
        afterSubmit(response);
      }
    } catch (e) {
      stopSubmit();
    }
  }

  async function renameOrganization(organizationId: string, marketName: string) {
    const response = await updateOrganization.mutateAsync({
      id: organizationId,
      data: { name: marketName },
    });

    const responseAsError = { ...response } as ResponseAsError;

    if (responseAsError?.statusCode === 409) {
      showToast('warning', { message: messages.userMarketModal.userMarketAlreadyExist(marketName) });
      // Throw the error to stop the submit process
      throw new Error(responseAsError.message);
    }
  }

  async function submitUserMarket(data: UserMarketFormProps) {
    startSubmit();

    try {
      let { organizationId, marketId } = defaultValues;

      // In update mode, if the market name has changed, we need to update the organization name first
      if (mode === FormMode.Update && data.marketName !== defaultValues.marketName && !!organizationId) {
        await renameOrganization(organizationId, data.marketName);
      }

      // This should happen only in create mode, but even if for some reason we get here in update mode,
      // this create call is an upsert, so it will only return the existing market if it already exists
      if (!organizationId || !marketId) {
        const market = await createMarketByName.mutateAsync({
          data: {
            name: data.marketName,
            organizationType: newMarketOrganizationType,
          },
        });

        // We know for sure that we got a market because if there was an error, the function will throw an exception
        organizationId = market!.organizationId;
        marketId = market!.id;
      }

      const { contacts, ...rest } = data;
      const touchedFieldsContacts = methods.formState.touchedFields.contacts ?? [];
      const dirtyFieldsContacts = methods.formState.dirtyFields.contacts ?? [];

      if (await areContactEmailsValid(contacts, touchedFieldsContacts)) {
        const contactIds = await updateContacts(contacts, dirtyFieldsContacts, organizationId);

        const userMarket: UserMarketCreate = {
          ...rest,
          marketId,
          contactIds: compact(contactIds),
        };

        if (mode === FormMode.Update && id) {
          try {
            await updateUserMarket.mutateAsync({ id, data: userMarket }, { onSuccess: afterSubmit });
          } catch (e) {
            stopSubmit();
          }
        } else {
          await createNewUserMarket(userMarket, data.marketName);
        }
      }
    } finally {
      stopSubmit();
    }
  }

  return { submitUserMarket, isSubmitting };
}
