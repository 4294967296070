import { FormMode, QuoteFileIdType } from 'enums';
import { Layer, Quote, SubmissionMarket } from 'types';

export interface QuoteLocationState {
  mode: FormMode;
  layer?: Layer;
  selectedMarket?: SubmissionMarket;
  selectedQuote?: Quote;
  quoteFileIds?: string[];
  marketId?: string;
  fileType?: QuoteFileIdType;
  insuranceProductId?: string;
}

export enum FlowGenerationStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Error = 'Error',
}
