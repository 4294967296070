import FilesListSection from './FilesListSection';
import FilesUploadSection from './FilesUploaderSection';
import { ContentProps } from './types';

export default function Content(props: ContentProps) {
  return (
    <>
      <FilesListSection {...props} />
      <FilesUploadSection {...props} />
    </>
  );
}
