import { DragEvent, useRef, useState } from 'react';

export const useDraggableElement = (draggedContent: string) => {
  const [isDragged, setIsDragged] = useState(false);
  const draggedElement = useRef<HTMLDivElement | null>(null);
  const onDragStart = (event: DragEvent<HTMLDivElement>) => {
    if (draggedContent) {
      event.dataTransfer.setData('text/plain', draggedContent);
    }

    const target = event.currentTarget;
    const clone = target.cloneNode(true) as HTMLDivElement;
    clone.style.maxWidth = '300px';
    clone.style.left = '-9999999px';
    clone.style.top = '-9999999px';
    clone.style.position = 'absolute';

    draggedElement.current = document.body.appendChild(clone);
    event.dataTransfer.setDragImage(clone, 150, 100);
    setIsDragged(true);
  };

  const onDragEnd = () => {
    setIsDragged(false);
    if (draggedElement.current) {
      draggedElement.current.remove();
    }
  };
  return { elementProps: { onDragStart, onDragEnd, draggable: true }, isDragged };
};
