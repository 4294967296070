import * as yup from 'yup';
import { AnySchema } from 'yup';
import { FormFieldsConfigProps } from 'hooks';
import { messages } from 'i18n';
import { InsightsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/form-methods/enums';

type InsightsFormFieldsConfig = Record<InsightsFieldsNames, FormFieldsConfigProps<InsightsFieldsNames>>;

export const insightsFormFieldsConfig: InsightsFormFieldsConfig = {
  [InsightsFieldsNames.Insights]: {
    label: '',
    placeholder: messages.quoteComparisonPage.fieldPlaceholders.flowInsight,
    name: InsightsFieldsNames.Insights,
    id: InsightsFieldsNames.Insights,
  },
};

export const insightsFormValidationShape: Record<InsightsFieldsNames, AnySchema> = {
  [InsightsFieldsNames.Insights]: yup.string().default(''),
};
