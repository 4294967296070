import { CoverageLine } from 'enums';
import { messages } from 'i18n';

export interface CoverageLineConfigProps {
  text: string;
}

type CoverageLineConfig = {
  [line in CoverageLine]: CoverageLineConfigProps;
};

export const coverageLineConfig: CoverageLineConfig = {
  [CoverageLine.Property]: {
    text: messages.coverageLine.property,
  },
  [CoverageLine.Casualty]: {
    text: messages.coverageLine.casualty,
  },
  [CoverageLine.ProfessionalLiability]: {
    text: messages.coverageLine.professionalLiability,
  },
  [CoverageLine.PublicDnO]: {
    text: messages.coverageLine.publicDnO,
  },
  [CoverageLine.PrivateDnO]: {
    text: messages.coverageLine.privateDnO,
  },
  [CoverageLine.NonProfitDnO]: {
    text: messages.coverageLine.nonProfitDnO,
  },
  [CoverageLine.EnO]: {
    text: messages.coverageLine.eno,
  },
  [CoverageLine.Cyber]: {
    text: messages.coverageLine.cyber,
  },
  [CoverageLine.EPL]: {
    text: messages.coverageLine.epl,
  },
  [CoverageLine.KidnapRansom]: {
    text: messages.coverageLine.KidnapRansom,
  },
  [CoverageLine.Crime]: {
    text: messages.coverageLine.Crime,
  },
  [CoverageLine.Fiduciary]: {
    text: messages.coverageLine.Fiduciary,
  },
  [CoverageLine.CommercialAutomobile]: {
    text: messages.coverageLine.CommercialAutomobile,
  },
  [CoverageLine.ExcessCommercialAuto]: {
    text: messages.coverageLine.ExcessCommercialAuto,
  },
  [CoverageLine.ExcessLiability]: {
    text: messages.coverageLine.ExcessLiability,
  },
  [CoverageLine.ExcessUmbrella]: {
    text: messages.coverageLine.ExcessUmbrella,
  },
  [CoverageLine.GeneralLiability]: {
    text: messages.coverageLine.GeneralLiability,
  },
  [CoverageLine.GeneralLiabilityAndPollutionLiability]: {
    text: messages.coverageLine.GeneralLiabilityAndPollutionLiability,
  },
  [CoverageLine.GeneralLiabilityAndProfessionalLiability]: {
    text: messages.coverageLine.GeneralLiabilityAndProfessionalLiability,
  },
  [CoverageLine.ProductsLiability]: {
    text: messages.coverageLine.ProductsLiability,
  },
  [CoverageLine.ProductsRecallLiability]: {
    text: messages.coverageLine.ProductsRecallLiability,
  },
  [CoverageLine.Umbrella]: { text: messages.coverageLine.Umbrella },
  [CoverageLine.WorkersCompOutOfState]: {
    text: messages.coverageLine.WorkersCompOutOfState,
  },
  [CoverageLine.WorkersCompensation]: {
    text: messages.coverageLine.WorkersCompensation,
  },
  [CoverageLine.PollutionLiability]: {
    text: messages.coverageLine.PollutionLiability,
  },
  [CoverageLine.HiredNonOwnedAuto]: {
    text: messages.coverageLine.HiredNonOwnedAuto,
  },
  [CoverageLine.MiscellaneousCommercial]: {
    text: messages.coverageLine.MiscellaneousCommercial,
  },
  [CoverageLine.BoatCommercial]: {
    text: messages.coverageLine.BoatCommercial,
  },
  [CoverageLine.GarageAndDealers]: {
    text: messages.coverageLine.GarageAndDealers,
  },
  [CoverageLine.MarineLiability]: {
    text: messages.coverageLine.MarineLiability,
  },
  [CoverageLine.ExcessMarineLiability]: {
    text: messages.coverageLine.ExcessMarineLiability,
  },

  [CoverageLine.BoilerAndMachinery]: {
    text: messages.coverageLine.BoilerAndMachinery,
  },
  [CoverageLine.BuildersRiskInstallation]: {
    text: messages.coverageLine.BuildersRiskInstallation,
  },
  [CoverageLine.Cargo]: { text: messages.coverageLine.Cargo },
  [CoverageLine.DICDifferenceInCondition]: {
    text: messages.coverageLine.DICDifferenceInCondition,
  },
  [CoverageLine.EarthquakeCommercial]: {
    text: messages.coverageLine.EarthquakeCommercial,
  },
  [CoverageLine.EquipmentFloater]: {
    text: messages.coverageLine.EquipmentFloater,
  },
  [CoverageLine.ExcessProperty]: {
    text: messages.coverageLine.ExcessProperty,
  },
  [CoverageLine.MotorTruckCargo]: {
    text: messages.coverageLine.MotorTruckCargo,
  },
  [CoverageLine.OceanMarine]: { text: messages.coverageLine.OceanMarine },
  [CoverageLine.Terrorism]: { text: messages.coverageLine.Terrorism },
  [CoverageLine.FloodCommercial]: {
    text: messages.coverageLine.FloodCommercial,
  },
  [CoverageLine.ExportImportNavigationTransitOfGoodsSPECIAL]: {
    text: messages.coverageLine.ExportImportNavigationTransitOfGoodsSPECIAL,
  },
  [CoverageLine.ForeignPackagePolicy]: {
    text: messages.coverageLine.ForeignPackagePolicy,
  },
  [CoverageLine.BusinessAndManagementPackage]: {
    text: messages.coverageLine.BusinessAndManagementPackage,
  },
  [CoverageLine.DirectorsAndOfficersLiability]: {
    text: messages.coverageLine.DirectorsAndOfficersLiability,
  },
  [CoverageLine.ElectronicDataProcessing]: {
    text: messages.coverageLine.ElectronicDataProcessing,
  },
  [CoverageLine.ERISAOnCrimePolicyOrERISABond]: {
    text: messages.coverageLine.ERISAOnCrimePolicyOrERISABond,
  },
  [CoverageLine.ExcessDirectorsAndOfficersLiability]: {
    text: messages.coverageLine.ExcessDirectorsAndOfficersLiability,
  },

  [CoverageLine.CommercialPackage]: {
    text: messages.coverageLine.CommercialPackage,
  },

  [CoverageLine.ExcessProfessionalLiability]: {
    text: messages.coverageLine.ExcessProfessionalLiability,
  },
  [CoverageLine.Bond]: {
    text: messages.coverageLine.Bond,
  },
  [CoverageLine.BusinessOwnersPackage]: {
    text: messages.coverageLine.BusinessOwnersPackage,
  },
  [CoverageLine.EmployedLawyersLiability]: {
    text: messages.coverageLine.EmployedLawyersLiability,
  },
  [CoverageLine.MiscellaneousProfessionalLiability]: {
    text: messages.coverageLine.MiscellaneousProfessionalLiability,
  },
  [CoverageLine.AccountantsPl]: {
    text: messages.coverageLine.AccountantsPl,
  },
  [CoverageLine.ArchitectEngineersPl]: {
    text: messages.coverageLine.ArchitectEngineersPl,
  },
  [CoverageLine.ContractorsProfessional]: {
    text: messages.coverageLine.ContractorsProfessional,
  },
  [CoverageLine.EmploymentOrganizationsPl]: {
    text: messages.coverageLine.EmploymentOrganizationsPl,
  },
  [CoverageLine.InsuranceAgentsBrokersEnO]: {
    text: messages.coverageLine.InsuranceAgentsBrokersEnO,
  },
  [CoverageLine.ManufacturersEnO]: {
    text: messages.coverageLine.ManufacturersEnO,
  },
  [CoverageLine.LawyersProfessionalLiability]: {
    text: messages.coverageLine.LawyersProfessionalLiability,
  },
  [CoverageLine.FinancialAdvisorsInvestmentAdvisorsPl]: {
    text: messages.coverageLine.FinancialAdvisorsInvestmentAdvisorsPl,
  },
  [CoverageLine.MediaMultimedia]: {
    text: messages.coverageLine.MediaMultimedia,
  },
  [CoverageLine.TechnologyEnO]: {
    text: messages.coverageLine.TechnologyEnO,
  },
  [CoverageLine.FranchisorsEnO]: {
    text: messages.coverageLine.FranchisorsEnO,
  },
  [CoverageLine.Rnd]: {
    text: messages.coverageLine.Rnd,
  },
  [CoverageLine.SpecifiedProfessionalLiability]: {
    text: messages.coverageLine.SpecifiedProfessionalLiability,
  },
  [CoverageLine.PhysiciansMedicalMalpractice]: {
    text: messages.coverageLine.PhysiciansMedicalMalpractice,
  },
  [CoverageLine.AlliedHealthcareProfessionalLiability]: {
    text: messages.coverageLine.AlliedHealthcareProfessionalLiability,
  },
  [CoverageLine.ManagementLiability]: {
    text: messages.coverageLine.ManagementLiability,
  },
  [CoverageLine.PrivateEmploymentPracticesLiability]: {
    text: messages.coverageLine.PrivateEmploymentPracticesLiability,
  },
  [CoverageLine.PrivateCrime]: {
    text: messages.coverageLine.PrivateCrime,
  },
  [CoverageLine.PrivateFiduciary]: {
    text: messages.coverageLine.PrivateFiduciary,
  },
  [CoverageLine.PublicEmploymentPracticesLiability]: {
    text: messages.coverageLine.PublicEmploymentPracticesLiability,
  },
  [CoverageLine.PublicCrime]: {
    text: messages.coverageLine.PublicCrime,
  },
  [CoverageLine.PublicFiduciary]: {
    text: messages.coverageLine.PublicFiduciary,
  },
  [CoverageLine.NonProfitEmploymentPracticesLiability]: {
    text: messages.coverageLine.NonProfitEmploymentPracticesLiability,
  },
  [CoverageLine.NonProfitDirectorsOfficers]: {
    text: messages.coverageLine.NonProfitDirectorsOfficers,
  },
  [CoverageLine.NonProfitCrime]: {
    text: messages.coverageLine.NonProfitCrime,
  },
  [CoverageLine.NonProfitFiduciary]: {
    text: messages.coverageLine.NonProfitFiduciary,
  },
  [CoverageLine.ExcessManagementLiability]: {
    text: messages.coverageLine.ExcessManagementLiability,
  },
  [CoverageLine.ExcessCyber]: {
    text: messages.coverageLine.ExcessCyber,
  },
  [CoverageLine.EnvironmentalLiability]: {
    text: messages.coverageLine.EnvironmentalLiability,
  },
  [CoverageLine.EnvironmentalProfessionalsPackage]: {
    text: messages.coverageLine.EnvironmentalProfessionalsPackage,
  },
  [CoverageLine.ContractorsPollutionsLiability]: {
    text: messages.coverageLine.ContractorsPollutionsLiability,
  },
  [CoverageLine.StorageTankPollutionLiability]: {
    text: messages.coverageLine.StorageTankPollutionLiability,
  },
  [CoverageLine.SitePollutionLiability]: {
    text: messages.coverageLine.SitePollutionLiability,
  },
  [CoverageLine.UniqueOfferings]: {
    text: messages.coverageLine.UniqueOfferings,
  },
  [CoverageLine.IntellectualProperty]: {
    text: messages.coverageLine.IntellectualProperty,
  },
  [CoverageLine.SexualAbuseMolestation]: {
    text: messages.coverageLine.SexualAbuseMolestation,
  },
  [CoverageLine.KidnapRansomExecutiveProtection]: {
    text: messages.coverageLine.KidnapRansomExecutiveProtection,
  },
  [CoverageLine.TerrorismActiveAssailantShooter]: {
    text: messages.coverageLine.TerrorismActiveAssailantShooter,
  },
};
