export enum AIFeature {
  QuoteCompare = 'QuoteCompare',
  QuoteGeneration = 'QuoteGeneration',
  SubmissionAcknowledgment = 'SubmissionAcknowledgment',
  ProductRecommendation = 'ProductRecommendation',
  QuoteBinderCompare = 'QuoteBinderCompare',
  BinderPolicyCompare = 'BinderPolicyCompare',
  QuotePolicyCompare = 'QuotePolicyCompare',
  RiskAssessment = 'RiskAssessment',
  ProposalGeneration = 'ProposalGeneration',
  MarketingUpdateGeneration = 'MarketingUpdateGeneration',
}
