import { compact } from 'lodash';

export function formatAddress(address: {
  city?: string;
  state?: string;
  country_code?: string;
  postal_code?: string;
  line1?: string;
  line2?: string;
}) {
  const formatAddressArr = [
    address.line1,
    address.line2,
    address.city,
    `${address.state || ''}${address.postal_code ? ` ${address.postal_code}` : ''}`,
    address.country_code,
  ];
  return compact(formatAddressArr).join(', ');
}
