import { LayerClient } from 'clients';
import { QueryKeys } from 'enums';
import { Layer } from 'types';
import { MutateProps, UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useQueryRetrieve, useQuerySearch } from './query/useQuery';

export function useRetrieveLayer(props: UseQueryBaseRetrieveOptions<Layer>) {
  const { id, queryOptions, enabled } = props;
  return useQueryRetrieve({
    queryKey: QueryKeys.Layers,
    clientClass: LayerClient,
    id,
    enabled,
    queryOptions: {
      // Default retrieve query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}
export function useSearchLayer(props?: UseQueryBaseSearchOptions<Layer>) {
  const { filter, queryOptions } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.Layers,
    clientClass: LayerClient,
    filter,
    queryOptions: {
      // Default search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateLayer(options?: MutateProps) {
  const {
    create: createLayer,
    update: updateLayer,
    destroy: destroyLayer,
  } = useMutate<Layer, Layer, Partial<Layer>, LayerClient>({
    queryKey: QueryKeys.Layers,
    clientClass: LayerClient,
    cancelInvalidation: options?.cancelInvalidation,
    destroyOptions: {
      throwOnError: true,
    },
  });

  return { createLayer, updateLayer, destroyLayer };
}
