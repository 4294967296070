import { Button, Chip, Stack, Typography } from '@common-components';
import { KeyboardArrowRight } from '@icons';
import { messages } from 'i18n';
import { getDisplayDateWithHoursAmPm } from 'utils';
import { activeTabUrlParamValues, BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';
import { useGetProposalEmailList } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/ProposalFlow/hooks/UseGetProposalEmailList';

interface EmptyStateScreenProps {
  setNotEmpty: () => void;
}
export default function EmptyStateScreen({ setNotEmpty }: EmptyStateScreenProps) {
  const proposalsList = useGetProposalEmailList();
  const updateQueryParam = useUpdateQueryParam();
  return (
    <Stack width={1} gap={2} height={1} bgcolor="grey.100" borderRadius={1} alignItems="center" justifyContent="center">
      <Stack gap={2} width={308}>
        {proposalsList.length > 0 &&
          proposalsList.map((proposal) => (
            <Chip
              onClick={() => {
                updateQueryParam({
                  addParams: [
                    { queryParam: BrokerUrlParams.ACTIVE_TAB, value: activeTabUrlParamValues.EMAIL },
                    { queryParam: BrokerUrlParams.SELECTED_EMAIL, value: proposal?.id },
                  ],
                });
              }}
              rounded
              variant="outlined"
              size="large"
              fullWidth
              label={`View ${proposal.subject} / ${getDisplayDateWithHoursAmPm(proposal.date)}`}
              key={proposal.id}
              endIcon={KeyboardArrowRight}
            />
          ))}
      </Stack>

      <Button size="large" variant="contained" onClick={setNotEmpty}>
        {messages.submissionWorkspace.tabs.proposal.emptyState.createProposal}
      </Button>
      <Typography maxWidth={520} textAlign="center" variant="caption" color="text.secondary">
        {messages.submissionWorkspace.tabs.proposal.emptyState.title}
      </Typography>
    </Stack>
  );
}
