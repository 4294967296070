import Highlighter from 'react-highlight-words';
import { useTheme } from './mui-index';

export interface HighlighterProps {
  searchWords: string[];
  textToHighlight: string;
}

export const Highlight = ({ searchWords, textToHighlight }: HighlighterProps) => {
  const theme = useTheme();

  return (
    <Highlighter
      searchWords={searchWords}
      autoEscape
      textToHighlight={textToHighlight}
      highlightStyle={{ background: theme.palette.warning.main }}
    />
  );
};
