import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';

export const Header = () => (
  <Stack
    position="sticky"
    top={0}
    bgcolor="common.white"
    p={3}
    gap={2}
    boxShadow="0 2px 2px -2px rgba(34,47,62,.1), 0 8px 8px -4px rgba(34,47,62,.07)"
  >
    <Typography variant="subtitle1">{messages.submissionWorkspace.customerSection.smartReports}</Typography>
  </Stack>
);
