import { useState } from 'react';
import { Toolbar, Typography } from '@common-components';
import { useSearchLayer } from 'hooks';
import { Layer, PartialSubmission } from 'types';
import DataGridList, { DEFAULT_PAGE_SIZE } from 'components/DataGridList';
import LayerLink from './LayerLink';

export interface LayerListPageProps {
  submission: PartialSubmission;
}

export default function LayerList({ submission }: LayerListPageProps) {
  const [offset, setOffset] = useState<number>(0);

  const pageSize = DEFAULT_PAGE_SIZE;

  const {
    items: layers,
    count,
    isInitialLoading,
  } = useSearchLayer({
    filter: {
      offset,
      limit: pageSize,
      submissionId: submission?.id,
    },
  });

  const columns = [
    {
      field: 'attachmentPoint',
      headerName: 'Attachment Point',
      renderCell: ({ row }: { row: unknown }) =>
        LayerLink({
          layer: row as Layer,
        }),
      flex: 0.1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
    },
  ];

  return (
    <div>
      <Toolbar sx={{ padding: 1 }}>
        <Typography variant="h6" id="tableTitle" component="div">
          Layers
        </Typography>
      </Toolbar>
      <DataGridList
        columns={columns}
        items={layers}
        count={count}
        setOffset={setOffset}
        isLoading={isInitialLoading}
        pageSize={pageSize}
      />
    </div>
  );
}
