import { ChipProps, SvgIcon } from '@common-components';
import {
  Computer as ComputerIcon,
  EmailOutlined as EmailOutlinedIcon,
  OpenInNew as OpenInNewIcon,
  SettingsEthernet as SettingsEthernetIcon,
} from '@icons';
import { SubmissionMethod } from 'enums';
import { messages } from 'i18n';

const { api: apiTitle, portal: portalTitle, email: emailTitle } = messages.addProductsPage.methodsDropdown;

interface MethodConfigProps extends ChipProps {
  icon: typeof SvgIcon;
}

export const MethodsConfig: Record<SubmissionMethod, MethodConfigProps> = {
  [SubmissionMethod.API]: {
    color: 'green.600',
    textColor: 'common.white',
    startIcon: SettingsEthernetIcon,
    iconColor: 'common.white',
    label: apiTitle,
    variant: 'contained',
    size: 'small',
    icon: SettingsEthernetIcon,
  },
  [SubmissionMethod.Portal]: {
    color: 'purple.600',
    textColor: 'common.white',
    iconColor: 'common.white',
    startIcon: OpenInNewIcon,
    label: portalTitle,
    variant: 'contained',
    size: 'small',
    icon: ComputerIcon,
  },
  [SubmissionMethod.Email]: {
    color: 'secondary',
    startIcon: EmailOutlinedIcon,
    label: emailTitle,
    variant: 'contained',
    size: 'small',
    icon: EmailOutlinedIcon,
  },
};
