import { useCallback, useEffect, useState } from 'react';
import { Editor } from 'tinymce';
import { useToast } from 'hooks';
import { messages } from 'i18n';
import { Sender } from 'types';
import useEmailContentState from 'components/Editor/useEmailContentState';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';
import { Draft, RecipientGroupToDraft, TemplateInstance } from './store/types';

const emptyTemplateInstance = { subject: '', body: '', cc: [], from: undefined };

interface UseDraftEditingProps {
  selectedRecipientGroupId?: string;
  activeTemplateId?: string;
  activeTemplateInstance?: TemplateInstance;
  isActiveTemplateInstanceDetached?: boolean;
  recipientGroupToDraft: RecipientGroupToDraft;
  setRecipientGroupDetachedTemplateInstance: (
    recipientGroupId: string,
    templateInstance: TemplateInstance | undefined,
  ) => void;
  setRecipientGroupDraft: (recipientGroupId: string, draft: Draft) => void;
  setTemplateInstance: (templateId: string, newTemplateInstance: TemplateInstance) => void;
  setDraftEditorMode: (mode: DraftEditorMode) => void;
  resolveForEditForAll: (subject: string, body: string) => { subject: string; body: string };
}

export default function useDraftEditing({
  selectedRecipientGroupId,
  activeTemplateId,
  activeTemplateInstance,
  isActiveTemplateInstanceDetached,
  recipientGroupToDraft,
  setRecipientGroupDetachedTemplateInstance,
  setRecipientGroupDraft,
  setTemplateInstance,
  setDraftEditorMode,
  resolveForEditForAll,
}: UseDraftEditingProps) {
  const { showToast } = useToast();

  const {
    subject: templateInstanceSubject,
    body: templateInstanceBody,
    from: templateInstanceFrom,
  } = activeTemplateInstance ?? emptyTemplateInstance;

  const { subject: resolvedSubject, body: resolvedBody } = resolveForEditForAll(
    templateInstanceSubject,
    templateInstanceBody,
  );

  // Temporary template instance fields, to be used in 'Edit for All' mode, prior to saving.
  const {
    body: editingBody,
    setBody: setEditingBody,
    subject: editingSubject,
    setSubject: setEditingSubject,
    resetContent: resetEditingContent,
    isDirty: isEditingContentDirty,
    setIsDirty: setIsEditingContentDirty,
  } = useEmailContentState(resolvedSubject, resolvedBody);

  const [editingFrom, setEditingFrom] = useState<Sender | undefined>(templateInstanceFrom);

  const ownSetIsEditingContentDirty = useCallback(
    (state: boolean) => {
      setIsEditingContentDirty(state);
    },
    [setIsEditingContentDirty],
  );

  const resetDirtyState = () => {
    setDraftEditorMode(DraftEditorMode.Preview);
    ownSetIsEditingContentDirty(false);
  };

  useEffect(() => {
    setEditingFrom(templateInstanceFrom);
    ownSetIsEditingContentDirty(false);
  }, [ownSetIsEditingContentDirty, templateInstanceFrom]);

  const setDetachedTemplateInstanceField = (
    field: keyof TemplateInstance,
    value: TemplateInstance[keyof TemplateInstance],
  ) => {
    const recipientGroupDraft = recipientGroupToDraft[selectedRecipientGroupId!];

    setRecipientGroupDraft(selectedRecipientGroupId!, {
      ...recipientGroupDraft,
      detachedTemplateInstance: { ...activeTemplateInstance!, [field]: value },
    });
  };

  const fieldToValue = {
    subject: editingSubject,
    body: editingBody,
    from: editingFrom,
  };

  const fieldToSetter = {
    subject: setEditingSubject,
    body: setEditingBody,
    from: setEditingFrom,
  };

  const getActiveField = (field: keyof TemplateInstance) => {
    if (isActiveTemplateInstanceDetached) {
      return activeTemplateInstance![field];
    }

    return fieldToValue[field];
  };
  const getActiveSetter = (field: keyof TemplateInstance) => {
    if (isActiveTemplateInstanceDetached) {
      if (field === 'body') {
        return (value: TemplateInstance['body'], editor?: Editor) => {
          setDetachedTemplateInstanceField(field, value);
          if (editor?.isDirty()) {
            ownSetIsEditingContentDirty(true);
          }
        };
      }

      return (value: TemplateInstance[keyof TemplateInstance]) => setDetachedTemplateInstanceField(field, value);
    }

    return fieldToSetter[field];
  };

  const onEditForAllCancel = () => {
    setDraftEditorMode(DraftEditorMode.Preview);
    resetEditingContent();
    setEditingFrom(templateInstanceFrom);
    ownSetIsEditingContentDirty(false);
  };

  const onEditForAllSave = () => {
    setTemplateInstance(activeTemplateId!, {
      subject: editingSubject,
      body: editingBody,
      from: editingFrom,
    });

    resetEditingContent();
    resetDirtyState();
    showToast('success', { message: messages.emailEditor.changesSaved });
  };

  const onResetToTemplate = () => {
    setDraftEditorMode(DraftEditorMode.Preview);
    setRecipientGroupDetachedTemplateInstance(selectedRecipientGroupId!, undefined);
    ownSetIsEditingContentDirty(false);
    resetEditingContent();
  };

  return {
    subject: getActiveField('subject') as string,
    body: getActiveField('body') as string,
    from: getActiveField('from') as Sender,
    isEditingContentDirty,
    setSubject: getActiveSetter('subject') as (subject: string) => void,
    setBody: getActiveSetter('body') as (body: string) => void,
    setFrom: getActiveSetter('from') as (sender: Sender | undefined) => void,
    setIsEditingContentDirty,
    onEditForAllSave,
    onEditForAllCancel,
    onResetToTemplate,
    resetDirtyState,
  };
}
