import { Dispatch, SetStateAction } from 'react';
import { Button, MobileStepper } from '@common-components';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@icons';

interface DotsStepperProps {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  numberOfSteps: number;
}

export default function DotsStepper({ activeStep, setActiveStep, numberOfSteps }: DotsStepperProps) {
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <MobileStepper
      variant="dots"
      steps={numberOfSteps}
      position="static"
      activeStep={activeStep - 1}
      sx={{ flexGrow: 1 }}
      nextButton={
        <Button color="secondary" size="small" onClick={handleNext} disabled={activeStep >= numberOfSteps}>
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button color="secondary" size="small" onClick={handleBack} disabled={activeStep === 1}>
          <KeyboardArrowLeft />
        </Button>
      }
    />
  );
}
