import { useBoolean } from 'hooks';

import { messages } from 'i18n';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import useLoadFilesSelection from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Files/useLoadFilesSelection';
import EmptyStateScreen from './components/EmptyStateScreen';
import FilesViewer from './components/FilesViewer';
import { ProposalFlowStep, StepProps } from './types';

export default function FilesStep({
  onStepChange,
  handleSaveProposal,
  isDirty,
  setIsDirty,
  selectedFiles,
  submission,
}: StepProps) {
  const [isEmptyState, { off: setNotEmpty }] = useBoolean(!submission?.proposal);

  const onSetNotEmpty = () => {
    setIsDirty(true);
    setNotEmpty();
  };

  useLoadFilesSelection({
    setIsDirty,
    preSelectedFileIds: submission!.proposal?.attachedFiles,
  });

  const beforeNavigation = async () => {
    await handleSaveProposal({
      subject: submission.proposal?.subject || '',
      body: submission.proposal?.body || '',
      contactIds: submission.proposal?.contactIds || [],
      attachedFiles: selectedFiles.map((file) => file.id),
    });
    return true;
  };

  const { emptyState, filesStep } = messages.submissionWorkspace.tabs.proposal;
  return (
    <InnerPageLayout
      outerDirtyState={{ isDirty, setIsDirty }}
      beforeNavigation={beforeNavigation}
      title={isEmptyState ? emptyState.header : filesStep.header}
      {...(!isEmptyState && {
        footerButtons: {
          cancelButton: (innerPageProps) => ({
            children: messages.buttons.saveForLater,
            loading: false,
            onClick: () => {
              innerPageProps.onClose();
            },
          }),
          proceedButton: () => ({
            children: filesStep.proceedButton,
            onClick: async () => {
              await onStepChange(ProposalFlowStep.Email, {
                subject: submission.proposal?.subject || '',
                body: submission.proposal?.body || '',
                contactIds: submission.proposal?.contactIds || [],
                attachedFiles: selectedFiles.map((file) => file.id),
              });
            },
          }),
        },
      })}
    >
      {() => (isEmptyState ? <EmptyStateScreen setNotEmpty={onSetNotEmpty} /> : <FilesViewer />)}
    </InnerPageLayout>
  );
}
