import { useEffect, useRef } from 'react';
import { useBoolean, useHotjar } from 'hooks';
import { HotjarEvents } from 'utils/hotjar-events';
import { FilesUploaderRef } from 'broker/components/FilesExplorer/components/Content/FilesUploaderSection/FilesUploader';

interface ShowUploaderProps {
  isFolderEmpty: boolean;
}

export function useShowUploader({ isFolderEmpty }: ShowUploaderProps) {
  const filesUploaderRef = useRef<FilesUploaderRef | null>(null);
  const [uploaderShown, { off: hideUploader, on: showUploader, set: setUploaderVisible }] = useBoolean(false);
  const hotjar = useHotjar();

  useEffect(() => {
    if (isFolderEmpty) {
      showUploader();
      hotjar.event(HotjarEvents.AddFile);
    } else {
      hideUploader();
    }
  }, [hideUploader, hotjar, isFolderEmpty, showUploader]);

  const onCloseUploader = () => {
    filesUploaderRef.current?.clearFiles();
    hideUploader();
  };

  return {
    uploaderShown,
    showUploader,
    setUploaderVisible,
    onCloseUploader,
    filesUploaderRef,
  };
}
