import { MouseEvent } from 'react';
import { EditorEvent } from 'tinymce';

import { CAPITOLA_TINY_CLASS_PREFIX } from './consts';

// This function is climbing the tree of the clicked element and returns an array of all the class names
function climbTree(target: any, arr: string[]): string[] {
  if (typeof target.className === 'string') {
    target.className
      ?.trim()
      .split(' ')
      .forEach((className: string) => {
        arr.push(className);
      });
  }

  if (!target.parentNode) {
    return arr;
  }

  return climbTree(target.parentNode, arr);
}

/**
 * Returns an event handler function that extracts the Capitola classnames of a clicked element hierarchy.
 * @param callback - A mutable ref object containing the callback (since Tiny's reference doesn't change).
 */
export function classnameExtractingEventHandler(callback: (classname: string) => void) {
  return (event: EditorEvent<MouseEvent>) => {
    const classNames = climbTree(event.target, []);
    classNames
      .filter((className) => className.startsWith(CAPITOLA_TINY_CLASS_PREFIX))
      .forEach((className) => callback(className));
  };
}
