export enum HeraldQuoteStatus {
    Pending = 'pending',
    Referral = 'referral',
    Referred = 'referred',
    Declined = 'declined',
    Active = 'active',
    Expired = 'expired',
    Unresponsive = 'unresponsive',
    Unsupported = 'unsupported',
    Rejected = 'rejected',
}
