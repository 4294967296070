import { Button } from '@common-components';
import { ShareMenuAction } from 'enums';
import { messages } from 'i18n';
import { InnerPageFooter } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageFooter';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { ShareMenuButton } from './ShareMenuButton';

interface MarketingReportFooterProps extends InnerPageProps {
  isLoading: boolean;
  selectedCount: number;
  marketIdInEditMode: string | undefined;
  isShareEnabled: boolean;
  handleSaveData: () => Promise<void>;
  handleCopy: () => Promise<boolean>;
  handleSendEmail: () => Promise<boolean>;
  handleOnDownload: () => Promise<boolean>;
  availableActions: ShareMenuAction[];
}

export const MarketingReportFooter = ({
  onClose,
  isLoading,
  selectedCount,
  marketIdInEditMode,
  isShareEnabled,
  handleSaveData,
  handleCopy,
  handleSendEmail,
  handleOnDownload,
  availableActions,
}: MarketingReportFooterProps) => {
  const copyAction = async () => {
    const hasChanges = await handleCopy();
    if (!hasChanges) {
      onClose();
    }
  };

  const emailAction = handleSendEmail;

  const downloadAction = async () => {
    const hasChanges = await handleOnDownload();
    if (!hasChanges) {
      onClose();
    }
  };

  const actionsConfig = {
    [ShareMenuAction.Copy]: copyAction,
    [ShareMenuAction.Email]: emailAction,
    [ShareMenuAction.Download]: downloadAction,
  };

  const getMenuActions = () => {
    const menuActions: Partial<Record<ShareMenuAction, () => void>> = {};
    availableActions.forEach((action) => {
      menuActions[action] = actionsConfig[action];
    });
    return menuActions;
  };

  const getProceedButton = () =>
    !isShareEnabled ? (
      <Button
        variant="contained"
        loading={isLoading}
        disabled={isLoading || selectedCount === 0 || !!marketIdInEditMode}
        onClick={async () => {
          await handleSaveData();
          onClose();
        }}
      >
        {messages.marketingReportPage.save}
      </Button>
    ) : (
      <ShareMenuButton
        loading={isLoading}
        disabled={isLoading || selectedCount === 0 || !!marketIdInEditMode}
        menuActions={getMenuActions()}
      />
    );
  return (
    <InnerPageFooter>
      <Button variant="outlined" onClick={onClose}>
        {messages.buttons.cancel}
      </Button>
      {getProceedButton()}
    </InnerPageFooter>
  );
};
