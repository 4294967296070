import InsuranceProductClient from 'clients/capitola/insurance-product';
import { QueryKeys } from 'enums';
import { InsuranceProduct } from 'types';
import { UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useQuerySearch } from './query/useQuery';

export function useSearchInsuranceProduct(props?: UseQueryBaseSearchOptions<InsuranceProduct>) {
  const { filter, queryOptions, enabled } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.InsuranceProduct,
    clientClass: InsuranceProductClient,
    enabled,
    filter,
    queryOptions: {
      // Default userMarket search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateInsuranceProduct() {
  const { update: updateInsuranceProduct } = useMutate<
    InsuranceProduct,
    InsuranceProduct,
    InsuranceProduct,
    InsuranceProductClient
  >({
    queryKey: QueryKeys.InsuranceProduct,
    clientClass: InsuranceProductClient,
    createOptions: {
      throwOnError: true,
    },
    updateOptions: { throwOnError: true },
  });

  return { updateInsuranceProduct };
}
