import { EmailTemplateVariables } from '@common/email-variables';
import { useEffect, useState } from 'react';
import { useToast } from 'hooks';
import { messages } from 'i18n';
import { EmailTemplate } from 'types';
import useEmailContentState from 'components/Editor/useEmailContentState';
import { sanitizeMentions } from 'components/Editor/utils/mentions';
import { DirtyRouteGuard } from 'broker/components/DirtyContentGuard';
import { templateVariablesConfig } from 'broker/utils/email-utils/email-resolver-utils/template-variables-utils';
import Editor from './Components/Editor';
import EditorHeaderButtons from './Components/EditorHeaderButtons';

enum TemplateStatus {
  Editing = 'Editing',
  Saving = 'Saving',
  Saved = 'Saved',
}

export interface TemplateEditorProps {
  selectedTemplate: EmailTemplate;
  onSaveTemplate: (template: EmailTemplate) => Promise<void>;
}

export default function TemplateEditor({ selectedTemplate, onSaveTemplate }: TemplateEditorProps) {
  const {
    body,
    setBody,
    subject,
    setSubject,
    isDirty: isEmailContentDirty,
    setIsDirty,
  } = useEmailContentState(selectedTemplate.subject, selectedTemplate.body);
  const [templateStatus, setTemplateStatus] = useState<TemplateStatus>(TemplateStatus.Editing);

  const variableKeys = Object.values(EmailTemplateVariables).filter((variable) =>
    templateVariablesConfig[variable]?.supportedTemplate
      ? templateVariablesConfig[variable].supportedTemplate.includes(selectedTemplate.type)
      : true,
  );
  const { showToast } = useToast();

  const onSaveClick = async () => {
    setTemplateStatus(TemplateStatus.Saving);
    const parsedBody = sanitizeMentions(body);
    try {
      await onSaveTemplate({ ...selectedTemplate, subject, body: parsedBody });
      setTemplateStatus(TemplateStatus.Saved);
      setIsDirty(false);
    } catch {
      setTemplateStatus(TemplateStatus.Editing);
    }
  };

  const isDirty = templateStatus === TemplateStatus.Editing && isEmailContentDirty;

  // Show success toast upon save completion
  useEffect(() => {
    if (templateStatus === TemplateStatus.Saved) {
      showToast('success', { message: messages.templatesPage.emailTemplateSaved });
      setTemplateStatus(TemplateStatus.Editing);
    }
  }, [templateStatus, showToast]);

  const editorHeaderButtons = (
    <EditorHeaderButtons
      isLoading={templateStatus === TemplateStatus.Saving}
      disableSave={!isDirty}
      onSaveClick={onSaveClick}
    />
  );

  return (
    <DirtyRouteGuard isDirty={isDirty}>
      <Editor
        key={selectedTemplate.id}
        selectedTemplate={selectedTemplate}
        editorContent={body}
        setEditorContent={setBody}
        onDirty={() => setIsDirty(true)}
        subject={subject}
        setSubject={setSubject}
        headerButtons={editorHeaderButtons}
        variableKeys={variableKeys}
      />
    </DirtyRouteGuard>
  );
}
