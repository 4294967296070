import { Box, Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { CopilotSteps, PartialSubmission, SubmissionMarket } from 'types';
import { useAutonomousMarketInfo } from 'broker/dialogs/SubmissionAutonomousCreation/useAutonomousMarketInfo';
import useCopilotSettings from 'broker/dialogs/SubmissionAutonomousCreation/useCopilotSettings';
import AutonomousMarketsForm from './AutonomousMarketsForm';
import BulletContent from './BulletContent';

export default function Marketing({
  submission,
  submissionMarkets,
  active,
}: {
  submission: PartialSubmission;
  submissionMarkets: SubmissionMarket[];
  active: boolean;
}) {
  const copilotSettings = useCopilotSettings({ submission, copilotStep: CopilotSteps.Marketing });
  const marketingMessages = messages.autonomousFlow.settingsModal.steps.marketing;

  const marketingBulletMessages = marketingMessages.bullets;
  const initSubmissionMarkets = useAutonomousMarketInfo(submissionMarkets);

  return (
    <Stack>
      <Typography variant="body2Bold" color="text.primary" mb={1}>
        {marketingMessages.title2}
      </Typography>
      <Typography variant="caption" color="text.primary" mb={1}>
        {marketingMessages.form.title}
      </Typography>
      <Box mb={5}>
        <AutonomousMarketsForm submission={submission} initSubmissionMarkets={initSubmissionMarkets} active={active} />
      </Box>
      <Typography variant="body2Bold" color="text.primary" mb={1}>
        {marketingBulletMessages.title}
      </Typography>
      <BulletContent
        bold={marketingBulletMessages.notifiesTheAssignee.bold}
        suffix={marketingBulletMessages.notifiesTheAssignee.suffix}
      />
      <BulletContent
        prefix={marketingBulletMessages.premiumPercentChangeNotificationThreshold.prefix}
        bold={marketingBulletMessages.premiumPercentChangeNotificationThreshold.bold(
          copilotSettings?.premiumPercentChangeNotificationThreshold || 0,
        )}
      />
    </Stack>
  );
}
