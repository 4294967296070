import { IconButton, NewMenu } from '@common-components';
import { Sort as SortIcon } from '@icons';
import { OptionsMenuState } from 'hooks';
import { messages } from 'i18n';
import { SortBy } from './utils';

interface SortMenuProps {
  optionsMenuState: OptionsMenuState;
  handleSortBy: (sortBy: SortBy) => void;
}
export function SortMenu({ optionsMenuState, handleSortBy }: SortMenuProps) {
  const { date, organization } = messages.submissionWorkspace.emailsTab.sortMenu;

  return (
    <>
      <IconButton icon={SortIcon} color="secondary" variant="text" size="small" onClick={optionsMenuState.openMenu} />
      <NewMenu
        optionsMenuState={optionsMenuState}
        menuItems={[
          {
            key: 'sort-menu',
            items: [
              {
                label: organization.title,
                onClick: () => handleSortBy(SortBy.Organization),
              },
              {
                label: date.title,
                onClick: () => handleSortBy(SortBy.Date),
              },
            ],
          },
        ]}
      />
    </>
  );
}
