import { ReactNode } from 'react';
import { Box, Stack, styled } from '@common-components';
import { FeedbackForm } from './FeedbackForm/FeedbackForm';
import { FeedbackFormData } from './FeedbackForm/form-utils';

interface MessageActionsProps {
  actions: ReactNode[];
  children: ReactNode;
  showFeedbackForm?: boolean;
  onFeedbackSubmit?: (data: FeedbackFormData) => Promise<void>;
}

const ActionsOverlay = styled(Box)(({ theme }) => ({
  right: theme.spacing(5),
  borderRadius: '4px',
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(1),
  opacity: 0,
  visibility: 'hidden',
  transition: 'opacity 0.3s, visibility 0.3s',
  position: 'sticky',
  bottom: '10px',
  justifyContent: 'end',
}));

const HoverBox = styled(Box)({
  position: 'relative',
  '&:hover .hover-buttons': {
    opacity: 1,
    visibility: 'visible',
  },
});

export default function MessageActions({
  children,
  actions,
  showFeedbackForm = false,
  onFeedbackSubmit,
}: MessageActionsProps) {
  return (
    <HoverBox>
      {children}
      <ActionsOverlay className="hover-buttons">
        <Stack alignItems="flex-end" gap={1}>
          <Stack direction="row" gap={1}>
            {actions.map((action, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={index} boxShadow={4} borderRadius="50%">
                {action}
              </Box>
            ))}
          </Stack>
          {onFeedbackSubmit && showFeedbackForm && <FeedbackForm onSubmit={onFeedbackSubmit} />}
        </Stack>
      </ActionsOverlay>
    </HoverBox>
  );
}
