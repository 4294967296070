import styled from '@emotion/styled';
import { DataGrid, DataGridProps } from '@common-components';

export const StyledDataGrid = styled(DataGrid)<DataGridProps>(({ theme }: any) => ({
  border: '1px solid transparent',
  marginBottom: theme.spacing(3),
  '& .MuiDataGrid-cell': {
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    whiteSpace: 'normal',
    alignItems: 'flex-start',
  },

  '& .MuiDataGrid-main > div:nth-of-type(2)': {
    height: '100% !important',
  },
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: 0,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    padding: 0,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.caption.fontWeight,
    letterSpacing: theme.typography.caption.letterSpacing,
  },

  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important',
  },

  '& .MuiDataGrid-virtualScroller': {
    height: '100% !important',
  },

  '& .MuiDataGrid-virtualScrollerContent': {
    height: '100% !important',
    overflow: 'hidden',
  },

  '& .MuiDataGrid-virtualScrollerRenderZone': {
    position: 'initial',
  },

  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
    backgroundColor: theme.palette.common.white,
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.white,
    },
  },
  '& .MuiDataGrid-row:hover': {
    maxHeight: 'none !important',
    backgroundColor: theme.palette.common.white,
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export default StyledDataGrid;
