import { UserMarket } from 'types';
import RoutedDialog from 'broker/components/RoutedDialog';
import { RouteKey } from 'broker/enums';
import Content from './Content';

interface UserMarketDialogProps {
  onMarketCreated?: (market: UserMarket) => void;
  isLocationStateRequired?: boolean;
}

export default function UserMarketDialog({
  isLocationStateRequired = false,
  ...userMarketDialogProps
}: UserMarketDialogProps) {
  return (
    <RoutedDialog
      ariaLabelId="user-market-modal"
      routeKey={RouteKey.UserMarket}
      isLocationStateRequired={isLocationStateRequired}
    >
      {(props) => <Content {...props} {...userMarketDialogProps} />}
    </RoutedDialog>
  );
}
