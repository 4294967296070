import { Chip } from '@common-components';
import { FilterElementProps } from 'utils';

interface CategoriesFilterItemProps {
  category: FilterElementProps;
  handleVisibleLabelChange: (key: string) => void;
}

export default function CategoriesFilterItem({ category, handleVisibleLabelChange }: CategoriesFilterItemProps) {
  return (
    <Chip
      size="large"
      key={category.key}
      label={category.label}
      onClick={() => handleVisibleLabelChange(category.key)}
      color="secondary"
      focused={category.checked}
      variant="outlined"
    />
  );
}
