import { Editor as TinyMCEEditor } from 'tinymce';
import { Stack } from '@common-components';
import { uniteStyles } from 'utils';
import { Editor as EditorBody } from 'components/Editor/Editor';
import { ContentContainerStyles } from 'broker/components/EmailEditor/styles';
import { EditorHeader, EditorHeaderProps } from './EditorHeader';

interface EditorProps {
  editorContent?: string;
  setEditorContent: (newContent: string, editor: TinyMCEEditor) => void;
  onDirty?: () => void;
  variableKeys: string[];
}

export default function Editor({
  editorContent,
  setEditorContent,
  onDirty,
  variableKeys,
  ...editModeHeaderProps
}: EditorProps & EditorHeaderProps) {
  return (
    <Stack direction="column" sx={{ flex: 1 }} className="cap-template-editor">
      <EditorHeader {...editModeHeaderProps} />
      <Stack
        justifyContent="space-between"
        sx={uniteStyles(ContentContainerStyles, { overflow: 'auto', '& .css-1idnlok': { flex: 1, height: 'auto' } })}
      >
        <EditorBody
          editorContent={editorContent}
          setEditorContent={setEditorContent}
          onDirty={onDirty}
          editorFeatures={{
            mentionKeys: variableKeys,
          }}
        />
      </Stack>
    </Stack>
  );
}
