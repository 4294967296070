import { debounce } from 'lodash';
import { SnackbarKey } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { CloudOff } from '@icons';
import { messages } from 'i18n';
import { logger, Nullable } from 'utils';
import { useToast } from './useToast';

export function useOnlineStatus(isOnline: boolean, isWebsocketOnline: boolean) {
  const { showToast, closeSnackbar } = useToast();
  const offlineSnackbar = useRef<Nullable<SnackbarKey>>(null);
  const [isOffline, setIsOffline] = useState(false);
  const isFirstRun = useRef(true);

  useEffect(() => {
    const debouncedSetIsOffline = debounce(() => {
      setIsOffline(!isOnline || !isWebsocketOnline);
    }, 5000);

    if (isFirstRun.current) {
      // If it's the first execution, apply debounce
      debouncedSetIsOffline();
      isFirstRun.current = false; // Mark the first execution as done
    } else {
      // No debounce for subsequent executions
      setIsOffline(!isOnline || !isWebsocketOnline);
    }

    return () => {
      debouncedSetIsOffline.cancel();
    };
  }, [isOnline, isWebsocketOnline, setIsOffline]);

  useEffect(() => {
    if (isOffline) {
      offlineSnackbar.current = showToast(
        'info',
        {
          message: messages.errors.checkInternetConnection,
          title: messages.errors.youAreOffline,
        },
        {
          persist: true,
          key: `offline-snackbar`,
          preventDuplicate: true,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          autoHideDuration: null,
          dismissable: false,
          icon: CloudOff,
        },
      );
    } else if (offlineSnackbar.current) {
      closeSnackbar(offlineSnackbar.current);
      offlineSnackbar.current = null;
    }
  }, [showToast, closeSnackbar, isOffline]);

  useEffect(() => {
    if ((!isOnline || !isWebsocketOnline) && isOffline) {
      logger.log('info', { message: 'User is offline', isOnline, isWebsocketOnline });
    }
  }, [isOffline, isOnline, isWebsocketOnline]);
}
