import { buttonClasses, darken, Theme } from '@common-components';

export const fileCardStyle = {
  transition: 'background-color 0.3s',
  '&:hover,&.active': {
    backgroundColor: 'blue.50',
    transition: 'background-color 0.3s',
  },
  '&.selected': {
    backgroundColor: 'blue.50',
    transition: 'background-color 0.3s',
  },
};

export const actionButtonStyle = {
  textTransform: 'capitalize',
  borderWidth: 1,
  borderStyle: 'solid',
  fontSize: '0.8125rem',
  fontWeight: 600,
} as const;

export const cancelButtonStyle = {
  bgcolor: 'common.white',
  color: 'blue.700',
  '&:hover,&:active': {
    backgroundColor: 'blue.50',
    color: 'blue.700',
  },
} as const;

export const getSaveButtonStyle = (isSubmitting: boolean) => ({
  color: 'common.white',
  borderColor: 'blue.700',
  [`&.${buttonClasses.root}`]: {
    backgroundColor: isSubmitting ? 'transparent' : 'blue.700',
  },
  '&:hover,&:active': {
    bgcolor: 'blue.800',
    color: 'common.white',
  },
  '&:disabled': !isSubmitting
    ? {
        borderColor: 'grey.300',
        bgcolor: 'grey.300',
        color: 'grey.500',
      }
    : undefined,
});

export const getAddLabelButtonStyle = (theme: Theme) => ({
  marginRight: 'auto',
  borderRadius: 1,
  height: theme.spacing(3),
  border: `1px solid ${theme.palette.blue[800]}`,
  bgcolor: 'white',
  color: 'blue.800',
  p: 0.5,
  '&:hover': {
    backgroundColor: darken(theme.palette.blue[50]!, 0.05),
  },
});

export const labelChipStyle = {
  fontSize: 10,
  fontWeight: 600,
  backgroundColor: 'grey.100',
  borderRadius: 1,
  cursor: 'pointer',
  ml: 1,
  mt: -0.5, // align chip text with item title
  alignSelf: 'start',
};
