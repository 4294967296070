import { useQueryClient } from '@tanstack/react-query';
import { NotificationClient } from 'clients';
import { QueryKeys } from 'enums';
import { Notification, NotificationSearch } from 'types';
import { UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useQuerySearch } from './query/useQuery';

export function useSearchNotification(props?: UseQueryBaseSearchOptions<Notification, NotificationSearch>) {
  const { filter, queryOptions, enabled } = props || {};

  return useQuerySearch({
    queryKey: QueryKeys.Notification,
    clientClass: NotificationClient,
    filter,
    enabled,
    queryOptions: {
      // Default search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateNotification() {
  const { update: updateNotification } = useMutate({
    queryKey: QueryKeys.Notification,
    clientClass: NotificationClient,
    updateOptions: { throwOnError: true, passthroughStatusCodes: [409] },
  });

  return { updateNotification };
}

export function useInvalidateNotification() {
  const queryClient = useQueryClient();
  return async () => {
    await queryClient.invalidateQueries([QueryKeys.Notification]);
  };
}
