import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';

type ColumnConfigType = {
  title: string;
  flex: number | string;
  align?: 'left' | 'center' | 'right' | 'inherit' | 'justify';
  paddingX?: number;
};

export enum UserMarketColumns {
  ExpandContacts = 'ExpandContacts',
  MarketName = 'MarketName',
  Contacts = 'Contacts',
  Categories = 'Categories',
  RiskAppetite = 'RiskAppetite',
  Memo = 'Memo',
  LastUpdate = 'LastUpdate',
  MatchReason = 'MatchReason',
  MoreActions = 'MoreActions',
}

type UserMarketColumnsConfig = Record<UserMarketColumns, ColumnConfigType>;

export const columnConfig: UserMarketColumnsConfig = {
  [UserMarketColumns.ExpandContacts]: {
    title: '',
    flex: '0 0 36px',
    align: 'center',
  },
  [UserMarketColumns.MarketName]: {
    title: messages.marketsPage.columnHeaders.marketName,
    flex: '0 0 216px',
    align: 'left',
  },
  [UserMarketColumns.Contacts]: {
    title: messages.marketsPage.columnHeaders.contacts,
    flex: '0 0 95px',
    align: 'center',
  },
  [UserMarketColumns.MatchReason]: {
    title: messages.marketsPage.columnHeaders.matchReason,
    flex: 1,
    align: 'left',
  },
  [UserMarketColumns.RiskAppetite]: {
    title: messages.marketsPage.columnHeaders.riskAppetite,
    flex: '0 0 122px',
    align: 'center',
  },
  [UserMarketColumns.Memo]: {
    title: messages.marketsPage.columnHeaders.memo,
    flex: '0 0 81px',
    align: 'center',
  },
  [UserMarketColumns.Categories]: {
    title: messages.marketsPage.columnHeaders.categories,
    flex: 1,
    align: 'left',
    paddingX: 1,
  },
  [UserMarketColumns.LastUpdate]: {
    title: messages.marketsPage.columnHeaders.lastUpdate,
    flex: '0 0 97px',
    align: 'left',
  },
  [UserMarketColumns.MoreActions]: {
    title: '',
    flex: '0 0 40px',
    align: 'left',
  },
};

interface MarketsTableHeaderProps {
  selectMode?: boolean;
}

export default function MarketsTableHeader({ selectMode }: MarketsTableHeaderProps) {
  return (
    <Stack
      bgcolor="common.white"
      position="sticky"
      top={0}
      zIndex={(theme) => theme.zIndex.appBar}
      direction="row"
      alignItems="center"
      flex={0}
      p={1.5}
      height={52}
      gap={3}
      borderBottom={1}
      borderColor="divider"
    >
      {Object.keys(columnConfig)
        .filter((key) => (selectMode ? key !== UserMarketColumns.LastUpdate : key !== UserMarketColumns.MatchReason))
        .map((key) => {
          const column = columnConfig[key as UserMarketColumns];
          return (
            <Typography
              variant="body2"
              component="div"
              color="typography.nonEssential"
              align={column.align}
              flex={column.flex}
              paddingX={column.paddingX}
              key={key}
            >
              {column.title}
            </Typography>
          );
        })}
    </Stack>
  );
}
