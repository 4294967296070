import { GridColDef, Typography } from '@common-components';
import { messages } from 'i18n';

function getColumns(): GridColDef[] {
  return [
    {
      field: 'layer',
      headerName: messages.ConceptualTowerPage.columnHeaders.layer,
      flex: 2,
      renderCell: (cellValue) => <Typography variant="body2">{cellValue.value}</Typography>,
      sortable: false,
    },
    {
      field: 'status',
      headerName: messages.ConceptualTowerPage.columnHeaders.status,
      flex: 1,
      renderCell: (cellValue) => <Typography variant="body2">{cellValue.value}</Typography>,
      sortable: false,
    },
    {
      field: 'limit',
      headerName: messages.ConceptualTowerPage.columnHeaders.limit,
      flex: 1,
      renderCell: (cellValue) => <Typography variant="body2">{cellValue.value}</Typography>,
      sortable: false,
    },
    {
      field: 'marketName',
      headerName: messages.ConceptualTowerPage.columnHeaders.marketName,
      flex: 1,
      renderCell: (cellValue) => <Typography variant="body2">{cellValue.value}</Typography>,
      sortable: false,
    },
    {
      field: 'isAdmitted',
      headerName: messages.ConceptualTowerPage.columnHeaders.admitted,
      flex: 1,
      renderCell: (cellValue) => <Typography variant="body2">{cellValue.value}</Typography>,
      sortable: false,
    },
    {
      field: 'premium',
      headerName: messages.ConceptualTowerPage.columnHeaders.premium,
      flex: 1,
      renderCell: (cellValue) => <Typography variant="body2">{cellValue.value}</Typography>,
      sortable: false,
    },
    {
      field: 'rpm',
      headerName: messages.ConceptualTowerPage.columnHeaders.rpm,
      flex: 1,
      renderCell: (cellValue) => <Typography variant="body2">{cellValue.value}</Typography>,
      sortable: false,
    },
  ];
}

export default getColumns;
