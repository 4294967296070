import { Business, EmailOutlined, Home, People, Report, Storage, Work } from '@icons';
import { UserRole } from 'enums';
import { messages } from 'i18n';
import { RouteDefinitionMap } from 'types';
import ADMIN_ROUTES from './admin-routes';
import { ConnectNylasV3Users } from './connect-nylas-v3-users';
import AddEmailTemplatePage from './pages/AddEmailTemplatePage';
import ContactListPage from './pages/ContactListPage';
import DbDumpsList from './pages/DbDumpsList';
import DefaultEmailTemplatesPage from './pages/DefaultEmailTemplatesPage';
import DlqStatusPage from './pages/DlqStatusPage';
import EditEmailTemplatePage from './pages/EditEmailTemplatePage';
import EmailTemplatesPage from './pages/EmailTemplatesPage';
import HomePage from './pages/HomePage';
import LayerPage from './pages/LayerPage';
import OrganizationListPage from './pages/OrganizationListPage';
import OrganizationPage from './pages/OrganizationPage';
import SubmissionListPage from './pages/SubmissionListPage';
import SubmissionPage from './pages/SubmissionPage';
import { TeamPage } from './pages/TeamPage';
import UserListPage from './pages/UserListPage';
import UserPage from './pages/UserPage';

const adminRoles = [UserRole.Admin];

const adminRoutes: RouteDefinitionMap = {
  [ADMIN_ROUTES.HOME]: {
    Component: HomePage,
    roles: adminRoles,
    menuItem: {
      Icon: Home,
      label: messages.menu.home,
      path: ADMIN_ROUTES.HOME,
    },
    isHomePage: true,
  },
  [ADMIN_ROUTES.ORGANIZATION]: {
    Component: OrganizationListPage,
    roles: adminRoles,
    menuItem: {
      Icon: Work,
      label: messages.menu.organizations,
      path: ADMIN_ROUTES.ORGANIZATION,
    },
  },
  [`${ADMIN_ROUTES.ORGANIZATION}/:id`]: {
    Component: OrganizationPage,
    roles: adminRoles,
  },
  [`${ADMIN_ROUTES.TEAM}/:id`]: {
    Component: TeamPage,
    roles: adminRoles,
  },
  [ADMIN_ROUTES.SUBMISSION]: {
    Component: SubmissionListPage,
    roles: adminRoles,
    menuItem: {
      Icon: Business,
      label: messages.menu.submissions,
      path: ADMIN_ROUTES.SUBMISSION,
    },
  },
  [`${ADMIN_ROUTES.SUBMISSION}/:id`]: {
    Component: SubmissionPage,
    roles: adminRoles,
  },
  [ADMIN_ROUTES.USER]: {
    Component: UserListPage,
    roles: adminRoles,
    menuItem: {
      Icon: People,
      label: messages.menu.users,
      path: ADMIN_ROUTES.USER,
    },
  },
  [`${ADMIN_ROUTES.USER}/:id`]: {
    Component: UserPage,
    roles: adminRoles,
  },
  [`${ADMIN_ROUTES.UserContacts}/:id`]: {
    Component: ContactListPage,
    roles: adminRoles,
  },
  [`${ADMIN_ROUTES.LAYER}/:id`]: {
    Component: LayerPage,
    roles: adminRoles,
  },
  [`${ADMIN_ROUTES.EMAIL_TEMPLATES_BY_USER}/:id`]: {
    Component: EmailTemplatesPage,
    roles: adminRoles,
  },
  [`${ADMIN_ROUTES.EMAIL_TEMPLATE}/:userId/:id`]: {
    Component: EditEmailTemplatePage,
    roles: adminRoles,
  },
  [`${ADMIN_ROUTES.EMAIL_TEMPLATE}/:userId/new`]: {
    Component: AddEmailTemplatePage,
    roles: adminRoles,
  },
  [ADMIN_ROUTES.DEFAULT_EMAIL_TEMPLATES]: {
    Component: DefaultEmailTemplatesPage,
    roles: adminRoles,
    menuItem: {
      Icon: EmailOutlined,
      label: messages.menu.defaultTemplates,
      path: ADMIN_ROUTES.DEFAULT_EMAIL_TEMPLATES,
    },
  },
  [`${ADMIN_ROUTES.DB_DUMPS}`]: {
    Component: DbDumpsList,
    roles: [...adminRoles, UserRole.BI],
    menuItem: {
      Icon: Storage,
      label: messages.menu.bi,
      path: `${ADMIN_ROUTES.DB_DUMPS}`,
    },
  },
  [`${ADMIN_ROUTES.DLQ_STATUS}`]: {
    Component: DlqStatusPage,
    roles: [...adminRoles],
    menuItem: {
      Icon: Report,
      label: messages.menu.dlqStatus,
      path: `${ADMIN_ROUTES.DLQ_STATUS}`,
    },
  },
  '/admin/migration/connect-nylas-v3-users': {
    Component: ConnectNylasV3Users,
    roles: adminRoles,
  },
};

export default adminRoutes;
