import { ReactNode } from 'react';
import { IconButton, Stack, Tooltip, Typography } from '@common-components';
import { West as WestIcon } from '@icons';
import { messages } from 'i18n';
import { truncateMultiLineText } from 'utils';
import { InnerPageProps } from './types';

export interface PageHeaderProps {
  onBack: () => void;
  title: string;
  subTitle?: string | undefined;
  innerPageProps?: InnerPageProps;
  headerSideContent?: (innerPageProps: InnerPageProps) => ReactNode;
  headerTopContent?: (innerPageProps: InnerPageProps) => ReactNode;
  headerBottomContent?: (innerPageProps: InnerPageProps) => ReactNode;
  shadowed?: boolean;
  hideBackButton?: boolean;
}

export function InnerPageHeader({
  onBack,
  title,
  subTitle,
  innerPageProps,
  headerSideContent,
  headerTopContent,
  headerBottomContent,
  shadowed,
  hideBackButton,
}: PageHeaderProps) {
  return (
    <>
      {headerTopContent && innerPageProps && <Stack>{headerTopContent(innerPageProps)}</Stack>}
      <Stack
        px={3}
        py={1.5}
        minHeight={64}
        justifyContent="center"
        sx={
          shadowed
            ? {
                position: 'relative',
                boxShadow: '0px 2px 3px 0px #0000001A, 0px 1px 1px 0px #0000000F',
              }
            : {}
        }
      >
        <Stack flexDirection="row" gap={2} alignItems="center">
          {!hideBackButton && (
            <IconButton tooltipContent={messages.buttons.back} color="secondary" onClick={onBack} icon={WestIcon} />
          )}
          <Stack overflow="hidden">
            <Typography variant="subtitle2">{title}</Typography>
            {subTitle && (
              <Tooltip tooltipContent={subTitle}>
                <Typography
                  overflow="hidden"
                  sx={{ ...truncateMultiLineText(2) }}
                  variant="caption"
                  color="text.secondary"
                >
                  {subTitle}
                </Typography>
              </Tooltip>
            )}
          </Stack>
          {innerPageProps && headerSideContent && <Stack flex={1}>{headerSideContent(innerPageProps)}</Stack>}
        </Stack>
        {innerPageProps && headerBottomContent && <Stack mt={4}>{headerBottomContent(innerPageProps)}</Stack>}
      </Stack>
    </>
  );
}
