import { SubmissionMarketRequestStatus } from 'enums';
import { messages } from 'i18n';
import theme from 'themes';
import { getQuoteExpirationSubtitle, getQuoteReadyColor } from './components/common/MarketRequestItem/utils';
import { MarketStatusConfigProps } from './ui-store/types';

type SubmissionMarketStatusConfig = Record<SubmissionMarketRequestStatus, MarketStatusConfigProps>;

export const marketRequestsStatusConfig: SubmissionMarketStatusConfig = {
  [SubmissionMarketRequestStatus.PolicyIssued]: {
    color: theme.palette.aquaGreen[100],
    title: messages.market.status.policyIssued,
    sortPriority: 1,
  },
  [SubmissionMarketRequestStatus.AwaitingPolicy]: {
    color: theme.palette.aquaGreen[100],
    title: messages.market.status.awaitingPolicy,
    sortPriority: 2,
  },
  [SubmissionMarketRequestStatus.Bound]: {
    color: theme.palette.aquaGreen[100],
    title: messages.market.status.bound,
    sortPriority: 3,
  },
  [SubmissionMarketRequestStatus.AwaitingBinder]: {
    color: theme.palette.aquaGreen[100],
    title: messages.market.status.awaitingBinder,
    sortPriority: 4,
  },
  [SubmissionMarketRequestStatus.QuoteReady]: {
    color: theme.palette.aquaGreen[100],
    getContextualColor: getQuoteReadyColor,
    title: messages.market.status.QuoteReady,
    getSubtitle: getQuoteExpirationSubtitle,
    sortPriority: 5,
  },
  [SubmissionMarketRequestStatus.PendingCustomer]: {
    color: theme.palette.peachOrange[100],
    title: messages.market.status.pendingCustomer,
    sortPriority: 6,
  },
  [SubmissionMarketRequestStatus.AwaitingQuote]: {
    color: theme.palette.goldYellow[100],
    title: messages.market.status.awaitingQuote,
    sortPriority: 7,
  },
  [SubmissionMarketRequestStatus.ReadyToMarket]: {
    color: theme.palette.teal[100],
    title: messages.market.status.readyToMarket,
    sortPriority: 8,
  },
  [SubmissionMarketRequestStatus.QuoteNotAccepted]: {
    color: theme.palette.coralRed[100],
    title: messages.market.status.unaccepted,
    sortPriority: 9,
  },
  [SubmissionMarketRequestStatus.Declined]: {
    color: theme.palette.coralRed[100],
    title: messages.market.status.declined,
    sortPriority: 10,
  },
};
