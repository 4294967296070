import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Box, LoaderOverlay, Stack, Typography } from '@common-components';
import { useCompareQuotes } from 'hooks';
import { messages } from 'i18n';
import { Quote } from 'types';
import { markdownToHtml } from 'utils';
import FormEditor from 'components/hookFormComponents/FormEditor';
import { AIButtonWithPopup } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIButtonWithPopup';
import { InsightPopulatedByAI } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/components/types';
import { insightsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/form-methods/config/insightsFieldsConfig';
import { InsightsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/form-methods/enums';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { useCreateAIThread } from 'broker/pages/SubmissionWorkspacePage/hooks';
import { QuotesList } from './QuotesList';

interface ComposeProps {
  quotes: Quote[];
  setInsightPopulatedByAI: (insight: InsightPopulatedByAI) => void;
  expiringPolicyFileId?: string;
}

export default function Compose({ quotes, setInsightPopulatedByAI, expiringPolicyFileId }: ComposeProps) {
  const { compareQuotes } = useCompareQuotes();
  const {
    title,
    loading,
    quoteComparisonInsights: { popupTitle, generatingQuoteComparisonInsights },
  } = messages.quoteComparisonPage.compose;

  const { setValue, getValues } = useFormContext();

  const convertToHtml = (content: string) => markdownToHtml(content);

  const setEditorContent = (content: string) => {
    setValue(insightsFormFieldsConfig[InsightsFieldsNames.Insights].name, content);
  };

  const isEmptyQuoteInsights = isEmpty(getValues(insightsFormFieldsConfig[InsightsFieldsNames.Insights].name));

  const { isLoading: isQuoteInsightsLoading, isContentStreaming: isQuoteInsightsStreaming } = useAIContext();

  const showQuoteInsightsLoader = isEmptyQuoteInsights && (isQuoteInsightsLoading || isQuoteInsightsStreaming);

  const isDisabled = showQuoteInsightsLoader;

  const aiInputData = { quoteIds: quotes.map((quote) => quote.id), expiringPolicyFileId };

  useCreateAIThread(
    () => compareQuotes(aiInputData),
    undefined,
    (result) => {
      if (result) {
        if (isEmptyQuoteInsights) {
          const htmlContent = convertToHtml(result?.response);
          setEditorContent(htmlContent);
          setInsightPopulatedByAI({
            result: htmlContent,
            input: aiInputData,
          });
        }
      }
    },
  );

  return (
    <Stack pt={4} px={6} height={1}>
      <QuotesList quotes={quotes} expiringPolicyFileId={expiringPolicyFileId} />
      <Stack mt={3} height={1}>
        <Typography variant="body2Bold" component="div" mb={1}>
          {title}
        </Typography>
        <Box position="relative" height={1}>
          <FormEditor
            disabled={isDisabled}
            {...insightsFormFieldsConfig[InsightsFieldsNames.Insights]}
            excludeToolbarProps={['fontfamily']}
          />
          {showQuoteInsightsLoader && <LoaderOverlay text={loading} />}
        </Box>
      </Stack>
      <AIButtonWithPopup popupTitle={popupTitle} tooltipText={generatingQuoteComparisonInsights} />
    </Stack>
  );
}
