import { uniteStyles } from 'utils';
import { Skeleton, SkeletonProps } from './mui-index';

interface TextSkeletonProps extends SkeletonProps {
  isLoading: boolean;
  index?: number;
}

export default function BoxSkeleton({ children, isLoading, index = 0, sx = {}, ...props }: TextSkeletonProps) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          {...props}
          sx={uniteStyles(
            {
              borderRadius: 1,
              maxWidth: 'none',
              '&::after': {
                animationDelay: `${index * 300}ms`,
              },
            },
            sx,
          )}
        >
          {children}
        </Skeleton>
      ) : (
        children
      )}
    </>
  );
}
