import { ActivityType } from 'enums';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import { SubmissionMarket } from 'types';
import { copyHtmlToClipboard } from 'utils';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { expiringTowerHtmlTable } from 'broker/utils/email-utils/email-resolver-utils/template-variables-utils';

import ExpiringTowerDataGrid from './components/ExpiringTowerDataGrid';
import { ExpiringTowerFooter } from './ExpiringTowerFooter';
import { ExpiringTowerLocationState } from './types';
import useReportRows from './useReportRows';

interface ExpiringTowerContentProps {
  submissionMarkets: SubmissionMarket[];
  locationState?: ExpiringTowerLocationState;
}

export default function Content({ submissionMarkets }: ExpiringTowerContentProps) {
  const [isLoading, { on: showLoader, off: hideLoader }] = useBoolean(false);
  const { rowsData } = useReportRows(submissionMarkets);
  const actionWithLoader = async (action: () => Promise<void>) => {
    showLoader();
    try {
      await action();
    } finally {
      hideLoader();
    }
  };
  const { logSubmissionActivity } = useSubmissionsWorkspace();
  const handleCopy = async () => {
    const html = expiringTowerHtmlTable(submissionMarkets);
    await copyHtmlToClipboard(html);
    await logSubmissionActivity({ activityType: ActivityType.ExpiringTowerCopiedToClipboard }, true);
  };

  return (
    <InnerPageLayout
      footer={(innerPageProps: InnerPageProps) => (
        <ExpiringTowerFooter
          isLoading={isLoading}
          handleCopy={() => actionWithLoader(handleCopy)}
          {...innerPageProps}
        />
      )}
      title={messages.expiringTowerPage.viewMode.title}
      subTitle={messages.expiringTowerPage.viewMode.subtitle}
      className="cap-expiring-tower"
      sx={{ padding: 0, margin: 0 }}
    >
      {() => <ExpiringTowerDataGrid reportRows={rowsData} />}
    </InnerPageLayout>
  );
}
