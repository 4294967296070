import styled from '@emotion/styled';
import { DataGrid, DataGridProps } from '@common-components';

interface StyledDataGridProps extends DataGridProps {
  theme?: any;
  boldLastRow?: boolean;
}

export const StyledDataGrid = styled(DataGrid)<StyledDataGridProps>(({ theme, boldLastRow = true }) => ({
  border: '1px solid transparent',

  '& .MuiDataGrid-row:last-of-type': {
    '& .MuiTypography-root': {
      fontWeight: boldLastRow ? 600 : 400,
    },
  },

  '& .MuiDataGrid-cell': {
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    whiteSpace: 'normal',
  },

  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: 0,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    ...theme.typography.captionBold,
    padding: 0,
    letterSpacing: theme.typography.caption.letterSpacing,
  },

  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important',
  },

  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
  },
  '& .MuiDataGrid-row:hover': {
    maxHeight: 'none !important',
    backgroundColor: theme.palette.common.white,
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.white,
    },
  },

  '& .MuiDataGrid-columnHeader ,& .MuiDataGrid-cell': {
    paddingLeft: 30,
    paddingRight: 30,
  },
}));

export default StyledDataGrid;
