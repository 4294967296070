import { EmailTemplateVariables } from '@common/email-variables';
import { smartObjectsConfig } from 'broker/utils/email-utils/SmartVariable/smart-objects-config';
import ConceptualTowerImage from './conceptual-tower.png';
import InformationRequestImage from './information-request.png';
import MarketingReportImage from './marketing-report.png';

export default {
  [smartObjectsConfig[EmailTemplateVariables.conceptualTower].key]: ConceptualTowerImage,
  [smartObjectsConfig[EmailTemplateVariables.marketingReport].key]: MarketingReportImage,
  [smartObjectsConfig[EmailTemplateVariables.informationRequested].key]: InformationRequestImage,
};
