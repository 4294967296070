import { Dispatch, useCallback } from 'react';
import { InsuranceProduct } from 'types';
import {
  ActionTypes,
  HandleFilterChangePayload,
  IsAllFilterPayload,
  SearchPayload,
  SetFilesExplorerPayload,
  SortTypePayload,
  StartMarketingIndicatorPayload,
  SuggestionsSubmissionStatusPayload,
  SyncSelectedFiles,
  ToolboxOverlayPayload,
  ToolboxStatePayload,
  UiStoreActions,
} from './types';

export default function useUiStoreActions(dispatch: Dispatch<ActionTypes>) {
  const setActiveOverlayItem = useCallback(
    (payload: ToolboxOverlayPayload) => {
      dispatch({ type: 'TOOLBOX_OVERLAY_SET_ACTIVE_ITEM', payload });
    },
    [dispatch],
  );

  const setMarketingSearch = useCallback(
    (payload: SearchPayload) => {
      dispatch({ type: 'SET_MARKETING_SEARCH', payload });
    },
    [dispatch],
  );

  const setMarketingFilters = useCallback(() => {
    dispatch({ type: 'SET_MARKETING_FILTERS' });
  }, [dispatch]);

  const setMarketingIsAllFilters = useCallback(
    (payload: IsAllFilterPayload) => {
      dispatch({ type: 'SET_PAGE_IS_ALL_FILTERS', payload });
    },
    [dispatch],
  );

  const setMarketingSortType = useCallback(
    (payload: SortTypePayload) => {
      dispatch({ type: 'SET_MARKETING_SORT_TYPE', payload });
    },
    [dispatch],
  );
  const handleMarketingFilterChange = useCallback(
    (payload: HandleFilterChangePayload) => {
      dispatch({ type: 'HANDLE_MARKETING_FILTERS_CHANGE', payload });
    },
    [dispatch],
  );

  const setFilesExplorer = useCallback(
    (payload: SetFilesExplorerPayload) => {
      dispatch({ type: 'SET_FILES_EXPLORER', payload });
    },
    [dispatch],
  );

  const updateSelectedFilesMetadata = useCallback(
    (payload: SyncSelectedFiles) => {
      dispatch({ type: 'SYNC_SELECTED_FILES', payload });
    },
    [dispatch],
  );

  const setToolboxState = useCallback(
    (payload: ToolboxStatePayload) => {
      dispatch({ type: 'SET_TOOLBOX_STATE', payload });
    },
    [dispatch],
  );

  const setStartMarketingIndicator = useCallback(
    (payload: StartMarketingIndicatorPayload) => {
      dispatch({ type: 'SET_START_MARKETING_INDICATOR', payload });
    },
    [dispatch],
  );

  const setSuggestedSubmissionStatus = useCallback(
    (payload: SuggestionsSubmissionStatusPayload) => {
      dispatch({ type: 'SET_SUGGESTED_SUBMISSION_STATUS', payload });
    },
    [dispatch],
  );

  const setPasteSmartObjectIndicator = useCallback(
    (payload: boolean) => {
      dispatch({ type: 'SET_PASTE_SMART_OBJECT_INDICATOR', payload });
    },
    [dispatch],
  );

  const registerInnerPage = useCallback(
    (payload: string) => {
      dispatch({ type: 'REGISTER_INNER_PAGE', payload });
    },
    [dispatch],
  );

  const unregisterInnerPage = useCallback(
    (payload: string) => {
      dispatch({ type: 'UNREGISTER_INNER_PAGE', payload });
    },
    [dispatch],
  );

  const setSelectedProductsSubmitApi = useCallback(
    (payload: InsuranceProduct[]) => {
      dispatch({ type: 'SET_SELECTED_PRODUCTS_SUBMIT_API', payload });
    },
    [dispatch],
  );

  const clearSelectedProductsSubmitApi = useCallback(() => {
    dispatch({ type: 'CLEAR_SELECTED_PRODUCTS_SUBMIT_API' });
  }, [dispatch]);

  const resetFilesExplorer = useCallback(() => {
    dispatch({
      type: 'SET_FILES_EXPLORER',
      payload: {
        selectionMode: false,
        selectedFiles: [],
        isSelectedFilesDirty: false,
        preSelectedFileIds: [],
        filter: {},
        isLoaded: false,
        highlightFileTypes: undefined,
        viewOnlyMode: false,
      },
    });
  }, [dispatch]);

  const modalAction: UiStoreActions = {
    setActiveOverlayItem,
    setMarketingSearch,
    setToolboxState,
    setMarketingFilters,
    setMarketingIsAllFilters,
    setMarketingSortType,
    handleMarketingFilterChange,
    setFilesExplorer,
    resetFilesExplorer,
    syncSelectedFiles: updateSelectedFilesMetadata,
    setStartMarketingIndicator,
    setPasteSmartObjectIndicator,
    setSuggestedSubmissionStatus,
    registerInnerPage,
    unregisterInnerPage,
    setSelectedProductsSubmitApi,
    clearSelectedProductsSubmitApi,
  };

  return modalAction;
}
