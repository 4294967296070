import { ReactNode } from 'react';
import { Stack } from '@common-components';

const pageHeaderHeight = 72;

const pageHeaderStyles = {
  flexDirection: 'row',
  spacing: 2,
  px: 3,
  bgcolor: 'common.white',
  boxShadow: 4,
  height: pageHeaderHeight,
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: `0 0 ${pageHeaderHeight}px`,
};

export default function PageHeader({ children }: { children: ReactNode }) {
  return <Stack sx={pageHeaderStyles}>{children}</Stack>;
}
