import { useBoxApi } from 'hooks/api/box';

export function useGetBoxPdfRepresentationAsBlob() {
  const { getFilePdfRepresentations, downloadFileRepresentation } = useBoxApi();

  return async (boxId: string) => {
    const filePdfRepresentations = await getFilePdfRepresentations(boxId);
    if (filePdfRepresentations.representations?.entries.length) {
      const pdfRepresentation = filePdfRepresentations.representations.entries.find(
        (entry) => entry.representation === 'pdf',
      );
      if (pdfRepresentation) {
        // trying to get a non page pdf representation of the file from box - https://developer.box.com/guides/representations/download-a-representation/#non-paginated-representations
        return downloadFileRepresentation(pdfRepresentation.content.url_template.replace('{+asset_path}', ''));
      }
    }
    return undefined;
  };
}
