import { NotificationType } from '../enums';
import { messages } from '../messages';

export type NotificationTypeConfigProps = {
    text: string;
    isSOP?: boolean;
};

const notificationTypesMessages = messages.notifications.type;

export const notificationTypeConfig: Record<NotificationType, NotificationTypeConfigProps> = {
    [NotificationType.UnrepliedEmail]: {
        text: notificationTypesMessages.followUp,
    },
    [NotificationType.ShareProposal]: {
        text: notificationTypesMessages.proposal,
        isSOP: true,
    },
    [NotificationType.ShareAcknowledgment]: {
        text: notificationTypesMessages.acknowledgment,
        isSOP: true,
    },
    [NotificationType.ShareMarketingUpdate]: {
        text: notificationTypesMessages.marketingUpdate,
        isSOP: true,
    },
};
