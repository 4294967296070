import { useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { Divider, Stack, Typography } from '@common-components';
import { EmailMessageContentType, EmailMessageRecipientType } from 'enums';
import { useCurrentUser, useFormProvider, useToast } from 'hooks';
import { useMutateEmailMessageBatch } from 'hooks/api/emailMessagesBatch';
import { messages } from 'i18n';
import { EmailMessageCreate } from 'types';
import { htmlToPlain } from 'utils';
import { DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { DirtyDialogGuardAttributes } from 'broker/components/DirtyContentGuard';
import { InviteFormData, schema } from './utils';

export default function Content({ closeAttempt, closeWithoutPrompt, setIsContentDirty }: DirtyDialogGuardAttributes) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showToast } = useToast();
  const { me } = useCurrentUser();

  const { createEmailMessageBatch } = useMutateEmailMessageBatch();
  const { methods } = useFormProvider<InviteFormData>({
    schema,
    setIsDirty: setIsContentDirty,
  });

  if (!me) {
    return null;
  }

  const { title, description, recipientName, getUserContactDetails } = messages.InviteModal;
  const { supportMail } = messages.general;
  const { firstName, lastName, email } = me;
  const teamName = me.team.name;
  const organizationName = me.organization.name;

  const onSubmit: SubmitHandler<InviteFormData> = async (data) => {
    setIsSubmitting(true);
    const userContactDetails: string = getUserContactDetails(firstName, lastName, email, organizationName);
    const message =
      userContactDetails + messages.InviteModal.getInviteMessage(organizationName, data.name, data.email, teamName);
    const messageToSend: EmailMessageCreate = {
      subject: messages.InviteModal.getInviteEmailSubject(teamName ?? '--'),
      contents: [
        {
          type: EmailMessageContentType.HTML,
          value: message,
        },
        {
          type: EmailMessageContentType.PLAIN,
          value: htmlToPlain(message),
        },
      ],
      recipients: [{ type: EmailMessageRecipientType.To, address: supportMail, name: recipientName }],
    };
    try {
      await createEmailMessageBatch.mutateAsync({
        data: {
          messages: [messageToSend],
        },
      });

      setIsSubmitting(false);
      showToast('success', { message: messages.InviteModal.sendingSuccessfully });
      closeWithoutPrompt();
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const onClose = () => {
    closeAttempt();
    methods.reset();
  };

  return (
    <>
      <DialogHeader title={title} id="header-invite-dialog" onClose={onClose} />
      <DialogContent>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
          {description}
        </Typography>
        <Divider />
        <FormProvider {...methods}>
          <Stack mt={3}>
            <FormTextField label={messages.myTeamPage.emailLabel} name="email" id="email" />
            <FormTextField label={messages.myTeamPage.nameLabel} name="name" id="name" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: messages.InviteModal.sendButton,
          onClick: methods.handleSubmit(onSubmit),
          loading: isSubmitting,
        }}
        cancelButton={{ children: messages.buttons.cancel, onClick: onClose }}
      />
    </>
  );
}
