import { useEffect, useRef, useState } from 'react';
import { ActivityType, ShareMenuAction } from 'enums';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import { copyHtmlToClipboard } from 'utils';
import { EmailEditorPageMode, RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import {
  informationRequestedReportHtmlTable,
  marketingReportHtmlTable,
} from 'broker/utils/email-utils/email-resolver-utils/template-variables-utils';
import MarketingReportContent from './components/MarketingReportContent';
import { MarketingReportFooter } from './MarketingReportFooter';
import { useMarketingReportPdf } from './useMarketingReportPdf';
import { useReportFlow } from './useReportFlow';
import { useSelectionModel } from './useSelectionModel';
import { getAvailableActions, getMarketingReportTitle } from './utils';

export default function MarketingReport() {
  const navigate = useNavigate();
  const { emailEditorUrl } = useWorkspaceUrls();
  const { saveMarketingReportFlow, isInformationRequestsReport } = useReportFlow();
  const [isLoading, { on: showLoader, off: hideLoader }] = useBoolean(false);
  const [marketIdInEditMode, setMarketIdInEditMode] = useState('');
  const isSavingBeforeGeneratingReport = useRef<boolean>(false);

  const { submission, markets, logSubmissionActivity } = useSubmissionsWorkspace();
  const {
    selectionModels,
    setSelectionModel,
    selectedCount,
    saveSelectionModelsToServer,
    marketRequestsByStatus,
    marketingReportRows,
  } = useSelectionModel({ isInformationRequestsReport });

  const { downloadReport } = useMarketingReportPdf({ submission, marketingReportRows });

  // If the report had to be saved prior to generating the report, we need to know which share action was selected,
  // so we can run it in handleShareActionAfterSave
  const shareMenuAction = useRef<ShareMenuAction | undefined>(undefined);
  const innerPageOnClose = useRef<() => void | undefined>();

  const title = getMarketingReportTitle(saveMarketingReportFlow, isInformationRequestsReport);

  const getHtmlValue = () =>
    isInformationRequestsReport ? informationRequestedReportHtmlTable(markets) : marketingReportHtmlTable(markets);

  useEffect(() => {
    const handleShareActionAfterSave = async () => {
      // we are waiting for the updated the server with new un/selected markets until we generate the report
      if (isSavingBeforeGeneratingReport.current) {
        showLoader();
        try {
          switch (shareMenuAction.current) {
            case ShareMenuAction.Download:
              await downloadReport();
              innerPageOnClose.current?.();
              break;
            case ShareMenuAction.Copy: {
              const html = getHtmlValue();
              await copyHtmlToClipboard(html);
              innerPageOnClose.current?.();
              break;
            }
            case ShareMenuAction.Email: // the email report is generated when navigating to the email editor, so there's nothing to do here
            default:
              break;
          }
        } finally {
          isSavingBeforeGeneratingReport.current = false;
          hideLoader();
        }
      }
    };

    handleShareActionAfterSave();
    // we only want to run this useEffect once marketingReportRows has changed because if so and isSavingBeforeGeneratingReport is true we want to generate marketing report
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketingReportRows]);

  const saveReportData = async () => {
    isSavingBeforeGeneratingReport.current = true;
    const changedMarketsCount = await saveSelectionModelsToServer(selectionModels);
    // The report data is only generated on changes to "markets" (e.g. initially and in a useEffect).
    // If the report changed, flag that, so we can download the report in a useEffect - "handleShareActionAfterSave"
    if (!changedMarketsCount) {
      isSavingBeforeGeneratingReport.current = false;
    }

    return changedMarketsCount > 0;
  };

  const handleSaveData = async () => {
    await saveReportData();
  };

  const actionWithLoader = async (action: () => Promise<boolean>) => {
    showLoader();
    try {
      return await action();
    } finally {
      hideLoader();
    }
  };

  const handleCopy = async () => {
    shareMenuAction.current = ShareMenuAction.Copy;
    const hasChanges = await saveReportData();
    if (!hasChanges) {
      const html = getHtmlValue();
      await copyHtmlToClipboard(html);
    }

    await logSubmissionActivity(
      {
        activityType: isInformationRequestsReport
          ? ActivityType.InformationRequestCopiedToClipboard
          : ActivityType.MarketingReportCopiedToClipboard,
      },
      true,
    );

    return hasChanges;
  };

  const handleSendEmail = async () => {
    shareMenuAction.current = ShareMenuAction.Email;
    await saveReportData();
    navigate(emailEditorUrl, {
      routeKey: RouteKey.EmailEditor,
      state: {
        mode: isInformationRequestsReport
          ? EmailEditorPageMode.InformationRequestsReport
          : EmailEditorPageMode.MarketingReport,
      },
      replace: true,
    });
    return false;
  };

  const handleOnDownload = async () => {
    shareMenuAction.current = ShareMenuAction.Download;
    const hasChanges = await saveReportData();
    if (!hasChanges) {
      await downloadReport();
    }

    await logSubmissionActivity(
      {
        activityType: isInformationRequestsReport
          ? ActivityType.InformationRequestDownloaded
          : ActivityType.MarketingReportDownloaded,
      },
      true,
    );
    return hasChanges;
  };

  return (
    submission && (
      <InnerPageLayout
        title={title}
        subTitle={messages.marketingReportPage.subtitle}
        className="cap-marketing-report-page"
        footer={(innerPageProps) => {
          innerPageOnClose.current = innerPageProps.onClose;
          return (
            <MarketingReportFooter
              isLoading={isLoading}
              selectedCount={selectedCount}
              marketIdInEditMode={marketIdInEditMode}
              isShareEnabled={!saveMarketingReportFlow}
              handleSaveData={handleSaveData}
              availableActions={getAvailableActions(isInformationRequestsReport)}
              handleCopy={() => actionWithLoader(handleCopy)}
              handleSendEmail={() => actionWithLoader(handleSendEmail)}
              handleOnDownload={() => actionWithLoader(handleOnDownload)}
              {...innerPageProps}
            />
          );
        }}
      >
        {(props) => (
          <MarketingReportContent
            isInformationRequestsReport={isInformationRequestsReport}
            marketIdInEditMode={marketIdInEditMode}
            setMarketIdInEditMode={setMarketIdInEditMode}
            selectionModels={selectionModels}
            setSelectionModel={setSelectionModel}
            hideLoader={hideLoader}
            showLoader={showLoader}
            marketRequestsByStatus={marketRequestsByStatus}
            {...props}
          />
        )}
      </InnerPageLayout>
    )
  );
}
