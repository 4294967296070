import { Avatar, Stack, Tooltip, Typography } from '@common-components';
import { AirplanemodeActive, PersonOutline } from '@icons';
import { CAPITOLA_SITE_ROLES } from 'enums';
import { messages } from 'i18n';
import { User } from 'types';
import { userFullName } from 'utils';
import IconBadge from 'components/IconBadge';

const IconSize = 28;

export const ActivityLogActor = ({ actor }: { actor?: User }) => (
  <Stack position="relative">
    <Tooltip tooltipContent={actor ? userFullName(actor) : messages.submissionMarketActivityLog.unknownUser}>
      <Avatar
        sx={{
          height: IconSize,
          width: IconSize,
          bgcolor: 'grey.100',
          color: 'text.secondary',
        }}
      >
        {actor && CAPITOLA_SITE_ROLES.includes(actor?.role) ? (
          <IconBadge
            IconComponent={AirplanemodeActive}
            bgcolor="green.300"
            badgeDimension={{ height: IconSize, width: IconSize }}
            size="extraSmall"
          />
        ) : (
          <Typography variant="caption" color="text.secondary" fontWeight="600">
            {actor?.email.charAt(0).toUpperCase() ?? <PersonOutline sx={{ fontSize: 15, pt: 0.2 }} />}
          </Typography>
        )}
      </Avatar>
    </Tooltip>
  </Stack>
);
