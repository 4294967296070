import { SubmissionMarketRequestClient } from 'clients';
import { QueryKeys } from 'enums';
import { SubmissionMarketRequest } from 'types';
import { MutateProps, UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useQuerySearch } from './query/useQuery';

export function useSearchSubmissionMarketRequest(props?: UseQueryBaseSearchOptions<SubmissionMarketRequest>) {
  const { filter, queryOptions } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.SubmissionMarketRequest,
    clientClass: SubmissionMarketRequestClient,
    filter,
    queryOptions: {
      // Default submssion search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateSubmissionMarketRequest(options?: MutateProps) {
  const {
    create: createSubmissionMarketRequest,
    update: updateSubmissionMarketRequest,
    destroy: destroySubmissionMarketRequest,
  } = useMutate<
    SubmissionMarketRequest,
    SubmissionMarketRequest,
    Partial<SubmissionMarketRequest>,
    SubmissionMarketRequestClient
  >({
    queryKey: QueryKeys.SubmissionMarketRequest,
    clientClass: SubmissionMarketRequestClient,
    cancelInvalidation: options?.cancelInvalidation,
    createOptions: { throwOnError: true },
    updateOptions: { throwOnError: true },
  });

  return { createSubmissionMarketRequest, updateSubmissionMarketRequest, destroySubmissionMarketRequest };
}
