import { SubmissionEmailClient } from 'clients';
import { QueryKeys } from 'enums';
import { SubmissionEmail, SubmissionEmailCreate, SubmissionEmailSearchItem } from 'types';
import { MutateProps, UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useQueryRetrieve, useQuerySearch } from './query/useQuery';

export function useSearchSubmissionEmail(props?: UseQueryBaseSearchOptions<SubmissionEmailSearchItem>) {
  const { filter, queryOptions } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.SubmissionEmail,
    clientClass: SubmissionEmailClient,
    filter,
    queryOptions: {
      // Default submission email search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useRetrieveSubmissionEmail({
  id,
  enabled,
  queryOptions,
}: UseQueryBaseRetrieveOptions<SubmissionEmail>) {
  return useQueryRetrieve({
    queryKey: QueryKeys.SubmissionEmail,
    clientClass: SubmissionEmailClient,
    id,
    enabled,
    queryOptions: {
      // Default submission email search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateSubmissionEmail(options?: MutateProps) {
  const { create: createSubmissionEmail, update: updateSubmissionEmail } = useMutate<
    SubmissionEmail,
    SubmissionEmailSearchItem,
    SubmissionEmailCreate,
    SubmissionEmailClient
  >({
    queryKey: QueryKeys.SubmissionEmail,
    clientClass: SubmissionEmailClient,
    cancelInvalidation: options?.cancelInvalidation,
    createOptions: { throwOnError: true },
    updateOptions: { throwOnError: true },
  });

  return { createSubmissionEmail, updateSubmissionEmail };
}
