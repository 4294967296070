import { PromptResult, PromptResultCreate } from '@common/types';
import { useCallback } from 'react';
import { PromptResultClient } from 'clients/capitola/prompt-result';
import { QueryKeys } from 'enums';
import { messages } from 'i18n';
import { AnyObject } from 'types';
import { logger } from 'utils';
import { MutateProps } from './query/types';
import { useMutate, useQueryMutation } from './query/useMutate';

export function useMutatePromptResult(options?: MutateProps) {
  const onError = useCallback((errorMessage: string, statusCode?: number, extraLogInfo?: AnyObject) => {
    const message = messages.general.errorMessage(errorMessage, statusCode);
    // silently log the error without showing a toast
    logger.log('error', extraLogInfo ? { ...extraLogInfo, message } : message);
  }, []);

  const { create: createPromptResult, client } = useMutate<
    PromptResult,
    PromptResult,
    PromptResultCreate,
    PromptResultClient
  >({
    queryKey: QueryKeys.PromptResult,
    clientClass: PromptResultClient,
    cancelInvalidation: options?.cancelInvalidation,
    createOptions: { throwOnError: false },
    onClientError: onError,
  });

  const batchCreatePromptResult = useQueryMutation<PromptResult[], PromptResultCreate[]>(
    (data) => client.batchCreate(data) as Promise<PromptResult[]>,
    QueryKeys.PromptResult,
    true,
  );

  return { createPromptResult, batchCreatePromptResult };
}
