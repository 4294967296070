import { useMemo } from 'react';
import { Box, Button, Stack } from '@common-components';
import { CompareQuotesUrlParam } from 'enums';
import { messages } from 'i18n';
import { QuoteComparisonInsightsRoutes } from 'broker/broker-routes';
import { RouteKey } from 'broker/enums';
import { useNavigateBack, useNavigateToStep } from 'broker/hooks';
import { PdfGenerationStatus } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/types';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import FooterStepper from './FooterStepper';
import { QuoteComparisonProps, quoteComparisonSteps } from './types';

const websiteBaseUrl = process.env.REACT_APP_WEBSITE_BASE_URL;

export default function Footer(quoteComparisonProps: QuoteComparisonProps) {
  const { currentStep, pdfGenerationStatus, quotes, expiringPolicyFileId } = quoteComparisonProps;
  const { compareQuotesSideBySideUrl } = useWorkspaceUrls();

  const navigateBack = useNavigateBack();
  const navigateToStep = useNavigateToStep();

  const isFinalStep = currentStep === QuoteComparisonInsightsRoutes.Upload;

  const handleChangeStep = (step: QuoteComparisonInsightsRoutes, direction: 'next' | 'prev') => {
    navigateToStep(quoteComparisonSteps[quoteComparisonSteps.indexOf(step) + (direction === 'next' ? 1 : -1)], {
      routeKey: RouteKey.QuoteComparisonInsights,
    });
  };

  const openSideBySideReview = () => {
    const urlParams = new URLSearchParams({
      [CompareQuotesUrlParam.QuoteIds]: quotes.map((quote) => quote.id).join(','),
    });
    if (expiringPolicyFileId) {
      urlParams.append(CompareQuotesUrlParam.ExpiringPolicyFileId, expiringPolicyFileId);
    }

    window.open(`${websiteBaseUrl}${compareQuotesSideBySideUrl}?${urlParams.toString()}`, '_blank');
  };

  const disabledSubmitButton = useMemo(
    () =>
      currentStep === QuoteComparisonInsightsRoutes.Upload &&
      [PdfGenerationStatus.Loading, PdfGenerationStatus.Error].includes(pdfGenerationStatus),
    [currentStep, pdfGenerationStatus],
  );

  const getSubmitButtonText = () => {
    switch (currentStep) {
      case QuoteComparisonInsightsRoutes.Upload:
        return messages.quoteComparisonPage.buttons.savePDF;
      case QuoteComparisonInsightsRoutes.Compose:
        return messages.quoteComparisonPage.buttons.nextGeneratePDF;
      default:
        return '';
    }
  };

  const onFinalStep = () => {
    navigateBack();
  };

  return (
    <Stack direction="row" gap={1} justifyContent="center">
      <Box flex={1} />
      <Stack flex={1} alignItems="center">
        <FooterStepper handleChangeStep={handleChangeStep} currentStep={currentStep} />
      </Stack>
      <Stack flex={1} direction="row" gap={1} justifyContent="flex-end" alignItems="center">
        <Stack direction="row" gap={1}>
          {!isFinalStep && (
            <Button variant="outlined" onClick={openSideBySideReview}>
              {messages.quoteComparisonPage.buttons.sideBySide}
            </Button>
          )}
          <Button
            variant="contained"
            disabled={disabledSubmitButton}
            onClick={() => {
              switch (currentStep) {
                case QuoteComparisonInsightsRoutes.Upload:
                  onFinalStep();
                  break;
                default:
                  handleChangeStep(currentStep, 'next');
                  break;
              }
            }}
          >
            {getSubmitButtonText()}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
