import {
  CreateFollowupMessageInput,
  CreateFollowupMessageOutput,
  ShareAIThreadInput,
  ShareAIThreadOutput,
} from 'types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export class AIClient extends CapitolaClient<{}> {
  public get resourceName(): string {
    return 'ai';
  }

  public async createFollowupMessage(
    createFollowupMessageInput: CreateFollowupMessageInput,
  ): Promise<CreateFollowupMessageOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'assistant/create-followup-message',
      body: { ...createFollowupMessageInput },
    });
  }

  public async shareAIThread(input: ShareAIThreadInput): Promise<ShareAIThreadOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'assistant/share-ai-thread',
      body: { ...input },
    });
  }
}
