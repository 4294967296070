import { useRef } from 'react';
import { useHover } from 'usehooks-ts';
import { Stack, Typography } from '@common-components';

import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { getCoverageLinesText, getDotSeparated, getTimezoneLessDisplayDate } from 'utils';

import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useBlockedByCopilot } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/useBlockedByCopilot';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';

interface SubmissionDetailsProps {
  partialSubmission: PartialSubmission;
}

export default function SubmissionDetails({ partialSubmission }: SubmissionDetailsProps) {
  const coverageLines = getCoverageLinesText(partialSubmission?.coverageLines);
  const dueDate = partialSubmission?.dueDate
    ? messages.submissionWorkspace.header.details.effectiveDate(getTimezoneLessDisplayDate(partialSubmission.dueDate))
    : '';
  const needByDate = partialSubmission?.needByDate
    ? messages.submissionWorkspace.header.details.needByDate(getTimezoneLessDisplayDate(partialSubmission.needByDate))
    : '';
  const navigate = useNavigate();
  const { editSubmissionUrl } = useWorkspaceUrls();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const blockedByCopilot = useBlockedByCopilot();

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Typography
        sx={{ textDecoration: isHover ? 'underline' : 'none', cursor: blockedByCopilot ? 'not-allowed' : 'pointer' }}
        onClick={() => {
          if (!blockedByCopilot) {
            navigate(editSubmissionUrl, {
              routeKey: RouteKey.SubmissionEdit,
              state: undefined,
            });
          }
        }}
        ref={hoverRef}
        variant="body2Bold"
        color="typography.primary"
        flex={1}
      >
        {getDotSeparated([coverageLines, dueDate, needByDate])}
      </Typography>
    </Stack>
  );
}
