import { EmailType } from 'broker/components/EmailEditor/store/types';
import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { RecipientsGroupsAvailableRecipients } from 'broker/components/EmailEditor/types';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';
import RecipientGroupTag from 'broker/components/Emails/RecipientGroupTag';
import { Recipient } from 'broker/components/Emails/types';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';
import { useApplyDirtyChanges } from './useApplyDirtyChanges';

interface RecipientGroupChipProps {
  recipientGroup: RecipientGroup;
  selected?: boolean;
  onClick?: (recipientGroupId: string) => void;
  icon?: JSX.Element | undefined;
  recipientGroupsAvailableContacts: RecipientsGroupsAvailableRecipients;
  isReadOnly?: boolean;
}

export const RecipientGroupChip = ({
  recipientGroup,
  selected,
  onClick,
  icon,
  recipientGroupsAvailableContacts,
  isReadOnly,
}: RecipientGroupChipProps) => {
  const {
    emailType,
    draftEditorMode,
    recipients: { newRecipients },
  } = useEmailEditorState();

  const {
    addCustomerContact,
    addMarketContact,
    recipients: { clearNewRecipients, toggleRecipient, removeRecipientGroup },
    draftEditing: { resetDirtyState },
  } = useEmailEditorActions();

  const { applyChanges } = useApplyDirtyChanges();

  const handleDelete = () => {
    applyChanges();
    removeRecipientGroup(recipientGroup.id);
  };

  const handleClick = () => {
    applyChanges();
    onClick?.(recipientGroup.id);
  };

  const handleAddContact = emailType === EmailType.Retailer ? addCustomerContact : addMarketContact;
  return (
    <RecipientGroupTag
      isNewEmailEditor
      recipientGroup={recipientGroup}
      key={recipientGroup.id}
      availableRecipients={recipientGroupsAvailableContacts[recipientGroup.id] ?? []}
      selected={selected}
      icon={icon}
      newRecipients={newRecipients ?? []}
      onClick={handleClick}
      onClearNewRecipients={clearNewRecipients}
      onAddRecipient={handleAddContact}
      onToggleRecipient={(recipient: Recipient, removeIfLast?: boolean) => {
        toggleRecipient(recipientGroup.id, recipient, removeIfLast);

        if (draftEditorMode === DraftEditorMode.Preview) {
          resetDirtyState();
        }
      }}
      onRemoveGroup={handleDelete}
      bottomSpacing={0}
      shouldBlockRemove={isReadOnly}
    />
  );
};
