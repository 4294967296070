import { SubmissionStatus, SubmissionTerminationReason, SubmissionTerminationStatus } from 'enums';
import { messages } from 'i18n';

type SubmissionTerminationReasonConfig = {
  message: string;
  status?: SubmissionTerminationStatus;
};

const reasonsMessages = messages.submissionWorkspace.termination.reasons;

export const submissionTerminationReasonConfig: Record<SubmissionTerminationReason, SubmissionTerminationReasonConfig> =
  {
    [SubmissionTerminationReason.CouldNotMatch]: {
      message: reasonsMessages.couldNotMatch,
      status: SubmissionStatus.Closed,
    },
    [SubmissionTerminationReason.CouldNotPursue]: {
      message: reasonsMessages.couldNotPursue,
      status: SubmissionStatus.Closed,
    },
    [SubmissionTerminationReason.UnacceptedCoverage]: {
      message: reasonsMessages.unacceptedCoverage,
      status: SubmissionStatus.Closed,
    },
    [SubmissionTerminationReason.UnacceptedPricing]: {
      message: reasonsMessages.unacceptedPricing,
      status: SubmissionStatus.Closed,
    },
    [SubmissionTerminationReason.UnacceptedService]: {
      message: reasonsMessages.unacceptedService,
      status: SubmissionStatus.Closed,
    },
    [SubmissionTerminationReason.UnacceptedGhosted]: {
      message: reasonsMessages.unacceptedGhosted,
      status: SubmissionStatus.Closed,
    },
    [SubmissionTerminationReason.AgentPulledBack]: {
      message: reasonsMessages.agentPulledBack,
      status: SubmissionStatus.Closed,
    },
    [SubmissionTerminationReason.AgentLostAccount]: {
      message: reasonsMessages.agentLostAccount,
      status: SubmissionStatus.Closed,
    },
    [SubmissionTerminationReason.AddedByMistake]: {
      message: reasonsMessages.addedByMistake,
      status: SubmissionStatus.Archived,
    },
    [SubmissionTerminationReason.AddedForTesting]: {
      message: reasonsMessages.addedForTesting,
      status: SubmissionStatus.Archived,
    },
    [SubmissionTerminationReason.Other]: { message: reasonsMessages.other },
  };
