import { Stack } from '@common-components';
import { InformationTooltipType } from 'contexts/information-tooltip/types';
import FilesAttachments from 'broker/components/EmailEditor/components/FilesAttachments';
import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';
import EditForAllActions from './EditForAllActions';
import PreviewActions from './PreviewActions';

export default function DraftEditorFooter() {
  const { draftEditorMode, attachedFiles } = useEmailEditorState();
  const {
    attachedFiles: { removeFile },
  } = useEmailEditorActions();

  const getActions = () => {
    switch (draftEditorMode) {
      case DraftEditorMode.Preview:
        return <PreviewActions />;
      case DraftEditorMode.EditForAll:
        return <EditForAllActions />;
      default:
        return null;
    }
  };

  const actions = getActions();

  return (
    <Stack direction="row" justifyContent="space-between" paddingX={1.5} pt={0.5} pb={1.5}>
      <FilesAttachments
        informationTooltipType={InformationTooltipType.EmailAttachments}
        attachedFiles={attachedFiles.attachedFiles}
        onRemoveFile={removeFile}
      />
      {actions}
    </Stack>
  );
}
