import { Dialog } from '@common-components';
import { DirtyDialogGuard } from 'broker/components/DirtyContentGuard';
import Content from './content';

interface InviteDialogProps {
  isInviteDialogOpen: boolean;
  closeInviteDialog: () => void;
}

export default function InviteDialog({ isInviteDialogOpen, closeInviteDialog }: InviteDialogProps) {
  return (
    <DirtyDialogGuard onClose={closeInviteDialog}>
      {({ closeAttempt, setIsContentDirty, isContentDirty, closeWithoutPrompt }) => (
        <Dialog open={isInviteDialogOpen}>
          <Content
            closeAttempt={closeAttempt}
            closeWithoutPrompt={closeWithoutPrompt}
            setIsContentDirty={setIsContentDirty}
            isContentDirty={isContentDirty}
          />
        </Dialog>
      )}
    </DirtyDialogGuard>
  );
}
