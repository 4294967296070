import { first, isEmpty } from 'lodash';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import {
  Avatar,
  Checkbox,
  CircularProgress,
  Collapse,
  Highlight,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@common-components';
import { BorderColorOutlined as EditIcon } from '@icons';
import { ContactRole } from 'enums';
import { useBoolean, useFormProvider, useHotjar, useToast } from 'hooks';
import { messages } from 'i18n';
import { Contact } from 'types';
import { contactFullName } from 'utils';
import { HotjarEvents } from 'utils/hotjar-events';
import contactRoleConfig from 'broker/configuration-mappers/contact-role-config';
import { useEmailFormFieldValidation } from 'broker/hooks';
import { useContactActions } from 'broker/pages/SubmissionWorkspacePage/store/useContactActions';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import ContactDetailItem from './ContactDetailItem';
import { ContactForm } from './ContactForm';
import { ContactFormButtons } from './ContactFormButtons';
import { ContactData, schema } from './types';

const TRANSITION_DURATION = '500ms';

export interface SubmissionMarketContactProps {
  contact: Contact;
  isChecked: boolean;
  onCheckboxClick: () => void;
  // contact is the only contact checked in the list of contacts
  lastContactChecked: boolean;
  isLoading: boolean;
  userMarketId: string;
  search: string;
  existsInUserMarket: boolean;
}

export function SubmissionMarketContact({
  contact,
  existsInUserMarket,
  isChecked,
  onCheckboxClick,
  lastContactChecked,
  isLoading,
  userMarketId,
  search,
}: SubmissionMarketContactProps) {
  const [isEditing, { off: stopEditing, on: startEditing }] = useBoolean(false);
  const [isSubmitting, { on: startSubmit, off: stopSubmit }] = useBoolean(false);
  const { showToast } = useToast();
  const hotjar = useHotjar();
  const { isReFetching } = useSubmissionsWorkspace();

  const { firstName, lastName, email, phoneNumber, title, role } = contact;
  const { methods } = useFormProvider<ContactData>({
    schema,
    mode: 'onBlur',
    defaultValues: { firstName, lastName, email, phoneNumber, title, role },
  });

  const { editUserMarketContact } = useContactActions();
  const { validateEmailAddresses } = useEmailFormFieldValidation({ methods });

  const onSaveClick: SubmitHandler<ContactData> = async (data) => {
    startSubmit();

    if (!(await validateEmailAddresses([data.email]))) {
      stopSubmit();
      return;
    }

    try {
      await editUserMarketContact(userMarketId, contact.id, {
        ...data,
        organizationId: contact.organizationId,
      });
    } catch (e) {
      stopSubmit();
      return;
    }

    stopSubmit();
    stopEditing();
    showToast('success', { message: messages.submissionMarketToolbox.contacts.updated });
  };

  const avatarText = () => {
    if (contact.role === ContactRole.SubmissionBox) {
      return messages.general.submissionBoxInitials;
    }
    const fullName = contactFullName(contact);
    if (!isEmpty(fullName)) {
      return `${first(contact.firstName)}${first(contact.lastName)}`;
    }
    return contact.email.slice(0, 2);
  };

  const onEditClick = () => {
    hotjar.event(HotjarEvents.EditMarketContact);
    startEditing();
  };

  return (
    <FormProvider {...methods}>
      <Stack
        direction="column"
        bgcolor={isEditing ? 'grey.100' : 'inherit'}
        px={1}
        pb={isEditing ? 3 : 0}
        sx={{ transition: TRANSITION_DURATION, position: 'relative' }}
      >
        <Tooltip
          placement="top"
          tooltipContent={!existsInUserMarket ? messages.submissionMarketToolbox.contacts.contactRemoved : ''}
        >
          <Stack direction="row" py={3} alignItems="flex-start">
            <Tooltip
              tooltipContent={lastContactChecked ? messages.submissionMarketToolbox.contacts.atLeastOneContact : ''}
            >
              {isLoading ? (
                <CircularProgress size={16} sx={{ mx: '11px' }} />
              ) : (
                <Checkbox
                  checked={isChecked}
                  onClick={onCheckboxClick}
                  disabled={isReFetching || lastContactChecked || !existsInUserMarket}
                  size="small"
                  disableRipple
                />
              )}
            </Tooltip>
            <Avatar
              sx={{
                // eslint-disable-next-line no-nested-ternary
                bgcolor: !existsInUserMarket ? 'grey.100' : isEditing ? 'blue.700' : 'blue.50',
                fontSize: 42,
                ml: 1,
                mr: 2.5,
              }}
            >
              <Typography
                variant="body1"
                /* eslint-disable-next-line no-nested-ternary */
                color={!existsInUserMarket ? 'grey.300' : isEditing ? 'common.white' : 'common.black'}
              >
                {avatarText().toUpperCase()}
              </Typography>
            </Avatar>
            <Stack direction="column" flex={1} textOverflow="ellipsis" overflow="hidden">
              <Typography
                color={!existsInUserMarket ? 'grey.300' : 'inherit'}
                variant="body2"
                fontWeight={600}
                noWrap
                title={contactFullName(contact)}
              >
                <Stack direction="row" gap={0.5}>
                  <Highlight searchWords={[search]} textToHighlight={`${contactFullName(contact)} `} />
                  {contact.role !== ContactRole.SubmissionBox && (
                    <>
                      <span>&#8226;</span>
                      <span>{contactRoleConfig[contact.role].text}</span>
                    </>
                  )}
                </Stack>
              </Typography>
              <Stack mb={1}>
                {!isEmpty(contact.title) && (
                  <ContactDetailItem existsInUserMarket={existsInUserMarket} title={contact.title!}>
                    {contact.title}
                  </ContactDetailItem>
                )}
                <ContactDetailItem existsInUserMarket={existsInUserMarket} title={contact.email}>
                  <Highlight searchWords={[search]} textToHighlight={contact.email} />
                </ContactDetailItem>

                {!isEmpty(contact.phoneNumber) && (
                  <ContactDetailItem existsInUserMarket={existsInUserMarket} title={contact.phoneNumber!}>
                    {contact.phoneNumber}
                  </ContactDetailItem>
                )}
              </Stack>
            </Stack>
            {existsInUserMarket &&
              (isEditing ? (
                <ContactFormButtons
                  onCancel={stopEditing}
                  onSave={methods.handleSubmit(onSaveClick)}
                  isSubmitting={isSubmitting}
                />
              ) : (
                <IconButton onClick={onEditClick} color="secondary" variant="text" icon={EditIcon} />
              ))}
          </Stack>
        </Tooltip>

        <Collapse in={isEditing} orientation="vertical">
          <ContactForm contact={contact} />
        </Collapse>
      </Stack>
    </FormProvider>
  );
}
