import { Stack, Typography } from '@common-components';
import { WarningAmber } from '@icons';
import { SubmissionTerminationStatus } from 'enums';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { Nullable } from 'utils';
import { useSubmissionTerminationDetails } from 'broker/hooks/useSubmissionTerminationDetails';

export default function TerminatedSubmissionBanner({ submission }: { submission: Nullable<PartialSubmission> }) {
  const terminationDetails = useSubmissionTerminationDetails({ submission });

  if (!submission) {
    return null;
  }

  const { title } = messages.submissionWorkspace.termination.banner;

  return (
    <Stack direction="row" py={1} px={1.5} gap={1} bgcolor="error.light" zIndex={(theme) => theme.zIndex.drawer + 1}>
      <WarningAmber color="error" fontSize="small" />
      <Stack>
        <Typography variant="body2Bold" color="text.primary">
          {title(submission.status as SubmissionTerminationStatus)}
        </Typography>
        <Typography variant="caption" color="text.primary">
          {terminationDetails}
        </Typography>
      </Stack>
    </Stack>
  );
}
