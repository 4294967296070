import { compact, isEmpty } from 'lodash';
import { useState } from 'react';
import { PromptResultFeature, SubmissionEmailLabel } from 'enums';
import { useBoolean, useGenerateMarketingUpdateContent, useMutatePromptResult } from 'hooks';
import { messages } from 'i18n';
import { getTodayDisplayDate } from 'utils';
import { PostEmailInformation } from 'broker/components/EmailEditor/types';
import { createRecipientGroupFromSubmissionCustomer } from 'broker/components/Emails/recipient-utils';
import SubmissionEmailEditorWithAI from 'broker/pages/SubmissionWorkspacePage/components/SubmissionEmailEditorWithAI';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import MarketingUpdateEmailLayout from './MarketingUpdateEmailLayout';
import { MarketingUpdateEmailLocationState } from './types';

interface MarketingUpdateEmailContentProps {
  locationState?: MarketingUpdateEmailLocationState;
}

export default function MarketingUpdateEmailContent({ locationState }: MarketingUpdateEmailContentProps) {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { generateMarketingUpdateContent } = useGenerateMarketingUpdateContent();
  const [isDirty, { set: setIsDirty }] = useBoolean(true);
  const [aiGeneratedBodyContent, setAiGeneratedBodyContent] = useState<string | undefined>(undefined);
  const { createPromptResult } = useMutatePromptResult();

  const pdfFileId = locationState?.pdfFileId;
  const emailSubject =
    partialSubmission?.draftMarketingUpdate?.emailSubject ??
    `Marketing Update | @INSURED-NAME - @COVERAGE-LINES ${getTodayDisplayDate()}`;
  const aiInputData = {
    submissionId: partialSubmission!.id,
    marketingPdfFileId: pdfFileId ?? '',
  };
  const draftAttachmentsFileIds = partialSubmission?.draftMarketingUpdate?.attachmentsFileIds;
  const preSelectedFileIds = isEmpty(draftAttachmentsFileIds) ? [pdfFileId] : draftAttachmentsFileIds;

  const decorateGeneratedContent = (generatedContent: string) =>
    `Hi @CUSTOMER-FIRST-NAMES,<br><br>${generatedContent}<br><br>@SIGNATURE`;

  const isLoading = !partialSubmission || !pdfFileId;

  return (
    <SubmissionEmailEditorWithAI
      isDirty={isDirty}
      contentDecorator={decorateGeneratedContent}
      initialBodyContent={partialSubmission?.draftMarketingUpdate?.emailBody}
      isSkipAllowed
      isLoading={isLoading}
      title={messages.marketingUpdate.title}
      aiThread={{
        createThread: () => generateMarketingUpdateContent(aiInputData),
        callback: (generatedContent) => {
          setAiGeneratedBodyContent(generatedContent);
        },
      }}
      emailEditorProps={(bodyContent) => ({
        preSelectedRecipients: [createRecipientGroupFromSubmissionCustomer(partialSubmission!)],
        emailLabel: SubmissionEmailLabel.MarketingUpdate,
        preSelectedFileIds: compact(preSelectedFileIds),
        repliedThreadBody: bodyContent,
        repliedThreadSubject: emailSubject,
        postEmailsSent: async (postEmailInformation: PostEmailInformation) => {
          if (aiGeneratedBodyContent !== undefined) {
            await createPromptResult.mutateAsync({
              data: {
                result: aiGeneratedBodyContent,
                input: aiInputData,
                modifiedResult: postEmailInformation.draftPreview.body,
                feature: PromptResultFeature.MarketingUpdateEmail,
                submissionId: partialSubmission!.id,
              },
            });
          }
        },
      })}
    >
      <MarketingUpdateEmailLayout setIsDirty={setIsDirty} submission={partialSubmission!} />
    </SubmissionEmailEditorWithAI>
  );
}
