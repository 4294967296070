import { useQueryClient } from '@tanstack/react-query';
import { UserClient } from 'clients';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
// eslint-disable-next-line import/no-internal-modules
import { useToast } from 'hooks/useToast';
import { messages } from 'i18n';
import { LoggedInUser, User, UserCreate, UserSearch } from 'types';
import { clearLocalStorage } from 'utils';
import { useCurrentUser } from './currentUser';
import { MutateProps, UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useImperativeQuery, useQueryRetrieve, useQuerySearch } from './query/useQuery';

interface UseSetEndUserProps extends Omit<UseQueryBaseRetrieveOptions<User>, 'id'> {
  email?: string;
  onSuccess?: (me: LoggedInUser) => void;
  onError?: () => void;
}

const { userNotFound } = messages.backofficePage;

export function useRetrieveUser(props: UseQueryBaseRetrieveOptions<User>) {
  const { id, queryOptions, enabled } = props;
  return useQueryRetrieve({
    queryKey: QueryKeys.Users,
    clientClass: UserClient,
    id,
    enabled,
    queryOptions: {
      // Default retrieve query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useSearchUser(props?: UseQueryBaseSearchOptions<User, UserSearch>) {
  const { filter, queryOptions, sorter, enabled } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.Users,
    clientClass: UserClient,
    enabled,
    filter,
    sorter:
      sorter ??
      ((a, b) =>
        `${a?.firstName ?? ''}${a?.lastName ?? ''}`.localeCompare(`${b?.firstName ?? ''} ${b?.lastName ?? ''}`)),
    queryOptions: {
      // Default user search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useSearchUserTeam(props?: UseQueryBaseSearchOptions<User, UserSearch>) {
  const { me } = useCurrentUser();
  const userTeamId = me?.endUser?.team.id ?? me?.team.id;
  const { filter, queryOptions, sorter, enabled } = props || {};
  return useSearchUser({
    enabled,
    filter: { ...filter, teamId: userTeamId },
    sorter,
    queryOptions: {
      // Default user search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useSetEndUser(props: UseSetEndUserProps = {}) {
  const { email, onSuccess, onError, queryOptions, enabled = false } = props;

  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useQueryRetrieve({
    queryKey: QueryKeys.Users,
    clientClass: UserClient,
    id: { email },
    enabled,
    queryOptions: {
      ...queryOptions,
      refetchOnWindowFocus: false,
      // Do not auto retry on error. Needed since we set throwOnError to true
      retry: false,
      onSuccess: async (user?: User) => {
        const me = user as LoggedInUser;

        // A user should always be returned. If it didn't return it means we got a 404,
        // indicating that the end user doesn't exist
        if (!me) {
          showToast('error', { message: userNotFound(email!) });
          onError?.();
        } else {
          clearLocalStorage();

          // Make sure that the React Query cache is cleared when switching between end users
          // This will also initiate a new "me" call to the server, which will update the end user field
          queryClient.clear();

          onSuccess?.(me);
        }
      },
      onError: () => {
        onError?.();
      },
    },
    retrieveOptions: {
      subPath: 'set-end-user',
      throwOnError: true,
      // 404 response should not be considered as an error, but instead return null
      ignoreStatusCodes: [404],
    },
  });
}

export function useMutateUser(options?: MutateProps) {
  const {
    update: updateUser,
    create: createUser,
    destroy: deleteUser,
  } = useMutate<User, User, UserCreate, UserClient>({
    queryKey: QueryKeys.Users,
    clientClass: UserClient,
    cancelInvalidation: options?.cancelInvalidation,
    updateOptions: { throwOnError: true },
    createOptions: { throwOnError: true },
    destroyOptions: { throwOnError: true },
  });

  return { updateUser, createUser, deleteUser };
}

export function useRetrieveTinyJwt() {
  const userClient = useClient(UserClient);

  const retrieveTinyJwt = useImperativeQuery({
    queryFn: () => userClient.retrieveTinyJwt(),
    queryKey: [QueryKeys.Users, 'tiny-jwt'],
  });

  return { retrieveTinyJwt };
}
