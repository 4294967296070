import { Stack } from '@common-components';
import { messages } from 'i18n';
import { CopilotSteps, PartialSubmission } from 'types';
import useCopilotSettings from 'broker/dialogs/SubmissionAutonomousCreation/useCopilotSettings';
import BulletContent from './marketing/BulletContent';

export default function PreMarketing({ submission }: { submission: PartialSubmission }) {
  const copilotSettings = useCopilotSettings({ submission, copilotStep: CopilotSteps.PreMarketing });

  const preMarketingBulletMessages = messages.autonomousFlow.settingsModal.steps.preMarketing.bullets;
  return (
    <Stack>
      <BulletContent
        prefix={preMarketingBulletMessages.daysBeforeInfoRequest.prefix}
        bold={preMarketingBulletMessages.daysBeforeInfoRequest.bold(copilotSettings?.daysBeforeInfoRequest || 0)}
        suffix={preMarketingBulletMessages.daysBeforeInfoRequest.suffix}
      />
      <BulletContent
        prefix={preMarketingBulletMessages.daysBeforeInfoFollowUp.prefix}
        bold={preMarketingBulletMessages.daysBeforeInfoFollowUp.bold(copilotSettings?.daysBeforeInfoFollowUp || 0)}
        suffix={preMarketingBulletMessages.daysBeforeInfoFollowUp.suffix}
      />
      <BulletContent
        prefix={preMarketingBulletMessages.daysBeforeLossRunRequest.prefix}
        bold={preMarketingBulletMessages.daysBeforeLossRunRequest.bold(copilotSettings?.daysBeforeLossRunRequest || 0)}
        suffix={preMarketingBulletMessages.daysBeforeLossRunRequest.suffix}
      />
      <BulletContent
        prefix={preMarketingBulletMessages.daysBeforeAssigneeNotification.prefix}
        bold={preMarketingBulletMessages.daysBeforeAssigneeNotification.bold(
          copilotSettings?.daysBeforeAssigneeNotification || 0,
        )}
        suffix={preMarketingBulletMessages.daysBeforeAssigneeNotification.suffix}
      />
    </Stack>
  );
}
