import { Contact, ExtendedContact, InsuranceProduct } from 'types';

export function getExtendedContact(contact: Contact, products: InsuranceProduct[]): ExtendedContact {
  const contactProducts = products.filter((product) =>
    product.contacts.some((productContact) => productContact.id === contact.id),
  );

  return {
    ...contact,
    products: contactProducts,
  };
}
