import { SlackClient, SlackMessageCreate } from 'clients';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
import { useImperativeQuery } from './query/useQuery';

export function useMutateSlack() {
  const slackClient = useClient(SlackClient);

  const postMessage = useImperativeQuery<{} | null, SlackMessageCreate>({
    queryFn: (data) => slackClient.postMessage(data),
    queryKey: [QueryKeys.Slack, 'postMessage'],
  });

  return { postMessage };
}
