import { SubmissionFileMetadataClient } from 'clients';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
import { SubmissionFileMetadata } from 'types';
import { UseQueryBaseSearchOptions } from './query/types';

import { useQueryMutation } from './query/useMutate';
import { useQuerySearch } from './query/useQuery';

export function useSearchSubmissionFileMetadata(props?: UseQueryBaseSearchOptions<SubmissionFileMetadata>) {
  const { filter, queryOptions, enabled } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.SubmissionFileMetadata,
    clientClass: SubmissionFileMetadataClient,
    enabled,
    filter,
    queryOptions: {
      // Default submssion search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateSubmissionFileMetadata() {
  const submissionFileMetadataClient = useClient(SubmissionFileMetadataClient);

  const requestSync = useQueryMutation<any, { submissionId: string; fileIds: string[] }>(
    ({ submissionId, fileIds }) => submissionFileMetadataClient.requestSync(submissionId, fileIds),
    QueryKeys.SubmissionFileMetadata,
  );

  const completeSync = useQueryMutation<any, { submissionId: string; fileIds: string[] }>(
    ({ submissionId, fileIds }) => submissionFileMetadataClient.completeSync(submissionId, fileIds),
    QueryKeys.SubmissionFileMetadata,
  );

  return { requestSync, completeSync };
}
