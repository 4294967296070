import { UseFormReturn } from 'react-hook-form';
import { ContactRole } from 'enums';
import { useMutateContact } from 'hooks';
import { ContactCreate } from 'types';
import { useEmailFormFieldValidation } from 'broker/hooks';
import { ContactFormProps, TouchedFieldsContactsProps, UserMarketFormProps } from './types';

interface UpdateUserMarketContactsProps {
  defaultValues?: Partial<UserMarketFormProps>;
  methods: UseFormReturn<Partial<UserMarketFormProps>, object>;
}

export function useUpdateUserMarketContacts({ methods }: UpdateUserMarketContactsProps) {
  const { createContact, updateContact } = useMutateContact();
  const { validateEmailAddresses } = useEmailFormFieldValidation({
    methods,
    scrollToFirstError: true,
  });

  async function areContactEmailsValid(
    contacts: ContactFormProps[],
    touchedFieldsContacts: TouchedFieldsContactsProps[],
  ) {
    const emailToIndexMap = contacts.reduce((map, contact, index) => {
      map[contact.email] = index;

      return map;
    }, {} as Record<string, number>);

    const updatedEmails = contacts
      .filter((_, index) => !!touchedFieldsContacts[index]?.email)
      .map((contact) => contact.email);

    return validateEmailAddresses(updatedEmails, (email: string) => `contacts.${emailToIndexMap[email]}.email`);
  }

  async function updateContacts(
    contacts: ContactFormProps[],
    dirtyFieldsContacts: TouchedFieldsContactsProps[],
    organizationId: string,
  ) {
    const dirtyContacts = contacts.filter((_contact, index) => !!dirtyFieldsContacts?.[index]);

    const updatedContactsIds = (
      await Promise.all(
        dirtyContacts.map(async (contact) => {
          const data: ContactCreate = {
            firstName: contact.role !== ContactRole.SubmissionBox ? contact.firstName || '' : '',
            lastName: contact.role !== ContactRole.SubmissionBox ? contact.lastName || '' : '',
            email: contact.email,
            phoneNumber: contact.role !== ContactRole.SubmissionBox ? contact.phoneNumber || '' : '',
            title: contact.role !== ContactRole.SubmissionBox ? contact.title || '' : '',
            role: contact.role,
            organizationId,
          };
          if (contact.id) {
            return updateContact.mutateAsync({
              id: contact.id,
              data,
            });
          }
          return createContact.mutateAsync({
            data,
          });
        }),
      )
    ).map((contact) => contact?.id);

    const nonDirtyContactIds = contacts
      .filter((contact) => !dirtyContacts.some((dirtyContact) => dirtyContact.id === contact.id))
      .map((contact) => contact.id);

    return updatedContactsIds.concat(nonDirtyContactIds);
  }

  return { areContactEmailsValid, updateContacts };
}
