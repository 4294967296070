import { Button, NewMenu, Stack } from '@common-components';
import { EditOutlined as EditOutlinedIcon, MailOutline as MailOutlineIcon } from '@icons';
import { useHotjar, useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { SubmissionMarket } from 'types';
import { HotjarEvents } from 'utils/hotjar-events';
import { MenuItemProps } from 'components/menu';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import { convertSubmissionMarketToRecipientGroup } from 'broker/components/Emails/recipient-utils';
import { EmailEditorPageMode, RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';

interface EmailToMarketButtonProps {
  submissionMarket: SubmissionMarket;
}

export default function ComposeEmailButtons() {
  const { composeEmailButton, emailToMarket, emailToCustomer } = messages.submissionWorkspace.emailsTab;
  const navigate = useNavigate();
  const optionsMenuState = useOptionsMenu();
  const submissionMenuButtonItems: MenuItemProps[] = [
    {
      label: emailToMarket,
      onClick: () =>
        navigate(BROKER_NESTED_ROUTES.WORKSPACE.EMAIL_EDITOR, {
          routeKey: RouteKey.EmailEditor,
          state: { mode: EmailEditorPageMode.SendMarket },
        }),
      startAdornment: MailOutlineIcon,
    },
    {
      label: emailToCustomer,
      onClick: () =>
        navigate(BROKER_NESTED_ROUTES.WORKSPACE.EMAIL_EDITOR, {
          routeKey: RouteKey.EmailEditor,
          state: { mode: EmailEditorPageMode.SendCustomer },
        }),
      startAdornment: MailOutlineIcon,
    },
  ];

  return (
    <Stack flex={1}>
      <Button
        onClick={optionsMenuState.openMenu}
        startIcon={<EditOutlinedIcon fontSize="small" />}
        variant="contained"
        color="secondary"
        size="small"
      >
        {composeEmailButton}
      </Button>
      <NewMenu
        placement="bottom-right"
        optionsMenuState={optionsMenuState}
        menuItems={[
          {
            key: 'compose-email',
            items: submissionMenuButtonItems,
          },
        ]}
      />
    </Stack>
  );
}

export function EmailToMarketButton({ submissionMarket }: EmailToMarketButtonProps) {
  const navigate = useNavigate();
  const hotjar = useHotjar();
  const { emailEditorUrl } = useWorkspaceUrls();
  const handleEmailToMarketClick = () => {
    hotjar.event(HotjarEvents.EmailReply);
    navigate(emailEditorUrl, {
      routeKey: RouteKey.EmailEditor,
      state: {
        mode: EmailEditorPageMode.SendMarket,
        recipientGroups: [convertSubmissionMarketToRecipientGroup(submissionMarket, submissionMarket.contacts)],
      },
    });
  };
  return (
    <Button
      variant="contained"
      color="secondary"
      size="small"
      onClick={handleEmailToMarketClick}
      startIcon={<EditOutlinedIcon />}
    >
      {messages.submissionWorkspace.emailsTab.emailToMarket}
    </Button>
  );
}
