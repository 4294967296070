export const LocalStorage = {
  ResolutionFlag: 'ResolutionFlag',
  ScreenWidthFlag: 'ScreenWidthFlag',
  AutoTabIndication: 'AutoTabIndication',
  SubmissionsView: 'SubmissionsView',
  SubmissionsBox: 'SubmissionsBox',
  ToolboxSize: 'ToolboxSize',
  InfoTooltip: 'InfoTooltip',
  ActivateOnce: 'ActivateOnce',
  StartMarketingIndicator: 'StartMarketingIndicator',
} as const;

export type LocalStorageKeys = keyof typeof LocalStorage;
