import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Dialog, Divider, Stack } from '@common-components';
import { CompareQuotesUrlParam } from 'enums';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { CompareQuoteHeader } from './CompareQuoteHeader';
import { CompareQuotePreview } from './CompareQuotePreview';
import { ComparedItem } from './types';

export function CompareQuotesSideBySideModal() {
  const currentUrl = window.location.search;
  const params = new URLSearchParams(currentUrl);
  const quoteIds = params.get(CompareQuotesUrlParam.QuoteIds)?.split(',');
  const expiringPolicyFileId = params.get(CompareQuotesUrlParam.ExpiringPolicyFileId)?.split(',');
  const { quotes: submissionQuotes, partialSubmission } = useSubmissionsWorkspace();
  const { boxFolderId } = partialSubmission!;

  const itemsList: ComparedItem[] = submissionQuotes
    .filter((quote) => quoteIds?.includes(quote.id))
    .map((quote) => ({
      id: quote.id,
      fileIds: quote.marketQuoteFileIds,
      label: quote.marketName ?? quote.id,
      metadata: {
        attachmentPoint: quote.layer.attachmentPoint,
        premium: quote.premium,
      },
    }));

  if (expiringPolicyFileId && !isEmpty(expiringPolicyFileId)) {
    itemsList.push({
      id: `expiring-policy-${expiringPolicyFileId}`,
      fileIds: expiringPolicyFileId,
      label: 'Expiring Policy',
    });
  }

  const [selectedItem1, setSelectedItem1] = useState<ComparedItem>(itemsList[0]);
  const [selectedItem2, setSelectedItem2] = useState<ComparedItem>(itemsList[1]);

  return (
    <Dialog open fullScreen>
      <Stack sx={{ flex: 1, overflowY: 'auto' }}>
        <Stack width={1} height={1}>
          <Stack direction="row" divider={<Divider flexItem orientation="vertical" />} boxShadow={3} zIndex={1}>
            <Stack flex={1} sx={{ p: 3 }}>
              <CompareQuoteHeader items={itemsList} selectedItem={selectedItem1} setSelectedItem={setSelectedItem1} />
            </Stack>
            <Stack flex={1} sx={{ p: 3 }}>
              <CompareQuoteHeader items={itemsList} selectedItem={selectedItem2} setSelectedItem={setSelectedItem2} />
            </Stack>
          </Stack>
          <Stack flex={1} direction="row" divider={<Divider flexItem orientation="vertical" />} overflow="hidden">
            <CompareQuotePreview fileIds={selectedItem1.fileIds} boxFolderId={boxFolderId} />
            <CompareQuotePreview fileIds={selectedItem2.fileIds} boxFolderId={boxFolderId} />
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}
