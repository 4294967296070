import { isArray } from 'lodash';
import { HeraldApplication, HeraldIndexEntry } from 'clients/types';
import { Submission } from 'types';
import { formatAddress, MakeFieldsRequired } from 'utils';
import {
  heraldDueDateParameterIds,
  HeraldFormState,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';
import {
  flowStaticQuestionsConfig,
  FlowStaticQuestionsIds,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/utils/flow-static-questions-config';

type SubmissionHeraldMapper = MakeFieldsRequired<
  {
    [key in keyof Submission]?: string[];
  },
  'insuredAddress'
>;

export const submissionHeraldMapper: SubmissionHeraldMapper = {
  insuredEmployeeCount: ['rsk_k39d_number_of_employees'],
  insuredAnnualRevenue: ['rsk_vrb1_total_annual_revenue'],
  insuredWebsite: ['rsk_dy7r_domain_names'],
  insuredName: ['rsk_m4p9_insured_name'],
  industry: ['rsk_b3jm_2017_naics_index'],
  insuredAddress: ['rsk_jsy2_primary_address', 'rsk_yor8_location', 'rsk_tvm3_mailing_address'],
  dueDate: heraldDueDateParameterIds,
  limit: [
    'cvg_wsz8_gl_general_aggregate_limit',
    'cvg_agj9_cyb_aggregate_limit',
    'cvg_8yyn_teo_cyb_aggregate_limit',
    'cvg_zlba_pl_aggregate_limit',
    'cvg_4k4d_ml_do_aggregate_limit',
    'cvg_1x3s_ml_epli_aggregate_limit',
    'cvg_pu6d_ml_do_retired_independent_liability_aggregate_limit',
    'cvg_zfn3_ml_do_securityholder_derivative_demand_aggregate_limit',
    'cvg_th04_ml_do_additional_clause_a_aggregate_limit',
    'cvg_bx1m_ml_do_terrorism_event_aggregate_limit',
    'cvg_1tu1_ml_do_public_relations_expenses_aggregate_limit',
    'cvg_w1n8_ml_fid_aggregate_limit',
    'cvg_pl12_ml_kr_aggregate_limit',
    'cvg_v1vv_ml_crm_aggregate_limit',
    'cvg_wsz8_gl_general_aggregate_limit',
    'cvg_672w_bop_general_aggregate_limit',
  ],
  needByDate: [FlowStaticQuestionsIds.NeedByDate],
};

export function mapDynamicFormToPartialSubmission(
  heraldApplication: HeraldApplication,
  heraldFormState: HeraldFormState,
  heraldIndexEntry?: HeraldIndexEntry,
): Partial<Submission> {
  const data: Partial<Submission> = {};
  Object.keys(submissionHeraldMapper).forEach((key) => {
    const submissionFieldName = key as keyof Submission;
    const mappedFieldNames = submissionHeraldMapper[submissionFieldName];
    if (!mappedFieldNames) {
      return;
    }
    let mappedValue: any;
    for (let i = 0; i < mappedFieldNames.length; i += 1) {
      const mappedFieldName = mappedFieldNames[i];
      const mappedFieldNameInFlowStaticConfig = flowStaticQuestionsConfig.find(
        (flowStaticQuestionConfig) => flowStaticQuestionConfig.parameter_id === mappedFieldName,
      );
      // if the field is in the flow static config, then get the value from the form state (as it wasn't in the heraldApplication)
      if (mappedFieldNameInFlowStaticConfig) {
        const heraldFormStateEntry = heraldFormState[mappedFieldName];
        if (isArray(heraldFormStateEntry)) {
          mappedValue = heraldFormStateEntry.map((field) => field.main);
        } else {
          mappedValue = heraldFormStateEntry?.main;
        }
      } else {
        mappedValue = heraldApplication.risk_values.find(
          (riskValue) => mappedFieldName === riskValue.risk_parameter_id,
        )?.value;
        if (!mappedValue) {
          mappedValue = heraldApplication.coverage_values.find(
            (coverageValue) => mappedFieldName === coverageValue.coverage_parameter_id,
          )?.value;
        }
      }

      if (mappedValue !== undefined) {
        break;
      }
    }

    if (mappedValue !== undefined) {
      if (submissionFieldName === 'industry') {
        if (heraldIndexEntry) {
          data[submissionFieldName] = {
            heraldId: heraldIndexEntry.id,
            description: heraldIndexEntry.description,
            naicsCode: heraldIndexEntry.naics_2017_6_digit,
          };
        }
      } else if (submissionFieldName === 'insuredAddress') {
        const location = mappedValue as unknown as {
          city?: string;
          state?: string;
          country_code?: string;
          postal_code?: string;
          line1?: string;
          line2?: string;
        } | null;
        if (location) {
          data.insuredAddress = formatAddress(location);
          data.insuredStructuredAddress = { state: location.state, country: location.country_code };
        } else {
          data.insuredAddress = '';
          data.insuredStructuredAddress = {};
        }
      } else {
        // @ts-ignore
        data[submissionFieldName] = mappedValue;
      }
    }
  });
  return data;
}
