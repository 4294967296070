import { ChangeEvent, useEffect, useState } from 'react';
import { TextField } from '@common-components';
import EditAdornment from './EditAdornment';
import { LinkAdornment } from './LinkAdornment';

export interface CardTextFieldProps {
  onChange?: (value: string) => void | Promise<void>;
  to?: string;
  label: string;
  value: string;
}

export default function CardTextField({ onChange, to, label, value }: CardTextFieldProps) {
  const [newValue, setNewValue] = useState(value);
  const [editing, setEditing] = useState(false);

  // If the field is read only, follow external changes from the prop
  useEffect(() => {
    if (value !== newValue && !editing) {
      setNewValue(value);
    }
    // We only want to update the internal state when the value prop changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function onNewValue(event: ChangeEvent<HTMLInputElement>) {
    const { value: changedValue } = event.target;
    setNewValue(changedValue);
  }

  async function onSave() {
    if (onChange) {
      await onChange(newValue);
    }
    setEditing(false);
  }

  return (
    <TextField
      disabled={!onChange || !editing}
      fullWidth
      InputProps={{
        endAdornment: (
          <>
            <EditAdornment editing={editing} onEdit={() => setEditing(true)} onSave={onChange ? onSave : undefined} />
            <LinkAdornment to={to} />
          </>
        ),
      }}
      InputLabelProps={{ shrink: true }}
      label={label}
      margin="normal"
      onChange={onNewValue}
      value={newValue}
      variant="outlined"
    />
  );
}
