import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  ButtonBase,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuProps,
  SearchBar,
  Selector,
  Stack,
  Tooltip,
  Typography,
} from '@common-components';
import { Close as CloseButton, FilterList as FilterListIcon, MoreHoriz as MoreHorizIcon } from '@icons';

import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { SortAndFilterProps } from 'utils';
import MenuItemWithCheckbox from 'components/menu/MenuItemWithCheckbox';
import { itemHorizontalPadding } from 'components/menu/utils';

export enum FilterButtonTypes {
  Dropdown = 'Dropdown',
  Dots = 'Dots',
  FilterIcon = 'FilterIcon',
}

export interface BaseFilterProps<T, S>
  extends Pick<SortAndFilterProps<T, S>, 'filters' | 'handleFilterChange' | 'isAllFilters' | 'handleAllSelected'> {
  disableZeroCountOptions?: boolean;
  search?: boolean;
  searchPlaceholder?: string;
  menuProps?: Omit<MenuProps, 'open'>;
  title?: string;
}

export interface FilterProps<T, S> extends BaseFilterProps<T, S> {
  buttonType: FilterButtonTypes;
  filterIconTooltipText?: string;
}

export default function Filter<T, S>({
  filters,
  handleFilterChange,
  handleAllSelected,
  menuProps,
  title = '',
  buttonType,
  disableZeroCountOptions = false,
  search = false,
  searchPlaceholder,
}: FilterProps<T, S>) {
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useOptionsMenu();
  const [filterItems, setFilterItems] = useState('');
  const [localFilters, setLocalFilters] = useState([...filters]);

  const filterItem = useMemo(
    () => (itemText: string) => filterItems && !itemText.toLowerCase().includes(filterItems.toLowerCase()),
    [filterItems],
  );

  const checkedItemsLength = filters.filter((filter) => filter.checked).length;

  const localFiltersByGroup = useMemo(
    () =>
      // groupBy to group filters by group property, if not provided, group by 'default'
      localFilters.reduce((acc, filter) => {
        const group = filter.group || 'default';
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(filter);
        return acc;
      }, {} as Record<string, typeof filters>),

    [localFilters],
  );

  useEffect(() => {
    setLocalFilters((prev) => {
      if (filters.length === 0) {
        return prev;
      }
      return [...filters];
    });
  }, [filters]);

  const onCloseMenu = () => {
    closeMenu();
    setFilterItems('');
  };

  const handleChipClick = (key: string) => {
    const fakeEvent = { target: { checked: false } } as ChangeEvent<HTMLInputElement>;
    handleFilterChange(fakeEvent, key); // Uncheck the filter
  };

  const getIconActiveFilterBadge = (color: 'secondary' | 'primary') =>
    checkedItemsLength > 0 && (
      <Badge
        badgeContent={checkedItemsLength}
        color={color}
        sx={{
          pl: 0.5,
          '& .MuiBadge-badge': {
            color: 'common.white',
            bgcolor: color === 'primary' ? 'blue.700' : 'green.500',
            position: 'relative',
            transform: 'scale(1) translate(0%, 0%)',
            transformOrigin: '50% 50%',
            '&.MuiBadge-invisible': {
              transform: 'scale(0) translate(0%, 0%)',
            },
          },
        }}
      />
    );

  const moreOptionsButton = (
    <Tooltip tooltipContent={messages.marketsPage.categoriesFilter.moreTooltip}>
      <ButtonBase
        onClick={openMenu}
        aria-label="menu"
        sx={{
          alignContent: 'center',
          height: 40,
          border: '1px solid',
          borderRadius: 1,
          borderColor: 'blueGrey.200',
          color: filters.some((filter) => filter.checked) ? 'green.500' : 'inherit',
          width: 66,
          '&:hover': {
            borderColor: 'blueGrey.800',
          },
        }}
      >
        <MoreHorizIcon />
        {getIconActiveFilterBadge('secondary')}
      </ButtonBase>
    </Tooltip>
  );

  const getFilterButtonType = () => {
    switch (buttonType) {
      case FilterButtonTypes.Dropdown:
        return <Selector content={title} onClick={openMenu} badgeContent={checkedItemsLength} />;
      case FilterButtonTypes.Dots:
        return moreOptionsButton;
      case FilterButtonTypes.FilterIcon:
        return (
          <IconButton
            size="small"
            variant="text"
            color="secondary"
            icon={FilterListIcon}
            indicator={checkedItemsLength > 0}
            onClick={openMenu}
          />
        );
      default:
        return null;
    }
  };

  const getFiltersEmptyState = (group: string) => (
    <Stack
      px={itemHorizontalPadding}
      py={2}
      height={52}
      justifyContent="center"
      alignItems="center"
      gap={2}
      color="text.disabled"
    >
      <Stack justifyContent="center" alignItems="center">
        <Typography variant="body2Bold">
          {messages.general.filtersNotFound(group !== 'default' ? group : undefined)}
        </Typography>
        <Typography variant="caption">{messages.general.filtersNotFoundSubTitle}</Typography>
      </Stack>
    </Stack>
  );

  return (
    <>
      <Tooltip
        disableFocusListener
        placement="top"
        tooltipContent={
          checkedItemsLength > 0
            ? Object.entries(localFiltersByGroup).map(([group, fl]) => (
                <Stack key={group} direction="row" flexWrap="wrap" gap={0.5}>
                  {fl
                    .filter((filter) => filter.checked)
                    .map((filter) => (
                      <Chip
                        size="small"
                        key={filter.key}
                        label={filter.label}
                        color="secondary"
                        endIcon={CloseButton}
                        onClick={() => {
                          handleChipClick(filter.key);
                        }}
                      />
                    ))}
                </Stack>
              ))
            : ''
        }
      >
        <Box>{getFilterButtonType()}</Box>
      </Tooltip>

      <Menu
        disableScrollLock
        disableEnforceFocus
        id="market-filters-menu-popover"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={onCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'market-filters-menu',
          sx: { py: 0 },
        }}
        {...menuProps}
      >
        {localFilters.length === 0 ? (
          <Stack px={2} py={3} component={Typography} variant="caption" color="text.secondary" minWidth={200}>
            {messages.general.filtersEmptyState(title)}
          </Stack>
        ) : (
          <Stack>
            {search && (
              <SearchBar
                autoFocus
                onChange={setFilterItems}
                value={filterItems}
                placeholder={searchPlaceholder || messages.general.searchPlaceholder}
                id="categoriesFilter"
                variant="standard"
                height={48}
                searchIconStyle={{ ml: 1 }}
              />
            )}
            <Stack maxHeight={230} minWidth={300} maxWidth={640} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
              {Object.entries(localFiltersByGroup).map(([group, fl], index) => (
                <Stack key={group}>
                  {index > 0 && <Divider />}
                  {group !== 'default' && (
                    <Typography
                      component="div"
                      px={itemHorizontalPadding}
                      py={0.75}
                      variant="caption2Bold"
                      color="typography.nonEssential"
                      bgcolor="grey.50"
                    >
                      {group}
                    </Typography>
                  )}
                  {fl.every((filter) => filterItem(filter.label)) && getFiltersEmptyState(group)}
                  {fl.map((filter) => (
                    <MenuItemWithCheckbox
                      sx={{ display: filterItem(filter.label) ? 'none' : 'inherit' }}
                      key={filter.key + filter.label}
                      // divider
                      label={filter.label}
                      checked={filter.checked || false}
                      disabled={disableZeroCountOptions}
                      onChange={(e) => handleFilterChange(e, filter.key)}
                    />
                  ))}
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}

        {localFilters.length > 0 && (
          <Stack>
            <Divider />
            <Stack direction="row" sx={{ p: 2 }} justifyContent="space-between" gap={2}>
              <Button
                onClick={() => {
                  handleAllSelected();
                  closeMenu();
                }}
                size="small"
                color="inherit"
              >
                {messages.buttons.clear}
              </Button>
              <Button onClick={closeMenu} size="small" variant="contained">
                {messages.buttons.done}
              </Button>
            </Stack>
          </Stack>
        )}
      </Menu>
    </>
  );
}
