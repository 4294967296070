// eslint-disable-next-line no-restricted-imports
import { Menu as MuiMenu } from '@mui/material';
import { isObject } from 'lodash';
import { useState } from 'react';
// eslint-disable-next-line import/no-internal-modules
import { OptionsMenuState } from 'hooks/useOptionsMenu';
import { messages } from 'i18n';
import { RequireAtLeastOne } from 'utils';
import Button from 'components/Button';
import { Divider, menuClasses, Stack } from 'components/mui-index';
import SearchBar from 'components/SearchBar';
import { MenuPlacement } from 'components/types';
import MenuContent from './MenuContent';
import { MenuGroup } from './types';
import { getMenuAnchorOrigin, getMenuTransformOrigin, itemHorizontalPadding, itemVerticalPadding } from './utils';

interface MenuProps {
  /**
   * useOptionsMenu() hook should be passed  .
   * @default 'useOptionsMenu()'
   */
  optionsMenuState: OptionsMenuState;
  /**
   * An array of objects That represent the menu items group.
   */
  menuItems: MenuGroup[];
  /** Footer props of the menu. */
  footer?:
    | true
    | {
        confirmButton?: RequireAtLeastOne<{ text?: string; onClick?: () => void; disabled?: boolean }>;
        cancelButton?: RequireAtLeastOne<{ text?: string; onClick?: () => void; disabled?: boolean }>;
      };
  /** Search bar props. */
  searchProps?: {
    placeholder?: string;
    onChange: (value: string) => void;
    defaultValue?: string;
  };
  /**
   * The Position of the menu.
   * @default 'center'
   */
  placement?: MenuPlacement;
  /** The opening direction of the sub menu. */
  subMenuPlacement?: 'left' | 'right';
}
export default function NewMenu({
  optionsMenuState,
  menuItems,
  placement = 'bottom-left',
  footer,
  searchProps,
  subMenuPlacement,
  ...props
}: MenuProps) {
  const { anchorEl, isMenuOpen, closeMenu } = optionsMenuState;

  const [searchValue, setSearchValue] = useState(searchProps?.defaultValue || '');

  const handleCloseMenu = () => {
    closeMenu();
    searchProps?.onChange('');
    setSearchValue(searchProps?.defaultValue || '');
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (searchProps?.onChange) {
      searchProps.onChange(value);
    }
  };

  return (
    <MuiMenu
      PaperProps={{
        sx: {
          minWidth: 280,
          [`& .${menuClasses.list}`]: {
            pb: footer ? 7.5 : 1,
            pt: searchProps ? 0 : 1,
          },
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      disableScrollLock
      disableEnforceFocus
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleCloseMenu}
      anchorOrigin={getMenuAnchorOrigin(placement)}
      transformOrigin={getMenuTransformOrigin(placement)}
      {...props}
    >
      {searchProps && (
        <SearchBar
          autoFocus
          onChange={handleSearchChange}
          value={searchValue}
          placeholder={searchProps.placeholder || messages.general.searchPlaceholder}
          variant="standard"
          height={48}
          searchIconStyle={{ ml: 1 }}
          clearSx={{ mr: 1 }}
          onKeyDown={(e) => e.stopPropagation()}
        />
      )}
      <MenuContent
        searchValue={searchValue}
        menuItems={menuItems}
        isMenuOpen={isMenuOpen}
        closeMenu={handleCloseMenu}
        subMenuPlacement={subMenuPlacement}
      />
      {footer && (
        <Stack position="absolute" bottom={0} left={0} right={0}>
          <Divider />
          <Stack
            direction="row"
            px={itemHorizontalPadding}
            py={itemVerticalPadding}
            justifyContent="space-between"
            gap={1}
          >
            {isObject(footer) && footer.cancelButton && (
              <Stack flex={1} alignItems="flex-start">
                <Button
                  onClick={() => {
                    if (isObject(footer) && footer.cancelButton?.onClick) {
                      footer.cancelButton.onClick();
                    }
                    handleCloseMenu();
                  }}
                  size="small"
                  color="inherit"
                  disabled={isObject(footer) && footer.cancelButton?.disabled}
                >
                  {isObject(footer) && footer.cancelButton?.text ? footer.cancelButton?.text : messages.buttons.clear}
                </Button>
              </Stack>
            )}

            <Stack flex={1} alignItems="flex-end">
              <Button
                onClick={() => {
                  if (isObject(footer) && footer.confirmButton?.onClick) {
                    footer.confirmButton.onClick();
                  }
                  handleCloseMenu();
                }}
                size="small"
                variant="contained"
                disabled={isObject(footer) && footer.confirmButton?.disabled}
              >
                {isObject(footer) && footer.confirmButton?.text ? footer.confirmButton?.text : messages.buttons.done}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
    </MuiMenu>
  );
}
