import { FormProvider, SubmitHandler } from 'react-hook-form';
import { Avatar, Stack, Typography } from '@common-components';
import { PersonOutline } from '@icons';
import { ContactRole, OrganizationType } from 'enums';
import { useBoolean, useFormProvider, useRetrieveUserMarket } from 'hooks';
import { messages } from 'i18n';
import { SubmissionMarket } from 'types';
import { useEmailFormFieldValidation } from 'broker/hooks';
import { useContactActions } from 'broker/pages/SubmissionWorkspacePage/store/useContactActions';
import { ContactForm } from './ContactForm';
import { ContactFormButtons } from './ContactFormButtons';
import { ContactData, schema } from './types';

export const AddContactForm = ({
  submissionMarket,
  close,
}: {
  submissionMarket: SubmissionMarket;
  close: () => void;
}) => {
  const isCarrier = submissionMarket.marketOrganizationType === OrganizationType.Carrier;
  const { methods } = useFormProvider<ContactData>({
    schema,
    mode: 'onBlur',
    defaultValues: { role: isCarrier ? ContactRole.Underwriter : ContactRole.Broker },
  });

  const [isSubmitting, { on: startSubmit, off: stopSubmit }] = useBoolean(false);

  const { data: userMarket } = useRetrieveUserMarket({ id: submissionMarket.userMarketId });

  const { addContactToUserMarket } = useContactActions();
  const { validateEmailAddresses } = useEmailFormFieldValidation({ methods });

  const handleClose = () => {
    close();
    methods.reset();
  };

  const addContact: SubmitHandler<ContactData> = async (data) => {
    if (!userMarket) {
      return;
    }

    startSubmit();

    if (!(await validateEmailAddresses([data.email]))) {
      stopSubmit();
      return;
    }

    await addContactToUserMarket(userMarket, { ...data, organizationId: userMarket.organizationId });

    stopSubmit();
    handleClose();
  };

  return (
    <FormProvider {...methods}>
      <Stack bgcolor="grey.100" px={1} pb={3}>
        <Stack direction="row" py={3} gap={2} alignItems="center" px={2}>
          <Avatar sx={{ bgcolor: 'blue.700', fontSize: 42 }}>
            <PersonOutline sx={{ color: 'common.white' }} />
          </Avatar>
          <Typography variant="body2" fontWeight={600} flex={1}>
            {messages.submissionMarketToolbox.contacts.newContact}
          </Typography>
          <ContactFormButtons
            onSave={methods.handleSubmit(addContact)}
            onCancel={handleClose}
            isSubmitting={isSubmitting}
          />
        </Stack>
        <ContactForm submissionMarket={submissionMarket} />
      </Stack>
    </FormProvider>
  );
};
