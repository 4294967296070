import { forEach, isEmpty, isNil, uniq } from 'lodash';
import { ApiError } from 'clients/errors';
import { ForbiddenError } from 'clients/errors/ForbiddenError';
import {
  CoverageValuesUpdate,
  HeraldApiError,
  HeraldApplicationResponse,
  HeraldCreateApplication,
  HeraldFormParameterId,
  HeraldUpdateApplication,
  RiskValuesUpdate,
} from 'clients/types';
import { HeraldQuoteStatus, SubmissionMarketRequestStatus, SubmissionMethod } from 'enums';
import {
  useHeraldApplicationApi,
  useHeraldSubmissionApi,
  useHotjar,
  useMutateHeraldQuote,
  useRetrieveHeraldApplication,
  useToast,
} from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission, SubmissionMarketRequest } from 'types';
import { logger } from 'utils';
import { HotjarEvents } from 'utils/hotjar-events';
import {
  isManagementLiabilityCoverage,
  ManagementLiabilityCoverageLines,
  managementLiabilityCoverageLineToHeraldCoveragesConfig,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/coverage-line-to-herald-coverages-config';
import { heraldParametersDefaultValuesConfig } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/utils/herald-parameters-default-values-config';
import { useGetApiProductsByCoverageLines } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { CoverageLineChanges } from './types';

function convertRiskValueToLean(riskValue: RiskValuesUpdate): RiskValuesUpdate {
  return {
    risk_parameter_id: riskValue.risk_parameter_id,
    value: riskValue.value,
    instance: riskValue.instance,
    child_risk_values: riskValue.child_risk_values?.map(convertRiskValueToLean),
  };
}

function convertCoverageValueToLean(coverageValue: CoverageValuesUpdate): CoverageValuesUpdate {
  return {
    coverage_parameter_id: coverageValue.coverage_parameter_id,
    value: coverageValue.value,
    instance: coverageValue.instance,
    child_coverage_values: coverageValue.child_coverage_values?.map(convertCoverageValueToLean),
  };
}

function addDefaultValuesToHeraldApplication(application: {
  coverage_values?: CoverageValuesUpdate[];
  risk_values?: RiskValuesUpdate[];
}) {
  const updateHeraldApplication = { ...application };
  const riskValues: RiskValuesUpdate[] = updateHeraldApplication.risk_values
    ? [...updateHeraldApplication.risk_values]
    : [];
  const coverageValues: CoverageValuesUpdate[] = updateHeraldApplication.coverage_values
    ? [...updateHeraldApplication.coverage_values]
    : [];

  forEach(heraldParametersDefaultValuesConfig, (value, key) => {
    if (key.startsWith('rsk_')) {
      // find if key exists in riskValues.risk_parameter_id
      const index = riskValues.findIndex((riskValueItem) => riskValueItem.risk_parameter_id === key);
      if (index !== -1) {
        // add value (default value) to it only if it is nill
        if (isNil(riskValues[index].value)) {
          riskValues[index] = { ...riskValues[index], value };
        }
      }
      // if it doesn't exist then add it to riskValues
      else {
        riskValues.push({ risk_parameter_id: key, value });
      }
    } else {
      // find if key exists in coverageValues.coverage_parameter_id
      const index = coverageValues.findIndex((coverageValueItem) => coverageValueItem.coverage_parameter_id === key);
      if (index !== -1) {
        // add value (default value) to it only if it is nill
        if (isNil(coverageValues[index].value)) {
          coverageValues[index] = { ...coverageValues[index], value };
        }
      }
      // if it doesn't exist then add it to coverageValues
      else {
        coverageValues.push({ coverage_parameter_id: key, value });
      }
    }
  });

  return {
    ...updateHeraldApplication,
    coverage_values: coverageValues,
    risk_values: riskValues,
  };
}

export function areHeraldProductsEligibleToCreateSubmission(
  submission: PartialSubmission,
  products: string[],
): { eligible: boolean; reason: string } {
  if (!submission.heraldData?.applicationId || submission.heraldData.isArchived) {
    return { eligible: false, reason: messages.heraldApi.noHeraldApplicationInCoverageLines };
  }
  const { heraldData } = submission;

  const productsNotInApplication = products.filter((product) => !heraldData.products.includes(product));
  if (productsNotInApplication.length > 0) {
    return { eligible: false, reason: messages.heraldApi.productsNotInApplication(productsNotInApplication) };
  }
  if (heraldData.status !== 'complete') {
    return { eligible: false, reason: messages.heraldApi.applicationIncompleteStatus };
  }

  return { eligible: true, reason: '' };
}
export function useCreateHeraldSubmissionFromApplication() {
  const { createApplication, getApplicationById } = useHeraldApplicationApi();
  const { createSubmission } = useHeraldSubmissionApi();

  return async (submission: PartialSubmission, products: string[]) => {
    const { eligible, reason } = areHeraldProductsEligibleToCreateSubmission(submission, products);
    if (!eligible) {
      throw new Error(reason);
    }
    const application = await getApplicationById(submission.heraldData!.applicationId);

    const intermediateApplication = await createApplication.mutateAsync({
      risk_values: application.risk_values,
      coverage_values: application.coverage_values,
      products,
    });

    if (!intermediateApplication?.application) {
      throw new Error(messages.heraldApi.failedToCreateHeraldSubmission);
    }
    const heraldSubmission = await createSubmission.mutateAsync(intermediateApplication.application.id);

    if (!heraldSubmission?.submission) {
      throw new Error(messages.heraldApi.failedToCreateHeraldSubmission);
    }

    return heraldSubmission.submission;
  };
}

export function useSubmitMarketRequestsViaApi() {
  const { updateSubmissionMarketRequest } = useSubmissionsWorkspace();
  const createHeraldSubmissionFromApplication = useCreateHeraldSubmissionFromApplication();
  const { createHeraldQuote } = useMutateHeraldQuote();
  const hotjar = useHotjar();
  const { showToast } = useToast();

  return async (submission: PartialSubmission, marketRequests: SubmissionMarketRequest[]) => {
    try {
      hotjar.event(HotjarEvents.SubmitProductViaApi);
      const products = uniq(
        marketRequests
          .filter((marketRequest) => !!marketRequest.insuranceProduct?.externalProductId)
          .map((marketRequest) => marketRequest.insuranceProduct!.externalProductId!),
      );

      const newHeraldSubmission = await createHeraldSubmissionFromApplication(submission, products);

      await Promise.all(
        newHeraldSubmission.quote_previews.map(async (quotePreview) => {
          const submissionMarketRequest = marketRequests.find(
            (marketRequest) => marketRequest.insuranceProduct?.externalProductId === quotePreview.product_id,
          )!;
          await createHeraldQuote.mutateAsync({
            data: {
              externalQuoteId: quotePreview.quote_id,
              status: HeraldQuoteStatus.Pending,
              submissionMarketRequestId: submissionMarketRequest.id,
            },
          });
          await updateSubmissionMarketRequest(submissionMarketRequest.id, {
            status: SubmissionMarketRequestStatus.AwaitingQuote,
            submissionMethod: SubmissionMethod.API,
          });
        }),
      );
    } catch (e: any) {
      if (e.message && !(e instanceof ApiError)) {
        // If the error is ApiError don't show a toast to avoid duplication
        // (it was already shown by the error context callback called by the base client)
        showToast('error', { message: e.message });
      }
      throw e;
    }
  };
}

export function useUpdateHeraldApplicationWithSubmissionCoverageLines() {
  const { showToast } = useToast();
  const { partialSubmission, updateSubmission } = useSubmissionsWorkspace();

  const { getApiProductsByCoverageLines } = useGetApiProductsByCoverageLines();
  const { refetch: fetchHeraldApplication } = useRetrieveHeraldApplication({
    id: partialSubmission?.heraldData?.applicationId || '',
    enabled: false,
  });
  const { createApplication, updateApplication } = useHeraldApplicationApi();

  const createHeraldApplication = async (externalProductIds: string[], selectedCoverages: string[]) => {
    const createApplicationData: HeraldCreateApplication = {
      products: externalProductIds,
      risk_values: [],
      coverage_values: isEmpty(selectedCoverages)
        ? []
        : [
            {
              coverage_parameter_id: HeraldFormParameterId.SelectedCoverages,
              value: selectedCoverages,
            },
          ],
    };
    Object.assign(createApplicationData, addDefaultValuesToHeraldApplication(createApplicationData));

    try {
      return await createApplication.mutateAsync(createApplicationData);
    } catch (e: unknown) {
      if (e instanceof ForbiddenError) {
        showToast('error', { message: e.message });
      } else {
        showToast('error', { message: messages.editSubmission.heraldAPiError });
        logger.log('error', {
          message: messages.heraldForm.failedToUpdateApplicationAfterUpdateSubmission,
          submissionId: partialSubmission?.id,
          error: e instanceof Error ? e.message : undefined,
          applicationData: JSON.stringify(createApplicationData),
        });
      }
    }
    return null;
  };

  const updateHeraldApplication = async (
    applicationId: string,
    externalProductIds: string[],
    selectedCoverages: string[],
  ) => {
    const existingApplication = await fetchHeraldApplication();
    const coverageValues = existingApplication.data?.application.coverage_values
      ? existingApplication.data.application.coverage_values.map(convertCoverageValueToLean)
      : [];
    const updatedCoverageValues = isEmpty(selectedCoverages)
      ? coverageValues
      : [
          ...coverageValues.filter((value) => value.coverage_parameter_id !== HeraldFormParameterId.SelectedCoverages),
          {
            coverage_parameter_id: HeraldFormParameterId.SelectedCoverages,
            value: selectedCoverages,
          },
        ];
    const updateData: HeraldUpdateApplication = {
      products: externalProductIds,
      risk_values: existingApplication.data?.application.risk_values
        ? existingApplication.data.application.risk_values.map(convertRiskValueToLean)
        : [],
      coverage_values: updatedCoverageValues,
    };

    // add default values to the updated application
    Object.assign(updateData, addDefaultValuesToHeraldApplication(updateData));
    try {
      return await updateApplication.mutateAsync({
        applicationId,
        heraldApplication: updateData,
      });
    } catch (e: unknown) {
      const fieldError = (() => {
        if (e && typeof e === 'object' && 'errors' in e) {
          const error = e as HeraldApiError;
          if (error.errors?.length) {
            // get the first error message
            return `${error.errors[0].path}: ${error.errors[0].display_message}`;
          }
        }
        return false;
      })();

      if (e instanceof ForbiddenError) {
        showToast('error', { message: e.message });
      } else if (fieldError) {
        showToast('error', {
          message: messages.heraldForm.failedToUpdateApplicationDueToValidation(fieldError),
        });
        logger.log('error', {
          message: messages.heraldForm.failedToUpdateApplicationDueToValidation(fieldError),
          submissionId: partialSubmission?.id,
          error: e instanceof Error ? e.message : undefined,
          applicationId,
        });
      } else {
        showToast('error', { message: messages.editSubmission.heraldAPiError });
        logger.log('error', {
          message: messages.heraldForm.failedToUpdateApplicationAfterUpdateSubmission,
          submissionId: partialSubmission?.id,
          error: e instanceof Error ? e.message : undefined,
          applicationId,
        });
      }
    }
    return null;
  };

  /**
   * Update the Herald application with the updated coverage lines from the submission
   * @param CoverageLineChanges - object - updatedCoverageLines: the updated coverage lines from the submission oldCoverageLines: the old coverage lines from the submission before update
   * @returns  string[]|undefined: the external product ids that are associated with the updated coverage lines or undefined if there was an error trying to update the application with the products
   */
  return async ({ oldCoverageLines, updatedCoverageLines }: CoverageLineChanges) => {
    const externalProduct = await getApiProductsByCoverageLines(updatedCoverageLines);
    const externalProductIds = uniq(externalProduct.map((product) => product.externalProductId)) as string[];

    const heraldSelectedCoverages = uniq(
      updatedCoverageLines
        .filter((coverageLine) => isManagementLiabilityCoverage(coverageLine))
        .map(
          (coverageLine) =>
            managementLiabilityCoverageLineToHeraldCoveragesConfig[coverageLine as ManagementLiabilityCoverageLines],
        ),
    );

    if (externalProductIds.length > 0) {
      let heraldResponse: HeraldApplicationResponse | null = null;
      if (partialSubmission?.heraldData) {
        heraldResponse = await updateHeraldApplication(
          partialSubmission.heraldData.applicationId,
          externalProductIds,
          heraldSelectedCoverages,
        );
      } else {
        heraldResponse = await createHeraldApplication(externalProductIds, heraldSelectedCoverages);
      }
      if (!heraldResponse) {
        // failed to update the application with the products, rollback the coverage lines to the old ones
        await updateSubmission(partialSubmission!.id, { coverageLines: oldCoverageLines || [] });
        // this shouldn't happen, but if there was an error trying to call herald and no exception was thrown, we return undefined
        return undefined;
      }
      await updateSubmission(partialSubmission!.id, {
        heraldData: {
          applicationId: heraldResponse.application.id,
          status: heraldResponse.application.status,
          products: heraldResponse.application.products,
          requiredQuestionsBeforeQuoteExit:
            heraldResponse.quote_exits?.length && partialSubmission?.heraldData?.requiredQuestionsBeforeQuoteExit
              ? partialSubmission?.heraldData?.requiredQuestionsBeforeQuoteExit
              : undefined,
        },
      });
    }
    // if there are no external product ids, we archive the herald data in the submission if it existed before
    else if (partialSubmission?.heraldData && !partialSubmission.heraldData.isArchived) {
      await updateSubmission(partialSubmission.id, {
        heraldData: { ...partialSubmission.heraldData, isArchived: true },
      });
    }
    return externalProductIds;
  };
}
