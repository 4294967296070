import { BoxSkeleton, Stack } from '@common-components';

export default function FileItemSkeleton() {
  return (
    <Stack direction="row" gap={2} px={2} alignItems="center">
      <BoxSkeleton width={128} height={72} isLoading sx={{ borderRadius: 1 }} />
      <Stack gap={1} flex={1}>
        <BoxSkeleton height={20} isLoading sx={{ width: 0.8 }} />
        <BoxSkeleton height={15} sx={{ width: 0.7 }} isLoading />
      </Stack>
    </Stack>
  );
}
