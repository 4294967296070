import { tabClasses, tabsClasses, Theme } from '@common-components';

export const ToolboxNavigationSize = 61;

export const TabStyle = {
  width: 40,
  height: 40,
  margin: 0,
  minWidth: 'auto',
  minHeight: 'auto',
  borderRadius: '50%',
  backgroundColor: 'blueGrey.50',
  transition: (theme: Theme) =>
    theme.transitions.create(['background-color', 'color'], {
      duration: theme.transitions.duration.short,
    }),
  '&:hover': {
    backgroundColor: 'blueGrey.100',
  },
  [`&.${tabClasses.selected}`]: {
    backgroundColor: 'purple.600',
    color: 'common.white',
  },
};

export const TabsStyle = (isOpened: boolean) => ({
  [`& .${tabsClasses.flexContainer}`]: {
    gap: 2,
    py: 2,
  },
  [`& .${tabsClasses.indicator}`]: {
    width: isOpened ? 4 : 0,
    backgroundColor: 'purple.600',
    left: 0,
  },
});
