import { useFormProvider } from 'hooks';
import { quoteComparisonSchema } from './config/quoteComparisonFieldsConfig';
import { InsightsData } from './types';

export const extractQuoteInsightsDefaultValues = (): InsightsData => ({
  insights: '',
});

export function useQuoteFormMethods(setIsDirty: (state: boolean) => void) {
  const insightsDefaultValues = extractQuoteInsightsDefaultValues();

  return useFormProvider({
    defaultValues: insightsDefaultValues,
    schema: quoteComparisonSchema,
    setIsDirty,
    mode: 'onChange',
  });
}
