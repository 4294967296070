import { QuoteFileIdType } from 'enums';
import { messages } from 'i18n';

export enum UrlParams {
  Id = 'id',
  SelectedFile = 'selectedFile',
}

export const quoteTypeToLabel: Record<QuoteFileIdType, string> = {
  [QuoteFileIdType.MarketQuote]: messages.market.filesTypeName.marketQuoteFileIds,
  [QuoteFileIdType.Binder]: messages.market.filesTypeName.binderFileIds,
  [QuoteFileIdType.Policy]: messages.market.filesTypeName.policyFileIds,
};
