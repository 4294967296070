import { QuoteFileIdType } from 'enums';
import { messages } from 'i18n';

type QuoteFilesInfoTooltip = {
  title: string;
  message: (marketName?: string) => string;
};

type QuoteFilesTooltipConfig = Record<QuoteFileIdType, QuoteFilesInfoTooltip>;

export const quoteFilesTooltipConfig: QuoteFilesTooltipConfig = {
  [QuoteFileIdType.MarketQuote]: {
    title: messages.addQuotePage.marketQuote.infoTooltip.title,
    message: messages.addQuotePage.marketQuote.infoTooltip.message,
  },
  [QuoteFileIdType.Binder]: {
    title: messages.addQuotePage.binder.infoTooltip.title,
    message: messages.addQuotePage.binder.infoTooltip.message,
  },
  [QuoteFileIdType.Policy]: {
    title: messages.addQuotePage.policy.infoTooltip.title,
    message: messages.addQuotePage.policy.infoTooltip.message,
  },
};

type QuoteFilesSectionProps = {
  attachFileButton: string;
};

type QuoteFilesSectionConfig = Record<QuoteFileIdType, QuoteFilesSectionProps>;
export const quoteFilesSectionConfig: QuoteFilesSectionConfig = {
  [QuoteFileIdType.MarketQuote]: {
    attachFileButton: messages.addQuotePage.marketQuote.attachFileButton,
  },
  [QuoteFileIdType.Binder]: {
    attachFileButton: messages.addQuotePage.binder.attachFileButton,
  },
  [QuoteFileIdType.Policy]: {
    attachFileButton: messages.addQuotePage.policy.attachFileButton,
  },
};
