import { omit } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { InsuranceProduct } from 'types';
import { SelectedProductsMap } from './types';

export function useProductsState(initialSelectedProducts?: SelectedProductsMap) {
  const [selectedProducts, setSelectedProducts] = useState<SelectedProductsMap | null>(null);

  // Initialize selected products if they are not set, the initialSelectedProducts can be sent on the next renders as it can wait for the insurance products to be loaded
  useEffect(() => {
    if (!selectedProducts && initialSelectedProducts) {
      setSelectedProducts(initialSelectedProducts);
    }
  }, [selectedProducts, initialSelectedProducts]);

  const onProductSelected = useCallback((product: InsuranceProduct) => {
    setSelectedProducts((prev) => ({
      ...prev,
      [product.id]: {
        ...product,
      },
    }));
  }, []);

  const onProductRemoved = useCallback((productId: string) => {
    setSelectedProducts((prev) => omit(prev, [productId]));
  }, []);

  return {
    selectedProducts: selectedProducts || ({} as SelectedProductsMap),
    setSelectedProducts,
    onProductSelected,
    onProductRemoved,
  };
}
