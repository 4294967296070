import { MouseEvent, ReactNode } from 'react';
import { Box, MenuItem, SxProps, Theme } from '@common-components';
import { uniteStyles } from 'utils';

interface AutocompleteActionButtonProps {
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  displayBorder?: boolean;
  sx?: SxProps<Theme>;
  children: ReactNode | string;
}

export default function AutocompleteActionButton({
  onClick,
  disabled = false,
  displayBorder = true,
  children,
}: AutocompleteActionButtonProps) {
  const borderStyling = displayBorder
    ? (theme: Theme) => ({ borderTop: '1px solid', borderColor: theme.palette.grey['100'] })
    : {};

  return (
    <Box sx={uniteStyles(borderStyling, { width: '100%' })}>
      <MenuItem
        component="div"
        sx={{ height: 48, color: 'primary.main', typography: 'body2' }}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </MenuItem>
    </Box>
  );
}
