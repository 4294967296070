import { getTimezoneLessDisplayDate } from '@common/utils/date-utils';
import { BoxTemplateLabels, FileType } from 'enums';
import { useMutateBoxItems } from 'hooks/api/box';
import { PartialSubmission } from 'types';
import { useGeneratePDF } from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/pdf-creator-util';
import { getPdfContent } from './pdf-content';

function getFileName() {
  return `Marketing_Update_${getTimezoneLessDisplayDate(new Date())}.pdf`;
}

export function useGenerateAndUploadMarketingReportPDF() {
  const { uploadFileWithMetadata } = useMutateBoxItems();
  const generatePDF = useGeneratePDF();

  async function generateAndUploadMarketingReportPDF(submission: PartialSubmission, editorContent?: string) {
    const content = getPdfContent({ submission, editorContent });
    const filePdfBlob = await generatePDF(content, 'landscape');

    return uploadFileWithMetadata({
      parentFolderId: submission.boxFolderId,
      fileName: getFileName(),
      file: filePdfBlob,
      retryWithDate: true,
      metadata: { [BoxTemplateLabels.FileType]: FileType.MarketingReport },
    });
  }

  return generateAndUploadMarketingReportPDF;
}
