// eslint-disable-next-line no-restricted-imports
import { PopoverOrigin, Theme } from '@mui/material';
import { MenuPlacement } from 'components/types';

export const itemHorizontalPadding = 1.5;
export const itemVerticalPadding = 1.5;

export const menuIconFontSize = 18;

export const getMenuAnchorOrigin = (placement: MenuPlacement): PopoverOrigin => {
  switch (placement) {
    case 'right-top-to-bottom':
      return { vertical: 'top', horizontal: 'right' };
    case 'left-top-to-bottom':
      return { vertical: 'top', horizontal: 'left' };
    case 'bottom-right':
      return { vertical: 'bottom', horizontal: 'right' };
    case 'bottom-left':
      return { vertical: 'bottom', horizontal: 'left' };
    case 'bottom-center':
      return { vertical: 'bottom', horizontal: 'center' };
    case 'top-right':
      return { vertical: 'top', horizontal: 'right' };
    case 'top-left':
      return { vertical: 'top', horizontal: 'left' };
    case 'top-center':
      return { vertical: 'top', horizontal: 'center' };
    default:
      return { vertical: 'bottom', horizontal: 'right' };
  }
};

// function that convert MenuPlacement to menu transformOrigin
export const getMenuTransformOrigin = (placement: MenuPlacement): PopoverOrigin => {
  switch (placement) {
    case 'right-top-to-bottom':
      return { vertical: 'top', horizontal: 'left' };
    case 'left-top-to-bottom':
      return { vertical: 'top', horizontal: 'right' };
    case 'bottom-right':
      return { vertical: 'top', horizontal: 'right' };
    case 'bottom-left':
      return { vertical: 'top', horizontal: 'left' };
    case 'bottom-center':
      return { vertical: 'top', horizontal: 'center' };
    case 'top-right':
      return { vertical: 'bottom', horizontal: 'right' };
    case 'top-left':
      return { vertical: 'bottom', horizontal: 'left' };
    case 'top-center':
      return { vertical: 'bottom', horizontal: 'center' };
    default:
      return { vertical: 'top', horizontal: 'right' };
  }
};

export function calculateItemsLimitMenuContainerHeight(itemsNumberLimit: number, theme: Theme) {
  const menuItemHeight = 20 + Number(theme.spacing(itemVerticalPadding).replace('px', '')) * 2;
  return (itemsNumberLimit + 0.5) * menuItemHeight;
}
