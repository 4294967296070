import { ChangeEvent } from 'react';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  MenuItemProps,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  TypographyProps,
} from '@common-components';

interface MenuItemWithCheckboxProps extends Omit<MenuItemProps, 'onChange'> {
  label: string;
  subLabel?: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  indicator?: string;
  typographyProps?: TypographyProps;
  sx?: SxProps<Theme>;
}

export default function MenuItemWithCheckbox({
  label,
  subLabel,
  indicator,
  checked,
  onChange,
  typographyProps,
  sx,
  ...props
}: MenuItemWithCheckboxProps) {
  return (
    <MenuItem sx={{ p: 0, ...sx }} {...props}>
      <FormControlLabel
        sx={{
          pl: 0.5,
          pr: 1.5,
          py: 0.5,
          flex: 1,
          m: 0,
          overflow: 'hidden',
          '& .MuiTypography-root': {
            overflow: 'hidden',
          },
        }}
        label={
          <Stack gap={0.5}>
            <Stack direction="row" alignItems="baseline" gap={2}>
              <Tooltip tooltipContent={label}>
                <Typography
                  variant="body2"
                  {...typographyProps}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {label}
                </Typography>
              </Tooltip>

              {indicator && (
                <Typography variant="caption" color="green">
                  {indicator}
                </Typography>
              )}
            </Stack>

            {subLabel && (
              <Typography variant="caption" color="grey.600">
                {subLabel}
              </Typography>
            )}
          </Stack>
        }
        control={<Checkbox disableRipple checked={checked} onChange={onChange} />}
      />
    </MenuItem>
  );
}
