import { isEmpty, partition } from 'lodash';
import { BoxSkeleton, Divider, Stack, Typography } from '@common-components';
import { SubmissionMethod } from 'enums';
import { messages } from 'i18n';
import { InsuranceProduct } from 'types';
import { demoData } from 'utils';
import DataGridNoResults from 'broker/components/common/QuotesAndPolicies/DataGridNoResults';
import MainScrollArea from 'broker/hoc/MainScrollArea';
import { ProductsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/types';
import ProductsTableHeader from './ProductsTableHeader';
import ProductsTableItem from './ProductsTableItem';

export function ProductsTableView({
  products,
  selectedProducts,
  submissionMarkets,
  onProductSelected,
  onProductRemoved,
  isLoading,
  searchInput,
  bottomTableContent,
  quoteExits,
}: ProductsProps) {
  // Helper function to determine available methods based on product properties
  const getAvailableMethods = (product: InsuranceProduct) => {
    const availableMethods = [];
    if (product.externalProductId) {
      availableMethods.push(SubmissionMethod.API);
    }
    if (product.submissionPortal) {
      availableMethods.push(SubmissionMethod.Portal);
    }
    if (!isEmpty(product.contactIds)) {
      availableMethods.push(SubmissionMethod.Email);
    }
    return availableMethods;
  };

  // Split the products into two groups: with API availability and without
  const [apiAvailableProducts, nonApiProducts] = partition(products, (product) => {
    const availableMethods = getAvailableMethods(product);
    return availableMethods.includes(SubmissionMethod.API);
  });

  const renderItems = (productsList: typeof products) => (
    <Stack
      divider={<Divider />}
      borderLeft={1}
      borderRight={1}
      borderBottom={1}
      borderColor="divider"
      borderRadius=" 0 0 8px 8px"
    >
      {!isEmpty(productsList) ? (
        productsList!.map((product) => {
          const submissionMarket = submissionMarkets.find((item) => item.marketId === product.marketId);
          return (
            <ProductsTableItem
              quoteExits={quoteExits}
              key={product.id}
              product={product}
              submissionMarket={submissionMarket}
              searchInput={searchInput}
              selectedProducts={selectedProducts}
              onProductSelected={onProductSelected}
              onProductRemoved={onProductRemoved}
            />
          );
        })
      ) : (
        <DataGridNoResults />
      )}
    </Stack>
  );

  const skeleton = (
    <Stack gap={2} p={2}>
      {demoData(10).map((row, index) => (
        <Stack gap={2} key={row.id}>
          <BoxSkeleton isLoading sx={{ width: 1, height: 45 }} index={index} />
          <Divider />
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Stack overflow="hidden" flex="1 1 0">
      <MainScrollArea id="marketsScrollArea" flex={1} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <Stack m={2}>
          <Typography variant="subtitle2" mb={1}>
            {messages.submissionWorkspace.addProducts.apiAvailable}
          </Typography>
          <ProductsTableHeader />
          {isLoading ? skeleton : renderItems(apiAvailableProducts)}
        </Stack>

        <Stack m={2}>
          <Typography variant="subtitle2" mb={1}>
            {messages.submissionWorkspace.addProducts.otherProducts}
          </Typography>
          <ProductsTableHeader />
          {isLoading ? skeleton : renderItems(nonApiProducts)}
        </Stack>
        {bottomTableContent}
      </MainScrollArea>
    </Stack>
  );
}
