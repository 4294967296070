import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useQuoteInsights } from 'hooks';
import { Submission } from 'types';
import { markdownToHtml } from 'utils';
import { insightsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/insightsFieldsConfig';
import { InsightsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';
import { useCreateAIThread } from 'broker/pages/SubmissionWorkspacePage/hooks';
import { InsightPopulatedByAI } from './types';

export interface QuoteInsightsInput {
  submission: Submission;
  marketQuoteFileIds: string[];
  setInsightPopulatedByAI: (insight: InsightPopulatedByAI) => void;
  shouldCreateAIThread: boolean;
}

export default function useGetQuoteInsights({
  submission,
  marketQuoteFileIds,
  setInsightPopulatedByAI,
  shouldCreateAIThread,
}: QuoteInsightsInput) {
  const { quoteInsights } = useQuoteInsights();
  const { setValue, getValues } = useFormContext();

  const convertToHtml = (content: string) => markdownToHtml(content);

  const setEditorContent = (content: string) => {
    setValue(insightsFormFieldsConfig[InsightsFieldsNames.Insights].name, content);
  };

  const isEmptyQuoteInsights = isEmpty(getValues(insightsFormFieldsConfig[InsightsFieldsNames.Insights].name));

  const aiInputData = { fileIds: marketQuoteFileIds, submissionId: submission.id };

  useCreateAIThread(
    () => quoteInsights(aiInputData),
    shouldCreateAIThread,
    (result) => {
      if (result) {
        if (isEmptyQuoteInsights) {
          const htmlContent = convertToHtml(result?.response);
          setEditorContent(htmlContent);
          setInsightPopulatedByAI({
            result: htmlContent,
            input: aiInputData,
          });
        }
      }
    },
  );
}
