import { Box, ButtonBase, darken, NewMenu, Stack, Typography } from '@common-components';
import { KeyboardArrowDown } from '@icons';
import { useOptionsMenu } from 'hooks';
import { DropdownMenuItems } from './common/MarketRequestItem/types';

interface MarketItemStatusBadgeProps {
  title: string;
  color?: string;
  dropdownMenuItems: DropdownMenuItems[];
}

export default function StatusBadge({ title, color, dropdownMenuItems }: MarketItemStatusBadgeProps) {
  const optionsMenuState = useOptionsMenu();

  const onMenuItemClicked = (action: () => void) => {
    action();
    optionsMenuState.closeMenu();
  };

  return (
    <>
      <Stack
        title={title}
        bgcolor={optionsMenuState.isMenuOpen ? darken(color!, 0.1) : color}
        px={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        component={ButtonBase}
        gap={0.5}
        height={1}
        width={1}
        onClick={optionsMenuState.openMenu}
        sx={{
          cursor: 'pointer',
          '& .MuiTouchRipple-root': { borderRadius: 0.75 },
          '&:hover': {
            bgcolor: darken(color!, 0.06),
          },
        }}
      >
        <Typography
          textAlign="center"
          variant="captionBold"
          color="text.primary"
          py={1}
          px={2}
          sx={{ textTransform: 'uppercase' }}
        >
          {title}
        </Typography>
        <Box position="absolute" top={10} right={10}>
          <KeyboardArrowDown sx={{ color: 'text.primary' }} fontSize="small" />
        </Box>
      </Stack>
      <NewMenu
        placement="right-top-to-bottom"
        optionsMenuState={optionsMenuState}
        menuItems={[
          {
            key: 'statusMenu',
            items: dropdownMenuItems.map((dropDownItem) => ({
              label: dropDownItem.label.toUpperCase(),
              disabled: dropDownItem.disabled === false ? false : !dropDownItem.child,
              color: dropDownItem.color,
              onClick: dropDownItem.onClick ? () => onMenuItemClicked(dropDownItem.onClick!) : undefined,
              subMenu: dropDownItem.child
                ? [
                    {
                      key: `statusSubMenu${dropDownItem.label}`,
                      items: dropDownItem.child?.map((dropDownItemChild) => ({
                        key: dropDownItemChild.label,
                        label: dropDownItemChild.label,
                        onClick: () => onMenuItemClicked(dropDownItemChild.onClick!),
                      })),
                    },
                  ]
                : undefined,
            })),
          },
        ]}
      />
    </>
  );
}
