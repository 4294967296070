import { useState } from 'react';
import { Divider, GridRenderCellParams, Stack, Tooltip, Typography } from '@common-components';
import { AddCircle } from '@icons';
import { EmailTemplateType } from 'enums';
import { useRetrieveUser, useSearchEmailTemplate } from 'hooks';
import { messages } from 'i18n';
import DataGridList, { DEFAULT_PAGE_SIZE } from 'components/DataGridList';
import InternalLink from 'components/InternalLink';
import ADMIN_ROUTES from 'admin/admin-routes';
import SelectField from 'admin/components/SelectField';

export default function EmailTemplateList({ userId }: { userId: string }) {
  const [templateType, setTemplateType] = useState<string | null>(null);
  const { data: user } = useRetrieveUser({ id: userId!, enabled: !!userId });
  const [offset, setOffset] = useState<number>(0);

  const pageSize = DEFAULT_PAGE_SIZE;

  const {
    items: emailTemplates,
    count,
    isInitialLoading,
  } = useSearchEmailTemplate({
    filter: {
      offset,
      limit: pageSize,
      userId,
      type: templateType as EmailTemplateType,
    },
  });

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => (
        <InternalLink to={ADMIN_ROUTES.getEmailTemplate(userId, params.id as string)}>
          <Typography variant="body2" noWrap>
            {params.formattedValue}
          </Typography>
        </InternalLink>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.1,
    },
    {
      field: 'subject',
      headerName: 'Subject',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip tooltipContent={params.formattedValue} arrow placement="right">
          <Typography variant="body2" noWrap>
            {params.formattedValue}
          </Typography>
        </Tooltip>
      ),
      flex: 0.1,
    },
  ];

  return (
    <>
      <Stack direction="row" p={2} alignItems="end">
        <Typography variant="h6" id="tableTitle" component="div">
          {messages.admin.emailTemplates.gridTitle}
        </Typography>
        <Divider orientation="vertical" flexItem variant="middle" sx={{ mx: 2 }} />
        <Typography variant="h6">{user?.email}</Typography>
        <Divider orientation="vertical" flexItem variant="middle" sx={{ mx: 2 }} />
        <SelectField choices={EmailTemplateType} label="Type" onChange={setTemplateType} value={templateType} />
        <Divider orientation="vertical" flexItem variant="middle" sx={{ mx: 2 }} />
        <InternalLink to={ADMIN_ROUTES.getEmailTemplate(userId)}>
          <Tooltip tooltipContent="Add new template">
            <AddCircle />
          </Tooltip>
        </InternalLink>
      </Stack>
      <DataGridList
        columns={columns}
        items={emailTemplates}
        count={count}
        setOffset={setOffset}
        isLoading={isInitialLoading}
        pageSize={pageSize}
      />
    </>
  );
}
