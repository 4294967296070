import { BoxSkeleton, Stack } from '@common-components';
import { workspaceToolbarHeight } from 'themes';

export function WorkspaceHeaderSkeleton() {
  return (
    <Stack p={3} gap={3} bgcolor="common.white" height={workspaceToolbarHeight} direction="row">
      <BoxSkeleton isLoading sx={{ flex: 1, height: 1 }} index={0} />
      <BoxSkeleton isLoading sx={{ flex: 2, height: 1 }} index={1} />
    </Stack>
  );
}
