import { Badge, badgeClasses, Box, SvgIcon, Tab, TabProps, Tooltip } from '@common-components';
import { TabStyle } from './styles';

interface StyledTabProps<T> extends Omit<TabProps, 'icon'> {
  ariaLabelId: string;
  value: T;
  tooltip: string;
  Icon: typeof SvgIcon;
  badgeContent?: number;
}

function AccessibilityProps<T>(index: T, ariaLabelId: string) {
  return {
    id: `${ariaLabelId}-tab-${index}`,
    'aria-controls': `workspace-toolbox-tabpanel-${index}`,
  };
}

export default function NavigationTab<T>({
  value,
  tooltip,
  sx,
  ariaLabelId,
  Icon,
  badgeContent,
  ...props
}: StyledTabProps<T>) {
  return (
    <Box px={1.25}>
      <Badge
        badgeContent={badgeContent}
        color="warning"
        sx={{
          [`& .${badgeClasses.badge}`]: {
            top: 4,
            right: 4,
          },
        }}
      >
        <Tooltip tooltipContent={tooltip} placement="left">
          <Tab
            sx={TabStyle}
            icon={<Icon sx={{ fontSize: 18 }} />}
            value={value}
            {...AccessibilityProps(value, ariaLabelId)}
            {...props}
          />
        </Tooltip>
      </Badge>
    </Box>
  );
}
