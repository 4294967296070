import { ComponentType } from 'react';
import { SvgIcon } from '@common-components';
import {
  AddAlarm,
  AutoAwesomeOutlined,
  Bolt as BoltIcon,
  InfoOutlined as InfoOutlinedIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
  LayersOutlined as LayersOutlinedIcon,
  MailOutline as MailOutlineIcon,
  PeopleAltOutlined,
} from '@icons';
import { messages } from 'i18n';
import { TOOLBOX_TABS, ToolboxTabs } from 'broker/pages/SubmissionWorkspacePage/types';
import { ActivityLogBase } from './components/ActivityLog/ActivityLogBase';
import SubmissionMarketContacts from './components/Contacts';
import EmailTab from './components/Email';
import FilesTab from './components/Files';
import MarketInfoTab from './components/Market/Info';
import { SmartReportsToolbox } from './components/SmartReports';
import TasksTab from './components/Tasks';
import TowerTab from './components/Tower';

interface ToolboxTabsProps {
  ariaLabelId: string;
  value: ToolboxTabs;
  tooltip: string;
  icon: typeof SvgIcon;
  component: ComponentType<any>;
}

export const toolboxTabsConfig: Record<ToolboxTabs, ToolboxTabsProps> = {
  [TOOLBOX_TABS.TASKS]: {
    ariaLabelId: TOOLBOX_TABS.TASKS,
    value: TOOLBOX_TABS.TASKS,
    tooltip: messages.submissionWorkspace.toolbox.tabs.tasks,
    icon: AddAlarm,
    component: TasksTab,
  },
  [TOOLBOX_TABS.FILES]: {
    ariaLabelId: TOOLBOX_TABS.FILES,
    value: TOOLBOX_TABS.FILES,
    tooltip: messages.submissionWorkspace.toolbox.tabs.files,
    icon: InsertDriveFileOutlinedIcon,
    component: FilesTab,
  },
  [TOOLBOX_TABS.TOWER]: {
    ariaLabelId: TOOLBOX_TABS.TOWER,
    value: TOOLBOX_TABS.TOWER,
    tooltip: messages.submissionWorkspace.toolbox.tabs.tower,
    icon: LayersOutlinedIcon,
    component: TowerTab,
  },
  [TOOLBOX_TABS.EMAIL]: {
    ariaLabelId: TOOLBOX_TABS.EMAIL,
    value: TOOLBOX_TABS.EMAIL,
    tooltip: messages.submissionWorkspace.toolbox.tabs.emails,
    icon: MailOutlineIcon,
    component: EmailTab,
  },
  [TOOLBOX_TABS.ACTIVITY_LOG]: {
    ariaLabelId: TOOLBOX_TABS.ACTIVITY_LOG,
    value: TOOLBOX_TABS.ACTIVITY_LOG,
    tooltip: messages.submissionWorkspace.toolbox.tabs.marketActivityLog,
    icon: BoltIcon,
    component: ActivityLogBase,
  },
  [TOOLBOX_TABS.CONTACTS]: {
    ariaLabelId: TOOLBOX_TABS.CONTACTS,
    value: TOOLBOX_TABS.CONTACTS,
    tooltip: messages.submissionWorkspace.toolbox.tabs.contacts,
    icon: PeopleAltOutlined,
    component: SubmissionMarketContacts,
  },
  [TOOLBOX_TABS.MARKET_INFO]: {
    ariaLabelId: TOOLBOX_TABS.MARKET_INFO,
    value: TOOLBOX_TABS.MARKET_INFO,
    tooltip: messages.submissionWorkspace.toolbox.tabs.info,
    icon: InfoOutlinedIcon,
    component: MarketInfoTab,
  },
  [TOOLBOX_TABS.SMART_REPORTS]: {
    ariaLabelId: TOOLBOX_TABS.SMART_REPORTS,
    value: TOOLBOX_TABS.SMART_REPORTS,
    tooltip: messages.submissionWorkspace.toolbox.tabs.smartReports,
    icon: AutoAwesomeOutlined,
    component: SmartReportsToolbox,
  },
};
