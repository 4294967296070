import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom-latest';
import { Fade, Stack } from '@common-components';
import { useMutateEmailTemplate, useSearchEmailTemplate } from 'hooks';
import { dialogTransitionDurationValue } from 'themes';
import { EmailTemplate } from 'types';
import EmailSkeleton from 'broker/components/skeletons/EmailSkeleton';
import TemplateEditor from 'broker/components/TemplateEditor/TemplateEditor';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import TemplatesSidebar from './TemplatesSideBar';
import { leftBarWidth } from './utils';

export default function Templates() {
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate>();

  const { items: templates, isLoading: isTemplatesLoading } = useSearchEmailTemplate();
  const { updateEmailTemplate } = useMutateEmailTemplate();

  const navigate = useNavigate();

  const { templateId } = useParams<{ templateId: string }>();

  const onSaveTemplate = async (emailTemplate: EmailTemplate) => {
    await updateEmailTemplate.mutateAsync({ id: emailTemplate.id, data: emailTemplate });
  };

  const handleTemplateChange = (template: EmailTemplate) => {
    navigate(`../${template.id}`, { routeKey: RouteKey.TemplatesPage, state: undefined });
  };

  // Whenever the route changes, select the template that matches the route
  useEffect(() => {
    // Can't load anything until templates are loaded
    if (templates.length > 0) {
      // Get the template that matches the route's templateId param
      const matchingTemplate = templates.find((template) => template.id === templateId);

      if (matchingTemplate) {
        setSelectedTemplate(matchingTemplate);
      }
      // In case there is no matching template, navigate to the first template
      else {
        const defaultTemplate = templates[0].id;
        navigate(`../${defaultTemplate}`, { routeKey: RouteKey.TemplatesPage, state: undefined, replace: true });
      }
    }
    // Save re-renders caused by location and navigate being re-defined on each render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, templates]);

  return (
    <Fade in timeout={{ enter: dialogTransitionDurationValue, exit: dialogTransitionDurationValue }}>
      <Stack direction="row" alignItems="stretch" sx={{ height: '100%' }}>
        <TemplatesSidebar
          selectedTemplate={selectedTemplate}
          onChange={handleTemplateChange}
          templates={templates}
          isTemplatesLoading={isTemplatesLoading}
        />
        {selectedTemplate ? (
          <Stack
            sx={{
              p: 3,
              width: (theme) => `calc(100% - ${theme.spacing(leftBarWidth)})`,
              backgroundColor: 'grey.100',
            }}
          >
            <TemplateEditor selectedTemplate={selectedTemplate} onSaveTemplate={onSaveTemplate} />
          </Stack>
        ) : (
          <EmailSkeleton />
        )}
      </Stack>
    </Fade>
  );
}
