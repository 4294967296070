import { some } from 'lodash';
import { useMemo } from 'react';
import { Badge, badgeClasses, Box, ButtonBase, Divider, NewMenu, Stack, Tooltip, Typography } from '@common-components';
import { KeyboardArrowDown as KeyboardArrowDownIcon, PersonOutline as PersonIcon } from '@icons';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { iconFontSize } from 'themes';

import { User } from 'types';
import { uniteStyles } from 'utils';

interface TeammatesCcGroupContactsProps {
  teammates: User[];
  selectedTeammates: User[];
  toggleTeammate?: (value: User) => void;
  isMenu?: boolean;
  spacingBottom?: number;
  disabled?: boolean;
}

const MarketStyles = {
  border: 1,
  borderColor: 'divider',
  px: 1,
  py: '2px',
  borderRadius: '20px',
  maxWidth: 1,
  alignItems: 'center',
  backgroundColor: 'common.white',
  '&:hover': { backgroundColor: 'blueGrey.100' },
} as const;

export default function TeammatesCcGroupContacts({
  teammates,
  isMenu,
  selectedTeammates,
  toggleTeammate,
  spacingBottom = 1,
  disabled,
}: TeammatesCcGroupContactsProps) {
  const optionsMenu = useOptionsMenu();

  const contactsListString = useMemo(
    () => teammates.map((teammate) => `${teammate.firstName} ${teammate.lastName}`).join(', '),
    [teammates],
  );

  return (
    <Box>
      <ButtonBase
        disabled={disabled}
        aria-label="market menu"
        onClick={optionsMenu.openMenu}
        color="inherit"
        sx={{ p: 0, mb: spacingBottom, borderRadius: 4 }}
      >
        <Tooltip tooltipContent={isMenu ? '' : contactsListString} placement="right">
          <Stack
            direction="row"
            sx={uniteStyles(MarketStyles, {
              borderRadius: 4,
            })}
          >
            <PersonIcon sx={{ color: 'text.secondary', fontSize: iconFontSize }} />
            <Typography noWrap variant="caption" sx={{ ml: 0.5, color: 'text.secondary' }}>
              {messages.emailEditor.cc}
            </Typography>
            <Divider variant="fullWidth" sx={{ mx: 1, my: '-2px' }} flexItem orientation="vertical" />
            <Badge
              badgeContent={selectedTeammates.length}
              showZero
              sx={{
                [`& .${badgeClasses.badge}`]: {
                  position: 'relative',
                  transform: 'inherit',
                  backgroundColor: 'blueGrey.100',
                },
              }}
            />
            {toggleTeammate && <KeyboardArrowDownIcon sx={{ ml: '2px' }} />}
          </Stack>
        </Tooltip>
      </ButtonBase>
      {toggleTeammate && (
        <NewMenu
          optionsMenuState={optionsMenu}
          menuItems={[
            {
              key: 'market-menu-popover',
              selectionType: 'checkbox',
              items: teammates.map((teammate) => ({
                label: `${teammate.firstName} ${teammate.lastName}`,
                onClick: () => toggleTeammate(teammate),
                tooltipContent: teammate.email,
                tooltipPlacement: 'right',
                isSelected: some(selectedTeammates, { id: teammate.id }),
              })),
            },
          ]}
        />
      )}
    </Box>
  );
}
