import { BoxItem } from 'box-ui-elements/es';
import { partition } from 'lodash';
import { useMemo } from 'react';
import { BoxTemplateLabels, UserRole } from 'enums';
import { useCurrentUser } from 'hooks';
import { getFileMetadataField, getFileType, sortBySyncRequested } from 'utils';
import { ExtendedBoxItem, FilesExplorerProps } from 'broker/components/FilesExplorer/types';
import { getFileIsHidden, getFileWithSyncMetadata } from 'broker/components/FilesExplorer/utils';

interface FilterAndSortFilesProps
  extends Pick<
    FilesExplorerProps,
    'visibleFileIds' | 'preSelectedFileIds' | 'additionalFileMetadata' | 'highlightFileTypes' | 'hideFilesConfig'
  > {
  allItems: BoxItem[];
}

export function useFilterAndSortFiles({
  allItems,
  visibleFileIds,
  preSelectedFileIds,
  additionalFileMetadata,
  highlightFileTypes,
  hideFilesConfig,
}: FilterAndSortFilesProps) {
  const { me } = useCurrentUser();

  // filter files
  const filteredFiles = useMemo(() => {
    let filteredItems: ExtendedBoxItem[] = allItems
      // Filter out folders
      .filter((item) => item.type === 'file')
      // Filter out hidden files
      .filter((item) => !getFileIsHidden(item))
      .map((item) => getFileWithSyncMetadata(item, additionalFileMetadata));

    // Filter out files that are hidden based on the hideFilesConfig and like the comment above, we need to update to check values as objects
    if (hideFilesConfig) {
      Object.entries(hideFilesConfig).forEach(([key, values]) => {
        if (values.length > 0) {
          filteredItems = filteredItems.filter((item) => {
            const metadataValue = getFileMetadataField(item, key);

            // Always show files without a FileType / market label
            if (
              [BoxTemplateLabels.FileType, BoxTemplateLabels.MarketId].includes(key as BoxTemplateLabels) &&
              !metadataValue
            ) {
              return true;
            }
            return values.includes(metadataValue);
          });
        }
      });
    }

    // Filter by each metadata field
    if (visibleFileIds) {
      filteredItems = filteredItems.filter((item) => visibleFileIds.includes(item.id));
    }

    return filteredItems;
  }, [additionalFileMetadata, allItems, hideFilesConfig, visibleFileIds]);

  // sort files
  return useMemo(() => {
    let sortedItems: ExtendedBoxItem[] = [...filteredFiles];
    // Allow backoffice to see unsynced files first
    if (me?.role === UserRole.BackOffice) {
      sortedItems = sortedItems.sort((a) => sortBySyncRequested(a.syncMetadata));
    }

    if (highlightFileTypes) {
      sortedItems = sortedItems.sort(
        (a, b) =>
          Number(highlightFileTypes?.includes(getFileType(b)!)) - Number(highlightFileTypes?.includes(getFileType(a)!)),
      );
    }

    if (preSelectedFileIds) {
      const [preSelectedItems, rest] = partition(sortedItems, (item) => preSelectedFileIds.includes(item.id));
      sortedItems = [...preSelectedItems, ...rest];
    }

    return sortedItems;
  }, [filteredFiles, highlightFileTypes, me?.role, preSelectedFileIds]);
}
