import { get } from 'lodash';
import { FocusEvent, HTMLAttributes, memo, Ref, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  createFilterOptions,
  FormControl,
  FormFieldRequiredLabel,
  FormHelperText,
} from '@common-components';
import { messages } from 'i18n';
import { FormControlHelperTextMarginBottom, FormControlPaddingBottom } from 'themes';
import AutocompleteActionButton from 'components/Autocomplete/AutocompleteActionButton';
import DefaultOverlay from 'components/DefaultOverlay';
import FormFieldSuggestionOverlay from 'components/FormFieldSuggestionOverlay';
import InputLabel from 'components/hookFormComponents/InputLabel';
import {
  AutocompleteStyles,
  FormHelperTextStyles,
  GridAutoCompleteFormHelperTextStyles,
  GridAutocompleteStyles,
} from 'components/hookFormComponents/styles';
import { SuggestionProps } from 'components/hookFormComponents/types';
import FormRenderOption from './FormRenderOption';
import {
  AutocompleteOption,
  ElevatedPaperComponent,
  getOptionLabel,
  getOptionValue,
  gridRenderInput,
  renderInput,
} from './utils';

export interface FormAutocompleteProps<T extends AutocompleteOption> {
  name: string;
  label: string;
  id?: string;
  isLoading?: boolean;
  defaultValue?: T;
  placeholder?: string;
  withTooltip?: boolean;
  fullWidth?: boolean;
  validateOnChange?: boolean;
  disabled?: boolean;
  optional?: boolean;
  options: T[];
  filterOptions?: T[];
  getOptionDisabled?: (option: T) => boolean;
  groupBy?: (option: T) => string;
  callToActionCallback?: (newValue?: string) => void;
  callToActionText?: string;
  inputRef?: Ref<HTMLInputElement>;
  gridMode?: boolean;
  optionalPlaceholder?: string;
  autoSelect?: boolean;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  size?: 'small' | 'medium';
  onBlur?: (e: FocusEvent<HTMLDivElement>) => boolean | undefined;
  onInputChange?: (value: string) => void;
  noFilter?: boolean;
  helperText?: string;
  defaultValueOverlayText?: string;
  suggestion?: SuggestionProps;
  enhancedRequired?: boolean;
}

// Note that 'option' refers to any of the options provided in the 'options' prop, and 'value' refers to the currently chosen option
const isOptionEqualToValue = (option: AutocompleteOption, value: AutocompleteOption) => option.value === value.value;

function FormAutocomplete<T extends AutocompleteOption>({
  name,
  label = 'Select',
  id,
  isLoading,
  defaultValue,
  withTooltip,
  optionalPlaceholder = '',
  placeholder = messages.general.selectPlaceholder,
  fullWidth = true,
  optional = false,
  disabled = false,
  validateOnChange = false,
  options,
  getOptionDisabled,
  groupBy,
  callToActionCallback,
  callToActionText,
  inputRef,
  gridMode = false,
  autoSelect = true,
  tooltipPlacement = 'top',
  size = 'small',
  filterOptions,
  onBlur,
  onInputChange,
  noFilter = false,
  helperText,
  defaultValueOverlayText,
  suggestion,
  enhancedRequired,
  ...props
}: FormAutocompleteProps<T>) {
  const placeholderVal = optionalPlaceholder || placeholder;

  const labelId = `${name}-label`;
  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext();

  const errorMessage = get(errors, name)?.message;
  const formHelperText = errorMessage || helperText;

  const filterOptionsFunc = createFilterOptions<T>();

  const [inputValue, setInputValue] = useState('');
  const [ownSuggestion, setOwnSuggestion] = useState<SuggestionProps | undefined>(suggestion);
  const [ownDefaultOverlayText, setOwnDefaultOverlayText] = useState<string | undefined>(defaultValueOverlayText);

  useEffect(() => {
    setOwnSuggestion(suggestion);
  }, [suggestion]);

  const formattedSuggestion = ownSuggestion?.value;

  const renderOption = (htmlProps: HTMLAttributes<HTMLElement>, option: T) => {
    if (option.action) {
      const { onClick, onMouseOver, ...rest } = htmlProps;
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Box {...rest} style={{ width: '100%', padding: 0 }}>
          <AutocompleteActionButton
            onClick={() => {
              callToActionCallback?.(inputValue);
            }}
          >
            {option.label}
          </AutocompleteActionButton>
        </Box>
      );
    }
    return <FormRenderOption key={option.value} option={option} htmlProps={htmlProps} />;
  };

  return (
    <FormControl
      sx={{
        pb: gridMode ? 0 : FormControlPaddingBottom,
        mb: formHelperText ? FormControlHelperTextMarginBottom : 0,
      }}
      fullWidth={fullWidth}
    >
      {!gridMode && <InputLabel id={labelId} error={!!errorMessage} label={label} optional={optional} htmlFor={name} />}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange: fieldPropsOnChange, value, onBlur: fieldPropsOnBlur, ...fieldProps } }) => (
          <Box position="relative">
            <Autocomplete
              {...fieldProps}
              id={id}
              value={options.find((option) => getOptionValue(option) === value) || null}
              onChange={(_, option) => {
                // Only perform the change if the option is not a call-to-action
                if (!option?.action) {
                  fieldPropsOnChange(getOptionValue(option));
                  if (errorMessage || validateOnChange) {
                    trigger(name);
                  }
                }
              }}
              inputValue={inputValue}
              onInputChange={(_, newInputValue) => {
                // If a user types and presses tab/enter rather than selecting an option
                if (newInputValue === callToActionText) {
                  callToActionCallback?.(inputValue);
                  // If the user selects a creatable option the dropdown
                } else {
                  setInputValue(newInputValue);
                  if (onInputChange) {
                    onInputChange(newInputValue);
                  }
                }
              }}
              defaultValue={defaultValue}
              sx={gridMode ? GridAutocompleteStyles : AutocompleteStyles}
              blurOnSelect
              openOnFocus
              autoSelect={autoSelect}
              disabled={disabled}
              loading={isLoading}
              fullWidth={fullWidth}
              options={options}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={getOptionDisabled}
              isOptionEqualToValue={isOptionEqualToValue}
              groupBy={groupBy}
              filterOptions={(optionItems, params) => {
                if (noFilter) {
                  return optionItems;
                }
                const filtered = filterOptionsFunc(optionItems, params).filter(
                  (filteredOption) => !filterOptions?.some((option) => option?.value === filteredOption?.value),
                );

                if (callToActionCallback && callToActionText) {
                  filtered.push({
                    value: 'call-to-action',
                    label: callToActionText,
                    action: callToActionCallback,
                  } as T);
                }

                return filtered;
              }}
              renderInput={(params) =>
                gridMode
                  ? gridRenderInput({
                      params: { ...params, error: !!errorMessage, placeholder: placeholderVal },
                      withTooltip: withTooltip && params.id !== document?.activeElement?.id,
                      tooltipPlacement,
                      ref: inputRef,
                    })
                  : renderInput({
                      params: { ...params, error: !!errorMessage, placeholder: placeholderVal },
                      withTooltip: withTooltip && params.id !== document?.activeElement?.id,
                      tooltipPlacement,
                      ref: inputRef,
                    })
              }
              renderOption={renderOption}
              PaperComponent={ElevatedPaperComponent}
              classes={{ input: 'data-hj-allow' }}
              size={size}
              {...props}
              onBlur={(e) => {
                let allowPropagation: boolean = true;
                if (onBlur) {
                  const response = onBlur(e);
                  if (response !== undefined) {
                    allowPropagation = response;
                  }
                }
                if (allowPropagation) {
                  fieldPropsOnBlur();
                }
              }}
            />
            {/* don't render default overlay if we have a suggestion */}
            {!suggestion && ownDefaultOverlayText && (
              <DefaultOverlay
                text={ownDefaultOverlayText}
                onClick={() => {
                  setOwnDefaultOverlayText(undefined);
                }}
              />
            )}
            {ownSuggestion?.value && formattedSuggestion && (
              <FormFieldSuggestionOverlay
                text={formattedSuggestion}
                onClick={() => {
                  setOwnSuggestion(undefined);
                }}
              />
            )}
            {enhancedRequired && !optional && !formattedSuggestion && !value && (
              <FormFieldRequiredLabel shiftLeft={24} />
            )}
          </Box>
        )}
      />
      {formHelperText && (
        <FormHelperText
          error={!!errorMessage}
          sx={gridMode ? GridAutoCompleteFormHelperTextStyles : FormHelperTextStyles}
        >
          {formHelperText}
        </FormHelperText>
      )}
      {ownSuggestion?.reason && <FormHelperText sx={FormHelperTextStyles}>{ownSuggestion.reason} </FormHelperText>}
    </FormControl>
  );
}

// This type casting is required due to memo not being able to forward generics type parameters
export default memo(FormAutocomplete) as typeof FormAutocomplete;
