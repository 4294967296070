import { cloneDeep } from 'lodash';
import { useRef } from 'react';
import { Box, NewMenu } from '@common-components';
import { EditOutlined, LinkOff } from '@icons';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';

export default function PreviewActions() {
  const {
    activeDraft,
    draftTemplates: { templateInstances },
    draftPreview: { subject, body },
    recipients: { selectedRecipientGroupId },
    areEditButtonsVisible,
  } = useEmailEditorState();

  const {
    setDraftEditorMode,
    draftTemplates: { setRecipientGroupDetachedTemplateInstance },
    hidePreviewActions,
    showPreviewActions,
  } = useEmailEditorActions();

  useOptionsMenu();

  const anchorRef = useRef(null);

  const onEditForMarket = () => {
    const templateInstance = templateInstances[activeDraft!.chosenTemplateId];
    const newTemplateInstance = {
      ...cloneDeep(templateInstance),
      subject,
      body,
    };

    setRecipientGroupDetachedTemplateInstance(selectedRecipientGroupId!, newTemplateInstance);
    setDraftEditorMode(DraftEditorMode.EditForMarket);
    hidePreviewActions();
  };

  const onEditForAll = () => {
    setDraftEditorMode(DraftEditorMode.EditForAll);
    hidePreviewActions();
  };

  return (
    <Box ref={anchorRef}>
      <NewMenu
        optionsMenuState={{
          isMenuOpen: areEditButtonsVisible,
          openMenu: showPreviewActions,
          closeMenu: hidePreviewActions,
          anchorEl: anchorRef.current,
          setAnchorEl: () => {},
        }}
        placement="top-right"
        menuItems={[
          {
            key: 'email-preview-actions-menu',
            items: [
              {
                label: messages.emailEditor.editForAll,
                onClick: onEditForAll,
                endAdornment: EditOutlined,
                variant: 'body2Bold',
              },
              {
                hidden: !selectedRecipientGroupId,
                label: messages.emailEditor.detachForMarket,
                onClick: onEditForMarket,
                endAdornment: LinkOff,
                variant: 'body2Bold',
              },
            ],
          },
        ]}
      />
    </Box>
  );
}
