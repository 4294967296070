import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, ButtonBase, colors, Divider, Menu, MenuItem, Stack } from '@common-components';
import { UserRole } from 'enums';
import { useCurrentUser, useHotjar, useOptionsMenu } from 'hooks';
import { useSetEndUser } from 'hooks/api/user';
import { messages } from 'i18n';
import { clearLocalStorage, logger } from 'utils';

const { grey } = colors;

const userAvatarStyles = {
  width: 28,
  height: 28,
  typography: 'body2',
  color: 'text.primary',
  backgroundColor: grey[300],
} as const;

export default function LoginControls({ menuPosition = 'left' }: { menuPosition?: 'left' | 'top' }) {
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useOptionsMenu();

  const { isLoading, user, logout } = useAuth0();
  const hotjar = useHotjar();
  const { me } = useCurrentUser();
  const { refetch: signOutEndUser } = useSetEndUser();

  const handleLogout = async () => {
    closeMenu();

    if (me?.role === UserRole.BackOffice) {
      await signOutEndUser();
    } else {
      clearLocalStorage();
    }

    logout({ returnTo: window.location.origin });
    logger.removeCustomVariable('userEmail');
    hotjar.identify('');
  };

  if (isLoading) {
    return null;
  }

  if (user) {
    return (
      <>
        <Stack gap={1} direction="row" justifyContent="flex-start" alignItems="center">
          <ButtonBase sx={{ p: 1, borderRadius: '50%' }} onClick={openMenu} aria-label="settings">
            <Avatar sx={userAvatarStyles} aria-label="user">
              {user.email?.charAt(0).toUpperCase()}
            </Avatar>
          </ButtonBase>
        </Stack>
        <Menu
          id="user-menu-popover"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={closeMenu}
          anchorOrigin={{
            horizontal: menuPosition === 'top' ? 'left' : 'left',
            vertical: menuPosition === 'top' ? 'bottom' : 'top',
          }}
          transformOrigin={{
            horizontal: menuPosition === 'top' ? 'right' : 'right',
            vertical: menuPosition === 'top' ? 'top' : 'bottom',
          }}
          MenuListProps={{
            'aria-labelledby': 'user-menu',
            sx: { width: 160 },
          }}
          sx={{ ml: 8 }}
        >
          <MenuItem onClick={handleLogout} sx={{ typography: 'button', color: 'common.black' }}>
            {messages.buttons.logOut}
          </MenuItem>
          <Divider sx={{ my: 1 }} />
          <MenuItem
            component="a"
            href="https://www.flowspecialty.com/docs/terms-of-use"
            target="_blank"
            onClick={closeMenu}
            sx={{ typography: 'caption', color: 'common.black' }}
          >
            {messages.userMenu.termsOfService}
          </MenuItem>
          <MenuItem
            component="a"
            href="https://www.flowspecialty.com/docs/privacy-policy"
            target="_blank"
            onClick={closeMenu}
            sx={{ typography: 'caption', color: 'common.black' }}
          >
            {messages.userMenu.privacyPolicy}
          </MenuItem>
        </Menu>
      </>
    );
  }

  return null;
}
