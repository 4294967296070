import { Box, BoxProps, SxProps, Theme, Tooltip, Typography } from '@common-components';
import { truncateMultiLineText, uniteStyles } from 'utils';
import TextSkeleton from 'broker/components/skeletons/TextSkeleton';

interface TitleSubTitleProps extends BoxProps {
  title: string;
  subtitle: string;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
}

export default function TitleSubTitle({ title, subtitle, sx, isLoading = false, ...props }: TitleSubTitleProps) {
  return (
    <Tooltip tooltipContent={subtitle}>
      <Box sx={uniteStyles({ lineHeight: 1, width: 1 }, sx)} {...props}>
        <Typography variant="body2" noWrap>
          <TextSkeleton width={150} isLoading={isLoading}>
            {title}
          </TextSkeleton>
        </Typography>
        <Typography variant="caption" color="textSecondary" noWrap sx={truncateMultiLineText(1)}>
          <TextSkeleton width={100} isLoading={isLoading}>
            {subtitle}
          </TextSkeleton>
        </Typography>
      </Box>
    </Tooltip>
  );
}
