import { Organization } from 'types';
import RoutedDialog from 'broker/components/RoutedDialog';
import { RouteKey } from 'broker/enums';
import Content from './Content';

interface AgencyDialogProps {
  onAgencyCreated?: (organization: Organization) => void;
}

export default function AgencyDialog({ ...agencyDialogProps }: AgencyDialogProps) {
  return (
    <RoutedDialog ariaLabelId="agency-modal" routeKey={RouteKey.Agency} isLocationStateRequired={false}>
      {(props) => <Content {...props} {...agencyDialogProps} />}
    </RoutedDialog>
  );
}
