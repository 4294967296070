import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { LoaderOverlay, Stack, Typography } from '@common-components';
import { useMount } from 'hooks';
import { useMutateBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { PartialSubmission, Quote } from 'types';
import Banner, { BannerMode } from 'broker/components/Banner';
import ContentPreview from 'broker/components/box/ContentPreview';
import { InsightsData } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/form-methods/types';
import { PdfGenerationStatus } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/types';
import { useGenerateAndUploadComparisonPDF } from './pdf-creator/quote-comparison-pdf-creator-util';

interface UploadProps {
  pdfGenerationStatus: PdfGenerationStatus;
  setPdfGenerationStatus: (pdfGenerationStatus: PdfGenerationStatus) => void;
  setIsDirty: (isDirty: boolean) => void;
  quotes: Quote[];
  submission: PartialSubmission;
  quoteComparisonFileId: string;
  setQuoteComparisonFileId: (fileId: string) => void;
}

export default function Upload({
  setPdfGenerationStatus,
  pdfGenerationStatus,
  setIsDirty,
  quotes,
  submission,
  quoteComparisonFileId,
  setQuoteComparisonFileId,
}: UploadProps) {
  const quoteComparisonMethods = useFormContext();
  const {
    quoteComparisonPdfTitle,
    quoteComparisonError: { title, defaultDescription },
  } = messages.quoteComparisonPage.upload;

  const { deleteFile } = useMutateBoxItems();
  // undefined means error
  const values = quoteComparisonMethods.getValues() as InsightsData;

  const generateAndUploadComparisonPDF = useGenerateAndUploadComparisonPDF();
  const hasError = pdfGenerationStatus === PdfGenerationStatus.Error;

  const createQuoteComparisonPDF = async () => {
    setPdfGenerationStatus(PdfGenerationStatus.Loading);
    if (!isEmpty(quoteComparisonFileId)) {
      // Delete the quote comparison file if it exists
      await deleteFile.mutateAsync({ fileId: quoteComparisonFileId });
      setQuoteComparisonFileId('');
    }
    generateAndUploadComparisonPDF(submission, quotes, values)
      .then((comparisonBoxItem) => {
        if (comparisonBoxItem) {
          setQuoteComparisonFileId(comparisonBoxItem.id);
          setPdfGenerationStatus(PdfGenerationStatus.Idle);
        } else {
          setPdfGenerationStatus(PdfGenerationStatus.Error);
        }
        setIsDirty(false);
      })
      .catch((error) => {
        setPdfGenerationStatus(PdfGenerationStatus.Error);
        throw error;
      });
  };

  // Execute the PDF download on component mount
  useMount(() => {
    createQuoteComparisonPDF();
  });

  const getContent = () => {
    if (hasError) {
      return (
        <Stack mt={2}>
          <Banner title={title} subtitle={defaultDescription} mode={BannerMode.Error} />
        </Stack>
      );
    }
    if (pdfGenerationStatus === PdfGenerationStatus.Loading) {
      return <LoaderOverlay hideOverlay />;
    }
    return quoteComparisonFileId ? (
      <ContentPreview fileId={quoteComparisonFileId} hasHeader={false} sx={{ height: 1 }} />
    ) : null;
  };

  return (
    <Stack height={1} py={4} px={12}>
      <Stack ml={hasError ? 0 : 2} mb={hasError ? 1 : 3}>
        <Typography variant="body1Bold">{quoteComparisonPdfTitle}</Typography>
      </Stack>
      {getContent()}
    </Stack>
  );
}
