import { MENTION_PREFIX } from '@common/email-variables';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Editor, RawEditorOptions } from 'tinymce';
import { useRetrieveTinyJwt } from 'hooks';
import { messages } from 'i18n';
import { defaultEditorFontSizeStringPx } from './consts';
import { addMentionsAutocomplete, addMentionToolbarButton, MentionProps } from './editor-functionality-tools';
import { Attach } from './EditorIcons';
import { variableChipStyle } from './styling';
import { mentionChipClassName } from './utils/mentions';

export interface EditorStyleOptions {
  isDisabled?: boolean;
}

const getEditorStyle = ({ isDisabled }: EditorStyleOptions = {}) => `
  body { 
    font-family:'Inter', sans-serif; 
    letter-spacing: 0.5px; 
    line-height: 1.5;
    -webkit-font-smoothing: antialiased; 
    color: #434e4b; 
    font-size: ${defaultEditorFontSizeStringPx};
    cursor: ${isDisabled ? 'default' : 'text'};
    caret-color: #1A6AF4;
   }
   
   .mce-content-body.mce-content-readonly {
     cursor: default;
   }
   
   ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  .${mentionChipClassName} { ${variableChipStyle} }`;

export type CustomButtonProps = {
  name: string;
  enabled?: boolean;
  tooltip?: string;
  icon?: string;
  text?: string;
  onAction: () => void;
};

declare type ToolbarMode = 'floating' | 'sliding' | 'scrolling' | 'wrap';

export const useTinyEditorProps = (
  mentionKeys: string[] | undefined,
  customButtons?: CustomButtonProps[],
  placeholder?: string,
  resolveVariable?: (variable: string) => string,
  onDirty?: () => void,
  clickHandler?: (event: any) => void,
  onPastePreprocess?: (editor: any, args: any) => void,
  editorStyleProps?: EditorStyleOptions,
  excludeToolbarProps?: string[],
): Omit<RawEditorOptions, 'selector'> => {
  const mentions = useMemo<MentionProps[]>(
    () => mentionKeys?.map((key) => ({ text: key, value: `${MENTION_PREFIX}${key}` })) ?? [],
    [mentionKeys],
  );
  const { retrieveTinyJwt } = useRetrieveTinyJwt();

  const getToolbarOptions = () => {
    const defaultOptions = `fontfamily fontsize undo redo | bold italic underline strikethrough | forecolor backcolor alignment lineheight | bullist numlist outdent indent | table link ${customButtons?.map(
      (button) => button.name,
    )} spellcheckdialog`;
    if (excludeToolbarProps) {
      return defaultOptions
        .split(' ')
        .filter((item) => !excludeToolbarProps.includes(item))
        .join(' ');
    }
    return defaultOptions;
  };

  return {
    placeholder,
    height: '100%',
    menubar: false,
    resize: false,
    contextmenu: 'table',
    statusbar: false,
    elementpath: false,
    forced_root_block: 'div',
    icons: 'small',
    convert_urls: false,
    extended_valid_elements: 'span[*],path[*],svg[*]',
    plugins: [
      'advlist',
      'autolink',
      'lists',
      'link',
      'charmap',
      'preview',
      'anchor',
      'searchreplace',
      'visualblocks',
      'fullscreen',
      'media',
      'table',
      'advtable',
      'codesample',
      'tinydrive',
      'image',
      'powerpaste',
    ],
    browser_spellcheck: true,
    powerpaste_allow_local_images: true,
    powerpaste_word_import: 'merge',
    powerpaste_googledocs_import: 'merge',
    powerpaste_html_import: 'merge',
    tinydrive_token_provider: async (success: any, failure: any) => {
      const jwt = await retrieveTinyJwt();
      if (jwt) {
        success({ token: jwt.value });
      } else {
        failure(messages.emailEditor.tinyTokenCreateFail);
      }
    },
    image_file_types: 'jpg,svg,webp,png,jpeg,gif',
    font_size_formats: '8px 10px 12px 14px 18px 24px 36px',
    font_family_formats:
      'Inter=Inter,sans-serif;Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;',
    line_height_formats: '1 1.15 1.5 2',
    // remove toolbar items with one that appear in the excludeToolbarProps
    toolbar: getToolbarOptions(),
    indentation: '20pt',
    toolbar_groups: {
      alignment: { icon: 'align-left', items: 'alignleft aligncenter alignright alignjustify' },
    },
    toolbar_mode: 'floating' as ToolbarMode,
    table_default_attributes: {
      border: '1',
      cellpadding: '8px',
      cellspacing: '8px',
    },
    paste_preprocess: (editor: any, args: any) => {
      if (typeof onPastePreprocess === 'function') {
        onPastePreprocess(editor, args);
      }

      if (excludeToolbarProps?.includes('fontfamily')) {
        const div = document.createElement('div');
        div.innerHTML = args.content;
        const elements = div.querySelectorAll('*');

        elements.forEach((element) => {
          const htmlElement = element as HTMLElement;
          htmlElement.style.fontFamily = '';
          // When processing pasted content from sources like Microsoft Word, you might encounter the face attribute, especially if the source content is formatted using older HTML standards
          htmlElement.removeAttribute('face');
        });

        args.content = div.innerHTML;
      }
    },
    content_style: getEditorStyle(editorStyleProps),
    setup: (editor: Editor) => {
      // Adding attach icon as it's missing in tiny
      editor.ui.registry.addIcon('attach', ReactDOMServer.renderToString(Attach()));

      editor.on('init', () => {
        const iframe = document.querySelector('iframe[id*="_ifr"]');
        if (iframe) {
          iframe.setAttribute('data-hj-allow-iframe', '');
        }
      });

      customButtons?.forEach(({ name, ...buttonProps }: CustomButtonProps) => {
        editor.ui.registry.addButton(name, <any>buttonProps);
      });

      // This mentions toolbar button is hidden for now. To show it - add 'mentions' to the toolbar
      addMentionToolbarButton(editor, mentionKeys ?? []);

      if (!isEmpty(mentions)) {
        addMentionsAutocomplete(editor, mentions, resolveVariable);
      }

      if (onDirty) {
        // This event triggers the first time the editor content becomes dirty
        editor.on('dirty', onDirty);
      }

      if (clickHandler) {
        editor.on('click', (e) => {
          // We need this queueMicrotask because if the handler is a navigation that causes Tiny event dispatching to crash because the dispatching is running in a loop
          queueMicrotask(() => clickHandler(e));
        });
      }

      editor.on('keydown', (event) => {
        if (event.code === 'Tab') {
          editor.dom.events.cancel(event);
          if (editor.selection.getSel()?.anchorOffset === 0) {
            editor.execCommand(event.shiftKey ? 'Outdent' : 'Indent');
          } else if (!event.shiftKey) {
            editor.execCommand('mceInsertContent', false, '\t');
          }
          event.preventDefault();
        }
      });
    },
  };
};
