import { Route, Routes } from 'react-router-dom-latest';
import { PartialSubmission } from 'types';
import { MarketingUpdateRoutes } from 'broker/broker-routes';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { PdfGenerationStatus } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/types';
import Compose from './steps/Compose';
import Upload from './steps/Upload';

interface StepsProps extends InnerPageProps {
  editorContent: string | undefined;
  setEditorContent: (value: string) => void;
  pdfGenerationStatus: PdfGenerationStatus;
  setPdfGenerationStatus: (value: PdfGenerationStatus) => void;
  submission: PartialSubmission;
  pdfFileId: string | undefined;
  setPdfFileId: (value: string) => void;
  initialPdfFileId?: string;
}

export default function Steps({
  editorContent,
  setEditorContent,
  pdfGenerationStatus,
  setPdfGenerationStatus,
  submission,
  pdfFileId,
  setPdfFileId,
  isDirty,
  setIsDirty,
  initialPdfFileId,
}: StepsProps) {
  return (
    <Routes>
      <Route
        path={`${MarketingUpdateRoutes.Compose}/*`}
        element={<Compose editorContent={editorContent} setEditorContent={setEditorContent} setIsDirty={setIsDirty} />}
      />
      <Route
        path={`${MarketingUpdateRoutes.Upload}/*`}
        element={
          <Upload
            pdfGenerationStatus={pdfGenerationStatus}
            setPdfGenerationStatus={setPdfGenerationStatus}
            submission={submission}
            editorContent={editorContent}
            pdfFileId={pdfFileId}
            setPdfFileId={setPdfFileId}
            isDirty={isDirty}
            initialPdfFileId={initialPdfFileId}
          />
        }
      />
    </Routes>
  );
}
