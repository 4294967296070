import { IconButton } from '@common-components';
import { Share } from '@icons';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import NewMenu, { MenuItemProps } from 'components/menu';
import ToolboxItemHeader from 'broker/components/ToolboxItemHeader';

interface HeaderProps {
  title: string;
  shareOptions?: MenuItemProps[];
}

export const Header = ({ title, shareOptions }: HeaderProps) => {
  const optionsMenuState = useOptionsMenu();

  return (
    <>
      <ToolboxItemHeader
        title={title}
        mainActionArea={
          shareOptions && (
            <IconButton
              disabledTooltipContent={messages.marketingReportPage.editMode.disabled.description}
              edge="end"
              color="secondary"
              variant="text"
              icon={Share}
              onClick={optionsMenuState.openMenu}
            />
          )
        }
      />
      <NewMenu
        placement="bottom-center"
        optionsMenuState={optionsMenuState}
        menuItems={[
          {
            key: 'share-activity-log',
            items: shareOptions!,
          },
        ]}
      />
    </>
  );
};
