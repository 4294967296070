import { Box } from '@common-components';
import { InformationTooltipType } from 'contexts/information-tooltip/types';
import { QuoteFileIdType } from 'enums';
import { useInformationTooltip } from 'hooks';
import InformationTooltip from 'broker/components/InformationTooltip/InformationTooltip';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { quoteFilesTooltipConfig } from './quote-files-config';

interface QuoteFilesInfoTooltipProps {
  marketName?: string;
  fileType: QuoteFileIdType;
}

export const QuoteFilesInfoTooltip = ({ marketName, fileType }: QuoteFilesInfoTooltipProps) => {
  const { shouldShow, hideInformationTooltip } = useInformationTooltip(InformationTooltipType.QuoteFiles);
  const { submission } = useSubmissionsWorkspace();

  if (!shouldShow) {
    return null;
  }

  return (
    <Box sx={{ position: 'absolute', right: '10px', top: '10px', backgroundColor: 'primary.main' }}>
      <InformationTooltip
        title={quoteFilesTooltipConfig[fileType].title}
        message={quoteFilesTooltipConfig[fileType].message(marketName)}
        isOpen={!submission?.isCopilotEnabled}
        height={125}
        width={267}
        placement="left"
        onClose={hideInformationTooltip}
      />
    </Box>
  );
};
