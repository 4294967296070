import { useState } from 'react';
import { flushSync } from 'react-dom';
import { Button, Stack } from '@common-components';
import APP_ROUTES from 'app-routes';
import { PromptResultFeature } from 'enums';
import { useMutatePromptResult } from 'hooks';
import { messages } from 'i18n';
import { AnyObject, PartialSubmission } from 'types';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { SetNextStepsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/useSetNextStep';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

interface FooterProps extends InnerPageProps {
  submission: PartialSubmission;
  setNextStep: (props?: SetNextStepsProps) => void;
  isAcknowledgmentFlow: boolean;
  isLoading: boolean;
  setIsDirty: (isDirty: boolean) => void;
  aiGeneratedRiskAssessment?: string;
  aiInputData: AnyObject;
  riskAssessment: string;
}

export function RiskAssessmentFooter({
  submission,
  setNextStep,
  isAcknowledgmentFlow,
  isLoading,
  setIsDirty,
  aiGeneratedRiskAssessment,
  aiInputData,
  onClose,
  riskAssessment,
}: FooterProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { selectProductsUrl } = useWorkspaceUrls();

  const { updateSubmission } = useSubmissionsWorkspace();
  const { createPromptResult } = useMutatePromptResult();

  const onUpdateRiskAssessment = async () => {
    setIsSubmitting(true);

    try {
      await updateSubmission(
        submission.id,
        {
          riskAssessment,
        },
        true,
      );

      // Force React to flush the dirty flag update before we navigate away
      flushSync(() => {
        setIsDirty(false);
      });

      if (aiGeneratedRiskAssessment !== undefined) {
        // run api in the background to create a prompt result (no need to wait for it)
        createPromptResult.mutateAsync({
          data: {
            result: aiGeneratedRiskAssessment,
            input: aiInputData,
            modifiedResult: riskAssessment,
            feature: PromptResultFeature.RiskAssessment,
            submissionId: submission!.id,
          },
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const loading = isSubmitting || isLoading;

  const getAckFlowButtons = () => (
    <Stack direction="row" gap={1}>
      <Button
        variant="outlined"
        onClick={async () => {
          await onUpdateRiskAssessment();
          navigate(APP_ROUTES.GENERAL.HOME, { routeKey: RouteKey.Home, state: undefined });
        }}
        loading={loading}
      >
        {messages.buttons.saveForLater}
      </Button>
      <Button
        variant="contained"
        onClick={async () => {
          await onUpdateRiskAssessment();
          navigate(selectProductsUrl, {
            routeKey: RouteKey.SelectProducts,
            state: undefined,
          });
        }}
        loading={loading}
      >
        {messages.general.nextAddProducts}
      </Button>
    </Stack>
  );

  const getEditSubmissionButtons = () => (
    <Button
      variant="outlined"
      onClick={async () => {
        await onUpdateRiskAssessment();
        onClose();
      }}
      loading={loading}
    >
      {messages.buttons.save}
    </Button>
  );

  return (
    <Stack direction="row" gap={1} justifyContent="space-between">
      <Button variant="outlined" onClick={() => setNextStep({ navigateBack: true })}>
        {messages.buttons.back}
      </Button>
      {isAcknowledgmentFlow ? getAckFlowButtons() : getEditSubmissionButtons()}
    </Stack>
  );
}
