import { GridSelectionModel } from '@common-components';
import { RouteKey } from 'broker/enums';
import { useNavigateToOverlay } from 'broker/hooks';
import { DeclinationLocationState } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Declination/types';
import { InformationRequestedLocationState } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/InformationRequested/types';
import { MarketingReportMarketRequest } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingReport/utils';
import getColumns from './columnsConfig';
import StyledDataGrid from './StyledDataGrid';

export interface MarketingReportContentProps {
  marketRequests: MarketingReportMarketRequest[];
  setSelectionModel: (newSelection: GridSelectionModel) => void;
  selectionModel: GridSelectionModel;
  showLoader: () => void;
  hideLoader: () => void;
  setMarketIdInEditMode: (id: string) => void;
  marketIdInEditMode: string;
  isInformationRequestsReport: boolean;
}

function MarketingDataGrid({
  marketRequests,
  selectionModel,
  setSelectionModel,
  showLoader,
  hideLoader,
  setMarketIdInEditMode,
  marketIdInEditMode,
  isInformationRequestsReport,
}: MarketingReportContentProps) {
  const navigateToOverlay = useNavigateToOverlay();

  const openQuoteInformationRequestedDialog = (
    informationRequestedLocationState: InformationRequestedLocationState,
  ) => {
    navigateToOverlay({
      routeKey: RouteKey.InformationRequest,
      state: { ...informationRequestedLocationState },
    });
  };

  const openDeclinationDialog = (declinationLocationState: DeclinationLocationState) => {
    navigateToOverlay({
      routeKey: RouteKey.Declination,
      state: { ...declinationLocationState },
    });
  };

  return (
    <StyledDataGrid
      disableVirtualization
      hideFooter
      rows={marketRequests}
      columns={getColumns(
        showLoader,
        hideLoader,
        setMarketIdInEditMode,
        marketIdInEditMode,
        openQuoteInformationRequestedDialog,
        openDeclinationDialog,
        isInformationRequestsReport,
      )}
      hideFooterPagination
      onSelectionModelChange={setSelectionModel}
      selectionModel={selectionModel}
      disableSelectionOnClick
      checkboxSelection
      disableColumnMenu
    />
  );
}

export default MarketingDataGrid;
