import { useMemo } from 'react';
import { FormMode } from 'enums';
import { ExtractedDataFields, useFormProvider } from 'hooks';
import { InsuranceProduct, Quote } from 'types';
import { quoteSchema } from './config/quotesFieldsConfig';
import { DetailData, InsightsData } from './types';

export const extractQuoteDetailsDefaultValues = (quote: Quote, selectedProductId?: string): DetailData => {
  const {
    premium,
    agentCommission,
    comments,
    triaPremium,
    carrierFee,
    flowBrokerFee,
    surplusLineTax,
    surplusStampingFee,
    layerId: attachmentPoint,
  } = quote;
  return {
    insuranceProductId: selectedProductId,
    attachmentPoint,
    limit: quote.limit?.toString() || '',
    premium: premium.toString(),
    agentCommission: agentCommission?.toString(),
    comments: comments?.toString() || '',
    expiresAt: quote.expiresAt,
    date: quote.date,
    subLimits: quote.subLimits,
    triaPremium: triaPremium?.toString(),
    carrierFee: carrierFee?.toString(),
    flowBrokerFee: flowBrokerFee?.toString(),
    surplusLineTax: surplusLineTax?.toString(),
    surplusStampingFee: surplusStampingFee?.toString(),
    // map subjectivities to array of objects with subjectivity key (react-hook-form can't handle array of strings)
    subjectivities: quote.subjectivities?.map((subjectivity) => ({ value: subjectivity })) || [],
  };
};

export const extractQuoteInsightsDefaultValues = (quote?: Quote): InsightsData => ({
  insights: quote?.insights || '',
});

export function useQuoteFormMethods(
  selectedQuote: Quote | undefined,
  setIsDirty: (state: boolean) => void,
  selectedProduct: InsuranceProduct | undefined,
  formMode: FormMode,
  suggestedValueProps?: ExtractedDataFields,
) {
  const detailsDefaultValues = useMemo(
    () => (selectedQuote ? extractQuoteDetailsDefaultValues(selectedQuote, selectedProduct?.id) : undefined),
    [selectedQuote, selectedProduct],
  );

  const policyDetailsDefaultValues = useMemo(
    () => ({
      policyNumber: selectedQuote?.policyDetails?.policyNumber || '',
      policyEffectiveDate: selectedQuote?.policyDetails?.effectiveDate || '',
      policyExpirationDate: selectedQuote?.policyDetails?.expirationDate || '',
      flowCommission: selectedQuote?.flowCommission || '',
    }),
    [selectedQuote],
  );

  const insightsDefaultValues = useMemo(() => extractQuoteInsightsDefaultValues(selectedQuote), [selectedQuote]);

  return useFormProvider({
    defaultValues: {
      date: new Date(), // If no value for date, today will be set by default
      ...detailsDefaultValues,
      ...insightsDefaultValues,
      ...policyDetailsDefaultValues,
    },
    schema: quoteSchema,
    setIsDirty,
    mode: 'onChange',
    suggestedValues: suggestedValueProps,
    applySuggestionsOnEmptyFields: formMode === FormMode.create,
  });
}
