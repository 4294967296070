import { InputAdornment, TextField } from '@common-components';
import { messages } from 'i18n';
import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';
import { FieldLabel } from './FieldLabel';

export const SubjectField = () => {
  const {
    draftEditorMode,
    draftPreview: { subject: previewSubject },
    draftEditing: { subject: editingSubject },
  } = useEmailEditorState();

  const {
    draftPreview: { setSubject: setPreviewSubject },
    draftEditing: { setSubject: setEditingSubject },
    showPreviewActions,
  } = useEmailEditorActions();

  const readOnly = draftEditorMode === DraftEditorMode.Preview;
  const activeSubject = readOnly ? previewSubject : editingSubject;
  const activeSetSubject = readOnly ? setPreviewSubject : setEditingSubject;

  return (
    <TextField
      multiline
      maxRows={3}
      id="subject"
      value={activeSubject}
      onChange={(event) => activeSetSubject(event.target.value)}
      onClick={readOnly ? showPreviewActions : undefined}
      sx={{ flex: 1 }}
      InputProps={{
        sx: {
          typography: 'body2Bold',
          color: 'text.primary',
          flex: 1,
          input: {
            cursor: readOnly ? 'default' : 'text',
          },
        },
        readOnly,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start" sx={{ mr: 0 }}>
            <FieldLabel text={messages.emailEditor.subject} />
          </InputAdornment>
        ),
      }}
      variant="standard"
    />
  );
};
