import { Transition } from 'history';
import { useState } from 'react';
import { QuoteFileIdType } from 'enums';
import { BROKER_NESTED_ROUTES, QuoteFormRoutes } from 'broker/broker-routes';
import { DirtyRouteGuard } from 'broker/components/DirtyContentGuard';
import { useRouteLocationState } from 'broker/components/useRouteLocationState';
import { RouteKey } from 'broker/enums';
import { useNavigateBack } from 'broker/hooks';
import QuoteContent from './QuoteContent';

interface QuoteProps {
  fileType: QuoteFileIdType;
  resetFilesExplorerSelectionOnUnMount?: boolean;
}

export default function Quote({ fileType, resetFilesExplorerSelectionOnUnMount = true }: QuoteProps) {
  const [isDirty, setIsDirty] = useState(false);

  const navigateBack = useNavigateBack();

  // This onClose applies only to the MarketQuote file types
  // Binder and Policy flows transition to compare view instead of calling this function
  const onClose = () => {
    navigateBack();
  };

  const locationState = useRouteLocationState(RouteKey.Quote, true);
  if (!locationState) {
    return null;
  }

  const beforeNavigation = async (transition: Transition) =>
    [...Object.values(QuoteFormRoutes), BROKER_NESTED_ROUTES.WORKSPACE.QUOTE_STATUS].some((substring) =>
      transition.location.pathname.includes(substring),
    );

  return (
    <DirtyRouteGuard isDirty={isDirty} beforeNavigation={beforeNavigation}>
      <QuoteContent
        locationState={locationState}
        setIsDirty={setIsDirty}
        isDirty={isDirty}
        onClose={onClose}
        fileType={fileType}
        resetFilesExplorerSelectionOnUnMount={resetFilesExplorerSelectionOnUnMount}
      />
    </DirtyRouteGuard>
  );
}
