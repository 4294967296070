import { emailLabelConfig } from '@common/config';
import { compact, isEmpty, sortBy, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { messages } from 'i18n';
import { FilterElementProps, getDisplayDate } from 'utils';
import { isOutboundType } from 'broker/components/Emails/types';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { EmailListItemAttributes } from './utils';

interface EmailsFilterProps {
  emails: EmailListItemAttributes[];
  paginationReset: () => void;
  isLoading: boolean;
  marketOrganizationId?: string;
}

export default function useEmailsFilter({
  emails,
  paginationReset,
  isLoading,
  marketOrganizationId,
}: EmailsFilterProps) {
  const [searchInput, setSearchInput] = useState('');
  const [isAllMarketsFilters, setIsAllMarketsFilters] = useState(true);
  const [emailsFilters, setEmailsFilters] = useState<FilterElementProps[]>([]);
  const { markets: submissionMarkets, partialSubmission } = useSubmissionsWorkspace();

  const setSearch = (value: string) => {
    paginationReset();
    setSearchInput(value);
  };

  const { organizationId: agentOrganizationId, organizationName: agentOrganizationName } = partialSubmission ?? {};

  const agentEmails = useMemo(
    () =>
      emails.filter((email) =>
        isOutboundType(email.type)
          ? email.recipientOrganization?.id === agentOrganizationId
          : email.senderOrganization?.id === agentOrganizationId,
      ),
    [emails, agentOrganizationId],
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const marketIdsList = compact(uniq(emails.flatMap((email) => email.labels?.organizationIds)));

    const marketsList = submissionMarkets
      .filter((market) => marketIdsList.includes(market.marketOrganizationId))
      .map((market) => ({
        id: market.marketOrganizationId,
        name: market.marketName,
        type: market.marketOrganizationType,
      }));

    const labelsList = compact(uniq(emails.map((email) => email.label)));

    const agentFilter =
      !isEmpty(agentEmails) && agentOrganizationId
        ? {
            key: agentOrganizationId,
            checked: false,
            label: agentOrganizationName ?? messages.emailMenus.agent,
            group: 'Agent',
          }
        : undefined;

    const baseMarketsFilters = marketsList.map((market) => ({
      key: market?.id,
      checked: market?.id === marketOrganizationId,
      label: market?.name,
      group: 'Market',
    }));

    const baseLabelsFilters = labelsList.map((label) => ({
      key: label,
      checked: false,
      label: emailLabelConfig[label].text,
      group: 'Label',
    }));

    // Sort by market name
    const sortedMarketFilters = [...sortBy(baseMarketsFilters, 'label')].filter((filter) => !!filter.key);
    const filters = agentFilter
      ? [agentFilter, ...sortedMarketFilters, ...baseLabelsFilters]
      : [...sortedMarketFilters, ...baseLabelsFilters];
    setEmailsFilters(filters);

    // Ensure filters are correctly set when marketOrganizationId is provided
    if (marketOrganizationId) {
      setIsAllMarketsFilters(false);
    }
  }, [
    isLoading,
    emails,
    marketOrganizationId,
    submissionMarkets,
    agentEmails,
    agentOrganizationId,
    agentOrganizationName,
  ]);

  // reset filters when returning from single market view to all markets view
  useEffect(() => {
    if (!marketOrganizationId) {
      setIsAllMarketsFilters(true);
    }
  }, [marketOrganizationId]);

  const filteredEmails = useMemo(() => {
    const shouldApplyEmail = (email: EmailListItemAttributes) =>
      email.subject.toLowerCase().includes(searchInput.toLowerCase()) ||
      email.senderEmail?.toLowerCase().includes(searchInput.toLowerCase()) ||
      email.recipientName?.toLowerCase().includes(searchInput.toLowerCase()) ||
      getDisplayDate(email.date).toLowerCase().includes(searchInput.toLowerCase());

    const shouldFilter = (input: string) => input.length > 1;
    const matchingEmails: EmailListItemAttributes[] = [];
    emails
      .filter((email) => {
        if (isAllMarketsFilters) {
          return true;
        }
        return emailsFilters.some(
          (filter) =>
            // agent filter
            ((agentOrganizationId === filter.key &&
              ((isOutboundType(email.type) && email.recipientOrganization?.id === filter.key) ||
                email.senderOrganization?.id === filter.key)) ||
              // market filter
              email.labels?.organizationIds?.includes(filter.key) ||
              // label filter
              filter.key === email.label) &&
            filter.checked,
        );
      })
      .forEach((email) => {
        const isMatch = shouldFilter(searchInput) ? shouldApplyEmail(email) : true;
        if (isMatch) {
          matchingEmails.push(email);
        }
      });
    return matchingEmails;
  }, [emails, searchInput, isAllMarketsFilters, emailsFilters, agentOrganizationId]);

  function updateCheckedFilter(existingFilters: FilterElementProps[], key: string, checked: boolean) {
    const changedFilter = existingFilters.find((filter) => filter.key === key);
    if (changedFilter) {
      changedFilter.checked = checked;
    }
    return existingFilters;
  }

  const handleAllFiltersSelected = () => {
    setEmailsFilters((prev) => prev.map((filter) => ({ ...filter, checked: false })));
    setIsAllMarketsFilters(true);
  };

  const handleFilterChange = (checked: boolean, key: string) => {
    setEmailsFilters((prev) => {
      const updatedFilters = updateCheckedFilter([...prev], key, checked);
      setIsAllMarketsFilters(updatedFilters.every((filter) => !filter.checked));
      return updatedFilters;
    });

    paginationReset();
  };

  const handleUncheckedFilter = (key: string) => {
    setEmailsFilters((prev) => updateCheckedFilter([...prev], key, false));
    setIsAllMarketsFilters(emailsFilters.every((filter) => !filter.checked || filter.key === key));
    paginationReset();
  };

  return {
    filteredEmails,
    search: searchInput,
    setSearch,
    isAllMarketsFilters,
    handleAllFiltersSelected,
    handleFilterChange,
    handleUncheckedFilter,
    emailsFilters,
  };
}
