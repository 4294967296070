import { Quote } from 'types';
import { QuoteComparisonInsightsRoutes } from 'broker/broker-routes';
import { PdfGenerationStatus } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/types';

export interface InsightPopulatedByAI {
  result: string;
  input: { quoteIds: string[] };
}

export interface QuoteComparisonProps {
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  currentStep: QuoteComparisonInsightsRoutes;
  pdfGenerationStatus: PdfGenerationStatus;
  setPdfGenerationStatus: (pdfGenerationStatus: PdfGenerationStatus) => void;
  insightPopulatedByAI?: InsightPopulatedByAI;
  setInsightPopulatedByAI: (insight: InsightPopulatedByAI) => void;
  quotes: Quote[];
  expiringPolicyFileId?: string;
}

export const quoteComparisonSteps = [QuoteComparisonInsightsRoutes.Compose, QuoteComparisonInsightsRoutes.Upload];
