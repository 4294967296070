import { useContext } from 'react';
import { EmailEditorActionsContext } from './email-editor-context';

export default function useEmailEditorActions() {
  const context = useContext(EmailEditorActionsContext);

  if (context === undefined) {
    throw new Error('Email Editor Actions Context must be used within the Workspace');
  }

  return context;
}
