import { isEmpty, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { UserRole } from 'enums';
import { useBoolean, useCurrentUser, useSearchUserTeam } from 'hooks';
import { User } from 'types';
import { isIntegrationEnabled } from 'utils/email-integration-utils';

export function useCcState(initSelectedTeammates: User[]) {
  const { me } = useCurrentUser();
  const integrationEnabled = isIntegrationEnabled(me);

  const [selectedTeammates, setSelectedTeammates] = useState<User[]>(initSelectedTeammates);
  const [availableTeammates, setAvailableTeammates] = useState<User[]>([]);
  const [isSelectedTeammatesInitialized, { on: setSelectedTeammatesInitialized }] = useBoolean(false);

  function getDefaultSelectedTeammates() {
    if (!me) {
      return [];
    }

    // if integration is enabled, we want to show only the selected teammates without the current user
    if (me?.role === UserRole.BackOffice || integrationEnabled) {
      return isEmpty(initSelectedTeammates) ? [] : initSelectedTeammates;
    }

    return !isEmpty(selectedTeammates) ? selectedTeammates : [{ ...me }];
  }

  function initializeState(users: User[]) {
    if (users && me) {
      setAvailableTeammates(sortBy(users, (user) => (user.id === me.id ? 0 : 1)));
      if (!isSelectedTeammatesInitialized) {
        setSelectedTeammates(getDefaultSelectedTeammates());

        setSelectedTeammatesInitialized();
      }
    }
  }

  const { items: users } = useSearchUserTeam({
    filter: { enabled: true },
    queryOptions: {
      onSuccess: (data) => {
        initializeState(data?.items || []);
      },
    },
  });

  useEffect(() => {
    initializeState(users);
    // We only want to initialize the state when 'me' or 'users' have loaded.
    // Alternative is to add useCallback to both getDefaultSelectedTeammates and initializeState.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, users]);

  const toggleTeammateSelection = (user: User) => {
    if (selectedTeammates.findIndex((teammate) => teammate.id === user.id) === -1) {
      setSelectedTeammates((prev) => [...prev, user]);
    } else {
      setSelectedTeammates((prev) => prev.filter((item) => item.id !== user.id));
    }
  };

  return {
    availableTeammates,
    selectedTeammates,
    toggleTeammateSelection,
  };
}
