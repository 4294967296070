import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useBoolean, useCurrentUser, useFormProvider, useMutateUser, useToast } from 'hooks';
import { messages } from 'i18n';
import { isIntegrationEnabled } from 'utils/email-integration-utils';
import { DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import { DirtyDialogGuardAttributes } from 'broker/components/DirtyContentGuard';
import EmailSettings from './components/EmailSettings';
import PersonalSettings from './components/PersonalSettings';
import FormSkeleton from './FormSkeleton';
import { schema, SettingsFormData } from './utils';

export default function Content({
  closeAttempt,
  closeWithoutPrompt,
  setIsContentDirty,
  isContentDirty,
}: DirtyDialogGuardAttributes) {
  const dialogTitle = messages.SettingsDialog.title;
  const { me } = useCurrentUser();
  const { firstName, lastName, email, signature, id, note, phoneNumber, title } = me!;
  const { showToast } = useToast();
  const { updateUser } = useMutateUser();
  const [isLoading, { on: showLoader, off: hideLoader }] = useBoolean(false);

  const { methods } = useFormProvider<SettingsFormData>({
    defaultValues: { firstName, lastName, email, signature, note, phoneNumber, title },
    schema,
    setIsDirty: setIsContentDirty,
  });

  const onSave: SubmitHandler<SettingsFormData> = async (data) => {
    showLoader();
    try {
      await updateUser.mutateAsync({
        id,
        data: {
          signature: data.signature,
          firstName: data.firstName,
          lastName: data.lastName,
          title: data.title,
          phoneNumber: data.phoneNumber,
          note: data.note,
        },
      });

      showToast('success', { message: messages.SettingsDialog.savedSuccessfully });
      closeWithoutPrompt();
    } finally {
      hideLoader();
    }
  };

  const onClose = () => {
    closeAttempt();
    methods.reset();
  };

  return (
    <>
      <DialogHeader title={dialogTitle} id="header-settings-dialog" onClose={onClose} />
      {me ? (
        <>
          <DialogContent dividers>
            <FormProvider {...methods}>
              <PersonalSettings />
              <EmailSettings integrationEnabled={isIntegrationEnabled(me ?? null)} />
            </FormProvider>
          </DialogContent>
          <DialogFooter
            proceedButton={{
              children: messages.buttons.save,
              onClick: methods.handleSubmit(onSave),
              disabled: !isContentDirty,
              loading: isLoading,
            }}
            cancelButton={{ children: messages.buttons.cancel, onClick: onClose }}
          />
        </>
      ) : (
        <>
          <DialogContent>
            <FormSkeleton />
          </DialogContent>
          <DialogFooter showSkeleton />
        </>
      )}
    </>
  );
}
