import { Quote } from 'types';

export enum QuoteStatusType {
  Bind = 'Bind',
  Policy = 'Policy',
}

export interface QuoteStatusRequestLocationState {
  type: QuoteStatusType;
  recipientQuotes: Quote[];
}
