import { ChangeEvent, useState } from 'react';
import { ActionDialog, Checkbox, DialogTextField, FormControlLabel, Typography } from '@common-components';
import { messages } from 'i18n';

export interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onAction: (deleteTeamDataCheckBox?: boolean) => Promise<void>;
  confirmationText: string;
  confirmationMessage: string;
  title: string;
  warningMessage: string;
  confirmButton: string;
  showDeleteTeamDataCheckBox?: boolean;
  deleteTeamDataMessage?: string;
}

export default function ConfirmationDialog({
  open,
  onClose,
  onAction,
  confirmationText,
  confirmationMessage,
  title,
  warningMessage,
  confirmButton,
  showDeleteTeamDataCheckBox,
  deleteTeamDataMessage,
}: ConfirmationDialogProps) {
  const [openSecondGuard, setOpenSecondGuard] = useState<boolean>(false);
  const [confirmActionText, setConfirmActionText] = useState<string>('');
  const [deleteTeamDataCheckBox, setDeleteTeamDataCheckBox] = useState<boolean>(false);

  const { confirmStepTitle, confirmWarning, cancelLabel, deleteTeamDataCheckBoxMessage } =
    messages.admin.confirmationDialog;

  const onConfirmDelete = async () => {
    await onAction(deleteTeamDataCheckBox);
    setOpenSecondGuard(false);
    setConfirmActionText('');
  };

  const onCloseConfirmDialog = () => {
    setConfirmActionText('');
    setOpenSecondGuard(false);
  };

  const handleDeleteTeamDataCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setDeleteTeamDataCheckBox(event.target.checked);
  };

  return (
    <>
      <ActionDialog
        actionLabel={confirmButton}
        cancelLabel={cancelLabel}
        enabled
        open={open}
        onClose={onClose}
        onAction={() => {
          setOpenSecondGuard(true);
          onClose();
        }}
        title={title}
      >
        <Typography textAlign="center" sx={{ fontSize: 90, lineHeight: 1 }}>
          &#9760;
        </Typography>
        <Typography color="red" variant="h6" textAlign="center">
          {warningMessage}
        </Typography>
      </ActionDialog>
      <ActionDialog
        actionLabel={confirmStepTitle}
        cancelLabel="Cancel"
        enabled={confirmActionText === confirmationText}
        open={openSecondGuard}
        onClose={onCloseConfirmDialog}
        onAction={onConfirmDelete}
        title={`${confirmStepTitle} ${confirmButton}`}
      >
        <Typography my={1} variant="h6">
          {confirmationMessage}
        </Typography>
        <DialogTextField
          margin="none"
          placeholder="Your answer"
          setValue={setConfirmActionText}
          value={confirmActionText}
        />
        {deleteTeamDataMessage && (
          <Typography my={1} variant="body1">
            {deleteTeamDataMessage}
          </Typography>
        )}
        {showDeleteTeamDataCheckBox && (
          <FormControlLabel
            label={deleteTeamDataCheckBoxMessage}
            control={<Checkbox checked={deleteTeamDataCheckBox} onChange={handleDeleteTeamDataCheckBox} />}
          />
        )}
        <Typography my={1} color="red" variant="h6">
          {confirmWarning}
        </Typography>
      </ActionDialog>
    </>
  );
}
