import { isEmpty } from 'lodash';
import { MouseEvent, memo as reactMemo } from 'react';

import {
  Box,
  Chip,
  Collapse,
  darken,
  Divider,
  Highlight,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@common-components';
import { ExpandLess, ExpandMore, People as PeopleIcon } from '@icons';
import { messages } from 'i18n';
import { getDisplayDate, truncateMultiLineText, userFullName } from 'utils';
import { stringCompare } from 'utils/comparators';
import LabelList from 'broker/components/common/LabelList';
import { UserMarketColumns } from 'broker/components/UserMarkets/components/MarketsTableHeader';
import { AgencyFormFieldsNames } from 'broker/dialogs/AgencyDialog/types';
import { AgenciesColumns, columnConfig } from './AgenciesTableHeader';
import AgenciesTableItemMenu from './AgenciesTableItemMenu';
import AgencyTableItemExpand from './AgencyTableItemExpand';
import { AgenciesItemProps } from './types';
import useUsersSorter from './useUsersSorter';

function AgenciesTableItem({
  isAgencyExpanded,
  onExpandClicked,
  agency,
  onClick,
  searchInput = '',
  checkedLabels,
  onOpenNewAgent,
}: AgenciesItemProps) {
  const { id, agents, domains, updatedAt } = agency;

  const sortedAgents = useUsersSorter({ agents, searchInput });

  const preventExpandCollapse = (e?: MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation();
    e?.preventDefault();
  };

  return (
    <Stack divider={<Divider flexItem />}>
      <Stack
        onClick={() => {
          onExpandClicked(id);
        }}
        direction="row"
        px={1.5}
        alignItems="center"
        height={(theme) => theme.spacing(9)}
        width={1}
        gap={3}
        sx={(theme) => ({
          cursor: 'pointer',
          position: isAgencyExpanded ? 'sticky' : 'static',
          bgcolor: 'common.white',
          zIndex: 1,
          top: 40,
          ':hover': {
            bgcolor: darken(theme.palette.common.white, 0.01),
          },
        })}
      >
        <Stack flex={columnConfig[AgenciesColumns.ExpandContacts].flex} alignItems="center">
          <IconButton icon={isAgencyExpanded ? ExpandLess : ExpandMore} size="small" color="secondary" variant="text" />
        </Stack>
        <Stack direction="row" flex={columnConfig[AgenciesColumns.Agency].flex} alignItems="center" overflow="hidden">
          <Stack gap={0.5}>
            <Tooltip tooltipContent="Organization name">
              <Typography fontWeight="bold" variant="body2" sx={{ ...truncateMultiLineText(1) }}>
                <Highlight searchWords={[searchInput]} textToHighlight={agency.name} />
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>

        <Stack
          flex={columnConfig[AgenciesColumns.Retailers].flex}
          alignItems={columnConfig[AgenciesColumns.Retailers].align}
          overflow="hidden"
          py={0.5}
        >
          <Chip
            rounded
            tooltipContent={
              !isEmpty(sortedAgents) ? (
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {[...sortedAgents]
                    .sort((a, b) => stringCompare(userFullName(b), userFullName(a)))
                    .map((contact) => (
                      <Stack sx={{ lineHeight: 1 }} key={contact.id}>
                        <Chip
                          indicator={false}
                          tooltipContent={contact.email}
                          label={userFullName(contact)}
                          searchTerm={searchInput}
                          color="secondary"
                        />
                      </Stack>
                    ))}
                </Stack>
              ) : (
                ''
              )
            }
            indicator={!!searchInput && sortedAgents.some((agent) => agent.passedFilter)}
            label={agents.length.toString()}
            color={isAgencyExpanded ? 'primary' : 'secondary'}
            focused={isAgencyExpanded}
            variant="outlined"
            startIcon={PeopleIcon}
          />
        </Stack>

        <Stack flex={columnConfig[AgenciesColumns.Domains].flex} direction="row" overflow="hidden" py={1} gap={1}>
          <LabelList
            variant="contained"
            isRounded
            labels={domains.map((domain) => ({ text: domain.domain }))}
            onAddLabelClick={() => onClick({ id, scrollTo: AgencyFormFieldsNames.Domain })}
            searchInput={searchInput}
            selectedLabels={checkedLabels}
            addButtonText={messages.agenciesPage.addDomain}
          />
        </Stack>
        {updatedAt && (
          <Stack
            flex={columnConfig[UserMarketColumns.LastUpdate].flex}
            alignItems={columnConfig[UserMarketColumns.LastUpdate].align}
            overflow="hidden"
          >
            <Typography variant="body2" noWrap>
              {getDisplayDate(updatedAt)}
            </Typography>
          </Stack>
        )}

        <Box onClick={preventExpandCollapse}>
          <AgenciesTableItemMenu extendedAgency={agency} onAddAgent={onOpenNewAgent} onEdit={onClick} />
        </Box>
      </Stack>
      <Collapse in={isAgencyExpanded} mountOnEnter unmountOnExit>
        <AgencyTableItemExpand
          filteredAgents={agents}
          agency={agency}
          onOpenNewContact={onOpenNewAgent}
          searchInput={searchInput}
        />
      </Collapse>
    </Stack>
  );
}

export default reactMemo(AgenciesTableItem);
