import { fileTypeConfig } from '@common/config';
import { MouseEvent } from 'react';
import { Chip, IconButton, Stack, Typography } from '@common-components';
import { Close as CloseIcon, DragIndicator, LabelOutlined as LabelOutlinedIcon } from '@icons';
import { getFileType } from 'utils';
import { ExtendedBoxItem } from 'broker/components/FilesExplorer/types';

import FileTypeIcon from './FileTypeIcon';

interface FilesListItemProps<T> {
  fileItem: T;
  onRemoveFile?: (file: T) => void;
  onItemClicked?: (file: T) => void;
  disabled?: boolean;
  prefix?: string;
  draggable?: boolean;
}

export function FilesListItem<T extends { name: string; metadata?: object }>({
  fileItem,
  onRemoveFile,
  onItemClicked,
  disabled,
  prefix = '',
  draggable = false,
}: FilesListItemProps<T>) {
  let fileLabel: string | undefined;

  // if fileItem is BoxItem, get file type from metadata and add label
  if (typeof fileItem.metadata === 'object') {
    const fileType = getFileType(fileItem as any as ExtendedBoxItem);
    fileLabel = fileType && fileTypeConfig[fileType]?.text;
  }

  return (
    <Stack direction="row" alignItems="center" gap={2} onClick={() => onItemClicked?.(fileItem)}>
      {prefix && <Typography variant="captionBold">{prefix}</Typography>}
      <Stack
        gap={1}
        flex={1}
        direction="row"
        borderRadius={1}
        border={1}
        borderColor="blue.100"
        bgcolor="common.white"
        sx={{ '&:hover': { bgcolor: 'blue.50' } }}
        p={1}
        alignItems="center"
        minWidth={0}
      >
        {draggable && <DragIndicator fontSize="small" color="disabled" />}
        <FileTypeIcon fileNameOrExtension={fileItem.name} />
        <Typography variant="captionBold" noWrap flex={1}>
          {`${fileItem.name}`}
        </Typography>
        {fileLabel && (
          <Chip label={fileLabel} size="small" startIcon={LabelOutlinedIcon} color="secondary" rounded={false} />
        )}
        {onRemoveFile && (
          <IconButton
            disabled={disabled}
            size="small"
            variant="text"
            color="secondary"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              onRemoveFile(fileItem);
            }}
            icon={CloseIcon}
          />
        )}
      </Stack>
    </Stack>
  );
}
