import { OffsetLimitPagination, Team } from 'types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export type TeamQuery = OffsetLimitPagination;

export class TeamClient extends CapitolaClient<Team> {
  public get resourceName(): string {
    return 'team';
  }

  public async clearData(teamId: string): Promise<Team | null> {
    return this.custom({
      httpMethod: HttpMethods.PATCH,
      path: `${teamId}/clear-data`,
      fetchOptions: { throwOnError: true },
    });
  }
}
