import { useState } from 'react';
import { useDebouncedEffect } from 'hooks';

export const useSearch = (
  initValue = {
    value: '',
    debouncedValue: '',
  },
  debounceRateMilliseconds = 200,
) => {
  // These 2 are essentially the same search term, the only difference is that
  // the debouncedValue is used for effects that we don't want recalculated
  // all the time while the value is used to display the actual term
  // without a delay
  const [value, setValue] = useState<string>(initValue.value);
  const [debouncedValue, setDebouncedValue] = useState<string>(initValue.debouncedValue);

  useDebouncedEffect(
    () => {
      if (value !== debouncedValue) {
        setDebouncedValue(value);
      }
    },
    debounceRateMilliseconds,
    [value],
  );

  const setSearch = (newValue: string, immediateUpdate = false) => {
    setValue(newValue);
    if (immediateUpdate) {
      setDebouncedValue(newValue);
    }
  };

  return { setSearch, search: { value, debouncedValue } };
};
