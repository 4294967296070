import { BoxItem } from 'box-ui-elements/es';
import { Grid, Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import FileTypeIcon from 'broker/components/common/FileTypeIcon';
import { SectionLabel } from 'broker/pages/SubmissionWorkspacePage/dialogs/CompareQuotesModal/components/QuotesSideBySide/components/QuoteDetails/SectionLabel';

export default function FilesGrid({ files }: { files?: BoxItem[] }) {
  return (
    files && (
      <Stack gap={1}>
        <SectionLabel label={messages.quoteReview.files} />
        <Grid container spacing={2}>
          {files.map((file) => (
            <Grid item xs={12} sm={6} md={4} key={file.name}>
              <Stack flex={1} direction="row" alignItems="center" minWidth={0}>
                <FileTypeIcon fileNameOrExtension={file.name} />
                <Typography variant="captionBold" noWrap>
                  {file.name}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    )
  );
}
