import { isEmpty, omit, partition } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { isMarketOrganization } from 'enums';
import { useDeepCompareEffect } from 'hooks';
import { SubmissionMarket, UserMarket } from 'types';
import { RecipientGroup, RecipientGroups } from 'broker/components/Emails/recipient-utils';
import { Recipient } from 'broker/components/Emails/types';
import { getAvailableContactsForUserMarket } from './store/utils';

interface Props {
  preSelectedRecipients?: RecipientGroup[];
  userMarkets?: UserMarket[];
  submissionMarkets?: SubmissionMarket[];
}

const getDefaultRecipientGroups = (preSelectedRecipients?: RecipientGroup[]) => {
  if (preSelectedRecipients && !isEmpty(preSelectedRecipients)) {
    return preSelectedRecipients.reduce(
      (result: RecipientGroups, recipientGroup) => ({
        ...result,
        [recipientGroup.id]: recipientGroup,
      }),
      {},
    );
  }

  return {};
};

export default function useEmailRecipientsState({ preSelectedRecipients, userMarkets, submissionMarkets }: Props = {}) {
  const [recipientGroups, setRecipientGroups] = useState<RecipientGroups>(
    getDefaultRecipientGroups(preSelectedRecipients),
  );
  const [newRecipients, setNewRecipients] = useState<Recipient[]>([]);
  const [selectedRecipientGroupId, setSelectedRecipientGroupId] = useState<string | undefined>(undefined);

  // delete removedRecipients if needed once it doesn't exists in userMarket.contacts[].id but exists in recipientGroups.recipients[].id
  useEffect(() => {
    if (userMarkets?.length) {
      Object.values(recipientGroups).forEach((recipientGroup) => {
        const recipientGroupUserMarket = userMarkets.find((userMarket) => userMarket.id === recipientGroup.id);

        const availableContacts = recipientGroupUserMarket
          ? getAvailableContactsForUserMarket(recipientGroupUserMarket, submissionMarkets || [])
          : undefined;

        if (availableContacts?.length) {
          const [existsInAvailableRecipientsList, nonExistsInAvailableRecipientsList] = partition(
            recipientGroup.recipients,
            (recipient) =>
              availableContacts.some(
                (recipientGroupAvailableContact) => recipientGroupAvailableContact.id === recipient.id,
              ),
          );

          if (nonExistsInAvailableRecipientsList.length) {
            setRecipientGroups((prev) => ({
              ...prev,
              [recipientGroup.id]: {
                ...recipientGroup,
                recipients: existsInAvailableRecipientsList,
              },
            }));
          }
        }
      });
    }
  }, [recipientGroups, submissionMarkets, userMarkets]);

  useDeepCompareEffect(() => {
    if (preSelectedRecipients && !isEmpty(preSelectedRecipients)) {
      setRecipientGroups(getDefaultRecipientGroups(preSelectedRecipients));
      setSelectedRecipientGroupId(preSelectedRecipients[0].id);
    }
  }, [preSelectedRecipients]);

  const onAddRecipientGroups = useCallback((groupsToAdd: RecipientGroups, replaceMarkets = false) => {
    if (replaceMarkets) {
      setRecipientGroups((prev) => {
        const nonMarketGroup = Object.keys(prev)
          .filter((groupId) => !isMarketOrganization(prev[groupId].type))
          .reduce(
            (groups, groupId) => ({
              ...groups,
              [groupId]: prev[groupId],
            }),
            {},
          );

        return { ...nonMarketGroup, ...groupsToAdd };
      });
    } else {
      setRecipientGroups((prev) => ({ ...prev, ...groupsToAdd }));
    }
  }, []);

  const onRemoveRecipientGroup = useCallback((groupId: string) => {
    setRecipientGroups((prev) => omit(prev, [groupId]));
  }, []);

  const onToggleRecipient = (groupId: string, toggledRecipient: Recipient, removeIfLast = true) => {
    setRecipientGroups((prev) => {
      const group = prev[groupId];

      if (!group) {
        return prev;
      }

      // Add recipient
      if (!group.recipients.find((recipient) => recipient.id === toggledRecipient.id)) {
        return {
          ...prev,
          [groupId]: {
            ...group,
            recipients: [...group.recipients, toggledRecipient],
          },
        };
      }

      // Remove recipient
      if (!removeIfLast && group.recipients.length === 1) {
        return prev;
      }

      const newRecipientsList = group.recipients.filter((item) => item.id !== toggledRecipient.id);

      return {
        ...prev,
        [groupId]: {
          ...group,
          recipients: newRecipientsList,
        },
      };
    });
  };

  const onRemoveRecipients = useCallback((groupId: string, recipientId: string) => {
    setRecipientGroups((prev) => {
      const group = prev[groupId];

      if (!group) {
        return prev;
      }

      const newRecipientsList = group.recipients.filter((item) => item.id !== recipientId);
      if (isEmpty(newRecipientsList)) {
        return omit(prev, [groupId]);
      }

      return {
        ...prev,
        [groupId]: {
          ...group,
          recipients: newRecipientsList,
        },
      };
    });
  }, []);

  const onAddRecipient = useCallback((recipient: Recipient, groupId: string) => {
    setRecipientGroups((prev) => {
      const group = prev[groupId];

      if (!group) {
        return prev;
      }

      return {
        ...prev,
        [groupId]: {
          ...group,
          recipients: [...group.recipients, recipient],
        },
      };
    });
    // new recipients need to be set after setRecipientGroups because on new recipients state change we want to be able to reorder the recipient group contact list and to consider that the new contact appears in recipientGroups state
    setNewRecipients((prev) => prev.concat(recipient));
  }, []);

  const onClearNewRecipients = () => {
    setNewRecipients([]);
  };

  // ensure that a recipient group is selected after recipient groups have been added/removed
  useEffect(() => {
    if (isEmpty(recipientGroups)) {
      setSelectedRecipientGroupId(undefined);
    } else if (!selectedRecipientGroupId && !isEmpty(recipientGroups)) {
      // sets the initial selected recipient group
      setSelectedRecipientGroupId(Object.values(recipientGroups)[0].id);
    } else if (selectedRecipientGroupId && !isEmpty(recipientGroups) && !recipientGroups[selectedRecipientGroupId]) {
      // handles the case when the selected recipient group was removed
      setSelectedRecipientGroupId(Object.values(recipientGroups)[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientGroups]);

  return {
    recipientGroups,
    newRecipients,
    onAddRecipientGroups,
    onRemoveRecipients,
    onToggleRecipient,
    onAddRecipient,
    onClearNewRecipients,
    onRemoveRecipientGroup,
    selectedRecipientGroupId,
    setSelectedRecipientGroupId,
  };
}
