import { LoaderOverlay } from '@common-components';
import { NylasAuthenticationStatus } from 'enums';
import { useCurrentUser, useRetrieveNylasAuthenticationStatus } from 'hooks';
import { NylasAuthenticationPage } from 'pages/NylasAuthenticationPage';
import { isIntegrationEnabled } from 'utils/email-integration-utils';
import DefaultLoggedInRedirect from 'components/DefaultLoggedInRedirect';

/* The `/home` is the redirect location on login. Once the user is logged in, this component
 * can detect their role and redirect to an appropriate location.
 * It will also trigger the email integration authentication flow if required.
 */
export default function HomeRedirect() {
  const { me } = useCurrentUser();
  const isEmailIntegrationEnabled = isIntegrationEnabled(me);

  const { data: nylasUser, isInitialLoading } = useRetrieveNylasAuthenticationStatus({
    enabled: isEmailIntegrationEnabled,
  });

  if (isInitialLoading) {
    return <LoaderOverlay />;
  }

  if (
    isEmailIntegrationEnabled &&
    nylasUser &&
    nylasUser.authenticationStatus !== NylasAuthenticationStatus.Authenticated
  ) {
    return (
      <NylasAuthenticationPage>
        <DefaultLoggedInRedirect />
      </NylasAuthenticationPage>
    );
  }

  return <DefaultLoggedInRedirect />;
}
