import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useMatch, useResolvedPath } from 'react-router-dom-latest';
import { Button, Divider, Stack, Typography } from '@common-components';
import { Share } from '@icons';
import { messages } from 'i18n';
import { ExtendedMarketRequest } from 'types';
import { BROKER_NESTED_ROUTES, RETAILER_SUBMISSION } from 'broker/broker-routes';
import { RouteKey } from 'broker/enums';
import MainScrollArea from 'broker/hoc/MainScrollArea';
import { useNavigate } from 'broker/hooks';
import EmptyState from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/components/EmptyState';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { submissionMarketsToExtendedMarketRequests } from 'broker/utils/submission-utills';
import MarketItem from './MarketItem';
import MarketsTableHeader from './MarketsTableHeader';

export default function MarketsView() {
  const navigate = useNavigate();
  const resolvedPath = useResolvedPath(`/${RETAILER_SUBMISSION}`);
  const isMainPage = useMatch({ path: resolvedPath.pathname, end: true });
  const { markets } = useSubmissionsWorkspace();
  const extendedMarketRequests: ExtendedMarketRequest[] = useMemo(
    () => submissionMarketsToExtendedMarketRequests(markets),
    [markets],
  );

  if (!isMainPage) {
    return null;
  }
  return (
    <>
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" bgcolor="common.white" px={4} pt={6}>
          <Typography variant="h3" color="typography.primary">
            {messages.marketingReportPage.marketingReportWithCount(markets.length)}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(BROKER_NESTED_ROUTES.WORKSPACE.MARKETING_REPORT, {
                routeKey: RouteKey.MarketingReport,
                state: { saveMarketingReportFlow: false },
              });
            }}
            startIcon={<Share />}
          >
            {messages.buttons.reports}
          </Button>
        </Stack>
      </Stack>

      <MainScrollArea id="markets-view" overflow="auto" flex={1}>
        {isEmpty(markets) ? (
          <EmptyState emptyState={isEmpty(markets)} />
        ) : (
          <Stack mx={4} my={2} border={1} borderColor="divider" borderRadius={2} divider={<Divider flexItem />}>
            <MarketsTableHeader />
            {extendedMarketRequests.map((extendedMarketRequest) => (
              <MarketItem key={extendedMarketRequest.id} extendedMarketRequest={extendedMarketRequest} />
            ))}
          </Stack>
        )}
      </MainScrollArea>
    </>
  );
}
