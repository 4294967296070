import { useParams } from 'react-router-dom-latest';
import { Paper } from '@common-components';
import EmailTemplateList from 'admin/components/email-templates/EmailTemplateList';

export default function EmailTemplatesPage() {
  const { id } = useParams<{ id: string }>();
  return (
    <Paper sx={{ mt: 0 }}>
      <EmailTemplateList userId={id!} />
    </Paper>
  );
}
