import { sortBy } from 'lodash';
import { ChangeEvent, useRef } from 'react';
import { Box, Divider, Stack, SxProps } from '@common-components';
import { LabelOutlined as LabelIcon } from '@icons';
import { useTruncated } from 'hooks';
import { messages } from 'i18n';
import { FilterElementProps } from 'utils';
import Filter, { FilterButtonTypes } from 'broker/components/common/Filter';
import CategoriesFilterItem from './CategoriesFilterItem';

interface CategoriesProps {
  labelFilters: FilterElementProps[];
  handleLabelFilterChange: (event: ChangeEvent<HTMLInputElement>, key: string) => void;
  handleVisibleLabelChange: (key: string) => void;
  isAllLabelFilters: boolean;
  handleAllLabelSelected: () => void;
  sx?: SxProps;
}

export default function CategoriesFilter({
  labelFilters,
  handleLabelFilterChange,
  handleVisibleLabelChange,
  isAllLabelFilters,
  handleAllLabelSelected,
  sx,
}: CategoriesProps) {
  const containerElementRef = useRef<HTMLElement | null>();

  const { localArrays, ref } = useTruncated({
    containerElementRef,
    items: labelFilters,
    sorter: (e) => e.label.toLowerCase(),
  });

  return (
    <Stack direction="row" width={1} gap={1} alignItems="flex-start" sx={sx} ref={containerElementRef}>
      <Box overflow="auto">
        <Stack direction="row" gap={1} ref={ref} alignItems="center">
          <Divider orientation="vertical" flexItem variant="fullWidth" />
          <LabelIcon sx={{ color: 'grey.500' }} />
          <Stack direction="row" gap={1} visibility={localArrays.visible.length > 0 ? 'visible' : 'hidden'}>
            {sortBy(localArrays.visible, (e) => e.label.toLowerCase()).map((category) => (
              <CategoriesFilterItem
                key={category.key}
                category={category}
                handleVisibleLabelChange={handleVisibleLabelChange}
              />
            ))}
          </Stack>
          <Box display={localArrays.hidden.length > 0 ? 'block' : 'none'}>
            <Filter
              buttonType={FilterButtonTypes.Dots}
              filters={sortBy(localArrays.hidden, (e) => e.label.toLowerCase())}
              handleFilterChange={handleLabelFilterChange}
              isAllFilters={isAllLabelFilters}
              handleAllSelected={handleAllLabelSelected}
              search
              searchPlaceholder={messages.marketsPage.categoriesFilter.search}
            />
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}
