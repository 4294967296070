import { flushSync } from 'react-dom';
import { EmailTemplateType, SubmissionEmailLabel } from 'enums';
import { logger } from 'utils';
import { createRecipientGroupFromSubmissionCustomer } from 'broker/components/Emails/recipient-utils';
import SubmissionEmailEditor from 'broker/pages/SubmissionWorkspacePage/components/SubmissionEmailEditor';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import EmailStepContent from './EmailStepContent';
import { StepProps } from './types';

export default function EmailStep(props: StepProps) {
  const { submission, setIsDirty } = props;

  const { updateSubmission } = useSubmissionsWorkspace();

  return (
    <SubmissionEmailEditor
      isDirty={false}
      emailEditorProps={{
        preSelectedTemplateType: !submission.proposal?.body ? EmailTemplateType.NewBusinessProposal : undefined,
        preSelectedRecipients: [createRecipientGroupFromSubmissionCustomer(submission!)],
        emailLabel: SubmissionEmailLabel.Proposal,
        repliedThreadBody: submission.proposal?.body,
        repliedThreadSubject: submission.proposal?.subject ? submission.proposal?.subject : undefined,
        preSelectedFileIds: submission.proposal?.attachedFiles,
        postEmailsSent: async () => {
          try {
            // Clear the draft ack email body and selected products after sending the email
            await updateSubmission(submission!.id, {
              proposal: null,
            });

            flushSync(() => {
              setIsDirty(false);
            });
          } catch (error) {
            logger.log('error', error as Error);
          }
        },
      }}
    >
      <EmailStepContent {...props} />
    </SubmissionEmailEditor>
  );
}
