import { MENTION_PREFIX } from '@common/email-variables';
import { EditOutlined } from '@icons';
import theme from 'themes';
import { CAPITOLA_TINY_CLASS_PREFIX } from 'components/Editor/consts';

export const SmartVariable = ({ variable, value }: { variable: string; value?: string }) => (
  <div
    className="template-variable"
    data-variable={variable}
    contentEditable="false"
    suppressContentEditableWarning
    style={{ border: `1px ${theme.palette.purple[500]} dashed`, padding: '8px', borderRadius: '8px' }}
  >
    <div
      style={{
        color: theme.palette.purple[600],
        borderRadius: '8px 8px 0 0',
        margin: '-1px 0',
        display: 'flex',
        fontWeight: 600,
        justifyContent: 'space-between',
        backgroundColor: theme.palette.purple[50],
        padding: '6px 0 6px 10px',
        border: `1px solid ${theme.palette.blueGrey[200]}`,
      }}
    >
      {`${MENTION_PREFIX}${variable}`}
      <span
        style={{
          fontSize: '16px',
          marginRight: '8px',
          cursor: 'pointer',
          width: '16px',
          height: '16px',
          fill: theme.palette.purple[600],
        }}
        className={`${CAPITOLA_TINY_CLASS_PREFIX}-${variable.toLowerCase()}`}
      >
        <EditOutlined />
      </span>
    </div>
    <span dangerouslySetInnerHTML={{ __html: value ?? '' }} />
  </div>
);
