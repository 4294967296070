import { MENTION_PREFIX } from '@common/email-variables';
import { useToast } from 'hooks';
import { messages } from 'i18n';
import { copyToClipboard } from 'utils';
import useActivateOnce from 'broker/hooks/useActivateOnce';
import { useUiStoreActions } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

export function useCopySmartReportHandler() {
  const { showToast, closeSnackbar } = useToast();

  const { activate: showTooltip, shownBefore } = useActivateOnce('PasteSmartObject');
  const { setPasteSmartObjectIndicator } = useUiStoreActions();

  const handleCopy = async (variable: string) => {
    await copyToClipboard(`${MENTION_PREFIX}${variable}`);

    if (!shownBefore) {
      showTooltip();
      setPasteSmartObjectIndicator(true);
    } else {
      const snackbarId = showToast('success', {
        message: messages.submissionWorkspace.customerSection.smartObjectDropdown.successPasteToast,
      });
      // can't use autoHideDuration as they have a bug when lossing focus from the window, in this case we are working inside the editor (iframe) so it won't hide the snackbar after 3 seconds
      // https://github.com/iamhosseindhv/notistack/issues/282
      setTimeout(() => {
        closeSnackbar(snackbarId);
      }, 3000);
    }
  };

  const handleCloseTooltip = () => {
    setPasteSmartObjectIndicator(false);
  };

  return { handleCopy, handleCloseTooltip };
}
