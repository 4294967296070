import { BoxItem } from 'box-ui-elements/es';
import { compact } from 'lodash';
import { useMemo, useState } from 'react';
import { Link, NewMenu } from '@common-components';
import { BoxTemplateLabels, FileType, SubmissionMarketRequestStatus } from 'enums';
import { useOptionsMenu } from 'hooks';
import { useSearchBoxItems } from 'hooks/api/box';
import { ExtendedMarketRequest, Quote } from 'types';
import { getFileMetadataField } from 'utils';
import { MenuGroup, MenuItemProps } from 'components/menu';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { quoteComparisonLimit } from 'broker/pages/SubmissionWorkspacePage/utils';
import { submissionMarketsToExtendedMarketRequests } from 'broker/utils/submission-utills';
import { useFileBucketConfig } from './useFileBucketConfig';

export default function CompareQuotesFilesBucketAction() {
  const { markets, submission } = useSubmissionsWorkspace();

  const { quoteComparisonInsightsUrl } = useWorkspaceUrls();

  const filesTypeConfig = useFileBucketConfig();

  const optionsMenuState = useOptionsMenu();

  const navigate = useNavigate();

  const extendedMarketRequests: ExtendedMarketRequest[] = useMemo(
    () => submissionMarketsToExtendedMarketRequests(markets, submission),
    [markets, submission],
  );

  const quotes = useMemo(
    () =>
      compact(
        extendedMarketRequests
          .filter((market) => market.status === SubmissionMarketRequestStatus.QuoteReady)
          .map((marketRequest) => marketRequest.quote),
      ),
    [extendedMarketRequests],
  );

  const { items } = useSearchBoxItems({
    filter: { chunkSize: 20, folderId: submission?.boxFolderId || '' },
  });

  const expiringProgramFiles = items.filter(
    (item) => getFileMetadataField(item, BoxTemplateLabels.FileType) === FileType.ExpiringProgram,
  );

  const [selectedExpiringProgram, setSelectedExpiringProgram] = useState<BoxItem | null>(null);
  const [selectedQuotes, setSelectedQuotes] = useState<Quote[]>([]);

  // Selection Callbacks
  const handleExpiringProgramSelect = (program: BoxItem) => {
    setSelectedExpiringProgram((prevSelected) => (prevSelected && prevSelected.id === program.id ? null : program));
  };

  const handleQuoteSelect = (quote: Quote) => {
    setSelectedQuotes((prevSelected) => {
      if (prevSelected.some((q) => q.id === quote.id)) {
        return prevSelected.filter((q) => q.id !== quote.id); // Deselect if already selected
      }
      if (prevSelected.length < 4) {
        return [...prevSelected, quote]; // Add if below limit
      }
      return prevSelected; // No change if already at limit
    });
  };

  // Generate menuItems with selection logic
  const expiringProgramItems: MenuItemProps[] = expiringProgramFiles.map((program) => ({
    key: program.id,
    label: program.name,
    isSelected: selectedExpiringProgram?.id === program.id,
    selectionType: 'checkbox', // Checkbox with limit of one selection
    onClick: () => handleExpiringProgramSelect(program),
  }));

  const quoteItems: MenuItemProps[] = quotes.map((quote) => ({
    key: quote.id,
    label: quote.marketName || `Quote ${quote.id}`,
    isSelected: selectedQuotes.some((q) => q.id === quote.id),
    selectionType: 'checkbox',
    onClick: () => handleQuoteSelect(quote),
    disabled: selectedQuotes.length >= quoteComparisonLimit && !selectedQuotes.some((q) => q.id === quote.id), // Only block new selections if at max limit
  }));

  // Confirm button disabled logic

  const isConfirmButtonDisabled =
    (selectedExpiringProgram ? selectedQuotes.length < 1 : selectedQuotes.length < 2) ||
    selectedQuotes.length > quoteComparisonLimit;

  // Define the menu structure
  const menuItems: MenuGroup[] = [
    {
      key: 'expiring-program-group',
      header: 'Expiring Programs',
      selectionType: 'checkbox',
      items: expiringProgramItems,
    },
    {
      key: 'quotes-group',
      header: 'Quotes',
      selectionType: 'checkbox',
      items: quoteItems,
    },
  ];

  return (
    <>
      <Link onClick={optionsMenuState.openMenu} underline="hover" sx={{ cursor: 'pointer', fontWeight: 'bold' }}>
        {filesTypeConfig[FileType.QuoteComparison].actionTitle}
      </Link>
      <NewMenu
        footer={{
          confirmButton: {
            text: 'Create Comparison',
            disabled: isConfirmButtonDisabled,
            onClick: () => {
              navigate(quoteComparisonInsightsUrl, {
                routeKey: RouteKey.QuoteComparisonInsights,
                state: {
                  quotes: selectedQuotes,
                  expiringPolicyFileId: selectedExpiringProgram?.id,
                },
              });
            },
          },
        }}
        optionsMenuState={optionsMenuState}
        menuItems={menuItems}
      />
    </>
  );
}
