import { compact } from 'lodash';
import { useCallback } from 'react';
import { contactFullName } from 'utils';
import { convertContactToRecipient, convertUserToRecipient } from 'broker/components/Emails/recipient-utils';
import { Recipient } from 'broker/components/Emails/types';
import { recipientFullName } from 'broker/components/Emails/utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export const useGetFullNameByEmail = () => {
  const { partialSubmission, markets } = useSubmissionsWorkspace();

  const getFullName = useCallback(
    (email: string) => {
      const contact = markets
        .flatMap((market) => market.contacts)
        .find((marketContact) => marketContact.email.toLocaleLowerCase() === email.toLocaleLowerCase());
      if (contact) {
        return contactFullName(contact);
      }
      const customerRecipient = partialSubmission?.user ? convertUserToRecipient(partialSubmission?.user) : undefined;
      const recipients: Recipient[] = compact([
        customerRecipient,
        ...(partialSubmission?.contacts?.map((contactItem) => convertContactToRecipient(contactItem)) || []),
      ]);

      const recipient = recipients.find(
        (submissionContact) => submissionContact.email.toLocaleLowerCase() === email.toLocaleLowerCase(),
      );
      return recipient ? recipientFullName(recipient) : email;
    },
    [markets, partialSubmission],
  );

  return { getFullName };
};
