// eslint-disable-next-line no-restricted-imports
import { useLocation as useRouterLocation } from 'react-router-dom-latest';
import { LocationState } from 'broker/types';

export function useLocation() {
  const location = useRouterLocation();
  const state = location.state as LocationState | undefined;

  return {
    ...location,
    state,
  };
}
