import { groupBy, isEmpty } from 'lodash';
import { messages } from 'i18n';
import { InsuranceProductFileType, ProductEmailSignals } from 'types';
import { BulletedContent } from './BulletedContent';

export const ProductEmailSignal = ({ name, emails }: ProductEmailSignals) => {
  if (isEmpty(emails)) {
    return null;
  }

  const emailsByType = groupBy(emails, (email) => email.fileType);

  const emailsCounts: string[] = [];
  if (!isEmpty(emailsByType[InsuranceProductFileType.DeclinationEmail])) {
    const declinationEmailsCount = emailsByType[InsuranceProductFileType.DeclinationEmail].length;
    emailsCounts.push(
      `${declinationEmailsCount} ${messages.submissionWorkspace.aiGeneratedContentPopup.declinationEmails(
        declinationEmailsCount,
      )}`,
    );
  }
  if (!isEmpty(emailsByType[InsuranceProductFileType.QuoteEmail])) {
    const quoteEmailsCount = emailsByType[InsuranceProductFileType.QuoteEmail].length;
    emailsCounts.push(
      ` ${quoteEmailsCount} ${messages.submissionWorkspace.aiGeneratedContentPopup.quoteEmails(quoteEmailsCount)}`,
    );
  }
  return <BulletedContent level={2} content={`${name} - ${emailsCounts.join(', ')}`} />;
};
