import { Button, IconButton, Stack } from '@common-components';
import { Add as AddIcon } from '@icons';
import { messages } from 'i18n';

interface AddMarketButtonProps {
  onClick: () => void;
  // If true, the button will be rendered as an icon button
  compact: boolean;
}

export const AddMarketsButton = ({ onClick, compact }: AddMarketButtonProps) => {
  if (compact) {
    return <IconButton icon={AddIcon} size="small" onClick={onClick} color="secondary" />;
  }

  return (
    <Button variant="contained" onClick={onClick}>
      <Stack direction="row" alignItems="center" gap={1}>
        <AddIcon fontSize="small" />
        {messages.emailEditor.addMarkets}
      </Stack>
    </Button>
  );
};
