import { chain, get, groupBy, pick } from 'lodash';
import moment from 'moment/moment';
import { messages } from 'i18n';
import { ActivityLog } from 'types';

const NO_GROUP_KEY = 'none';

export const groupActivitiesByDate = (activities: ActivityLog[]): Record<string, ActivityLog[]> =>
  chain(Object.entries(groupBy(activities, (activity) => get(activity, 'groupKey', NO_GROUP_KEY))))
    .flatMap(([groupKey, activitiesInGroup]) => {
      if (groupKey === NO_GROUP_KEY) {
        return activitiesInGroup;
      }
      if (activitiesInGroup.length <= 1) {
        return activitiesInGroup;
      }
      return [
        {
          ...pick(activitiesInGroup[0], ['submissionId', 'actorId', 'actor', 'activityType', 'createdAt', 'groupKey']),
          groupedActivities: activitiesInGroup,
          id: '',
        },
      ];
    })
    .orderBy('createdAt', 'desc')
    .groupBy((activity) => {
      const activityMoment = moment(activity.createdAt);
      const isToday = activityMoment.isSame(moment(), 'day');
      return isToday ? messages.submissionActivityLog.today : activityMoment.format('MMM D, YYYY');
    })
    .value();
