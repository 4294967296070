import { CircularProgress, Stack } from '@common-components';
import { useMutateUserMarket, useSearchSubmissionMarket } from 'hooks';
import { messages } from 'i18n';
import { UserMarket } from 'types';
import { ConfirmationDialog } from 'components/Dialog';

interface DeleteMarketDialogProps {
  userMarket: UserMarket;
  isOpen: boolean;
  onClose: () => void;
}

export default function DeleteMarketDialog({ userMarket, isOpen, onClose }: DeleteMarketDialogProps) {
  const { count: submissionMarketsCount, isInitialLoading: isLoadingSubmissionMarkets } = useSearchSubmissionMarket({
    filter: {
      userMarketId: userMarket.id,
      limit: 1,
    },
    queryOptions: {
      enabled: isOpen,
    },
  });

  const { deleteUserMarket } = useMutateUserMarket();

  const deletionAllowed = submissionMarketsCount === 0;

  const onDelete = async () => {
    await deleteUserMarket.mutateAsync({ id: userMarket.id });
    onClose();
  };

  const { title, confirmation, unableToDelete } = messages.marketsPage.deleteDialog;

  const messageText = deletionAllowed
    ? confirmation(userMarket.marketName)
    : unableToDelete(userMarket.marketName, submissionMarketsCount);

  return (
    <ConfirmationDialog
      message={
        isLoadingSubmissionMarkets ? (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          messageText
        )
      }
      title={title}
      isOpen={isOpen}
      isSubmitting={deleteUserMarket.isLoading}
      onSubmit={deletionAllowed ? onDelete : onClose}
      onCancel={onClose}
      hideCancelButton={!deletionAllowed}
      proceedButton={{
        children: deletionAllowed ? messages.buttons.delete : messages.buttons.gotIt,
        color: deletionAllowed ? 'error' : 'primary',
        disabled: isLoadingSubmissionMarkets,
      }}
      cancelButton={{
        children: messages.buttons.cancel,
        color: 'secondary',
      }}
    />
  );
}
