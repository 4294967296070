import { SubmissionEmail, SubmissionMarket } from 'types';

export type SubmissionMarketIdToEmailId = Record<string, string>;

/**
 * @submissionMarkets - contains contacts and submission market id
 * @submissionEmails - contains the recipients and the email id
 * We want to generate a mapping between submission market id to its correspondent email id
 */
export function buildSubmissionMarketIdToEmailId(
  submissionEmails: SubmissionEmail[],
  submissionMarkets: SubmissionMarket[],
) {
  return submissionMarkets.reduce<SubmissionMarketIdToEmailId>((acc, submissionMarket) => {
    const submissionEmail = submissionEmails.find(
      (email) => email.recipientOrganizationDetails?.id === submissionMarket.marketOrganizationId,
    );
    if (!submissionEmail) {
      return acc;
    }
    acc[submissionMarket.id] = submissionEmail.id;
    return acc;
  }, {});
}
