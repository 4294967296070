import { BoxSkeleton, Button } from '@common-components';
import { messages } from 'i18n';

interface EditorHeaderButtonsProps {
  isLoading: boolean;
  onSaveClick: () => void;
  disableSave?: boolean;
}

export default function EditorHeaderButtons({ isLoading, onSaveClick, disableSave = false }: EditorHeaderButtonsProps) {
  return (
    <BoxSkeleton isLoading={isLoading}>
      <Button variant="contained" disabled={disableSave} loading={isLoading} onClick={onSaveClick}>
        {`${messages.buttons.save}`}
      </Button>
    </BoxSkeleton>
  );
}
