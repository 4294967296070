import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Dictionary } from 'lodash';
import { HTMLAttributes } from 'react';
import { Button } from '@common-components';
import { messages } from 'i18n';
import { Indexed } from 'utils';
import { Box, ListItem } from 'components/mui-index';

export interface BaseOption {
  label: string;
  value: string;
}

export type GetOptionProps<T> = ({ option, index }: { option: T; index: number }) => HTMLAttributes<HTMLLIElement>;

export function OptionsList<T extends BaseOption>({
  options,
  getOptionProps,
  inputValue,
}: {
  options: Dictionary<Indexed<T>[]>;
  getOptionProps: GetOptionProps<T>;
  inputValue: string;
}) {
  const groups = Object.keys(options);
  //  limit the number of items in the list to the top 200
  const maxItems = 200;

  return (
    <>
      {groups.map((groupKey) => (
        <Box key={groupKey} sx={{ '.MuiBox-root': { border: 'none' } }}>
          {groups.length > 1 && (
            <Box component="li" color={(theme) => theme.palette.grey[700]} py={0.75} pl={1.5}>
              {groupKey}
            </Box>
          )}
          {options[groupKey].slice(0, maxItems).map((option) => {
            const matches = match(option.label, inputValue, { insideWords: true, findAllOccurrences: true });
            const parts = parse(option.label, matches);
            return (
              <ListItem
                key={option.label}
                {...getOptionProps({ option, index: option.index })}
                sx={{ '&.MuiListItem-root': { border: 'none', py: 0 } }}
              >
                {option.label.startsWith(`${messages.buttons.add}: `) ? (
                  <Box sx={{ width: '100%', justifyContent: 'flex-start' }}>
                    <Button>{option.label}</Button>
                  </Box>
                ) : (
                  <Box component="span" py={0.5}>
                    {parts.map((part, index) => (
                      <Box
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        component="span"
                        sx={{ '&.MuiBox-root': { m: 0, fontWeight: part.highlight ? 700 : 400 } }}
                      >
                        {part.text}
                      </Box>
                    ))}
                  </Box>
                )}
              </ListItem>
            );
          })}
        </Box>
      ))}
    </>
  );
}
