import { useCallback } from 'react';
import {
  createSearchParams,
  NavigateOptions as RouterOptions,
  To,
  // eslint-disable-next-line no-restricted-imports
  useNavigate as useRouterNavigate,
  useSearchParams,
} from 'react-router-dom-latest';
import { useAppStoreState } from 'contexts/app-store/AppStoreProvider';
import { RouteKey } from 'broker/enums';
import { LocationState, LocationStateEntries } from 'broker/types';
import { useLocation } from './useLocation';

export interface NavigateOptions extends RouterOptions {
  routeKey: RouteKey;
  state: LocationStateEntries[RouteKey];
}

// todo: replace with useNavigation and exclude react-router useNavigation and useLocation from broker
export function useNavigate() {
  const location = useLocation();
  const navigate = useRouterNavigate();
  const [searchParams] = useSearchParams();
  const { stickySearchParams } = useAppStoreState();

  return useCallback(
    (to: To, navigateOptions: NavigateOptions) => {
      const { routeKey, replace } = navigateOptions;
      const currentState = location.state;

      const localTo = typeof to === 'object' ? to : { pathname: to };

      const urlParams = new URLSearchParams(localTo.search);
      const search = Object.fromEntries(urlParams);

      searchParams.forEach((value, key) => {
        if (stickySearchParams.includes(key) && !search[key]) {
          search[key] = value;
        }
      });

      const nextState: LocationState = {
        ...currentState,
        [routeKey]: {
          ...navigateOptions.state,
          backTo: !replace
            ? { pathname: location.pathname, search: location.search, hash: location.hash }
            : currentState?.[currentState?.routeKey]?.backTo,
          backToRouteKey: !replace ? currentState?.routeKey : currentState?.[currentState?.routeKey]?.backToRouteKey,
        },
        routeKey,
      };

      if (replace && currentState?.routeKey) {
        delete nextState[currentState.routeKey];
      }

      // console.log('navigateTo', nextState);

      return navigate(
        { ...localTo, search: `${createSearchParams(search)}` },
        { ...navigateOptions, state: nextState },
      );
    },
    [location.hash, location.pathname, location.search, location.state, navigate, searchParams, stickySearchParams],
  );
}
