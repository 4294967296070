import { useState } from 'react';

export enum FileActionType {
  Rename = 'Rename',
  EditLabel = 'EditLabel',
}

export interface FileAction {
  actionType: FileActionType;
  isLoading: boolean;
}

export type FilesActions = Record<string, FileAction | undefined>;

export interface FilesDisplay {
  filesActions: FilesActions;
  openInlineAction: (itemId: string, actionType: FileActionType) => void;
  submitInlineAction: (itemId: string) => void;
  closeInlineAction: (itemId: string) => void;
}

export function useFilesDisplay(): FilesDisplay {
  const [fileActions, setFileActions] = useState<FilesActions>({});

  const openInlineAction = (itemId: string, actionType: FileActionType) => {
    setFileActions((prev) => ({
      ...prev,
      [itemId]: { actionType, isLoading: false },
    }));
  };

  const submitInlineAction = (itemId: string) => {
    setFileActions((prev) => ({
      ...prev,
      [itemId]: { ...prev[itemId]!, isLoading: true },
    }));
  };

  const closeInlineAction = (itemId: string) => {
    setFileActions((prev) => {
      const prevCopy = { ...prev };
      delete prevCopy[itemId];
      return prevCopy;
    });
  };

  return {
    filesActions: fileActions,
    openInlineAction,
    submitInlineAction,
    closeInlineAction,
  };
}
