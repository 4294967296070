import { isEmpty } from 'lodash';
import { useMemo, useRef } from 'react';
import { createSearchParams } from 'react-router-dom-latest';
import { Button, darken, Icon, IconButton, Stack } from '@common-components';
import { Add as AddIcon, Edit as EditIcon } from '@icons';
import { UserStatus } from 'enums';
import { RowsPerPage, usePagination } from 'hooks';
import { messages } from 'i18n';
import { User } from 'types';
import { userFullName } from 'utils';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import TypographyColumn from 'broker/components/common/TypographyColumn';
import DotsStepper from 'broker/components/DotsStepper';
import { MarketOnClick } from 'broker/components/UserMarkets/types';
import { AddAgentToAgencyMetadata } from 'broker/dialogs/AddAgentToAgencyModal/types';
import { UrlParam } from 'broker/dialogs/UserMarketDialog/utils';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { Agency } from './types';

interface AgencyTableItemExpandProps {
  filteredAgents: User[];
  agency: Agency;
  onOpenNewContact: (metaData: AddAgentToAgencyMetadata) => void;
  searchInput: string;
}
export default function AgencyTableItemExpand({ filteredAgents, searchInput, agency }: AgencyTableItemExpandProps) {
  const elementRef = useRef<HTMLElement | null>(null);
  const navigate = useNavigate();

  const openEditModal: MarketOnClick = ({ id }) => {
    navigate(
      {
        pathname: BROKER_NESTED_ROUTES.AGENCIES.AGENT,
        search: `${createSearchParams([[UrlParam.Id, id]])}`,
      },
      {
        routeKey: RouteKey.Agent,
        state: { organizationId: agency.id, organizationName: agency.name },
      },
    );
  };

  const addAgent = () => {
    navigate(
      {
        pathname: BROKER_NESTED_ROUTES.AGENCIES.AGENT,
      },
      {
        routeKey: RouteKey.Agent,
        state: { organizationId: agency.id, organizationName: agency.name },
      },
    );
  };

  const { page, setPage, rowsPerPage } = usePagination({ defaultRowsPerPage: RowsPerPage.Five });

  const agentsSlice = useMemo(
    () => filteredAgents.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage),
    [filteredAgents, page, rowsPerPage],
  );

  const numberOfSteps = Math.ceil(filteredAgents.length / rowsPerPage);
  return (
    <Stack ref={elementRef} bgcolor="blueGrey.50" width={1}>
      {agentsSlice.map((agent) => (
        <Stack
          key={agent.id}
          direction="row"
          width={1}
          height={52}
          pl={4}
          gap={3}
          sx={(theme) => ({
            ':not(:last-child)': {
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
            ':hover': {
              bgcolor: darken(theme.palette.blueGrey['50'] as string, 0.06),
            },
          })}
        >
          <TypographyColumn
            width={220}
            variant={agent?.status === UserStatus.PendingReview ? 'body2Bold' : 'body2'}
            color={agent?.status === UserStatus.PendingReview ? 'error.main' : 'inherit'}
            searchInput={searchInput}
            textToHighlight={isEmpty(userFullName(agent)) ? messages.agenciesPage.unknownSender : userFullName(agent)}
          />

          <TypographyColumn width={250} searchInput={searchInput} textToHighlight={agent.email} />
          <TypographyColumn width={250} searchInput={searchInput} textToHighlight={agent.phoneNumber || ''} />
          <Stack flex={1} justifyContent="center">
            <TypographyColumn width={140} searchInput={searchInput} textToHighlight={agent.title || ''} />
          </Stack>
          <Stack flex="0 0 60px" direction="row" alignItems="center">
            <IconButton
              onClick={() => openEditModal({ id: agent.id })}
              icon={EditIcon}
              size="small"
              variant="text"
              color="secondary"
            />
          </Stack>
        </Stack>
      ))}
      <Stack direction="row" justifyContent="space-between" height={52} gap={1.25}>
        <Stack flex={1} direction="row" alignItems="center">
          <Button onClick={addAgent} startIcon={<Icon component={AddIcon} />}>
            {messages.agenciesPage.addAgent}
          </Button>
        </Stack>
        {}
        {numberOfSteps > 1 && (
          <Stack margin="0 auto" flex={1} maxWidth="max-content">
            <DotsStepper setActiveStep={setPage} activeStep={page} numberOfSteps={numberOfSteps} />
          </Stack>
        )}
        <Stack flex={1} />
      </Stack>
    </Stack>
  );
}
