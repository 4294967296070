import { useNavigate, useParams } from 'react-router-dom-latest';
import { Checkbox, FormControlLabel, Grid, Paper } from '@common-components';
import { useMutateSubmission, useRetrieveSubmission, useToast } from 'hooks';
import { PartialSubmission } from 'types';
import { getCoverageLinesText, getDisplayDateWithHoursAmPm } from 'utils';
import CardTextField from 'components/CardTextField';
import DetailCard from 'components/DetailCard';
import ADMIN_ROUTES from 'admin/admin-routes';
import LayerList from 'admin/components/LayerList';

export default function SubmissionPage() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { id: submissionId } = useParams<{ id: string }>();
  const { data: submission } = useRetrieveSubmission({ id: submissionId!, enabled: !!submissionId });
  const { updateSubmission } = useMutateSubmission();

  // Canceling invalidation upon delete to prevent an attempt to refetch the submission before navigating away
  const { deleteSubmission } = useMutateSubmission({ cancelInvalidation: true });

  const onDelete = async () => {
    await deleteSubmission.mutateAsync({ id: submission!.id });
    navigate(ADMIN_ROUTES.SUBMISSION);
  };

  const setField = async (fieldName: keyof PartialSubmission, value: string | boolean) => {
    await updateSubmission.mutateAsync({
      id: submission!.id,
      data: {
        [fieldName]: value,
      },
    });

    showToast('success', { message: 'Submission updated successfully' });
  };

  if (!submission) {
    return null;
  }

  return (
    <Grid sx={{ m: 1 }} container wrap="wrap" spacing={3}>
      <Grid item xs={12} sm={6} lg={4}>
        <DetailCard
          avatar="T"
          onDelete={onDelete}
          title={
            submission.insuredName
              ? `${submission.insuredName} - ${getCoverageLinesText(submission.coverageLines)}`
              : ''
          }
        >
          <CardTextField
            label="Organization"
            to={ADMIN_ROUTES.getOrganizationById(submission.organizationId)}
            value={submission.organizationName}
          />
          <CardTextField
            label="Team"
            to={submission.team?.id ? ADMIN_ROUTES.getTeamById(submission.team.id) : undefined}
            value={submission.team?.name || '-'}
          />
          <CardTextField
            label="Assigned To"
            to={submission.assignee ? ADMIN_ROUTES.getUserById(submission.assignee.id) : undefined}
            value={submission.assignee?.email ?? 'Unassigned'}
          />
          <CardTextField label="Creation Date" value={getDisplayDateWithHoursAmPm(submission.createdAt)} />

          <CardTextField
            label="Insured Name"
            value={submission.insuredName || ''}
            onChange={(value) => setField('insuredName', value)}
          />
          <CardTextField
            label="Limit"
            value={submission.limit?.toString() || ''}
            onChange={(value) => setField('limit', value)}
          />
          <FormControlLabel
            label="Is demo submission?"
            control={
              <Checkbox
                disableRipple
                checked={submission.isDemo}
                onChange={({ target }) => setField('isDemo', target.checked)}
              />
            }
          />
        </DetailCard>
      </Grid>
      <Grid item xs={12} sm={8} lg={8}>
        <Paper>
          <LayerList submission={submission} />
        </Paper>
      </Grid>
    </Grid>
  );
}
