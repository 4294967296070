import { uniq } from 'lodash';
import { EmailTemplateType } from 'enums';
import { messages } from 'i18n';
import { Quote } from 'types';
import EmailEditor from 'broker/components/EmailEditor';
import EmailEditorFooter from 'broker/components/EmailEditor/EmailEditorFooter';
import { PostEmailInformation } from 'broker/components/EmailEditor/types';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import {
  QuoteStatusRequestLocationState,
  QuoteStatusType,
} from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/SendQuoteStatusRequest/types';
import { useGetQuoteStatusEmailProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/SendQuoteStatusRequest/useGetQuoteStatusEmailProps';
import SubmissionEmailEditor from 'broker/pages/SubmissionWorkspacePage/components/SubmissionEmailEditor';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

interface SendQuoteStatusNewEmailProps {
  emailRecipientsQuotes: Quote[];
  locationState: QuoteStatusRequestLocationState;
}

const { submissionWorkspace } = messages;

export default function SendQuoteStatusNewEmail({
  locationState: { type },
  emailRecipientsQuotes,
}: SendQuoteStatusNewEmailProps) {
  const dialogMessages =
    type === QuoteStatusType.Bind
      ? submissionWorkspace.dialogs.quoteStatusRequest.bind
      : submissionWorkspace.dialogs.quoteStatusRequest.policy;
  const { logEmailActivities } = useSubmissionsWorkspace();
  const { emailQuotesFiles, selectedMarketRecipientGroups } = useGetQuoteStatusEmailProps(emailRecipientsQuotes, type);
  const onQuoteStatusEmailSent: (postEmailInformation: PostEmailInformation) => Promise<void> | void = async ({
    submissionMarketIdToEmailId,
    emailTemplateType,
  }) => {
    await logEmailActivities(
      emailTemplateType,
      Object.keys(submissionMarketIdToEmailId).map((submissionMarketId) => ({
        submissionMarketId,
        submissionEmailId: submissionMarketIdToEmailId[submissionMarketId],
      })),
    );
  };

  return (
    <SubmissionEmailEditor
      emailEditorProps={{
        preSelectedTemplateType:
          type === QuoteStatusType.Bind ? EmailTemplateType.BindOrder : EmailTemplateType.PolicyOrder,
        preSelectedFileIds: uniq(emailQuotesFiles.flatMap((file) => file)),
        preSelectedRecipients: selectedMarketRecipientGroups,
        postEmailsSent: onQuoteStatusEmailSent,
      }}
    >
      <InnerPageLayout
        className="cap-quote-status-request"
        title={dialogMessages.headerTitle}
        subTitle=""
        footer={(innerPageProps) => <EmailEditorFooter {...innerPageProps} />}
      >
        {() => <EmailEditor />}
      </InnerPageLayout>
    </SubmissionEmailEditor>
  );
}
