import { EmailTemplateClient } from 'clients';
import { QueryKeys } from 'enums';
import { EmailTemplate, EmailTemplateCreate } from 'types';
import { useCurrentUser } from './currentUser';
import { UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useQueryRetrieve, useQuerySearch } from './query/useQuery';

export function useSearchEmailTemplate(props?: UseQueryBaseSearchOptions<EmailTemplate>) {
  const { filter, enabled, queryOptions, sorter } = props || {};
  const { me } = useCurrentUser();

  return useQuerySearch({
    queryKey: QueryKeys.EmailTemplates,
    clientClass: EmailTemplateClient,
    filter: { userId: me?.endUser?.id ?? me?.id, ...filter },
    sorter: sorter ?? ((a, b) => a.name.localeCompare(b.name)),
    enabled,
    queryOptions: {
      ...queryOptions,
    },
  });
}

export function useRetrieveEmailTemplate(props: UseQueryBaseRetrieveOptions<EmailTemplate>) {
  const { id, queryOptions, enabled } = props;
  return useQueryRetrieve({
    queryKey: QueryKeys.EmailTemplates,
    clientClass: EmailTemplateClient,
    id,
    enabled,
    queryOptions: {
      ...queryOptions,
    },
  });
}

export function useMutateEmailTemplate() {
  const { update: updateEmailTemplate, create: createEmailTemplate } = useMutate<
    EmailTemplate,
    EmailTemplate,
    EmailTemplateCreate,
    EmailTemplateClient
  >({
    queryKey: QueryKeys.EmailTemplates,
    clientClass: EmailTemplateClient,
    createOptions: { throwOnError: true },
    updateOptions: {
      throwOnError: true,
    },
  });

  return { updateEmailTemplate, createEmailTemplate };
}
