import { QuoteFileIdType } from 'enums';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import RoutedInlineDialog from 'broker/components/RoutedInlineDialog';
// The below eslint-disable are due to the fact that the email editor highly depends on the submission workspace page,
// but some parts of it might be relevant to use elsewhere. This should be addressed in CAP-3264
// In this case, the modal could potentially move to the workspace folder and only rendered in the SubmissionEmailEditor,
// but this will leave the EmailEditor with internal components that navigate to this modal and depend on it being rendered.
// eslint-disable-next-line import/no-restricted-paths
import Quote from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote';

export default function EmailEditorQuoteModal() {
  return (
    <RoutedInlineDialog className="cap-contained-quote-form" path={`${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE}/*`}>
      <Quote fileType={QuoteFileIdType.MarketQuote} resetFilesExplorerSelectionOnUnMount={false} />
    </RoutedInlineDialog>
  );
}
