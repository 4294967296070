import { useEffect, useState } from 'react';
import { CancelActionButton } from './components/BulkActionButtons/CancelActionButton';
import { CompareQuotesActionButton } from './components/BulkActionButtons/CompareQuotesActionButton';
import { BulkActionsMode, SelectionActionButtonBaseProps } from './types';

interface useBulkActionsProps {
  bulkSubmitActions: ((props: SelectionActionButtonBaseProps) => JSX.Element)[];
  comparisonExpiringPolicyFileId?: string;
  onCancel: () => void;
}

export default function useBulkActionState({
  bulkSubmitActions,
  comparisonExpiringPolicyFileId,
  onCancel,
}: useBulkActionsProps) {
  const [bulkActionMode, setBulkActionMode] = useState<BulkActionsMode>();
  const [bulkActionButtons, setBulkActionButtons] = useState<
    ((props: SelectionActionButtonBaseProps) => JSX.Element)[]
  >([]);

  useEffect(() => {
    switch (bulkActionMode) {
      case BulkActionsMode.Submit:
        setBulkActionButtons(bulkSubmitActions);
        break;
      case BulkActionsMode.CompareQuotes:
        setBulkActionButtons([
          (props) => (
            <CompareQuotesActionButton {...props} comparisonExpiringPolicyFileId={comparisonExpiringPolicyFileId} />
          ),
          (props) => (
            <CancelActionButton
              {...props}
              onCancelling={() => {
                setBulkActionMode(undefined);
                onCancel();
              }}
            />
          ),
        ]);
        break;
      default:
        setBulkActionButtons([]);
        break;
    }
  }, [bulkActionMode, bulkSubmitActions, comparisonExpiringPolicyFileId, onCancel]);

  return {
    bulkActionButtons,
    setBulkActionMode,
    bulkActionMode,
  };
}
