import { useCallback } from 'react';
import { Stack } from '@common-components';
import { useGetParam } from 'hooks';
import { BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';
import { useToolboxExpanded } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/useToolboxExpanded';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import EmailsList from './EmailsList';
import { EmailDescriptor } from './utils';

export interface EmailProps {
  hideEmailActions?: boolean;
}
export default function Email({ hideEmailActions = false }: EmailProps) {
  const { setActiveOverlayItem } = useUiStoreActions();
  const { toolboxOverlay } = useUiStoreState();
  const updateQueryParam = useUpdateQueryParam();
  const selectedEmailUrlParam = useGetParam(BrokerUrlParams.SELECTED_EMAIL);
  const handleEmailSelected = useCallback(
    (email: EmailDescriptor, auto: boolean = false) => {
      if (!auto) {
        updateQueryParam({ addParams: [{ queryParam: BrokerUrlParams.SELECTED_EMAIL, value: email?.id }] });
      }
      setActiveOverlayItem({ selectedEmailView: { emailId: email?.id, emailType: email?.type } });
    },
    [updateQueryParam, setActiveOverlayItem],
  );

  useToolboxExpanded(!!toolboxOverlay?.selectedEmailView && !!selectedEmailUrlParam);

  return (
    <Stack flex={1} sx={{ position: 'relative', overflowY: 'hidden', overflowX: 'hidden' }}>
      <EmailsList
        selectedEmailId={toolboxOverlay.selectedEmailView?.emailId}
        hideEmailActions={hideEmailActions}
        onEmailSelected={handleEmailSelected}
      />
    </Stack>
  );
}
