import { CustomSwitch } from '@common-components';
import { messages } from 'i18n';

// Define the props interface for TypeScript
interface EmailReminderToggleProps {
  checked: boolean;
  onChange: () => void;
}

export default function EmailReminderToggle({ checked, onChange }: EmailReminderToggleProps) {
  return (
    <CustomSwitch
      checked={checked}
      onChange={onChange}
      onTooltipContent={messages.emailEditor.createFollowUpReminder}
      offTooltipContent={messages.emailEditor.noFollowUpReminder}
      onIconCode="m22 5.72-4.6-3.86-1.29 1.53 4.6 3.86zM7.88 3.39 6.6 1.86 2 5.71l1.29 1.53zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9m0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7m-1.46-5.47L8.41 12.4l-1.06 1.06 3.18 3.18 6-6-1.06-1.06z"
      offIconCode="M12 6c3.87 0 7 3.13 7 7 0 .84-.16 1.65-.43 2.4l1.52 1.52c.58-1.19.91-2.51.91-3.92 0-4.97-4.03-9-9-9-1.41 0-2.73.33-3.92.91L9.6 6.43C10.35 6.16 11.16 6 12 6m10-.28-4.6-3.86-1.29 1.53 4.6 3.86zM2.92 2.29 1.65 3.57 2.98 4.9l-1.11.93 1.42 1.42 1.11-.94.8.8C3.83 8.69 3 10.75 3 13c0 4.97 4.02 9 9 9 2.25 0 4.31-.83 5.89-2.2l2.2 2.2 1.27-1.27L3.89 3.27zm13.55 16.1C15.26 19.39 13.7 20 12 20c-3.87 0-7-3.13-7-7 0-1.7.61-3.26 1.61-4.47zM8.02 3.28 6.6 1.86l-.86.71 1.42 1.42z"
    />
  );
}
