import { Box } from '@common-components';
import { messages } from 'i18n';
import InformationTooltip from 'broker/components/InformationTooltip/InformationTooltip';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

interface PasteInformationTooltipProps {
  onHide: () => void;
}
export default function PasteInformationTooltip({ onHide }: PasteInformationTooltipProps) {
  const { submission } = useSubmissionsWorkspace();

  return (
    <Box
      sx={{
        position: 'absolute',
        left: '-5px',
        top: '25px',
        backgroundColor: 'primary.main',
      }}
    >
      <InformationTooltip
        title={`${messages.submissionWorkspace.customerSection.pasteTooltip.title}`}
        message={messages.submissionWorkspace.customerSection.pasteTooltip.content}
        secondaryMessage=""
        isOpen={!submission?.isCopilotEnabled}
        height={125}
        placement="top-start"
        width={250}
        onClose={onHide}
      />
    </Box>
  );
}
