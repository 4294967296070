import {
  AICreateThreadOutput,
  BinderPolicyComparisonInput,
  ExtractQuoteData,
  OffsetLimitPagination,
  Quote,
  QuoteBinderComparisonInput,
  QuoteComparisonInput,
  QuoteInsightsInput,
  QuotePolicyComparisonInput,
} from 'types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export type QuoteQuery = OffsetLimitPagination;
export class QuoteClient extends CapitolaClient<Quote> {
  public get resourceName(): string {
    return 'quote';
  }

  public async compareQuotes({
    quoteIds,
    expiringPolicyFileId,
  }: QuoteComparisonInput): Promise<AICreateThreadOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'compare',
      body: {
        quoteIds,
        expiringPolicyFileId,
      },
    });
  }

  public async extractQuoteData({ submissionId, fileId }: ExtractQuoteData): Promise<void | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'extract',
      body: {
        submissionId,
        fileId,
      },
    });
  }

  public async extractPolicyData({ submissionId, fileId }: ExtractQuoteData): Promise<void | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'extract-policy',
      body: {
        submissionId,
        fileId,
      },
    });
  }

  public async compareQuoteBinder({
    quoteId,
    binderIds,
  }: QuoteBinderComparisonInput): Promise<AICreateThreadOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'compare-quote-binder',
      body: {
        quoteId,
        binderIds,
      },
    });
  }

  public async compareBinderPolicy({
    quoteId,
    policyIds,
  }: BinderPolicyComparisonInput): Promise<AICreateThreadOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'compare-binder-policy',
      body: {
        quoteId,
        policyIds,
      },
    });
  }

  public async compareQuotePolicy({
    quoteId,
    policyIds,
  }: QuotePolicyComparisonInput): Promise<AICreateThreadOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'compare-quote-policy',
      body: {
        quoteId,
        policyIds,
      },
    });
  }

  public async getInsights({ fileIds, submissionId }: QuoteInsightsInput): Promise<AICreateThreadOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'insights',
      body: {
        fileIds,
        submissionId,
      },
    });
  }
}
