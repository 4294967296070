import { Avatar, Stack } from '@common-components';
import { portalWorkspaceToolbarHeight } from 'themes';
import { PartialSubmission } from 'types';
import SubmissionTitle from './SubmissionTitle';

interface HeaderProps {
  partialSubmission: PartialSubmission;
}

export default function Header({ partialSubmission }: HeaderProps) {
  return (
    <Stack
      bgcolor="common.white"
      px={4}
      width={1}
      gap={1}
      height={portalWorkspaceToolbarHeight}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="column" flex={1} overflow="auto">
        <SubmissionTitle submission={partialSubmission} />
      </Stack>
      <Stack />
      <Stack width={1} flex={1} alignItems="flex-end">
        <Avatar
          sx={{
            textTransform: 'uppercase',
          }}
        >
          {partialSubmission.assignee?.firstName.slice(0, 2)}
        </Avatar>
      </Stack>
    </Stack>
  );
}
