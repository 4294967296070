import { User } from 'types';
import RoutedDialog from 'broker/components/RoutedDialog';
import { RouteKey } from 'broker/enums';
import Content from './Content';

interface AddAgentToAgencyModalProps {
  onAgentCreated?: (user: User) => void;
  isLocationStateRequired: boolean;
}

export default function AddAgentToAgencyModal({
  isLocationStateRequired = true,
  ...addAgentDialogProps
}: AddAgentToAgencyModalProps) {
  return (
    <RoutedDialog ariaLabelId="agent-modal" routeKey={RouteKey.Agent} isLocationStateRequired={isLocationStateRequired}>
      {(props) => <Content {...props} {...addAgentDialogProps} />}
    </RoutedDialog>
  );
}
