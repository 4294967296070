import { messages } from 'i18n';
import { SelectionActionButtonBaseProps } from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/types';
import BulkActionButton from './BulkActionButton';

interface CancelActionButtonProps extends SelectionActionButtonBaseProps {
  onCancelling: () => void;
}

export function CancelActionButton({ onCancelling, ...bulkActionButtonProps }: CancelActionButtonProps) {
  return (
    <BulkActionButton
      multipleSelection
      alwaysEnabled
      onClick={onCancelling}
      {...bulkActionButtonProps}
      variant="outlined"
    >
      {messages.buttons.cancel}
    </BulkActionButton>
  );
}
