import * as yup from 'yup';
import { FormFieldsConfigProps } from 'hooks';
import { messages } from 'i18n';

export interface FeedbackFormData {
  feedback: string;
}

export const schema = yup.object().shape({
  feedback: yup.string().trim(),
});

export enum FormFieldsNames {
  Feedback = 'feedback',
}

type FormFieldsConfig = Record<FormFieldsNames, FormFieldsConfigProps<FormFieldsNames>>;

export const formFieldsConfig: FormFieldsConfig = {
  [FormFieldsNames.Feedback]: {
    label: '',
    placeholder: messages.submissionWorkspace.aiGeneratedContentPopup.feedback.form.placeholder,
    name: FormFieldsNames.Feedback,
    id: FormFieldsNames.Feedback,
  },
};
