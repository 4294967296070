export enum InformationTooltipType {
  EmailAttachments = 'EmailAttachments',
  QuoteFiles = 'QuoteFiles',
  ProposalAttachments = 'ProposalAttachments',
  SmartReports = 'SmartReports',
  UploadFiles = 'UploadFiles',
}

export type InformationTooltipState = Record<InformationTooltipType, boolean>;

export type InformationTooltipPayload = {
  type: InformationTooltipType;
  isShown: boolean;
};

export type ActionTypes = { type: 'SET_INFORMATION_TOOLTIP'; payload: InformationTooltipPayload };

export interface InformationTooltipStoreActions {
  setInformationTooltip: (payload: InformationTooltipPayload) => void;
}
