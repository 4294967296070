import { ChangeEvent } from 'react';
import { Button, Stack } from '@common-components';
import { messages } from 'i18n';

interface FooterProps {
  isUploading: boolean;
  handleFilesAdded: (e: ChangeEvent<HTMLInputElement>) => void;
  uploadFiles: () => void;
}

export default function Footer({ isUploading, handleFilesAdded, uploadFiles }: FooterProps) {
  return (
    <Stack direction="row" justifyContent="flex-end" gap={1} p={2}>
      <Button variant="outlined" component="label" disabled={isUploading}>
        {messages.filesUploader.selectMore}
        <input type="file" hidden multiple onChange={handleFilesAdded} />
      </Button>
      <Button onClick={uploadFiles} variant="contained" loading={isUploading}>
        {messages.filesUploader.upload}
      </Button>
    </Stack>
  );
}
