import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { createSearchParams, NavigateOptions, useNavigate, useSearchParams } from 'react-router-dom-latest';
import { useAppStoreState } from 'contexts/app-store/AppStoreProvider';
import { useLocation } from './useLocation';

interface NavigateBackOptions {
  navigationOptions?: NavigateOptions;
}

export function useNavigateBack() {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { stickySearchParams } = useAppStoreState();

  return useCallback(
    ({ navigationOptions }: NavigateBackOptions = { navigationOptions: {} }) => {
      if (!location.state?.routeKey) {
        navigate('./..', navigationOptions);
        return;
      }
      const currentRouteKey = location.state.routeKey;

      if (!location.state[currentRouteKey]?.backToRouteKey) {
        navigate('./..', navigationOptions);
        return;
      }
      const to = location.state[currentRouteKey]?.backTo;

      const localTo = typeof to === 'object' ? to : { pathname: to };

      const nextRouteKey = location.state[currentRouteKey]!.backToRouteKey;
      const newState = { ...location.state, routeKey: nextRouteKey };
      delete newState[currentRouteKey];

      const urlParams = new URLSearchParams(localTo.search);
      const search = Object.fromEntries(urlParams);

      stickySearchParams.forEach((key) => {
        if (!searchParams.get(key)) {
          delete search[key];
        } else {
          search[key] = searchParams.get(key)!;
        }
      });

      if (!to) {
        navigate({ pathname: './..', search: `${createSearchParams(search)}` }, navigationOptions);
        return;
      }

      // console.log('GoBack', newState);
      navigate({ ...localTo, search: `${createSearchParams(search)}` }, { ...navigationOptions, state: newState });
    },
    [location.state, navigate, searchParams, stickySearchParams],
  );
}
