import { ActionTypes, AppStoreState } from './types';

const appStoreReducer = (state: AppStoreState, action: ActionTypes) => {
  switch (action.type) {
    case 'SET_STICKY_SEARCH_PARAMS': {
      return {
        ...state,
        stickySearchParams: action.payload,
      };
    }
    default:
      return state;
  }
};

export default appStoreReducer;
