import { EmailTemplateVariables } from '@common/email-variables';
import { BoxItem } from 'box-ui-elements/es';
import { isEmpty } from 'lodash';
import { ReactElement, useMemo } from 'react';
import { Box, Divider, InputAdornment, Stack, TextField, Typography } from '@common-components';
import { EmailTemplateType } from 'enums';
import { useRefFunction } from 'hooks';
import { messages } from 'i18n';
import { Contact, Submission, User } from 'types';
import { defaultEditorFontSizeStringPx } from 'components/Editor/consts';
import { Editor } from 'components/Editor/Editor';
import { toMentionTagString } from 'components/Editor/utils/mentions';
import SingleContactRecipientTag from 'broker/components/EmailEditor/components/SingleContactRecipientTag';
import { EmailTemplateContext } from 'broker/components/EmailEditor/store/types';
import useGetContacts from 'broker/components/EmailEditor/useGetContacts';
import { EmailIntegrationBanner } from 'broker/components/Emails/EmailIntegrationBanner';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';
import { useIsIntegrationEnabled } from 'broker/components/Emails/useIsIntegrationEnabled';
import TeammatesCcGroupContacts from 'broker/components/TeammatesCcGroupContacts';
import CreateCustomerContactModal from 'broker/dialogs/CreateCustomerContactModal';
import { useCopySmartReportHandler } from 'broker/pages/SubmissionWorkspacePage/hooks/useCopySmartReportHandler';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import { appendAttachments } from 'broker/utils/email-utils/email-resolver-utils/email-resolving-utils';
import { useSmartObjectEditHandler } from 'broker/utils/email-utils/use-smart-object-edit-handler';
import PasteInformationTooltip from './PasteInformationTooltip';
import { ProposalAttachmentsInfoTooltip } from './PropsalAttachmentsInfoTooltip';
import { SmartReportsInfoTooltip } from './SmartReports/SmartReportsInfoTooltip';
import { TemplateVariablesResolver } from './template-variables-resolver';

export interface ProposalSectionProps {
  recipientGroup: RecipientGroup;
  availableContacts?: Contact[];
  newContacts: Contact[];
  onToggleContact: (contact: Contact) => void;
  onContactCreated: (contact: Contact | null) => void;
  onClearNewContacts: () => void;
  content: string;
  proposalInitialValue: string;
  setContent: (content: string) => void;
  subject: string;
  setSubject: (subject: string) => void;
  isPreviewMode: boolean;
  variablesResolver: TemplateVariablesResolver;
  setIsDirty: (isDirty: boolean) => void;
  footer: ReactElement;
  openAttachFiles: () => void;
  attachedFiles?: BoxItem[];
  teammatesList: User[];
  selectedTeammates: User[];
  toggleTeammate: (value: User) => void;
  submission: Submission;
  handleSaveProposal: () => Promise<void>;
  templateVariablesResolver?: any;
  emailTemplateType: EmailTemplateType;
  templateContextData: EmailTemplateContext;
}

export const ProposalContent = ({
  recipientGroup,
  handleSaveProposal,
  availableContacts = [],
  onContactCreated,
  content,
  proposalInitialValue,
  setContent,
  subject,
  setSubject,
  isPreviewMode,
  variablesResolver,
  setIsDirty,
  footer,
  openAttachFiles,
  attachedFiles,
  teammatesList,
  selectedTeammates,
  toggleTeammate,
  submission,
  emailTemplateType,
  templateContextData,
}: ProposalSectionProps) => {
  const isIntegrationEnabled = useIsIntegrationEnabled();
  const { contacts, isContactsLoading } = useGetContacts();
  const { pasteSmartObjectIndicator } = useUiStoreState();
  const { handleCloseTooltip: hidePasteTooltip } = useCopySmartReportHandler();
  const smartObjectEditHandler = useSmartObjectEditHandler({ templateContextData });

  const onPastePreprocess = useRefFunction((_editor: any, args: any) => {
    args.content = variablesResolver.resolveContentForEditMode(args.content);
  });

  const resolvableTemplateVariables = useMemo(
    () =>
      Object.values(EmailTemplateVariables).filter(
        (variable) => !!variablesResolver.resolveVariable(variable as EmailTemplateVariables),
      ),
    [variablesResolver],
  );

  const resolveVariable = useRefFunction((variable) => toMentionTagString(variable));

  return (
    <>
      <Stack
        flex={1}
        border={1}
        borderColor={isPreviewMode ? 'grey.300' : 'common.black'}
        borderRadius={1}
        mt={2}
        mx={3}
        overflow="auto"
      >
        {isIntegrationEnabled && (
          <EmailIntegrationBanner submission={submission} emailTemplateType={emailTemplateType} />
        )}
        <Stack direction="row" flexWrap="wrap">
          <Stack direction="row" alignItems="baseline" columnGap={1} pt={1} px={1} flex={1}>
            <Typography
              variant="body2"
              color="textSecondary"
              mb={1}
            >{`${messages.submissionWorkspace.customerSection.to}:`}</Typography>
            <SingleContactRecipientTag recipientGroup={recipientGroup} />
            {/* !! once we will want to add option to add additional customer contacts to the proposal */}
            {/* <RecipientGroupTag */}
            {/*  recipientGroup={recipientGroup} */}
            {/*  availableContacts={availableContacts} */}
            {/*  onToggleContact={onToggleContact} */}
            {/*  newContacts={newContacts} */}
            {/*  onAddContact={() => */}
            {/*    navigate(BROKER_NESTED_ROUTES.MODALS.CREATE_CUSTOMER_CONTACT, { */}
            {/*      routeKey: RouteKey.CreateCustomerContact, */}
            {/*      state: { selectedOrganization: recipientGroup.id }, */}
            {/*    }) */}
            {/*  } */}
            {/*  onClearNewContacts={onClearNewContacts} */}
            {/* /> */}
          </Stack>

          <Stack direction="row" alignItems="baseline" columnGap={1} pt={1} px={1}>
            <TeammatesCcGroupContacts
              teammates={teammatesList}
              selectedTeammates={selectedTeammates}
              toggleTeammate={toggleTeammate}
            />
          </Stack>
        </Stack>
        <Divider />
        {isPreviewMode ? (
          <Stack direction="row" columnGap={1} p={1}>
            <Typography
              variant="body2"
              color="textSecondary"
            >{`${messages.submissionWorkspace.customerSection.subject}:`}</Typography>
            <Typography variant="body2" color="common.black">
              {variablesResolver.resolveSubjectVariables(subject)}
            </Typography>
          </Stack>
        ) : (
          <TextField
            id="subject"
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
            InputProps={{
              sx: { typography: 'body2' },
              readOnly: isPreviewMode,
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 2 }}>
                  <Typography variant="body2">{`${messages.submissionWorkspace.customerSection.subject}:`} </Typography>
                </InputAdornment>
              ),
            }}
            fullWidth
            multiline
            maxRows={3}
            variant="standard"
            sx={{ px: 1, py: 0.5 }}
          />
        )}
        <Divider />
        {isPreviewMode ? (
          <Box
            flex={1}
            overflow="auto"
            p={2}
            fontSize={defaultEditorFontSizeStringPx}
            dangerouslySetInnerHTML={{
              __html: appendAttachments(
                variablesResolver.resolveContentVariables(content, true),
                attachedFiles ?? [],
                null,
                true,
              ),
            }}
          />
        ) : (
          <Stack flex={1} position="relative">
            <ProposalAttachmentsInfoTooltip />
            <SmartReportsInfoTooltip />
            {pasteSmartObjectIndicator && <PasteInformationTooltip onHide={hidePasteTooltip} />}
            <Editor
              editorContent={content}
              clickHandler={smartObjectEditHandler}
              initialValue={proposalInitialValue}
              setEditorContent={setContent}
              onDirty={() => setIsDirty(true)}
              onPastePreprocess={onPastePreprocess}
              onFocus={() => hidePasteTooltip()}
              onBlur={
                // we want to save proposal after focusing out the editor
                // as we can make changes outside the editor like selecting an attachment
                // that action will trigger again a resolve of the template, so we need the template to be updated with the changes
                handleSaveProposal
              }
              editorFeatures={{
                mentionKeys: resolvableTemplateVariables,
                customButtons: [
                  {
                    name: 'attach',
                    icon: 'attach',
                    tooltip: messages.emailEditor.attachTooltip,
                    onAction: openAttachFiles,
                  },
                ],
                additionalContent: footer,
                resolveVariable,
              }}
            />
          </Stack>
        )}
      </Stack>
      <CreateCustomerContactModal
        contacts={isEmpty(availableContacts) ? contacts : availableContacts}
        contactsIsLoading={isEmpty(availableContacts) ? isContactsLoading : false}
        onContactCreated={onContactCreated}
        insuredName={submission.insuredName}
      />
    </>
  );
};
