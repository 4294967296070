import { compiler } from 'markdown-to-jsx';
import { renderToStaticMarkup } from 'react-dom/server';
import { markdownStyles } from './markdown-styles';

export interface ManipulateHtmlOptions {
  addTargetBlankToLink?: boolean;
}

export function manipulateHtml(html: string, options: ManipulateHtmlOptions = {}): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  if (options.addTargetBlankToLink) {
    const anchors = doc.querySelectorAll('a');
    anchors.forEach((anchor) => {
      anchor.setAttribute('target', '_blank');
    });
  }

  // Return the modified HTML as a string
  return doc.body.innerHTML;
}

export function stringToHtmlTag(input: string, tagName = 'div') {
  const div = document.createElement(tagName);
  div.innerHTML = input;

  return div;
}

export function htmlToPlain(html: string) {
  const div = stringToHtmlTag(html);

  return div.textContent || div.innerText || '';
}

export function stringToReact(input: string) {
  return <div dangerouslySetInnerHTML={{ __html: input }} />;
}

export function itemsToHtmlList(items: string[], title?: string) {
  return renderToStaticMarkup(
    <div>
      {title}
      <ul>
        {items.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>,
  );
}

// `compiler` is part of markdown-to-jsx - the same library we use for Markdown component
export function markdownToHtml(markdown: string) {
  return renderToStaticMarkup(
    compiler(markdown, {
      overrides: markdownStyles,
    }),
  );
}
