import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import { useLocation } from 'broker/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { LocationStateEntries } from 'broker/types';
import SendQuoteStatusNewEmail from './components/SendQuoteStatusNewEmail';
import { filterAttachmentQuotes } from './utils';

export default function SendQuoteStatusRequest() {
  const { submission } = useSubmissionsWorkspace();
  const location = useLocation();
  const state = location.state?.[RouteKey.QuoteStatusRequest] as
    | LocationStateEntries[RouteKey.QuoteStatusRequest]
    | undefined;

  const emailRecipientsQuotes = state?.recipientQuotes ? filterAttachmentQuotes(state.recipientQuotes, state.type) : [];

  if (!submission) {
    return null;
  }

  return (
    <WithRouteLocationState routeKey={RouteKey.QuoteStatusRequest} isStateRequired>
      {(locationState) => (
        <SendQuoteStatusNewEmail emailRecipientsQuotes={emailRecipientsQuotes} locationState={locationState} />
      )}
    </WithRouteLocationState>
  );
}
