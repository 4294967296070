import RoutedDialog from 'broker/components/RoutedDialog';
import { RouteKey } from 'broker/enums';
import Content from './components/Content';

export default function CompareQuotesModal() {
  return (
    <RoutedDialog
      routeKey={RouteKey.CompareQuotes}
      isLocationStateRequired
      className="cap-compare-quotes-modal"
      fullScreen
      sx={{ height: 1 }}
      muiPaperProps={{ sx: { bgcolor: 'common.white' } }}
      ariaLabelId="compare-quotes-modal"
    >
      {(props) => <Content {...props} />}
    </RoutedDialog>
  );
}
