import { Badge, Button, ButtonBase, Divider, Menu, Stack } from '@common-components';
import { FilterList as FilterListDownIcon } from '@icons';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { BaseFilterProps } from 'broker/components/common/Filter';
import AdditionalFilterToggle from './AdditionalFilterToggle';

interface AdditionalFiltersProps<T, S> {
  inSubmissionFilter: BaseFilterProps<T, S>;
  marketTypeFilter?: BaseFilterProps<T, S>;
}

export default function AdditionalFilters<T, S>({
  inSubmissionFilter,
  marketTypeFilter,
}: AdditionalFiltersProps<T, S>) {
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useOptionsMenu();

  return (
    <>
      <Badge
        variant="dot"
        invisible={marketTypeFilter?.isAllFilters}
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: 'green.500',
          },
        }}
      >
        <ButtonBase sx={{ height: 25, width: 25 }} onClick={openMenu}>
          <FilterListDownIcon fontSize="small" />
        </ButtonBase>
      </Badge>

      <Menu
        disableScrollLock
        disableEnforceFocus
        id="market-filters-menu-popover"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'market-filters-menu',
          sx: { py: 0 },
        }}
      >
        <Stack>
          <Stack maxHeight={250} width={350} p={2} gap={3} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <AdditionalFilterToggle
              handleFilterChange={inSubmissionFilter.handleFilterChange}
              filters={inSubmissionFilter.filters}
              isAllFilters={inSubmissionFilter.isAllFilters}
              handleAllSelected={inSubmissionFilter.handleAllSelected}
              title={messages.marketsPage.additionalFilters.submissionStatus.title}
            />
            {marketTypeFilter && (
              <AdditionalFilterToggle
                filters={marketTypeFilter.filters}
                handleFilterChange={marketTypeFilter.handleFilterChange}
                isAllFilters={marketTypeFilter.isAllFilters}
                handleAllSelected={marketTypeFilter.handleAllSelected}
                title={messages.marketsPage.additionalFilters.marketType.title}
              />
            )}
          </Stack>
        </Stack>

        <Stack>
          <Divider />
          <Stack direction="row" sx={{ p: 2 }} justifyContent="space-between" gap={2}>
            <Button
              onClick={() => {
                inSubmissionFilter.handleAllSelected();
                marketTypeFilter?.handleAllSelected();
                closeMenu();
              }}
              size="small"
              color="inherit"
            >
              {messages.buttons.clear}
            </Button>
            <Button onClick={closeMenu} size="small" variant="contained">
              {messages.buttons.done}
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </>
  );
}
