import { EmailMessageBatch, SubmissionEmailMessageBatchCreate } from 'types';
import { CapitolaClient } from './base';

export class EmailMessageBatchClient extends CapitolaClient<EmailMessageBatch> {
  public get resourceName(): string {
    return 'email-message-batch';
  }

  public async createBySubmission(body: SubmissionEmailMessageBatchCreate) {
    return this.create(body, {
      subPath: 'by-submission',
      throwOnError: true,
    });
  }
}
