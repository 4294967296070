import { fileTypeConfig } from '@common/config';
import { BoxItem } from 'box-ui-elements/es';
import { Box, Chip, Stack } from '@common-components';
import { messages } from 'i18n';
import { getDisplayDate, getDotSeparated, getFileType, uniteStyles } from 'utils';
import ListItemDescription from 'components/ListItemDescription';
import FileTypeIcon from 'broker/components/common/FileTypeIcon';

import { HOVER_STYLES } from './EmailListItem';

interface AttachmentProps {
  file: BoxItem;
  onClick: (file: BoxItem) => void;
}

export default function Attachment({ file, onClick }: AttachmentProps) {
  const fileType = getFileType(file);
  const fileTypeLabel = fileType && fileTypeConfig[fileType]?.text;
  const { lastModified } = messages.submissionWorkspace.emailsTab;

  const onFileClick = () => {
    onClick(file);
  };

  const subtitle = `${lastModified} ${getDotSeparated([
    getDisplayDate(file.modified_at ?? ''),
    file.modified_by?.name,
  ])}`;

  return (
    <Stack
      className="cap-attachment"
      direction="row"
      alignItems="center"
      flex={1}
      sx={uniteStyles(HOVER_STYLES, {
        cursor: 'pointer',
      })}
      onClick={onFileClick}
    >
      <Stack direction="row" px={3} py={2} flex={1} minWidth={0} alignItems="center">
        <FileTypeIcon fileNameOrExtension={file.name} />
        <Box ml={2} flex={1} minWidth={0}>
          <ListItemDescription title={file.name} titleTooltip={file.name} subtitle={subtitle} />
        </Box>
        {fileTypeLabel && <Chip size="small" label={fileTypeLabel} color="secondary" />}
      </Stack>
    </Stack>
  );
}
