import { AssistantStreamingEvent } from '@common/enums/assistant-streaming-event';
import { useCallback, useRef, useState } from 'react';
import { AIFeature } from 'enums';
import { ServerPushEvent, streamingText, useServerPushNotifications } from 'utils';
import { useUnsupportedFilesNotification } from './useUnsupportedFilesNotification';

/**
 * This hook is used as a React state for AI features response, that listens and updates the state (actual content)
 * with the server streaming messages.
 */
export const useStateWithServerStreaming = (feature: AIFeature): [string, boolean, () => void] => {
  const [value, setValue] = useState('');
  const isStreaming = useRef<boolean>(false);
  const activeStreams = useRef<Record<string, string>>({});

  const { showNotification } = useUnsupportedFilesNotification();

  const deltaMessageHandler = useCallback(
    ({ event, data }: ServerPushEvent) => {
      // When the server starts streaming a new message, it first sends a 'new-streamed-message' event
      // this event signals the frontend to start listening for the new message (for the specific context) and discard older streamed messages
      if (event === AssistantStreamingEvent.NewStreamedMessage) {
        activeStreams.current = {
          ...activeStreams.current,
          [data.context]: data.messageGuid,
        };
        return;
      }
      if (event === AssistantStreamingEvent.MessageRetry) {
        setValue('');
        isStreaming.current = false;
      }
      if (event === AssistantStreamingEvent.SkippedUnsupportedFile) {
        showNotification(data.message);
      }
      if (activeStreams.current[data.context] !== data.messageGuid) {
        return;
      }

      if (event === AssistantStreamingEvent.AssistantEvent) {
        if (data.message === 'thread.message.created') {
          // A new streaming is upon us...
          setValue('');
        } else if (data.message === 'thread.message.completed') {
          isStreaming.current = false;
        }
      }
      if (event === AssistantStreamingEvent.AssistantMessageDelta) {
        isStreaming.current = true;
        setValue((current) => streamingText(current, data.message));
      }
    },
    [showNotification],
  );

  const removeEventEmitterListener = useServerPushNotifications(deltaMessageHandler, feature);

  function stopStreaming() {
    isStreaming.current = false;
    removeEventEmitterListener();
    setValue('');
  }

  return [value, isStreaming.current, stopStreaming];
};
