import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { AIFeature } from 'enums';
import { useMatchNavigation } from 'hooks';
import { messages } from 'i18n';
import { QuoteComparisonInsightsRoutes } from 'broker/broker-routes';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { useQuoteFormMethods } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/form-methods/useQuoteFormMethods';
import { AIContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import Footer from './components/Footer';
import QuoteComparisonSteps from './components/QuoteComparisonSteps';
import { InsightPopulatedByAI } from './components/types';
import { PdfGenerationStatus, QuoteComparisonInsightsLocationState } from './types';

export interface QuoteComparisonInsightsContentProps {
  locationState: QuoteComparisonInsightsLocationState;
  setIsDirty: (isDirty: boolean) => void;
  isDirty: boolean;
}

export default function QuoteComparisonInsightsContent({
  locationState,
  isDirty,
  setIsDirty,
}: QuoteComparisonInsightsContentProps) {
  const { quotes, expiringPolicyFileId } = locationState;
  const [insightPopulatedByAI, setInsightPopulatedByAI] = useState<InsightPopulatedByAI | undefined>();
  const { methods } = useQuoteFormMethods(setIsDirty);
  const currentStep = useMatchNavigation(QuoteComparisonInsightsRoutes, QuoteComparisonInsightsRoutes.Compose);
  const [pdfGenerationStatus, setPdfGenerationStatus] = useState<PdfGenerationStatus>(PdfGenerationStatus.Idle);

  return (
    <AIContextProvider feature={AIFeature.QuoteCompare}>
      <FormProvider {...methods}>
        <InnerPageLayout
          className="cap-quotes-comparison-nested-view"
          title={messages.quoteComparisonPage.title}
          sx={{ p: 0 }}
          subTitle={messages.quoteComparisonPage.subTitle}
          footer={() => (
            <Footer
              isDirty={isDirty}
              setIsDirty={setIsDirty}
              currentStep={currentStep}
              setInsightPopulatedByAI={setInsightPopulatedByAI}
              quotes={quotes}
              insightPopulatedByAI={insightPopulatedByAI}
              pdfGenerationStatus={pdfGenerationStatus}
              setPdfGenerationStatus={setPdfGenerationStatus}
              expiringPolicyFileId={expiringPolicyFileId}
            />
          )}
        >
          {() => (
            <QuoteComparisonSteps
              isDirty={isDirty}
              setIsDirty={setIsDirty}
              setInsightPopulatedByAI={setInsightPopulatedByAI}
              quotes={quotes}
              pdfGenerationStatus={pdfGenerationStatus}
              setPdfGenerationStatus={setPdfGenerationStatus}
              expiringPolicyFileId={expiringPolicyFileId}
            />
          )}
        </InnerPageLayout>
      </FormProvider>
    </AIContextProvider>
  );
}
