import { MouseEvent, useMemo } from 'react';
import { EditorEvent } from 'tinymce';
import { BoxSkeleton, Stack } from '@common-components';
import { Editor } from 'components/Editor/Editor';
import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';
import { getRecipientBasedVariables } from 'broker/utils/email-utils/email-resolver-utils/template-variables-utils';
import { useSmartObjectEditHandler } from 'broker/utils/email-utils/use-smart-object-edit-handler';
import { BodyFieldStyle } from './styles';

export const BodyField = () => {
  const {
    isPending,
    draftEditorMode,
    emailType,
    activeDraft,
    recipients: { selectedRecipientGroupId },
    draftPreview: { body: previewBody },
    draftEditing: { body: editingBody },
    areEditButtonsVisible,
    templates: { templateContextData },
  } = useEmailEditorState();
  const smartObjectEditHandler = useSmartObjectEditHandler({ templateContextData });
  const {
    draftPreview: { setBody: setPreviewBody },
    draftEditing: { setBody: setEditingBody, setIsEditingContentDirty: setIsDirty },
    resolveVariable,
    showPreviewActions,
  } = useEmailEditorActions();

  const activeBody = draftEditorMode === DraftEditorMode.Preview ? previewBody : editingBody;
  const activeSetBody = draftEditorMode === DraftEditorMode.Preview ? setPreviewBody : setEditingBody;

  /**
   * Generates a key for the editor component so that it is re-rendered when the selected recipient group changes
   * */
  const getEditorHash = () => {
    if (!activeDraft) {
      return `${selectedRecipientGroupId}::${draftEditorMode}`;
    }

    const { chosenTemplateId, detachedTemplateInstance } = activeDraft;
    const templateIdentifier = detachedTemplateInstance ? 'detachedTemplateInstance' : chosenTemplateId;
    return `${selectedRecipientGroupId}::${templateIdentifier}::${draftEditorMode}`;
  };

  const editorHash = useMemo(getEditorHash, [activeDraft, draftEditorMode, selectedRecipientGroupId]);

  const templateVariablesSuggestions = getRecipientBasedVariables(emailType);
  const isPreviewMode = draftEditorMode === DraftEditorMode.Preview;

  const onDirty = () => {
    if (isPreviewMode) {
      return;
    }

    setIsDirty(true);
  };

  const editorClickHandler = (e: EditorEvent<MouseEvent>) => {
    if (isPreviewMode) {
      showPreviewActions();
    }
    smartObjectEditHandler(e);
  };

  return (
    <Stack sx={BodyFieldStyle}>
      {isPending ? (
        <BoxSkeleton isLoading sx={{ width: 1, height: 1 }} />
      ) : (
        <Editor
          key={editorHash}
          editorContent={activeBody}
          setEditorContent={activeSetBody}
          onDirty={onDirty}
          clickHandler={editorClickHandler}
          onFocus={isPreviewMode ? showPreviewActions : undefined}
          disabled={isPreviewMode && areEditButtonsVisible}
          editorStyleProps={isPreviewMode ? { isDisabled: true } : undefined}
          autoFocus={!isPreviewMode}
          toolbar={!isPreviewMode}
          editorFeatures={{
            mentionKeys: templateVariablesSuggestions,
            resolveVariable,
          }}
        />
      )}
    </Stack>
  );
};
