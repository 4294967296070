import * as commonTypes from '@common/types';
import { BoxItem } from 'box-ui-elements/es';
import { Editor } from 'tinymce';
import { EmailTemplateType, FileType, MarketRequestType, SubmissionMarketRequestStatus } from 'enums';
import {
  Contact,
  EmailTemplate,
  ExtendedMarketRequest,
  Layer,
  LoggedInUser,
  PartialSubmission,
  Quote,
  Sender,
  Submission,
  SubmissionMarket,
  SubmissionMarketCreate,
  SubmissionMarketRequest,
  User,
  UserMarket,
} from 'types';
import { RecipientsGroupsAvailableRecipients } from 'broker/components/EmailEditor/types';
import { RecipientGroup, RecipientGroups } from 'broker/components/Emails/recipient-utils';
import { Recipient } from 'broker/components/Emails/types';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';

import { AddContactToMarketMetadata } from 'broker/dialogs/AddContactToMarketModal/types';

export enum EmailType {
  Market = 'Market',
  Retailer = 'Retailer',
  UserNotification = 'UserNotification',
  ExternalUser = 'ExternalUser',
}

export interface EmailTemplateContext extends commonTypes.EmailTemplateContext {
  user: LoggedInUser;
  submission: PartialSubmission;
  submissionMarkets: SubmissionMarket[];
  extendedMarketRequests: ExtendedMarketRequest[];
  quotes: Quote[];
  // should be 1 of the 2, if all sent precedence for attachment-point resolving is layer,triggeringQuotes
  layer?: Layer;
  triggeringQuote?: Quote;
  triggeringMarket?: SubmissionMarket;
  recipient?: RecipientGroup;
  attachedFiles?: BoxItem[];
  layers?: Layer[];
  selectedTemplate?: EmailTemplateType;
  submissionMissingFiles?: string[];
}

export interface TemplateInstance {
  subject: string;
  body: string;
  from?: Sender;
}

export interface Draft {
  chosenTemplateId: string;
  detachedTemplateInstance?: TemplateInstance;
}

export type RecipientGroupToDraft = Record<string, Draft>;
export type TemplateInstances = Record<string, TemplateInstance>;

export interface SubmissionsWorkspaceState {
  markets: SubmissionMarket[];
  submission: PartialSubmission | null;
  quotes: Quote[];
  layers: Layer[];
}

export interface PreSelectedMarket {
  id: string;
  contactIds: string[];
}

export interface ResolvedMessage {
  body: string;
  subject: string;
}

export interface EmailEditorState {
  submissionsWorkspace: SubmissionsWorkspaceState;
  templates: {
    templates: EmailTemplate[];
    templatesLoading: boolean;
    templateContextData: Omit<EmailTemplateContext, 'recipient'>;
  };
  activeDraft: Draft | undefined;
  recipients: {
    recipientsGroupsAvailableRecipients: RecipientsGroupsAvailableRecipients;
    newRecipients: Recipient[];
    recipientGroups: RecipientGroups;
    recipientGroupsArray: RecipientGroup[];
    preSelectedRecipientGroups: RecipientGroup[] | undefined;
    selectedRecipientGroupId: string | undefined;
  };
  customerContacts: {
    isAddContactToMarketOpen: boolean;
    addContactToMarketMetadata: AddContactToMarketMetadata | undefined;
  };
  draftTemplates: {
    recipientGroupToDraft: Record<string, Draft>;
    templateInstances: TemplateInstances;
  };
  draftPreview: {
    subject: string;
    body: string;
  };
  draftEditing: {
    subject: string;
    body: string;
    from: Sender;
    isEditingContentDirty: boolean;
    isActiveTemplateInstanceDetached: boolean | undefined;
  };
  cc: { selectedTeammates: User[]; availableTeammates: User[] };
  attachedFiles: {
    attachedFiles: BoxItem[];
  };
  addTask?: boolean;
  marketRequestType?: MarketRequestType;
  preSelectedMarkets: PreSelectedMarket[];
  draftEditorMode: DraftEditorMode;
  // TODO: Remove after implementation of new email editor
  isPreviewMode: boolean;
  emailType: EmailType;
  isPending: boolean;
  isAnimating: undefined | boolean;
  isAnyEmailEmpty: boolean;
  preSelectedTemplateType?: EmailTemplateType | undefined;
  isSubmittingEmail: boolean;
  getWasEmailsSent: () => boolean;
  areEditButtonsVisible: boolean;
  isSingleRecipientMode: boolean;
  resolvedMessages: ResolvedMessage[];
  disabled?: boolean;
}

export interface SubmissionsWorkspaceActions {
  addSubmissionMarkets: (
    addedMarkets: SubmissionMarketCreate[],
    reFetchWorkspace?: boolean,
  ) => Promise<SubmissionMarket[]>;
  reFetchWorkspace: () => Promise<void>;
  updateSubmission: (id: string, data: Partial<Submission>, reFetchWorkspace?: boolean) => Promise<void>;
  addSubmissionMarketRequests: (
    marketRequests: {
      submissionMarketId: string;
      status: SubmissionMarketRequestStatus;
      layerId?: string;
    }[],
    reFetchWorkspace?: boolean,
  ) => Promise<SubmissionMarketRequest[]>;
}

export interface EmailEditorActions {
  submissionsWorkspace: SubmissionsWorkspaceActions;
  customerContacts: {
    closeAddContactToMarketModal: () => void;
    openAddNewContactToMarketDialog: (metaData: AddContactToMarketMetadata) => void;
  };
  recipients: {
    removeRecipientGroup: (groupId: string) => void;
    toggleRecipient: (groupId: string, toggledRecipient: Recipient, removeIfLast?: boolean) => void;
    clearNewRecipients: () => void;
    addRecipientGroups: (groupsToAdd: RecipientGroups, replaceMarkets?: boolean) => void;
    addRecipient: (recipient: Recipient, groupId: string) => void;
    setSelectedRecipientGroupId: (recipientGroupId: string | undefined) => void;
  };
  attachedFiles: {
    removeFile: (file: BoxItem) => void;
  };
  draftTemplates: {
    setRecipientGroupChosenTemplate: (recipientGroupId: string, templateId: string) => void;
    setRecipientGroupDetachedTemplateInstance: (recipientGroupId: string, templateInstance: TemplateInstance) => void;
    setTemplateInstance: (templateId: string, templateInstance: TemplateInstance) => void;
  };
  draftPreview: {
    setSubject: (subject: string) => void;
    setBody: (body: string) => void;
  };
  draftEditing: {
    setSubject: (subject: string) => void;
    setBody: (body: string, editor?: Editor) => void;
    setFrom: (sender: Sender | undefined) => void;
    setIsEditingContentDirty: (isDirty: boolean) => void;
    onEditForAllCancel: () => void;
    onEditForAllSave: () => void;
    resetDirtyState: () => void;
    onResetToTemplate: () => void;
  };
  cc: { toggleTeammateSelection: (user: User) => void };
  resolveVariable: (variable: string) => string;
  addCustomerContact: () => void;
  customerContactCreated: (newContact: Contact) => Promise<void>;
  addMarketContact: (userMarketId: string) => void;
  addMarkets: (addedMarkets: UserMarket[], replace?: boolean) => void;
  setDraftEditorMode: (draftEditorMode: DraftEditorMode) => void;
  // TODO: Delete after implementation of new email editor
  togglePreviewMode: () => void;
  toggleAddTask: () => void;
  showPreviewActions: () => void;
  hidePreviewActions: () => void;
  sendEmail: () => Promise<number>;
}

export const PRIMARY_MARKETING_TEMPLATE_FILE_TYPES_TO_HIGHLIGHT = [FileType.LossRuns, FileType.Submission];
export const XS_MARKETING_TEMPLATE_FILE_TYPES_TO_HIGHLIGHT = [
  FileType.MarketQuote,
  FileType.LossRuns,
  FileType.Submission,
];
