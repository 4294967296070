import { useCallback, useRef, useState } from 'react';
import { useBlockNavigation, useBoolean, useConfirmBrowserClose } from 'hooks';
import { messages } from 'i18n';

export enum NavigationBlock {
  None, // Do not block navigation
  BackOnly, // Block only browser back button (navigation to the previous route)
  All, // Block all route navigations
}

interface useDirtyContentGuardProps {
  onClose?: () => void;
  navigationBlock?: NavigationBlock;
}

export function useDirtyContentGuard({
  onClose,
  navigationBlock = NavigationBlock.All,
}: useDirtyContentGuardProps = {}) {
  const [isDirtyDialogOpen, { on: openDirtyDialog, off: closeDirtyDialog }] = useBoolean();
  const [isContentDirty, setIsContentDirty] = useState(false);
  const shouldUnblockNavigationRef = useRef(false);

  // Allow the caller to set the onClose function after initializing the hook
  const onCloseRef = useRef(onClose);

  // The guarded navigation function in case of blocking route navigations in dirty state
  const onNavigationRef = useRef<() => void>();

  const setOnClose = (newOnClose: () => void) => {
    onCloseRef.current = newOnClose;
  };

  const performGuardedActions = () => {
    onNavigationRef.current?.();
    onCloseRef.current?.();
  };

  const closeAttempt = useCallback(() => {
    if (isContentDirty) {
      openDirtyDialog();
    } else {
      performGuardedActions();
    }
  }, [isContentDirty, openDirtyDialog]);

  // Block closing the browser tab on dirty state
  useConfirmBrowserClose({ shouldConfirm: isContentDirty, confirmationMessage: messages.dirtyPageModal.subHeader });

  // Block navigation on dirty state, mostly used to handle browser back button
  useBlockNavigation({
    shouldBlock: isContentDirty && navigationBlock !== NavigationBlock.None,
    onBlock: (transition, unblockNavigation) => {
      if (
        shouldUnblockNavigationRef.current ||
        (navigationBlock === NavigationBlock.BackOnly && transition.action !== 'POP')
      ) {
        unblockNavigation();
        transition.retry();
        return;
      }

      // Override the close functionality with the attempted navigation in case the user chooses to discard the changes
      onNavigationRef.current = () => {
        unblockNavigation();
        transition.retry();
      };

      closeAttempt();
    },
  });

  // Used when the user chooses to discard the changes
  const closeWithoutPrompt = () => {
    // Release the navigation block before attempting the guarded actions
    shouldUnblockNavigationRef.current = true;

    setIsContentDirty(false);
    closeDirtyDialog();
    performGuardedActions();
  };

  // Used when the user wants to keep the changes and cancel the close attempt
  const onCloseDirtyDialog = () => {
    // Clear the transition attempt before closing the dirty dialog
    // so the next close attempt will not try to run the same transition again
    onNavigationRef.current = undefined;
    closeDirtyDialog();
  };

  return {
    closeWithoutPrompt,
    closeAttempt,
    setOnClose,
    isDirtyDialogOpen,
    setIsContentDirty,
    isContentDirty,
    closeDirtyDialog: onCloseDirtyDialog,
  };
}
