import { isSubmissionTerminated, SubmissionTerminationStatus } from 'enums';
import { useRetrieveUser } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { DisplayMonthAndDay, getDisplayDate, Nullable } from 'utils';
import { submissionTerminationReasonConfig } from 'broker/configuration-mappers/submission-termination-reason';

type useSubmissionTerminationReasonProps = {
  submission: Nullable<PartialSubmission>;
  reasonOnly?: boolean;
};

export function useSubmissionTerminationDetails({
  submission,
  reasonOnly = false,
}: useSubmissionTerminationReasonProps) {
  const { terminationDetails } = messages.submissionWorkspace.termination;

  const { data: user } = useRetrieveUser({
    id: submission?.archiveReason?.archivedBy!,
    enabled: !!submission?.archiveReason?.archivedBy && !reasonOnly,
  });

  const { archiveReason, status } = submission || {};

  if (!isSubmissionTerminated(status) || !archiveReason?.reason) {
    return '';
  }

  const reasonMessage = submissionTerminationReasonConfig[archiveReason.reason].message;
  const reasonDetails = archiveReason.details ? `, ${archiveReason?.details}` : '';

  const reason = `${reasonMessage}${reasonDetails}`;

  if (reasonOnly) {
    return reason;
  }

  const archiveDisplayDate = archiveReason.archivedAt
    ? getDisplayDate(archiveReason.archivedAt, DisplayMonthAndDay)
    : '';

  return terminationDetails(status as SubmissionTerminationStatus, reason, archiveDisplayDate, user);
}
