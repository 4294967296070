import { invert, uniqBy } from 'lodash';
import { useEffect, useRef } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { Grid, Typography } from '@common-components';
import { ContactRole, OrganizationType, UserRole } from 'enums';
import { useBoolean, useCurrentUser, useFormProvider, useMount, useMutateContact, useMutateOrganization } from 'hooks';
import { messages } from 'i18n';
import { Contact, ContactCreate, Organization } from 'types';
import { nameCompare } from 'utils/comparators';
import { DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import FormAutocompleteCreatable, {
  InnerValueSetter,
} from 'components/hookFormComponents/FormAutocomplete/FormAutocompleteCreatable';
import FormRadioButton from 'components/hookFormComponents/FormRadioButton';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import { CreateCustomerContactLocationState } from 'broker/dialogs/CreateCustomerContactModal/types';
import { FormData, FormFieldsNames, schema } from 'broker/dialogs/CreateCustomerContactModal/utils';
import { useEmailFormFieldValidation } from 'broker/hooks';

export interface ContentProps {
  contacts: Contact[];
  contactsIsLoading?: boolean;
  onContactCreated: (contact: Contact) => Promise<void> | void;
  insuredName?: string;
  locationState?: CreateCustomerContactLocationState;
}

const contactRoleToOrganizationType: { [item in ContactRole]?: OrganizationType } = {
  [ContactRole.Retailer]: OrganizationType.Retailer,
  [ContactRole.Insured]: OrganizationType.Insured,
};
const organizationTypeToContactType = invert(contactRoleToOrganizationType);

export default function Content({
  ariaLabelId,
  onClose,
  setDialogIsDirty,
  closeWithoutPrompt,
  contacts,
  contactsIsLoading = false,
  onContactCreated,
  insuredName,
  locationState,
}: ContentProps & BaseRoutedDialogContentProps) {
  const { methods } = useFormProvider<FormData>({ schema, setIsDirty: setDialogIsDirty });
  const { endUser } = useCurrentUser();

  const isRetailerEndUser = endUser?.role === UserRole.Retailer;

  const currentContactRole = methods.getValues('contactRole');

  const onOrganizationCreated = (organization: Organization | null) =>
    methods.setValue('organizationId', organization?.id ?? '');

  const { createContact } = useMutateContact();
  const { createOrganization } = useMutateOrganization();
  const ref = useRef<InnerValueSetter>(null);

  const organizations = uniqBy(
    contacts.map((contact) => ({
      name: contact.organizationName,
      id: contact.organizationId,
      organizationType: contactRoleToOrganizationType[contact.role],
    })),
    'id',
  ).sort(nameCompare);

  const organizationIds = organizations.map((organization) => organization.id);

  const [isSubmitting, { on: startSubmit, off: stopSubmit }] = useBoolean(false);

  const { validateEmailAddresses } = useEmailFormFieldValidation({ methods });

  const selectedOrganization = locationState?.selectedOrganization;

  const preSelectedOrganization = organizations.find((organization) => organization.id === selectedOrganization);

  useMount(() => {
    let initialValue: ContactRole = isRetailerEndUser ? ContactRole.Insured : ContactRole.Retailer;
    if (preSelectedOrganization) {
      initialValue = organizationTypeToContactType[preSelectedOrganization.organizationType!] as ContactRole;
    }
    methods.setValue('contactRole', initialValue);
  });

  useEffect(() => {
    if (preSelectedOrganization) {
      methods.setValue('organizationId', preSelectedOrganization?.id);
    } // If the customer type is insured we can try to pre-populate the company name
    else if (currentContactRole === ContactRole.Insured && insuredName) {
      const organization = organizations.find((org) => org.name === insuredName);
      if (organization) {
        methods.setValue('organizationId', organization.id);
      } else {
        ref.current?.setInnerValue(insuredName);
        methods.setValue('organizationId', insuredName);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContactRole, insuredName, preSelectedOrganization]);

  const getFormDataToSend = (data: FormData): ContactCreate => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    title: data.title,
    phoneNumber: data.phoneNumber,
    organizationId: data.organizationId,
    role: data.contactRole,
  });

  const mutateContact = async (contactData: ContactCreate) => {
    try {
      const newContact = await createContact.mutateAsync({ data: contactData });
      if (newContact) {
        await onContactCreated(newContact);
        closeWithoutPrompt();
      }
    } catch (e) {
      stopSubmit();
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    startSubmit();

    if (!(await validateEmailAddresses([data.email]))) {
      stopSubmit();
      return;
    }

    const contactData = getFormDataToSend(data);

    if (!organizationIds.includes(contactData.organizationId)) {
      // In this case organizationId holds a name rather than an ID
      const newOrg = await createOrganization.mutateAsync(
        {
          data: {
            name: contactData.organizationId,
            type: contactRoleToOrganizationType[contactData.role]!,
          },
        },
        { onSuccess: onOrganizationCreated },
      );

      if (newOrg) {
        contactData.organizationId = newOrg.id;
        await mutateContact(contactData);
      }
      stopSubmit();
    } else {
      await mutateContact(contactData);
      stopSubmit();
    }
  };

  return (
    <>
      <DialogHeader
        title={messages.createCustomerModal.getTitle(isRetailerEndUser)}
        id={ariaLabelId}
        onClose={onClose}
      />
      <DialogContent sx={{ mt: 0, px: 2, py: 1 }}>
        <Typography sx={{ mb: 3, mt: 2, color: 'common.black' }} variant="body1">
          {messages.createCustomerModal.getFormHeader(isRetailerEndUser)}
        </Typography>
        <FormProvider {...methods}>
          <Grid rowSpacing={1} columnSpacing={3} container>
            {!isRetailerEndUser && (
              <Grid item xs={12}>
                <FormRadioButton
                  label={messages.createCustomerModal.labels.customerType}
                  name={FormFieldsNames.ContactRole}
                  id={FormFieldsNames.ContactRole}
                  disabled={contactsIsLoading || !!preSelectedOrganization}
                  options={[
                    { label: messages.createCustomerModal.retailer, value: ContactRole.Retailer },
                    { label: messages.createCustomerModal.insured, value: ContactRole.Insured },
                  ]}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <FormTextField
                placeholder={messages.createCustomerModal.placeholders.firstName}
                label={messages.createCustomerModal.labels.firstName}
                name={FormFieldsNames.FirstName}
                id={FormFieldsNames.FirstName}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                placeholder={messages.createCustomerModal.placeholders.lastName}
                label={messages.createCustomerModal.labels.lastName}
                name={FormFieldsNames.LastName}
                id={FormFieldsNames.LastName}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormAutocompleteCreatable
                imperativeRef={ref}
                label={messages.createCustomerModal.labels.companyName}
                name={FormFieldsNames.OrganizationId}
                id={FormFieldsNames.OrganizationId}
                isLoading={contactsIsLoading}
                disabled={!!preSelectedOrganization}
                options={organizations
                  .filter(
                    (organization) =>
                      organization.organizationType ===
                      contactRoleToOrganizationType[methods.watch(FormFieldsNames.ContactRole)],
                  )
                  .map((organization) => ({
                    label: organization.name,
                    value: organization.id,
                  }))}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                placeholder={messages.createCustomerModal.placeholders.email}
                label={messages.createCustomerModal.labels.email}
                name={FormFieldsNames.Email}
                id={FormFieldsNames.Email}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                placeholder={messages.createCustomerModal.placeholders.phoneNumber}
                label={messages.createCustomerModal.labels.phoneNumber}
                name={FormFieldsNames.PhoneNumber}
                id={FormFieldsNames.PhoneNumber}
                optional
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                placeholder={messages.createCustomerModal.placeholders.title}
                label={messages.createCustomerModal.labels.title}
                name={FormFieldsNames.Title}
                id={FormFieldsNames.Title}
                optional
                fullWidth
              />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: messages.createCustomerModal.getProceedText(isRetailerEndUser),
          onClick: methods.handleSubmit(onSubmit),
          loading: isSubmitting,
        }}
        cancelButton={{
          children: messages.createCustomerModal.cancel,
          onClick: onClose,
        }}
      />
    </>
  );
}
