import { Layer } from 'types';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { QuoteLocationState } from './types';

export function useGetQuoteData(state: Pick<QuoteLocationState, 'layer' | 'selectedQuote' | 'mode'>) {
  const { layers } = useSubmissionsWorkspace();
  const { selectedQuote, layer } = state;

  let selectedLayer: Layer | undefined = layer;

  if (selectedQuote?.id) {
    selectedLayer = layers.find((layerItem) => layerItem.id === selectedQuote?.layerId);
  }

  return { selectedLayer };
}
