import { compact, flatten, isEmpty, union, uniq } from 'lodash';
import { matchSorter } from 'match-sorter';
import moment from 'moment';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { CoverageLine } from 'enums';
import { useBoolean, useSearchInsuranceProduct } from 'hooks';
import { messages } from 'i18n';
import { ExtendedContact, ExtendedUserMarket, UserMarket } from 'types';
import {
  contactFullName,
  coverageLineConfig,
  FilterElementProps,
  SearchInputProps,
  Sorters,
  updateCheckedFilter,
} from 'utils';
import { stringCompare } from 'utils/comparators';
import {
  marketOrganizationTypeConfig,
  marketOrganizationTypeFilterOptions,
  UserMarketSortTypes,
} from 'broker/components/UserMarkets/utils';
import { getExtendedContact } from 'broker/utils';

const inSubmissionFilterOptions = [
  messages.marketsPage.additionalFilters.submissionStatus.inSubmission,
  messages.marketsPage.additionalFilters.submissionStatus.notInSubmission,
];

export const sorters: Sorters<UserMarket, UserMarketSortTypes> = {
  [UserMarketSortTypes.Updated]: {
    sorter: (a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)),
    label: messages.marketsPage.sorter.updated,
  },
  [UserMarketSortTypes.Asc]: {
    sorter: (a, b) => a.marketName.localeCompare(b.marketName),
    label: messages.marketsPage.sorter.asc,
  },

  [UserMarketSortTypes.Desc]: {
    sorter: (a, b) => b.marketName.localeCompare(a.marketName),
    label: messages.marketsPage.sorter.desc,
  },
};

export interface UseUserMarketsFilterProps extends SearchInputProps {
  sortedAndFilteredItems: ExtendedUserMarket[];
  coverageLineFilters: FilterElementProps[];
  handleCoverageLineFilterChange: (event: ChangeEvent<HTMLInputElement>, key: string) => void;
  handleMarketOrganizationTypeFilterChange: (event: ChangeEvent<HTMLInputElement>, key: string) => void;
  handleInSubmissionFilterChange: (event: ChangeEvent<HTMLInputElement>, key: string) => void;
  handleMarketStatusFilterChange: (event: ChangeEvent<HTMLInputElement>, key: string) => void;
  isAllCoverageLineFilters: boolean;
  isAllMarketOrganizationTypeFilters: boolean;
  handleAllCoverageLineSelected: () => void;
  handleAllMarketOrganizationTypeSelected: () => void;
  handleAllInSubmissionSelected: () => void;
  handleAllMarketTypeSelected: () => void;
  labelFilters: FilterElementProps[];
  inSubmissionFilters: FilterElementProps[];
  marketTypeFilters: FilterElementProps[];
  marketOrganizationTypeFilters: FilterElementProps[];
  labelsList: string[];
  handleLabelFilterChange: (event: ChangeEvent<HTMLInputElement>, key: string) => void;
  handleVisibleLabelChange: (key: string) => void;
  isAllLabelFilters: boolean;
  isAllInSubmissionFilters: boolean;
  isAllMarketTypeFilters: boolean;
  handleAllLabelSelected: () => void;
  sortType: UserMarketSortTypes;
  setSortType: (value: UserMarketSortTypes) => void;
  clearFilters: () => void;
  isFilterApplied: boolean;
}

export default function useUserMarketsFilter(
  markets: UserMarket[],
  isMarketsLoading: boolean,
  isFetching: boolean,
  paginationReset: () => void,
  marketsIdsInSubmission?: string[],
  incumbentMarketIds?: string[],
  defaultSortType = UserMarketSortTypes.Asc,
  preferredCoverageLines?: CoverageLine[],
): UseUserMarketsFilterProps {
  const { items: products, isLoading: isLoadingProducts } = useSearchInsuranceProduct();
  const isLoading = isMarketsLoading || isLoadingProducts;
  const [isAllCoverageLineFilters, { set: setAllCoverageLineFilters }] = useBoolean(true);
  const [isAllLabelFilters, { set: setAllLabelFilters }] = useBoolean(true);
  const [isAllInSubmissionFilters, { set: setAllInSubmissionFilters }] = useBoolean(true);
  const [isAllMarketTypeFilters, { set: setAllMarketTypeFilters }] = useBoolean(true);
  const [isAllMarketOrganizationTypeFilters, { set: setAllMarketOrganizationTypeFilters }] = useBoolean(true);

  const [sortType, setSortType] = useState<UserMarketSortTypes>(defaultSortType);

  const [coverageLineFilters, setCoverageLineFilters] = useState(
    Object.keys(coverageLineConfig)
      .sort((a, b) =>
        coverageLineConfig[a as CoverageLine].text.localeCompare(coverageLineConfig[b as CoverageLine].text),
      )
      .map((coverageLine) => ({
        key: coverageLine,
        checked: false,
        label: coverageLineConfig[coverageLine as CoverageLine].text,
      })),
  );
  const [labelFilters, setLabelFilters] = useState<FilterElementProps[]>([]);
  const [inSubmissionFilters, setInSubmissionFilters] = useState<FilterElementProps[]>([]);
  const [marketTypeFilters, setMarketTypeFilters] = useState<FilterElementProps[]>([]);
  const [marketOrganizationTypeFilters, setMarketOrganizationTypeFilters] = useState<FilterElementProps[]>(
    marketOrganizationTypeFilterOptions.map((marketOrganizationType) => ({
      key: marketOrganizationTypeConfig[marketOrganizationType]?.key!,
      checked: false,
      label: marketOrganizationTypeConfig[marketOrganizationType]?.label!,
    })),
  );

  const [searchInput, setSearchInput] = useState<string>('');

  const computedMarkets: ExtendedUserMarket[] = useMemo(
    () =>
      markets.map((market) => {
        const extendedContacts = market.contacts.map(
          (marketContact): ExtendedContact => getExtendedContact(marketContact, products),
        );

        const marketCoverageLines = uniq(
          flatten(
            products
              .filter((product) => product.marketId === market.marketId)
              .map((product) =>
                product.coverageLines.map((insuranceProductCoverageLine) => insuranceProductCoverageLine.coverageLine),
              ),
          ),
        );

        return {
          ...market,
          coverageLines: marketCoverageLines,
          contacts: extendedContacts,
          matchReasons: compact([
            incumbentMarketIds?.includes(market.id) ? 'incumbent' : null,
            preferredCoverageLines?.find((preferredCoverageLine) => marketCoverageLines.includes(preferredCoverageLine))
              ? 'coverageLines'
              : null,
          ]),
        };
      }),
    [incumbentMarketIds, markets, preferredCoverageLines, products],
  );

  const filteredMarkets = useMemo(
    () =>
      [...computedMarkets]
        .sort(sorters[UserMarketSortTypes[sortType]].sorter)
        .filter((market) => {
          if (isAllCoverageLineFilters) {
            return true;
          }
          return coverageLineFilters.some(
            (filter) => filter.checked && market.coverageLines.includes(filter.key as CoverageLine),
          );
        })
        .filter((market) => {
          if (isAllMarketOrganizationTypeFilters) {
            return true;
          }
          return marketOrganizationTypeFilters.some(
            (filter) => filter.key === market.organizationType && filter.checked,
          );
        })
        .filter((market) => {
          if (isAllLabelFilters) {
            return true;
          }
          return labelFilters.some(
            (filter) => market.categories?.some((category) => category === filter.key) && filter.checked,
          );
        })
        .filter((market) => {
          if (isAllInSubmissionFilters) {
            return true;
          }
          if (inSubmissionFilters[0].checked) {
            return marketsIdsInSubmission?.includes(market.id);
          }

          if (inSubmissionFilters[1].checked) {
            return !marketsIdsInSubmission?.includes(market.id);
          }
          return true;
        })
        .filter((market) => {
          if (isAllMarketTypeFilters) {
            return true;
          }
          if (marketTypeFilters[0].checked) {
            return incumbentMarketIds?.includes(market.id);
          }

          if (marketTypeFilters[1].checked) {
            return !incumbentMarketIds?.includes(market.id);
          }
          return true;
        })
        .filter((market) => {
          if (searchInput.length < 2) {
            return true;
          }

          const marketTerms = [
            market.marketName,
            market.memo,
            market.riskAppetite,
            ...market.coverageLines.map((coverageLine) => coverageLineConfig[coverageLine].text),
            marketOrganizationTypeConfig[market.organizationType]?.label,
            ...(market.categories ?? []),
            ...market.contacts.flatMap((contact) => [
              contactFullName(contact),
              contact.email,
              ...contact.products.map((insuranceProduct) => insuranceProduct.name),
            ]),
          ].filter(Boolean);
          const isInMarketTerms = marketTerms.some((term) => term?.toLowerCase().includes(searchInput.toLowerCase()));
          if (isInMarketTerms) {
            return isInMarketTerms;
          }
          const matchSorterFilter = matchSorter([market], searchInput, { keys: ['marketName'] });
          return !isEmpty(matchSorterFilter);
        }),
    [
      coverageLineFilters,
      isAllCoverageLineFilters,
      marketsIdsInSubmission,
      incumbentMarketIds,
      labelFilters,
      marketTypeFilters,
      isAllInSubmissionFilters,
      inSubmissionFilters,
      isAllLabelFilters,
      isAllMarketTypeFilters,
      computedMarkets,
      searchInput,
      sortType,
      marketOrganizationTypeFilters,
      isAllMarketOrganizationTypeFilters,
    ],
  );

  const handleAllCoverageLineSelected = () => {
    setCoverageLineFilters((prevFilters) => prevFilters.map((filter) => ({ ...filter, checked: false })));
    setAllCoverageLineFilters(true);
  };

  const handleAllInSubmissionSelected = () => {
    setInSubmissionFilters((prevFilters) => prevFilters.map((filter) => ({ ...filter, checked: false })));
    setAllInSubmissionFilters(true);
  };

  const handleAllMarketTypeSelected = () => {
    setMarketTypeFilters((prevFilters) => prevFilters.map((filter) => ({ ...filter, checked: false })));
    setAllMarketTypeFilters(true);
  };

  const handleAllLabelSelected = () => {
    setLabelFilters((prevFilters) => prevFilters.map((filter) => ({ ...filter, checked: false })));
    setAllLabelFilters(true);
  };

  const handleAllMarketOrganizationTypeSelected = () => {
    setMarketOrganizationTypeFilters((prevFilters) => prevFilters.map((filter) => ({ ...filter, checked: false })));
    setAllMarketOrganizationTypeFilters(true);
  };

  const setSearch = (value: string) => {
    paginationReset();
    setSearchInput(value);
  };

  const handleCoverageLineFilterChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    setCoverageLineFilters((prevFilters) => updateCheckedFilter([...prevFilters], key, event.target.checked));
    paginationReset();
    setAllCoverageLineFilters(coverageLineFilters.every((filter) => !filter.checked));
  };

  const handleMarketOrganizationTypeFilterChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    setMarketOrganizationTypeFilters((prevFilters) => updateCheckedFilter([...prevFilters], key, event.target.checked));
    paginationReset();
    setAllMarketOrganizationTypeFilters(marketOrganizationTypeFilters.every((filter) => !filter.checked));
  };

  const handleInSubmissionFilterChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    setInSubmissionFilters((prevFilters) => updateCheckedFilter([...prevFilters], key, event.target.checked));
    paginationReset();
    setAllInSubmissionFilters(inSubmissionFilters.every((filter) => filter.checked === inSubmissionFilters[0].checked));
  };

  const handleMarketStatusFilterChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    setMarketTypeFilters((prevFilters) => updateCheckedFilter([...prevFilters], key, event.target.checked));
    paginationReset();
    setAllMarketTypeFilters(marketTypeFilters.every((filter) => filter.checked === marketTypeFilters[0].checked));
  };

  const handleLabelFilterChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    setLabelFilters((prevFilters) => updateCheckedFilter([...prevFilters], key, event.target.checked));
    paginationReset();
    setAllLabelFilters(labelFilters.every((filter) => !filter.checked));
  };

  const handleVisibleLabelChange = (key: string) => {
    setLabelFilters((prevFilters) => {
      const existingFilters = [...prevFilters];
      const changedFilter = existingFilters.find((filter) => filter.key === key);
      if (changedFilter) {
        changedFilter.checked = !changedFilter.checked;
      }
      return existingFilters;
    });
    setAllLabelFilters(labelFilters.every((filter) => !filter.checked));
  };

  const inSubmissionFilterSetter = (inSubmissionList: string[]) => {
    setInSubmissionFilters((prev) =>
      inSubmissionList.map((label) => ({
        key: label,
        checked: prev.find((item) => item.key === label)?.checked ?? false,
        label,
      })),
    );
  };

  const labelFilterSetter = (labelsList: string[]) => {
    setLabelFilters((prev) =>
      labelsList.map((label) => ({
        key: label,
        checked: prev.find((item) => item.key === label)?.checked ?? false,
        label,
      })),
    );
  };

  const labelsList = useMemo(
    () => union(...markets.map((market) => market.categories ?? [])).sort(stringCompare),
    [markets],
  );

  useEffect(() => {
    if (!isLoading && !isFetching && markets.length > 0) {
      labelFilterSetter(labelsList);
      inSubmissionFilterSetter(inSubmissionFilterOptions);
    }
  }, [isLoading, isFetching, markets, labelsList, marketsIdsInSubmission, incumbentMarketIds]);

  const clearFilters = () => {
    handleAllCoverageLineSelected();
    handleAllLabelSelected();
    handleAllInSubmissionSelected();
    handleAllMarketTypeSelected();
    handleAllMarketOrganizationTypeSelected();
  };

  const isFilterApplied =
    !isAllCoverageLineFilters ||
    !isAllLabelFilters ||
    !isAllInSubmissionFilters ||
    !isAllMarketTypeFilters ||
    !isAllMarketOrganizationTypeFilters;

  return {
    coverageLineFilters,
    handleCoverageLineFilterChange,
    isAllCoverageLineFilters,
    handleAllCoverageLineSelected,
    handleAllInSubmissionSelected,
    handleAllMarketTypeSelected,
    labelFilters,
    marketTypeFilters,
    inSubmissionFilters,
    labelsList,
    handleAllLabelSelected,
    isAllLabelFilters,
    handleInSubmissionFilterChange,
    handleMarketStatusFilterChange,
    isAllInSubmissionFilters,
    isAllMarketTypeFilters,
    handleLabelFilterChange,
    handleVisibleLabelChange,
    search: searchInput,
    setSearch,
    sortType,
    setSortType,
    sortedAndFilteredItems: filteredMarkets,
    marketOrganizationTypeFilters,
    isAllMarketOrganizationTypeFilters,
    handleAllMarketOrganizationTypeSelected,
    handleMarketOrganizationTypeFilterChange,
    clearFilters,
    isFilterApplied,
  };
}
