import { Chip, IconButton, Stack } from '@common-components';
import { DeleteOutline } from '@icons';
import { LayerStatus } from 'enums';
import { messages } from 'i18n';
import { Layer } from 'types';

interface SidePanelProps {
  isTopLayer: boolean;
  layer: Layer;
  status: string;
  color?: string;
  setRemoveLayerDialogOpen: (layerId: string) => void;
  hasCandidateQuotes: boolean;
}

enum DeleteLayerStatus {
  Hide,
  Show,
  Disabled,
}

export default function SidePanel({
  isTopLayer,
  layer,
  setRemoveLayerDialogOpen,
  status,
  hasCandidateQuotes,
}: SidePanelProps) {
  const getDeleteIconStatus = () => {
    const isPrimaryLayer = !layer.attachmentPoint;
    const validLayerStatusForDelete = [LayerStatus.Quoting, LayerStatus.Reviewing];
    if (isPrimaryLayer || !validLayerStatusForDelete.includes(layer.status)) {
      return DeleteLayerStatus.Hide;
    }
    if (isTopLayer && !hasCandidateQuotes) {
      return DeleteLayerStatus.Show;
    }
    return DeleteLayerStatus.Disabled;
  };

  const deleteIconStatus = getDeleteIconStatus();

  return (
    <Stack gap={1} direction="row" alignItems="center" justifyContent="flex-end">
      <Chip label={status} color="secondary" variant="contained" size="small" />
      {deleteIconStatus !== DeleteLayerStatus.Hide && (
        <IconButton
          variant="text"
          edge="end"
          color="secondary"
          icon={DeleteOutline}
          onClick={() => setRemoveLayerDialogOpen(layer.id)}
          disabledTooltipContent={messages.submissionWorkspace.dialogs.removeExcessLayer.disabled.text}
          disabled={deleteIconStatus === DeleteLayerStatus.Disabled}
        />
      )}
    </Stack>
  );
}
