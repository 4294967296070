import { PartialSubmission } from 'types';

export interface SubmissionAutonomousCreationLocationState {
  submission: PartialSubmission;
}

export enum StepsRoutes {
  Basic = 'basic',
  Advanced = 'advanced',
}
