import { Stack, Typography } from '@common-components';
import { ChatOutlined as ChatOutlinedIcon, OutlinedFlag as OutlinedFlagIcon } from '@icons';
import { useRetrieveUserMarket } from 'hooks';
import { messages } from 'i18n';
import { useCurrentSubmissionMarket } from 'broker/pages/SubmissionWorkspacePage/hooks';
import MarketInfoCategoriesSection from './MarketInfoCategoriesSection';
import MarketInfoEditableSection from './MarketInfoEditableSection';

export default function Info() {
  const market = useCurrentSubmissionMarket();

  const { data: userMarket, isInitialLoading } = useRetrieveUserMarket({
    id: market?.userMarketId || '',
  });

  if (!market) {
    return null;
  }

  return (
    <Stack overflow="hidden" flex={1}>
      <Typography variant="h4" px={3} pt={4} pb={1}>
        {messages.marketsGeneral.marketInformation}
      </Typography>
      <Stack gap={3} overflow="auto" flex={1} p={3}>
        <MarketInfoEditableSection
          title={messages.submissionWorkspace.dialogs.marketDetails.riskAppetite}
          isLoading={isInitialLoading}
          editProp="riskAppetite"
          initValue={userMarket?.riskAppetite || ''}
          userMarketId={market.userMarketId}
          Icon={OutlinedFlagIcon}
        />
        <MarketInfoEditableSection
          title={messages.submissionWorkspace.dialogs.marketDetails.memo}
          isLoading={isInitialLoading}
          editProp="memo"
          initValue={userMarket?.memo || ''}
          userMarketId={market.userMarketId}
          Icon={ChatOutlinedIcon}
        />
        <MarketInfoCategoriesSection
          isLoading={isInitialLoading}
          initValue={userMarket?.categories || []}
          userMarketId={market.userMarketId}
        />
      </Stack>
    </Stack>
  );
}
