import { BoxItem } from 'box-ui-elements/es';
import { createSearchParams } from 'react-router-dom-latest';
import { AttachEmailOutlined as AttachEmailOutlinedIcon, AttachFile as AttachFileIcon } from '@icons';
import { FileType, FormMode } from 'enums';
import { messages } from 'i18n';
import { SubmissionMarket } from 'types';
import { getFileType } from 'utils';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import { GetFileSecondaryActions } from 'broker/components/FilesExplorer/types';
import { getFileMarketIds } from 'broker/components/FilesExplorer/utils';
import { EmailEditorPageMode, RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';

export function useGetFileSecondaryActions(
  submissionMarkets: SubmissionMarket[],
  hideQuoteActions = false,
): GetFileSecondaryActions {
  const navigate = useNavigate();
  const { createQuoteFormUrl } = useWorkspaceUrls();

  return (file: BoxItem) => {
    const fileType = getFileType(file);
    // I assume there is only one submission market per file in this context. We use multiple submission markets only for files that we generate.
    const fileMarket = submissionMarkets.find((submissionMarket) =>
      getFileMarketIds(file)?.includes(submissionMarket.id),
    );

    return [
      {
        label: messages.fileExplorer.menu.emailCustomer,
        onClick: () => {
          navigate(BROKER_NESTED_ROUTES.WORKSPACE.EMAIL_EDITOR, {
            routeKey: RouteKey.EmailEditor,
            state: { mode: EmailEditorPageMode.SendCustomer, attachedFileIds: [file.id] },
          });
        },
        endAdornment: AttachEmailOutlinedIcon,
      },
      {
        label: messages.fileExplorer.menu.emailMarket,
        onClick: () =>
          navigate(BROKER_NESTED_ROUTES.WORKSPACE.EMAIL_EDITOR, {
            routeKey: RouteKey.EmailEditor,
            state: { mode: EmailEditorPageMode.SendMarket, attachedFileIds: [file.id] },
          }),
        endAdornment: AttachEmailOutlinedIcon,
      },
      {
        label: messages.fileExplorer.menu.addQuote,
        onClick: () =>
          navigate(
            {
              pathname: createQuoteFormUrl,
              search: `${createSearchParams([['selectedFile', file.id]])}`,
            },
            {
              routeKey: RouteKey.Quote,
              state: {
                mode: FormMode.create,
                quoteFileIds: [file.id],
                selectedMarket: fileMarket,
              },
            },
          ),
        endAdornment: AttachFileIcon,
        hidden: hideQuoteActions,
        disabled: [FileType.CapitolaQuote, undefined].every((type) => fileType !== type),
        disabledTooltipContent: messages.fileExplorer.menu.addQuoteDisabled,
      },
    ];
  };
}
