import { isEmpty } from 'lodash';
import { useState } from 'react';
import { hotjar } from 'react-hotjar';
import { useMount } from './useMount';

export const useHotjarInitialization = () => {
  const [hotjarInitialized, setHotjarInitialized] = useState(false);

  useMount(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarSnippetVersion = process.env.REACT_APP_HOTJAR_SNIPPET_VERSION;
    if (isEmpty(hotjarSiteId) || isEmpty(hotjarSnippetVersion)) {
      return;
    }
    hotjar.initialize(Number(hotjarSiteId), Number(hotjarSnippetVersion));
    setHotjarInitialized(true);
  });

  return hotjarInitialized;
};
