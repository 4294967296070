import { useEffect, useMemo, useState } from 'react';

const VISIBILITY_CHANGE_EVENT = 'visibilitychange';

export const useWindowVisibilityListener = () => {
  const [windowVisibility, setWindowVisibility] = useState<DocumentVisibilityState>('visible');

  function handleVisibilityChange() {
    setWindowVisibility(document.visibilityState);
  }
  useEffect(() => {
    document.addEventListener(VISIBILITY_CHANGE_EVENT, handleVisibilityChange);

    return () => document.removeEventListener(VISIBILITY_CHANGE_EVENT, handleVisibilityChange);
  }, []);

  return useMemo(() => ({ isWindowVisible: windowVisibility === 'visible' }), [windowVisibility]);
};
