import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Route, Routes } from 'react-router-dom-latest';
import { useCurrentUser, useFormProvider } from 'hooks';
import { SubmissionMarket } from 'types';
import NavigateBack from 'broker/components/NavigateBack';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import AdvancedContent from './AdvancedContent/AdvancedContent';
import { AutonomousMarketFormData, schema, sliderMarks } from './AdvancedContent/utils';
import BasicContent from './BasicContent';
import { StepsRoutes, SubmissionAutonomousCreationLocationState } from './types';
import useAutonomousFlow from './useAutonomousFlow';
import useSelectUser from './useSelectUser';

export interface FormWrapperProps extends BaseRoutedDialogContentProps {
  activeStep: StepsRoutes;
  locationState: SubmissionAutonomousCreationLocationState;
  submissionMarkets: SubmissionMarket[];
}

export default function FormWrapper(props: FormWrapperProps) {
  const {
    locationState: { submission },
    setDialogIsDirty,
    closeWithoutPrompt,
    submissionMarkets,
  } = props;

  const { endUser } = useCurrentUser();

  const initStep = submission.isCopilotEnabled
    ? submission.copilotSettings?.activeSteps
    : endUser?.organization.copilotSettings?.activeSteps;

  const [value, setValue] = useState(
    sliderMarks.find((mark) => mark.serverSteps.length === (initStep?.length || 1))?.value || 0,
  );

  const { selectedUser, setSelectedUser, users } = useSelectUser({
    submissionUser: submission.assignee,
  });

  const { initSubmissionMarkets, startAutonomousFlow, isAutonomousFlowInProgress } = useAutonomousFlow({
    submissionId: submission.id,
    submissionMarkets,
    insuredName: submission.insuredName || '',
    selectedUserId: selectedUser?.id || null,
    onAutonomousFlowSuccess: closeWithoutPrompt,
    activeSteps: sliderMarks.find((mark) => mark.value === value)?.serverSteps || [],
  });

  const { methods } = useFormProvider<AutonomousMarketFormData>({
    schema,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { markets: initSubmissionMarkets },
    setIsDirty: setDialogIsDirty,
  });

  const submitForm = async () => {
    const handleSubmit = methods.handleSubmit(async (data) => {
      await startAutonomousFlow(data.markets.filter((market) => market.marketId));
    });

    await handleSubmit();
  };

  return (
    <FormProvider {...methods}>
      <Routes>
        <Route
          path={`${StepsRoutes.Basic}/*`}
          element={
            <BasicContent
              {...props}
              submission={submission}
              selectedUser={selectedUser}
              submissionMarkets={submissionMarkets}
              submitForm={submitForm}
              value={value}
              setValue={setValue}
              isAutonomousFlowInProgress={isAutonomousFlowInProgress}
            />
          }
        />
        <Route
          path={`${StepsRoutes.Advanced}/*`}
          element={
            <AdvancedContent
              {...props}
              submission={submission}
              users={users}
              selectedUser={selectedUser}
              onAssigneeChange={setSelectedUser}
              submissionMarkets={submissionMarkets}
              submitForm={submitForm}
              value={value}
              setValue={setValue}
              isAutonomousFlowInProgress={isAutonomousFlowInProgress}
            />
          }
        />

        <Route path="*" element={<NavigateBack replace />} />
      </Routes>
    </FormProvider>
  );
}
