import { Size, Variant } from 'components/types';

const sizes = {
  spacingBase: 8,
  tooltipMinWidth: 80,
  tooltipMaxWidth: 480,
};

export const baseInputComponentsProps: Record<Variant, Record<Size, any>> = {
  contained: {
    small: {
      py: '4px',
      px: '16px',
    },
    medium: {
      py: '10px',
      px: '16px',
    },
    large: {
      py: '16px',
      px: '24px',
    },
  },
  outlined: {
    small: {
      py: '3px',
      px: '15px',
    },
    medium: {
      py: '9px',
      px: '15px',
    },
    large: {
      py: '15px',
      px: '23px',
    },
  },
  text: {
    small: {
      py: '4px',
      px: '16px',
    },
    medium: {
      py: '10px',
      px: '16px',
    },
    large: {
      py: '16px',
      px: '24px',
    },
  },
};

export default sizes;
