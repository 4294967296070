import { EmailTemplateVariables, TemplateVariablesResolver } from '@common/email-variables';
import { BoxItem, SharedLink } from 'box-ui-elements/es';
import { renderToStaticMarkup } from 'react-dom/server';
import { messages } from 'i18n';
import theme from 'themes';
import { stringToReact } from 'utils';
import { defaultEditorFontSizeStringPx } from 'components/Editor/consts';
import { sanitizeMentions } from 'components/Editor/utils/mentions';
import { smartObjectsConfig } from 'broker/utils/email-utils/SmartVariable/smart-objects-config';
import { renderSmartObjectVariable, revertSmartObjectToVariable } from 'broker/utils/email-utils/SmartVariable/utils';
import { VariableInfo } from './template-variables-utils';

const defaultIconSize = 21;
export const minAttachedFilesToCreateArchive = 2;

export function resolveText<T>(
  text: string,
  data: T,
  resolverMap: TemplateVariablesResolver<T, VariableInfo>,
  mentionPrefix: string,
  shouldOmitUnresolvedVars = false,
  editForAllMode = false,
  resolveSmartObjects = false,
) {
  let result = text;

  // Explaining the different use-cases and resolving logics:
  // 1. When resolving any mode, if a value is returned - use it. Otherwise...
  // 2. In resolving 'Edit Mode', we want to keep the variables with 'undefined' value as is (@VARIABLE-NAME).
  // This is expressed by the 'shouldOmitUnresolvedVars' parameter being set to 'true'.
  // 3. When resolving 'Preview Mode', we want to resolve variables that don't have 'omitIfEmpty' set to 'true' to just their name.
  // This is expressed by the 'shouldOmitUnresolvedVars' parameter being set to 'false'.
  // 4. When resolving the 'Preview Mode', we want to resolve variables that have 'omitIfEmpty' set to 'true' to an empty string.
  Object.keys(resolverMap).forEach((key) => {
    const variableRegex = new RegExp(`${mentionPrefix}${key}(?![-])`, 'g');

    // If the variable is not found in the text, there is no need to try and resolve it.
    if (result.search(variableRegex) === -1) {
      return;
    }

    const variableInfo = resolverMap[key as EmailTemplateVariables](data);

    if (editForAllMode && variableInfo.unresolvedInEditForAll) {
      return;
    }

    // Checking explicitly for undefined as empty string should be treated as resolved.
    if (variableInfo.value !== undefined) {
      // if smart object
      if (key in smartObjectsConfig && !resolveSmartObjects) {
        result = result.replaceAll(
          variableRegex,
          renderSmartObjectVariable(key as EmailTemplateVariables, resolverMap, data),
        );
      } else {
        result = result.replaceAll(variableRegex, variableInfo.value);
      }
    } else if (shouldOmitUnresolvedVars) {
      result = result.replaceAll(variableRegex, '');
    } else {
      // Keep as is (@VARIABLE-NAME).
    }
  });

  return result;
}

// There are template variables that are recipient dependent, and thus can't be resolved immediately in edit mode, and so return 'undefined' and are later computed and shown in preview mode.
// If the template variable has no resolvent past the edit mode, we return an empty string instead, so that we wouldn't try to resolve it in preview mode (which would cause the preview to simply show the variable name).
export function resolveHtml<T>(
  html: string,
  data: T,
  resolver: TemplateVariablesResolver<T>,
  mentionPrefix: string,
  shouldOmitUnresolvedVars?: boolean,
  editForAllMode?: boolean,
  resolveSmartObjects = false,
) {
  const div = document.createElement('div');
  const divFontSize = document.createElement('div');
  divFontSize.innerHTML = html;
  divFontSize.style.fontSize = defaultEditorFontSizeStringPx;

  div.appendChild(divFontSize);
  sanitizeMentions(div);

  const text = revertSmartObjectToVariable(div.innerHTML);

  return resolveText(
    text,
    data,
    resolver,
    mentionPrefix,
    shouldOmitUnresolvedVars,
    editForAllMode,
    resolveSmartObjects,
  );
}

function attachmentItem(file: BoxItem) {
  const fileIcon = (
    <span
      style={{
        width: `${defaultIconSize}px`,
        float: 'left',
      }}
    >
      📄
    </span>
  );

  const linkText = (
    <span
      style={{
        color: 'black',
        textDecoration: 'none',
        margin: '0',
        maxWidth: `calc(100% - ${defaultIconSize + 9}px)`,
        paddingLeft: '8px',
        float: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {file.name}
    </span>
  );

  const attachmentLink = (
    <a
      href={file.shared_link.download_url}
      title={file.name}
      target="_blank"
      rel="noreferrer"
      style={{
        height: '40px',
        width: '300px',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.grey[100]!,
        border: '1px solid #E0E0E0',
        borderRadius: '5px',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
        margin: '8px',
        float: 'left',
      }}
    >
      {fileIcon}
      {linkText}
    </a>
  );

  return attachmentLink;
}

export function appendAttachments(
  html: string,
  files: BoxItem[],
  attachmentsArchive: SharedLink | null,
  previewMode = false,
) {
  const attachmentsCount = files.length;

  if (attachmentsCount === 0) {
    return html;
  }

  // Attachments title
  const attachmentsTitle = (
    <span style={{ fontWeight: 'bold', float: 'left' }}>
      {messages.emailEditor.attachedFilesTitle(attachmentsCount)}
    </span>
  );

  // 'Download All Attachments' button
  const downloadAllButtonText = (
    <span
      style={{
        fontSize: '14px',
        fontWeight: 'bold',
        textDecoration: 'none',
        color: '#434E4B',
        paddingRight: '5px',
        float: 'left',
      }}
    >
      {messages.emailEditor.downloadAllAttachments}
    </span>
  );

  const downloadAllButtonIcon = <span style={{ verticalAlign: 'top', float: 'left' }}>📩</span>;

  // We only load in the URL only if an attachments archive was provided, and we are not in preview mode
  const downloadUrl = attachmentsArchive && !previewMode ? attachmentsArchive.download_url : undefined;
  const downloadAllButton = (
    <a href={downloadUrl} target="_blank" title="Download All" style={{ float: 'right' }} rel="noreferrer">
      {downloadAllButtonText}
      {downloadAllButtonIcon}
    </a>
  );

  const attachmentsHeader = (
    <div style={{ marginTop: '40px', marginBottom: '0' }}>
      {attachmentsTitle}
      {files.length >= minAttachedFilesToCreateArchive && downloadAllButton}
    </div>
  );

  const attachmentsList = <div style={{ clear: 'left' }}>{files.map((file) => attachmentItem(file))}</div>;

  // If we are neither in preview mode nor were given an attachments archive, there is no need to show the attachments header
  const attachments = (
    <div>
      {previewMode || attachmentsArchive ? attachmentsHeader : null}
      {attachmentsList}
    </div>
  );

  const body = stringToReact(html);

  const wrapper = (
    <div>
      {body}
      {attachments}
    </div>
  );

  return renderToStaticMarkup(wrapper);
}
