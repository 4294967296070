import { OrganizationType } from 'enums';
import { messages } from 'i18n';

export enum UserMarketSortTypes {
  Asc = 'Asc',
  Desc = 'Desc',
  Updated = 'Updated',
}

export const marketOrganizationTypeFilterOptions = [OrganizationType.Broker, OrganizationType.Carrier];

export interface MarketOrganizationTypeProps {
  key: OrganizationType;
  label: string;
}

export const marketOrganizationTypeConfig: Partial<Record<OrganizationType, MarketOrganizationTypeProps>> = {
  [OrganizationType.Carrier]: {
    key: OrganizationType.Carrier,
    label: messages.marketsPage.marketType.directMarket,
  },
  [OrganizationType.Broker]: {
    key: OrganizationType.Broker,
    label: messages.marketsPage.marketType.wholesaler,
  },
};
