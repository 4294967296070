import { useQueryClient } from '@tanstack/react-query';
import { UserMarketClient } from 'clients';
import { QueryKeys } from 'enums';
import { UserMarket, UserMarketCreate } from 'types';
import { UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useQueryRetrieve, useQuerySearch } from './query/useQuery';

export function useSearchUserMarket(props?: UseQueryBaseSearchOptions<UserMarket>) {
  const { filter, queryOptions, sorter, enabled } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.UserMarkets,
    clientClass: UserMarketClient,
    enabled,
    filter,
    sorter: sorter ?? ((a, b) => a.marketName.localeCompare(b.marketName)),
    queryOptions: {
      // Default userMarket search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useRetrieveUserMarket(props: UseQueryBaseRetrieveOptions<UserMarket>) {
  const { id, queryOptions, enabled } = props;
  return useQueryRetrieve({
    queryKey: QueryKeys.UserMarkets,
    clientClass: UserMarketClient,
    id,
    enabled,
    queryOptions: {
      // Default userMarket retrieve query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateUserMarket() {
  const {
    create: createUserMarket,
    update: updateUserMarket,
    destroy: deleteUserMarket,
  } = useMutate<UserMarket, UserMarket, UserMarketCreate, UserMarketClient>({
    queryKey: QueryKeys.UserMarkets,
    clientClass: UserMarketClient,
    createOptions: {
      passthroughStatusCodes: [409],
      throwOnError: true,
    },
    updateOptions: { throwOnError: true },
    destroyOptions: { throwOnError: true },
  });

  return { createUserMarket, updateUserMarket, deleteUserMarket };
}

export function useInvalidateUserMarketCache() {
  const queryClient = useQueryClient();
  return async () => {
    await queryClient.invalidateQueries([QueryKeys.UserMarkets]);
  };
}
