import { Highlight, Tooltip, Typography, Variant } from '@common-components';
import { truncateMultiLineText } from 'utils';

const listItemTitleStyle: Record<
  ListItemType,
  Record<ListTitleType, { color: string; variant: Variant | 'body2Bold' }>
> = {
  primary: {
    title: {
      color: 'text.primary',
      variant: 'body2Bold',
    },
    subTitle: {
      color: 'text.primary',
      variant: 'body2',
    },
  },
  secondary: {
    title: {
      color: 'text.secondary',
      variant: 'body2',
    },
    subTitle: {
      color: 'text.secondary',
      variant: 'body2',
    },
  },
};

export type ListItemType = 'primary' | 'secondary';
type ListTitleType = 'title' | 'subTitle';
interface ListItemTitleProps {
  // text that will be highlighted against the searchTerm
  text: string;

  // literal text that will not be highlighted
  textPrefix?: string;

  // if provided, provides tooltip with the text as its value
  textTooltip?: string;

  // text will be highlighted when matching
  searchTerm?: string;
  itemType?: ListItemType;

  // sets the styling
  titleType: ListTitleType;

  truncateMaxLines?: number;
}

export default function ListItemTitle({
  text,
  textPrefix = '',
  textTooltip = '',
  searchTerm = '',
  itemType = 'primary',
  titleType,
  truncateMaxLines = 1,
}: ListItemTitleProps) {
  const style = listItemTitleStyle[itemType][titleType];

  return (
    <Tooltip tooltipContent={textTooltip}>
      <Typography {...style} sx={{ display: 'block', ...truncateMultiLineText(truncateMaxLines) }}>
        {textPrefix}
        <Highlight searchWords={[searchTerm]} textToHighlight={text} />
      </Typography>
    </Tooltip>
  );
}
