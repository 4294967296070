import boxMessages from 'box-ui-elements/i18n/en-US';
import { useCurrentUser } from 'hooks';
import { messages } from 'i18n';

export default function useBoxMessages() {
  const { me } = useCurrentUser();

  return {
    ...boxMessages,
    'be.uploadEmptyWithFolderUploadDisabled': messages.box.dragAndDrop,
    'be.upload': messages.box.uploadFiles,
    'be.shareAccessCollab': messages.box.shareDialog.folderLevel(me!.organization.name),
    'be.uploadEmptyFileInput': messages.box.uploadEmptyFileInput,
  };
}
