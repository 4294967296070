import { messages } from 'i18n';
import { UserDetails } from 'types';
import { SubmissionViewMode } from './utils';

type ColumnConfigType = {
  key: string;
  title: string;
  flex: number | string;
  showForModes: SubmissionViewMode[];
  align?: 'left' | 'center' | 'right';
};

export type SubmissionsAssignee = Record<string, UserDetails>;

export const SUBMISSIONS_VIEW_COLUMNS = {
  Pinned: 'pinned',
  Received: 'received',
  DueDate: 'dueDate',
  InsuredName: 'insuredName',
  Status: 'status',
  Contacts: 'contacts',
  Assignee: 'assignee',
  Reminder: 'reminder',
  LastUpdated: 'lastUpdated',
  Menu: 'menu',
} as const;

type SubmissionsViewColumnsKeys = keyof typeof SUBMISSIONS_VIEW_COLUMNS;
export type SubmissionsViewColumnsValues = (typeof SUBMISSIONS_VIEW_COLUMNS)[SubmissionsViewColumnsKeys];

type SubmissionsViewColumnsConfig = (
  mode: SubmissionViewMode,
) => Record<SubmissionsViewColumnsValues, ColumnConfigType>;

export const submissionViewModeConfig: Record<
  SubmissionViewMode,
  { title: string; assigneeTitle: string; icon: string; allowCreateSubmission?: boolean }
> = {
  [SubmissionViewMode.General]: {
    title: messages.submissionsView.header.title,
    assigneeTitle: messages.submissionsView.columnHeaders.assignee,
    icon: 'view_list',
  },
  [SubmissionViewMode.PreviewBox]: {
    title: messages.submissionsView.header.submissionBoxTitle,
    icon: 'view_module',
    assigneeTitle: messages.submissionsView.columnHeaders.assignee,
    allowCreateSubmission: true,
  },
  [SubmissionViewMode.Retailer]: {
    title: messages.submissionsView.header.retailersTitle,
    assigneeTitle: messages.submissionsView.columnHeaders.flowTeamMember,
    icon: 'view_list',
  },
};

export const submissionsViewColumnsConfig: SubmissionsViewColumnsConfig = (mode) => ({
  [SUBMISSIONS_VIEW_COLUMNS.Pinned]: {
    key: SUBMISSIONS_VIEW_COLUMNS.Pinned,
    title: '',
    flex: '0 0 36px',
    showForModes: [SubmissionViewMode.General],
  },
  [SUBMISSIONS_VIEW_COLUMNS.Received]: {
    key: SUBMISSIONS_VIEW_COLUMNS.Received,
    title: 'Received',
    flex: '0 0 116px',
    showForModes: [SubmissionViewMode.PreviewBox],
  },
  [SUBMISSIONS_VIEW_COLUMNS.Contacts]: {
    key: SUBMISSIONS_VIEW_COLUMNS.Contacts,
    title: messages.submissionsView.columnHeaders.customers,
    flex: 1,
    showForModes: [SubmissionViewMode.General, SubmissionViewMode.PreviewBox],
  },

  [SUBMISSIONS_VIEW_COLUMNS.InsuredName]: {
    key: SUBMISSIONS_VIEW_COLUMNS.InsuredName,
    title: messages.submissionsView.columnHeaders.insured,
    flex: 1,
    showForModes: [SubmissionViewMode.General, SubmissionViewMode.PreviewBox, SubmissionViewMode.Retailer],
  },
  [SUBMISSIONS_VIEW_COLUMNS.Status]: {
    key: SUBMISSIONS_VIEW_COLUMNS.Status,
    title: messages.submissionsView.columnHeaders.status,
    flex: 1,
    showForModes: [SubmissionViewMode.General, SubmissionViewMode.PreviewBox, SubmissionViewMode.Retailer],
  },
  [SUBMISSIONS_VIEW_COLUMNS.DueDate]: {
    key: SUBMISSIONS_VIEW_COLUMNS.DueDate,
    title: messages.submissionsView.columnHeaders.effectiveDate,
    flex: 1,
    showForModes: [SubmissionViewMode.General, SubmissionViewMode.PreviewBox, SubmissionViewMode.Retailer],
  },
  [SUBMISSIONS_VIEW_COLUMNS.Assignee]: {
    key: SUBMISSIONS_VIEW_COLUMNS.Assignee,
    title: submissionViewModeConfig[mode].assigneeTitle,
    flex: 1,
    showForModes: [SubmissionViewMode.General, SubmissionViewMode.PreviewBox, SubmissionViewMode.Retailer],
  },
  [SUBMISSIONS_VIEW_COLUMNS.LastUpdated]: {
    key: SUBMISSIONS_VIEW_COLUMNS.LastUpdated,
    title: messages.submissionsView.columnHeaders.lastUpdated,
    flex: 1,
    showForModes: [SubmissionViewMode.Retailer],
    align: 'right',
  },
  [SUBMISSIONS_VIEW_COLUMNS.Reminder]: {
    key: SUBMISSIONS_VIEW_COLUMNS.Reminder,
    title: messages.submissionsView.columnHeaders.reminder,
    flex: 1.5,
    showForModes: [SubmissionViewMode.General],
  },
  [SUBMISSIONS_VIEW_COLUMNS.Menu]: {
    key: SUBMISSIONS_VIEW_COLUMNS.Menu,
    title: '',
    flex: '0 0 36px',
    showForModes: [],
  },
});
