import { IconButton, Stack, Typography } from '@common-components';
import { Share } from '@icons';
import { messages } from 'i18n';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';

interface HeaderProps {
  limit: string | null;
  reachedLimit: string;
  submissionId?: string;
  hideConceptualTowerShareButton?: boolean;
}

export default function Header({ limit, reachedLimit, hideConceptualTowerShareButton }: HeaderProps) {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      color="inherit"
      bgcolor="white"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      pt={2.5}
    >
      <Stack>
        <Typography variant="h4" noWrap>
          {messages.submissionWorkspace.excessTower}
        </Typography>
        <Typography color="text.secondary" variant="caption" noWrap>
          {messages.submission.reachedLimitText(reachedLimit, limit)}
        </Typography>
      </Stack>
      <Stack visibility={hideConceptualTowerShareButton ? 'hidden' : 'visible'}>
        <IconButton
          icon={Share}
          tooltipContent={messages.submissionWorkspace.menus.towerHeader.sendConceptualTower.send}
          color="secondary"
          variant="text"
          onClick={() => {
            navigate(BROKER_NESTED_ROUTES.WORKSPACE.CONCEPTUAL_TOWER, {
              routeKey: RouteKey.ConceptualTower,
              state: {},
            });
          }}
        />
      </Stack>
    </Stack>
  );
}
