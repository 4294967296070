import { Transition } from 'history';
import { ReactElement } from 'react';
import { DirtyRouteGuard } from 'broker/components/DirtyContentGuard';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { useBeforeEmailEditorNavigatingInInnerRoute } from 'broker/components/EmailEditor/useBeforeEmailEditorNavigatingInInnerRoute';

interface EmailEditorDirtyGuardProps {
  children: ReactElement;
  isDirty?: boolean;
}

// 'isDirty' default value is true since in most cases we want to show the dirty guard, so this prevents complexity and possible oversights.
export function EmailEditorDirtyGuard({ children, isDirty = true }: EmailEditorDirtyGuardProps) {
  const { isNavigatingToInnerEmailRoute } = useBeforeEmailEditorNavigatingInInnerRoute();
  const { getWasEmailsSent } = useEmailEditorState();

  const beforeNavigation = async (a: Transition) => {
    if (getWasEmailsSent()) {
      return true;
    }
    return isNavigatingToInnerEmailRoute(a);
  };

  return (
    <DirtyRouteGuard isDirty={isDirty} beforeNavigation={beforeNavigation}>
      {children}
    </DirtyRouteGuard>
  );
}
