import { useEffect } from 'react';
import { LocalStorage } from 'enums';
import { messages } from 'i18n';
import { useToast } from './useToast';
import { useWindowDimensions } from './useWindowDimensions';

export function useScreenResolutionAlert(isEnabled: boolean) {
  const { showToast } = useToast();
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (height < 700 && isEnabled) {
      if (localStorage.getItem(LocalStorage.ResolutionFlag) !== 'true') {
        localStorage.setItem(LocalStorage.ResolutionFlag, 'true');
        showToast('warning', { message: messages.general.resolutionAlert });
      }
    }
    if (width < 800 && isEnabled) {
      if (localStorage.getItem(LocalStorage.ScreenWidthFlag) !== 'true') {
        localStorage.setItem(LocalStorage.ScreenWidthFlag, 'true');
        showToast('warning', { message: messages.general.widthAlert });
      }
    }
  }, [height, width, showToast, isEnabled]);
}
