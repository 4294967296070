import {
  backdropClasses,
  Box,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Icon,
  IconButton,
  Link,
  Popover,
  Stack,
  Theme,
  Typography,
} from '@common-components';
import { AutoAwesomeOutlined, Close, Link as LinkIcon } from '@icons';
import { UserRole } from 'enums';
import { useCurrentUser, useEnvironment, useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { softShadow } from 'themes';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { AIGeneratedContent } from './AIGeneratedContent';
import { RegenerateButton } from './RegenerateButton';
import { AIThreadInputDataButton } from './thread-input-data/AIThreadInputDataButton';

const PopupMinHeight = 720;
const PopupMaxHeight = '80vh';
const PopupWidth = 960;
const HeaderHeight = 36;
const openAIPlatformUrl = 'https://platform.openai.com/threads/';

export interface ButtonWithPopupProps {
  popupTitle: string;
  tooltipText?: string;
  top?: number;
  right?: number;
  withFileLinks?: boolean;
  isRichTextResponse?: boolean;
}

export function AIButtonWithPopup({
  popupTitle,
  tooltipText,
  top = 88,
  right = 24,
  withFileLinks = true,
  isRichTextResponse = true,
}: ButtonWithPopupProps) {
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useOptionsMenu();
  const { isLoading, isContentStreaming, threadId } = useAIContext();
  const { me } = useCurrentUser();
  const environment = useEnvironment();

  return (
    <Box
      position="absolute"
      top={top}
      right={right}
      zIndex={(theme) => theme.zIndex.tooltip}
      bgcolor="green.300"
      color="common.black"
      borderRadius="50%"
      boxShadow={softShadow}
    >
      {isContentStreaming && (
        <Box position="absolute">
          <CircularProgress sx={{ color: 'text.secondary' }} thickness={2} />
        </Box>
      )}
      <IconButton
        isTooltipOpen={isLoading && !isContentStreaming && !!tooltipText && !isMenuOpen}
        tooltipCustomColor="green.300"
        tooltipPlacement="left"
        tooltipContent={
          <Stack flexDirection="row" flex={1}>
            <Icon component={AutoAwesomeOutlined} sx={{ fontSize: 18, mr: 1 }} />
            <Box>{tooltipText}</Box>
          </Stack>
        }
        loading={isLoading && !isContentStreaming}
        color="inherit"
        icon={AutoAwesomeOutlined}
        onClick={openMenu}
      />

      {isMenuOpen && (
        <ClickAwayListener onClickAway={closeMenu}>
          <Popover
            disableEnforceFocus
            disableScrollLock
            autoFocus={false}
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={closeMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                p: 1,
                width: PopupWidth,
                minHeight: PopupMinHeight,
                maxHeight: PopupMaxHeight,
                display: 'flex',
                flexDirection: 'column',
              },
            }}
            sx={{
              zIndex: (theme: Theme) => theme.zIndex.tooltip,
              // 1. a hack to hide the backdrop and allow to scroll the content
              bottom: 'auto',
              // 2. a hack to hide the backdrop and allow to scroll the content
              [`& .${backdropClasses.root}`]: {
                display: 'none',
              },
            }}
          >
            <Stack
              direction="row"
              position="sticky"
              top={0}
              justifyContent="space-between"
              alignItems="center"
              height={HeaderHeight}
            >
              <Stack direction="row" gap={2}>
                <Typography ml={1} variant="h5">
                  {popupTitle}
                </Typography>
                <AIThreadInputDataButton withFileLinks={withFileLinks} />
                {threadId && (environment !== 'prod' || me?.role === UserRole.BackOffice) && (
                  <Chip
                    variant="outlined"
                    endIcon={LinkIcon}
                    color="secondary"
                    size="medium"
                    tooltipContent={
                      <Link href={`${openAIPlatformUrl}${threadId}`} target="_blank" rel="noopener noreferrer">
                        {messages.submissionWorkspace.aiGeneratedContentPopup.openThreadInOpenAIPlatform}
                      </Link>
                    }
                    tooltipPlacement="bottom"
                    disableFocusListener
                  />
                )}
              </Stack>
              <Stack direction="row" alignItems="center">
                <RegenerateButton />
                <IconButton icon={Close} onClick={closeMenu} variant="text" color="secondary" />
              </Stack>
            </Stack>
            <Stack flex={1} overflow="auto" textAlign="left">
              <AIGeneratedContent withFileLinks={withFileLinks} isRichTextResponse={isRichTextResponse} />
            </Stack>
          </Popover>
        </ClickAwayListener>
      )}
    </Box>
  );
}
