import { useQueryClient } from '@tanstack/react-query';
import { SubmissionMarketClient } from 'clients';
import { QueryKeys } from 'enums';
import { SubmissionMarket } from 'types';
import { MutateProps, UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useQuerySearch } from './query/useQuery';

export function useSearchSubmissionMarket(props?: UseQueryBaseSearchOptions<SubmissionMarket>) {
  const { filter, queryOptions } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.SubmissionMarkets,
    clientClass: SubmissionMarketClient,
    filter,
    queryOptions: {
      // Default submission search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateSubmissionMarket(options?: MutateProps) {
  const {
    create: createSubmissionMarket,
    update: updateSubmissionMarket,
    destroy: destroySubmissionMarket,
  } = useMutate<SubmissionMarket, SubmissionMarket, Partial<SubmissionMarket>, SubmissionMarketClient>({
    queryKey: QueryKeys.SubmissionMarkets,
    clientClass: SubmissionMarketClient,
    cancelInvalidation: options?.cancelInvalidation,
    createOptions: { throwOnError: true },
    updateOptions: { throwOnError: true },
    destroyOptions: { throwOnError: true },
  });

  return { createSubmissionMarket, updateSubmissionMarket, destroySubmissionMarket };
}

export function useInvalidateSubmissionMarketCache() {
  const queryClient = useQueryClient();
  return async () => {
    await queryClient.invalidateQueries([QueryKeys.SubmissionMarkets]);
  };
}
