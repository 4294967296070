import { ActionTypes, Modal } from './types';

const workspaceReducer = (state: Modal, action: ActionTypes) => {
  switch (action.type) {
    case 'CHOOSE_QUOTE_SET_IS_OPEN':
      return {
        ...state,
        chooseQuote: {
          ...action.payload,
          isOpen: true,
        },
      };
    case 'CHOOSE_QUOTE_SET_IS_CLOSE':
      return {
        ...state,
        chooseQuote: {
          ...state.chooseQuote,
          isOpen: false,
        },
      };
    default:
      return state;
  }
};

export default workspaceReducer;
