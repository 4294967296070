import { messages } from 'i18n';
import FormFieldOverlay from './FormFieldOverlay';

interface DefaultOverlayProps {
  text: string;
  onClick: () => void;
}

export default function DefaultOverlay({ text, onClick }: DefaultOverlayProps) {
  return (
    <FormFieldOverlay
      text={text}
      onClick={onClick}
      bgColor="blue.50"
      labelBgColor="blue.100"
      labelText={messages.overlayChip.default}
      labelTextColor="blue.600"
    />
  );
}
