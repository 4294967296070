import { ComponentType } from 'react';
import { messages } from 'i18n';
import { CopilotSteps, PartialSubmission, SubmissionMarket } from 'types';
import Binding from './collapseContent/Binding';
import Marketing from './collapseContent/marketing/Marketing';
import PreMarketing from './collapseContent/PreMarketing';
import Proposal from './collapseContent/Proposal';

export interface CopilotStepsProps {
  stepNumber: number;
  title: string;
  subTitle: string;
  component: ComponentType<{
    submission: PartialSubmission;
    submissionMarkets: SubmissionMarket[];
    active: boolean;
  }>;
}

const stepMessages = messages.autonomousFlow.settingsModal.steps;
export const copilotStepsConfig = (name: string): Record<CopilotSteps, CopilotStepsProps> => ({
  [CopilotSteps.PreMarketing]: {
    stepNumber: 1,
    title: stepMessages.preMarketing.title,
    subTitle: stepMessages.preMarketing.subTitle,
    component: PreMarketing,
  },
  [CopilotSteps.Marketing]: {
    stepNumber: 2,
    title: stepMessages.marketing.title,
    subTitle: stepMessages.marketing.subTitle1,
    component: Marketing,
  },

  [CopilotSteps.Proposal]: {
    stepNumber: 3,
    title: stepMessages.proposal.title,
    subTitle: stepMessages.proposal.subTitle(name),
    component: Proposal,
  },
  [CopilotSteps.BindersAndPolicies]: {
    stepNumber: 4,
    title: stepMessages.binding.title,
    subTitle: stepMessages.binding.subTitle,
    component: Binding,
  },
});
