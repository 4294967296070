import { Button, MobileStepper } from '@common-components';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@icons';

interface StepperProps {
  activeStepIndex: number;
  stepsLength: number;
  handleChangeStep: (index: number) => void;
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
}
export default function Stepper({
  activeStepIndex,
  stepsLength,
  handleChangeStep,
  isPrevDisabled = false,
  isNextDisabled = false,
}: StepperProps) {
  const onChangeStep = (prevStep: number, direction: 'next' | 'prev') => {
    let index = prevStep;
    if (direction === 'next') {
      index += 1;
    } else {
      index -= 1;
    }
    handleChangeStep(index);
  };

  return (
    <MobileStepper
      variant="dots"
      steps={stepsLength}
      position="static"
      activeStep={activeStepIndex}
      sx={{ maxWidth: 400, flexGrow: 1, bgcolor: 'transparent', gap: 2 }}
      nextButton={
        <Button
          size="small"
          onClick={() => onChangeStep(activeStepIndex, 'next')}
          disabled={activeStepIndex === stepsLength - 1 || isNextDisabled}
        >
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button
          size="small"
          onClick={() => onChangeStep(activeStepIndex, 'prev')}
          disabled={activeStepIndex === 0 || isPrevDisabled}
        >
          <KeyboardArrowLeft />
        </Button>
      }
    />
  );
}
