import { Contact } from 'types';
import { Dialog } from 'components/Dialog';
import { DirtyDialogGuard } from 'broker/components/DirtyContentGuard';
import Content from './components/Content';
import { AddContactToMarketMetadata } from './types';

export type OnNewContact = (contact: Contact, userMarketId: string) => void;

export interface AddContactToMarketModalProps {
  isOpen: boolean;
  onClose: () => void;
  metaData: AddContactToMarketMetadata;
  onContactAdded?: OnNewContact;
  onContactUpdated?: (contact: Contact, userMarketId: string) => void;

  disableBackdrop?: boolean;
}

export default function AddContactToMarketModal({
  isOpen,
  onClose,
  onContactAdded,
  onContactUpdated,
  metaData,
  disableBackdrop = false,
}: AddContactToMarketModalProps) {
  return (
    <DirtyDialogGuard onClose={onClose}>
      {({ closeAttempt, setIsContentDirty, closeWithoutPrompt }) => (
        <Dialog
          open={isOpen}
          onClose={closeAttempt}
          aria-labelledby="addContactToMarketModal"
          className="cap-add-contact-to-market-modal"
          hideBackdrop={disableBackdrop}
        >
          <Content
            onClose={closeAttempt}
            onContactAdded={onContactAdded}
            onContactUpdated={onContactUpdated}
            setFormDirtyState={setIsContentDirty}
            closeWithoutPrompt={closeWithoutPrompt}
            {...metaData}
          />
        </Dialog>
      )}
    </DirtyDialogGuard>
  );
}
