import { ReactNode, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom-latest';
import { useToast } from 'hooks';
import { messages } from 'i18n';
import { AnyObject } from 'types';
import { logger } from 'utils';
import { BROKER_ROUTES } from 'broker/broker-routes';
import { ErrorContext } from './error';

export const ErrorContextProvider = ({ children }: { children: ReactNode }) => {
  const { showToast } = useToast();
  const navigate = useNavigate();

  const backToSubmissionsView = useCallback(() => {
    navigate(`/${BROKER_ROUTES.HOME}`);
  }, [navigate]);

  const onError = useCallback(
    (errorMessage: string, statusCode?: number, extraLogInfo?: AnyObject) => {
      const message = messages.general.errorMessage(errorMessage, statusCode);

      // prevent multiple toasts with the same message (which might happen during cascading errors on network failure)
      showToast('error', { message }, { persist: true, key: errorMessage, preventDuplicate: true });
      logger.log('error', extraLogInfo ? { ...extraLogInfo, message } : message);
    },
    [showToast],
  );

  const errorContextValue = useMemo(
    () => ({ onError, onSubmissionNotFound: backToSubmissionsView }),
    [backToSubmissionsView, onError],
  );

  return <ErrorContext.Provider value={errorContextValue}>{children}</ErrorContext.Provider>;
};
