import { useContext } from 'react';
import { EmailEditorStateContext } from './email-editor-context';

export default function useEmailEditorState() {
  const context = useContext(EmailEditorStateContext);

  if (context === undefined) {
    throw new Error('Email Editor State Context must be used within the Workspace');
  }

  return context;
}
