import { MenuItem, SelectChangeEvent, SxProps, Theme } from './mui-index';
import { Select } from './Select';

export interface DialogSelectProps<T> {
  options: Record<string, T | null>;
  placeholder: string;
  setValue: (value: T | null) => void;
  value: string | null;
  className?: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

export default function DialogSelect<T>({
  options,
  placeholder,
  setValue,
  value,
  className,
  disabled,
  sx = {},
}: DialogSelectProps<T>) {
  function handleChange(event: SelectChangeEvent) {
    const { value: newValue } = event.target;
    setValue(options[newValue] || null);
  }

  return (
    <Select
      sx={{ mt: 1, ...sx }}
      className={className}
      fullWidth
      margin="dense"
      variant="outlined"
      displayEmpty
      id="type"
      onChange={handleChange}
      value={value || ''}
      disabled={disabled}
    >
      <MenuItem value="">{placeholder}</MenuItem>
      {Object.entries(options).map(([key], index) => (
        <MenuItem
          /* eslint-disable-next-line react/no-array-index-key */
          key={index}
          value={key}
        >
          {key}
        </MenuItem>
      ))}
    </Select>
  );
}
