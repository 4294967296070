import { useFormContext } from 'react-hook-form';
import { formControlClasses, inputBaseClasses, Stack } from '@common-components';
import { ContactRole, OrganizationType } from 'enums';
import { messages } from 'i18n';
import { Contact, SubmissionMarket } from 'types';
import { MultiSelectionAutoCompleteClassName } from 'components/Autocomplete/MultiSelectionAutocomplete';
import { MultiSelectFormControlClassName } from 'components/hookFormComponents/FormMultiSelectAutocomplete';
import FormRadioButton from 'components/hookFormComponents/FormRadioButton';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { contactFormConfig, ContactFormField } from './types';

export const ContactForm = ({
  contact,
  submissionMarket,
}: {
  contact?: Contact;
  submissionMarket?: SubmissionMarket;
}) => {
  const { watch } = useFormContext();
  const role = watch('role', contact?.role) ?? ContactRole.Broker;

  function getHumanContactOption() {
    if (submissionMarket) {
      return submissionMarket.marketOrganizationType === OrganizationType.Broker
        ? { value: ContactRole.Broker, label: messages.marketsPage.marketType.wholesaler }
        : { value: ContactRole.Underwriter, label: messages.general.underwriter };
    }

    return contact?.organizationType === OrganizationType.Broker
      ? { value: ContactRole.Broker, label: messages.marketsPage.marketType.wholesaler }
      : { value: ContactRole.Underwriter, label: messages.general.underwriter };
  }
  return (
    <Stack
      bgcolor="common.white"
      px={3}
      mx={2}
      borderRadius={2}
      sx={{
        [`.${formControlClasses.root}:not(.${MultiSelectFormControlClassName})`]: { pb: 2 },
        [`.${formControlClasses.root}.${MultiSelectFormControlClassName}`]: { pb: 0 },
        [`.${formControlClasses.root}.${MultiSelectFormControlClassName} label`]: { margin: 0 },
        [`& .${MultiSelectionAutoCompleteClassName} .${inputBaseClasses.root}`]: { padding: 1.5 },
      }}
    >
      <FormRadioButton
        label=""
        disabled={!!contact}
        defaultValue={role}
        options={[getHumanContactOption(), { value: ContactRole.SubmissionBox, label: messages.general.submissionBox }]}
        {...contactFormConfig[ContactFormField.Role]}
      />

      {/* TODO: fix margin top */}
      {role !== ContactRole.SubmissionBox && (
        <FormTextField fullWidth {...contactFormConfig[ContactFormField.FirstName]} />
      )}
      {role !== ContactRole.SubmissionBox && (
        <FormTextField fullWidth {...contactFormConfig[ContactFormField.LastName]} />
      )}
      <FormTextField fullWidth {...contactFormConfig[ContactFormField.Email]} />

      {role !== ContactRole.SubmissionBox && (
        <FormTextField fullWidth {...contactFormConfig[ContactFormField.PhoneNumber]} />
      )}
      {role !== ContactRole.SubmissionBox && <FormTextField fullWidth {...contactFormConfig[ContactFormField.Title]} />}
    </Stack>
  );
};
