import { BoxSkeleton, Stack } from '@common-components';

export function WorkspaceMainSkeleton() {
  return (
    <Stack gap={2} p={3} bgcolor="common.white" height={1}>
      <BoxSkeleton isLoading sx={{ width: 1, height: 0.5 }} index={0} />
      <BoxSkeleton isLoading sx={{ width: 1, height: 1 }} index={1} />
      <BoxSkeleton isLoading sx={{ width: 1, height: 1 }} index={2} />
      <BoxSkeleton isLoading sx={{ width: 1, height: 1 }} index={4} />
    </Stack>
  );
}
