import { IconButton, Stack } from '@common-components';
import { DeleteOutline, RemoveRedEyeOutlined } from '@icons';
import { QuoteItemActions } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Tower/LayerItem/QuoteList/useQuoteItemActions';

export default function QuoteActions({ view, remove, hideQuoteActions }: QuoteItemActions) {
  return (
    <Stack direction="row" className="quoteActions" alignItems="center">
      <IconButton
        disabled={hideQuoteActions}
        icon={RemoveRedEyeOutlined}
        onClick={view}
        aria-label="remove"
        color="secondary"
        variant="text"
      />
      <IconButton icon={DeleteOutline} onClick={remove} aria-label="remove" color="secondary" variant="text" />
    </Stack>
  );
}
