import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Editor as TinyMCEEditor } from 'tinymce';
import { Box } from '@common-components';
import { useBoolean, useDisableRefetchOnWindowFocus } from 'hooks';
import { CustomButtonProps, EditorStyleOptions, useTinyEditorProps } from './useTinyEditorProps';

interface EditorFeatures {
  mentionKeys?: string[];
  customButtons?: CustomButtonProps[];
  additionalContent?: ReactElement;
  resolveVariable?: (variable: string) => string;
}

interface EditorEventsProps {
  onBlur?: () => void;
  onFocus?: () => void;
}

export interface EditorProps extends EditorEventsProps {
  editorContent?: string;
  initialValue?: string;
  setEditorContent: (newContent: string, editor: TinyMCEEditor) => void;
  onDirty?: () => void;
  editorFeatures?: EditorFeatures;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  toolbar?: boolean;
  onPastePreprocess?: (editor: any, args: any) => void;
  clickHandler?: (e: any) => void;
  editorStyleProps?: EditorStyleOptions;
  excludeToolbarProps?: string[];
  removeFontFamilyOnPaste?: boolean;
}

const TINYMCE_API_KEY = process.env.REACT_APP_TINYMCE_API_KEY;

// Note that the element containing the Editor *must* have a height for the TinyEditor's text box to have height
export const Editor = ({
  editorContent,
  initialValue,
  setEditorContent,
  editorFeatures = {},
  disabled = false,
  placeholder,
  onDirty,
  autoFocus,
  clickHandler,
  toolbar = true,
  onPastePreprocess,
  editorStyleProps,
  excludeToolbarProps,
  ...events
}: EditorProps) => {
  const { mentionKeys, customButtons, additionalContent, resolveVariable } = editorFeatures;
  const editorProps = useTinyEditorProps(
    mentionKeys,
    customButtons,
    placeholder,
    resolveVariable,
    undefined,
    clickHandler,
    onPastePreprocess,
    editorStyleProps,
    excludeToolbarProps,
  );

  const [isFocus, { on: setFocusOn, off: setFocusOff }] = useBoolean(false);

  // disable query on focus refetching if tiny is in focus
  useDisableRefetchOnWindowFocus(isFocus);

  return (
    <>
      <Box
        sx={{ '& .tox-tinymce': { border: 'none', backgroundColor: 'common.white', height: '100% !important' } }}
        className="cap-editor"
        flex={1}
      >
        {/* This ErrorBoundary is meant to catch errors we encounter in the unmount of the component so the fallback is not really rendered.
            So far we didn't encounter errors in the rendering flow while the component is mounted, but even if they will happen
            we will render an empty box instead of crashing the page and will get an error in the logs.
        */}
        <ErrorBoundary fallback={<Box />}>
          <TinyEditor
            {...events}
            apiKey={TINYMCE_API_KEY}
            initialValue={initialValue}
            value={editorContent}
            onEditorChange={setEditorContent}
            onDirty={onDirty}
            disabled={disabled}
            onFocus={() => {
              setFocusOn();
              events.onFocus?.();
            }}
            onBlur={() => {
              setFocusOff();
              events.onBlur?.();
            }}
            init={{
              ...editorProps,
              ...(autoFocus && { auto_focus: true }),
              ...(!toolbar && { toolbar: false }),
            }}
          />
        </ErrorBoundary>
      </Box>
      {additionalContent && (
        <Box flexDirection="row" display="flex" justifyContent="space-between" pt={1}>
          {additionalContent}
        </Box>
      )}
    </>
  );
};
