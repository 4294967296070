import { useMemo } from 'react';
import { CoverageLine, MarketRequestType } from 'enums';
import { usePagination } from 'hooks';
import { UserMarket } from 'types';
import useUserMarketsFilter from 'broker/components/UserMarkets/hooks/useUserMarketsFilter';
import { UserMarketSortTypes } from 'broker/components/UserMarkets/utils';
import { GetUserMarketsFilterProps } from './types';

export interface UserMarketsFilterProps {
  markets: UserMarket[];
  preferredCoverageLines: CoverageLine[];
  isLoadingMarkets: boolean;
  isFetchingMarkets: boolean;
  marketsIdsInSubmission: string[];
  primaryIncumbentMarketIds: string[];
  xsIncumbentMarketIds: string[];
  marketRequestType?: MarketRequestType;
}

export default function useGetUserMarketsFilter({
  markets,
  preferredCoverageLines,
  isFetchingMarkets,
  isLoadingMarkets,
  marketsIdsInSubmission,
  primaryIncumbentMarketIds,
  xsIncumbentMarketIds,
  marketRequestType,
}: UserMarketsFilterProps): GetUserMarketsFilterProps {
  const { page, setPage, rowsPerPage, setRowsPerPage, paginationReset } = usePagination();
  const incumbentMarketIds = useMemo(
    () => [...primaryIncumbentMarketIds, ...xsIncumbentMarketIds],
    [primaryIncumbentMarketIds, xsIncumbentMarketIds],
  );
  const filteredUserMarkets = useUserMarketsFilter(
    markets,
    isLoadingMarkets,
    isFetchingMarkets,
    paginationReset,
    marketsIdsInSubmission,
    incumbentMarketIds,
    UserMarketSortTypes.Asc,
    preferredCoverageLines,
  );

  const sortedAndFilteredItems = useMemo(() => {
    const sortByCoverageLines = filteredUserMarkets.sortedAndFilteredItems.sort(
      (a, b) =>
        Number(b.coverageLines.some((contactCoverageLine) => preferredCoverageLines.includes(contactCoverageLine))) -
        Number(a.coverageLines.some((contactCoverageLine) => preferredCoverageLines.includes(contactCoverageLine))),
    );
    const primaryIncumbentMarkets = sortByCoverageLines.filter((market) =>
      primaryIncumbentMarketIds.includes(market.id),
    );
    const xsIncumbentMarkets = sortByCoverageLines.filter((market) => xsIncumbentMarketIds.includes(market.id));
    const restMarkets = sortByCoverageLines.filter(
      (market) => !primaryIncumbentMarketIds.includes(market.id) && !xsIncumbentMarketIds.includes(market.id),
    );

    if (marketRequestType === MarketRequestType.Primary) {
      return [...primaryIncumbentMarkets, ...xsIncumbentMarkets, ...restMarkets];
    }
    return [...xsIncumbentMarkets, ...primaryIncumbentMarkets, ...restMarkets];
  }, [
    filteredUserMarkets.sortedAndFilteredItems,
    marketRequestType,
    preferredCoverageLines,
    primaryIncumbentMarketIds,
    xsIncumbentMarketIds,
  ]);

  return {
    ...filteredUserMarkets,
    sortedAndFilteredItems,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  };
}
