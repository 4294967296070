import { ReactNode } from 'react';
import { Stack } from '@common-components';
import { LinkOff as LinkOffIcon } from '@icons';
import { iconFontSize } from 'themes';
import TemplateIcon from 'broker/components/common/TemplateIcon';
import { Draft } from 'broker/components/EmailEditor/store/types';
import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { RecipientsGroupsAvailableRecipients } from 'broker/components/EmailEditor/types';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';
import { RecipientGroupChip } from './RecipientGroupChip';

interface RecipientGroupsListProps {
  recipientGroups: RecipientGroup[];
  recipientGroupsAvailableContacts: RecipientsGroupsAvailableRecipients;
  isReadOnly?: boolean;
  itemsToAppend?: ReactNode;
}

export const RecipientGroupsList = ({
  recipientGroups,
  recipientGroupsAvailableContacts,
  itemsToAppend,
  isReadOnly,
}: RecipientGroupsListProps) => {
  const {
    activeDraft,
    isSingleRecipientMode,
    recipients: { selectedRecipientGroupId },
    draftTemplates: { recipientGroupToDraft },
  } = useEmailEditorState();

  const {
    recipients: { setSelectedRecipientGroupId },
    setDraftEditorMode,
  } = useEmailEditorActions();

  const getDraftMode = (draft: Draft) =>
    draft.detachedTemplateInstance ? DraftEditorMode.EditForMarket : DraftEditorMode.Preview;

  const handleRecipientGroupClick = (recipientGroupId: string) => {
    const prevDraft = activeDraft!;
    const prevMode = getDraftMode(prevDraft);

    const currentDraft = recipientGroupToDraft[recipientGroupId];
    const currentMode = getDraftMode(currentDraft);

    if (prevMode !== currentMode) {
      setDraftEditorMode(currentMode);
    }

    setSelectedRecipientGroupId(recipientGroupId);
  };

  const isEveryRecipientGroupWithTheSameTemplate = () => {
    const firstRecipientGroup = recipientGroups[0];
    const firstRecipientGroupTemplateId = recipientGroupToDraft[firstRecipientGroup.id]?.chosenTemplateId;

    return recipientGroups.every(
      (recipientGroup) => recipientGroupToDraft[recipientGroup.id]?.chosenTemplateId === firstRecipientGroupTemplateId,
    );
  };

  const isTemplateSameAsSelectedRecipientGroup = (recipientGroup: RecipientGroup) => {
    if (!selectedRecipientGroupId) {
      return false;
    }

    if (isEveryRecipientGroupWithTheSameTemplate()) {
      return false;
    }

    // no need to check if the template is the same if the recipient group is detached
    if (activeDraft?.detachedTemplateInstance) {
      return false;
    }

    const selectedRecipientGroupTemplateId = activeDraft?.chosenTemplateId;
    if (!selectedRecipientGroupTemplateId) {
      return false;
    }

    return selectedRecipientGroupTemplateId === recipientGroupToDraft[recipientGroup.id]?.chosenTemplateId;
  };

  const getIcon = (recipientGroup: RecipientGroup) => {
    if (!selectedRecipientGroupId) {
      return undefined;
    }

    const isDetached = recipientGroupToDraft[recipientGroup.id]?.detachedTemplateInstance;

    if (isDetached && !isSingleRecipientMode) {
      return <LinkOffIcon sx={{ fontSize: iconFontSize }} />;
    }

    if (isTemplateSameAsSelectedRecipientGroup(recipientGroup)) {
      // adjust the template icon to the text because it looks off due to the icon shape
      return <TemplateIcon spacingTop="2px" />;
    }

    return undefined;
  };

  return (
    <Stack direction="row" alignItems="center" rowGap={0.5} flexWrap="wrap">
      {recipientGroups.map(
        (recipientGroup: RecipientGroup) =>
          recipientGroupsAvailableContacts[recipientGroup.id] && (
            <RecipientGroupChip
              icon={getIcon(recipientGroup)}
              key={recipientGroup.id}
              recipientGroup={recipientGroup}
              selected={selectedRecipientGroupId === recipientGroup.id}
              recipientGroupsAvailableContacts={recipientGroupsAvailableContacts}
              onClick={handleRecipientGroupClick}
              isReadOnly={isReadOnly}
            />
          ),
      )}
      {itemsToAppend}
    </Stack>
  );
};
