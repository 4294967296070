import { BoxItem } from 'box-ui-elements/es';
import { isEmpty } from 'lodash';
import { ReactNode } from 'react';
import { Button } from '@common-components';
import { EmailTemplateType, NylasAuthenticationStatus, UserRole } from 'enums';
import { useCurrentUser, useRetrieveNylasAuthenticationStatus } from 'hooks';
import { messages } from 'i18n';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';
import { useIsIntegrationEnabled } from 'broker/components/Emails/useIsIntegrationEnabled';
import { EmailTemplateConfig } from 'broker/configuration-mappers/email-template-config';
import { BrokenAttachmentsGuard } from './BrokenAttachmentGuard';
import { getUnresolvedVariables } from './getUnresolvedVariables';
import { NoAttachmentsGuard } from './NoAttachmentsGuard';
import RecipientsConfirmationGuard from './RecipientsConfirmationGuard';
import SendEmailNonProductionGuard from './SendEmailNonProductionGuard';

interface SendEmailButtonProps {
  recipientGroups: RecipientGroup[];
  attachedFiles: BoxItem[];
  onSend: () => void;
  isDisabled: boolean;
  disabledTooltipContent?: string;
  buttonCaption?: string;
  emailTemplateType?: EmailTemplateType;
  buttonSize?: 'small' | 'medium' | 'large';
  isLoading?: boolean;
  isCustomerEmail: boolean;
  isCopilotEnabled?: boolean;
  CustomConfirmationMessage?: () => ReactNode;
  resolvedMessages: { body: string; subject: string }[];
}

export default function SendEmailButton({
  onSend,
  recipientGroups,
  attachedFiles,
  isDisabled,
  disabledTooltipContent,
  buttonCaption = `${messages.buttons.send} (${recipientGroups.length})`,
  emailTemplateType,
  buttonSize,
  isLoading = false,
  isCustomerEmail,
  isCopilotEnabled,
  CustomConfirmationMessage,
  resolvedMessages,
}: SendEmailButtonProps) {
  const { me } = useCurrentUser();

  const emailTypeConfig = emailTemplateType ? EmailTemplateConfig[emailTemplateType] : undefined;

  const isIntegrationEnabled = useIsIntegrationEnabled();

  const { data: nylasUser, isInitialLoading: isLoadingNylasAuthStatus } = useRetrieveNylasAuthenticationStatus({
    enabled: isIntegrationEnabled,
  });

  const { unresolvedVariables, unresolvedVariablesTooltip } = getUnresolvedVariables({ resolvedMessages });

  const isNylasAuthenticationRequired =
    isIntegrationEnabled && nylasUser?.authenticationStatus !== NylasAuthenticationStatus.Authenticated;

  const isAllowSendByUserRole = () => {
    if (me?.role === UserRole.BackOffice) {
      // The BO sends email to the end-user
      if (emailTypeConfig?.backOfficeTemplate) {
        return true;
      }

      // When co-pilot is enabled, the BO can send all email types
      return !!isCopilotEnabled;
    }

    return true;
  };

  const isUserAllowSend = isAllowSendByUserRole();

  const disabledSendButton =
    isDisabled || unresolvedVariables.length > 0 || !isUserAllowSend || isNylasAuthenticationRequired;

  const getDisabledTooltipContent = () => {
    if (!isUserAllowSend) {
      return messages.emailEditor.sendEmailDisabledForBackofficeToolTip;
    }
    if (isNylasAuthenticationRequired) {
      return messages.emailEditor.sendEmailDisabledForUnauthenticatedUser;
    }
    if (isEmpty(unresolvedVariables)) {
      return disabledTooltipContent || messages.emailEditor.sendEmailDefaultDisabledTooltip;
    }
    return unresolvedVariablesTooltip;
  };

  return (
    <SendEmailNonProductionGuard recipientGroups={recipientGroups} onSend={onSend}>
      {({ guardedAction: nonProductionGuardedSend }) => (
        <RecipientsConfirmationGuard
          recipientGroups={recipientGroups}
          onSend={nonProductionGuardedSend}
          CustomConfirmationMessage={CustomConfirmationMessage}
        >
          {({ guardedAction: recipientsGuardedSend }) => (
            <BrokenAttachmentsGuard attachedFiles={attachedFiles} onSend={recipientsGuardedSend}>
              {({ guardedAction: brokenAttachmentsGuardedSend }) => (
                <NoAttachmentsGuard
                  onSend={brokenAttachmentsGuardedSend}
                  attachedFiles={attachedFiles}
                  isCustomerEmail={isCustomerEmail}
                >
                  {({ guardedAction: noAttachmentsGuardedSend }) => (
                    <Button
                      variant="contained"
                      size={buttonSize}
                      disabled={disabledSendButton}
                      disabledTooltipContent={getDisabledTooltipContent()}
                      onClick={noAttachmentsGuardedSend}
                      loading={isLoadingNylasAuthStatus || isLoading}
                    >
                      {buttonCaption}
                    </Button>
                  )}
                </NoAttachmentsGuard>
              )}
            </BrokenAttachmentsGuard>
          )}
        </RecipientsConfirmationGuard>
      )}
    </SendEmailNonProductionGuard>
  );
}
