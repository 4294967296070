import { FormFieldsConfigProps } from 'hooks';
import { SubjectivitiesFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';

type SubjectivitiesFormArrayFormFieldsConfig = Record<
  SubjectivitiesFieldsNames,
  FormFieldsConfigProps<SubjectivitiesFieldsNames>
>;

export const subjectivitiesFormArrayFieldsConfig: SubjectivitiesFormArrayFormFieldsConfig = {
  [SubjectivitiesFieldsNames.Value]: {
    label: '',
    placeholder: '',
    name: SubjectivitiesFieldsNames.Value,
    id: SubjectivitiesFieldsNames.Value,
  },
};
