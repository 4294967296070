import { BoxItem } from 'box-ui-elements/es';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { Box, Button, Divider, IconButton, NewMenu, Stack, Typography } from '@common-components';
import { Close as CloseIcon, ContentCopy } from '@icons';
import { useConditionallyRenderedModal, useHotjar, useOptionsMenu, useSearchEmailTemplate } from 'hooks';
import { useMutateInboundEmailMessage } from 'hooks/api/inboundEmailMessage';
import { messages } from 'i18n';
import { getDisplayDateWithHoursAmPm, getDotSeparated, manipulateHtml, truncateMultiLineText } from 'utils';
import { HotjarEvents } from 'utils/hotjar-events';
import { getRecipientName } from 'broker/components/Emails/recipient-utils';
import { EmailItemType, isOutboundType } from 'broker/components/Emails/types';
import { useGetEmail } from 'broker/components/Emails/useGetEmail';
import EmailSkeleton from 'broker/components/skeletons/EmailSkeleton';
import ContainedFileViewerModal from 'broker/dialogs/ContainedFileViewerModal';
import { OpenContainedFileViewerModalProps } from 'broker/dialogs/ContainedFileViewerModal/ContainedFileViewerModal';
import { EmailEditorPageMode } from 'broker/enums';
import { useFileActions } from 'broker/hooks';
import { ReplyMode } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EmailReplyInnerPage/types';
import { useGetFileSecondaryActions } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Files/useGetFileSecondaryActions';
import { ToolboxNavigationSize } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/styles';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import Attachments from './Attachments';
import EmailMenus from './EmailMenus';
import { RecipientsLists } from './RecipientsLists';
import { useCustomerTemplatesReplyMenu } from './useCustomerTemplatesReplyMenu';
import useEmailAction from './useEmailAction';
import { useGetEmailListItem } from './UseGetEmailListItem';
import { EmailIdentifierProps, isInboundCustomerEmail } from './utils';

interface EmailViewProps extends EmailIdentifierProps {
  onClose: () => void;
  hideEmailActions: boolean;
}

// single view should overlay the email list
const emailViewStyle = {
  overflowY: 'hidden',
  zIndex: 1,
  overflowX: 'hidden',
  position: 'absolute',
  top: 0,
  left: ToolboxNavigationSize,
  right: 0,
  bottom: 0,
  bgcolor: 'common.white',
};

export default function EmailView({ emailId, emailType, onClose, hideEmailActions = false }: EmailViewProps) {
  const { partialSubmission, markets } = useSubmissionsWorkspace();
  const hotjar = useHotjar();
  const { unifiedEmail, attachments } = useGetEmail({
    emailId,
    type: emailType,
  });
  const optionsMenuState = useOptionsMenu();

  const emailListItemAttribute = useGetEmailListItem(emailId);
  const showReplyMenu =
    emailListItemAttribute && !!partialSubmission && isInboundCustomerEmail(emailListItemAttribute, partialSubmission);

  const { navigateToEmailReplyEditor } = useEmailAction();
  const { deleteFile, downloadFile } = useFileActions();
  const [deletedAttachments, setDeletedAttachments] = useState<string[]>([]);
  const {
    openModal: openContainedFileViewerModal,
    closeModal: closeContainedFileViewerModal,
    modalState: containedFileViewerState,
  } = useConditionallyRenderedModal<OpenContainedFileViewerModalProps>({});
  const { reply, followUp, assign } = messages.submissionWorkspace.emailsTab.menuOptions;
  const { assignEmailToSubmission } = useMutateInboundEmailMessage();
  const getFileSecondaryActions = useGetFileSecondaryActions(markets);

  const assignToSubmission = async (inboundEmailMessageId: string) => {
    await assignEmailToSubmission.mutateAsync({
      submissionId: partialSubmission!.id,
      inboundEmailMessageId,
    });
    onClose();
  };

  const handleAttachmentClicked = (file: BoxItem) => {
    openContainedFileViewerModal({ file });
  };

  const handleDeleteAttachment = async (item: BoxItem) => {
    await deleteFile(item);
    setDeletedAttachments((prevDeleted: string[]) => [...prevDeleted, item.id]);
    closeContainedFileViewerModal();
  };

  const handleDuplicate = () => {
    hotjar.event(HotjarEvents.EmailDuplicate);
    navigateToEmailReplyEditor({ emailId, emailType }, ReplyMode.Duplicate);
  };

  const handleReply = (mode?: EmailEditorPageMode) => {
    hotjar.event(HotjarEvents.EmailReply);
    navigateToEmailReplyEditor({ emailId, emailType }, ReplyMode.FollowUp, mode);
  };

  const modifiedEmailHtmlBody = useMemo(
    () => manipulateHtml(unifiedEmail?.body || '', { addTargetBlankToLink: true }),
    [unifiedEmail?.body],
  );

  const { items: templates, isLoading: isTemplatesLoading } = useSearchEmailTemplate({ enabled: showReplyMenu });

  const customerTemplatesReplyMenu = useCustomerTemplatesReplyMenu({
    handleReplyTemplateClick: handleReply,
    templates,
  });

  if (!unifiedEmail) {
    return (
      <Stack direction="column" flex={1} sx={emailViewStyle}>
        <EmailSkeleton />
      </Stack>
    );
  }

  return (
    <Stack direction="column" id="cap-email-toolbox-preview" flex={1} sx={emailViewStyle}>
      <Stack px={3} pt={2}>
        <Stack mb={2} mt={1.5} justifyContent="space-between" direction="row" alignItems="center">
          <Box>
            <IconButton
              color="secondary"
              edge="start"
              aria-label="Back"
              icon={CloseIcon}
              onClick={onClose}
              size="small"
            />
          </Box>
          {!hideEmailActions && (
            <Stack direction="row" alignItems="center" gap={0.5}>
              {isOutboundType(emailType) && (
                <IconButton
                  variant="text"
                  icon={ContentCopy}
                  size="small"
                  color="inherit"
                  onClick={handleDuplicate}
                  disabled={!isOutboundType(emailType)}
                />
              )}
              {emailType !== EmailItemType.UnassignedInbound && (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={showReplyMenu ? optionsMenuState.openMenu : () => handleReply()}
                    loading={isTemplatesLoading}
                  >
                    {isOutboundType(emailType) ? followUp : reply}
                  </Button>
                  {showReplyMenu && customerTemplatesReplyMenu && (
                    <NewMenu optionsMenuState={optionsMenuState} menuItems={customerTemplatesReplyMenu} />
                  )}
                </>
              )}
              {emailType === EmailItemType.UnassignedInbound && (
                <Button variant="contained" size="small" onClick={() => assignToSubmission(emailId)}>
                  {assign}
                </Button>
              )}
            </Stack>
          )}
        </Stack>
        {emailListItemAttribute && partialSubmission && (
          <Box mb={1}>
            <EmailMenus
              email={emailListItemAttribute}
              emailType={emailType}
              submission={partialSubmission}
              markets={markets}
            />
          </Box>
        )}
        <Typography title={unifiedEmail.subject} variant="h4" sx={{ ...truncateMultiLineText(3) }}>
          {unifiedEmail.subject}
        </Typography>
        <Stack direction="row" mb={2}>
          <Typography variant="caption" color="textSecondary">
            {getDotSeparated([
              ...(isOutboundType(unifiedEmail.emailType)
                ? [getRecipientName(unifiedEmail.recipients, unifiedEmail.recipientOrganization)]
                : [unifiedEmail.senderEmail, unifiedEmail.senderOrganization?.name]),
              getDisplayDateWithHoursAmPm(unifiedEmail.createdAt),
            ])}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack flex={1} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <RecipientsLists unifiedEmail={unifiedEmail} />
        <Typography variant="body2" mb={4} component="div">
          <Box
            sx={{ overflowY: 'auto', overflowX: 'auto', px: 3 }}
            dangerouslySetInnerHTML={{
              __html: modifiedEmailHtmlBody,
            }}
          />
        </Typography>
      </Stack>
      {!isEmpty(attachments) && (
        <>
          <Divider />
          <Stack p={2}>
            <Attachments
              attachments={attachments}
              onAttachmentClick={handleAttachmentClicked}
              deletedAttachments={deletedAttachments}
            />
          </Stack>
        </>
      )}
      {containedFileViewerState.shouldRender && (
        <ContainedFileViewerModal
          containerId="cap-email-toolbox-preview"
          onDownloadClicked={(item: BoxItem) => downloadFile(item)}
          onDeleteClicked={handleDeleteAttachment}
          isOpened={containedFileViewerState.isOpen}
          onClose={closeContainedFileViewerModal}
          getFileSecondaryActions={hideEmailActions ? undefined : getFileSecondaryActions}
          {...containedFileViewerState.modalProps!}
        />
      )}
    </Stack>
  );
}
