import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

export const buttonVariable = (link: string, text: string) =>
    renderToStaticMarkup(
        <table role="presentation" contentEditable="false">
            <tbody>
                <tr>
                    <td
                        style={{ borderRadius: '4px', backgroundColor: '#33057E', padding: '10px 16px' }}
                        contentEditable="false"
                    >
                        <a
                            href={link}
                            target="_blank"
                            style={{
                                color: '#fff',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                textDecoration: 'none',
                            }}
                            rel="noreferrer"
                        >
                            {text}
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>,
    );
