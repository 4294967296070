import * as yup from 'yup';
import { messages } from 'i18n';
import { AgentFormFieldsNames, FormFieldsConfig } from './types';

export enum UrlParam {
  Id = 'id',
}

export enum FormMode {
  Create = 'create',
  Update = 'update',
}

const { requiredField } = messages.formMessages;

export const formFieldsConfig: FormFieldsConfig = {
  [AgentFormFieldsNames.FirstName]: {
    label: messages.agenciesPage.agentForm.labels.firstName,
    name: AgentFormFieldsNames.FirstName,
    id: AgentFormFieldsNames.FirstName,
    optional: false,
  },
  [AgentFormFieldsNames.LastName]: {
    label: messages.agenciesPage.agentForm.labels.lastName,
    name: AgentFormFieldsNames.LastName,
    id: AgentFormFieldsNames.LastName,
    optional: false,
  },
  [AgentFormFieldsNames.Email]: {
    label: messages.agenciesPage.agentForm.labels.email,
    name: AgentFormFieldsNames.Email,
    id: AgentFormFieldsNames.Email,
    optional: false,
  },
  [AgentFormFieldsNames.PhoneNumber]: {
    label: messages.agenciesPage.agentForm.labels.phone,
    name: AgentFormFieldsNames.PhoneNumber,
    id: AgentFormFieldsNames.PhoneNumber,
    optional: true,
  },
  [AgentFormFieldsNames.Title]: {
    label: messages.agenciesPage.agentForm.labels.title,
    name: AgentFormFieldsNames.Title,
    id: AgentFormFieldsNames.Title,
    optional: true,
  },
};

export const schema = yup
  .object()
  .shape({
    firstName: yup.string().default('').required(requiredField),
    lastName: yup.string().default('').required(requiredField),
    email: yup.string().email().default('').required(requiredField),
    title: yup.string().default(''),
    phoneNumber: yup.string().default(''),
  })
  .required();
