import { get, isEmpty } from 'lodash';
import { FC, ReactElement, useCallback } from 'react';
import { FieldValues, useFieldArray, UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@common-components';
import { Delete as DeleteIcon } from '@icons';
import { useBoolean, useDeepCompareEffect } from 'hooks';
import { messages } from 'i18n';
import { FormFieldsSpacingVertical } from 'themes';
import { LabelStyles } from 'components/hookFormComponents/styles';

export interface FormArrayFieldProps {
  getFieldName: (fieldName: string) => string;
  index: number;
  disabled?: boolean;
  autofocusFirstField?: boolean;
  defaultValue?: any;
}

const fieldWrapper = 'array-field-wrapper';

interface FormArrayFieldsProps<T> {
  name: string;
  title?: string;
  boldTitle?: boolean;
  subtitle?: string;
  emptyOnStart?: boolean;
  defaultValue?: T;
  FormArrayFieldComponent: FC<FormArrayFieldProps>;
  disabled?: boolean;
  fixedRows?: boolean;
  useGrid?: boolean;
  customButtonStyles?: SxProps<Theme>;
  deleteButtonMargin?: number;
  customHeader?: (
    props: UseFieldArrayReturn<FieldValues, string, 'id'>,
    onAddAnother: () => Promise<void>,
  ) => ReactElement;
  additionalProps?: any;
}

export default function FormArrayFields<T>({
  name,
  title = '',
  boldTitle = false,
  subtitle = '',
  defaultValue,
  FormArrayFieldComponent,
  disabled,
  emptyOnStart = false,
  fixedRows = false,
  useGrid = true,
  customHeader,
  deleteButtonMargin = 2.5,
  additionalProps,
}: FormArrayFieldsProps<T>) {
  const { control, trigger, formState } = useFormContext();

  const formArrayProps = useFieldArray({ control, name });
  const { fields, append, remove } = formArrayProps;

  const [isAutofocus, { on: autofocusOn }] = useBoolean(false);

  const errorMessage = get(formState.errors, name)?.message;
  const onAddAnotherClick = useCallback(async () => {
    if (fields.length === 0) {
      append(defaultValue!);
      autofocusOn();
    } else {
      const canAddAnotherItem = await trigger(name);
      if (canAddAnotherItem) {
        append(defaultValue!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [append, defaultValue, name, fields]);

  useDeepCompareEffect(() => {
    if (defaultValue && !emptyOnStart && isEmpty(fields)) {
      append(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emptyOnStart, defaultValue]);

  const renderFormArrayFieldComponent = (field: Record<'id', string>, index: number, ownAdditionalProps?: any) => (
    <FormArrayFieldComponent
      key={field.id}
      getFieldName={(fieldName: string) => (fieldName ? `${name}.${index}.${fieldName}` : `${name}.${index}`)}
      index={index}
      disabled={disabled}
      autofocusFirstField={isAutofocus && index === fields.length - 1}
      defaultValue={defaultValue}
      {...ownAdditionalProps}
    />
  );

  return (
    <FormControl sx={{ mb: 2, width: 1 }} error={!!errorMessage} component="fieldset" variant="standard">
      {title && (
        <FormLabel sx={LabelStyles}>
          <Stack direction="row" gap={1} alignItems="baseline">
            <Typography variant={boldTitle ? 'body2Bold' : 'body2'} noWrap>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="caption" noWrap>
                {subtitle}
              </Typography>
            )}
          </Stack>
        </FormLabel>
      )}

      {customHeader?.(formArrayProps, onAddAnotherClick)}

      <FormGroup sx={{ width: 1, flexDirection: 'row' }}>
        {useGrid ? (
          <Stack width={1}>
            <Stack gap={FormFieldsSpacingVertical} id={`${name}-${fieldWrapper}`}>
              {fields.map((field, index) => (
                <Stack key={field.id} direction="row" alignItems="flex-start" gap={0.5}>
                  <Stack width={1}>{renderFormArrayFieldComponent(field, index, additionalProps)}</Stack>
                  {!disabled && !fixedRows && (
                    <Stack width={32} alignItems="center" justifyContent="center" mt={deleteButtonMargin}>
                      <IconButton
                        icon={DeleteIcon}
                        onClick={() => remove(index)}
                        disabled={fields.length <= 1}
                        tooltipContent={messages.buttons.delete}
                        disabledTooltipContent={messages.buttons.disabled}
                        tooltipPlacement="left"
                        variant="text"
                        color="secondary"
                        size="small"
                        aria-label={messages.buttons.delete}
                      />
                    </Stack>
                  )}
                </Stack>
              ))}
            </Stack>

            {!disabled && defaultValue && !fixedRows && !customHeader && (
              <Button onClick={onAddAnotherClick} variant="outlined" size="small">
                {fields.length === 0 ? messages.buttons.addFirst('') : messages.buttons.addAnother('')}
              </Button>
            )}
          </Stack>
        ) : (
          <>{fields.map((field, index) => renderFormArrayFieldComponent(field, index, additionalProps))}</>
        )}
      </FormGroup>
    </FormControl>
  );
}
