import { isEqual, sortBy } from 'lodash';

export function arraysContainSameValues(array1: any[], array2: any[]) {
  // Sort both arrays
  const sortedArray1 = sortBy(array1);
  const sortedArray2 = sortBy(array2);

  // Use isEqual to compare sorted arrays
  return isEqual(sortedArray1, sortedArray2);
}
