import { Button, Stack } from '@common-components';
import { messages } from 'i18n';
import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';

export default function EditForAllActions() {
  const {
    draftEditing: { isEditingContentDirty },
  } = useEmailEditorState();
  const {
    draftEditing: { onEditForAllCancel, onEditForAllSave },
  } = useEmailEditorActions();

  return (
    <Stack
      direction="row"
      gap={1}
      sx={(theme) => ({
        transition: theme.transitions.create([], {
          duration: theme.transitions.duration.shortest,
        }),
      })}
    >
      <Button variant="outlined" size="small" onClick={onEditForAllCancel}>
        {messages.emailEditor.discard}
      </Button>

      <Button disabled={!isEditingContentDirty} variant="contained" size="small" onClick={onEditForAllSave}>
        {messages.emailEditor.saveTemplate}
      </Button>
    </Stack>
  );
}
