import { flatten } from 'lodash';
import shortUUID from 'short-uuid';
import { messages } from 'i18n';
import { SubmissionMarket } from 'types';

import { ExpiringTowerReportRow } from './types';

export function getExpiringTowerReportRows(submissionMarkets: SubmissionMarket[]): ExpiringTowerReportRow[] {
  return flatten(
    submissionMarkets.map(
      (market) =>
        market.incumbentInfo.map((incumbent) => ({
          ...incumbent,
          id: shortUUID().new(),
          marketName: market.marketName,
          marketId: market.userMarketId,
          layer: incumbent.isPrimary ? messages.general.primary : messages.general.excess,
        })) || [],
    ),
  );
}
