import { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useHotjar, useLocalStorage } from 'hooks';
import { FilterElementProps } from 'utils';
import { HotjarEvents } from 'utils/hotjar-events';

export interface SubmissionFilterAttributes {
  isAllSelected: boolean;
  handleFilterChange: (event: ChangeEvent<HTMLInputElement>, key: string) => void;
  handleSelectAll: () => void;
  filters: FilterElementProps[];
  setFilters: Dispatch<SetStateAction<FilterElementProps[]>>;
}

interface useSubmissionFilterProps {
  storageKey: string;
  baseFiltersList: FilterElementProps[];
  hotjarEvent: HotjarEvents;
  paginationReset: () => void;
}
export default function useSubmissionFilter({
  storageKey,
  baseFiltersList,
  hotjarEvent,
  paginationReset,
}: useSubmissionFilterProps): SubmissionFilterAttributes {
  const hotjar = useHotjar();

  const [filters, setFilters] = useLocalStorage(storageKey, baseFiltersList);
  const isAllSelected = useMemo(() => filters.every((filter) => !filter.checked), [filters]);

  function updateCheckedFilter(
    existingFilters: FilterElementProps[],
    key: string,
    event: ChangeEvent<HTMLInputElement>,
  ) {
    const changedFilter = existingFilters.find((filter) => filter.key === key);
    if (changedFilter) {
      changedFilter.checked = event.target.checked;
    }
    return existingFilters;
  }

  const handleSelectAll = useCallback(() => {
    setFilters((prevFilters) => prevFilters.map((filter) => ({ ...filter, checked: false })));
  }, [setFilters]);

  const handleFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, key: string) => {
      hotjar.event(hotjarEvent);
      paginationReset();
      setFilters((prevFilters) => updateCheckedFilter([...prevFilters], key, event));
    },
    [hotjar, hotjarEvent, paginationReset, setFilters],
  );

  return {
    filters,
    setFilters,
    isAllSelected,
    handleSelectAll,
    handleFilterChange,
  };
}
