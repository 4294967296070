import { fileTypeConfig } from '@common/config';
import { useCallback } from 'react';
import { LabelOutlined } from '@icons';
import { BoxTemplateLabels, FileType, UserRole } from 'enums';
import { messages } from 'i18n';
import { getFileMetadataField } from 'utils/files-utils';
import { defaultFileTypesSelection } from 'broker/components/FilesExplorer/config';
import { LabelMetadata } from 'broker/components/FilesExplorer/types';
import { LabelsMenuAnchorType } from 'broker/components/LabelsMenu';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export function useFileLabelsMetadata() {
  const { markets } = useSubmissionsWorkspace();

  const getLabelsMetadata = useCallback((): LabelMetadata[] => {
    const labelsMetadata: LabelMetadata[] = [
      {
        options: defaultFileTypesSelection.map((selectableFileType) => ({
          label: fileTypeConfig[selectableFileType].text,
          value: selectableFileType as string,
        })),
        metadataFieldName: BoxTemplateLabels.FileType,
        icon: LabelOutlined,
        getAnchorType: (file, value) => {
          if (!value) {
            return LabelsMenuAnchorType.AddLabel;
          }
          const classifiedByRole = getFileMetadataField(file, BoxTemplateLabels.ClassifiedByRole);
          if (classifiedByRole === UserRole.Machine) {
            return LabelsMenuAnchorType.EditLabelCoPilot;
          }

          return LabelsMenuAnchorType.EditLabel;
        },
        // This hack allows file types that are not selectable in the UI but may be present in metadata
        getSelectedOption: (value) => ({ label: fileTypeConfig[value as FileType]?.text, value }),
      },
    ];

    // If there are no markets we don't show the market label since the options will be empty
    if (markets.length > 0) {
      labelsMetadata.push({
        options: markets.map((market) => ({ value: market.id, label: market.marketName })),
        metadataFieldName: BoxTemplateLabels.MarketId,
        // @ts-ignore
        getAnchorType(file, value) {
          if (!value) {
            return LabelsMenuAnchorType.AddLabel;
          }

          return LabelsMenuAnchorType.EditLabel;
        },
        addLabelText: messages.labelsMenu.addMarketLabelButton,
        multiSelect: true,
      });
    }
    return labelsMetadata;
  }, [markets]);

  return {
    getLabelsMetadata,
  };
}
