import { ReactElement } from 'react';
import DirtyPageDialog from 'broker/dialogs/DirtyPageDialog';
import { NavigationBlock, useDirtyContentGuard } from './useDirtyContentGuard';

export interface DirtyDialogGuardAttributes {
  closeAttempt: () => void;
  closeWithoutPrompt: () => void;
  setIsContentDirty: (state: boolean) => void;
  isContentDirty: boolean;
}

export const DirtyDialogGuard = ({
  onClose,
  children,
}: {
  onClose: () => void;
  children: (props: DirtyDialogGuardAttributes) => ReactElement;
}) => {
  const { setIsContentDirty, isContentDirty, isDirtyDialogOpen, closeDirtyDialog, closeWithoutPrompt, closeAttempt } =
    useDirtyContentGuard({ onClose, navigationBlock: NavigationBlock.BackOnly });

  return (
    <>
      {children({
        setIsContentDirty,
        isContentDirty,
        closeWithoutPrompt,
        closeAttempt,
      })}
      <DirtyPageDialog onContinueEditing={closeDirtyDialog} onDiscard={closeWithoutPrompt} open={isDirtyDialogOpen} />
    </>
  );
};
