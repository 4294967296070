import { FormMode } from 'enums';
import { useMount } from 'hooks';
import { useUiStoreActions } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

/**
 *
 * @param viewOnlyMode
 * @param mode: Quote FormMode to ensure that only if the mode is not view we will override the files explorer viewOnlyMode
 */
export default function useSetFilesExplorerViewMode(viewOnlyMode: boolean, mode: FormMode) {
  const { setFilesExplorer } = useUiStoreActions();
  useMount(() => {
    if (mode !== FormMode.view) {
      setFilesExplorer({ viewOnlyMode });
    }
  });
}
