import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import Content from './Content';

export default function EmailEditorInnerPage() {
  return (
    <WithRouteLocationState routeKey={RouteKey.EmailEditor} isStateRequired>
      {(locationState) => <Content locationState={locationState} />}
    </WithRouteLocationState>
  );
}
