import { EmailTemplateVariables, MENTION_PREFIX } from '@common/email-variables';
import { BoxItem, SharedLink } from 'box-ui-elements/es';
import { EmailMessageContentType, EmailMessageRecipientType } from 'enums';
import { useHotjar, useToast } from 'hooks';
import { useMutateEmailMessageBatch } from 'hooks/api/emailMessagesBatch';
import { messages } from 'i18n';
import { EmailMessageBatch, EmailMessageCreate, Sender, User } from 'types';
import { htmlToPlain, logger } from 'utils';
import { HotjarEvents } from 'utils/hotjar-events';
import { EmailTemplateContext } from 'broker/components/EmailEditor/store/types';
import { isTotalAttachmentsSizeReached } from 'broker/components/EmailEditor/utils/file-utils';
import { RecipientGroup, RecipientGroups } from 'broker/components/Emails/recipient-utils';
import { EmailStatus } from 'broker/components/Emails/SendingMessagesScreen';
import useArchive from 'broker/components/Emails/useArchive';
import {
  minAttachedFilesToCreateArchive,
  resolveText,
} from 'broker/utils/email-utils/email-resolver-utils/email-resolving-utils';
import { EmailTemplateVariablesMap } from 'broker/utils/email-utils/email-resolver-utils/template-variables-utils';
import { recipientFullName } from './utils';

export type SendEmailMessageResolving = (
  attachmentsArchive: SharedLink | null,
  sendAttachmentsAsLinks: boolean,
  recipientGroup: RecipientGroup,
) => { subject: string; body: string };

function sanitizeArchiveName(archiveName: string, emailTemplateContext: EmailTemplateContext) {
  let sanitizedArchiveName = resolveText(archiveName, emailTemplateContext, EmailTemplateVariablesMap, MENTION_PREFIX);

  Object.values(EmailTemplateVariables).forEach((variable) => {
    sanitizedArchiveName = sanitizedArchiveName.replaceAll(variable, '');
  });

  return sanitizedArchiveName.replaceAll(/[^a-zA-Z0-9_-]/g, '_');
}

export const useSendEmail = (
  subject: string,
  attachedFiles: BoxItem[],
  recipientGroups: RecipientGroups,
  resolveMessage: SendEmailMessageResolving,
  setEmailStatus: (status: EmailStatus) => void,
  emailTemplateContext: EmailTemplateContext,
  ccRecipients: User[],
  selectedSender?: Sender,
  isBySubmissionEmail = true,
) => {
  const { createEmailMessageBatchBySubmission, createEmailMessageBatch } = useMutateEmailMessageBatch();

  const createArchive = useArchive({
    boxFolderId: emailTemplateContext.submission.boxFolderId,
  });

  const { showToast } = useToast();
  const hotjar = useHotjar();

  const resolvedMessages = Object.values(recipientGroups).map((recipientGroup) =>
    resolveMessage(null, false, recipientGroup),
  );

  const sendEmail = async (postSendCallback?: (sentEmails?: EmailMessageBatch) => Promise<void>) => {
    hotjar.event(HotjarEvents.SendEmail);

    const sendAttachmentsAsLinks = isTotalAttachmentsSizeReached(attachedFiles);
    let attachmentsArchive: SharedLink | null;

    if (sendAttachmentsAsLinks && attachedFiles.length >= minAttachedFilesToCreateArchive) {
      setEmailStatus(EmailStatus.Uploading);
      const sanitizedArchiveName = sanitizeArchiveName(subject, emailTemplateContext);
      attachmentsArchive = await createArchive(attachedFiles, sanitizedArchiveName);
      if (!attachmentsArchive) {
        showToast('error', { message: messages.emailEditor.failedToUploadArchive });
        logger.log('error', messages.emailEditor.failedToUploadArchive);
        setEmailStatus(EmailStatus.Editing);
        return;
      }
    }

    setEmailStatus(EmailStatus.Sending);

    const messagesToSend: EmailMessageCreate[] = Object.values(recipientGroups).map((recipientGroup) => {
      const resolvedMessage = resolveMessage(attachmentsArchive, sendAttachmentsAsLinks, recipientGroup);
      const recipients = recipientGroup.recipients.map((recipient) => ({
        type: EmailMessageRecipientType.To,
        address: recipient.email,
        name: recipientFullName(recipient),
      }));

      Object.values(ccRecipients).forEach((recipient) => {
        recipients.push({
          type: EmailMessageRecipientType.Cc,
          address: recipient.email,
          name: `${recipient.firstName} ${recipient.lastName}`,
        });
      });

      return {
        subject: resolvedMessage.subject,
        contents: [
          {
            type: EmailMessageContentType.HTML,
            value: resolvedMessage.body,
          },
          {
            type: EmailMessageContentType.PLAIN,
            value: htmlToPlain(resolvedMessage.body),
          },
          ...(sendAttachmentsAsLinks
            ? []
            : attachedFiles.map((file) => ({
                type: EmailMessageContentType.BOX_FILE_ID,
                value: file.id,
              }))),
        ],
        recipients,
        replyToName: selectedSender?.replyToName,
      };
    });

    const createEmailMessageBatchByRole = isBySubmissionEmail
      ? createEmailMessageBatchBySubmission
      : createEmailMessageBatch;

    try {
      const sentEmails = await createEmailMessageBatchByRole.mutateAsync({
        data: {
          submissionId: emailTemplateContext.submission.id,
          messages: messagesToSend,
        },
      });

      await postSendCallback?.(sentEmails ?? undefined);
      setEmailStatus(EmailStatus.Sent);
    } catch (e) {
      setEmailStatus(EmailStatus.Editing);
    }
  };

  return { sendEmail, resolvedMessages };
};
