import { Stack, Typography } from '@common-components';
import { Search as SearchIcon } from '@icons';
import { BoxTemplateLabels } from 'enums';
import { useMutateBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { ContentProps } from 'broker/components/FilesExplorer/components/Content/types';
import { ExtendedBoxItem } from 'broker/components/FilesExplorer/types';
import { FilesUploader } from './FilesUploader';

interface FilesUploadSectionProps
  extends Pick<
    ContentProps,
    | 'afterFileOperation'
    | 'autoLabel'
    | 'ownFilter'
    | 'filesUploaderRef'
    | 'showUploader'
    | 'selectionMode'
    | 'setSelectedFiles'
    | 'selectedFiles'
    | 'isLoading'
    | 'folderId'
    | 'uploaderShown'
    | 'alwaysShowUploader'
    | 'setIsFileActionOccurring'
    | 'onCloseUploader'
    | 'onFileUploadCompleted'
    | 'viewOnlyMode'
  > {}

export default function FilesUploadSection({
  afterFileOperation,
  autoLabel,
  ownFilter,
  filesUploaderRef,
  showUploader,
  selectionMode,
  setSelectedFiles,
  selectedFiles,
  isLoading,
  folderId,
  uploaderShown,
  alwaysShowUploader,
  setIsFileActionOccurring,
  onCloseUploader,
  onFileUploadCompleted,
  viewOnlyMode,
}: FilesUploadSectionProps) {
  const isCompactFilesUploader = !uploaderShown && alwaysShowUploader;
  const { addFileMetadata } = useMutateBoxItems(true);

  const setAutoLabels = async (boxItems: ExtendedBoxItem[]) => {
    if (autoLabel && ownFilter) {
      const defaultLabels: Partial<Record<BoxTemplateLabels, string>> = Object.keys(ownFilter).reduce(
        (newDefaultLabels: Record<string, string>, key) => {
          const label = key as BoxTemplateLabels;

          if (ownFilter[label]?.length === 1) {
            // ESLint bug
            // eslint-disable-next-line prefer-destructuring
            newDefaultLabels[label] = ownFilter[label]![0].value;
          }

          return newDefaultLabels;
        },
        {},
      );

      if (Object.keys(defaultLabels).length !== 0) {
        const updateMetadataRequests: Array<Promise<void | null>> = [];
        boxItems.forEach((boxItem) => {
          updateMetadataRequests.push(addFileMetadata.mutateAsync({ fileId: boxItem.id, metadata: defaultLabels }));
        });
        await Promise.all(updateMetadataRequests);
      }
    }
  };

  const onFileUploadComplete = async (boxItems: ExtendedBoxItem[]) => {
    if (selectionMode && selectedFiles && setSelectedFiles) {
      setSelectedFiles([...selectedFiles, ...boxItems]);
    }

    setIsFileActionOccurring(true);
    onCloseUploader();

    if (onFileUploadCompleted) {
      onFileUploadCompleted(boxItems);
    }

    await setAutoLabels(boxItems);

    await afterFileOperation();
  };

  return !isLoading && (uploaderShown || isCompactFilesUploader) ? (
    <Stack
      flex={isCompactFilesUploader ? 'init' : 1}
      mt={isCompactFilesUploader ? 2 : 0}
      mb={isCompactFilesUploader ? 1 : 4}
      mx={2}
      overflow="auto"
    >
      {viewOnlyMode ? (
        <Stack flex={1} gap={1} justifyContent="center" alignItems="center">
          <SearchIcon opacity={0.2} fontSize="large" />
          <Typography sx={{ opacity: 0.2 }} variant="body2">
            {messages.fileExplorer.noItemsFound}
          </Typography>
        </Stack>
      ) : (
        <FilesUploader
          imperativeRef={filesUploaderRef}
          compact={isCompactFilesUploader}
          folderId={folderId}
          onFilesAdded={showUploader}
          onComplete={onFileUploadComplete}
          subtitle={messages.filesUploader.subtitle}
        />
      )}
    </Stack>
  ) : null;
}
