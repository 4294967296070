import { ChangeEvent } from 'react';
import { Button, Link, Stack, Typography } from '@common-components';
import { messages } from 'i18n';

interface EmptyStateProps {
  compact: boolean;
  handleFilesAdded: (e: ChangeEvent<HTMLInputElement>) => void;
  subtitle?: string;
}

export function EmptyState({ compact, handleFilesAdded, subtitle }: EmptyStateProps) {
  if (compact) {
    return (
      <Stack direction="row" alignItems="center" gap={1} justifyContent="center" flex={1} py={3}>
        <Typography variant="body1Bold">{messages.filesUploader.emptyState.dragAndDrop}</Typography>
        <Link component="label" underline="hover" sx={{ cursor: 'pointer' }}>
          {messages.filesUploader.emptyState.browseComputer}
          <input type="file" onChange={handleFilesAdded} multiple hidden />
        </Link>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" gap={2} justifyContent="center" flex={1} px={4}>
      <Stack gap={1} textAlign="center">
        <Typography variant="body2Bold">{messages.filesUploader.dragAndDrop}</Typography>
        {subtitle && (
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Button variant="outlined" component="label">
        {messages.filesUploader.browseComputer}
        <input type="file" onChange={handleFilesAdded} multiple hidden />
      </Button>
    </Stack>
  );
}
