import { useCallback, useEffect } from 'react';

interface useConfirmBrowserCloseProps {
  shouldConfirm: boolean;
  confirmationMessage: string;
}

export function useConfirmBrowserClose({ shouldConfirm, confirmationMessage }: useConfirmBrowserCloseProps) {
  const onBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (shouldConfirm) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = confirmationMessage;
      }
    },
    [confirmationMessage, shouldConfirm],
  );

  // Prevent closing the browser tab on dirty state
  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [onBeforeUnload]);
}
