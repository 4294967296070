import { messages } from 'i18n';
import Banner, { BannerMode } from 'broker/components/Banner';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';

export default function ApiNotReadyBanner() {
  const { missingApiInformationBanner } = messages.submissionWorkspace;
  const navigate = useNavigate();
  const { editSubmissionUrl } = useWorkspaceUrls();
  return (
    <Banner
      title={missingApiInformationBanner.title}
      subtitle={missingApiInformationBanner.description}
      mode={BannerMode.Error}
      button={{
        text: missingApiInformationBanner.button,
        onClick: () =>
          navigate(editSubmissionUrl, {
            routeKey: RouteKey.SubmissionEdit,
            state: undefined,
          }),
      }}
    />
  );
}
