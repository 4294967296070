import { Grid, Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { formFieldsConfig } from 'broker/dialogs/SettingsDialog/utils';

export default function PersonalSettings() {
  return (
    <Stack>
      <Typography variant="subtitle2" pb={2}>
        {messages.SettingsDialog.personalSettings}
      </Typography>
      <FormTextField
        label={formFieldsConfig.email.label}
        name={formFieldsConfig.email.name}
        id={formFieldsConfig.email.id}
        disabled
      />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormTextField
            label={formFieldsConfig.firstName.label}
            name={formFieldsConfig.firstName.name}
            id={formFieldsConfig.firstName.id}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            label={formFieldsConfig.lastName.label}
            name={formFieldsConfig.lastName.name}
            id={formFieldsConfig.lastName.id}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormTextField
            label={formFieldsConfig.title.label}
            name={formFieldsConfig.title.name}
            id={formFieldsConfig.title.id}
            optional
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            label={formFieldsConfig.note.label}
            name={formFieldsConfig.note.name}
            id={formFieldsConfig.note.id}
            optional
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormTextField
            label={formFieldsConfig.phoneNumber.label}
            name={formFieldsConfig.phoneNumber.name}
            id={formFieldsConfig.phoneNumber.id}
            optional
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
