import { EmailTemplateVariables, MENTION_PREFIX } from '@common/email-variables';
import { toMentionTagString } from 'components/Editor/utils/mentions';
import { EmailTemplateContext } from 'broker/components/EmailEditor/store/types';
import { resolveText } from 'broker/utils/email-utils/email-resolver-utils/email-resolving-utils';
import {
  EmailTemplateVariablesMap,
  ProposalAutoResolvedVariables,
} from 'broker/utils/email-utils/email-resolver-utils/template-variables-utils';
import { smartObjectsConfig } from 'broker/utils/email-utils/SmartVariable/smart-objects-config';
import { renderSmartObjectVariable, revertSmartObjectToVariable } from 'broker/utils/email-utils/SmartVariable/utils';

/**
 * This class handles all template variables resolution concerns for the proposal
 */
export class TemplateVariablesResolver {
  private readonly context: EmailTemplateContext;

  constructor(context: EmailTemplateContext) {
    this.context = context;
  }

  public get templateContext() {
    return this.context;
  }

  /**
   * This is the regular resolution - we use it in preview and when saving the proposal
   */
  public resolveContentVariables(content: string, resolveSmartObjects = false) {
    const unresolvedContent = revertSmartObjectToVariable(content);
    return resolveText(
      unresolvedContent,
      this.context,
      EmailTemplateVariablesMap,
      MENTION_PREFIX,
      undefined,
      undefined,
      resolveSmartObjects,
    );
  }

  public resolveSubjectVariables(subject: string, resolveSmartObjects = false) {
    return resolveText(
      subject,
      this.context,
      EmailTemplateVariablesMap,
      MENTION_PREFIX,
      undefined,
      undefined,
      resolveSmartObjects,
    );
  }

  /**
   * This is the "smart" content resolution for edit mode - smart variables are resolved within a non-editable frame
   */
  public resolveContentForEditMode(content: string) {
    return Object.values(EmailTemplateVariables).reduce((previousValue, variable) => {
      let resolvedVariable: string;

      if (variable in smartObjectsConfig) {
        resolvedVariable = renderSmartObjectVariable(variable, EmailTemplateVariablesMap, this.context);
      } else if (variable in ProposalAutoResolvedVariables) {
        resolvedVariable = this.resolveVariable(variable);
      } else {
        resolvedVariable = toMentionTagString(`${MENTION_PREFIX}${variable}`);
      }

      return previousValue.replaceAll(`${MENTION_PREFIX}${variable}`, resolvedVariable);
    }, content);
  }

  public resolveVariable(variable: EmailTemplateVariables) {
    return EmailTemplateVariablesMap[variable](this.context).value ?? '';
  }
}
