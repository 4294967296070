import { useCallback, useMemo, useState } from 'react';
import { CoverageLine } from 'enums';
import { InsuranceProduct } from 'types';
import { FilterType, UseProductFilterAttributes } from './types';
import { useCoverageLineFilter } from './useCoverageLineFilter';
import { useIsAdmittedFilter } from './useIsAdmittedFilter';
import { useIsPrimaryFilter } from './useIsPrimaryFilter';

export function useProductFilters(
  products: InsuranceProduct[],
  isLoading: boolean,
  paginationReset: () => void,
): UseProductFilterAttributes {
  const coverageLineFilter = useCoverageLineFilter({
    paginationReset,
  });

  const isAdmittedFilter = useIsAdmittedFilter({
    products,
    productsLoaded: !isLoading,
    paginationReset,
  });

  const isPrimaryFilter = useIsPrimaryFilter({
    products,
    productsLoaded: !isLoading,
    paginationReset,
  });

  const [searchInput, setSearchInput] = useState<string>('');

  const filteredProducts = useMemo(
    () =>
      [...products]
        .sort((a, b) => {
          if (a.marketName !== b.marketName) {
            return a.marketName.localeCompare(b.marketName);
          }
          if (a.isPrimary !== b.isPrimary) {
            return a.isPrimary ? -1 : 1;
          }
          return a.isAdmitted ? -1 : 1;
        })
        .filter((product) => {
          if (coverageLineFilter.isAllSelected) {
            return true;
          }
          return coverageLineFilter.filters.some(
            (filter) =>
              filter.checked &&
              product.coverageLines.some((item) => item.coverageLine === (filter.key as CoverageLine)),
          );
        })
        .filter((product) => {
          if (isAdmittedFilter.isAllSelected) {
            return true;
          }
          if (isAdmittedFilter.filters[0].checked) {
            return product.isAdmitted;
          }
          if (isAdmittedFilter.filters[1].checked) {
            return !product.isAdmitted;
          }
          return true;
        })
        .filter((product) => {
          if (isPrimaryFilter.isAllSelected) {
            return true;
          }
          if (isPrimaryFilter.filters[1].checked) {
            return !product.isPrimary;
          }
          if (isPrimaryFilter.filters[0].checked) {
            return product.isPrimary;
          }
          return true;
        })
        .filter((product) => {
          if (searchInput.length < 2) {
            return true;
          }
          const productTerms = [product.marketName].filter(Boolean);
          return productTerms.some((term) => term?.toLowerCase().includes(searchInput.toLowerCase()));
        }),
    [coverageLineFilter, isAdmittedFilter, isPrimaryFilter, products, searchInput],
  );
  const setSearch = useCallback(
    (value: string) => {
      paginationReset();
      setSearchInput(value);
    },
    [paginationReset],
  );

  const clearFilters = useCallback(() => {
    coverageLineFilter.handleSelectAll();
    isAdmittedFilter.handleSelectAll();
    isPrimaryFilter.handleSelectAll();
  }, [coverageLineFilter, isAdmittedFilter, isPrimaryFilter]);

  const isFilterApplied =
    !coverageLineFilter.isAllSelected || !isAdmittedFilter.isAllSelected || !isPrimaryFilter.isAllSelected;

  const filters = {
    [FilterType.CoverageLine]: coverageLineFilter,
    [FilterType.IsAdmitted]: isAdmittedFilter,
    [FilterType.IsPrimary]: isPrimaryFilter,
  };

  return {
    sortedAndFilteredItems: filteredProducts,
    filters,
    clearFilters,
    isFilterApplied,
    search: searchInput,
    setSearch,
  };
}
