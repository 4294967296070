import { AIFeature } from 'enums';
import { PartialSubmission } from 'types';
import { SetNextStepsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/useSetNextStep';
import { AIContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import RiskAssessmentContent from './RiskAssessmentContent';

interface RiskAssessmentFormProps {
  submission: PartialSubmission;
  setNextStep: (props?: SetNextStepsProps) => void;
}

export default function RiskAssessmentStep({ submission, setNextStep }: RiskAssessmentFormProps) {
  return (
    <AIContextProvider feature={AIFeature.RiskAssessment}>
      <RiskAssessmentContent submission={submission} setNextStep={setNextStep} />
    </AIContextProvider>
  );
}
