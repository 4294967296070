import { Stack } from '@common-components';
import { EmailIntegrationBanner } from 'broker/components/Emails/EmailIntegrationBanner';
import { useIsIntegrationEnabled } from 'broker/components/Emails/useIsIntegrationEnabled';
import DraftEditor from './components/DraftEditor';
import { EmailAttachmentsInfoTooltip } from './components/EmailAttachmentsInfoTooltip';
import RecipientSelection from './components/RecipientGroupSelector/RecipientSelection';
import EmailEditorDialogs from './EmailEditorDialogs';
import useEmailEditorState from './store/useEmailEditorState';

export default function EmailEditor() {
  const {
    preSelectedTemplateType,
    submissionsWorkspace: { submission },
  } = useEmailEditorState();

  const isIntegrationEnabled = useIsIntegrationEnabled();

  return (
    <Stack gap={2} height={1} sx={{ position: 'relative' }}>
      {isIntegrationEnabled && (
        <EmailIntegrationBanner submission={submission} emailTemplateType={preSelectedTemplateType} />
      )}
      <Stack px={3} pb={3} height={1}>
        <RecipientSelection />
        <DraftEditor />
        <EmailEditorDialogs />
      </Stack>
      <EmailAttachmentsInfoTooltip isOpen={!submission?.isCopilotEnabled} />
    </Stack>
  );
}
