import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { Features } from 'utils/features-config';
import { FeatureGate } from 'components/FeatureGate';
import PageHeader from 'broker/components/common/layout/PageHeader';
import { submissionViewModeConfig } from './config';
import CreateSubmissionButton from './CreateSubmission/CreateSubmissionButton';
import { SubmissionViewMode } from './utils';

export interface SubmissionsViewHeaderProps {
  count: number;
  filteredCount: number;
  isFilterApplied: boolean;
  viewMode: SubmissionViewMode;
}

export default function SubmissionHeader({
  count,
  filteredCount,
  isFilterApplied,
  viewMode,
}: SubmissionsViewHeaderProps) {
  return (
    <PageHeader>
      <Stack direction="row" alignItems="baseline" gap={1}>
        <Typography variant="h4">{submissionViewModeConfig[viewMode].title}</Typography>
        <Typography color="text.secondary" variant="body2">
          {isFilterApplied ? messages.general.outOfSubmissions(filteredCount.toString(), count.toString()) : count}
        </Typography>
      </Stack>

      {submissionViewModeConfig[viewMode].allowCreateSubmission && (
        <FeatureGate feature={Features.CreateSubmissionManually}>
          <CreateSubmissionButton />
        </FeatureGate>
      )}
    </PageHeader>
  );
}
