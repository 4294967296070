import { DatesRangeType } from 'enums';
import { messages } from 'i18n';
import { isInFutureDaysRange, isInThisAndMonthsRange } from 'utils';

export interface SubmissionDatesRangeProps {
  text: string;
  filterFn: (date: Date) => boolean;
}

export type SubmissionDatesRangeConfig = Record<DatesRangeType, SubmissionDatesRangeProps>;

export const submissionDatesRangeConfig: SubmissionDatesRangeConfig = {
  [DatesRangeType.Next90Days]: {
    text: messages.submission.datesRange.next90Days,
    filterFn: (dueDate: Date) => isInFutureDaysRange(dueDate, 90),
  },
  [DatesRangeType.Next60Days]: {
    text: messages.submission.datesRange.next60Days,
    filterFn: (dueDate: Date) => isInFutureDaysRange(dueDate, 60),
  },
  [DatesRangeType.Next30Days]: {
    text: messages.submission.datesRange.next30Days,
    filterFn: (dueDate: Date) => isInFutureDaysRange(dueDate, 30),
  },
  [DatesRangeType.ThisAndNext2Months]: {
    text: messages.submission.datesRange.thisAndNext2Months,
    filterFn: (dueDate: Date) => isInThisAndMonthsRange(dueDate, 2),
  },
  [DatesRangeType.ThisAndNextMonth]: {
    text: messages.submission.datesRange.thisAndNextMonth,
    filterFn: (dueDate: Date) => isInThisAndMonthsRange(dueDate, 1),
  },
  [DatesRangeType.ThisMonth]: {
    text: messages.submission.datesRange.thisMonth,
    filterFn: (dueDate: Date) => isInThisAndMonthsRange(dueDate, 0),
  },
};
