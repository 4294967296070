import { find } from 'lodash';
import { useBoxClient, useHotjar } from 'hooks';
import { HotjarEvents } from 'utils/hotjar-events';
import { EmailEditorPageMode, RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { ReplyMode } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EmailReplyInnerPage/types';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { EmailIdentifierProps } from './utils';

export default function useEmailAction() {
  const navigate = useNavigate();
  const hotjar = useHotjar();
  const { submissionEmailUrl } = useWorkspaceUrls();
  const { activities } = useSubmissionsWorkspace();
  const boxClient = useBoxClient();

  const getActivityType = (emailId: string) =>
    find(activities, (logEntry) => logEntry.metadata?.submissionEmailId === emailId)?.activityType;

  const navigateToEmailReplyEditor = (
    emailItem: EmailIdentifierProps,
    viewMode: ReplyMode,
    emailEditorPageMode?: EmailEditorPageMode,
    searchParams?: string,
  ) => {
    const url = submissionEmailUrl(emailItem.emailId);
    const activityType = getActivityType(emailItem.emailId);
    navigate(
      {
        pathname: url,
        search: searchParams || undefined,
      },
      {
        routeKey: RouteKey.EmailReply,
        state: {
          submissionEmailId: emailItem.emailId,
          activityType,
          initialReplyMode: viewMode,
          emailType: emailItem.emailType,
          mode: emailEditorPageMode || EmailEditorPageMode.Default,
        },
      },
    );
  };

  const downloadEmail = async (fileId: string) => {
    hotjar.event(HotjarEvents.EmailDownload);
    const file = await boxClient.getFile(fileId, { includeMetadata: true });
    await boxClient.downloadFileToBrowser(file!);
  };

  return { navigateToEmailReplyEditor, downloadEmail };
}
