import { autocompleteClasses, styled } from '@common-components';

export const StyledList = styled('ul')(({ theme }) => ({
  width: '100%',
  margin: '2px 0 0',
  padding: 0,
  position: 'absolute',
  listStyle: 'none',
  backgroundColor: theme.palette.common.white,
  maxHeight: theme.spacing(32),
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[12],
  zIndex: theme.zIndex.modal,

  '& li:not(first-child)': {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },

  '& li': {
    display: 'flex',
    '& span': {
      flexGrow: 1,
      margin: '5px 12px',
      ...theme.typography.body1,
    },
    '& button': {
      textTransform: 'none',
      margin: '5px 4px',
      ...theme.typography.body1,
    },
  },

  [`& li.${autocompleteClasses.focused}`]: {
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
  },
}));
