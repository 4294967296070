import { useCurrentUser } from 'hooks';
import { CopilotSettings, PartialSubmission } from 'types';

const useCopilotSettings = <T extends keyof CopilotSettings>({
  submission,
  copilotStep,
}: {
  submission: PartialSubmission;
  copilotStep: T;
}) => {
  const { endUser } = useCurrentUser();

  return submission.isCopilotEnabled
    ? submission.copilotSettings?.[copilotStep]
    : endUser?.organization.copilotSettings?.[copilotStep];
};

export default useCopilotSettings;
