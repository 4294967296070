import { RecipientGroupToDraft, TemplateInstance, TemplateInstances } from 'broker/components/EmailEditor/store/types';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';

export function getTemplateInstanceLookup(
  recipientGroupToDraft: RecipientGroupToDraft,
  templateInstances: TemplateInstances,
): (recipientGroupId: string) => TemplateInstance {
  return (recipientGroupId: string) => {
    const draft = recipientGroupToDraft[recipientGroupId] || {};

    const templateInstance = draft.detachedTemplateInstance
      ? draft.detachedTemplateInstance
      : templateInstances[draft.chosenTemplateId];

    return templateInstance;
  };
}

export function isAnyTemplateInstanceMissingData(
  recipientGroupToDraft: RecipientGroupToDraft,
  templateInstances: TemplateInstances,
  recipientGroups: RecipientGroup[],
) {
  const getTemplateInstance = getTemplateInstanceLookup(recipientGroupToDraft, templateInstances);

  return recipientGroups.some((recipientGroup) => {
    const templateInstance = getTemplateInstance(recipientGroup.id);

    if (!templateInstance) {
      return false;
    }

    const { subject, body } = templateInstance;
    return !subject || !body;
  });
}
