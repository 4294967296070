import { omit } from 'lodash';
import { useState } from 'react';
import { Box, Divider, GridColDef, Toolbar, Typography } from '@common-components';
import { ContactPage, MailOutline } from '@icons';
import { OrganizationType, UserRole } from 'enums';
import { getUserRole } from 'enums/type-mappers/user-role-config';
import { useSearchUser } from 'hooks';
import { messages } from 'i18n';
import { Organization, Team, User } from 'types';
import CreateButton from 'components/CreateButton';
import DataGridList, { DEFAULT_PAGE_SIZE } from 'components/DataGridList';
import InternalLink from 'components/InternalLink';
import ADMIN_ROUTES from 'admin/admin-routes';
import UserCreateDialog from 'admin/dialogs/UserCreateDialog';
import OrganizationLink from './OrganizationLink';
import SearchField from './SearchField';
import SelectField from './SelectField';
import TeamLink from './TeamLink';
import UserLink from './UserLink';

export interface UserListPageProps {
  organization?: Organization;
  team?: Team;
  maxHeight?: string;
}

export default function UserList({ organization, team, maxHeight }: UserListPageProps) {
  /*
    Allowing user creation if there is no preselected organization
    (allowing the user to select it upon creation)
    and if the preselected organization is not of Carrier type
    (since we currently don't support users for carriers)
    */
  const allowUserCreation = !organization || organization.type !== OrganizationType.Carrier;

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string>();
  const [role, setRole] = useState<string | null>(null);
  const [offset, setOffset] = useState<number>(0);

  const getUserName = (user: User) => `${user.firstName} ${user.lastName}`;

  const pageSize = DEFAULT_PAGE_SIZE;

  const {
    items: users,
    count,
    isInitialLoading,
  } = useSearchUser({
    filter: {
      offset,
      limit: pageSize,
      organizationId: organization?.id,
      teamId: team?.id,
      q: query,
      role: role as UserRole,
    },
  });

  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      renderCell: ({ row }: { row: unknown }) => UserLink({ user: row as User }),
      flex: 0.1,
    },
    {
      field: 'name',
      headerName: 'Name',
      renderCell: ({ row }: { row: unknown }) => getUserName(row as User),
      flex: 0.1,
    },
    {
      field: 'role',
      headerName: 'Role',
      renderCell: ({ row }: { row: unknown }) => getUserRole((row as User).role),
      flex: 0.1,
    },
  ];

  if (team === undefined) {
    columns.push({
      field: 'teamName',
      headerName: 'team',
      renderCell: ({ row }: { row: unknown }) =>
        TeamLink({
          team: {
            id: (row as User).team.id ?? '',
            name: (row as User).team.name ?? '',
          },
        }),
      flex: 0.1,
    });
  }

  if (organization === undefined) {
    columns.push({
      field: 'organizationName',
      headerName: 'Organization',
      renderCell: ({ row }: { row: unknown }) =>
        OrganizationLink({
          organization: {
            id: (row as User).organization.id,
            name: (row as User).organization.name,
          },
        }),
      flex: 0.1,
    });
  }

  columns.push({
    field: 'contacts',
    headerName: 'Contacts',
    align: 'center',
    renderCell: ({ row }: { row: User }) => (
      <InternalLink to={ADMIN_ROUTES.getContactByTeamId(row.team.id)}>
        <ContactPage />
      </InternalLink>
    ),
  });

  columns.push({
    field: 'emailTemplates',
    headerName: 'Email Templates',
    align: 'center',
    renderCell: ({ row }: { row: User }) => (
      <InternalLink to={ADMIN_ROUTES.getEmailTemplatesByUserId(row.id)}>
        <MailOutline />
      </InternalLink>
    ),
  });

  const unmappedChoices = omit(UserRole, UserRole.Broker);
  const choices = Object.fromEntries(
    Object.keys(unmappedChoices).map((value) => [value, getUserRole(value as UserRole)]),
  );

  return (
    <>
      <Toolbar sx={{ p: 1 }}>
        <Typography variant="h6" id="tableTitle" component="div" sx={{ mr: 2 }}>
          {messages.admin.organizationPage.usersGridTitle}
        </Typography>

        <Divider orientation="vertical" flexItem variant="middle" />

        <SearchField onChange={setQuery} />

        <SelectField choices={choices} label="Role" value={role} onChange={setRole} />
        {allowUserCreation && <CreateButton onClick={() => setOpen(true)} />}
      </Toolbar>
      <Box sx={{ maxHeight: maxHeight || 'inherit', overflowY: 'auto' }}>
        <DataGridList
          columns={columns}
          items={users}
          count={count}
          setOffset={setOffset}
          isLoading={isInitialLoading}
          pageSize={pageSize}
        />
        {allowUserCreation && (
          <UserCreateDialog organization={organization} team={team} open={open} onClose={() => setOpen(false)} />
        )}
      </Box>
    </>
  );
}
