import { useCallback, useEffect, useState } from 'react';
import { Button, Container, Grid, Paper, Stack, Typography } from '@common-components';
import APP_ROUTES from 'app-routes';
import { useBoolean, useCurrentUser, useSetEndUser } from 'hooks';
import { messages } from 'i18n';
import { softShadow } from 'themes';
import { LoggedInUser, User } from 'types';
import Autocomplete from 'components/Autocomplete';
import { RouteKey } from 'broker/enums';
import { useLocation, useNavigate } from 'broker/hooks';
import SetUserDialog from './SetUserDialog';
import { useSearchEndUser } from './useSearchBrokerUsers';

const { title, setUser, signOut, emailFieldLabel } = messages.backofficePage;

interface BackOfficePageLocationState {
  shouldClearEndUser?: boolean;
}

export default function BackOfficePage() {
  const { me } = useCurrentUser();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const { users } = useSearchEndUser();

  const [isSetUserDialogOpen, { on: openSetUserDialog, off: closeSetUserDialog }] = useBoolean(false);

  const [endUserEmail, setEndUserEmail] = useState<string>();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const { isFetching: isSettingEndUser } = useSetEndUser({
    enabled: !!endUserEmail,
    email: endUserEmail,
    onSuccess: (userInfo: LoggedInUser) => {
      if (userInfo.endUser) {
        navigate(APP_ROUTES.GENERAL.HOME, { routeKey: RouteKey.Home, state: undefined });
      }
    },
    onError: () => {
      // Clear the state to allow retry
      setEndUserEmail(undefined);
    },
  });

  const { isFetching: isClearingEndUser, refetch: clearEndUser } = useSetEndUser();

  const onChange = (value: User | User[] | null) => {
    setSelectedUser(value as User);
  };
  const onSignOut = useCallback(async () => {
    setSelectedUser(null);
    await clearEndUser();
  }, [clearEndUser]);

  useEffect(() => {
    if ((locationState as BackOfficePageLocationState)?.shouldClearEndUser) {
      onSignOut();
    }
  }, [onSignOut, locationState]);

  return (
    <>
      <Container
        className="cap-back-office-home-page"
        maxWidth="xl"
        sx={{ height: 1, py: 3, display: 'flex', flexDirection: 'column' }}
      >
        <Typography variant="h4" mb={3}>
          {title}
        </Typography>
        <Grid container>
          <Grid item xs={6} component={Paper} boxShadow={softShadow} p={3}>
            <Autocomplete
              value={selectedUser}
              onChange={onChange}
              options={users}
              getDisplayValues={(user) => ({
                title: `${user.organization.name} | ${user.firstName} ${user.lastName} | ${user.email}`,
              })}
              placeholder={emailFieldLabel}
            />
            <Stack direction="row" justifyContent="flex-end" gap={2} mt={2}>
              <Button loading={isClearingEndUser} onClick={onSignOut} disabled={!me?.endUser}>
                {signOut}
              </Button>
              <Button
                loading={isSettingEndUser && !isClearingEndUser}
                disabled={!selectedUser}
                variant="contained"
                onClick={openSetUserDialog}
              >
                {setUser}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      {selectedUser && (
        <SetUserDialog
          isSetUserDialogOpen={isSetUserDialogOpen}
          closeSetUserDialog={closeSetUserDialog}
          onSubmit={() => setEndUserEmail(selectedUser?.email)}
          usersEmail={selectedUser?.email}
        />
      )}
    </>
  );
}
