import { Stack } from '@common-components';
import { messages } from 'i18n';
import { ExtendedMarketRequest } from 'types';
import { marketRequestTypeConfig } from 'utils';
import MarketRequestItemLabel from './MarketRequestItemLabel';

interface MarketRequestItemLabelsProps {
  marketRequest: ExtendedMarketRequest;
}

export default function MarketRequestItemLabels({ marketRequest }: MarketRequestItemLabelsProps) {
  return (
    <Stack gap={0.5}>
      {marketRequest.market.incumbentInfo.length > 0 && (
        <MarketRequestItemLabel
          text={messages.market.status.getIncumbentText(!!marketRequest.market.incumbentInfo[0]?.isPrimary)}
        />
      )}
      {marketRequest.type && <MarketRequestItemLabel text={marketRequestTypeConfig[marketRequest.type].title} />}
    </Stack>
  );
}
