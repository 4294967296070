import { isEmpty } from 'lodash';
import { Box } from '@common-components';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';
import RecipientGroupContacts from 'broker/components/RecipientGroupContacts';

interface SingleContactRecipientTagProps {
  recipientGroup: RecipientGroup;
}

export default function SingleContactRecipientTag({ recipientGroup }: SingleContactRecipientTagProps) {
  if (isEmpty(recipientGroup)) {
    return null;
  }

  return (
    <Box className="cap-external-recipients-tag" display="flex" pt={0}>
      <RecipientGroupContacts recipientGroup={recipientGroup} isMenu={false} isNewEmailEditor hideContactsCount />
    </Box>
  );
}
