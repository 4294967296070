import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

/**
 * this hooks allows parent component to get the value of an inner component that changes form hook value
 */
export const useFormHookFieldValue = (fieldName: string) => {
  const { watch } = useFormContext();
  const [value, setValue] = useState(watch(fieldName));
  useEffect(() => {
    const subscription = watch(({ [fieldName]: fieldValue }, { name }) => {
      if (name === fieldName && fieldValue !== value) {
        setValue(fieldValue);
      }
    });

    return () => subscription.unsubscribe();
  }, [fieldName, watch, value]);

  return value;
};
