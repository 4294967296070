// Change specific properties of the interface from optional to required
export type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

/* Define type guard to filter out undefined.
 */
export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export type MakeFieldsOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type MakeFieldsRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type Indexed<T> = T & { index: number };

export type Nullable<T> = T | null;

export type PartialRecord<T extends string | number | symbol, K> = Partial<Record<T, K>>;
