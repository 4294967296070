// This TextField is imported directly from @mui/material because our implementation
// uses forwardRef which is not compatible with NumberFormat customInput.
// eslint-disable-next-line no-restricted-imports
import { TextField } from '@mui/material';
import { memo, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Box, Button, InputAdornment, MenuItem, Stack } from '@common-components';
import { LayerStatus } from 'enums';
import { useBoolean, useMount } from 'hooks';
import { messages } from 'i18n';
import { Layer, Submission } from 'types';
import { attachmentPointText, sortLayersByAttachmentPoint } from 'utils';
import AutocompleteActionButton from 'components/Autocomplete/AutocompleteActionButton';
import FormSelect from 'components/hookFormComponents/FormSelect';
import { layerStatusConfig } from 'broker/configuration-mappers/layer-status-config';
import { detailsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/detailsFieldsConfig';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';

interface AttachmentPointSelectProps {
  selectedLayer?: Layer;
  submission?: Submission;
  layers: Layer[];
  onAddNewLayer: (newLayer: Layer) => void;
}

const stopImmediatePropagation = (e: any) => {
  e.stopPropagation();
  e.preventDefault();
};

function AttachmentPointSelect({ selectedLayer, layers, submission, onAddNewLayer }: AttachmentPointSelectProps) {
  const isDisabled = !!selectedLayer?.id;

  const [inputValue, setInputValue] = useState('');

  const { setValue, getValues } = useFormContext();

  const fieldName = detailsFormFieldsConfig[DetailsFieldsNames.AttachmentPoint].name;

  const { newAttachmentPoint, addAttachmentPoint } = messages.addQuotePage;

  const [isNewAttachmentPoint, { on: showNewAttachmentPoint, off: hideNewAttachmentPoint }] = useBoolean(false);

  const [isSelectOpen, { on: setIsSelectOn, off: setIsSelectOff }] = useBoolean(false);

  const sortedLayers = useMemo(() => sortLayersByAttachmentPoint(layers), [layers]);

  // If a default layer was not provided, set the primary layer as the default value
  useMount(() => {
    if (!selectedLayer && !getValues(fieldName)) {
      setValue(fieldName, layers.find((layer) => !layer.attachmentPoint)?.id);
    }
  });

  const closeDropDown = () => {
    setIsSelectOff();
    hideNewAttachmentPoint();
  };

  const addNewAttachmentPoint = () => {
    const formattedAttachmentPoint = Number(inputValue);
    let ownSelectedLayer = layers.find((layer) => layer.attachmentPoint === formattedAttachmentPoint);
    if (!ownSelectedLayer) {
      ownSelectedLayer = {
        id: inputValue,
        attachmentPoint: formattedAttachmentPoint,
        submissionId: submission?.id || '',
        status: LayerStatus.Quoting,
      };
      onAddNewLayer(ownSelectedLayer);
    }
    setValue(fieldName, ownSelectedLayer.id);
    hideNewAttachmentPoint();
    setIsSelectOff();
  };

  useEffect(() => {
    if (selectedLayer?.id) {
      setValue(fieldName, selectedLayer.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLayer]);

  function handleAddAttachmentPoint(e: MouseEvent<HTMLDivElement>) {
    // When this event is propagated, the select input change value is triggered, and the menu is closed.
    // So we prevent this propagation.
    e.stopPropagation();
    showNewAttachmentPoint();
  }

  return (
    <FormSelect
      {...detailsFormFieldsConfig[DetailsFieldsNames.AttachmentPoint]}
      disabled={isDisabled}
      validateOnChange
      open={isSelectOpen}
      onChangeCallback={closeDropDown}
      SelectDisplayProps={{
        onClick: () => {
          if (!isDisabled) {
            setIsSelectOn();
          }
        },
      }}
      MenuProps={{
        onBackdropClick: () => {
          closeDropDown();
        },
      }}
    >
      [
      {sortedLayers.map((option) => (
        <MenuItem
          sx={{ height: 48 }}
          key={option.id}
          value={option.id}
          disabled={layerStatusConfig[option.status].hasSelectedQuote}
        >
          {attachmentPointText(option.attachmentPoint)}
        </MenuItem>
      ))}
      ,
      <Box>
        {isNewAttachmentPoint ? (
          <Stack
            gap={1.5}
            sx={(theme) => ({ px: 2, pb: 1, pt: 2, borderTop: '1px solid', borderColor: theme.palette.grey['100'] })}
          >
            <NumberFormat
              value={inputValue}
              name="addAttachmentPoint"
              id="addAttachmentPoint"
              customInput={TextField}
              prefix="$"
              type="text"
              thousandSeparator
              placeholder="$1,000,000"
              size="small"
              fullWidth
              autoFocus
              InputProps={{ startAdornment: <InputAdornment position="start">xs</InputAdornment> }}
              onValueChange={({ value: v }) => {
                setInputValue(v);
              }}
              onClickCapture={stopImmediatePropagation}
              onKeyDown={(e: any) => e.stopPropagation()}
            />
            <Button
              variant="contained"
              fullWidth
              disabled={inputValue === '' || Number(inputValue) === 0}
              onClick={addNewAttachmentPoint}
            >
              {addAttachmentPoint}
            </Button>
          </Stack>
        ) : (
          <AutocompleteActionButton onClick={handleAddAttachmentPoint}>{newAttachmentPoint}</AutocompleteActionButton>
        )}
      </Box>
      ]
    </FormSelect>
  );
}

export default memo(AttachmentPointSelect);
