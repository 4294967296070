import { LayerStatus } from 'enums';
import { messages } from 'i18n';
import theme from 'themes';

export interface LayerStatusConfigProps {
  color?: string;
  text: string;
  hasSelectedQuote: boolean;
}

type LayerStatusConfig = {
  [status in LayerStatus]: LayerStatusConfigProps;
};

export const layerStatusConfig: LayerStatusConfig = {
  [LayerStatus.Quoting]: {
    color: theme.palette.grey[700],
    text: messages.layer.status.quote,
    hasSelectedQuote: false,
  },
  [LayerStatus.Reviewing]: {
    color: theme.palette.grey[700],
    text: messages.layer.status.reviewing,
    hasSelectedQuote: false,
  },
  [LayerStatus.Bound]: {
    color: theme.palette.green[500],
    text: messages.layer.status.bound,
    hasSelectedQuote: true,
  },

  [LayerStatus.PolicyIssued]: {
    color: theme.palette.green[500],
    text: messages.layer.status.policyIssued,
    hasSelectedQuote: true,
  },
};
