import { Checkbox, DataGrid, Divider, Paper, Toolbar, Typography } from '@common-components';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { getDisplayDateWithHoursAmPm } from 'utils';
import OrganizationLink from 'admin/components/OrganizationLink';
import SearchField from 'admin/components/SearchField';
import SubmissionLink from 'admin/components/SubmissionLink';
import TeamLink from 'admin/components/TeamLink';
import UserLink from 'admin/components/UserLink';
import useSubmissionsFilter from 'admin/hooks/useSubmissionsFilter';

export default function SubmissionListPage() {
  const { filteredItems, setQuery, count, isLoading } = useSubmissionsFilter();

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: ({ row }: { row: PartialSubmission }) => SubmissionLink({ submission: row }),
      flex: 0.1,
      sortable: false,
    },
    {
      field: 'organizationId',
      headerName: 'Organization',
      renderCell: ({ row }: { row: PartialSubmission }) =>
        OrganizationLink({
          organization: {
            id: row.organizationId,
            name: row.organizationName,
          },
        }),
      flex: 0.1,
    },
    {
      field: 'team',
      headerName: 'Team',
      renderCell: ({ row }: { row: PartialSubmission }) => (row.team ? TeamLink({ team: row.team }) : '-'),
      flex: 0.1,
    },
    {
      field: 'user',
      headerName: 'Assigned To',
      renderCell: ({ row }: { row: PartialSubmission }) =>
        row.assignee ? <UserLink user={row.assignee} /> : messages.submissionsView.unassigned,
      flex: 0.1,
    },
    {
      field: 'createdAt',
      headerName: 'Creation Date',
      renderCell: ({ row }: { row: PartialSubmission }) => getDisplayDateWithHoursAmPm(row.createdAt),
      flex: 0.1,
    },
    {
      field: 'isDemo',
      headerName: 'Is Demo',
      renderCell: ({ row }: { row: PartialSubmission }) => <Checkbox checked={row.isDemo} disabled />,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
    },
  ];

  return (
    <Paper sx={{ mt: 1 }}>
      <Toolbar sx={{ p: 1 }}>
        <Typography variant="h6" id="tableTitle" component="div" sx={{ mr: 2 }}>
          Submissions
        </Typography>

        <Divider orientation="vertical" flexItem variant="middle" />
        <SearchField onChange={setQuery} />
      </Toolbar>
      <DataGrid
        autoHeight
        columns={columns}
        disableSelectionOnClick
        loading={isLoading}
        pagination
        rowCount={count}
        rows={filteredItems}
        sortingOrder={['asc', 'desc']}
      />
    </Paper>
  );
}
