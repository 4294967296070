import { Button, Stack } from '@common-components';
import { messages } from 'i18n';

export interface ContactActionButtonsProps {
  onSave: () => void;
  onCancel: () => void;
  isSubmitting: boolean;
}

export const ContactFormButtons = ({ onSave, onCancel, isSubmitting }: ContactActionButtonsProps) => (
  <Stack direction="row" gap={1}>
    <Button variant="outlined" size="small" onClick={onCancel}>
      {messages.buttons.cancel}
    </Button>
    <Button variant="contained" size="small" onClick={onSave} loading={isSubmitting}>
      {messages.buttons.save}
    </Button>
  </Stack>
);
