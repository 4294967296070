import { isEmpty } from 'lodash';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Collapse, Grid, Stack, Typography } from '@common-components';
import {
  Done as DoneIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@icons';
import { isMarketOrganization } from 'enums';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import { ConfirmationDialogGuard, ConfirmationDialogGuardAttributes } from 'broker/components/ConfirmationDialogGuard';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';

interface SendEmailConfirmationGuardProps {
  recipientGroups: RecipientGroup[];
  onSend: () => void;
  children: (props: ConfirmationDialogGuardAttributes) => ReactElement;
  CustomConfirmationMessage?: () => ReactNode;
}

export default function RecipientsConfirmationGuard({
  onSend,
  children,
  recipientGroups,
  CustomConfirmationMessage,
}: SendEmailConfirmationGuardProps) {
  const [recipientMessage, setRecipientMessage] = useState<string>();
  const [recipientList, setRecipientList] = useState<string[]>([]);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(true);
  const [isMarketsNumberCollapseOpen, { toggle: toggleMarketsNumberCollapse }] = useBoolean(false);

  useEffect(() => {
    if (isEmpty(recipientGroups) || isMarketOrganization(recipientGroups[0].type)) {
      setRecipientMessage(
        messages.emailEditor.sendMessagesGuards.generalMarketsNumberConfirmationMessage(recipientGroups.length),
      );
      const newRecipientList: string[] = [];
      recipientGroups.map((recipient) => newRecipientList.push(recipient.name));
      setRecipientList(newRecipientList);
    } else {
      // Should not show this confirmation dialog if the email is sent to the customer
      setShowConfirmation(false);
    }
  }, [recipientGroups]);

  const renderMessagesList = () => (
    <Stack gap={2}>
      <Typography variant="h4" mb={1}>
        {messages.emailEditor.sendMessagesGuards.listNoteHeader}
      </Typography>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        onClick={toggleMarketsNumberCollapse}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'common.white',
          },
        }}
      >
        <DoneIcon fontSize="small" sx={{ bgcolor: 'blue.600', borderRadius: '50%', p: 0.5, color: 'common.white' }} />
        <Typography variant="body1">{recipientMessage}</Typography>
        {isMarketsNumberCollapseOpen ? (
          <KeyboardArrowUpIcon fontSize="small" />
        ) : (
          <KeyboardArrowDownIcon fontSize="small" />
        )}
      </Stack>
      <Collapse in={isMarketsNumberCollapseOpen}>
        <Grid container pl={3.5} spacing={1.5}>
          {recipientList.map((recipient) => (
            <Grid key={recipient} item xs={4}>
              <Typography variant="body2">{recipient}</Typography>
            </Grid>
          ))}
        </Grid>
      </Collapse>
      {CustomConfirmationMessage && <CustomConfirmationMessage />}
    </Stack>
  );

  return (
    <ConfirmationDialogGuard
      title={messages.emailEditor.sendMessagesGuards.recipientsConfirmationTitle}
      message={renderMessagesList()}
      action={onSend}
      showConfirmation={showConfirmation}
    >
      {({ guardedAction }) => children({ guardedAction })}
    </ConfirmationDialogGuard>
  );
}
