import { ActivityType } from 'enums';
import { EmailItemType } from 'broker/components/Emails/types';
import { EmailEditorPageMode } from 'broker/enums';

// TODO: Rename this type.
export enum ReplyMode {
  Duplicate = 'Duplicate',
  FollowUp = 'FollowUp',
}

export interface EmailReplyLocationState {
  submissionEmailId: string;
  activityType?: ActivityType;
  initialReplyMode?: ReplyMode;
  emailType?: EmailItemType;
  mode: EmailEditorPageMode;
}
