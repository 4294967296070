import { MouseEvent, ReactNode } from 'react';
import { Button } from '@common-components';
import { SelectionActionButtonBaseProps } from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/types';

export interface BulkActionButtonProps extends Omit<SelectionActionButtonBaseProps, 'onClick'> {
  multipleSelection?: boolean;
  alwaysEnabled?: boolean;
  children: ReactNode;
  loading?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => Promise<void> | void;
}
export default function BulkActionButton({
  selectedMarketRequests,
  multipleSelection = true,
  alwaysEnabled = false,
  onSelectionButtonClick,
  loading = false,
  onClick,
  children,
  ...props
}: BulkActionButtonProps) {
  const disabled =
    !alwaysEnabled &&
    (selectedMarketRequests.length === 0 || (!multipleSelection && selectedMarketRequests.length > 1));
  return (
    <Button
      variant="contained"
      disabled={disabled}
      onClick={async (e: MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
          await onClick(e);
        }
        onSelectionButtonClick();
      }}
      loading={loading}
      {...props}
    >
      {children}
    </Button>
  );
}
