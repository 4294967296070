import { ReactNode } from 'react';
import { Stack, Typography } from '@common-components';
import HtmlText from 'broker/components/common/HtmlText';

interface QuoteFieldProps {
  title: string;
  data: string | string[];
  asHtml?: boolean;
}

function BulletText({ children }: { children: ReactNode }) {
  return (
    <Typography variant="body2" color="common.black">
      {children}
    </Typography>
  );
}

export default function QuoteField({ title, data, asHtml }: QuoteFieldProps) {
  const dataAsElement = (elementData: string) => (asHtml ? <HtmlText html={elementData} /> : elementData);

  return (
    <Stack gap={1}>
      <Typography variant="body2" color="textSecondary">
        {title}
      </Typography>
      <Stack gap={1}>
        {Array.isArray(data) ? (
          data.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <BulletText key={index}>{dataAsElement(item)}</BulletText>
          ))
        ) : (
          <BulletText>{dataAsElement(data)}</BulletText>
        )}
      </Stack>
    </Stack>
  );
}
