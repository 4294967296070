import { ContactRole } from 'enums';
import { messages } from 'i18n';

export const contactRoleConfig: Record<ContactRole, string> = {
  [ContactRole.Broker]: messages.roles.contactRoles.broker,
  [ContactRole.Underwriter]: messages.roles.contactRoles.underwriter,
  [ContactRole.Retailer]: messages.roles.contactRoles.retailer,
  [ContactRole.Insured]: messages.roles.contactRoles.insured,
  [ContactRole.SubmissionBox]: messages.roles.contactRoles.submissionBox,
};

export const getContactRole = (contactRole: ContactRole) => contactRoleConfig[contactRole] || contactRole;
