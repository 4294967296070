import { Stack, Typography } from '@common-components';

interface HeaderItemProps {
  title: string;
  subTitle: string;
}
export default function HeaderItem({ title, subTitle }: HeaderItemProps) {
  return (
    <Stack border={1} borderColor="divider" flex={1} p={2} borderRadius={1}>
      <Typography variant="body1Bold" noWrap>
        {title}
      </Typography>
      <Typography variant="caption2" color="typography.nonEssential" noWrap>
        {subTitle}
      </Typography>
    </Stack>
  );
}
