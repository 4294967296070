import { findAll } from 'highlight-words-core';
import parser from 'html-react-parser';
import { HighlighterProps } from '@common-components';

/**
 * Highlight text in html (as opposed to Highlight.tsx which highlights text in plain text)
 */
export const HighlightHtml = ({ searchWords, textToHighlight }: HighlighterProps) => {
  // create chunks from the html string
  const chunks = findAll({
    caseSensitive: false,
    searchWords,
    textToHighlight,
  });

  // convert chunks to string with highlighted keyword
  // for html-react-parser
  const formattedHTML = chunks
    .map((chunk) => {
      const { end, highlight, start } = chunk;
      const text = textToHighlight.slice(start, end);
      if (highlight) {
        return `<mark>${text}</mark>`;
      }
      return text;
    })
    .join('');
  return <>{parser(formattedHTML)}</>;
};
