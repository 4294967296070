import { compact } from 'lodash';
import { FunctionComponent, ReactElement, ReactNode } from 'react';
import { FieldErrors } from 'react-hook-form';
import { SxProps, Theme } from 'components/mui-index';

export function isOverflown(element?: Element | null) {
  if (!element) {
    return false;
  }

  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

export function uniteStyles(styles: SxProps<Theme>, sx?: SxProps<Theme>): SxProps<Theme> | undefined {
  if (!sx) {
    return styles;
  }

  if (typeof sx === 'object' && typeof styles === 'function') {
    return (theme: Theme) =>
      ({
        ...styles(theme),
        ...sx,
      } as const);
  }
  if (typeof sx === 'object' && typeof styles === 'object') {
    return () =>
      ({
        ...styles,
        ...sx,
      } as const);
  }
  if (typeof sx === 'function' && typeof styles === 'function') {
    return (theme: Theme) =>
      ({
        ...styles(theme),
        ...sx(theme),
      } as const);
  }
  if (typeof sx === 'function' && typeof styles === 'object') {
    return (theme: Theme) =>
      ({
        ...styles,
        ...sx(theme),
      } as const);
  }

  return undefined;
}

// converting MUI spacing value ( for example '12px' ) to Number for calculation purposes.
export const getSpacing = (theme: Theme, value: number) => Number(theme.spacing(value).slice(0, -2));

export function scrollIntoView(input?: HTMLElement | null, options: ScrollIntoViewOptions = {}) {
  input?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    ...options,
  });
}

export const scrollToError = (errors: FieldErrors) => {
  const firstErrorElement = document.getElementById(Object.keys(errors)[0]);
  scrollIntoView(firstErrorElement);
};

export const scrollToElement = (id: string, options: ScrollIntoViewOptions = {}) => {
  const element = document.getElementById(id);
  scrollIntoView(element, options);
};

export const scrollToElementPosition = (id: string, options: ScrollToOptions = {}) => {
  const element = document.getElementById(id);
  element?.scrollTo(options);
};
interface ConditionalWrapProps {
  condition: boolean;
  wrap: (children: ReactElement) => ReactElement;
  children: ReactElement | ReactNode;
}
export const ConditionalWrap: FunctionComponent<ConditionalWrapProps> = ({
  condition,
  wrap,
  children,
}: ConditionalWrapProps) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  const wrappedChildren = <>{children}</>;
  return condition ? wrap(wrappedChildren) : wrappedChildren;
};

export const demoData = (count: number) =>
  Array(count)
    .fill(null)
    .map((_val, index) => ({ id: index }));

export const DOT_SEPARATOR = ' • ';
export const getDotSeparated = (values: (string | undefined)[] = []) => compact(values).join(DOT_SEPARATOR);

export const truncateMultiLineText = (maxLines: number): SxProps<Theme> => ({
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: `${maxLines}`,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  whiteSpace: 'initial',
});
