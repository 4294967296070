import { Query, QueryCache, QueryClient } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';
import { ApiError } from 'clients/errors';

export const useInitializeReactQuery = (onError: (error: Error, extraLogInfo: string) => void) => {
  const queryClientRef = useRef<QueryClient | undefined>(undefined);

  const onReactQueryError = useCallback(
    (error: unknown, query: Query) => {
      if (!(error instanceof ApiError)) {
        onError(error as Error, `React Query: ${query.state.fetchFailureCount} failures`);
      }
    },
    [onError],
  );

  // configure global error handlers for react-query
  const queryCache = new QueryCache({
    onError: onReactQueryError,
  });

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({ queryCache });
  }

  return { queryClient: queryClientRef.current };
};
