import { HeraldNormalizedParameter } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';

export const buildFieldName = (
  fieldNamePrefix: string,
  fieldName: string,
  placeholder: string,
  heraldParameter: HeraldNormalizedParameter,
) => {
  const fields = heraldParameter.schema.properties!;
  const name = `${fieldNamePrefix}.${fieldName}`;
  return {
    name,
    id: name,
    label: `${(heraldParameter.schema.required?.includes(fieldName) ? '*' : '') + fields[fieldName]!.title}`,
    placeholder,
    required: true,
  };
};
