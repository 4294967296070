import { Skeleton, SkeletonProps } from '@common-components';

interface TextSkeletonProps extends SkeletonProps {
  isLoading: boolean;
  index?: number;
}

export default function TextSkeleton({ children, isLoading, width = 200, index = 0, ...props }: TextSkeletonProps) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoading ? (
        <Skeleton
          animation="wave"
          width={width}
          sx={{
            '&::after': {
              animationDelay: `${index * 300}ms`,
            },
          }}
          {...props}
        />
      ) : (
        children
      )}
    </>
  );
}
