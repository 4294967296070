import { Button, MobileStepper } from '@common-components';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@icons';
import { QuoteComparisonInsightsRoutes } from 'broker/broker-routes';
import { quoteComparisonSteps } from './types';

interface FooterStepperProps {
  currentStep: QuoteComparisonInsightsRoutes;
  handleChangeStep: (activeStep: QuoteComparisonInsightsRoutes, direction: 'next' | 'prev') => void;
}
export default function FooterStepper({ currentStep, handleChangeStep }: FooterStepperProps) {
  return (
    <MobileStepper
      variant="dots"
      steps={quoteComparisonSteps.length}
      position="static"
      activeStep={quoteComparisonSteps.indexOf(currentStep)}
      sx={{ maxWidth: 400, flexGrow: 1, bgcolor: 'transparent', gap: 2 }}
      nextButton={
        <Button
          size="small"
          onClick={() => handleChangeStep(currentStep, 'next')}
          disabled={quoteComparisonSteps.indexOf(currentStep) === quoteComparisonSteps.length - 1}
        >
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button
          size="small"
          onClick={() => handleChangeStep(currentStep, 'prev')}
          disabled={quoteComparisonSteps.indexOf(currentStep) === 0}
        >
          <KeyboardArrowLeft />
        </Button>
      }
    />
  );
}
