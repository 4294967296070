import { PromptResult, PromptResultCreate } from '@common/types';
import { logger } from 'utils';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export class PromptResultClient extends CapitolaClient<PromptResult> {
  public get resourceName(): string {
    return 'prompt-result';
  }

  public async batchCreate(data: PromptResultCreate[]): Promise<{} | null> {
    try {
      return await this.custom({
        httpMethod: HttpMethods.POST,
        body: { data },
        path: `batch`,
        fetchOptions: { throwOnError: true },
      });
    } catch (e) {
      // this operation should happen in the background, so we silently want to log the error
      logger.log('error', { message: 'Error while batch creating prompt results', error: e });
    }
    return null;
  }
}
