import { ReactNode } from 'react';
import { messages } from 'i18n';
import { ButtonProps } from 'components/Button';
import Dialog from './Dialog';
import DialogContent from './DialogContent';
import DialogFooter from './DialogFooter';
import DialogHeader from './DialogHeader';

export type ConfirmationDialogProps = {
  message: ReactNode;
  title: string;
  isOpen: boolean;
  onSubmit: () => Promise<void> | void;
  onCancel: () => void;
  hideCancelButton?: boolean;
  isSubmitting?: boolean;
  cancelButton?: ButtonProps;
  proceedButton?: ButtonProps;
};

export const ConfirmationDialog = ({
  message,
  title,
  isOpen,
  onSubmit,
  onCancel,
  isSubmitting = false,
  cancelButton,
  proceedButton,
  hideCancelButton = false,
}: ConfirmationDialogProps) => (
  <Dialog
    open={isOpen}
    onClose={onCancel}
    aria-labelledby="confirmation-dialog-title"
    aria-describedby="confirmation-dialog-description"
    alertDialog
    // usually, the z-index of the dialog is 1300, but we want to make sure the dialog is on top of the tooltip for the note component case
    sx={{
      zIndex: (theme) => theme.zIndex.tooltip + 1,
    }}
  >
    <DialogHeader title={title} id="confirmation-dialog-title" onClose={onCancel} />
    <DialogContent dividers={false} sx={{ mt: 1 }}>
      {message}
    </DialogContent>
    <DialogFooter
      proceedButton={{
        children: messages.buttons.continue,
        onClick: onSubmit,
        loading: isSubmitting,
        ...proceedButton,
      }}
      {...(!hideCancelButton && {
        cancelButton: {
          children: messages.buttons.cancel,
          onClick: onCancel,
          ...cancelButton,
        },
      })}
      disableElevation
    />
  </Dialog>
);
