import clsx from 'clsx';
import { isString } from 'lodash';
import { forwardRef, MouseEvent } from 'react';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@icons';
// eslint-disable-next-line import/no-internal-modules
import { useHotjar } from 'hooks/useHotjar';
import { HotjarEvents } from 'utils/hotjar-events';
import { Highlight } from './Highlight';
import InlineBadge from './InlineBadge';
import { alpha, Badge, badgeClasses, Box, ButtonBase, Stack, SvgIcon, Theme, Typography } from './mui-index';
import { ProcessingAnimation } from './ProcessingAnimation';
import Tooltip from './Tooltip';
import { BadgeVariant, Color, Size, TooltipWrapperProps, Variant } from './types';

type ChipVariant = Extract<Variant, 'contained' | 'outlined'>;
type ChipColor = Extract<Color, 'primary' | 'secondary'>;
export type ChipSize = 'extraSmall' | Size;

export interface ChipProps extends TooltipWrapperProps {
  /** The label to display in the chip */
  label?: string;
  /**
   * The size of the component.
   * @default 'medium'
   */
  size?: ChipSize;
  /** The color of the chip can be 'primary', 'secondary' or any custom color ('#fffff', "blue.600") */
  color?: ChipColor | string;
  /** The color of the text on the chip ('#fffff', "blue.600") */
  textColor?: string;
  /** The color of the icon on the chip ('#fffff', "blue.600") */
  iconColor?: string;
  /**
   * The variant to use.
   * @default 'contained'
   */
  variant?: ChipVariant;
  /**
   * Determent the corners style.
   * @default 'true'
   */
  rounded?: boolean;
  /** The function to call when the chip is clicked */
  onClick?: ((event: MouseEvent<HTMLButtonElement>) => void) | ((event?: MouseEvent<HTMLButtonElement>) => void);
  /**
   * The icon to display in the start of chip
   * @type typeof SvgIcon
   * */
  startIcon?: typeof SvgIcon;
  /**
   * The icon to display in the end of chip
   * @type typeof SvgIcon
   * */
  endIcon?: typeof SvgIcon;
  /** The text to display in the end Badge
   * @type number | string
   * */
  endBadgeContent?: number | string;
  /** The variant of the end Badge */
  endBadgeVariant?: BadgeVariant;
  /** The color of the end Badge */
  endBadgeColor?: Color;
  /** The text to display in the start Badge
   * @type number | string
   * */
  startBadgeContent?: number | string;
  /** The color of the start Badge */
  startBadgeColor?: Color;
  /** The variant of the end Badge */
  startBadgeVariant?: BadgeVariant;
  /** The hotjar event to report when the button is clicked */
  hotjarEvent?: HotjarEvents;
  /** If true, the chip will be displayed as a dropdown */
  dropdown?: boolean;
  /** If true, the chip will be disabled */
  disabled?: boolean;
  /** If true, the chip will be in error mode */
  error?: boolean;
  /** If true, the chip will be in focus state   */
  focused?: boolean;
  /** search terms to highlight */
  searchTerm?: string;
  /** notification badge */
  indicator?: boolean;
  /** If true, processing animation will be displayed */
  processing?: boolean;
  /** If true, the tooltip will be open */
  fullWidth?: boolean;
}

const variantProps: Record<ChipVariant, Record<ChipColor, any>> = {
  contained: {
    primary: {
      color: 'common.white',
      bgcolor: 'primary.main',
      '&.focused': {
        bgcolor: 'blue.700',
      },
      '&.error': {
        bgcolor: 'error.light',
        color: 'error.main',
      },
      '&[disabled], &.disabled': {
        color: 'text.disabled',
        bgcolor: 'grey.50',
      },
      '&:hover.clickable': {
        bgcolor: 'blue.500',
      },
    },
    secondary: {
      color: 'text.secondary',
      bgcolor: 'grey.50',
      '&.focused': {
        bgcolor: (theme: Theme) => alpha(theme.palette.primary.main, 0.06),
      },
      '&.error': {
        bgcolor: 'error.light',
        color: 'error.main',
      },
      '&[disabled], &.disabled': {
        color: 'text.disabled',
        bgcolor: 'grey.50',
      },
      '&:hover.clickable': {
        bgcolor: (theme: Theme) => alpha(theme.palette.blueGrey[800]!, 0.06),
      },
    },
  },
  outlined: {
    primary: {
      color: 'primary.main',
      bgcolor: 'transparent',
      border: 1,
      borderColor: 'primary.main',
      '&.focused': {
        color: 'primary.main',
        bgcolor: (theme: Theme) => alpha(theme.palette.primary.main, 0.06),
        borderColor: 'primary.main',
      },
      '&.error': {
        color: 'error.main',
        bgcolor: 'error.light',
        borderColor: 'error.main',
      },
      '&[disabled], &.disabled': {
        color: 'text.disabled',
        bgcolor: 'transparent',
        borderColor: 'blueGrey.200',
      },
      '&:hover.clickable': {
        bgcolor: (theme: Theme) => alpha(theme.palette.blueGrey[800]!, 0.06),
      },
    },
    secondary: {
      color: 'text.secondary',
      bgcolor: 'transparent',
      border: 1,
      borderColor: 'blueGrey.200',
      '&.focused': {
        color: 'primary.main',
        bgcolor: (theme: Theme) => alpha(theme.palette.primary.main, 0.06),
        borderColor: 'primary.main',
      },
      '&.error': {
        color: 'error.main',
        borderColor: 'error.main',
        bgcolor: 'error.light',
      },
      '&[disabled], &.disabled': {
        color: 'text.disabled',
        bgcolor: 'transparent',
        borderColor: 'blueGrey.200',
      },
      '&:hover.clickable': {
        bgcolor: (theme: Theme) => alpha(theme.palette.blueGrey[800]!, 0.06),
      },
    },
  },
};

const variantCustomColorProps: (color: string) => Record<ChipVariant, any> = (color: string) => ({
  contained: {
    color: 'text.primary',
    bgcolor: color,
    transition: (theme: Theme) =>
      theme.transitions.create(['filter'], {
        duration: theme.transitions.duration.short,
      }),
    '&.focused': {
      filter: 'brightness(1.1)',
    },
    '&.error': {
      bgcolor: 'error.light',
      color: 'error.main',
    },
    '&[disabled], &.disabled': {
      color: 'text.disabled',
      bgcolor: 'grey.50',
    },
    '&:hover.clickable': {
      filter: 'brightness(0.9)',
    },
  },
  outlined: {
    color: 'text.primary',
    bgcolor: 'transparent',
    border: 1,
    borderColor: color,
    transition: (theme: Theme) =>
      theme.transitions.create(['filter'], {
        duration: theme.transitions.duration.short,
      }),
    '&.focused': {
      filter: 'brightness(1.1)',
    },
    '&.error': {
      bgcolor: 'error.light',
      color: 'error.main',
    },
    '&[disabled], &.disabled': {
      color: 'text.disabled',
      bgcolor: 'grey.50',
      borderColor: 'blueGrey.200',
    },
    '&:hover.clickable': {
      filter: 'brightness(0.9)',
    },
  },
});

export const chipComponentsProps: Record<ChipVariant, Record<ChipSize, any>> = {
  contained: {
    extraSmall: {
      py: '0px',
      px: '0px',
    },
    small: {
      py: '2px',
      px: '3px',
    },
    medium: {
      py: '6px',
      px: '9px',
    },
    large: {
      py: '12px',
      px: '16px',
    },
  },
  outlined: {
    extraSmall: {
      py: '0px',
      px: '0px',
    },
    small: {
      py: '1px',
      px: '2px',
    },
    medium: {
      py: '5px',
      px: '8px',
    },
    large: {
      py: '11px',
      px: '15px',
    },
  },
};

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      label,
      disabled = false,
      color = 'primary',
      iconColor = 'inherit',
      focused = false,
      onClick,
      endIcon,
      startIcon,
      variant = 'contained',
      size = 'medium',
      hotjarEvent,
      dropdown = false,
      rounded = false,
      endBadgeContent,
      endBadgeColor = 'primary',
      endBadgeVariant = 'standard',
      startBadgeContent,
      startBadgeVariant = 'standard',
      startBadgeColor = 'primary',
      tooltipContent,
      disabledTooltipContent,
      tooltipPlacement = 'top',
      isTooltipOpen,
      disableTooltipPadding = false,
      tooltipDarkMode = false,
      error = false,
      searchTerm = '',
      indicator = false,
      textColor,
      processing = false,
      fullWidth = false,
      ...props
    },
    ref,
  ) => {
    const hotjar = useHotjar();
    const tooltipSearchedContent =
      searchTerm && isString(tooltipContent) ? (
        <Highlight searchWords={[searchTerm]} textToHighlight={tooltipContent} />
      ) : (
        tooltipContent
      );
    const tooltipContentToUse = disabled ? disabledTooltipContent : tooltipSearchedContent;

    const EndIcon = endIcon;
    const StartIcon = startIcon;
    const clickHandler = (e: MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        if (hotjarEvent) {
          hotjar.event(hotjarEvent);
        }
        onClick(e);
      }
    };

    const tooltipSearchDetected =
      searchTerm && isString(tooltipContent) && tooltipContent.toLowerCase().includes(searchTerm.toLowerCase());

    return (
      <Tooltip
        tooltipContent={tooltipContentToUse ?? ''}
        placement={tooltipPlacement}
        isOpen={isTooltipOpen}
        disablePadding={disableTooltipPadding}
        darkMode={tooltipDarkMode}
        arrow
        {...props}
      >
        <Box ref={ref} display="inline-block" overflow="hidden" width={fullWidth ? 1 : 'auto'}>
          <Badge
            variant="dot"
            color="success"
            invisible={!indicator && !tooltipSearchDetected}
            sx={{
              maxWidth: 1,
              ...(rounded &&
                (indicator || tooltipSearchDetected) && {
                  [`& .${badgeClasses.colorSuccess}`]: {
                    top: 4,
                    right: 4,
                    transform: 'translate(4px, -4px)',
                  },
                }),
              ...(color !== 'primary' &&
                color !== 'secondary' &&
                variant === 'outlined' && {
                  position: 'relative',
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: rounded ? 8 : 1,
                    bgcolor: color,
                    opacity: 0.1,
                    pointerEvents: 'none',
                  },
                }),
            }}
          >
            <ButtonBase
              onClick={clickHandler}
              disabled={disabled}
              disableRipple={!onClick}
              disableTouchRipple={!onClick}
              className={clsx({ focused }, { error }, { disabled }, { clickable: !!onClick })}
              sx={{
                overflow: 'hidden',
                borderRadius: rounded ? 8 : 1,
                ...(color === 'primary' || color === 'secondary'
                  ? { ...variantProps[variant][color] }
                  : { ...variantCustomColorProps(color)[variant] }),
                ...chipComponentsProps[variant][size],
                ...(!onClick && { cursor: 'default' }),
                transition: (theme) =>
                  theme.transitions.create(['background-color', 'color', 'border-color'], {
                    duration: theme.transitions.duration.short,
                  }),
                gap: 0.5,
              }}
            >
              {processing && <ProcessingAnimation />}
              {(startBadgeContent || startIcon) && (
                <Stack direction="row" gap={0.5} alignItems="center">
                  {StartIcon && <StartIcon sx={{ fontSize: '1rem', color: iconColor }} color="inherit" />}
                  {startBadgeContent && (
                    <InlineBadge
                      badgeContent={startBadgeContent}
                      badgeVariant={startBadgeVariant}
                      badgeColor={startBadgeColor}
                      disabled={disabled}
                      badgeSize="small"
                    />
                  )}
                </Stack>
              )}
              {label && (
                <Typography px={0.5} variant="captionBold" color={textColor || 'inherit'} noWrap>
                  <Highlight searchWords={[searchTerm]} textToHighlight={label} />
                </Typography>
              )}
              {(EndIcon || endBadgeContent || dropdown) && (
                <Stack direction="row" gap={0.5} alignItems="center">
                  {EndIcon && <EndIcon sx={{ fontSize: '1rem', color: iconColor }} color="inherit" />}
                  {endBadgeContent && (
                    <InlineBadge
                      badgeContent={endBadgeContent}
                      badgeColor={endBadgeColor}
                      disabled={disabled}
                      badgeSize="small"
                      badgeVariant={endBadgeVariant}
                    />
                  )}
                  {dropdown && <KeyboardArrowDownIcon sx={{ fontSize: '1rem' }} />}
                </Stack>
              )}
            </ButtonBase>
          </Badge>
        </Box>
      </Tooltip>
    );
  },
);

export default Chip;
