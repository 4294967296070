import { useCallback, useContext, useMemo } from 'react';
import { NO_TOKEN_SKIP_FETCH } from 'clients';
import BoxClient from 'clients/box';
import { ErrorContext } from 'contexts/error/error';
import { logger } from 'utils';
import { useCurrentUser } from './api/currentUser';

export function useBoxClient() {
  const { me, refetchMe } = useCurrentUser();
  const { onError } = useContext(ErrorContext);

  const getToken = useCallback(async () => {
    const { boxAccessToken } = me || {};
    const { expirationDate, token } = boxAccessToken || {};

    // If the token expired or missing, refresh it and return the new token
    if (!token || (expirationDate && Date.now() >= expirationDate)) {
      logger.log('info', 'refreshing box token');

      const { data: currentUser, isFetching } = await refetchMe();

      if (!!token && token === currentUser?.boxAccessToken?.token) {
        logger.log(
          'error',
          `using old token, isFetching = ${isFetching}, expirationDate = ${currentUser?.boxAccessToken.expirationDate}`,
        );
      }

      if (!currentUser) {
        logger.log('error', 'failed to retrieve user, skipping box fetch');
        return NO_TOKEN_SKIP_FETCH;
      }

      if (!currentUser.boxAccessToken?.token) {
        logger.log(
          'error',
          `retrieved user without a box token ${token ? 'falling back to previous box token' : 'skipping box fetch'}`,
        );
      } else {
        logger.log('info', `using new box token, expirationDate: ${currentUser.boxAccessToken.expirationDate}`);
        return currentUser.boxAccessToken.token;
      }
    }

    if (!token) {
      logger.log('error', 'no box token found, skipping box fetch');
    } else {
      logger.log(
        'info',
        `using existing box token, expiring at: ${expirationDate}, bases on expiresInMS: ${boxAccessToken?.expiresInMS}`,
      );
    }

    return Promise.resolve(token ?? NO_TOKEN_SKIP_FETCH);
  }, [me, refetchMe]);

  return useMemo(
    () =>
      new BoxClient({
        getToken,
        onError,
      }),
    [getToken, onError],
  );
}
