import { Badge, badgeClasses } from './mui-index';
import { BadgeVariant, Color } from './types';

export interface InlineBadgeProps {
  /** The number or string to display in the badge */
  badgeContent?: number | string;
  /** The color of the badge */
  badgeColor?: Color;
  /** Whether the badge is disabled */
  disabled?: boolean;
  /** The size of the badge */
  badgeSize?: 'small' | 'default';
  /** The variant of the badge */
  badgeVariant?: BadgeVariant;
}
export default function InlineBadge({
  badgeContent,
  badgeColor,
  disabled,
  badgeSize = 'default',
  badgeVariant = 'standard',
}: InlineBadgeProps) {
  const boxSize = badgeSize === 'small' ? 16 : 20;
  return (
    <Badge
      badgeContent={badgeContent}
      variant={badgeVariant}
      color={badgeColor}
      sx={{
        width: badgeContent ? 'auto' : 0,
        [`& .${badgeClasses.badge}`]: {
          ...(badgeVariant === 'standard' && { px: badgeContent ? 0.75 : 0 }),
          ...(badgeVariant === 'standard' && { minWidth: badgeContent ? 20 : 0 }),
          ...(badgeVariant === 'standard' && { height: badgeContent ? boxSize : 0 }),
          ...(disabled && { bgcolor: 'grey.400' }),
          color: badgeColor === 'info' ? 'text.primary' : 'common.white',
          position: 'relative',
          transform: 'scale(1) translate(0%, 0%)',
          transformOrigin: '50% 50%',
          transition: (theme) =>
            theme.transitions.create(['all'], {
              duration: theme.transitions.duration.short,
            }),
          [`&.${badgeClasses.invisible}`]: {
            transform: 'scale(0) translate(0%, 0%)',
          },
        },
      }}
    />
  );
}
