import { isEmpty, startCase } from 'lodash';
import { messages } from 'i18n';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { BulletedContent } from './BulletedContent';
import { ProductEmailSignal } from './ProductEmailSignal';

export const ProductsDataSection = () => {
  const { enrichmentData } = useAIContext();

  if (isEmpty(enrichmentData?.productsEmailSignals)) {
    return null;
  }

  const { productsEmailSignals } = enrichmentData!;

  return (
    <BulletedContent
      level={1}
      content={`${startCase(messages.submissionWorkspace.aiGeneratedContentPopup.productsEmailSignals)}`}
    >
      {productsEmailSignals?.map((productEmailSignal) => (
        <ProductEmailSignal
          key={productEmailSignal.name}
          name={productEmailSignal.name}
          emails={productEmailSignal.emails}
        />
      ))}
      <BulletedContent level={2} content={messages.submissionWorkspace.aiGeneratedContentPopup.marketBrochures} />
    </BulletedContent>
  );
};
