import { BROKER_SITE_ROLES } from 'enums';
import { useCurrentUser } from 'hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export function useBlockedByCopilot() {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { me } = useCurrentUser();

  return partialSubmission?.isCopilotEnabled && BROKER_SITE_ROLES.includes(me!.role);
}
