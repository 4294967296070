import { lobCoverageLineMapping } from '@common/config';
import { getLineOfBusinessForCoverageLine } from '@common/utils';
import { flatten, uniq } from 'lodash';
import { useCallback } from 'react';
import { CoverageLine } from 'enums';
import { useSearchInsuranceProduct } from 'hooks';

export function useGetApiProductsByCoverageLines() {
  const { refetch: fetchProducts } = useSearchInsuranceProduct({
    enabled: false,
  });

  const getApiProductsByCoverageLines = useCallback(
    async (coverageLines: CoverageLine[]) => {
      const { data } = await fetchProducts();
      const products = data?.items || [];

      // if the coverage line was from a line of business of family, we need to include all the coverage lines family
      const coverageLinesEntireFamily = uniq(
        flatten(
          coverageLines.map((coverageLine) => {
            const lineOfBusiness = lobCoverageLineMapping[coverageLine]
              ? coverageLine
              : getLineOfBusinessForCoverageLine(coverageLine);
            if (lineOfBusiness && lobCoverageLineMapping[lineOfBusiness]) {
              return [lineOfBusiness, ...(lobCoverageLineMapping[lineOfBusiness] || [])];
            }
            return [coverageLine];
          }),
        ),
      );

      return products
        .filter((product) =>
          coverageLinesEntireFamily.some((submissionCoverageLine) =>
            product.coverageLines.some(
              (insuranceProductCoverageLine) => insuranceProductCoverageLine.coverageLine === submissionCoverageLine,
            ),
          ),
        )
        .filter((product) => !!product.externalProductId);
    },
    [fetchProducts],
  );

  return {
    getApiProductsByCoverageLines,
  };
}
