import { messages } from 'i18n';
import { InformationRequestType, SubmissionMarketRequest } from 'types';
import getOpenItems from './open-items-config';

export const informationRequestedConfig = (
  submissionMarketRequest: SubmissionMarketRequest,
): { [item in InformationRequestType]?: string } => ({
  ...Object.entries(getOpenItems(submissionMarketRequest.marketName))
    .filter(([, value]) => value.informationRequested)
    .reduce<{ [item in InformationRequestType]?: string }>((acc, [key, value]) => {
      acc[key as InformationRequestType] = value.text;
      return acc;
    }, {}),
});

export const getInformationRequestedMessage = (
  submissionMarketRequest?: SubmissionMarketRequest,
  singleItemMutation: (message?: string) => string = (message) => message ?? '',
) => {
  if (!submissionMarketRequest?.informationRequested) {
    return undefined;
  }
  const config = informationRequestedConfig(submissionMarketRequest);
  return submissionMarketRequest.informationRequested
    .map((info) => {
      const currentInformationRequestedType = config[info.type];
      if (info.type === 'Other') {
        return singleItemMutation(info.details);
      }
      if (!currentInformationRequestedType) {
        return null;
      }
      const formattedMessages = messages.templateVariables.informationRequested(
        currentInformationRequestedType,
        info.details,
      );
      return singleItemMutation(formattedMessages);
    })
    .filter(Boolean)
    .join('');
};
