import { LocalStorage } from 'enums';

export enum FilterType {
  Insured = 'insuredName',
  CoverageLine = 'coverageLines',
  Status = 'status',
  Assignee = 'user',
  DatesRange = 'dueDate',
  Customer = 'customer',
}

export enum SubmissionSortType {
  CreatedDateNewest = 'CreatedDateNewest',
  CreatedDateOldest = 'CreatedDateOldest',
  EffectiveDateNewest = 'EffectiveDateNewest',
  EffectiveDateOldest = 'EffectiveDateOldest',
  Updated = 'Updated',
  InsuredAsc = 'InsuredAsc',
  InsuredDesc = 'InsuredDesc',
  ReminderDateNewest = 'ReminderDateNewest',
  ReminderDateOldest = 'ReminderDateOldest',
}

export enum SubmissionViewMode {
  PreviewBox = 'PreviewBox',
  General = 'General',
  Retailer = 'Retailer',
}

export const SubmissionsViewStorageKeys = {
  insured: `${LocalStorage.SubmissionsView}_${FilterType.Insured}`,
  coverageLine: `${LocalStorage.SubmissionsView}_${FilterType.CoverageLine}`,
  status: `${LocalStorage.SubmissionsView}_${FilterType.Status}`,
  datesRange: `${LocalStorage.SubmissionsView}_${FilterType.DatesRange}`,
  assignee: `${LocalStorage.SubmissionsView}_${FilterType.Assignee}`,
  customer: `${LocalStorage.SubmissionsView}_${FilterType.Customer}`,
  insuredAll: `${LocalStorage.SubmissionsView}_${FilterType.Insured}_all`,
  coverageLineAll: `${LocalStorage.SubmissionsView}_${FilterType.CoverageLine}_all`,
  statusAll: `${LocalStorage.SubmissionsView}_${FilterType.Status}_all`,
  assigneeAll: `${LocalStorage.SubmissionsView}_${FilterType.Assignee}_all`,
  datesRangeAll: `${LocalStorage.SubmissionsView}_${FilterType.DatesRange}_all`,
  customerAll: `${LocalStorage.SubmissionsView}_${FilterType.Customer}_all`,
  sort: `${LocalStorage.SubmissionsView}_sort`,
  pinned: `${LocalStorage.SubmissionsView}_pinned`,
} as const;

export const SubmissionBoxStorageKeys = {
  insured: `${LocalStorage.SubmissionsBox}_${FilterType.Insured}`,
  coverageLine: `${LocalStorage.SubmissionsBox}_${FilterType.CoverageLine}`,
  status: `${LocalStorage.SubmissionsBox}_${FilterType.Status}`,
  datesRange: `${LocalStorage.SubmissionsBox}_${FilterType.DatesRange}`,
  assignee: `${LocalStorage.SubmissionsBox}_${FilterType.Assignee}`,
  customer: `${LocalStorage.SubmissionsBox}_${FilterType.Customer}`,
  insuredAll: `${LocalStorage.SubmissionsBox}_${FilterType.Insured}_all`,
  coverageLineAll: `${LocalStorage.SubmissionsBox}_${FilterType.CoverageLine}_all`,
  statusAll: `${LocalStorage.SubmissionsBox}_${FilterType.Status}_all`,
  assigneeAll: `${LocalStorage.SubmissionsBox}_${FilterType.Assignee}_all`,
  datesRangeAll: `${LocalStorage.SubmissionsBox}_${FilterType.DatesRange}_all`,
  customerAll: `${LocalStorage.SubmissionsBox}_${FilterType.Customer}_all`,
  sort: `${LocalStorage.SubmissionsBox}_sort`,
} as const;
