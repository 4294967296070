import { isEmpty, sortBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { messages } from 'i18n';
import { SelectProduct } from 'types';
import { coverageLineConfig } from 'utils';
import FormAutocomplete from 'components/hookFormComponents/FormAutocomplete/FormAutocomplete';
import { AutocompleteOption } from 'components/hookFormComponents/FormAutocomplete/utils';
import { detailsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/detailsFieldsConfig';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';

interface ProductSelectProps {
  initialProductId?: string;
  selectProducts: SelectProduct[];
  isLoading: boolean;
}

type SelectProductOption = AutocompleteOption & { inSubmission: boolean };

const addQuoteMessages = messages.addQuotePage;

export default function ProductSelect({ initialProductId, selectProducts, isLoading }: ProductSelectProps) {
  const fieldName = detailsFormFieldsConfig[DetailsFieldsNames.InsuranceProductId].name;
  const { setValue } = useFormContext();

  const getSelectProductCategory = (selectProduct: SelectProductOption) =>
    selectProduct.inSubmission
      ? addQuoteMessages.selectProductGroups.inSubmission
      : addQuoteMessages.selectMarketGroups.notInSubmission;

  const getProductLabel = (selectProduct: SelectProduct) =>
    `${selectProduct.marketName} ${selectProduct.label ? `, ${selectProduct.label}` : ''}`;

  const getProductSubLabel = (selectProduct: SelectProduct) => {
    const productCoverageLines = selectProduct.coverageLines
      .map((productCoverageLine) => coverageLineConfig[productCoverageLine.coverageLine].text)
      .join(', ');
    const isPrimaryLabel = selectProduct.isPrimary ? addQuoteMessages.primary : addQuoteMessages.excess;
    const isAdmittedLabel = selectProduct.isAdmitted ? addQuoteMessages.admitted : addQuoteMessages.nonAdmitted;
    return `${
      !isEmpty(productCoverageLines) ? `${productCoverageLines} | ` : ''
    } ${isPrimaryLabel} | ${isAdmittedLabel}`;
  };

  useEffect(() => {
    if (initialProductId) {
      setValue(fieldName, initialProductId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProductId]);

  const productOptions = useMemo(
    () =>
      sortBy(
        selectProducts.map((selectProduct) => ({
          label: getProductLabel(selectProduct),
          subLabel: getProductSubLabel(selectProduct),
          value: selectProduct.id,
          inSubmission: selectProduct.inSubmission,
        })),
        [(product) => !product.inSubmission, 'label'],
      ),
    [selectProducts],
  );

  return (
    <FormAutocomplete
      isLoading={isLoading}
      disabled={!!initialProductId}
      options={productOptions}
      groupBy={getSelectProductCategory}
      autoSelect={false}
      {...detailsFormFieldsConfig[DetailsFieldsNames.InsuranceProductId]}
    />
  );
}
