import { groupBy } from 'lodash';
import { Stack } from '@common-components';
import { formatBigNumber, isSubmissionLimitReached, sortLayersByAttachmentPoint } from 'utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import Header from './Header';
import LayersList from './LayersList';

export interface TowerProps {
  hideQuoteActions?: boolean;
  hideConceptualTowerShareButton?: boolean;
}
export default function Tower({ hideQuoteActions, hideConceptualTowerShareButton }: TowerProps) {
  const { partialSubmission, submission, layers, quotes } = useSubmissionsWorkspace();

  const limit = partialSubmission?.limit ? formatBigNumber(partialSubmission.limit) : null;

  const reachedLimit = formatBigNumber(partialSubmission?.reachedLimit || 0);

  const quotesByLayer = groupBy(quotes, ({ layerId }) => layerId);

  const sortedLayers = sortLayersByAttachmentPoint(layers);

  return (
    <Stack overflow="auto" height={1}>
      <Header
        limit={limit}
        reachedLimit={reachedLimit}
        hideConceptualTowerShareButton={hideConceptualTowerShareButton}
      />
      <Stack overflow="auto" flex={1}>
        <LayersList
          layers={sortedLayers}
          quotes={quotesByLayer}
          submissionLimitReached={!!submission?.limit && isSubmissionLimitReached(submission)}
          hideQuoteActions={hideQuoteActions}
        />
      </Stack>
    </Stack>
  );
}
