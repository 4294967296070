import { useState } from 'react';
import { Route, Routes } from 'react-router-dom-latest';
import { QuoteComparisonInsightsRoutes } from 'broker/broker-routes';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import Compose from './formSteps/Compose';
import Upload from './formSteps/Upload';
import { QuoteComparisonProps } from './types';

export default function QuoteComparisonSteps({
  setIsDirty,
  setInsightPopulatedByAI,
  quotes,
  expiringPolicyFileId,
  pdfGenerationStatus,
  setPdfGenerationStatus,
}: Omit<QuoteComparisonProps, 'currentStep'>) {
  const { partialSubmission: submission } = useSubmissionsWorkspace();
  const [quoteComparisonFileId, setQuoteComparisonFileId] = useState<string>('');

  return (
    <Routes>
      <Route
        path={`${QuoteComparisonInsightsRoutes.Compose}/*`}
        element={
          <Compose
            quotes={quotes}
            setInsightPopulatedByAI={setInsightPopulatedByAI}
            expiringPolicyFileId={expiringPolicyFileId}
          />
        }
      />
      <Route
        path={`${QuoteComparisonInsightsRoutes.Upload}/*`}
        element={
          <Upload
            setIsDirty={setIsDirty}
            pdfGenerationStatus={pdfGenerationStatus}
            setPdfGenerationStatus={setPdfGenerationStatus}
            quotes={quotes}
            submission={submission!}
            quoteComparisonFileId={quoteComparisonFileId}
            setQuoteComparisonFileId={setQuoteComparisonFileId}
          />
        }
      />
    </Routes>
  );
}
