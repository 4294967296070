/* global google */
import { useEffect, useRef } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@common-components';
import { Insured } from 'types';
import { scrollIntoView } from 'utils';
import FormAutocomplete from 'components/hookFormComponents/FormAutocomplete/FormAutocomplete';
import FormAutocompleteCreatable from 'components/hookFormComponents/FormAutocomplete/FormAutocompleteCreatable';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { SuggestionProps } from 'components/hookFormComponents/types';
import IndustryAutoComplete from 'broker/components/common/IndustryAutoComplete';
import {
  companyTypes,
  formFieldsConfig,
} from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/Screens/SubmissionStaticForm/StaticForm/utils/form-utils';

interface SubmissionInsuredInformationProps {
  insured: Insured[];
  onAddressChange: (placeResult?: google.maps.places.PlaceResult) => void;
  navigatedToCreateContact: boolean;
  setNavigatedToCreateContact: (val: boolean) => void;
  onSelectedInsuredChange: (selectedInsured?: string | null) => void;
  appliedSuggestions?: Record<string, SuggestionProps>;
}

export default function SubmissionInsuredInformation({
  insured,
  onAddressChange,
  navigatedToCreateContact,
  setNavigatedToCreateContact,
  onSelectedInsuredChange,
  appliedSuggestions,
}: SubmissionInsuredInformationProps) {
  const methods = useFormContext();
  const { setValue } = methods;

  const customerRef = useRef<HTMLInputElement | null>(null);

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    options: {
      types: [],
    },
    language: 'en',
    onPlaceSelected: (place) => {
      if (place.formatted_address) {
        onAddressChange(place);
        setValue(formFieldsConfig.insuredAddress.name, place.formatted_address);
      }
    },
  });

  useEffect(() => {
    const element = ref.current as HTMLElement | null;
    if (element?.setAttribute) {
      // workaround to remove autocomplete "new-password" that google auto-complete adds to the input field
      element.setAttribute('autocomplete', 'off');
    }
  }, [ref]);

  useEffect(() => {
    // once returning from createContact screen then auto scroll to that field
    if (navigatedToCreateContact && customerRef.current) {
      scrollIntoView(customerRef.current);
      setNavigatedToCreateContact(false);
    }
  }, [navigatedToCreateContact, setNavigatedToCreateContact]);

  return (
    <Stack>
      <FormAutocompleteCreatable
        {...formFieldsConfig.insuredName}
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredName.name]}
        onChange={onSelectedInsuredChange}
        options={insured.map((insuredOption) => ({
          label: insuredOption.name,
          value: insuredOption.id,
        }))}
      />

      <IndustryAutoComplete
        fieldProps={formFieldsConfig.industry}
        suggestion={appliedSuggestions?.[formFieldsConfig.industry.name]}
      />

      <FormAutocomplete
        {...formFieldsConfig.insuredCompanyType}
        options={companyTypes.map((type) => ({ label: type, value: type }))}
        autoSelect={false}
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredCompanyType.name]}
      />

      <FormTextField
        {...formFieldsConfig.insuredEmployeeCount}
        isNumberFormat
        numberFormatProps={{ prefix: '' }}
        placeholder="1,000"
        fullWidth
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredEmployeeCount.name]}
      />

      <FormTextField
        {...formFieldsConfig.insuredAnnualRevenue}
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredAnnualRevenue.name]}
        isNumberFormat
        fullWidth
        placeholder="$10,000,000"
      />

      <FormTextField
        {...formFieldsConfig.insuredAddress}
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredAddress.name]}
        inputProps={{
          ref,
          onChange: () => {
            // once editing the address then reset the structured address state
            onAddressChange(undefined);
          },
        }}
        fullWidth
      />

      <FormTextField
        {...formFieldsConfig.insuredWebsite}
        fullWidth
        suggestion={appliedSuggestions?.[formFieldsConfig.insuredWebsite.name]}
      />
    </Stack>
  );
}
