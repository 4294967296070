// eslint-disable-next-line no-restricted-imports
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';
import { forwardRef, ReactChild, ReactElement, ReactFragment, ReactPortal } from 'react';
// eslint-disable-next-line no-restricted-imports
import sizes from 'themes/sizes';

export interface TooltipProps extends Omit<MuiTooltipProps, 'title'> {
  /** The text to display in the tooltip */
  tooltipContent: boolean | ReactChild | ReactFragment | ReactPortal;
  /** The element to display the tooltip on */
  children: ReactElement<any, any>;
  /** The placement of the tooltip */
  placement?:
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
  /** Whether to show the tooltip arrow */
  arrow?: boolean;
  /** Whether to use dark mode */
  darkMode?: boolean;
  /** Whether theme color is overridden */
  customColor?: string;
  /** Whether the tooltip is open programmatically */
  isOpen?: boolean;
  /** Whether to disable the padding of the tooltip window */
  disablePadding?: boolean;
  /** Whether the tooltip is enabled */
  enabled?: boolean;
}

const Tooltip = forwardRef(
  (
    {
      tooltipContent = '',
      children,
      placement = 'bottom',
      arrow = true,
      darkMode = false,
      customColor,
      disablePadding = false,
      isOpen,
      enabled = true,
      ...props
    }: TooltipProps,
    ref,
  ) => (
    <MuiTooltip
      ref={ref}
      title={enabled ? tooltipContent : ''}
      open={isOpen}
      placement={placement}
      arrow={arrow}
      enterDelay={300}
      enterNextDelay={300}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: customColor || (darkMode ? 'grey.900' : 'common.white'),
            boxShadow: 4,
            borderRadius: 1,
            textAlign: 'center',
            minWidth: sizes.tooltipMinWidth,
            maxWidth: sizes.tooltipMaxWidth,
            color: darkMode ? 'common.white' : 'inherit',
            padding: disablePadding ? 0 : 1,
          },
        },
        arrow: {
          sx: {
            color: customColor || (darkMode ? 'grey.900' : 'common.white'),
            '&::before': {
              boxShadow: 2,
            },
          },
        },
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  ),
);

export default Tooltip;
