import { Box, Stack, Typography } from '@common-components';
import useProposalSmartObjectMenu from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/Customer/Proposal/useProposalSmartObjectMenu';
import AwesomeIcon from './icon.svg';
import SmartReportsToolTip from './SmartReportsTooltip';

interface SmartObjectBadgeProps {
  proposalContent: string;
}
export function SmartReportBadge({ proposalContent }: SmartObjectBadgeProps) {
  const { inSubmissionMenuGroup } = useProposalSmartObjectMenu({
    proposalContent,
  });
  const numberOfSmartReportsInDocument = inSubmissionMenuGroup.items.length;

  if (numberOfSmartReportsInDocument === 0) {
    return null;
  }

  return (
    <SmartReportsToolTip inSubmissionMenuGroup={inSubmissionMenuGroup}>
      <Stack position="relative">
        <Box component="img" src={AwesomeIcon} sx={{ height: 32, width: 32 }} />
        {numberOfSmartReportsInDocument > 0 && (
          <Typography
            display="flex"
            justifyContent="center"
            variant="captionBold"
            position="absolute"
            width={20}
            height={20}
            borderRadius={5}
            bottom={-3}
            right={-10}
            bgcolor="primary.main"
            color="common.white"
            alignItems="center"
          >
            {numberOfSmartReportsInDocument}
          </Typography>
        )}
      </Stack>
    </SmartReportsToolTip>
  );
}
