import { omit } from 'lodash';
import { useCallback, useState } from 'react';
import { Contact, UserMarket } from 'types';
import { SelectedMarketsMap } from 'broker/components/UserMarkets/types';

export default function useMarketsState() {
  const [selectedMarkets, setSelectedMarkets] = useState<SelectedMarketsMap>({});

  const onMarketsSelected = useCallback((markets: UserMarket[], replace = false) => {
    const marketsMap = markets.reduce(
      (map: SelectedMarketsMap, market: UserMarket) => ({
        ...map,
        [market.id]: market,
      }),
      {},
    );

    if (replace) {
      setSelectedMarkets(marketsMap);
    } else {
      setSelectedMarkets((prev) => ({ ...prev, ...marketsMap }));
    }
  }, []);

  const selectNewContact = useCallback((contact: Contact, userMarketId: string) => {
    setSelectedMarkets((prevSelectedMarkets) => ({
      ...prevSelectedMarkets,
      [userMarketId]: {
        ...prevSelectedMarkets[userMarketId],
        contacts: prevSelectedMarkets[userMarketId]?.contacts
          ? [...prevSelectedMarkets[userMarketId].contacts, contact]
          : [contact],
      },
    }));
  }, []);

  const onMarketRemoved = useCallback((marketId: string) => {
    setSelectedMarkets((prev) => omit(prev, [marketId]));
  }, []);

  const clearSelectedMarkets = useCallback(() => {
    setSelectedMarkets({});
  }, []);

  return {
    selectedMarkets,
    selectNewContact,
    setSelectedMarkets,
    onMarketsSelected,
    onMarketRemoved,
    clearSelectedMarkets,
  };
}
