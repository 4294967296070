import { Box } from '@common-components';
import ListItemTitle, { ListItemType } from 'components/ListItemTitle';

interface ListItemDescriptionProps {
  title: string;

  // literal text that will not be highlighted
  titlePrefix?: string;
  titleTooltip?: string;
  subtitle: string;

  // literal text that will not be highlighted
  subtitlePrefix?: string;
  subtitleTooltip?: string;
  searchTerm?: string;
  itemType?: ListItemType;
}

export default function ListItemDescription({
  title,
  titlePrefix = '',
  titleTooltip = '',
  subtitle,
  subtitlePrefix = '',
  subtitleTooltip = '',
  searchTerm = '',
  itemType,
}: ListItemDescriptionProps) {
  return (
    <Box width={1} height={1} minWidth={0}>
      <ListItemTitle
        titleType="title"
        truncateMaxLines={2}
        text={title}
        textPrefix={titlePrefix}
        textTooltip={titleTooltip}
        searchTerm={searchTerm}
        itemType={itemType}
      />
      <ListItemTitle
        titleType="subTitle"
        text={subtitle}
        textPrefix={subtitlePrefix}
        textTooltip={subtitleTooltip}
        searchTerm={searchTerm}
        itemType={itemType}
      />
    </Box>
  );
}
