import { Divider, Stack, Toolbar, Typography } from '@common-components';
import { messages } from 'i18n';

export function EmailTemplateEditorHeader({
  isNewTemplate,
  user,
}: {
  isNewTemplate: boolean;
  user: { email: string; id: string };
}) {
  return (
    <Stack direction="row" alignItems="center">
      <Toolbar sx={{ p: 1 }}>
        <Typography variant="h6" id="tableTitle" component="div">
          {messages.admin.emailTemplates.editTitle(isNewTemplate)}
        </Typography>
      </Toolbar>
      <Divider orientation="vertical" flexItem variant="middle" sx={{ mr: 2 }} />
      <Typography variant="h6">{user?.email}</Typography>
    </Stack>
  );
}
