import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Button, Stack, Typography } from '@common-components';
import { useFormProvider } from 'hooks';
import { messages } from 'i18n';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { FeedbackFormData, formFieldsConfig, FormFieldsNames, schema } from './form-utils';

interface FeedbackFormProps {
  onSubmit: (data: FeedbackFormData) => Promise<void>;
}

export function FeedbackForm({ onSubmit }: FeedbackFormProps) {
  const { methods } = useFormProvider<FeedbackFormData>({
    schema,
  });

  const feedbackValue = methods.watch(formFieldsConfig[FormFieldsNames.Feedback].name);

  const [showSuccess, setShowSuccess] = useState(false);
  const feedbackMessages = messages.submissionWorkspace.aiGeneratedContentPopup.feedback;
  return (
    <Stack mr={5} width={showSuccess ? 346 : 480} borderRadius={5} p={2} pt={1} bgcolor="grey.100">
      {showSuccess ? (
        <Stack gap={1} pt={1} pb={2}>
          <Typography variant="h5">{feedbackMessages.success.title}</Typography>
          <Typography variant="body2">{feedbackMessages.success.message}</Typography>
        </Stack>
      ) : (
        <FormProvider {...methods}>
          <FormTextField
            {...formFieldsConfig[FormFieldsNames.Feedback]}
            fullWidth
            multiline
            maxRows={6}
            minRows={6}
            inputProps={{ sx: { color: 'text.primary' } }}
            sx={{
              '& .MuiOutlinedInput-input::placeholder': {
                opacity: 1,
                color: 'text.disabled',
              },
            }}
            autoFocus
            hideBorder
          />

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="caption">{feedbackMessages.form.footerText}</Typography>
            <Button
              size="small"
              color="info"
              variant="contained"
              loading={methods.formState.isSubmitting}
              disabled={!feedbackValue}
              disabledTooltipContent={feedbackMessages.form.disabledTooltipContent}
              onClick={() => {
                methods.handleSubmit(async (data) => {
                  await onSubmit(data);
                  setShowSuccess(true);
                })();
              }}
            >
              {feedbackMessages.form.submit}
            </Button>
          </Stack>
        </FormProvider>
      )}
    </Stack>
  );
}
