import { useRef } from 'react';
import { Divider, Stack } from '@common-components';
import { useHover } from 'hooks';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';
import { BodyField } from './ContentFields/BodyField';
import { ContentFields } from './ContentFields/ContentFields';
import DraftEditorFooter from './DraftEditorFooter/DraftEditorFooter';

export default function DraftEditor() {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const { draftEditorMode } = useEmailEditorState();

  const getBackgroundColor = () => (draftEditorMode === DraftEditorMode.Preview ? 'blueGrey.50' : 'common.white');

  const getBorderColor = () => {
    if (draftEditorMode === DraftEditorMode.Preview) {
      return isHover ? 'text.secondary' : 'divider';
    }

    if (draftEditorMode === DraftEditorMode.EditForAll) {
      return 'primary.main';
    }

    if (draftEditorMode === DraftEditorMode.EditForMarket) {
      return 'divider';
    }

    return 'divider';
  };

  return (
    <Stack
      height={1}
      sx={{
        border: '1px solid',
        borderColor: getBorderColor(),
        borderRadius: 1,
        transition: 'border 0.25s ease',
      }}
    >
      <Stack
        ref={hoverRef}
        flex={1}
        justifyContent="space-between"
        sx={{
          height: 1,
          transition: 'background 0.25s ease',
          backgroundColor: getBackgroundColor(),
          borderRadius: 1,
        }}
      >
        <Stack divider={<Divider />} flex={1}>
          <ContentFields />
          <BodyField />
        </Stack>
        <DraftEditorFooter />
      </Stack>
    </Stack>
  );
}
