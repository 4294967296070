import { EmailTemplateVariables } from '@common/email-variables';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-latest';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
} from '@common-components';
import { EmailTemplateType } from 'enums';
import { useMutateEmailTemplate, useRetrieveUser, useToast } from 'hooks';
import { messages } from 'i18n';
import { EmailTemplate } from 'types';
import { Editor } from 'components/Editor/Editor';
import { replaceVariablesWithMentionTag, sanitizeMentions } from 'components/Editor/utils/mentions';
import ADMIN_ROUTES from 'admin/admin-routes';
import { EmailTemplateEditorHeader } from './EmailTemplateEditorHeader';
import { useTemplateFields } from './useTemplateFields';

export function EmailTemplateEditor({ emailTemplate }: { emailTemplate?: EmailTemplate }) {
  const { updateEmailTemplate, createEmailTemplate } = useMutateEmailTemplate();
  const { userId } = useParams();

  const { data: user } = useRetrieveUser({ id: userId!, enabled: !!userId });

  const navigate = useNavigate();

  const { showToast } = useToast();

  const { subject, setSubject, name, setName, type, setType, metadata, setMetadata } = useTemplateFields(emailTemplate);

  const [editorContent, setEditorContent] = useState<string>('');

  useEffect(() => {
    setEditorContent(replaceVariablesWithMentionTag(emailTemplate?.body ?? ''));
  }, [emailTemplate]);

  const handleSave = async () => {
    const body = sanitizeMentions(editorContent);

    if (emailTemplate) {
      await updateEmailTemplate.mutateAsync({
        id: emailTemplate.id,
        data: {
          body,
          subject,
          name,
          type,
          metadata,
          userId,
        },
      });
    } else {
      await createEmailTemplate.mutateAsync({ data: { body, subject, name, type: type!, metadata, userId: userId! } });
    }
    showToast('success', { message: messages.admin.emailTemplates.templateUpsert(!emailTemplate) });
    navigate(ADMIN_ROUTES.getEmailTemplatesByUserId(userId!));
  };

  const setFromEvent =
    (setter: (value: string) => void) =>
    ({ target }: { target: { value: string } }) => {
      setter(target.value);
    };

  function navigateBack() {
    navigate(ADMIN_ROUTES.getEmailTemplatesByUserId(userId!));
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <EmailTemplateEditorHeader isNewTemplate={!emailTemplate} user={user} />
      <Stack p={3} gap={1} flexDirection="column">
        <TextField value={name} onChange={setFromEvent(setName)} label="Template Name" sx={{ mb: 3 }} />
        <TextField select value={type ?? ''} onChange={setFromEvent(setType)} label="Type" sx={{ maxWidth: '300px' }}>
          {Object.keys(EmailTemplateType).map((templateType) => (
            <MenuItem key={templateType} value={templateType}>
              {templateType}
            </MenuItem>
          ))}
        </TextField>
        <FormControlLabel
          label="Incumbent Template"
          control={
            <Checkbox
              checked={metadata?.isIncumbent}
              onChange={(e) => setMetadata({ isIncumbent: e.target.checked })}
              sx={{ alignSelf: 'start' }}
            />
          }
        />
        <TextField value={subject} onChange={setFromEvent(setSubject)} label="Subject" sx={{ mb: 3 }} />
        <InputLabel>Body</InputLabel>
        <Box
          className="cap-editor-wrapper"
          height={(theme) => theme.spacing(40)}
          display="flex"
          flexDirection="column"
          sx={{
            borderRadius: 2,
            backgroundColor: 'common.white',
            boxShadow: 1,
            overflow: 'auto',
          }}
        >
          <Editor
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            editorFeatures={{ mentionKeys: Object.values(EmailTemplateVariables) }}
          />
        </Box>
        <Grid container justifyContent="space-evenly">
          <Button variant="outlined" onClick={navigateBack}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Stack>
    </>
  );
}
