import { Button, Stack, Typography } from '@common-components';
import { bannerConfig } from './config';
import { BannerMode } from './types';

interface BannerProps {
  title: string;
  subtitle: string;
  mode: BannerMode;
  button?: {
    text: string;
    onClick: () => void;
  };
}

export default function Banner({ title, subtitle, button, mode }: BannerProps) {
  const config = bannerConfig[mode];
  return (
    <Stack direction="row" gap={1} py={1} px={1.5} alignItems="center" bgcolor={config.bgColor}>
      <Stack direction="row" alignItems="flex-start" flex={1} gap={1}>
        {config.icon}
        <Stack overflow="hidden">
          <Typography noWrap variant="body2Bold" color="text.primary">
            {title}
          </Typography>

          <Typography variant="caption" color="text.primary">
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
      {button && (
        <Button size="medium" color="inherit" variant="outlined" onClick={button.onClick}>
          {button.text}
        </Button>
      )}
    </Stack>
  );
}
