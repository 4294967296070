import { isEmpty, isEqual, omit } from 'lodash';
import { FC, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Grid, IconButton, Stack } from '@common-components';
import { Delete as DeleteIcon } from '@icons';
import { messages } from 'i18n';
import { FormFieldsSpacingHorizontal, FormFieldsSpacingVertical } from 'themes';

export interface GridFormArrayFieldProps {
  getFieldName: (fieldName: string) => string;
  disabled?: boolean;
  index: number;
}

const fieldWrapper = 'array-field-wrapper';

interface GridFormArrayFieldsProps<T extends {}> {
  name: string;
  defaultValue: T;
  // props from default values that are ignored in comparing last row to the initial state of the row
  defaultValueIgnoredProps?: (keyof T)[];
  FormArrayFieldComponent: FC<GridFormArrayFieldProps>;
  disabled?: boolean;
  disableDeleteIconDivider?: boolean;
}

export default function GridFormArrayFields<T extends {}>({
  name,
  defaultValue,
  defaultValueIgnoredProps = [],
  FormArrayFieldComponent,
  disabled,
  disableDeleteIconDivider,
}: GridFormArrayFieldsProps<T>) {
  const {
    control,
    formState: { isDirty, errors },
    watch,
    reset,
    trigger,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  const gridViewDeleteButtonMargin = (index: number) => (!isEmpty(errors[name]?.[index]) ? 3 : 0);
  const addRow = () => {
    const isDirtyBeforeRowAdded = isDirty;
    append(defaultValue);
    if (!isDirtyBeforeRowAdded) {
      reset({}, { keepValues: true });
    }
  };

  useEffect(() => {
    if (fields.length === 1 && isDirty) {
      trigger(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.length]);

  const watchRow = watch(name);

  const isLastEmpty = isEqual(
    // @ts-ignore
    omit(watchRow?.at(-1), [defaultValueIgnoredProps]),
    // @ts-ignore
    omit(defaultValue, [defaultValueIgnoredProps]),
  );

  useEffect(() => {
    if (!isLastEmpty && !disabled) {
      addRow();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLastEmpty]);

  return (
    <Grid
      container
      columnSpacing={FormFieldsSpacingVertical}
      rowSpacing={FormFieldsSpacingHorizontal}
      id={`${name}-${fieldWrapper}`}
    >
      {fields.map((field, index) => (
        <Grid item xs={12} key={field.id}>
          <Stack direction="row" height={1}>
            <Stack sx={{ width: '100%' }}>
              <FormArrayFieldComponent
                getFieldName={(fieldName: string) => `${name}.${index}.${fieldName}`}
                disabled={disabled}
                index={index}
              />
            </Stack>
            {!disabled && (
              <Stack
                width={36}
                alignItems="center"
                justifyContent="center"
                borderBottom={index !== fields.length - 1 && !disableDeleteIconDivider ? 1 : 0}
                borderColor="divider"
                textAlign="center"
                height={(theme) =>
                  `calc(100% - ${theme.spacing(
                    gridViewDeleteButtonMargin(index) - (!isEmpty(errors[name]?.[index]) ? 2 : 0),
                  )})`
                }
              >
                <IconButton
                  icon={DeleteIcon}
                  onClick={() => remove(index)}
                  disabled={index === fields.length - 1}
                  tooltipContent={messages.buttons.delete}
                  tooltipPlacement="left"
                  size="small"
                  color="secondary"
                  variant="text"
                  aria-label={messages.buttons.delete}
                />
              </Stack>
            )}
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}
