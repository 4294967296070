import { RouteKey } from 'broker/enums';
import { useLocation } from 'broker/hooks';
import { MarketingReportLocationState } from './types';

export const useReportFlow = () => {
  const location = useLocation();
  const state = location.state?.[RouteKey.MarketingReport] as MarketingReportLocationState | undefined;
  const saveMarketingReportFlow = state?.saveMarketingReportFlow ?? false;
  const isInformationRequestsReport = state?.isInformationRequestsReport ?? false;

  return { saveMarketingReportFlow, isInformationRequestsReport };
};
