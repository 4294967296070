import { useState } from 'react';
import { useSearchParams } from 'react-router-dom-latest';
import { useBoolean, useMount } from 'hooks';
import { useMutateNylasUser } from 'hooks/api/nylasUser';
import { logger } from 'utils';

export function useCreateNylasToken() {
  const [isTokenCreated, { on: setTokenCreated }] = useBoolean(false);
  const [error, setError] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { createAccessToken } = useMutateNylasUser();

  useMount(() => {
    // Get the params we got from Nylas in the url
    const code = searchParams.get('code');
    const reason = searchParams.get('reason');

    if (code) {
      // Clean the url from the code param
      searchParams.delete('code');
      setSearchParams(searchParams);

      // Create an access token for the user
      createAccessToken.mutate(
        { code },
        {
          onSuccess: setTokenCreated,
        },
      );
    } else if (reason) {
      // Clean the url from the reason param
      searchParams.delete('reason');
      setSearchParams(searchParams);

      logger.log('error', reason);
      setError(reason);
    }
  });

  return {
    isTokenCreated,
    error,
  };
}
