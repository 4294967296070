import { useEffect, useState } from 'react';
import { Stack, Typography } from '@common-components';
import {
  AutoAwesomeOutlined as AutoAwesomeOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  FileUploadOutlined as FileUploadOutlinedIcon,
  WarningAmber as WarningAmberIcon,
} from '@icons';
import { InformationTooltipType } from 'contexts/information-tooltip/types';
import { FileType, SubmissionTask, SubmissionTaskStatus } from 'enums';
import { useInformationTooltip } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission, Tasks } from 'types';
import { BrokerUrlParams } from 'broker/broker-routes';
import { SubmissionTaskExpectedFileTypesConfig } from 'broker/config/submission-tasks-file-types-config';
import { RouteKey } from 'broker/enums';
import { useNavigate, useUpdateQueryParam } from 'broker/hooks';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { TOOLBOX_TABS } from 'broker/pages/SubmissionWorkspacePage/types';
import { isIncompleteApiApplication } from 'broker/utils';

import { TaskItem } from './TaskItem';
import { UploadFilesInfoTooltip } from './UploadFilesInfoTooltip';

interface TasksListProps {
  submission: PartialSubmission;
  getMissingFileTypes: (labels: FileType[]) => string[];
}

export function TasksList({ submission, getMissingFileTypes }: TasksListProps) {
  const { updateSubmissionTasks, partialSubmission } = useSubmissionsWorkspace();
  const preMarketingTasksMessages = messages.submissionWorkspace.preMarketing.tasks;
  const navigate = useNavigate();
  const { editSubmissionUrl } = useWorkspaceUrls();

  const getMissingFilesForTask = (submissionTask: SubmissionTask) => {
    const expectedFileTypes = SubmissionTaskExpectedFileTypesConfig[submissionTask];
    if (!expectedFileTypes) {
      return [];
    }
    return getMissingFileTypes(expectedFileTypes);
  };

  const { showInformationTooltip } = useInformationTooltip(InformationTooltipType.UploadFiles);
  const updateQueryParam = useUpdateQueryParam();

  const { tasks: serverTasks } = submission;
  const [tasks, setTasks] = useState<Tasks>(serverTasks);

  useEffect(() => {
    setTasks(serverTasks);
  }, [serverTasks]);

  const handleStatusChange = async (task: SubmissionTask, status: SubmissionTaskStatus) => {
    setTasks((prevTasks) => ({
      ...prevTasks,
      [task]: {
        ...prevTasks[task],
        status,
      },
    }));
    try {
      await updateSubmissionTasks({ submissionId: submission.id, task, status });
    } catch (error) {
      setTasks(serverTasks);
    }
  };

  const moveTaskToInProgress = async (task: SubmissionTask) => {
    if (!submission.tasks[task] || submission.tasks[task]!.status === SubmissionTaskStatus.ToDo) {
      await handleStatusChange(task, SubmissionTaskStatus.InProgress);
    }
  };

  const handleAttachFiles = async (task: SubmissionTask) => {
    updateQueryParam({
      addParams: [{ queryParam: BrokerUrlParams.ACTIVE_TAB, value: TOOLBOX_TABS.FILES }],
      removeParams: [BrokerUrlParams.SELECTED_FILE, BrokerUrlParams.SELECTED_EMAIL],
    });
    showInformationTooltip();
    await moveTaskToInProgress(task);
  };

  return (
    <Stack flex={1} pb={3} mt={1} gap={2}>
      <TaskItem
        task={SubmissionTask.ReviewSubmission}
        status={tasks.ReviewSubmission?.status || SubmissionTaskStatus.ToDo}
        handleStatusChange={handleStatusChange}
        actions={[
          {
            task: messages.submissionWorkspace.preMarketing.actions.editSubmission,
            tooltip: messages.submissionWorkspace.preMarketing.actions.editSubmission,
            icon: EditOutlinedIcon,
            onClick: () =>
              navigate(editSubmissionUrl, {
                routeKey: RouteKey.SubmissionEdit,
                state: undefined,
              }),
          },
        ]}
        extraContent={
          isIncompleteApiApplication(partialSubmission) ? (
            <Stack direction="row" gap={1} alignItems="center">
              <WarningAmberIcon color="error" sx={{ fontSize: 16 }} />
              <Typography variant="captionBold" color="error">
                {messages.submissionWorkspace.preMarketing.tasks[SubmissionTask.ReviewSubmission].extraContent}
              </Typography>
            </Stack>
          ) : undefined
        }
        titleStartIcon={AutoAwesomeOutlinedIcon}
      />
      <TaskItem
        task={SubmissionTask.ExpiringPolicies}
        status={tasks.ExpiringPolicies?.status || SubmissionTaskStatus.ToDo}
        handleStatusChange={handleStatusChange}
        actions={[
          {
            task: messages.submissionWorkspace.preMarketing.actions.upload,
            tooltip: messages.submissionWorkspace.preMarketing.actions.upload,
            icon: FileUploadOutlinedIcon,
            onClick: () => handleAttachFiles(SubmissionTask.ExpiringPolicies),
          },
        ]}
        missingAlert={{
          status: 'warning',
          title: preMarketingTasksMessages[SubmissionTask.ExpiringPolicies].missingAlert.title,
          content: [
            {
              title: preMarketingTasksMessages[SubmissionTask.ExpiringPolicies].missingAlert.requiredFields,
              items: getMissingFilesForTask(SubmissionTask.ExpiringPolicies),
            },
          ],
        }}
      />
      <TaskItem
        task={SubmissionTask.RenewalDocs}
        status={tasks.RenewalDocs?.status || SubmissionTaskStatus.ToDo}
        handleStatusChange={handleStatusChange}
        actions={[
          {
            task: messages.submissionWorkspace.preMarketing.actions.upload,
            tooltip: messages.submissionWorkspace.preMarketing.actions.upload,
            icon: FileUploadOutlinedIcon,
            onClick: () => handleAttachFiles(SubmissionTask.RenewalDocs),
          },
        ]}
        missingAlert={{
          status: 'warning',
          title: preMarketingTasksMessages[SubmissionTask.RenewalDocs].missingAlert.title,
          content: [
            {
              title: preMarketingTasksMessages[SubmissionTask.RenewalDocs].missingAlert.requiredFields,
              items: getMissingFilesForTask(SubmissionTask.RenewalDocs),
            },
          ],
        }}
      />
      <TaskItem
        task={SubmissionTask.LossRuns}
        status={tasks.LossRuns?.status || SubmissionTaskStatus.ToDo}
        handleStatusChange={handleStatusChange}
        actions={[
          {
            task: messages.submissionWorkspace.preMarketing.actions.upload,
            tooltip: messages.submissionWorkspace.preMarketing.actions.upload,
            icon: FileUploadOutlinedIcon,
            onClick: () => handleAttachFiles(SubmissionTask.LossRuns),
          },
        ]}
        missingAlert={{
          status: 'warning',
          title: preMarketingTasksMessages[SubmissionTask.LossRuns].missingAlert.title,
          content: [
            {
              title: preMarketingTasksMessages[SubmissionTask.LossRuns].missingAlert.requiredFields,
              items: getMissingFilesForTask(SubmissionTask.LossRuns),
            },
          ],
        }}
      />
      <UploadFilesInfoTooltip />
    </Stack>
  );
}
