import { LoaderOverlay, Stack } from '@common-components';
import { useMount } from 'hooks';
import { useMutateBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import Banner, { BannerMode } from 'broker/components/Banner';
import ContentPreview from 'broker/components/box/ContentPreview';
import { PdfGenerationStatus } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/types';
import { useGenerateAndUploadMarketingReportPDF } from './pdf-creator/marketing-update-pdf-creator-util';

interface UploadProps {
  pdfGenerationStatus: PdfGenerationStatus;
  setPdfGenerationStatus: (pdfGenerationStatus: PdfGenerationStatus) => void;
  submission: PartialSubmission;
  editorContent: string | undefined;
  pdfFileId: string | undefined;
  setPdfFileId: (value: string) => void;
  isDirty: boolean;
  initialPdfFileId?: string;
}

export default function Upload({
  pdfGenerationStatus,
  setPdfGenerationStatus,
  submission,
  editorContent,
  pdfFileId,
  setPdfFileId,
  isDirty,
  initialPdfFileId,
}: UploadProps) {
  const generateAndUploadMarketingReportPDF = useGenerateAndUploadMarketingReportPDF();
  const { deleteFile } = useMutateBoxItems();
  const hasError = pdfGenerationStatus === PdfGenerationStatus.Error;
  const { title: errorTitle, description: errorDescription } = messages.marketingUpdate.generatePdfError;

  const createMarketingUpdatePDF = async () => {
    setPdfGenerationStatus(PdfGenerationStatus.Loading);
    // delete only new pdf file, that was generated in this session
    if (pdfFileId && pdfFileId !== initialPdfFileId) {
      await deleteFile.mutateAsync({ fileId: pdfFileId });
      setPdfFileId('');
    }
    generateAndUploadMarketingReportPDF(submission, editorContent)
      .then((marketingUpdateBoxItem) => {
        if (marketingUpdateBoxItem) {
          setPdfFileId(marketingUpdateBoxItem.id);
          setPdfGenerationStatus(PdfGenerationStatus.Idle);
        } else {
          setPdfGenerationStatus(PdfGenerationStatus.Error);
        }
      })
      .catch((error) => {
        setPdfGenerationStatus(PdfGenerationStatus.Error);
        throw error;
      });
  };

  // Execute the PDF download on component mount
  useMount(() => {
    if (isDirty || (editorContent && !pdfFileId)) {
      createMarketingUpdatePDF();
    }
  });

  const getContent = () => {
    if (hasError) {
      return (
        <Stack mt={2}>
          <Banner title={errorTitle} subtitle={errorDescription} mode={BannerMode.Error} />
        </Stack>
      );
    }
    if (pdfGenerationStatus === PdfGenerationStatus.Loading) {
      return <LoaderOverlay hideOverlay />;
    }
    return pdfFileId ? <ContentPreview fileId={pdfFileId} hasHeader={false} sx={{ height: 1 }} /> : null;
  };

  return (
    <Stack pt={4} px={6} height={1}>
      {getContent()}
    </Stack>
  );
}
