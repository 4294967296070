import { useMemo } from 'react';
import { BoxTemplateLabels } from 'enums';
import { useToast } from 'hooks';
import { extractErrorMessage, MakeFieldsOptional } from 'utils';
import { labelCompare } from 'utils/comparators';
import { getFileMetadataField } from 'utils/files-utils';
import { ExtendedBoxItem, LabelMetadata } from 'broker/components/FilesExplorer/types';
import LabelsMenu, { LabelsMenuProps } from 'broker/components/LabelsMenu';
import useSingleFieldOptimisticUpdate from 'broker/hooks/useSingleFieldOptimisticUpdate';

export interface FileLabelsMenuProps
  extends MakeFieldsOptional<
    Pick<
      LabelsMenuProps,
      | 'onLabelChanged'
      | 'onClearLabel'
      | 'isOpenOverride'
      | 'onClose'
      | 'permanentLabel'
      | 'readonly'
      | 'addLabelText'
      | 'icon'
      | 'showTooltip'
    >,
    'onClose' | 'onClearLabel' | 'onLabelChanged'
  > {
  file: ExtendedBoxItem;
  labelMetadata: LabelMetadata;
  onLabelUpdate: (item: ExtendedBoxItem, labelFieldName: BoxTemplateLabels, fileType?: string) => Promise<void>;
}

export default function FileLabelsMenu({
  labelMetadata,
  file,
  onLabelUpdate,
  onLabelChanged,
  onClearLabel,
  onClose,
  ...props
}: FileLabelsMenuProps) {
  const labelValue = useMemo(() => getFileMetadataField(file, labelMetadata.metadataFieldName), [file, labelMetadata]);
  const ownOnLabelUpdate = async (newValue?: string) => onLabelUpdate(file, labelMetadata.metadataFieldName, newValue);

  const { value: ownLabelValue, onChange } = useSingleFieldOptimisticUpdate<string | undefined>({
    serverValue: labelValue,
    apiUpdate: ownOnLabelUpdate,
  });

  const { showToast } = useToast();

  async function onLabelChange(value: string | undefined) {
    try {
      await onChange(value);
    } catch (e) {
      showToast('error', { message: extractErrorMessage(e) });
    }
  }

  function getSelectedOptions() {
    if (!ownLabelValue) {
      return undefined;
    }
    if (labelMetadata.getSelectedOption) {
      return [labelMetadata.getSelectedOption(ownLabelValue)];
    }
    // for markets label we need to split the value to get the market ids
    const marketIds = ownLabelValue.split(',');
    return labelMetadata.options.filter((market) => marketIds.includes(market.value));
  }

  return (
    <LabelsMenu
      {...props}
      anchorType={labelMetadata.getAnchorType(file, ownLabelValue)}
      options={labelMetadata.options.sort(labelCompare)}
      selectedLabels={getSelectedOptions()}
      onClose={onClose}
      multiSelect={labelMetadata.multiSelect}
      onLabelChanged={async (value) => {
        if (onLabelChanged) {
          await onLabelChanged(value);
        }
        await onLabelChange(value);
      }}
      onClearLabel={async () => {
        await onLabelChange(undefined);
        if (onClearLabel) {
          onClearLabel();
        }
      }}
    />
  );
}
