import { ReactNode } from 'react';
import { SearchBar, Stack, Typography } from '@common-components';
import { truncateMultiLineText } from 'utils';

interface ListItemsHeaderProps {
  title: string;
  subtitle?: string;
  searchProps?: {
    search: string;
    setSearch: (search: string) => void;
    searchPlaceholder: string;
  };
  hideSearchSection?: boolean;
  mainActionArea?: ReactNode;
  arrangingArea?: ReactNode;
}
export default function ToolboxItemHeader({
  title,
  subtitle,
  searchProps,
  arrangingArea = null,
  mainActionArea = null,
  hideSearchSection,
}: ListItemsHeaderProps) {
  return (
    <Stack px={2} pb={2} alignItems="center" justifyContent="space-between">
      <Stack height={80} direction="row" justifyContent="space-between" width={1} alignItems="center">
        <Stack gap={0.5}>
          <Typography variant="body1" fontSize={18} fontWeight={600} sx={{ ...truncateMultiLineText(2) }}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="caption" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Stack>
        {mainActionArea}
      </Stack>
      {!hideSearchSection && (
        <Stack width={1} direction="row" gap={2} justifyContent="flex-end" alignItems="center">
          {searchProps && (
            <SearchBar
              fullWidth
              value={searchProps.search}
              onChange={searchProps.setSearch}
              placeholder={searchProps.searchPlaceholder}
              id="search-files"
              autoComplete="off"
            />
          )}
          {arrangingArea}
        </Stack>
      )}
    </Stack>
  );
}
