import { useCallback } from 'react';
import { messages } from 'i18n';
import { useToast } from './useToast';

export const useUnsupportedFilesNotification = () => {
  const { showToast } = useToast();

  const showNotification = useCallback(
    (message: string) => {
      showToast('warning', {
        title: messages.submissionWorkspace.aiGeneratedContentPopup.skippedUnsupportedFilesTitle,
        message: (
          <>
            {messages.submissionWorkspace.aiGeneratedContentPopup.skippedUnsupportedFilesMessage}:
            <br />
            {message.split(',').map((fileName) => (
              <>
                {`"${fileName}"`}
                <br />
              </>
            ))}
          </>
        ),
      });
    },
    [showToast],
  );
  return { showNotification };
};
