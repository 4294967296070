import { isArray, isEmpty, once } from 'lodash';
import { useCallback, useLayoutEffect, useState } from 'react';
import { Outlet } from 'react-router-dom-latest';
import { BoxSkeleton, Button, Divider, Paper, SearchBar, Stack } from '@common-components';
import { SubmissionStatus, SubmissionTerminationStatus } from 'enums';
import {
  useConditionallyRenderedModal,
  useCurrentUser,
  useHotjar,
  useInvalidateSubmissionsCache,
  useLocalStorage,
  useMutateSubmission,
  usePagination,
  useSearchSubmission,
  useToast,
} from 'hooks';
import { useSearchUserTeam } from 'hooks/api/user';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { demoData, scrollToElementPosition } from 'utils';
import { HotjarEvents } from 'utils/hotjar-events';
import NoItemsFound from 'broker/components/common/NoItemsFound';
import Sort from 'broker/components/common/Sort';
import TablePagination from 'broker/components/common/TablePagination';
import SubmissionHeader from 'broker/components/SubmissionsView/SubmissionsHeader';
import { submissionStatusConfig } from 'broker/configuration-mappers/submission-status-config';
import TerminateSubmissionDialog from 'broker/dialogs/TerminateSubmissionDialog';
import MainScrollArea from 'broker/hoc/MainScrollArea';
import { convertCoverageLinesTextToEnum } from 'broker/utils/submission-utills';
import { SubmissionsAssignee } from './config';
import SubmissionFilter from './SubmissionFilter';
import SubmissionTableHeader from './SubmissionTableHeader';
import SubmissionTableItem from './SubmissionTableItem';
import SubmissionViewNoResults from './SubmissionViewNoResults';
import useSubmissionFilters, { sorters } from './useSubmissionFilters';
import { FilterType, SubmissionsViewStorageKeys, SubmissionViewMode } from './utils';

const inflight: SubmissionStatus[] = [];
const preflight: SubmissionStatus[] = [];

Object.keys(submissionStatusConfig).forEach((status) => {
  const statusConfiguration = submissionStatusConfig[status as SubmissionStatus];
  if (statusConfiguration.preflight) {
    preflight.push(status as SubmissionStatus);
  } else {
    inflight.push(status as SubmissionStatus);
  }
});

const searchFilter = (viewMode: SubmissionViewMode) => {
  switch (viewMode) {
    case SubmissionViewMode.General:
      return {
        includedStatuses: inflight,
      };
    case SubmissionViewMode.PreviewBox:
      return {
        includedStatuses: preflight,
      };
    case SubmissionViewMode.Retailer:
      return {
        includedStatuses: [...inflight, ...preflight],
      };
    default:
      return {
        includedStatuses: inflight,
      };
  }
};

type TerminateDialogProps = {
  targetStatus: SubmissionTerminationStatus;
  submission: PartialSubmission;
};

export default function SubmissionsView({ viewMode }: { viewMode: SubmissionViewMode }) {
  const maxPinnedRows = 5;
  const MainScrollAreaId = 'submissionsDataGridScrollArea';

  const invalidateSubmissionsCache = useInvalidateSubmissionsCache();

  const {
    items: submissions,
    count,
    isLoading,
    isFetching,
  } = useSearchSubmission({
    filter: searchFilter(viewMode),
  });

  const { updateSubmission } = useMutateSubmission();

  const { items: users } = useSearchUserTeam({ filter: { enabled: true } });
  const [submissionsAssignee, setSubmissionsAssignee] = useState<SubmissionsAssignee>({});
  const [ownSubmissions, setOwnSubmissions] = useState<Array<PartialSubmission>>([]);
  const [pinnedSubmissions, setPinnedSubmissions] = useLocalStorage<string[]>(SubmissionsViewStorageKeys.pinned, []);
  const { showToast } = useToast();
  const hotjar = useHotjar();
  const { endUser } = useCurrentUser();

  const {
    openModal: openTerminationDialog,
    closeModal: closeTerminationDialog,
    modalState: terminationDialogState,
  } = useConditionallyRenderedModal<TerminateDialogProps>({});

  const { page, setPage, rowsPerPage, setRowsPerPage, paginationReset } = usePagination();

  const handleChange = (value: number) => {
    setPage(value);
    scrollToElementPosition(MainScrollAreaId, { top: 0, left: 0 });
  };

  const onTerminateClick = (submission: PartialSubmission, targetStatus: SubmissionTerminationStatus) => {
    openTerminationDialog({ targetStatus, submission });
  };

  const { filters, filteredSubmissions, search, setSearch, sortType, setSortType } = useSubmissionFilters(
    ownSubmissions,
    pinnedSubmissions,
    isLoading,
    isFetching,
    users,
    paginationReset,
    viewMode,
  );

  const submissionsSlice = filteredSubmissions.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage);

  useLayoutEffect(() => {
    setOwnSubmissions(
      submissions.map((submission) => {
        if (viewMode === SubmissionViewMode.PreviewBox) {
          // for submission box populate submission empty fields with extracted data
          const extractedData = submission.submissionExtractedData?.extractedData;
          return {
            ...submission,
            insuredName: submission.insuredName ?? extractedData?.insuredName?.value,
            coverageLines:
              isEmpty(submission.coverageLines) && isArray(extractedData?.coverageLines?.value)
                ? convertCoverageLinesTextToEnum(extractedData!.coverageLines!.value!)
                : submission.coverageLines,
          };
        }
        return submission;
      }),
    );

    setSubmissionsAssignee(
      submissions.reduce(
        (prev, submission) => ({
          ...prev,
          [submission.id]: submission.assignee,
        }),
        {} as SubmissionsAssignee,
      ),
    );
  }, [submissions, viewMode]);

  async function togglePin(id: string) {
    const isPinned = pinnedSubmissions.includes(id);
    if (pinnedSubmissions.length >= maxPinnedRows && !isPinned) {
      showToast('warning', { message: messages.submissionsView.maxPinnedAlert(maxPinnedRows) });
      return;
    }

    if (isPinned) {
      setPinnedSubmissions(pinnedSubmissions.filter((submissionId) => submissionId !== id));
    } else {
      setPinnedSubmissions([...pinnedSubmissions, id]);
    }

    paginationReset();
    scrollToElementPosition(MainScrollAreaId, { top: 0, left: 0 });
  }

  const onAssigneeChange = async (submissionId: string, userId: string) => {
    setSubmissionsAssignee((prev) => {
      const newAssignee = users.find((user) => user.id === userId);
      if (newAssignee) {
        return {
          ...prev,
          [submissionId]: newAssignee,
        };
      }
      return prev;
    });
    await updateSubmission.mutateAsync(
      // @ts-ignore
      { id: submissionId, data: { assigneeId: userId || null } },
      {
        onSuccess: () => showToast('success', { message: messages.submissionsView.assigneeSuccessUpdate }),
        onError: () => showToast('warning', { message: messages.submissionsView.assigneeErrorUpdate }),
      },
    );
  };

  const isFilterApplied = Object.values(filters).some((filter) => !filter.isAllSelected);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchHotjarEventOnce = useCallback(
    once(() => {
      hotjar.event(HotjarEvents.SubmissionsSearch);
    }),
    [hotjar],
  );

  const onSearch = (value: string) => {
    setSearch(value);
    searchHotjarEventOnce();
  };

  return (
    <>
      <Stack height={1}>
        <SubmissionHeader
          viewMode={viewMode}
          count={count}
          filteredCount={filteredSubmissions.length}
          isFilterApplied={isFilterApplied || search.length > 1}
        />

        <Stack flex={1} p={3} gap={2}>
          {(isLoading || ownSubmissions.length > 0) && viewMode !== SubmissionViewMode.Retailer && (
            <Stack direction="row" gap={1} alignItems="center">
              <SearchBar
                value={search}
                onChange={onSearch}
                placeholder={messages.submissionsView.search}
                id="search-user-markets"
                sx={{ width: 290 }}
              />
              <SubmissionFilter
                title={messages.submissionsView.columnHeaders.customers}
                filter={filters[FilterType.Customer]}
                showSearch
              />
              <SubmissionFilter
                filter={filters[FilterType.Insured]}
                title={messages.submissionsView.columnHeaders.insured}
                showSearch
              />
              <SubmissionFilter
                filter={filters[FilterType.CoverageLine]}
                title={messages.submissionsView.columnHeaders.coverageLines}
                showSearch
              />
              {viewMode === SubmissionViewMode.General && (
                <SubmissionFilter
                  filter={filters[FilterType.Status]}
                  title={messages.submissionsView.columnHeaders.status}
                />
              )}

              <SubmissionFilter
                filter={filters[FilterType.DatesRange]}
                title={messages.submissionsView.columnHeaders.effectiveDate}
              />
              <SubmissionFilter
                filter={filters[FilterType.Assignee]}
                title={messages.submissionsView.columnHeaders.assignee}
              />
              {isFilterApplied && (
                <Button
                  hotjarEvent={HotjarEvents.SubmissionsClearFilters}
                  onClick={() => {
                    Object.values(filters).forEach((filter) => {
                      filter.handleSelectAll();
                      paginationReset();
                    });
                  }}
                >
                  {messages.buttons.clearFilters}
                </Button>
              )}
              <Stack direction="row" flex={1} justifyContent="flex-end">
                <Sort setSortType={setSortType} sortType={sortType} sorters={sorters} />
              </Stack>
            </Stack>
          )}

          {isLoading || ownSubmissions.length > 0 ? (
            <Stack component={Paper} variant="outlined" overflow="hidden" flex="1 1 0">
              <MainScrollArea
                id={MainScrollAreaId}
                sx={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  flex: 1,
                }}
              >
                <SubmissionTableHeader viewMode={viewMode} sortType={sortType} setSortType={setSortType} />
                {isLoading ? (
                  <Stack gap={2} p={2}>
                    {demoData(10).map((row, index) => (
                      <Stack gap={2} key={row.id}>
                        <BoxSkeleton isLoading sx={{ width: 1, height: 45 }} index={index} />
                        <Divider />
                      </Stack>
                    ))}
                  </Stack>
                ) : (
                  submissionsSlice.map((submission) => (
                    <SubmissionTableItem
                      viewMode={viewMode}
                      key={submission.id}
                      submission={submission}
                      togglePin={togglePin}
                      isPinned={pinnedSubmissions.includes(submission.id)}
                      users={users}
                      submissionsAssignee={submissionsAssignee}
                      onAssigneeChange={onAssigneeChange}
                      endUser={endUser}
                      searchInput={search}
                      onTerminateClick={onTerminateClick}
                    />
                  ))
                )}
                {!isLoading && submissionsSlice.length === 0 && <SubmissionViewNoResults />}
              </MainScrollArea>
              {filteredSubmissions.length > rowsPerPage && (
                <>
                  <Divider />
                  <TablePagination
                    from={page * rowsPerPage - rowsPerPage + 1}
                    to={page * rowsPerPage}
                    outOf={filteredSubmissions.length}
                    handleChange={handleChange}
                    count={Math.ceil(filteredSubmissions.length / rowsPerPage)}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </>
              )}
            </Stack>
          ) : (
            <NoItemsFound title={messages.submissionsView.emptyState} />
          )}
        </Stack>
      </Stack>
      <Outlet />

      {terminationDialogState.shouldRender && (
        <TerminateSubmissionDialog
          showTerminationConfirmation={terminationDialogState.isOpen}
          closeTerminationConfirmation={closeTerminationDialog}
          submission={terminationDialogState.modalProps!.submission}
          targetStatus={terminationDialogState.modalProps!.targetStatus}
          onUpdateSuccess={invalidateSubmissionsCache}
        />
      )}
    </>
  );
}
