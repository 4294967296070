import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { messages } from 'i18n';
import theme from 'themes';
import { getTodayDisplayDate } from 'utils';
import { ConceptualTowerReportRow } from './types';

const columnsConfig = [
  { header: messages.ConceptualTowerPage.columnHeaders.layer, dataKey: 'layer' },
  { header: messages.ConceptualTowerPage.columnHeaders.status, dataKey: 'status' },
  { header: messages.ConceptualTowerPage.columnHeaders.limit, dataKey: 'limit' },
  { header: messages.ConceptualTowerPage.columnHeaders.marketName, dataKey: 'marketName' },
  { header: messages.ConceptualTowerPage.columnHeaders.admitted, dataKey: 'isAdmitted' },
  { header: messages.ConceptualTowerPage.columnHeaders.premium, dataKey: 'premium' },
  { header: messages.ConceptualTowerPage.columnHeaders.rpm, dataKey: 'rpm' },
];

export function generateBlobPdf(rowsData: ConceptualTowerReportRow[], boldLastRow?: boolean): Blob {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('landscape');
  doc.text(messages.ConceptualTowerPage.fileTitle, 14, 15);
  doc.setFontSize(10);
  doc.text(getTodayDisplayDate(), 65, 15);
  doc.setLineHeightFactor(1.5);

  autoTable(doc, {
    startY: 30,
    body: rowsData as any,
    columns: columnsConfig,
    rowPageBreak: 'avoid',
    styles: { overflow: 'linebreak', cellPadding: 5 },
    headStyles: { fillColor: theme.palette.blue[700] },
    willDrawCell(data) {
      const rows = data.table.body;
      if (data.row.index === rows.length - 1 && boldLastRow) {
        doc.setFont('helvetica', 'bold');
      }
    },
  });

  return doc.output('blob');
}
