import { Organization } from 'types';
import InternalLink from 'components/InternalLink';

import ADMIN_ROUTES from 'admin/admin-routes';

export interface OrganizationLinkProps {
  organization: Pick<Organization, 'id' | 'name'>;
}

export default function OrganizationLink({ organization }: OrganizationLinkProps) {
  return <InternalLink to={ADMIN_ROUTES.getOrganizationById(organization.id)}>{organization.name}</InternalLink>;
}
