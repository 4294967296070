import SubmissionInboundEmailClient from 'clients/capitola/submission-inbound-email';
import { QueryKeys } from 'enums';
import { SubmissionInboundEmail } from 'types';
import { MutateProps } from './query/types';
import { useMutate } from './query/useMutate';

export function useMutateSubmissionInboundEmail(options?: MutateProps) {
  const { update: updateSubmissionInboundEmail } = useMutate<
    SubmissionInboundEmail,
    SubmissionInboundEmail,
    SubmissionInboundEmail,
    SubmissionInboundEmailClient
  >({
    // We use this query key to trigger a reload of the emails in the emails tab, the only location where this mutation is used.
    queryKey: QueryKeys.InboundEmailMessage,
    clientClass: SubmissionInboundEmailClient,
    cancelInvalidation: options?.cancelInvalidation,
    updateOptions: { throwOnError: true },
  });

  return { updateSubmissionInboundEmail };
}
