import { Box, Button, Checkbox, FormControlLabel, Stack, Typography } from '@common-components';
import { InsertDriveFile, ListAlt } from '@icons';
import { messages } from 'i18n';
import { Layer, Quote } from 'types';
import { attachmentPointText, formatPrice } from 'utils';
import { getQuoteMetaData } from 'broker/pages/SubmissionWorkspacePage/dialogs/CompareQuotesModal/utils';
import QuoteHeaderItem from './QuoteHeaderItem';

interface QuoteHeaderProps {
  quote: Quote;
  layer: Layer;
  onSelect: () => void;
  isSelected: boolean;
  toggle: () => void;
  showDetails: boolean;
}

export default function QuoteHeader({ quote, layer, onSelect, isSelected, toggle, showDetails }: QuoteHeaderProps) {
  const { limitComponent } = getQuoteMetaData(quote, layer);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        p: 2,
        bgcolor: isSelected ? 'blue.25' : 'common.white',
      }}
      gap={2}
    >
      <Stack>
        <FormControlLabel
          label={
            <Typography sx={{ color: 'common.black' }} variant="h5" noWrap ml={0.5}>
              {quote.marketName}
            </Typography>
          }
          control={
            <Checkbox
              id={quote.id}
              key={quote.id}
              checked={isSelected}
              onClick={onSelect}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
        />
      </Stack>

      <Stack flexShrink={0} direction="row" alignItems="center" gap={4}>
        <QuoteHeaderItem
          title={attachmentPointText(layer.attachmentPoint)}
          subTitle={messages.quoteItemLabels.attachmentPoint}
        />

        <QuoteHeaderItem title={`$${formatPrice(quote.premium)}`} subTitle={messages.quoteItemLabels.premium} />

        <QuoteHeaderItem
          title={
            <Stack direction="row" gap={0.5}>
              <Stack>{limitComponent}</Stack>
            </Stack>
          }
          subTitle={`${messages.quoteItemLabels.limit}`}
        />
        <Box>
          <Button variant="contained" startIcon={showDetails ? <InsertDriveFile /> : <ListAlt />} onClick={toggle}>
            {showDetails ? messages.buttons.file : messages.buttons.details}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}
