import { afterDecimal } from './number-utils';

export function formatPrice(price: number, minimumDecimalDigits?: number) {
  return price.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: minimumDecimalDigits || 0 });
}

// shorten the number with k/mm suffix only if the shorten number contains less than 3 decimal points
export function formatBigNumber(value: string | number, fixedDigits = 3, minimumDecimalDigits?: number) {
  const number = Number(value);

  const toFixedPointNotation = (num: number) => Number(num.toFixed(fixedDigits));

  if (number >= 1_000_000) {
    const dividedNumber = number / 1_000_000;
    if (afterDecimal(dividedNumber) <= 3) {
      return `${toFixedPointNotation(dividedNumber)}MM`;
    }
  } else if (number >= 100_000) {
    const dividedNumber = number / 1_000;
    if (afterDecimal(dividedNumber) <= 3) {
      return `${toFixedPointNotation(dividedNumber)}K`;
    }
  }

  return `${formatPrice(number, minimumDecimalDigits)}`;
}

export function calculateRPM(premium: number, limit: number): number {
  return premium / (limit / 1_000_000);
}
