import { ReactElement, ReactNode, useState } from 'react';
import { ButtonProps } from '@common-components';
import { ConfirmationDialog } from 'components/Dialog';

export interface ConfirmationDialogGuardAttributes {
  guardedAction: () => void;
}

export const ConfirmationDialogGuard = ({
  message,
  title,
  showConfirmation,
  action,
  children,
  cancelButton,
  proceedButton,
  isSubmitting,
}: {
  message: ReactNode;
  title: string;
  showConfirmation: boolean;
  action: () => void;
  children: (props: ConfirmationDialogGuardAttributes) => ReactElement;
  cancelButton?: ButtonProps;
  proceedButton?: ButtonProps;
  isSubmitting?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const guardedAction = () => {
    if (!showConfirmation) {
      action();
      return;
    }

    setIsOpen(true);
  };

  return (
    <>
      {children({ guardedAction })}
      <ConfirmationDialog
        message={message}
        title={title}
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        onSubmit={() => {
          action();
          setIsOpen(false);
        }}
        cancelButton={cancelButton}
        proceedButton={proceedButton}
        isSubmitting={isSubmitting}
      />
    </>
  );
};
