import { NylasAuthTrigger, useBoolean, useRetrieveNylasAuthenticationUrl } from 'hooks';
import { NylasAuthenticationUrl } from 'types';

export function useNylasAuth(trigger: NylasAuthTrigger) {
  const [isAuthFlowTriggered, { on: triggerAuthFlow, off: disableAuthFlow }] = useBoolean(false);
  const { isInitialLoading: isLoadingAuthUrl } = useRetrieveNylasAuthenticationUrl({
    enabled: isAuthFlowTriggered,
    trigger,
    queryOptions: {
      onSuccess: (response?: NylasAuthenticationUrl) => {
        const authUrl = response?.url;
        disableAuthFlow();

        if (authUrl) {
          window.open(authUrl);
        }
      },
    },
  });
  return { triggerAuthFlow, isLoadingAuthUrl };
}
