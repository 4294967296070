import { messages } from 'i18n';
import { ActivityLog } from 'types';
import { BrokerUrlParams } from 'broker/broker-routes';
import { EmailItemType } from 'broker/components/Emails/types';
import { useUpdateQueryParam } from 'broker/hooks';
import { TOOLBOX_TABS } from 'broker/pages/SubmissionWorkspacePage/types';
import { useUiStoreActions } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

const workspaceMessages = messages.submissionWorkspace.marketRequestsWorkspace;

interface EmailActivityProps {
  emailActivity?: ActivityLog;
}
export default function useEmailActivityLink({ emailActivity }: EmailActivityProps) {
  const { setActiveOverlayItem } = useUiStoreActions();
  const updateQueryParam = useUpdateQueryParam();
  const { viewEmail } = workspaceMessages.methods;

  const submissionEmailId = emailActivity?.metadata?.submissionEmailId;

  if (!submissionEmailId) {
    return null;
  }

  return {
    onClick: () => {
      setActiveOverlayItem({
        selectedEmailView: {
          emailId: submissionEmailId,
          emailType: EmailItemType.SubmissionOutbound,
        },
      });
      updateQueryParam({
        addParams: [
          { queryParam: BrokerUrlParams.SELECTED_EMAIL, value: submissionEmailId },
          { queryParam: BrokerUrlParams.ACTIVE_TAB, value: TOOLBOX_TABS.EMAIL },
        ],
      });
    },
    linkText: viewEmail,
  };
}
