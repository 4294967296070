import { ComponentType, useEffect, useMemo, useRef } from 'react';
import { CircularProgress, Stack, SxProps, Theme } from '@common-components';
import { useCurrentUser, useWindowVisibilityListener } from 'hooks';
import { uniteStyles } from 'utils';
import useBoxToken from './useBoxToken';

export interface WithBoxTokenProps {
  sx?: SxProps<Theme>;
}

// Passing the sx prop to the spinner to be able to render it in the desired height of the box component
// and position the spinner in the center
function Spinner({ sx }: { sx?: SxProps<Theme> }) {
  return (
    <Stack alignItems="center" justifyContent="center" sx={sx}>
      <CircularProgress />
    </Stack>
  );
}

// A spinner that also manages the box refresh token flow
function FetchingTokenSpinner({ sx }: { sx?: SxProps<Theme> }) {
  useBoxToken();

  return <Spinner sx={sx} />;
}

export function withBoxToken<P extends WithBoxTokenProps>(BoxComponent: ComponentType<P>) {
  return ({ sx, ...props }: P) => {
    const { me } = useCurrentUser();
    const { isWindowVisible } = useWindowVisibilityListener();
    const isBoxComponentMounted = useRef<boolean>(false);

    // Recalculate this flag only when the expiration data changes or the window visibility changes
    // As long the window is visible our timer will make sure that the token is valid, so we only need to recalculate
    // when our app is visible again and the token might not be valid
    const isTokenExpired = useMemo(() => {
      const expirationDate = me?.boxAccessToken?.expirationDate;
      if (!expirationDate) {
        return true;
      }

      return expirationDate <= Date.now();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me, isWindowVisible]);

    useEffect(() => {
      if (!isTokenExpired) {
        isBoxComponentMounted.current = true;
      }
    }, [isTokenExpired]);

    // In the first render of the component, if the token is not valid we postpone the render till after the token is renewed,
    // and we are also responsible for refreshing the token.
    if (isTokenExpired && !isBoxComponentMounted.current) {
      return <FetchingTokenSpinner sx={sx} />;
    }

    // On later renders, if we see that the token expired, we don't want to lose the state of the Box component,
    // so instead of unmounting it we change its visibility to hidden until the token is valid again
    return (
      <>
        {isTokenExpired && <Spinner sx={sx} />}
        <BoxComponent {...(props as P)} sx={uniteStyles(isTokenExpired ? { display: 'none' } : {}, sx)} />
      </>
    );
  };
}
