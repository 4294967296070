import { SubmissionActivityLog } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/SubmissionActivity';
import { SubmissionMarketActivityLog } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/SubmissionMarketActivity/SubmissionMarketActivityLog';

export enum ActivityLogLevel {
  Submission,
  SubmissionMarket,
}

export interface ActivityLogProps {
  activityLogLevel?: ActivityLogLevel;
}

export const ActivityLogBase = ({ activityLogLevel = ActivityLogLevel.Submission }: ActivityLogProps) => {
  if (activityLogLevel === ActivityLogLevel.SubmissionMarket) {
    return <SubmissionMarketActivityLog />;
  }
  return <SubmissionActivityLog />;
};
