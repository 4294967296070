import { MENTION_PREFIX } from '@common/email-variables';
import { cloneDeep, difference, isEmpty, mapValues, omit } from 'lodash';
import { useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'hooks';
import { EmailTemplate } from 'types';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';
import { resolveHtml, resolveText } from 'broker/utils/email-utils/email-resolver-utils/email-resolving-utils';
import { EmailTemplateVariablesMap } from 'broker/utils/email-utils/email-resolver-utils/template-variables-utils';
import { Draft, EmailTemplateContext, RecipientGroupToDraft, TemplateInstance, TemplateInstances } from './store/types';
import { getTemplate } from './utils/template-selection-utils';

export default function useDraftTemplates(
  recipientGroupsArray: RecipientGroup[],
  templates: EmailTemplate[],
  resolveForPreview: (
    subject: string,
    body: string,
    recipientGroup: RecipientGroup,
  ) => { body: string; subject: string },
  preSelectedFileIds?: string[],
  isSingleRecipientMode?: boolean,
  templateContextData?: Omit<EmailTemplateContext, 'recipient'>,
) {
  const submissionMarkets = templateContextData?.submissionMarkets;
  const attachedFiles = templateContextData?.attachedFiles;
  const [recipientGroupToDraft, setRecipientGroupToDraft] = useState<RecipientGroupToDraft>({});
  const [templateInstances, setTemplateInstances] = useState<TemplateInstances>({});
  const [initializedSetBefore, setInitializedSetBefore] = useState(false);

  const setRecipientGroupDraft = (recipientGroupId: string, draft: Draft) => {
    setRecipientGroupToDraft((prevRecipientGroupToDraft) => ({
      ...prevRecipientGroupToDraft,
      [recipientGroupId]: draft,
    }));
  };

  const setTemplateInstance = (templateId: string, newTemplateInstance: TemplateInstance) => {
    setTemplateInstances((prevTemplateInstances) => ({
      ...prevTemplateInstances,
      [templateId]: newTemplateInstance,
    }));
  };

  const setRecipientGroupDetachedTemplateInstance = (
    recipientGroupId: string,
    templateInstance: TemplateInstance | undefined,
  ) => {
    const draft = { ...recipientGroupToDraft[recipientGroupId], detachedTemplateInstance: templateInstance };
    setRecipientGroupDraft(recipientGroupId, draft);
  };

  const setRecipientGroupChosenTemplate = (recipientGroupId: string, templateId: string) => {
    const draft = { ...recipientGroupToDraft[recipientGroupId], chosenTemplateId: templateId };
    setRecipientGroupDraft(recipientGroupId, draft);
  };

  // State management //

  const updateTemplatesByRecipientGroups = () => {
    if (isEmpty(templates)) {
      return;
    }

    setRecipientGroupToDraft((prevRecipientGroupToDraft) => {
      const prevRecipientGroupsIds = Object.keys(prevRecipientGroupToDraft);
      const newRecipientGroupsIds = recipientGroupsArray.map((recipientGroup) => recipientGroup.id);

      const addedRecipientGroupsIds = difference(newRecipientGroupsIds, prevRecipientGroupsIds);
      const removedRecipientGroupsIds = difference(prevRecipientGroupsIds, newRecipientGroupsIds);

      const newRecipientGroupToDraft = cloneDeep(prevRecipientGroupToDraft);

      addedRecipientGroupsIds.forEach((recipientGroupId) => {
        newRecipientGroupToDraft[recipientGroupId] = { chosenTemplateId: templates[0].id };
      });

      removedRecipientGroupsIds.forEach((recipientGroupId) => {
        omit(newRecipientGroupToDraft, recipientGroupId);
      });

      return newRecipientGroupToDraft;
    });
  };

  // update detachedTemplateInstances once data in the context has changed
  useDeepCompareEffect(() => {
    if (templateContextData) {
      setRecipientGroupToDraft((prev) =>
        mapValues(prev, (group) => ({
          ...group,
          detachedTemplateInstance: group.detachedTemplateInstance
            ? {
                ...cloneDeep(group.detachedTemplateInstance),
                subject: resolveText(
                  group.detachedTemplateInstance.subject,
                  templateContextData,
                  EmailTemplateVariablesMap,
                  MENTION_PREFIX,
                ),
                body: resolveHtml(
                  group.detachedTemplateInstance.body,
                  templateContextData,
                  EmailTemplateVariablesMap,
                  MENTION_PREFIX,
                ),
              }
            : undefined,
        })),
      );
    }
  }, [templateContextData]);

  // Update recipientGroupToDraft on when recipient groups are added or removed
  useEffect(() => {
    updateTemplatesByRecipientGroups();
    // Activate only on recipient group additions & removals.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientGroupsArray]);

  // Initialize recipientGroupToDraft & templateInstances when templates & submissionMarkets load.
  useEffect(() => {
    const initializeState = () => {
      const shouldWaitForAttachedPreSelectedFiles = preSelectedFileIds?.length && !attachedFiles?.length;
      if (isEmpty(templates) || !submissionMarkets || shouldWaitForAttachedPreSelectedFiles || initializedSetBefore) {
        return;
      }

      const newTemplateInstances = Object.fromEntries(
        templates.map((template) => [
          template.id,
          {
            subject: template.subject,
            body: template.body,
            from: undefined,
          },
        ]),
      );

      let newRecipientGroupToDraft;

      if (isSingleRecipientMode) {
        const templateInstance = newTemplateInstances[templates[0].id];

        newRecipientGroupToDraft = {
          [recipientGroupsArray[0].id]: {
            chosenTemplateId: templates[0].id,
            // This effectively denotes this recipient group as detached.
            detachedTemplateInstance: resolveForPreview(
              templateInstance.subject,
              templateInstance.body,
              recipientGroupsArray[0],
            ),
          },
        };
      } else {
        newRecipientGroupToDraft = Object.fromEntries(
          recipientGroupsArray.map((recipientGroup) => {
            const chosenTemplate = getTemplate(templates[0].type, recipientGroup, templates, { submissionMarkets });

            return [
              recipientGroup.id,
              {
                chosenTemplateId: chosenTemplate.id,
              },
            ];
          }),
        );
      }

      setRecipientGroupToDraft(newRecipientGroupToDraft);
      setTemplateInstances(newTemplateInstances);
      setInitializedSetBefore(true);
    };

    initializeState();
    // The values of isSingleRecipientMode, recipientGroupsArray, resolveForPreview don't actually change, but I did not want to add 'useMemo' on each of them.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, submissionMarkets, initializedSetBefore, attachedFiles, preSelectedFileIds]);

  return {
    recipientGroupToDraft,
    templateInstances,
    setRecipientGroupDraft,
    setRecipientGroupChosenTemplate,
    setRecipientGroupDetachedTemplateInstance,
    setTemplateInstance,
  };
}
