import { Link, useMatch, useResolvedPath } from 'react-router-dom-latest';
import { Divider, ListItem, ListItemButton, listItemClasses, ListItemIcon, ListItemText } from '@common-components';
import { UserRole } from 'enums';
import { useCurrentUser } from 'hooks';
import { RouteMenuItem } from 'types';

export interface SideBarMenuItemProps {
  menuItem: RouteMenuItem;
  roles?: UserRole[];
  isHomePage?: boolean;
}

export default function SideBarMenuItem({ menuItem, roles, isHomePage }: SideBarMenuItemProps) {
  const { me } = useCurrentUser();

  const resolved = useResolvedPath(menuItem.path);
  const match = useMatch({ path: resolved.pathname, end: Boolean(isHomePage) });

  const { Icon, label, divider, path } = menuItem;

  if (roles && (!me || !roles.includes(me.role))) {
    // not authorized: do not render menu item
    return null;
  }

  return (
    <>
      <ListItem disableGutters>
        <ListItemButton
          selected={Boolean(match)}
          alignItems="center"
          disableGutters
          component={Link}
          to={path}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 1,
            color: 'text.secondary',
            [`&.${listItemClasses.selected}`]: {
              color: 'primary.main',
              backgroundColor: 'blueGrey.50',
              '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: -1,
                width: 4,
                backgroundColor: 'primary.main',
              },
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: 'auto', color: 'inherit' }}>
            <Icon />
          </ListItemIcon>
          <ListItemText
            sx={{ maxWidth: 1, px: 1 }}
            primary={label}
            primaryTypographyProps={{
              align: 'center',
              color: 'inherit',
              fontSize: 9,
              variant: 'caption',
              lineHeight: 'normal',
            }}
          />
        </ListItemButton>
      </ListItem>
      {divider && <Divider variant="middle" />}
    </>
  );
}
