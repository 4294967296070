import { HeraldQuote, HeraldQuoteCreate } from '@common/types';
import { HeraldQuoteClient } from 'clients';
import { QueryKeys } from 'enums';
import { MutateProps } from './query/types';
import { useMutate } from './query/useMutate';

export function useMutateHeraldQuote(options?: MutateProps) {
  const { create: createHeraldQuote } = useMutate<HeraldQuote, HeraldQuote, HeraldQuoteCreate, HeraldQuoteClient>({
    queryKey: QueryKeys.HeraldQuotes,
    clientClass: HeraldQuoteClient,
    cancelInvalidation: options?.cancelInvalidation,
    createOptions: { throwOnError: true },
    updateOptions: { throwOnError: true },
    destroyOptions: { throwOnError: true },
  });

  return { createHeraldQuote };
}
