import { ReactNode } from 'react';
import AppLoader from './AppLoader';
import { Stack, Typography } from './mui-index';

interface LoaderOverlayProps {
  text?: string;
  hideOverlay?: boolean;
  extraContent?: ReactNode;
}

export function LoaderOverlay({ text, hideOverlay = false, extraContent }: LoaderOverlayProps) {
  return (
    <Stack
      position="absolute"
      right={0}
      left={0}
      top={0}
      bottom={0}
      alignItems="center"
      justifyContent="center"
      zIndex={10}
      sx={{
        ...(!hideOverlay && { bgcolor: 'common.white', opacity: 0.8 }),
      }}
    >
      <Stack gap={2} alignItems="center" justifyContent="center">
        <Stack direction="row" alignItems="center" justifyContent="center">
          <AppLoader />
        </Stack>
        {text && <Typography>{text}</Typography>}
        {extraContent}
      </Stack>
    </Stack>
  );
}
