import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export interface PDFDecryptBody {
  boxItemId: string;
  folderId: string;
}

export interface PDFDecryptResponse {
  boxItemId: string;
}

export default class PdfClient extends CapitolaClient<{}> {
  public get resourceName(): string {
    return 'pdf';
  }

  public async decrypt(body: PDFDecryptBody): Promise<PDFDecryptResponse> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'decrypt',
      fetchOptions: { throwOnError: true },
      body: {
        ...body,
      },
    }) as Promise<PDFDecryptResponse>;
  }
}
