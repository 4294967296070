import { FileType } from 'enums';
import { useToast } from 'hooks';
import { useMutateBoxItems, useReFetchBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { Submission } from 'types';
import { extractErrorMessage } from 'utils';
import { downloadBlob } from 'utils/files-utils';
import { generateBlobReport } from './pdfHandler';
import { MarketingReportRow } from './utils';

interface useMarketingReportPdfProps {
  submission: Submission | null;
  marketingReportRows: MarketingReportRow[];
}

export const useMarketingReportPdf = ({ submission, marketingReportRows }: useMarketingReportPdfProps) => {
  const { uploadFileWithMetadata } = useMutateBoxItems(true);
  const { reFetchFileItems } = useReFetchBoxItems();
  const { showToast } = useToast();

  const getBlobReport = () =>
    generateBlobReport(marketingReportRows, submission!.coverageLines, submission!.insuredName, submission!.dueDate);

  const downloadReport = async () => {
    const pdfFileReport = getBlobReport();
    const fileName = `${messages.marketingReportPage.filePrefixName}${Date.now()}.pdf`;

    downloadBlob(pdfFileReport, fileName);

    try {
      // uploading to box is a side effect of downloading. The product requirement is to upload to box whatever the user downloads.
      await uploadFileWithMetadata({
        parentFolderId: submission!.boxFolderId,
        fileName,
        file: pdfFileReport,
        metadata: { fileType: FileType.MarketingReport },
      });

      await reFetchFileItems();
    } catch (e) {
      showToast('error', { message: extractErrorMessage(e) });
    }
  };

  return { downloadReport };
};
