import { useState } from 'react';
import { Box, Button, Card, Dialog, DialogTitle, IconButton, Stack, Typography } from '@common-components';
import { Close as CloseIcon } from '@icons';
import { Dlq } from 'enums';
import { useBoolean } from 'hooks';
import { useRedriveDlqMessages, useRetrieveDlqStatus } from 'hooks/api/dlqStatus';
import { DlqRedriveOutput } from 'types';

const config: Record<string, { title: string; type: Dlq }> = {
  inboundEmailDlq: {
    title: 'Inbound Email DLQ',
    type: Dlq.InboundEmail,
  },
  sendEmailDlq: {
    title: 'Send Email DLQ',
    type: Dlq.SendEmail,
  },
  inboundEmailAiDlq: {
    title: 'Inbound Email AI DLQ',
    type: Dlq.InboundEmailAI,
  },
  heraldProcessingDlq: {
    title: 'Herald Processing DLQ',
    type: Dlq.HeraldProcessing,
  },
};

const DlqStatusPage = () => {
  const messages = useRetrieveDlqStatus();
  const { redriveMessages } = useRedriveDlqMessages();

  const [isMetadataDialogOpen, { on: openMetadataDialog, off: closeMetadataDialog }] = useBoolean(false);
  const [isRedriveDialogOpen, { on: openRedriveDialog, off: closeRedriveDialog }] = useBoolean(false);
  const [data, setData] = useState({});
  const [redrivedMessages, setRedrivedMessages] = useState<DlqRedriveOutput | null>(null);

  async function handleClick(additionalData: any) {
    setData(additionalData);
    openMetadataDialog();
  }

  async function handleRedrive(dlq: Dlq) {
    const response = await redriveMessages({ dlq });
    setRedrivedMessages(response);
    openRedriveDialog();
  }

  return (
    <>
      <Stack flexDirection="column" alignItems="flex-start" rowGap={2} padding={5}>
        {messages &&
          Object.keys(messages).map((queueName) => (
            <Card elevation={4} key={queueName} sx={{ p: 3, display: 'flex', width: '100%', flexDirection: 'column' }}>
              <Stack direction="row" justifyContent="space-between" pb={2}>
                <Typography variant="h6">{config[queueName].title}</Typography>
                <Button variant="contained" color="primary" onClick={() => handleRedrive(config[queueName].type)}>
                  Redrive
                </Button>
              </Stack>
              <Stack flexDirection="column" gap={2}>
                {messages[queueName].map((event) => (
                  <Card
                    key={event.message.id}
                    sx={{ px: 1, bgcolor: 'grey.100' }}
                    variant="outlined"
                    onClick={() => handleClick(event.data)}
                  >
                    <Box component="pre" my={1}>
                      {JSON.stringify(event.message, null, 2)}
                    </Box>
                  </Card>
                ))}
              </Stack>
            </Card>
          ))}
      </Stack>
      <Dialog open={isMetadataDialogOpen} onClose={closeMetadataDialog} maxWidth="xl" fullWidth>
        <DialogTitle>
          <Box component="pre">
            <Typography variant="body1">{JSON.stringify(data, null, 2)}</Typography>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={closeMetadataDialog}
              icon={CloseIcon}
              color="secondary"
              variant="text"
            />
          </Box>
        </DialogTitle>
      </Dialog>
      <Dialog open={isRedriveDialogOpen} onClose={closeRedriveDialog} maxWidth="xl" fullWidth>
        <DialogTitle>
          <Box component="pre">
            <Typography variant="body1">{JSON.stringify(redrivedMessages, null, 2)}</Typography>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={closeRedriveDialog}
              icon={CloseIcon}
              color="secondary"
              variant="text"
            />
          </Box>
        </DialogTitle>
      </Dialog>
    </>
  );
};
export default DlqStatusPage;
