import { BoxSkeleton, Stack } from '@common-components';

export default function ContentSkeleton() {
  return (
    <Stack gap={2} p={4} bgcolor="common.white" height={1}>
      <BoxSkeleton isLoading sx={{ width: 1, height: 150 }} index={0} />
      <Stack gap={2} height={1} direction="row">
        <Stack flex={1} gap={2}>
          <BoxSkeleton isLoading sx={{ width: 1, height: 1 }} index={0} />
        </Stack>
        <Stack flex={2} gap={2}>
          <BoxSkeleton isLoading sx={{ width: 1, height: 1 }} index={0} />
          <BoxSkeleton isLoading sx={{ width: 1, height: 1 }} index={1} />
          <BoxSkeleton isLoading sx={{ width: 1, height: 1 }} index={2} />
          <BoxSkeleton isLoading sx={{ width: 1, height: 1 }} index={4} />
        </Stack>
      </Stack>
      <BoxSkeleton isLoading sx={{ width: 1, height: 150 }} index={0} />
    </Stack>
  );
}
