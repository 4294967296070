import { union } from 'lodash';
import { UserClient } from 'clients';
import { QueryKeys, UserRole } from 'enums';
import { UseQueryBaseSearchOptions } from 'hooks/api/query/types';
import { useQuerySearch } from 'hooks/api/query/useQuery';
import { User, UserSearch } from 'types';

export function useSearchUsersByRole(role: UserRole, props?: UseQueryBaseSearchOptions<User, UserSearch>) {
  const { filter, queryOptions, sorter, enabled } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.Users,
    clientClass: UserClient,
    enabled,
    filter: { ...filter, role },
    sorter: sorter ?? ((a, b) => a.email.localeCompare(b.email)),
    queryOptions: {
      // Default user search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useSearchEndUser() {
  const { items: capitolaBrokers, refetch: refetchCapitolaBrokers } = useSearchUsersByRole(UserRole.CapitolaBroker, {
    filter: { enabled: true },
  });
  const { items: brokers, refetch: refetchBroker } = useSearchUsersByRole(UserRole.Broker, {
    filter: { enabled: true },
  });
  const { items: retailers, refetch: refetchRetailer } = useSearchUsersByRole(UserRole.Retailer, {
    filter: { enabled: true },
  });
  const refetch = async () => {
    await Promise.all([refetchCapitolaBrokers(), refetchRetailer(), refetchBroker()]);
  };
  const users = union(capitolaBrokers, brokers, retailers);
  return { users, refetch };
}
