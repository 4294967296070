import { isEmpty } from 'lodash';
import { EmailTemplate } from 'types';
import { RecipientGroupToDraft, TemplateInstances } from './store/types';

interface UseActiveTemplateInstanceProps {
  recipientGroupToDraft: RecipientGroupToDraft;
  templateInstances: TemplateInstances;
  templates: EmailTemplate[];
  selectedRecipientGroupId?: string;
}

export default function useActiveDraft({
  recipientGroupToDraft,
  templateInstances,
  templates,
  selectedRecipientGroupId,
}: UseActiveTemplateInstanceProps) {
  const activeDraft = selectedRecipientGroupId ? recipientGroupToDraft[selectedRecipientGroupId] : undefined;
  const hasActiveDraft = !!activeDraft;

  const returnNoDraft = () => ({
    activeDraft: undefined,
    activeTemplateId: undefined,
    activeTemplateInstance: undefined,
    isActiveTemplateInstanceDetached: undefined,
  });

  const returnDefaultDraft = () => ({
    activeDraft,
    activeTemplateId: templates[0].id,
    activeTemplateInstance: templateInstances[templates[0].id],
    isActiveTemplateInstanceDetached: false,
  });

  const returnDetachedDraft = () => ({
    activeDraft,
    activeTemplateId: activeDraft!.chosenTemplateId,
    activeTemplateInstance: activeDraft!.detachedTemplateInstance,
    isActiveTemplateInstanceDetached: true,
  });

  const returnAttachedDraft = () => ({
    activeDraft,
    activeTemplateId: activeDraft!.chosenTemplateId,
    activeTemplateInstance: templateInstances[activeDraft!.chosenTemplateId],
    isActiveTemplateInstanceDetached: false,
  });

  if (!hasActiveDraft) {
    if (isEmpty(templates)) {
      return returnNoDraft();
    }

    return returnDefaultDraft();
  }

  if (activeDraft?.detachedTemplateInstance) {
    return returnDetachedDraft();
  }

  return returnAttachedDraft();
}
