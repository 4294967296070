import { Box, Button, Stack } from '@common-components';
import { AutoAwesomeOutlined } from '@icons';
import { InformationTooltipType } from 'contexts/information-tooltip/types';
import { useInformationTooltip } from 'hooks';
import { messages } from 'i18n';
import { BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';
import { TOOLBOX_TABS } from 'broker/pages/SubmissionWorkspacePage/types';
import { SmartReportBadge } from './SmartReportBadge';

interface SmartReportsButtonProps {
  proposalContent: string;
}

export const SmartReports = ({ proposalContent }: SmartReportsButtonProps) => {
  const { showInformationTooltip } = useInformationTooltip(InformationTooltipType.SmartReports);
  const updateQueryParam = useUpdateQueryParam();

  return (
    <Stack alignItems="center" flexDirection="row">
      <SmartReportBadge proposalContent={proposalContent} />
      <Box ml={1}>
        <Button
          size="small"
          onClick={() => {
            updateQueryParam({
              addParams: [{ queryParam: BrokerUrlParams.ACTIVE_TAB, value: TOOLBOX_TABS.SMART_REPORTS }],
              removeParams: [BrokerUrlParams.SELECTED_FILE, BrokerUrlParams.SELECTED_EMAIL],
            });
            showInformationTooltip();
          }}
          startIcon={<AutoAwesomeOutlined />}
        >
          {messages.submissionWorkspace.customerSection.smartObjectDropdown.selectorText}
        </Button>
      </Box>
    </Stack>
  );
};
