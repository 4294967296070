import { useGetParam } from 'hooks';
import { useLocation } from 'broker/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export function useCurrentSubmissionMarket() {
  const id = useGetParam('id');
  const location = useLocation();
  const { state } = location;
  const currentRouteKey = state?.routeKey;
  const currentState = currentRouteKey && state?.[currentRouteKey];
  const { markets } = useSubmissionsWorkspace();

  const marketById = markets.find((item) => item.id === id);

  if (marketById) {
    return marketById;
  }

  if (currentState && 'selectedMarket' in currentState) {
    return markets.find((item) => item.id === currentState.selectedMarket?.id);
  }
  return undefined;
}
