import { IconButton, NewMenu } from '@common-components';
import { MoreVert, Unarchive } from '@icons';
import { NotificationStatus } from 'enums';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';

interface TaskItemMenuActionsProps {
  onChangeStatus: (status: NotificationStatus) => void;
  loading?: boolean;
}

export default function TaskItemMenuActions({ onChangeStatus, loading }: TaskItemMenuActionsProps) {
  const optionsMenuState = useOptionsMenu();

  return (
    <>
      <IconButton
        edge="end"
        icon={MoreVert}
        loading={loading}
        color="secondary"
        variant="text"
        onClick={optionsMenuState.openMenu}
      />
      <NewMenu
        subMenuPlacement="left"
        optionsMenuState={optionsMenuState}
        menuItems={[
          {
            key: 'task-actions',
            items: [
              {
                label: messages.general.markAsActive,
                endAdornment: Unarchive,
                onClick: () => onChangeStatus(NotificationStatus.New),
              },
            ],
          },
        ]}
      />
    </>
  );
}
