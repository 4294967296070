export enum SubmissionMarketRequestStatus {
    ReadyToMarket = 'ReadyToMarket',
    AwaitingQuote = 'AwaitingQuote',
    QuoteReady = 'QuoteReady',
    AwaitingBinder = 'AwaitingBinder',
    Bound = 'Bound',
    AwaitingPolicy = 'AwaitingPolicy',
    PolicyIssued = 'PolicyIssued',
    Declined = 'Declined',
    QuoteNotAccepted = 'QuoteNotAccepted',
    PendingCustomer = 'PendingCustomer',
}
