import { useState } from 'react';
import { IconButton } from 'components';
import { copyHtmlToClipboard, copyToClipboard, markdownToHtml } from 'utils';
import { Check, ContentCopy } from 'components/icons';

export const CopyButton = ({ content, copyAsHtml = true }: { content: string; copyAsHtml?: boolean }) => {
  const [copied, setCopied] = useState(false);

  return (
    <IconButton
      key="copy"
      icon={copied ? Check : ContentCopy}
      size="small"
      color="secondary"
      onClick={async () => {
        if (copyAsHtml) {
          await copyHtmlToClipboard(markdownToHtml(content));
        } else {
          await copyToClipboard(content);
        }
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      }}
    />
  );
};
