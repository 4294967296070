import { useState } from 'react';
import { Button, Stack, Typography } from '@common-components';
import { CapitolaClient } from 'clients';
// eslint-disable-next-line import/no-restricted-paths
import { useClient } from 'hooks/useClient';
import { HttpMethods } from 'utils/http';

class MigrationClient extends CapitolaClient<{}> {
  public get resourceName(): string {
    return 'migration';
  }
}

export function ConnectNylasV3Users() {
  const [responseMessagesList, setResponseMessagesList] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const migrationClient = useClient(MigrationClient);

  async function runMigration() {
    setLoading(true);
    const response = await migrationClient.custom<{ responseMessages: string[] }>({
      path: 'connect-nylas-v3-users',
      httpMethod: HttpMethods.POST,
    });
    if (response) {
      setResponseMessagesList(response.responseMessages);
    }
    setLoading(false);
  }

  return (
    <Stack flexDirection="column" width="fit-content">
      <Button loading={loading} onClick={runMigration} variant="contained">
        Click me!
      </Button>
      {responseMessagesList.map((response, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography key={index}>{response}</Typography>
      ))}
    </Stack>
  );
}
