import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { InsuranceProduct } from 'types';
import { coverageLineConfig, getDisplayDateWithHoursAmPm } from 'utils';

interface ProductInfoProps {
  product: InsuranceProduct;
}

export default function ProductInfo({ product }: ProductInfoProps) {
  const tooltipMessages = messages.marketsPage.productListTooltip;
  return (
    <Stack>
      <Stack direction="row" gap={1}>
        <Typography variant="body2Bold">{tooltipMessages.coverageLines}:</Typography>
        <Typography variant="body2">
          {product.coverageLines
            .map((insuranceProductCoverageLine) => coverageLineConfig[insuranceProductCoverageLine.coverageLine].text)
            .join(',')}
        </Typography>
      </Stack>
      {product.isAdmitted !== undefined && (
        <Stack direction="row" gap={1}>
          <Typography variant="body2Bold">{tooltipMessages.admitted}:</Typography>
          <Typography variant="body2">{product.isAdmitted ? messages.general.yes : messages.general.no}</Typography>
        </Stack>
      )}
      {product.isPrimary !== undefined && (
        <Stack direction="row" gap={1}>
          <Typography variant="body2Bold">{tooltipMessages.isPrimary}:</Typography>
          <Typography variant="body2">{product.isPrimary ? messages.general.yes : messages.general.no}</Typography>
        </Stack>
      )}

      {product.offeringLimit !== undefined && (
        <Stack direction="row" gap={1}>
          <Typography variant="body2Bold">{tooltipMessages.offeringLimit}:</Typography>
          <Typography variant="body2">{product.offeringLimit}</Typography>
        </Stack>
      )}
      <Stack direction="row" gap={1}>
        <Typography variant="body2Bold">{tooltipMessages.lastUpdated}:</Typography>
        <Typography variant="body2">{getDisplayDateWithHoursAmPm(product.updatedAt)}</Typography>
      </Stack>
      <Stack direction="row" gap={1}>
        <Typography variant="body2Bold">{tooltipMessages.updatedBy}:</Typography>
        <Typography variant="body2">{product.updatedBy}</Typography>
      </Stack>
    </Stack>
  );
}
