import { useCallback } from 'react';
import { Box } from '@common-components';
import { useSearchUserMarket } from 'hooks';
import { PartialSubmission } from 'types';
import GridFormArrayFields, {
  GridFormArrayFieldProps,
} from 'components/hookFormComponents/GridFormFields/GridFormArrayFields';
import { marketAutocompleteLabelShiftPadding } from 'broker/dialogs/SubmissionAutonomousCreation/AdvancedContent/styles';
import {
  AutonomousMarketFieldNames,
  autonomousMarketFormConfig,
  autonomousMarketFormFieldsDefaults,
  AutonomousMarketInfoData,
} from 'broker/dialogs/SubmissionAutonomousCreation/AdvancedContent/utils';

import AutonomousMarketsFormFields from './AutonomousMarketsFormFields';

export default function AutonomousMarketsForm({
  submission,
  initSubmissionMarkets,
  active,
}: {
  submission: PartialSubmission;
  initSubmissionMarkets: AutonomousMarketInfoData[];
  active: boolean;
}) {
  const { items: userMarkets } = useSearchUserMarket();

  const AutonomousMarketsFormFieldsWrapper = useCallback(
    (props: GridFormArrayFieldProps) => (
      <AutonomousMarketsFormFields {...props} userMarkets={userMarkets} initSubmissionMarkets={initSubmissionMarkets} />
    ),
    [initSubmissionMarkets, userMarkets],
  );

  return (
    <Box pr={marketAutocompleteLabelShiftPadding}>
      <GridFormArrayFields
        disableDeleteIconDivider
        disabled={submission.isCopilotEnabled || !active}
        defaultValue={autonomousMarketFormFieldsDefaults}
        FormArrayFieldComponent={AutonomousMarketsFormFieldsWrapper}
        {...autonomousMarketFormConfig[AutonomousMarketFieldNames.Markets]}
      />
    </Box>
  );
}
