import { Link, Tooltip, Typography } from '@common-components';
import { activeTabUrlParamValues, BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';

export interface FileListItemProps {
  file: { fileName: string; FileId: string };
}

export function FileListItem({ file }: FileListItemProps) {
  const updateQueryParam = useUpdateQueryParam();

  const handleClickFile = (fileId: string) => {
    updateQueryParam({
      addParams: [
        { queryParam: BrokerUrlParams.ACTIVE_TAB, value: activeTabUrlParamValues.FILES },
        {
          queryParam: BrokerUrlParams.SELECTED_FILE,
          value: fileId,
        },
      ],
      removeParams: [BrokerUrlParams.SELECTED_FILE, BrokerUrlParams.SELECTED_EMAIL],
    });
  };

  return (
    <Tooltip tooltipContent={file.fileName} key={file.FileId}>
      <Link
        key={file.FileId}
        onClick={() => handleClickFile(file.FileId)}
        sx={{
          cursor: 'pointer',
          maxWidth: 300,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'block',
          textDecoration: 'none',
        }}
      >
        <Typography variant="body2">{file.fileName}</Typography>
      </Link>
    </Tooltip>
  );
}
