import { isEmpty } from 'lodash';
import { Box, Button, ButtonBase, NewMenu, Stack, Typography } from '@common-components';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import TemplateIcon from 'broker/components/common/TemplateIcon';
import { ConfirmationDialogGuard } from 'broker/components/ConfirmationDialogGuard';
import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';
import TemplateTag from './TemplateTag';

export default function TemplateSelector() {
  const {
    activeDraft,
    templates: { templates },
    recipients: { selectedRecipientGroupId },
    draftEditorMode,
    isSingleRecipientMode,
  } = useEmailEditorState();

  const {
    draftTemplates: { setRecipientGroupChosenTemplate },
    draftEditing: { onResetToTemplate },
  } = useEmailEditorActions();

  const optionsMenu = useOptionsMenu();

  if (isEmpty(templates)) {
    return null;
  }

  const selectedTemplateId = selectedRecipientGroupId ? activeDraft?.chosenTemplateId : templates[0].id;
  const selectedTemplateName = templates.find((templateInstance) => templateInstance.id === selectedTemplateId)?.name;

  const handleMenuItemClick = (templateId: string) => {
    setRecipientGroupChosenTemplate(selectedRecipientGroupId!, templateId);
    optionsMenu.closeMenu();
  };

  const { title, header, subHeader, discardChanges } = messages.resetToTemplateModal;

  if (isSingleRecipientMode) {
    return null;
  }

  if (draftEditorMode === DraftEditorMode.EditForMarket) {
    return (
      <ConfirmationDialogGuard
        title={title}
        message={
          <Stack gap={1}>
            <Typography variant="h4" color="contrastText">
              {header}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {subHeader}
            </Typography>
          </Stack>
        }
        action={onResetToTemplate}
        showConfirmation
        proceedButton={{ children: discardChanges }}
      >
        {({ guardedAction }) => (
          <Button variant="text" startIcon={<TemplateIcon />} onClick={guardedAction}>
            {messages.emailEditor.resetToTemplate}
          </Button>
        )}
      </ConfirmationDialogGuard>
    );
  }

  return (
    <Box className="cap-template-selector">
      <ButtonBase
        onClick={optionsMenu.openMenu}
        disabled={!selectedTemplateId}
        aria-label="templates-menu-button"
        color="inherit"
      >
        <TemplateTag
          selectedTemplateName={selectedTemplateName ?? messages.emailEditor.addMarketAbove}
          disabled={!selectedTemplateId}
        />
      </ButtonBase>

      <NewMenu
        optionsMenuState={optionsMenu}
        menuItems={[
          {
            key: 'cap-templates-menu-popover',
            selectionType: 'radio',
            items: templates.map((template) => ({
              label: template.name,
              selected: selectedTemplateId === template.id,
              onClick: () => handleMenuItemClick(template.id),
            })),
          },
        ]}
      />
    </Box>
  );
}
