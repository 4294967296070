import { HeraldAccessTokenInfoResponse } from 'types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

/**
 * HeraldClient is a client for the herald resource in the Capitola API, used for actions that must
 * be performed by our server such as creating access token for our client to access Herald API with.
 */
export class HeraldClient extends CapitolaClient<{}> {
  public get resourceName(): string {
    return 'herald';
  }

  public async createAccessToken(): Promise<HeraldAccessTokenInfoResponse | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'create-token',
      fetchOptions: { throwOnError: true },
    });
  }
}
