import { CapitolaClient } from 'clients';
import { useClient } from 'hooks/useClient';
import { BulkCreateResponse } from 'types';
import { QueryCreateParams, UseQueryMutateOptions } from './types';
import { useQueryMutation } from './useMutate';

export const useBulkCreate = <Type, ListItem, Client extends CapitolaClient<Type, ListItem>>({
  queryKey,
  cancelInvalidation,
  clientClass,
  onSuccess,
  createOptions,
}: UseQueryMutateOptions<Client, BulkCreateResponse<Type>>) => {
  const client = useClient(clientClass);

  const bulkCreate = useQueryMutation<BulkCreateResponse<Type>, QueryCreateParams<Record<string, string | File>>>(
    ({ data, fetchOptions }) => client.bulkCreate(data, fetchOptions || createOptions),
    queryKey,
    cancelInvalidation,
    onSuccess,
  );

  return { bulkCreate };
};
