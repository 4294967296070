export interface DropdownMenuItems {
  label: string;
  color?: string;
  child?: DropdownMenuItems[];
  onClick?: () => void;
  disabled?: boolean;
}

export type ColumnConfigType = {
  key: string;
  title: string;
  flex?: {} | number;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right';
};

export enum SUBMISSION_MARKET_COLUMNS {
  Product = 'product',
  Status = 'status',
  Info = 'info',
  Note = 'note',
}
