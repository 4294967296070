import { keyframes } from '@emotion/react';
import { Box } from './mui-index';

export function ProcessingAnimation() {
  const rotateAnimation = keyframes`
        0% {
            width: 0;
        }
        100% {
            width: 100%;
        }
`;

  return (
    <Box
      sx={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: 'common.black',
        opacity: 0.1,
        zIndex: 1,
        animation: `${rotateAnimation} 2s linear infinite`,
      }}
      height={1}
    />
  );
}
