import { isBoolean } from 'lodash';
import { forwardRef } from 'react';
// eslint-disable-next-line import/no-internal-modules
import { useBoolean } from 'hooks/useBoolean';
import { menuMinWidth } from 'themes';
import { ConfirmationDialog } from 'components/Dialog/ConfirmationDialog';
import { Box, Checkbox, MenuItem, Radio, Stack, Typography } from 'components/mui-index';
import Tooltip from 'components/Tooltip';
import { MenuItemProps } from './types';
import { itemHorizontalPadding, itemVerticalPadding, menuIconFontSize } from './utils';

export const NewMenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  (
    {
      label,
      subLabel,
      disabled,
      startAdornment: StartAdornment,
      endAdornment: EndAdornment,
      color,
      textColor,
      onClick,
      selectionType,
      isSelected,
      tooltipContent,
      tooltipPlacement = 'top',
      disabledTooltipContent,
      isTooltipOpen,
      disableTooltipPadding = false,
      tooltipDarkMode = false,
      variant = 'body2',
      confirmationDialog,
      subLabelColor,
      endAdornmentColor,
      ...props
    },
    ref,
  ) => {
    const tooltipContentToUse = disabled ? disabledTooltipContent : tooltipContent;
    const [showConfirmation, { on: openConfirmation, off: closeConfirmation }] = useBoolean(false);

    function handleClick() {
      if (disabled) {
        return undefined;
      }
      if (confirmationDialog) {
        return openConfirmation();
      }
      return onClick?.();
    }

    return (
      <>
        <MenuItem
          sx={{
            bgcolor: color,
            py: itemVerticalPadding,
            px: itemHorizontalPadding,
            width: 1,
            ...(disabled && {
              opacity: 0.38,
              cursor: 'default',
            }),
          }}
          onClick={handleClick}
          disableRipple={disabled}
          ref={ref}
          {...props}
        >
          <Tooltip
            tooltipContent={tooltipContentToUse ?? ''}
            placement={tooltipPlacement}
            arrow
            isOpen={isTooltipOpen}
            darkMode={tooltipDarkMode}
            disablePadding={disableTooltipPadding}
          >
            <Box width={1}>
              <Stack
                sx={{
                  ...(disabled && {
                    pointerEvents: 'none',
                  }),
                }}
                direction="row"
                minWidth={menuMinWidth}
                alignItems="center"
                gap={1}
                width={1}
              >
                {selectionType === 'checkbox' && isBoolean(isSelected) && (
                  <Checkbox sx={{ p: 0 }} disableRipple checked={isSelected} />
                )}
                {selectionType === 'radio' && isBoolean(isSelected) && (
                  <Radio sx={{ p: 0 }} disableRipple checked={isSelected} />
                )}
                {StartAdornment && (
                  <StartAdornment
                    sx={{
                      fontSize: menuIconFontSize,
                      color: textColor || 'text.primary',
                    }}
                  />
                )}
                <Stack width={1} direction="column" alignItems="baseline">
                  <Typography color={`${textColor || 'text.primary'}`} variant={variant}>
                    {label}
                  </Typography>
                  {subLabel && (
                    <Typography variant="caption" color={subLabelColor || 'grey.600'}>
                      {subLabel}
                    </Typography>
                  )}
                </Stack>

                {EndAdornment && (
                  <Stack flex={1} alignItems="flex-end">
                    <EndAdornment
                      sx={{
                        fontSize: menuIconFontSize,
                        color: endAdornmentColor || 'text.primary',
                      }}
                    />
                  </Stack>
                )}
              </Stack>
            </Box>
          </Tooltip>
        </MenuItem>
        {confirmationDialog && onClick && (
          <ConfirmationDialog
            onSubmit={onClick}
            isOpen={showConfirmation}
            onCancel={closeConfirmation}
            {...confirmationDialog!}
          />
        )}
      </>
    );
  },
);
