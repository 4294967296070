import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import TeammatesCcGroupContacts from 'broker/components/TeammatesCcGroupContacts';

export const CcField = () => {
  const {
    cc: { availableTeammates, selectedTeammates },
  } = useEmailEditorState();

  const {
    cc: { toggleTeammateSelection },
  } = useEmailEditorActions();

  return (
    <TeammatesCcGroupContacts
      teammates={availableTeammates}
      selectedTeammates={selectedTeammates}
      toggleTeammate={toggleTeammateSelection}
      spacingBottom={0}
    />
  );
};
