import { createContext } from 'react';
import { AnyObject } from 'types';

export interface ErrorCallback {
  (errorMessage: string, statusCode?: number, extraLogInfo?: AnyObject): void;
}

export const ErrorContext = createContext<{
  onError: ErrorCallback;
  onSubmissionNotFound: () => void;
}>({
  onError: () => {},
  onSubmissionNotFound: () => {},
});
