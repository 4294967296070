import { Stack } from '@common-components';
import TemplateSelector from 'broker/components/EmailEditor/components/TemplateSelector/TemplateSelector';
import { DoubleFieldWrapper } from './DoubleFieldWrapper';
import { SubjectField } from './SubjectField';

export const ContentFields = () => (
  <Stack direction="column" justifyContent="center">
    <DoubleFieldWrapper>
      <SubjectField />
      <TemplateSelector />
    </DoubleFieldWrapper>
  </Stack>
);
