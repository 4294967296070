import { isNil, keyBy, mapValues } from 'lodash';
import { convertIndustryObjectToOption } from 'broker/components/common/IndustryAutoComplete/utils';
import {
  FormStateBaseProps,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';

export default function mapHeraldParametersToFormState(heraldNormalizedParameters: HeraldNormalizedParameter[]) {
  const state: Record<string, FormStateBaseProps | FormStateBaseProps[]> = {};

  const mapToFormState = (
    heraldNormalizedParameter: HeraldNormalizedParameter,
    inArray = false,
  ): FormStateBaseProps | FormStateBaseProps[] | undefined => {
    if (heraldNormalizedParameter.arrayElements) {
      return heraldNormalizedParameter.arrayElements
        .filter((element) => !!element.value)
        .map((element) => ({
          main: element.value ?? undefined,
          ...(element.childValues && {
            children: mapValues(keyBy(element.childValues, 'parameter_id'), (childValue) =>
              mapToFormState(childValue, true),
            ),
          }),
        })) as FormStateBaseProps[];
    }

    let value = heraldNormalizedParameter.value ?? undefined;
    if (heraldNormalizedParameter.industryDetails) {
      value = convertIndustryObjectToOption({
        ...heraldNormalizedParameter.industryDetails,
        naicsCode: heraldNormalizedParameter.industryDetails.naics_2017_6_digit,
        heraldId: heraldNormalizedParameter.industryDetails.id,
      });
    }

    // for repeated question that is isNil we don't want to add it as an empty element to FormStateBaseProps[]
    return inArray && isNil(value)
      ? undefined
      : ({
          main: value,
          ...(heraldNormalizedParameter.childValues && {
            children: mapValues(keyBy(heraldNormalizedParameter.childValues, 'parameter_id'), (childValue) =>
              mapToFormState(childValue),
            ),
          }),
        } as FormStateBaseProps);
  };

  heraldNormalizedParameters.forEach((heraldNormalizedParameter) => {
    state[heraldNormalizedParameter.parameter_id] = mapToFormState(heraldNormalizedParameter)!;
  });

  return state;
}
