import { Contact, EmailValidationResponse, OffsetLimitPagination } from 'types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export type ContactQuery = OffsetLimitPagination;
export class ContactClient extends CapitolaClient<Contact> {
  public get resourceName(): string {
    return 'contact';
  }

  public async validateEmails(emails: string[]): Promise<EmailValidationResponse | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'email-validation',
      body: {
        emails,
      },
      fetchOptions: {
        // Explicitly set throwOnError to false as we don't want failure in validation to stop the flow
        throwOnError: false,
      },
    });
  }
}
