import { useEffect, useRef, useState } from 'react';

interface SingleFieldOptimisticUpdateProps<T> {
  serverValue?: T;
  apiUpdate: (newValue: T) => Promise<any | void>;
}

export default function useSingleFieldOptimisticUpdate<T>({
  serverValue,
  apiUpdate,
}: SingleFieldOptimisticUpdateProps<T>) {
  const [value, setValue] = useState(serverValue);
  const isUpdatingRef = useRef(false);

  useEffect(() => {
    if (!isUpdatingRef.current) {
      setValue(serverValue);
    }
  }, [serverValue]);

  const onChange = async (newValue: T) => {
    // optimistically update the local state
    const prevValue = value;
    isUpdatingRef.current = true;

    setValue(newValue);
    try {
      await apiUpdate(newValue);
    } catch (e) {
      // In case of failure, rollback to previous server state
      setValue(prevValue);

      // Throw the error, to allow the usage to decide if an additional action is needed
      throw e;
    } finally {
      isUpdatingRef.current = false;
    }
  };

  return {
    value,
    onChange,
  };
}
