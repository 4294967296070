import { Box, Stack, Typography } from '@common-components';
import { ArrowDownward, ArrowUpward } from '@icons';
import { submissionsViewColumnsConfig } from './config';
import { SubmissionSortType, SubmissionViewMode } from './utils';

const iconSize = 12;
interface SubmissionTableHeaderProps {
  viewMode: SubmissionViewMode;
  sortType: SubmissionSortType;
  setSortType: (value: SubmissionSortType) => void;
}

// Mapping column keys to both Asc and Desc sort types
const SUBMISSION_SORT_TYPE_MAP: Record<string, { asc: SubmissionSortType; desc: SubmissionSortType }> = {
  received: { asc: SubmissionSortType.CreatedDateOldest, desc: SubmissionSortType.CreatedDateNewest },
  dueDate: { asc: SubmissionSortType.EffectiveDateOldest, desc: SubmissionSortType.EffectiveDateNewest },
  insuredName: { asc: SubmissionSortType.InsuredDesc, desc: SubmissionSortType.InsuredAsc },
  reminder: { asc: SubmissionSortType.ReminderDateOldest, desc: SubmissionSortType.ReminderDateNewest },
  lastUpdated: { asc: SubmissionSortType.Updated, desc: SubmissionSortType.Updated }, // Only one sort direction
};

export default function SubmissionTableHeader({ viewMode, sortType, setSortType }: SubmissionTableHeaderProps) {
  const handleSort = (columnKey: string) => {
    const { asc, desc } = SUBMISSION_SORT_TYPE_MAP[columnKey];

    if (sortType === desc) {
      setSortType(asc);
    } else {
      setSortType(desc);
    }
  };

  const getSortIcon = (columnKey: string, isSorted: boolean) => {
    const { asc } = SUBMISSION_SORT_TYPE_MAP[columnKey];

    if (isSorted) {
      return sortType === asc ? (
        <ArrowUpward
          sx={{
            fontSize: iconSize,
          }}
        />
      ) : (
        <ArrowDownward
          sx={{
            fontSize: iconSize,
          }}
        />
      );
    }
    return (
      <ArrowDownward
        sx={{
          fontSize: iconSize,
        }}
      />
    );
  };

  return (
    <Stack
      bgcolor="common.white"
      position="sticky"
      top={0}
      zIndex={(theme) => theme.zIndex.appBar}
      direction="row"
      flex={0}
      gap={1}
      px={2}
      borderBottom={1}
      borderColor="divider"
    >
      {Object.values(submissionsViewColumnsConfig(viewMode)).map((column) => {
        const sortConfig = SUBMISSION_SORT_TYPE_MAP[column.key]; // Get sorting config
        const isSorted = sortType === sortConfig?.asc || sortType === sortConfig?.desc;

        return column.showForModes.includes(viewMode) ? (
          <Stack
            py={2}
            key={column.key}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width={1}
            flex={column.flex}
            onClick={() => sortConfig && handleSort(column.key)}
            sx={{
              '&:hover .sort-icon': {
                visibility: sortConfig ? 'visible' : 'hidden',
              },
            }}
          >
            <Typography
              variant="caption"
              color="text.secondary"
              align={column.align}
              fontWeight={isSorted ? 'bold' : 'normal'}
            >
              {column.title}
            </Typography>
            {sortConfig && (
              <Box
                className="sort-icon"
                sx={{
                  visibility: isSorted ? 'visible' : 'hidden',
                  mx: 2,
                }}
              >
                {getSortIcon(column.key, isSorted)}
              </Box>
            )}
          </Stack>
        ) : null;
      })}
    </Stack>
  );
}
