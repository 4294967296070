import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import { ActivityLogClient, LayerClient, QuoteClient, SubmissionClient, SubmissionMarketClient } from 'clients';
import { retryOnError } from 'clients/base';
import { ErrorContext } from 'contexts';
import { QueryKeys } from 'enums';
import { queryRetryStrategy } from 'hooks/api/query/query-retry-strategy';
import { cachedQueryOptions, defaultQueryOptions } from 'hooks/api/query/useQuery';
import { useClient } from 'hooks/useClient';
import {
  addCalculatedDataToMarket,
  addComputedFieldsToQuote,
  addSelectedQuoteLimitToLayer,
  resolveActivities,
} from './helpers';

export default function useRetrieveExtendedSubmission({ submissionId }: { submissionId?: string }) {
  const { onSubmissionNotFound } = useContext(ErrorContext);
  const submissionClient = useClient(SubmissionClient);
  const quoteClient = useClient(QuoteClient);
  const layerClient = useClient(LayerClient);
  const submissionMarketClient = useClient(SubmissionMarketClient);
  const activityLogClient = useClient(ActivityLogClient);

  const fetchWorkspaceClient = async () => {
    // We are sure that we have a submissionId here because the query is disabled otherwise
    const submissionPromise = submissionClient.retrieve(submissionId!, { ...retryOnError });
    const quotesPromise = quoteClient.search({ submissionId }, { ...retryOnError });
    const layersPromise = layerClient.search({ submissionId }, { ...retryOnError });
    const submissionMarketsPromise = submissionMarketClient.search({ submissionId }, { ...retryOnError });
    const activitiesPromise = activityLogClient.search({ submissionId }, { ...retryOnError });

    const data = await Promise.all([
      submissionPromise,
      quotesPromise,
      layersPromise,
      submissionMarketsPromise,
      activitiesPromise,
    ]);

    const submission = data[0];
    if (!submission) {
      onSubmissionNotFound();
    }

    const quotes = data[1]?.items || [];
    const layers = data[2]?.items || [];
    const markets = data[3]?.items || [];
    const activities = data[4]?.items || [];

    const enrichedQuotes = addComputedFieldsToQuote(quotes, markets, layers);

    return {
      submission,
      quotes: enrichedQuotes,
      layers: addSelectedQuoteLimitToLayer(quotes, layers),
      markets: addCalculatedDataToMarket(layers, markets, enrichedQuotes),
      activities: resolveActivities(activities, layers, enrichedQuotes),
    };
  };

  const queryClient = useQueryClient();

  const fetchExtendedSubmission = useCallback(
    async () => queryClient.invalidateQueries([QueryKeys.ExtendedSubmission, submissionId]),
    [submissionId, queryClient],
  );

  const clearCache = async () => {
    queryClient.removeQueries([QueryKeys.ExtendedSubmission, submissionId]);
  };

  const extendedSubmissionQuery = useQuery([QueryKeys.ExtendedSubmission, submissionId], fetchWorkspaceClient, {
    ...defaultQueryOptions,
    ...cachedQueryOptions,
    enabled: !!submissionId,
    // override the default retry strategy to handle the case where the submission is not found
    ...queryRetryStrategy(() => onSubmissionNotFound()),
  });
  return { ...extendedSubmissionQuery, clearCache, fetchExtendedSubmission };
}
