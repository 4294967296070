import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import RoutedInlineDialog from 'broker/components/RoutedInlineDialog';
// The below eslint-disable are due to the fact that the email editor highly depends on the submission workspace page,
// but some parts of it might be relevant to use elsewhere. This should be addressed in CAP-3264
// In this case, the modal could potentially move to the workspace folder and only rendered in the SubmissionEmailEditor,
// but this will leave the EmailEditor with internal components that navigate to this modal and depend on it being rendered.
// eslint-disable-next-line import/no-restricted-paths
import ConceptualTower from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/ConceptualTower';

export default function EmailEditorConceptualTower() {
  return (
    <RoutedInlineDialog
      className="cap-contained-conceptual-tower"
      path={`${BROKER_NESTED_ROUTES.WORKSPACE.CONCEPTUAL_TOWER}/*`}
    >
      <ConceptualTower />
    </RoutedInlineDialog>
  );
}
