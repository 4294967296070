import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@common-components';
import { ButtonMenuItem } from './utils';

interface SideBarButtonItemProps {
  menuItem: ButtonMenuItem;
  handleClick: () => void;
}

export default function SideBarButtonItem({ menuItem, handleClick }: SideBarButtonItemProps) {
  const { label, Icon } = menuItem;
  return (
    <ListItem disablePadding>
      <ListItemButton
        alignItems="center"
        disableGutters
        sx={{ display: 'flex', flexDirection: 'column', maxWidth: 1 }}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ minWidth: 'auto' }}>
          <Icon />
        </ListItemIcon>
        <ListItemText
          sx={{ maxWidth: 1, px: 1 }}
          primary={label}
          primaryTypographyProps={{
            align: 'center',
            noWrap: true,
            fontSize: 9,
            color: 'textSecondary',
            variant: 'caption',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}
