import { forwardRef } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

const defaultPrefix = '$';
export interface CustomMoneyInputProps extends NumberFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
export default forwardRef<HTMLElement, CustomMoneyInputProps>(
  ({ name, onChange, prefix = defaultPrefix, ...props }: CustomMoneyInputProps, ref) => (
    <NumberFormat
      name={name}
      getInputRef={ref}
      allowNegative={false}
      thousandSeparator
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      prefix={prefix}
      isNumericString
      {...props}
    />
  ),
);
