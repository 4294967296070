import { MouseEvent } from 'react';
import { Box, Chip, Stack } from '@common-components';
import { AirplanemodeActive } from '@icons';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { BROKER_NESTED_ROUTES, BROKER_ROUTES } from 'broker/broker-routes';
import { StepsRoutes } from 'broker/dialogs/SubmissionAutonomousCreation/types';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';

interface CopilotIndicatorProps {
  submission: PartialSubmission;
  copilotSettings: boolean;
  isCopilotEnabled: boolean;
  flex: number | string;
  display?: 'none' | 'flex';
}
export default function CopilotIndicator({
  submission,
  isCopilotEnabled,
  copilotSettings,
  flex,
  display = 'flex',
}: CopilotIndicatorProps) {
  const navigate = useNavigate();

  // const coPilotCandidate =
  //   submission.status === SubmissionStatus.New && submission.type === SubmissionType.Renewal && copilotSettings;
  // currently co-pilot is not enabled, in the future we will see if we want to remove it from code
  const coPilotCandidate = false;
  return copilotSettings ? (
    <Box visibility={coPilotCandidate || isCopilotEnabled ? 'visible' : 'hidden'}>
      <Chip
        tooltipContent={isCopilotEnabled ? messages.autonomousFlow.editFlow : messages.autonomousFlow.createFlow}
        startIcon={AirplanemodeActive}
        color={isCopilotEnabled ? 'green.300' : 'secondary'}
        variant={isCopilotEnabled ? 'contained' : 'outlined'}
        rounded
        onClick={(e?: MouseEvent<HTMLButtonElement>) => {
          e?.stopPropagation();
          e?.preventDefault();
          navigate(
            isCopilotEnabled
              ? `/${BROKER_ROUTES.HOME}/${BROKER_NESTED_ROUTES.HOME.SUBMISSION_AUTONOMOUS_CREATION}/${StepsRoutes.Advanced}`
              : `/${BROKER_ROUTES.HOME}/${BROKER_NESTED_ROUTES.HOME.SUBMISSION_AUTONOMOUS_CREATION}/${StepsRoutes.Basic}`,
            {
              routeKey: RouteKey.SubmissionAutonomousCreation,
              state: { submission },
            },
          );
        }}
      />
    </Box>
  ) : (
    <Stack flex={flex} display={display} justifyContent="center" />
  );
}
