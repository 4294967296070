import { messages } from 'i18n';
import { ActivityLogList } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/ActivityLog';
import { useShareActivityLog } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/ActivityLog/useShareActivityLog';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { submissionActivityTypeConfig } from './submission-activity-type-config';

export const SubmissionActivityLog = () => {
  const { activities } = useSubmissionsWorkspace();
  const { getShareOptions } = useShareActivityLog();

  return (
    <ActivityLogList
      activities={activities}
      activityTypeConfig={submissionActivityTypeConfig}
      title={messages.submissionWorkspace.customerSection.submissionActivityLog}
      shareOptions={getShareOptions(activities)}
    />
  );
};
