import * as yup from 'yup';
import { messages } from 'i18n';

const { requiredField } = messages.formMessages;
export const capitolaKnowledgeBaseUrl = 'https://support.flowspecialty.com/';
export interface SupportFormData {
  subject: string;
  message: string;
}

export const schema = yup.object().shape({
  subject: yup.string().trim().required(requiredField),
  message: yup.string().trim().required(requiredField),
});
