// eslint-disable-next-line no-restricted-imports
import { TextField as MuiTextField } from '@mui/material';
import { forwardRef, ReactElement } from 'react';
import { TextFieldProps } from './mui-index';

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ InputProps, ...props }: TextFieldProps, ref): ReactElement => (
    <MuiTextField
      InputProps={{ ...InputProps, classes: { ...(InputProps?.classes ?? {}), input: 'data-hj-allow' } }}
      {...props}
      ref={ref}
    />
  ),
);
