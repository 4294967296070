import * as yup from 'yup';
import { messages } from 'i18n';
import { FormFieldsConfig, ReviewAgentFormFieldsNames } from './types';

const { requiredField } = messages.formMessages;

export const formFieldsConfig: FormFieldsConfig = {
  [ReviewAgentFormFieldsNames.FirstName]: {
    label: messages.reviewAgent.labels.firstName,
    name: ReviewAgentFormFieldsNames.FirstName,
    id: ReviewAgentFormFieldsNames.FirstName,
    optional: false,
  },
  [ReviewAgentFormFieldsNames.LastName]: {
    label: messages.reviewAgent.labels.lastName,
    name: ReviewAgentFormFieldsNames.LastName,
    id: ReviewAgentFormFieldsNames.LastName,
    optional: false,
  },
  [ReviewAgentFormFieldsNames.Email]: {
    label: messages.reviewAgent.labels.email,
    name: ReviewAgentFormFieldsNames.Email,
    id: ReviewAgentFormFieldsNames.Email,
    optional: false,
  },
  [ReviewAgentFormFieldsNames.Title]: {
    label: messages.reviewAgent.labels.title,
    name: ReviewAgentFormFieldsNames.Title,
    id: ReviewAgentFormFieldsNames.Title,
    optional: true,
  },
};

export const schema = yup
  .object()
  .shape({
    firstName: yup.string().default('').required(requiredField),
    lastName: yup.string().default('').required(requiredField),
    title: yup.string().default(''),
  })
  .required();
