import { Box, Checkbox, darken, FormControlLabel, Stack, Theme, Typography } from '@common-components';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@icons';
import { BaseFilterProps } from 'broker/components/common/Filter';

export const labelStyle = (theme: Theme) =>
  ({
    mx: 0,
    borderColor: 'divider',
    borderWidth: 1,
    borderStyle: 'solid',
    height: 36,
    py: 0.5,
    flex: 1,
    overflow: 'hidden',
    m: 0,
    justifyContent: 'center',
    ':hover': {
      bgcolor: darken(theme.palette.common.white, 0.02),
    },
    '&:first-of-type': {
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
      borderRight: 'none',
    },
    '&:last-of-type': {
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    },
  } as const);

export default function AdditionalFilterToggle<T, S>({
  filters,
  handleFilterChange,
  title,
}: BaseFilterProps<T, S> & { title?: string }) {
  return (
    <Stack>
      {title && (
        <Typography mb={1} variant="body2">
          {title}
        </Typography>
      )}
      <Stack direction="row" width={1}>
        {filters.map((filter) => (
          <FormControlLabel
            key={filter.key}
            label={
              <Typography variant="body2" pr={2} noWrap>
                {filter.label}
              </Typography>
            }
            sx={labelStyle}
            control={
              <Checkbox
                checkedIcon={<CheckCircleOutlineIcon color="secondary" sx={{ fontSize: 18 }} />}
                icon={<Box display="none" />}
                disableRipple
                checked={filter.checked}
                onChange={(e) => handleFilterChange(e, filter.key)}
              />
            }
          />
        ))}
      </Stack>
    </Stack>
  );
}
