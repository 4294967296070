import { EmailTemplateVariables, TemplateVariablesResolver } from '@common/email-variables';
import { renderToString } from 'react-dom/server';
import { stringToHtmlTag } from 'utils';
import { sanitizeMentions } from 'components/Editor/utils/mentions';
import { VariableInfo } from 'broker/utils/email-utils/email-resolver-utils/template-variables-utils';
import { SmartVariable } from './SmartVariable';

const VARIABLE_DATA_SELECTOR = 'data-variable';

/**
 * This is the markup for "smart" non-editable resolution
 */
export function renderSmartObjectVariable<T>(
  variable: EmailTemplateVariables,
  resolverMap: TemplateVariablesResolver<T, VariableInfo>,
  context: T,
) {
  const { value } = resolverMap[variable](context);
  return renderToString(SmartVariable({ variable, value }));
}

/**
 * This revert is meant to strip the special markup from variables and smart variables
 * so the data that is saved eventually contains only the plain variable
 */
export function revertSmartObjectToVariable(content: string) {
  const div = stringToHtmlTag(content);

  // This part replaces the resolved-for-edit-mode *smart* variables
  div.querySelectorAll(`[${VARIABLE_DATA_SELECTOR}]`).forEach((variableElement) => {
    const variable = variableElement.getAttribute(VARIABLE_DATA_SELECTOR);
    if (!variable) {
      return;
    }

    if (!variableElement.parentElement) {
      return;
    }

    const span = stringToHtmlTag(`@${variable}`, 'span');
    variableElement.parentElement.replaceChild(span, variableElement);
  });

  // This part replaces the *regular* template variables
  return sanitizeMentions(div.innerHTML);
}
