import { Dispatch, SetStateAction } from 'react';
import { Route, Routes } from 'react-router-dom-latest';
import { Button, Divider, Stack } from '@common-components';
import { RowsPerPage } from 'hooks';
import { messages } from 'i18n';
import { Contact, ExtendedUserMarket, SubmissionMarket, UserMarket } from 'types';
import { FilterElementProps } from 'utils';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import NoItemsFound from 'broker/components/common/NoItemsFound';
import TablePagination from 'broker/components/common/TablePagination';
import MarketsTableView from 'broker/components/UserMarkets/components/MarketsTableView';
import { SelectedMarketsMap } from 'broker/components/UserMarkets/types';
import UserMarketDialog from 'broker/dialogs/UserMarketDialog';
import useIsToolboxOpen from 'broker/pages/SubmissionWorkspacePage/hooks/useIsToolboxOpen';

interface UserMarketsProps {
  coverageLineFilters: FilterElementProps[];
  isAllCoverageLineFilters: boolean;
  items: UserMarket[];
  submissionMarkets: SubmissionMarket[];
  isLoading: boolean;
  sortedAndFilteredItems: ExtendedUserMarket[];
  search: string;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<RowsPerPage>>;
  labelFilters: FilterElementProps[];
  onCreateMarket: () => void;
  onMarketsSelected?: (markets: UserMarket[], replace?: boolean) => void;
  onContactSelected?: (contact: Contact[]) => void;
  selectNewContact?: (contact: Contact, userMarketId: string) => void;
  selectedMarkets?: SelectedMarketsMap;
  marketsIdsInSubmission?: string[];
  onMarketRemoved?: (marketId: string) => void;
  selectedMarketsCount?: number;
  disableSelection?: boolean;
  incumbentMarketIds?: string[];
}

export default function UserMarkets({
  coverageLineFilters,
  isAllCoverageLineFilters,
  items,
  submissionMarkets,
  sortedAndFilteredItems,
  isLoading,
  search,
  onCreateMarket,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  labelFilters,
  onMarketsSelected,
  selectedMarkets,
  marketsIdsInSubmission,
  onMarketRemoved,
  disableSelection,
  incumbentMarketIds,
  selectNewContact,
}: UserMarketsProps) {
  const showItems = isLoading || items.length > 0;
  const isToolboxOpen = useIsToolboxOpen();

  const handleChange = (value: number) => {
    setPage(value);
  };

  const marketsSlice = sortedAndFilteredItems.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage);

  const checkedLabels = labelFilters.filter((label) => label.checked).map((label) => label.label);

  const onSelectMarket = (contacts: Contact[], market: UserMarket) => {
    if (onMarketsSelected) {
      onMarketsSelected([{ ...market, contacts }]);
    }
  };

  const onSelectionClick = (isMarketSelected: boolean, market: UserMarket) => {
    if (isMarketSelected && onMarketRemoved) {
      onMarketRemoved(market.id);
    } else {
      onSelectMarket(market.contacts, market);
    }
  };

  const handleContactClicked = (contact: Contact, selectedContacts: Contact[], market: UserMarket) => {
    if (!selectedContacts) {
      onSelectMarket([contact], market);
      return;
    }

    const clickedContactIndex = selectedContacts.findIndex((selectedContact) => selectedContact.id === contact.id);
    if (clickedContactIndex === -1) {
      onSelectMarket([...selectedContacts, contact], market);
    } else {
      const newSelectedContacts = [...selectedContacts];
      newSelectedContacts.splice(clickedContactIndex, 1);
      onSelectMarket(newSelectedContacts, market);
    }
  };

  return (
    <>
      <Stack height={1}>
        <Stack flex={1}>
          {showItems && (
            <MarketsTableView
              coverageLineFilters={coverageLineFilters}
              isAllCoverageLineFilters={isAllCoverageLineFilters}
              marketsIdsInSubmission={marketsIdsInSubmission}
              submissionMarkets={submissionMarkets}
              onSelectMarket={onSelectMarket}
              selectedMarkets={selectedMarkets}
              extendedMarkets={marketsSlice}
              isLoading={isLoading}
              searchInput={search}
              checkedLabels={checkedLabels}
              selectionMode
              onSelectionClick={onSelectionClick}
              handleContactClicked={handleContactClicked}
              disableSelection={disableSelection}
              incumbentMarketIds={incumbentMarketIds}
              selectNewContact={selectNewContact}
              bottomTableContent={
                <Stack>
                  {sortedAndFilteredItems.length > rowsPerPage && (
                    <>
                      <Divider />
                      <TablePagination
                        from={page * rowsPerPage - rowsPerPage + 1}
                        to={page * rowsPerPage}
                        outOf={sortedAndFilteredItems.length}
                        handleChange={handleChange}
                        count={Math.ceil(sortedAndFilteredItems.length / rowsPerPage)}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        overrideContainerStyle={{ borderRadius: 0, bgcolor: 'common.white' }}
                      />
                    </>
                  )}
                </Stack>
              }
              isCompact={isToolboxOpen}
            />
          )}

          {!showItems && (
            <NoItemsFound title={messages.marketsPage.emptyState}>
              <Button onClick={onCreateMarket}>{messages.marketsPage.emptyStateSubTitle}</Button>
            </NoItemsFound>
          )}
        </Stack>
      </Stack>
      <Routes>
        <Route
          path={BROKER_NESTED_ROUTES.MARKETS.MARKET}
          element={<UserMarketDialog isLocationStateRequired={false} />}
        />
      </Routes>
    </>
  );
}
