import { useCallback } from 'react';
import { useMatchNavigation } from 'hooks';
import { PartialSubmission } from 'types';
import { Nullable } from 'utils';
import { SubmissionStep } from './types';
import { getSubmissionFormStep } from './utils';

type StepGetter = (submission: PartialSubmission) => SubmissionStep | undefined;

const StepsConfig: Record<SubmissionStep, { getPrev: StepGetter; getNext: StepGetter }> = {
  [SubmissionStep.ReviewAgent]: {
    getPrev: () => undefined,
    getNext: () => SubmissionStep.PreForm,
  },
  [SubmissionStep.PreForm]: {
    getPrev: () => SubmissionStep.ReviewAgent,
    getNext: getSubmissionFormStep,
  },
  [SubmissionStep.StaticForm]: {
    getPrev: () => SubmissionStep.PreForm,
    getNext: () => SubmissionStep.RiskAssessment,
  },
  [SubmissionStep.DynamicForm]: {
    getPrev: () => SubmissionStep.PreForm,
    getNext: () => SubmissionStep.RiskAssessment,
  },
  [SubmissionStep.RiskAssessment]: {
    getPrev: getSubmissionFormStep,
    getNext: () => undefined,
  },
};

export interface SetNextStepsProps {
  navigateBack?: boolean;
  forceStep?: SubmissionStep;
}

export default function useSetNextStep(
  submission: Nullable<PartialSubmission>,
  setStep: (step: SubmissionStep) => void,
) {
  const step = useMatchNavigation(SubmissionStep);

  return useCallback(
    ({ navigateBack, forceStep }: SetNextStepsProps = { navigateBack: false, forceStep: undefined }) => {
      if (forceStep) {
        setStep(forceStep);
        return;
      }

      if (!step || !submission) {
        return;
      }

      const targetStep = navigateBack ? StepsConfig[step].getPrev(submission) : StepsConfig[step].getNext(submission);

      if (!targetStep) {
        return;
      }

      setStep(targetStep);
    },
    [step, setStep, submission],
  );
}
