import { Box } from '@common-components';
import { InformationTooltipType } from 'contexts/information-tooltip/types';
import { SubmissionTask } from 'enums';
import { useInformationTooltip } from 'hooks';
import { messages } from 'i18n';
import InformationTooltip from 'broker/components/InformationTooltip/InformationTooltip';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export const UploadFilesInfoTooltip = () => {
  const { shouldShow, hideInformationTooltip } = useInformationTooltip(InformationTooltipType.UploadFiles);
  const { partialSubmission } = useSubmissionsWorkspace();
  const { uploadFilesTooltipTitle, uploadFilesTooltipMessage } =
    messages.submissionWorkspace.preMarketing.tasks[SubmissionTask.RenewalDocs];

  if (!shouldShow) {
    return null;
  }

  return (
    <Box sx={{ position: 'absolute', right: '20px', top: '110px', backgroundColor: 'primary.main' }}>
      <InformationTooltip
        title={uploadFilesTooltipTitle}
        message={uploadFilesTooltipMessage}
        isOpen={!partialSubmission?.isCopilotEnabled}
        height={115}
        width={280}
        placement="left"
        onClose={hideInformationTooltip}
      />
    </Box>
  );
};
