import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';

interface AIGeneratedEmptyStateProps {
  title?: string;
  description?: string;
}
export function AIGeneratedEmptyState({
  title = messages.submissionWorkspace.aiGeneratedContentPopup.emptyState.title,
  description = messages.submissionWorkspace.aiGeneratedContentPopup.emptyState.description,
}: AIGeneratedEmptyStateProps) {
  return (
    <Stack flex={1} gap={1} alignItems="center" justifyContent="center">
      <Stack alignItems="center">
        <Typography color="text.disabled" variant="body2Bold">
          {title}
        </Typography>
        <Stack width={240} textAlign="center">
          <Typography color="text.disabled" variant="caption">
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
