import { ReactElement } from 'react';
import { EmailEditorDirtyGuard } from 'broker/components/EmailEditor/components/EmailEditorDirtyGuard';
import {
  EmailEditorProps,
  EmailEditorStoreProvider,
} from 'broker/components/EmailEditor/store/EmailEditorStoreProvider';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

interface SubmissionEmailEditorProps {
  emailEditorProps: Partial<EmailEditorProps>;
  children: ReactElement;
  isDirty?: boolean;
}

export default function SubmissionEmailEditor({ emailEditorProps, children, isDirty }: SubmissionEmailEditorProps) {
  const {
    partialSubmission,
    markets,
    quotes,
    layers,
    addSubmissionMarkets,
    updateSubmission,
    reFetchWorkspace,
    addSubmissionMarketRequests,
  } = useSubmissionsWorkspace();

  // The recipient group is a required in order for the editor to know upon initializing 'recipientGroupToDraft' whether
  // to initialize the recipientGroup in detached mode or not.
  if (!partialSubmission || !emailEditorProps.preSelectedRecipients) {
    return null;
  }

  return (
    <EmailEditorStoreProvider
      submissionState={{ submission: partialSubmission, markets, quotes, layers }}
      submissionActions={{ addSubmissionMarkets, updateSubmission, reFetchWorkspace, addSubmissionMarketRequests }}
      {...emailEditorProps}
    >
      <EmailEditorDirtyGuard isDirty={isDirty}>{children}</EmailEditorDirtyGuard>
    </EmailEditorStoreProvider>
  );
}
