import { Dlq } from 'enums';
import { DlqEvent, DlqRedriveOutput } from 'types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export class DlqClient extends CapitolaClient<DlqEvent> {
  public get resourceName(): string {
    return 'dlq-status';
  }

  public redriveMessages(dlq: Dlq): Promise<DlqRedriveOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'redrive',
      body: { dlq },
      fetchOptions: { throwOnError: true },
    });
  }
}
