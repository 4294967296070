import { Stack } from '@common-components';
import { Header } from './Header';
import { SmartReportsList } from './SmartReportsList';

export const SmartReportsToolbox = () => (
  <Stack overflow="hidden" height={1}>
    <Header />
    <SmartReportsList />
  </Stack>
);
