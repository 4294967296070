import { ReactNode } from 'react';
import { Typography } from '@common-components';

interface ContactDetailItemProps {
  existsInUserMarket: boolean;
  children: ReactNode;
  title: string;
}
export default function ContactDetailItem({ existsInUserMarket, title, children }: ContactDetailItemProps) {
  return (
    <Typography color={!existsInUserMarket ? 'grey.300' : 'text.secondary'} variant="body2" noWrap title={title}>
      {children}
    </Typography>
  );
}
