import { createContext, useContext } from 'react';
import { Nullable } from 'utils';

export interface AuthenticationState {
  isAuthenticated: boolean;
  isLoading: boolean;
  getIdToken: () => Promise<string>;
}

export const AuthenticationContext = createContext<Nullable<AuthenticationState>>(null);

export function useAuthenticationContext() {
  const authenticationContext = useContext(AuthenticationContext);
  if (authenticationContext === null) {
    throw new Error('token should be retrieved only after app set token for logged in user.');
  }
  return authenticationContext;
}
