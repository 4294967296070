import { Stack, Typography } from '@common-components';
import { QuoteFileIdType } from 'enums';
import { ExtendedMarketRequest } from 'types';
import { formatDistanceToNowStrict } from 'utils';
import MarketRequestItemLabels from 'broker/pages/SubmissionWorkspacePage/components/common/MarketRequestItem/MarketRequestItemLabels';
import MarketRequestMetaData from 'broker/pages/SubmissionWorkspacePage/components/common/MarketRequestItem/MarketRequestMetaData';
import { marketRequestsStatusConfig } from 'broker/pages/SubmissionWorkspacePage/market-request-status-config';
import { columnsConfig, MarketsTableColumns } from './config';
import QuoteButton from './QuoteButton';

interface MarketItemProps {
  extendedMarketRequest: ExtendedMarketRequest;
}

const getQuoteType = (extendedMarketRequest: ExtendedMarketRequest) => {
  if (extendedMarketRequest.quote?.policyFileIds?.length) {
    return QuoteFileIdType.Policy;
  }

  if (extendedMarketRequest.quote?.binderFileIds?.length) {
    return QuoteFileIdType.Binder;
  }

  if (extendedMarketRequest.quote) {
    return QuoteFileIdType.MarketQuote;
  }

  return null;
};

export default function MarketItem({ extendedMarketRequest }: MarketItemProps) {
  const quoteType = getQuoteType(extendedMarketRequest);

  return (
    <Stack direction="row" px={3} gap={3} alignItems="center">
      <Stack direction="row" alignItems="center" flex={columnsConfig[MarketsTableColumns.Market].flex}>
        <Stack gap={0.5}>
          <Typography variant="body1" fontWeight={600}>
            {extendedMarketRequest.market.marketName}
          </Typography>
          {(extendedMarketRequest.type || extendedMarketRequest.market.incumbentInfo.length > 0) && (
            <MarketRequestItemLabels marketRequest={extendedMarketRequest} />
          )}
        </Stack>
      </Stack>

      <Stack
        flex={columnsConfig[MarketsTableColumns.Status].flex}
        py={4}
        overflow="hidden"
        bgcolor={
          marketRequestsStatusConfig[extendedMarketRequest.status].getContextualColor?.(extendedMarketRequest) ??
          marketRequestsStatusConfig[extendedMarketRequest.status].color
        }
        alignItems="center"
        justifyContent="center"
        alignSelf="stretch"
      >
        <Typography variant="body1" fontWeight={600}>
          {marketRequestsStatusConfig[extendedMarketRequest.status].title}
        </Typography>
      </Stack>
      <Stack flex={columnsConfig[MarketsTableColumns.Info].flex} overflow="auto">
        <MarketRequestMetaData marketRequest={extendedMarketRequest} />
        <Typography variant="overline" color="text.secondary">
          {formatDistanceToNowStrict(extendedMarketRequest.market.updatedAt)}
        </Typography>
      </Stack>
      <Stack flex={columnsConfig[MarketsTableColumns.CTA].flex}>
        {quoteType && <QuoteButton quote={extendedMarketRequest.quote!} quoteType={quoteType} />}
      </Stack>
    </Stack>
  );
}
