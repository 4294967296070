import { useEffect, useState } from 'react';
import { Grid, Paper, Toolbar, Typography } from '@common-components';
import { useAuthenticationContext } from 'contexts';
import { messages } from 'i18n';
import ReadOnlyTextField from 'admin/components/ReadOnlyTextField';

export default function HomePage() {
  const { getIdToken } = useAuthenticationContext();
  const [idToken, setIdToken] = useState<string>('');
  const buildNumber = process.env.REACT_APP_BUILD_NUMBER ?? 'unknown';
  const gitHash = process.env.REACT_APP_GIT_HASH ?? 'unknown';

  useEffect(() => {
    const setToken = async () => {
      const token = await getIdToken();
      setIdToken(token);
    };

    setToken();
  }, [getIdToken]);

  return (
    <Paper sx={{ m: 1 }}>
      <Toolbar sx={{ p: 1 }}>
        <Typography variant="h6" id="tableTitle" component="div">
          {messages.admin.admin}
        </Typography>
      </Toolbar>

      <Grid sx={{ m: 1 }} container wrap="wrap" spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <ReadOnlyTextField label="Build Number" value={buildNumber} />
          <ReadOnlyTextField label="Git Hash" value={gitHash} />
          <ReadOnlyTextField label="Id Token" isPassword value={idToken} />
        </Grid>
      </Grid>
    </Paper>
  );
}
