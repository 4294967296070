import { FileSyncStatus } from 'enums';
import { messages } from 'i18n';
import { SyncMetadata } from 'types';
import { getDisplayDateWithHoursAmPm } from './date-utils';

export const getFileSyncStatus = (item: SyncMetadata): FileSyncStatus | undefined => {
  if (item.syncMetadata?.syncedDate) {
    return FileSyncStatus.Synced;
  }

  if (item.syncMetadata?.syncRequestDate) {
    return FileSyncStatus.Syncing;
  }
  return undefined;
};
export const syncBadgeTooltip = (item: SyncMetadata, syncedSystem?: string) => {
  if (item.syncMetadata?.syncedDate) {
    return messages.sync.syncedToOn(syncedSystem ?? '', getDisplayDateWithHoursAmPm(item.syncMetadata?.syncedDate));
  }

  if (item.syncMetadata?.syncRequestDate) {
    return messages.sync.syncRequestedToOn(
      syncedSystem ?? '',
      getDisplayDateWithHoursAmPm(item.syncMetadata?.syncRequestDate),
    );
  }
  return '';
};
