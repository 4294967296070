import { useState } from 'react';
import { AIFeature, QuoteFileIdType, SubmissionStatus } from 'enums';
import { useToast } from 'hooks';
import { messages } from 'i18n';
import { attachmentPointText } from 'utils';
import { DialogContent, DialogHeader } from 'components/Dialog';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import SideBySide from 'broker/pages/SubmissionWorkspacePage/components/Layout/SideBySide/SideBySide';
import { QuoteStatusLocationState } from 'broker/pages/SubmissionWorkspacePage/dialogs/QuoteStatusModal/utils';
import { useStatusChangeDialog } from 'broker/pages/SubmissionWorkspacePage/dialogs/SubmissionStatusChangeDialog/useStatusChangeDialog';
import { useWorkspaceName } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useComparison } from './useComparison';
import useQuoteModalState from './useQuoteModalState';

interface ContentProps extends BaseRoutedDialogContentProps {
  locationState: QuoteStatusLocationState;
  aiFeature: AIFeature;
}

export default function Content({ ariaLabelId, onClose, locationState, aiFeature }: ContentProps) {
  const { markets, layers, submission, bindQuote, issueQuote } = useSubmissionsWorkspace();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showStatusChangeDialog } = useStatusChangeDialog();

  const workspaceName = useWorkspaceName();
  const state = useQuoteModalState(locationState);
  const { selectedQuote, fileType, policyDetails, flowCommission } = state;
  const { uploadedFileIds } = locationState;

  const { showToast } = useToast();

  useComparison({ quoteId: selectedQuote.id, uploadedFileIds, aiFeature });

  const addStatus = async () => {
    setIsSubmitting(true);
    try {
      if (fileType === QuoteFileIdType.Binder) {
        await bindQuote(selectedQuote.id, uploadedFileIds, policyDetails, flowCommission);
        showToast('success', {
          message: messages.quoteStatusModal.bound.successMessage(selectedQuote.marketName!),
        });
        showStatusChangeDialog({
          current: submission!.status,
          newStatus: SubmissionStatus.Issuing,
        });
      } else if (fileType === QuoteFileIdType.Policy) {
        await issueQuote(selectedQuote.id, uploadedFileIds, policyDetails, flowCommission);
        showToast('success', {
          message: messages.quoteStatusModal.binderPolicyIssued.successMessage(selectedQuote.marketName!),
        });
        showStatusChangeDialog({
          current: submission!.status,
          newStatus: SubmissionStatus.Issued,
        });
      }
      onClose();
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const selectedLayer = selectedQuote.layer;

  const layerTitle = attachmentPointText(selectedLayer?.attachmentPoint);

  const statusFileMismatch = () => {
    if (state?.fileType === QuoteFileIdType.Binder) {
      showToast('info', {
        message: messages.quoteStatusModal.bound.missMatchMessage(selectedQuote.marketName!),
      });
    } else if (fileType === QuoteFileIdType.Policy) {
      showToast('info', {
        message: messages.quoteStatusModal.binderPolicyIssued.missMatchMessage(selectedQuote.marketName!),
      });
    }
    onClose();
  };

  let modalMessages;

  if (fileType === QuoteFileIdType.Binder) {
    modalMessages = messages.quoteStatusModal.bound;
  } else if (aiFeature === AIFeature.BinderPolicyCompare) {
    modalMessages = messages.quoteStatusModal.binderPolicyIssued;
  } else {
    modalMessages = messages.quoteStatusModal.quotePolicyIssued;
  }

  if (!markets || !layers || !submission) {
    return null;
  }

  return (
    <>
      <DialogHeader
        id={ariaLabelId}
        title={`${modalMessages.title(layerTitle)} - ${workspaceName}`}
        onClose={onClose}
      />
      <DialogContent disablePaddings>
        <SideBySide
          item1={{
            header: modalMessages.sideBySide.headerItem1(selectedQuote.marketName!),
            fileIds:
              aiFeature === AIFeature.BinderPolicyCompare
                ? selectedQuote.binderFileIds
                : selectedQuote.marketQuoteFileIds,
          }}
          item2={{
            header: modalMessages.sideBySide.headerItem2(selectedQuote.marketName!),
            fileIds: uploadedFileIds,
          }}
          cancelButton={{
            children: modalMessages.sideBySide.cancelButton,
            onClick: statusFileMismatch,
          }}
          proceedButton={{
            children: modalMessages.sideBySide.proceedButton,
            onClick: addStatus,
            loading: isSubmitting,
          }}
        />
      </DialogContent>
    </>
  );
}
