import { OrganizationType } from 'enums';
import { messages } from 'i18n';

const organizationTypeConfig: Record<OrganizationType, string> = {
  [OrganizationType.Capitola]: messages.roles.organizationTypes.capitola,
  [OrganizationType.Broker]: messages.roles.organizationTypes.broker,
  [OrganizationType.Carrier]: messages.roles.organizationTypes.carrier,
  [OrganizationType.Retailer]: messages.roles.organizationTypes.retailer,
  [OrganizationType.Insured]: messages.roles.organizationTypes.insured,
  [OrganizationType.Issuer]: messages.roles.organizationTypes.issuer,
};

export const getOrganizationType = (organizationType: OrganizationType) => organizationTypeConfig[organizationType];
