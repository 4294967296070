export enum MarketsTableColumns {
  Market = 'market',
  Status = 'status',
  Info = 'info',
  CTA = 'cta',
}

export const columnsConfig = {
  [MarketsTableColumns.Market]: {
    key: MarketsTableColumns.Market,
    title: 'Market',
    flex: '0 0 220px',
  },
  [MarketsTableColumns.Status]: {
    key: MarketsTableColumns.Status,
    title: 'Status',
    flex: '0 0 220px',
  },
  [MarketsTableColumns.Info]: {
    key: MarketsTableColumns.Info,
    title: 'Info',
    flex: 1,
  },
  [MarketsTableColumns.CTA]: {
    key: MarketsTableColumns.CTA,
    title: '',
    flex: '0 0 80px',
  },
};
