import { useState } from 'react';
import { DialogContent } from '@common-components';
import { useBoolean, useCompareQuotes } from 'hooks';
import { messages } from 'i18n';
import { Layer, Quote } from 'types';
import SideBySide from 'broker/pages/SubmissionWorkspacePage/components/Layout/SideBySide';
import { useCreateAIThread } from 'broker/pages/SubmissionWorkspacePage/hooks';
import QuoteDetails from './components/QuoteDetails';
import QuoteHeader from './components/QuoteHeader';

interface QuotesSideBySideProps {
  quotes: Quote[];
  layer: Layer;
  onCancelClicked: () => void;
  onProceedClicked: (quote: Quote) => void;
  isSubmitting: boolean;
}

export default function QuotesSideBySide({
  quotes,
  layer,
  onCancelClicked,
  onProceedClicked,
  isSubmitting,
}: QuotesSideBySideProps) {
  const [selectedQuote, setSelectedQuote] = useState<Quote>();
  const [item1ShowDetails, { toggle: toggleItem1ShowDetails }] = useBoolean();
  const [item2ShowDetails, { toggle: toggleItem2ShowDetails }] = useBoolean();

  const { compareQuotes } = useCompareQuotes();

  useCreateAIThread(() =>
    compareQuotes({
      quoteIds: quotes.map((quote) => quote.id),
    }),
  );

  const onSelectQuote = (quote: Quote) => {
    setSelectedQuote((prev) => {
      if (prev?.id === quote.id) {
        return undefined;
      }
      return quote;
    });
  };

  return (
    <DialogContent
      sx={{
        bgcolor: 'grey.100',
        height: 1,
        p: 0,
        flex: 1,
        overflowY: 'auto',
      }}
    >
      <SideBySide
        item1={{
          header: (
            <QuoteHeader
              quote={quotes[0]}
              layer={layer}
              onSelect={() => onSelectQuote(quotes[0])}
              isSelected={selectedQuote?.id === quotes[0].id}
              toggle={toggleItem1ShowDetails}
              showDetails={item1ShowDetails}
            />
          ),
          details: <QuoteDetails quote={quotes[0]} layer={layer} />,
          showDetails: item1ShowDetails,
          fileIds: quotes[0].marketQuoteFileIds,
        }}
        item2={{
          header: (
            <QuoteHeader
              quote={quotes[1]}
              layer={layer}
              onSelect={() => onSelectQuote(quotes[1])}
              isSelected={selectedQuote?.id === quotes[1].id}
              toggle={toggleItem2ShowDetails}
              showDetails={item2ShowDetails}
            />
          ),
          details: <QuoteDetails quote={quotes[1]} layer={layer} />,
          showDetails: item2ShowDetails,
          fileIds: quotes[1].marketQuoteFileIds,
        }}
        proceedButton={{
          children: messages.compareQuotesModal.sideBySide.proceedButton(selectedQuote?.marketName),
          onClick: () => onProceedClicked(selectedQuote!),
          disabled: !selectedQuote,
          loading: isSubmitting,
        }}
        cancelButton={{
          children: messages.compareQuotesModal.sideBySide.cancelButton,
          onClick: onCancelClicked,
        }}
      />
    </DialogContent>
  );
}
