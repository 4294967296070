import { FileType } from 'enums';
import { NavigateOptions } from 'broker/hooks';

export interface FileTypeConfigProp {
  title: string;
  actionTitle: string;
  navigationOptions?: NavigateOptions;
  route?: string;
}

// Define `ProposalFileTypes` to include only the specific types you care about
export const proposalFileTypes = [FileType.CapitolaQuote, FileType.QuoteComparison, FileType.MarketingReport] as const;

// Create a type from `proposalFileTypes` for use in `Record`
export type ProposalFileType = (typeof proposalFileTypes)[number];
export type FileTypeConfig = Record<ProposalFileType, FileTypeConfigProp>;
