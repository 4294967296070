import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const DEFAULT_CONFIG = {
  timeout: 0,
  ignoreInitialCall: true,
};

/**
 * This effect was taken from https://github.com/samanmohamadi/use-debounced-effect
 * It is like useEffect but with a minimal time between evaluations
 * @param config - timeout - minimum time between effect reevaluations, ignoreInitialCall - whether to execute the first call
 * @param callback - the evaluation method
 * @param deps - the dependencies that triggers reevaluation
 */
export function useDebouncedEffect(
  callback: EffectCallback,
  config: { timeout: number; ignoreInitialCall: boolean } | number,
  deps: DependencyList = [],
) {
  let currentConfig;
  if (typeof config === 'object') {
    currentConfig = {
      ...DEFAULT_CONFIG,
      ...config,
    };
  } else {
    currentConfig = {
      ...DEFAULT_CONFIG,
      timeout: config,
    };
  }
  const { timeout, ignoreInitialCall } = currentConfig;
  const data = useRef<{ firstTime: boolean; clearFunc: (() => void) | void }>({
    firstTime: true,
    clearFunc: () => {},
  });
  useEffect(() => {
    const { firstTime, clearFunc } = data.current;

    if (firstTime && ignoreInitialCall) {
      data.current.firstTime = false;
      return () => {};
    }

    const handler = setTimeout(() => {
      if (clearFunc && typeof clearFunc === 'function') {
        clearFunc();
      }
      data.current.clearFunc = callback();
    }, timeout);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout, ...deps]);
}
