import { notificationAssigneeTypeConfig } from '@common/config';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { Box, Chip, ClickAwayListener, IconButton, Stack, Tooltip, Typography } from '@common-components';
import { CalendarToday, Done, Edit } from '@icons';
import { NotificationStatus } from 'enums';
import { useBoolean, useFormProvider, useMutateNotification, useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { Notification } from 'types';
import { getTimezoneLessDisplayDate, truncateMultiLineText } from 'utils';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { urgencyConfig } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Tasks/config';
import { schema, TaskDataProps } from './config';
import TaskItemDatePicker from './TaskItemDatePicker';
import TaskItemMenuActions from './TaskItemMenuActions';
import { useGetTaskItemConfig } from './useGetTaskItemConfig';

interface TaskItemProps extends Notification {
  isDisabled?: boolean;
}

export default function TaskItem({
  title,
  type,
  status,
  dueDate,
  entityId,
  level,
  assigneeType,
  id,
  isDisabled,
}: TaskItemProps) {
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useOptionsMenu();
  const [isSubmitting, { on: startSubmit, off: stopSubmit }] = useBoolean();
  const [isEditingTitle, { off: stopEdit, on: startEdit }] = useBoolean(false);
  const taskItemConfig = useGetTaskItemConfig({ entityId, notificationType: type });

  const { methods } = useFormProvider<TaskDataProps>({
    schema,
    defaultValues: { title },
  });

  const handleTaskClick = () => {
    if (isDisabled) {
      return;
    }
    taskItemConfig.cta.onClick();
  };

  const { updateNotification } = useMutateNotification();

  const handleChangeDate = async (date: Date | null) => {
    startSubmit();
    await updateNotification.mutateAsync({ id, data: { dueDate: date?.toISOString() } });
    stopSubmit();
  };

  const handleStatusChange = async (s: NotificationStatus) => {
    startSubmit();
    await updateNotification.mutateAsync({ id, data: { status: s } });
    stopSubmit();
  };

  const handleTitleChange: SubmitHandler<TaskDataProps> = async (data) => {
    stopEdit();
    if (data.title === '' || data.title === title) {
      methods.reset();
      return;
    }
    startSubmit();
    await updateNotification.mutateAsync({ id, data: { title: data.title } });
    stopSubmit();
  };

  const isInactive = isSubmitting || isDisabled || status === NotificationStatus.Completed;

  return (
    <>
      <Stack
        p={2}
        direction="row"
        alignItems="center"
        gap={2}
        bgcolor={status === NotificationStatus.New ? urgencyConfig[level].color : 'common.white'}
      >
        <Stack width={1} gap={1} overflow="hidden">
          <ClickAwayListener
            onClickAway={() => {
              if (!isEditingTitle) {
                return;
              }
              methods.handleSubmit(handleTitleChange)();
            }}
          >
            <Tooltip
              disableFocusListener
              tooltipContent={
                <FormProvider {...methods}>
                  <Box
                    p={1}
                    pb={0}
                    width={400}
                    onClick={(event) => event.stopPropagation()} // Add this to prevent the click from propagating
                  >
                    <FormTextField maxRows={6} minRows={6} fullWidth multiline autoFocus name="title" id="title" />
                  </Box>
                </FormProvider>
              }
              open={isEditingTitle}
            >
              <Box
                pr={4}
                position="relative"
                onClick={() => {
                  if (isInactive) {
                    return;
                  }
                  startEdit();
                }}
                sx={{
                  cursor: isInactive ? 'default' : 'text',
                  ...(!isInactive && {
                    '&:hover': { '& > div': { visibility: 'visible' } },
                  }),
                }}
              >
                <Typography
                  title={isDisabled ? messages.general.taskIsDisabled : title}
                  variant="body2Bold"
                  color="text.primary"
                  sx={{ ...truncateMultiLineText(3) }}
                >
                  {title}
                </Typography>
                {status === NotificationStatus.New && (
                  <Box position="absolute" right={0} top={0} visibility="hidden">
                    <Edit
                      sx={{
                        color: 'text.secondary',
                        fontSize: 12,
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Tooltip>
          </ClickAwayListener>
          {status === NotificationStatus.New && (
            <Chip
              label={taskItemConfig.cta.text}
              variant="outlined"
              color="secondary"
              onClick={handleTaskClick}
              startIcon={taskItemConfig.cta.startIcon}
              rounded
              disabled={isDisabled}
              disabledTooltipContent={messages.general.taskIsDisabled}
            />
          )}

          <Stack direction="row" gap={0.5}>
            <Chip
              color={taskItemConfig.isSOP ? 'purple.700' : 'secondary'}
              textColor={taskItemConfig.isSOP ? 'common.white' : 'text.primary'}
              size="small"
              label={taskItemConfig.type}
            />
            <Chip color="secondary" size="small" label={notificationAssigneeTypeConfig[assigneeType].text} />
            <Chip
              color="secondary"
              size="small"
              startIcon={CalendarToday}
              label={getTimezoneLessDisplayDate(dueDate || new Date())}
              dropdown
              onClick={openMenu}
            />
          </Stack>
        </Stack>

        {status === NotificationStatus.New ? (
          <IconButton
            tooltipContent={messages.general.markAsComplete}
            variant="text"
            size="small"
            color="secondary"
            icon={Done}
            loading={isSubmitting}
            onClick={() => handleStatusChange(NotificationStatus.Completed)}
          />
        ) : (
          <TaskItemMenuActions loading={isSubmitting} onChangeStatus={handleStatusChange} />
        )}
      </Stack>
      {isMenuOpen && (
        <TaskItemDatePicker
          anchorEl={anchorEl}
          isMenuOpen={isMenuOpen}
          closeMenu={closeMenu}
          selectedDate={dueDate ? new Date(dueDate) : null}
          setSelectedDate={handleChangeDate}
        />
      )}
    </>
  );
}
