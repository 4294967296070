import { Box, InputLabel as MuiInputLabel, Stack, Typography, TypographyProps } from '@common-components';
import { messages } from 'i18n';
import { LabelStyles } from './styles';

interface InputLabelProps {
  id?: string;
  error: boolean;
  label: string;
  optional?: boolean;
  htmlFor: string;
  hideOptionalLabel?: boolean;
  typographyProps?: TypographyProps;
}

export default function InputLabel({
  id,
  error,
  label,
  htmlFor,
  optional = false,
  hideOptionalLabel = false,
  typographyProps,
}: InputLabelProps) {
  return (
    <MuiInputLabel id={id} error={error} sx={LabelStyles} shrink htmlFor={htmlFor}>
      <Box>
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Typography variant="caption" color="textSecondary" {...typographyProps}>
            {label}
          </Typography>
          {optional && !hideOptionalLabel && (
            <Typography variant="caption" color="textSecondary">
              {messages.general.optional}
            </Typography>
          )}
        </Stack>
      </Box>
    </MuiInputLabel>
  );
}
