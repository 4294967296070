import { useEffect } from 'react';
import { messages } from 'i18n';
import { InsuranceProduct } from 'types';
import useProductFilter from './useProductFilter';

interface useIsAdmittedFilterProps {
  products: InsuranceProduct[];
  productsLoaded: boolean;
  paginationReset: () => void;
}

const isAdmittedFilterOptions = [
  messages.addProductsPage.additionalFilters.isAdmitted.admitted,
  messages.addProductsPage.additionalFilters.isAdmitted.nonAdmitted,
];

export function useIsAdmittedFilter({ products, productsLoaded, paginationReset }: useIsAdmittedFilterProps) {
  const isAdmittedFilter = useProductFilter({
    baseFiltersList: [],
    paginationReset,
  });

  const setIsAdmittedFilters = isAdmittedFilter.setFilters;

  useEffect(() => {
    if (!productsLoaded || products.length === 0) {
      return;
    }

    const isAdmittedFilterSetter = (isAdmittedList: string[]) => {
      setIsAdmittedFilters((prev) =>
        isAdmittedList.map((label) => ({
          key: label,
          checked: prev.find((item) => item.key === label)?.checked ?? false,
          label,
        })),
      );
    };

    isAdmittedFilterSetter(isAdmittedFilterOptions);
  }, [productsLoaded, products, setIsAdmittedFilters]);

  return isAdmittedFilter;
}
