/* eslint-disable no-undef */
export async function copyToClipboard(text: string) {
  await navigator.clipboard.writeText(text);
}

export async function copyHtmlToClipboard(html: string) {
  await navigator.clipboard.write([
    new ClipboardItem({
      'text/html': new Blob([html], { type: 'text/html' }),
      'text/plain': new Blob([html], { type: 'text/plain' }), // This is a fallback
    }),
  ]);
}
