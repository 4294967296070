import { debounce, isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Chip, Collapse, Stack, Typography } from '@common-components';
import { PartialSubmission } from 'types';
import { userFullName } from 'utils';
import { WorkspaceTabsRoutesValues } from 'broker/broker-routes';
import { SubmissionViewMode } from 'broker/components/SubmissionsView/utils';
import { submissionStatusConfig } from 'broker/configuration-mappers/submission-status-config';
import { useGetSubmissionCreationEmail } from 'broker/pages/SubmissionWorkspacePage/hooks';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import NavigationTabs from './NavigationTabs';
import SubmissionDetails from './SubmissionDetails';
import { SubmissionHeaderMenu } from './SubmissionHeaderMenu';

interface HeaderProps {
  activeTab: WorkspaceTabsRoutesValues;
  partialSubmission: PartialSubmission;
}

export default function Header({ activeTab, partialSubmission }: HeaderProps) {
  const { submissionCreationEmail } = useGetSubmissionCreationEmail();
  const submissionName = partialSubmission.insuredName || submissionCreationEmail?.subject || '';
  const { innerPageState } = useUiStoreState();

  const [isHovered, setIsHovered] = useState(false);

  const hoverInHandler = debounce(() => setIsHovered(true), 400);

  const hoverOutHandler = debounce(() => setIsHovered(false), 2000);

  const handleMouseEnter = useCallback(() => {
    hoverOutHandler.cancel();
    hoverInHandler();
  }, [hoverInHandler, hoverOutHandler]);

  const handleMouseLeave = useCallback(() => {
    hoverOutHandler();
  }, [hoverOutHandler]);

  const isTitleCollapsed = !isEmpty(innerPageState) ? !isHovered : false;

  return (
    <Stack
      bgcolor="common.white"
      px={3}
      pt={2}
      width={1}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" width={1} mb={2}>
        <Stack direction="column" flex={1} maxWidth={980}>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="h3" component="h1" color="typography.contrast">
              {submissionName}{' '}
              {partialSubmission?.insuredStructuredAddress?.state &&
                `(${partialSubmission?.insuredStructuredAddress?.state})`}
            </Typography>
            <Chip
              color={submissionStatusConfig[partialSubmission.status].bgColor}
              size="small"
              label={submissionStatusConfig[partialSubmission.status].text(SubmissionViewMode.General)}
              processing={submissionStatusConfig[partialSubmission.status].inProgressStatus}
            />
          </Stack>

          {/* Add hover event handlers to the Collapse component */}
          <Collapse in={!isTitleCollapsed} timeout={{ enter: 200, exit: 500 }}>
            {!isEmpty(partialSubmission.coverageLines) && <SubmissionDetails partialSubmission={partialSubmission} />}
            <Typography variant="caption" color="typography.nonEssential">
              {partialSubmission.user && `${userFullName(partialSubmission.user)}, `}{' '}
              {partialSubmission.organizationName}
            </Typography>
          </Collapse>
        </Stack>
        <SubmissionHeaderMenu submissionCreationEmail={submissionCreationEmail} submission={partialSubmission} />
      </Stack>

      <Collapse in={isEmpty(innerPageState)}>
        <NavigationTabs activeTab={activeTab} />
      </Collapse>
    </Stack>
  );
}
