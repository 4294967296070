import { maxBy } from 'lodash';
import { PartialSubmission, Quote, Submission } from 'types';

export const isSubmissionLimitReached = (submission: Submission) =>
  !!submission.limit && submission.limit - submission.reachedLimit <= 0;

export const didQuotesReachLimit = (quotes: Quote[], limit: number) => {
  if (!quotes.length) {
    return false;
  }
  const topLayerQuote = maxBy(quotes, (q) => q.layer.attachmentPoint || 0);
  return (topLayerQuote?.limit || 0) + (topLayerQuote?.layer.attachmentPoint || 0) >= limit;
};

export function isDynamicApplication(partialSubmission: PartialSubmission) {
  return partialSubmission.heraldData && !partialSubmission.heraldData.isArchived;
}
