import { useCallback } from 'react';
import {
  Box,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@common-components';
import { lineColor, lineSize, stepConfig } from './config'; // Importing from the config file
import { FormStepIcon } from './FormStepIcon'; // The FormStepIcon component you previously built
import { FormStepIconProps, MainAreaDimensionsState } from './types';

interface StepProps {
  label: string;
  subLabel?: string;
  hasError?: boolean;
  hasRequired?: boolean;
  isCompleted?: boolean;
}

interface VerticalFormStepperProps {
  steps: StepProps[]; // The list of sections
  activeStep: number; // The index of the active section
  onClick: (index: number) => void; // The handler for clicking on a step
  mainAreaDimensionsState?: MainAreaDimensionsState;
  sx?: SxProps<Theme>;
}

export default function VerticalFormStepper({
  steps,
  activeStep, // Index of the current active step (section)
  onClick, // Called when a step is clicked
  sx,
  mainAreaDimensionsState = MainAreaDimensionsState.Large,
}: VerticalFormStepperProps) {
  const theme = useTheme();
  const downXl = useMediaQuery(theme.breakpoints.down('xl'));

  // Determines if the stepper should be in a compact mode
  let isCompactStepper = false;
  if (mainAreaDimensionsState === MainAreaDimensionsState.Small) {
    isCompactStepper = true;
  } else if (downXl && mainAreaDimensionsState === MainAreaDimensionsState.Medium) {
    isCompactStepper = true;
  }

  const getFormStepIconWrapper = useCallback((props: FormStepIconProps) => <FormStepIcon {...props} />, []);

  return (
    <Stepper
      nonLinear
      connector={
        <Box
          sx={{
            width: lineSize,
            ml: 1,
            height: 1,
            bgcolor: lineColor,
          }}
        />
      }
      sx={{
        py: 1,
        height: steps.length * 60, // Adjust height dynamically based on number of steps
        alignItems: 'flex-start',
        ...sx,
      }}
      activeStep={activeStep} // This controls the active section highlight
      orientation="vertical"
    >
      {steps.map((step, index) => {
        const isActive = activeStep === index;

        // Use stepConfig for bgColor
        let bgColor;
        if (step.hasError) {
          bgColor = stepConfig.error[isActive ? 'active' : 'inactive'].bg;
        } else if (step.hasRequired) {
          bgColor = stepConfig.required[isActive ? 'active' : 'inactive'].bg;
        } else if (step.isCompleted) {
          bgColor = stepConfig.completed[isActive ? 'active' : 'inactive'].bg;
        } else {
          bgColor = stepConfig.default[isActive ? 'active' : 'inactive'].bg;
        }

        return (
          <Step key={step.label}>
            <StepButton
              disableRipple
              color="inherit"
              onClick={() => onClick(index)} // Set active section on click
            >
              <StepLabel
                error={step.hasError}
                sx={{ p: 0 }}
                StepIconComponent={(props) =>
                  getFormStepIconWrapper({
                    ...props,
                    label: step.label,
                    subLabel: step.subLabel,
                    isCompactStepper,
                    hasRequired: step.hasRequired,
                    isCompleted: step.isCompleted,
                  })
                }
              >
                <Box
                  px={0.5}
                  borderRadius={1}
                  hidden={isCompactStepper}
                  bgcolor={bgColor}
                  color={isActive ? 'common.white' : 'grey.600'}
                >
                  <Typography noWrap variant="captionBold">
                    {step.label}
                  </Typography>
                </Box>
                {step.subLabel && (
                  <Box position="absolute" top={28} left={46} hidden={isCompactStepper}>
                    <Typography noWrap variant="caption" color="grey.600">
                      {step.subLabel}
                    </Typography>
                  </Box>
                )}
              </StepLabel>
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
}
