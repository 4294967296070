import { SlackChannels } from 'enums';
import { logger } from 'utils';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export interface SlackMessageCreate {
  message: string;
  channels: SlackChannels[];
}

export class SlackClient extends CapitolaClient<{}> {
  public get resourceName(): string {
    return 'slack';
  }

  public async postMessage(data: SlackMessageCreate): Promise<{} | null> {
    try {
      return await this.custom({
        httpMethod: HttpMethods.POST,
        path: 'post-message',
        fetchOptions: { throwOnError: true },
        body: { ...data },
      });
    } catch (e) {
      logger.log('error', { message: 'Error while slack post message', error: e });
    }
    return null;
  }
}
