import { v4 as uuidv4 } from 'uuid';
import { Client, ClientOptions, FetchOptions } from 'clients/base';
import { WebsocketSubscriberIdSessionStorageKey } from 'server-streaming';
import { AnyObject, BulkCreateResponse, List } from 'types';
import { HttpMethods } from 'utils/http';

export interface CapitolaFetchOptions extends Partial<FetchOptions> {
  subPath?: string;
}

export interface CustomProps {
  httpMethod: HttpMethods;
  path: string;
  query?: AnyObject;
  body?: AnyObject;
  fetchOptions?: Partial<FetchOptions>;
}

// eslint-disable-next-line import/prefer-default-export
export abstract class CapitolaClient<T, ListItem = T> extends Client {
  constructor({ baseUrl, getBearerToken, onError, prefix = 'api/v1' }: ClientOptions) {
    super({
      baseUrl,
      getBearerToken,
      onError,
      prefix,
    });
  }

  public async fetch(
    url: string,
    { headers, ...options }: RequestInit = {},
    fetchOptions: FetchOptions = {},
  ): Promise<unknown | null> {
    const subscriberId = sessionStorage.getItem(WebsocketSubscriberIdSessionStorageKey);
    const requestHeaders = subscriberId
      ? { ...headers, 'subscriber-id': subscriberId, 'message-guid': uuidv4() }
      : headers;
    return super.fetch(url, { headers: requestHeaders, ...options, credentials: 'include' }, fetchOptions);
  }

  public async create<C extends {}>(
    body: C,
    { subPath, ...fetchOptions }: CapitolaFetchOptions = {},
  ): Promise<T | null> {
    const url = subPath ? this.buildUrl(subPath) : this.buildUrl();

    const result = await this.fetch(
      url,
      {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
      fetchOptions,
    );

    return result === null ? null : (result as T);
  }

  public async destroy(identifier: string, fetchOptions: Partial<FetchOptions> = {}): Promise<void> {
    const url = this.buildUrl(identifier);

    await this.fetch(
      url,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
      { passthroughStatusCodes: [404], ...fetchOptions },
    );
  }

  public async retrieve(
    identifier: string | Partial<T>,
    { subPath, ...fetchOptions }: CapitolaFetchOptions = {},
  ): Promise<T | null> {
    // Supporting two types of retrieve endpoints:
    // 1. Retrieve by id, where the api route is in the structure of '.../resource-name/:id'
    // 2. Retrieve a single result by a query, where the api route is in the structure of: '.../resource-name/sub-path?key=value
    const url = typeof identifier === 'string' ? this.buildUrl(identifier) : this.buildUrl(subPath, identifier);

    const result = await this.fetch(
      url,
      {
        method: 'GET',
      },
      fetchOptions,
    );

    return result === null ? null : (result as T);
  }

  public async search<Q extends AnyObject>(
    query: Q,
    fetchOptions: Partial<FetchOptions> = {},
  ): Promise<List<ListItem> | null> {
    const url = this.buildUrl(null, query);

    const result = await this.fetch(
      url,
      {
        method: 'GET',
      },
      fetchOptions,
    );

    return result === null ? null : (result as List<ListItem>);
  }

  public async update(
    identifier: string,
    body: Partial<T>,
    fetchOptions: Partial<FetchOptions> = {},
  ): Promise<T | null> {
    const url = this.buildUrl(identifier);

    const result = await this.fetch(
      url,
      {
        body: JSON.stringify(body),
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
      fetchOptions,
    );

    return result === null ? null : (result as T);
  }

  public async custom<R>({
    httpMethod,
    path,
    query,
    body,
    fetchOptions = {},
  }: {
    httpMethod: HttpMethods;
    path: string;
    query?: AnyObject;
    body?: AnyObject;
    fetchOptions?: Partial<FetchOptions>;
  }): Promise<R | null> {
    const url = this.buildUrl(path, query);

    const result = await this.fetch(
      url,
      {
        body: body ? JSON.stringify(body) : undefined,
        method: httpMethod,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
      fetchOptions,
    );

    return result === null ? null : (result as R);
  }

  public async bulkCreate<C extends Record<string, string | File>>(data: C, fetchOptions: Partial<FetchOptions> = {}) {
    const formData = new FormData();
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, item] of Object.entries(data)) {
      formData.append(key, item);
    }
    const url = this.buildUrl('bulk-create');

    const result = await this.fetch(
      url,
      {
        body: formData,
        method: 'POST',
        headers: {},
      },
      fetchOptions,
    );

    return result === null ? null : (result as BulkCreateResponse<T>);
  }
}
