import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { AIFeature } from 'enums';
import { FeatureState, MultiAIFeaturesContextProps } from './types';

const MultipleAIFeaturesContext = createContext<MultiAIFeaturesContextProps | undefined>(undefined);

export const MultipleAIFeaturesContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<Partial<Record<AIFeature, FeatureState>>>({});

  const setFeatureState = useCallback(
    (feature: AIFeature, newStateSetter: (currentState: FeatureState | undefined) => Partial<FeatureState>) => {
      setState((prevState) => ({
        ...prevState,
        [feature]: {
          ...prevState[feature],
          ...newStateSetter(prevState[feature]),
        },
      }));
    },
    [],
  );

  const contextValue = useMemo(
    () => ({
      state,
      setFeatureState,
    }),
    [state, setFeatureState],
  );

  return <MultipleAIFeaturesContext.Provider value={contextValue}>{children}</MultipleAIFeaturesContext.Provider>;
};

export const useMultipleAIFeaturesContext = () => {
  const context = useContext(MultipleAIFeaturesContext);
  if (!context) {
    throw new Error('useMultipleAIFeaturesContext must be used within a MultipleAIFeaturesContext');
  }
  return context;
};
