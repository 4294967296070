// This hook disable react query from refetching on window focus
import { focusManager } from '@tanstack/react-query';
import { useEffect } from 'react';

export function useDisableRefetchOnWindowFocus(shouldDisable = true) {
  useEffect(() => {
    focusManager.setFocused(shouldDisable ? false : undefined);
    return () => focusManager.setFocused(undefined);
  }, [shouldDisable]);
}
