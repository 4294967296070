import { FocusEvent, forwardRef, KeyboardEvent, ReactNode, useImperativeHandle, useRef, useState } from 'react';
import { ChevronRight } from '@icons';
import { Box, Menu } from 'components/mui-index';

import { NewMenuItem } from './NewMenuItem';
import { BaseMenuItemProps } from './types';

export interface NestedMenuItemProps extends BaseMenuItemProps {
  parentMenuOpen: boolean;
  children?: ReactNode;
  tabIndex?: number;
  menuPosition?: 'left' | 'right';
}

export const NestedMenuItem = forwardRef<HTMLLIElement | null, NestedMenuItemProps>((props, ref) => {
  const {
    parentMenuOpen,
    label,
    endAdornment = ChevronRight,
    startAdornment,
    children,
    tabIndex: tabIndexProp,
    menuPosition = 'right',
    color,
  } = props;

  const menuItemRef = useRef<HTMLLIElement | null>(null);
  useImperativeHandle(ref, () => menuItemRef.current!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const containerRef = useRef<HTMLDivElement | null>(null);

  const menuContainerRef = useRef<HTMLDivElement | null>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsSubMenuOpen(true);
  };
  const handleMouseLeave = () => {
    setIsSubMenuOpen(false);
  };

  // Check if any immediate children are active
  const isSubmenuFocused = () => {
    const active = containerRef.current?.ownerDocument.activeElement ?? null;
    return Array.from(menuContainerRef.current!.children).some((child) => child === active);
  };

  const handleFocus = (e: FocusEvent<HTMLElement>) => {
    if (e.target === containerRef.current) {
      setIsSubMenuOpen(true);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      return;
    }

    if (isSubmenuFocused()) {
      e.stopPropagation();
    }

    const active = containerRef.current?.ownerDocument.activeElement;

    if (e.key === 'ArrowLeft' && isSubmenuFocused()) {
      containerRef.current?.focus();
    }

    if (e.key === 'ArrowRight' && e.target === containerRef.current && e.target === active) {
      const firstChild = menuContainerRef.current?.children[0] as HTMLDivElement;
      firstChild?.focus();
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;

  // Root element must have a `tabIndex` attribute for keyboard navigation
  let tabIndex;
  if (!props.disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
  }

  return (
    <Box
      ref={containerRef}
      onFocus={handleFocus}
      tabIndex={tabIndex}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
    >
      <NewMenuItem
        ref={menuItemRef}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        label={label}
        color={color}
      />

      <Menu
        // Set pointer events to 'none' to prevent the invisible Popover div
        // from capturing events for clicks and hovers
        style={{ pointerEvents: 'none' }}
        PaperProps={{ sx: { minWidth: 280 } }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          horizontal: menuPosition === 'right' ? 'right' : 'left',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: menuPosition === 'right' ? 'left' : 'right',
          vertical: 'top',
        }}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false);
        }}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
          {children}
        </div>
      </Menu>
    </Box>
  );
});
