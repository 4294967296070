import { Team } from 'types';
import InternalLink from 'components/InternalLink';
import ADMIN_ROUTES from 'admin/admin-routes';

export interface TeamLinkProps {
  team: Pick<Team, 'id' | 'name'>;
}

export default function TeamLink({ team }: TeamLinkProps) {
  return <InternalLink to={ADMIN_ROUTES.getTeamById(team.id)}>{team.name}</InternalLink>;
}
