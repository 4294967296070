import { useQuery } from '@tanstack/react-query';
import { NylasUserClient } from 'clients';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
import { CreateNylasUserToken, CreateNylasUserTokenResponse, NylasAuthenticationUrl, NylasUser } from 'types';
import { MutateProps, UseQueryBaseRetrieveOptions } from './query/types';
import { useMutate, useQueryMutation } from './query/useMutate';
import { defaultQueryOptions, useQueryRetrieve } from './query/useQuery';

export enum NylasAuthTrigger {
  DisconnectedEmailGuard = 'DisconnectedEmailGuard',
  EmailIntegrationToggle = 'EmailIntegrationToggle',
  EmailIntegrationBanner = 'EmailIntegrationBanner',
  NylasAuthenticationPage = 'NylasAuthenticationPage',
}

interface RetrieveNylasAuthenticationUrlOptions
  extends Omit<UseQueryBaseRetrieveOptions<NylasAuthenticationUrl>, 'id'> {
  trigger: NylasAuthTrigger;
}
export function useRetrieveNylasAuthenticationUrl({
  queryOptions,
  enabled,
  trigger,
}: RetrieveNylasAuthenticationUrlOptions) {
  const client = useClient(NylasUserClient);

  // We can't use useQueryRetrieve here because the return value of this API call (NylasAuthenticationUrl)
  // is not aligned with the entity represented by this Client (NylasUser)
  return useQuery([QueryKeys.NylasUser, { id: 'auth-url', trigger }], () => client.getAuthenticationUrl(), {
    ...defaultQueryOptions,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled,
    // Do not auto retry on error. Needed since we set throwOnError to true
    retry: false,
    ...queryOptions,
  });
}

export function useRetrieveNylasAuthenticationStatus({
  queryOptions,
  enabled,
}: Omit<UseQueryBaseRetrieveOptions<NylasUser>, 'id'>) {
  return useQueryRetrieve({
    queryKey: QueryKeys.NylasUser,
    clientClass: NylasUserClient,
    id: 'auth-status',
    enabled,
    queryOptions: {
      ...queryOptions,
      // Do not auto retry on error. Needed since we set throwOnError to true
      retry: false,
    },
    retrieveOptions: {
      throwOnError: true,
    },
  });
}
export function useMutateNylasUser(options?: MutateProps) {
  const { destroy: revokeGrant } = useMutate<NylasUser, NylasUser, Partial<NylasUser>, NylasUserClient>({
    queryKey: QueryKeys.NylasUser,
    clientClass: NylasUserClient,
    cancelInvalidation: options?.cancelInvalidation,
    destroyOptions: { throwOnError: true },
  });

  const nylasUserClient = useClient(NylasUserClient);

  const createAccessToken = useQueryMutation<CreateNylasUserTokenResponse, CreateNylasUserToken>(
    ({ code }) => nylasUserClient.createAccessToken(code),
    QueryKeys.NylasUser,
    options?.cancelInvalidation,
  );

  return { createAccessToken, revokeGrant };
}
