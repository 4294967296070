import { SubmissionFileMetadata } from 'types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export default class SubmissionFileMetadataClient extends CapitolaClient<SubmissionFileMetadata> {
  public get resourceName(): string {
    return 'submission-file-metadata';
  }

  public async requestSync(submissionId: string, fileIds: string[]) {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'request-sync',
      body: {
        submissionId,
        fileIds,
      },
    });
  }

  public async completeSync(submissionId: string, fileIds: string[]) {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'complete-sync',
      body: {
        submissionId,
        fileIds,
      },
    });
  }
}
