import { EmailTemplateVariables, MENTION_PREFIX } from '@common/email-variables';
import { renderToStaticMarkup } from 'react-dom/server';
import { EmailMessageRecipientType, EmailTemplateType } from 'enums';
import { messages } from 'i18n';
import { getDisplayDateWithHoursAmPm } from 'utils';
import { EmailItemType } from 'broker/components/Emails/types';
import { UnifiedEmail } from 'broker/components/Emails/UnifiedEmail';

function resolveRecipient(unifiedEmail: UnifiedEmail, recipientType: EmailMessageRecipientType) {
  return (
    unifiedEmail.recipients
      .filter((recipient) => recipient.type === recipientType)
      .map((recipient) => recipient.address) ?? ''
  );
}

export const renderFollowupBody = (unifiedEmail: UnifiedEmail, preSelectedTemplateType?: EmailTemplateType): string => {
  const replyToName = unifiedEmail.replyToName ? ` ${unifiedEmail.replyToName}` : '';
  const to = resolveRecipient(unifiedEmail, EmailMessageRecipientType.To);
  const cc = resolveRecipient(unifiedEmail, EmailMessageRecipientType.Cc);

  return renderToStaticMarkup(
    <div>
      {/* In case were following up on an inbound email - it's a reply. If we don't have a pre-selected template, we only render the signature */}
      {unifiedEmail.emailType === EmailItemType.SubmissionInbound && !preSelectedTemplateType && (
        <>
          <br /> <br />
          {MENTION_PREFIX}
          {EmailTemplateVariables.signature}
        </>
      )}
      <br />
      <hr style={{ borderTop: '2px dotted #bbb' }} />
      <br />
      <div>{`${messages.sentEmailDialog.from}:${replyToName} <${unifiedEmail.senderEmail}>`}</div>
      <div>{`${messages.sentEmailDialog.date}: ${getDisplayDateWithHoursAmPm(unifiedEmail.createdAt)}`}</div>
      <div>{`${messages.sentEmailDialog.subject}: ${unifiedEmail.subject}`}</div>
      {to && <div>{`${messages.sentEmailDialog.to}: ${to}`}</div>}
      {cc && <div>{`${messages.sentEmailDialog.cc}: ${cc}`}</div>}
      <br />
      <br />
      <span dangerouslySetInnerHTML={{ __html: unifiedEmail.body }} />
    </div>,
  );
};
