import { Stack, StackProps, Typography } from '@common-components';
import { messages } from 'i18n';

interface CardTitleProps extends StackProps {
  isPrimaryBadge: boolean;
}

export default function CardTitle({ children, isPrimaryBadge }: CardTitleProps) {
  return (
    <Stack direction="row" alignItems="center" gap={0.4} minWidth={0}>
      <Typography variant="subtitle1" noWrap>
        {children}
      </Typography>
      {isPrimaryBadge && (
        <Typography variant="subtitle1" sx={{ pl: 2 }}>
          {messages.general.primary}
        </Typography>
      )}
    </Stack>
  );
}
