import { AutocompleteGetTagProps, Chip } from '@common-components';
import { Close as CloseIcon } from '@icons';

export const SelectionChip = ({
  label,
  onDelete,
  ...other
}: ReturnType<AutocompleteGetTagProps> & { label: string }) => (
  <Chip label={label} endIcon={CloseIcon} color="secondary" {...other} onClick={onDelete} />
);
