export enum SubmissionTerminationReason {
    CouldNotPursue = 'CouldNotPursue',
    CouldNotMatch = 'CouldNotMatch',
    UnacceptedPricing = 'UnacceptedPricing',
    UnacceptedCoverage = 'UnacceptedCoverage',
    UnacceptedService = 'UnacceptedService',
    UnacceptedGhosted = 'UnacceptedGhosted',
    AgentPulledBack = 'AgentPulledBack',
    AgentLostAccount = 'AgentLostAccount',
    AddedByMistake = 'AddedByMistake',
    AddedForTesting = 'AddedForTesting',
    Other = 'Other',
}
