import { HeraldApplicationResponse } from 'clients/types';
import { AIFeature } from 'enums';
import { usePagination } from 'hooks';
import { messages } from 'i18n';
import { InsuranceProduct } from 'types';
import ApiNotReadyBanner from 'broker/pages/SubmissionWorkspacePage/components/ApiNotReadyBanner';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { AIContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import useIsToolboxOpen from 'broker/pages/SubmissionWorkspacePage/hooks/useIsToolboxOpen';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { isIncompleteApiApplication } from 'broker/utils';
import { Footer } from './Components/Footer';
import HeaderBottomSideContent from './Components/HeaderBottomSideContent';
import { Products } from './Components/Products';
import SubmissionRiskAssessment from './Components/SubmissionRiskAssessment';
import { useProductFilters } from './useProductFilters';
import { useProductsState } from './useProductsState';

export interface AddProductsProps {
  onProductsSelected: (products: InsuranceProduct[]) => Promise<void>;
  products: InsuranceProduct[];
  isLoading: boolean;
  heraldApplicationResponse?: HeraldApplicationResponse;
}

export default function AddProducts({
  onProductsSelected,
  products,
  isLoading,
  heraldApplicationResponse,
}: AddProductsProps) {
  const isToolboxOpened = useIsToolboxOpen();
  const { markets: submissionMarkets, partialSubmission } = useSubmissionsWorkspace();
  const { page, setPage, rowsPerPage, setRowsPerPage, paginationReset } = usePagination();

  const productsFilters = useProductFilters(products, isLoading, paginationReset);

  const { selectedProducts, onProductSelected, onProductRemoved } = useProductsState();

  return (
    <InnerPageLayout
      title={messages.addProductsPage.title}
      subTitle={messages.addProductsPage.subtitle}
      sx={{ paddingX: 0, paddingTop: 1, paddingBottom: 0 }}
      headerBottomContent={() => (
        <HeaderBottomSideContent isToolboxOpened={isToolboxOpened} productsFilters={productsFilters} />
      )}
      headerTopContent={isIncompleteApiApplication(partialSubmission) ? () => <ApiNotReadyBanner /> : undefined}
      headerSideContent={() => <SubmissionRiskAssessment submission={partialSubmission} />}
      footer={(innerPageProps: InnerPageProps) => (
        <Footer
          innerPageProps={innerPageProps}
          selectedProducts={selectedProducts}
          onProductsSelected={onProductsSelected}
          partialSubmission={partialSubmission}
        />
      )}
    >
      {() => (
        <AIContextProvider feature={AIFeature.ProductRecommendation}>
          <Products
            heraldApplicationResponse={heraldApplicationResponse}
            items={products}
            isLoadingProducts={isLoading}
            sortedAndFilteredItems={productsFilters.sortedAndFilteredItems}
            search={productsFilters.search}
            setPage={setPage}
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            selectedProducts={selectedProducts}
            onProductSelected={onProductSelected}
            onProductRemoved={onProductRemoved}
            submissionMarkets={submissionMarkets}
          />
        </AIContextProvider>
      )}
    </InnerPageLayout>
  );
}
