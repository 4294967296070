import { Link, Tooltip } from '@common-components';
import { CitationType, isEmailCitationType } from 'enums';
import { activeTabUrlParamValues, BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';
import useCitationLabel from 'broker/pages/SubmissionWorkspacePage/hooks/useCitationLabel';

interface AICitationLinkProps {
  sourceId: string;
  sourceType: CitationType;
  title: string;
}

export const AICitationLink = ({ sourceId, sourceType, title }: AICitationLinkProps) => {
  const updateQueryParam = useUpdateQueryParam();
  const citationLabel = useCitationLabel(sourceType, sourceId);

  const activeTab = isEmailCitationType(sourceType) ? activeTabUrlParamValues.EMAIL : activeTabUrlParamValues.FILES;
  const paramName = isEmailCitationType(sourceType) ? BrokerUrlParams.SELECTED_EMAIL : BrokerUrlParams.SELECTED_FILE;

  return (
    <Tooltip tooltipContent={title} placement="top-end">
      <Link
        underline="hover"
        ml={0.5}
        sx={{ cursor: 'pointer' }}
        onClick={() =>
          updateQueryParam({
            removeParams: [BrokerUrlParams.SELECTED_EMAIL, BrokerUrlParams.SELECTED_FILE],
            addParams: [
              { queryParam: BrokerUrlParams.ACTIVE_TAB, value: activeTab },
              {
                queryParam: paramName,
                value: sourceId,
              },
            ],
          })
        }
      >
        ({citationLabel})
      </Link>
    </Tooltip>
  );
};
