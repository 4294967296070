import { Box, IconButton } from '@common-components';
import { ArrowDownward } from '@icons';

interface ScrollFormIndicatorProps {
  handleScrollDown: () => void;
}
export default function ScrollFormIndicator({ handleScrollDown }: ScrollFormIndicatorProps) {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 20,
        left: '50%',
        zIndex: (theme) => theme.zIndex.mobileStepper,
        transform: 'translateX(-50%)',
        borderRadius: '50%',
        boxShadow: 4,
      }}
    >
      <IconButton onClick={handleScrollDown} icon={ArrowDownward} color="primary" variant="contained" />
    </Box>
  );
}
