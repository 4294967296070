import { useMemo } from 'react';
import { messages } from 'i18n';
import { SubmissionMarketRequest } from 'types';
import { coverageLineConfig } from 'utils';
import { getDeclinationMetadataByType } from 'broker/configuration-mappers/declination-config';
import { informationRequestedConfig } from 'broker/configuration-mappers/information-request-config';
import { MarketingUpdateStatus } from 'broker/enums';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { MarketingUpdateStatusMap } from './utils';

export interface MarketingUpdateItem {
  marketName: string;
  marketingUpdateStatus: MarketingUpdateStatus;
  details: string;
  additionalInfo: string;
}
const { admitted, nonAdmitted, primary, excess, limit, totalPremium } = messages.marketingUpdate.marketingUpdateItem;

const getQuotedDetails = (marketRequest: SubmissionMarketRequest) => {
  switch (MarketingUpdateStatusMap[marketRequest.status]) {
    case MarketingUpdateStatus.Quoted:
    case MarketingUpdateStatus.Bound:
      if (!marketRequest.insuranceProduct || !marketRequest.quote) {
        return '';
      }
      return `${
        marketRequest.insuranceProduct.coverageLines
          ?.map((line) => coverageLineConfig[line.coverageLine].text)
          .join(', ') || ''
      }, ${marketRequest.insuranceProduct.isAdmitted ? admitted : nonAdmitted}, ${
        marketRequest.insuranceProduct.isPrimary ? primary : excess
      }${marketRequest.quote.limit ? `, ${limit}: $${marketRequest.quote.limit}` : ''}, ${totalPremium}: $${
        marketRequest.quote.premium + (marketRequest.quote.triaPremium ?? 0)
      }`;
    default:
      return '';
  }
};

const getDeclinationInfo = (marketRequest: SubmissionMarketRequest) =>
  marketRequest.declinationReasons
    .map(
      (reason) => `${getDeclinationMetadataByType(reason.type)?.reason}${reason.details ? `: ${reason.details}` : ''}`,
    )
    .join(', ');

const getInformationRequestedInfo = (marketRequest: SubmissionMarketRequest) => {
  const informationRequestsConfig = informationRequestedConfig(marketRequest);
  return marketRequest.informationRequested
    .map((request) =>
      request.type === 'Other'
        ? request.details
        : `${informationRequestsConfig[request.type]}${request.details ? `: ${request.details}` : ''}`,
    )
    .join(', ');
};

const getAdditionalInfo = (marketRequest: SubmissionMarketRequest) => {
  switch (MarketingUpdateStatusMap[marketRequest.status]) {
    case MarketingUpdateStatus.Declined:
      return getDeclinationInfo(marketRequest);
    case MarketingUpdateStatus.InReview:
      return getInformationRequestedInfo(marketRequest);
    default:
      return '';
  }
};

export const useGetMarketingUpdateItems = () => {
  const { markets } = useSubmissionsWorkspace();

  const marketingUpdateItems = useMemo(() => {
    const items: MarketingUpdateItem[] = [];

    markets.forEach((market) => {
      market.marketRequests.forEach((marketRequest) => {
        items.push({
          marketName: market.marketName,
          marketingUpdateStatus: MarketingUpdateStatusMap[marketRequest.status],
          details: getQuotedDetails(marketRequest),
          additionalInfo: getAdditionalInfo(marketRequest),
        });
      });
    });

    return items.reduce<Record<MarketingUpdateStatus, MarketingUpdateItem[]>>((acc, item) => {
      if (!acc[item.marketingUpdateStatus]) {
        acc[item.marketingUpdateStatus] = [];
      }
      acc[item.marketingUpdateStatus].push(item);
      return acc;
    }, {} as Record<MarketingUpdateStatus, MarketingUpdateItem[]>);
  }, [markets]);

  return marketingUpdateItems;
};
