import { Theme } from '@common-components';

export const FlexRowStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 3,
  my: 2,
} as const;

export function RecipientsContainerStyles(isMoreView = false) {
  return (theme: Theme) =>
    ({
      width: '100%',
      mt: 3,
      borderBottom: 1,
      borderBottomColor: 'divider',
      height: isMoreView ? undefined : theme.spacing(6.25),
      overflow: 'hidden',
    } as const);
}

export const RecipientGroupsStyles = {
  pt: 0.8,
  ml: 0,
  flexGrow: 1,
  alignSelf: 'start',
} as const;

export const ContentContainerStyles = {
  borderRadius: 2,
  backgroundColor: 'common.white',
  flex: '1 1 1px',
  boxShadow: 1,
} as const;
