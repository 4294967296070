import { debounce } from 'lodash';
import { FocusEvent, memo, useState } from 'react';
import { useDeepCompareEffect } from 'hooks';
import { FormAutocompleteProps } from 'components/hookFormComponents/FormAutocomplete/FormAutocomplete';
import { AutocompleteOption } from 'components/hookFormComponents/FormAutocomplete/utils';
import { SuggestionProps } from 'components/hookFormComponents/types';
import { Content } from './Content';

interface DynamicAutocompleteProps<T extends AutocompleteOption>
  extends Omit<FormAutocompleteProps<T>, 'options' | 'onBlur'> {
  getApiOptions: (searchTerm: string) => Promise<T[]>;
  selectedOption?: T;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLElement>) => boolean | undefined;
  suggestion?: SuggestionProps;
}

export function FormDynamicAutocomplete<T extends AutocompleteOption>({
  getApiOptions,
  selectedOption,
  suggestion,
  ...autocompleteProps
}: DynamicAutocompleteProps<T>) {
  const [inputChangedOnce, setInputChangedOnce] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValueState] = useState(selectedOption);
  const [options, setOptions] = useState<T[]>([]);

  const updateOptions = (optionsToUpdate: T[]) => {
    let internalOptions = optionsToUpdate;
    if (selectedOption && !optionsToUpdate.some((option) => option.value === selectedOption.value)) {
      internalOptions = [...internalOptions, selectedOption];
    }
    setOptions(internalOptions);
  };

  useDeepCompareEffect(() => {
    if (selectedOption && !options.some((option) => option.value === selectedOption.value)) {
      setOptions((prev) => [...prev, selectedOption]);
    }
  }, [selectedOption, options]);

  // Function to fetch options from the API
  const fetchOptions = async (searchTerm: any) => {
    try {
      setIsLoading(true);
      const optionsResult = await getApiOptions(searchTerm);
      setIsLoading(false);
      return optionsResult;
    } catch (error) {
      setIsLoading(false);
      return [];
    }
  };

  // Debounce the API call to avoid making requests for each keystroke
  const debouncedFetchOptions = debounce(async (searchTerm) => {
    const newOptions = await fetchOptions(searchTerm);
    updateOptions(newOptions);
  }, 500); // Adjust the delay as needed

  // Handle input change
  const handleInputChange = (newInputValue: any) => {
    // don't trigger api call if the initial value didn't change
    if (!inputChangedOnce) {
      if (newInputValue === initialValueState?.label) {
        return;
      }
      setInputChangedOnce(true);
    }
    debouncedFetchOptions(newInputValue); // Trigger API call with debounced function
  };

  return (
    <Content
      {...autocompleteProps}
      selectedOption={selectedOption}
      onInputChange={handleInputChange}
      options={options}
      isLoading={isLoading}
      suggestion={suggestion}
    />
  );
}

export default memo(FormDynamicAutocomplete) as typeof FormDynamicAutocomplete;
