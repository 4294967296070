export const Attach = (color?: string | undefined) => (
  <svg
    className="svg-icon"
    style={{
      width: '1.4em',
      height: '1.4em',
      verticalAlign: 'middle',
      fill: 'currentColor',
      overflow: 'hidden',
      color: color ?? 'inherit',
    }}
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M704 256v490.666667c0 94.293333-76.373333 170.666667-170.666667 170.666666s-170.666667-76.373333-170.666666-170.666666V213.333333c0-58.88 47.786667-106.666667 106.666666-106.666666s106.666667 47.786667 106.666667 106.666666v448a42.666667 42.666667 0 1 1-85.333333 0V256h-64v405.333333c0 58.88 47.786667 106.666667 106.666666 106.666667s106.666667-47.786667 106.666667-106.666667V213.333333c0-94.293333-76.373333-170.666667-170.666667-170.666666s-170.666667 76.373333-170.666666 170.666666v533.333334c0 129.706667 105.173333 234.666667 234.666666 234.666666s234.666667-104.96 234.666667-234.666666V256h-64z" />
  </svg>
);
