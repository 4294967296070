import { TeamClient } from 'clients';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
import { Team, TeamSearch } from 'types';
import { UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useMutate, useQueryMutation } from './query/useMutate';
import { useQueryRetrieve, useQuerySearch } from './query/useQuery';

export function useRetrieveTeam(props: UseQueryBaseRetrieveOptions<Team>) {
  const { id, queryOptions, enabled } = props;
  return useQueryRetrieve({
    queryKey: QueryKeys.Teams,
    clientClass: TeamClient,
    id,
    enabled,
    queryOptions: {
      // Default retrieve query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useSearchTeam(props?: UseQueryBaseSearchOptions<Team, TeamSearch>) {
  const { filter, queryOptions } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.Teams,
    clientClass: TeamClient,
    filter,
    queryOptions: {
      // Default search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateTeam() {
  const { create: createTeam, update: updateTeam } = useMutate<Team, Team, Partial<Team>, TeamClient>({
    queryKey: QueryKeys.Teams,
    clientClass: TeamClient,
    createOptions: { throwOnError: true },
    updateOptions: { throwOnError: true },
  });

  const teamClient = useClient(TeamClient);
  const clearTeamData = useQueryMutation<Team, string>((id) => teamClient.clearData(id), QueryKeys.Teams);

  return { createTeam, updateTeam, clearTeamData };
}
