import * as yup from 'yup';
import { messages } from 'i18n';

const { requiredField, invalidEmail } = messages.formMessages;

export interface InviteFormData {
  email: string;
  name: string;
}

export const schema = yup.object().shape({
  email: yup.string().trim().email(invalidEmail).required(requiredField),
  name: yup.string().trim().required(requiredField),
});
