import { Transition } from 'history';
import { useRef } from 'react';
import { useMount } from 'hooks';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import { useLocation } from 'broker/hooks';

export function useBeforeEmailEditorNavigatingInInnerRoute() {
  const location = useLocation();

  // capture the location of the editor to skip the dirty guard when navigating back to it
  const editorLocation = useRef<string>('');

  useMount(() => {
    editorLocation.current = location.pathname;
  });

  const isNavigatingToInnerEmailRoute = async (transition: Transition) => {
    // We know that the emails were sent, so we can skip the dirty guard
    // The fact that we are using a ref here makes sure we don't make wrong decisions due to the nature of react render cycles

    // Allow transition to routes that are internal to the email editor flow
    const routesWithoutDirtyGuard = [
      BROKER_NESTED_ROUTES.MODALS.CREATE_CUSTOMER_CONTACT,
      BROKER_NESTED_ROUTES.WORKSPACE.ADD_MARKETS,
      BROKER_NESTED_ROUTES.WORKSPACE.ADD_PRODUCTS,
      editorLocation.current,
    ];
    return routesWithoutDirtyGuard.some((route) => transition.location.pathname.includes(route));
  };

  return {
    isNavigatingToInnerEmailRoute,
  };
}
