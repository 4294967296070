import { ActionTypes, InformationTooltipState } from './types';

const informationTooltipReducer = (state: InformationTooltipState, action: ActionTypes) => {
  switch (action.type) {
    case 'SET_INFORMATION_TOOLTIP': {
      return {
        ...state,
        [action.payload.type]: action.payload.isShown,
      };
    }

    default:
      return state;
  }

  return state;
};

export default informationTooltipReducer;
