import { Stack, Tooltip, Typography } from '@common-components';
import { FileDownloadDone, Warning } from '@icons'; // Assuming Done is the checkmark icon
import { stepConfig, stepIconSize } from './config';
import { FormStepIconProps } from './types';

export function FormStepIcon(props: FormStepIconProps) {
  const { active, error, label, subLabel, isCompactStepper, hasRequired } = props;

  // Render function to avoid repetition
  function renderIcon(bgColor: string, iconColor: string, icon: JSX.Element | null) {
    return (
      <Tooltip
        tooltipContent={
          isCompactStepper ? (
            <Stack alignItems="flex-start">
              <Typography variant="captionBold" color="grey.600">
                {label}
              </Typography>
              {subLabel && (
                <Typography color="grey.600" variant="caption">
                  {subLabel}
                </Typography>
              )}
            </Stack>
          ) : (
            ''
          )
        }
        placement="right"
      >
        <Stack
          mr={0.5}
          width={stepIconSize}
          height={stepIconSize}
          alignItems="center"
          justifyContent="center"
          sx={{ position: 'relative' }}
        >
          <Stack
            sx={{
              width: stepIconSize,
              height: stepIconSize,
              borderRadius: '50%',
              bgcolor: bgColor,
              color: iconColor,
              boxShadow: active ? 2 : 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 2,
            }}
          >
            {icon}
          </Stack>
        </Stack>
      </Tooltip>
    );
  }

  // Determine the background and icon based on state using early returns
  if (error) {
    return renderIcon(
      stepConfig.error[active ? 'active' : 'inactive'].bg,
      stepConfig.error[active ? 'active' : 'inactive'].icon,
      <Warning sx={{ color: stepConfig.error[active ? 'active' : 'inactive'].icon, fontSize: 12 }} />,
    );
  }

  if (hasRequired) {
    return renderIcon(
      stepConfig.required[active ? 'active' : 'inactive'].bg,
      stepConfig.required[active ? 'active' : 'inactive'].icon,
      <Warning sx={{ color: stepConfig.required[active ? 'active' : 'inactive'].icon, fontSize: 12 }} />,
    );
  }

  return renderIcon(
    stepConfig.completed[active ? 'active' : 'inactive'].bg,
    stepConfig.completed[active ? 'active' : 'inactive'].icon,
    <FileDownloadDone sx={{ color: stepConfig.completed[active ? 'active' : 'inactive'].icon, fontSize: 12 }} />,
  );
}
