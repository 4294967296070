import { isEqual } from 'lodash';
import { useRef } from 'react';

export function useDeepCompareMemoize<T>(dependency: T) {
  const ref = useRef<T>();

  if (!isEqual(dependency, ref.current)) {
    ref.current = dependency;
  }

  return ref.current;
}
