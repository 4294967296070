import { Paper } from '@common-components';
import UserList from 'admin/components/UserList';

export default function UserListPage() {
  return (
    <Paper sx={{ mt: 0 }}>
      <UserList />
    </Paper>
  );
}
