import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';

export default function DataGridNoResults() {
  return (
    <Stack height="100%" order={1} alignItems="center" justifyContent="center" py={10}>
      <Typography sx={{ px: 3 }} variant="h5" color="textSecondary">
        {messages.general.noResults}
      </Typography>
    </Stack>
  );
}
