import { MarketIssuingCompanyClient } from 'clients/capitola/market-issuing-company';
import { QueryKeys } from 'enums';
import { CreateMarketIssuingCompany, MarketIssuingCompany } from 'types';
import { MutateProps } from './query/types';
import { useMutate } from './query/useMutate';

export function useMutateMarketIssuingCompany(options?: MutateProps) {
  const { create: createMarketIssuingCompany } = useMutate<
    MarketIssuingCompany,
    MarketIssuingCompany,
    CreateMarketIssuingCompany,
    MarketIssuingCompanyClient
  >({
    queryKey: QueryKeys.MarketIssuingCompany,
    clientClass: MarketIssuingCompanyClient,
    cancelInvalidation: options?.cancelInvalidation,
    createOptions: { throwOnError: true },
  });

  return { createMarketIssuingCompany };
}
