import { some } from 'lodash';
import { useState } from 'react';
import { NewMenu } from '@common-components';
import { OptionsMenuState } from 'hooks';
import { messages } from 'i18n';
import { Quote } from 'types';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { getQuoteMetaData } from 'broker/pages/SubmissionWorkspacePage/dialogs/CompareQuotesModal/utils';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';

interface CompareQuotesMenuProps {
  optionsMenu: OptionsMenuState;
  quotes: Quote[];
}
export default function CompareQuotesMenu({ optionsMenu, quotes }: CompareQuotesMenuProps) {
  const [selectedQuotes, setSelectedQuotes] = useState<Quote[]>([]);
  const navigate = useNavigate();
  const { compareQuotesUrl } = useWorkspaceUrls();

  const onToggleQuote = (quote: Quote) => {
    const index = selectedQuotes.findIndex((selectedQuote) => selectedQuote.id === quote.id);
    const newSelectedQuotes = [...selectedQuotes];
    if (index !== -1) {
      newSelectedQuotes.splice(index, 1);
      setSelectedQuotes(newSelectedQuotes);
    } else {
      if (newSelectedQuotes.length === 2) {
        newSelectedQuotes.splice(0, 1);
      }
      setSelectedQuotes([...newSelectedQuotes, quote]);
    }
  };

  return (
    <NewMenu
      optionsMenuState={optionsMenu}
      menuItems={[
        {
          key: 'compare-quotes-menu-popover',
          header: messages.compareQuotesModal.subMenu.title,
          subheader: messages.compareQuotesModal.subMenu.subTitle,
          selectionType: 'checkbox',
          items: quotes.map((quote) => {
            const { limitComponent } = getQuoteMetaData(quote, quote.layer!);

            return {
              label: quote.marketName!,
              subLabel: limitComponent + (!quote.layer.attachmentPoint ? ` ${messages.general.retention}` : ''),
              isSelected: some(selectedQuotes, { id: quote.id }),
              onClick: () => onToggleQuote(quote),
            };
          }),
          moreActions: {
            justifyContent: 'flex-end',
            items: [
              {
                text: messages.general.continue,
                variant: 'contained',
                disabled: selectedQuotes.length !== 2,
                onClick: () => {
                  navigate(compareQuotesUrl, {
                    routeKey: RouteKey.CompareQuotes,
                    state: {
                      quotes: selectedQuotes,
                      layer: selectedQuotes[0].layer,
                    },
                  });
                  optionsMenu.closeMenu();
                  setSelectedQuotes([]);
                },
              },
            ],
          },
        },
      ]}
    />
  );
}
