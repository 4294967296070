import { useEffect } from 'react';
import { SubmissionStatus } from 'enums';
import { HotjarEvents } from 'utils/hotjar-events';
import {
  submissionStatusConfig,
  visibleSubmissionStatuses,
} from 'broker/configuration-mappers/submission-status-config';
import useSubmissionFilter from './useSubmissionFilter';
import { SubmissionBoxStorageKeys, SubmissionsViewStorageKeys, SubmissionViewMode } from './utils';

const excludedStatuses = [SubmissionStatus.Issued, SubmissionStatus.Archived, SubmissionStatus.Closed];
const getBaseStatusFiltersList = (mode: SubmissionViewMode) =>
  visibleSubmissionStatuses
    .filter((status) => !submissionStatusConfig[status].preflight)
    .sort((a, b) => submissionStatusConfig[a].sortPriority - submissionStatusConfig[b].sortPriority)
    .map((status) => ({
      key: status,
      checked: mode === SubmissionViewMode.PreviewBox ? true : !excludedStatuses.includes(status),
      label: submissionStatusConfig[status].text(mode),
    }));

export function useStatusFilter({ mode, paginationReset }: { paginationReset: () => void; mode: SubmissionViewMode }) {
  const storageKey =
    mode === SubmissionViewMode.General ? SubmissionsViewStorageKeys.status : SubmissionBoxStorageKeys.status;

  const baseStatusFilters = getBaseStatusFiltersList(mode);

  const statusFilter = useSubmissionFilter({
    baseFiltersList: baseStatusFilters,
    storageKey,
    hotjarEvent: HotjarEvents.SubmissionsStatusFilter,
    paginationReset,
  });

  // First time the filters are loaded from the local storage, we need to make sure that all the status filters are present
  // This is relevant when we add a new status in the code that didn't exist before, thus missing in the local storage
  // By default, the new status filter will be turned off
  useEffect(() => {
    if (statusFilter.filters.length !== baseStatusFilters.length) {
      statusFilter.setFilters((prev) =>
        baseStatusFilters.map((status) => ({
          key: status.key,
          checked: prev.find((localStorageStatus) => localStorageStatus.key === status.key)?.checked ?? false,
          label: status.label,
        })),
      );
    }
  }, [statusFilter, baseStatusFilters]);

  return statusFilter;
}
