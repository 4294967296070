import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, Stack } from '@common-components';
import { PersonOutline as PersonIcon } from '@icons';
import { ContactRole, OrganizationType } from 'enums';
import { messages } from 'i18n';
import { FormFieldsSpacingHorizontal, FormFieldsSpacingVertical } from 'themes';
import { FormArrayFieldProps } from 'components/hookFormComponents/FormArrayFields/FormArrayFields';
import FormRadioButton from 'components/hookFormComponents/FormRadioButton';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { contactFieldsNames } from './utils';

export default function ContactFormFields({ getFieldName, autofocusFirstField, defaultValue }: FormArrayFieldProps) {
  const methods = useFormContext();
  const role = methods.watch(getFieldName(contactFieldsNames.role)) ?? defaultValue.role;
  function getHumanContactOption() {
    return defaultValue.organizationType === OrganizationType.Broker
      ? { value: ContactRole.Broker, label: messages.marketsPage.marketType.wholesaler }
      : { value: ContactRole.Underwriter, label: messages.general.underwriter };
  }

  useEffect(() => {
    methods.setValue(
      getFieldName(contactFieldsNames.role),
      /* if there is no email in first contact filed --> there are no contacts
       so the init value after organization change is human(wholesaler/underwriter) */
      methods.getValues().contacts[0].email === '' ? getHumanContactOption().value : role,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue.organizationType]);

  return (
    <Stack direction="row">
      <PersonIcon sx={{ color: 'grey.500', mt: 2, mr: 2 }} />
      <Grid columnSpacing={FormFieldsSpacingVertical} rowSpacing={FormFieldsSpacingHorizontal} container>
        <Grid item xs={12} mb={1}>
          <FormRadioButton
            label=""
            name={getFieldName(contactFieldsNames.role)}
            id={getFieldName(contactFieldsNames.role)}
            options={[
              getHumanContactOption(),
              { value: ContactRole.SubmissionBox, label: messages.general.submissionBox },
            ]}
          />
        </Grid>
        {role !== ContactRole.SubmissionBox ? (
          <>
            <FormTextField
              type="hidden"
              placeholder=""
              label=""
              name={getFieldName(contactFieldsNames.id)}
              id={getFieldName(contactFieldsNames.id)}
            />
            <Grid item xs={6}>
              <FormTextField
                autoFocus={autofocusFirstField}
                placeholder={messages.addContactToMarketModal.placeholders.firstName}
                label={messages.addContactToMarketModal.labels.firstName}
                name={getFieldName(contactFieldsNames.firstName)}
                id={getFieldName(contactFieldsNames.firstName)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                placeholder={messages.addContactToMarketModal.placeholders.lastName}
                label={messages.addContactToMarketModal.labels.lastName}
                name={getFieldName(contactFieldsNames.lastName)}
                id={getFieldName(contactFieldsNames.lastName)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                placeholder={messages.addContactToMarketModal.placeholders.email}
                label={messages.addContactToMarketModal.labels.email}
                name={getFieldName(contactFieldsNames.email)}
                id={getFieldName(contactFieldsNames.email)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                placeholder={messages.addContactToMarketModal.placeholders.phoneNumber}
                label={messages.addContactToMarketModal.labels.phoneNumber}
                name={getFieldName(contactFieldsNames.phoneNumber)}
                id={getFieldName(contactFieldsNames.phoneNumber)}
                optional
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                placeholder={messages.addContactToMarketModal.placeholders.title}
                label={messages.addContactToMarketModal.labels.title}
                name={getFieldName(contactFieldsNames.title)}
                id={getFieldName(contactFieldsNames.title)}
                optional
              />
            </Grid>
          </>
        ) : (
          <>
            <FormTextField
              type="hidden"
              placeholder=""
              label=""
              name={getFieldName(contactFieldsNames.id)}
              id={getFieldName(contactFieldsNames.id)}
            />
            <Grid item xs={6}>
              <FormTextField
                placeholder={messages.addContactToMarketModal.placeholders.email}
                label={messages.addContactToMarketModal.labels.email}
                name={getFieldName(contactFieldsNames.email)}
                id={getFieldName(contactFieldsNames.email)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
}
