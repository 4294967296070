import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom-latest';
import { ButtonBase, Stack } from '@common-components';
import { HomeOutlined } from '@icons';
import LoginControls from 'components/layout/header/LoginControls';
import { PORTAL } from 'broker/broker-routes';
import FlowTextLogo from './FlowTextLogo';

const headerSize = 40;
export default function RetailerTopBar() {
  const resolved = useResolvedPath(`/${PORTAL}`);
  const match = useMatch({ path: resolved.pathname, end: true });
  const navigate = useNavigate();

  return (
    <Stack
      height={headerSize}
      justifyContent="space-between"
      alignItems="center"
      bgcolor="purple.700"
      color="common.white"
      px={3}
      direction="row"
    >
      <Stack alignItems="center" height={1} gap={2} direction="row">
        <ButtonBase
          onClick={() => navigate(`/${PORTAL}`)}
          sx={{
            height: 1,
            width: headerSize,
            px: 1,
            ...(match && {
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: 4,
                backgroundColor: 'common.white',
              },
            }),
          }}
        >
          <HomeOutlined />
        </ButtonBase>
        <FlowTextLogo />
      </Stack>
      <LoginControls menuPosition="top" />
    </Stack>
  );
}
