import { FileType } from 'enums';
import { useToast } from 'hooks';
import { useMutateBoxItems, useReFetchBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { Submission } from 'types';
import { extractErrorMessage } from 'utils';
import { downloadBlob } from 'utils/files-utils';
import { generateBlobPdf } from './pdfHandler';
import { ConceptualTowerReportRow } from './types';

interface useTowerPdfProps {
  rowsData: ConceptualTowerReportRow[];
  submission: Submission;
  boldLastRow?: boolean;
}

export const useTowerPdf = ({ rowsData, submission, boldLastRow = true }: useTowerPdfProps) => {
  const { uploadFileWithMetadata } = useMutateBoxItems(true);
  const { reFetchFileItems } = useReFetchBoxItems();
  const { showToast } = useToast();

  const downloadReport = async () => {
    const pdfFileReport = generateBlobPdf(rowsData, boldLastRow);
    const fileName = `${messages.ConceptualTowerPage.filePrefixName}${Date.now()}.pdf`;

    downloadBlob(pdfFileReport, fileName);

    try {
      await uploadFileWithMetadata({
        parentFolderId: submission.boxFolderId,
        fileName,
        file: pdfFileReport,
        metadata: { fileType: FileType.ConceptualTower },
      });

      // don't deny proceeding to next step - the files explorer will refresh async
      await reFetchFileItems();
    } catch (e) {
      showToast('error', { message: extractErrorMessage(e) });
    }
  };

  return { downloadReport };
};
