import { Link, Stack, Typography } from '@common-components';
import { InfoOutlined } from '@icons';
import { messages } from 'i18n';

export default function Disclaimer() {
  const modalMessages = messages.autonomousFlow.settingsModal;

  return (
    <Stack direction="row" pl={2} py={1} bgcolor="blue.100" gap={1} pr={34}>
      <InfoOutlined color="primary" fontSize="small" />
      <Typography pt={0.25} variant="caption" color="typography.secondary">
        <Link href={`mailto:${messages.general.supportMail}`} target="_blank">
          {messages.general.contactUs}
        </Link>{' '}
        {modalMessages.disclaimer}
      </Typography>
    </Stack>
  );
}
