import { useState } from 'react';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import EmailStep from './EmailStep';
import FilesStep from './FilesStep';
import { ProposalFlowStep, UpdateProposalProps } from './types';

export default function ProposalFlowPage() {
  const [activeStep, setActiveStep] = useState<ProposalFlowStep>(ProposalFlowStep.Files);

  const [isDirty, setIsDirty] = useState(false);

  const { partialSubmission } = useSubmissionsWorkspace();

  const { updateSubmission } = useSubmissionsWorkspace();

  const {
    filesExplorer: { selectedFiles },
  } = useUiStoreState();

  const handleSaveProposal = async (props: UpdateProposalProps) => {
    await updateSubmission(partialSubmission!.id, {
      proposal: {
        ...props,
        lastSaved: new Date(),
      },
    });
  };

  const onStepChange = async (step: ProposalFlowStep, proposalData: UpdateProposalProps) => {
    await handleSaveProposal(proposalData);
    if (step === ProposalFlowStep.Email) {
      setIsDirty(true);
    }
    setActiveStep(step);
  };

  return activeStep === ProposalFlowStep.Files ? (
    <FilesStep
      selectedFiles={selectedFiles}
      submission={partialSubmission!}
      handleSaveProposal={handleSaveProposal}
      onStepChange={onStepChange}
      activeStep={activeStep}
      isDirty={isDirty}
      setIsDirty={setIsDirty}
    />
  ) : (
    <EmailStep
      selectedFiles={selectedFiles}
      submission={partialSubmission!}
      handleSaveProposal={handleSaveProposal}
      onStepChange={onStepChange}
      activeStep={activeStep}
      isDirty={isDirty}
      setIsDirty={setIsDirty}
    />
  );
}
