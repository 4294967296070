import { ReactNode } from 'react';
import { BoxSkeleton, Stack } from '@common-components';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { DoubleFieldStyle } from './styles';

export const DoubleFieldWrapper = ({ children }: { children: ReactNode }) => {
  const { isPending } = useEmailEditorState();

  return (
    <BoxSkeleton isLoading={isPending} sx={{ width: 1 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={DoubleFieldStyle}>
        {children}
      </Stack>
    </BoxSkeleton>
  );
};
