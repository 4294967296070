import { Auth0Provider } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import { useNavigate } from 'react-router-dom-latest';
import App from './App';
import { APP_ROUTES } from './app-routes';
import { AppContextProviders } from './AppContextProviders';
import BrowserRouter from './components/BrowserRouter';
import { useConfig } from './config';
import { useHotjarInitialization } from './hooks';

export default function Auth0App() {
  // NB: Auth0 redirects won't work unless src/index.tsx wraps the app with a browser router
  const navigate = useNavigate();
  const config = useConfig();

  const hotjarInitialized = useHotjarInitialization();

  // Initialize the redirect location to the home page.
  const redirectUri = window.location.origin + APP_ROUTES.GENERAL.HOME;

  /* On login, change the page address to remove `code` and `state` query string parameters
   */
  const onRedirectCallback = useCallback(
    (appState?: { returnTo?: string }): void => {
      const pathname = appState?.returnTo ?? `${window.location.pathname}${window.location.hash}`;
      navigate(pathname);
    },
    [navigate],
  );

  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      // The below prop is required to log out the user from all opened tabs when he logs out in one tab:
      // https://community.auth0.com/t/how-to-check-if-user-has-logged-out-in-another-tab/56324/4
      cacheLocation="localstorage"
    >
      <AppContextProviders isHotjarInitialized={hotjarInitialized}>
        <App />
      </AppContextProviders>
    </Auth0Provider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  /* NB: Material-UI transitions are
  currently incompatible with StrictMode; should be fixed in v5. */
  <BrowserRouter>
    <Auth0App />
  </BrowserRouter>,
);
