import { NotificationLevelType } from 'enums';

interface NotificationLevelTypeConfigProps {
  color: string;
}

export const notificationLevelConfig: Record<NotificationLevelType, NotificationLevelTypeConfigProps> = {
  [NotificationLevelType.Neutral]: {
    color: 'common.white',
  },
  [NotificationLevelType.Warning]: {
    color: 'warning.light',
  },
  [NotificationLevelType.Alert]: {
    color: 'error.light',
  },
};
