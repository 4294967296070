import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CapitolaClient } from 'clients';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
import { QueryCreateParams, QueryDestroyParams, QueryUpdateParams, UseQueryMutateOptions } from './types';

export const useQueryMutation = <Type, RequestParams extends {}>(
  func: (data: RequestParams) => Promise<Type | null>,
  queryKey: QueryKeys,
  cancelInvalidation?: boolean,
  onSuccess?: (response: Type | null) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation<Type | null, Error, RequestParams>(func, {
    onSuccess: async (response: Type | null) => {
      onSuccess?.(response);
      if (!cancelInvalidation) {
        await queryClient.invalidateQueries([queryKey]);
      }
    },
  });
};

export const useMutate = <Type, ListItem, CreateType extends {}, Client extends CapitolaClient<Type, ListItem>>({
  queryKey,
  cancelInvalidation,
  clientClass,
  createOptions,
  updateOptions,
  destroyOptions,
  onSuccess,
  onClientError,
}: UseQueryMutateOptions<Client, Type>) => {
  const client = useClient(clientClass, { onError: onClientError });

  const create = useQueryMutation<Type, QueryCreateParams<CreateType>>(
    ({ data, fetchOptions }) => client.create(data, fetchOptions || createOptions),
    queryKey,
    cancelInvalidation,
    onSuccess,
  );

  const update = useQueryMutation<Type, QueryUpdateParams<Type>>(
    ({ id, data, fetchOptions }) => client.update(id, data, fetchOptions || updateOptions),
    queryKey,
    cancelInvalidation,
    onSuccess,
  );

  const destroy = useQueryMutation<void, QueryDestroyParams>(
    ({ id, fetchOptions }) => client.destroy(id, fetchOptions || destroyOptions),
    queryKey,
    cancelInvalidation,
  );

  return { create, update, destroy, client };
};
