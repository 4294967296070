import { messages } from 'i18n';
import { ColumnConfigType, SUBMISSION_MARKET_COLUMNS } from './types';

export const marketRequestConfig: Record<SUBMISSION_MARKET_COLUMNS, ColumnConfigType> = {
  [SUBMISSION_MARKET_COLUMNS.Product]: {
    key: SUBMISSION_MARKET_COLUMNS.Product,
    title: messages.submissionWorkspace.marketRequestsWorkspace.columnTitles.product,
    width: 220,
    maxWidth: 220,
  },
  [SUBMISSION_MARKET_COLUMNS.Status]: {
    key: SUBMISSION_MARKET_COLUMNS.Status,
    title: messages.submissionWorkspace.marketRequestsWorkspace.columnTitles.status,
    width: 144,
    maxWidth: 144,
  },
  [SUBMISSION_MARKET_COLUMNS.Info]: {
    key: SUBMISSION_MARKET_COLUMNS.Info,
    title: messages.submissionWorkspace.marketRequestsWorkspace.columnTitles.info,
    flex: 5,
  },
  [SUBMISSION_MARKET_COLUMNS.Note]: {
    key: SUBMISSION_MARKET_COLUMNS.Note,
    title: messages.submissionWorkspace.marketRequestsWorkspace.columnTitles.note,
    flex: 2,
  },
};
