import { keyBy, merge, values } from 'lodash';
import { SubmissionMarket, UserMarket } from 'types';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';

export function getInitialEditorMode(isSingleRecipientMode: boolean, hasSelectedTemplate: boolean) {
  // If we have a single recipient, we start in edit mode by default, as there is no preview mode in this case
  if (isSingleRecipientMode) {
    return DraftEditorMode.EditForMarket;
  }

  // If there is no selected template type, we start in edit mode by default to allow the user to edit the empty email
  if (!hasSelectedTemplate) {
    return DraftEditorMode.EditForAll;
  }

  return DraftEditorMode.Preview;
}

// gets the available contacts for a user market from it's contacts, including contacts that exist in the submission market but didn't exist in the user market (these are contacts that have been removed, but we still want to show them in the email editor once we are in submission that contained them)
export function getAvailableContactsForUserMarket(userMarket: UserMarket, submissionMarkets: SubmissionMarket[]) {
  const submissionMarket = submissionMarkets.find((item) => userMarket.id === item.userMarketId);
  return values(merge(keyBy(userMarket.contacts, 'id'), keyBy(submissionMarket?.contacts || [], 'id')));
}
