import { EmailIntegrationProvider } from 'enums';

export const EmailIntegrationProviderMap: Record<EmailIntegrationProvider, string> = {
  [EmailIntegrationProvider.Aol]: 'Aol',
  [EmailIntegrationProvider.Office365]: 'Office365',
  [EmailIntegrationProvider.Gmail]: 'Gmail',
  [EmailIntegrationProvider.Icloud]: 'Icloud',
  [EmailIntegrationProvider.Hotmail]: 'Hotmail',
  [EmailIntegrationProvider.Exchange]: 'Microsoft Exchange',
  [EmailIntegrationProvider.Imap]: 'Imap',
  [EmailIntegrationProvider.Outlook]: 'Outlook',
  [EmailIntegrationProvider.Yahoo]: 'Yahoo',
};
