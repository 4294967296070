import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Card, CardActions, CardContent, InputAdornment } from '@common-components';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import FormTextField from 'components/hookFormComponents/FormTextField';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { FormData, formFieldsNames, schema } from './utils';

interface AddAttachmentPointCardProps {
  onCancel: () => void;
  existingXsAttachmentPoints: number[];
}

export default function AddAttachmentPointCard({ onCancel, existingXsAttachmentPoints }: AddAttachmentPointCardProps) {
  const { submission, createLayer, reFetchWorkspace } = useSubmissionsWorkspace();
  const [isSubmitting, { on: startSubmit, off: stopSubmit }] = useBoolean();

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { attachmentPoint: attachmentPointFieldName } = formFieldsNames;

  const { isValid } = methods.formState;

  const onCreateLayer = async (submissionId: string, attachmentPoint: number) =>
    createLayer({
      submissionId,
      attachmentPoint,
    });

  const isExistingAttachmentPoint = (attachmentPoint: string): boolean =>
    existingXsAttachmentPoints.includes(Number(attachmentPoint));

  const submitLayer: SubmitHandler<FormData> = async (data) => {
    if (!isExistingAttachmentPoint(data.attachmentPoint)) {
      startSubmit();
      await onCreateLayer(submission!.id, Number(data.attachmentPoint));
      await reFetchWorkspace();
      stopSubmit();
      onCancel();
    } else {
      methods.setError('attachmentPoint', { message: messages.submissionWorkspace.tower.attachmentPointAlreadyExists });
    }
  };

  return (
    <FormProvider {...methods}>
      <Card
        className="cap-add-attachment-point-card"
        variant="outlined"
        sx={{
          my: 1,
          overflow: 'visible',
        }}
      >
        <CardContent sx={{ px: 3, py: 1.5 }}>
          <FormTextField
            label={messages.submissionWorkspace.tower.attachmentPoint}
            name={attachmentPointFieldName}
            id={attachmentPointFieldName}
            fullWidth
            autoFocus
            startAdornment={<InputAdornment position="start">xs</InputAdornment>}
            isNumberFormat
            numberFormatProps={{
              placeholder: '$100,000',
              prefix: '$',
              min: 1,
              type: 'text',
              isNumericString: true,
            }}
            paddingBottom={0}
          />
        </CardContent>
        <CardActions
          sx={{
            justifyContent: 'space-between',
            py: 1.5,
            px: 3,
            alignContent: 'center',
            bgcolor: 'blueGrey.50',
          }}
        >
          <Button variant="text" onClick={onCancel} fullWidth>
            {messages.buttons.cancel}
          </Button>
          <Button
            loading={isSubmitting}
            disabled={!isValid}
            variant="contained"
            onClick={methods.handleSubmit(submitLayer)}
            fullWidth
          >
            {messages.buttons.create}
          </Button>
        </CardActions>
      </Card>
    </FormProvider>
  );
}
