import { CircularProgress, Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';
import RecipientGroupContacts from 'broker/components/RecipientGroupContacts';

export enum EmailStatus {
  Editing = 'Editing',
  Uploading = 'Uploading',
  Sending = 'Sending',
  Sent = 'Sent',
}

interface SentMessagesScreenProps {
  recipientGroups: RecipientGroup[];
  emailStatus: EmailStatus;
}

export default function SendingMessagesScreen({ recipientGroups, emailStatus }: SentMessagesScreenProps) {
  const { sentMessagesScreen } = messages.emailEditor;

  const loadingMessage =
    emailStatus === EmailStatus.Uploading
      ? sentMessagesScreen.uploadingFiles
      : sentMessagesScreen.sendingMessages(recipientGroups.length);

  return (
    <Stack sx={{ m: 'auto' }} alignItems="center">
      <CircularProgress color="secondary" size={80} sx={{ mb: 4 }} />
      <Typography variant="body1" color="common.black">
        {loadingMessage}
      </Typography>
      <Stack sx={{ mt: 6, mb: 5, mx: 5 }} direction="row" flexWrap="wrap" gap={2} justifyContent="center">
        {recipientGroups.map((group) => (
          <Stack key={group.id} direction="row">
            <RecipientGroupContacts recipientGroup={group} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
