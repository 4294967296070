import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { FilteredMarketsEmptyState } from './FilteredMarketsEmptyState';
import StartMarketingButton from './StartMarketingButton';

interface EmptyStateProps {
  emptyState: boolean;
  clearFilters?: () => void;
}

export default function EmptyState({ clearFilters, emptyState }: EmptyStateProps) {
  return (
    <Stack height={1} alignItems="center" justifyContent="center">
      {!emptyState ? (
        <Typography sx={{ px: 3 }} variant="h5" color="textSecondary">
          <FilteredMarketsEmptyState clearFilters={clearFilters} />
        </Typography>
      ) : (
        <>
          <Typography variant="h4" color="textSecondary" mb={2}>
            {messages.submissionWorkspace.emptyState.title}
          </Typography>
          <StartMarketingButton placement="bottom-right" buttonProps={{ variant: 'contained' }} />
        </>
      )}
    </Stack>
  );
}
