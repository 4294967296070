import { Dictionary } from 'lodash';
import { useState } from 'react';
import { Box, Button, Divider, Stack, Typography } from '@common-components';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import { Layer, Quote } from 'types';
import RemoveLayerDialog from 'broker/pages/SubmissionWorkspacePage/dialogs/RemoveLayerDialog';
import AddAttachmentPointCard from './AddAttachmentPointCard';
import LayerItem from './LayerItem';

interface LayersListProps {
  layers: Layer[];
  quotes: Dictionary<Quote[]>;
  submissionLimitReached: boolean | null;
  hideQuoteActions?: boolean;
}

export default function LayersList({ layers, quotes, submissionLimitReached, hideQuoteActions }: LayersListProps) {
  const [removeLayerDialog, { on: openRemoveLayerDialog, off: closeRemoveLayerDialog }] = useBoolean(false);

  const [selectedLayer, setSelectedLayer] = useState<Layer | undefined>(undefined);

  const [isAddingAttachmentPoint, { on: openAddAttachmentPointCard, off: closeAddAttachmentPointCard }] =
    useBoolean(false);

  const onOpenRemoveLayerDialog = (layerId: string) => {
    setSelectedLayer(layers.find((layer) => layer.id === layerId));
    openRemoveLayerDialog();
  };

  const xsAttachmentPoints = layers
    .map((layer) => layer.attachmentPoint)
    .filter((attachmentPoint): attachmentPoint is number => !!attachmentPoint);

  let topLayer;
  if (submissionLimitReached) {
    topLayer = (
      <Divider
        sx={{
          position: 'sticky',
          alignItems: 'flex-start',
          px: 2,
          py: 3,
          '&::before, &::after': {
            borderColor: 'divider',
          },
        }}
        key="top-layer-divider"
      >
        <Typography align="center" padding={2} color="text.disabled" variant="body2Bold" key="limit-reached-text">
          {messages.submissionWorkspace.tower.targetLimitReached}
        </Typography>
      </Divider>
    );
  } else if (isAddingAttachmentPoint) {
    topLayer = (
      <AddAttachmentPointCard
        key="add-attachment-point-form"
        onCancel={closeAddAttachmentPointCard}
        existingXsAttachmentPoints={xsAttachmentPoints}
      />
    );
  } else {
    topLayer = (
      <Box key="add-attachment-point-form" mb={1}>
        <Button fullWidth key="add-attachment-point-button" variant="outlined" onClick={openAddAttachmentPointCard}>
          {messages.submissionWorkspace.tower.addAttachmentPoint}
        </Button>
      </Box>
    );
  }

  return (
    <>
      <Stack flexDirection="column-reverse" px={2} py={2} height={1} overflow="auto" bgcolor="common.white">
        {[
          layers.map((layer, index) => (
            <LayerItem
              hideQuoteActions={hideQuoteActions}
              key={layer.id}
              layer={layer}
              layersLength={layers.length}
              index={index}
              quotes={quotes[layer.id] || []}
              setRemoveLayerDialogOpen={onOpenRemoveLayerDialog}
            />
          )),
          topLayer,
        ]}
      </Stack>
      <RemoveLayerDialog layer={selectedLayer!} open={removeLayerDialog} setClose={closeRemoveLayerDialog} />
    </>
  );
}
