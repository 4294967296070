import { isEmpty, startCase } from 'lodash';
import { Link } from '@common-components';
import { messages } from 'i18n';
import { activeTabUrlParamValues, BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { BulletedContent } from './BulletedContent';

export const EmailsSection = () => {
  const { enrichmentData } = useAIContext();

  const updateQueryParam = useUpdateQueryParam();

  if (isEmpty(enrichmentData?.inboundEmails) && !enrichmentData?.submissionCreationEmail) {
    return null;
  }

  const { submissionCreationEmail, inboundEmails } = enrichmentData!;

  const openEmail = (emailId?: string) => {
    if (!emailId) {
      return;
    }
    updateQueryParam({
      addParams: [
        { queryParam: BrokerUrlParams.ACTIVE_TAB, value: activeTabUrlParamValues.EMAIL },
        { queryParam: BrokerUrlParams.SELECTED_EMAIL, value: emailId },
      ],
    });
  };

  return (
    <BulletedContent
      level={1}
      content={`${startCase(messages.submissionWorkspace.aiGeneratedContentPopup.emailsText)}:`}
    >
      <BulletedContent
        level={2}
        content={
          <>
            {startCase(messages.submissionWorkspace.aiGeneratedContentPopup.submissionEmail)}:{' '}
            <Link onClick={() => openEmail(submissionCreationEmail?.id)} sx={{ cursor: 'pointer' }}>
              {submissionCreationEmail?.subject}
            </Link>
          </>
        }
      />
      {inboundEmails.map((email) => (
        <BulletedContent
          key={email.id}
          level={2}
          content={
            <>
              {startCase(messages.submissionWorkspace.aiGeneratedContentPopup.inboundEmail)}:{' '}
              <Link onClick={() => openEmail(email.id)} sx={{ cursor: 'pointer' }}>
                {email?.subject}
              </Link>
            </>
          }
        />
      ))}
    </BulletedContent>
  );
};
