import { useCallback, useEffect, useState } from 'react';
import { Editor } from 'tinymce';
import { useBoolean } from 'hooks';

export default function useEditorContentState(content: string) {
  const [editorContent, setEditorContent] = useState<string>(content);
  const [isDirty, { set: setIsDirty }] = useBoolean(false);

  useEffect(() => {
    setEditorContent(content);
    setIsDirty(false);
  }, [content, setIsDirty]);

  const onSetEditorContent = useCallback(
    (newContent: string, editor?: Editor) => {
      setEditorContent(newContent);

      // The initial dirty indication is managed by the onDirty event of the editor,
      // but after saving the changes we clear the dirty flag in the state, so the way to identify a subsequent change
      // is here, in the change event of the editor.
      // We have to use it in combination with the event because the dirty flag value is false on the first edit and only
      // true in the next change
      if (editor?.isDirty()) {
        setIsDirty(true);
      }
    },
    [setIsDirty],
  );

  return {
    editorContent,
    setEditorContent: onSetEditorContent,
    isDirty,
    setIsDirty,
  };
}
