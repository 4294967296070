import { useBoolean, useToast } from 'hooks';
import { messages } from 'i18n';
import { dialogTransitionDurationValue } from 'themes';
import { Layer } from 'types';
import { attachmentPointRemovedToast } from 'utils';
import { ConfirmationDialog } from 'components/Dialog';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

interface RemoveLayerDialogProps {
  layer: Layer;
  open: boolean;
  setClose: () => void;
}

export default function RemoveLayerDialog({ layer, open, setClose }: RemoveLayerDialogProps) {
  const { submissionWorkspace, buttons } = messages;
  const dialogMessages = submissionWorkspace.dialogs.removeExcessLayer;
  const [isLoading, { on: showLoader, off: hideLoader }] = useBoolean(false);

  const workspaceActions = useSubmissionsWorkspace();

  const { showDelayedToast } = useToast();

  const onDeleteLayer = async () => {
    showLoader();
    try {
      await workspaceActions?.deleteLayer(layer.id);

      // noinspection ES6MissingAwait
      showDelayedToast(
        'success',
        { message: attachmentPointRemovedToast(layer?.attachmentPoint!) },
        dialogTransitionDurationValue,
      );
    } finally {
      setClose();
      hideLoader();
    }
  };

  return (
    <ConfirmationDialog
      title={dialogMessages.headerTitle}
      message={dialogMessages.enabled.title(layer?.attachmentPoint!)}
      isOpen={open}
      onCancel={setClose}
      onSubmit={onDeleteLayer}
      cancelButton={{ children: buttons.cancel, color: 'secondary' }}
      proceedButton={{
        loading: isLoading,
        color: 'error',
        children: dialogMessages.enabled.button,
      }}
    />
  );
}
