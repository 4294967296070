import { messages } from 'i18n';
import { EmailTemplate } from 'types';
import { MenuItemProps } from 'components/menu';
import { EmailTemplateConfig } from 'broker/configuration-mappers/email-template-config';
import { EmailEditorPageMode } from 'broker/enums';

interface UseGetReplyTemplatesMenuProps {
  handleReplyTemplateClick: (emailEditorPageMode?: EmailEditorPageMode) => void;
  templates: EmailTemplate[];
}

export function useCustomerTemplatesReplyMenu({ handleReplyTemplateClick, templates }: UseGetReplyTemplatesMenuProps) {
  const { title, empty } = messages.submissionWorkspace.emailsTab.replyTemplatesMenu;

  const menuOptions: MenuItemProps[] = [];
  menuOptions.push({
    label: empty,
    onClick: handleReplyTemplateClick,
  });
  templates
    .filter((template) => EmailTemplateConfig[template.type].emailEditorModeForCustomerReply)
    .map((template) =>
      menuOptions.push({
        label: template.name,
        onClick: () => handleReplyTemplateClick(EmailTemplateConfig[template.type].emailEditorModeForCustomerReply),
      }),
    );

  return [{ key: 'reply-options', header: title, items: menuOptions }];
}
