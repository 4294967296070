import { RecipientGroup, RecipientGroups } from 'broker/components/Emails/recipient-utils';
import { TemplateInstance } from './store/types';

interface UseDraftPreviewProps {
  recipientGroups: RecipientGroups;
  selectedRecipientGroupId?: string;
  activeTemplateInstance?: TemplateInstance;
  resolveForPreview: (
    subject: string,
    body: string,
    recipientGroup: RecipientGroup,
  ) => { subject: string; body: string };
  resolveForNoRecipientPreview: (subject: string, body: string) => { subject: string; body: string };
}

export default function useDraftPreview({
  recipientGroups,
  selectedRecipientGroupId,
  activeTemplateInstance,
  resolveForPreview,
  resolveForNoRecipientPreview,
}: UseDraftPreviewProps) {
  // The purpose of these functions is to conform to the expected interface when working with Tiny, and also to prevent
  // using the same setter (specifically, the editing setter) for both modes, which causes a breaking bug due to
  // a quirk of Tiny using the given setter when it is given a different value.
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setSubject = (subject: string) => {};
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setBody = (body: string) => {};
  // Temporary state, until templates state loads.
  if (!activeTemplateInstance) {
    return { subject: '', body: '', setSubject, setBody };
  }

  // When no markets have been added yet, show the partially resolved active template instance.
  if (!selectedRecipientGroupId) {
    return {
      ...resolveForNoRecipientPreview(activeTemplateInstance.subject, activeTemplateInstance.body),
      setSubject,
      setBody,
    };
  }

  // When a market is selected, resolve the template instance for it fully.
  const selectedRecipientGroup = recipientGroups[selectedRecipientGroupId];
  return {
    ...resolveForPreview(activeTemplateInstance.subject, activeTemplateInstance.body, selectedRecipientGroup),
    setSubject,
    setBody,
  };
}
