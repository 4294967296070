import { normalizeBoxItemName } from '@common/utils';
import { BoxItem, SharedLink } from 'box-ui-elements/es';
import { pick } from 'lodash';
import { ErrorCallback } from 'contexts';
import { BoxTemplateLabels } from 'enums';
import { messages } from 'i18n';
import { TEMPLATE_KEY, TEMPLATE_SCOPE } from 'types';
import { downloadFile } from 'utils/files-utils';
import { Client, FetchOptions, retryOnError } from './base';

const BASE_URL = 'https://api.box.com';
const BASE_UPLOAD_URL = 'https://upload.box.com/api/2.0';
export interface BoxClientOptions {
  getToken: () => Promise<string | undefined>;
  onError: ErrorCallback;
}

// Values here reflect the allowed values defined in '@types/box-ui-elements/index.d.ts
export enum ShareLinkAccessLevel {
  Open = 'open',
  Collaborators = 'collaborators',
  Company = 'company',
}

export enum IsEditedOptions {
  True = 'True',
  False = 'False',
}

export interface ShareLinkResponse {
  // eslint-disable-next-line camelcase
  shared_link: SharedLink;
}

interface BoxFolderResponse {
  entries: BoxItem[];
  total_count: number;
  folder_upload_email?: { email: string };
}

interface UploadFileResponse {
  entries: BoxItem[];
}

export interface CreateFolderOptions {
  allowUploadViaEmail?: boolean;
}

interface CreateFolderResponse {
  id: string;
}

interface BoxConflictError {
  type: string;
  status: number;
  code: string;
  // eslint-disable-next-line camelcase
  context_info: {
    conflicts: [{ id: string }];
  };
}

export interface GetFileOptions {
  includeMetadata: boolean;
}

export default class BoxClient extends Client {
  private boxResource!: string;

  constructor({ getToken, onError }: BoxClientOptions) {
    super({
      baseUrl: BASE_URL,
      getBearerToken: getToken,
      prefix: '2.0',
      onError,
    });
  }

  public buildUrl(path: string | null = null, query: object = {}): string {
    // changing array query params to comma separated string value (box expects array this way, our api parses it 'name=1&name=2')
    const normalizedQuery: Record<string, any> = {};
    Object.entries(query).forEach(([key, value]) => {
      normalizedQuery[key] = Array.isArray(value) ? value.join(',') : value;
    });
    return super.buildUrl(path, normalizedQuery);
  }

  public get resourceName(): string {
    return this.boxResource;
  }

  public async createShareLink(fileId: string): Promise<ShareLinkResponse['shared_link'] | null> {
    this.boxResource = 'files';
    const url = this.buildUrl(fileId, { fields: 'shared_link' });
    const response = await this.fetch(
      url,
      {
        method: 'PUT',
        body: JSON.stringify({ shared_link: { access: ShareLinkAccessLevel.Open } }),
      },
      // TODO: For now we don't throw on missing files, just return null.
      // We will need to block file deletions or handle missing files in a robust way later CAP-263
      { ignoreStatusCodes: [404] },
    );

    if (!response) {
      return null;
    }

    return (response as ShareLinkResponse).shared_link;
  }

  private static hasPublicShareLink(file: BoxItem) {
    // eslint-disable-next-line @typescript-eslint/naming-convention,camelcase
    const { shared_link } = file;

    // eslint-disable-next-line camelcase
    if (!shared_link?.url) {
      return false;
    }

    // eslint-disable-next-line camelcase
    return shared_link.access === ShareLinkAccessLevel.Open;
  }

  public async createShareLinks(files: BoxItem[]): Promise<BoxItem[]> {
    const filesWithLinks: BoxItem[] = [];

    await Promise.all(
      files.map(async (file) => {
        if (BoxClient.hasPublicShareLink(file)) {
          filesWithLinks.push(file);
        } else {
          const shareLink = await this.createShareLink(file.id);

          if (shareLink) {
            filesWithLinks.push({
              ...file,
              shared_link: shareLink,
            });
          }
        }
      }),
    );

    return filesWithLinks;
  }

  public async getFile(fileId: string, options: GetFileOptions = { includeMetadata: false }): Promise<BoxItem | null> {
    this.boxResource = 'files';

    const fields = this.getFieldsToRetrieve(options);
    const url = this.buildUrl(fileId, { ...fields });

    const response = await this.fetch(
      url,
      {
        method: 'GET',
      },
      // TODO: For now we don't throw on missing files, just return null.
      // We will need to block file deletions or handle missing files in a robust way later CAP-263
      { ignoreStatusCodes: [404] },
    );

    return response as BoxItem | null;
  }

  public async getFilePdfRepresentations(fileId: string): Promise<BoxItem> {
    this.boxResource = 'files';
    const url = this.buildUrl(fileId, { fields: 'representations' });

    const response = await this.fetch(url, {
      method: 'GET',
      headers: {
        'x-rep-hints': '[pdf]',
      },
    });

    return response as BoxItem;
  }

  // https://developer.box.com/guides/representations/download-a-representation/
  public async downloadFileRepresentation(url: string): Promise<Blob> {
    const response = await this.fetch(
      url,
      {
        method: 'GET',
      },
      { dataType: 'blob' },
    );

    return response as Blob;
  }

  public async getFilesByFileType(folderId: string, fileType: string): Promise<BoxItem[]> {
    this.boxResource = 'metadata_queries';
    const url = this.buildUrl('execute_read');
    const response = await this.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ancestor_folder_id: folderId,
        fields: ['id', 'name', `metadata.${TEMPLATE_SCOPE}.${TEMPLATE_KEY}.${BoxTemplateLabels.FileType}`],
        from: `${TEMPLATE_SCOPE}.${TEMPLATE_KEY}`,
        query: `${BoxTemplateLabels.FileType} = :value`,
        query_params: {
          value: fileType,
        },
      }),
    });

    if (!response) {
      return [];
    }

    return (response as BoxFolderResponse).entries || [];
  }

  public async getFileMetadata(fileId: string): Promise<Partial<Record<BoxTemplateLabels, string>>> {
    this.boxResource = 'files';
    const url = this.buildUrl(`${fileId}/metadata/${TEMPLATE_SCOPE}/${TEMPLATE_KEY}`);

    const response = await this.fetch(
      url,
      {
        method: 'GET',
      },
      { passthroughStatusCodes: [404] },
    );

    return pick(response, Object.values(BoxTemplateLabels));
  }

  public async getTotalCount(folderId: string): Promise<number> {
    this.boxResource = 'folders';

    const query = {
      limit: 1,
      fields: ['id'],
    };

    const url = this.buildUrl(`${folderId}/items`, query);

    const response = (await this.fetch(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      { ...retryOnError },
    )) as BoxFolderResponse | null;

    return response?.total_count || 0;
  }

  public async getFolderItems(folderId: string, offset = 0, limit = 20): Promise<BoxItem[]> {
    this.boxResource = 'folders';

    const query = {
      limit,
      offset,
      fields: [
        'id',
        'name',
        'size',
        'created_by',
        'modified_at',
        'modified_by',
        'authenticated_download_url',
        `metadata.${TEMPLATE_SCOPE}.${TEMPLATE_KEY}.${BoxTemplateLabels.FileType}`,
        `metadata.${TEMPLATE_SCOPE}.${TEMPLATE_KEY}.${BoxTemplateLabels.MarketId}`,
        `metadata.${TEMPLATE_SCOPE}.${TEMPLATE_KEY}.${BoxTemplateLabels.IsHidden}`,
        `metadata.${TEMPLATE_SCOPE}.${TEMPLATE_KEY}.${BoxTemplateLabels.IsVisibleToRetailer}`,
        `metadata.${TEMPLATE_SCOPE}.${TEMPLATE_KEY}.${BoxTemplateLabels.ClassifiedByRole}`,
      ],
    };

    const url = this.buildUrl(`${folderId}/items`, query);

    const response = (await this.fetch(
      url,
      {
        method: 'GET',

        headers: {
          'Content-Type': 'application/json',
        },
      },
      retryOnError,
    )) as BoxFolderResponse;

    return response?.entries || [];
  }

  public async getSharableFile(fileId: string): Promise<BoxItem | null> {
    const file = await this.getFile(fileId);

    if (!file) {
      return null;
    }

    if (BoxClient.hasPublicShareLink(file)) {
      return file;
    }

    const sharedLink = await this.createShareLink(file.id);

    // If a share link is not created we are not returning the file at all
    // since the whole purpose of this function is to return a sharable file
    if (!sharedLink) {
      return null;
    }

    return {
      ...file,
      shared_link: sharedLink,
    };
  }

  public async createFolder(
    parentFolderId: string,
    folderName: string,
    { allowUploadViaEmail = false }: CreateFolderOptions = {},
  ): Promise<string | null> {
    this.boxResource = 'folders';
    const url = this.buildUrl();
    const response = await this.fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify({
          name: normalizeBoxItemName(folderName),
          parent: {
            id: parentFolderId,
          },
          folder_upload_email: allowUploadViaEmail
            ? {
                access: 'open',
              }
            : undefined,
        }),
      },
      { passthroughStatusCodes: [409] },
    );

    const newFolderId = (response as CreateFolderResponse).id;

    if (newFolderId) {
      return newFolderId;
    }

    const responseAsError = response as BoxConflictError;

    if (responseAsError.status === 409 && responseAsError.code === 'item_name_in_use') {
      const existingFolderId = responseAsError.context_info.conflicts[0]?.id;

      if (existingFolderId) {
        return existingFolderId;
      }
    }

    await this.handleError(response as Response);
    return null;
  }

  public async updateFolder(folderId: string, folderFields: { name: string }): Promise<void> {
    this.boxResource = 'folders';
    const url = this.buildUrl(folderId);

    await this.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(folderFields),
    });
  }

  public async deleteFolder(folderId: string, recursive?: boolean): Promise<void> {
    this.boxResource = 'folders';
    const url = this.buildUrl(folderId, { recursive });

    await this.fetch(url, {
      method: 'DELETE',
    });
  }

  public async downloadFileToBrowser(file: BoxItem) {
    const token = await this.getBearerToken();
    downloadFile(`${file.authenticated_download_url}?access_token=${token}`);
  }

  public async downloadFileAsBlob(fileId: string): Promise<Blob> {
    this.boxResource = 'files';
    const url = this.buildUrl(`${fileId}/content`);

    const downloadLinkResponse = (await this.fetch(
      url,
      {
        method: 'GET',
      },
      { dataType: 'response' },
    )) as { url: string };

    const fileBlobResponse = await this.fetch(
      downloadLinkResponse.url,
      {
        method: 'GET',
      },
      { dataType: 'blob' },
    );

    return fileBlobResponse as Promise<Blob>;
  }

  public async uploadFile(
    parentFolderId: string,
    fileName: string,
    file: Blob,
    retryWithDate = false,
  ): Promise<BoxItem> {
    const form = new FormData();
    const attributes = {
      name: normalizeBoxItemName(fileName),
      parent: { id: parentFolderId },
    };
    form.append('attributes', JSON.stringify(attributes));
    form.append('file', file);
    this.boxResource = 'files/content';
    const url = `${BASE_UPLOAD_URL}/${this.resourceName}`;
    const passThroughCodes = retryWithDate ? [409] : [];

    const response = await this.fetch(
      url,
      {
        method: 'POST',
        body: form,
      },
      { passthroughStatusCodes: passThroughCodes, throwOnError: true },
    );

    const responseAsError = response as BoxConflictError;
    if (retryWithDate && responseAsError.status === 409 && responseAsError.code === 'item_name_in_use') {
      const fileNameSplit = fileName.split('.');
      fileNameSplit[0] += `_${new Date().getTime()}`;
      const suggestedFileName = fileNameSplit.join('.');
      return this.uploadFile(parentFolderId, suggestedFileName, file);
    }
    return (response as UploadFileResponse).entries[0];
  }

  public async deleteFile(fileId: string) {
    this.boxResource = 'files';
    const url = this.buildUrl(`${fileId}`);

    await this.fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public async updateFile(fileId: string, fields: { name?: string; parent?: { id: string } }) {
    this.boxResource = 'files';
    const url = this.buildUrl(`${fileId}`);

    const response = await this.fetch(
      url,
      {
        method: 'PUT',
        body: JSON.stringify(fields),
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
      },
      { passthroughStatusCodes: [409] },
    );

    const responseAsError = response as BoxConflictError;

    if (responseAsError.status === 409 && responseAsError.code === 'item_name_in_use') {
      await this.onError(messages.box.fileWithNameAlreadyExists);
    }
  }

  public async addFileMetadata(fileId: string, metadata: Partial<Record<BoxTemplateLabels, string>>) {
    this.boxResource = 'files';
    const url = this.buildUrl(`${fileId}/metadata/${TEMPLATE_SCOPE}/${TEMPLATE_KEY}`);

    const response = await this.fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify(metadata),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      { passthroughStatusCodes: [409] },
    );

    // if box responded with an error
    if (response && typeof response === 'object' && 'code' in response) {
      const responseAsError = response as BoxConflictError;

      if (
        responseAsError.code.toLowerCase() === 'tuple_already_exists' ||
        responseAsError.code.toLowerCase() === 'conflict'
      ) {
        await this.updateFileMetadata(fileId, metadata);
      } else {
        // shows toast + logs in sentry
        await this.onError(messages.box.updateLabelError, 409);
        // throws exception so caller will stop normal process
        throw new Error(messages.box.updateLabelError);
      }
    }
  }

  public async getFolderDetails(folderId: string) {
    this.boxResource = 'folders';
    const url = this.buildUrl(`${folderId}`);

    return (await this.fetch(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      { ...retryOnError },
    )) as BoxFolderResponse | null;
  }

  public async openFolderForUploadViaEmail(folderId: string) {
    this.boxResource = 'folders';
    const url = this.buildUrl(`${folderId}`);
    const response = (await this.fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ folder_upload_email: { access: 'open' } }),
    })) as BoxFolderResponse | null;

    return response?.folder_upload_email?.email;
  }

  public async getFolderEmailAddress(folderId: string): Promise<string | undefined> {
    const response = await this.getFolderDetails(folderId);

    return response?.folder_upload_email?.email ?? this.openFolderForUploadViaEmail(folderId);
  }

  public async updateFileMetadata(fileId: string, metadata: Partial<Record<BoxTemplateLabels, string>>) {
    this.boxResource = 'files';
    const url = this.buildUrl(`${fileId}/metadata/${TEMPLATE_SCOPE}/${TEMPLATE_KEY}`);

    await this.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(
        Object.entries(metadata).map(([key, value]) => ({
          op: 'add',
          path: `/${key}`,
          value,
        })),
      ),
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
    });
  }

  public async deleteFileMetadata(
    fileId: string,
    metadataFields: Partial<Array<BoxTemplateLabels>>,
    fetchOptions: FetchOptions = {},
  ) {
    this.boxResource = 'files';
    const url = this.buildUrl(`${fileId}/metadata/${TEMPLATE_SCOPE}/${TEMPLATE_KEY}`);

    return this.fetch(
      url,
      {
        method: 'PUT',
        body: JSON.stringify(
          metadataFields.map((field) => ({
            op: 'remove',
            path: `/${field}`,
          })),
        ),
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
      },
      { ...fetchOptions },
    );
  }

  public async createZip(attachedFiles: BoxItem[], downloadFileName?: string) {
    this.boxResource = 'zip_downloads';
    const url = this.buildUrl();

    const zipCreateResponse = (await this.fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        download_file_name: downloadFileName ?? 'all_attachments',
        items: attachedFiles.map((file) => ({
          id: file.id,
          type: file.type,
        })),
      }),
    })) as { status_url: string; download_url: string };

    return zipCreateResponse.download_url;
  }

  private getFieldsToRetrieve(options: GetFileOptions) {
    const fields = [
      'id',
      'name',
      'size',
      'type',
      'shared_link',
      'folderId',
      'parent',
      'authenticated_download_url',
      'created_by',
      'modified_at',
      'modified_by',
    ];

    if (options.includeMetadata) {
      fields.push(`metadata.${TEMPLATE_SCOPE}.${TEMPLATE_KEY}`);
    }

    return {
      fields,
    };
  }
}
