import { isEmpty } from 'lodash';
import { Chip, Stack } from '@common-components';
import { Attachment, Done } from '@icons';
import { FormMode, QuoteFileIdType, SubmissionMarketRequestStatus } from 'enums';
import { messages } from 'i18n';
import { SubmissionMarket, SubmissionMarketRequest } from 'types';
import { quoteFileKeyConfig } from 'broker/components/common/QuotesAndPolicies/quotesAndPoliciesConfig';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';

interface FileProps {
  marketRequest: SubmissionMarketRequest;
  market: SubmissionMarket;
}

function QuoteChip({ label, onClick }: { label: string; onClick?: () => void }) {
  return <Chip size="small" color="grey.100" startIcon={Attachment} label={label} onClick={onClick} />;
}

function ShareChip({ label, missingData }: { label: string; missingData: boolean }) {
  return (
    <Chip
      size="small"
      color={missingData ? 'goldYellow.400' : 'grey.100'}
      label={label}
      startIcon={missingData ? undefined : Done}
    />
  );
}

export default function File({ marketRequest, market }: FileProps) {
  const navigate = useNavigate();
  const { quoteFormUrl, flowQuoteUrl, binderUrl, policyUrl } = useWorkspaceUrls();

  const isQuoteNotAccepted = marketRequest.status === SubmissionMarketRequestStatus.QuoteNotAccepted;

  const hasPolicy = marketRequest.status === SubmissionMarketRequestStatus.PolicyIssued;
  const hasBinder = [SubmissionMarketRequestStatus.Bound, SubmissionMarketRequestStatus.AwaitingPolicy].includes(
    marketRequest.status,
  );
  const hasQuote = [SubmissionMarketRequestStatus.QuoteReady, SubmissionMarketRequestStatus.AwaitingBinder].includes(
    marketRequest.status,
  );
  const flowQuoteGenerated = hasQuote && !isEmpty(marketRequest.quote?.capitolaQuoteFileIds);

  const sharingDetails = marketRequest.quote?.sharingDetails;

  const quoteTitle = messages.submissionWorkspace.marketRequestsWorkspace.viewQuote;
  const binderTitle = quoteFileKeyConfig[QuoteFileIdType.Binder].title;
  const policyTitle = quoteFileKeyConfig[QuoteFileIdType.Policy].title;

  const navigationDestination = (filesType: QuoteFileIdType) => {
    switch (filesType) {
      case QuoteFileIdType.MarketQuote:
        return flowQuoteUrl;
      case QuoteFileIdType.Binder:
        return binderUrl;
      case QuoteFileIdType.Policy:
        return policyUrl;
      default:
        return quoteFormUrl;
    }
  };
  const onThumbClick = (filesType: QuoteFileIdType) => {
    navigate(navigationDestination(filesType), {
      routeKey: RouteKey.Quote,
      state: {
        fileType: filesType,
        mode: FormMode.view,
        selectedMarket: market,
        selectedQuote: marketRequest.quote,
        insuranceProductId: marketRequest.insuranceProduct?.id,
      },
    });
  };

  const renderChips = (title: string, onClick: () => void, sharedIndicator: string, isShared: boolean) => (
    <Stack direction="row" justifyContent="center" alignItems="stretch" gap={1}>
      <QuoteChip label={title} onClick={onClick} />
      <ShareChip label={sharedIndicator} missingData={!isShared} />
    </Stack>
  );

  if (hasPolicy) {
    const isPolicyShared = !!sharingDetails?.policyShared;
    const sharedIndicator = messages.submissionWorkspace.marketRequestsWorkspace.policyShared(isPolicyShared);
    return renderChips(policyTitle, () => onThumbClick(QuoteFileIdType.Policy), sharedIndicator, isPolicyShared);
  }

  if (hasBinder) {
    const isBinderShared = !!sharingDetails?.binderShared;
    const sharedIndicator = messages.submissionWorkspace.marketRequestsWorkspace.binderShared(isBinderShared);
    return renderChips(binderTitle, () => onThumbClick(QuoteFileIdType.Binder), sharedIndicator, isBinderShared);
  }

  // else, show quote chips
  const isQuoteShared = !!sharingDetails?.quoteShared;
  const quoteSharedIndicator = messages.submissionWorkspace.marketRequestsWorkspace.quoteShared(isQuoteShared);

  return (
    (flowQuoteGenerated || isQuoteNotAccepted) && (
      <Stack direction="row" justifyContent="left" alignItems="stretch" gap={1} flexWrap="wrap">
        <QuoteChip label={quoteTitle} onClick={() => onThumbClick(QuoteFileIdType.MarketQuote)} />

        <ShareChip label={quoteSharedIndicator} missingData={!isQuoteShared} />
      </Stack>
    )
  );
}
