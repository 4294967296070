import { ContentPreview as BoxContentPreview } from 'box-ui-elements/es';
import { useEffect, useState } from 'react';
import { Stack } from '@common-components';
import { uniteStyles } from 'utils';
import { PreviewFilesMenu } from './PreviewFilesMenu';
import BoxStyles from './styles';
import useBoxMessages from './useBoxMessages';
import useBoxToken from './useBoxToken';
import { withBoxToken, WithBoxTokenProps } from './withBoxToken';

type FilesMenuProps = {
  collectionFileIds: string[];
  boxFolderId: string;
};

type ContentPreviewProps = WithBoxTokenProps & {
  fileId: string;
  hasHeader?: boolean;
  filesMenuProps?: FilesMenuProps;
};

function ContentPreview({ sx, hasHeader = true, fileId, filesMenuProps }: ContentPreviewProps) {
  const { getToken } = useBoxToken();
  const boxMessages = useBoxMessages();
  const [selectedFileId, setSelectedFileId] = useState<string>(fileId);

  useEffect(() => {
    setSelectedFileId(fileId);
  }, [fileId]);

  return (
    <Stack sx={uniteStyles(BoxStyles, sx)}>
      {filesMenuProps && (
        <PreviewFilesMenu
          fileId={fileId}
          selectedFileId={selectedFileId}
          setSelectedFileId={setSelectedFileId}
          collectionFileIds={filesMenuProps.collectionFileIds}
          boxFolderId={filesMenuProps.boxFolderId}
        />
      )}
      <BoxContentPreview
        className="box-content-preview"
        language="en-US"
        messages={boxMessages}
        token={getToken}
        hasHeader={hasHeader}
        collection={filesMenuProps?.collectionFileIds}
        fileId={selectedFileId}
      />
    </Stack>
  );
}

export default withBoxToken(ContentPreview);
