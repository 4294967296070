import { useState } from 'react';
import { Card, Chip, InputBase, InputLabel, Stack, styled } from '@common-components';
import { Add as AddIcon, Close as CloseButton } from '@icons';
import { useBoolean } from 'hooks';
import { useMutateOrganizationDomain, useSearchOrganizationDomain } from 'hooks/api/organization-domain';
import { messages } from 'i18n';
import { Organization } from 'types';

const { whitelistedDomainsTitle, placeholder, addDomain } = messages.admin.userOrganizationConfiguration;

const StyledInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: '4px',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
    fontSize: '0.75rem',
    color: theme.palette.text.primary,
    '&:focus': {
      borderColor: theme.palette.primary.dark,
    },
  },
}));

export default function WhitelistedDomainList({ organization }: { organization: Organization }) {
  const { items: domains } = useSearchOrganizationDomain({ filter: { organizationId: organization.id } });
  const { deleteOrganizationDomain, createOrganizationDomain } = useMutateOrganizationDomain();

  const [newDomain, setNewDomain] = useState('');
  const [isSubmitting, { on: startSubmit, off: stopSubmit }] = useBoolean(false);
  const [isAddingDomain, { on: startAddingDomain, off: stopAddingDomain }] = useBoolean(false);

  const onAddDomain = async () => {
    if (newDomain) {
      try {
        startSubmit();
        await createOrganizationDomain.mutateAsync({ data: { domain: newDomain, organizationId: organization.id } });
        setNewDomain('');
        stopAddingDomain();
      } finally {
        stopSubmit();
      }
    }
  };

  const onDeleteDomain = async (id: string) => {
    startSubmit();
    try {
      await deleteOrganizationDomain.mutateAsync({ id });
    } finally {
      stopSubmit();
    }
  };

  return (
    <Card sx={{ p: 1 }}>
      <InputLabel sx={{ mb: 1, fontSize: 12 }}>{whitelistedDomainsTitle}</InputLabel>
      <Stack gap={2}>
        <Stack direction="row" gap={1} flexWrap="wrap">
          {domains?.map((chip) => (
            <Chip
              key={chip.id}
              label={chip.domain}
              onClick={() => onDeleteDomain(chip.id)}
              disabled={isSubmitting}
              size="medium"
              endIcon={CloseButton}
              variant="contained"
              color="secondary"
            />
          ))}

          {isAddingDomain ? (
            <StyledInput
              value={newDomain}
              autoFocus
              placeholder={placeholder}
              onChange={(e) => setNewDomain(e.target.value)}
              onBlur={stopAddingDomain}
              onKeyPress={(e) => e.key === 'Enter' && onAddDomain()}
            />
          ) : (
            <Chip
              label={addDomain}
              onClick={startAddingDomain}
              disabled={isSubmitting}
              size="medium"
              endIcon={AddIcon}
              variant="contained"
            />
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
