import { compact } from 'lodash';
import { Button, NewMenu, Stack } from '@common-components';
import { InfoOutlined } from '@icons';
import { SubmissionMethod } from 'enums';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { MethodsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/Components/methods-config';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { isIncompleteApiApplication } from 'broker/utils';

interface SubmitButtonProps {
  onSelectSubmissionMethod: (method: SubmissionMethod) => void;
  requestsCount: number;
  disabled?: boolean;
}

export default function SubmitButton({ onSelectSubmissionMethod, requestsCount, disabled }: SubmitButtonProps) {
  const optionsMenuState = useOptionsMenu();
  const { partialSubmission } = useSubmissionsWorkspace();

  return (
    <Stack>
      <Button variant="contained" onClick={optionsMenuState.openMenu} color="secondary" disabled={disabled}>
        {messages.submissionWorkspace.marketRequestsWorkspace.submit(requestsCount)}
      </Button>
      <NewMenu
        placement="bottom-right"
        optionsMenuState={optionsMenuState}
        menuItems={[
          {
            key: 'bulkSubmit',
            items: compact([
              {
                label: messages.submissionWorkspace.menus.submit.submitViaEmail,
                startAdornment: MethodsConfig[SubmissionMethod.Email].icon,
                onClick: () => {
                  onSelectSubmissionMethod(SubmissionMethod.Email);
                },
              },
              {
                label: messages.submissionWorkspace.menus.submit.submitViaApi,
                startAdornment: MethodsConfig[SubmissionMethod.API].icon,
                onClick: () => {
                  onSelectSubmissionMethod(SubmissionMethod.API);
                },
                disabled: isIncompleteApiApplication(partialSubmission),
                endAdornment: isIncompleteApiApplication(partialSubmission) ? InfoOutlined : undefined,
                disabledTooltipContent: messages.submissionWorkspace.menus.submit.apiNotReady,
              },
            ]),
          },
        ]}
      />
    </Stack>
  );
}
