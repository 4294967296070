import { EmailLabels } from '@common/types';
import { orderBy } from 'lodash';
import { isCustomerOrganization, SubmissionEmailLabel } from 'enums';
import { messages } from 'i18n';
import { OrganizationBase, PartialSubmission, SyncMetadata, UserDetails } from 'types';
import { EmailItemType, isOutboundType } from 'broker/components/Emails/types';

const { submissionEmailMenuLabel, unassignedEmailsMenuLabel } = messages.submissionWorkspace.emailsTab;

export enum EmailsListType {
  Inbox = 'Inbox',
  Outbox = 'Outbox',
}

export interface EmailIdentifierProps {
  emailId: string;
  emailType: EmailItemType;
}

export enum InboxEmailType {
  Unassigned = 'Unassigned',
  Submission = 'Submission',
}

export enum SortBy {
  Date = 'date',
  Organization = 'organization',
}

export const inboxEmailTypeMenuItemLabels: Record<InboxEmailType, string> = {
  [InboxEmailType.Unassigned]: unassignedEmailsMenuLabel,
  [InboxEmailType.Submission]: submissionEmailMenuLabel,
};

export interface EmailListItemAttributes extends SyncMetadata {
  id: string;
  subject: string;
  date: Date;
  hasAttachments: boolean;
  senderEmail?: string;
  recipientName?: string;
  type: EmailItemType;
  boxFileId?: string;
  emailMessageBatchId?: string;
  recipientOrganization?: OrganizationBase;
  senderOrganization?: OrganizationBase;
  submissionInboundEmailId?: string;
  label?: SubmissionEmailLabel;
  labels?: EmailLabels;
  classifiedByUser?: UserDetails;
}

export type EmailDescriptor = Pick<EmailListItemAttributes, 'id' | 'type'>;

export const footerHeight = 83;
export const filtersMaxHeight = 64;

export const chipStyle = {
  '& .MuiChip-label': {
    typography: 'captionBold',
    color: 'text.secondary',
  },
  bgcolor: 'grey.100',
};

export interface EmailGroup {
  organizationId: string;
  organizationName: string;
  emails: EmailListItemAttributes[];
}

export function getEmailOrganization(email: EmailListItemAttributes) {
  if (isOutboundType(email.type)) {
    return email.recipientOrganization;
  }

  return email.senderOrganization;
}

const getOrganizationName = (organization: OrganizationBase | undefined) => {
  const { other, agent } = messages.submissionWorkspace.emailsTab;

  if (!organization) {
    return other;
  }

  if (isCustomerOrganization(organization.type)) {
    return agent;
  }

  return organization.name;
};

const getOrganizationId = (organization: OrganizationBase | undefined) => organization?.id || '';

export function emailGroupsByOrganization(emails: EmailListItemAttributes[]) {
  return emails.reduce((emailsByOrganization, email) => {
    const emailOrganization = getEmailOrganization(email);
    const emailOrganizationId = getOrganizationId(emailOrganization);
    const emailOrganizationName = getOrganizationName(emailOrganization);

    if (!emailsByOrganization[emailOrganizationId]) {
      emailsByOrganization[emailOrganizationId] = {
        organizationId: emailOrganizationId,
        organizationName: emailOrganizationName,
        emails: [email],
      };
    } else {
      emailsByOrganization[emailOrganizationId].emails.push(email);
    }

    return emailsByOrganization;
  }, {} as Record<string, EmailGroup>);
}

export function getLatestEmailDate(emails: EmailListItemAttributes[]) {
  // We assume the emails are sorted by date, so this is enough.
  return new Date(emails[0].date).getTime();
}

export function sortEmailGroupsByLatestDate(emailGroups: EmailGroup[]) {
  // Sort the objects based on the latest email date
  return orderBy(emailGroups, (group) => getLatestEmailDate(group.emails), 'desc');
}

export function getEmailGroups(emails: EmailListItemAttributes[], sortBy: SortBy) {
  // If sorting by date, we don't need to group the emails by organization
  if (sortBy === SortBy.Date) {
    const emailsGroup = {
      organizationId: '',
      organizationName: '',
      emails: emails.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    } as EmailGroup;
    return emails.length > 0 ? [emailsGroup] : [];
  }
  const emailsByOrganization = emailGroupsByOrganization(emails);
  const emailGroupsArray: EmailGroup[] = Object.values(emailsByOrganization);

  sortEmailGroupsByLatestDate(emailGroupsArray);

  return emailGroupsArray;
}

export function isInboundCustomerEmail(email: EmailListItemAttributes, submission: PartialSubmission) {
  return email.senderOrganization?.id === submission.organizationId;
}

export function isCustomerEmail(submission: PartialSubmission, email: EmailListItemAttributes) {
  return (
    submission.organizationId === email.senderOrganization?.id ||
    submission.organizationId === email.recipientOrganization?.id
  );
}
