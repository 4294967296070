import { useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import { FormProvider } from 'react-hook-form';
import { Button, Grid, Stack, Typography } from '@common-components';
import { UserStatus } from 'enums';
import { useFormProvider, useMutateUser } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import FormTextField from 'components/hookFormComponents/FormTextField';
import Banner, { BannerMode } from 'broker/components/Banner';
import { DirtyRouteGuard } from 'broker/components/DirtyContentGuard';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { SetNextStepsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/useSetNextStep';
import { useIsAcknowledgmentFlow } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { formFieldsConfig, schema } from './form-utils';
import { ReviewAgentFormData, ReviewAgentFormFieldsNames } from './types';

interface ReviewAgentFormProps {
  setNextStep: (props?: SetNextStepsProps) => void;
  submission: PartialSubmission;
}

export default function ReviewAgentForm({ setNextStep, submission }: ReviewAgentFormProps) {
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isAcknowledgmentFlow } = useIsAcknowledgmentFlow();

  const { updateUser } = useMutateUser();
  const { reFetchWorkspace } = useSubmissionsWorkspace();

  const defaultValues = useMemo(() => {
    const getDefaultValues = (submissionData: PartialSubmission): ReviewAgentFormData => ({
      firstName: submissionData.user?.firstName || '',
      lastName: submissionData.user?.lastName || '',
      email: submissionData.user?.email || '',
      title: submissionData.user?.title || '',
    });

    return getDefaultValues(submission);
  }, [submission]);

  const { methods } = useFormProvider<ReviewAgentFormData>({
    schema,
    defaultValues,
    setIsDirty,
  });

  const onUpdateUser = async (data: ReviewAgentFormData) => {
    const { firstName, lastName, title } = data;

    setIsSubmitting(true);

    try {
      await updateUser.mutateAsync({
        // If we got to this screen that there must be a user because we know the status is pending review
        id: submission.user!.id,
        data: {
          firstName,
          lastName,
          title: title ?? undefined,
          status: UserStatus.Enabled,
        },
      });

      // Refetch the workspace to get the updated user
      await reFetchWorkspace();

      // Force React to flush the dirty flag update before we navigate away
      flushSync(() => {
        setIsDirty(false);
      });
      setNextStep();
    } finally {
      setIsSubmitting(false);
    }
  };

  const titleText = () => {
    if (isAcknowledgmentFlow) {
      return messages.acknowledgmentFlow.title;
    }
    return messages.editSubmission.formTitle;
  };

  return (
    <FormProvider {...methods}>
      <DirtyRouteGuard isDirty={isDirty}>
        <InnerPageLayout
          hideBackButton={isAcknowledgmentFlow}
          title={titleText()}
          headerTopContent={() => (
            <Banner
              title={messages.reviewAgent.banner.title}
              subtitle={messages.reviewAgent.banner.subtitle}
              mode={BannerMode.Error}
            />
          )}
          className="review-agent-form"
        >
          {() => (
            <Stack maxWidth={640} margin="auto" gap={1}>
              <Typography variant="h5">{messages.reviewAgent.title(submission.organizationName)}</Typography>

              <Grid columnSpacing={1} container>
                <Grid item xs={6}>
                  <FormTextField {...formFieldsConfig[ReviewAgentFormFieldsNames.FirstName]} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField {...formFieldsConfig[ReviewAgentFormFieldsNames.LastName]} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField {...formFieldsConfig[ReviewAgentFormFieldsNames.Email]} disabled fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField {...formFieldsConfig[ReviewAgentFormFieldsNames.Title]} fullWidth />
                </Grid>
              </Grid>

              <Stack justifyContent="flex-end" direction="row">
                <Button loading={isSubmitting} onClick={methods.handleSubmit(onUpdateUser)} variant="contained">
                  {messages.reviewAgent.save}
                </Button>
              </Stack>
            </Stack>
          )}
        </InnerPageLayout>
      </DirtyRouteGuard>
    </FormProvider>
  );
}
