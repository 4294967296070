import { isEmpty } from 'lodash';
import { Box, Typography } from '@common-components';
import { FormFieldsConfigProps, useBoolean } from 'hooks';
import { FormControlPaddingBottom } from 'themes';
import FormEditor from 'components/hookFormComponents/FormEditor';

interface FormEditorWithPreviewFieldProps {
  initialValue: string;
  formFieldsConfig: FormFieldsConfigProps<string>;
  height?: number;
}

export function FormEditorWithPreviewField({
  initialValue,
  formFieldsConfig,
  height = 170,
}: FormEditorWithPreviewFieldProps) {
  const [isEditing, { on: startEditing }] = useBoolean(false);
  return isEditing ? (
    <Box height={height + 23}>
      <FormEditor hideOptionalLabel autoFocus {...formFieldsConfig} />
    </Box>
  ) : (
    <Box
      border={1}
      borderRadius={1}
      borderColor="grey.400"
      p="16.5px 14px"
      height={height}
      id={formFieldsConfig.id}
      sx={{ overflowY: 'auto', '&:hover': { borderColor: 'text.primary' }, cursor: 'pointer' }}
      onClick={startEditing}
      mb={FormControlPaddingBottom}
    >
      {isEmpty(initialValue) ? (
        <Typography variant="body2" color="textSecondary">
          {formFieldsConfig.placeholder ?? ''}
        </Typography>
      ) : (
        <Box
          dangerouslySetInnerHTML={{
            __html: initialValue,
          }}
        />
      )}
    </Box>
  );
}
