import { EmailTemplateVariables } from '@common/email-variables';
import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { ResolvedMessage } from 'broker/components/EmailEditor/store/types';

interface getUnresolvedVariablesProps {
  resolvedMessages: ResolvedMessage[];
}
export const getUnresolvedVariables = ({ resolvedMessages }: getUnresolvedVariablesProps) => {
  const unresolvedVariables = Object.values(EmailTemplateVariables).filter((variable) =>
    resolvedMessages.some(
      (resolvedMessage) =>
        (resolvedMessage.subject && resolvedMessage.subject.includes(`@${variable}`)) ||
        (resolvedMessage.body && resolvedMessage.body.includes(`@${variable}`)),
    ),
  );

  const unresolvedVariablesTooltip = (
    <Stack gap={0.5}>
      <Typography variant="caption">{messages.emailEditor.sendEmailDisabledTooltipUnresolvedVariables}</Typography>
      <Stack gap={0.5} alignItems="start">
        {unresolvedVariables.map((variable) => (
          <Typography variant="caption" key={variable}>
            {variable}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );

  return { unresolvedVariables, unresolvedVariablesTooltip };
};
