import { includes } from 'lodash';
import { Button, NewMenu } from '@common-components';
import {
  ContentCopy as ContentCopyIcon,
  AttachEmailOutlined as EmailIcon,
  FileDownloadOutlined as FileDownloadIcon,
} from '@icons';
import { ShareMenuAction } from 'enums';
import { useOptionsMenu, useToast } from 'hooks';
import { messages } from 'i18n';
import { MenuItemProps } from 'components/menu';

export type ShareMenuActionProps = Partial<Record<ShareMenuAction, () => void>>;
export type ShareMenu = Record<ShareMenuAction, MenuItemProps>;

// export type ShareMenuAction = 'onEmail' | 'onDownload' | 'onCopy';
const shareMessages = messages.submissionWorkspace.menus.shareReport;

interface ShareMenuButtonProps {
  loading: boolean;
  disabled: boolean;
  // allows passing in a partial object of actions to limit the available share options
  menuActions: ShareMenuActionProps;
}

const allShareActions: ShareMenu = {
  [ShareMenuAction.Email]: { label: shareMessages.options.email, startAdornment: EmailIcon },
  [ShareMenuAction.Download]: { label: shareMessages.options.download, startAdornment: FileDownloadIcon },
  [ShareMenuAction.Copy]: { label: shareMessages.options.copy, startAdornment: ContentCopyIcon },
};

export const ShareMenuButton = ({ loading, disabled, menuActions }: ShareMenuButtonProps) => {
  const { showToast } = useToast();
  const optionsMenuState = useOptionsMenu();

  const getShareMenuAction = (action: ShareMenuAction) => {
    if (includes([ShareMenuAction.Download, ShareMenuAction.Copy], action)) {
      return () => {
        menuActions[action]?.();
        showToast('success', { message: messages.submissionWorkspace.menus.shareReport.confirmations[action] });
      };
    }

    return menuActions[action];
  };

  const getMenuItemsByActions = (): MenuItemProps[] =>
    Object.keys(allShareActions)
      .filter((action) => action in menuActions)
      .map((filteredAction) => {
        const actionKey = filteredAction as ShareMenuAction;
        return { ...allShareActions[actionKey], onClick: getShareMenuAction(actionKey) };
      });

  return (
    <>
      <Button variant="contained" loading={loading} disabled={disabled} onClick={optionsMenuState.openMenu}>
        {shareMessages.shareButton}
      </Button>
      <NewMenu
        placement="top-right"
        optionsMenuState={optionsMenuState}
        menuItems={[{ key: 'share', items: getMenuItemsByActions() }]}
      />
    </>
  );
};
