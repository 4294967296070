import { sortBy } from 'lodash';
import { ReactChild, useRef } from 'react';
import { Box, ButtonBase, Chip, darken, Divider, Grid, Stack, Typography } from '@common-components';
import { Add as AddIcon } from '@icons';
import { useTruncated } from 'hooks';

interface LabelProps {
  text: string;
  tooltipContent?: ReactChild;
}

interface LabelListProps {
  labels: LabelProps[];
  onAddLabelClick?: () => void;
  searchInput?: string;
  selectedLabels: string[];
  addButtonText: string;
  isRounded?: boolean;
  variant?: 'contained' | 'outlined';
}

export default function LabelList({
  labels,
  onAddLabelClick,
  searchInput = '',
  selectedLabels,
  addButtonText,
  isRounded = false,
  variant = 'outlined',
}: LabelListProps) {
  const containerElementRef = useRef<HTMLElement | null>();
  const { localArrays, ref } = useTruncated({
    containerElementRef,
    items: labels,
    sorter: (e) => e.text.toLowerCase(),
  });

  const isInvisibleBadge = () =>
    (!searchInput || !localArrays.hidden.some((category) => category.text.toLowerCase().includes(searchInput))) &&
    !localArrays.hidden.some((category) => selectedLabels.includes(category.text));

  return (
    <Stack direction="row" width={1} alignItems="center" ref={containerElementRef}>
      {localArrays.visible.length > 0 && (
        <Box overflow="auto">
          <Stack direction="row" ref={ref}>
            {localArrays.visible.map((category) => (
              <Box p={0.5} key={category.text}>
                <Chip
                  tooltipContent={category.tooltipContent}
                  rounded={isRounded}
                  focused={selectedLabels.includes(category.text)}
                  label={category.text}
                  searchTerm={searchInput}
                  variant={variant}
                  color="secondary"
                />
              </Box>
            ))}
          </Stack>
        </Box>
      )}

      {localArrays.hidden.length > 0 && (
        <Box ml={0.5}>
          <Chip
            rounded={isRounded}
            label={`+${localArrays.hidden.length.toString()}`}
            variant={variant}
            indicator={!isInvisibleBadge()}
            color="secondary"
            tooltipContent={
              <Grid container spacing={1}>
                {sortBy(localArrays.hidden, (e) => e.text.toLowerCase()).map((category) => (
                  <Grid item sx={{ lineHeight: 1 }} key={category.text}>
                    <Chip
                      rounded={isRounded}
                      variant={variant}
                      searchTerm={searchInput}
                      label={category.text}
                      color="secondary"
                      focused={selectedLabels.includes(category.text)}
                    />
                  </Grid>
                ))}
              </Grid>
            }
          />
        </Box>
      )}
      {labels.length > 0 && onAddLabelClick && (
        <Divider
          orientation="vertical"
          flexItem
          sx={{ height: 16, alignSelf: 'center', mr: 1, ml: localArrays.hidden.length > 0 ? 1 : 0.5 }}
        />
      )}

      {onAddLabelClick && (
        <ButtonBase
          sx={(theme) => ({
            borderRadius: 1,
            bgcolor: 'transparent',
            color: 'text.primary',
            borderColor: 'secondary',
            p: 0.5,
            '&:hover': {
              backgroundColor: darken(theme.palette.blue[50]!, 0.05),
            },
          })}
          onClick={onAddLabelClick}
        >
          <AddIcon fontSize="small" />
          {labels.length === 0 && (
            <Typography variant="caption" fontWeight={500} pr={0.5} noWrap>
              {addButtonText}
            </Typography>
          )}
        </ButtonBase>
      )}
    </Stack>
  );
}
