import { FileType, FormMode, QuoteFileIdType } from 'enums';
import { ExtractedDataFields } from 'hooks';
import { messages } from 'i18n';
import { getFileType } from 'utils';
import { QuoteFormRoutes } from 'broker/broker-routes';
import { ExtendedBoxItem } from 'broker/components/FilesExplorer/types';
import {
  extractValidPolicyFieldsSuggestions,
  extractValidQuoteFieldsSuggestions,
} from './form-methods/extract-valid-quote-fields-suggestions';

interface QuoteTypeProps {
  title: string;
  topHeaderTitle: string;
  topHeaderSubtitle: string;
  headerTitle: string;
  headerSubtitle: string;
}

const ModeTitlePrefixMap = {
  [FormMode.create]: messages.addQuotePage.attach,
  [FormMode.edit]: messages.addQuotePage.update,
  [FormMode.view]: messages.addQuotePage.view,
};

type QuoteTypeConfigProps = Record<QuoteFileIdType, QuoteTypeProps>;

export const getQuoteMessages = (formMode: FormMode): QuoteTypeConfigProps => {
  const prefix = ModeTitlePrefixMap[formMode] || messages.general.attach;

  return {
    [QuoteFileIdType.MarketQuote]: {
      title: `${prefix} ${messages.general.quote}`,
      topHeaderTitle: messages.addQuotePage.marketQuote.topHeaderTitle,
      topHeaderSubtitle:
        formMode === FormMode.view
          ? messages.addQuotePage.marketQuote.viewSubtitle
          : messages.addQuotePage.marketQuote.topHeaderSubtitle,
      headerTitle: messages.addQuotePage.marketQuote.headerTitle,
      headerSubtitle: messages.addQuotePage.marketQuote.headerSubtitle,
    },
    [QuoteFileIdType.Binder]: {
      title: `${prefix} ${messages.general.binder}`,
      topHeaderTitle: messages.addQuotePage.binder.topHeaderTitle,
      topHeaderSubtitle: messages.addQuotePage.binder.topHeaderSubtitle,
      headerTitle: messages.addQuotePage.binder.headerTitle,
      headerSubtitle: messages.addQuotePage.binder.headerSubtitle,
    },
    [QuoteFileIdType.Policy]: {
      title: `${prefix} ${messages.general.policy}`,
      topHeaderTitle: messages.addQuotePage.policy.topHeaderTitle,
      topHeaderSubtitle: messages.addQuotePage.policy.topHeaderSubtitle,
      headerTitle: messages.addQuotePage.policy.headerTitle,
      headerSubtitle: messages.addQuotePage.policy.headerSubtitle,
    },
  };
};

const getRequiredFileType = (fileType: QuoteFileIdType) => {
  switch (fileType) {
    case QuoteFileIdType.Binder:
      return FileType.MarketBinder;
    case QuoteFileIdType.Policy:
      return FileType.Policy;
    default:
      return FileType.MarketQuote;
  }
};

const getExtractedDataFields = (
  fileType: QuoteFileIdType,
  mode: FormMode,
  fileWithExtractionMetadata?: ExtendedBoxItem,
) => {
  if (mode !== FormMode.view) {
    if (fileType === QuoteFileIdType.MarketQuote) {
      const extractedData = fileWithExtractionMetadata?.syncMetadata?.extractedData;
      if (extractedData) {
        return extractValidQuoteFieldsSuggestions(extractedData);
      }
    }
    if (fileType === QuoteFileIdType.Policy) {
      const extractedData = fileWithExtractionMetadata?.syncMetadata?.policyExtractedData;
      if (extractedData) {
        return extractValidPolicyFieldsSuggestions(extractedData);
      }
    }
  }

  return undefined;
};

const getFormSteps = (fileType: QuoteFileIdType, formMode: FormMode) => {
  const formSteps =
    fileType === QuoteFileIdType.MarketQuote
      ? [QuoteFormRoutes.Upload, QuoteFormRoutes.Details, QuoteFormRoutes.Insights, QuoteFormRoutes.FlowQuote]
      : [QuoteFormRoutes.Upload, QuoteFormRoutes.Details];

  // in view mode we don't show upload screen
  return formMode === FormMode.view ? formSteps.filter((step) => step !== QuoteFormRoutes.Upload) : formSteps;
};

const getFileToExtract = (fileType: QuoteFileIdType, selectedFiles: ExtendedBoxItem[]) => {
  if (fileType === QuoteFileIdType.MarketQuote) {
    return selectedFiles.find((f) => getFileType(f) === FileType.MarketQuote);
  }
  if (fileType === QuoteFileIdType.Policy) {
    return selectedFiles.find((f) => getFileType(f) === FileType.Policy);
  }
  return undefined;
};

interface QuoteConfigProps {
  fileType: QuoteFileIdType;
  formMode: FormMode;
  fileWithExtractionMetadata?: ExtendedBoxItem;
  currentStep: QuoteFormRoutes;
  selectedFiles: ExtendedBoxItem[];
}

export interface QuoteConfig {
  messages: QuoteTypeProps;
  requiredFileType: FileType;
  suggestedValueProps?: ExtractedDataFields;
  showFooter: boolean;
  formSteps: QuoteFormRoutes[];
  areMarketQuoteFieldsDisabled?: boolean;
  isBinderMode: boolean;
  isPolicyMode: boolean;
  isFileExtractionSupported: boolean;
  fileToExtract?: ExtendedBoxItem;
}

export const getQuoteConfig = ({
  fileWithExtractionMetadata,
  fileType,
  formMode,
  currentStep,
  selectedFiles,
}: QuoteConfigProps): QuoteConfig => {
  const isBinderPolicyViewMode =
    [QuoteFileIdType.Binder, QuoteFileIdType.Policy].includes(fileType) && formMode === FormMode.view;
  return {
    messages: getQuoteMessages(formMode)[fileType],
    requiredFileType: getRequiredFileType(fileType),
    suggestedValueProps: getExtractedDataFields(fileType, formMode, fileWithExtractionMetadata),
    showFooter: currentStep !== QuoteFormRoutes.Upload && !isBinderPolicyViewMode,
    formSteps: getFormSteps(fileType, formMode),
    areMarketQuoteFieldsDisabled: formMode === FormMode.view || fileType !== QuoteFileIdType.MarketQuote,
    isBinderMode: fileType === QuoteFileIdType.Binder,
    isPolicyMode: fileType === QuoteFileIdType.Policy,
    isFileExtractionSupported: fileType !== QuoteFileIdType.Binder,
    fileToExtract: getFileToExtract(fileType, selectedFiles),
  };
};
