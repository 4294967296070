import { useEffect } from 'react';
import { Outlet, useMatch } from 'react-router-dom-latest';
import { BROKER_ROUTES } from 'broker/broker-routes';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';

const TEMPLATE_PLACEHOLDER = 'template-placeholder';

export default function TemplatesPage() {
  const navigate = useNavigate();
  const match = useMatch(`/${BROKER_ROUTES.TEMPLATES}`);

  useEffect(() => {
    // If this page is entered with no templateId param, navigate to an arbitrary templateId ('template-placeholder')
    // This will trigger the Templates component to load the default template.
    if (match) {
      navigate(TEMPLATE_PLACEHOLDER, { routeKey: RouteKey.TemplatesPage, state: undefined, replace: true });
    }
  }, [match, navigate]);

  return <Outlet />;
}
