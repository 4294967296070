import { BoxItem } from 'box-ui-elements/es';
import { Box, Stack, Typography } from '@common-components';
import { InformationTooltipType } from 'contexts/information-tooltip/types';
import { AttachFilesButton } from './DraftEditorFooter/AttachFilesButton';
import FilesIcon from './files-icon.svg';
import FilesToolTip from './FilesToolTip';

interface FilesAttachmentsProps {
  informationTooltipType: InformationTooltipType;
  attachedFiles: BoxItem[];
  onRemoveFile: (file: BoxItem) => void;
}

export default function FilesAttachments({
  informationTooltipType,
  attachedFiles,
  onRemoveFile,
}: FilesAttachmentsProps) {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      {attachedFiles.length > 0 && (
        <FilesToolTip attachedFiles={attachedFiles} onRemoveFile={onRemoveFile}>
          <Stack position="relative">
            <Box component="img" src={FilesIcon} sx={{ height: 28, width: 28 }} />
            {attachedFiles.length > 0 && (
              <Typography
                display="flex"
                justifyContent="center"
                variant="captionBold"
                position="absolute"
                width={20}
                height={20}
                borderRadius={5}
                bottom={-7}
                right={-10}
                bgcolor="primary.main"
                color="common.white"
                alignItems="center"
              >
                {attachedFiles.length}
              </Typography>
            )}
          </Stack>
        </FilesToolTip>
      )}
      <AttachFilesButton informationTooltipType={informationTooltipType} />
    </Stack>
  );
}
