import { flushSync } from 'react-dom';
import { Box } from '@common-components';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { MarketingUpdateRoutes } from 'broker/broker-routes';
import EmailEditor from 'broker/components/EmailEditor';
import EmailEditorFooter from 'broker/components/EmailEditor/EmailEditorFooter';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { AIButtonWithPopup } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIButtonWithPopup';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

interface MarketingUpdateEmailLayoutProps {
  setIsDirty: (value: boolean) => void;
  submission: PartialSubmission;
}

export default function MarketingUpdateEmailLayout({ setIsDirty, submission }: MarketingUpdateEmailLayoutProps) {
  const navigate = useNavigate();
  const { marketingWorkspaceUrl, marketingUpdateUrl } = useWorkspaceUrls();
  const { updateSubmission } = useSubmissionsWorkspace();
  const {
    draftPreview: { body, subject },
    attachedFiles: { attachedFiles },
  } = useEmailEditorState();

  const saveForLater = async () => {
    await updateSubmission(submission.id, {
      draftMarketingUpdate: {
        ...submission.draftMarketingUpdate,
        emailBody: body,
        emailSubject: subject,
        attachmentsFileIds: attachedFiles.map((file) => file.id),
      },
    });
  };

  return (
    <InnerPageLayout
      title={messages.marketingUpdate.marketingUpdateEmail.title}
      onCloseOverride={async () => {
        navigate(marketingWorkspaceUrl, { routeKey: RouteKey.Marketing, state: {} });
      }}
      footer={(innerPageProps) => (
        <EmailEditorFooter
          {...innerPageProps}
          successMessage={messages.marketingUpdate.marketingUpdateEmail.successMessage}
          proceedButtonText={messages.marketingUpdate.buttons.sendToClient}
          onAfterSent={async () => {
            innerPageProps.onClose();
            await updateSubmission(submission.id, {
              draftMarketingUpdate: {},
            });
          }}
          secondaryButtonText={messages.buttons.saveForLater}
          secondaryButtonAction={async () => {
            // Force React to flush the dirty flag update before we navigate away
            flushSync(() => {
              setIsDirty(false);
            });
            await saveForLater();
            navigate(marketingWorkspaceUrl, { routeKey: RouteKey.Marketing, state: {} });
          }}
          backButtonAction={async () => {
            // Force React to flush the dirty flag update before we navigate away
            flushSync(() => {
              setIsDirty(false);
            });
            await saveForLater();
            navigate(marketingUpdateUrl(MarketingUpdateRoutes.Upload), {
              routeKey: RouteKey.MarketingUpdate,
              state: {},
            });
          }}
          backButtonText={messages.buttons.back}
        />
      )}
    >
      {() => (
        <Box position="relative" height={1}>
          <AIButtonWithPopup
            popupTitle={messages.marketingUpdate.marketingUpdateEmail.aiPopupTitle}
            tooltipText={messages.marketingUpdate.marketingUpdateEmail.aiTooltipText}
            top={148}
            right={37}
          />
          <EmailEditor />
        </Box>
      )}
    </InnerPageLayout>
  );
}
