import { IconButton } from '@common-components';
import { PublishedWithChanges, Sync } from '@icons';
import { FileSyncStatus } from 'enums';
import { useCurrentUser, useToast } from 'hooks';
import { useMutateSubmissionFileMetadata } from 'hooks/api/submissionFileMetadata';
import { messages } from 'i18n';
import { SyncedSystemType } from 'types';
import { trimAndTruncateString } from 'utils';
import { Features } from 'utils/features-config';
import { HotjarEvents } from 'utils/hotjar-events';
import { FeatureGate } from 'components/FeatureGate';
import { syncedSystemsConfig } from 'broker/configuration-mappers/synced-systems-config';

interface SyncFileButtonsProps {
  submissionId: string;
  syncSystem: SyncedSystemType;
  displayText: string;
  id?: string;
  syncStatus?: FileSyncStatus;
}
export default function SyncFileButtons({
  syncStatus,
  id,
  syncSystem,
  submissionId,
  displayText,
}: SyncFileButtonsProps) {
  const { endUser } = useCurrentUser();

  const submissionFileMetadataApi = useMutateSubmissionFileMetadata();

  const syncedSystems = endUser?.organization.syncedSystems?.[syncSystem];

  const { showToast } = useToast();

  const trimmedDisplayText = trimAndTruncateString(displayText, 16);

  if (syncStatus === FileSyncStatus.Syncing) {
    return (
      <FeatureGate feature={Features.ApproveSyncFilesIntegration}>
        <IconButton
          loading={submissionFileMetadataApi.completeSync.isLoading}
          tooltipContent={messages.sync.markAsSynced}
          color="secondary"
          icon={PublishedWithChanges}
          hotjarEvent={HotjarEvents.MarkFileAsSynced}
          onClick={async () => {
            await submissionFileMetadataApi.completeSync.mutateAsync({ submissionId, fileIds: [id!] });
            // trim the display text to 20 characters and add ellipsis
            showToast('success', { message: messages.sync.syncApproved(trimmedDisplayText) });
          }}
          variant="text"
          size="small"
        />
      </FeatureGate>
    );
  }

  if (!syncStatus && syncedSystems) {
    return (
      <FeatureGate feature={Features.SyncFilesIntegration}>
        <IconButton
          loading={submissionFileMetadataApi.requestSync.isLoading}
          tooltipContent={messages.sync.syncToSystem(syncedSystemsConfig[syncSystem].label, syncedSystems)}
          disabledTooltipContent={messages.sync.syncNotAvailable}
          color="secondary"
          disabled={!id}
          icon={Sync}
          hotjarEvent={HotjarEvents.RequestFileSync}
          onClick={async () => {
            await submissionFileMetadataApi.requestSync.mutateAsync({ submissionId, fileIds: [id!] });
            showToast('success', { message: messages.sync.syncRequested(trimmedDisplayText) });
          }}
          variant="text"
          size="small"
        />
      </FeatureGate>
    );
  }
  return null;
}
