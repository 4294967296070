import { Box, Stack, Typography } from '@common-components';
import { columnsConfig, MarketsTableColumns } from './config';

export default function MarketsTableHeader() {
  return (
    <Stack direction="row" alignItems="center" px={3} py={2} gap={3}>
      <Typography variant="caption" color="text.secondary" flex={columnsConfig[MarketsTableColumns.Market].flex}>
        {columnsConfig[MarketsTableColumns.Market].title}
      </Typography>
      <Typography variant="caption" color="text.secondary" flex={columnsConfig[MarketsTableColumns.Status].flex}>
        {columnsConfig[MarketsTableColumns.Status].title}
      </Typography>
      <Typography variant="caption" color="text.secondary" flex={columnsConfig[MarketsTableColumns.Info].flex}>
        {columnsConfig[MarketsTableColumns.Info].title}
      </Typography>
      <Box flex={columnsConfig[MarketsTableColumns.CTA].flex} />
    </Stack>
  );
}
