import { isEmpty } from 'lodash';
import { AIFeature, QuoteFileIdType } from 'enums';
import RoutedDialog from 'broker/components/RoutedDialog';
import { RouteKey } from 'broker/enums';
import { AIContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import Content from './components/Content';
import { QuoteStatusLocationState } from './utils';

export default function QuoteStatusModal() {
  const getAIContextFeature = (state: QuoteStatusLocationState) => {
    if (state.fileType === QuoteFileIdType.Binder) {
      return AIFeature.QuoteBinderCompare;
    }
    if (!isEmpty(state.selectedQuote.binderFileIds)) {
      return AIFeature.BinderPolicyCompare;
    }
    return AIFeature.QuotePolicyCompare;
  };

  return (
    <RoutedDialog
      routeKey={RouteKey.QuoteStatus}
      isLocationStateRequired
      className="cap-quote-status-modal"
      fullScreen
      ariaLabelId="quote-status-modal"
    >
      {({ locationState, ...rest }) => (
        <AIContextProvider feature={getAIContextFeature(locationState)}>
          <Content {...rest} locationState={locationState} aiFeature={getAIContextFeature(locationState)} />
        </AIContextProvider>
      )}
    </RoutedDialog>
  );
}
