import * as yup from 'yup';
import { detailsFormValidationShape } from './detailsFieldsConfig';
import { insightsFormValidationShape } from './insightsFieldsConfig';

export const quoteSchema = yup
  .object()
  .shape({
    ...detailsFormValidationShape,
    ...insightsFormValidationShape,
  })
  .required();
