import { InsuredClient } from 'clients';
import { QueryKeys } from 'enums';
import { Insured } from 'types';
import { UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useBulkCreate } from './query/useBulkCreate';
import { useQueryRetrieve, useQuerySearch } from './query/useQuery';

export function useSearchInsured(props?: UseQueryBaseSearchOptions<Insured>) {
  const { filter, queryOptions, enabled, sorter } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.Insureds,
    clientClass: InsuredClient,
    filter,
    enabled,
    sorter,
    queryOptions: {
      // Default Insured search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useRetrieveInsured(props: UseQueryBaseRetrieveOptions<Insured>) {
  const { id, queryOptions, enabled } = props;
  return useQueryRetrieve({
    queryKey: QueryKeys.Insureds,
    clientClass: InsuredClient,
    id,
    enabled,
    queryOptions: {
      // Default Insured retrieve query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}
export function useBulkCreateInsureds() {
  const { bulkCreate: bulkCreateInsureds } = useBulkCreate<Insured, Insured, InsuredClient>({
    queryKey: QueryKeys.Insureds,
    clientClass: InsuredClient,
    createOptions: { throwOnError: true },
  });

  return { bulkCreateInsureds };
}
