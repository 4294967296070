import { ReactNode, useContext, useReducer } from 'react';
import { UiStoreActionsContext, UiStoreInitState, UiStoreStateContext } from './context';
import uiStoreReducer from './reducer';

import useUiStoreActionsHook from './useUiStoreActions';

interface ModalProviderProps {
  children?: ReactNode;
}

function UiStoreProvider({ children }: ModalProviderProps) {
  const [state, dispatch] = useReducer(uiStoreReducer, UiStoreInitState);

  const uiStoreAction = useUiStoreActionsHook(dispatch);

  return (
    <UiStoreStateContext.Provider value={state}>
      <UiStoreActionsContext.Provider value={uiStoreAction}>{children}</UiStoreActionsContext.Provider>
    </UiStoreStateContext.Provider>
  );
}

function useUiStoreState() {
  const context = useContext(UiStoreStateContext);
  if (context === undefined) {
    throw new Error('UI Store State Context must be used within the Workspace');
  }
  return context;
}

function useUiStoreActions() {
  const context = useContext(UiStoreActionsContext);
  if (context === undefined) {
    throw new Error('UI Store Actions Context must be used within the Workspace');
  }
  return context;
}

export { UiStoreProvider, useUiStoreState, useUiStoreActions };
