import { LocalStorage } from 'enums';
import { useLocalStorage } from 'hooks';

export default function useActivateOnce(activateOnceType: string) {
  const [activatedBeforeByType, setActivatedBeforeByType] = useLocalStorage<{ [key in string]?: boolean }>(
    LocalStorage.ActivateOnce,
    {},
  );

  const activate = () => {
    if (!activatedBeforeByType[activateOnceType]) {
      setActivatedBeforeByType((prevTooltipInfo) => ({
        ...prevTooltipInfo,
        [activateOnceType]: true,
      }));
    }
  };

  return {
    activate,
    shownBefore: activatedBeforeByType[activateOnceType],
  };
}
