import { ApiError } from 'clients/errors';

export const queryRetryStrategy = (onApiErrorCallback?: () => void) => ({
  retry: (failureCount: number, error: unknown) => {
    // don't retry on Api errors (e.g 404) as they will consistently fail
    if (error instanceof ApiError) {
      onApiErrorCallback?.();
      return false;
    }
    return failureCount < 3;
  },
});
