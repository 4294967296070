import { Button, Stack } from '@common-components';
import { FormMode } from 'enums';
import { useHotjar } from 'hooks';
import { messages } from 'i18n';
import { HotjarEvents } from 'utils/hotjar-events';
import InformationTooltip from 'broker/components/InformationTooltip/InformationTooltip';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import StartMarketingButton from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/components/StartMarketingButton';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

export default function HeaderButtons() {
  const navigate = useNavigate();
  const { startMarketingIndicator } = useUiStoreState();

  const hotjar = useHotjar();
  const { createQuoteFormUrl } = useWorkspaceUrls();

  const { setStartMarketingIndicator } = useUiStoreActions();
  const { partialSubmission } = useSubmissionsWorkspace();

  const addQuote = () => {
    hotjar.event(HotjarEvents.AddQuote);
    navigate(createQuoteFormUrl, { routeKey: RouteKey.Quote, state: { mode: FormMode.create } });
  };

  return (
    <Stack gap={1} direction="row" alignItems="center" justifyContent="center">
      <Button variant="contained" color="secondary" onClick={addQuote}>
        {messages.submissionWorkspace.marketRequestsWorkspace.addMarketQuote}
      </Button>
      <InformationTooltip
        isOpen={startMarketingIndicator.showStartMarketingIndicator && !partialSubmission?.isCopilotEnabled}
        onClose={() => {
          setStartMarketingIndicator({ showStartMarketingIndicator: false, submissionId: partialSubmission!.id });
        }}
        title={messages.submissionWorkspace.marketRequestsWorkspace.startMarketingInfoToolbox.title}
        message={messages.submissionWorkspace.marketRequestsWorkspace.startMarketingInfoToolbox.message}
        width={328}
      >
        <StartMarketingButton placement="bottom-right" buttonProps={{ variant: 'contained' }} />
      </InformationTooltip>
    </Stack>
  );
}
