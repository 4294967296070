import { Box } from '@common-components';
import { SubmissionMarket } from 'types';
import ComposeEmailButtons, { EmailToMarketButton } from './ComposeEmailButtons';

interface EmailsListFooterProps {
  submissionMarket?: SubmissionMarket;
}

export default function EmailsListComposeButton({ submissionMarket }: EmailsListFooterProps) {
  return (
    <Box>
      {!submissionMarket && <ComposeEmailButtons />}
      {submissionMarket && <EmailToMarketButton submissionMarket={submissionMarket} />}
    </Box>
  );
}
