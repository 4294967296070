import { createContext, useContext } from 'react';

export const HotjarInitializedContext = createContext<boolean | null>(null);

export function useHotjarInitializedContext() {
  const hotjarInitialized = useContext(HotjarInitializedContext);
  if (hotjarInitialized === null) {
    throw new Error('Hotjar initialization state context is missing');
  }
  return hotjarInitialized;
}
