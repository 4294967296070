import { useMemo } from 'react';
import { UserRole } from 'enums';
import { useCurrentUser, useSearchSubmissionFileMetadata } from 'hooks';
import { SubmissionFileMetadata } from 'types';
import { sortBySyncRequested } from 'utils';
import useGetSubmissionRelatedEmails from './useGetSubmissionRelatedEmails';
import { EmailListItemAttributes } from './utils';

interface UseGetSubmissionEmailsWithMetadataProps {
  submissionId?: string;
  marketOrganizationId?: string;
}

function getFileSyncMetadata(submissionFileMetadata: SubmissionFileMetadata[], id?: string) {
  const submissionFileMetadataItem = submissionFileMetadata.find((item) => item.fileId === id);
  return submissionFileMetadataItem?.metadata;
}

export default function useGetSubmissionEmailsWithFileMetadata({
  submissionId,
  marketOrganizationId,
}: UseGetSubmissionEmailsWithMetadataProps) {
  const { me } = useCurrentUser();

  const {
    inboundUnassignedEmailsList: submissionInboundUnassignedEmailsList,
    submissionEmailsList,
    isLoading: isEmailsLoading,
  } = useGetSubmissionRelatedEmails({
    submissionId,
    marketOrganizationId,
  });

  const { items: submissionFileMetadata, isLoading: loadingSubmissionFileMetadata } = useSearchSubmissionFileMetadata({
    filter: {
      submissionId,
    },
    enabled: !!submissionId,
  });

  const emailsList = useMemo(() => {
    const localSubmissionEmailsList = submissionEmailsList.map(
      (email) =>
        ({
          ...email,
          syncMetadata: getFileSyncMetadata(submissionFileMetadata, email.boxFileId),
        } as EmailListItemAttributes),
    );
    if (me?.role === UserRole.BackOffice) {
      localSubmissionEmailsList.sort((a) => sortBySyncRequested(a.syncMetadata));
    }
    return localSubmissionEmailsList;
  }, [submissionEmailsList, me?.role, submissionFileMetadata]);

  const inboundUnassignedEmailsList = useMemo(() => {
    const localInboundUnassignedEmailsList = submissionInboundUnassignedEmailsList.map(
      (email) =>
        ({
          ...email,
          syncMetadata: getFileSyncMetadata(submissionFileMetadata, email.boxFileId),
        } as EmailListItemAttributes),
    );
    if (me?.role === UserRole.BackOffice) {
      localInboundUnassignedEmailsList.sort((a) => sortBySyncRequested(a.syncMetadata));
    }
    return localInboundUnassignedEmailsList;
  }, [submissionInboundUnassignedEmailsList, me?.role, submissionFileMetadata]);

  const isLoading = loadingSubmissionFileMetadata || isEmailsLoading;

  return {
    inboundUnassignedEmailsList,
    submissionEmailsList: emailsList,
    isLoading,
  };
}
