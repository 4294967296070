import { User } from 'types';
import { userFullName } from 'utils';

export enum AgenciesSortTypes {
  Asc = 'Asc',
  Desc = 'Desc',
  Updated = 'Updated',
}

export function didUserPassedFilter({ contact, searchInput }: { contact: User; searchInput: string }) {
  const contactTerms = [userFullName(contact), contact.firstName, contact.lastName, contact.email].filter(Boolean);

  return !!searchInput && contactTerms.some((term) => term?.toLowerCase().includes(searchInput.toLowerCase()));
}
