import { ReactComponent as LogoIcon } from 'assets/images/brand/flow-logo.svg';
import { uniteStyles } from 'utils';
import { SvgIcon, SvgIconProps } from 'components/mui-index';

interface AppLogoProps extends SvgIconProps {
  size?: number;
}

export default function AppLogo({ size = 1, sx }: AppLogoProps) {
  return <SvgIcon sx={uniteStyles({ fontSize: 54, zoom: size }, sx)} component={LogoIcon} viewBox="0 0 64 64" />;
}
