import * as commonTypes from '@common/types';
import { uniqBy } from 'lodash';
import { ContactRole, EmailMessageRecipientType, isCustomerOrganization, OrganizationType } from 'enums';
import {
  Contact,
  EmailMessageRecipient,
  InsuranceProduct,
  Organization,
  PartialSubmission,
  RecipientOrganizationDetails,
  SubmissionMarket,
  User,
  UserDetails,
  UserMarket,
} from 'types';
import { UnifiedEmail } from 'broker/components/Emails/UnifiedEmail';
import { isOutboundType, Recipient } from './types';

export interface RecipientGroup extends commonTypes.RecipientGroup {
  id: string;
  type: OrganizationType;
  name: string;
  recipients: Recipient[];
}

export type RecipientGroups = Record<string, RecipientGroup>;

export function convertOrganizationToRecipientGroup(organization: Organization): RecipientGroup {
  return {
    id: organization.id,
    name: organization.name,
    type: organization.type,
    recipients: [],
  };
}

export function convertUserToRecipient(user: UserDetails): Recipient {
  return {
    ...user,
    isSubmissionBox: false,
  };
}

export function convertContactToRecipient(contact: Contact): Recipient {
  return {
    ...contact,
    disabled: !!contact.disabled,
    isSubmissionBox: contact.role === ContactRole.SubmissionBox,
  };
}

export function convertRetailerContactsToRecipientGroup(contacts: Contact[]): RecipientGroup {
  return {
    id: contacts[0].organizationId,
    name: contacts[0].organizationName,
    type: OrganizationType.Retailer,
    recipients: contacts.map((contact) => convertContactToRecipient(contact)),
  };
}

export function createRecipientGroupFromSubmissionCustomer(
  submission: PartialSubmission,
  predefinedRecipients?: Recipient[],
): RecipientGroup {
  let recipients: Recipient[] = [];
  if (predefinedRecipients) {
    recipients = predefinedRecipients;
  } else if (submission.user) {
    // submission.user can potentially be NULL (if the user was deleted)
    recipients = [convertUserToRecipient(submission.user)];
  }

  return {
    id: submission.organizationId,
    name: submission.organizationName,
    type: OrganizationType.Retailer,
    recipients,
  };
}

export function convertEndUserToRecipientGroup(endUser: User): RecipientGroup {
  return {
    id: endUser.organization.id,
    name: endUser.organization.name,
    type: endUser.organization.type,
    recipients: [convertUserToRecipient(endUser)],
  };
}

export function convertSubmissionMarketToRecipientGroup(market: SubmissionMarket, contacts: Contact[]): RecipientGroup {
  return {
    id: market.userMarketId,
    name: market.marketName,
    type: market.marketOrganizationType,
    // pre-selected contacts should not be disabled ones
    recipients: contacts.filter((contact) => !contact.disabled).map((contact) => convertContactToRecipient(contact)),
  };
}

export function convertUserMarketToRecipientGroup(market: UserMarket, filteredContacts?: Contact[]): RecipientGroup {
  const contacts = filteredContacts || market.contacts;
  return {
    id: market.id,
    name: market.marketName,
    type: market.organizationType,
    recipients: contacts.map((contact) => convertContactToRecipient(contact)),
  };
}

export function convertInsuranceProductsToRecipientGroups(
  productsByUserMarket: Record<string, { products: InsuranceProduct[]; userMarket: UserMarket }>,
): RecipientGroup[] {
  return Object.values(productsByUserMarket).map(({ products, userMarket }) => ({
    id: userMarket.id,
    name: userMarket.marketName,
    type: userMarket.organizationType,
    recipients: uniqBy(
      products.flatMap((product) => product.contacts.map((contact) => convertContactToRecipient(contact))),
      'email',
    ),
  }));
}

const DUMMY_RECIPIENT_GROUP_ID = 'DUMMY_RECIPIENT_GROUP_ID';

export function convertExternalContactToRecipientGroup(emailAddress: string): RecipientGroup {
  const recipient: Partial<Recipient> = { id: 'DUMMY_USER_ID', email: emailAddress };
  return {
    id: DUMMY_RECIPIENT_GROUP_ID,
    name: emailAddress,
    recipients: [recipient],
  } as RecipientGroup;
}

export function isExternalRecipientGroup(recipientGroup: RecipientGroup) {
  return recipientGroup.id === DUMMY_RECIPIENT_GROUP_ID;
}

function getToRecipientAddresses(unifiedEmail: UnifiedEmail) {
  return unifiedEmail.recipients
    .filter((recipient) => recipient.type === EmailMessageRecipientType.To)
    .map((recipient) => recipient.address);
}

export const getReplyOrganization = (unifiedEmail: UnifiedEmail) =>
  isOutboundType(unifiedEmail.emailType)
    ? { organization: unifiedEmail.recipientOrganization, contactEmail: unifiedEmail.recipients[0]?.address! }
    : {
        organization: unifiedEmail.senderOrganization,
        contactEmail: unifiedEmail.senderEmail!,
      };

export const getReplyRecipientGroup = (
  unifiedEmail: UnifiedEmail,
  submission: PartialSubmission,
  userMarkets: UserMarket[],
  retailerTeamMembers?: Recipient[],
) => {
  const { organization, contactEmail } = getReplyOrganization(unifiedEmail);

  // If there is no organization, it has to be an outbound email that was sent to an external recipient.
  // because if it's an inbound we would have the organization.
  if (!organization) {
    return convertExternalContactToRecipientGroup(contactEmail);
  }

  const contactsAddresses = isOutboundType(unifiedEmail.emailType)
    ? getToRecipientAddresses(unifiedEmail)?.map((emailAddress) => emailAddress.toLocaleLowerCase())
    : unifiedEmail.senderEmail?.toLocaleLowerCase();

  if (isCustomerOrganization(organization.type)) {
    // If we received retailerTeamMembers then we should find the contact within them, based on the unifiedEmail
    // we reply to, instead of taking the submission.user as the default recipient
    if (retailerTeamMembers && contactsAddresses) {
      const recipientsToSet = retailerTeamMembers.filter((member) =>
        contactsAddresses.includes(member.email.toLocaleLowerCase()),
      );

      return createRecipientGroupFromSubmissionCustomer(submission, recipientsToSet);
    }

    // Fallback to the default behavior
    return createRecipientGroupFromSubmissionCustomer(submission);
  }

  const userMarket = userMarkets.find((market) => market.organizationId === organization.id);
  if (userMarket) {
    const filteredContacts = userMarket.contacts.filter((contact) =>
      contactsAddresses?.includes(contact.email.toLocaleLowerCase()),
    );
    return convertUserMarketToRecipientGroup(userMarket, filteredContacts);
  }

  return convertOrganizationToRecipientGroup(organization);
};

export const isCapitolaMail = (email: string) =>
  email.endsWith('@capitola-ins.com') || email.endsWith('@flowspecialty.com');

export const getRecipientName = (
  recipients?: EmailMessageRecipient[] | Omit<EmailMessageRecipient, 'id'>[],
  recipientOrganizationDetails?: RecipientOrganizationDetails,
) => {
  if (recipientOrganizationDetails?.name) {
    return recipientOrganizationDetails.name;
  }

  const recipient = recipients?.find(({ type }) => type === EmailMessageRecipientType.To);
  return recipient?.address;
};
