import { Button, Divider, Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import PageHeader from 'broker/components/common/layout/PageHeader';

export interface SubmissionsViewHeaderProps {
  count: number;
  filteredCount: number;
  isFilterApplied: boolean;
  selectionMode: boolean;
  onCreateMarket: () => void;
  isLoadingMarkets?: boolean;
  selectedMarketsCount?: number;
  clearSelectedMarkets?: () => void;
}

export default function MarketsHeader({
  count,
  filteredCount,
  isFilterApplied,
  onCreateMarket,
  selectedMarketsCount = 0,
  selectionMode,
  clearSelectedMarkets,
}: SubmissionsViewHeaderProps) {
  return (
    <PageHeader>
      <Stack direction="row" alignItems="baseline" gap={1}>
        <Typography variant="h4">{messages.marketsPage.header.title}</Typography>
        <Typography color="text.secondary" variant="body2">
          {isFilterApplied ? messages.general.outOfMarkets(filteredCount.toString(), count.toString()) : count}
        </Typography>
        {selectionMode && (
          <Stack direction="row" alignItems="center">
            <Typography color="textSecondary" variant="body2" sx={{ minWidth: 75 }}>
              {`/  ${selectedMarketsCount} ${messages.marketsGeneral.selected}`}
            </Typography>
            {selectedMarketsCount > 0 && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    alignSelf: 'center',
                    height: 16,
                    mx: 1,
                  }}
                />
                <Button onClick={clearSelectedMarkets}>{messages.buttons.clear}</Button>
              </>
            )}
          </Stack>
        )}
      </Stack>
      <Button variant="contained" onClick={() => onCreateMarket()}>
        {messages.marketsPage.header.createMarket}
      </Button>
    </PageHeader>
  );
}
