import { Button, Stack, Typography } from '@common-components';
import { ReplyOutlined, WarningAmber } from '@icons';
import { messages } from 'i18n';
import { EmailEditorPageMode } from 'broker/enums';
import useNavigateToLatestCustomerEmail from './useNavigateToLatestCustomerEmail';

export interface MissingInformationBannerProps {
  level: 'warning' | 'error';
  isLoadingMissingInformation: boolean;
}

export default function MissingInformationBanner({
  level,
  isLoadingMissingInformation,
}: MissingInformationBannerProps) {
  const { missingInformationBanner } = messages.submissionWorkspace;
  const description = level === 'error' ? missingInformationBanner.description : null;
  const { navigateToLatestCustomerEmail, isLoading: isLoadingEmails } = useNavigateToLatestCustomerEmail({
    emailEditorPageMode: EmailEditorPageMode.MissingInformationRequest,
  });

  return (
    <Stack direction="row" gap={1} my={1} py={1} px={1.5} alignItems="center" bgcolor={`${level}.light`}>
      <Stack direction="row" alignItems="flex-start" flex={1} gap={1}>
        <WarningAmber color={level} fontSize="small" />
        <Stack overflow="hidden">
          <Typography noWrap variant="body2Bold" color="text.primary">
            {missingInformationBanner.title[level]}
          </Typography>
          {description && (
            <Typography variant="caption" color="text.primary">
              {description}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Button
        size="medium"
        color="inherit"
        variant="outlined"
        startIcon={<ReplyOutlined />}
        loading={isLoadingEmails || isLoadingMissingInformation}
        onClick={navigateToLatestCustomerEmail}
      >
        {missingInformationBanner.button}
      </Button>
    </Stack>
  );
}
