import { BoxItem } from 'box-ui-elements/es';
import { Box, Button, Stack } from '@common-components';
import { Visibility } from '@icons';
import { EmailTemplateType } from 'enums';
import { useToast } from 'hooks';
import { messages } from 'i18n';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';
import SendEmailButton from 'broker/components/Emails/SendEmailButton';

export interface ProposalHeaderProps {
  isPreviewMode: boolean;
  togglePreviewMode: () => void;
  saveProposal: () => Promise<void>;
  sendProposal: () => Promise<void>;
  isSaving: boolean;
  isDirty: boolean;
  attachedFiles: BoxItem[];
  recipientGroup: RecipientGroup;
  emailTemplateType?: EmailTemplateType;
  isSendDisabled: boolean;
  isCopilotEnabled?: boolean;
  resolvedMessages: { body: string; subject: string }[];
}

export const ProposalActionButtons = ({
  isPreviewMode,
  togglePreviewMode,
  sendProposal,
  saveProposal,
  isDirty,
  isSaving,
  attachedFiles,
  recipientGroup,
  emailTemplateType,
  isSendDisabled,
  isCopilotEnabled,
  resolvedMessages,
}: ProposalHeaderProps) => {
  const { showToast } = useToast();

  const handleSaveClick = async () => {
    await saveProposal();
    showToast('success', { message: messages.submissionWorkspace.customerSection.proposalSaved });
  };

  const handleSendProposalClick = async () => {
    await Promise.all([saveProposal(), sendProposal()]);
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} mx={3} mb={2}>
      <Box flex={1} justifyContent="start">
        <Button
          variant="text"
          onClick={togglePreviewMode}
          startIcon={<Visibility />}
          disabled={!recipientGroup.recipients.length}
        >
          {isPreviewMode
            ? messages.submissionWorkspace.customerSection.edit
            : messages.submissionWorkspace.customerSection.preview}
        </Button>
      </Box>
      <Button variant="outlined" loading={isSaving} onClick={handleSaveClick} disabled={!isDirty}>
        {messages.submissionWorkspace.customerSection.save}
      </Button>
      <SendEmailButton
        onSend={handleSendProposalClick}
        attachedFiles={attachedFiles}
        isDisabled={isSendDisabled}
        disabledTooltipContent={messages.submissionWorkspace.customerSection.sendProposalDisabledTooltip}
        recipientGroups={[recipientGroup]}
        buttonCaption={messages.submissionWorkspace.customerSection.sendProposal}
        emailTemplateType={emailTemplateType}
        isCustomerEmail
        isCopilotEnabled={isCopilotEnabled}
        resolvedMessages={resolvedMessages}
      />
    </Stack>
  );
};
