import { BoxItem } from 'box-ui-elements/es';
import { useCallback } from 'react';
import { BoxTemplateLabels } from 'enums';
import { useBoxClient, useHotjar } from 'hooks';
import { useMutateBoxItems } from 'hooks/api/box';
import { getFileMetadataField } from 'utils/files-utils';
import { HotjarEvents } from 'utils/hotjar-events';

export function useFileActions() {
  const hotjar = useHotjar();

  const boxClient = useBoxClient();
  const {
    deleteFile: deleteFileApi,
    updateFile: updateFileApi,
    addFileMetadata,
    updateFileMetadata,
    deleteFileMetadata,
  } = useMutateBoxItems(true);

  const deleteFile = useCallback(
    async (item: BoxItem) => {
      await deleteFileApi.mutateAsync({ fileId: item.id });
      hotjar.event(HotjarEvents.DeleteFile);
    },
    [deleteFileApi, hotjar],
  );

  const downloadFile = useCallback(
    async (file: BoxItem) => {
      await boxClient.downloadFileToBrowser(file);
      hotjar.event(HotjarEvents.DownloadFile);
    },
    [boxClient, hotjar],
  );
  const renameFile = useCallback(
    async (file: BoxItem, newName: string) => {
      await updateFileApi.mutateAsync({ fileId: file.id, fields: { name: newName } });
      hotjar.event(HotjarEvents.RenameFile);
    },
    [hotjar, updateFileApi],
  );

  const editFileLabel = useCallback(
    async (file: BoxItem, labelFieldName: BoxTemplateLabels, selectedLabel?: string) => {
      const existingLabel = getFileMetadataField(file, labelFieldName);

      if (existingLabel === selectedLabel) {
        return;
      }

      if (!selectedLabel) {
        await deleteFileMetadata.mutateAsync({ fileId: file.id, metadataFields: [labelFieldName] });
      } else if (!existingLabel) {
        await addFileMetadata.mutateAsync({ fileId: file.id, metadata: { [labelFieldName]: selectedLabel } });
      } else {
        await updateFileMetadata.mutateAsync({ fileId: file.id, metadata: { [labelFieldName]: selectedLabel } });
      }

      if (labelFieldName === BoxTemplateLabels.FileType) {
        hotjar.event(HotjarEvents.EditLabel);
      }
    },
    [addFileMetadata, deleteFileMetadata, hotjar, updateFileMetadata],
  );

  const downloadFileAsBlob = useCallback(async (fileId: string) => boxClient.downloadFileAsBlob(fileId), [boxClient]);

  return {
    editFileLabel,
    renameFile,
    deleteFile,
    downloadFile,
    downloadFileAsBlob,
  };
}
