import { QuoteFileIdType } from 'enums';
import { messages } from 'i18n';
import { Quote } from 'types';

export type QuoteFileKey = keyof Pick<Quote, QuoteFileIdType>;

type QuoteFileKeyConfig = {
  [key in QuoteFileKey]: { title: string };
};

export const quoteFileKeyConfig: QuoteFileKeyConfig = {
  [QuoteFileIdType.MarketQuote]: {
    title: messages.market.filesTypeName.marketQuoteFileIds,
  },
  [QuoteFileIdType.Binder]: {
    title: messages.market.filesTypeName.binderFileIds,
  },
  [QuoteFileIdType.Policy]: {
    title: messages.market.filesTypeName.policyFileIds,
  },
};
