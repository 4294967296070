import { uniq } from 'lodash';
import { useMemo } from 'react';
import { useSearchUserMarket } from 'hooks';

export default function useUserMarketCategories() {
  const { items: userMarkets } = useSearchUserMarket();

  const existingCategories = useMemo(
    () => uniq(userMarkets.flatMap((market) => market.categories ?? [])).sort(),
    [userMarkets],
  );

  return { existingCategories };
}
