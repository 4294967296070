import { EmailTemplateVariables } from '@common/email-variables';
import { FormMode } from 'enums';
import { messages } from 'i18n';
import { BROKER_NESTED_ROUTES, QuoteFormRoutes } from 'broker/broker-routes';
import { EmailTemplateContext } from 'broker/components/EmailEditor/store/types';
import { RouteKey } from 'broker/enums';
import { NavigateOptions } from 'broker/hooks';

export interface SmartObjectProps {
  key: string;
  label: string;
  navigationRoute: string;
  navigationOptions: (emailTemplateContext?: Omit<EmailTemplateContext, 'recipient'>) => NavigateOptions;
  isSmartReport: boolean;
  hideIcon?: boolean;
  description?: string;
}

export type SmartObjectsConfig = Record<string, SmartObjectProps>;
export const smartObjectsConfig: SmartObjectsConfig = {
  [EmailTemplateVariables.conceptualTower]: {
    key: EmailTemplateVariables.conceptualTower,
    label: messages.submissionWorkspace.customerSection.conceptualTower.label,
    description: messages.submissionWorkspace.customerSection.conceptualTower.description,
    navigationRoute: BROKER_NESTED_ROUTES.WORKSPACE.CONCEPTUAL_TOWER,
    navigationOptions: () => ({ routeKey: RouteKey.ConceptualTower, state: { viewOnly: true } }),
    hideIcon: true,
    isSmartReport: true,
  },
  [EmailTemplateVariables.informationRequested]: {
    key: EmailTemplateVariables.informationRequested,
    label: messages.submissionWorkspace.customerSection.informationRequests.label,
    description: messages.submissionWorkspace.customerSection.informationRequests.description,
    navigationRoute: BROKER_NESTED_ROUTES.WORKSPACE.MARKETING_REPORT,
    navigationOptions: () => ({ routeKey: RouteKey.MarketingReport, state: { isInformationRequestsReport: true } }),
    isSmartReport: true,
  },
  [EmailTemplateVariables.marketingReport]: {
    key: EmailTemplateVariables.marketingReport,
    label: messages.submissionWorkspace.customerSection.marketingReport.label,
    description: messages.submissionWorkspace.customerSection.marketingReport.description,
    navigationRoute: BROKER_NESTED_ROUTES.WORKSPACE.MARKETING_REPORT,
    navigationOptions: () => ({ routeKey: RouteKey.MarketingReport, state: { saveMarketingReportFlow: true } }),
    isSmartReport: true,
  },
  [EmailTemplateVariables.quote]: {
    key: EmailTemplateVariables.quote,
    label: messages.submissionWorkspace.customerSection.yoyReport.label,
    description: messages.submissionWorkspace.customerSection.yoyReport.description,
    navigationRoute: `${BROKER_NESTED_ROUTES.WORKSPACE.QUOTE}/${QuoteFormRoutes.Details}`,
    navigationOptions: (emailTemplateContext?: Omit<EmailTemplateContext, 'recipient'>) => ({
      routeKey: RouteKey.Quote,
      state: { selectedQuote: emailTemplateContext?.triggeringQuote, mode: FormMode.edit },
    }),
    isSmartReport: false,
  },
};
