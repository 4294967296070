import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Route, Routes } from 'react-router-dom-latest';
import { Button, LoaderOverlay } from '@common-components';
import { QuoteFileIdType } from 'enums';
import { useBoolean, useMutateQuote, useSearchSubmissionFileMetadata } from 'hooks';
import { messages } from 'i18n';
import { Layer, Suggestion } from 'types';
import { useServerPushNotifications } from 'utils';
import { QuoteFormRoutes } from 'broker/broker-routes';
import { ExtendedBoxItem } from 'broker/components/FilesExplorer/types';
import { getFileWithSyncMetadata } from 'broker/components/FilesExplorer/utils';
import { QuoteConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/config';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import Details from './formSteps/Details';
import FlowQuote from './formSteps/FlowQuote';
import QuoteInsights from './formSteps/QuoteInsights';
import Upload from './formSteps/Upload';
import { QuoteFormProps } from './types';
import useGetSelectProducts from './useGetSelectProducts';

interface QuoteFormFieldsProps extends Omit<QuoteFormProps, 'fileIds' | 'onClose'> {
  marketQuoteFileIds: string[];
  setFlowQuoteIds: (flowQuoteId: string[]) => void;
  extractedDataSuggestions?: Record<string, Suggestion>;
  isDirty: boolean;
  quoteConfig: QuoteConfig;
}

export default function QuoteFormFields(quoteFormProps: QuoteFormFieldsProps) {
  const {
    currentStep,
    mode,
    submission,
    submissionMarkets,
    layers,
    fileType,
    products,
    productsLoading,
    selectedProductId,
    marketQuoteFileIds,
    flowQuoteIds,
    setFlowQuoteIds,
    flowGenerationStatus,
    setFlowGenerationStatus,
    hasMarketQuoteFileLabel,
    isRequiredLabelMissing,
    extractedDataSuggestions,
    setInsightPopulatedByAI,
    isDirty,
    selectedLayer,
    quoteConfig,
    selectedQuote,
  } = quoteFormProps;

  const selectProducts = useGetSelectProducts({ submissionMarkets, insuranceProducts: products });

  const marketName = products.find((product) => product.id === selectedProductId)?.marketName ?? '';
  const [formLayers, setLayers] = useState(layers);
  const {
    filesExplorer: { selectedFiles },
  } = useUiStoreState();
  const { setFilesExplorer } = useUiStoreActions();

  const [isExtracting, { on: startExtraction, off: stopExtraction }] = useBoolean(false);

  const setSelectedFiles = useCallback(
    (boxItems: ExtendedBoxItem[]) => setFilesExplorer({ selectedFiles: boxItems, isLoaded: true }),
    [setFilesExplorer],
  );

  const { refetch: fetchFilesMetadata } = useSearchSubmissionFileMetadata({
    filter: {
      submissionId: submission.id,
    },
  });

  const { extractQuoteData, extractPolicyData } = useMutateQuote();

  const updateFilesWithNewMetadata = async () => {
    const { data } = await fetchFilesMetadata();
    if (!isEmpty(selectedFiles)) {
      setSelectedFiles(selectedFiles.map((file) => getFileWithSyncMetadata(file, data?.items)));
    }
  };

  useServerPushNotifications(async ({ event, data }) => {
    if (event !== 'quote-extraction-complete') {
      return;
    }

    const parsedMessage = JSON.parse(data.message);
    const { fileId } = parsedMessage;

    if (fileId && isExtracting) {
      await updateFilesWithNewMetadata();
    }

    stopExtraction();
  }, 'quote-extraction');

  const requestExtraction = async ({ fileId }: { fileId: string }) => {
    startExtraction();
    const data = {
      submissionId: submission.id,
      fileId,
    };
    if (fileType === QuoteFileIdType.MarketQuote) {
      await extractQuoteData(data);
    } else if (fileType === QuoteFileIdType.Policy) {
      await extractPolicyData(data);
    }
  };

  const addLayer = (layer: Layer) => {
    setLayers(formLayers.concat(layer));
  };

  return (
    <>
      <Routes>
        <Route
          path={`${QuoteFormRoutes.Upload}/*`}
          element={
            <Upload
              quoteConfig={quoteConfig}
              currentStep={currentStep}
              fileType={fileType}
              mode={mode}
              submission={submission}
              marketName={marketName}
              isRequiredLabelMissing={isRequiredLabelMissing}
              onContinue={async () => {
                const { fileToExtract } = quoteConfig;
                if (quoteConfig.isFileExtractionSupported && !fileToExtract?.syncMetadata) {
                  await requestExtraction({ fileId: fileToExtract?.id || '' });
                }
              }}
            />
          }
        />
        <Route
          path={`${QuoteFormRoutes.Details}/*`}
          element={
            <Details
              quoteConfig={quoteConfig}
              fileType={fileType}
              mode={mode}
              layers={formLayers}
              selectProducts={selectProducts}
              productsLoading={productsLoading}
              initialProductId={selectedProductId}
              initialLayer={selectedLayer}
              onAddNewLayer={addLayer}
              extractedDataSuggestions={extractedDataSuggestions}
              marketQuoteFileIds={marketQuoteFileIds}
              setInsightPopulatedByAI={setInsightPopulatedByAI}
              submission={submission}
              selectedQuote={selectedQuote}
              hasMarketQuoteFileLabel={hasMarketQuoteFileLabel}
            />
          }
        />
        <Route
          path={`${QuoteFormRoutes.Insights}/*`}
          element={<QuoteInsights mode={mode} hasMarketQuoteFileLabel={hasMarketQuoteFileLabel} />}
        />
        <Route
          path={`${QuoteFormRoutes.FlowQuote}/*`}
          element={
            <FlowQuote
              mode={mode}
              submission={submission}
              marketQuoteFileIds={marketQuoteFileIds}
              marketName={marketName}
              flowQuoteIds={flowQuoteIds}
              setFlowQuoteIds={setFlowQuoteIds}
              flowGenerationStatus={flowGenerationStatus}
              setFlowGenerationStatus={setFlowGenerationStatus}
              isDirty={isDirty}
            />
          }
        />
      </Routes>
      {isExtracting && (
        <LoaderOverlay
          text={messages.addQuotePage.extractingData}
          extraContent={<Button onClick={stopExtraction}>{messages.general.abort}</Button>}
        />
      )}
    </>
  );
}
