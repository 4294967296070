import { Contact } from 'types';
import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { convertContactToRecipient } from 'broker/components/Emails/recipient-utils';
import AddContactToMarketModal from 'broker/dialogs/AddContactToMarketModal';

export default function EmailEditorAddContactToMarketModal() {
  const {
    customerContacts: { closeAddContactToMarketModal },
    recipients: { addRecipient },
  } = useEmailEditorActions();

  const {
    customerContacts: { isAddContactToMarketOpen, addContactToMarketMetadata },
  } = useEmailEditorState();

  return addContactToMarketMetadata ? (
    <AddContactToMarketModal
      isOpen={isAddContactToMarketOpen}
      onContactAdded={(contact: Contact, userMarketId: string) => {
        addRecipient(convertContactToRecipient(contact), userMarketId);
      }}
      onClose={closeAddContactToMarketModal}
      metaData={addContactToMarketMetadata}
    />
  ) : null;
}
