/* global google */

import { InsuredStructuredAddress } from 'types';

export function mapGoogleMapResultToStructuredAddress(
  googleResult: google.maps.places.PlaceResult,
): InsuredStructuredAddress & { route?: string; city?: string; postalCode?: string; streetNumber?: string } {
  return {
    placeId: googleResult.place_id,
    state: googleResult.address_components?.reduce<string | undefined>((current, addressComponent) => {
      if (addressComponent.types.includes('administrative_area_level_1')) {
        return addressComponent.short_name;
      }
      return current;
    }, undefined),
    country: googleResult.address_components?.reduce<string | undefined>((current, addressComponent) => {
      if (addressComponent.types.includes('country')) {
        return addressComponent.short_name;
      }
      return current;
    }, undefined),
    route: googleResult.address_components?.reduce<string | undefined>((current, addressComponent) => {
      if (addressComponent.types.includes('route')) {
        return addressComponent.short_name;
      }
      return current;
    }, undefined),
    city: googleResult.address_components?.reduce<string | undefined>((current, addressComponent) => {
      if (addressComponent.types.includes('locality')) {
        return addressComponent.short_name;
      }
      return current;
    }, undefined),
    postalCode: googleResult.address_components?.reduce<string | undefined>((current, addressComponent) => {
      if (addressComponent.types.includes('postal_code')) {
        return addressComponent.short_name;
      }
      return current;
    }, undefined),

    streetNumber: googleResult.address_components?.reduce<string | undefined>((current, addressComponent) => {
      if (addressComponent.types.includes('street_number')) {
        return addressComponent.short_name;
      }
      return current;
    }, undefined),
  };
}
