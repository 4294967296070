import { MENTION_PREFIX } from '@common/email-variables';
import { isEmpty } from 'lodash';
import { Editor } from 'tinymce';

import { toMentionTagString } from './utils/mentions';

export declare type MentionProps = { text: string; value: string };
const getSuggestions = (mentions: MentionProps[], pattern: string) =>
  mentions.filter((char) => char.text.toLowerCase().indexOf(pattern.toLowerCase()) !== -1);

const onAutocompleteSuggestionSelected = (
  autocompleteApi: { hide: () => void },
  range: Range,
  value: string,
  editor: Editor,
  resolveVariable?: (variable: string) => string,
) => {
  editor.selection.setRng(range);
  // When resolving an autocomplete we add the space tag (&nbsp;) at the end to put the caret outside the outer span element and allow immediate further typing.
  editor.insertContent(`${resolveVariable?.(value) ?? toMentionTagString(value)} `);
  autocompleteApi.hide();
};

export const addMentionToolbarButton = (editor: any, mentionKeys: string[]) => {
  if (isEmpty(mentionKeys)) {
    return;
  }

  editor.ui.registry.addMenuButton('mentions', {
    text: '',
    icon: 'plus',
    fetch: (callback: any) => {
      const items = mentionKeys?.map((key) => ({
        type: 'menuitem',
        text: key,
        onAction: () => editor.insertContent(toMentionTagString(key)),
      }));
      callback(items);
    },
  });
};

export const addMentionsAutocomplete = (
  editor: any,
  mentions: MentionProps[],
  resolveVariable?: (variable: string) => string,
) => {
  editor.ui.registry.addAutocompleter('mentions', {
    ch: MENTION_PREFIX,
    minChars: 0,
    columns: 1,
    highlightOn: ['char_name'],
    onAction: (autocompleteApi: { hide: () => void }, range: Range, value: string) => {
      onAutocompleteSuggestionSelected(autocompleteApi, range, value, editor, resolveVariable);
    },
    fetch(pattern: string) {
      return new Promise<any[]>((resolve) => {
        const results = getSuggestions(mentions, pattern).map((char) => ({
          type: 'cardmenuitem',
          value: char.value,
          label: char.text,
          items: [
            {
              type: 'cardcontainer',
              direction: 'vertical',
              items: [
                {
                  type: 'cardtext',
                  text: char.text,
                  name: 'char_name',
                },
              ],
            },
          ],
        }));
        resolve(results);
      });
    },
  });
};
