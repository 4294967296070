import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { UserClient } from 'clients';
import { useAuthenticationContext } from 'contexts';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
import { LoggedInUser } from 'types';
import { UseQueryBaseRetrieveOptions } from './query/types';
import { cachedQueryOptions, defaultQueryOptions } from './query/useQuery';

const BOX_TOKEN_EXPIRATION_BUFFER = 5000; // 5 seconds

function setBoxTokenExpirationDate(meUser: LoggedInUser) {
  const { boxAccessToken } = meUser;
  const { expiresInMS, expirationDate } = boxAccessToken || {};

  if (!!expiresInMS && expirationDate === undefined) {
    // Calculating the time for the Box token to expire, reducing a buffer to account for
    // network delay etc.
    // Calculation is done only when expirationDate is undefined, to make sure we are not overriding a value that
    // was already calculated externally
    boxAccessToken.expirationDate = Date.now() + expiresInMS - BOX_TOKEN_EXPIRATION_BUFFER;
  }
}

function replaceWithEndUserBoxFolderId(meUser: LoggedInUser) {
  if (meUser.endUser) {
    // override boxFolderId because we never use the boxFolderId of the backoffice user
    const endUserBoxFolderId = meUser.endUser.organization.boxFolderId;
    meUser.organization.boxFolderId = endUserBoxFolderId;
  }
}

export function useCurrentUser(options?: Pick<UseQueryBaseRetrieveOptions<LoggedInUser>, 'queryOptions'>) {
  const client = useClient(UserClient);
  const { isAuthenticated } = useAuthenticationContext();

  const retrieveMe = async () => {
    const meUser = await client.retrieveMe();

    if (!meUser) {
      return null;
    }

    setBoxTokenExpirationDate(meUser);
    replaceWithEndUserBoxFolderId(meUser);

    return meUser;
  };

  const { data, isInitialLoading, refetch } = useQuery([QueryKeys.Users, { id: 'me' }], retrieveMe, {
    ...defaultQueryOptions,
    ...cachedQueryOptions,
    ...options?.queryOptions,
    enabled: isAuthenticated,
  });

  const me = useMemo(() => data ?? null, [data]);
  const endUser = useMemo(() => me?.endUser || me, [me]);

  return {
    me,
    endUser,
    refetchMe: refetch,
    isInitialLoading,
  };
}
