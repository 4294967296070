import { SubmissionStatus } from 'enums';
import { submissionStatusConfig } from 'broker/configuration-mappers/submission-status-config';
import { useUiStoreActions } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

export interface ShowStatusChangeDialogProps {
  current: SubmissionStatus;
  newStatus: SubmissionStatus;
}

export function useStatusChangeDialog() {
  const { setSuggestedSubmissionStatus } = useUiStoreActions();

  const showStatusChangeDialog = ({ current, newStatus }: ShowStatusChangeDialogProps) => {
    const shouldUpdateStatus =
      submissionStatusConfig[current].sortPriority < submissionStatusConfig[newStatus].sortPriority;
    if (shouldUpdateStatus) {
      setSuggestedSubmissionStatus({ suggestedSubmissionStatus: newStatus });
    }
  };

  return { showStatusChangeDialog };
}
