import { throttle } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

/**
 * Scroll to the bottom of the content when it changes.
 * If the content wasn't changed - don't scroll.
 * @param content
 */
export const useScrollToBottomOnContentChange = (content: any) => {
  const belowContentRef = useRef<HTMLDivElement | null>(null);
  const isInitialMount = useRef(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrollToBottom = useCallback(
    throttle(() => {
      if (belowContentRef.current) {
        belowContentRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200),
    [],
  );

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      scrollToBottom();
    }
  }, [content, scrollToBottom]);

  return belowContentRef;
};
