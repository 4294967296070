import { SvgIcon, SxProps, Theme } from '@common-components';
import { extractExtensionFromFile, fileExtensionToIcon } from 'utils/files-utils';

interface FileTypeIconProps {
  fileNameOrExtension?: string;
  styles?: SxProps<Theme>;
}

/**
 * Returns the icon for a given file type or a general File icon if no match is found
 * */
export default function FileTypeIcon({ fileNameOrExtension = '', styles }: FileTypeIconProps) {
  const fileExtension =
    fileNameOrExtension.indexOf('.') < 0 ? fileNameOrExtension : extractExtensionFromFile(fileNameOrExtension);

  const fileIcon = fileExtensionToIcon(fileExtension);

  return <SvgIcon component={fileIcon} sx={{ width: 28, height: 28, ...styles }} viewBox="0 0 28 28" />;
}
