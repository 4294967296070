import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Backdrop, CircularProgress } from '@common-components';
import { useCurrentUser } from 'hooks';
import DefaultLoggedInRedirect from 'components/DefaultLoggedInRedirect';

export default function LandingPage() {
  const { loginWithRedirect } = useAuth0();
  const { me } = useCurrentUser();

  useEffect(() => {
    if (!me) {
      loginWithRedirect();
    }
  }, [loginWithRedirect, me]);

  if (me) {
    return <DefaultLoggedInRedirect />;
  }

  return (
    <Backdrop open invisible>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
