import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';

export const useApplyDirtyChanges = () => {
  const {
    draftEditing: { isEditingContentDirty },
    draftEditorMode,
  } = useEmailEditorState();

  const {
    draftEditing: { onEditForAllSave },
  } = useEmailEditorActions();

  const applyChanges = () => {
    if (!isEditingContentDirty) {
      return;
    }

    if ([DraftEditorMode.EditForAll, DraftEditorMode.Preview].includes(draftEditorMode)) {
      onEditForAllSave();
    }
  };

  return { applyChanges };
};
