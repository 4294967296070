import { ReactNode } from 'react';
import { Paper, Stack, Typography } from '@common-components';
import backgroundImage from 'assets/images/submission-workspace/empty-state.svg';

interface NoItemsFoundProps {
  title: string;
  subTitleBeforeLink?: string;
  expiringProgramLink?: string;
  subTitleAfterLink?: string;
  children?: ReactNode;
}

export default function NoItemsFound({ title, children }: NoItemsFoundProps) {
  return (
    <Stack
      component={Paper}
      justifyContent="center"
      alignItems="center"
      gap={2}
      flex={1}
      variant="outlined"
      className="cap-no-items-found"
      p={3}
    >
      <img
        width={202}
        height={197}
        src={`${backgroundImage}?w=202&h=197&fit=crop&auto=format`}
        srcSet={`${backgroundImage}?w=202&h=197&fit=crop&auto=format&dpr=2 2x`}
        alt="default-state"
        loading="lazy"
      />
      <Typography textAlign="center" maxWidth={300} variant="body1" color="textSecondary">
        {title}
      </Typography>
      {children}
    </Stack>
  );
}
