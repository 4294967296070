import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom-latest';
import { Box, Divider, Stack } from '@common-components';
import { useSearchBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { Quote } from 'types';
import { useUpdateQueryParam } from 'broker/hooks';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { QuoteInfo } from 'broker/pages/SubmissionWorkspacePage/dialogs/CompareQuotesModal/components/QuotesSideBySide/components/QuoteDetails/QuoteInfo';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreActions } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import FilesGrid from './FilesGrid';
import { UrlParams } from './utils';

const getQuoteFileIds = (quote?: Quote) => {
  let fileIds: Array<string> = [];

  if (!quote) {
    return fileIds;
  }

  if (quote.policyFileIds && quote.policyFileIds.length > 0) {
    fileIds = quote.policyFileIds;
  }

  if (quote.binderFileIds && quote.binderFileIds.length > 0) {
    fileIds = quote.binderFileIds;
  }

  if (quote.capitolaQuoteFileIds && quote.capitolaQuoteFileIds.length > 0) {
    fileIds = quote.capitolaQuoteFileIds;
  }

  return fileIds;
};

export default function QuoteReview() {
  const [searchParams] = useSearchParams();
  const quoteId = searchParams.get(UrlParams.Id);

  const { submission, quotes, layers } = useSubmissionsWorkspace();
  const quote = quotes.find((q) => q.id === quoteId);
  const layer = layers.find((l) => l.id === quote?.layerId);

  const { setFilesExplorer } = useUiStoreActions();
  const updateQueryParam = useUpdateQueryParam();

  const { comments } = quote!;
  const fileIds = useMemo(() => getQuoteFileIds(quote), [quote]);

  useEffect(() => {
    if (fileIds && fileIds.length > 0) {
      setFilesExplorer({
        visibleFileIds: fileIds,
      });

      updateQueryParam({
        addParams: [{ queryParam: UrlParams.SelectedFile, value: fileIds[0] }],
      });
    }
  }, [fileIds, updateQueryParam, setFilesExplorer]);

  const { items: files, isLoading: filesLoading } = useSearchBoxItems({
    filter: { chunkSize: 20, folderId: submission?.boxFolderId! },
  });

  const quoteFiles = files?.filter((file) => fileIds?.includes(file.id));

  return (
    quote && (
      <InnerPageLayout className="cap-quote-review-page" title={messages.quoteReview.reviewQuote} sx={{ p: 0 }}>
        {() => (
          <Box maxWidth={640} border="1px solid" borderColor="grey.200" p={4} my={8} mx="auto">
            {quote && (
              <Stack direction="column" gap={4}>
                {comments && (
                  <>
                    <div dangerouslySetInnerHTML={{ __html: comments }} />
                    <Divider />
                  </>
                )}
                {!filesLoading && <FilesGrid files={quoteFiles} />}
                <QuoteInfo quote={quote} layer={layer!} />
              </Stack>
            )}
          </Box>
        )}
      </InnerPageLayout>
    )
  );
}
