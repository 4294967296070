import { BoxSkeleton } from '@common-components';
import TextSkeleton from './TextSkeleton';

export default function FormFieldSkeleton({ index }: { index?: number }) {
  return (
    <>
      <TextSkeleton index={index} width={80} isLoading />
      <BoxSkeleton index={index} sx={{ width: 1, height: 50 }} isLoading />
    </>
  );
}
