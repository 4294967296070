import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';

type ColumnConfigType = {
  title: string;
  flex: number | string;
  align?: 'left' | 'center' | 'right' | 'inherit' | 'justify';
  paddingX?: number;
  maxWidth?: number;
};

export enum ProductColumns {
  SelectProduct = 'SelectProduct',
  Product = 'Product',
  AvailableVia = 'AvailableVia',
  Match = 'Match',
  Notes = 'Notes',
}

type ProductColumnsConfig = Record<ProductColumns, ColumnConfigType>;

export const columnConfig: ProductColumnsConfig = {
  [ProductColumns.SelectProduct]: {
    title: '',
    flex: '0 0 64px',
    align: 'center',
  },
  [ProductColumns.Product]: {
    title: messages.addProductsPage.columnHeaders.product,
    flex: '0 0 260px',
    align: 'left',
  },
  [ProductColumns.AvailableVia]: {
    title: messages.addProductsPage.columnHeaders.availableVia,
    flex: '0 0 260px',
    align: 'left',
  },
  [ProductColumns.Match]: {
    title: messages.addProductsPage.columnHeaders.match,
    flex: 1,
    align: 'left',
  },
  [ProductColumns.Notes]: {
    title: messages.addProductsPage.columnHeaders.notes,
    flex: 1,
    maxWidth: 720,
    align: 'left',
  },
};

export default function ProductsTableHeader() {
  return (
    <Stack
      bgcolor="common.white"
      position="sticky"
      top={0}
      flex={0}
      zIndex={(theme) => theme.zIndex.appBar}
      direction="row"
      alignItems="center"
    >
      <Stack
        width={1}
        border={1}
        borderColor="divider"
        borderRadius="8px 8px 0 0"
        bgcolor="common.white"
        direction="row"
        alignItems="center"
        flex={1}
        px={1.5}
        py={1}
        gap={3}
      >
        {Object.keys(columnConfig).map((key) => {
          const column = columnConfig[key as ProductColumns];
          return (
            <Typography
              variant="body2"
              component="div"
              color="typography.nonEssential"
              align={column.align}
              flex={column.flex}
              maxWidth={column.maxWidth}
              paddingX={column.paddingX}
              key={key}
            >
              {column.title}
            </Typography>
          );
        })}
      </Stack>
    </Stack>
  );
}
