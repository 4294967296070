import { Typography } from '@common-components';

interface SectionLabelProps {
  label: string;
}

export function SectionLabel({ label }: SectionLabelProps) {
  return (
    <Typography variant="captionBold" color="text.secondary" px={0.5}>
      {label}
    </Typography>
  );
}
