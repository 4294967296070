import { ReactNode } from 'react';
import { AdapterMoment, LocalizationProvider, ThemeProvider } from '@common-components';
import { Auth0ContextProvider } from './components/Auth0ContextProvider';
import ToastProvider from './components/ToastProvider';
import { HotjarInitializedContext, ReactQueryProvider } from './contexts';
import { ErrorContextProvider } from './contexts/error';
import theme from './themes';

export const AppContextProviders = ({
  isHotjarInitialized,
  children,
}: {
  isHotjarInitialized: boolean;
  children: ReactNode;
}) => (
  <HotjarInitializedContext.Provider value={isHotjarInitialized}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ToastProvider>
          <Auth0ContextProvider>
            <ErrorContextProvider>
              <ReactQueryProvider>{children}</ReactQueryProvider>
            </ErrorContextProvider>
          </Auth0ContextProvider>
        </ToastProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </HotjarInitializedContext.Provider>
);
