import { isEmpty } from 'lodash';
import { Box, Divider, IconButton, Paper, Stack, Typography } from '@common-components';
import { AssignmentInd, AssignmentLate, Edit as EditIcon, MailOutline, PhoneEnabled } from '@icons';
import { messages } from 'i18n';
import { softShadow } from 'themes';
import { User } from 'types';
import HoverCard from 'broker/components/HoverCard';
import ContactSingleInfoData from './ContactSingleInfoData';

export default function TeammateCard({ user, isMe, onClick }: { user: User; isMe: boolean; onClick?: () => void }) {
  return (
    <HoverCard>
      <Stack
        component={Paper}
        boxShadow={softShadow}
        sx={{
          borderRadius: 2,
          height: 250,
          pt: 1,
          pb: 2,
          pl: 2,
          pr: 2,
          justifyContent: 'space-between',
          maxHeight: 300,
          cursor: isMe ? 'pointer' : 'default',
          ...(isMe && {
            ':hover': {
              '& .edit-icon': {
                visibility: 'visible',
              },
            },
          }),
        }}
        onClick={isMe ? onClick : undefined}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography
            sx={{ mb: 1 }}
            variant="body1"
            fontWeight="bold"
          >{`${user.firstName} ${user.lastName}`}</Typography>
          <Box className="edit-icon" sx={{ visibility: 'hidden' }}>
            <IconButton icon={EditIcon} edge="end" size="small" color="secondary" variant="text" />
          </Box>
        </Stack>
        <ContactSingleInfoData
          content={isEmpty(user.title) ? messages.myTeamPage.placeHolders.userTitle : user.title!}
          Icon={AssignmentInd}
          isPlaceHolder={isEmpty(user.title)}
        />
        <ContactSingleInfoData
          content={isEmpty(user.note) ? messages.myTeamPage.placeHolders.note : user.note!}
          Icon={AssignmentLate}
          isPlaceHolder={isEmpty(user.note)}
        />
        <Divider sx={{ mt: 1.5, mb: 1 }} />
        <Typography sx={{ mb: 1 }} variant="body1">
          {messages.myTeamPage.ContactInformation}
        </Typography>
        <ContactSingleInfoData
          content={isEmpty(user.email) ? messages.myTeamPage.placeHolders.email : user.email!}
          Icon={MailOutline}
          isPlaceHolder={isEmpty(user.email)}
        />
        <ContactSingleInfoData
          content={isEmpty(user.phoneNumber) ? messages.myTeamPage.placeHolders.phoneNumber : user.phoneNumber!}
          Icon={PhoneEnabled}
          isPlaceHolder={isEmpty(user.phoneNumber)}
        />
      </Stack>
    </HoverCard>
  );
}
