import { QuoteStatus, SubmissionMarketRequestStatus } from 'enums';
import { Quote } from 'types';

export function getSubmissionMarketStatusFromQuote(quote: Quote) {
  if (quote.status === QuoteStatus.Rejected) {
    return SubmissionMarketRequestStatus.QuoteNotAccepted;
  }
  if (quote.binderFileIds.length > 0) {
    return SubmissionMarketRequestStatus.Bound;
  }
  if (quote.policyFileIds.length > 0) {
    return SubmissionMarketRequestStatus.PolicyIssued;
  }

  return SubmissionMarketRequestStatus.QuoteReady;
}
