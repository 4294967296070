import { isEmpty } from 'lodash';
import { useMount } from 'hooks';
import { AICreateThreadOutput } from 'types';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { removeCitations } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/citations-utils';
import { CreateThreadAction } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/SingleAIFeatureContextProvider';

export const useCreateAIThread = (
  createThread: CreateThreadAction,
  creationCondition: boolean = true,
  callback?: (response: AICreateThreadOutput | null) => void,
) => {
  const { createAIThread, messages, setCreateThreadAction } = useAIContext();

  useMount(() => {
    const createThreadAction = async () => {
      if (!isEmpty(messages) || !creationCondition) {
        return;
      }
      const response = await createAIThread(createThread);

      // Stripping the response from citations, these are only used in the AI popup, not in the features themselves
      const strippedResponse = response
        ? { ...response, response: removeCitations(response?.response, response?.citations) }
        : response;

      callback?.(strippedResponse);
    };
    setCreateThreadAction(() => createThread);
    createThreadAction();
  });
};
