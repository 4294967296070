import { Fragment, useState } from 'react';
import { Box, Button, Collapse, Divider, IconButton, Stack } from '@common-components';
import { Add, Close } from '@icons';
import { useBoolean, useRetrieveUserMarket } from 'hooks';
import { messages } from 'i18n';
import { Contact } from 'types';
import ToolboxItemHeader from 'broker/components/ToolboxItemHeader';
import { useSearch } from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/useSearch';
import { useCurrentSubmissionMarket } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { AddContactForm } from './AddContactForm';
import ContactsSkeleton from './ContactsSkeleton';
import { SubmissionMarketContact } from './SubmissionMarketContact';
import getFilteredContacts from './utils';

export default function SubmissionMarketContacts() {
  const submissionMarket = useCurrentSubmissionMarket();
  const { removeContactFromSubmissionMarket, addContactsToSubmissionMarket } = useSubmissionsWorkspace();
  const { search, setSearch } = useSearch();
  const [addContactFormOpen, { off: closeAddContactForm, toggle: toggleContactForm }] = useBoolean(false);

  const { data: userMarket, isInitialLoading } = useRetrieveUserMarket({ id: submissionMarket?.userMarketId ?? '' });

  const [checkingContact, setCheckingContact] = useState<string | null>(null);

  if (!submissionMarket) {
    return null;
  }

  const userMarketContactIds = new Set(userMarket?.contacts.map((contact) => contact.id) || []);
  const submissionMarketContactIds = new Set(submissionMarket.contacts.map((contact) => contact.id));

  const filteredContacts = getFilteredContacts({ userMarket, submissionMarket, submissionMarketContactIds, search });

  const handleCheckboxClick = async (contact: Contact) => {
    setCheckingContact(contact.id);

    if (submissionMarketContactIds.has(contact.id)) {
      await removeContactFromSubmissionMarket(submissionMarket.id, contact);
    } else {
      await addContactsToSubmissionMarket(userMarket!.id, [contact]);
    }

    setCheckingContact(null);
  };

  return (
    <Stack pb={2}>
      <Box position="sticky" top={0} bgcolor="common.white" zIndex={(theme) => theme.zIndex.appBar}>
        <ToolboxItemHeader
          title={`${submissionMarket.marketName} ${messages.menu.contacts}`}
          searchProps={{
            search: search.value,
            setSearch,
            searchPlaceholder: messages.submissionMarketToolbox.contacts.placeholders.search,
          }}
          mainActionArea={
            addContactFormOpen ? (
              <IconButton color="secondary" size="small" icon={Close} onClick={toggleContactForm} />
            ) : (
              <Button
                onClick={toggleContactForm}
                startIcon={<Add fontSize="small" />}
                variant="contained"
                color="secondary"
                size="small"
              >
                {messages.submissionMarketToolbox.contacts.addNewContact}
              </Button>
            )
          }
        />
      </Box>
      <Stack>
        <Collapse in={addContactFormOpen} orientation="vertical">
          <AddContactForm close={closeAddContactForm} submissionMarket={submissionMarket} />
        </Collapse>
        {isInitialLoading ? (
          <ContactsSkeleton />
        ) : (
          filteredContacts.map((contact) => {
            const isChecked = submissionMarketContactIds.has(contact.id);
            return (
              <Fragment key={contact.id}>
                <SubmissionMarketContact
                  contact={contact}
                  existsInUserMarket={userMarketContactIds.has(contact.id)}
                  isChecked={isChecked}
                  onCheckboxClick={() => handleCheckboxClick(contact)}
                  lastContactChecked={isChecked && submissionMarket?.contacts?.length === 1}
                  isLoading={contact.id === checkingContact}
                  userMarketId={submissionMarket.userMarketId}
                  search={search.value}
                />
                <Divider orientation="horizontal" flexItem variant="middle" sx={{ mx: 0 }} />
              </Fragment>
            );
          })
        )}
      </Stack>
    </Stack>
  );
}
