import { useState } from 'react';
import { Stack } from '@common-components';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { AIButtonWithPopup } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIButtonWithPopup';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { SetNextStepsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/useSetNextStep';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { useIsAcknowledgmentFlow } from 'broker/pages/SubmissionWorkspacePage/hooks';
import RiskAssessmentField from './RiskAssessmentField';
import { RiskAssessmentFooter } from './RiskAssessmentFooter';

interface RiskAssessmentNoteProps {
  submission: PartialSubmission;
  setNextStep: (props?: SetNextStepsProps) => void;
}

export default function RiskAssessmentContent({ submission, setNextStep }: RiskAssessmentNoteProps) {
  const [riskAssessment, setRiskAssessment] = useState<string>(submission.riskAssessment ?? '');
  const { isAcknowledgmentFlow } = useIsAcknowledgmentFlow();
  const { isLoading: isRiskAssessmentLoading, isContentStreaming: isRiskAssessmentStreaming } = useAIContext();
  const [aiGeneratedRiskAssessment, setAiGeneratedRiskAssessment] = useState<string | undefined>();

  const isDisabled = !submission.riskAssessment && (isRiskAssessmentLoading || isRiskAssessmentStreaming);
  const riskAssessmentAiInput = { submissionId: submission.id };

  return (
    <InnerPageLayout
      className="risk-assessment-note"
      title={isAcknowledgmentFlow ? messages.acknowledgmentFlow.title : messages.riskAssessmentNote.title}
      hideBackButton={isAcknowledgmentFlow}
      footer={(innerPageProps: InnerPageProps) => (
        <RiskAssessmentFooter
          submission={submission}
          setNextStep={setNextStep}
          riskAssessment={riskAssessment}
          aiGeneratedRiskAssessment={aiGeneratedRiskAssessment}
          aiInputData={riskAssessmentAiInput}
          isAcknowledgmentFlow={isAcknowledgmentFlow}
          isLoading={isDisabled}
          {...innerPageProps}
        />
      )}
    >
      {({ setIsDirty }: InnerPageProps) => (
        <Stack maxWidth={640} margin="auto">
          <AIButtonWithPopup popupTitle={messages.riskAssessmentNote.popupTitle} isRichTextResponse={false} />
          <RiskAssessmentField
            submission={submission}
            riskAssessment={riskAssessment}
            setRiskAssessment={setRiskAssessment}
            setAiGeneratedRiskAssessment={setAiGeneratedRiskAssessment}
            setIsDirty={setIsDirty}
            riskAssessmentAiInput={riskAssessmentAiInput}
            isDisabled={isDisabled}
          />
        </Stack>
      )}
    </InnerPageLayout>
  );
}
