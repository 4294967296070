import { fileTypeConfig } from '@common/config';
import { BoxItem } from 'box-ui-elements/es';
import { keyBy } from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FileType } from 'enums';
import { FilterElementProps, getFileType, updateCheckedFilter } from 'utils';
import { labelCompare } from 'utils/comparators';
import { FileExplorerFilter } from 'broker/components/FilesExplorer/types';
import { getFileMarketIds, shouldApplySearchInputFilter } from 'broker/components/FilesExplorer/utils';

interface FilesFilterProps {
  files: BoxItem[];
  filterFileTypes: {
    value: string;
    label: string;
  }[];
  filterMarkets: {
    value: string;
    label: string;
  }[];
  paginationReset: () => void;
  initFilter?: FileExplorerFilter;
}

export function useFilesFilter({
  files,
  initFilter,
  filterFileTypes,
  filterMarkets,
  paginationReset,
}: FilesFilterProps) {
  const [searchInput, setSearchInput] = useState<string>('');

  const setSearch = (value: string) => {
    paginationReset();
    setSearchInput(value);
  };

  const baseFilterByLabelList = useMemo(() => {
    const baseFiltersList: FilterElementProps[] = filterFileTypes
      .map((fileType) => ({
        key: fileType.value,
        checked: initFilter?.fileType?.some((fileTypeFilter) => fileTypeFilter.value === fileType.value) || false,
        label: fileTypeConfig[fileType.label as FileType].text || '',
        group: 'Label',
      }))
      .filter((filterList) => !!filterList.label)
      .sort(labelCompare);
    return baseFiltersList;
  }, [filterFileTypes, initFilter?.fileType]);

  const baseFilterByMarketList = useMemo(() => {
    const baseFiltersList: FilterElementProps[] = filterMarkets
      .map((market) => ({
        key: market.value,
        checked: !!initFilter?.marketId?.some((marketFilter) => marketFilter.value === market.value),
        label: market.label,
        group: 'Market',
      }))
      .filter((filterList) => !!filterList.label)
      .sort(labelCompare);
    return baseFiltersList;
  }, [filterMarkets, initFilter?.marketId]);

  const allBaseFilters = useMemo(
    () => [...baseFilterByLabelList, ...baseFilterByMarketList],
    [baseFilterByLabelList, baseFilterByMarketList],
  );

  const [fileFilters, setFileFilters] = useState(allBaseFilters);

  const isAllSelected = useMemo(() => fileFilters.every((filter) => !filter.checked), [fileFilters]);

  useEffect(() => {
    setFileFilters((prevFilters) =>
      prevFilters.map((filter) => ({
        ...filter,
        checked:
          (initFilter?.fileType?.some((fileTypeFilter) => fileTypeFilter.value === filter.key) ||
            initFilter?.marketId?.some((marketFilter) => marketFilter.value === filter.key)) ??
          false,
      })),
    );
  }, [initFilter]);

  const filteredFiles = useMemo(() => {
    const filterMarketById = keyBy(filterMarkets, (market) => market.value);

    // Check if any labels or markets are selected
    const isLabelSelected = fileFilters.some((filter) => filter.group === 'Label' && filter.checked);
    const isMarketSelected = fileFilters.some((filter) => filter.group === 'Market' && filter.checked);

    return files
      .filter((item) => {
        // Label filter check: if labels are selected, ensure the item matches a selected label, otherwise allow all labels
        const fileTypeMatches =
          !isLabelSelected ||
          fileFilters.some((filter) => filter.group === 'Label' && filter.checked && filter.key === getFileType(item));

        // Market filter check: if markets are selected, ensure the item matches a selected market, otherwise allow all markets
        const marketMatches =
          !isMarketSelected ||
          fileFilters.some(
            (filter) => filter.group === 'Market' && filter.checked && getFileMarketIds(item)?.includes(filter.key),
          );

        // Apply both conditions only if both are selected
        return fileTypeMatches && marketMatches;
      })
      .filter((item) => {
        if (shouldApplySearchInputFilter(searchInput)) {
          return true;
        }

        const fileTypeLabel = getFileType(item);
        const fileMarketIds = getFileMarketIds(item);
        const marketNames = fileMarketIds?.map((marketId) => filterMarketById[marketId]?.label) ?? [];

        const itemTerms = [item.name, item.created_by?.name, fileTypeLabel, ...marketNames].filter(Boolean);

        return itemTerms.some((term) => term?.toLowerCase().includes(searchInput.toLowerCase()));
      });
  }, [files, fileFilters, searchInput, filterMarkets]);

  const handleAllFileFilterSelected = () => {
    setFileFilters((prevFilters) => prevFilters.map((filter) => ({ ...filter, checked: false })));
  };

  const handleFileFilterChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    setFileFilters((prevFilters) => updateCheckedFilter([...prevFilters], key, event.target.checked));
    paginationReset();
  };

  const handleUncheckedFileFilter = (key: string) => {
    setFileFilters((prevFilters) => updateCheckedFilter([...prevFilters], key, false));
    paginationReset();
  };

  return {
    filteredFiles,
    fileFilters,
    isAllFileFilters: isAllSelected,
    handleFileFilterChange,
    handleAllFileFilterSelected,
    search: searchInput,
    setSearch,
    handleUncheckedFileFilter,
  };
}
