import { Collapse, Grid, Stack, Typography } from '@common-components';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  WarningAmber as WarningAmberIcon,
} from '@icons';
import { EmailTemplateType, SubmissionMarketRequestStatus } from 'enums';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import { SubmissionMarket } from 'types';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';

interface PreviouslySubmittedMarketsConfirmationProps {
  emailTemplateType?: EmailTemplateType;
  recipientGroups: RecipientGroup[];
  markets: SubmissionMarket[];
}

const marketingTemplates = [
  EmailTemplateType.RfqAttachmentPoint,
  EmailTemplateType.PrimaryMarketing,
  EmailTemplateType.ExcessMarketing,
  EmailTemplateType.RfqGeneral,
];

export default function PreviouslySubmittedMarketsConfirmation({
  emailTemplateType,
  recipientGroups,
  markets,
}: PreviouslySubmittedMarketsConfirmationProps) {
  const [isSubmittedMarketsCollapseOpen, { toggle: toggleSubmittedMarketsCollapse }] = useBoolean(false);

  if (!emailTemplateType || !marketingTemplates.includes(emailTemplateType)) {
    return null;
  }

  const alreadySubmittedMarkets = recipientGroups.filter((recipientGroup) => {
    const market = markets.find((marketItem) => marketItem.userMarketId === recipientGroup.id);
    return (
      market &&
      market.marketRequests?.length > 0 &&
      market.marketRequests[0].status !== SubmissionMarketRequestStatus.ReadyToMarket
    );
  });

  if (alreadySubmittedMarkets.length === 0) {
    return null;
  }

  return (
    <>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        onClick={toggleSubmittedMarketsCollapse}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'common.white',
          },
        }}
      >
        <WarningAmberIcon fontSize="small" sx={{ bgcolor: 'warning.main', borderRadius: '50%', p: 0.5 }} />
        <Typography variant="body1">
          {messages.emailEditor.sendMessagesGuards.alreadySubmittedMarkets(alreadySubmittedMarkets.length)}
        </Typography>
        {isSubmittedMarketsCollapseOpen ? (
          <KeyboardArrowUpIcon fontSize="small" />
        ) : (
          <KeyboardArrowDownIcon fontSize="small" />
        )}
      </Stack>
      <Collapse in={isSubmittedMarketsCollapseOpen}>
        <Grid container pl={3.5} spacing={1.5}>
          {alreadySubmittedMarkets.map((alreadySubmittedMarket) => (
            <Grid key={alreadySubmittedMarket.id} item xs={4}>
              <Typography variant="body2">{alreadySubmittedMarket.name}</Typography>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </>
  );
}
