import { EmailMessageBatchClient } from 'clients/capitola/email-message-batch';
import { QueryKeys } from 'enums';
import { EmailMessageBatch, EmailMessageBatchCreate, SubmissionEmailMessageBatchCreate } from 'types';
import { QueryCreateParams } from './query/types';
import { useMutate, useQueryMutation } from './query/useMutate';

export function useMutateEmailMessageBatch() {
  const { create: createEmailMessageBatch, client } = useMutate<
    EmailMessageBatch,
    EmailMessageBatch,
    EmailMessageBatchCreate,
    EmailMessageBatchClient
  >({
    queryKey: QueryKeys.EmailMessageBatch,
    clientClass: EmailMessageBatchClient,
    createOptions: { throwOnError: true },
  });

  const createEmailMessageBatchBySubmission = useQueryMutation<
    EmailMessageBatch,
    QueryCreateParams<SubmissionEmailMessageBatchCreate>
  >(({ data }) => client.createBySubmission(data), QueryKeys.EmailMessageBatch);

  return { createEmailMessageBatch, createEmailMessageBatchBySubmission };
}
