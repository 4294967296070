import { useEffect } from 'react';
import { Outlet } from 'react-router-dom-latest';
import { Box, Stack, Theme } from '@common-components';
import { useAppStoreActions } from 'contexts/app-store/AppStoreProvider';
import { isSubmissionTerminated, SubmissionStatus } from 'enums';
import { useMatchNavigation, useMount, useRefState, useUnmount } from 'hooks';
import { logger } from 'utils';
import { activeTabUrlParamValues, BrokerUrlParams, WORKSPACE_TABS_ROUTES } from 'broker/broker-routes';
import BlockingOverlay from 'broker/components/BlockingOverlay';
import ReFetchLoader from 'broker/components/common/ReFetchLoader';
import { TABS_PANEL_ID } from 'broker/config';
import { submissionStatusConfig } from 'broker/configuration-mappers/submission-status-config';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { transferableWorkspaceQueryParams } from 'broker/hooks/config';
import ChooseQuoteModal from 'broker/pages/SubmissionWorkspacePage/dialogs/ChooseQuoteModal';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import AutonomousBanner from './components/AutonomousBanner';
import { WorkspaceMainSkeleton } from './components/common/WorkspaceMainSkeleton';
import Header from './components/Header';
import { WorkspaceHeaderSkeleton } from './components/Header/WorkspaceHeaderSkeleton';
import InnerOverlay from './components/InnerOverlay';
import TabsPanel from './components/TabsPanel';
import TerminatedSubmissionBanner from './components/TerminatedSubmissionBanner';
import Toolbox from './components/Toolbox';
import { ToolboxNavigationSize } from './components/Toolbox/styles';
import { useBlockedByCopilot } from './components/Toolbox/useBlockedByCopilot';
import { SubmissionStatusChangeDialog } from './dialogs/SubmissionStatusChangeDialog';
import { useGetSubmissionCreationEmail, useIsAcknowledgmentFlow, useWorkspaceUrls } from './hooks';
import useSubmissionsWorkspace from './store/useSubmissionWorkspace';
import { useWorkspaceResize } from './useWorkspaceResize';

export default function Main() {
  const activeTab = useMatchNavigation(WORKSPACE_TABS_ROUTES);
  const [initRender, setInitRender] = useRefState(true);

  const {
    partialSubmission,
    submission,
    markets: submissionMarkets,
    isReFetching,
    clearCache,
  } = useSubmissionsWorkspace();
  const { submissionCreationEmail, isLoading: isLoadingSubmissionEmail } = useGetSubmissionCreationEmail();
  const { suggestedSubmissionStatus } = useUiStoreState();
  const { setSuggestedSubmissionStatus } = useUiStoreActions();

  const blockedByCopilot = useBlockedByCopilot();
  const navigate = useNavigate();

  const { editSubmissionUrl } = useWorkspaceUrls();

  const { setStickySearchParams } = useAppStoreActions();

  useEffect(() => {
    if (partialSubmission) {
      // Set logger variable
      logger.addCustomVariable('submissionId', partialSubmission.id);
    }

    return () => logger.removeCustomVariable('submissionId');
    // Don't need to re-run this every time 'getSubmissionFiles' is defined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partialSubmission]);

  const { isAcknowledgmentFlow } = useIsAcknowledgmentFlow();

  const shouldLandOnPreMarketing =
    partialSubmission &&
    (submissionStatusConfig[partialSubmission.status].preflight ||
      // terminated statuses are is considered inflight,
      // but if the marketing tab is disabled due to missing required fields user should land in pre-marketing tab
      (isSubmissionTerminated(partialSubmission.status) && !submission) ||
      partialSubmission.status === SubmissionStatus.New);

  // check if the submission is in Acknowledgment flow, if so on first render after everything
  // is loaded in the workspace redirect to edit submission, where the flow begins
  useEffect(() => {
    if (
      partialSubmission &&
      isAcknowledgmentFlow &&
      shouldLandOnPreMarketing &&
      activeTab &&
      initRender() &&
      !isLoadingSubmissionEmail
    ) {
      setInitRender(false);

      const toolboxQueryPath = submissionCreationEmail
        ? `?${BrokerUrlParams.ACTIVE_TAB}=${activeTabUrlParamValues.EMAIL}&&selectedEmail=${submissionCreationEmail.id}`
        : `?${BrokerUrlParams.ACTIVE_TAB}=${activeTabUrlParamValues.FILES}`;

      navigate(
        { pathname: editSubmissionUrl, search: toolboxQueryPath },
        {
          routeKey: RouteKey.SubmissionEdit,
          state: undefined,
        },
      );
    }
  }, [
    partialSubmission,
    isAcknowledgmentFlow,
    shouldLandOnPreMarketing,
    activeTab,
    navigate,
    editSubmissionUrl,
    initRender,
    setInitRender,
    isLoadingSubmissionEmail,
    submissionCreationEmail,
  ]);

  useMount(() => {
    setStickySearchParams(transferableWorkspaceQueryParams);
  });

  useUnmount(() => {
    clearCache();
    setStickySearchParams([]);
  });

  const defaultTab = shouldLandOnPreMarketing ? WORKSPACE_TABS_ROUTES.PRE_MARKETING : WORKSPACE_TABS_ROUTES.MARKETING;

  const { sideBarSizeValue } = useWorkspaceResize({
    isTabless: false,
  });

  return (
    <>
      <Stack height={1} position="relative">
        {isSubmissionTerminated(partialSubmission?.status) && (
          <TerminatedSubmissionBanner submission={partialSubmission} />
        )}
        <Stack boxShadow={4} zIndex={(theme: Theme) => theme.zIndex.drawer}>
          {partialSubmission && activeTab ? (
            <Header activeTab={activeTab} partialSubmission={partialSubmission} />
          ) : (
            <WorkspaceHeaderSkeleton />
          )}
        </Stack>
        {partialSubmission?.isCopilotEnabled && (
          <AutonomousBanner submission={partialSubmission} submissionMarkets={submissionMarkets} />
        )}
        <Stack bgcolor="background.default" flex={1} direction="row" overflow="hidden">
          <Stack id={TABS_PANEL_ID} position="relative" bgcolor="common.white" flex={1} overflow="auto">
            {!!partialSubmission && blockedByCopilot && <BlockingOverlay />}
            {partialSubmission ? <TabsPanel defaultTab={defaultTab} /> : <WorkspaceMainSkeleton />}
            <InnerOverlay />
          </Stack>
          <Box
            sx={{
              width: 4,
              bgcolor: 'grey.100',
              borderLeft: 1,
              borderColor: 'divider',
            }}
          />
          <Stack
            bgcolor="common.white"
            width={sideBarSizeValue}
            maxWidth={0.5}
            minWidth={ToolboxNavigationSize}
            overflow="auto"
            sx={{
              transition: (theme: Theme) =>
                theme.transitions.create(['width'], {
                  duration: theme.transitions.duration.short,
                }),
            }}
          >
            {partialSubmission ? <Toolbox /> : <WorkspaceMainSkeleton />}
          </Stack>
        </Stack>
      </Stack>
      {isReFetching && <ReFetchLoader />}
      {partialSubmission && <Outlet />}
      <ChooseQuoteModal />
      {suggestedSubmissionStatus && (
        <SubmissionStatusChangeDialog
          isOpen
          onClose={() => {
            setSuggestedSubmissionStatus({ suggestedSubmissionStatus: null });
          }}
          newSubmissionStatus={suggestedSubmissionStatus}
        />
      )}
    </>
  );
}
