import { withAuthenticationRequired } from '@auth0/auth0-react';

import { ComponentType } from 'react';
import { Navigate } from 'react-router-dom-latest';
import APP_ROUTES from 'app-routes';
import { UserRole } from 'enums';
import { User } from 'types';

export interface ProtectedRouteProps {
  Component: ComponentType | null;
  me: User | null;
  roles?: UserRole[];
}

const UnauthorizedNavigate = () => <Navigate to={APP_ROUTES.GENERAL.LANDING} />;

export default function ProtectedRoute({ Component, me, roles }: ProtectedRouteProps) {
  if (!Component) {
    return null;
  }

  let MaybeProtectedComponent: ComponentType = Component;

  if (roles !== undefined) {
    // route requires auth
    if (!me) {
      // not logged in: force redirect
      MaybeProtectedComponent = withAuthenticationRequired(Component);
    } else if (!roles.includes(me.role)) {
      // not authorized: do not enable route
      MaybeProtectedComponent = UnauthorizedNavigate;
    }
  }

  return <MaybeProtectedComponent />;
}
