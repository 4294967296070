import { fileTypeConfig } from '@common/config';
import { useCallback } from 'react';
import { BoxTemplateLabels, FileType } from 'enums';
import { useSearchBoxItems } from 'hooks/api/box';
import { PartialSubmission } from 'types';
import { getFileMetadataField } from 'utils/files-utils';

export default function useSubmissionMissingFiles(partialSubmission: PartialSubmission | null, enabled = true) {
  const { items: files, isLoading: isLoadingBoxItems } = useSearchBoxItems({
    filter: { chunkSize: 20, folderId: partialSubmission?.boxFolderId || '' },
    enabled,
  });

  /**
   * Returns the file types that don't exist in the submission folder and a loading state.
   * If a call is being made to this function while the submission files are still being loaded
   * it returns an empty array ( = no missing files).
   * @param expectedFileTypes - the file types to check
   * @returns the display names of the missing file types (labels) from the given list
   */
  const getMissingFileTypes = useCallback(
    (expectedFileTypes: FileType[]) => {
      if (isLoadingBoxItems) {
        return [];
      }
      const missingFilesTypes = expectedFileTypes.filter(
        (type) => !files.some((file) => getFileMetadataField(file, BoxTemplateLabels.FileType) === type),
      );
      return missingFilesTypes.map((label) => fileTypeConfig[label].text);
    },
    [files, isLoadingBoxItems],
  );

  return { getMissingFileTypes, isLoading: isLoadingBoxItems };
}
