import { entries, groupBy, uniq } from 'lodash';
import { useEffect } from 'react';
import { PartialSubmission } from 'types';
import { stringCompare } from 'utils/comparators';
import { HotjarEvents } from 'utils/hotjar-events';
import useSubmissionFilter from './useSubmissionFilter';
import { SubmissionBoxStorageKeys, SubmissionsViewStorageKeys, SubmissionViewMode } from './utils';

interface useAssigneeFilterProps {
  submissions: PartialSubmission[];
  paginationReset: () => void;
  areSubmissionsLoaded: boolean;
  mode: SubmissionViewMode;
}

export function useCustomerFilter({
  submissions,
  areSubmissionsLoaded,
  paginationReset,
  mode,
}: useAssigneeFilterProps) {
  const submissionsByOrganizations = groupBy(submissions, (submission) => submission.organizationName);
  const customerBaseFilterList = entries(submissionsByOrganizations).map(([key, value]) => ({
    key,
    label: key,
    count: value.length,
    checked: false,
  }));

  const customerFilter = useSubmissionFilter({
    baseFiltersList: customerBaseFilterList,
    storageKey:
      mode === SubmissionViewMode.General ? SubmissionsViewStorageKeys.customer : SubmissionBoxStorageKeys.customer,
    hotjarEvent: HotjarEvents.SubmissionsCustomerFilter,
    paginationReset,
  });

  const setCustomerFilter = customerFilter.setFilters;

  // Update the filter options once we have the submissions from the server
  useEffect(() => {
    // We can't create customerList until data is loaded so just bail out
    if (!areSubmissionsLoaded || submissions.length === 0) {
      return;
    }

    const customers = uniq(submissions.map((submission) => submission.organizationName)).sort(stringCompare);

    setCustomerFilter((prev) =>
      customers.map((customer) => ({
        key: customer,
        checked: prev.find((line) => line.key === customer)?.checked ?? false,
        label: customer,
      })),
    );
  }, [areSubmissionsLoaded, setCustomerFilter, submissions]);

  return customerFilter;
}
