import { BoxItem } from 'box-ui-elements/es';
import { isEmpty } from 'lodash';
import { ReactNode } from 'react';
import { Button, Divider, NewMenu, SearchBar, Stack } from '@common-components';
import { BoxTemplateLabels, FileType, SubmissionMarketRequestStatus, SubmissionMethod } from 'enums';
import { useOptionsMenu } from 'hooks';
import { useSearchBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { getFileMetadataField } from 'utils/files-utils';
import { MenuItemProps } from 'components/menu';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import Filter, { FilterButtonTypes } from 'broker/components/common/Filter';

import Sort from 'broker/components/common/Sort';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import {
  isInformationRequestsReportEnabled,
  isMarketingReportEnabled,
} from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingReport/utils';
import SubmitButton from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/components/SubmitButton';
import {
  MarketsSortAndFilterProps,
  sorters,
} from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/useMarketRequestsSortAndFilter';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import CoveragesFilter from './CoveragesFilter';
import { useGetMarketingUpdateMenuItem } from './useGetMarketingUpdateMenuItem';

interface HeaderProps {
  headerButtons?: ReactNode;
  sortingProps: MarketsSortAndFilterProps;
  showSearchAndFilter: boolean;
  onSelectSubmissionMethod: (method: SubmissionMethod) => void;
  onSelectQuoteComparison: (expiringPolicyFile?: BoxItem) => void;
}

export default function Header({
  headerButtons,
  sortingProps,
  showSearchAndFilter = true,
  onSelectSubmissionMethod,
  onSelectQuoteComparison,
}: HeaderProps) {
  const navigate = useNavigate();

  const { markets, partialSubmission } = useSubmissionsWorkspace();

  const { items, isLoading: boxItemsLoading } = useSearchBoxItems({
    filter: { chunkSize: 20, folderId: partialSubmission?.boxFolderId || '' },
  });
  const marketingUpdateMenuItem = useGetMarketingUpdateMenuItem();

  // filter out only expiring policy files
  const filteredItems = items.filter(
    (item) => getFileMetadataField(item, BoxTemplateLabels.FileType) === FileType.ExpiringProgram,
  );

  const {
    filters,
    handleStatusFilterChange,
    search,
    setSearch,
    sortType,
    setSortType,
    isAllFilters,
    sortedAndFilteredItems,
    setAllStatusSelected,
    coverageLineFilters,
    handleVisibleCoverageChange,
  } = sortingProps;
  const optionsMenuState = useOptionsMenu();

  const readyToMarketRequests = sortedAndFilteredItems?.filter(
    (marketRequest) => marketRequest.status === SubmissionMarketRequestStatus.ReadyToMarket,
  );

  const showSubmitButton = readyToMarketRequests?.length > 0;

  const getMenuOptions = (expiringPolicyFiles?: BoxItem[]) => {
    const menuItems: MenuItemProps[] = [];
    menuItems.push(marketingUpdateMenuItem);
    menuItems.push({
      label: messages.submissionWorkspace.menus.share.informationRequests,
      onClick: () => {
        navigate(BROKER_NESTED_ROUTES.WORKSPACE.MARKETING_REPORT, {
          routeKey: RouteKey.MarketingReport,
          state: { saveMarketingReportFlow: false, isInformationRequestsReport: true },
        });
      },
      disabled: !isInformationRequestsReportEnabled(markets),
    });
    menuItems.push({
      label: messages.submissionWorkspace.menus.share.quoteComparison,
      onClick: onSelectQuoteComparison,
    });
    if (!isEmpty(expiringPolicyFiles)) {
      expiringPolicyFiles?.map((file) =>
        menuItems.push({
          label: messages.submissionWorkspace.menus.share.compareToExpiringPolicy,
          subLabel: file.name,
          onClick: () => onSelectQuoteComparison(file),
        }),
      );
    } else {
      menuItems.push({
        label: messages.submissionWorkspace.menus.share.compareToExpiringPolicy,
        disabled: true,
        disabledTooltipContent: messages.submissionWorkspace.menus.share.noExpiringPolicyFiles,
        tooltipPlacement: 'right',
      });
    }
    return menuItems;
  };

  return (
    <Stack py={2} px={2} gap={1}>
      {showSearchAndFilter && (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" gap={1} alignItems="center">
              <SearchBar
                value={search}
                placeholder={messages.general.searchMarkets}
                onChange={(value: string) => setSearch(value)}
              />
              <Filter
                title="Status"
                buttonType={FilterButtonTypes.Dropdown}
                filters={filters}
                handleFilterChange={handleStatusFilterChange}
                handleAllSelected={setAllStatusSelected}
                isAllFilters={isAllFilters}
              />
              <Divider orientation="vertical" flexItem variant="middle" sx={{ mx: 1, my: 1.4 }} />
              <Sort menuPlacement="bottom-left" setSortType={setSortType} sortType={sortType} sorters={sorters} />
            </Stack>
            <Stack direction="row" gap={1}>
              {showSubmitButton && (
                <SubmitButton
                  onSelectSubmissionMethod={onSelectSubmissionMethod}
                  requestsCount={readyToMarketRequests?.length ?? 0}
                />
              )}
              <Button
                disabledTooltipContent={
                  boxItemsLoading ? '' : messages.marketingReportPage.editMode.disabled.description
                }
                disabled={!isMarketingReportEnabled(markets)}
                variant="contained"
                color="secondary"
                onClick={optionsMenuState.openMenu}
                loading={boxItemsLoading}
              >
                {messages.buttons.reports}
              </Button>
              {headerButtons}
            </Stack>
            <NewMenu
              placement="bottom-right"
              optionsMenuState={optionsMenuState}
              menuItems={[
                {
                  key: 'share',
                  items: getMenuOptions(filteredItems),
                },
              ]}
            />
          </Stack>
          <CoveragesFilter
            coverageFilter={coverageLineFilters}
            handleVisibleLabelChange={handleVisibleCoverageChange}
          />
        </>
      )}
    </Stack>
  );
}
