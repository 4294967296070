import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Chip } from '@common-components';
import { messages } from 'i18n';
import { UserMarket } from 'types';
import { labelCompare } from 'utils/comparators';
import FormAutocomplete from 'components/hookFormComponents/FormAutocomplete/FormAutocomplete';
import { GridFormArrayFieldProps } from 'components/hookFormComponents/GridFormFields/GridFormArrayFields';
import { marketAutocompleteLabelShiftPadding } from 'broker/dialogs/SubmissionAutonomousCreation/AdvancedContent/styles';
import {
  AutonomousMarketArrayFieldNames,
  AutonomousMarketFieldNames,
  autonomousMarketFormFieldsConfig,
  AutonomousMarketInfoData,
} from 'broker/dialogs/SubmissionAutonomousCreation/AdvancedContent/utils';

interface AutonomousMarketsFormFieldsProps extends GridFormArrayFieldProps {
  userMarkets: UserMarket[];
  initSubmissionMarkets: AutonomousMarketInfoData[];
}
export default function AutonomousMarketsFormFields({
  getFieldName,
  userMarkets,
  disabled,
  index,
  initSubmissionMarkets,
}: AutonomousMarketsFormFieldsProps) {
  const { watch } = useFormContext();

  const marketSelectedList = watch(AutonomousMarketFieldNames.Markets);

  const isIncumbent = initSubmissionMarkets.find(
    (submissionMarket) => submissionMarket.marketId === marketSelectedList[index].marketId,
  )?.isIncumbent;

  const marketOptions = useMemo(
    () =>
      userMarkets
        .map((selectMarket) => ({
          label: selectMarket.marketName,
          value: selectMarket.id,
        }))
        .sort(labelCompare),
    [userMarkets],
  );

  return (
    <Box position="relative">
      <FormAutocomplete
        disabled={disabled}
        withTooltip
        size="small"
        autoSelect={false}
        tooltipPlacement="left"
        gridMode
        options={marketOptions}
        filterOptions={marketSelectedList.map((market: UserMarket) => ({
          value: market.marketId,
        }))}
        placeholder={autonomousMarketFormFieldsConfig[AutonomousMarketArrayFieldNames.MarketId].placeholder}
        optionalPlaceholder={
          index === marketSelectedList.length - 1
            ? messages.autonomousFlow.settingsModal.advanced.formFields.market.optionalPlaceholder
            : ''
        }
        name={getFieldName(autonomousMarketFormFieldsConfig[AutonomousMarketArrayFieldNames.MarketId].name)}
        label=""
        id={getFieldName(autonomousMarketFormFieldsConfig[AutonomousMarketArrayFieldNames.MarketId].name)}
      />

      {marketSelectedList[index].marketId && (
        <Box position="absolute" right={(theme) => theme.spacing(-marketAutocompleteLabelShiftPadding - 4)} top={14}>
          <Chip
            size="small"
            label={
              isIncumbent
                ? messages.autonomousFlow.settingsModal.steps.marketing.form.incumbent
                : messages.autonomousFlow.settingsModal.steps.marketing.form.newMarket
            }
            variant="outlined"
            color="secondary"
          />
        </Box>
      )}
    </Box>
  );
}
