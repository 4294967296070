import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { CapitolaClient } from 'clients';
import { retryOnError } from 'clients/base';
import { useClient } from 'hooks/useClient';
import { queryRetryStrategy } from './query-retry-strategy';
import { UseImperativeQueryOptions, UseQueryRetrieveOptions, UseQuerySearchOptions } from './types';

export const defaultQueryOptions = {
  // We decided to currently disabled the refetch on network connection because we experience a lot of errors
  // around that. See: CAP-1435
  refetchOnReconnect: false,
  ...queryRetryStrategy(),
};

// Query options that are used for queries that are used extensively throughout the app
// These queries are cached indefinitely and refetched on window focus
export const cachedQueryOptions = {
  staleTime: Infinity,
  refetchOnWindowFocus: 'always',
} as const;

export function useQuerySearch<Type, ListItem, Client extends CapitolaClient<Type, ListItem>>({
  queryKey,
  filter = {},
  enabled = true,
  queryOptions,
  clientClass,
  searchOptions,
  sorter,
}: UseQuerySearchOptions<Type, ListItem, Client>) {
  const client = useClient(clientClass);
  const query = useQuery(
    [queryKey, filter],
    async () => {
      const data = await client.search(filter, { ...retryOnError, ...searchOptions });
      if (data) {
        return { items: (sorter ? data.items?.sort(sorter) : data.items) || [], count: data.count || 0 };
      }
      return { items: [], count: 0 };
    },
    {
      ...defaultQueryOptions,
      enabled,
      ...queryOptions,
    },
  );

  const { data, ...rest } = query;

  const items = useMemo(() => data?.items || [], [data?.items]);
  const count = data?.count || 0;

  return {
    items,
    count,
    ...rest,
  };
}

export function useQueryRetrieve<Type, ListItem, Client extends CapitolaClient<Type, ListItem>>({
  id,
  enabled = true,
  queryKey,
  queryOptions,
  clientClass,
  retrieveOptions,
}: UseQueryRetrieveOptions<Type, ListItem, Client>) {
  const client = useClient(clientClass);

  return useQuery([queryKey, { id }], () => client.retrieve(id, { ...retryOnError, ...retrieveOptions }), {
    ...defaultQueryOptions,
    cacheTime: 0,
    enabled: enabled && !!id,
    ...queryOptions,
  });
}

// A hook that allows using react query to fetch data imperatively, and return the result directly from the function call.
// Relevant when the fetched data is needed to make a decision in the flow, not to be rendered in the UI.
// E.g. call the server to validate the form data before submitting it.
export function useImperativeQuery<Output, Input = void>({
  queryFn,
  queryKey,
  queryOptions,
}: UseImperativeQueryOptions<Output, Input>) {
  const queryClient = useQueryClient();

  return (input: Input) =>
    queryClient.fetchQuery({
      queryKey: Array.isArray(queryKey) ? [...queryKey, { input }] : [queryKey, { input }],
      queryFn: () => queryFn(input),
      ...defaultQueryOptions,
      cacheTime: 0,
      ...queryOptions,
    });
}
