import { ReactNode, useContext, useReducer } from 'react';
import {
  InformationTooltipActionsContext,
  InformationTooltipStateContext,
  InformationTooltipStoreInitState,
} from './context';
import uiStoreReducer from './reducer';

import useInformationTooltipActionsHook from './useInformationTooltipActions';

interface ModalProviderProps {
  children?: ReactNode;
}

function InformationTooltipProvider({ children }: ModalProviderProps) {
  const [state, dispatch] = useReducer(uiStoreReducer, InformationTooltipStoreInitState);

  const informationTooltipAction = useInformationTooltipActionsHook(dispatch);

  return (
    <InformationTooltipStateContext.Provider value={state}>
      <InformationTooltipActionsContext.Provider value={informationTooltipAction}>
        {children}
      </InformationTooltipActionsContext.Provider>
    </InformationTooltipStateContext.Provider>
  );
}

function useInformationTooltipState() {
  const context = useContext(InformationTooltipStateContext);
  if (context === undefined) {
    throw new Error('Information Tooltip State Context must be used within provider');
  }
  return context;
}

function useInformationTooltipActions() {
  const context = useContext(InformationTooltipActionsContext);
  if (context === undefined) {
    throw new Error('Information Tooltip Actions Context must be used within provider');
  }
  return context;
}

export { InformationTooltipProvider, useInformationTooltipState, useInformationTooltipActions };
