import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { BoxItem } from 'box-ui-elements/es';
import { Grid } from '@common-components';
import { FilesListItem } from './FilesListItem';

interface SelectedFilesListProps {
  columns?: number;
  disableRemove?: boolean;
  selectedFiles: BoxItem[];
  onRemoveFile: (file: BoxItem) => void;
  onFileClicked?: (file: BoxItem) => void;
  showFilesCounter?: boolean;
  onDragCallback?: (result: DropResult) => void;
}

export const SelectedFilesList = ({
  columns = 1,
  disableRemove,
  selectedFiles,
  onRemoveFile,
  onFileClicked,
  showFilesCounter = false,
  onDragCallback,
}: SelectedFilesListProps) => (
  <DragDropContext onDragEnd={onDragCallback || (() => {})}>
    <Droppable droppableId="selectedFiles">
      {(providedDroppable, snapshot) => (
        <Grid
          container
          gap={1}
          maxHeight={(theme) => theme.spacing(60)}
          sx={{ overflowY: 'auto', backgroundColor: snapshot.isDraggingOver ? 'blue.100' : 'inherit' }}
          {...providedDroppable.droppableProps}
          ref={providedDroppable.innerRef}
        >
          {selectedFiles.map((fileItem, index) => (
            <Draggable
              key={fileItem.id}
              draggableId={fileItem.id.toString()}
              index={index}
              isDragDisabled={!onDragCallback || disableRemove}
            >
              {(provided) => (
                <Grid
                  item
                  xs={12 / columns}
                  key={fileItem.id}
                  ref={provided.innerRef}
                  {...(onDragCallback ? provided.draggableProps : {})}
                  {...(onDragCallback ? provided.dragHandleProps : {})}
                >
                  <FilesListItem
                    draggable={!!onDragCallback && !disableRemove}
                    fileItem={fileItem}
                    onRemoveFile={onRemoveFile}
                    disabled={disableRemove}
                    prefix={showFilesCounter ? `${index + 1}. ` : undefined}
                    onItemClicked={onFileClicked}
                  />
                </Grid>
              )}
            </Draggable>
          ))}
          {providedDroppable.placeholder}
        </Grid>
      )}
    </Droppable>
  </DragDropContext>
);
