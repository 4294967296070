import { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom-latest';
import { Dialog } from '@common-components';
import { TABS_PANEL_ID } from 'broker/config';

interface RoutedInlineDialogProps {
  path: string;
  className: string;
  children: ReactNode;
}

export default function RoutedInlineDialog({ path, className, children }: RoutedInlineDialogProps) {
  return (
    <Routes>
      <Route
        path={path}
        element={
          <Dialog
            className={className}
            container={document.getElementById(TABS_PANEL_ID)}
            open
            onClose={() => {}}
            fullScreen
            sx={{ position: 'absolute', zIndex: 2 }}
            BackdropProps={{ sx: { position: 'absolute' } }}
            disableEnforceFocus
          >
            {children}
          </Dialog>
        }
      />
    </Routes>
  );
}
