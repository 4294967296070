import { useState } from 'react';
import { Box, ButtonBase, Collapse, Stack, Typography } from '@common-components';
import { ExpandMore } from '@icons';
import { PartialSubmission, SubmissionMarket } from 'types';
import { CopilotStepsProps } from './config';

interface CollapseItemProps extends CopilotStepsProps {
  active: boolean;
  submission: PartialSubmission;
  submissionMarkets: SubmissionMarket[];
}
export default function CollapseItem({
  component,
  stepNumber,
  title,
  subTitle,
  active,
  submission,
  submissionMarkets,
}: CollapseItemProps) {
  const Component = component;
  const [expanded, setExpanded] = useState(active);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      border={1}
      borderColor="divider"
      borderRadius={1}
      position="relative"
      sx={{
        '&:before': {
          content: active ? '""' : 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          width: 4,
          height: '100%',
          backgroundColor: 'green.400',
          borderRadius: '4px 0 0 4px',
          zIndex: 1,
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        textAlign="left"
        width={1}
        gap={2}
        component={ButtonBase}
        onClick={handleExpandClick}
        p={2}
        sx={{
          bgcolor: 'common.white',
        }}
      >
        <Box
          sx={{
            borderRadius: '50%',
            width: 28,
            height: 28,
            backgroundColor: active ? 'green.400' : 'blueGrey.100',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="captionBold" color="textPrimary">
            {stepNumber}
          </Typography>
        </Box>
        <Stack flex={1}>
          <Typography variant="subtitle2" color="textPrimary">
            {title}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {subTitle}
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: (theme) =>
              theme.transitions.create(['transform'], {
                duration: theme.transitions.duration.short,
              }),
          }}
        >
          <ExpandMore fontSize="small" />
        </Stack>
      </Stack>
      <Collapse in={expanded} timeout="auto">
        <Stack px={7} py={3} gap={1} maxWidth={800}>
          <Box
            bgcolor={submission.isCopilotEnabled || !active ? 'grey.100' : 'blueGrey.50'}
            borderRadius={1}
            pl={2}
            pr={12}
            py={2}
          >
            <Component submission={submission} submissionMarkets={submissionMarkets} active={active} />
          </Box>
        </Stack>
      </Collapse>
    </Box>
  );
}
