import { isString, keyBy, mapValues, omitBy } from 'lodash';
import { AnySchema } from 'yup';
import * as yup from 'yup';
import { HeraldSchemaFormat } from 'clients/types';
import { messages } from 'i18n';
import { customYupValidateNumber, isValidDomain } from 'utils';
import {
  heraldIndustryQuestionParameterId,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';
import {
  convertExactDigitsToReadableError,
  testRequiredNestedObject,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/utils/validations-utils';

const getSchemaForHeraldStringInputType = (heraldSchema: HeraldNormalizedParameter['schema']) => {
  let yupSchema;
  yupSchema = yup.string().nullable();

  // for string schema type
  if (heraldSchema.min_length) {
    yupSchema = yupSchema.test(
      'MinLengthString',
      messages.errors.minChars(heraldSchema.min_length),
      (value: string | null | undefined) =>
        typeof value === 'string' ? !value || value.length >= heraldSchema.min_length! : true,
    );
  }

  // for string schema type
  if (heraldSchema.max_length) {
    yupSchema = yupSchema.max(heraldSchema.max_length, messages.errors.maxChars(heraldSchema.max_length));
  }

  const heraldSchemaPattern = heraldSchema.pattern;
  if (heraldSchemaPattern) {
    const message = isString(heraldSchemaPattern) && convertExactDigitsToReadableError(heraldSchemaPattern);
    yupSchema = yupSchema.test(
      'PatternTest',
      message || `Invalid Pattern ( ${heraldSchemaPattern} )`,
      (value: string | null | undefined) => !value || new RegExp(heraldSchemaPattern).test(value),
    );
  }
  if (heraldSchema.format === HeraldSchemaFormat.Email) {
    yupSchema = yupSchema.email('Invalid Email');
  }

  if (heraldSchema.format === HeraldSchemaFormat.Hostname) {
    yupSchema = yupSchema.test('DomainValidation', 'Invalid Domain', isValidDomain);
  }

  return yupSchema;
};

const getSchemaForHeraldParameter = (
  heraldSchema: HeraldNormalizedParameter['schema'],
  heraldNormalizedParameter?: HeraldNormalizedParameter,
) => {
  let yupSchema;
  if (heraldNormalizedParameter?.parameter_id === heraldIndustryQuestionParameterId) {
    // ignore industry schema we keep it as an object instead of string id, it doesn't need to go through validation as it is an autocomplete
    return yup.mixed().nullable();
  }
  if (heraldSchema.type === 'string') {
    yupSchema = getSchemaForHeraldStringInputType(heraldSchema);
  } else if (['integer', 'number'].includes(heraldSchema.type)) {
    if (heraldSchema.minimum || heraldSchema.maximum) {
      yupSchema = yup
        .string()
        .default('')
        .test(
          'customYupValidateNumber',
          'customYupValidateNumber',
          customYupValidateNumber({
            fieldName: '',
            minNumber: heraldSchema.minimum,
            maxNumber: heraldSchema.maximum,
            allowZero: true,
          }),
        );
    }
  } else if (heraldSchema.type === 'array') {
    if (heraldSchema.min_items) {
      yupSchema = yup.array().min(heraldSchema.min_items);
    }
  } else if (heraldSchema.type === 'object') {
    const properties = heraldSchema.properties!;
    yupSchema = yup
      .object()
      .shape(
        Object.keys(properties).reduce((acc, curr) => {
          // @ts-ignore
          acc[curr] = getSchemaForHeraldParameter(properties[curr]);
          return acc;
        }, {}),
      )
      .test('at-least-one-field', 'at-least-one-field', testRequiredNestedObject(heraldSchema.required || []));
  }

  return yupSchema;
};

export default function mapHeraldParametersToYupShape(heraldNormalizedParameters: HeraldNormalizedParameter[]) {
  const schema: Record<string, AnySchema> = {};

  const getFormPropertySchema = (heraldNormalizedParameter: HeraldNormalizedParameter) => {
    const mainSchema = getSchemaForHeraldParameter(heraldNormalizedParameter.schema, heraldNormalizedParameter);
    const childValues = heraldNormalizedParameter.arrayElements
      ? heraldNormalizedParameter.arrayElements[0].childValues
      : heraldNormalizedParameter.childValues;
    const childrenSchema: any = childValues
      ? mapValues(keyBy(childValues, 'parameter_id'), (childValue) => getFormPropertySchema(childValue))
      : undefined;

    const childShape = childrenSchema
      ? yup
          .object()
          .shape(omitBy(childrenSchema, (schemaItem) => schemaItem === undefined) as Record<string, yup.SchemaOf<any>>)
      : undefined;

    let formPropertySchema;

    if (mainSchema || childShape) {
      formPropertySchema = yup.object().shape({
        main: mainSchema || yup.mixed().nullable(),
        children: childShape ? childShape.nullable() : yup.mixed().nullable(),
      });
      if (heraldNormalizedParameter.arrayElements) {
        // @ts-ignore
        formPropertySchema = yup.array().of(formPropertySchema);
      }
    }
    return formPropertySchema;
  };

  heraldNormalizedParameters.forEach((heraldNormalizedParameter) => {
    schema[heraldNormalizedParameter.parameter_id] =
      getFormPropertySchema(heraldNormalizedParameter) || yup.mixed().nullable();
  });

  return yup.object().shape(schema);
}
