import { flatMap, isEmpty, uniq } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Stack, Typography } from '@common-components';
import { CoverageLine } from 'enums';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import { InsuranceProduct, PartialSubmission } from 'types';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { SelectedProductsMap } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/types';
import AddMissingCoverageLinesConfirmationDialog from './AddMissingCoverageLinesConfirmationDialog';

interface FooterProps {
  innerPageProps: InnerPageProps;
  selectedProducts: SelectedProductsMap;
  onProductsSelected: (products: InsuranceProduct[]) => Promise<void>;
  partialSubmission: PartialSubmission | null;
}

export function Footer({ innerPageProps, selectedProducts, onProductsSelected, partialSubmission }: FooterProps) {
  const [isSelectingProducts, { on: startSelectingProducts, off: stopSelectingProducts }] = useBoolean(false);
  const { onClose } = innerPageProps;

  const [
    isAddMissingCoverageLinesDialogOpen,
    { off: closeAddMissingCoverageLinesDialog, on: openAddMissingCoverageLinesDialog },
  ] = useBoolean(false);
  const [newCoverageLines, setNewCoverageLines] = useState<CoverageLine[]>([]);

  const createProducts = useCallback(async () => {
    try {
      const productItems = Object.values(selectedProducts);
      startSelectingProducts();
      await onProductsSelected(productItems);
      onClose();
    } catch (e) {
      stopSelectingProducts();
    }
  }, [onClose, onProductsSelected, selectedProducts, startSelectingProducts, stopSelectingProducts]);

  const onSubmitClick = useCallback(async () => {
    const productItems = Object.values(selectedProducts);
    const productItemsWithNewCoverageLinesOnly = productItems.filter(
      (insuranceProductItem) =>
        // check if the product has a coverage line that exists in the submission, if not then we should add its coverage lines to the submission
        !insuranceProductItem.coverageLines.some((productCoverageLine) =>
          partialSubmission?.coverageLines?.includes(productCoverageLine.coverageLine),
        ),
    );
    const newCoverageLineItems = uniq(
      flatMap(productItemsWithNewCoverageLinesOnly, (insuranceProductItem) =>
        insuranceProductItem.coverageLines
          .map((productCoverageLine) => productCoverageLine.coverageLine)
          .filter((coverageLine) => !partialSubmission?.coverageLines?.includes(coverageLine)),
      ),
    );
    if (newCoverageLineItems.length) {
      setNewCoverageLines(newCoverageLineItems);
      openAddMissingCoverageLinesDialog();
    } else {
      await createProducts();
    }
  }, [createProducts, openAddMissingCoverageLinesDialog, partialSubmission?.coverageLines, selectedProducts]);

  return (
    <>
      <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
        <Stack direction="row" overflow="hidden">
          <Typography variant="body2" noWrap>
            {messages.addProductsPage.footer.getSelectedText(Object.keys(selectedProducts).length)}
          </Typography>
        </Stack>

        <Stack direction="row" gap={1}>
          <Button variant="outlined" onClick={onClose}>
            {messages.buttons.cancel}
          </Button>
          <Button
            variant="contained"
            onClick={onSubmitClick}
            disabled={isEmpty(selectedProducts)}
            loading={isSelectingProducts}
          >
            {messages.addProductsPage.footer.addProducts}
          </Button>
        </Stack>
      </Stack>
      {partialSubmission && (
        <AddMissingCoverageLinesConfirmationDialog
          isOpen={isAddMissingCoverageLinesDialogOpen}
          closeDialog={closeAddMissingCoverageLinesDialog}
          newCoverageLines={newCoverageLines}
          updatedCoverageLines={uniq([...(partialSubmission?.coverageLines || []), ...newCoverageLines])}
          createProducts={createProducts}
          partialSubmission={partialSubmission}
        />
      )}
    </>
  );
}
