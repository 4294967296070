import { ConceptualTowerReportRow } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/ConceptualTower/types';
import getColumns from './columnsConfig';
import StyledDataGrid from './StyledDataGrid';

export interface ConceptualTowerContentProps {
  reportRows: ConceptualTowerReportRow[];
  boldLastTableRow: boolean;
}

function ConceptualTowerDataGrid({ reportRows, boldLastTableRow = true }: ConceptualTowerContentProps) {
  return (
    <StyledDataGrid
      disableColumnFilter
      rowHeight={80}
      hideFooter
      autoHeight
      rows={reportRows}
      columns={getColumns()}
      hideFooterPagination
      disableColumnSelector
      disableColumnMenu
      disableSelectionOnClick
      boldLastRow={boldLastTableRow}
    />
  );
}

export default ConceptualTowerDataGrid;
