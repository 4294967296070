import { useMemo } from 'react';
import { FileType, FormMode } from 'enums';
import { messages } from 'i18n';
import { RouteKey } from 'broker/enums';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import { FileTypeConfig } from './config';

export const useFileBucketConfig = () => {
  const { createQuoteFormUrl, marketingUpdateUrl } = useWorkspaceUrls();

  const { filesStep } = messages.submissionWorkspace.tabs.proposal;

  return useMemo(
    (): FileTypeConfig => ({
      [FileType.CapitolaQuote]: {
        title: filesStep.buckets.flowQuote.title,
        actionTitle: filesStep.buckets.flowQuote.actionTitle,
        route: createQuoteFormUrl,
        navigationOptions: { routeKey: RouteKey.Quote, state: { mode: FormMode.create } },
      },
      [FileType.QuoteComparison]: {
        title: filesStep.buckets.quoteComparison.title,
        actionTitle: filesStep.buckets.quoteComparison.actionTitle,
      },
      [FileType.MarketingReport]: {
        title: filesStep.buckets.marketingReport.title,
        actionTitle: filesStep.buckets.marketingReport.actionTitle,
        route: marketingUpdateUrl(),
        navigationOptions: { routeKey: RouteKey.MarketingUpdate, state: { omitEmailStep: true } },
      },
    }),
    [
      createQuoteFormUrl,
      filesStep.buckets.flowQuote.actionTitle,
      filesStep.buckets.flowQuote.title,
      filesStep.buckets.marketingReport.actionTitle,
      filesStep.buckets.marketingReport.title,
      filesStep.buckets.quoteComparison.actionTitle,
      filesStep.buckets.quoteComparison.title,
      marketingUpdateUrl,
    ],
  );
};
