import { ActivityType, EmailTemplateType, MarketRequestType } from 'enums';
import { useCurrentUser } from 'hooks';
import { messages } from 'i18n';
import { Layer, PartialSubmission } from 'types';
import { marketRequestTypeConfig } from 'utils';
import { EmailEditorProps } from 'broker/components/EmailEditor/store/EmailEditorStoreProvider';
import { PostEmailInformation } from 'broker/components/EmailEditor/types';
import {
  convertEndUserToRecipientGroup,
  createRecipientGroupFromSubmissionCustomer,
} from 'broker/components/Emails/recipient-utils';
import { allExpectedSubmissionFileTypes } from 'broker/config/submission-tasks-file-types-config';
import { EmailEditorPageMode } from 'broker/enums';
import useSubmissionMissingFiles from 'broker/hooks/useSubmissionMissingFiles';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { EmailEditorPageLocationState } from './types';
import { getQuotesFileIds } from './utils';

interface GetEmailEditorPageProps extends Partial<EmailEditorProps> {
  title: string;
  subtitle?: string;
  isLoading?: boolean;
}

function useHandleEmailSentToMarket() {
  const { logEmailActivities, updateSubmissionMarketRequest } = useSubmissionsWorkspace();

  return async (
    postEmailInformation: PostEmailInformation,
    layer?: Layer,
    locationState?: EmailEditorPageLocationState,
  ) => {
    const { submissionMarketIdToEmailId, emailTemplateType } = postEmailInformation;

    await Promise.all([
      locationState?.updateSubmissionMarketRequestStatus
        ? updateSubmissionMarketRequest(locationState.updateSubmissionMarketRequestStatus.submissionMarketRequestId, {
            status: locationState.updateSubmissionMarketRequestStatus.targetStatus,
          })
        : undefined,
      logEmailActivities(
        emailTemplateType,
        Object.keys(submissionMarketIdToEmailId).map((submissionMarketId) => ({
          submissionMarketId,
          submissionEmailId: submissionMarketIdToEmailId[submissionMarketId],
          layerId: layer?.id,
        })),
        false,
      ),
    ]);
  };
}

export default function useGetEmailEditorProps(
  state: EmailEditorPageLocationState,
  submission?: PartialSubmission,
): GetEmailEditorPageProps {
  const handleEmailSentToMarket = useHandleEmailSentToMarket();
  const { me } = useCurrentUser();

  const { logSubmissionActivity, partialSubmission, quotes, updateQuote } = useSubmissionsWorkspace();
  const { mode, layer, recipientGroups, marketRequestType, triggeringQuoteId } = state;
  const triggeringQuote = triggeringQuoteId
    ? quotes.find((quoteItem) => quoteItem.id === triggeringQuoteId)
    : undefined;
  // Optimization: prevent the box query from running if the template is not MissingInformationRequest
  const { getMissingFileTypes, isLoading } = useSubmissionMissingFiles(
    partialSubmission,
    mode === EmailEditorPageMode.MissingInformationRequest,
  );
  const rfqTemplateType = () => {
    if (layer) {
      return marketRequestType === MarketRequestType.Primary
        ? EmailTemplateType.PrimaryMarketing
        : EmailTemplateType.RfqAttachmentPoint;
    }
    return marketRequestType ? marketRequestTypeConfig[marketRequestType].emailTemplate : EmailTemplateType.RfqGeneral;
  };

  const emailEditorPropsByMode: Record<EmailEditorPageMode, GetEmailEditorPageProps> = {
    [EmailEditorPageMode.SendRfq]: {
      title: messages.submissionWorkspace.dialogs.emailEditor.submitToMarkets.title,
      subtitle: messages.submissionWorkspace.dialogs.emailEditor.submitToMarkets.subtitle,
      preSelectedTemplateType: rfqTemplateType(),
      preSelectedRecipients: recipientGroups || [],
      postEmailsSent: async (postEmailInformation) => handleEmailSentToMarket(postEmailInformation, layer),
      templateContextMetaData: {
        layer,
      },
      marketRequestType,
    },

    [EmailEditorPageMode.SendCustomer]: {
      title: messages.submissionWorkspace.dialogs.emailEditor.sendMessageToCustomers,
      preSelectedRecipients: submission ? [createRecipientGroupFromSubmissionCustomer(submission)] : [],
      postEmailsSent: async () => logSubmissionActivity({ activityType: ActivityType.CustomerEmailSent }),
      preSelectedFileIds: state.attachedFileIds ? state.attachedFileIds : [],
      preSelectedTemplateType: EmailTemplateType.Compose,
    },
    [EmailEditorPageMode.CannotPursue]: {
      title: messages.submissionWorkspace.dialogs.emailEditor.canNotPursue,
      preSelectedRecipients: submission ? [createRecipientGroupFromSubmissionCustomer(submission)] : [],
      preSelectedTemplateType: EmailTemplateType.CannotPursue,
    },
    [EmailEditorPageMode.PositiveExpectation]: {
      title: messages.submissionWorkspace.dialogs.emailEditor.positiveExpectation,
      preSelectedRecipients: submission ? [createRecipientGroupFromSubmissionCustomer(submission)] : [],
      preSelectedTemplateType: EmailTemplateType.PositiveExpectation,
    },
    [EmailEditorPageMode.MissingInformationRequest]: {
      title: messages.submissionWorkspace.dialogs.emailEditor.missingInformationRequest.title,
      subtitle: messages.submissionWorkspace.dialogs.emailEditor.missingInformationRequest.subtitle,
      preSelectedRecipients: submission ? [createRecipientGroupFromSubmissionCustomer(submission)] : [],
      preSelectedTemplateType: EmailTemplateType.MissingInformationRequest,
      templateContextMetaData: {
        submissionMissingFiles: getMissingFileTypes(allExpectedSubmissionFileTypes),
      },
    },
    [EmailEditorPageMode.UserNotification]: {
      title: messages.submissionWorkspace.dialogs.emailEditor.sendMessage,
      preSelectedRecipients: me?.endUser ? [convertEndUserToRecipientGroup(me.endUser)] : [],
      preSelectedTemplateType: EmailTemplateType.UserNotification,
    },
    [EmailEditorPageMode.SendMarket]: {
      title: messages.submissionWorkspace.dialogs.emailEditor.sendMessageToMarkets,
      preSelectedRecipients: recipientGroups || [],
      postEmailsSent: async (postEmailInformation) => handleEmailSentToMarket(postEmailInformation, undefined, state),
      preSelectedFileIds: state.attachedFileIds ? state.attachedFileIds : [],
      preSelectedTemplateType: EmailTemplateType.Compose,
    },
    [EmailEditorPageMode.EscalationPolicy]: {
      title: messages.submissionWorkspace.dialogs.emailEditor.sendMessage,
      preSelectedRecipients: me?.endUser ? [convertEndUserToRecipientGroup(me.endUser)] : [],
      preSelectedTemplateType: EmailTemplateType.EscalationPolicy,
      postEmailsSent: async () =>
        logSubmissionActivity({
          activityType: ActivityType.CopilotEscalationEmailSent,
          metadata: { recipientUserId: me?.endUser?.id },
        }),
    },
    // This special mode is used for the Reply email editor, title is usually overridden by useReplyEmail
    [EmailEditorPageMode.Default]: {
      title: messages.submissionWorkspace.dialogs.emailEditor.sendMessage,
    },
    [EmailEditorPageMode.NewQuote]: {
      title: messages.submissionWorkspace.dialogs.emailEditor.sendMessageToCustomers,
      preSelectedTemplateType: EmailTemplateType.NewQuote,
      preSelectedRecipients: submission ? [createRecipientGroupFromSubmissionCustomer(submission)] : [],
      preSelectedFileIds: triggeringQuote?.capitolaQuoteFileIds ?? [],
      templateContextMetaData: {
        triggeringQuote,
      },
      postEmailsSent: async () => {
        if (triggeringQuote) {
          const { sharingDetails } = triggeringQuote;
          await updateQuote(triggeringQuote.id, { sharingDetails: { ...sharingDetails, quoteShared: true } });
        }
      },
    },
    [EmailEditorPageMode.LayerBinder]: {
      title: messages.submissionWorkspace.dialogs.emailEditor.sendMessageToCustomers,
      preSelectedTemplateType: EmailTemplateType.LayerBinder,
      preSelectedRecipients: submission ? [createRecipientGroupFromSubmissionCustomer(submission)] : [],
      preSelectedFileIds: triggeringQuote?.binderFileIds ?? [],
      templateContextMetaData: {
        triggeringQuote,
      },
      postEmailsSent: async () => {
        if (triggeringQuote) {
          const { sharingDetails } = triggeringQuote;
          await updateQuote(triggeringQuote.id, { sharingDetails: { ...sharingDetails, binderShared: true } });
        }
      },
    },
    [EmailEditorPageMode.LayerPolicy]: {
      title: messages.submissionWorkspace.dialogs.emailEditor.sendMessageToCustomers,
      preSelectedTemplateType: EmailTemplateType.LayerPolicy,
      preSelectedRecipients: submission ? [createRecipientGroupFromSubmissionCustomer(submission)] : [],
      preSelectedFileIds: triggeringQuote?.policyFileIds ?? [],
      templateContextMetaData: {
        triggeringQuote,
      },
      postEmailsSent: async () => {
        if (triggeringQuote) {
          const { sharingDetails } = triggeringQuote;
          await updateQuote(triggeringQuote.id, { sharingDetails: { ...sharingDetails, policyShared: true } });
        }
      },
    },
    [EmailEditorPageMode.MarketingReport]: {
      title: messages.marketingReportPage.composeMarketingReport,
      preSelectedTemplateType: EmailTemplateType.MarketingReport,
      preSelectedRecipients: submission ? [createRecipientGroupFromSubmissionCustomer(submission)] : [],
      preSelectedFileIds: getQuotesFileIds(quotes),
      postEmailsSent: () =>
        logSubmissionActivity({
          activityType: ActivityType.MarketingReportSent,
        }),
    },
    [EmailEditorPageMode.InformationRequestsReport]: {
      title: messages.marketingReportPage.composeMarketingReport,
      preSelectedTemplateType: EmailTemplateType.InformationRequestedRetailer,
      preSelectedRecipients: submission ? [createRecipientGroupFromSubmissionCustomer(submission)] : [],
      postEmailsSent: () =>
        logSubmissionActivity({
          activityType: ActivityType.InformationRequestSent,
        }),
    },
    [EmailEditorPageMode.ConceptualTower]: {
      title: messages.ConceptualTowerPage.sendMode.title,
      subtitle: messages.ConceptualTowerPage.sendMode.subtitle,
      preSelectedTemplateType: EmailTemplateType.ConceptualTower,
      preSelectedRecipients: submission ? [createRecipientGroupFromSubmissionCustomer(submission)] : [],
      postEmailsSent: () =>
        logSubmissionActivity({
          activityType: ActivityType.ConceptualTowerSent,
        }),
    },
  };

  return { ...emailEditorPropsByMode[mode], isLoading };
}
