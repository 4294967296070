import { useParams } from 'react-router-dom-latest';
import { Paper } from '@common-components';
import { useRetrieveEmailTemplate } from 'hooks';
import { EmailTemplateEditor } from 'admin/components/email-templates/EmailTemplateEditor';

export default function EditEmailTemplatePage() {
  const { id: emailTemplateId } = useParams<{ id: string }>();

  const { data: emailTemplate } = useRetrieveEmailTemplate({
    id: emailTemplateId!,
    enabled: !!emailTemplateId,
  });

  return (
    <Paper sx={{ mt: 0 }}>
      <EmailTemplateEditor emailTemplate={emailTemplate} />
    </Paper>
  );
}
