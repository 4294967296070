import { QuoteFileIdType } from 'enums';
import { Quote } from 'types';
import { QuoteFileKey } from 'broker/components/common/QuotesAndPolicies/quotesAndPoliciesConfig';

export function getSelectionQuotes(marketActiveQuotes: Quote[], fileType?: QuoteFileKey) {
  if (!fileType) {
    return [];
  }

  if (fileType === QuoteFileIdType.MarketQuote) {
    return marketActiveQuotes.filter((quote) => quote.binderFileIds.length === 0 && quote.policyFileIds.length === 0);
  }

  if (fileType === QuoteFileIdType.Binder) {
    return marketActiveQuotes.filter((quote) => quote.binderFileIds.length > 0 && quote.policyFileIds.length === 0);
  }

  return marketActiveQuotes.filter((quote) => quote.policyFileIds.length > 0);
}
