import { compact } from 'lodash';
import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { AIFeature, AIMessageType, UserRole } from 'enums';
import { useAIAPI, useBoolean, useCurrentUser, useStateWithServerStreaming } from 'hooks';
import { AICreateThreadOutput, AIMessage, ShareAIThreadInput } from 'types';
import { logger } from 'utils';
import { removeCitations, resolveCitations } from './citations-utils';
import { useMultipleAIFeaturesContext } from './MultipleAIFeaturesContextProvider';
import { ThreadEnrichmentData } from './types';
import { useResolveEnrichmentData } from './useResolveEnrichmentData';
import useShareThreadOnUnmount from './useShareThreadOnUnmount';

export type CreateThreadAction = () => Promise<AICreateThreadOutput | null>;

export interface AIContextData {
  feature: AIFeature;
  messages: AIMessage[];
  assistantId?: string;
  threadId?: string;
  createAIThread: (action: CreateThreadAction) => Promise<AICreateThreadOutput | null>;
  sendAIThread: (input: Pick<ShareAIThreadInput, 'feedback' | 'messages'>) => Promise<void>;
  addFollowupMessage: (followupQuestion: string) => Promise<void>;
  isContentStreaming: boolean;
  isLoading: boolean;
  isRegenerating: boolean;
  setCreateThreadAction: (action: () => CreateThreadAction) => void;
  regenerateAIThread: () => Promise<void>;
  enrichmentData?: ThreadEnrichmentData;
}

export const AIFeatureContext = createContext<AIContextData | undefined>(undefined);

export function SingleAIFeatureContextProvider({ children, feature }: { children: ReactNode; feature: AIFeature }) {
  const { state, setFeatureState } = useMultipleAIFeaturesContext();
  const featureState = state[feature];
  const { shareAIThread, addMessageAndRun } = useAIAPI();
  const { me } = useCurrentUser();
  const [createThreadAction, setCreateThreadAction] = useState<CreateThreadAction | undefined>(undefined);

  const [streamedContent, isContentStreaming, stopStreaming] = useStateWithServerStreaming(feature);

  const [isLoading, { on: startLoading, off: stopLoading }] = useBoolean(false);
  const [isRegenerating, { on: startRegenerating, off: stopRegenerating }] = useBoolean(false);

  const sendAIThread = useCallback(
    async (input: Pick<ShareAIThreadInput, 'feedback' | 'messages'>) => {
      await shareAIThread({
        ...input,
        feature,
        threadId: featureState?.threadId || '',
        user: me?.email || '',
      });
    },
    [shareAIThread, feature, featureState?.threadId, me?.email],
  );

  useShareThreadOnUnmount({ messages: featureState?.messages, sendAIThread });

  const resolveEnrichmentData = useResolveEnrichmentData();

  const createAIThread = useCallback(
    async (action: CreateThreadAction) => {
      try {
        stopStreaming();
        startLoading();
        setFeatureState(feature, () => ({
          messages: [],
          threadId: undefined,
          assistantId: undefined,
          uploadedFiles: [],
          enrichmentData: undefined,
        }));

        const result = await action();
        if (!result) {
          return null;
        }

        // For now citations is opened on all features for all users, and for recommendations to BackOffice users only
        // TODO: https://capitola-ins.atlassian.net/browse/CAP-4679
        const resolvedResponse =
          feature !== AIFeature.ProductRecommendation || me?.role === UserRole.BackOffice
            ? resolveCitations(result.response, result.citations)
            : removeCitations(result.response, result.citations);

        const newMessages = [
          { type: AIMessageType.Prompt, content: result.prompt },
          { type: AIMessageType.Response, content: resolvedResponse },
        ];

        const enrichmentData = await resolveEnrichmentData(
          result.uploadedFileIds,
          result.inboundEmailIds,
          result.productsEmailSignals,
        );

        setFeatureState(feature, () => ({
          messages: newMessages,
          threadId: result.threadId,
          assistantId: result.assistantId,
          enrichmentData,
        }));

        return result;
      } finally {
        stopLoading();
      }
    },
    [feature, resolveEnrichmentData, setFeatureState, startLoading, stopLoading, stopStreaming, me?.role],
  );

  const regenerateAIThread = useCallback(async () => {
    try {
      startRegenerating();
      if (createThreadAction) {
        await createAIThread(createThreadAction);
      }
    } finally {
      stopRegenerating();
    }
  }, [createAIThread, createThreadAction, startRegenerating, stopRegenerating]);

  const addFollowupMessage = useCallback(
    async (followupQuestion: string) => {
      if (!featureState?.threadId || !featureState?.assistantId) {
        logger.log('error', {
          message: 'An attempt to add followup question without an existing thread context',
          feature,
        });
        return;
      }

      try {
        startLoading();
        setFeatureState(feature, (currentState) => ({
          messages: [...(currentState?.messages ?? []), { type: AIMessageType.Prompt, content: followupQuestion }],
        }));
        const addMessageAndRunResult = await addMessageAndRun({
          prompt: followupQuestion,
          streamingContext: feature,
          threadId: featureState.threadId,
          assistantId: featureState.assistantId,
        });
        if (addMessageAndRunResult) {
          setFeatureState(feature, (currentState) => ({
            messages: [
              ...(currentState?.messages ?? []),
              { type: AIMessageType.Response, content: addMessageAndRunResult.response },
            ],
          }));
        }
      } finally {
        stopLoading();
      }
    },
    [addMessageAndRun, feature, featureState, setFeatureState, startLoading, stopLoading],
  );

  const messages = useMemo(
    () =>
      compact([
        ...(featureState?.messages ?? []),
        isContentStreaming ? { content: streamedContent, type: AIMessageType.Response, isStreaming: true } : undefined,
      ]),
    [featureState?.messages, isContentStreaming, streamedContent],
  );

  const contextValue: AIContextData = useMemo(
    () => ({
      feature,
      messages,
      enrichmentData: featureState?.enrichmentData,
      createAIThread,
      addFollowupMessage,
      isContentStreaming,
      isLoading,
      sendAIThread,
      isRegenerating,
      setCreateThreadAction,
      regenerateAIThread,
      threadId: featureState?.threadId,
    }),
    [
      feature,
      messages,
      featureState,
      createAIThread,
      addFollowupMessage,
      isContentStreaming,
      isLoading,
      sendAIThread,
      isRegenerating,
      regenerateAIThread,
    ],
  );

  return <AIFeatureContext.Provider value={contextValue}>{children}</AIFeatureContext.Provider>;
}
