import { Route, Routes } from 'react-router-dom-latest';
import { Box, Divider, IconButton, Stack, Tab, Tabs } from '@common-components';
import { ChevronRight } from '@icons';
import { NotificationStatus } from 'enums';
import { useGetParam, useMatchNavigation, useSearchNotification } from 'hooks';
import { messages } from 'i18n';
import { BrokerUrlParams } from 'broker/broker-routes';
import BlockingOverlay from 'broker/components/BlockingOverlay';
import { useUpdateQueryParam } from 'broker/hooks';
import useOverlays from 'broker/hooks/useOverlays';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import {
  BASE_TOOLBOX_TABS,
  ToolboxTabs,
  WORKSPACE_PAGES_ROUTES,
  WorkspaceToolboxTabsRoutesValues,
} from 'broker/pages/SubmissionWorkspacePage/types';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import EmailView from './components/Email/EmailView';
import { workspaceToolboxRoutesTabsConfig } from './config';
import NavigationTab from './NavigationTab';
import { TabsStyle } from './styles';
import TabPanel from './TabPanel';
import { toolboxTabsConfig } from './toolboxTabsConfig';
import { useBlockedByCopilot } from './useBlockedByCopilot';
import { useGetActiveTab } from './useGetActiveTab';
import { useToolbox } from './useToolbox';

// Wrap the Divider with a component to avoid the "React does not recognize the props" warnings in the console
// See: https://stackoverflow.com/questions/60526742/using-dividers-inside-material-ui-tabs
function TabDivider() {
  return <Divider sx={{ m: 2 }} />;
}

export default function Toolbox() {
  const { toolboxOverlay } = useUiStoreState();
  const currentWorkspacePageRoute = useMatchNavigation(WORKSPACE_PAGES_ROUTES, WORKSPACE_PAGES_ROUTES.MARKETING);
  const updateQueryParam = useUpdateQueryParam();
  const activeTab = useGetActiveTab();
  const selectedEmailUrlParam = useGetParam(BrokerUrlParams.SELECTED_EMAIL);
  const { partialSubmission } = useSubmissionsWorkspace();

  const blockedByCopilot = useBlockedByCopilot();

  const overlays = useOverlays() as WorkspaceToolboxTabsRoutesValues[];
  const { setActiveOverlayItem, setToolboxState } = useUiStoreActions();

  const currentWorkspacePage: WorkspaceToolboxTabsRoutesValues =
    overlays.length && workspaceToolboxRoutesTabsConfig[overlays[0]] ? overlays[0] : currentWorkspacePageRoute;

  useToolbox({ activeTab, currentWorkspacePage });
  const handleChange = (_: any, newValue: ToolboxTabs) => {
    updateQueryParam({
      addParams: [
        {
          queryParam: BrokerUrlParams.ACTIVE_TAB,
          value: newValue,
        },
      ],
      removeParams: [BrokerUrlParams.SELECTED_FILE, BrokerUrlParams.SELECTED_EMAIL],
    });
    setToolboxState({ isExpanded: false });
  };

  const shouldHideEmailViewActions = (): boolean => {
    const { config } = workspaceToolboxRoutesTabsConfig[currentWorkspacePage!];
    if (Object.keys(config || {}).includes(BASE_TOOLBOX_TABS.EMAIL)) {
      return !!config?.[BASE_TOOLBOX_TABS.EMAIL]?.hideEmailActions;
    }
    return false;
  };

  const { items: notifications } = useSearchNotification({
    filter: {
      submissionId: partialSubmission?.id,
    },
    enabled: !!partialSubmission,
  });

  const handleClick = (toolboxTab: ToolboxTabs) => {
    if (activeTab === toolboxTab) {
      updateQueryParam({
        removeParams: [BrokerUrlParams.SELECTED_FILE, BrokerUrlParams.SELECTED_EMAIL],
      });
    }
  };

  const activeTasksLength = notifications.filter((task) => task.status === NotificationStatus.New).length;

  return (
    <Routes>
      <Route
        path=":tab/*"
        element={
          <Stack direction="row" overflow="auto" flex={1} position="relative">
            <Stack alignItems="center" pt={3.5}>
              <Box height={80}>
                <Box sx={{ rotate: activeTab ? 0 : '180deg' }}>
                  <IconButton
                    tooltipContent={
                      activeTab
                        ? messages.submissionWorkspace.toolbox.closeToolbox
                        : messages.submissionWorkspace.toolbox.openToolbox
                    }
                    color="secondary"
                    icon={ChevronRight}
                    size="small"
                    onClick={() => {
                      if (activeTab) {
                        updateQueryParam({
                          removeParams: [
                            BrokerUrlParams.ACTIVE_TAB,
                            BrokerUrlParams.SELECTED_FILE,
                            BrokerUrlParams.SELECTED_EMAIL,
                          ],
                        });
                        setToolboxState({ isExpanded: false });
                      } else {
                        updateQueryParam({
                          addParams: [
                            {
                              queryParam: BrokerUrlParams.ACTIVE_TAB,
                              value: workspaceToolboxRoutesTabsConfig[currentWorkspacePage].defaultTab,
                            },
                          ],
                        });
                      }
                    }}
                  />
                </Box>
              </Box>
              <Tabs
                value={activeTab || 'CLEAR'}
                onChange={handleChange}
                aria-label="toolbox navigation"
                sx={TabsStyle(!!activeTab)}
                orientation="vertical"
              >
                {Object.values(BASE_TOOLBOX_TABS)
                  .filter((tab) => !workspaceToolboxRoutesTabsConfig[currentWorkspacePage].hideBaseTabs?.includes(tab))
                  .map((toolboxTab) => {
                    const config = toolboxTabsConfig[toolboxTab];
                    return (
                      <NavigationTab
                        badgeContent={
                          toolboxTab === BASE_TOOLBOX_TABS.TASKS && activeTab !== toolboxTab
                            ? activeTasksLength
                            : undefined
                        }
                        key={toolboxTab}
                        ariaLabelId={config.ariaLabelId}
                        value={config.value}
                        tooltip={config.tooltip}
                        Icon={config.icon}
                        onClick={() => handleClick(toolboxTab)}
                      />
                    );
                  })}

                {workspaceToolboxRoutesTabsConfig[currentWorkspacePage].additionalTabs && <TabDivider />}
                {workspaceToolboxRoutesTabsConfig[currentWorkspacePage].additionalTabs?.map((toolboxTab) => {
                  const config = toolboxTabsConfig[toolboxTab];
                  return (
                    <NavigationTab
                      key={toolboxTab}
                      ariaLabelId={config.ariaLabelId}
                      value={config.value}
                      tooltip={config.tooltip}
                      Icon={config.icon}
                    />
                  );
                })}
                <Tab sx={{ minWidth: '1px', minHeight: 0, height: '1px', p: 0 }} value="CLEAR" />
              </Tabs>
            </Stack>
            {Object.values(BASE_TOOLBOX_TABS).map((toolboxTab) => {
              const config = toolboxTabsConfig[toolboxTab];
              const Component = config.component;
              if (
                !activeTab ||
                workspaceToolboxRoutesTabsConfig[currentWorkspacePage].hideBaseTabs?.includes(toolboxTab)
              ) {
                return null;
              }

              return (
                <TabPanel key={toolboxTab} value={activeTab} index={toolboxTab}>
                  {blockedByCopilot && <BlockingOverlay />}
                  <Component
                    {...(workspaceToolboxRoutesTabsConfig[currentWorkspacePage].config?.[toolboxTab]
                      ? workspaceToolboxRoutesTabsConfig[currentWorkspacePage].config![toolboxTab]!
                      : {})}
                  />
                </TabPanel>
              );
            })}
            {workspaceToolboxRoutesTabsConfig[currentWorkspacePage].additionalTabs?.map((toolboxTab) => {
              const config = toolboxTabsConfig[toolboxTab];
              const Component = config.component;
              if (!activeTab) {
                return null;
              }
              return (
                <TabPanel key={toolboxTab} value={activeTab} index={toolboxTab}>
                  <Component
                    {...(workspaceToolboxRoutesTabsConfig[currentWorkspacePage].config?.[toolboxTab]
                      ? workspaceToolboxRoutesTabsConfig[currentWorkspacePage].config![toolboxTab]!
                      : {})}
                  />
                </TabPanel>
              );
            })}

            {toolboxOverlay?.selectedEmailView && selectedEmailUrlParam && (
              <EmailView
                emailId={toolboxOverlay.selectedEmailView.emailId}
                emailType={toolboxOverlay.selectedEmailView.emailType}
                hideEmailActions={shouldHideEmailViewActions()}
                onClose={() => {
                  updateQueryParam({
                    removeParams: [BrokerUrlParams.SELECTED_EMAIL],
                  });
                  setActiveOverlayItem({ selectedEmailView: undefined });
                  setToolboxState({ isExpanded: false });
                }}
              />
            )}
          </Stack>
        }
      />
    </Routes>
  );
}
