import { OptionsObject, useSnackbar } from 'notistack';
import { ReactNode } from 'react';
import { SvgIconComponent } from '@icons';
import { Box, Stack, Typography } from 'components/mui-index';
import Toast from 'components/Toast';

const defaultToastParams: Partial<OptionsObject> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

type ToastOptions = OptionsObject & { dismissable?: boolean; icon?: SvgIconComponent };
type ToastVariant = 'success' | 'error' | 'warning' | 'info' | 'default';
interface ToastMessage {
  message: ReactNode;
  title?: string;
}

export const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const openToast = (variant: ToastVariant, message: ToastMessage, options?: ToastOptions) => {
    const key = options?.key ?? `toast-${variant}-${Date.now()}`;
    const content = message.title ? (
      <Stack>
        <Typography variant="body1Bold">{message.title}</Typography>
        <Typography variant="caption">{message.message}</Typography>
      </Stack>
    ) : (
      message.message
    );
    return enqueueSnackbar(content, {
      ...defaultToastParams,
      key,
      variant,
      className: 'cap-toast-snackbar',
      content:
        variant === 'default' ? undefined : (
          /* This box wrapper is to prevent short flickering upon toast dismiss */
          <Box>
            <Toast id={key} variant={variant} dismissable={options?.dismissable ?? true} icon={options?.icon}>
              {content}
            </Toast>
          </Box>
        ),
      ...options,
    });
  };

  const showToast = (variant: ToastVariant, message: ToastMessage, options?: ToastOptions) =>
    openToast(variant, message, options);

  const showDelayedToast = async (
    variant: ToastVariant,
    message: ToastMessage,
    delay: number,
    options?: ToastOptions,
  ) => setTimeout(() => openToast(variant, message, options), delay);

  return { showToast, showDelayedToast, closeSnackbar };
};
