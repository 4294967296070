import { isEmpty } from 'lodash';
import { Chip, Divider, Menu, Stack, Typography } from '@common-components';
import { SettingsEthernet, WarningAmber } from '@icons';
import { QuoteExits } from 'clients/types';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { InsuranceProduct } from 'types';

export interface ApiProductsDropDownProps {
  products: InsuranceProduct[];
  quoteExits?: QuoteExits[];
  menuPosition: 'left' | 'right';
}

export default function ApiProductsDropDown({ products, menuPosition, quoteExits }: ApiProductsDropDownProps) {
  const { anchorEl, isMenuOpen, closeMenu, openMenu } = useOptionsMenu();

  const numberOfActiveApiProducts = quoteExits
    ? products.filter((product) => !quoteExits.some((q) => product.externalProductId === q.product_id)).length
    : products.length;

  return (
    <>
      <Chip
        disabled={products.length === 0}
        startIcon={SettingsEthernet}
        rounded
        dropdown
        variant="outlined"
        label={messages.heraldForm.apiAvailable(numberOfActiveApiProducts, products.length)}
        onClick={openMenu}
        color="green.400"
      />
      <Menu
        anchorOrigin={{
          horizontal: menuPosition,
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: menuPosition,
          vertical: 'top',
        }}
        id="api-products-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'user-menu',
          sx: { width: 330 },
        }}
      >
        {products.length > 0 && (
          <Stack width={1} p={2} gap={2} divider={<Divider />}>
            {products.map((item) => {
              const quoteExit = quoteExits?.find((q) => q.product_id === item.externalProductId);
              const hasQuoteExit = !isEmpty(quoteExit);
              return (
                <Stack key={item.id} direction="row" gap={1} alignItems="center" justifyContent="space-between">
                  <Stack>
                    <Typography variant="body2">{item.marketName}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {item.name}
                    </Typography>
                    {hasQuoteExit && (
                      <Typography color="error" variant="caption">
                        {quoteExit.status_details[0].status_text.applicant_facing_text}
                      </Typography>
                    )}
                  </Stack>
                  {hasQuoteExit && <WarningAmber color="error" />}
                </Stack>
              );
            })}
          </Stack>
        )}
      </Menu>
    </>
  );
}
