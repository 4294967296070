import { Highlight, Stack, Tooltip, Typography, TypographyProps } from '@common-components';

interface TypographyColumnProps extends Omit<TypographyProps, 'ref'> {
  width: number;
  searchInput: string;
  textToHighlight: string;
}
export default function TypographyColumn({
  width,
  searchInput,
  textToHighlight,
  color = 'text.primary',
  variant = 'body2',
  ...rest
}: TypographyColumnProps) {
  return (
    <Stack flex={`0 0 ${width}px`} overflow="hidden" direction="row" alignItems="center">
      <Tooltip tooltipContent={<Highlight searchWords={[searchInput]} textToHighlight={textToHighlight} />}>
        <Typography color={color} variant={variant} component="div" overflow="hidden" noWrap {...rest}>
          <Highlight searchWords={[searchInput]} textToHighlight={textToHighlight} />
        </Typography>
      </Tooltip>
    </Stack>
  );
}
