import { QuoteStatusLocationState } from 'broker/pages/SubmissionWorkspacePage/dialogs/QuoteStatusModal/utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export default function useQuoteModalState(locationState: QuoteStatusLocationState) {
  const { markets } = useSubmissionsWorkspace();

  const { selectedQuote } = locationState;

  const submissionMarket = markets.find((market) => market.id === selectedQuote.submissionMarketId)!;

  return {
    ...locationState,
    selectedQuote,
    submissionMarket,
  };
}
