import { useCallback } from 'react';
import { Box } from '@common-components';
import APP_ROUTES from 'app-routes';
import { messages } from 'i18n';
import { generateSubmissionPath } from 'broker/broker-routes';
import EmailEditor from 'broker/components/EmailEditor';
import EmailEditorFooter from 'broker/components/EmailEditor/EmailEditorFooter';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { RouteKey } from 'broker/enums';
import { useNavigate, useNavigateBack } from 'broker/hooks';
import { AIButtonWithPopup } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIButtonWithPopup';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

interface AcknowledgmentEmailInnerPageLayoutProps {
  setIsDirty: (is: boolean) => void;
}

export default function AcknowledgmentEmailInnerPageLayout({ setIsDirty }: AcknowledgmentEmailInnerPageLayoutProps) {
  const { partialSubmission, updateSubmission } = useSubmissionsWorkspace();
  const navigateBack = useNavigateBack();
  const {
    draftPreview: { body: draftBody },
  } = useEmailEditorState();
  const navigate = useNavigate();
  const { successMessage, sendToClient, aiPopupTitle } = messages.acknowledgmentEmailPage;

  const onAfterSent = useCallback(() => {
    navigate(
      {
        pathname: generateSubmissionPath(partialSubmission!.id),
      },
      {
        routeKey: RouteKey.WorkspaceTab,
        state: undefined,
      },
    );
  }, [navigate, partialSubmission]);

  return (
    <InnerPageLayout
      title={messages.acknowledgmentFlow.title}
      onCloseOverride={async () => {
        setIsDirty(false);
        try {
          setIsDirty(false);
          await updateSubmission(partialSubmission!.id, {
            draftAckEmailBody: draftBody,
          });
          navigateBack();
        } catch (e) {
          setIsDirty(true);
        }
      }}
      footer={(innerPageProps) => (
        <EmailEditorFooter
          {...innerPageProps}
          successMessage={successMessage}
          proceedButtonText={sendToClient}
          onAfterSent={onAfterSent}
          secondaryButtonText={messages.buttons.saveForLater}
          secondaryButtonAction={async () => {
            setIsDirty(false);
            await updateSubmission(partialSubmission!.id, {
              draftAckEmailBody: draftBody,
            });
            navigate(APP_ROUTES.GENERAL.HOME, { routeKey: RouteKey.Home, state: undefined });
          }}
        />
      )}
    >
      {() => (
        <Box position="relative" height={1}>
          <AIButtonWithPopup popupTitle={aiPopupTitle} top={148} right={37} />
          <EmailEditor />
        </Box>
      )}
    </InnerPageLayout>
  );
}
