import EditSubmissionNew from './components/NestedViews/EditSubmissionNew';
import MarketingReport from './components/NestedViews/MarketingReport';
import CompareQuotesModal from './dialogs/CompareQuotesModal';
import QuoteStatusModal from './dialogs/QuoteStatusModal/QuoteStatusModal';

import PortalWorkspacePage from './PortalWorkspacePage/PortalWorkspacePage';
import QuoteReview from './PortalWorkspacePage/QuoteReview/QuoteReview';
import SubmissionWorkspacePage from './SubmissionWorkspacePage';

export default SubmissionWorkspacePage;

export { CompareQuotesModal, QuoteStatusModal, PortalWorkspacePage, EditSubmissionNew, MarketingReport, QuoteReview };
