import { messages } from 'i18n';
import { StaticFormSection } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/Screens/SubmissionStaticForm/StaticForm/types';

type StaticSubmissionSectionConfig = {
  label: string;
  priority: number;
};

export const staticSubmissionSectionConfig: Record<StaticFormSection, StaticSubmissionSectionConfig> = {
  [StaticFormSection.InsuredInformation]: { label: messages.editSubmission.insuredInformation, priority: 1 },
  [StaticFormSection.CoverageParameters]: { label: messages.editSubmission.coverageParameters, priority: 2 },
};
