import { ModalProvider } from 'broker/pages/SubmissionWorkspacePage/dialogs/modalProvider/modalProvider';
import { UiStoreProvider } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import Main from './Main';

export default function PortalWorkspacePage() {
  return (
    <ModalProvider>
      <UiStoreProvider>
        <Main />
      </UiStoreProvider>
    </ModalProvider>
  );
}
