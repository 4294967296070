import { useMemo } from 'react';
import { InboundEmailMessageStatus, SubmissionEmailLabel } from 'enums';
import { useSearchInboundEmailMessage } from 'hooks/api/inboundEmailMessage';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export function useGetSubmissionCreationEmail() {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { items: submissionInboundEmails, isLoading } = useSearchInboundEmailMessage({
    filter: {
      submissionId: partialSubmission?.id,
      excludedStatus: InboundEmailMessageStatus.Processing,
    },
    enabled: !!partialSubmission?.id,
  });

  return useMemo(
    () => ({
      isLoading,
      submissionCreationEmail: submissionInboundEmails.find(
        (submissionInboundMessage) => submissionInboundMessage.label === SubmissionEmailLabel.SubmissionBox,
      ),
    }),
    [submissionInboundEmails, isLoading],
  );
}
