import { Stack } from '@common-components';
import { ActivityType } from 'enums';
import { ActivityLog } from 'types';
import { MenuItemProps } from 'components/menu';
import { ActivitiesForDate } from './ActivitiesForDate';
import { Header } from './Header';
import { ActivityTypeDisplayConfig } from './types';
import { groupActivitiesByDate } from './utils';

interface MarketActivityProps {
  activities: ActivityLog[];
  activityTypeConfig: Partial<Record<ActivityType, ActivityTypeDisplayConfig>>;
  title: string;
  shareOptions?: MenuItemProps[];
}

export const ActivityLogList = ({ activities, activityTypeConfig, title, shareOptions }: MarketActivityProps) => {
  const activitiesByDate = groupActivitiesByDate(activities);

  return (
    <Stack overflow="hidden" height={1}>
      <Header title={title} shareOptions={shareOptions} />
      <Stack position="relative" sx={{ overflowY: 'auto' }} height={1}>
        <Stack direction="column" px={2} pb={3} minHeight={1} overflow="auto">
          {Object.entries(activitiesByDate).map(([date, activitiesInDate]) => (
            <ActivitiesForDate key={date} date={date} activities={activitiesInDate} config={activityTypeConfig} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
