import { Ref } from 'react';
import { Paper, PaperProps, TextField, TextFieldProps, Tooltip } from '@common-components';
import { GridFormFieldCommonStyles } from 'components/hookFormComponents/GridFormFields/styles';

export interface AutocompleteOption {
  label: string;
  subLabel?: string;
  value: string;
  action?: () => void;
}

export function ElevatedPaperComponent({ children, ...props }: PaperProps) {
  return (
    <Paper elevation={8} sx={{ minWidth: 300 }} {...props}>
      {children}
    </Paper>
  );
}

interface RenderInputProps {
  params: TextFieldProps;
  withTooltip?: boolean;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  ref?: Ref<HTMLInputElement>;
}

export const gridRenderInput = ({ params, withTooltip, tooltipPlacement, ref }: RenderInputProps) => (
  <Tooltip tooltipContent={withTooltip ? params.inputProps?.value : ''} placement={tooltipPlacement}>
    <TextField
      inputRef={ref}
      // prevent new line on enter key
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
        }
      }}
      variant="standard"
      sx={{
        ...GridFormFieldCommonStyles,
        p: 0,
      }}
      {...params}
    />
  </Tooltip>
);

export const renderInput = ({ params, withTooltip, tooltipPlacement, ref }: RenderInputProps) => (
  <Tooltip tooltipContent={withTooltip ? params.inputProps?.value : ''} placement={tooltipPlacement}>
    <TextField
      inputRef={ref}
      sx={{
        input: {
          typography: 'body2',
        },
      }}
      {...params}
    />
  </Tooltip>
);

const getOptionKey =
  (attribute: keyof Pick<AutocompleteOption, 'label' | 'value'>) => (option: AutocompleteOption | string | null) => {
    if (typeof option === 'object' && option !== null) {
      return option[attribute];
    }

    if (typeof option === 'string') {
      return option;
    }

    // needs to be null in order to reset the auto complete (deleting the choice) when there is default values (update mode)
    return null;
  };

export const getOptionLabel = (option: AutocompleteOption | string | null) => {
  if (typeof option === 'object' && option !== null) {
    return option.label + (option.subLabel ? ` (${option.subLabel})` : '');
  }

  if (typeof option === 'string') {
    return option;
  }

  return '';
};

export const getOptionValue = getOptionKey('value');
