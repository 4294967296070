import { EmailTemplateType, MarketRequestType } from 'enums';
import { messages } from 'i18n';

export const marketRequestTypeConfig: Record<
  MarketRequestType,
  { title: string; addMarketsPageSubTitle: string; emailTemplate: EmailTemplateType }
> = {
  [MarketRequestType.Primary]: {
    title: messages.submissionWorkspace.marketRequestsWorkspace.primary,
    addMarketsPageSubTitle: messages.addMarketsPage.subtitle.primary,
    emailTemplate: EmailTemplateType.PrimaryMarketing,
  },
  [MarketRequestType.Excess]: {
    title: messages.submissionWorkspace.marketRequestsWorkspace.excess,
    addMarketsPageSubTitle: messages.addMarketsPage.subtitle.excess,
    emailTemplate: EmailTemplateType.ExcessMarketing,
  },
};
