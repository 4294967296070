import { useMemo, useState } from 'react';
import { SubmissionMethod } from 'enums';
import { CancelActionButton } from './components/BulkActionButtons/CancelActionButton';
import { SubmitViaActionButton } from './components/BulkActionButtons/SubmitViaActionButton';
import { SelectionActionButtonBaseProps } from './types';

export function useBulkSubmitMethod() {
  const [bulkSubmitMethod, setBulkSubmitMethod] = useState<SubmissionMethod>();
  const clearBulkSubmitMethod = () => {
    setBulkSubmitMethod(undefined);
  };

  const bulkSubmitActionButtons: ((props: SelectionActionButtonBaseProps) => JSX.Element)[] = useMemo(() => {
    switch (bulkSubmitMethod) {
      case SubmissionMethod.Email:
      case SubmissionMethod.API:
        return [
          (props) => (
            <SubmitViaActionButton {...props} method={bulkSubmitMethod} clearBulkSubmitMethod={clearBulkSubmitMethod} />
          ),
          (props) => <CancelActionButton {...props} onCancelling={clearBulkSubmitMethod} />,
        ];

      default:
        return [];
    }
  }, [bulkSubmitMethod]);

  return { bulkSubmitMethod, setBulkSubmitMethod, clearBulkSubmitMethod, bulkSubmitActionButtons };
}
