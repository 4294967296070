import { Box, Divider, Stack } from '@common-components';
import { messages } from 'i18n';
import { CcField } from 'broker/components/EmailEditor/components/ContentFields/CcField';
import { FieldLabel } from 'broker/components/EmailEditor/components/ContentFields/FieldLabel';
import SingleContactRecipientTag from 'broker/components/EmailEditor/components/SingleContactRecipientTag';
import { EmailType } from 'broker/components/EmailEditor/store/types';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import MarketSelector from './MarketSelector';
import { RecipientGroupsList } from './RecipientGroupsList';

export default function RecipientSelection() {
  const {
    emailType,
    isSingleRecipientMode,
    recipients: { recipientGroupsArray, recipientsGroupsAvailableRecipients },
  } = useEmailEditorState();

  const renderRecipients = () => {
    if (emailType === EmailType.ExternalUser || emailType === EmailType.UserNotification) {
      return <SingleContactRecipientTag recipientGroup={recipientGroupsArray[0]} />;
    }

    if (isSingleRecipientMode) {
      return (
        <RecipientGroupsList
          recipientGroups={recipientGroupsArray}
          recipientGroupsAvailableContacts={recipientsGroupsAvailableRecipients}
          isReadOnly
        />
      );
    }

    return (
      <MarketSelector
        recipientGroups={recipientGroupsArray}
        recipientGroupsAvailableContacts={recipientsGroupsAvailableRecipients}
      />
    );
  };

  return (
    <Box maxHeight={96} width="100%" sx={{ py: 1, px: 1.5, backgroundColor: 'white' }}>
      <Stack direction="row" alignItems="center">
        <FieldLabel text={messages.emailEditor.to} sx={{ alignSelf: 'flex-start', mt: '6px' }} />
        <Stack flex={1} sx={{ overflowY: 'auto' }}>
          {renderRecipients()}
        </Stack>
        <Stack sx={{ alignSelf: 'flex-start' }} direction="row">
          <Divider orientation="vertical" flexItem variant="middle" sx={{ mx: 1, my: 0.5 }} />
          <CcField />
        </Stack>
      </Stack>
    </Box>
  );
}
