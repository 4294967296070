import { Typography } from '@common-components';
import { messages } from 'i18n';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';

interface SubmitViaApiDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isSubmitting: boolean;
  numberOfProducts: number;
}

export default function SubmitViaApiDialog({
  isOpen,
  onClose,
  onConfirm,
  isSubmitting,
  numberOfProducts,
}: SubmitViaApiDialogProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} alertDialog alertDialogMaxWidth={600}>
      <DialogHeader
        id="submit-via-api-modal-title"
        title={messages.submissionWorkspace.submitViaApiModal.title(numberOfProducts)}
        onClose={onClose}
      />
      <DialogContent>
        <Typography variant="body1" color="textPrimary" mb={6}>
          {messages.submissionWorkspace.submitViaApiModal.description}
        </Typography>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: messages.submissionWorkspace.submitViaApiModal.buttons.submitNow,
          onClick: onConfirm,
          loading: isSubmitting,
        }}
        cancelButton={{
          children: messages.submissionWorkspace.submitViaApiModal.buttons.illSubmitLater,
          onClick: onClose,
        }}
      />
    </Dialog>
  );
}
