import { EmailTemplateType } from 'enums';
import { PartialSubmission, SubmissionMarket } from 'types';
import { Nullable } from 'utils';
import { EmailItemType } from 'broker/components/Emails/types';
import { UnifiedEmail } from 'broker/components/Emails/UnifiedEmail';
import { ReplyMode } from './types';

export const useFollowupTemplate = (
  replyMode: ReplyMode,
  submission: Nullable<PartialSubmission>,
  submissionMarkets: SubmissionMarket[],
  unifiedEmail: UnifiedEmail | undefined,
) => {
  if (replyMode === ReplyMode.Duplicate) {
    return undefined;
  }

  if (unifiedEmail?.emailType === EmailItemType.SubmissionInbound) {
    return undefined;
  }

  if (!submission || !unifiedEmail) {
    return undefined;
  }

  if (submission.organizationId === unifiedEmail.recipientOrganization?.id) {
    return EmailTemplateType.CustomerEmailFollowup;
  }

  if (
    submissionMarkets.some(
      ({ marketOrganizationId }) => marketOrganizationId === unifiedEmail.recipientOrganization?.id,
    )
  ) {
    return EmailTemplateType.MarketEmailFollowup;
  }

  return undefined;
};
