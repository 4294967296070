import Banner from 'broker/components/Banner';
import { missingLabelAlertBannerConfig } from './config';
import { AlertBannerMode } from './types';

interface MissingLabelAlertBannerProps {
  mode: AlertBannerMode;
}

export default function MissingLabelAlertBanner({ mode }: MissingLabelAlertBannerProps) {
  const config = missingLabelAlertBannerConfig[mode];
  return <Banner title={config.title} subtitle={config.subtitle} mode={config.bannerMode} />;
}
