import { messages } from 'i18n';

export enum MarketingUpdateStatus {
  Bound = 'Bound',
  Quoted = 'Quoted',
  InReview = 'InReview',
  Declined = 'Declined',
}

export interface MarketingUpdateStatusConfigProps {
  text: string;
}

type MarketingUpdateStatusConfig = {
  [status in MarketingUpdateStatus]: MarketingUpdateStatusConfigProps;
};

export const marketingUpdateStatusConfig: MarketingUpdateStatusConfig = {
  [MarketingUpdateStatus.Bound]: {
    text: messages.marketingUpdate.status.bound,
  },
  [MarketingUpdateStatus.Quoted]: {
    text: messages.marketingUpdate.status.quoted,
  },
  [MarketingUpdateStatus.InReview]: {
    text: messages.marketingUpdate.status.inReview,
  },
  [MarketingUpdateStatus.Declined]: {
    text: messages.marketingUpdate.status.declined,
  },
};
