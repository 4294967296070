import { Box, Stack, Typography } from '@common-components';
import { Warning as WarningIcon } from '@icons';
import { messages } from 'i18n';
import { Quote } from 'types';
import { DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import { QuoteFileKey, quoteFileKeyConfig } from 'broker/components/common/QuotesAndPolicies/quotesAndPoliciesConfig';
import QuotesAndPoliciesItem from 'broker/components/common/QuotesAndPolicies/QuotesAndPoliciesItem';

export interface RemoveQuoteModalContentProps {
  ariaLabelId: string;
  setIsClose: () => void;
  quotes: Quote[];
  quoteType: QuoteFileKey;
  onProceed: (quote: Quote) => Promise<void>;
  isProceeding: boolean;
  title?: string;
  subtitle?: string;
  proceedText?: string;
  showWarningIcon?: boolean;
}

export default function ChooseQuoteModalContent({
  ariaLabelId,
  setIsClose,
  quotes,
  quoteType,
  title = messages.submissionWorkspace.dialogs.chooseQuote.title(quoteFileKeyConfig[quoteType].title),
  subtitle = messages.submissionWorkspace.dialogs.chooseQuote.subtitle(quoteFileKeyConfig[quoteType].title),
  proceedText = messages.submissionWorkspace.dialogs.chooseQuote.proceedText(quoteFileKeyConfig[quoteType].title),
  onProceed,
  isProceeding,
  showWarningIcon = false,
}: RemoveQuoteModalContentProps) {
  const onClickProceed = async () => {
    await onProceed(quotes[0]);
  };

  return (
    <>
      <DialogHeader title={title} id={ariaLabelId} onClose={setIsClose} />

      <Box sx={{ px: 3, pt: 2, pb: 4.8 }}>
        <Stack direction="row" gap={1}>
          {showWarningIcon && <WarningIcon fontSize="medium" color="warning" />}
          <Typography noWrap variant="h4">
            {subtitle}
          </Typography>
        </Stack>
      </Box>
      <DialogContent disablePaddings>
        <Box sx={{ flex: 1, bgcolor: 'grey.100', padding: 3 }}>
          <Stack gap={3}>
            {quotes.map((quote) => (
              <QuotesAndPoliciesItem key={quote.id} quote={quote} fileType={quoteType} />
            ))}
          </Stack>
        </Box>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: proceedText,
          onClick: onClickProceed,
          disabled: isProceeding,
          loading: isProceeding,
        }}
        cancelButton={{
          children: messages.compareQuotesModal.listSelect.cancelButton,
          onClick: setIsClose,
        }}
      />
    </>
  );
}
