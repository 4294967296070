import { Box, Stack, Typography } from '@common-components';
import noResultsImage from 'assets/images/submission-workspace/no-filtered-results.svg';
import { messages } from 'i18n';

export const FilteredMarketsEmptyState = ({ clearFilters }: { clearFilters?: () => void }) => (
  <Stack direction="column" alignItems="center" mb={7.5} gap={2}>
    <img
      width={170}
      height={170}
      src={`${noResultsImage}?w=170&h=170&fit=crop&auto=format`}
      srcSet={`${noResultsImage}?w=170&h=170&fit=crop&auto=format&dpr=2 2x`}
      alt="default-state"
      loading="lazy"
    />
    <Typography variant="h5" color="common.black" mb={2}>
      {messages.general.noResultsFound}
    </Typography>
    {clearFilters && (
      <Typography variant="body2" color="textSecondary">
        {messages.general.tryDifferentFilter}{' '}
        <Box component="span" sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={clearFilters}>
          {messages.general.resetFilters}
        </Box>
      </Typography>
    )}
  </Stack>
);
