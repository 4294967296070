import { useHotjar } from 'hooks';
import { messages } from 'i18n';
import { HotjarEvents } from 'utils/hotjar-events';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { SelectionActionButtonBaseProps } from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/types';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import { quoteComparisonLimit } from 'broker/pages/SubmissionWorkspacePage/utils';
import BulkActionButton from './BulkActionButton';

export function CompareQuotesActionButton(bulkActionButtonProps: SelectionActionButtonBaseProps) {
  const hotjar = useHotjar();
  const { selectedMarketRequests, comparisonExpiringPolicyFileId } = bulkActionButtonProps;
  const { quoteComparisonInsightsUrl } = useWorkspaceUrls();

  // Ensure that the 'Compare Quotes' button is only enabled when:
  // - 1 to 4 market requests are selected if an expiring policy exists, or
  // - 2 to 4 market requests are selected otherwise.
  // - All selected market requests must have an associated quote.
  const hasMissingQuote = selectedMarketRequests.some((request) => !request.quote);

  const validMarketCount = comparisonExpiringPolicyFileId
    ? selectedMarketRequests.length >= 1 && selectedMarketRequests.length <= quoteComparisonLimit
    : selectedMarketRequests.length >= 2 && selectedMarketRequests.length <= quoteComparisonLimit;

  const isButtonDisabled = hasMissingQuote || !validMarketCount;
  const disabledTooltipContent = messages.submissionWorkspace.marketRequestsWorkspace.compareQuotesDisabledText(
    comparisonExpiringPolicyFileId ? 1 : 2,
  );

  const navigate = useNavigate();
  const onClick = () => {
    hotjar.event(HotjarEvents.CompareQuotes);
    navigate(quoteComparisonInsightsUrl, {
      routeKey: RouteKey.QuoteComparisonInsights,
      state: {
        quotes: selectedMarketRequests.map((marketRequest) => marketRequest.quote!),
        expiringPolicyFileId: comparisonExpiringPolicyFileId,
      },
    });
  };

  return (
    <BulkActionButton
      disabled={isButtonDisabled}
      disabledTooltipContent={disabledTooltipContent}
      onClick={onClick}
      {...bulkActionButtonProps}
    >
      {messages.submissionWorkspace.marketRequestsWorkspace.compareQuotes}
    </BulkActionButton>
  );
}
