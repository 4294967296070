import { QueryClientProvider } from '@tanstack/react-query';
import { ReactNode, useContext } from 'react';
import { useInitializeReactQuery } from 'hooks/api/query/useInitializeReactQuery';
import { ErrorContext } from './error/error';

export const ReactQueryProvider = ({ children }: { children: ReactNode }) => {
  const { onError } = useContext(ErrorContext);

  const { queryClient } = useInitializeReactQuery((error, extraLogInfo) => {
    onError(error.message, undefined, { extraLogInfo });
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
