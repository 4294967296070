import { MENTION_PREFIX } from '@common/email-variables';
import { EditOutlined } from '@icons';
import { MenuGroup } from 'components/menu/types';
import { useNavigate } from 'broker/hooks';
import { smartObjectsConfig } from 'broker/utils/email-utils/SmartVariable/smart-objects-config';

interface ProposalSmartObjectMenuProps {
  proposalContent: string;
}

export default function useProposalSmartObjectMenu({ proposalContent }: ProposalSmartObjectMenuProps) {
  const navigate = useNavigate();

  const smartObjectKeys = Object.keys(smartObjectsConfig).filter(
    (smartObjectKey) => smartObjectsConfig[smartObjectKey].isSmartReport,
  );
  const inSubmissionSmartObjects = smartObjectKeys.filter((smartObjectKey) =>
    proposalContent.includes(`${MENTION_PREFIX}${smartObjectKey}`),
  );

  const inSubmissionMenuGroup: MenuGroup = {
    key: 'inSubmissionMenuGroup',
    items: inSubmissionSmartObjects.map((inSubmissionSmartObject) => {
      const smartObject = smartObjectsConfig[inSubmissionSmartObject];
      return {
        label: smartObject.label,
        onClick: () => {
          navigate(smartObject.navigationRoute, smartObject.navigationOptions());
        },
        endAdornment: EditOutlined,
      };
    }),
  };

  return { inSubmissionMenuGroup };
}
