import { Link, Stack, Typography } from '@common-components';
import { WarningAmber as WarningAmberIcon } from '@icons';
import APP_ROUTES from 'app-routes';
import { messages } from 'i18n';
import { logger } from 'utils';

export const GlobalErrorPage = ({ error }: { error: Error }) => {
  logger.log('error', error);

  return (
    <Stack justifyContent="center" alignItems="center" height="90vh" width="100vw">
      <WarningAmberIcon fontSize="large" color="disabled" />
      <Typography variant="body2Bold" color="blueGrey.400" mt={2}>
        {messages.errorPage.title}
      </Typography>
      <Typography variant="caption" color="blueGrey.400" maxWidth={265} textAlign="center">
        {messages.errorPage.description}
        <br />
        <Link href={APP_ROUTES.GENERAL.HOME} rel="noopener noreferrer">
          {messages.errorPage.refreshPage}
        </Link>
      </Typography>
    </Stack>
  );
};
