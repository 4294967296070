import { ReactNode } from 'react';
import { Box, ButtonProps, Divider, Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { boxBackground } from 'themes/colors';
import { DialogFooter } from 'components/Dialog';
import ContentPreview from 'broker/components/box/ContentPreview';
import { AIButtonWithPopup } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIButtonWithPopup';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

const addHiddenStyles = (isHidden: boolean) => ({
  visibility: isHidden ? 'hidden' : 'visible',
  height: isHidden ? 0 : 1,
  px: isHidden ? 0 : 1,
  py: isHidden ? 0 : 2,
});

interface ItemProps {
  header: string | ReactNode;
  fileIds: string[];
  details?: ReactNode;
  showDetails?: boolean;
}

interface SideBySideProps {
  item1: ItemProps;
  item2: ItemProps;
  cancelButton?: ButtonProps;
  proceedButton?: ButtonProps;
}

export default function SideBySide({ item1, item2, cancelButton, proceedButton }: SideBySideProps) {
  const items: ItemProps[] = [item1, item2];
  const { partialSubmission } = useSubmissionsWorkspace();
  const { boxFolderId } = partialSubmission!;

  const { comparisonPopupTitle } = messages.submissionWorkspace.quoteComparison;
  const { isLoading } = useAIContext();

  return (
    <Stack width={1} height={1}>
      <Stack direction="row" divider={<Divider flexItem orientation="vertical" />} boxShadow={3} zIndex={1}>
        {items.map((item) => (
          <Stack key={item.fileIds[0]} flex={1}>
            {typeof item.header === 'string' ? (
              <Typography variant="h5" color="common.black" sx={{ p: 3, pt: 5 }}>
                {item.header}
              </Typography>
            ) : (
              item.header
            )}
          </Stack>
        ))}
      </Stack>
      <AIButtonWithPopup
        popupTitle={comparisonPopupTitle}
        withFileLinks={false}
        tooltipText={
          isLoading
            ? messages.submissionWorkspace.aiGeneratedContentPopup.generatingComparison
            : messages.submissionWorkspace.aiGeneratedContentPopup.comparisonReady
        }
      />
      <Stack flex={1} direction="row" divider={<Divider flexItem orientation="vertical" />} overflow="hidden">
        {items.map((item) => (
          <Stack key={item.fileIds[0]} flex={1}>
            <Box
              bgcolor={boxBackground}
              sx={{ ...addHiddenStyles(!item.showDetails), bgcolor: 'common.white', overflow: 'hidden' }}
            >
              {item.details}
            </Box>
            <Box bgcolor={boxBackground} sx={{ ...addHiddenStyles(!!item.showDetails) }}>
              <ContentPreview
                filesMenuProps={{
                  collectionFileIds: item.fileIds,
                  boxFolderId,
                }}
                fileId={item.fileIds[0]}
                hasHeader={false}
                sx={{ height: 1 }}
              />
            </Box>
          </Stack>
        ))}
      </Stack>
      <DialogFooter proceedButton={proceedButton} cancelButton={cancelButton} />
    </Stack>
  );
}
