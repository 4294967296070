import { ContactClient } from 'clients';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
// eslint-disable-next-line import/no-internal-modules
import { useToast } from 'hooks/useToast';
import { messages } from 'i18n';
import { Contact, ContactCreate, ContactSearch } from 'types';
import { UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useBulkCreate } from './query/useBulkCreate';
import { useMutate } from './query/useMutate';
import { useImperativeQuery, useQueryRetrieve, useQuerySearch } from './query/useQuery';
import { ResponseAsError } from './types';

export function useSearchContact(props?: UseQueryBaseSearchOptions<Contact, ContactSearch>) {
  const { filter, queryOptions, enabled, sorter } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.Contacts,
    clientClass: ContactClient,
    filter,
    enabled,
    sorter,
    queryOptions: {
      // Default contact search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useRetrieveContact(props: UseQueryBaseRetrieveOptions<Contact>) {
  const { id, queryOptions, enabled } = props;
  return useQueryRetrieve({
    queryKey: QueryKeys.Contacts,
    clientClass: ContactClient,
    id,
    enabled,
    queryOptions: {
      // Default contact retrieve query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateContact() {
  const { showToast } = useToast();
  const { create: createContact, update: updateContact } = useMutate<Contact, Contact, ContactCreate, ContactClient>({
    queryKey: QueryKeys.Contacts,
    clientClass: ContactClient,
    createOptions: { throwOnError: true, passthroughStatusCodes: [409] },
    updateOptions: { throwOnError: true, passthroughStatusCodes: [409] },
    onSuccess: (response: Contact | null) => {
      const responseAsError = { ...response } as ResponseAsError;

      if (responseAsError.statusCode === 409) {
        // For a 409 error show a more meaningful message and throw so the usage can handle as any other error
        showToast('error', { message: messages.errors.duplicateContact });
        throw new Error(responseAsError.message);
      }
    },
  });
  return { createContact, updateContact };
}

export function useBulkCreateContacts() {
  const { bulkCreate: bulkCreateContacts } = useBulkCreate<Contact, Contact, ContactClient>({
    queryKey: QueryKeys.Contacts,
    clientClass: ContactClient,
    createOptions: { throwOnError: true },
  });

  return { bulkCreateContacts };
}

export function useValidateEmailAddresses() {
  const contactClient = useClient(ContactClient);

  const validateEmails = useImperativeQuery({
    queryFn: (emails: string[]) => {
      if (emails.length === 0) {
        return Promise.resolve([]);
      }

      return contactClient.validateEmails(emails).then((response) => response?.validationResults ?? []);
    },
    queryKey: QueryKeys.Contacts,
  });

  return { validateEmails };
}
