import { Chip, IconButton, Stack, SxProps, Theme } from '@common-components';
import { Edit } from '@icons';
import { QuoteExits } from 'clients/types';
import { CoverageLine } from 'enums';
import { InsuranceProduct } from 'types';
import { coverageLineConfig } from 'utils';
import { SubmissionStep } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/types';
import { SetNextStepsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/useSetNextStep';
import ApiProductsDropDown from './ApiProductsDropDown';

interface CoverageLinesChipsProps {
  coverageLines: CoverageLine[];
  setNextStep: (props?: SetNextStepsProps) => void;
  insuranceProducts?: InsuranceProduct[];
  heraldProducts?: string[];
  quoteExits?: QuoteExits[];
  sx?: SxProps<Theme>;
}

export default function CoverageLinesChips({
  coverageLines,
  setNextStep,
  sx,
  quoteExits,
  heraldProducts,
  insuranceProducts,
}: CoverageLinesChipsProps) {
  return (
    <Stack direction="row" gap={1} alignItems="center" pb={0} sx={sx} justifyContent="space-between">
      <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
        {coverageLines.map((coverageLine) => (
          <Chip
            key={coverageLine}
            size="medium"
            color="secondary"
            label={coverageLineConfig[coverageLine as CoverageLine].text}
            rounded
          />
        ))}
        <IconButton
          icon={Edit}
          variant="text"
          color="secondary"
          onClick={() => setNextStep({ forceStep: SubmissionStep.PreForm })}
        />
      </Stack>
      {heraldProducts && insuranceProducts && (
        <ApiProductsDropDown
          menuPosition="right"
          products={insuranceProducts.filter(
            (insuranceProduct) =>
              insuranceProduct.externalProductId && heraldProducts.includes(insuranceProduct.externalProductId),
          )}
          quoteExits={quoteExits}
        />
      )}
    </Stack>
  );
}
