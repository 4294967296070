import { FormFieldsConfigProps } from 'hooks';

export interface ReviewAgentFormData {
  firstName: string;
  lastName: string;
  email: string;
  title?: string | null;
}

export enum ReviewAgentFormFieldsNames {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Title = 'title',
}

export type FormFieldsConfig = Record<ReviewAgentFormFieldsNames, FormFieldsConfigProps<ReviewAgentFormFieldsNames>>;
