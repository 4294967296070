export const FieldStyle = { py: 0.5, pr: 1, pl: 2 };

export const DoubleFieldStyle = { py: 0.5, px: 2 };

export const BodyFieldStyle = {
  width: 1,
  height: 1,
  // Wrapper
  '.tox-tinymce': {
    backgroundColor: 'transparent !important',
  },
  // Toolbar
  '.tox .tox-toolbar-overlord': {
    backgroundColor: 'transparent',
  },
  '.tox:not(.tox-tinymce-inline) .tox-editor-header': {
    backgroundColor: 'transparent',
  },
  '.tox .tox-toolbar__primary': {
    backgroundColor: 'transparent',
  },
  // Edit Area
  '.tox .tox-edit-area__iframe': {
    backgroundColor: 'transparent',
  },
};
