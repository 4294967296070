import { DependencyList, EffectCallback, useEffect } from 'react';
import { useDeepCompareMemoize } from './utils';

/* An alternative for useEffect hook that does a deep comparison on the dependency array
 * instead of the default reference comparison.
 *
 * IMPORTANT - since we are using deep comparison, this can be an expensive operation for complex objects / arrays
 * use it only for simple dependencies such as array of ids
 */

export function useDeepCompareEffect(callback: EffectCallback, dependencies: DependencyList) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
