import { JSX, MouseEvent, useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { Box, Button, Checkbox, Stack } from '@common-components';
import { BoxTemplateLabels, FileType } from 'enums';
import { useCurrentUser, useToast } from 'hooks';
import { messages } from 'i18n';
import { SyncedSystemType } from 'types';
import { DOT_SEPARATOR, extractErrorMessage, getFileSyncStatus, getFileType, syncBadgeTooltip } from 'utils';
import { ConfirmationDialog } from 'components/Dialog';
import FormTextField from 'components/hookFormComponents/FormTextField';
import IconBadge from 'components/IconBadge';
import ListItemDescription from 'components/ListItemDescription';
import FileTypeIcon from 'broker/components/common/FileTypeIcon';
import { FileActionType, FilesDisplay } from 'broker/components/FilesExplorer/hooks/useFilesDisplay';
import { ExtendedBoxItem, GetFileSecondaryActions, LabelMetadata } from 'broker/components/FilesExplorer/types';
import {
  fileLastModified,
  fileModifiedBy,
  FileRenameFormData,
  FileRenameFormFieldsNames,
  fileSyncConfig,
} from 'broker/components/FilesExplorer/utils';
import FileCardMenuActions from './FileCardMenuActions';
import FileLabelsMenu from './FileLabelsMenu';
import { fileCardStyle } from './style';
import useFileCard from './useFileCard';

interface FileCardProps {
  file: ExtendedBoxItem;
  searchInput: string;
  onPreviewClicked: (item: ExtendedBoxItem) => void;
  onRenameSubmit: (item: ExtendedBoxItem, newName: string) => Promise<void>;
  onDownloadClicked: (item: ExtendedBoxItem) => void;
  onDeleteClicked: (item: ExtendedBoxItem) => void;
  onLabelUpdate: (item: ExtendedBoxItem, labelFieldName: BoxTemplateLabels, fileType?: string) => Promise<void>;
  showLabelSelector?: boolean;
  selectionMode?: boolean;
  onSelected?: (item: ExtendedBoxItem) => void;
  isSelected?: boolean;
  filesDisplay: FilesDisplay;
  getFileSecondaryActions?: GetFileSecondaryActions;
  getPrimaryActionComponent?: (file: ExtendedBoxItem) => JSX.Element;
  highlightFileTypes?: FileType[];
  viewOnlyMode?: boolean;
  labelsMetadata?: LabelMetadata[];
}

export default function FileCard({
  file,
  searchInput,
  onPreviewClicked,
  onRenameSubmit,
  onDownloadClicked,
  onDeleteClicked,
  onLabelUpdate,
  selectionMode,
  onSelected,
  isSelected = false,
  filesDisplay,
  getFileSecondaryActions,
  getPrimaryActionComponent,
  highlightFileTypes = [],
  viewOnlyMode,
  labelsMetadata,
}: FileCardProps) {
  const { endUser } = useCurrentUser();
  const { showToast } = useToast();

  // enable opening the labels menu programmatically and not only through user click on anchor
  const [isLabelsMenuOpen, setIsLabelsMenuOpen] = useState(false);

  const fileDisplayState = filesDisplay.filesActions[file.id];
  const { rename, deleteConfirmation, hideAllActions } = useFileCard(file, filesDisplay);

  const onRename = () => {
    rename.show();
  };

  const onLabelClicked = () => {
    setIsLabelsMenuOpen(true);
  };

  const elementId = `file-card-${file.id}`;

  const onRenameSave: SubmitHandler<FileRenameFormData> = async (data) => {
    try {
      const fileExtension = file.name.split('.').pop();
      filesDisplay.submitInlineAction(file.id);
      await onRenameSubmit(file, `${data.name}.${fileExtension}`);
    } catch (e) {
      showToast('error', { message: extractErrorMessage(e) });
    } finally {
      rename.hide();
    }
  };

  const onDeleteConfirmationSubmit = async () => {
    deleteConfirmation.startSubmitting();
    try {
      await onDeleteClicked(file);
    } catch (e) {
      showToast('error', { message: extractErrorMessage(e) });
    } finally {
      deleteConfirmation.stopSubmitting();
      deleteConfirmation.close();
    }
  };

  const handleClickOnCard = (e: MouseEvent) => {
    // only trigger a preview if the "whitespace" of the card was clicked
    if (e.target === e.currentTarget) {
      onPreviewClicked(file);
    }
  };

  const syncedSystem = endUser?.organization.syncedSystems?.[SyncedSystemType.FilesStorage];

  const shouldHighlight = getFileType(file) && highlightFileTypes?.includes(getFileType(file)!);

  return (
    <>
      <Stack
        id={elementId}
        className={`${fileDisplayState ? 'active' : ''} ${isSelected ? 'selected' : ''}`}
        p={2}
        bgcolor={shouldHighlight ? 'highlight' : undefined}
        borderBottom={fileDisplayState?.actionType === FileActionType.EditLabel ? 1 : 0}
        borderColor="divider"
        alignContent="center"
        sx={{
          ...fileCardStyle,
          ':last-of-type': {
            border: 'none',
          },
          cursor: 'pointer',
        }}
        onClick={handleClickOnCard}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          {selectionMode && (
            <Box width={16}>
              <Checkbox
                size="small"
                disabled={viewOnlyMode}
                disableRipple
                edge="start"
                checked={isSelected}
                onChange={() => onSelected?.(file)}
              />
            </Box>
          )}
          <Box position="relative" sx={{ cursor: 'pointer' }} onClick={() => onPreviewClicked(file)}>
            {getFileSyncStatus(file) && (
              <Box position="absolute" bottom={0} right={0}>
                <IconBadge
                  {...fileSyncConfig[getFileSyncStatus(file)!]}
                  tooltipContent={syncBadgeTooltip(file, syncedSystem)}
                  size="extraSmall"
                />
              </Box>
            )}
            <FileTypeIcon fileNameOrExtension={file.name} />
          </Box>
          <Stack gap={1} flexGrow={1} overflow="hidden" onClick={handleClickOnCard} alignItems="flex-start">
            <Stack gap={0.5} direction="row" maxWidth={1}>
              {labelsMetadata?.map((labelMetadata, index) => (
                <FileLabelsMenu
                  key={labelMetadata.metadataFieldName}
                  onLabelUpdate={onLabelUpdate}
                  file={file}
                  readonly={viewOnlyMode}
                  isOpenOverride={index === 0 ? isLabelsMenuOpen : false}
                  onClose={index === 0 ? () => setIsLabelsMenuOpen(false) : undefined}
                  onLabelChanged={index === 0 ? () => setIsLabelsMenuOpen(false) : undefined}
                  onClearLabel={index === 0 ? () => setIsLabelsMenuOpen(false) : undefined}
                  labelMetadata={labelMetadata}
                  addLabelText={labelMetadata.addLabelText}
                  icon={labelMetadata.icon}
                  showTooltip
                />
              ))}
            </Stack>
            <Stack sx={{ cursor: 'pointer' }} onClick={() => onPreviewClicked(file)}>
              <ListItemDescription
                title={file.name}
                titleTooltip={file.name}
                subtitle={fileModifiedBy(file)}
                subtitlePrefix={`${fileLastModified(file)}${DOT_SEPARATOR}`}
                searchTerm={searchInput}
              />
            </Stack>
          </Stack>
          {!fileDisplayState && !viewOnlyMode && (
            <Stack direction="row" justifyContent="center" alignItems="center" gap={0.5}>
              {getPrimaryActionComponent?.(file)}
              <FileCardMenuActions
                file={file}
                onDownloadClicked={onDownloadClicked}
                getFileSecondaryActions={getFileSecondaryActions}
                onRename={onRename}
                onDeleteClicked={deleteConfirmation.open}
                onLabelClicked={onLabelClicked}
                fileType={getFileType(file)}
              />
            </Stack>
          )}
          {fileDisplayState && (
            <Stack gap={1} direction="row" alignSelf="flex-start" justifyContent="center">
              <Button size="small" variant="outlined" onClick={hideAllActions}>
                {messages.fileExplorer.cancel}
              </Button>

              {fileDisplayState?.actionType === FileActionType.Rename && (
                <Button
                  size="small"
                  variant="contained"
                  disabled={!rename.methods.formState.isValid}
                  onClick={rename.methods.handleSubmit(onRenameSave)}
                  loading={fileDisplayState?.isLoading}
                >
                  {messages.fileExplorer.save}
                </Button>
              )}
            </Stack>
          )}
        </Stack>

        {fileDisplayState?.actionType === FileActionType.Rename && (
          <FormProvider {...rename.methods}>
            <FormTextField
              placeholder={messages.fileExplorer.formFields.name.placeholder}
              name={FileRenameFormFieldsNames.Name}
              id={FileRenameFormFieldsNames.Name}
              autoFocus
              fullWidth
            />
          </FormProvider>
        )}
      </Stack>

      {deleteConfirmation.shouldRender && (
        <ConfirmationDialog
          message={messages.deleteFileConfirmation.description(file.name)}
          title={messages.deleteFileConfirmation.title}
          isOpen={deleteConfirmation.isOpen}
          onSubmit={onDeleteConfirmationSubmit}
          onCancel={deleteConfirmation.close}
          isSubmitting={deleteConfirmation.isSubmitting}
        />
      )}
    </>
  );
}
