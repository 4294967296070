import { Button, Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import PageHeader from 'broker/components/common/layout/PageHeader';

export interface SubmissionsViewHeaderProps {
  count: number;
  filteredCount: number;
  isFilterApplied: boolean;
  onCreateAgency: () => void;
}

export default function AgenciesHeader({
  count,
  filteredCount,
  isFilterApplied,
  onCreateAgency,
}: SubmissionsViewHeaderProps) {
  return (
    <PageHeader>
      <Stack direction="row" alignItems="baseline" gap={1}>
        <Typography variant="h4">{messages.agenciesPage.header.title}</Typography>
        <Typography color="text.secondary" variant="body2">
          {isFilterApplied ? messages.general.outOfAgencies(filteredCount.toString(), count.toString()) : count}
        </Typography>
      </Stack>
      <Button variant="contained" onClick={() => onCreateAgency()}>
        {messages.agenciesPage.createAgency}
      </Button>
    </PageHeader>
  );
}
