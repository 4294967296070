import { useConfig } from 'config';

export type EnvironmentName = 'local' | 'dev' | 'prod';

const environmentMapping: Record<string, EnvironmentName> = {
  Local: 'local',
  Dev: 'dev',
  Prod: 'prod',
};

export const useEnvironment = (): EnvironmentName => {
  const { envName } = useConfig().backend;
  if (!envName) {
    throw new Error('REACT_APP_ENV_NAME environment variable must be defined');
  }
  return environmentMapping[envName];
};
