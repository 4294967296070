import { EmailType } from 'broker/components/EmailEditor/store/types';
import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import useGetContacts from 'broker/components/EmailEditor/useGetContacts';
import CreateCustomerContactModal from 'broker/dialogs/CreateCustomerContactModal';

export default function EmailEditorCreateCustomerContactModal() {
  const {
    emailType,
    recipients: { preSelectedRecipientGroups },
    submissionsWorkspace: { submission },
  } = useEmailEditorState();

  const { contacts, isContactsLoading } = useGetContacts();
  const { customerContactCreated } = useEmailEditorActions();

  return emailType === EmailType.Retailer ? (
    <CreateCustomerContactModal
      // TODO: encapsulate the logic of getting the first recipient group for the retailer
      contacts={contacts.filter((contact) =>
        preSelectedRecipientGroups![0].recipients.find((recipient) => recipient.id === contact.id),
      )}
      contactsIsLoading={isContactsLoading}
      onContactCreated={customerContactCreated}
      insuredName={submission?.insuredName}
    />
  ) : null;
}
