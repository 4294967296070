import * as commonTypes from '@common/types';

export interface Recipient extends commonTypes.Recipient {
  // represents the id of contact/user entity (currently if the recipient is in a market recipient group we know it is the id of a contact entity, and if the recipient is in a retailer recipient group we know it is the id of a user entity)
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  isSubmissionBox: boolean;
  disabled?: boolean;
}

export enum EmailItemType {
  SubmissionOutbound = 'SubmissionOutbound',
  SubmissionInbound = 'SubmissionInbound',
  UnassignedInbound = 'UnassignedInbound',
  SubmissionInboundFromUser = 'SubmissionInboundFromUser',
}

export const isOutboundType = (type: EmailItemType) =>
  [EmailItemType.SubmissionOutbound, EmailItemType.SubmissionInboundFromUser].includes(type);
