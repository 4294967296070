import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Button, Divider, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@common-components';
import {
  ChatOutlined as ChatIcon,
  LabelOutlined as LabelIcon,
  SpeedOutlined as SpeedIcon,
  WorkOutline as WorkIcon,
} from '@icons';
import { ContactRole, OrganizationType } from 'enums';
import { useFormProvider } from 'hooks';
import { messages } from 'i18n';
import { FormFieldsSpacingHorizontal, FormFieldsSpacingVertical } from 'themes';
import { UserMarket } from 'types';
import { scrollToElement } from 'utils';
import { DialogContent, DialogFooter } from 'components/Dialog';
import FormArrayFields from 'components/hookFormComponents/FormArrayFields';
import { InnerValueSetter } from 'components/hookFormComponents/FormAutocomplete/FormAutocompleteCreatable';
import { FormEditorWithPreviewField } from 'components/hookFormComponents/FormEditorWithPreviewField';
import { FormMultiSelectAutocomplete } from 'components/hookFormComponents/FormMultiSelectAutocomplete';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import useUserMarketCategories from 'broker/hooks/useUserMarketCategories';
import ContactFormFields from './ContactFormFields';
import FormFieldTitle from './FormFieldTitle';
import { ContactFormProps, UserMarketFormProps } from './types';
import { useSubmitUserMarket } from './useSubmitUserMarket';
import { formFieldsConfig, FormFieldsNames, FormMode, schema } from './utils';

const focusOnRef = (ref: MutableRefObject<HTMLInputElement | null>) => {
  setTimeout(() => {
    ref.current?.focus();
  }, 200);
};

interface FormContentProps
  extends Pick<BaseRoutedDialogContentProps, 'onClose' | 'closeWithoutPrompt' | 'setDialogIsDirty'> {
  defaultValues: Partial<UserMarketFormProps>;
  mode: FormMode;
  id: string | null;
  scrollTo?: FormFieldsNames;
  defaultMarketName?: string;
  onMarketCreated?: (market: UserMarket) => void;
}

export default function FormContent({
  setDialogIsDirty,
  onClose,
  closeWithoutPrompt,
  defaultValues,
  mode,
  id,
  scrollTo,
  onMarketCreated,
  defaultMarketName,
}: FormContentProps) {
  const [newMarketOrganizationType, setMarketOrganizationType] = useState<OrganizationType>(
    defaultValues.marketOrganizationType ?? OrganizationType.Carrier,
  );
  const getContactRoleByMarketOrganizationType = () =>
    newMarketOrganizationType === OrganizationType.Carrier ? ContactRole.Underwriter : ContactRole.Broker;

  const { methods } = useFormProvider({ defaultValues, schema, setIsDirty: setDialogIsDirty });
  const marketIdRef = useRef<InnerValueSetter>(null);

  const categoriesRef = useRef<HTMLInputElement | null>(null);

  const contactGridId = formFieldsConfig[FormFieldsNames.Contacts].id;

  const { existingCategories } = useUserMarketCategories();

  const { submitUserMarket, isSubmitting } = useSubmitUserMarket({
    id,
    mode,
    newMarketOrganizationType,
    methods,
    defaultValues,
    onMarketCreated,
    closeWithoutPrompt,
  });

  const defaultContactValue: ContactFormProps = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    title: '',
    role: getContactRoleByMarketOrganizationType(),
    organizationType: newMarketOrganizationType,
  };

  useEffect(() => {
    if (scrollTo) {
      setTimeout(() => {
        scrollToElement(scrollTo, { block: 'center' });
      }, 100);
      if (scrollTo === FormFieldsNames.Categories) {
        focusOnRef(categoriesRef);
      }
    }
  }, [scrollTo]);

  function resetForm() {
    methods.reset();
    marketIdRef.current?.setInnerValue('');
  }
  function handleOrgTypeChange(value: OrganizationType) {
    if (value !== null) {
      setMarketOrganizationType(value);
      resetForm();
      defaultContactValue.role = getContactRoleByMarketOrganizationType();
    }
  }

  return (
    <FormProvider {...methods}>
      <DialogContent>
        <Grid columnSpacing={FormFieldsSpacingVertical} rowSpacing={FormFieldsSpacingHorizontal} container>
          <Grid item xs={12}>
            <Typography>{messages.userMarketModal.marketInfoSection}</Typography>
            <Divider sx={{ my: 2 }} />
          </Grid>

          <Grid item xs={12}>
            <ToggleButtonGroup
              value={newMarketOrganizationType}
              exclusive
              onChange={(_, value) => handleOrgTypeChange(value)}
              aria-label="market type toggle"
              size="small"
              color="primary"
              disabled={mode === FormMode.Update}
              sx={{ bgcolor: 'common.white', pl: 5, pb: 3 }}
            >
              <ToggleButton value={OrganizationType.Carrier} aria-label="carrier mode">
                {messages.marketsPage.marketType.directMarket}
              </ToggleButton>
              <ToggleButton value={OrganizationType.Broker} aria-label="broker mode">
                {messages.marketsPage.marketType.wholesaler}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" gap={2}>
              <WorkIcon sx={{ color: 'grey.500' }} />
              <FormTextField
                defaultValue={defaultMarketName}
                {...formFieldsConfig[FormFieldsNames.MarketName]}
                fullWidth
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" gap={2}>
              <LabelIcon sx={{ color: 'grey.500' }} />
              <Stack flex={1}>
                <FormFieldTitle title={messages.userMarketModal.categorySection} />
                <FormMultiSelectAutocomplete
                  inputRef={categoriesRef}
                  options={existingCategories.map((category) => ({ label: category, value: category }))}
                  hideOptionalLabel
                  freeSolo
                  defaultValue={defaultValues?.categories ?? []}
                  {...formFieldsConfig[FormFieldsNames.Categories]}
                  createOption={(value) => ({ label: `${messages.buttons.add}: ${value}`, value })}
                />
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" gap={2}>
              <SpeedIcon sx={{ color: 'grey.500' }} />
              <Stack flex={1} gap={1}>
                <FormFieldTitle title={messages.userMarketModal.formFields.label.riskAppetite} />
                <FormEditorWithPreviewField
                  initialValue={defaultValues?.riskAppetite ?? ''}
                  formFieldsConfig={formFieldsConfig[FormFieldsNames.RiskAppetite]}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" gap={2}>
              <ChatIcon sx={{ color: 'grey.500' }} />
              <Stack flex={1} gap={1}>
                <FormFieldTitle title={messages.userMarketModal.formFields.label.memo} />
                <FormEditorWithPreviewField
                  initialValue={defaultValues?.memo ?? ''}
                  formFieldsConfig={formFieldsConfig[FormFieldsNames.Memo]}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} id={contactGridId}>
            <FormArrayFields
              customHeader={({ fields }, onAddAnotherClick) => (
                <Stack
                  direction="row"
                  pt={2}
                  gap={2}
                  pb={1}
                  position="sticky"
                  top={(theme) => theme.spacing(-4)}
                  bgcolor="common.white"
                  zIndex={(theme) => theme.zIndex.drawer}
                >
                  <Stack flex={1}>
                    <Stack direction="row">
                      <Typography flex={1} variant="body1">
                        {formFieldsConfig[FormFieldsNames.Contacts].label}
                      </Typography>
                      <Button variant="outlined" size="small" onClick={onAddAnotherClick}>
                        {fields.length === 0 ? messages.buttons.addFirst('') : messages.buttons.addAnother('')}
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              )}
              defaultValue={defaultContactValue}
              FormArrayFieldComponent={ContactFormFields}
              {...formFieldsConfig[FormFieldsNames.Contacts]}
              customButtonStyles={{ ml: 5 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: messages.userMarketModal.submit[mode],
          onClick: methods.handleSubmit(submitUserMarket),
          loading: isSubmitting,
        }}
        cancelButton={{
          children: messages.addContactToMarketModal.cancel,
          onClick: onClose,
        }}
      />
    </FormProvider>
  );
}
