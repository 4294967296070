export const MENTION_PREFIX = '@';

export enum EmailTemplateVariables {
    insuredName = 'INSURED-NAME',
    insuredAddress = 'INSURED-ADDRESS',
    coverageLines = 'COVERAGE-LINES',
    effectiveDate = 'EFFECTIVE-DATE',
    needByDate = 'NEED-BY-DATE',
    targetLimit = 'TARGET-LIMIT',
    attachmentPoint = 'ATTACHMENT-POINT',
    triggeringMarket = 'TRIGGERING-MARKET',
    marketRecipient = 'MARKET-NAME',
    marketRecipientFirstNames = 'UNDERWRITERS-FIRST-NAMES',
    informationRequested = 'INFORMATION-REQUESTED',
    declinationReason = 'DECLINATION-REASON',
    brokerageName = 'BROKERAGE-NAME',
    customerFirstNames = 'CUSTOMER-FIRST-NAMES',
    signature = 'SIGNATURE',
    conceptualTower = 'CONCEPTUAL-TOWER',
    marketingReport = 'MARKETING-REPORT',
    insuredEmployeeCount = 'INSURED-EMPLOYEE-COUNT',
    insuredWebsite = 'INSURED-WEBSITE',
    emailAttachments = 'EMAIL-ATTACHMENTS',
    userName = 'USER-NAME',
    marketingStepLink = 'MARKETING-STEP-LINK',
    companyLetterhead = 'COMPANY-LETTERHEAD',
    missingFiles = 'MISSING-FILES',
    missingSubmissionDetails = 'MISSING-SUBMISSION-DETAILS',
    submissionExternalLinkButton = 'SUBMISSION-EXTERNAL-LINK-BUTTON',
    submissionInternalLink = 'SUBMISSION-INTERNAL-LINK',
    quote = 'QUOTE',
    quotePageLink = 'QUOTE-PAGE-LINK',
    quoteMarketName = 'QUOTE-MARKET-NAME',
    quoteAggregateLimit = 'QUOTE-AGGREGATE-LIMIT',
    quoteCoverageLines = 'QUOTE-COVERAGE-LINES',
    quoteDate = 'QUOTE-DATE',
    assigneeName = 'ASSIGNEE-NAME',
    customerOrganization = 'CUSTOMER-ORGANIZATION',
    customerFullName = 'CUSTOMER-FULL-NAME',
    submissionBoxEmailSubject = 'SUBMISSION-BOX-EMAIL-SUBJECT',
    policyNumber = 'POLICY-NUMBER',
    policyEffectiveDate = 'POLICY-EFFECTIVE-DATE',
    numberOfMarkets = 'NUMBER-OF-MARKETS',
    numberOfDeclinations = 'NUMBER-OF-DECLINATIONS',
    numberOfQuotes = 'NUMBER-OF-QUOTES',
    subjectivities = 'SUBJECTIVITIES',
}

// Basic output for variable resolver. Usages can expand this interface to add more fields needed for resolving logic.
export interface VariableInfo {
    value: string | undefined;
}

export type TemplateVariablesResolver<Input, Output extends VariableInfo = VariableInfo> = Record<
    EmailTemplateVariables,
    (data: Input) => Output
>;
