import { EmailTemplateType, UserRole } from 'enums';
import { useCurrentUser } from 'hooks';
import { PartialSubmission } from 'types';
import { EmailTemplateConfig } from 'broker/configuration-mappers/email-template-config';
import { useIsIntegrationEnabled } from './useIsIntegrationEnabled';

export interface useEmailIntegrationBannerProps {
  emailTemplateType?: EmailTemplateType;
  submission?: PartialSubmission | null;
}
export const useEmailIntegrationBanner = ({ emailTemplateType, submission }: useEmailIntegrationBannerProps) => {
  const { me } = useCurrentUser();
  const isIntegrationEnabled = useIsIntegrationEnabled();
  const isCopilotEnabled = submission?.isCopilotEnabled;
  const isBackOfficeTemplate = emailTemplateType && EmailTemplateConfig[emailTemplateType].backOfficeTemplate;

  function shouldShowBanner() {
    if (!isIntegrationEnabled) {
      return false;
    }

    if (me?.role !== UserRole.BackOffice) {
      return true;
    }

    return !!(me?.role === UserRole.BackOffice && !isBackOfficeTemplate && isCopilotEnabled);
  }

  const shouldShowIntegrationBanner = shouldShowBanner();

  return { shouldShowIntegrationBanner };
};
