import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export function useNavigateToMarketingUpdate() {
  const navigate = useNavigate();
  const { partialSubmission } = useSubmissionsWorkspace();
  const { marketingUpdateUrl, marketingUpdateEmailUrl } = useWorkspaceUrls();

  const navigateToMarketingUpdate = () => {
    if (partialSubmission?.draftMarketingUpdate?.pdfFileId) {
      navigate(marketingUpdateEmailUrl, {
        routeKey: RouteKey.MarketingUpdateEmail,
        state: {
          pdfFileId: partialSubmission?.draftMarketingUpdate?.pdfFileId,
        },
      });
    } else {
      navigate(marketingUpdateUrl(), {
        routeKey: RouteKey.MarketingUpdate,
        state: {},
      });
    }
  };

  return { navigateToMarketingUpdate };
}
