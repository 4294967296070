import { keyBy } from 'lodash';
import { EmailTemplateType } from 'enums';
import { EmailTemplate, SubmissionMarket } from 'types';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';

type TemplateSelectorContext = {
  submissionMarkets: SubmissionMarket[];
};

type TemplateSelector = (
  recipientGroup: RecipientGroup,
  templates: EmailTemplate[],
  context: TemplateSelectorContext,
) => EmailTemplate;

const defaultTemplateSelector: TemplateSelector = (_recipientGroup: RecipientGroup, templates: EmailTemplate[]) =>
  templates[0];

const marketingTemplateSelector: TemplateSelector = (
  recipientGroup: RecipientGroup,
  templates: EmailTemplate[],
  { submissionMarkets }: TemplateSelectorContext,
) => {
  const marketIdToSubmissionMarket = keyBy(submissionMarkets, (market) => market.userMarketId);

  const isRecipientGroupIncumbent = marketIdToSubmissionMarket[recipientGroup.id]?.incumbentInfo?.length > 0;
  const chosenTemplate = templates.find((template) => !!template.metadata?.isIncumbent === isRecipientGroupIncumbent);

  return chosenTemplate ?? templates[0];
};

const templateTypeToTemplateSelector: Partial<Record<EmailTemplateType, TemplateSelector>> = {
  [EmailTemplateType.RfqGeneral]: marketingTemplateSelector,
  [EmailTemplateType.RfqAttachmentPoint]: marketingTemplateSelector,
  [EmailTemplateType.PrimaryMarketing]: marketingTemplateSelector,
  [EmailTemplateType.ExcessMarketing]: marketingTemplateSelector,
};

const getTemplateSelector = (templateType: EmailTemplateType): TemplateSelector =>
  templateTypeToTemplateSelector[templateType] || defaultTemplateSelector;

export const getTemplate = (
  templateType: EmailTemplateType,
  recipientGroup: RecipientGroup,
  templates: EmailTemplate[],
  context: TemplateSelectorContext,
) => {
  const templateSelector = getTemplateSelector(templateType);
  return templateSelector(recipientGroup, templates, context);
};
