import { MouseEvent } from 'react';
import { IconButton, NewMenu, Stack } from '@common-components';
import { Add as AddIcon, Delete as DeleteIcon, EditOutlined as EditOutlinedIcon, MoreVert } from '@icons';
import { OrganizationType } from 'enums';
import { useBoolean, useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { ExtendedUserMarket } from 'types';
import { MarketOnClick } from 'broker/components/UserMarkets/types';
import { AddContactToMarketMetadata } from 'broker/dialogs/AddContactToMarketModal/types';
import DeleteMarketDialog from './DeleteMarketDialog';
import { columnConfig, UserMarketColumns } from './MarketsTableHeader';

interface MarketsTableItemMenuProps {
  extendedMarket: ExtendedUserMarket;
  onAddContact: (metaData: AddContactToMarketMetadata) => void;
  onEdit: MarketOnClick;
}

export default function MarketsTableItemMenu({ extendedMarket, onAddContact, onEdit }: MarketsTableItemMenuProps) {
  const moreOptionsMenu = useOptionsMenu();
  const [isDeleteDialogOpen, { on: openDeleteDialog, off: closeDeleteDialog }] = useBoolean(false);

  return (
    <>
      <Stack
        flex={columnConfig[UserMarketColumns.MoreActions].flex}
        alignItems={columnConfig[UserMarketColumns.MoreActions].align}
      >
        <IconButton
          variant="text"
          color="secondary"
          icon={MoreVert}
          onClick={(e?: MouseEvent<HTMLButtonElement>) => {
            moreOptionsMenu.openMenu(e);
          }}
        />
        <NewMenu
          optionsMenuState={moreOptionsMenu}
          placement="bottom-right"
          menuItems={[
            {
              key: 'more-actions',
              items: [
                {
                  label: messages.marketsPage.edit,
                  startAdornment: EditOutlinedIcon,
                  onClick: () => {
                    onEdit({ id: extendedMarket.id });
                  },
                },
                {
                  label:
                    extendedMarket.organizationType === OrganizationType.Carrier
                      ? messages.marketsPage.addUnderWriter
                      : messages.marketsPage.addWholesaler,
                  startAdornment: AddIcon,
                  onClick: () => {
                    onAddContact({ userMarket: extendedMarket });
                  },
                },
                {
                  label: messages.marketsPage.addSubmissionBox,
                  startAdornment: AddIcon,
                  onClick: () => {
                    onAddContact({ userMarket: extendedMarket, defaultSubmissionBox: true });
                  },
                },
                {
                  label: messages.marketsPage.deleteMarket,
                  startAdornment: DeleteIcon,
                  onClick: openDeleteDialog,
                },
              ],
            },
          ]}
        />
      </Stack>
      <DeleteMarketDialog userMarket={extendedMarket} isOpen={isDeleteDialogOpen} onClose={closeDeleteDialog} />
    </>
  );
}
