import { MouseEvent, ReactNode } from 'react';
import { Stack, StackProps } from '@common-components';
import { submissionsViewColumnsConfig, SubmissionsViewColumnsValues } from './config';
import { SubmissionViewMode } from './utils';

interface SubmissionTableColumnProps extends Omit<StackProps, 'flex' | 'display'> {
  children: ReactNode;
  column: SubmissionsViewColumnsValues;
  viewMode: SubmissionViewMode;
  stopPropagation?: boolean;
}
export default function SubmissionTableColumn({
  children,
  column,
  viewMode,
  stopPropagation = false,
  ...rest
}: SubmissionTableColumnProps) {
  const submissionConfig = submissionsViewColumnsConfig(viewMode);

  return submissionConfig[column].showForModes.includes(viewMode) ? (
    <Stack
      flex={submissionConfig[column].flex}
      {...rest}
      onClick={
        stopPropagation
          ? (e: MouseEvent) => {
              e.stopPropagation();
            }
          : undefined
      }
    >
      {children}
    </Stack>
  ) : null;
}
