import { HTMLAttributes } from 'react';
import { Stack, Typography } from '@common-components';
import { AutocompleteOption } from './utils';

interface FormRenderOptionProps<T> {
  key: string;
  htmlProps: HTMLAttributes<HTMLElement>;
  option: T;
}
export default function FormRenderOption<T extends AutocompleteOption>({
  htmlProps,
  option,
}: FormRenderOptionProps<T>) {
  return (
    <Stack
      {...htmlProps}
      style={{ padding: '8px 16px', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row' }}
    >
      <Typography variant="body2" mr={option.subLabel ? 1 : 0}>
        {option.label}
      </Typography>
      {option.subLabel && (
        <Typography variant="body2" color="text.secondary" noWrap>
          {option.subLabel}
        </Typography>
      )}
    </Stack>
  );
}
