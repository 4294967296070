import { sortBy } from 'lodash';
import { Button, Divider, IconButton, SearchBar, Stack } from '@common-components';
import { Close as CloseIcon } from '@icons';
import { messages } from 'i18n';
import Filter, { FilterButtonTypes } from 'broker/components/common/Filter';
import AdditionalFilters from 'broker/components/UserMarkets/AdditionalFilters';
import CategoriesFilter from 'broker/components/UserMarkets/components/CategoriesFilter';
import { GetUserMarketsFilterProps } from './types';

interface HeaderBottomSideContentProps {
  userMarketsFilter: GetUserMarketsFilterProps;
  isToolboxOpened: boolean;
}

export default function HeaderBottomSideContent({ isToolboxOpened, userMarketsFilter }: HeaderBottomSideContentProps) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <SearchBar
        value={userMarketsFilter.search}
        onChange={userMarketsFilter.setSearch}
        placeholder={messages.marketsPage.search}
        id="search-user-markets"
        sx={{ minWidth: '40%' }}
      />
      <Filter
        buttonType={FilterButtonTypes.Dropdown}
        title={messages.marketsPage.columnHeaders.coverageLines}
        filters={userMarketsFilter.coverageLineFilters}
        handleFilterChange={userMarketsFilter.handleCoverageLineFilterChange}
        isAllFilters={userMarketsFilter.isAllCoverageLineFilters}
        handleAllSelected={userMarketsFilter.handleAllCoverageLineSelected}
        search
        searchPlaceholder={messages.marketsPage.coverageLinesFilter.search}
      />
      <AdditionalFilters
        inSubmissionFilter={{
          filters: userMarketsFilter.inSubmissionFilters,
          handleFilterChange: userMarketsFilter.handleInSubmissionFilterChange,
          isAllFilters: userMarketsFilter.isAllInSubmissionFilters,
          handleAllSelected: userMarketsFilter.handleAllInSubmissionSelected,
        }}
        marketTypeFilter={{
          filters: userMarketsFilter.marketOrganizationTypeFilters,
          handleFilterChange: userMarketsFilter.handleMarketOrganizationTypeFilterChange,
          isAllFilters: userMarketsFilter.isAllMarketOrganizationTypeFilters,
          handleAllSelected: userMarketsFilter.handleAllMarketOrganizationTypeSelected,
        }}
      />
      <Stack direction="row" overflow="hidden" marginRight={1}>
        <CategoriesFilter
          labelFilters={sortBy(userMarketsFilter.labelFilters, (e) => e.label)}
          handleLabelFilterChange={userMarketsFilter.handleLabelFilterChange}
          handleVisibleLabelChange={userMarketsFilter.handleVisibleLabelChange}
          isAllLabelFilters={userMarketsFilter.isAllLabelFilters}
          handleAllLabelSelected={userMarketsFilter.handleAllLabelSelected}
          sx={{ maxWidth: 350 }}
        />
      </Stack>

      {userMarketsFilter.isFilterApplied && (
        <Stack direction="row" gap={1}>
          <Divider flexItem orientation="vertical" variant="fullWidth" />
          {isToolboxOpened ? (
            <IconButton
              size="small"
              aria-label="close"
              onClick={userMarketsFilter.clearFilters}
              icon={CloseIcon}
              color="secondary"
              variant="contained"
              tooltipContent={messages.buttons.clearFilters}
            />
          ) : (
            <Button startIcon={<CloseIcon />} size="small" color="secondary" onClick={userMarketsFilter.clearFilters}>
              {messages.buttons.clearFilters}
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}
