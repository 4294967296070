import { Link, Stack, Typography } from '@common-components';
import { InfoOutlined as InfoOutlinedIcon } from '@icons';
import { messages } from 'i18n';

const { callout } = messages.SettingsDialog.emailSettings.emailIntegration;

export function EnableEmailIntegrationCallout() {
  return (
    <Stack direction="row" bgcolor="blue.100" py={1} px={2} alignItems="center">
      <InfoOutlinedIcon fontSize="small" sx={{ mr: 1 }} color="primary" />
      <Typography variant="caption">
        {`${callout.message} `}
        <Link href={`mailto:${messages.general.supportMail}`} sx={{ textDecoration: 'none' }}>
          {callout.contactUs}
        </Link>
      </Typography>
    </Stack>
  );
}
