import * as yup from 'yup';
import { messages } from 'i18n';
import { customYupValidateNumber, customYupValidateURL } from 'utils/form-validations';
import {
  FormFieldsConfig,
  StaticFormSection,
  SubmissionFormFieldsNames,
} from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/Screens/SubmissionStaticForm/StaticForm/types';

const { invalidDate, needBySmallerThanDue } = messages.formMessages;

export const companyTypes = ['Public', 'Private'];

export const formFieldsConfig: FormFieldsConfig = {
  [SubmissionFormFieldsNames.InsuredName]: {
    label: messages.submissionModal.companyName,
    name: SubmissionFormFieldsNames.InsuredName,
    id: SubmissionFormFieldsNames.InsuredName,
    optional: true,
    section: StaticFormSection.InsuredInformation,
  },
  [SubmissionFormFieldsNames.Industry]: {
    label: messages.submissionModal.industry,
    name: SubmissionFormFieldsNames.Industry,
    id: SubmissionFormFieldsNames.Industry,
    optional: true,
    section: StaticFormSection.InsuredInformation,
  },
  [SubmissionFormFieldsNames.InsuredCompanyType]: {
    label: messages.submissionModal.insuredCompanyType,
    name: SubmissionFormFieldsNames.InsuredCompanyType,
    id: SubmissionFormFieldsNames.InsuredCompanyType,
    optional: true,
    section: StaticFormSection.InsuredInformation,
  },
  [SubmissionFormFieldsNames.InsuredAddress]: {
    label: messages.submissionModal.insuredAddress,
    name: SubmissionFormFieldsNames.InsuredAddress,
    id: SubmissionFormFieldsNames.InsuredAddress,
    optional: true,
    section: StaticFormSection.InsuredInformation,
  },
  [SubmissionFormFieldsNames.Limit]: {
    label: messages.submissionModal.limit,
    name: SubmissionFormFieldsNames.Limit,
    id: SubmissionFormFieldsNames.Limit,
    optional: true,
    section: StaticFormSection.CoverageParameters,
  },
  [SubmissionFormFieldsNames.DueDate]: {
    label: messages.submissionModal.dueDate,
    name: SubmissionFormFieldsNames.DueDate,
    id: SubmissionFormFieldsNames.DueDate,
    optional: true,
    section: StaticFormSection.CoverageParameters,
  },
  [SubmissionFormFieldsNames.NeedByDate]: {
    label: messages.submissionModal.needByDate,
    name: SubmissionFormFieldsNames.NeedByDate,
    id: SubmissionFormFieldsNames.NeedByDate,
    optional: true,
    section: StaticFormSection.CoverageParameters,
  },
  [SubmissionFormFieldsNames.InsuredAnnualRevenue]: {
    label: messages.submissionModal.insuredAnnualRevenue,
    name: SubmissionFormFieldsNames.InsuredAnnualRevenue,
    id: SubmissionFormFieldsNames.InsuredAnnualRevenue,
    optional: true,
    section: StaticFormSection.InsuredInformation,
  },
  [SubmissionFormFieldsNames.InsuredEmployeeCount]: {
    label: messages.submissionModal.insuredEmployeeCount,
    name: SubmissionFormFieldsNames.InsuredEmployeeCount,
    id: SubmissionFormFieldsNames.InsuredEmployeeCount,
    optional: true,
    section: StaticFormSection.InsuredInformation,
  },
  [SubmissionFormFieldsNames.InsuredWebsite]: {
    label: messages.submissionModal.insuredWebsite,
    name: SubmissionFormFieldsNames.InsuredWebsite,
    id: SubmissionFormFieldsNames.InsuredWebsite,
    optional: true,
    section: StaticFormSection.InsuredInformation,
  },
};

const getNeedByDateRequiredValidation = (isRequired: boolean) => (value: string, ctx: yup.TestContext) => {
  if ((!isRequired && !value) || !ctx.parent.dueDate) {
    return true;
  }

  return new Date(ctx.parent.dueDate) >= new Date(value);
};

export const updateDisabledFields = [SubmissionFormFieldsNames.InsuredName];

export const schema = yup
  .object()
  .shape({
    insuredName: yup.string().default('').nullable(),
    // it doesn't need to go through validation as it is an autocomplete
    industry: yup.mixed().nullable(),
    insuredCompanyType: yup.string().default('').nullable(),
    insuredAddress: yup.string().default(''),
    limit: yup
      .string()
      .default('')
      .test(
        'customYupValidateNumber',
        'customYupValidateNumber',
        customYupValidateNumber({ fieldName: messages.submissionModal.limit }),
      ),
    dueDate: yup.string().default('').notOneOf(['Invalid date'], invalidDate),
    needByDate: yup
      .string()
      .default('')
      .notOneOf(['Invalid date'], invalidDate)
      .test('needBySmallerThanDue', needBySmallerThanDue, getNeedByDateRequiredValidation(false)),
    insuredAnnualRevenue: yup
      .string()
      .default('')
      .test(
        'customYupValidateNumber',
        'customYupValidateNumber',
        customYupValidateNumber({ fieldName: messages.submissionModal.insuredAnnualRevenue }),
      ),
    insuredEmployeeCount: yup
      .string()
      .default('')
      .test(
        'customYupValidateNumber',
        'customYupValidateNumber',
        customYupValidateNumber({ fieldName: messages.submissionModal.insuredEmployeeCount, allowDecimal: false }),
      ),
    insuredWebsite: yup
      .string()
      .default('')
      .test('customYupValidateURL', 'customYupValidateURL', customYupValidateURL()),
  })
  .required();
