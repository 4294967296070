import { MouseEvent } from 'react';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@icons';
// eslint-disable-next-line import/no-internal-modules
import { useHotjar } from 'hooks/useHotjar';
import { baseInputComponentsProps } from 'themes/sizes';
import { HotjarEvents } from 'utils/hotjar-events';
import { Box, ButtonBase, Stack, Typography } from 'components/mui-index';
import InlineBadge, { InlineBadgeProps } from './InlineBadge';
import Tooltip from './Tooltip';
import { Size, TooltipWrapperProps, Variant } from './types';

export interface SelectorProps extends TooltipWrapperProps, InlineBadgeProps {
  /** The selector text */
  content: string;
  /** The function to call when the selector is clicked */
  onClick?: (event?: MouseEvent<HTMLButtonElement> | undefined) => void;
  /** Whether the selector should be rounded */
  rounded?: boolean;
  /** Whether the selector should take up the full width of its container */
  fullWidth?: boolean;
  /** The size of the selector */
  size?: Size;
  /** The variant of the selector */
  variant?: Exclude<Variant, 'contained'>;
  /** Whether the selector is disabled */
  disabled?: boolean;
  /** Whether the selector is in view mode */
  viewMode?: boolean;
  /** The hotjar event to report when the selector is clicked */
  hotjarEvent?: HotjarEvents;
}

const variantProps: Record<Exclude<Variant, 'contained'>, any> = {
  outlined: {
    color: 'text.primary',
    bgcolor: 'common.white',
    border: 1,
    borderColor: 'divider',
    '&[disabled]': {
      borderColor: 'divider',
      color: 'text.disabled',
      bgcolor: 'blueGrey.100',
    },
    '&:hover': {
      bgcolor: 'blueGrey.50',
      borderColor: 'blueGrey.800',
    },
  },
  text: {
    color: 'text.primary',
    bgcolor: 'transparent',
    '&[disabled]': {
      color: 'text.disabled',
    },
    '&:hover': {
      bgcolor: 'blueGrey.50',
    },
  },
};

export default function Selector({
  content,
  onClick,
  badgeContent = 0,
  tooltipContent,
  disabledTooltipContent,
  tooltipPlacement = 'top',
  badgeColor = 'primary',
  fullWidth = false,
  disabled = false,
  size = 'medium',
  variant = 'outlined',
  hotjarEvent,
  isTooltipOpen,
  disableTooltipPadding = false,
  tooltipDarkMode = false,
  viewMode = false,
  rounded,
  ...props
}: SelectorProps) {
  const hotjar = useHotjar();

  const tooltipContentToUse = disabled ? disabledTooltipContent : tooltipContent;
  const badgeContentToUse = badgeContent === '' ? 0 : badgeContent;

  const clickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick && !viewMode) {
      if (hotjarEvent) {
        hotjar.event(hotjarEvent);
      }
      onClick(e);
    }
  };

  return (
    <Tooltip
      tooltipContent={tooltipContentToUse ?? ''}
      placement={tooltipPlacement}
      isOpen={isTooltipOpen}
      disablePadding={disableTooltipPadding}
      darkMode={tooltipDarkMode}
      arrow
      {...props}
    >
      <Box display="inline-block" maxWidth={1} width={fullWidth ? 1 : 'auto'}>
        <ButtonBase
          sx={{
            textAlign: 'left',
            width: fullWidth ? 1 : 'auto',
            maxWidth: 1,
            borderRadius: rounded ? 6 : 1,
            ...baseInputComponentsProps[variant][size],
            ...variantProps[variant],
            ...(viewMode && {
              '&:hover': {
                bgcolor: 'common.white',
                borderColor: 'divider',
              },
            }),
            transition: (theme) =>
              theme.transitions.create(['background-color', 'color', 'border-color'], {
                duration: theme.transitions.duration.short,
              }),
          }}
          onClick={clickHandler}
          disabled={disabled}
          disableRipple={viewMode}
        >
          <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={0.5} width={1}>
            <Typography flex={1} variant="body2" component="div" noWrap pr={0.5}>
              {content}
            </Typography>
            <InlineBadge badgeContent={badgeContentToUse} badgeColor={badgeColor} disabled={disabled} />
            {!viewMode && <KeyboardArrowDownIcon fontSize="small" />}
          </Stack>
        </ButtonBase>
      </Box>
    </Tooltip>
  );
}
