import { ActivityType } from 'enums';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import { Layer, Quote, Submission } from 'types';
import { copyHtmlToClipboard } from 'utils';
import { EmailEditorPageMode, RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import ConceptualTowerDataGrid from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/ConceptualTower/components/ConceptualTowerDataGrid';
import { ConceptualTowerLocationState } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/ConceptualTower/types';
import useReportRows from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/ConceptualTower/useReportRows';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { conceptualTowerHtmlTable } from 'broker/utils/email-utils/email-resolver-utils/template-variables-utils';
import { ConceptualTowerFooter } from './ConceptualTowerFooter';
import { useTowerPdf } from './useTowerPdf';

interface ConceptualTowerContentProps {
  submission: Submission;
  quotes: Quote[];
  layers: Layer[];
  locationState?: ConceptualTowerLocationState;
}

export default function Content({ submission, quotes, layers, locationState }: ConceptualTowerContentProps) {
  const navigate = useNavigate();
  const { emailEditorUrl } = useWorkspaceUrls();
  const [isLoading, { on: showLoader, off: hideLoader }] = useBoolean(false);
  const { rowsData, boldLastRow } = useReportRows(quotes, layers);
  const { downloadReport } = useTowerPdf({ rowsData, submission, boldLastRow });
  const viewOnly = locationState?.viewOnly || false;

  const getModeMessages = () =>
    viewOnly ? messages.ConceptualTowerPage.viewMode : messages.ConceptualTowerPage.generateMode;

  const actionWithLoader = async (action: () => Promise<void>) => {
    showLoader();
    try {
      await action();
    } finally {
      hideLoader();
    }
  };
  const { logSubmissionActivity } = useSubmissionsWorkspace();
  const handleCopy = async () => {
    const html = conceptualTowerHtmlTable(quotes, layers);
    await copyHtmlToClipboard(html);
    await logSubmissionActivity({ activityType: ActivityType.ConceptualTowerCopiedToClipboard }, true);
  };

  const handleSendEmail = async () => {
    navigate(emailEditorUrl, {
      routeKey: RouteKey.EmailEditor,
      state: {
        mode: EmailEditorPageMode.ConceptualTower,
      },
      replace: true,
    });
  };

  const handleOnDownload = async () => {
    await Promise.all([
      downloadReport(),
      logSubmissionActivity({ activityType: ActivityType.ConceptualTowerDownloaded }, true),
    ]);
  };

  return (
    <InnerPageLayout
      footer={(innerPageProps: InnerPageProps) => (
        <ConceptualTowerFooter
          isLoading={isLoading}
          handleCopy={() => actionWithLoader(handleCopy)}
          handleSendEmail={() => actionWithLoader(handleSendEmail)}
          handleOnDownload={() => actionWithLoader(handleOnDownload)}
          {...innerPageProps}
        />
      )}
      title={getModeMessages().title}
      subTitle={getModeMessages().subtitle}
      className="cap-conceptual-tower"
      sx={{ padding: 0, margin: 0 }}
    >
      {() => <ConceptualTowerDataGrid reportRows={rowsData} boldLastTableRow={boldLastRow} />}
    </InnerPageLayout>
  );
}
