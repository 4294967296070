import * as pdfmake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// eslint-disable-next-line import/no-unresolved
import { Content, PageOrientation } from 'pdfmake/interfaces';
import { logger } from 'utils';
import { getPdfContentWithTemplate } from './pdf-content-creator';
import pdfLogo from './pdf-logo.png';

// Set the PDF fonts
// @ts-ignore
pdfmake.vfs = pdfFonts.pdfMake.vfs;

export async function getLogoBase64() {
  // Fetch PNG image and convert to base64-encoded string
  const response = await fetch(pdfLogo);
  const blob = await response.blob();
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onload = () => {
      const base64Data = reader.result?.toString().split(',')[1];
      resolve(base64Data || '');
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
}

export function useGeneratePDF() {
  async function generatePDF(content: Content, pageOrientation?: PageOrientation) {
    logger.log('info', { message: 'start Generating PDF', content, pageOrientation });
    const base64Svg = await getLogoBase64();
    const filePdf = pdfmake.createPdf(getPdfContentWithTemplate({ base64Svg, content, pageOrientation }));

    return new Promise<Blob>((resolve) => {
      filePdf.getBlob((filePdfItem) => {
        logger.log('info', { message: 'end Generated PDF', filePdfItem });
        resolve(filePdfItem);
      });
    });
  }
  return generatePDF;
}
