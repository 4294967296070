export enum DeclinationType {
    AUTO_INDUSTRY_BASED = 'AUTO_INDUSTRY_BASED',
    OPEN_CLAIM_HISTORY = 'OPEN_CLAIM_HISTORY',
    PRIOR_CLAIM_HISTORY = 'PRIOR_CLAIM_HISTORY',
    RISK = 'RISK',
    ATTACHMENT_POINT_TOO_LOW = 'ATTACHMENT_POINT_TOO_LOW',
    UNDERLYING_PRICING = 'UNDERLYING_PRICING',
    LEGAL_ENVIRONMENT = 'LEGAL_ENVIRONMENT',
    CANNOT_COMPETE = 'CANNOT_COMPETE',
    MARKET_BLOCKED = 'MARKET_BLOCKED',
    OTHER = 'OTHER',
}
