import { Chip, NewMenu, Stack, Typography } from '@common-components';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { attachmentPointText } from 'utils';
import { ComparedItem } from './types';

export interface CompareQuoteHeaderProps {
  items: ComparedItem[];
  selectedItem: ComparedItem;
  setSelectedItem: (item: ComparedItem) => void;
}

export function CompareQuoteHeader({ items, selectedItem, setSelectedItem }: CompareQuoteHeaderProps) {
  const optionsMenu = useOptionsMenu();

  const handleSelectItem = (item: ComparedItem) => {
    setSelectedItem(item);
    optionsMenu.closeMenu();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        px: 2,
      }}
      gap={2}
    >
      <Chip
        dropdown
        onClick={optionsMenu.openMenu}
        label={selectedItem.label}
        variant="contained"
        color="secondary"
        size="large"
      />
      {selectedItem.metadata && (
        <Stack flexShrink={0} direction="row" alignItems="center" gap={4}>
          <Stack>
            <Typography variant="body2" noWrap>
              {attachmentPointText(selectedItem.metadata?.attachmentPoint)}
            </Typography>
            <Typography variant="caption2" color="typography.nonEssential" noWrap>
              {messages.quoteItemLabels.attachmentPoint}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body2" noWrap>
              {selectedItem.metadata?.premium}
            </Typography>
            <Typography variant="caption2" color="typography.nonEssential" noWrap>
              {messages.quoteItemLabels.premium}
            </Typography>
          </Stack>
        </Stack>
      )}
      <NewMenu
        optionsMenuState={optionsMenu}
        menuItems={[
          {
            key: 'quotes',
            items: items.map((item) => ({
              label: item.label ?? item.id,
              isSelected: selectedItem.id === item.id,
              onClick: () => handleSelectItem(item),
              disabled: selectedItem.id === item.id,
            })),
          },
        ]}
      />
    </Stack>
  );
}
