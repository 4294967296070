enum Routes {
  Organization = '/admin/organization',
  User = '/admin/user',
  TeamContacts = '/admin/team-contacts',
  Submission = '/admin/submission',
  Layer = '/admin/layer',
  EmailTemplatesByUser = '/admin/email-templates-by-user',
  EmailTemplate = '/admin/email-template',
  DefaultEmailTemplate = '/admin/default-email-templates',
  DbDumps = '/bi/db-dumps',
  DlqStatus = '/admin/dlq-status',
  Team = '/admin/team',
}

const ADMIN_ROUTES = {
  HOME: '/admin',
  ORGANIZATION: Routes.Organization,
  getOrganizationById: (id: string) => `${Routes.Organization}/${id}`,
  TEAM: Routes.Team,
  getTeamById: (id: string) => `${Routes.Team}/${id}`,
  USER: Routes.User,
  UserContacts: Routes.TeamContacts,
  getContactByTeamId: (id: string) => `${Routes.TeamContacts}/${id}`,
  EMAIL_TEMPLATES_BY_USER: Routes.EmailTemplatesByUser,
  getEmailTemplatesByUserId: (id: string) => `${Routes.EmailTemplatesByUser}/${id}`,
  getUserById: (id: string) => `${Routes.User}/${id}`,
  SUBMISSION: Routes.Submission,
  getSubmissionById: (id: string) => `${Routes.Submission}/${id}`,
  LAYER: Routes.Layer,
  getLayerById: (id: string) => `${Routes.Layer}/${id}`,
  EMAIL_TEMPLATE: Routes.EmailTemplate,
  getEmailTemplate: (userId: string, templateId?: string) => `${Routes.EmailTemplate}/${userId}/${templateId || 'new'}`,
  DEFAULT_EMAIL_TEMPLATES: Routes.DefaultEmailTemplate,
  DB_DUMPS: Routes.DbDumps,
  DLQ_STATUS: Routes.DlqStatus,
};

export enum adminUrlParams {
  ID = 'id',
}

export default ADMIN_ROUTES;
