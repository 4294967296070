import { ReactNode } from 'react';
import { Box } from '@common-components';
import { IndentedBox } from './IndentedBox';

export const BulletedContent = ({
  level,
  content,
  children,
}: {
  level: number;
  content: ReactNode;
  children?: ReactNode;
}) => (
  <Box>
    {level === 1 ? '•' : '-'} {content}
    {children ? <IndentedBox level={level}>{children}</IndentedBox> : null}
  </Box>
);
