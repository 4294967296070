import { renderToStaticMarkup } from 'react-dom/server';
import theme from 'themes';

export default function getHtmlTable(rowsData: Record<string, any>[], headers: { [key in string]: string }) {
  const headerStyle = {
    color: theme.palette.typography.nonEssential,
    border: `1px solid ${theme.palette.blueGrey[200]}!important`,
    fontWeight: 400,
    padding: 5,
  };

  const categoryHeader = {
    padding: 5,
    paddingTop: 16,
    border: 'none',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[50],
    fontWeight: 600,
  };

  const regularCell = {
    border: `1px solid  ${theme.palette.blueGrey[200]}!important`,
    padding: 5,
    color: theme.palette.text.secondary,
  };

  const table = (
    <table
      className="cap-table"
      style={{
        border: `1px solid  ${theme.palette.blueGrey[200]}!important`,
        width: '100%',
        borderCollapse: 'collapse',
      }}
    >
      <tr>
        {Object.keys(headers).map(
          (cell) =>
            cell !== 'id' && (
              <td key={cell} style={headerStyle}>
                {headers[cell]}
              </td>
            ),
        )}
      </tr>
      {rowsData.map((row: any) => (
        // eslint-disable-next-line react/jsx-key
        <tr>
          {Object.keys(headers).map(
            (cell) =>
              cell !== 'id' && (
                <td key={cell} style={row.id === row.marketName ? categoryHeader : regularCell}>
                  {row[cell]}
                </td>
              ),
          )}
        </tr>
      ))}
    </table>
  );

  return renderToStaticMarkup(table);
}
