import { MarketClient } from 'clients';
import { QueryKeys } from 'enums';
import { Market, MarketCreate } from 'types';
import { UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useBulkCreate } from './query/useBulkCreate';
import { useMutate } from './query/useMutate';
import { useQueryRetrieve, useQuerySearch } from './query/useQuery';

export function useSearchMarket(props?: UseQueryBaseSearchOptions<Market>) {
  const { filter, queryOptions, enabled, sorter } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.Markets,
    clientClass: MarketClient,
    enabled,
    sorter,
    filter,
    queryOptions: {
      // Default market search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useRetrieveMarket(props: UseQueryBaseRetrieveOptions<Market>) {
  const { id, queryOptions, enabled } = props;
  return useQueryRetrieve({
    queryKey: QueryKeys.Markets,
    clientClass: MarketClient,
    id,
    enabled,
    queryOptions: {
      // Default market retrieve query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateMarket() {
  const { create: createMarket, update: updateMarket } = useMutate<Market, Market, MarketCreate, MarketClient>({
    queryKey: QueryKeys.Markets,
    clientClass: MarketClient,
  });

  const { create: createMarketByName } = useMutate<Market, Market, MarketCreate, MarketClient>({
    queryKey: QueryKeys.Markets,
    clientClass: MarketClient,
    createOptions: {
      subPath: 'by-name',
      throwOnError: true,
    },
  });

  return { createMarket, updateMarket, createMarketByName };
}

export function useBulkCreateMarkets() {
  const { bulkCreate: bulkCreateMarkets } = useBulkCreate<Market, Market, MarketClient>({
    queryKey: QueryKeys.Markets,
    clientClass: MarketClient,
    createOptions: { throwOnError: true },
  });

  return { bulkCreateMarkets };
}
