import { ReactElement } from 'react';
import { Typography } from '@common-components';
import { useEnvironment } from 'hooks';
import { messages } from 'i18n';
import { ConfirmationDialogGuard, ConfirmationDialogGuardAttributes } from 'broker/components/ConfirmationDialogGuard';
import { isCapitolaMail, RecipientGroup } from 'broker/components/Emails/recipient-utils';

interface SendEmailNonProductionGuardProps {
  recipientGroups: RecipientGroup[];
  onSend: () => void;
  children: (props: ConfirmationDialogGuardAttributes) => ReactElement;
}

export default function SendEmailNonProductionGuard({
  recipientGroups,
  onSend,
  children,
}: SendEmailNonProductionGuardProps) {
  const environment = useEnvironment();
  const recipientsEmails = Object.entries(recipientGroups)
    .flatMap(([, group]) => group.recipients)
    .map((contact) => contact.email);

  return (
    <ConfirmationDialogGuard
      message={
        <>
          <Typography variant="h4">{messages.emailEditor.recipients}:</Typography>
          <Typography variant="body1">
            {recipientsEmails.map((email) => (
              <li key={email}>{email}</li>
            ))}
          </Typography>
        </>
      }
      title={messages.emailEditor.sendMessagesGuards.nonProductionSendingConfirmation(environment)}
      action={onSend}
      showConfirmation={environment !== 'prod' && recipientsEmails.some((email) => !isCapitolaMail(email))}
    >
      {({ guardedAction }) => children({ guardedAction })}
    </ConfirmationDialogGuard>
  );
}
