//! !!!!!!!
// update also in lambdas/common/backend/enums/herald/legal-entity.ts:
//! !!!!!!!
export enum LegalEntity {
    CCorporation = 'C Corporation',
    SCorporation = 'S Corporation',
    LimitedLiabilityCompany = 'Limited Liability Company',
    SoleProprietorship = 'Sole Proprietorship',
    Individual = 'Individual',
    NonProfitCorporation = 'Non-Profit Corporation',
    Association = 'Association',
    GeneralPartnership = 'General Partnership',
    LimitedPartnership = 'Limited Partnership',
    Estate = 'Estate',
    Trust = 'Trust',
    Trustee = 'Trustee',
    JointVenture = 'Joint Venture',
    GovernmentPublicEntity = 'Government/Public Entity',
    ReligiousOrganization = 'Religious Organization',
}
