import { useState } from 'react';
import { useParams } from 'react-router-dom-latest';
import { Grid, List, Paper } from '@common-components';
import { Delete as DeleteIcon, UploadFile as UploadFileIcon } from '@icons';
import { useMutateTeam, useRetrieveOrganization, useRetrieveTeam, useSearchUser, useToast } from 'hooks';
import { messages } from 'i18n';
import CardTextField from 'components/CardTextField';
import DetailCard from 'components/DetailCard';
import ContactsBulkUploadDialog from 'admin/components/ContactsBulkUploadDialog';
import SubmissionsBulkUploadDialog from 'admin/components/SubmissionsBulkUploadDialog';
import UserList from 'admin/components/UserList';
import ConfirmationDialog from 'admin/dialogs/ConfirmationDialog';

const {
  titleDeleteTeamData,
  successDeleteTeamData,
  warningMessageDeleteTeamData,
  deleteTeamDataButton,
  confirmDeleteTeamDataMessage,
  deleteTeamDataMessage,
} = messages.admin.deleteTeamDataDialog;

export function TeamPage() {
  const teamId = useParams<{ id: string }>().id;
  const { data: team } = useRetrieveTeam({ id: teamId!, enabled: !!teamId });

  const { data: organization } = useRetrieveOrganization({
    id: team?.organizationId ?? '',
    enabled: !!team,
  });

  const { count: teamMembersCount } = useSearchUser({ filter: { teamId: team?.id }, enabled: !!team });

  const { updateTeam, clearTeamData } = useMutateTeam();

  const { showToast } = useToast();
  const [showDeleteDataDialog, setShowDeleteDataDialog] = useState<boolean>(false);

  const [showContactsUpload, setShowContactsUpload] = useState<boolean>(false);
  const [showSubmissionsUpload, setShowSubmissionsUpload] = useState<boolean>(false);

  const updateTeamName = async (value: string) => {
    await updateTeam.mutateAsync({
      id: team!.id,
      data: {
        name: value,
      },
    });
  };

  const updateTeamDisplayName = async (value: string) => {
    await updateTeam.mutateAsync({
      id: team!.id,
      data: {
        emailDisplayName: value,
      },
    });
  };

  async function deleteTeamData() {
    await clearTeamData.mutateAsync(team!.id);
    showToast('success', { message: successDeleteTeamData });

    setShowDeleteDataDialog(false);
  }

  if (!organization) {
    return null;
  }

  return (
    <>
      <DetailCard
        avatar="C"
        title={team!.name}
        customActions={[
          {
            title: titleDeleteTeamData,
            iconElement: DeleteIcon,
            onClick: () => setShowDeleteDataDialog(true),
          },
          { title: 'Upload contacts', iconElement: UploadFileIcon, onClick: () => setShowContactsUpload(true) },
          {
            title: 'Upload submissions',
            iconElement: UploadFileIcon,
            onClick: () => setShowSubmissionsUpload(true),
          },
        ]}
      >
        <Grid sx={{ mt: 0 }} container wrap="wrap" spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <CardTextField onChange={updateTeamName} label="Name" value={team!.name} />
            <CardTextField onChange={updateTeamDisplayName} label="email display name" value={team!.emailDisplayName} />
            <CardTextField label="Organization Id" value={team!.organizationId} />
          </Grid>
          <Grid item xs={12} sm={8} lg={8}>
            <List>
              <Paper sx={{ m: 1 }}>
                <UserList {...{ team, organization }} />
              </Paper>
            </List>
          </Grid>
        </Grid>
      </DetailCard>
      <ContactsBulkUploadDialog team={team!} isOpen={showContactsUpload} onClose={() => setShowContactsUpload(false)} />
      <SubmissionsBulkUploadDialog
        team={team!}
        isOpen={showSubmissionsUpload}
        onClose={() => setShowSubmissionsUpload(false)}
      />
      <ConfirmationDialog
        open={showDeleteDataDialog}
        onAction={deleteTeamData}
        onClose={() => setShowDeleteDataDialog(false)}
        confirmationText={team!.name}
        confirmationMessage={confirmDeleteTeamDataMessage}
        title={titleDeleteTeamData}
        warningMessage={warningMessageDeleteTeamData}
        confirmButton={deleteTeamDataButton}
        deleteTeamDataMessage={teamMembersCount! > 1 ? deleteTeamDataMessage(teamMembersCount!) : undefined}
      />
    </>
  );
}
