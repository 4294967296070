import { NotificationAssigneeType } from '../enums';
import { messages } from '../messages';

type NotificationAssigneeTypeConfigProps = {
    text: string;
};

const notificationAssigneeMessages = messages.notifications.assignee;

export const notificationAssigneeTypeConfig: Record<NotificationAssigneeType, NotificationAssigneeTypeConfigProps> = {
    [NotificationAssigneeType.Agent]: {
        text: notificationAssigneeMessages.agent,
    },
    [NotificationAssigneeType.Underwriter]: {
        text: notificationAssigneeMessages.underwriter,
    },
};
