import { useEffect, useState } from 'react';
import { Divider, Toolbar, Typography } from '@common-components';
import { ContactRole } from 'enums';
import { getContactRole } from 'enums/type-mappers/contact-role-config';
import { useBoolean, useSearchContact } from 'hooks';
import { messages } from 'i18n';
import { Contact, Organization } from 'types';
import DataGridList, { DEFAULT_PAGE_SIZE } from 'components/DataGridList';
import SearchField from './SearchField';
import SelectField from './SelectField';
import TeamLink from './TeamLink';

export default function OrganizationContactList({ organization }: { organization: Organization }) {
  const [query, setQuery] = useState<string>();
  const [role, setRole] = useState<string | null>(null);
  const [hasContacts, { on: setHasContacts }] = useBoolean(false);

  const [offset, setOffset] = useState<number>(0);

  const pageSize = DEFAULT_PAGE_SIZE;

  const {
    items: contacts,
    count,
    isInitialLoading,
  } = useSearchContact({
    filter: {
      offset,
      limit: pageSize,
      q: query,
      organizationId: organization.id,
      role: role as ContactRole,
    },
  });

  // Hide the contacts grid if there are no contacts
  useEffect(() => {
    if (count > 0) {
      setHasContacts();
    }
  }, [count, setHasContacts]);

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 0.1,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 0.1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.1,
    },
    {
      field: 'role',
      headerName: 'Role',
      renderCell: ({ row }: { row: unknown }) => getContactRole((row as Contact).role),
      flex: 0.1,
    },
    {
      field: 'team',
      headerName: 'Team',
      flex: 0.1,
      renderCell: ({ row }: { row: unknown }) =>
        TeamLink({
          team: {
            id: (row as Contact).teamId ?? '',
            name: (row as Contact).teamName ?? '',
          },
        }),
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      flex: 0.1,
    },
    {
      field: 'title',
      headerName: 'Job Title',
      flex: 0.1,
    },
  ];

  if (!hasContacts) {
    return null;
  }

  const unmappedChoices = ContactRole;
  const choices = Object.fromEntries(
    Object.keys(unmappedChoices).map((value) => [value, getContactRole(value as ContactRole)]),
  );

  return (
    <>
      <Toolbar sx={{ p: 1 }}>
        <Typography variant="h6" id="tableTitle" component="div" sx={{ mr: 2 }}>
          {messages.admin.organizationPage.contactsGridTitle}
        </Typography>

        <Divider orientation="vertical" flexItem variant="middle" />

        <SearchField onChange={setQuery} />

        <SelectField choices={choices} label="Role" onChange={setRole} value={role} />
      </Toolbar>
      <Typography variant="body2" sx={{ pl: 3, pb: 2 }}>
        {messages.admin.organizationPage.contactsGridSubtitle(organization.name)}
      </Typography>
      <DataGridList
        columns={columns}
        items={contacts}
        count={count}
        setOffset={setOffset}
        isLoading={isInitialLoading}
        pageSize={pageSize}
      />
    </>
  );
}
