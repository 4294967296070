import { ReactNode } from 'react';
import Button from './Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from './mui-index';

export interface ActionDialogProps {
  actionLabel: string;
  cancelLabel?: string;
  children?: ReactNode;
  enabled?: boolean;
  onClose: () => void;
  onAction?: () => Promise<void> | void;
  open: boolean;
  text?: string;
  title: string;
}

export default function ActionDialog({
  actionLabel,
  cancelLabel = 'Cancel',
  children,
  enabled = true,
  onClose,
  onAction,
  open,
  text,
  title,
}: ActionDialogProps) {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} aria-labelledby="create">
      <DialogTitle id="create">{title}</DialogTitle>
      {text && <Divider />}
      <DialogContent>
        {text && <DialogContentText>{text}</DialogContentText>}
        {children}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={onAction} color="primary" disabled={!enabled}>
          {actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
