import { UseFormReturn } from 'react-hook-form';

export async function asyncHandleSubmit<T>(formMethods: UseFormReturn<any, object>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    formMethods.handleSubmit(
      (formData) => {
        resolve(formData);
      },
      (e) => {
        reject(e);
      },
    )();
  });
}
