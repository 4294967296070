import { isEmpty } from 'lodash';
import { ClipboardEvent, useState } from 'react';
import { Box, IconButton, LoaderOverlay, Stack, TextField } from '@common-components';
import { ArrowForward } from '@icons';
import { AIMessageType } from 'enums';
import { useCtrlEnterSubmitButton, useScrollToBottomOnContentChange } from 'hooks';
import { messages } from 'i18n';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { AIGeneratedEmptyState } from './AIGeneratedEmptyState';
import { AIPromptMessage } from './AIPromptMessage';
import { AIResponseMessage } from './AIResponseMessage';
import { FeedbackFormData } from './MessageActions/FeedbackForm/form-utils';

export function AIGeneratedContent({
  withFileLinks,
  isRichTextResponse,
}: {
  withFileLinks: boolean;
  isRichTextResponse: boolean;
}) {
  const {
    feature,
    messages: aiMessages,
    isLoading,
    isContentStreaming,
    addFollowupMessage,
    sendAIThread,
    isRegenerating,
  } = useAIContext();
  const belowContentRef = useScrollToBottomOnContentChange(aiMessages);
  const [followupQuestion, setFollowupQuestion] = useState('');

  const { buttonRef, handleKeyDown } = useCtrlEnterSubmitButton();

  if (isRegenerating && !isContentStreaming) {
    return <LoaderOverlay />;
  }

  if (isEmpty(aiMessages)) {
    return <AIGeneratedEmptyState />;
  }

  const onFeedbackSubmit = async (data: FeedbackFormData, index: number) => {
    await sendAIThread({
      feedback: data.feedback,
      messages: aiMessages.slice(0, index + 1),
    });
  };

  const onFollowupQuestion = async () => {
    const followupQuestionValue = followupQuestion;
    setFollowupQuestion('');
    await addFollowupMessage(followupQuestionValue);
  };

  const handlePasteAsPlainText = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent the default paste action

    // This is a hack to get the plain text from the clipboard
    const clipboardData = event.clipboardData.getData('text/html') ?? event.clipboardData.getData('text/plain');
    const tempDiv = document.createElement('div');

    // This part aims to replace <br> and <p> tags with new lines (otherwise we lose them when extracting the inner text
    tempDiv.innerHTML = clipboardData
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<\/p>\s*<p>/gi, '\n\n')
      .replace(/<p>/gi, '')
      .replace(/<\/p>/gi, '\n\n');

    const plainText = tempDiv.textContent || tempDiv.innerText || '';

    setFollowupQuestion(plainText);
  };

  return (
    <Stack flex={1} direction="column" overflow="hidden">
      <Stack flex={1} direction="column" p={1} pb={0} overflow="auto">
        {aiMessages.map((message, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} display="grid">
            {message.type === AIMessageType.Prompt ? (
              <AIPromptMessage message={message} />
            ) : (
              <AIResponseMessage
                message={message}
                onFeedbackSubmit={onFeedbackSubmit}
                index={index}
                withFileLinks={withFileLinks}
                isRichTextResponse={isRichTextResponse}
              />
            )}
          </Box>
        ))}
        <Box ref={belowContentRef} />
      </Stack>
      <Stack direction="row" mt="auto" gap={1} pt={2} pb={1} bgcolor="common.white" mx={1}>
        <TextField
          variant="outlined"
          maxRows={10}
          multiline
          sx={{ marginTop: 'auto', flex: 1 }}
          placeholder={messages.submissionWorkspace.aiGeneratedContentPopup.askAboutThis(
            messages.submissionWorkspace.aiGeneratedContentPopup.featureToName[feature],
          )}
          value={followupQuestion}
          onChange={({ target }) => {
            setFollowupQuestion(target.value);
          }}
          onKeyDown={handleKeyDown}
          onPaste={handlePasteAsPlainText}
        />
        <Box alignSelf="end" pb={1}>
          <IconButton
            ref={buttonRef}
            disabled={isLoading || isContentStreaming || followupQuestion === ''}
            icon={ArrowForward}
            size="medium"
            loading={isLoading}
            onClick={onFollowupQuestion}
          />
        </Box>
      </Stack>
    </Stack>
  );
}
