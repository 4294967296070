import { Stack, Typography } from '@common-components';

interface QuoteHeaderItemProps {
  title: string | JSX.Element;
  subTitle: string;
}
export default function QuoteHeaderItem({ title, subTitle }: QuoteHeaderItemProps) {
  return (
    <Stack>
      <Typography variant="body2" noWrap>
        {title}
      </Typography>
      <Typography variant="caption2" color="typography.nonEssential" noWrap>
        {subTitle}
      </Typography>
    </Stack>
  );
}
