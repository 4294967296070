import { uniq } from 'lodash';
import {
  HeraldApplicationNormalized,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';

export function getHeraldRequiredQuestionIds(heraldApplication: HeraldApplicationNormalized) {
  const requiredQuestionIds: string[] = [];
  function setValuesRequiredQuestionId(normalizedParameter: HeraldNormalizedParameter) {
    if (normalizedParameter.isRequired) {
      requiredQuestionIds.push(normalizedParameter.parameter_id);
    }
    if (normalizedParameter.childValues) {
      normalizedParameter.childValues.forEach(setValuesRequiredQuestionId);
    }
  }
  heraldApplication.risk_values.forEach(setValuesRequiredQuestionId);
  heraldApplication.coverage_values.forEach(setValuesRequiredQuestionId);
  // herald array has the question repeat itself so added uniq to remove duplicates
  return uniq(requiredQuestionIds);
}
