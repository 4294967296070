import { sortBy } from 'lodash';
import { Route, Routes } from 'react-router-dom-latest';
import { Box, Button, Divider, Link, Paper, SearchBar, Stack, Typography } from '@common-components';
import { usePagination, useSearchUserMarket } from 'hooks';
import { messages } from 'i18n';
import { Contact, SubmissionMarket, UserMarket } from 'types';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import Filter, { FilterButtonTypes } from 'broker/components/common/Filter';
import NoItemsFound from 'broker/components/common/NoItemsFound';
import Sort from 'broker/components/common/Sort';
import TablePagination from 'broker/components/common/TablePagination';
import useUserMarketsFilter, { sorters } from 'broker/components/UserMarkets/hooks/useUserMarketsFilter';
import UserMarketDialog from 'broker/dialogs/UserMarketDialog';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import AdditionalFilters from './AdditionalFilters';
import CategoriesFilter from './components/CategoriesFilter';
import MarketsTableView from './components/MarketsTableView';
import MarketsHeader from './MarketsHeader';
import { SelectedMarketsMap } from './types';
import { UserMarketSortTypes } from './utils';

interface UserMarketsProps {
  selectionMode?: boolean;
  onMarketsSelected?: (markets: UserMarket[], replace?: boolean) => void;
  selectedMarkets?: SelectedMarketsMap;
  submissionMarkets?: SubmissionMarket[];
  marketsIdsInSubmission?: string[];
  incumbentMarketIds?: string[];
  onMarketRemoved?: (marketId: string) => void;
  isLoadingMarkets?: boolean;
  selectedMarketsCount?: number;
  clearSelectedMarkets?: () => void;
  disableSelection?: boolean;
  defaultSortType?: UserMarketSortTypes;
}

export default function UserMarkets({
  selectionMode,
  isLoadingMarkets,
  selectedMarketsCount,
  onMarketsSelected,
  selectedMarkets,
  marketsIdsInSubmission,
  incumbentMarketIds,
  submissionMarkets,
  onMarketRemoved,
  clearSelectedMarkets,
  disableSelection,
  defaultSortType,
}: UserMarketsProps) {
  const { items, count, isLoading, isFetching } = useSearchUserMarket({});
  const { page, setPage, rowsPerPage, setRowsPerPage, paginationReset } = usePagination();

  const {
    coverageLineFilters,
    handleCoverageLineFilterChange,
    handleInSubmissionFilterChange,
    handleMarketStatusFilterChange,
    isAllCoverageLineFilters,
    handleAllCoverageLineSelected,
    labelFilters,
    handleLabelFilterChange,
    handleVisibleLabelChange,
    handleAllLabelSelected,
    handleAllInSubmissionSelected,
    handleAllMarketTypeSelected,
    isAllLabelFilters,
    isAllInSubmissionFilters,
    isAllMarketTypeFilters,
    inSubmissionFilters,
    marketTypeFilters,
    sortedAndFilteredItems,
    search,
    setSearch,
    setSortType,
    sortType,
    marketOrganizationTypeFilters,
    isAllMarketOrganizationTypeFilters,
    handleAllMarketOrganizationTypeSelected,
    handleMarketOrganizationTypeFilterChange,
    clearFilters,
    isFilterApplied,
  } = useUserMarketsFilter(
    items,
    isLoading,
    isFetching,
    paginationReset,
    marketsIdsInSubmission,
    incumbentMarketIds,
    defaultSortType,
  );

  const navigate = useNavigate();

  const showItems = isLoading || items.length > 0;

  const handleChange = (value: number) => {
    setPage(value);
  };

  const marketsSlice = sortedAndFilteredItems.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage);

  const checkedLabels = labelFilters.filter((label) => label.checked).map((label) => label.label);

  const onCreateMarket = () => {
    navigate(`${BROKER_NESTED_ROUTES.MARKETS.MARKET}`, {
      routeKey: RouteKey.UserMarket,
      state: undefined,
    });
  };

  const onSelectMarket = (contacts: Contact[], market: UserMarket) => {
    if (onMarketsSelected) {
      onMarketsSelected([{ ...market, contacts }]);
    }
  };

  const onSelectionClick = (isMarketSelected: boolean, market: UserMarket) => {
    if (isMarketSelected && onMarketRemoved) {
      onMarketRemoved(market.id);
    } else {
      onSelectMarket(market.contacts, market);
    }
  };

  const handleContactClicked = (contact: Contact, selectedContacts: Contact[], market: UserMarket) => {
    if (!selectedContacts) {
      onSelectMarket([contact], market);
      return;
    }

    const clickedContactIndex = selectedContacts.findIndex((selectedContact) => selectedContact.id === contact.id);
    if (clickedContactIndex === -1) {
      onSelectMarket([...selectedContacts, contact], market);
    } else {
      const newSelectedContacts = [...selectedContacts];
      newSelectedContacts.splice(clickedContactIndex, 1);
      onSelectMarket(newSelectedContacts, market);
    }
  };

  return (
    <>
      <Stack height={1}>
        <MarketsHeader
          count={count}
          filteredCount={sortedAndFilteredItems.length}
          isFilterApplied={isFilterApplied || search.length > 1}
          onCreateMarket={onCreateMarket}
          isLoadingMarkets={isLoadingMarkets}
          selectedMarketsCount={selectedMarketsCount}
          clearSelectedMarkets={clearSelectedMarkets}
          selectionMode={!!selectionMode}
        />
        <Stack flex={1} p={3} gap={2}>
          {showItems && (
            <Stack direction="row" justifyContent="space-between" maxHeight={40} gap={1} alignItems="center">
              <SearchBar
                value={search}
                onChange={setSearch}
                placeholder={messages.marketsPage.search}
                id="search-user-markets"
                sx={{ minWidth: 200 }}
              />
              {!selectionMode && (
                <Filter
                  buttonType={FilterButtonTypes.Dropdown}
                  filters={coverageLineFilters}
                  handleFilterChange={handleCoverageLineFilterChange}
                  isAllFilters={isAllCoverageLineFilters}
                  handleAllSelected={handleAllCoverageLineSelected}
                  title={messages.marketsPage.coverageLines}
                  search
                  searchPlaceholder={messages.marketsPage.coverageLinesFilter.search}
                />
              )}
              <Filter
                buttonType={FilterButtonTypes.Dropdown}
                filters={marketOrganizationTypeFilters}
                handleFilterChange={handleMarketOrganizationTypeFilterChange}
                isAllFilters={isAllMarketOrganizationTypeFilters}
                handleAllSelected={handleAllMarketOrganizationTypeSelected}
                title={messages.marketsPage.columnHeaders.marketType}
              />
              {selectionMode && (
                <AdditionalFilters
                  inSubmissionFilter={{
                    filters: inSubmissionFilters,
                    handleFilterChange: handleInSubmissionFilterChange,
                    isAllFilters: isAllInSubmissionFilters,
                    handleAllSelected: handleAllInSubmissionSelected,
                  }}
                  marketTypeFilter={{
                    filters: marketTypeFilters,
                    handleFilterChange: handleMarketStatusFilterChange,
                    isAllFilters: isAllMarketTypeFilters,
                    handleAllSelected: handleAllMarketTypeSelected,
                  }}
                />
              )}

              <Stack direction="row" overflow="hidden" gap={1} flex={1}>
                <CategoriesFilter
                  labelFilters={sortBy(labelFilters, (e) => e.label)}
                  handleLabelFilterChange={handleLabelFilterChange}
                  handleVisibleLabelChange={handleVisibleLabelChange}
                  isAllLabelFilters={isAllLabelFilters}
                  handleAllLabelSelected={handleAllLabelSelected}
                />
              </Stack>
              <Box
                sx={{
                  visibility: isFilterApplied ? 'inherit' : 'hidden',
                }}
              >
                <Button size="small" onClick={clearFilters}>
                  {messages.buttons.clearFilters}
                </Button>
              </Box>

              <Stack direction="row" gap={1} justifyContent="flex-end">
                <Sort setSortType={setSortType} sortType={sortType} sorters={sorters} />
              </Stack>
            </Stack>
          )}
          {showItems && (
            <Stack
              flex={1}
              overflow="auto"
              component={Paper}
              variant="outlined"
              divider={sortedAndFilteredItems.length > rowsPerPage ? <Divider /> : undefined}
            >
              <MarketsTableView
                coverageLineFilters={coverageLineFilters}
                isAllCoverageLineFilters={isAllCoverageLineFilters}
                marketsIdsInSubmission={marketsIdsInSubmission}
                submissionMarkets={submissionMarkets}
                onSelectMarket={onSelectMarket}
                selectedMarkets={selectedMarkets}
                extendedMarkets={marketsSlice}
                isLoading={isLoading}
                searchInput={search}
                checkedLabels={checkedLabels}
                selectionMode={selectionMode}
                onSelectionClick={onSelectionClick}
                handleContactClicked={handleContactClicked}
                disableSelection={disableSelection}
              />

              <TablePagination
                from={page * rowsPerPage - rowsPerPage + 1}
                to={page * rowsPerPage}
                outOf={sortedAndFilteredItems.length}
                handleChange={handleChange}
                count={Math.ceil(sortedAndFilteredItems.length / rowsPerPage)}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Stack>
          )}
          {!showItems && (
            <NoItemsFound title={messages.marketsPage.emptyState}>
              <Link component={Button} underline="none" onClick={onCreateMarket}>
                <Typography variant="h4">{messages.marketsPage.emptyStateSubTitle}</Typography>
              </Link>
            </NoItemsFound>
          )}
        </Stack>
      </Stack>
      <Routes>
        <Route
          path={BROKER_NESTED_ROUTES.MARKETS.MARKET}
          element={<UserMarketDialog isLocationStateRequired={false} />}
        />
      </Routes>
    </>
  );
}
