import { useMemo } from 'react';
import { Quote } from 'types';
import { convertSubmissionMarketToRecipientGroup } from 'broker/components/Emails/recipient-utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { QuoteStatusType } from './types';
import { filterAttachmentQuotes } from './utils';

export function useGetQuoteStatusEmailProps(emailRecipientsQuotes: Quote[], type: QuoteStatusType) {
  const { quotes, markets } = useSubmissionsWorkspace();

  const emailQuotesFiles = useMemo(
    () =>
      type === QuoteStatusType.Policy
        ? filterAttachmentQuotes(quotes, QuoteStatusType.Policy).map((quote) => quote.binderFileIds)
        : filterAttachmentQuotes(quotes, QuoteStatusType.Bind).map((quote) => quote.capitolaQuoteFileIds),
    [type, quotes],
  );

  const selectedMarketRecipientGroups = useMemo(() => {
    const emailRecipientsIds = emailRecipientsQuotes.map((quote) => quote.submissionMarketId);

    return markets
      .filter((market) => emailRecipientsIds.includes(market.id))
      .map((selectedMarket) => convertSubmissionMarketToRecipientGroup(selectedMarket, selectedMarket.contacts));
  }, [emailRecipientsQuotes, markets]);

  return {
    emailQuotesFiles,
    selectedMarketRecipientGroups,
  };
}
