import { Fragment, MouseEvent } from 'react';
import { Button, Card, CardActions, CardContent, Grid, Stack } from '@common-components';

import { QuoteStatus } from 'enums';
import { cardHeaderHeight, softShadow } from 'themes';
import { Layer, Quote } from 'types';
import { attachmentPointText } from 'utils';
import { layerStatusConfig } from 'broker/configuration-mappers/layer-status-config';
import CardTitle from './CardTitle';
import MarketQuoteList from './QuoteList/MarketQuoteList';
import SelectedQuoteItem from './QuoteList/SelectedQuoteItem';
import SidePanel from './SidePanel';
import { CardActionsStyles, CardContentStyles } from './styles';
import useLayerItemMainActions from './useLayerItemMainActions';

interface LayerItemProps {
  layer: Layer;
  index: number;
  layersLength: number;
  quotes: Quote[];
  setRemoveLayerDialogOpen: (layerId: string) => void;
  hideQuoteActions?: boolean;
}

export default function LayerItem({
  layer,
  quotes,
  index,
  layersLength,
  setRemoveLayerDialogOpen,
  hideQuoteActions,
}: LayerItemProps) {
  const isTopLayer = index === layersLength - 1;

  const isPrimaryLayer = !layer.attachmentPoint;

  const selectedQuote = quotes.find((quote) => quote.status === QuoteStatus.Selected);
  const singleInLayerQuote = quotes.find((quote) => quote.isSingleInLayer);

  const candidateQuotes = quotes.filter((quote) => quote.status === QuoteStatus.Quoting);
  const hasCandidateQuotes = candidateQuotes.length > 0;

  const layerMainActions = useLayerItemMainActions(layer, isPrimaryLayer, candidateQuotes, singleInLayerQuote);

  const layerTitle = attachmentPointText(layer.attachmentPoint);

  const statusProps = layerStatusConfig[layer.status];

  return (
    <Grid flexBasis="auto" item xs={12} sx={{ py: 1 }}>
      <Card variant="outlined" sx={{ boxShadow: softShadow }}>
        <CardContent sx={CardContentStyles}>
          <Stack
            borderBottom={1}
            borderColor="divider"
            width={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            p={2}
            height={cardHeaderHeight}
          >
            <CardTitle isPrimaryBadge={index === 0 && statusProps.hasSelectedQuote}>
              {statusProps.hasSelectedQuote ? selectedQuote?.marketName : layerTitle}
            </CardTitle>
            <SidePanel
              isTopLayer={isTopLayer}
              setRemoveLayerDialogOpen={setRemoveLayerDialogOpen}
              layer={layer}
              hasCandidateQuotes={hasCandidateQuotes}
              status={statusProps.text}
              color={statusProps.color}
            />
          </Stack>

          {hasCandidateQuotes && (
            <MarketQuoteList hideQuoteActions={hideQuoteActions} quotes={candidateQuotes} layer={layer} />
          )}
          {selectedQuote && (
            <SelectedQuoteItem hideQuoteActions={hideQuoteActions} quote={selectedQuote} layer={layer} />
          )}
        </CardContent>
        {layerMainActions.length > 0 && !hideQuoteActions && (
          <CardActions sx={CardActionsStyles}>
            <Stack direction="row" width={1} gap={1} px={1}>
              {layerMainActions.map((layerMainAction) => {
                const { Icon } = layerMainAction;
                return (
                  <Fragment key={layerMainAction.title}>
                    <Button
                      startIcon={<Icon />}
                      onClick={(e?: MouseEvent<HTMLButtonElement>) => {
                        layerMainAction.clickHandler(e);
                      }}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      tooltipContent={layerMainAction.title}
                    >
                      {layerMainAction.title}
                    </Button>

                    {layerMainAction.extraElement ? layerMainAction.extraElement : null}
                  </Fragment>
                );
              })}
            </Stack>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
}
