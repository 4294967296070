import { ReactNode } from 'react';
import { Box, Tooltip } from '@common-components';
import MenuContent from 'components/menu/MenuContent';
import { MenuGroup } from 'components/menu/types';

interface SmartReportsTooltipProps {
  children?: ReactNode;
  inSubmissionMenuGroup: MenuGroup;
}

export default function SmartReportsToolTip({ children, inSubmissionMenuGroup }: SmartReportsTooltipProps) {
  return (
    <Tooltip
      disablePadding
      arrow
      placement="top-start"
      tooltipContent={
        <Box pt={1} sx={{ textAlignLast: 'left' }}>
          <MenuContent menuItems={[inSubmissionMenuGroup]} isMenuOpen closeMenu={() => {}} />
        </Box>
      }
    >
      <Box>{children}</Box>
    </Tooltip>
  );
}
