import { useBoolean, useCurrentUser, useMutateNylasUser } from 'hooks';

export function useRevokeNylasGrant() {
  const [isLoading, { off: stopRevokeGrant, on: startRevokeGrant }] = useBoolean(false);
  const { revokeGrant } = useMutateNylasUser();
  const { me } = useCurrentUser();

  const revokeNylasGrant = async () => {
    startRevokeGrant();
    if (me) {
      await revokeGrant.mutateAsync({ id: me?.id });
    }
    stopRevokeGrant();
  };

  return { revokeNylasGrant, isLoading };
}
