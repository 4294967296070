import theme, { ActionsFooterHeight, DialogFooterElevation } from 'themes';
import BoxSkeleton from 'components/BoxSkeleton';
import Button, { ButtonProps } from 'components/Button';
import { Box, DialogActions, Stack } from 'components/mui-index';

interface DialogFooterProps {
  cancelButton?: ButtonProps;
  proceedButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  disableElevation?: boolean;
  showSkeleton?: boolean;
}

export default function DialogFooter({
  cancelButton,
  proceedButton,
  secondaryButton,
  disableElevation = false,
  showSkeleton,
}: DialogFooterProps) {
  if (!proceedButton && !cancelButton && !showSkeleton) {
    return null;
  }
  const { children: cancelButtonText, ...cancelButtonProps } = cancelButton || {};
  const { children: proceedButtonText, ...proceedButtonProps } = proceedButton || {};
  const { children: secondaryButtonText, ...secondaryButtonProps } = secondaryButton || {};
  return (
    <Stack
      gap={1}
      direction="row"
      component={DialogActions}
      bgcolor="common.white"
      height={theme.spacing(ActionsFooterHeight)}
      boxShadow={disableElevation ? 0 : DialogFooterElevation}
      px={3}
      py={2}
      justifyContent="flex-end"
      zIndex={1}
      sx={{ '*:not(:first-of-type)': { ml: 0 } }}
    >
      {(cancelButton || !!showSkeleton) && (
        <BoxSkeleton width={100} height={40} isLoading={!!showSkeleton}>
          <Box mr={!proceedButton ? 'auto' : 0}>
            <Button variant="text" {...cancelButtonProps}>
              {cancelButtonText}
            </Button>
          </Box>
        </BoxSkeleton>
      )}
      {(secondaryButton || !!showSkeleton) && (
        <BoxSkeleton width={140} height={40} isLoading={!!showSkeleton}>
          <Button variant="outlined" {...secondaryButtonProps}>
            {secondaryButtonText}
          </Button>
        </BoxSkeleton>
      )}
      {(proceedButton || !!showSkeleton) && (
        <BoxSkeleton width={140} height={40} isLoading={!!showSkeleton}>
          <Button variant="contained" {...proceedButtonProps}>
            {proceedButtonText}
          </Button>
        </BoxSkeleton>
      )}
    </Stack>
  );
}
