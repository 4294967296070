const blue600 = '#1A6AF4';
const blueGrey400 = '#B0B5BF';
const nonEssentialText = '#9AA0AD';
const contrastText = '#030C1C';
const primaryText = '#2F3237';
const secondaryText = '#6C7179';
const green500 = '#20B689';
const green600 = '#1B986B';

export const boxBackground = '#F5F5F5';

const colors = {
  typography: {
    contrast: contrastText,
    primary: primaryText,
    secondary: secondaryText,
    nonEssential: nonEssentialText,
    disabled: blueGrey400,
  },
  blue: {
    50: '#F5F9FF',
    100: '#E5EFFF',
    200: '#CCDFFF',
    300: '#A8C8FF',
    400: '#7AABFF',
    500: '#3D84FF',
    600: blue600,
    700: '#0B51CB',
    800: '#083E9B',
    900: '#002D7A',
  },
  green: {
    50: '#F2FDFA',
    100: '#DDF8F2',
    200: '#B4F3E8',
    300: '#7DE8D1',
    400: '#40D4AD',
    500: green500,
    600: green600,
    700: '#007B4C',
    800: '#105B35',
    900: '#003D1E',
  },
  primary: {
    main: blue600,
  },
  secondary: {
    main: green500,
  },
  text: {
    primary: primaryText,
    secondary: secondaryText,
    disabled: blueGrey400,
  },
  common: {
    black: '#101010',
  },
  grey: {
    50: '#F7F7F7',
    100: '#F1F1F1',
    200: '#E3E3E3',
    300: '#C7C7C7',
    400: '#ACACAC',
    500: '#929292',
    600: '#606060',
    700: '#484848',
    800: '#323232',
    900: '#1D1D1D',
  },
  blueGrey: {
    50: '#F8F9FC',
    100: '#EEF1F6',
    200: '#E0E4EB',
    300: '#CACFD8',
    400: blueGrey400,
    500: '#8D929B',
    600: '#5C6067',
    700: '#45494F',
    800: '#2F3237',
    900: '#1A1D22',
  },
  purple: {
    50: '#F6F0FE',
    100: '#E6D8FD',
    200: '#CDB1FC',
    300: '#B48AFA',
    400: '#9B63F8',
    500: '#7F37F6',
    600: '#4407A6',
    700: '#33057E',
    800: '#240458',
    900: '#140231',
  },
  success: {
    main: green600,
  },
  error: {
    main: '#D93921',
    light: '#fdf3f2',
  },
  warning: {
    main: '#F0C60A',
    light: '#FEF9E6',
  },
  info: {
    main: '#F1F1F1',
  },
  coralRed: {
    400: '#ED757B',
    100: '#FCE4EC',
  },
  aquaGreen: {
    400: '#00AA77',
    100: '#E9F3DC',
  },
  goldYellow: {
    400: '#EFD67E',
    100: '#FBF2DD',
  },
  peachOrange: {
    300: '#F9B475',
    100: '#FCE9DA',
  },
  teal: {
    800: '#2C5962',
    400: '#75B7BD',
    200: '#D9E1E3',
    100: '#DCE9EE',
  },
  lilac: {
    600: '#9960D5',
    200: '#EDE2F7',
  },
  indigo: {
    600: '#5659D7',
    200: '#E1E1F8',
  },
  turquoise: {
    400: '#73C9C5',
    100: '#E6F5F5',
  },
  cornflowerBlue: {
    500: '#6799F5',
    100: '#E4EDFD',
  },
  background: {
    default: '#F7FAFA',
    light: '#F8FCFF',
  },
  divider: '#E0E4EB',
  highlight: '#FFFCE6',
};

export default colors;
