import { flatten, union, uniq } from 'lodash';
import { useEffect } from 'react';
import { CoverageLine } from 'enums';
import { PartialSubmission } from 'types';
import { coverageLineConfig } from 'utils';
import { HotjarEvents } from 'utils/hotjar-events';
import useSubmissionFilter from './useSubmissionFilter';
import { SubmissionBoxStorageKeys, SubmissionsViewStorageKeys, SubmissionViewMode } from './utils';

interface useCoverageLineFilterProps {
  submissions: PartialSubmission[];
  areSubmissionsLoaded: boolean;
  paginationReset: () => void;
  mode: SubmissionViewMode;
}
export function useCoverageLineFilter({
  submissions,
  areSubmissionsLoaded,
  paginationReset,
  mode,
}: useCoverageLineFilterProps) {
  const coverageLineFilter = useSubmissionFilter({
    baseFiltersList: [],
    storageKey:
      mode === SubmissionViewMode.General
        ? SubmissionsViewStorageKeys.coverageLine
        : SubmissionBoxStorageKeys.coverageLine,
    hotjarEvent: HotjarEvents.SubmissionsCoverageLineFilter,
    paginationReset,
  });

  const setCoverageLineFilters = coverageLineFilter.setFilters;

  // Update the filter options once we have the submissions from the server
  useEffect(() => {
    // we can't create coverageLinesList until data is loaded so just bail out
    if (!areSubmissionsLoaded || submissions.length === 0) {
      return;
    }

    const coverageLineSetter = (coverageLinesList: string[]) => {
      setCoverageLineFilters((prev) =>
        coverageLinesList.map((coverageLine) => ({
          key: coverageLine,
          checked: prev.find((line) => line.key === coverageLine)?.checked ?? false,
          label: coverageLineConfig[coverageLine as CoverageLine].text,
        })),
      );
    };

    const coverageLinesList = union(
      uniq(flatten(submissions.map((submission) => submission.coverageLines || []))),
    ).sort((a, b) => a.localeCompare(b));

    coverageLineSetter(coverageLinesList);
  }, [areSubmissionsLoaded, submissions, setCoverageLineFilters]);

  return coverageLineFilter;
}
