import { ActivityLogClient } from 'clients/capitola/activity-log';
import { QueryKeys } from 'enums';
import { ActivityLog } from 'types';
import { MutateProps } from './query/types';
import { useMutate } from './query/useMutate';

export function useMutateActivityLog(options?: MutateProps) {
  const { create } = useMutate<ActivityLog, ActivityLog, Partial<ActivityLog>, ActivityLogClient>({
    queryKey: QueryKeys.ActivityLog,
    clientClass: ActivityLogClient,
    cancelInvalidation: options?.cancelInvalidation,
    createOptions: { throwOnError: true },
  });

  return {
    createActivityLog: create.mutateAsync,
  };
}
