import { SubmissionMarketRequestStatus } from 'enums';
import { FilterElementProps } from 'utils';
import { submissionMarketRequestStatusConfig } from 'broker/configuration-mappers/submission-market-request-status-config';

export const getMarketRequestFiltersList = (prevFilterList?: FilterElementProps[]): FilterElementProps[] =>
  Object.values(SubmissionMarketRequestStatus).map((status) => ({
    key: status,
    checked: prevFilterList ? !!prevFilterList.find((filter) => filter.key === status)?.checked : false,
    label: submissionMarketRequestStatusConfig[status].text,
  }));
