import { compact } from 'lodash';
import { CoverageLine } from 'enums';
import { ExtendedMarketRequest, PartialSubmission, SubmissionMarket } from 'types';
import { coverageLineConfig } from 'utils';

export function convertCoverageLinesTextToEnum(coverageLines: string[]) {
  return compact(
    coverageLines.map((coverageLine) =>
      Object.keys(coverageLineConfig).find((key) => {
        if (coverageLineConfig[key as CoverageLine].text.toLowerCase() === coverageLine.toLowerCase()) {
          return key as CoverageLine;
        }
        return false;
      }),
    ),
  ) as CoverageLine[];
}

export function submissionMarketsToExtendedMarketRequests(
  submissionMarkets: SubmissionMarket[],
  submission?: PartialSubmission | null,
): ExtendedMarketRequest[] {
  return submissionMarkets
    .map((market) =>
      market.marketRequests.map((marketRequest) => ({
        ...marketRequest,
        market,
        submissionCoverageLines: submission?.coverageLines,
      })),
    )
    .flat();
}
