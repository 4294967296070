import { Layer } from 'types';
import InternalLink from 'components/InternalLink';
import ADMIN_ROUTES from 'admin/admin-routes';

export interface LayerLinkProps {
  layer: Pick<Layer, 'id' | 'attachmentPoint'>;
}

export default function LayerLink({ layer }: LayerLinkProps) {
  return <InternalLink to={ADMIN_ROUTES.getLayerById(layer.id)}>{layer.attachmentPoint || 'primary'}</InternalLink>;
}
