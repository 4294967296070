import { FormFieldsConfigProps } from 'hooks';
import { Organization, User } from 'types';

export interface AddAgentToAgencyMetadata {
  organization: Organization;
  existingUser?: User;
}

export interface AddAgentToAgencyDialogLocationState {
  organizationId: string;
  organizationName: string;
}

export interface AgentFormDataProps {
  firstName: string;
  lastName: string;
  email: string;
  title?: string;
  phoneNumber?: string | null;
}

export enum AgentFormFieldsNames {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Title = 'title',
}

export type FormFieldsConfig = Record<AgentFormFieldsNames, FormFieldsConfigProps<AgentFormFieldsNames>>;
