import { unionBy } from 'lodash';
import { SubmissionMarket, UserMarket } from 'types';
import { contactFullName } from 'utils';

interface FilteredContactsProps {
  search: { value: string; debouncedValue: string };
  submissionMarketContactIds: Set<string>;
  submissionMarket: SubmissionMarket;
  userMarket?: UserMarket;
}

export default function getFilteredContacts({
  search,
  submissionMarket,
  userMarket,
  submissionMarketContactIds,
}: FilteredContactsProps) {
  const availableContacts = unionBy(userMarket?.contacts || [], submissionMarket?.contacts || [], 'id').sort(
    (a, b) => Number(submissionMarketContactIds.has(b.id)) - Number(submissionMarketContactIds.has(a.id)),
  );

  return (
    availableContacts.filter((contact) =>
      [contact.email, contactFullName(contact)].some((part) =>
        part.toLowerCase().includes(search.debouncedValue.toLowerCase()),
      ),
    ) ?? []
  );
}
