import { Transition } from 'history';
import { Box } from '@common-components';
import { messages } from 'i18n';
import EmailEditor from 'broker/components/EmailEditor';
import EmailEditorFooter from 'broker/components/EmailEditor/EmailEditorFooter';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { useBeforeEmailEditorNavigatingInInnerRoute } from 'broker/components/EmailEditor/useBeforeEmailEditorNavigatingInInnerRoute';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { ProposalFlowStep, StepProps } from './types';

export default function EmailStepContent({
  isDirty,
  setIsDirty,
  onStepChange,
  handleSaveProposal,
  selectedFiles,
}: StepProps) {
  const { emailStep } = messages.submissionWorkspace.tabs.proposal;
  const { isNavigatingToInnerEmailRoute } = useBeforeEmailEditorNavigatingInInnerRoute();

  const {
    draftPreview: { body: draftBody, subject: draftSubject },
  } = useEmailEditorState();

  const saveProposal = async () => {
    await handleSaveProposal({
      subject: draftSubject,
      body: draftBody,
      contactIds: [],
      attachedFiles: selectedFiles.map((file) => file.id),
    });
  };

  const beforeNavigation = async (a: Transition) => {
    if (await isNavigatingToInnerEmailRoute(a)) {
      return true;
    }
    await saveProposal();
    return true;
  };

  const emailStepMessages = messages.submissionWorkspace.tabs.proposal.emailStep;

  return (
    <InnerPageLayout
      outerDirtyState={{ isDirty, setIsDirty }}
      beforeNavigation={beforeNavigation}
      title={emailStep.header}
      footer={(innerPageProps) => (
        <EmailEditorFooter
          {...innerPageProps}
          backButtonAction={async () => {
            await onStepChange(ProposalFlowStep.Files, {
              subject: draftSubject,
              body: draftBody,
              contactIds: [],
              attachedFiles: selectedFiles.map((file) => file.id),
            });
          }}
          backButtonText={messages.buttons.backToFiles}
          successMessage={emailStepMessages.successMessage}
          proceedButtonText={emailStepMessages.submitButton}
          onAfterSent={innerPageProps.onClose}
          secondaryButtonText={messages.buttons.saveForLater}
          secondaryButtonAction={async () => {
            innerPageProps.onClose();
          }}
        />
      )}
    >
      {() => (
        <Box position="relative" height={1}>
          <EmailEditor />
        </Box>
      )}
    </InnerPageLayout>
  );
}
