import { Button, Divider, Stack, Typography } from '@common-components';
import { FormMode, LayerStatus } from 'enums';
import { messages } from 'i18n';
import { Quote, SubmissionMarket } from 'types';
import { layerTitle } from 'utils';

import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { QuoteFileKey, quoteFileKeyConfig } from './quotesAndPoliciesConfig';

interface QuoteHeaderProps {
  quote: Quote;
  fileType: QuoteFileKey;
  market?: SubmissionMarket;
}

export default function QuoteHeader({ quote, fileType, market }: QuoteHeaderProps) {
  const navigate = useNavigate();

  const canEdit = () => {
    switch (fileType) {
      case 'binderFileIds':
        return quote.layer.status === LayerStatus.Bound;
      case 'policyFileIds':
        return quote.layer.status === LayerStatus.PolicyIssued;
      default:
        return false;
    }
  };

  const isEditMode = canEdit();

  const handleViewQuote = () => {
    const mode = isEditMode ? FormMode.edit : FormMode.view;
    switch (fileType) {
      case 'binderFileIds':
      case 'policyFileIds':
        navigate(`${BROKER_NESTED_ROUTES.WORKSPACE.POLICY}`, {
          routeKey: RouteKey.Quote,
          state: {
            selectedMarket: market!,
            fileType,
            mode,
            selectedQuote: quote,
          },
        });
        break;
      default:
    }
  };

  return (
    <Stack className="cap-quote-header" direction="row" alignItems="center" gap={1} sx={{ px: 2 }} height={56}>
      <Typography sx={{ color: 'common.black', textTransform: 'uppercase' }} variant="body1" noWrap>
        {layerTitle(quote.layer?.attachmentPoint)}
      </Typography>

      <div>
        <Divider flexItem orientation="vertical" sx={{ py: 0.9, borderColor: 'common.black' }} />
      </div>

      <Typography color="textSecondary" flex={1} variant="body2" noWrap>
        {quoteFileKeyConfig[fileType].title}
      </Typography>
      {market && (
        <Button onClick={handleViewQuote}>{isEditMode ? messages.buttons.viewAndUpdate : messages.buttons.view}</Button>
      )}
    </Stack>
  );
}
