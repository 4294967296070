import { Divider, Stack, Typography } from '@common-components';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@icons';
import { messages } from 'i18n';
import { iconFontSize } from 'themes';
import TemplateIcon from 'broker/components/common/TemplateIcon';

const TemplateTagStyles = {
  maxWidth: 1,
  px: 2,
  py: 1,
  alignItems: 'center',
} as const;

interface TemplateTagProps {
  selectedTemplateName: string;
  disabled?: boolean;
}

export default function TemplateTag({ selectedTemplateName, disabled }: TemplateTagProps) {
  return (
    <Stack direction="row" gap={1} sx={{ ...TemplateTagStyles, backgroundColor: disabled ? 'grey.100' : 'inherit' }}>
      <TemplateIcon />
      <Typography variant="captionBold" color="text.secondary" noWrap>
        {messages.emailEditor.template}
      </Typography>

      <Divider flexItem orientation="vertical" />

      <Typography
        noWrap
        variant="caption"
        color={disabled ? 'text.secondary' : 'primary'}
        sx={{ minWidth: 80, fontWeight: 'bold' }}
      >
        {selectedTemplateName}
      </Typography>
      <KeyboardArrowDownIcon
        color={disabled ? 'disabled' : 'primary'}
        sx={{ fontSize: iconFontSize, height: 16, width: 16 }}
      />
    </Stack>
  );
}
