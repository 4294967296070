import { compact } from 'lodash';
import { LoaderOverlay, Stack, Typography } from '@common-components';
import { BoxTemplateLabels, FileType } from 'enums';
import { useSearchBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { Quote } from 'types';
import { getFileMetadataField } from 'utils/files-utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { FileListItem } from './FileListItem';

export interface QuotesListProps {
  quotes: Quote[];
  expiringPolicyFileId?: string;
}

export function QuotesList({ quotes, expiringPolicyFileId }: QuotesListProps) {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { boxFolderId } = partialSubmission!;

  const { items, isLoading: boxItemsLoading } = useSearchBoxItems({ filter: { chunkSize: 20, folderId: boxFolderId } });
  // filter out only market quotes
  const filteredItems = items.filter(
    (item) => getFileMetadataField(item, BoxTemplateLabels.FileType) === FileType.MarketQuote,
  );
  const expiringPolicyFile = items.find((item) => item.id === expiringPolicyFileId);

  if (boxItemsLoading) {
    return (
      <Stack>
        <LoaderOverlay text={messages.quoteComparisonPage.compose.loadingQuotesList} />
      </Stack>
    );
  }

  const getQuoteFiles = (quote: Quote): { fileName: string; FileId: string }[] => {
    const quoteFiles = quote.marketQuoteFileIds.map((fileId) => {
      const file = filteredItems.find((item) => item.id === fileId);
      if (!file) {
        return null;
      }
      return { fileName: file?.name ?? '', FileId: fileId };
    });
    return compact(quoteFiles);
  };

  return (
    <Stack gap={0.5}>
      <Typography variant="body2Bold" component="div" mb={1}>
        {messages.quoteComparisonPage.compose.filesListTitle}
      </Typography>
      {quotes.map((quote) => (
        <Stack direction="row" gap={1} key={quote.id} whiteSpace="nowrap">
          <Typography variant="body2">{`- ${quote.marketName}`}</Typography>
          {getQuoteFiles(quote).map((file) => (
            <FileListItem key={file.FileId} file={file} />
          ))}
        </Stack>
      ))}
      {expiringPolicyFile && (
        <Stack direction="row" gap={1} whiteSpace="nowrap">
          <Typography variant="body2">{`- ${messages.quoteComparisonPage.compose.expiringPolicy}`}</Typography>
          <FileListItem file={{ fileName: expiringPolicyFile.name, FileId: expiringPolicyFile.id }} />
        </Stack>
      )}
    </Stack>
  );
}
