import { MouseEvent, useCallback, useMemo, useState } from 'react';

export interface OptionsMenuState {
  isMenuOpen: boolean;
  closeMenu: () => void;
  openMenu: (event: MouseEvent<HTMLElement> | undefined) => void;
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchor: HTMLElement | null) => void;
}

export function useOptionsMenu(): OptionsMenuState {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const openMenu = useCallback((event?: MouseEvent<HTMLElement>) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return useMemo(
    () => ({ anchorEl, isMenuOpen, openMenu, closeMenu, setAnchorEl }),
    [anchorEl, closeMenu, isMenuOpen, openMenu, setAnchorEl],
  );
}
