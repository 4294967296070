import { map } from 'lodash';
import { useInvalidateUserMarketCache, useMutateContact, useMutateUserMarket } from 'hooks';
import { ContactCreate, UserMarket } from 'types';
import useSubmissionsWorkspace from './useSubmissionWorkspace';

export const useContactActions = () => {
  const { createContact, updateContact } = useMutateContact();
  const { updateUserMarket } = useMutateUserMarket();
  const invalidateUserMarketCache = useInvalidateUserMarketCache();

  const { markets: submissionMarkets, addContactsToSubmissionMarket, reFetchWorkspace } = useSubmissionsWorkspace();

  async function invalidateMarkets() {
    await Promise.all([await invalidateUserMarketCache(), await reFetchWorkspace()]);
  }

  const editUserMarketContact = async (userMarketId: string, contactId: string, contactData: ContactCreate) => {
    const submissionMarket = submissionMarkets.find((market) => market.userMarketId === userMarketId);
    if (!submissionMarket) {
      throw new Error('Submission market not found to replace changed contact');
    }

    const updatedContact = await updateContact.mutateAsync({
      id: contactId,
      data: contactData,
      fetchOptions: { throwOnError: true },
    });
    if (!updatedContact) {
      throw new Error('Editing contact failed');
    }

    await invalidateMarkets();
  };

  const addContactToUserMarket = async (userMarket: UserMarket, contactToAdd: ContactCreate) => {
    const addedContact = (await createContact.mutateAsync({
      data: contactToAdd,
      fetchOptions: { throwOnError: true },
    }))!;

    const userMarketContactIds = map(userMarket.contacts, 'id');
    await updateUserMarket.mutateAsync({
      id: userMarket.id,
      data: { contactIds: [...userMarketContactIds, addedContact.id] },
    });
    await addContactsToSubmissionMarket(userMarket.id, [addedContact]);
  };

  return { addContactToUserMarket, editUserMarketContact };
};
