import { BrowserHistory, createBrowserHistory, Update } from 'history';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { BrowserRouterProps, Router } from 'react-router-dom-latest';
import { NavigationContext } from 'contexts';

// Our own version of BrowserRouter, based on the implementation of React Routers BrowserRouter:
// https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/index.tsx#L133-L160
// The main change is that this implementation exposes the history object,
// so we can later use it to do things like blocking navigation on dirty state.
// Based on this SO thread: https://stackoverflow.com/questions/69871987/react-router-v6-navigate-outside-of-components/70000286#70000286
export default function BrowserRouter({ children, ...props }: BrowserRouterProps) {
  const historyRef = useRef<BrowserHistory>(createBrowserHistory({ window }));
  const history = historyRef.current;

  const historyObject = useMemo(() => ({ history }), [history]);

  const [locationState, setLocationState] = useState<Update>({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setLocationState), [history]);

  return (
    <NavigationContext.Provider value={historyObject}>
      <Router {...props} location={locationState.location} navigationType={locationState.action} navigator={history}>
        {children}
      </Router>
    </NavigationContext.Provider>
  );
}
