import { ReactElement } from 'react';
import Dialog, { DialogProps } from 'components/Dialog/Dialog';
import { DirtyDialogGuard, DirtyDialogGuardAttributes } from 'broker/components/DirtyContentGuard';
import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import { useNavigateBack } from 'broker/hooks';
import { LocationStateEntries } from 'broker/types';

export interface BaseRoutedDialogContentProps
  extends Omit<DirtyDialogGuardAttributes, 'setIsContentDirty' | 'closeAttempt'> {
  ariaLabelId: string;
  setDialogIsDirty: (isDirty: boolean) => void;
  onClose: () => void;
}

export interface RoutedDialogContentProps<TRouteKey extends RouteKey> extends BaseRoutedDialogContentProps {
  locationState: LocationStateEntries[TRouteKey];
}

interface RoutedDialogProps<TRouteKey extends RouteKey>
  extends Omit<DialogProps, 'open' | 'onClose' | 'aria-labelledby' | 'children'> {
  ariaLabelId: string;
  children: (props: RoutedDialogContentProps<TRouteKey>) => ReactElement;
  routeKey: TRouteKey;
  isLocationStateRequired: boolean;
}

export default function RoutedDialog<TRouteKey extends RouteKey>({
  ariaLabelId,
  children,
  routeKey,
  isLocationStateRequired,
  ...dialogProps
}: RoutedDialogProps<TRouteKey>) {
  const navigateBack = useNavigateBack();

  const onClose = () => {
    navigateBack();
  };

  return (
    <DirtyDialogGuard onClose={onClose}>
      {({ setIsContentDirty, closeAttempt, ...dirtyContentGuardAttributes }) => (
        <Dialog open onClose={closeAttempt} aria-labelledby={ariaLabelId} {...dialogProps}>
          <WithRouteLocationState routeKey={routeKey} isStateRequired={isLocationStateRequired}>
            {(locationState) =>
              children({
                ariaLabelId,
                setDialogIsDirty: setIsContentDirty,
                onClose: closeAttempt,
                locationState,
                ...dirtyContentGuardAttributes,
              })
            }
          </WithRouteLocationState>
        </Dialog>
      )}
    </DirtyDialogGuard>
  );
}
