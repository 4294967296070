export const messages = {
    emailVariables: {
        continueInFlow: 'Continue in Flow',
        openQuoteInFlow: 'Open quote in Flow',
        here: 'here',
    },
    emailLabels: {
        Acknowledgment: 'Acknowledgment',
        Declination: 'Declination',
        InformationRequest: 'Information Request',
        Quote: 'Quote',
        Indication: 'Indication',
        LossRuns: 'Loss Runs',
        SubmissionBox: 'Submission Email',
        Policy: 'Policy',
        Binder: 'Binder',
        SurplusDocuments: 'Surplus Documents',
        Correspondence: 'Correspondence',
        SubmissionMarketing: 'Marketing',
        Proposal: 'Proposal',
        TowerUpdate: 'Tower Update',
        MarketingUpdate: 'Marketing Update',
        BinderRequested: 'Binder Order',
        PolicyRequested: 'Policy Requested',
        SubmissionUpdate: 'Market Submission Update',
        CustomerFollowup: 'Customer Follow-up',
        MarketFollowup: 'Market Follow-up',
        CannotPursue: 'Can Not Pursue',
        PositiveExpectation: 'Positive Expectation',
        AdditionalInfo: 'Additional Information',
    },
    fileTypesLabels: {
        ExpiringProgram: 'Expiring Policy',
        Submission: 'Application',
        MarketQuote: 'Market Quote',
        CapitolaQuote: 'Flow Quote',
        MarketBinder: 'Market Binder',
        CapitolaBinder: 'Flow Binder',
        MarketingReport: 'Marketing Report',
        ConceptualTower: 'Conceptual Tower',
        Policy: 'Market Policy',
        FlowPolicy: 'Flow Policy',
        PolicyCoverLatter: 'Policy Cover Letter',
        CustomerEmail: 'Customer Email',
        MarketingEmail: 'Marketing Email',
        PastApplication: 'Application - Prior',
        LossRuns: 'Loss Runs',
        Supplement: 'Supporting documents',
        Proposal: 'Proposal',
        ReplyEmail: 'Reply Email',
        InboundEmail: 'Inbound Email',
        RansomwareApplication: 'Ransomware Application',
        MarketInvoice: 'Market Invoice',
        CapitolaInvoice: 'Flow Invoice',
        Financials: 'Financials',
        SignedApplication: 'Signed Application',
        DueDiligence: 'Affidavit / Due Diligence',
        Declination: 'Declination',
        OtherSurplusForms: 'Other Surplus Lines Forms',
        PolicySpecimen: 'Policy Specimen',
        Endorsement: 'Endorsement',
        BOR: 'BOR',
        QuoteComparison: 'Quote Comparison',
        Subjectivities: 'Subjectivity(s)',
        NoLossLetter: 'No Loss Letter',
        CarrierSurplusForm: 'Carrier Surplus Form',
        AOR: 'AOR',
    },
    notifications: {
        type: {
            followUp: 'Follow up',
            proposal: 'Proposal',
            acknowledgment: 'Acknowledgement',
            marketingUpdate: 'Marketing Update',
        },
        assignee: {
            agent: 'Agent',
            underwriter: 'Underwriter',
        },
    },
};
