import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@common-components';
import { Suggestion } from 'types';
import FormArrayFields from 'components/hookFormComponents/FormArrayFields';
import { FormArrayFieldProps } from 'components/hookFormComponents/FormArrayFields/FormArrayFields';
import mapHeraldParameterToFormComponent from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/mappers/map-herald-parameter-to-form-component';
import {
  FormStateBaseProps,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';

export interface BaseHeraldFormArrayFieldsProps {
  heraldNormalizedParameter: HeraldNormalizedParameter;
  onBlur: () => boolean | undefined;
  suggestion?: Suggestion;
}

export interface BaseHeraldFormFieldsValue {
  main: any;
  children?: any[];
}
export const defaultHeraldAddressArrayValue: BaseHeraldFormFieldsValue = {
  main: {
    line1: '',
    line2: '',
    line3: '',
    organization: '',
    city: '',
    state: '',
    postal_code: '',
    country_code: '',
  },
};

export const defaultHeraldClaimEventArrayValue = {
  main: {
    description: '',
    date: '',
    amount: '',
  },
};

interface HeraldFormArrayFieldsProps extends BaseHeraldFormArrayFieldsProps, FormArrayFieldProps {}

const HeraldFormArrayFields: FC<HeraldFormArrayFieldsProps> = ({
  getFieldName,
  heraldNormalizedParameter,
  index,
  onBlur,
  suggestion,
}: HeraldFormArrayFieldsProps) => {
  const { getValues } = useFormContext();
  const value = getValues(getFieldName('')) as FormStateBaseProps;
  const parentJsx = mapHeraldParameterToFormComponent(
    heraldNormalizedParameter,
    value,
    {
      name: getFieldName(''),
      label: heraldNormalizedParameter.fieldLabel,
    },
    onBlur,
    true,
    index === 0 ? suggestion : undefined,
  );

  const arrayElement = heraldNormalizedParameter.arrayElements![index];

  const childrenJSx = arrayElement?.childValues?.map((childValue) =>
    // if a repeated question has a repeated child question, then render FormArrayFields for the child question
    childValue.arrayElements ? (
      <FormArrayFields
        key={childValue.parameter_id}
        name={getFieldName(`children.${childValue.parameter_id}`)}
        defaultValue={{}}
        FormArrayFieldComponent={HeraldFormArrayFields as FC<FormArrayFieldProps>}
        additionalProps={{ heraldNormalizedParameter: childValue } as BaseHeraldFormArrayFieldsProps}
      />
    ) : (
      mapHeraldParameterToFormComponent(
        childValue,
        value,
        {
          name: getFieldName(`children.${childValue.parameter_id}`),
          label: childValue.fieldLabel,
        },
        onBlur,
        true,
      )
    ),
  );

  return (
    <Stack>
      {parentJsx}
      {childrenJSx || null}
    </Stack>
  );
};

export default HeraldFormArrayFields;
