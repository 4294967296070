import { Stack, Typography } from '@common-components';
import { EmailTemplate } from 'types';

const TemplateContainerStyles = (isSelected = false) =>
  ({
    border: 0,
    borderBottom: 1,
    borderBottomColor: 'divider',
    '&:first-of-type': {
      borderTop: 1,
      borderTopColor: 'divider',
    },
    p: 2,
    cursor: 'pointer',
    backgroundColor: isSelected ? 'grey.100' : 'common.white',
    alignItems: 'stretch',
    gap: 2,
    position: 'relative',
    transition: 'all 300ms',
    '&:before': {
      content: '""',
      position: 'absolute',
      height: 'calc(100% + 2px)',
      width: 4,
      backgroundColor: isSelected ? 'green.400' : 'transparent',
      top: -1,
      left: 0,
    },
  } as const);

interface EmailTemplateCardProps {
  template: EmailTemplate;
  onClick?: (emailTemplate: EmailTemplate) => void;
  isSelected?: boolean;
}

export default function EmailTemplateCard({ template, isSelected, onClick }: EmailTemplateCardProps) {
  const onCardClick = () => onClick?.(template);

  return (
    <Stack component="button" onClick={onCardClick} sx={TemplateContainerStyles(isSelected)}>
      <Typography sx={{ textAlign: 'left', color: isSelected ? 'common.black' : 'grey.900' }}>
        {template.name}
      </Typography>
    </Stack>
  );
}
