import { Stack } from '@common-components';
import { CopilotSteps, PartialSubmission, SubmissionMarket, UserDetails } from 'types';
import CollapseItem from './CollapseItem';
import { copilotStepsConfig } from './config';
import { valueToIsCollapseActive } from './utils';

export default function MainCollapse({
  value,
  submission,
  submissionMarkets,
  selectedUser,
}: {
  value: number;
  submission: PartialSubmission;
  submissionMarkets: SubmissionMarket[];
  selectedUser?: UserDetails;
}) {
  return (
    <Stack gap={2}>
      {Object.values(CopilotSteps).map((key) => (
        <CollapseItem
          key={key}
          {...copilotStepsConfig(`${selectedUser?.firstName || ''} ${selectedUser?.lastName || ''}`)[key]}
          active={valueToIsCollapseActive(value, key)}
          submission={submission}
          submissionMarkets={submissionMarkets}
        />
      ))}
    </Stack>
  );
}
