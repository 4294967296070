import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { To, useNavigate as useRouterNavigate } from 'react-router-dom-latest';
import { RouteKey } from 'broker/enums';
import { LocationStateEntries } from 'broker/types';
import { useLocation } from './useLocation';
import { NavigateOptions } from './useNavigate';

export interface StepNavigateOptions extends Omit<NavigateOptions, 'state'> {
  state?: Partial<LocationStateEntries[RouteKey]>;
}

export function useNavigateToStep() {
  const location = useLocation();
  const navigate = useRouterNavigate();

  return useCallback(
    (to: To, navigateOptions: StepNavigateOptions) => {
      const { routeKey, state } = navigateOptions;

      // preserve state
      return navigate(
        { ...(typeof to === 'object' ? (to as object) : { ...location, pathname: to }) },
        {
          ...navigateOptions,
          state: {
            ...location.state,
            [routeKey]: {
              ...location.state?.[routeKey],
              ...state,
            },
          },
        },
      );
    },
    [location, navigate],
  );
}
