import { useState } from 'react';
import { useNavigate } from 'react-router-dom-latest';
import { ActionDialog, CircularProgress, DialogTextField, Stack, Typography } from '@common-components';
import { useMutateTeam } from 'hooks';
import { messages } from 'i18n';
import { Organization } from 'types';
import ADMIN_ROUTES from 'admin/admin-routes';

enum TeamCreateSteps {
  Init,
  Loading,
  TeamCreated,
}
export interface TeamCreateDialogProps {
  organization: Organization;
  open: boolean;
  onClose: () => void;
}

const { loadingMessage, teamCreatedSuccessfully, enterNewTeam } = messages.admin.teamCreatedDialog;

export default function TeamCreateDialog({ organization, open, onClose }: TeamCreateDialogProps) {
  const navigate = useNavigate();
  const { createTeam } = useMutateTeam();
  const [activeStep, setActiveStep] = useState<TeamCreateSteps>(TeamCreateSteps.Init);

  const [name, setName] = useState<string | null>(null);
  const [teamId, setTeamId] = useState<string | null>(null);

  const enabled = !!name && activeStep !== TeamCreateSteps.Loading;

  const onCreate = async () => {
    setActiveStep(TeamCreateSteps.Loading);
    const team = await createTeam.mutateAsync({
      data: {
        name: name!,
        organizationId: organization.id,
      },
    });

    if (team) {
      setTeamId(team.id);
    }
    setActiveStep(TeamCreateSteps.TeamCreated);
  };

  const renderStep = () => {
    switch (activeStep) {
      case TeamCreateSteps.Init: {
        return (
          <ActionDialog
            actionLabel="Create"
            enabled={enabled}
            open={open}
            onClose={onClose}
            onAction={onCreate}
            title="Create Team"
          >
            <DialogTextField placeholder="Name" setValue={setName} value={name} autoFocus />
          </ActionDialog>
        );
      }
      case TeamCreateSteps.Loading: {
        return (
          <Stack sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
            {loadingMessage}
            <CircularProgress />
          </Stack>
        );
      }
      case TeamCreateSteps.TeamCreated: {
        return <Typography variant="h6">{!!teamId && teamCreatedSuccessfully}</Typography>;
      }
      default:
        return null;
    }
  };

  return (
    <ActionDialog
      actionLabel={activeStep === TeamCreateSteps.Init ? 'Create' : enterNewTeam}
      enabled={enabled}
      open={open}
      onClose={onClose}
      onAction={activeStep === TeamCreateSteps.Init ? onCreate : () => navigate(ADMIN_ROUTES.getTeamById(teamId ?? ''))}
      title="Create Team"
    >
      {renderStep()}
    </ActionDialog>
  );
}
