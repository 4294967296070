import { MENTION_PREFIX } from '@common/email-variables';
import { BoxItem, SharedLink } from 'box-ui-elements/es';
import { useCallback } from 'react';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';
import {
  appendAttachments,
  resolveHtml,
  resolveText,
} from 'broker/utils/email-utils/email-resolver-utils/email-resolving-utils';
import { EmailTemplateVariablesMap } from 'broker/utils/email-utils/email-resolver-utils/template-variables-utils';
import { EmailTemplateContext } from './store/types';

interface UseResolveDraftProps {
  templateContextData: EmailTemplateContext;
  attachedFiles: BoxItem[];
}

export type ResolveForSend = (
  subject: string,
  body: string,
  recipientGroup: RecipientGroup,
  attachmentsArchive: SharedLink | null,
) => { body: string; subject: string };

export default function useResolveDraft({ templateContextData, attachedFiles }: UseResolveDraftProps) {
  const resolveForNoRecipientPreview = useCallback(
    (subject: string, body: string) => ({
      subject: resolveText(subject, templateContextData, EmailTemplateVariablesMap, MENTION_PREFIX),
      body: resolveHtml(body, templateContextData, EmailTemplateVariablesMap, MENTION_PREFIX),
    }),
    [templateContextData],
  );

  const resolveForEditForAll = useCallback(
    (subject: string, body: string) => ({
      subject: resolveText(subject, templateContextData, EmailTemplateVariablesMap, MENTION_PREFIX, undefined, true),
      body: resolveHtml(body, templateContextData, EmailTemplateVariablesMap, MENTION_PREFIX, undefined, true),
    }),
    [templateContextData],
  );

  const resolveForPreview = useCallback(
    (subject: string, body: string, recipientGroup: RecipientGroup, resolveSmartObject = false) => {
      const templateContext = {
        ...templateContextData,
        recipient: recipientGroup,
      };

      return {
        subject: resolveText(
          subject,
          templateContext,
          EmailTemplateVariablesMap,
          MENTION_PREFIX,
          undefined,
          undefined,
          resolveSmartObject,
        ),
        body: resolveHtml(
          body,
          templateContext,
          EmailTemplateVariablesMap,
          MENTION_PREFIX,
          undefined,
          undefined,
          resolveSmartObject,
        ),
      };
    },
    [templateContextData],
  );

  const resolveForSend = useCallback(
    (subject: string, body: string, recipientGroup: RecipientGroup, attachmentsArchive: SharedLink | null) => {
      // eslint-disable-next-line prefer-const
      let { body: resolvedBody, subject: resolvedSubject } = resolveForPreview(subject, body, recipientGroup, true);

      if (attachmentsArchive) {
        resolvedBody = appendAttachments(resolvedBody, attachedFiles, attachmentsArchive);
      }

      return {
        body: resolvedBody,
        subject: resolvedSubject,
      };
    },
    [attachedFiles, resolveForPreview],
  );

  return { resolveForPreview, resolveForNoRecipientPreview, resolveForEditForAll, resolveForSend };
}
