import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { Backdrop, Box, Chip, Divider, Grid, Stack } from '@common-components';
import { LabelOutlined as LabelOutlinedIcon } from '@icons';
import { useBoolean, useFormProvider, useHotjar, useMutateUserMarket, useToast } from 'hooks';
import { messages } from 'i18n';
import { HotjarEvents } from 'utils/hotjar-events';
import { FormMultiSelectAutocomplete } from 'components/hookFormComponents/FormMultiSelectAutocomplete';
import { DirtyActionsGuardAttributes, withDirtyActionsGuard } from 'broker/components/DirtyContentGuard';
import { formFieldsConfig, FormFieldsNames } from 'broker/dialogs/UserMarketDialog/utils';
import useUserMarketCategories from 'broker/hooks/useUserMarketCategories';
import MarketInfoSectionHeader from './MarketInfoSectionHeader';

interface FormData {
  categories: string[];
}

interface MarketInfoCategoriesSectionProps {
  userMarketId: string;
  initValue: string[];
  isLoading: boolean;
}

const schema = yup.object().shape({
  text: yup.string().trim().nullable(),
});

const title = messages.submissionWorkspace.dialogs.marketDetails.categories;

function MarketInfoCategoriesSection({
  userMarketId,
  initValue,
  isLoading,
  setIsContentDirty,
  isContentDirty,
  closeAttemptWithAction,
  closeWithoutPromptWithAction,
}: MarketInfoCategoriesSectionProps & DirtyActionsGuardAttributes) {
  const { updateUserMarket } = useMutateUserMarket();

  const { existingCategories } = useUserMarketCategories();

  const [isEdit, { on: startEdit, off: stopEdit }] = useBoolean(false);

  const { showToast } = useToast();
  const hotjar = useHotjar();

  const onCancel = () => {
    closeAttemptWithAction(stopEdit);
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    await updateUserMarket.mutateAsync({ id: userMarketId, data });
    showToast('success', { message: `${title} ${messages.general.savedSuccessfully}` });
    closeWithoutPromptWithAction(stopEdit);
  };

  const { methods } = useFormProvider<FormData>({
    schema,
    setIsDirty: setIsContentDirty,
    defaultValues: { categories: initValue },
  });

  const onEditClick = () => {
    methods.reset({ categories: initValue });
    startEdit();

    hotjar.event(HotjarEvents.EditMarketCategories);
  };

  const getContent = () => {
    if (isEdit) {
      return (
        <Box display="flex">
          <FormMultiSelectAutocomplete
            options={existingCategories.map((category) => ({ label: category, value: category }))}
            hideOptionalLabel
            freeSolo
            disableBorder
            defaultValue={initValue}
            {...formFieldsConfig[FormFieldsNames.Categories]}
            createOption={(value) => ({ label: `${messages.buttons.add}: ${value}`, value })}
          />
        </Box>
      );
    }

    return (
      <Box display="flex" onClick={onEditClick}>
        <Grid container height={1} overflow="hidden" padding={2} spacing={1}>
          {initValue.map((category) => (
            <Grid item key={category}>
              <Chip label={category} color="secondary" disabled />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <FormProvider {...methods}>
      <Backdrop invisible open={isEdit} sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }} onClick={onCancel} />
      <Stack
        zIndex={isEdit ? 'drawer' : 'inherit'}
        border={1}
        borderColor={isEdit ? 'primary.main' : 'divider'}
        borderRadius={2}
      >
        <MarketInfoSectionHeader
          title={title}
          Icon={LabelOutlinedIcon}
          isContentDirty={isContentDirty}
          isEdit={isEdit}
          isSubmitting={methods.formState.isSubmitting}
          isLoading={isLoading}
          onCancel={onCancel}
          onEdit={onEditClick}
          onSubmit={methods.handleSubmit(onSubmit)}
        />

        <Divider />

        {getContent()}
      </Stack>
    </FormProvider>
  );
}

export default withDirtyActionsGuard(MarketInfoCategoriesSection);
