import { groupBy, isEmpty, keyBy, map, merge, pickBy } from 'lodash';
import { useEffect } from 'react';
import { useCurrentUser } from 'hooks';
import { PartialSubmission, User } from 'types';
import { FilterElementProps } from 'utils';
import { HotjarEvents } from 'utils/hotjar-events';
import useSubmissionFilter from './useSubmissionFilter';
import { SubmissionBoxStorageKeys, SubmissionsViewStorageKeys, SubmissionViewMode } from './utils';

interface useAssigneeFilterProps {
  submissions: PartialSubmission[];
  areSubmissionsLoaded: boolean;
  teamMembers: User[];
  paginationReset: () => void;
  mode: SubmissionViewMode;
}

export function useAssigneeFilter({
  submissions,
  areSubmissionsLoaded,
  teamMembers,
  paginationReset,
  mode,
}: useAssigneeFilterProps) {
  const { me } = useCurrentUser();

  const assigneeFilter = useSubmissionFilter({
    baseFiltersList: [],
    storageKey:
      mode === SubmissionViewMode.General ? SubmissionsViewStorageKeys.assignee : SubmissionBoxStorageKeys.assignee,
    hotjarEvent: HotjarEvents.SubmissionsAssigneeFilter,
    paginationReset,
  });

  const { setFilters: setAssigneeFilters } = assigneeFilter;

  // Update the filter options once we have the submissions from the server
  useEffect(() => {
    // we can't create submissionsByAssignee until data is loaded so just bail out
    if (!areSubmissionsLoaded || submissions.length === 0) {
      return;
    }

    const assigneeFilterSetter = () => {
      const submissionsByAssignee = groupBy(submissions, ({ assignee }) => assignee.id);

      setAssigneeFilters((prev) => {
        const members: FilterElementProps[] = teamMembers.map((teamUser) => ({
          key: teamUser.id,
          checked: false,
          label: `${teamUser.firstName} ${teamUser.lastName}`,
        }));

        const isChecked = (teamUser: FilterElementProps) => {
          const noPersistedFilter = isEmpty(prev);

          if (noPersistedFilter) {
            return teamUser.key === me?.id ? !isEmpty(submissionsByAssignee[teamUser.key]) : false;
          }

          return prev.find((line) => line.key === teamUser.key)?.checked ?? false;
        };

        const membersByKey = keyBy(members, 'key');

        // filter the existing filters to only keep the ones that are still in the team
        const prevByKey = pickBy(keyBy(prev, 'key'), (filter) => !!membersByKey[filter.key]);

        const mergedMembers = merge(membersByKey, prevByKey);

        const newAssigneeFilters: FilterElementProps[] = map(mergedMembers, (teamUser) => ({
          key: teamUser.key,
          checked: isChecked(teamUser),
          label: teamUser.label,
        }));

        return newAssigneeFilters;
      });
    };

    assigneeFilterSetter();
  }, [areSubmissionsLoaded, me?.id, submissions, teamMembers, setAssigneeFilters]);

  return assigneeFilter;
}
