import { useSearchParams } from 'react-router-dom-latest';
import { useDeepCompareCallback } from 'hooks';
import { useLocation } from './useLocation';

type UpdateQueryParamsArgs = {
  addParams?: { queryParam: string; value: string }[];
  removeParams?: string[];
};

export function useUpdateQueryParam() {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  return useDeepCompareCallback(
    ({ addParams = [], removeParams = [] }: UpdateQueryParamsArgs = {}) => {
      setSearchParams(
        (prev) => {
          const newSearchParams = new URLSearchParams(prev);

          if (removeParams.length > 0) {
            removeParams.forEach((queryParam) => {
              newSearchParams.delete(queryParam);
            });
          }

          if (addParams.length > 0) {
            addParams.forEach(({ queryParam, value }) => {
              newSearchParams.set(queryParam, value);
            });
          }

          return { ...Object.fromEntries(newSearchParams.entries()) };
        },
        { state: { ...location.state }, replace: true },
      );
    },
    [location.state, setSearchParams],
  );
}
