import { ReactNode } from 'react';
import { WarningAmber } from '@icons';
import { BannerMode } from './types';

interface BannerConfigProps {
  bgColor: string;
  icon: ReactNode;
}
export const bannerConfig: Record<BannerMode, BannerConfigProps> = {
  [BannerMode.Error]: {
    bgColor: 'error.light',
    icon: <WarningAmber color="error" fontSize="small" />,
  },
  [BannerMode.Warning]: {
    bgColor: 'warning.light',
    icon: <WarningAmber color="warning" fontSize="small" />,
  },
};
