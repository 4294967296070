import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import useEmailEditorActions from 'broker/components/EmailEditor/store/useEmailEditorActions';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import RoutedInlineDialog from 'broker/components/RoutedInlineDialog';
// The below eslint-disable are due to the fact that the email editor highly depends on the submission workspace page,
// but some parts of it might be relevant to use elsewhere. This should be addressed in CAP-3264
// In this case, the modal could potentially move to the workspace folder and only rendered in the SubmissionEmailEditor,
// but this will leave the EmailEditor with internal components that navigate to this modal and depend on it being rendered.
// eslint-disable-next-line import/no-restricted-paths
import AddMarkets from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddMarkets';

export default function EmailEditorMarketsModal() {
  const {
    preSelectedMarkets,
    submissionsWorkspace: { submission, markets },
  } = useEmailEditorState();

  const { addMarkets } = useEmailEditorActions();

  const marketsIdsInSubmission = markets.map((market) => market.userMarketId);

  return (
    submission && (
      <RoutedInlineDialog
        className="cap-contained-add-markets"
        path={`${BROKER_NESTED_ROUTES.WORKSPACE.ADD_MARKETS}/*`}
      >
        <AddMarkets
          preferredCoverageLines={submission.coverageLines || []}
          preSelectedMarkets={preSelectedMarkets}
          onMarketsSelected={addMarkets}
          marketsIdsInSubmission={marketsIdsInSubmission}
        />
      </RoutedInlineDialog>
    )
  );
}
