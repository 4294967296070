import { Transition } from 'history';
import { isEmpty, union } from 'lodash';
import { useRef, useState } from 'react';
import { Stack } from '@common-components';
import { HeraldFormType } from 'clients/types';
import { HeraldStatus, SubmissionTask, SubmissionTaskStatus } from 'enums';
import { useToast } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import { DirtyRouteGuard } from 'broker/components/DirtyContentGuard';
import HeraldForm from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm';
import { mapDynamicFormToPartialSubmission } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/mappers/submission-herald-mapper';
import {
  DynamicFormImperativeHandle,
  FormStateBaseProp,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';
import { OnHeraldApplicationUpdateParams } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/types';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { SubmissionStep } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/types';
import { SetNextStepsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/useSetNextStep';
import { useGetMainAreaDimensionsState, useIsAcknowledgmentFlow } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useSendPromptReport } from 'broker/utils/use-send-prompt-report';
import { useGetExtractedData } from './useGetExtractedData';
import { getHeraldRequiredQuestionIds } from './utils';

interface SubmissionDynamicFormProps {
  setNextStep: (props?: SetNextStepsProps) => void;
  submission: PartialSubmission;
  firstVisit: boolean;
}

export default function SubmissionDynamicForm({ setNextStep, submission, firstVisit }: SubmissionDynamicFormProps) {
  const [isDirty, setIsDirty] = useState(false);
  const sendPromptReport = useSendPromptReport();
  const { isAcknowledgmentFlow } = useIsAcknowledgmentFlow();
  const dynamicFormRef = useRef<DynamicFormImperativeHandle | null>(null);
  const mainAreaDimensionsState = useGetMainAreaDimensionsState();
  const { reFetchWorkspace, updateSubmission, updateSubmissionTasks } = useSubmissionsWorkspace();
  const { showToast } = useToast();
  const extractedData = useGetExtractedData(submission.submissionExtractedData);

  const beforeNavigation = async (transition: Transition) => {
    // allow navigating to routes that include the dynamic form (like navigating in toolbox tabs inside the page)
    if (
      transition.location.pathname.includes(
        `${BROKER_NESTED_ROUTES.WORKSPACE.EDIT_SUBMISSION}/${SubmissionStep.DynamicForm}`,
      )
    ) {
      return true;
    }
    try {
      if (dynamicFormRef.current) {
        await dynamicFormRef.current.submitForm();
      }
    } catch (e) {
      return false;
    }
    return true;
  };

  const titleText = () => {
    if (isAcknowledgmentFlow) {
      return messages.acknowledgmentFlow.title;
    }
    return messages.editSubmission.formTitle;
  };

  const onHeraldFormUpdate = async ({
    app: heraldApplication,
    industry,
    displayToast = true,
    previousAppState,
    quoteExits,
    formState,
  }: OnHeraldApplicationUpdateParams) => {
    const submissionUpdateFields = mapDynamicFormToPartialSubmission(
      heraldApplication,
      formState,
      industry || undefined,
    );

    const getRequiredQuestionsBeforeQuoteExit = () => {
      // once we got to quote exit before we don't want to clean requiredQuestionsBeforeQuoteExit array because of an issue we have that there is no synchronize between heraldApplication state (inside herald form) with the requiredQuestionsOverride prop
      // otherwise it will cause a bug that if user gets out of quoteExit then there will be a cycle of heraldApplication in quote exit but with no requiredQuestionsOverride, which will cause issue that the sections will disappear
      if (isEmpty(quoteExits) && !submission.heraldData?.requiredQuestionsBeforeQuoteExit?.length) {
        return undefined;
      }
      const heraldRequiredQuestionIds = getHeraldRequiredQuestionIds(previousAppState);
      return submission.heraldData?.requiredQuestionsBeforeQuoteExit
        ? union(submission.heraldData.requiredQuestionsBeforeQuoteExit, heraldRequiredQuestionIds)
        : heraldRequiredQuestionIds;
    };

    await updateSubmission(submission.id, {
      ...submissionUpdateFields,
      // we must have submission.heraldData otherwise we wouldn't have loaded this form
      heraldData: {
        ...submission.heraldData!,
        status: heraldApplication.status,
        requiredQuestionsBeforeQuoteExit: getRequiredQuestionsBeforeQuoteExit(),
      },
    });
    if (heraldApplication.status === HeraldStatus.Complete) {
      await updateSubmissionTasks({
        submissionId: submission.id,
        status: SubmissionTaskStatus.Done,
        task: SubmissionTask.ReviewSubmission,
      });
    }
    await reFetchWorkspace();
    if (displayToast && !isAcknowledgmentFlow) {
      showToast('success', { message: messages.submissionModal.submissionUpdated });
    }
    if (extractedData) {
      await sendPromptReport({
        formState,
        suggestedValueProps: extractedData,
        entityId: submission.id,
        submissionId: submission.id,
        getValue: (formValue) => {
          if (Array.isArray(formValue)) {
            return formValue.map((value) => value[FormStateBaseProp.Main]);
          }
          return formValue?.main;
        },
      });
    }
  };

  return (
    <DirtyRouteGuard isDirty={isDirty} beforeNavigation={beforeNavigation}>
      <InnerPageLayout
        hideBackButton={isAcknowledgmentFlow}
        title={titleText()}
        className="edit-submission-dynamic-form"
        sx={{ padding: 0, overflow: 'hidden' }}
      >
        {() => (
          <Stack width={1} gap={3} height={1}>
            <HeraldForm
              coverageLines={submission.coverageLines}
              setNextStep={setNextStep}
              submissionId={submission.id}
              ref={dynamicFormRef}
              submissionExtractedData={extractedData}
              firstVisit={firstVisit}
              isAcknowledgmentFlow={isAcknowledgmentFlow}
              mainAreaDimensionsState={mainAreaDimensionsState}
              formType={HeraldFormType.Quote}
              heraldApplicationId={submission.heraldData!.applicationId}
              setIsDirty={setIsDirty}
              onUpdate={onHeraldFormUpdate}
              onClose={setNextStep}
              submission={submission}
              // questions that were required before quote exit, should still be shown
              forceShowQuestions={submission.heraldData?.requiredQuestionsBeforeQuoteExit}
            />
          </Stack>
        )}
      </InnerPageLayout>
    </DirtyRouteGuard>
  );
}
