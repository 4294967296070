import { ReactElement } from 'react';
import { RouteKey } from 'broker/enums';
import { LocationStateEntries } from 'broker/types';
import { useRouteLocationState } from './useRouteLocationState';

interface WithRouteLocationStateProps<TRouteKey extends RouteKey> {
  children: (locationStateEntries: LocationStateEntries[TRouteKey]) => ReactElement;
  routeKey: TRouteKey;
  isStateRequired?: boolean;
}

export default function WithRouteLocationState<TRouteKey extends RouteKey>({
  children,
  routeKey,
  isStateRequired = true,
}: WithRouteLocationStateProps<TRouteKey>) {
  const locationState = useRouteLocationState(routeKey, isStateRequired);
  if (!locationState) {
    return null;
  }
  return children(locationState);
}
