import { useEffect } from 'react';
import { useUnmount } from 'hooks';
import { useUiStoreActions } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

export function useToolboxExpanded(isExpanded: boolean) {
  const { setToolboxState } = useUiStoreActions();

  useEffect(() => {
    setToolboxState({ isExpanded });
  }, [isExpanded, setToolboxState]);

  useUnmount(() => {
    setToolboxState({ isExpanded: false });
  });
}
