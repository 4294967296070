import { Box, Stack, TextField } from '@common-components';
import { messages } from 'i18n';
import { DefaultEmailTemplate } from 'types';

import { Dialog, DialogContent, DialogHeader } from 'components/Dialog';

interface DefaultTemplateDialogProps {
  isOpen: boolean;
  onClose: () => void;
  defaultTemplate?: DefaultEmailTemplate;
}

export default function DefaultTemplateDialog({ isOpen, onClose, defaultTemplate }: DefaultTemplateDialogProps) {
  if (!defaultTemplate) {
    return null;
  }

  const { template, roles } = defaultTemplate;
  const { nameLabel, typeLabel, subjectLabel, bodyLabel, rolesLabel } = messages.admin.defaultTemplates.singleView;

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="default-template" fullScreen sx={{ height: 1 }}>
      <DialogHeader title={template.name} id="defaultTemplateDialog" onClose={onClose} />

      <DialogContent sx={{ bgcolor: 'common.white', height: 1 }}>
        <Stack p={3} gap={3}>
          <Stack direction="row" gap={3}>
            <TextField
              value={template.name}
              label={nameLabel}
              InputProps={{
                readOnly: true,
              }}
              sx={{ flex: 1 }}
            />
            <TextField
              value={template.type}
              label={typeLabel}
              InputProps={{
                readOnly: true,
              }}
              sx={{ flex: 1 }}
            />
            <TextField
              value={roles.join(', ')}
              label={rolesLabel}
              InputProps={{
                readOnly: true,
              }}
              sx={{ flex: 1 }}
            />
          </Stack>

          <TextField
            value={template.subject}
            label={subjectLabel}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            label={bodyLabel}
            InputProps={{
              readOnly: true,
              startAdornment: <Box py={2} dangerouslySetInnerHTML={{ __html: template.body }} />,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
