import { yupResolver } from '@hookform/resolvers/yup';
import { compact } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Grid, Typography } from '@common-components';
import { ContactRole, OrganizationType } from 'enums';
import {
  useBoolean,
  useCurrentUser,
  useMutateContact,
  useMutateInsuranceProduct,
  useMutateUserMarket,
  useSearchInsuranceProduct,
} from 'hooks';
import { messages } from 'i18n';
import { Contact, ContactCreate, UserMarket } from 'types';
import { scrollToError } from 'utils';
import { DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import { FormMultiSelectAutocomplete } from 'components/hookFormComponents/FormMultiSelectAutocomplete';
import FormRadioButton from 'components/hookFormComponents/FormRadioButton';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { FormData, FormFieldsNames, schema } from 'broker/dialogs/AddContactToMarketModal/utils';
import { useEmailFormFieldValidation } from 'broker/hooks';

interface ContentProps {
  defaultSubmissionBox?: boolean;
  userMarket: UserMarket;
  existingContact?: Contact;
  onContactAdded?: (contact: Contact, userMarketId: string) => void;
  onContactUpdated?: (contact: Contact, userMarketId: string) => void;
  onClose: () => void;
  closeWithoutPrompt: () => void;
  setFormDirtyState: (state: boolean) => void;
}

export default function Content({
  userMarket,
  existingContact,
  onClose,
  closeWithoutPrompt,
  setFormDirtyState,
  onContactAdded,
  onContactUpdated,
  defaultSubmissionBox,
}: ContentProps) {
  const { me } = useCurrentUser();
  const { updateUserMarket } = useMutateUserMarket();
  const { updateInsuranceProduct } = useMutateInsuranceProduct();
  const { items: insuranceProducts } = useSearchInsuranceProduct();

  const marketsProducts = insuranceProducts.filter((product) => product.marketId === userMarket.marketId);

  const { createContact, updateContact } = useMutateContact();
  const [isSubmitting, { on: startSubmit, off: stopSubmit }] = useBoolean(false);
  const isCarrier = userMarket.organizationType === OrganizationType.Carrier;

  const getDefaultContactType = () => {
    if (defaultSubmissionBox) {
      return ContactRole.SubmissionBox;
    }
    return isCarrier ? ContactRole.Underwriter : ContactRole.Broker;
  };

  const initProducts = useMemo(
    () =>
      existingContact
        ? marketsProducts
            .filter((product) => product.contacts.some((contact) => contact.id === existingContact.id))
            .map((product) => product.id)
        : [],
    [existingContact, marketsProducts],
  );

  const getFormDataFromContact = (contactItem: Contact): FormData => ({
    ...contactItem,
    role: contactItem.role,
    firstName: contactItem?.firstName || '',
    lastName: contactItem?.lastName || '',
    phoneNumber: contactItem?.phoneNumber || '',
    title: contactItem?.title || '',
    products: initProducts,
  });

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: existingContact ? getFormDataFromContact(existingContact) : { role: getDefaultContactType() },
  });

  const { validateEmailAddresses } = useEmailFormFieldValidation({ methods });

  useEffect(() => {
    if (methods.formState.isSubmitted && !methods.formState.isValid) {
      scrollToError(methods.formState.errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState.submitCount]);

  useEffect(() => {
    if (methods.formState.isDirty) {
      setFormDirtyState(true);
    } else {
      setFormDirtyState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState.isDirty]);

  const getFormDataToSend = (data: FormData): ContactCreate => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    organizationId: userMarket.organizationId,
    role: data.role,
    title: data.title,
  });

  const updateProducts = async (updatedProductIds: string[], contactId: string) => {
    const oldProductIds = initProducts;
    const productsToAdd = updatedProductIds.filter((id) => !oldProductIds.includes(id));
    const productsToRemove = oldProductIds.filter((id) => !updatedProductIds.includes(id));

    if (productsToAdd.length) {
      await Promise.all(
        productsToAdd.map((productId) =>
          updateInsuranceProduct.mutateAsync({
            id: productId,
            data: {
              contactIds: [
                ...compact(
                  insuranceProducts
                    .find((product) => product.id === productId)
                    ?.contacts.map((contact) => contact.id) || [],
                ),
                contactId,
              ],
              updatedBy: me?.email,
            },
          }),
        ),
      );
    }

    if (productsToRemove.length) {
      await Promise.all(
        productsToRemove.map((productId) =>
          updateInsuranceProduct.mutateAsync({
            id: productId,
            data: {
              contactIds: insuranceProducts
                .find((product) => product.id === productId)
                ?.contacts.filter((contact) => contact.id !== contactId)
                .map((contact) => contact.id),
              updatedBy: me?.email,
            },
          }),
        ),
      );
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    startSubmit();

    if (!(await validateEmailAddresses([data.email]))) {
      stopSubmit();
      return;
    }

    try {
      if (!existingContact) {
        const contact = await createContact.mutateAsync({ data: getFormDataToSend(data) });
        if (contact) {
          await Promise.all([
            updateUserMarket.mutateAsync({
              id: userMarket.id,
              data: {
                contactIds: [...userMarket.contacts.map((userMarketContact) => userMarketContact.id), contact.id],
              },
            }),
            updateProducts(data.products, contact.id),
          ]);

          onContactAdded?.(contact, userMarket.id);
          closeWithoutPrompt();
        }
      } else {
        const contact = await updateContact.mutateAsync({ id: existingContact.id, data: getFormDataToSend(data) });
        if (contact) {
          await updateProducts(data.products, contact.id);
          onContactUpdated?.(contact, userMarket.id);
          closeWithoutPrompt();
        }
      }
    } finally {
      stopSubmit();
    }
  };

  const role = methods.watch(FormFieldsNames.Role);

  function getHumanContactOption() {
    return isCarrier
      ? { value: ContactRole.Underwriter, label: messages.general.underwriter }
      : { value: ContactRole.Broker, label: messages.marketsPage.marketType.wholesaler };
  }
  return (
    <>
      <DialogHeader
        title={
          existingContact
            ? messages.addContactToMarketModal.updateTitle(userMarket.marketName)
            : messages.addContactToMarketModal.addTitle(userMarket.marketName)
        }
        id="addContactToMarketModal"
        onClose={onClose}
      />
      <DialogContent sx={{ mt: 3, p: 2 }}>
        <Typography sx={{ mb: 3, color: 'common.black' }} variant="body1">
          {messages.addContactToMarketModal.formHeader}
        </Typography>
        <FormProvider {...methods}>
          <Grid rowSpacing={1} columnSpacing={3} container>
            <Grid item xs={12}>
              <FormRadioButton
                label={messages.addContactToMarketModal.labels.contactType}
                name={FormFieldsNames.Role}
                id={FormFieldsNames.Role}
                options={[
                  getHumanContactOption(),
                  { value: ContactRole.SubmissionBox, label: messages.general.submissionBox },
                ]}
                defaultValue={ContactRole.Underwriter}
              />
            </Grid>
            {role !== ContactRole.SubmissionBox && (
              <Grid item xs={6}>
                <FormTextField
                  placeholder={messages.addContactToMarketModal.placeholders.firstName}
                  label={messages.addContactToMarketModal.labels.firstName}
                  name={FormFieldsNames.FirstName}
                  id={FormFieldsNames.FirstName}
                  fullWidth
                />
              </Grid>
            )}
            {role !== ContactRole.SubmissionBox && (
              <Grid item xs={6}>
                <FormTextField
                  placeholder={messages.addContactToMarketModal.placeholders.lastName}
                  label={messages.addContactToMarketModal.labels.lastName}
                  name={FormFieldsNames.LastName}
                  id={FormFieldsNames.LastName}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <FormTextField
                placeholder={messages.addContactToMarketModal.placeholders.email}
                label={messages.addContactToMarketModal.labels.email}
                name={FormFieldsNames.Email}
                id={FormFieldsNames.Email}
                fullWidth
              />
            </Grid>

            {role !== ContactRole.SubmissionBox && (
              <Grid item xs={6}>
                <FormTextField
                  placeholder={messages.addContactToMarketModal.placeholders.phoneNumber}
                  label={messages.addContactToMarketModal.labels.phoneNumber}
                  name={FormFieldsNames.PhoneNumber}
                  id={FormFieldsNames.PhoneNumber}
                  optional
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormMultiSelectAutocomplete
                optional
                defaultValue={[]}
                placeholder={messages.addContactToMarketModal.placeholders.products}
                label={messages.addContactToMarketModal.labels.products}
                name={FormFieldsNames.Products}
                id={FormFieldsNames.Products}
                options={marketsProducts.map((marketProduct) => ({
                  label: marketProduct.name,
                  value: marketProduct.id,
                }))}
              />
            </Grid>

            {role !== ContactRole.SubmissionBox && (
              <Grid item xs={6}>
                <FormTextField
                  placeholder={messages.addContactToMarketModal.placeholders.title}
                  label={messages.addContactToMarketModal.labels.title}
                  name={FormFieldsNames.Title}
                  id={FormFieldsNames.Title}
                  optional
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: existingContact
            ? messages.addContactToMarketModal.updateContact
            : messages.addContactToMarketModal.addContact,
          onClick: methods.handleSubmit(onSubmit),
          loading: isSubmitting,
        }}
        cancelButton={{
          children: messages.addContactToMarketModal.cancel,
          onClick: onClose,
        }}
      />
    </>
  );
}
