import { IconButton, NewMenu, PopoverOrigin } from '@common-components';
import { Sort as SortIcon } from '@icons';
import { useOptionsMenu } from 'hooks';
import { SortAndFilterProps, Sorters } from 'utils';
import { MenuPlacement } from 'components/types';

type SortProps<T, S extends string> = Pick<SortAndFilterProps<T, S>, 'setSortType' | 'sortType'> & {
  sorters: Sorters<T, S>;
  menuPlacement?: MenuPlacement;
  transformOrigin?: PopoverOrigin;
};

export default function Sort<T, S extends string>({ sortType, setSortType, sorters, menuPlacement }: SortProps<T, S>) {
  const optionsMenu = useOptionsMenu();

  return (
    <>
      <IconButton icon={SortIcon} color="secondary" variant="text" onClick={optionsMenu.openMenu} />
      <NewMenu
        optionsMenuState={optionsMenu}
        placement={menuPlacement}
        menuItems={[
          {
            key: 'sorter-menu-popover',
            selectionType: 'radio',
            items: Object.entries(sorters).map(([key]) => ({
              label: sorters[key as keyof Sorters<T, S>].label,
              isSelected: sortType === key,
              onClick: () => {
                setSortType(key as S);
              },
            })),
          },
        ]}
      />
    </>
  );
}
