import { useMemo } from 'react';
import { User } from 'types';
import { didUserPassedFilter } from './utils';

interface UserFilterProps {
  agents: User[];
  searchInput: string;
}

export default function useUsersSorter({ agents, searchInput }: UserFilterProps) {
  return useMemo(() => {
    const mappedContacts = agents.map((agent) => ({
      ...agent,
      passedFilter: didUserPassedFilter({ searchInput, contact: agent }),
    }));

    mappedContacts.sort((a, b) => Number(b.passedFilter) - Number(a.passedFilter));

    return mappedContacts;
  }, [agents, searchInput]);
}
