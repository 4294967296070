import { CoverageLine } from 'enums';
import { FormFieldsConfigProps } from 'hooks';

export interface PreFormData {
  coverageLines: CoverageLine[];
}

export enum PreFormFieldsNames {
  CoverageLines = 'coverageLines',
}

export type FormFieldsConfig = Record<PreFormFieldsNames, FormFieldsConfigProps<PreFormFieldsNames>>;
