import { isString } from 'lodash';
import { useSnackbar, VariantType } from 'notistack';
import { forwardRef, MouseEvent, ReactNode } from 'react';
import { CheckCircle, Close, Dangerous, Info, SvgIconComponent, Warning } from '@icons';
import { ConditionalWrap } from 'utils';
import { Card, Stack, Typography } from 'components/mui-index';
import IconButton from './IconButton';

type VariantStylingProps = {
  icon?: SvgIconComponent;
  color: string;
};

const variantStylingProps: { [key in VariantType]?: VariantStylingProps } = {
  success: {
    icon: CheckCircle,
    color: 'success.main',
  },
  error: {
    icon: Dangerous,
    color: 'error.main',
  },
  warning: {
    icon: Warning,
    color: 'warning.main',
  },
  info: {
    icon: Info,
    color: 'primary.main',
  },
};

type ToastProps = {
  id: string | number;
  variant: VariantType;
  dismissable: boolean;
  icon?: SvgIconComponent;
  children: ReactNode;
};
const Toast = forwardRef<HTMLElement, ToastProps>(({ id, variant, children, dismissable, icon }: ToastProps, ref) => {
  const { closeSnackbar } = useSnackbar();
  const Icon = icon ?? variantStylingProps[variant]?.icon;
  const variantColor = variantStylingProps[variant]?.color;

  return (
    <Stack
      ref={ref}
      component={Card}
      elevation={4}
      gap={2}
      direction="row"
      alignItems="center"
      p={2}
      border={1}
      borderColor={variantColor}
      minHeight={(theme) => theme.spacing(8)}
      onClick={(event: MouseEvent<HTMLDivElement>) => {
        // Prevent closing other tooltips when clicking on the toast
        // We need to special case on anchors because navigation in child components doesn't work when prevent event handling
        const target = event.target as HTMLLinkElement;
        if (!target.href) {
          event.stopPropagation();
          event.preventDefault();
        }
      }}
    >
      {Icon && <Icon fontSize="large" sx={{ color: variantColor }} />}
      <ConditionalWrap
        condition={isString(children)}
        wrap={(wrappedChildren) => <Typography variant="body2">{wrappedChildren}</Typography>}
      >
        {children}
      </ConditionalWrap>

      {dismissable && (
        <IconButton onClick={() => closeSnackbar(id)} edge="end" icon={Close} color="secondary" size="small" />
      )}
    </Stack>
  );
});
export default Toast;
