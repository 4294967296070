import { messages } from 'i18n';
import { ConfirmationDialog } from 'components/Dialog';

interface DirtyPageDialogProps {
  open: boolean;
  onContinueEditing: () => void;
  onDiscard: () => void;
}

export default function DirtyPageDialog({ open, onContinueEditing, onDiscard }: DirtyPageDialogProps) {
  return (
    <ConfirmationDialog
      title={messages.dirtyPageModal.header}
      message={messages.dirtyPageModal.subHeader}
      isOpen={open}
      onCancel={onContinueEditing}
      onSubmit={onContinueEditing}
      cancelButton={{ children: messages.dirtyPageModal.discardChanges, onClick: onDiscard }}
      proceedButton={{ children: messages.dirtyPageModal.continueEditing }}
    />
  );
}
