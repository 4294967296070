import * as yup from 'yup';
import { ContactRole } from 'enums';
import { messages } from 'i18n';

const { requiredField, invalidEmail } = messages.formMessages;

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .when('role', {
      is: ContactRole.SubmissionBox,
      otherwise: yup.string().required(requiredField),
    }),
  lastName: yup
    .string()
    .trim()
    .when('role', {
      is: ContactRole.SubmissionBox,
      otherwise: yup.string().required(requiredField),
    }),
  email: yup.string().trim().email(invalidEmail).required(requiredField),
  phoneNumber: yup.string().trim(),
  role: yup.string().required(requiredField),
  title: yup.string().trim(),
});

export interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  title: string;
  role: ContactRole;
  products: string[];
}

export enum FormFieldsNames {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Products = 'products',
  PhoneNumber = 'phoneNumber',
  Role = 'role',
  Title = 'title',
}
