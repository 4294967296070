import { isString } from 'lodash';
import { Chip, Stack, Tooltip, Typography } from '@common-components';
import { ActivityLog } from 'types';
import { getTimeDisplay } from 'utils';
import { ActivityLogActor } from './ActivityLogActor';
import { ActivityTypeDisplayConfig } from './types';

type ActivityLogCardProps = { activityLog: ActivityLog; displayConfig: ActivityTypeDisplayConfig };

export function ActivityLogCard({ activityLog, displayConfig }: ActivityLogCardProps) {
  function getDisplayText() {
    return isString(displayConfig.displayText) ? displayConfig.displayText : displayConfig.displayText(activityLog);
  }

  return (
    <Stack direction="row" className="cap-submission-market-activity" pl={1.5}>
      <Stack direction="column" rowGap={1} ml={2} flex={1}>
        <Stack direction="row" alignItems="center" gap={2} marginLeft={-3}>
          <ActivityLogActor actor={activityLog.actor} />
          <Stack direction="column" flex={1}>
            <Typography variant="body2" color="text.primary" flex={1}>
              {getDisplayText()}
            </Typography>
            <Typography variant="caption" color="typography.nonEssential" flex={1}>
              {getTimeDisplay(activityLog.createdAt)}
            </Typography>
          </Stack>
          <Tooltip tooltipContent={displayConfig.iconTooltip?.(activityLog) ?? ''}>
            <Chip
              startIcon={displayConfig.icon}
              label={`${activityLog.groupedActivities?.length ?? ''}`}
              color="secondary"
              variant="outlined"
              rounded
            />
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
}
