import { sliderClasses, Theme } from '@common-components';

export const marketAutocompleteLabelShiftPadding = 12;

export const SliderStyled = {
  color: 'green.300',
  width: 6,
  [`& .${sliderClasses.track}`]: {
    border: 'none',
    backgroundColor: 'grey.50',
  },
  [`& .${sliderClasses.mark}`]: {
    border: 'none',
    backgroundColor: 'text.primary',
  },
  [`& .${sliderClasses.markLabel}`]: {
    backgroundColor: 'grey.50',
    px: 1,
    py: 0.5,
    borderRadius: 1,
    typography: 'captionBold',
    transition: (theme: Theme) =>
      theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.shortest,
      }),
    ' &:hover': {
      backgroundColor: 'grey.100',
    },
  },
  [`& .${sliderClasses.markLabelActive}`]: {
    backgroundColor: 'green.300',
    ' &:hover': {
      backgroundColor: 'green.400',
    },
  },
  [`& .${sliderClasses.thumb}`]: {
    height: 24,
    width: 24,
    backgroundColor: 'green.300',
    boxShadow: 3,
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 4,
    },
    '&:before': {
      display: 'none',
    },
  },
} as const;
