import { Stack, TextField, Typography } from '@common-components';
import { Speed as SpeedIcon } from '@icons';
import { RiskAssessmentRequestData } from 'clients';
import { useGenerateRiskAssessment } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { useCreateAIThread } from 'broker/pages/SubmissionWorkspacePage/hooks';

interface ContentProps {
  submission: PartialSubmission;
  riskAssessment: string;
  setRiskAssessment: (value: string) => void;
  setIsDirty: (value: boolean) => void;
  setAiGeneratedRiskAssessment: (value: string | undefined) => void;
  riskAssessmentAiInput: RiskAssessmentRequestData;
  isDisabled: boolean;
}

export default function RiskAssessmentField({
  submission,
  riskAssessment,
  setRiskAssessment,
  setIsDirty,
  setAiGeneratedRiskAssessment,
  riskAssessmentAiInput,
  isDisabled,
}: ContentProps) {
  const { generateRiskAssessment } = useGenerateRiskAssessment();

  useCreateAIThread(
    () => generateRiskAssessment(riskAssessmentAiInput),
    true,
    (result) => {
      if (result) {
        if (!submission.riskAssessment) {
          setRiskAssessment(result?.response);
          setIsDirty(true);
          setAiGeneratedRiskAssessment(result?.response);
        }
      }
    },
  );

  return (
    <Stack gap={3}>
      <Stack direction="row" gap={1}>
        <SpeedIcon />
        <Typography variant="subtitle2">{messages.riskAssessmentNote.title}</Typography>
      </Stack>
      <Stack gap={1}>
        <TextField
          value={riskAssessment}
          placeholder={messages.riskAssessmentNote.placeholder}
          multiline
          rows={10}
          disabled={isDisabled}
          onChange={(event) => {
            setRiskAssessment(event.target.value);
            setIsDirty(true);
          }}
          InputProps={{
            sx: {
              typography: 'body2',
            },
          }}
        />
        <Typography variant="caption" color="textSecondary">
          {messages.riskAssessmentNote.explanation}
        </Typography>
      </Stack>
    </Stack>
  );
}
