import { BoxItem, SharedLink } from 'box-ui-elements/es';
import { compact, some, values } from 'lodash';
import { BoxTemplateLabels, EmailMessageContentType, EmailMessageRecipientType } from 'enums';
import { useBoxClient, useHotjar, useToast } from 'hooks';
import { useMutateEmailMessageBatch } from 'hooks/api/emailMessagesBatch';
import { messages } from 'i18n';
import { EmailMessageBatch, EmailMessageCreate, User } from 'types';
import { htmlToPlain, Nullable } from 'utils';
import { HotjarEvents } from 'utils/hotjar-events';
import { EmailTemplateContext, TemplateInstance } from 'broker/components/EmailEditor/store/types';
import { ResolveForSend } from 'broker/components/EmailEditor/useResolveDraft';
import { isTotalAttachmentsSizeReached } from 'broker/components/EmailEditor/utils/file-utils';
import { RecipientGroups } from 'broker/components/Emails/recipient-utils';
import useArchive from 'broker/components/Emails/useArchive';
import { minAttachedFilesToCreateArchive } from 'broker/utils/email-utils/email-resolver-utils/email-resolving-utils';
import { recipientFullName } from './utils';

export const useSendDraftEmail = (
  attachedFiles: BoxItem[],
  recipientGroups: RecipientGroups,
  cc: User[],
  resolveForSend: ResolveForSend,
  emailTemplateContext: EmailTemplateContext,
  getTemplateInstance: (recipientGroupId: string) => TemplateInstance,
  submissionOrganizationId?: Nullable<string>,
  isBySubmissionEmail = true,
  inReplyToMessageId?: string,
  onBehalfOf?: string,
) => {
  const boxClient = useBoxClient();
  const { createEmailMessageBatchBySubmission, createEmailMessageBatch } = useMutateEmailMessageBatch();

  const createArchive = useArchive({
    boxFolderId: emailTemplateContext.submission.boxFolderId,
  });

  const { showToast } = useToast();
  const hotjar = useHotjar();

  const resolvedMessages = compact(
    Object.values(recipientGroups).map((recipientGroup) => {
      const templateInstance = getTemplateInstance(recipientGroup.id);
      if (templateInstance) {
        return resolveForSend(templateInstance.subject, templateInstance.body, recipientGroup, null);
      }
      return null;
    }),
  );

  const sendEmail = async (postSendCallback?: (sentEmails?: EmailMessageBatch) => Promise<void>) => {
    hotjar.event(HotjarEvents.SendEmail);

    const sendAttachmentsAsLinks = isTotalAttachmentsSizeReached(attachedFiles);
    let attachmentsArchive: SharedLink | null;

    if (sendAttachmentsAsLinks && attachedFiles.length >= minAttachedFilesToCreateArchive) {
      const archiveName = `attachments_${new Date().getTime()}`;
      attachmentsArchive = await createArchive(attachedFiles, archiveName);
      if (!attachmentsArchive) {
        showToast('error', { message: messages.emailEditor.failedToUploadArchive });
        throw new Error(messages.emailEditor.failedToUploadArchive);
      }
    }

    const messagesToSend: EmailMessageCreate[] = Object.values(recipientGroups).map((recipientGroup) => {
      const templateInstance = getTemplateInstance(recipientGroup.id);

      const resolvedMessage = resolveForSend(
        templateInstance.subject,
        templateInstance.body,
        recipientGroup,
        attachmentsArchive,
      );

      const recipients = recipientGroup.recipients.map((recipient) => ({
        type: EmailMessageRecipientType.To,
        address: recipient.email,
        name: recipientFullName(recipient),
      }));

      cc.forEach((user) => {
        recipients.push({
          type: EmailMessageRecipientType.Cc,
          address: user.email,
          name: `${user.firstName} ${user.lastName}`,
        });
      });

      return {
        subject: resolvedMessage.subject,
        contents: [
          {
            type: EmailMessageContentType.HTML,
            value: resolvedMessage.body,
          },
          {
            type: EmailMessageContentType.PLAIN,
            value: htmlToPlain(resolvedMessage.body),
          },
          ...(sendAttachmentsAsLinks
            ? []
            : attachedFiles.map((file) => ({
                type: EmailMessageContentType.BOX_FILE_ID,
                value: file.id,
              }))),
        ],
        recipients,
        replyToName: templateInstance.from?.replyToName,
        inReplyTo: inReplyToMessageId,
        onBehalfOf,
      };
    });

    const createEmailMessageBatchByRole = isBySubmissionEmail
      ? createEmailMessageBatchBySubmission
      : createEmailMessageBatch;

    const sentEmails = await createEmailMessageBatchByRole.mutateAsync({
      data: {
        submissionId: emailTemplateContext.submission.id,
        messages: messagesToSend,
      },
    });

    // If the email was sent to a retailer, mark the attachments as visible to retailer
    if (some(values(recipientGroups), (recipientGroup) => recipientGroup.id === submissionOrganizationId)) {
      await Promise.all(
        attachedFiles.map((file) =>
          boxClient.addFileMetadata(file.id, { [BoxTemplateLabels.IsVisibleToRetailer]: 'True' }),
        ),
      );
    }
    await postSendCallback?.(sentEmails ?? undefined);
  };

  return { sendEmail, resolvedMessages };
};
