import { alpha, Box, Theme } from '@common-components';

export default function BlockingOverlay() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: (theme: Theme) => theme.zIndex.modal,
        bgcolor: (theme: Theme) => alpha(theme.palette.grey[500] as string, 0.2),
        cursor: 'not-allowed',
      }}
    />
  );
}
