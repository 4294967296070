import { Tooltip, Typography } from '@common-components';
import { Quote } from 'types';
import { coverageLineConfig, truncateMultiLineText } from 'utils';
import { QuoteCoverageLines } from './QuoteCoverageLines';

export default function QuoteCoverageLinesTooltip({ quote }: { quote: Quote }) {
  const coverageLines = quote.coverageLines ? QuoteCoverageLines({ coverageLines: quote.coverageLines }) : '';
  const coverageLinesList = quote.coverageLines
    ? quote.coverageLines.map((coverageLine) => coverageLineConfig[coverageLine].text).join(', ')
    : '';

  return (
    <Tooltip tooltipContent={coverageLines} placement="left-start">
      <Typography component="span" noWrap variant="caption" color="text.secondary" sx={truncateMultiLineText(1)}>
        {coverageLinesList}
      </Typography>
    </Tooltip>
  );
}
