import { useState } from 'react';
import { useBoolean } from 'hooks';

import { AddContactToMarketMetadata } from './AddContactToMarketModal/types';

export default function useAddContactToMarketModal() {
  const [addContactToMarketOpen, { on: openAddContactToMarketModal, off: closeAddContactToMarketModal }] =
    useBoolean(false);
  const [addContactToMarketMetaData, setAddContactToMarketMetaData] = useState<undefined | AddContactToMarketMetadata>(
    undefined,
  );

  const onOpenNewContact = (metaData: AddContactToMarketMetadata) => {
    openAddContactToMarketModal();
    setAddContactToMarketMetaData(metaData);
  };

  return {
    addContactToMarketOpen,
    closeAddContactToMarketModal,
    addContactToMarketMetaData,
    onOpenNewContact,
  };
}
