import {
  useCurrentUser,
  useInvalidateSubmissionMarketCache,
  useMutateSubmission,
  useMutateSubmissionMarket,
  useToast,
} from 'hooks';
import { messages } from 'i18n';
import { CopilotSteps, SubmissionMarket } from 'types';
import { AutonomousMarketInfoData } from './AdvancedContent/utils';
import { useAutonomousMarketInfo } from './useAutonomousMarketInfo';

const useAutonomousFlow = ({
  submissionMarkets,
  submissionId,
  selectedUserId = null,
  onAutonomousFlowSuccess,
  activeSteps,
  insuredName,
}: {
  submissionMarkets: SubmissionMarket[];
  submissionId: string;
  insuredName: string;
  selectedUserId: string | null;
  onAutonomousFlowSuccess: () => void;
  activeSteps?: CopilotSteps[];
}) => {
  const { createSubmissionMarket, updateSubmissionMarket } = useMutateSubmissionMarket({
    cancelInvalidation: true,
  });
  const { updateSubmission } = useMutateSubmission();

  const { showToast } = useToast();

  const { endUser } = useCurrentUser();

  const invalidateSubmissionMarketCache = useInvalidateSubmissionMarketCache();

  const startAutonomousFlow = async (marketsToAdd: AutonomousMarketInfoData[]) => {
    await Promise.all(
      marketsToAdd.map(async (marketToAdd) => {
        const existingMarket = submissionMarkets.find((market) => market.userMarketId === marketToAdd.marketId);

        if (!existingMarket) {
          return createSubmissionMarket.mutateAsync({
            data: {
              copilotEnrolled: true,
              userMarketId: marketToAdd.marketId,
              submissionId,
            },
          });
        }

        return updateSubmissionMarket.mutateAsync({
          id: existingMarket.id,
          data: {
            copilotEnrolled: true,
          },
        });
      }),
    );

    await invalidateSubmissionMarketCache();

    await updateSubmission.mutateAsync(
      {
        id: submissionId,
        data: {
          userId: selectedUserId,
          isCopilotEnabled: true,
          copilotSettings: {
            ...endUser?.organization.copilotSettings!,
            activeSteps: activeSteps || endUser!.organization.copilotSettings!.activeSteps!,
          },
        },
      },
      {
        onSuccess: () => showToast('success', { message: messages.autonomousFlow.success(insuredName) }),
        onError: () => showToast('warning', { message: messages.autonomousFlow.error }),
      },
    );
    onAutonomousFlowSuccess();
  };

  const initSubmissionMarkets = useAutonomousMarketInfo(submissionMarkets);

  return {
    startAutonomousFlow,
    initSubmissionMarkets,
    isAutonomousFlowInProgress:
      updateSubmissionMarket.isLoading || createSubmissionMarket.isLoading || updateSubmission.isLoading,
  };
};

export default useAutonomousFlow;
