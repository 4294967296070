import { range } from 'lodash';
import { BoxSkeleton, Stack } from '@common-components';

export default function ContactsSkeleton() {
  return (
    <Stack gap={4} pt={2}>
      {range(0, 4).map((i) => (
        <Stack key={i} direction="row" gap={2} px={2} alignItems="center">
          <BoxSkeleton width={40} height={40} isLoading sx={{ borderRadius: 5 }} />
          <Stack gap={1} flex={1}>
            <BoxSkeleton height={20} isLoading sx={{ width: 0.8 }} />
            <BoxSkeleton height={15} sx={{ width: 0.7 }} isLoading />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
