import {
  AirplanemodeActive,
  AttachFile,
  Check,
  ContactSupport,
  ContentCopy,
  DeleteOutline,
  EditOutlined,
  EmailOutlined,
  FileCopyOutlined,
  FileDownloadOutlined,
  MarkEmailReadOutlined,
  SendOutlined,
  ShareOutlined,
  ThumbDownOffAlt,
} from '@icons';
import { ActivityType, SubmissionStatus } from 'enums';
import { messages } from 'i18n';
import { ActivityLog } from 'types';
import { SubmissionViewMode } from 'broker/components/SubmissionsView/utils';
import { submissionStatusConfig } from 'broker/configuration-mappers/submission-status-config';
import { ActivityTypeDisplayConfig } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/ActivityLog';
import { SubmissionLevelActivities } from './types';

const addBasedOnPrimarySuffix = (displayText: string, activityLog: ActivityLog) =>
  displayText +
  (activityLog.quote?.primaryQuote
    ? `, ${messages.general.xsOfMarket(activityLog.quote.primaryQuote.marketName ?? '')} `
    : '');

function listMarkets(activityLog: ActivityLog) {
  return activityLog.groupedActivities?.map((activity) => activity.submissionMarket?.marketName).join(', ') ?? '';
}

export const submissionActivityTypeConfig: Record<SubmissionLevelActivities, ActivityTypeDisplayConfig> = {
  [ActivityType.AddedManually]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.AddedManually],
    iconTooltip: listMarkets,
    icon: Check,
  },
  [ActivityType.AddedAsIncumbent]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.AddedAsIncumbent],
    iconTooltip: listMarkets,
    icon: Check,
  },
  [ActivityType.MarketDeclined]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.MarketDeclined],
    icon: ThumbDownOffAlt,
  },
  [ActivityType.InformationRequested]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.InformationRequested],
    icon: ContactSupport,
  },
  [ActivityType.RequestForQuote]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.RequestForQuote],
    iconTooltip: listMarkets,
    icon: MarkEmailReadOutlined,
  },
  [ActivityType.MarketEmailSent]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.MarketEmailSent],
    icon: MarkEmailReadOutlined,
    iconTooltip: listMarkets,
  },
  [ActivityType.FilesUploaded]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.FilesUploaded],
    icon: FileCopyOutlined,
  },
  [ActivityType.CustomerEmailSent]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.CustomerEmailSent],
    icon: SendOutlined,
  },
  [ActivityType.CopilotEscalationEmailSent]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.CopilotEscalationEmailSent],
    icon: SendOutlined,
  },
  [ActivityType.ProposalSent]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.ProposalSent],
    icon: SendOutlined,
  },
  [ActivityType.ConceptualTowerCopiedToClipboard]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.ConceptualTowerCopiedToClipboard],
    icon: ContentCopy,
  },
  [ActivityType.ConceptualTowerDownloaded]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.ConceptualTowerDownloaded],
    icon: FileDownloadOutlined,
  },
  [ActivityType.ConceptualTowerSent]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.ConceptualTowerSent],
    icon: ShareOutlined,
  },
  [ActivityType.ExpiringTowerCopiedToClipboard]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.ExpiringTowerCopiedToClipboard],
    icon: ContentCopy,
  },
  [ActivityType.MarketingReportCopiedToClipboard]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.MarketingReportCopiedToClipboard],
    icon: ContentCopy,
  },
  [ActivityType.MarketingReportDownloaded]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.MarketingReportDownloaded],
    icon: FileDownloadOutlined,
  },
  [ActivityType.MarketingReportSent]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.MarketingReportSent],
    icon: ShareOutlined,
  },
  [ActivityType.InformationRequestCopiedToClipboard]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.InformationRequestCopiedToClipboard],
    icon: ContentCopy,
  },
  [ActivityType.InformationRequestDownloaded]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.InformationRequestDownloaded],
    icon: FileDownloadOutlined,
  },
  [ActivityType.InformationRequestSent]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.InformationRequestSent],
    icon: ShareOutlined,
  },
  [ActivityType.SubmissionCreated]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.SubmissionCreated],
    icon: Check,
  },
  [ActivityType.SubmissionUpdated]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.SubmissionUpdated],
    icon: EditOutlined,
  },
  [ActivityType.ProposalUpdated]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.ProposalUpdated],
    icon: EditOutlined,
  },
  [ActivityType.IncumbentInfoEdited]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.IncumbentInfoEdited],
    icon: EditOutlined,
  },
  [ActivityType.CustomerEmailReceived]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.CustomerEmailReceived],
    icon: EmailOutlined,
  },
  [ActivityType.MarketEmailReceived]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.MarketEmailReceived],
    icon: EmailOutlined,
  },
  [ActivityType.QuoteAttached]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionActivityLog.activitiesTitles[ActivityType.QuoteAttached](activityLog),
        activityLog,
      ),
    icon: AttachFile,
  },
  [ActivityType.SubmissionStatusUpdated]: {
    displayText: (activityLog) => {
      const statusChangedField = activityLog.metadata?.changedFields?.find(
        (fieldChanged) => fieldChanged.field === 'status',
      );
      const oldValue = statusChangedField?.oldValue
        ? submissionStatusConfig[statusChangedField.oldValue as SubmissionStatus]?.text(SubmissionViewMode.General)
        : '';
      const newValue = statusChangedField?.newValue
        ? submissionStatusConfig[statusChangedField.newValue as SubmissionStatus]?.text(SubmissionViewMode.General)
        : '';
      return messages.submissionActivityLog.activitiesTitles[ActivityType.SubmissionStatusUpdated](oldValue, newValue);
    },
    icon: EditOutlined,
  },
  [ActivityType.QuoteNotAccepted]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionActivityLog.activitiesTitles[ActivityType.QuoteNotAccepted](activityLog),
        activityLog,
      ),
    icon: ThumbDownOffAlt,
  },
  [ActivityType.QuoteRemoved]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionActivityLog.activitiesTitles[ActivityType.QuoteRemoved](activityLog),
        activityLog,
      ),
    icon: DeleteOutline,
  },
  [ActivityType.QuoteUpdated]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionActivityLog.activitiesTitles[ActivityType.QuoteUpdated](activityLog),
        activityLog,
      ),
    icon: AttachFile,
  },
  [ActivityType.BinderAttached]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionActivityLog.activitiesTitles[ActivityType.BinderAttached](activityLog),
        activityLog,
      ),
    icon: AttachFile,
  },
  [ActivityType.BinderUpdated]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionActivityLog.activitiesTitles[ActivityType.BinderUpdated](activityLog),
        activityLog,
      ),
    icon: AttachFile,
  },
  [ActivityType.BinderRemoved]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionActivityLog.activitiesTitles[ActivityType.BinderRemoved](activityLog),
        activityLog,
      ),
    icon: DeleteOutline,
  },
  [ActivityType.PolicyIssued]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionActivityLog.activitiesTitles[ActivityType.PolicyIssued](activityLog),
        activityLog,
      ),
    icon: AttachFile,
  },
  [ActivityType.PolicyRemoved]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionActivityLog.activitiesTitles[ActivityType.PolicyRemoved](activityLog),
        activityLog,
      ),
    icon: DeleteOutline,
  },
  [ActivityType.PolicyUpdated]: {
    displayText: (activityLog) =>
      addBasedOnPrimarySuffix(
        messages.submissionActivityLog.activitiesTitles[ActivityType.PolicyUpdated](activityLog),
        activityLog,
      ),
    icon: AttachFile,
  },
  [ActivityType.ArfSent]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.ArfSent],
    icon: Check,
  },
  [ActivityType.ArfRequest]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.ArfRequest],
    icon: Check,
  },
  [ActivityType.SubmissionCopilotEnabled]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.SubmissionCopilotEnabled],
    icon: AirplanemodeActive,
  },
  [ActivityType.SubmissionCopilotDisabled]: {
    displayText: messages.submissionActivityLog.activitiesTitles[ActivityType.SubmissionCopilotDisabled],
    icon: AirplanemodeActive,
  },
};
