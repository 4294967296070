import { compact, groupBy, mapValues } from 'lodash';
import { useState } from 'react';
import { SubmissionMethod } from 'enums';
import { useHotjar, useToast } from 'hooks';
import { messages } from 'i18n';
import { HotjarEvents } from 'utils/hotjar-events';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import { convertSubmissionMarketToRecipientGroup } from 'broker/components/Emails/recipient-utils';
import { EmailEditorPageMode, RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { SelectionActionButtonBaseProps } from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/types';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useSubmitMarketRequestsViaApi } from 'broker/pages/SubmissionWorkspacePage/utils';
import BulkActionButton from './BulkActionButton';

interface SubmitViaActionButtonProps extends SelectionActionButtonBaseProps {
  method: SubmissionMethod;
  clearBulkSubmitMethod: () => void;
}

export function SubmitViaActionButton({
  method,
  clearBulkSubmitMethod,
  ...bulkActionButtonProps
}: SubmitViaActionButtonProps) {
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { selectedMarketRequests } = bulkActionButtonProps;
  const navigate = useNavigate();
  const { partialSubmission } = useSubmissionsWorkspace();
  const hotjar = useHotjar();
  const submitMarketRequestsViaApi = useSubmitMarketRequestsViaApi();

  // map selected requests (products) to submission markets (multiple product per submission market)
  const requestsBySubmissionMarket = mapValues(groupBy(selectedMarketRequests, 'market.id'), (requests) => {
    const products = compact(requests.map((request) => request.insuranceProduct));
    const contactIds = compact(products.flatMap((product) => product.contactIds));
    const contacts = requests[0].market.contacts.filter((contact) => contactIds.includes(contact.id));
    const { market } = requests[0];
    return { submissionMarket: market, contacts };
  });

  const onClick = async () => {
    hotjar.event(HotjarEvents.SubmitToMarket);
    switch (method) {
      case SubmissionMethod.Email:
        clearBulkSubmitMethod();
        return navigate(BROKER_NESTED_ROUTES.WORKSPACE.EMAIL_EDITOR, {
          routeKey: RouteKey.EmailEditor,
          state: {
            mode: EmailEditorPageMode.SendRfq,
            recipientGroups: Object.values(requestsBySubmissionMarket).map((selectedMarketRequest) =>
              convertSubmissionMarketToRecipientGroup(
                selectedMarketRequest.submissionMarket,
                selectedMarketRequest.contacts,
              ),
            ),
          },
        });
      case SubmissionMethod.API:
        try {
          setIsLoading(true);
          await submitMarketRequestsViaApi(partialSubmission!, selectedMarketRequests);
          setIsLoading(false);
          showToast('success', { message: messages.heraldApi.rfqSentViaApi });
          clearBulkSubmitMethod();
        } catch (e) {
          setIsLoading(false);
          throw e;
        }
        return null;
      default:
        return null;
    }
  };

  return (
    <BulkActionButton multipleSelection loading={isLoading} onClick={onClick} {...bulkActionButtonProps}>
      {messages.submissionWorkspace.marketRequestsWorkspace.submitViaMethod(method)}
    </BulkActionButton>
  );
}
