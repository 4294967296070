import { EmailEditorPageMode, RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { ReplyMode } from './NestedViews/EmailReplyInnerPage/types';
import useEmailAction from './Toolbox/components/Email/useEmailAction';
import useGetSubmissionRelatedEmails from './Toolbox/components/Email/useGetSubmissionRelatedEmails';
import { isCustomerEmail } from './Toolbox/components/Email/utils';

interface UseNavigateToLatestCustomerEmailProps {
  emailEditorPageMode: EmailEditorPageMode;
}

export default function useNavigateToLatestCustomerEmail({
  emailEditorPageMode,
}: UseNavigateToLatestCustomerEmailProps) {
  const { partialSubmission: submission } = useSubmissionsWorkspace();
  const { navigateToEmailReplyEditor } = useEmailAction();
  const submissionId = submission?.id;

  const { submissionEmailsList, isLoading } = useGetSubmissionRelatedEmails({ submissionId });

  // Returns the first email, which is the latest one.
  const latestCustomerEmail = submissionEmailsList.find((email) => isCustomerEmail(submission!, email));
  const { emailEditorUrl } = useWorkspaceUrls();
  const navigate = useNavigate();

  const navigateToLatestCustomerEmail = () => {
    if (latestCustomerEmail) {
      navigateToEmailReplyEditor(
        { emailId: latestCustomerEmail.id, emailType: latestCustomerEmail.type },
        ReplyMode.FollowUp,
        emailEditorPageMode,
      );
    } else {
      // Navigate to the email editor page with the email template type, without replying to an email.
      navigate(emailEditorUrl, {
        routeKey: RouteKey.EmailEditor,
        state: {
          mode: emailEditorPageMode,
        },
      });
    }
  };

  return { navigateToLatestCustomerEmail, isLoading };
}
