import { ButtonProps } from '@common-components';

import { ExtendedMarketRequest } from 'types';

export enum MarketSortTypes {
  Updated = 'Updated',
  Status = 'Status',
  Asc = 'Asc',
  Desc = 'Desc',
}

export interface SelectionActionButtonBaseProps extends Omit<ButtonProps, 'children'> {
  selectedMarketRequests: ExtendedMarketRequest[];
  onSelectionButtonClick: () => void;
  comparisonExpiringPolicyFileId?: string;
}

export enum BulkActionsMode {
  Submit = 'Submit',
  CompareQuotes = 'CompareQuotes',
}
