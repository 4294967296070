import { useBoxClient, useToast } from 'hooks';
import { messages } from 'i18n';

interface UseDownloadFilesProps {
  organizationBoxFolderId: string;
}

export default function useDownloadFilesByType({ organizationBoxFolderId }: UseDownloadFilesProps) {
  const boxClient = useBoxClient();
  const { showToast } = useToast();

  return async (fileType: string, downloadFileName?: string) => {
    try {
      const filesToDownload = await boxClient.getFilesByFileType(organizationBoxFolderId, fileType);
      if (filesToDownload.length === 0) {
        return null;
      }
      return await boxClient.createZip(filesToDownload, downloadFileName);
    } catch (error) {
      showToast('error', { message: messages.general.operationFailed });
      return null;
    }
  };
}
