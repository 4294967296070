import { UserRole } from 'enums';
import { messages } from 'i18n';

const userRoleConfig: Record<UserRole, string> = {
  [UserRole.Broker]: messages.roles.userRoles.broker,
  [UserRole.Retailer]: messages.roles.userRoles.retailer,
  [UserRole.Admin]: messages.roles.userRoles.admin,
  [UserRole.Machine]: messages.roles.userRoles.machine,
  [UserRole.BI]: messages.roles.userRoles.bi,
  [UserRole.BackOffice]: messages.roles.userRoles.backOffice,
  [UserRole.CapitolaBroker]: messages.roles.userRoles.capitolaBroker,
};

export const getUserRole = (userRole: UserRole) => userRoleConfig[userRole];
