import { Link as RouterLink, To } from 'react-router-dom-latest';
import { Link, LinkProps } from '@common-components';

interface InternalLinkProps extends LinkProps {
  to: To;
}

export default function InternalLink({
  to,
  sx,
  underline = 'hover',
  color = 'primary',
  children,
  ...props
}: InternalLinkProps) {
  return (
    <Link component={RouterLink} sx={sx} color={color} to={to} underline={underline} {...props}>
      {children}
    </Link>
  );
}
