import { Stack } from '@common-components';
import FileTypeIcon from 'broker/components/common/FileTypeIcon';

interface ViewQuoteActionProps {
  view: () => void;
  hideQuoteActions?: boolean;
}

export function ViewQuoteAction({ view, hideQuoteActions }: ViewQuoteActionProps) {
  return (
    <Stack
      justifyContent="center"
      onClick={hideQuoteActions ? undefined : view}
      sx={{ cursor: hideQuoteActions ? 'default' : 'pointer' }}
      pr={2.5}
    >
      <FileTypeIcon />
    </Stack>
  );
}
