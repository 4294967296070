import { Box } from '@common-components';
import { InformationTooltipType } from 'contexts/information-tooltip/types';
import { useInformationTooltip } from 'hooks';
import { messages } from 'i18n';
import InformationTooltip from 'broker/components/InformationTooltip/InformationTooltip';

export const EmailAttachmentsInfoTooltip = ({ isOpen }: { isOpen: boolean }) => {
  const { shouldShow, hideInformationTooltip } = useInformationTooltip(InformationTooltipType.EmailAttachments);

  if (!shouldShow) {
    return null;
  }

  return (
    <Box sx={{ position: 'absolute', right: '10px', top: '10px', backgroundColor: 'primary.main' }}>
      <InformationTooltip
        title={messages.emailEditor.infoTooltip.title}
        message={messages.emailEditor.infoTooltip.message}
        secondaryMessage={messages.emailEditor.infoTooltip.secondaryMessage}
        isOpen={isOpen}
        height={125}
        placement="left"
        onClose={hideInformationTooltip}
      />
    </Box>
  );
};
