import { Stack } from '@common-components';
import { messages } from 'i18n';
import { CopilotSteps, PartialSubmission } from 'types';
import useCopilotSettings from 'broker/dialogs/SubmissionAutonomousCreation/useCopilotSettings';
import BulletContent from './marketing/BulletContent';

export default function Proposal({ submission }: { submission: PartialSubmission }) {
  const copilotSettings = useCopilotSettings({ submission, copilotStep: CopilotSteps.Proposal });

  const proposalBulletMessages = messages.autonomousFlow.settingsModal.steps.proposal.bullets;

  return (
    <Stack>
      <BulletContent
        prefix={proposalBulletMessages.proposalIsReadyForApproval.prefix}
        bold={proposalBulletMessages.proposalIsReadyForApproval.bold}
      />
      <BulletContent
        prefix={proposalBulletMessages.unrespondedProposalDaysNotificationThreshold.prefix}
        bold={proposalBulletMessages.unrespondedProposalDaysNotificationThreshold.bold(
          copilotSettings?.unrespondedProposalDaysNotificationThreshold || 0,
        )}
        suffix={proposalBulletMessages.unrespondedProposalDaysNotificationThreshold.suffix}
      />
    </Stack>
  );
}
