import { useQuery } from '@tanstack/react-query';
import { DlqClient } from 'clients/capitola/dlq-status';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
import { DlqEvent, DlqRedriveOutput, RedriveDlqInput } from 'types';
import { HttpMethods } from 'utils/http';
import { useImperativeQuery } from './query/useQuery';

export function useRetrieveDlqStatus() {
  const client = useClient(DlqClient);
  const { data: fetchedData } = useQuery([QueryKeys.DlqStatus], {
    queryFn: () => client.custom({ httpMethod: HttpMethods.GET, path: '' }),
  });
  const messages = fetchedData as Record<string, DlqEvent[]>;
  return messages;
}

export function useRedriveDlqMessages() {
  const client = useClient(DlqClient);

  const redriveMessages = useImperativeQuery<DlqRedriveOutput | null, RedriveDlqInput>({
    queryFn: ({ dlq }: RedriveDlqInput) => client.redriveMessages(dlq),
    queryKey: [QueryKeys.DlqStatus],
  });

  return { redriveMessages };
}
