import { useRef } from 'react';
import { useHover } from 'usehooks-ts';
import { alpha, Divider, Stack, Typography } from '@common-components';
import { Layer, Quote } from 'types';
import QuoteActions from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Tower/LayerItem/QuoteList/components/QuoteActions';
import QuoteDetails from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Tower/LayerItem/QuoteList/components/QuoteDetails';
import { ViewQuoteAction } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Tower/LayerItem/QuoteList/components/ViewQuoteAction';
import useQuoteItemActions from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Tower/LayerItem/QuoteList/useQuoteItemActions';

export interface MarketQuoteListItemProps {
  quote: Quote;
  layer: Layer;
  isLast: boolean;
  hideQuoteActions?: boolean;
}

export default function MarketQuoteListItem({ quote, layer, isLast, hideQuoteActions }: MarketQuoteListItemProps) {
  const quoteActions = useQuoteItemActions(quote, layer);
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Stack
      ref={hoverRef}
      bgcolor="blueGrey.50"
      sx={(theme) => ({
        transition: theme.transitions.create(['background-color'], {
          duration: theme.transitions.duration.shortest,
        }),
        '&:hover': {
          bgcolor: alpha(theme.palette.blueGrey[800] as string, 0.06),
        },
      })}
    >
      <Stack direction="row" p={2} justifyContent="space-between" gap={2} minWidth={0}>
        <Stack direction="row" textOverflow="ellipsis" overflow="hidden">
          {quoteActions && <ViewQuoteAction view={quoteActions?.view} hideQuoteActions={hideQuoteActions} />}
          <Stack minWidth={0} gap={1}>
            <Typography variant="body1Bold" noWrap>
              {quote.marketName}
            </Typography>
            <QuoteDetails layer={layer} quote={quote} />
          </Stack>
        </Stack>
        <Stack justifyContent="center" visibility={isHover ? 'visible' : 'hidden'}>
          {quoteActions && <QuoteActions hideQuoteActions={hideQuoteActions} {...quoteActions} />}
        </Stack>
      </Stack>
      {!isLast && <Divider />}
    </Stack>
  );
}
