import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom-latest';
import { Button, Divider, Link, Paper, SearchBar, Stack, Typography } from '@common-components';
import { OrganizationType, UserRole } from 'enums';
import { usePagination, useSearchOrganization, useSearchUser } from 'hooks';
import { useSearchOrganizationDomain } from 'hooks/api/organization-domain';
import { messages } from 'i18n';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import NoItemsFound from 'broker/components/common/NoItemsFound';
import Sort from 'broker/components/common/Sort';
import TablePagination from 'broker/components/common/TablePagination';
import AddAgentToAgencyModal from 'broker/dialogs/AddAgentToAgencyModal';
import AgencyDialog from 'broker/dialogs/AgencyDialog';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import AgenciesHeader from './AgenciesHeader';
import AgenciesTableView from './AgenciesTableView';
import { Agency } from './types';
import useAgenciesFilter, { sorters } from './useAgenciesFilter';

export default function AgenciesPage() {
  const { page, setPage, rowsPerPage, setRowsPerPage, paginationReset } = usePagination();

  const {
    items: organizations,
    count,
    isLoading: isOrganizationsLoading,
  } = useSearchOrganization({
    filter: {
      type: OrganizationType.Retailer,
    },
  });

  const { items: domains, isLoading: isDomainsLoading } = useSearchOrganizationDomain();

  const { items: users, isLoading: isUsersLoading } = useSearchUser({
    filter: {
      role: UserRole.Retailer,
      enabled: true,
    },
  });

  // create a new array of agencies with agents and memo to avoid re-render
  const agencies: Agency[] = useMemo(
    () =>
      organizations.map((item) => ({
        ...item,
        agents: users.filter((user) => user.organization.id === item.id),
        domains: domains.filter((domain) => domain.organizationId === item.id),
      })),
    [organizations, users, domains],
  );

  const { search, setSearch, sortType, setSortType, sortedAndFilteredItems } = useAgenciesFilter(
    agencies,
    paginationReset,
  );

  const agenciesSlice = sortedAndFilteredItems.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage);
  const isLoading = isOrganizationsLoading || isUsersLoading || isDomainsLoading;
  const showItems = agencies.length > 0 || isLoading;
  const navigate = useNavigate();

  const handleChange = (value: number) => {
    setPage(value);
  };

  const createAgency = () => {
    navigate(
      {
        pathname: BROKER_NESTED_ROUTES.AGENCIES.AGENCY,
      },
      {
        routeKey: RouteKey.Agency,
        state: {},
      },
    );
  };

  return (
    <>
      <Stack height={1}>
        <AgenciesHeader
          count={count}
          filteredCount={sortedAndFilteredItems.length}
          isFilterApplied={search.length > 1}
          onCreateAgency={createAgency}
        />
        <Stack flex={1} p={3} gap={2}>
          {showItems && (
            <Stack direction="row" justifyContent="space-between" maxHeight={40} gap={1} alignItems="center">
              <SearchBar
                value={search}
                onChange={setSearch}
                placeholder={messages.agenciesPage.search}
                id="search-user-markets"
                sx={{ minWidth: 320 }}
              />

              <Stack direction="row" gap={1} justifyContent="flex-end">
                <Sort setSortType={setSortType} sortType={sortType} sorters={sorters} />
              </Stack>
            </Stack>
          )}
          {showItems && (
            <Stack
              flex={1}
              overflow="auto"
              component={Paper}
              variant="outlined"
              divider={agencies.length > rowsPerPage ? <Divider /> : undefined}
            >
              <AgenciesTableView
                agencies={agenciesSlice}
                isLoading={isLoading}
                searchInput={search}
                checkedLabels={[]}
                handleAgentClicked={() => {}}
              />

              <TablePagination
                from={page * rowsPerPage - rowsPerPage + 1}
                to={page * rowsPerPage}
                outOf={agencies.length}
                handleChange={handleChange}
                count={Math.ceil(agencies.length / rowsPerPage)}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Stack>
          )}
          {!showItems && (
            <NoItemsFound title={messages.agenciesPage.emptyState}>
              <Link component={Button} underline="none" onClick={createAgency}>
                <Typography variant="h4">{messages.agenciesPage.emptyStateSubTitle}</Typography>
              </Link>
            </NoItemsFound>
          )}
        </Stack>
      </Stack>
      <Routes>
        <Route
          path={BROKER_NESTED_ROUTES.AGENCIES.AGENT}
          element={<AddAgentToAgencyModal isLocationStateRequired={false} />}
        />
      </Routes>
      <Routes>
        <Route path={BROKER_NESTED_ROUTES.AGENCIES.AGENCY} element={<AgencyDialog />} />
      </Routes>
    </>
  );
}
