import { useCallback, useRef } from 'react';

type CallbackType<R> = (...args: any[]) => R;

/**
 * This hook is used to create a ref to a function
 * This is valuable when passing the function to a component that doesn't allow updating the function reference on each render
 *
 * Usage examples:
 * 1. const func = useRefFunction(() => {
 *      console.log(koko);
 *    });
 *    func();
 *
 * 2. With arguments:
 *    function addNumbers(a: number, b: number): number {
 *      return a + b + stateNumber;
 *    }
 *    const addNumbersRefFunction = useRefFunction(addNumbers);
 *    const res = addNumbersRefFunction(3, 5); ---> res=8
 *
 * @param func
 */
export function useRefFunction<R>(func: CallbackType<R>) {
  const functionRef = useRef(func);
  functionRef.current = func;
  return useCallback((...args: any[]) => functionRef.current(...args), []);
}
