import { BoxItem } from 'box-ui-elements/es';
import { ChangeEvent, Ref, useImperativeHandle, useState } from 'react';
import { DroppableArea, Stack } from '@common-components';
import { useBoolean, useToast } from 'hooks';
import { useMutateBoxItems } from 'hooks/api/box';
import { extractErrorMessage, isDefined } from 'utils';
import { FilesListItem } from 'broker/components/common/FilesListItem';
import { EmptyState } from './EmptyState';
import Footer from './Footer';

export interface FilesUploaderRef {
  clearFiles: () => void;
}

interface FilesUploaderProps {
  folderId: string;
  onComplete: (boxItems: BoxItem[]) => void;
  onFilesAdded?: (files: File[]) => void;
  compact?: boolean;
  imperativeRef?: Ref<FilesUploaderRef>;
  subtitle?: string;
}

export function FilesUploader({
  folderId,
  onComplete,
  onFilesAdded,
  compact = false,
  imperativeRef,
  subtitle,
}: FilesUploaderProps) {
  const [files, setFiles] = useState<File[]>([]);
  const [isUploading, { off: stopUploading, on: startUploading }] = useBoolean(false);

  const { uploadFile } = useMutateBoxItems();
  const { showToast } = useToast();

  const addFiles = (newFiles: File[]) => {
    const uniqueFiles = newFiles.filter((newFile) => !files.some((file) => file.name === newFile.name));

    setFiles((prev) => [...prev, ...uniqueFiles]);
    onFilesAdded?.(uniqueFiles);
  };

  const onRemoveFile = (file: File) => {
    setFiles((prev) => prev.filter((fileItem) => fileItem.name !== file.name));
  };

  const clearFiles = () => {
    setFiles([]);
  };

  useImperativeHandle(imperativeRef, () => ({ clearFiles }));

  const handleFilesAdded = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    addFiles(Array.from(e.target.files));
  };

  const uploadFiles = async () => {
    startUploading();
    try {
      const boxFiles = await Promise.all(
        files.map((file) =>
          uploadFile.mutateAsync({
            parentFolderId: folderId,
            fileName: file.name,
            file,
            retryWithDate: true,
          }),
        ),
      );

      onComplete(boxFiles.filter(isDefined));
      setFiles([]);
    } catch (e) {
      showToast('error', { message: extractErrorMessage(e) });
    } finally {
      stopUploading();
    }
  };

  return (
    <DroppableArea onDrop={addFiles}>
      <Stack
        height={1}
        sx={{
          border: `1px dashed`,
          borderColor: 'primary.main',
          borderRadius: 1,
          bgcolor: 'background.light',
        }}
      >
        {files.length === 0 ? (
          <EmptyState compact={compact} handleFilesAdded={handleFilesAdded} subtitle={subtitle} />
        ) : (
          <>
            <Stack overflow="auto" flexGrow={1}>
              {files.map((file) => (
                <FilesListItem key={file.name} fileItem={file} onRemoveFile={isUploading ? undefined : onRemoveFile} />
              ))}
            </Stack>
            <Footer isUploading={isUploading} handleFilesAdded={handleFilesAdded} uploadFiles={uploadFiles} />
          </>
        )}
      </Stack>
    </DroppableArea>
  );
}
