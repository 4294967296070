import { Quote } from 'types';
import { filterBindRequestQuotes, filterPolicyRequestQuotes } from 'utils';
import { QuoteStatusType } from './types';

export function filterAttachmentQuotes(quotes: Quote[], type?: QuoteStatusType) {
  if (type === QuoteStatusType.Bind) {
    return filterBindRequestQuotes(quotes);
  }
  if (type === QuoteStatusType.Policy) {
    return filterPolicyRequestQuotes(quotes);
  }
  return [];
}
