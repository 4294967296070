import { Button } from '@common-components';
import { ShareMenuAction } from 'enums';
import { messages } from 'i18n';
import { InnerPageFooter } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageFooter';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { ShareMenuButton } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingReport/ShareMenuButton';

interface ConceptualTowerFooterProps extends InnerPageProps {
  isLoading: boolean;
  handleCopy: () => Promise<void>;
  handleSendEmail: () => Promise<void>;
  handleOnDownload: () => Promise<void>;
}

export const ConceptualTowerFooter = ({
  onClose,
  isLoading,
  handleCopy,
  handleOnDownload,
  handleSendEmail,
}: ConceptualTowerFooterProps) => (
  <InnerPageFooter>
    <Button variant="outlined" onClick={onClose}>
      {messages.buttons.cancel}
    </Button>
    <ShareMenuButton
      loading={isLoading}
      disabled={isLoading}
      menuActions={{
        [ShareMenuAction.Copy]: async () => {
          await handleCopy();
          onClose();
        },
        [ShareMenuAction.Email]: handleSendEmail,
        [ShareMenuAction.Download]: async () => {
          await handleOnDownload();
          onClose();
        },
      }}
    />
  </InnerPageFooter>
);
