import { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@common-components';
import { FileCopy, Visibility, VisibilityOff } from '@icons';

export interface CopyToClipboardProps {
  value: string;
}

export interface VisibilityToggleProps {
  setVisible: (value: boolean) => void;
  visible: boolean;
}

export interface AdornmentsProps {
  isPassword: boolean;
  setVisible: (value: boolean) => void;
  value: string;
  visible: boolean;
}

export interface ReadOnlyTextFieldProps {
  label: string;
  isPassword?: boolean;
  value: string;
}

export function CopyToClipboard({ value }: CopyToClipboardProps) {
  async function copy() {
    // eslint-disable-next-line no-undef
    await navigator.clipboard.writeText(value);
  }

  return (
    <InputAdornment position="end">
      <IconButton
        icon={FileCopy}
        aria-label="copy to clipboard"
        onClick={() => copy()}
        edge="end"
        color="secondary"
        variant="text"
      />
    </InputAdornment>
  );
}

export function VisibilityToggle({ setVisible, visible }: VisibilityToggleProps) {
  return (
    <InputAdornment position="end">
      <IconButton
        icon={visible ? Visibility : VisibilityOff}
        aria-label="toggle visibility"
        onClick={() => setVisible(!visible)}
        edge="end"
        color="secondary"
        variant="text"
      />
    </InputAdornment>
  );
}

export function Adornments({ isPassword, setVisible, value, visible }: AdornmentsProps) {
  return (
    <>
      {isPassword && <VisibilityToggle visible={visible} setVisible={setVisible} />}
      <CopyToClipboard value={value} />
    </>
  );
}

export default function ReadOnlyTextField({ label, isPassword = false, value }: ReadOnlyTextFieldProps) {
  const [visible, setVisible] = useState(!isPassword);

  return (
    <TextField
      value={value}
      label={label}
      margin="normal"
      fullWidth
      type={visible ? 'text' : 'password'}
      disabled
      InputProps={{
        endAdornment: <Adornments isPassword={isPassword} setVisible={setVisible} value={value} visible={visible} />,
      }}
    />
  );
}
