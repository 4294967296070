import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GridSelectionModel } from '@common-components';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useMarketingReportData } from './useMarketingReportData';
import { getMarketReportsRows, MarketingReportMarketRequest, MarketingReportRow } from './utils';

interface useSelectionModelProps {
  isInformationRequestsReport?: boolean;
}
// This hook functions as a bridge between the server data and the UI view model (selectionModels)
export const useSelectionModel = ({ isInformationRequestsReport }: useSelectionModelProps) => {
  const { markets } = useSubmissionsWorkspace();
  const [selectionModels, setSelectionModels] = useState<GridSelectionModel[]>([]);
  const { marketRequestsByStatus, getPriorityByStatus, saveSelectedStateToServer } = useMarketingReportData({
    isInformationRequestsReport,
  });
  const [selectedCount, setSelectedCount] = useState(0);
  const [marketingReportRows, setMarketingReportRows] = useState<MarketingReportRow[]>([]);

  const didMount = useRef<boolean>(false);

  const setSelectionModel = useCallback((index: number, selectionModel: GridSelectionModel) => {
    setSelectionModels((prevSelectionModels) => {
      const newSelectionModels = [...prevSelectionModels];
      newSelectionModels[index] = selectionModel;

      setSelectedCount(newSelectionModels.reduce((count, current) => (current ? count + current.length : count), 0));

      return newSelectionModels;
    });
  }, []);

  useEffect(() => {
    setMarketingReportRows(getMarketReportsRows(markets, isInformationRequestsReport));
  }, [markets, isInformationRequestsReport]);

  useEffect(() => {
    const createInitialSelectionModels = () => {
      _.forEach(marketRequestsByStatus, (singleArrayByStatus: MarketingReportMarketRequest[]) => {
        setSelectionModel(
          getPriorityByStatus(singleArrayByStatus[0].status),
          singleArrayByStatus
            .filter((marketRequest) => marketRequest.isInMarketingReport)
            .map((marketRequest) => marketRequest.id!), // Only non-ReadyToMarket requests in this array
        );
      });

      didMount.current = true;
    };

    if (!didMount.current && !_.isEmpty(marketRequestsByStatus)) {
      createInitialSelectionModels();
    }
  }, [getPriorityByStatus, marketRequestsByStatus, setSelectionModel]);

  return {
    selectionModels,
    setSelectionModel,
    selectedCount,
    saveSelectionModelsToServer: saveSelectedStateToServer,
    marketRequestsByStatus,
    marketingReportRows,
  };
};
