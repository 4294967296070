import { isEmpty } from 'lodash';
import { memo as reactMemo } from 'react';
import { Checkbox, Chip, darken, Divider, Stack, Tooltip, Typography } from '@common-components';
import { SubmissionMethod } from 'enums';
import { messages } from 'i18n';
import { truncateMultiLineText } from 'utils';
import { ProductDescription } from 'broker/pages/SubmissionWorkspacePage/components/common/ProductDescription';
import { ProductItemProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/types';
import { MethodsConfig } from './methods-config';
import { columnConfig, ProductColumns } from './ProductsTableHeader';

function ProductsTableItem({
  product,
  submissionMarket,
  selectedProducts,
  onProductSelected,
  onProductRemoved,
  quoteExits,
  searchInput = '',
}: ProductItemProps) {
  const { contactIds, externalProductId, submissionPortal } = product;

  const quoteExit = quoteExits?.find((q) => q.product_id === product.externalProductId);

  const availableMethods = [];
  if (externalProductId) {
    availableMethods.push(SubmissionMethod.API);
  }
  if (submissionPortal) {
    availableMethods.push(SubmissionMethod.Portal);
  }
  if (!isEmpty(contactIds)) {
    availableMethods.push(SubmissionMethod.Email);
  }

  const availableMethodsList = availableMethods.map((method) => {
    const chipConfig = MethodsConfig[method];
    const isApiQuoteExitProduct = !!quoteExit && method === SubmissionMethod.API;
    return (
      <Chip
        {...chipConfig}
        key={method}
        textColor={isApiQuoteExitProduct ? 'gray.800' : chipConfig.textColor}
        iconColor={isApiQuoteExitProduct ? 'gray.800' : chipConfig.iconColor}
        disabled={isApiQuoteExitProduct}
        disabledTooltipContent={
          isApiQuoteExitProduct ? quoteExit.status_details[0].status_text.applicant_facing_text : ''
        }
      />
    );
  });

  return (
    <Stack
      divider={<Divider flexItem />}
      px={1.5}
      gap={1.5}
      direction="row"
      alignItems="stretch"
      height={(theme) => theme.spacing(12)}
      width={1}
      sx={{
        cursor: 'pointer',
        ':hover': {
          bgcolor: (theme) => darken(theme.palette.common.white, 0.02),
          '& .edit-icon': {
            visibility: 'visible',
          },
        },
        ':last-child': {
          borderRadius: '0 0 8px 8px',
        },
      }}
    >
      <Tooltip
        tooltipContent={isEmpty(availableMethods) ? messages.addProductsPage.noMethodsTooltip : ''}
        placement="right"
      >
        <Stack
          flex={columnConfig[ProductColumns.SelectProduct].flex}
          overflow="hidden"
          direction="row"
          alignItems="center"
        >
          <Checkbox
            disableRipple
            color="secondary"
            checked={product.id in selectedProducts}
            onChange={
              product.id in selectedProducts ? () => onProductRemoved(product.id) : () => onProductSelected(product)
            }
            disabled={isEmpty(availableMethods)}
          />
        </Stack>
      </Tooltip>

      <Stack direction="row" flex={columnConfig[ProductColumns.Product].flex} alignItems="center" overflow="hidden">
        <ProductDescription product={product} submissionMarket={submissionMarket} searchInput={searchInput} />
      </Stack>

      <Stack
        gap={0.5}
        direction="row"
        flex={columnConfig[ProductColumns.AvailableVia].flex}
        alignItems="center"
        overflow="hidden"
      >
        {availableMethodsList.length > 0 ? (
          availableMethodsList
        ) : (
          <Typography>{messages.addProductsPage.methodsDropdown.notAvailableYet}</Typography>
        )}
      </Stack>

      <Stack direction="row" flex={columnConfig[ProductColumns.Match].flex} alignItems="center" overflow="hidden">
        <Chip rounded label={messages.addProductsPage.comingSoon} color="secondary" />
      </Stack>
      <Stack
        py={2}
        maxWidth={columnConfig[ProductColumns.Notes].maxWidth}
        flex={columnConfig[ProductColumns.Notes].flex}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        overflow="hidden"
      >
        <Typography
          height={1}
          overflow="hidden"
          sx={{
            ...truncateMultiLineText(3),
          }}
          variant="body2"
          color={!product.note ? 'text.disabled' : 'text.primary'}
          title={product.note}
        >
          {product.note ?? ''}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default reactMemo(ProductsTableItem);
