import { Box, Stack } from '@common-components';
import { boxBackground } from 'themes/colors';
import ContentPreview from 'broker/components/box/ContentPreview';

export interface CompareQuotePreviewProps {
  fileIds: string[];
  boxFolderId: string;
}

export function CompareQuotePreview({ fileIds, boxFolderId }: CompareQuotePreviewProps) {
  return (
    <Stack flex={1}>
      <Box bgcolor={boxBackground} sx={{ height: 1, visibility: 'visible', px: 1, py: 2 }}>
        <ContentPreview
          filesMenuProps={{ collectionFileIds: fileIds, boxFolderId }}
          fileId={fileIds[0]}
          hasHeader={false}
          sx={{ height: 1 }}
        />
      </Box>
    </Stack>
  );
}
