import { useCallback, useContext, useMemo } from 'react';
import { HeraldApiClient, NO_TOKEN_SKIP_FETCH } from 'clients';
import { ErrorContext } from 'contexts';
import { logger } from 'utils';
import { useGetHeraldAccessToken } from './api/herald';

/**
 * A hook for creating a HeraldApiClient instance.
 */
export function useHeraldApiClient() {
  const { onError } = useContext(ErrorContext);
  const { getOrCreateAccessToken } = useGetHeraldAccessToken();

  const getToken = useCallback(async () => {
    const token = await getOrCreateAccessToken();

    // This should never happen, but if it does, we should log it and skip the fetch
    if (!token) {
      logger.log('error', 'failed to retrieve token, skipping herald fetch');
      return NO_TOKEN_SKIP_FETCH;
    }

    return token.accessToken;
  }, [getOrCreateAccessToken]);

  return useMemo(
    () =>
      new HeraldApiClient({
        getToken,
        onError,
      }),
    [getToken, onError],
  );
}
