import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';

export default function SubmissionViewNoResults() {
  return (
    <Stack height="100%" gap={0.5} order={1} alignItems="center" justifyContent="center" py={10}>
      <Typography variant="body1Bold" color="text.secondary">
        {messages.submissionsView.noResults.title}
      </Typography>
      <Typography variant="body1" color="typography.nonEssential">
        {messages.submissionsView.noResults.subtitle}
      </Typography>
    </Stack>
  );
}
