// create custom hook
import { useEffect, useState } from 'react';
import { BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';
import {
  AdditionalToolboxTabs,
  BASE_TOOLBOX_TABS,
  BaseToolboxTabs,
  ToolboxTabs,
  WorkspaceToolboxTabsRoutesValues,
} from 'broker/pages/SubmissionWorkspacePage/types';
import { workspaceToolboxRoutesTabsConfig } from './config';

export const useToolbox = ({
  activeTab,
  currentWorkspacePage,
}: {
  activeTab: ToolboxTabs | undefined;
  currentWorkspacePage: WorkspaceToolboxTabsRoutesValues;
}) => {
  const [currentWorkspacePageForcedOpened, setCurrentWorkspacePageForcedOpened] = useState<
    WorkspaceToolboxTabsRoutesValues | undefined
  >();

  const updateQueryParam = useUpdateQueryParam();

  // Reset tab to default if it's not in the current toolbox
  useEffect(() => {
    if (
      activeTab &&
      !Object.values(BASE_TOOLBOX_TABS).includes(activeTab as BaseToolboxTabs) &&
      !workspaceToolboxRoutesTabsConfig[currentWorkspacePage].additionalTabs?.includes(
        activeTab as AdditionalToolboxTabs,
      )
    ) {
      updateQueryParam({
        addParams: [
          {
            queryParam: BrokerUrlParams.ACTIVE_TAB,
            value: workspaceToolboxRoutesTabsConfig[currentWorkspacePage].defaultTab,
          },
        ],
      });
    }
  }, [activeTab, currentWorkspacePage, updateQueryParam]);

  // Force open toolbox if currentWorkspacePage has forceOpen set to true
  useEffect(() => {
    if (
      workspaceToolboxRoutesTabsConfig[currentWorkspacePage].forceOpen &&
      currentWorkspacePageForcedOpened !== currentWorkspacePage
    ) {
      setCurrentWorkspacePageForcedOpened(currentWorkspacePage);
      updateQueryParam({
        addParams: [
          {
            queryParam: BrokerUrlParams.ACTIVE_TAB,
            value: workspaceToolboxRoutesTabsConfig[currentWorkspacePage].defaultTab,
          },
        ],
        removeParams: [BrokerUrlParams.SELECTED_EMAIL, BrokerUrlParams.SELECTED_FILE],
      });
    }
  }, [updateQueryParam, currentWorkspacePage, currentWorkspacePageForcedOpened]);

  // reset currentWorkspacePageForcedOpened on page change
  useEffect(() => {
    if (currentWorkspacePageForcedOpened && currentWorkspacePageForcedOpened !== currentWorkspacePage) {
      setCurrentWorkspacePageForcedOpened(undefined);
    }
  }, [currentWorkspacePage, currentWorkspacePageForcedOpened]);
};
