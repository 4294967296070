import { Box, Stack } from '@common-components';
import { FileType } from 'enums';

import CompareQuotesFilesBucketAction from './CompareQuotesFilesBucketAction';
import FilesBucket from './FilesBucket';

export default function FilesViewer() {
  return (
    <Stack>
      <Box height={1} overflow="hidden" py={1} px={6} sx={{ position: 'relative', overflowY: 'auto' }}>
        <Stack height={1} maxWidth={640} mx="auto">
          <Stack gap={6}>
            <FilesBucket fileType={FileType.CapitolaQuote} />
            <FilesBucket fileType={FileType.QuoteComparison} component={<CompareQuotesFilesBucketAction />} />
            <FilesBucket fileType={FileType.MarketingReport} />
            <FilesBucket />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
