import { useCallback, useMemo, useState } from 'react';

export function useBoolean(defaultValue?: boolean) {
  const [state, setState] = useState<boolean>(defaultValue || false);

  const on = useCallback(() => {
    setState(true);
  }, []);
  const off = useCallback(() => {
    setState(false);
  }, []);
  const toggle = useCallback(() => {
    setState((prev) => !prev);
  }, []);
  const set = useCallback((nextState: boolean) => {
    setState(nextState);
  }, []);

  const booleanState = useMemo(
    () =>
      [
        state,
        {
          on,
          off,
          toggle,
          set,
        },
      ] as const,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  return booleanState;
}
