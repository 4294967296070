import { BackdropProps, Backdrop as MuiBackdrop, Portal, Theme } from '@common-components';
import { uniteStyles } from 'utils';

const backdropContainer = document.getElementById('backdrop-root');

export default function Backdrop({ open, sx, children }: BackdropProps) {
  return (
    <Portal container={backdropContainer}>
      <MuiBackdrop sx={uniteStyles({ zIndex: (theme: Theme) => theme.zIndex.tooltip + 1 }, sx)} open={open}>
        {children}
      </MuiBackdrop>
    </Portal>
  );
}
