import { BoxTemplateLabels, FileType } from 'enums';
import { ITEM_NAME_TO_LONG_ERROR, useMutateBoxItems } from 'hooks/api/box';
import { PartialSubmission, Quote } from 'types';
import { InsightsData } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/form-methods/types';
import { useGeneratePDF } from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/pdf-creator-util';
import { getPdfContent } from './pdf-content';

function getFileName(quotes: Quote[]) {
  return `Comparison_${quotes.map((quote) => quote.marketName?.toLowerCase()).join('_')}.pdf`;
}

function getShortFileName(quotes: Quote[]) {
  return `Comparison_${quotes.length}_quotes.pdf`;
}

function getMarketIds(quotes: Quote[]) {
  return quotes.map((quote) => quote.submissionMarketId).join(',');
}

export function useGenerateAndUploadComparisonPDF() {
  const { uploadFileWithMetadata } = useMutateBoxItems();
  const generatePDF = useGeneratePDF();

  async function generateAndUploadComparisonPDF(
    submission: PartialSubmission,
    quotes: Quote[],
    insights: InsightsData,
  ) {
    const content = getPdfContent({ submission, quotes, insights });
    const filePdfBlob = await generatePDF(content, 'landscape');

    const marketIds = getMarketIds(quotes);
    try {
      return await uploadFileWithMetadata({
        parentFolderId: submission.boxFolderId,
        fileName: getFileName(quotes),
        file: filePdfBlob,
        retryWithDate: true,
        metadata: { [BoxTemplateLabels.FileType]: FileType.QuoteComparison, [BoxTemplateLabels.MarketId]: marketIds },
      });
    } catch (error) {
      const e = error as Error;
      if (e.message === ITEM_NAME_TO_LONG_ERROR) {
        return await uploadFileWithMetadata({
          parentFolderId: submission.boxFolderId,
          fileName: getShortFileName(quotes),
          file: filePdfBlob,
          retryWithDate: true,
          metadata: { [BoxTemplateLabels.FileType]: FileType.QuoteComparison, [BoxTemplateLabels.MarketId]: marketIds },
        });
      }
      throw error;
    }
  }

  return generateAndUploadComparisonPDF;
}
