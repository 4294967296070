import { uniteStyles } from 'utils';
import { DialogContent as MuiDialogContent, DialogContentProps as MuiDialogContentProps } from 'components/mui-index';

interface DialogContentProps extends MuiDialogContentProps {
  darkBackground?: boolean;
  disablePaddings?: boolean;
}

export const dialogContentPx = 2;

export default function DialogContent({
  children,
  sx,
  darkBackground = false,
  disablePaddings = false,
  ...props
}: DialogContentProps) {
  return (
    <MuiDialogContent
      sx={uniteStyles(
        {
          display: 'flex',
          flexDirection: 'column',
          px: disablePaddings ? 0 : dialogContentPx,
          py: disablePaddings ? 0 : 1.5,
          bgcolor: darkBackground ? 'grey.100' : 'inherit',
        },
        sx,
      )}
      {...props}
    >
      {children}
    </MuiDialogContent>
  );
}
