export enum HotjarEvents {
  ErrorLogged = 'error_logged',
  AddProducts = 'add_products',
  SubmitToMarket = 'submit_to_market',
  SubmitProductViaPortal = 'submit_product_via_portal',
  SubmitProductViaApi = 'submit_product_via_api',
  InformationRequested = 'information_requested',
  MarketDeclined = 'market_declined',
  AddQuote = 'add_quote',
  AttachQuote = 'attach_quote',
  ChangeQuoteStatus = 'change_quote_status',
  RejectQuote = 'reject_quote',
  CompareQuotes = 'compare_quotes',
  SendEmail = 'send_email',
  RequestBinder = 'request_binder',
  RequestPolicy = 'request_policy',
  AddBinder = 'add_binder',
  AddPolicy = 'add_policy',
  AddFile = 'add_file',
  PreviewFile = 'preview_file',
  RenameFile = 'rename_file',
  EditLabel = 'edit_label',
  DownloadFile = 'download_file',
  DeleteFile = 'delete_file',
  EmailDownload = 'email_download',
  EmailReply = 'email_reply',
  EmailDuplicate = 'email_duplicate',
  DownloadCustomerEmails = 'download_customer_emails',
  DownloadMarketEmails = 'download_market_emails',
  DownloadInboundEmails = 'download_inbound_emails',
  DownloadAllEmailsAndFiles = 'download_all_files_emails',
  EditMarketContact = 'edit_market_contact',
  EditMarketRiskAppetite = 'edit_market_risk_appetite',
  EditMarketMemo = 'edit_market_memo',
  EditMarketCategories = 'edit_market_categories',
  SubmissionsInsuredFilter = 'submissions_insured_filter',
  SubmissionsAssigneeFilter = 'submissions_assignee_filter',
  SubmissionsCoverageLineFilter = 'submissions_coverage_line_filter',
  SubmissionsStatusFilter = 'submissions_status_filter',
  SubmissionsTypeFilter = 'submissions_type_filter',
  SubmissionsClearFilters = 'submissions_clear_filters',
  SubmissionsSearch = 'submissions_search',
  SubmissionsDatesRangeFilter = 'submissions_dates_range_filter',
  EmailIntegrationConnect = 'email_integration_connect',
  EmailIntegrationDontConnect = 'email_integration_dont_connect',
  EmailIntegrationSettingsToggleOn = 'email_integration_settings_toggle_on',
  EmailIntegrationSettingsToggleOff = 'email_integration_settings_toggle_off',
  RequestFileSync = 'request_file_sync',
  MarkFileAsSynced = 'mark_file_as_synced',
  EmailLabeled = 'email_labeled',
  AddMarkets = 'add_markets',
  SubmissionsCustomerFilter = 'submissions_customer_filter',
  RemoveDeclination = 'remove_declination',
  RemoveQuote = 'remove_quote',
  ViewQuote = 'view_quote',
  EditQuote = 'edit_quote',
  EditBinder = 'edit_binder',
  ViewBinder = 'view_binder',
  EditPolicy = 'edit_policy',
  ViewPolicy = 'view_policy',
  RemoveInformationRequested = 'remove_information_requested',
  SendAdditionalInformationToMarket = 'send_additional_information_to_market',
  RemoveBinder = 'remove_binder',
}
