import { Stack } from '@common-components';
import { FormMode } from 'enums';
import { Suggestion } from 'types';
import FormDatePicker from 'components/hookFormComponents/FormDatePicker';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { detailsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/detailsFieldsConfig';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';
import { CommissionTextField } from './CommissionTextField';

interface PolicyDetailsProps {
  mode: FormMode;
  extractedDataSuggestions?: Record<string, Suggestion>;
}
export default function PolicyDetails({ mode, extractedDataSuggestions = {} }: PolicyDetailsProps) {
  const isDisabled = mode === FormMode.view;
  return (
    <Stack gap={1}>
      <FormTextField
        disabled={isDisabled}
        fullWidth
        {...detailsFormFieldsConfig[DetailsFieldsNames.PolicyNumber]}
        optional
      />
      <Stack direction="row" gap={2}>
        <FormDatePicker
          disabled={isDisabled}
          fullWidth
          {...detailsFormFieldsConfig[DetailsFieldsNames.PolicyEffectiveDate]}
          optional
        />
        <FormDatePicker
          disabled={isDisabled}
          fullWidth
          {...detailsFormFieldsConfig[DetailsFieldsNames.PolicyExpirationDate]}
          suggestion={extractedDataSuggestions[DetailsFieldsNames.PolicyExpirationDate]}
          optional
        />
      </Stack>
      <CommissionTextField isDisabled={isDisabled} />
    </Stack>
  );
}
