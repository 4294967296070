import { compact } from 'lodash';
import { PartialSubmission } from 'types';
import { htmlToPdfMakeWrapper, logger } from 'utils';

interface PdfContentProps {
  submission: PartialSubmission;
  editorContent?: string;
}

export const getPdfContent = ({ submission, editorContent }: PdfContentProps) => {
  logger.log('info', {
    message: 'getPdfContent arguments',
    submissionId: submission.id,
  });

  return compact([editorContent ? htmlToPdfMakeWrapper(editorContent) : undefined]);
};
