import { useMemo } from 'react';
import { flushSync } from 'react-dom';
import { Box, Button, Stack } from '@common-components';
import { messages } from 'i18n';
import { MarketingUpdateRoutes } from 'broker/broker-routes';
import Stepper from 'broker/components/common/Stepper';
import { RouteKey } from 'broker/enums';
import { useNavigate, useNavigateToStep } from 'broker/hooks';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { PdfGenerationStatus } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/types';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import { marketingUpdateSteps } from './types';

interface FooterProps extends InnerPageProps {
  currentStep: MarketingUpdateRoutes;
  onSaveForLater: () => Promise<void>;
  isSubmitting: boolean;
  pdfGenerationStatus: PdfGenerationStatus;
  navigateToMarketingUpdateEmail: () => void;
  editorContent: string | undefined;
  omitEmailStep?: boolean;
}

export default function Footer({
  currentStep,
  onSaveForLater,
  isSubmitting,
  pdfGenerationStatus,
  setIsDirty,
  navigateToMarketingUpdateEmail,
  editorContent,
  omitEmailStep,
  onClose,
}: FooterProps) {
  const navigateToStep = useNavigateToStep();
  const navigate = useNavigate();
  const { marketingWorkspaceUrl } = useWorkspaceUrls();
  const finalStep = marketingUpdateSteps[marketingUpdateSteps.length - 1];

  const handleSaveForLater = async (shouldClose: boolean) => {
    flushSync(() => {
      setIsDirty(false);
    });
    await onSaveForLater();

    if (omitEmailStep) {
      onClose();
      return;
    }

    if (shouldClose) {
      navigate(marketingWorkspaceUrl, { routeKey: RouteKey.Marketing, state: {} });
    }
  };

  const handleChangeStep = async (index: number) => {
    // final step, save for later and navigate to email editor
    if (index === marketingUpdateSteps.length) {
      await handleSaveForLater(false);

      if (omitEmailStep) {
        onClose();
        return;
      }

      navigateToMarketingUpdateEmail();
      return;
    }
    navigateToStep(marketingUpdateSteps[index], {
      routeKey: RouteKey.MarketingUpdate,
    });
  };

  const getSubmitButtonText = () => {
    if (currentStep === finalStep && omitEmailStep) {
      return messages.marketingUpdate.buttons.saveMarketingUpdate;
    }

    if (currentStep === finalStep) {
      return messages.marketingUpdate.buttons.nextComposeEmail;
    }
    const nextStep = marketingUpdateSteps[marketingUpdateSteps.indexOf(currentStep) + 1];
    switch (nextStep) {
      case MarketingUpdateRoutes.Upload:
        return messages.marketingUpdate.buttons.nextGeneratePDF;
      default:
        return '';
    }
  };

  const disabledButtons = useMemo(
    () =>
      currentStep === MarketingUpdateRoutes.Upload &&
      [PdfGenerationStatus.Loading, PdfGenerationStatus.Error].includes(pdfGenerationStatus),
    [currentStep, pdfGenerationStatus],
  );

  return (
    <Stack direction="row" gap={1} justifyContent="center">
      <Box flex={1} />
      <Stack flex={1} alignItems="center">
        <Stepper
          activeStepIndex={marketingUpdateSteps.indexOf(currentStep)}
          stepsLength={2}
          handleChangeStep={handleChangeStep}
        />
      </Stack>
      <Stack flex={1} direction="row" gap={1} justifyContent="flex-end" alignItems="center">
        <Button
          variant="outlined"
          onClick={() => handleSaveForLater(true)}
          loading={isSubmitting}
          disabled={disabledButtons}
        >
          {messages.marketingUpdate.buttons.saveForLater}
        </Button>
        <Button
          variant="contained"
          onClick={() => handleChangeStep(marketingUpdateSteps.indexOf(currentStep) + 1)}
          loading={isSubmitting}
          disabled={disabledButtons || !editorContent}
        >
          {getSubmitButtonText()}
        </Button>
      </Stack>
    </Stack>
  );
}
