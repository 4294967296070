import { FileMetadata, Layer, Quote } from 'types';

export function sortLayersByAttachmentPoint(items: Layer[]) {
  return [...items].sort((a, b) => {
    if (typeof a.attachmentPoint === 'undefined') {
      return -1;
    }
    if (typeof b.attachmentPoint === 'undefined') {
      return 1;
    }
    if (a.attachmentPoint === b.attachmentPoint) {
      return 0;
    }
    return a.attachmentPoint! < b.attachmentPoint! ? -1 : 1;
  });
}

export function sortSelectedQuoteByAttachmentPoint(items: Quote[]) {
  return [...items].sort((a, b) => {
    if (typeof a.layer?.attachmentPoint === 'undefined') {
      return 1;
    }
    if (typeof b.layer?.attachmentPoint === 'undefined') {
      return -1;
    }
    if (a.layer?.attachmentPoint === b.layer?.attachmentPoint) {
      return 0;
    }
    return a.layer?.attachmentPoint! < b.layer?.attachmentPoint! ? 1 : -1;
  });
}

interface ItemWithDateField {
  date: Date;
}

export function sortByDate<T extends ItemWithDateField>(items: T[]): T[] {
  return [...items].sort((a, b) => (a.date < b.date ? 1 : -1));
}

export const sortBySyncRequested = (a?: FileMetadata) => {
  if (a?.syncRequestDate && !a?.syncedDate) {
    return -1;
  }
  return 1;
};
