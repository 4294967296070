import { SubmissionTaskStatus } from 'enums';
import { messages } from 'i18n';
import theme from 'themes';

export interface SubmissionTaskStatusConfigProps {
  color?: string;
  text: string;
}

type SubmissionTaskStatusConfig = Record<SubmissionTaskStatus, SubmissionTaskStatusConfigProps>;

export const submissionTaskStatusConfig: SubmissionTaskStatusConfig = {
  [SubmissionTaskStatus.ToDo]: {
    color: theme.palette.teal[100],
    text: messages.submissionWorkspace.preMarketing.statuses[SubmissionTaskStatus.ToDo],
  },
  [SubmissionTaskStatus.InProgress]: {
    color: theme.palette.goldYellow[100],
    text: messages.submissionWorkspace.preMarketing.statuses[SubmissionTaskStatus.InProgress],
  },
  [SubmissionTaskStatus.Done]: {
    color: theme.palette.aquaGreen[100],
    text: messages.submissionWorkspace.preMarketing.statuses[SubmissionTaskStatus.Done],
  },
};
