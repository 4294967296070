import { ChangeEvent, useState } from 'react';
import { TextField } from './TextField';

export interface DialogTextFieldProps {
  placeholder: string;
  setValue: (value: string) => void;
  value: string | null;
  autoFocus?: boolean;
  type?: string;
  margin?: 'dense' | 'normal' | 'none';
  isValid?: boolean;
}

export default function DialogTextField({
  placeholder,
  setValue,
  value,
  autoFocus,
  type = 'string',
  margin = 'dense',
  isValid = true,
}: DialogTextFieldProps) {
  const [touched, setTouched] = useState(false);
  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { value: newValue } = event.target;
    setValue(newValue);
  }

  return (
    <TextField
      autoFocus={autoFocus}
      fullWidth
      margin={margin}
      placeholder={placeholder}
      onChange={onChange}
      value={value || ''}
      type={type}
      sx={{ border: isValid || !touched ? '' : '1px solid red' }}
      onBlur={() => {
        setTouched(true);
      }}
    />
  );
}
