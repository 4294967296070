import { ActionDialog, Typography } from '@common-components';
import { messages } from 'i18n';
import { Organization } from 'types';

interface ConvertMarketTypeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConvert: () => Promise<void>;
  organization: Organization;
}

export function ConvertMarketTypeDialog({ isOpen, onClose, organization, onConvert }: ConvertMarketTypeDialogProps) {
  const convertOrganizationType = async () => {
    await onConvert();
    onClose();
  };

  const { title, convert, message } = messages.admin.organizationPage.convertMarketTypeDialog;

  return (
    <ActionDialog
      actionLabel={convert}
      open={isOpen}
      onClose={onClose}
      onAction={convertOrganizationType}
      title={title(organization)}
    >
      <Typography variant="body2">{message(organization)}</Typography>
    </ActionDialog>
  );
}
