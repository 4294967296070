import { useEffect, useState } from 'react';
import { EmailTemplateMetadata, EmailTemplateType } from 'enums';
import { EmailTemplate } from 'types';

export const useTemplateFields = (emailTemplate?: EmailTemplate) => {
  const [type, setTypeInner] = useState(emailTemplate?.type);
  const [name, setName] = useState(emailTemplate?.name ?? '');
  const [subject, setSubject] = useState(emailTemplate?.subject ?? '');
  const [metadata, setMetadata] = useState<EmailTemplateMetadata>({
    isIncumbent: emailTemplate?.metadata?.isIncumbent ?? false,
  });

  const updateMetadata = (newMetadata: Partial<EmailTemplateMetadata>) => {
    setMetadata({ ...metadata, ...newMetadata });
  };

  useEffect(() => {
    if (emailTemplate) {
      setTypeInner(emailTemplate.type);
      setName(emailTemplate.name);
      setSubject(emailTemplate.subject);
      updateMetadata(emailTemplate.metadata ?? {});
    }
    // We don't want to re-set the fields whenever 'updateMetadata' changes (which is whenever metadata changes).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplate]);

  const setType = (value: string) => {
    const templateType = Object.entries(EmailTemplateType).find(([, val]) => val === value)?.[0] as EmailTemplateType;
    setTypeInner(templateType);
  };

  return { type, setType, name, setName, subject, setSubject, metadata, setMetadata };
};
