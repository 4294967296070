import moment from 'moment/moment';
import { Box, Popover, StaticDatePicker, TextField } from '@common-components';
import { OptionsMenuState } from 'hooks';
import { datePickerDateFormat } from 'utils';

interface TaskItemDatePickerProps extends Pick<OptionsMenuState, 'anchorEl' | 'isMenuOpen' | 'closeMenu'> {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
}
export default function TaskItemDatePicker({
  anchorEl,
  isMenuOpen,
  closeMenu,
  selectedDate,
  setSelectedDate,
}: TaskItemDatePickerProps) {
  return (
    <Popover
      disableEnforceFocus
      disableScrollLock
      autoFocus={false}
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box
        sx={{
          '& .PrivatePickersToolbar-root': {
            display: 'none',
          },
        }}
      >
        <StaticDatePicker
          onChange={(date) => {
            if (date) {
              // The DesktopDatePicker provides the date in the local timezone, but we want to treat it as UTC.
              const dateAsString = date.format(datePickerDateFormat);
              const utcDate = moment.utc(dateAsString, datePickerDateFormat);

              setSelectedDate(utcDate.toDate());
            } else {
              setSelectedDate(date);
            }
            closeMenu();
          }}
          value={moment.utc(selectedDate)}
          renderInput={(params) => (
            <div style={{ display: 'none' }}>
              <TextField {...params} />
            </div>
          )}
        />
      </Box>
    </Popover>
  );
}
