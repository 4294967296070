import { compact } from 'lodash';
import { EmailMessageRecipientType, EmailTemplateType, isCustomerOrganization } from 'enums';
import { useSearchUserMarket } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { Nullable } from 'utils';
import { getReplyOrganization, getReplyRecipientGroup } from 'broker/components/Emails/recipient-utils';
import { UnifiedEmail } from 'broker/components/Emails/UnifiedEmail';
import { useCcState } from 'broker/components/Emails/useCcState';
import { useGetAllTeamMembersAsRecipients } from 'broker/components/Emails/useGetAllTeamMembersAsRecipients';
import { ReplyMode } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EmailReplyInnerPage/types';
import { renderFollowupBody } from './utils';

export interface usePreviewEmailProps {
  replyMode: ReplyMode;
  unifiedEmail: UnifiedEmail | undefined;
  submission: Nullable<PartialSubmission>;
  preSelectedTemplateType?: EmailTemplateType;
}

export default function useReplyEmail({
  replyMode,
  unifiedEmail,
  submission,
  preSelectedTemplateType,
}: usePreviewEmailProps) {
  const { items: userMarkets, isLoading: isUserMarketsLoading } = useSearchUserMarket({ filter: {} });
  const { availableTeammates } = useCcState([]);
  let repliedThreadSubject;
  let repliedThreadBody;

  const shouldFetchTeamMembers =
    !!unifiedEmail && !!submission && isCustomerOrganization(getReplyOrganization(unifiedEmail).organization?.type);
  const { isLoading: isLoadingTeamMembers, allTeamMembersAsRecipients: retailerTeamMembers } =
    useGetAllTeamMembersAsRecipients(shouldFetchTeamMembers, submission?.team?.id);

  if (!unifiedEmail || !submission || isUserMarketsLoading || isLoadingTeamMembers) {
    return {
      isLoading: true,
    };
  }

  const getRecipientGroup = () => {
    // Duplicate can only be on outbound emails (so it's sufficient to check recipientOrganization in this case)
    if (replyMode === ReplyMode.Duplicate && !isCustomerOrganization(unifiedEmail.recipientOrganization?.type)) {
      return null;
    }

    return getReplyRecipientGroup(unifiedEmail, submission, userMarkets, retailerTeamMembers);
  };

  const getCcTeammates = () =>
    compact(
      unifiedEmail.recipients
        .filter((recipient) => recipient.type === EmailMessageRecipientType.Cc)
        .map((ccTeamMate) =>
          availableTeammates.find(
            (teammate) => teammate.email.toLocaleLowerCase() === ccTeamMate.address.toLocaleLowerCase(),
          ),
        ),
    );
  const recipientGroup = getRecipientGroup();
  const ccTeammates = getCcTeammates() || [];
  switch (replyMode) {
    case ReplyMode.Duplicate:
      repliedThreadSubject = unifiedEmail.subject;
      repliedThreadBody = unifiedEmail.unresolvedContent;
      break;
    case ReplyMode.FollowUp:
      repliedThreadSubject = messages.sentEmailDialog.getReSubject(unifiedEmail.subject);
      repliedThreadBody = renderFollowupBody(unifiedEmail!, preSelectedTemplateType);
      break;
    default:
  }

  const title = messages.sentEmailDialog.title(unifiedEmail.createdAt!);
  const subtitle = '';

  return {
    recipientGroup,
    ccTeammates,
    repliedThreadSubject,
    repliedThreadBody,
    title,
    subtitle,
    isLoading: false,
  };
}
