import { useState } from 'react';
import { useBoolean, useMatchNavigation } from 'hooks';
import { messages } from 'i18n';
import { BROKER_NESTED_ROUTES, MarketingUpdateRoutes } from 'broker/broker-routes';
import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import { useNavigate, useNavigateBack } from 'broker/hooks';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { PdfGenerationStatus } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/types';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import Footer from './components/Footer';
import Steps from './components/Steps';
import { useGenerateTable } from './components/steps/Compose/useGenerateTable';

export default function MarketingUpdate() {
  const navigate = useNavigate();
  const htmlTable = useGenerateTable();
  const { partialSubmission, updateSubmission } = useSubmissionsWorkspace();
  const { marketingWorkspaceUrl, marketingUpdateEmailUrl } = useWorkspaceUrls();
  const [pdfGenerationStatus, setPdfGenerationStatus] = useState<PdfGenerationStatus>(PdfGenerationStatus.Idle);
  const currentStep = useMatchNavigation(MarketingUpdateRoutes, MarketingUpdateRoutes.Compose);
  const [editorContent, setEditorContent] = useState<string | undefined>(
    partialSubmission?.draftMarketingUpdate?.pdfContent || htmlTable,
  );
  const [pdfFileId, setPdfFileId] = useState<string | undefined>(partialSubmission?.draftMarketingUpdate?.pdfFileId);
  const [isSubmitting, { on: startSubmit, off: stopSubmit }] = useBoolean(false);
  const initialPdfFileId = partialSubmission?.draftMarketingUpdate?.pdfFileId;
  const navigateBack = useNavigateBack();

  const saveForLater = async () => {
    startSubmit();
    await updateSubmission(partialSubmission!.id, {
      draftMarketingUpdate: {
        ...partialSubmission?.draftMarketingUpdate,
        pdfContent: editorContent,
        ...(pdfFileId && { pdfFileId }),
      },
    });
    stopSubmit();
  };

  const navigateToMarketingUpdateEmail = () => {
    if (!pdfFileId) {
      return;
    }
    navigate(marketingUpdateEmailUrl, {
      routeKey: RouteKey.MarketingUpdateEmail,
      state: {
        pdfFileId,
      },
    });
  };

  if (!partialSubmission) {
    return null;
  }

  return (
    <WithRouteLocationState routeKey={RouteKey.MarketingUpdate} isStateRequired>
      {(locationState) => (
        <InnerPageLayout
          title={messages.marketingUpdate.title}
          beforeNavigation={async (transition) =>
            transition.location.pathname.includes(BROKER_NESTED_ROUTES.WORKSPACE.MARKETING_UPDATE)
          }
          onCloseOverride={async () => {
            if (locationState.omitEmailStep) {
              navigateBack();
              return;
            }
            navigate(marketingWorkspaceUrl, { routeKey: RouteKey.Marketing, state: {} });
          }}
          footer={(innerPageProps: InnerPageProps) => (
            <Footer
              omitEmailStep={!!locationState.omitEmailStep}
              currentStep={currentStep}
              onSaveForLater={saveForLater}
              isSubmitting={isSubmitting}
              pdfGenerationStatus={pdfGenerationStatus}
              navigateToMarketingUpdateEmail={navigateToMarketingUpdateEmail}
              editorContent={editorContent}
              {...innerPageProps}
            />
          )}
        >
          {(innerPageProps: InnerPageProps) => (
            <Steps
              editorContent={editorContent}
              setEditorContent={setEditorContent}
              pdfGenerationStatus={pdfGenerationStatus}
              setPdfGenerationStatus={setPdfGenerationStatus}
              submission={partialSubmission}
              pdfFileId={pdfFileId}
              setPdfFileId={setPdfFileId}
              initialPdfFileId={initialPdfFileId}
              {...innerPageProps}
            />
          )}
        </InnerPageLayout>
      )}
    </WithRouteLocationState>
  );
}
