import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';
import { AnyObject } from 'types';

/*
 * Testing Sentry locally: to enable writing to Sentry from local development -
 * 1. Set the environment variable REACT_APP_SENTRY_DSN to the value from .env.development
 * 2. remove `if (process.env.NODE_ENV !== 'production')` block of code from frontend/src/hooks/useLoggerInitialization.tsx
 * Revert when done testing.
 */
const addCustomVariable = (key: string, value: string) => {
  Sentry.setTag(key, value);
};

const removeCustomVariable = (key: string) => {
  Sentry.setTag(key, undefined);
};

const log = (severity: SeverityLevel, data: Error | (AnyObject & { message: string }) | string) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('New logged data', { severity, data });
  }

  if (data instanceof Error) {
    Sentry.captureException(data);
  } else if (typeof data === 'string') {
    Sentry.captureMessage(data, severity);
  } else {
    const { message, ...extra } = data;
    Sentry.captureMessage(message, { level: severity, extra });
  }
};

export const logger = { log, removeCustomVariable, addCustomVariable };
