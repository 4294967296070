import { useEffect, useState } from 'react';
import { CoverageLine } from 'enums';
import { InsuranceProduct } from 'types';
import { useGetApiProductsByCoverageLines } from 'broker/pages/SubmissionWorkspacePage/hooks';

export function useApiProductsByCoverageLines(coverageLines: CoverageLine[]) {
  const { getApiProductsByCoverageLines } = useGetApiProductsByCoverageLines();

  const [apiProducts, setApiProducts] = useState<InsuranceProduct[]>([]);

  useEffect(() => {
    if (!coverageLines) {
      return;
    }
    getApiProductsByCoverageLines(coverageLines).then((products) => {
      setApiProducts(products);
    });
  }, [coverageLines, getApiProductsByCoverageLines]);

  return {
    apiProductsByCoverageLines: apiProducts,
  };
}
