import { ReactNode } from 'react';
import { Stack } from '@common-components';
import { ToolboxTabs } from 'broker/pages/SubmissionWorkspacePage/types';
// import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

interface TabPanelProps {
  children?: ReactNode;
  index: ToolboxTabs;
  value: ToolboxTabs;
}

export default function TabPanel({ children, value, index }: TabPanelProps) {
  return (
    <Stack
      borderLeft={1}
      borderColor="divider"
      boxShadow={4}
      zIndex={1}
      overflow="auto"
      flex={1}
      role="tabpanel"
      hidden={value !== index}
      display={value !== index ? 'none' : 'flex'}
      id={`toolbox-tabpanel-${index}`}
      aria-labelledby={`toolbox-tab-${index}`}
      position="relative"
    >
      {value === index && children}
    </Stack>
  );
}
