import { MENTION_PREFIX } from '@common/email-variables';
import { useEffect, useRef } from 'react';
import { toMentionTagString } from 'components/Editor/utils/mentions';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';
import { DraftEditorMode } from 'broker/components/Emails/useDraftEditorMode';
import { resolveText } from 'broker/utils/email-utils/email-resolver-utils/email-resolving-utils';
import { EmailTemplateVariablesMap } from 'broker/utils/email-utils/email-resolver-utils/template-variables-utils';
import { EmailTemplateContext } from './store/types';

export default function useResolveVariable(
  sharedTemplateContext: Omit<EmailTemplateContext, 'recipient'>,
  draftEditorMode: DraftEditorMode,
  selectedRecipientGroup?: RecipientGroup,
) {
  const sharedTemplateContextRef = useRef(sharedTemplateContext);

  useEffect(() => {
    sharedTemplateContextRef.current = sharedTemplateContext;
  }, [sharedTemplateContext]);

  const resolveVariable = (
    variable: string,
    templateContext: EmailTemplateContext | Omit<EmailTemplateContext, 'recipient'>,
    editForAllMode?: boolean,
  ) =>
    resolveText(variable, templateContext, EmailTemplateVariablesMap, MENTION_PREFIX, undefined, editForAllMode) ||
    (editForAllMode ? toMentionTagString(variable) : '');
  const resolveVariableForEditForAll = (variable: string) =>
    resolveVariable(variable, sharedTemplateContextRef.current, true);

  const resolveVariableForEditForMarket = (variable: string) => {
    const recipientTemplateContextData = {
      ...sharedTemplateContextRef.current,
      recipient: selectedRecipientGroup!,
    };

    return resolveVariable(variable, recipientTemplateContextData);
  };

  return {
    resolveVariable:
      draftEditorMode === DraftEditorMode.EditForMarket
        ? resolveVariableForEditForMarket
        : resolveVariableForEditForAll,
  };
}
