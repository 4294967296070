import { ExpiringTowerReportRow } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/ExpiringTower/types';
import getColumns from './columnsConfig';
import StyledDataGrid from './StyledDataGrid';

export interface ExpiringTowerContentProps {
  reportRows: ExpiringTowerReportRow[];
}

function ExpiringTowerDataGrid({ reportRows }: ExpiringTowerContentProps) {
  return (
    <StyledDataGrid
      disableColumnFilter
      rowHeight={80}
      hideFooter
      autoHeight
      rows={reportRows}
      columns={getColumns()}
      hideFooterPagination
      disableColumnSelector
      disableColumnMenu
      disableSelectionOnClick
    />
  );
}

export default ExpiringTowerDataGrid;
