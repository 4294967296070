import { Dispatch, useCallback } from 'react';
import { ActionTypes, InformationTooltipPayload, InformationTooltipStoreActions } from './types';

export default function useInformationTooltipActions(dispatch: Dispatch<ActionTypes>) {
  // Helper functions

  // Actions returned

  const setInformationTooltip = useCallback(
    (payload: InformationTooltipPayload) => {
      dispatch({ type: 'SET_INFORMATION_TOOLTIP', payload });
    },
    [dispatch],
  );

  const modalAction: InformationTooltipStoreActions = {
    setInformationTooltip,
  };

  return modalAction;
}
