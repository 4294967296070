import { isEmpty } from 'lodash';
import { Box, Stack, Tooltip } from '@common-components';
import { Info as InfoIcon } from '@icons';
import { messages } from 'i18n';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import useEmailEditorState from 'broker/components/EmailEditor/store/useEmailEditorState';
import { RecipientsGroupsAvailableRecipients } from 'broker/components/EmailEditor/types';
import { RecipientGroup } from 'broker/components/Emails/recipient-utils';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { AddMarketsButton } from './AddMarketsButton';
import { RecipientGroupsList } from './RecipientGroupsList';
import { useApplyDirtyChanges } from './useApplyDirtyChanges';

interface MarketSelectorProps {
  recipientGroups: RecipientGroup[];
  recipientGroupsAvailableContacts: RecipientsGroupsAvailableRecipients;
}

export default function MarketSelector({ recipientGroups, recipientGroupsAvailableContacts }: MarketSelectorProps) {
  const navigate = useNavigate();
  const { marketRequestType } = useEmailEditorState();

  const { applyChanges } = useApplyDirtyChanges();

  const handleAddMarkets = () => {
    applyChanges();

    navigate(BROKER_NESTED_ROUTES.WORKSPACE.ADD_MARKETS, {
      routeKey: RouteKey.AddMarkets,
      state: { marketRequestType },
    });
  };

  return (
    <Stack direction="row">
      {recipientGroups.length > 0 && (
        <Tooltip tooltipContent={messages.emailEditor.info} placement="bottom-start">
          <InfoIcon sx={{ color: 'grey.700', fontSize: 16, mt: 1, mr: 0.5 }} />
        </Tooltip>
      )}
      <Box sx={{ overflowY: 'auto', maxHeight: '66px' }}>
        <RecipientGroupsList
          recipientGroups={recipientGroups}
          recipientGroupsAvailableContacts={recipientGroupsAvailableContacts}
          itemsToAppend={<AddMarketsButton onClick={handleAddMarkets} compact={!isEmpty(recipientGroups)} />}
        />
      </Box>
    </Stack>
  );
}
