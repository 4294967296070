import { MouseEvent, ReactElement } from 'react';
import { Add, CompareArrows, SvgIconComponent } from '@icons';
import { FormMode, LayerStatus } from 'enums';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { Layer, Quote } from 'types';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import CompareQuotesMenu from './CompareQuotesMenu';

type LayerItemMainActions = {
  title: string;
  clickHandler: (e?: MouseEvent<HTMLButtonElement>) => void;
  Icon: SvgIconComponent;
  extraElement?: ReactElement;
};

export default function useLayerItemMainActions(
  layer: Layer,
  isPrimaryLayer: boolean,
  candidateQuotes: Quote[],
  singleInLayerQuote?: Quote,
): LayerItemMainActions[] {
  const navigate = useNavigate();

  const compareQuotesOptionsMenu = useOptionsMenu();

  const mainActionsMessages = messages.submissionWorkspace.menus.layer.mainActions;
  const { createQuoteFormUrl, createPolicyUrl, createBinderUrl, compareQuotesUrl } = useWorkspaceUrls();

  const attachPrimaryQuote = {
    Icon: Add,
    title: mainActionsMessages.attachPrimaryQuote.title,
    clickHandler: () => {
      navigate(createQuoteFormUrl, {
        routeKey: RouteKey.Quote,
        state: { layer, mode: FormMode.create },
      });
    },
  };

  const attachExcessQuote = {
    Icon: Add,
    title: mainActionsMessages.attachExcessQuote.title,
    clickHandler: () => {
      navigate(createQuoteFormUrl, {
        routeKey: RouteKey.Quote,
        state: { layer, mode: FormMode.create },
      });
    },
  };

  const attachAdditionalQuote = {
    Icon: Add,
    title: mainActionsMessages.attachAdditionalQuote.title,
    clickHandler: () => {
      navigate(createQuoteFormUrl, {
        routeKey: RouteKey.Quote,
        state: { layer, mode: FormMode.create },
      });
    },
  };

  const compareQuotes = {
    Icon: CompareArrows,
    title: mainActionsMessages.compareQuotes.title,
    clickHandler: (e?: MouseEvent<HTMLButtonElement>) => {
      if (candidateQuotes.length > 2 && e) {
        compareQuotesOptionsMenu.openMenu({
          ...e,
          currentTarget: e.currentTarget,
        });
      } else {
        navigate(compareQuotesUrl, {
          routeKey: RouteKey.CompareQuotes,
          state: {
            quotes: candidateQuotes,
            layer,
          },
        });
      }
    },
    extraElement: <CompareQuotesMenu optionsMenu={compareQuotesOptionsMenu} quotes={candidateQuotes} />,
  };

  const attachBinder = {
    Icon: Add,
    title: mainActionsMessages.attachBinder.title,
    clickHandler: () => {
      navigate(createBinderUrl, {
        routeKey: RouteKey.Quote,
        state: {
          mode: FormMode.create,
          selectedQuote: singleInLayerQuote,
        },
      });
    },
  };

  const attachPolicy = {
    Icon: Add,
    title: mainActionsMessages.attachPolicy.title,
    clickHandler: () => {
      navigate(createPolicyUrl, {
        routeKey: RouteKey.Quote,
        state: {
          mode: FormMode.create,
          selectedQuote: singleInLayerQuote,
        },
      });
    },
  };

  switch (layer.status) {
    case LayerStatus.Quoting:
      return isPrimaryLayer ? [attachPrimaryQuote] : [attachExcessQuote];
    case LayerStatus.Reviewing:
      if (candidateQuotes.length === 0) {
        return isPrimaryLayer ? [attachPrimaryQuote] : [attachExcessQuote];
      }
      return candidateQuotes.length === 1
        ? [attachAdditionalQuote, attachBinder]
        : [compareQuotes, attachAdditionalQuote];
    case LayerStatus.Bound:
      return [attachPolicy];
    default:
      return [];
  }
}
