import { ALL_USER_ROLES } from 'enums';
import adminRoutes from './admin';
import { APP_ROUTES } from './app-routes';
import brokerRoutes from './broker';
import HomeRedirect from './pages/HomeRedirect';
import LandingPage from './pages/LandingPage';
import NylasPostAuthenticationPage from './pages/NylasPostAuthenticationPage';
import { RouteDefinitionMap } from './types';

const routes: RouteDefinitionMap = {
  // The landing page does not require authentication
  [APP_ROUTES.GENERAL.LANDING]: {
    Component: LandingPage,
  },
  // The home page requires authentication and is shared between all user roles
  [APP_ROUTES.GENERAL.HOME]: {
    Component: HomeRedirect,
    roles: ALL_USER_ROLES,
  },
  [APP_ROUTES.GENERAL.POST_AUTHENTICATION]: {
    Component: NylasPostAuthenticationPage,
    roles: ALL_USER_ROLES,
  },
  // Routes for the `admin` role
  ...adminRoutes,
  // Routes for the `broker` role
  ...brokerRoutes,
};

export default routes;
