import { NotificationLevelType, NotificationStatus } from 'enums';
import { messages } from 'i18n';

export interface UrgencyConfigProps {
  color: string;
  darkColor?: string;
}

type UrgencyConfig = Record<NotificationLevelType, UrgencyConfigProps>;

export const urgencyConfig: UrgencyConfig = {
  [NotificationLevelType.Neutral]: {
    color: 'common.white',
  },
  [NotificationLevelType.Warning]: {
    color: 'warning.light',
    darkColor: 'warning.main',
  },
  [NotificationLevelType.Alert]: {
    color: 'error.light',
    darkColor: 'error.main',
  },
};

export interface StatusConfigProps {
  emptyStateText: string;
}

type StatusConfig = Record<NotificationStatus, StatusConfigProps>;

export const statusConfig: StatusConfig = {
  [NotificationStatus.New]: {
    emptyStateText: messages.tasks.emptyState.active,
  },
  [NotificationStatus.Completed]: {
    emptyStateText: messages.tasks.emptyState.completed,
  },
};
