import PdfClient, { PDFDecryptBody, PDFDecryptResponse } from 'clients/capitola/pdf';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
import { useQueryMutation } from './query/useMutate';

export function useMutatePdf() {
  const client = useClient(PdfClient);
  const decryptPdf = useQueryMutation<PDFDecryptResponse, PDFDecryptBody>(
    (data) => client.decrypt(data),
    QueryKeys.Pdf,
    true,
  );

  return { decryptPdf };
}
