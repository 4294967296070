import { useEffect } from 'react';
import { Outlet } from 'react-router-dom-latest';
import { Box, Stack, Theme } from '@common-components';
import { useAppStoreActions } from 'contexts/app-store/AppStoreProvider';
import { isSubmissionTerminated } from 'enums';
import { useMount, useUnmount } from 'hooks';
import { logger } from 'utils';
import ReFetchLoader from 'broker/components/common/ReFetchLoader';
import { transferableWorkspaceQueryParams } from 'broker/hooks/config';
import { WorkspaceMainSkeleton } from 'broker/pages/SubmissionWorkspacePage/components/common/WorkspaceMainSkeleton';
import { WorkspaceHeaderSkeleton } from 'broker/pages/SubmissionWorkspacePage/components/Header/WorkspaceHeaderSkeleton';
import TerminatedSubmissionBanner from 'broker/pages/SubmissionWorkspacePage/components/TerminatedSubmissionBanner';
import { ToolboxNavigationSize } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/styles';
import ChooseQuoteModal from 'broker/pages/SubmissionWorkspacePage/dialogs/ChooseQuoteModal';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useWorkspaceResize } from 'broker/pages/SubmissionWorkspacePage/useWorkspaceResize';
import FilesSideBar from './FilesSideBar';
import Header from './Header/Header';
import MarketsView from './MarketsView';

export default function Main() {
  const { partialSubmission, isReFetching, clearCache } = useSubmissionsWorkspace();
  const { setStickySearchParams } = useAppStoreActions();

  useEffect(() => {
    if (partialSubmission) {
      // Set logger variable
      logger.addCustomVariable('submissionId', partialSubmission.id);
    }

    return () => logger.removeCustomVariable('submissionId');
  }, [partialSubmission]);

  useMount(() => {
    setStickySearchParams(transferableWorkspaceQueryParams);
  });

  useUnmount(() => {
    clearCache();
    setStickySearchParams([]);
  });

  const { sideBarSizeValue } = useWorkspaceResize({
    isTabless: true,
  });

  return (
    <>
      <Stack height={1} position="relative">
        {isSubmissionTerminated(partialSubmission?.status) && (
          <TerminatedSubmissionBanner submission={partialSubmission} />
        )}
        <Stack boxShadow={2} zIndex={(theme: Theme) => theme.zIndex.drawer}>
          {partialSubmission ? <Header partialSubmission={partialSubmission} /> : <WorkspaceHeaderSkeleton />}
        </Stack>
        <Stack bgcolor="background.default" flex={1} direction="row" overflow="hidden">
          <Stack position="relative" bgcolor="common.white" flex={1} overflow="auto">
            {partialSubmission ? <MarketsView /> : <WorkspaceMainSkeleton />}
            <Outlet />
          </Stack>
          <Box
            sx={{
              width: 4,
              bgcolor: 'grey.100',
              borderLeft: 1,
              borderColor: 'divider',
            }}
          />
          <Stack
            bgcolor="common.white"
            width={sideBarSizeValue}
            maxWidth={0.5}
            minWidth={ToolboxNavigationSize}
            overflow="auto"
            sx={{
              transition: (theme: Theme) =>
                theme.transitions.create(['width'], {
                  duration: theme.transitions.duration.short,
                }),
            }}
          >
            {partialSubmission ? <FilesSideBar /> : <WorkspaceMainSkeleton />}
          </Stack>
        </Stack>
      </Stack>
      {isReFetching && <ReFetchLoader />}
      <ChooseQuoteModal />
    </>
  );
}
