import { KeyboardEvent, useCallback, useRef } from 'react';

/**
 * Hook to easily bind a ctrl+enter (or cmd+enter in macOs) key combination to "click" a button.
 * Usage:
 *   const { buttonRef, handleKeyDown } = useCtrlEnterSubmitButton();
 *   return (
 *     <input onKeyDown={handleKeyDown} ... />
 *     <button ref={buttonRef} onClick={submit} />
 *   );
 */
export const useCtrlEnterSubmitButton = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      if (buttonRef.current) {
        buttonRef.current.click();
      }
    }
  }, []);

  return { buttonRef, handleKeyDown };
};
