import moment from 'moment';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { CoverageLine } from 'enums'; // Assuming CoverageLine enum is imported from here
import { useMount } from 'hooks';
import { messages } from 'i18n';
import { ExtendedMarketRequest } from 'types';
import { coverageLineConfig, FilterElementProps, SortAndFilterProps, Sorters } from 'utils';
import { marketRequestsStatusConfig } from 'broker/pages/SubmissionWorkspacePage/market-request-status-config';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import { MarketSortTypes } from './types';
import { useSearch } from './useSearch';

export const sorters: Sorters<ExtendedMarketRequest, MarketSortTypes> = {
  [MarketSortTypes.Status]: {
    sorter: (a, b) =>
      marketRequestsStatusConfig[a.status].sortPriority - marketRequestsStatusConfig[b.status].sortPriority,
    label: messages.submissionWorkspace.menus.sort.status,
  },
  [MarketSortTypes.Updated]: {
    sorter: (a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)),
    label: messages.submissionWorkspace.menus.sort.updated,
  },
  [MarketSortTypes.Asc]: {
    sorter: (a, b) => a.market.marketName.localeCompare(b.market.marketName),
    label: messages.submissionWorkspace.menus.sort.asc,
  },
  [MarketSortTypes.Desc]: {
    sorter: (a, b) => b.market.marketName.localeCompare(a.market.marketName),
    label: messages.submissionWorkspace.menus.sort.desc,
  },
};

export type MarketsSortAndFilterProps = Omit<
  SortAndFilterProps<ExtendedMarketRequest, MarketSortTypes>,
  'handleFilterChange' | 'handleAllSelected'
> & {
  handleStatusFilterChange: (event: ChangeEvent<HTMLInputElement>, key: string) => void;
  setAllStatusSelected: () => void;
  coverageLineFilters: FilterElementProps[]; // Returning the new filters
  handleVisibleCoverageChange: (key: string) => void;
};

export default function useMarketRequestsSortAndFilter(
  extendedMarketRequests: ExtendedMarketRequest[],
  onFilterChange: () => void,
): MarketsSortAndFilterProps {
  const store = useUiStoreState();
  const {
    setMarketingSearch,
    setMarketingSortType,
    handleMarketingFilterChange,
    setMarketingFilters,
    setMarketingIsAllFilters,
  } = useUiStoreActions();
  const { search, setSearch } = useSearch(store.marketingFilters.search);

  const { partialSubmission } = useSubmissionsWorkspace(); // Accessing partialSubmission here
  const coverageLines = partialSubmission?.coverageLines || []; // Fetching the specific coverage lines from the submission

  // Filter based only on the coverage lines in the submission
  const [coverageLineFilters, setCoverageLineFilters] = useState(
    coverageLines.map((coverageLine) => ({
      key: coverageLine as string,
      checked: false,
      label: coverageLineConfig[coverageLine as CoverageLine].text,
    })),
  );

  const [isAllCoverageLineFilters, setAllCoverageLineFilters] = useState(true);

  const setSortType = (value: MarketSortTypes) => {
    setMarketingSortType({ sortType: value });
  };

  useEffect(() => {
    setMarketingSearch({ search: { value: search.value, debouncedValue: search.debouncedValue } });
  }, [search.debouncedValue, search.value, setMarketingSearch]);

  useMount(() => {
    setMarketingFilters();
  });

  const createFiltersList = useCallback(() => {
    setMarketingFilters();
  }, [setMarketingFilters]);

  const sortedAndFilteredMarketRequests = useMemo(
    () =>
      [...extendedMarketRequests]
        .sort(sorters[MarketSortTypes[MarketSortTypes.Asc]].sorter)
        .sort(sorters[MarketSortTypes[store.marketingFilters.sortType]].sorter)
        .filter((marketRequest) => {
          if (store.marketingFilters.isAllFilters) {
            return true;
          }
          return store.marketingFilters.filters.some((filter) => {
            if (!filter.checked) {
              return false;
            }
            return marketRequest.status === filter.key;
          });
        })
        .filter((marketRequest) => {
          if (isAllCoverageLineFilters) {
            return true;
          }

          // Updated filtering logic to match coverage lines correctly
          return coverageLineFilters.some(
            (filter) =>
              filter.checked &&
              marketRequest.insuranceProduct?.coverageLines?.some((line) => line.coverageLine === filter.key),
          );
        })
        .filter((marketRequest) => {
          if (
            store.marketingFilters.search.debouncedValue.length < 2 ||
            marketRequest.market.marketName
              .toLowerCase()
              .includes(store.marketingFilters.search.debouncedValue.toLowerCase())
          ) {
            return true;
          }
          return marketRequest.notes
            ?.toLowerCase()
            .includes(store.marketingFilters.search.debouncedValue.toLowerCase());
        }),
    [extendedMarketRequests, store, isAllCoverageLineFilters, coverageLineFilters],
  );

  const handleStatusFilterChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    handleMarketingFilterChange({ checked: event.target.checked, key });
    onFilterChange();
  };

  const handleVisibleCoverageChange = (key: string) => {
    setCoverageLineFilters((prevFilters) => {
      const existingFilters = [...prevFilters];

      const changedFilter = existingFilters.find((filter) => filter.key === key);

      if (changedFilter) {
        changedFilter.checked = !changedFilter.checked;
      }

      return existingFilters;
    });

    // Update `isAllCoverageLineFilters` to true if none of the filters are checked
    setAllCoverageLineFilters(coverageLineFilters.every((filter) => !filter.checked));
  };

  useEffect(() => {
    createFiltersList();
  }, [createFiltersList, extendedMarketRequests]);

  return {
    filters: store.marketingFilters.filters,
    handleStatusFilterChange,
    isAllFilters: store.marketingFilters.isAllFilters,
    search: store.marketingFilters.search.value,
    setSearch: (value) => {
      setSearch(value, !value);
    },
    sortType: store.marketingFilters.sortType,
    setSortType,
    sortedAndFilteredItems: sortedAndFilteredMarketRequests,
    setAllStatusSelected: () => setMarketingIsAllFilters({ isAllFilters: true }),
    coverageLineFilters,
    handleVisibleCoverageChange,
  };
}
