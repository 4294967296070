import { CSSProperties } from 'react';
import { alpha, Color, createTheme, PaletteColor } from 'components/mui-index';
import colors from './colors';
import sizes from './sizes';

// allow configuration using `createTheme`

type ColorPalettePartial = Partial<PaletteColor>;

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1Bold: true;
    body2Bold: true;
    captionBold: true;
    caption2: true;
    caption2Bold: true;
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    body1Bold: CSSProperties;
    body2Bold: CSSProperties;
    captionBold: CSSProperties;
    caption2: CSSProperties;
    caption2Bold: CSSProperties;
  }

  interface TypographyVariantsOptions {
    body1Bold?: CSSProperties;
    body2Bold?: CSSProperties;
    captionBold?: CSSProperties;
    caption2?: CSSProperties;
    caption2Bold?: CSSProperties;
  }

  interface PaletteOptions extends ColorPalettePartial {
    blue: Partial<Color>;
    green: Partial<Color>;
    purple: Partial<Color>;
    blueGrey: Partial<Color>;

    typography: {
      contrast: string;
      primary: string;
      secondary: string;
      nonEssential: string;
      disabled: string;
    };
  }

  interface Palette {
    blue: Partial<Color>;
    green: Partial<Color>;
    purple: Partial<Color>;
    blueGrey: Partial<Color>;

    typography: {
      contrast: string;
      primary: string;
      secondary: string;
      nonEssential: string;
      disabled: string;
    };
    coralRed: {
      400: string;
      100: string;
    };
    aquaGreen: {
      400: string;
      100: string;
    };
    goldYellow: {
      400: string;
      100: string;
    };
    peachOrange: {
      300: string;
      100: string;
    };
    teal: {
      800: string;
      400: string;
      200: string;
      100: string;
    };
    lilac: {
      600: string;
      200: string;
    };
    indigo: {
      600: string;
      200: string;
    };
    turquoise: {
      400: string;
      100: string;
    };
    cornflowerBlue: {
      500: string;
      100: string;
    };
  }
}

// end of allow configuration using `createTheme`

const theme = createTheme({
  spacing: sizes.spacingBase,

  // letterSpacing - 1em => 16px
  // fontSize - 1rem => 16px
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, // Semi Bold
      fontSize: '2rem', // 32px
      lineHeight: '2.5rem', // 40px
      letterSpacing: '0.009rem', // 0.15px
      textTransform: 'none',
    },
    h2: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, // Semi Bold
      fontSize: '1.75rem', // 28px
      lineHeight: '2.25rem', // 36px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    h3: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, // Semi Bold
      fontSize: '1.5rem', // 24px
      lineHeight: '2rem', // 32px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    h4: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, // Semi Bold
      fontSize: '1.25rem', // 20px
      lineHeight: '1.75rem', // 28px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    h5: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, //  Semi Bold
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      letterSpacing: '0.009rem', // 0.15px
      textTransform: 'none',
    },
    h6: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, // Semi Bold
      fontSize: '0.875rem', // 14px
      lineHeight: '1.25rem', // 20px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    subtitle1: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, // Semi Bold
      fontSize: '1.25rem', // 20px
      lineHeight: '1.75rem', // 28px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    subtitle2: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, //  Semi Bold
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      letterSpacing: '0.009rem', // 0.15px
      textTransform: 'none',
    },
    body1: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400, // Regular
      fontSize: '1rem', // 16px
      lineHeight: '1.25rem', // 20px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    body1Bold: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, // Semi Bold
      fontSize: '1rem', // 16px
      lineHeight: '1.25rem', // 20px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    body2: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400, // Regular
      fontSize: '0.875rem', // 14px
      lineHeight: '1.25rem', // 20px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    body2Bold: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, // Semi Bold
      fontSize: '0.875rem', // 14px
      lineHeight: '1.25rem', // 20px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    button: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, // Semi Bold
      fontSize: '0.875rem', // 14px
      lineHeight: '1.25rem', // 20px
      letterSpacing: '0.016rem', // 0.25px
      textTransform: 'none',
    },
    caption: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400, // Regular
      fontSize: '0.75rem', // 12px
      lineHeight: '1rem', // 16px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    captionBold: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, // Semi Bold
      fontSize: '0.75rem', // 12px
      lineHeight: '1rem', // 16px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    caption2: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400, // Regular
      fontSize: '0.625rem', // 10px
      lineHeight: '0.875rem', // 14px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    caption2Bold: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600, // Semi Bold
      fontSize: '0.625rem', // 10px
      lineHeight: '0.875rem', // 14px
      letterSpacing: '0', // 0
      textTransform: 'none',
    },
    overline: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400, // Regular
      fontSize: '0.625rem', // 10px
      lineHeight: '0.875rem', // 14px
      letterSpacing: '0', // 0
      textTransform: 'uppercase',
    },
  },

  palette: {
    ...colors,
  },

  components: {
    MuiBadge: {
      styleOverrides: {
        colorSuccess: {
          backgroundColor: colors.primary.main,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: colors.blue[500],
          '&.Mui-checked:not(.Mui-disabled)': {
            color: colors.blue[500],
          },
          '&.MuiCheckbox-indeterminate': {
            color: colors.blue[500],
          },
        },
        colorSecondary: {
          '&.Mui-checked:not(.Mui-disabled)': {
            color: colors.blue[500],
          },
          '&.MuiCheckbox-indeterminate': {
            color: colors.blue[500],
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          color: colors.blue[500],
          '&.Mui-checked:not(.Mui-disabled)': {
            color: colors.blue[500],
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: colors.blue[50],
            color: colors.blue[600],
          },
        },
      },
    },
  },
});

export default theme;

export const softShadow = `0px 2px 3px ${alpha(theme.palette.grey[900], 0.1)}`;
export const layoutShadow = `0px 10px 40px ${alpha(theme.palette.common.black, 0.12)}`;

export const workspaceToolbarHeight = 78;

export const portalWorkspaceToolbarHeight = 96;

export const SubmissionWorkspaceHeaderHeight = 13;
export const AppHeaderHeight = 0;

export const ExcessHeaderHeight = 8;
export const MarketIndicatorHeight = 5;
export const ProposalHeaderHeight = 40;
export const PreMarketingHeaderHeight = '60px';
export const PreMarketingHeaderPending = '10px';

export const DialogHeaderHeight = 7;
export const ActionsFooterHeight = 9;
export const DialogFooterElevation = 8;

export const StyledTooltipMinWidth = 10;
export const StyledTooltipMaxWidth = 55;
export const dialogTransitionDurationValue = 400;
export const dialogTransitionDuration = `${dialogTransitionDurationValue}ms`;

export const FormControlRadioButtonPaddingBottom = 1;

export const FormControlPaddingBottom = 2;
export const FormControlHelperTextMarginBottom = 0;
export const FormFieldsSpacingVertical = 1;
export const FormFieldsSpacingHorizontal = 1;
export const menuMinWidth = 120;
export const menuMaxWidth = 480;
export const iconFontSize = '0.9rem';

export const cardHeaderHeight = 52;
