export default {
  Other: 'Other',
  MediaLiability: 'Media Liability',
  NetworkSecurityPrivacyLiability: 'Network Security and Privacy Liability',
  TechnologyEAndOLiability: 'Technology E&O Liability',
  ProfessionalEAndOLiability: 'Professional E&O Liability',
  PrivacyRegulatoryClaims: 'Privacy Regulatory Claims',
  PaymentCardIndustryFinesPenalties: 'Payment Card Industry Fines and Penalties',
  PrivacyBreachExpense: 'Privacy Breach Expense',
  CyberExtortionThreatExpense: 'Cyber Extortion Threat Expense',
  DataAssetRestoration: 'Data Asset Restoration',
  ReputationDamage: 'Reputation Damage',
  BusinessInterruptionLoss: 'Business Interruption Loss and Extra Expense',
  BusinessInterruptionLossSystemFailure: 'Business Interruption Loss and Extra Expense - System Failure',
  DependentBusinessInterruptionLoss: 'Dependent Business Interruption Loss - and Extra Expense - System Failure',
  SocialEngineering: 'Social Engineering',
  TelecomFraud: 'Telecom Fraud',
  WireTransferFraud: 'Wire Transfer Fraud',
  HardwareReplacement: 'Hardware Replacement (Bricking)',
  EnhancedCriminalRewardFund: 'Enhanced Criminal Reward Fund',
  CryptoJacking: 'Cryptojacking',
  VoluntaryShutdownCoverage: 'Voluntary Shutdown Coverage',
  SideAIndividualNonIndemnifiedLiabilityCoverage: 'SIDE A: Individual non-indemnified liability coverage',
  SideADifferenceInConditions: 'SIDE A DIC (Difference in Conditions)',
  SideBIndividualIndemnifiedLiabilityCoverage: 'SIDE B: Individual Indemnified Liability Coverage',
  SideCEntityLiabilityCoverage: 'SIDE C: Entity Liability Coverage',
  GovernmentFundingCoverage: 'Government Funding Coverage',
  EntitySecuritiesCoverage: 'Entity Securities Coverage',
  AdditionalLimitDedicatedForExecutives: 'Additional Limit dedicated for Executives',
  CrisisPlus: 'Crisis Plus (Reputation Harm)',
  BooksRecordsCost: 'Books Records Cost',
  DerivativeInvestigativeCostsCoverage: 'Derivative Investigative Costs coverage',
  EPLCoverage: 'EPL coverage',
  ThirdPartyCoverage: 'Third party coverage',
  MassOrClassAction: 'Mass or Class Action',
  WorkplaceViolence: 'Workplace Violence',
  FederalImmigrationAndNationalityAct: 'Federal Immigration and Nationality Act',
  WageHourClaims: 'Wage and Hour Claims',
  TelecommunicationsClaims: 'Telecommunications Claims',
  EmployeeTheft: 'Employee theft',
  ForgeryAlteration: 'Forgery or alteration',
  InsideThePremisesTheftOfMoneySecurities: 'Inside the premises – theft of money and securities',
  InsideThePremisesRobberySafeBurglaryOfOtherProperty:
    'Inside the premises – robbery or safe burglary of other property',
  OutsideThePremises: 'Outside the premises',
  ComputerFundsTransferFraud: 'Computer and funds transfer fraud',
  MoneyOrdersCounterfeitCurrency: 'Money orders and counterfeit currency',
  ClientsProperty: "Client's property",
  Extortion: 'Extortion/Ransomware',
  DesignatedAgents: 'Designated Agents',
  FraudulentImpersonationCoverage: 'Fraudulent Impersonation Coverage',
  FinesPenaltiesCoverageForHealthcareReformActs:
    'Fines and Penalties Coverage for Healthcare Reform Acts, HIPAA, COBRA, Pension Protection Act of 2006, Section 4975, Section 502(c), Section 502(i) and Section 502(l)',
  CoverageForVoluntaryComplianceResolutionProgramVoluntarySettlement:
    'Coverage for Voluntary Compliance Resolution Program and Voluntary Settlement Program administered by IRS, DOL or similar governmental authority',
  PPACACivilMoneyPenaltiesEndorsement: 'PPACA Civil Money Penalties endorsement',
  IRSSection4975Coverage: 'IRS Section 4975 Coverage',
  KidnapRansomExtortionPaymentCyberExtortion: 'Kidnap ransom or extortion payment, including cyber extortion',
  RansomDelivery: 'Ransom delivery',
  LegalLiability: 'Legal liability',
  ReasonableExpensesIncurredInConnectionWithKidnapping:
    'Reasonable expenses incurred in connection with a kidnapping, extortion threat, hijacking, political threat, or wrongful detention',
  ProductRecallExpenses: 'Product recall expenses',
  IndependentSecurityConsultant: 'Independent security consultant',
  BusinessInterruption: 'Business interruption',
  AccidentalDeathDismemberment: 'Accidental death and dismemberment',
  EmergencyPoliticalRepatriation: 'Emergency political repatriation',
  DisappearanceInvestigationExpense: 'Disappearance investigation expense',
  ExpressKidnapping: 'Express kidnapping',
  HostageCrisis: 'Hostage crisis',

  AdditionalSideALimit: 'Additional Side A limit',
  DerivativeDemandInvestigationsSublimit: 'Derivative Demand Investigations sub limit',

  MajorShareholderExclusion: 'Major shareholder Exclusion',
  RegulatoryExclusion: 'Regulatory Exclusion',
  WageHourSublimitRetention: 'Wage / Hour sub limit and retention',
  ImmigrationClaimsImmigrationReformControlActInvestigationsSublimit:
    'Immigration Claims /Immigration Reform Control Act Investigations sub limit',
  WorkplaceViolenceExtensionSublimit: 'Workplace Violence Extension - sub limit',
  ThirdPartyCoverageAndRetention: 'Third party coverage and retention',
  NoteRetentionForHighWageEarners: 'Note if retention for high wage earners',
  DefenseCostsToBeInAdditionToLimitsOfLiability: 'Defense costs to be in addition to limits of liability',
  HIPAACoverage: 'HIPAA Coverage',
  Erisa: 'Erisa',
  ExecutivesProperty: 'Executives Property',
  CheckForgery: 'Check Forgery',
  CreditCard: 'Credit Card ',
  CounterfeitCurrency: 'Counterfeit Currency',
  InsidePremises: 'Inside Premises',
  InTransit: 'In Transit',
  ComputerCrime: 'Computer Crime',
  DDIDerivativeInvestigationsCost: 'DDI - Derivative Investigations Cost ',
  CrisisManagementResponse: 'Crisis Management Response',
  PersonalReputationExpenses: 'Personal Reputation Expenses',
  BooksRecords: 'Books & Records',
  RegulatoryDefenseExpense: 'Regulatory Defense and expense',
  DependentSystemFailure: 'Dependent System Failure',
  ReputationHarm: 'Reputation Harm',
};
