import { ReactNode, useContext, useReducer } from 'react';

import { ModalActionsContext, ModalInitState, ModalStateContext } from './context';
import modalReducer from './reducer';

import useModalActionsHook from './useModalActions';

interface ModalProviderProps {
  children?: ReactNode;
}

function ModalProvider({ children }: ModalProviderProps) {
  const [state, dispatch] = useReducer(modalReducer, ModalInitState);

  const modalAction = useModalActionsHook(dispatch);

  return (
    <ModalStateContext.Provider value={state}>
      <ModalActionsContext.Provider value={modalAction}>{children}</ModalActionsContext.Provider>
    </ModalStateContext.Provider>
  );
}

function useModalState() {
  const context = useContext(ModalStateContext);
  if (context === undefined) {
    throw new Error('WorkspaceModalStateContext must be used within a Submission Workspace Context');
  }
  return context;
}

function useModalActions() {
  const context = useContext(ModalActionsContext);
  if (context === undefined) {
    throw new Error('WorkspaceModalActionsContext must be used within a Submission Workspace Context');
  }
  return context;
}

export { ModalProvider, useModalState, useModalActions };
