import { Stack } from '@common-components';
import { Layer, Quote } from 'types';
import MarketQuoteListItem from './MarketQuoteListItem';

interface MarketQuoteListProps {
  quotes: Quote[];
  layer: Layer;
  hideQuoteActions?: boolean;
}

export default function MarketQuoteList({ quotes, layer, hideQuoteActions }: MarketQuoteListProps) {
  return (
    <Stack>
      {quotes.map((quote, index) => (
        <MarketQuoteListItem
          hideQuoteActions={hideQuoteActions}
          key={quote.id}
          quote={quote}
          layer={layer}
          isLast={quotes.length - 1 === index}
        />
      ))}
    </Stack>
  );
}
