import { WarningAmber as WarningAmberIcon } from '@icons';
import { NotificationLevelType, NotificationStatus, NotificationType } from 'enums';
import { useSearchNotification } from 'hooks';
import { messages } from 'i18n';
import { getDisplayDate } from 'utils';
import { MenuItemProps } from 'components/menu';
import { useNavigateToMarketingUpdate } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingUpdate/useNavigateToMarketingUpdate';
import { urgencyConfig } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Tasks/config';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export function useGetMarketingUpdateMenuItem(): MenuItemProps {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { navigateToMarketingUpdate } = useNavigateToMarketingUpdate();
  const { items: notifications } = useSearchNotification({
    filter: {
      submissionId: partialSubmission?.id,
    },
    enabled: !!partialSubmission,
  });
  // there is only one open marketing update task at a time
  const marketingUpdateTask = notifications.find(
    (task) => task.status === NotificationStatus.New && task.type === NotificationType.ShareMarketingUpdate,
  );
  const marketingUpdateShareByDate = marketingUpdateTask?.dueDate ? getDisplayDate(marketingUpdateTask?.dueDate) : null;

  return {
    label: messages.submissionWorkspace.menus.share.marketingUpdate,
    subLabel: marketingUpdateShareByDate
      ? messages.submissionWorkspace.menus.share.marketingUpdateSubLabel(marketingUpdateShareByDate)
      : '',
    subLabelColor: marketingUpdateTask ? urgencyConfig[marketingUpdateTask.level].darkColor : undefined,
    endAdornment:
      marketingUpdateTask && marketingUpdateTask.level !== NotificationLevelType.Neutral ? WarningAmberIcon : undefined,
    endAdornmentColor: marketingUpdateTask ? urgencyConfig[marketingUpdateTask.level].darkColor : undefined,
    onClick: navigateToMarketingUpdate,
  };
}
