import { ErrorCallback } from 'contexts';
import { ApiError, NetworkError } from './errors';

const NO_TOKEN_SKIP_FETCH = 'NO_TOKEN_SKIP_FETCH';

type dataType = 'response' | 'json' | 'blob';

export interface ClientOptions {
  baseUrl: string;
  getBearerToken: () => Promise<string | undefined>;
  onError: ErrorCallback;
  prefix?: string;
}

export interface FetchOptions {
  passthroughStatusCodes?: number[];
  ignoreStatusCodes?: number[];
  throwOnError?: boolean;
  reportNetworkErrors?: boolean;
  dataType?: dataType;
}

export interface NormalizedErrorResponseBody {
  message?: string;
  extraLogInfo?: any;
}

export const retryOnError: Pick<FetchOptions, 'throwOnError' | 'reportNetworkErrors'> = {
  throwOnError: true,
  reportNetworkErrors: false,
};

export abstract class Client {
  public readonly baseUrl: string;

  protected getBearerToken: () => Promise<string | undefined>;

  public readonly onError: ErrorCallback;

  public readonly prefix?: string;

  constructor({ baseUrl, getBearerToken, onError, prefix }: ClientOptions) {
    this.baseUrl = baseUrl;
    this.getBearerToken = getBearerToken;
    this.onError = onError;
    this.prefix = prefix;
  }

  public abstract get resourceName(): string;

  public buildUrl(path: string | null = null, query: object = {}): string {
    const parts: string[] = this.prefix
      ? [this.baseUrl, this.prefix, this.resourceName]
      : [this.baseUrl, this.resourceName];

    if (path !== null) {
      parts.push(path);
    }

    const url = new URL(parts.join('/'));
    Object.entries(query).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        // If the value is an array, append each item as a key value to the query string (same key)
        value.forEach((item) => url.searchParams.append(key, String(item)));
      } else if (value !== null && value !== undefined) {
        // For non-null and non-undefined single values
        url.searchParams.append(key, String(value));
      }
    });

    return url.toString();
  }

  /**
   * Allows the different client types to normalize error response body to a unified structure before handling it in handleError
   * This is useful when the API error response structure is different from the default structure, if this is the case, the client should override this method
   */
  protected normalizeErrorResponse(errorResponseBody: any): NormalizedErrorResponseBody {
    return { message: errorResponseBody.message };
  }

  public async handleError(response: Response, shouldThrow = false): Promise<void> {
    const statusCode = response.status;
    let normalizedErrorResponseBody: NormalizedErrorResponseBody = {};

    try {
      const errorResponseBody = await response.json();
      normalizedErrorResponseBody = this.normalizeErrorResponse(errorResponseBody);
    } catch (err) {
      // error message was not json
    }

    const errorMessage = normalizedErrorResponseBody.message ?? response.statusText;
    this.onError(errorMessage, statusCode, normalizedErrorResponseBody.extraLogInfo);

    if (shouldThrow) {
      throw new ApiError(errorMessage, statusCode);
    }
  }

  public async getResponseData(response: Response, dataType: dataType) {
    if (dataType === 'json') {
      return response.json();
    }
    if (dataType === 'blob') {
      return response.blob();
    }
    if (dataType === 'response') {
      return response;
    }

    return response;
  }

  public async fetch(
    url: string,
    { headers, ...options }: RequestInit = {},
    {
      passthroughStatusCodes = [],
      ignoreStatusCodes = [],
      throwOnError = false,
      dataType = 'json',
      reportNetworkErrors = true,
    }: FetchOptions = {},
  ): Promise<unknown | null> {
    try {
      const bearerToken = await this.getBearerToken();

      if (bearerToken === NO_TOKEN_SKIP_FETCH) {
        return null;
      }

      let response;
      try {
        response = await fetch(url, {
          ...options,
          headers: {
            ...headers,
            Authorization: `Bearer ${bearerToken}`,
          },
        });
      } catch (e) {
        throw new NetworkError();
      }

      if (response.status === 204) {
        return null;
      }

      if (passthroughStatusCodes.includes(response.status)) {
        const data = await this.getResponseData(response, 'json');
        return data as unknown;
      }

      if (response.ok) {
        const data = await this.getResponseData(response, dataType);
        return data as unknown;
      }

      if (ignoreStatusCodes.includes(response.status)) {
        return null;
      }

      await this.handleError(response, throwOnError);
    } catch (err) {
      if (!(err instanceof ApiError) && reportNetworkErrors) {
        const isNetworkError = err instanceof NetworkError;
        // If this error is an ApiError we already handled the onError, so handling only remaining error types here
        this.onError((err as Error).message, isNetworkError ? undefined : -1, { data: 'No retries' });
      }

      if (throwOnError) {
        throw err;
      }
    }

    return null;
  }
}

export { NO_TOKEN_SKIP_FETCH };
