import { ChangeEvent } from 'react';
import { Box, Chip, Stack } from '@common-components';
import { Close as CloseButton } from '@icons';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { SubmissionMarket } from 'types';
import { FilterElementProps } from 'utils';
import Filter, { FilterButtonTypes } from 'broker/components/common/Filter';
import ToolboxItemHeader from 'broker/components/ToolboxItemHeader';
import EmailsListComposeButton from './EmailsListComposeButton';
import { SortMenu } from './SortMenu';
import { filtersMaxHeight, SortBy } from './utils';

interface HeaderProps {
  search: string;
  setSearch: (search: string) => void;
  submissionMarket?: SubmissionMarket;
  hideEmailActions: boolean;
  marketsFilters: FilterElementProps[];
  isAllMarketsFilters: boolean;
  handleFilterChange: (event: ChangeEvent<HTMLInputElement>, key: string) => void;
  handleAllFiltersSelected: () => void;
  handleUncheckedFilter: (key: string) => void;
  setIsEmailsGrouped?: (isEmailsGrouped: boolean) => void;
  handleSortBy: (sortBy: SortBy) => void;
}

export default function Header({
  search,
  setSearch,
  submissionMarket,
  hideEmailActions,
  marketsFilters,
  isAllMarketsFilters,
  handleAllFiltersSelected,
  handleFilterChange,
  handleUncheckedFilter,
  handleSortBy,
}: HeaderProps) {
  const optionsMenuState = useOptionsMenu();

  const { searchInEmails, title } = messages.submissionWorkspace.emailsTab;

  return (
    <Stack position="sticky" top={0} bgcolor="common.white">
      <ToolboxItemHeader
        title={title}
        searchProps={{
          search,
          setSearch,
          searchPlaceholder: searchInEmails,
        }}
        mainActionArea={!hideEmailActions && <EmailsListComposeButton submissionMarket={submissionMarket} />}
        arrangingArea={
          <Stack direction="row" gap={1}>
            <SortMenu optionsMenuState={optionsMenuState} handleSortBy={handleSortBy} />
            <Filter
              buttonType={FilterButtonTypes.FilterIcon}
              filters={marketsFilters}
              isAllFilters={isAllMarketsFilters}
              handleAllSelected={handleAllFiltersSelected}
              handleFilterChange={handleFilterChange}
              search
            />
          </Stack>
        }
      />
      {marketsFilters.some((market) => market.checked) && (
        <Stack
          direction="row"
          sx={{ p: 2, pt: 0, gap: 0.5, flexWrap: 'wrap', maxHeight: filtersMaxHeight, overflowY: 'auto' }}
        >
          {marketsFilters
            .filter((market) => market.checked)
            .map((market) => (
              <Box key={market.key}>
                <Chip
                  rounded
                  label={market.label}
                  onClick={() => handleUncheckedFilter(market.key)}
                  size="medium"
                  endIcon={CloseButton}
                  variant="contained"
                  color="secondary"
                />
              </Box>
            ))}
        </Stack>
      )}
    </Stack>
  );
}
