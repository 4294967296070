import { BoxItem } from 'box-ui-elements/es';
import { ReactNode } from 'react';
import { Box, Tooltip } from '@common-components';
import { SelectedFilesList } from 'broker/components/common/SelectedFilesList';

interface FilesToolTipProps {
  attachedFiles: BoxItem[];
  onRemoveFile: (file: BoxItem) => void;
  children?: ReactNode;
}

export default function FilesToolTip({ attachedFiles, onRemoveFile, children }: FilesToolTipProps) {
  return (
    <Tooltip
      disablePadding
      arrow
      placement="top-start"
      tooltipContent={
        <Box
          sx={(theme) => ({
            minWidth: theme.spacing(60),
            p: 1,
          })}
        >
          <SelectedFilesList selectedFiles={attachedFiles} onRemoveFile={onRemoveFile} />
        </Box>
      }
    >
      <Box>{children}</Box>
    </Tooltip>
  );
}
