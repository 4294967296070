import _ from 'lodash';
import { SubmissionMarketRequestStatus } from 'enums';
import { messages } from 'i18n';

export interface SubmissionMarketStatusReportConfigProps {
  text: string;
  sortPriority: number;
}

type SubmissionMarketStatusReportConfig = {
  [status in SubmissionMarketRequestStatus]: SubmissionMarketStatusReportConfigProps;
};

const submissionMarketStatusReportConfig: SubmissionMarketStatusReportConfig = {
  [SubmissionMarketRequestStatus.ReadyToMarket]: {
    text: messages.market.status.readyToMarket,
    sortPriority: 1,
  },
  [SubmissionMarketRequestStatus.AwaitingQuote]: {
    text: messages.market.status.awaitingQuote,
    sortPriority: 2,
  },
  [SubmissionMarketRequestStatus.PendingCustomer]: {
    text: messages.market.status.pendingCustomer,
    sortPriority: 3,
  },
  [SubmissionMarketRequestStatus.QuoteReady]: {
    text: messages.market.status.QuoteReady,
    sortPriority: 4,
  },
  [SubmissionMarketRequestStatus.AwaitingBinder]: {
    text: messages.market.status.awaitingBinder,
    sortPriority: 5,
  },
  [SubmissionMarketRequestStatus.Bound]: {
    text: messages.market.status.bound,
    sortPriority: 6,
  },
  [SubmissionMarketRequestStatus.AwaitingPolicy]: {
    text: messages.market.status.awaitingPolicy,
    sortPriority: 7,
  },
  [SubmissionMarketRequestStatus.PolicyIssued]: {
    text: messages.market.status.policyIssued,
    sortPriority: 8,
  },
  [SubmissionMarketRequestStatus.QuoteNotAccepted]: {
    text: messages.market.status.unaccepted,
    sortPriority: 9,
  },
  [SubmissionMarketRequestStatus.Declined]: {
    text: messages.market.status.declined,
    sortPriority: 10,
  },
};
export { submissionMarketStatusReportConfig };

export const sortedStatusList = _.sortBy(submissionMarketStatusReportConfig, ['sortPriority']);
