import { keyframes } from '@emotion/react';
import AppLogo from './layout/side-bar/AppLogo';
import { Box } from './mui-index';

const rotateAnimation = keyframes`
    50% {
        transform: rotate(90deg) scaleX(0.5);
    }
    100% {
        transform: rotate(180deg);
    }
`;

const AppLoader = () => (
  <Box
    sx={{
      position: 'relative',
      width: 56,
      height: 56,
      overflow: 'hidden',
      borderRadius: 4,
      zIndex: 0,
    }}
  >
    <Box
      sx={{
        content: '""',
        position: 'absolute',
        top: 4,
        left: 4,
        right: 4,
        bottom: 4,
        bgcolor: 'common.white',
        borderRadius: 3,
        zIndex: 1,
      }}
    />
    <Box
      sx={{
        content: '""',
        position: 'absolute',
        top: '-50%',
        left: '-50%',
        right: '-50%',
        bottom: '-50%',
        bgcolor: 'purple.600',
        clipPath: 'polygon(0 0, 0 100%, 100% 0, 100% 100%)',
        animation: `${rotateAnimation} 1s linear infinite`,
        zIndex: 0,
      }}
    />
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
      }}
    >
      <AppLogo />
    </Box>
  </Box>
);
export default AppLoader;
