import { Button } from '@common-components';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import CreateSubmissionDialog from './CreateSubmissionDialog';

export default function CreateSubmissionButton() {
  const [isCreateDialogOpen, { off: closeCreateDialog, on: openCreateDialog }] = useBoolean(false);

  return (
    <>
      <Button onClick={openCreateDialog} variant="contained">
        {messages.submissionsView.header.createSubmission}
      </Button>
      <CreateSubmissionDialog isOpen={isCreateDialogOpen} closeDialog={closeCreateDialog} />
    </>
  );
}
