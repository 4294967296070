import { EmailMessageContentType, EmailMessageRecipientType, EmailProviderType } from 'enums';
import { AttachedBoxFile, EmailMessageRecipient, InboundEmailMessage, SubmissionEmail, User } from 'types';
import { MakeFieldsOptional } from 'utils';
import { EmailItemType } from './types';
import { UnifiedEmail } from './UnifiedEmail';
import { extractEmailsAndNames } from './utils';

// return file ids, exclude inline attachments
function getFileIds(attachedBoxFiles: AttachedBoxFile[]) {
  return attachedBoxFiles
    .filter((attachedBoxFile) => !attachedBoxFile.isInline)
    .map((attachedBoxFile) => attachedBoxFile.id);
}

function extractRecipients(inboundMessage: InboundEmailMessage): MakeFieldsOptional<EmailMessageRecipient, 'id'>[] {
  const to = extractEmailsAndNames(inboundMessage.to, EmailMessageRecipientType.To);
  const cc = extractEmailsAndNames(inboundMessage.cc, EmailMessageRecipientType.Cc);
  return [...to, ...cc];
}

function extractInboundRecipients(
  inboundMessage: InboundEmailMessage,
): MakeFieldsOptional<EmailMessageRecipient, 'id'>[] {
  const providerType = inboundMessage.externalMetadata?.providerType;
  const extractRecipientsFromMessageFields = providerType === EmailProviderType.Nylas;
  return extractRecipientsFromMessageFields ? extractRecipients(inboundMessage) : inboundMessage.forwardedRecipients;
}

export function normalizeOutbound(outboundMessage: SubmissionEmail): UnifiedEmail {
  const contents = outboundMessage?.emailMessage.contents;
  const fileIds =
    contents?.filter((item) => item.type === EmailMessageContentType.BOX_FILE_ID).map((content) => content.value) || [];

  return {
    createdAt: outboundMessage.createdAt,
    recipients: outboundMessage.emailMessage.recipients,
    unresolvedContent: outboundMessage.unresolvedContent,
    subject: outboundMessage.emailMessage.subject ?? '',
    body: contents?.find((content) => content.type === EmailMessageContentType.HTML)?.value ?? '',
    fileIds,
    emailType: EmailItemType.SubmissionOutbound,
    senderEmail: outboundMessage.senderEmail?.toLocaleLowerCase(),
    replyToName: outboundMessage.emailMessage.replyToName,
    recipientOrganization: outboundMessage.recipientOrganizationDetails,
    externalId: outboundMessage.emailMessage.externalId,
    onBehalfOf: outboundMessage.emailMessage.onBehalfOf,
  };
}

export function normalizeInbound(inboundMessage: InboundEmailMessage, emailType: EmailItemType): UnifiedEmail {
  const fileIds = getFileIds(inboundMessage.attachedBoxFiles);
  const recipients = extractInboundRecipients(inboundMessage);

  return {
    body: inboundMessage.bodyHtml,
    createdAt: inboundMessage.receivedAt,
    recipients,
    recipient: inboundMessage.recipient,
    subject: inboundMessage.subject,
    senderEmail: inboundMessage.sender.toLocaleLowerCase(),
    fileIds,
    emailType,
    senderOrganization: inboundMessage.senderOrganization,
    externalId: inboundMessage.externalId,
  };
}

export function normalizeInboundFromUser(inboundMessage: InboundEmailMessage, user?: User): UnifiedEmail {
  const fileIds = getFileIds(inboundMessage.attachedBoxFiles);
  const recipients = extractInboundRecipients(inboundMessage);

  return {
    body: inboundMessage.bodyHtml,
    createdAt: inboundMessage.receivedAt,
    recipients,
    recipient: inboundMessage.recipient,
    unresolvedContent: inboundMessage.bodyHtml,
    subject: inboundMessage.subject,
    fileIds,
    emailType: EmailItemType.SubmissionInboundFromUser,
    senderEmail: user?.email,
    recipientOrganization: inboundMessage.recipientOrganization,
    replyToName: inboundMessage.forwardedEmailReplyToName,
    externalId: inboundMessage.externalId,
  };
}
