import { Paper } from '@common-components';
import { EmailTemplateEditor } from 'admin/components/email-templates/EmailTemplateEditor';

export default function AddEmailTemplatePage() {
  return (
    <Paper sx={{ mt: 0 }}>
      <EmailTemplateEditor />
    </Paper>
  );
}
