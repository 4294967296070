import { sortBy } from 'lodash';
import { MutableRefObject, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isOverflown } from 'utils';
import { useDeepCompareLayoutEffect } from './useDeepCompareLayoutEffect';
import { useRefFunction } from './useRefFunction';

interface UseTruncatedProps<T> {
  items: T[];
  sorter?: ((e: T) => string) | string[];
  containerElementRef: MutableRefObject<HTMLElement | null | undefined>;
}

export function useTruncated<T>({ items, sorter = [], containerElementRef }: UseTruncatedProps<T>) {
  const [elementWidth, setElementWidth] = useState(containerElementRef?.current?.clientWidth || 0);

  const changeElementWidth = useRefFunction((width: number) => {
    if (width !== elementWidth) {
      setElementWidth(width);
    }
  });

  useEffect(() => {
    let resizeObserver: any;
    if (containerElementRef.current) {
      resizeObserver = new ResizeObserver(() => {
        // Do what you want to do when the size of the element changes
        if (containerElementRef.current?.clientWidth) {
          changeElementWidth(containerElementRef.current.clientWidth);
        }
      });
      resizeObserver.observe(containerElementRef.current);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect(); // clean up
      }
    };
  }, [changeElementWidth, containerElementRef]);

  const truncatedElementRef = useRef<HTMLElement>(null);

  const [localArrays, setLocalArrays] = useState<{ visible: T[]; hidden: T[]; initCalc: boolean }>(() => ({
    visible: sortBy(items, sorter),
    hidden: [],
    initCalc: true,
  }));

  useLayoutEffect(() => {
    if (truncatedElementRef.current) {
      truncatedElementRef.current.style.overflow = 'hidden';
    }
  }, []);

  useDeepCompareLayoutEffect(() => {
    setLocalArrays({ visible: sortBy(items, sorter), hidden: [], initCalc: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, elementWidth]);

  useLayoutEffect(() => {
    if (isOverflown(truncatedElementRef.current)) {
      if (localArrays.initCalc) {
        const sliceIndex = Math.ceil(
          items.length * (truncatedElementRef.current!.clientWidth / truncatedElementRef.current!.scrollWidth),
        );

        setLocalArrays((prev) => ({
          visible: prev.visible.slice(0, sliceIndex),
          hidden: prev.visible.slice(sliceIndex),
          initCalc: false,
        }));
      } else {
        setLocalArrays((prev) => {
          const removed = prev.visible.pop();
          if (!removed) {
            return prev;
          }
          return {
            visible: [...prev.visible],
            hidden: [...prev.hidden, removed],
            initCalc: prev.initCalc,
          };
        });
      }
    }
  }, [localArrays, truncatedElementRef, items.length]);

  return useMemo(() => ({ localArrays, ref: truncatedElementRef }), [localArrays, truncatedElementRef]);
}
