import { useEffect } from 'react';
import { useBoolean } from 'hooks';
import { Quote } from 'types';
import { Dialog } from 'components/Dialog';
import {
  useModalActions,
  useModalState,
} from 'broker/pages/SubmissionWorkspacePage/dialogs/modalProvider/modalProvider';
import ChooseQuoteModalContent from './ChooseQuoteModalContent';
import { getSelectionQuotes } from './utils';

const ariaLabelId = 'choose-quote-dialog';

export default function ChooseQuoteModal() {
  const { chooseQuote } = useModalState();

  const quotes = getSelectionQuotes(chooseQuote.marketActiveQuotes, chooseQuote.quoteType);

  const modalActions = useModalActions();

  const [isProceeding, { on: startProceeding, off: stopProceeding }] = useBoolean(false);

  const onProceed = async (quote: Quote) => {
    startProceeding();

    try {
      await chooseQuote.onProceed!(quote);
      modalActions.chooseQuoteSetIsClose();
    } finally {
      stopProceeding();
    }
  };

  const skip = chooseQuote.isOpen && quotes?.length === 1 && chooseQuote.skipOnSingle;

  useEffect(() => {
    if (skip) {
      modalActions.chooseQuoteSetIsClose();
      chooseQuote.onProceed!(quotes![0]);
    }
  }, [chooseQuote, modalActions, quotes, skip]);

  if (skip) {
    return null;
  }

  return (
    <Dialog
      onClose={modalActions.chooseQuoteSetIsClose}
      open={chooseQuote.isOpen}
      aria-labelledby={ariaLabelId}
      hideBackdrop={chooseQuote.disableBackdrop}
    >
      {quotes.length > 0 && (
        <ChooseQuoteModalContent
          ariaLabelId={ariaLabelId}
          setIsClose={modalActions.chooseQuoteSetIsClose!}
          quotes={quotes}
          quoteType={chooseQuote.quoteType!}
          title={chooseQuote.title}
          subtitle={chooseQuote.subtitle}
          proceedText={chooseQuote.proceedText}
          onProceed={onProceed}
          isProceeding={isProceeding}
          showWarningIcon={chooseQuote.showWarningIcon}
        />
      )}
    </Dialog>
  );
}
