import { useMount } from 'hooks';
import { useNavigateBack } from 'broker/hooks';

interface NavigateBackProps {
  replace?: boolean;
}

export default function NavigateBack({ replace = false }: NavigateBackProps) {
  const navigateBack = useNavigateBack();
  useMount(() => {
    navigateBack({ navigationOptions: { replace } });
  });
  return null;
}
