import { Paper } from '@common-components';
import ContactList from 'admin/components/ContactList';

export default function ContactListPage() {
  return (
    <Paper sx={{ mt: 0 }}>
      <ContactList />
    </Paper>
  );
}
