import { ReactNode, useContext, useReducer } from 'react';
import { AppStoreActionsContext, AppStoreInitState, AppStoreStateContext } from './context';
import appStoreReducer from './reducer';

import useAppStoreActionsHook from './useAppStoreActions';

interface ModalProviderProps {
  children?: ReactNode;
}

function AppStoreProvider({ children }: ModalProviderProps) {
  const [state, dispatch] = useReducer(appStoreReducer, AppStoreInitState);

  const appStoreAction = useAppStoreActionsHook(dispatch);

  return (
    <AppStoreStateContext.Provider value={state}>
      <AppStoreActionsContext.Provider value={appStoreAction}>{children}</AppStoreActionsContext.Provider>
    </AppStoreStateContext.Provider>
  );
}

function useAppStoreState() {
  const context = useContext(AppStoreStateContext);
  if (context === undefined) {
    throw new Error('App StoreActions state Context must be used within provider');
  }
  return context;
}

function useAppStoreActions() {
  const context = useContext(AppStoreActionsContext);
  if (context === undefined) {
    throw new Error('App StoreActions Context Actions Context must be used within provider');
  }
  return context;
}

export { AppStoreProvider, useAppStoreState, useAppStoreActions };
