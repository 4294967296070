import { compact, uniq } from 'lodash';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormStateBaseProps } from './types';

interface UseGetSectionsIndicatorsProps {
  activeSection?: string;
  answeredHeraldParametersBySections: Record<string, { total: number; answered: number }>;
  sectionList: string[];
  firstVisit: boolean;
  filteredHeraldParameters: { parameter_id: string; section: string }[];
  methods: UseFormReturn<Record<string, FormStateBaseProps | FormStateBaseProps[]>, object>;
}

export function useGetSectionsIndicators({
  answeredHeraldParametersBySections,
  filteredHeraldParameters,
  methods,
}: UseGetSectionsIndicatorsProps) {
  // Calculate the number of errors by counting the keys in methods.formState.errors
  const errorCount = Object.keys(methods.formState.errors).length;

  const totalUnansweredQuestions = useMemo(
    () =>
      Object.keys(answeredHeraldParametersBySections).reduce(
        (acc, section) =>
          acc +
          (answeredHeraldParametersBySections[section].total - answeredHeraldParametersBySections[section].answered),
        0,
      ),
    [answeredHeraldParametersBySections],
  );

  const sectionsWithErrors = useMemo(() => {
    // Gather actual validation errors from formState
    const validationErrors = compact(
      Object.keys(methods.formState.errors).map((heraldParameterId) => {
        const filteredHeraldParameterItem = filteredHeraldParameters.find(
          (filteredHeraldParameter) => filteredHeraldParameter.parameter_id === heraldParameterId,
        );

        if (filteredHeraldParameterItem) {
          return filteredHeraldParameterItem.section; // Return the section that has an error
        }

        return undefined;
      }),
    );

    // Combine only validation errors, excluding missing questions as errors
    return uniq(validationErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredHeraldParameters, methods.formState.errors, errorCount]); // Track changes in the number of errors

  return {
    sectionsWithErrors,
    totalUnansweredQuestions,
  };
}
