export enum OpenItemTaxonomy {
  PrimaryCarrierCurrentlyDatedApplications = 'PrimaryCarrierCurrentlyDatedApplications',
  TriggeringMarketApplicationDatedWithin30DaysEffectiveDateCoverage = 'TriggeringMarketApplicationDatedWithin30DaysEffectiveDateCoverage',
  ConfirmationUnderlyingSubjectivitiesClosedByUnderwriterSubmittedToMarket = 'ConfirmationUnderlyingSubjectivitiesClosedByUnderwriterSubmittedToMarket',
  CurrentLossRunsPast5Years = 'CurrentLossRunsPast5Years',
  ConfirmOpenRDPPortsClosedAddressed = 'ConfirmOpenRDPPortsClosedAddressed',
  RiskControlCallWithMarketCyberRiskControlTeam = 'RiskControlCallWithMarketCyberRiskControlTeam',
  DoesApplicantPermitRemoteAccessToComputerSystems = 'DoesApplicantPermitRemoteAccessToComputerSystems',
  WarrantyForNewLayer = 'WarrantyForNewLayer',
  CopyOfUnderlyingQuoteInsurance = 'CopyOfUnderlyingQuoteInsurance',
  CopyOfUnderlyingBinderInsurance = 'CopyOfUnderlyingBinderInsurance',
  CopyOfUnderlyingPolicyInsurance = 'CopyOfUnderlyingPolicyInsurance',
  FirmHadMicrosoftExchangeServerTheLast365Days = 'FirmHadMicrosoftExchangeServerTheLast365Days',
  RansomwareApplication = 'RansomwareApplication',
  ConsummationOfferingTermsSubstantiallySimilarOutlinedCurrentS1Dated = 'ConsummationOfferingTermsSubstantiallySimilarOutlinedCurrentS1Dated',
  FinalCopyS1 = 'FinalCopyS1',
  IndividualProducerNameNationalProducerNumberRequiredPrior = 'IndividualProducerNameNationalProducerNumberRequiredPrior',
  ReceiptTerrorismPolicyholderDisclosureStatement = 'ReceiptTerrorismPolicyholderDisclosureStatement',
  IPOCloseWithin5DaysBinding = 'IPOCloseWithin5DaysBinding',
  ProvideMostRecentAuditedFinancialStatements = 'ProvideMostRecentAuditedFinancialStatements',
  ProvideInterimYearDateProfitLossIncomeStatements = 'ProvideInterimYearDateProfitLossIncomeStatements',
  DescribeDetailProfessionalServicesWhichCoverageDesired = 'DescribeDetailProfessionalServicesWhichCoverageDesired',
  OpenItemsOnApplicationPleaseProvideAnswers = 'OpenItemsOnApplicationPleaseProvideAnswers',
  List5LargestJobsProjectsPast3Years = 'List5LargestJobsProjectsPast3Years',
}
