import { ElementType, useEffect, useRef } from 'react';
import { Box, BoxProps } from '@common-components';

export default function MainScrollArea<C extends ElementType>(props: BoxProps<C, { component?: C }>) {
  const { sx, id, children, component, ...rest } = props;

  const focusRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus({
        preventScroll: true,
      });
    }
  }, []);

  return (
    <Box
      component={component}
      id={id}
      tabIndex={-1}
      ref={focusRef}
      sx={{
        outline: '0px solid transparent !important',
        '&:focus': {
          outline: '0px solid transparent !important',
        },
        '&:focus-visible': {
          outline: '0px solid transparent !important',
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
