import { BoxItem } from 'box-ui-elements/es';
import { Stack } from '@common-components';
import { InformationTooltipType } from 'contexts/information-tooltip/types';
import FilesAttachments from 'broker/components/EmailEditor/components/FilesAttachments';
import { SmartReports } from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/Customer/Proposal/SmartReports';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

interface ProposalEditorFooterProps {
  proposalContent: string;
}

export default function ProposalEditorFooter({ proposalContent }: ProposalEditorFooterProps) {
  const {
    filesExplorer: { selectedFiles },
  } = useUiStoreState();

  const { setFilesExplorer } = useUiStoreActions();

  const onRemoveFile = (file: BoxItem) => {
    const remainingFiles = selectedFiles.filter((fileItem) => fileItem.id !== file.id);

    setFilesExplorer({ selectedFiles: remainingFiles });
  };

  return (
    // eslint-disable-next-line react/forbid-component-props
    <Stack padding={2} direction="row" spacing={1} gap={1}>
      <FilesAttachments
        informationTooltipType={InformationTooltipType.ProposalAttachments}
        attachedFiles={selectedFiles}
        onRemoveFile={onRemoveFile}
      />
      <SmartReports proposalContent={proposalContent} />
    </Stack>
  );
}
