import { every, isArray, isNil, isPlainObject } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  AnsweredHeraldParametersBySections,
  FormStateBaseProp,
  FormStateBaseProps,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';

interface GetAnsweredHeraldParametersBySectionsProps {
  heraldParametersBySections: Record<string, HeraldNormalizedParameter[]>;
  formValues: Record<string, FormStateBaseProps | FormStateBaseProps[] | undefined>;
}

export function isHeraldFormValueEmpty(value: any) {
  const isConsideredEmpty = (val: any) => isNil(val) || val === '' || (isArray(val) && val.length === 0);

  return isConsideredEmpty(value) || (isPlainObject(value) && every(value, (val) => isConsideredEmpty(val)));
}

export function getAllRequiredKeysAsSet(heraldParametersBySections: Record<string, HeraldNormalizedParameter[]>) {
  const allRequiredParams = new Set<string>();

  const addRequiredParams = (param: HeraldNormalizedParameter) => {
    if (param.isRequired) {
      allRequiredParams.add(param.parameter_id);
    }
    if (param.childValues) {
      param.childValues.forEach((child) => {
        addRequiredParams(child);
      });
    }
  };

  Object.values(heraldParametersBySections).forEach((paramsArray) => {
    paramsArray.forEach((param) => addRequiredParams(param));
  });
  return allRequiredParams;
}

export function useGetAnsweredHeraldParametersBySections({
  heraldParametersBySections,
  formValues,
}: GetAnsweredHeraldParametersBySectionsProps) {
  const allRequiredParameters: Set<string> = useMemo(
    () => getAllRequiredKeysAsSet(heraldParametersBySections),
    [heraldParametersBySections],
  );

  const isHeraldEmpty = useCallback(
    (heraldFormState: FormStateBaseProps | FormStateBaseProps[] | undefined): boolean => {
      if (!heraldFormState) {
        return true;
      }
      if (Array.isArray(heraldFormState)) {
        const allEmpty = heraldFormState.every((element) => isHeraldEmpty(element));

        return allEmpty;
      }

      const isMainEmpty = isHeraldFormValueEmpty(heraldFormState[FormStateBaseProp.Main]);
      if (isMainEmpty) {
        return true;
      }

      if (heraldFormState[FormStateBaseProp.Children]) {
        // if any of the *required* children is "empty", then the parent is considered "empty"
        return Object.keys(heraldFormState[FormStateBaseProp.Children]).some((key) => {
          const isChildRequired = allRequiredParameters.has(key);
          const childFormState = heraldFormState[FormStateBaseProp.Children]?.[key];
          return isChildRequired && isHeraldEmpty(childFormState);
        });
      }

      return false;
    },
    [allRequiredParameters],
  );

  const unAnsweredQuestions = useMemo(
    () => Object.keys(formValues).filter((key) => isHeraldEmpty(formValues[key])),
    [isHeraldEmpty, formValues],
  );

  const answeredHeraldParametersBySections: AnsweredHeraldParametersBySections = useMemo(() => {
    // iterate over heraldParametersBySections to create an object with the same keys but with only  the number of answered questions
    const ownAnsweredHeraldParametersBySections: AnsweredHeraldParametersBySections = {};
    Object.keys(heraldParametersBySections).forEach((key) => {
      const heraldRequiredParametersBySection = heraldParametersBySections[key].filter((param) => param.isRequired);
      ownAnsweredHeraldParametersBySections[key] = {
        answered: heraldRequiredParametersBySection.filter((param) => !unAnsweredQuestions.includes(param.parameter_id))
          .length,
        total: heraldRequiredParametersBySection.length,
      };
    });
    return ownAnsweredHeraldParametersBySections;
  }, [heraldParametersBySections, unAnsweredQuestions]);

  return answeredHeraldParametersBySections;
}
