import { compact, isEmpty, isEqual } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FileType } from 'enums';
import { useDeepCompareEffect, useMount, useSearchSubmissionFileMetadata, useUnmount } from 'hooks';
import { useSearchBoxItems } from 'hooks/api/box';
import { getFileWithSyncMetadata } from 'broker/components/FilesExplorer/utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

interface LoadFilesSelectionProps {
  preSelectedFileIds?: string[];
  setIsDirty?: (isDirty: boolean) => void;
  highlightFileTypes?: FileType[];
  resetOnUnMount?: boolean;
  viewOnlyMode?: boolean;
}
export default function useLoadFilesSelection({
  preSelectedFileIds,
  setIsDirty,
  highlightFileTypes,
  resetOnUnMount = true,
  viewOnlyMode,
}: LoadFilesSelectionProps) {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { items } = useSearchBoxItems({ filter: { chunkSize: 20, folderId: partialSubmission?.boxFolderId || '' } });
  const { setFilesExplorer, resetFilesExplorer } = useUiStoreActions();
  const { filesExplorer } = useUiStoreState();
  const [isSelectedFilesInitialized, setIsSelectedFilesInitialized] = useState(false);

  // Add submission file metadata to the selected files
  const { items: submissionFileMetadata, isLoading: isLoadingSubmissionFileMetadata } = useSearchSubmissionFileMetadata(
    {
      filter: {
        submissionId: partialSubmission?.id,
      },
      enabled: !!partialSubmission,
    },
  );

  const isLoading = useRef(true);

  useMount(() => {
    setFilesExplorer({
      selectionMode: true,
      selectedFiles: [],
      isSelectedFilesDirty: false,
      preSelectedFileIds: preSelectedFileIds || [],
      highlightFileTypes,
      viewOnlyMode,
    });

    isLoading.current = false;
  });

  useUnmount(() => {
    if (resetOnUnMount) {
      resetFilesExplorer();
    }
  });

  useEffect(() => {
    if (!isSelectedFilesInitialized && !isEmpty(preSelectedFileIds)) {
      const selectedFiles = compact(
        preSelectedFileIds!.map((selectedFile) => items.find((folderFile) => folderFile.id === selectedFile)),
      );

      if (selectedFiles.length && !isLoadingSubmissionFileMetadata) {
        setIsSelectedFilesInitialized(true);
        setFilesExplorer({
          selectedFiles: selectedFiles.map((file) => getFileWithSyncMetadata(file, submissionFileMetadata)),
          isLoaded: true,
        });
      }
    }
  }, [
    preSelectedFileIds,
    isSelectedFilesInitialized,
    items,
    setFilesExplorer,
    isLoadingSubmissionFileMetadata,
    submissionFileMetadata,
  ]);

  useDeepCompareEffect(() => {
    setFilesExplorer({ preSelectedFileIds });
  }, [preSelectedFileIds, setFilesExplorer]);

  const selectedFilesIds = useMemo(
    () => filesExplorer.selectedFiles?.map((file) => file.id),
    [filesExplorer.selectedFiles],
  );

  // useEffect to check if the selected files ids are different then the preSelectedFiles ids
  // if they are different it means the user made a change in the form which the files explorer is part of
  // fixed issue that selectedFiles were a dependency of the useEffect, which would cause the useEffect to run unexpectedly once a property like metadata was updated in the selected file (after the form was already submitted which would turn on the isDirty flag)
  useDeepCompareEffect(() => {
    if (setIsDirty) {
      if (!filesExplorer.isLoaded) {
        return;
      }
      if (!isEqual(selectedFilesIds, preSelectedFileIds)) {
        setIsDirty(true);
      }
    }
    // filesExplorer.isLoaded should not be in dependencies list (avoid edge case where it is changed from false to true
    // and causes an extra setIsDirty call)
  }, [preSelectedFileIds, selectedFilesIds, setIsDirty]);

  useEffect(() => {
    if (filesExplorer.isSelectedFilesDirty) {
      setIsDirty?.(true);
    }
  }, [filesExplorer.isSelectedFilesDirty, setIsDirty]);

  return { isLoading: isLoading.current };
}
