import { User } from 'types';
import InternalLink from 'components/InternalLink';
import ADMIN_ROUTES from 'admin/admin-routes';

export interface UserLinkProps {
  user: Pick<User, 'id' | 'email'>;
}

export default function UserLink({ user }: UserLinkProps) {
  return <InternalLink to={ADMIN_ROUTES.getUserById(user.id)}>{user.email}</InternalLink>;
}
