import { Stack, SvgIcon, Tooltip, Typography } from '@common-components';

export default function ContactSingleInfoData({
  Icon,
  content = '-',
  isPlaceHolder,
}: {
  Icon: typeof SvgIcon;
  content: string;
  isPlaceHolder: boolean;
}) {
  return (
    <Tooltip tooltipContent={content}>
      <Stack direction="row" alignItems="center" mt={1.5}>
        <Icon fontSize="medium" sx={{ p: 0, mr: 1, color: 'text.secondary' }} />
        <Typography variant="body2" color={isPlaceHolder ? 'text.secondary' : 'text.primary'} noWrap>
          {content}
        </Typography>
      </Stack>
    </Tooltip>
  );
}
