import { useEffect, useRef } from 'react';
import { useCurrentUser, useWindowVisibilityListener } from 'hooks';

const REFRESH_TOKEN_BUFFER_MS = 5 * 60 * 1000; // 5 minutes

const useBoxToken = () => {
  const { me, refetchMe } = useCurrentUser();
  const { isWindowVisible } = useWindowVisibilityListener();

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { boxAccessToken } = me || {};

  // Creating a stable ref for an async function that returns the most up-to-date token
  // This function is used for the Box components to get the access token
  const refToGetToken = useRef(() => Promise.resolve(boxAccessToken?.token));

  function clearTimer() {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }

  // Making sure the token is kept refreshed. Upon load, checking if the token is about to expire
  // If it does - initiating a refresh call.
  // If not, setting a timer to refresh once it is about to expire
  useEffect(() => {
    const expirationDate = boxAccessToken?.expirationDate;

    async function refreshToken() {
      const { data: currentUser } = await refetchMe();

      if (currentUser?.boxAccessToken?.token) {
        refToGetToken.current = async () => currentUser.boxAccessToken?.token;
      }
    }

    if (isWindowVisible) {
      const timeToRefreshToken = (expirationDate ?? 0) - Date.now() - REFRESH_TOKEN_BUFFER_MS;

      // Time to refresh can be negative in case the window was not visible
      // when we had to refresh the token
      if (timeToRefreshToken <= 0) {
        refreshToken();
      } else {
        timer.current = setTimeout(() => {
          refreshToken();
          timer.current = null;
        }, timeToRefreshToken);
      }
    }

    return clearTimer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, isWindowVisible]);

  // Making sure we clear a running timer when the browser tab is not visible
  useEffect(() => {
    if (!isWindowVisible) {
      clearTimer();
    }
  }, [isWindowVisible]);

  // Returning a function that is based on the stable ref and not directly the current object
  // This is because the Box components are saving the ref to the function sent in the initial
  // render and will not get the update if we send a prop with a new function
  return { getToken: () => refToGetToken.current() };
};

export default useBoxToken;
