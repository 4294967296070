import { MainAreaDimensionsState } from 'broker/components/common/VerticalFormStepper/types';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import useIsToolboxOpen from './useIsToolboxOpen';

export function useGetMainAreaDimensionsState() {
  const { toolboxState } = useUiStoreState();
  const isToolboxOpen = useIsToolboxOpen();
  let mainAreaDimensionsState = MainAreaDimensionsState.Large;
  if (toolboxState.isExpanded) {
    mainAreaDimensionsState = MainAreaDimensionsState.Small;
  } else if (isToolboxOpen) {
    mainAreaDimensionsState = MainAreaDimensionsState.Medium;
  }

  return mainAreaDimensionsState;
}
