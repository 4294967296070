// function that gets all keys from local storage that start with a certain prefix
import { LocalStorage, LocalStorageKeys } from 'enums';

interface LocalStorageConfigProps {
  clearOnSignOut: boolean;
}

const localStorageConfig: Record<LocalStorageKeys, LocalStorageConfigProps> = {
  [LocalStorage.ResolutionFlag]: {
    clearOnSignOut: false,
  },
  [LocalStorage.ScreenWidthFlag]: {
    clearOnSignOut: false,
  },
  [LocalStorage.AutoTabIndication]: {
    clearOnSignOut: false,
  },
  [LocalStorage.SubmissionsView]: {
    clearOnSignOut: true,
  },
  [LocalStorage.SubmissionsBox]: {
    clearOnSignOut: true,
  },
  [LocalStorage.ToolboxSize]: {
    clearOnSignOut: false,
  },
  [LocalStorage.InfoTooltip]: {
    clearOnSignOut: false,
  },
  [LocalStorage.StartMarketingIndicator]: {
    clearOnSignOut: false,
  },
  [LocalStorage.ActivateOnce]: {
    clearOnSignOut: false,
  },
} as const;

const getLocalStorageKeysByPrefix = (prefix: LocalStorageKeys) =>
  Object.keys(window.localStorage).filter((key) => key.startsWith(prefix));

export const clearLocalStorage = () => {
  const prefixToClear = Object.values(LocalStorage).filter((key) => localStorageConfig[key].clearOnSignOut);

  prefixToClear.forEach((key) => {
    getLocalStorageKeysByPrefix(key).forEach((localStorageKey) => window.localStorage.removeItem(localStorageKey));
  });
};
