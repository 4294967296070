import { Collapse, ListItemButton, ListItemText, Stack, Typography } from '@common-components';
import { ExpandLess, ExpandMore } from '@icons';
import { EmailTemplate } from 'types';
import EmailTemplateCard from 'broker/components/TemplateEditor/Components/EmailTemplateCard';

export interface SidebarSectionProps {
  handleClick: () => void;
  isOpen: boolean;
  isOtherSectionOpen: boolean;
  templates: EmailTemplate[];
  onChange: (template: EmailTemplate) => void;
  selectedTemplate: EmailTemplate | undefined;
  title: string;
  top?: number;
}

const listItemStyles = {
  position: 'sticky',
  top: 0,
  bottom: 0,
  zIndex: 1,
  '&:hover': {
    backgroundColor: 'grey.100',
  },
  border: 1,
  borderColor: 'grey.300',
};

export default function SidebarSection({
  handleClick,
  isOtherSectionOpen,
  isOpen,
  templates,
  onChange,
  selectedTemplate,
  title,
  top,
}: SidebarSectionProps) {
  return (
    <>
      <ListItemButton
        onClick={handleClick}
        disabled={templates.length === 0}
        sx={{
          ...listItemStyles,
          bgcolor: isOtherSectionOpen ? 'common.white' : 'grey.100',
          top: top ?? 0,
        }}
      >
        <ListItemText>
          <Typography
            variant="h4"
            sx={{
              color: 'common.black',
            }}
          >
            {title}
          </Typography>
        </ListItemText>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto">
        <Stack>
          {templates.map((template) => (
            <EmailTemplateCard
              key={template.id}
              template={template}
              onClick={(emailTemplate: EmailTemplate) => onChange(emailTemplate)}
              isSelected={template.id === selectedTemplate?.id}
            />
          ))}
        </Stack>
      </Collapse>
    </>
  );
}
