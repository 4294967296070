export const CardContentStyles = {
  p: 0,
  '&:last-child': {
    p: 0,
  },
} as const;

export const CardActionsStyles = {
  justifyContent: 'center',
  borderTop: 1,
  borderColor: 'divider',
  bgcolor: 'blueGrey.50',
} as const;
