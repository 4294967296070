import { useState } from 'react';
import { useCurrentUser, useSearchUserTeam } from 'hooks';
import { UserDetails } from 'types';

const useSelectUser = ({ submissionUser }: { submissionUser: UserDetails }) => {
  const { items: users } = useSearchUserTeam();

  const { endUser } = useCurrentUser();

  const [selectedUser, setSelectedUser] = useState<UserDetails>(submissionUser || endUser);

  const setUser = async (_: string, userId: string | undefined) => {
    const user = users.find((u) => u.id === userId);
    if (user) {
      setSelectedUser(user);
    }
  };

  return { selectedUser, setSelectedUser: setUser, users };
};

export default useSelectUser;
