import { isEmpty, keyBy, groupBy as lodashGroupBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import { FocusEvent, Ref, useState } from 'react';
import { Box, CircularProgress, inputBaseClasses, Stack, TextField, useAutocomplete } from '@common-components';
import { messages } from 'i18n';
import { Indexed } from 'utils';
import DefaultOverlay from 'components/DefaultOverlay';
import AutocompleteActionButton from './AutocompleteActionButton';
import { BaseOption, OptionsList } from './OptionsList';
import { SelectionChip } from './SelectionChip';
import { StyledList } from './StyledList';

export interface CallToAction {
  action: () => void;
  label: string;
}

export interface MultiSelectionAutocompleteProps<T extends BaseOption> {
  setSelectedOptions: (selectedOptions: T[]) => void;
  dropdownAfterTyping?: boolean;
  selectedOptions: T[];
  options: T[];
  placeholder?: string;
  id?: string;
  groupBy?: (option: T) => string;
  inputRef?: Ref<HTMLInputElement>;
  loading?: boolean;
  freeSolo?: boolean;
  createOption?: (value: string) => T;
  callToAction?: CallToAction;
  disableBorder?: boolean;
  hasErrors?: boolean;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  defaultOverlayText?: string;
}

export const MultiSelectionAutoCompleteClassName = 'cap-multi-selection-autocomplete';

export function MultiSelectionAutocomplete<T extends BaseOption>({
  id,
  dropdownAfterTyping = false,
  options,
  selectedOptions,
  setSelectedOptions,
  placeholder,
  inputRef,
  loading,
  groupBy,
  freeSolo,
  createOption,
  callToAction,
  disableBorder,
  onBlur,
  hasErrors = false,
  defaultOverlayText,
}: MultiSelectionAutocompleteProps<T>) {
  const [inputValue, setInputValue] = useState('');
  const [ownDefaultOverlayText, setOwnDefaultOverlayText] = useState<string | undefined>(defaultOverlayText);

  // const optionsSet = useMemo(() => new Set(options.map((option) => option.label.toLowerCase())), [options]);

  const labelToOption = keyBy(options, (option) => option.label);

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions: nonGroupedOptions,
    value,
    setAnchorEl,
    popupOpen,
  } = useAutocomplete({
    id,
    multiple: true,
    options,
    filterSelectedOptions: true,
    freeSolo,
    openOnFocus: true,
    getOptionLabel: (option) => option.label,
    inputValue,
    onChange: (_, newValues) => {
      const newSelectedOptions = newValues
        .map((newValue) => {
          if (typeof newValue === 'string') {
            const optionFromString = labelToOption[newValue];
            if (optionFromString) {
              return optionFromString;
            }
            return createOption?.(newValue);
          }
          return newValue;
        })
        .filter(Boolean);

      // Reset input value after adding an option
      if (selectedOptions.length < newSelectedOptions.length) {
        setInputValue('');
      }

      setSelectedOptions(newSelectedOptions as T[]);
    },
    value: selectedOptions,
    filterOptions: (currentOptions) => {
      const filtered = matchSorter(currentOptions, inputValue, { keys: ['label'] });
      if (
        inputValue.length > 2 &&
        isEmpty(currentOptions.filter((option) => option.label === inputValue)) &&
        createOption
      ) {
        filtered.push(createOption(inputValue));
      }
      return filtered;
    },
  });

  // This is a hack to set index for each item in the options list with respect to indexes in previous groups
  // When MUI `useAutocomplete` is provided with grouping - it creates the groups with relevant options,
  // but then we don't have the indexes of the options. That messes the functionality of `getOptionProps` that rely on the options to have indexes.
  const indexedOptions = (nonGroupedOptions as T[]).map<Indexed<T>>((option, index) => ({ ...option, index }));
  const groupedOptions = lodashGroupBy(indexedOptions, groupBy);
  const noResults = !callToAction && inputValue && isEmpty(groupedOptions);
  const shouldShowOptionsList =
    ((!dropdownAfterTyping || inputValue) && !isEmpty(groupedOptions)) || (popupOpen && (callToAction || noResults));
  return (
    <Box position="relative" className={MultiSelectionAutoCompleteClassName} minHeight={40}>
      <Box {...getRootProps()} ref={setAnchorEl}>
        <TextField
          size="small"
          inputRef={inputRef}
          inputProps={{
            ...getInputProps(),
          }}
          error={hasErrors}
          onChange={(event) => setInputValue(event.target.value)}
          // those are different props - one for the raw input and one for mui Input
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            startAdornment: value.map((option: T | string, index: number) => (
              // getTagsProps is adding key attribute so no need for key
              // eslint-disable-next-line react/jsx-key
              <SelectionChip label={typeof option === 'string' ? option : option.label} {...getTagProps({ index })} />
            )),
          }}
          placeholder={placeholder}
          fullWidth
          sx={{
            ...(ownDefaultOverlayText && { display: 'none' }),
            input: {
              typography: 'body2',
              p: 0,
            },
            [`& .${inputBaseClasses.root}`]: {
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: 1,
              py: 0.8,
              px: 2,
            },
            ...(disableBorder && {
              '& fieldset': {
                border: 'none',
              },
            }),
          }}
          onBlur={onBlur}
        />
      </Box>
      {ownDefaultOverlayText && (
        <DefaultOverlay
          text={ownDefaultOverlayText}
          onClick={() => {
            setOwnDefaultOverlayText(undefined);
          }}
        />
      )}

      {shouldShowOptionsList && (
        <StyledList {...getListboxProps()}>
          {loading ? (
            <Stack p={1} alignItems="center" justifyContent="center">
              <CircularProgress color="success" size={24} />
            </Stack>
          ) : (
            <>
              <Stack sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <OptionsList options={groupedOptions} getOptionProps={getOptionProps} inputValue={inputValue} />
              </Stack>
              {noResults && (
                <Box component="li" width="100%" paddingY={3} paddingX={2}>
                  <Stack color="text.disabled">{messages.general.noResults}</Stack>
                </Box>
              )}
              {callToAction && (
                <Box component="li" width="100%" padding={0}>
                  <AutocompleteActionButton onClick={callToAction.action}>
                    {callToAction.label}
                  </AutocompleteActionButton>
                </Box>
              )}
            </>
          )}
        </StyledList>
      )}
    </Box>
  );
}
