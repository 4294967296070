import { PartialSubmission } from 'types';
import { getCoverageLinesText } from 'utils';
import InternalLink from 'components/InternalLink';
import ADMIN_ROUTES from 'admin/admin-routes';

export interface SubmissionLinkProps {
  submission: PartialSubmission;
}

export default function SubmissionLink({ submission }: SubmissionLinkProps) {
  return (
    <InternalLink to={ADMIN_ROUTES.getSubmissionById(submission.id)}>
      {submission.insuredName} - {getCoverageLinesText(submission.coverageLines)}
    </InternalLink>
  );
}
