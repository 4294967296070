import { Chip, Stack } from '@common-components';
import { NotificationStatus } from 'enums';
import { useSearchNotification } from 'hooks';
import ToolboxItemHeader from 'broker/components/ToolboxItemHeader';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import TasksList from './TasksList';

export interface TasksProps {
  isDisabled?: boolean;
}
export default function Tasks({ isDisabled }: TasksProps) {
  const { setToolboxState } = useUiStoreActions();
  const { toolboxState } = useUiStoreState();

  const handleTabChange = (tab: NotificationStatus) => {
    // Update the store with the new active tab
    setToolboxState({ tasksActiveTab: tab });
  };

  const { partialSubmission } = useSubmissionsWorkspace();

  const { items: notifications } = useSearchNotification({
    filter: {
      submissionId: partialSubmission?.id,
    },
    enabled: !!partialSubmission,
  });

  const activeTasksLength = notifications.filter((task) => task.status === NotificationStatus.New).length;
  const completedTasksLength = notifications.filter((task) => task.status === NotificationStatus.Completed).length;
  const currentNotifications = notifications.filter((task) => task.status === toolboxState.tasksActiveTab);

  return (
    <Stack overflow="auto" height={1}>
      <ToolboxItemHeader
        title="Tasks"
        arrangingArea={
          <Stack width={1} direction="row" gap={1}>
            <Chip
              label={`Active (${activeTasksLength})`}
              variant={toolboxState.tasksActiveTab === NotificationStatus.New ? 'outlined' : 'contained'}
              color="secondary"
              rounded
              onClick={() => handleTabChange(NotificationStatus.New)}
            />
            <Chip
              label={`Completed (${completedTasksLength})`}
              variant={toolboxState.tasksActiveTab === NotificationStatus.Completed ? 'outlined' : 'contained'}
              color="secondary"
              rounded
              onClick={() => handleTabChange(NotificationStatus.Completed)}
            />
          </Stack>
        }
      />
      <Stack overflow="auto" flex={1}>
        <TasksList
          isDisabled={isDisabled}
          notifications={currentNotifications}
          notificationStatus={toolboxState.tasksActiveTab || NotificationStatus.New}
        />
      </Stack>
    </Stack>
  );
}
