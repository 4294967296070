import { Dispatch, useCallback } from 'react';
import { ActionTypes, AppStoreActions, StickySearchParams } from './types';

export default function useAppStoreActions(dispatch: Dispatch<ActionTypes>) {
  const setStickySearchParams = useCallback(
    (payload: StickySearchParams) => {
      dispatch({ type: 'SET_STICKY_SEARCH_PARAMS', payload });
    },
    [dispatch],
  );

  const modalAction: AppStoreActions = {
    setStickySearchParams,
  };

  return modalAction;
}
