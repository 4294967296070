import { useState } from 'react';
import { createSearchParams } from 'react-router-dom-latest';
import { BoxSkeleton, Divider, Stack } from '@common-components';
import { demoData } from 'utils';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import DataGridNoResults from 'broker/components/common/QuotesAndPolicies/DataGridNoResults';
import { UrlParam } from 'broker/dialogs/AgencyDialog/utils';
import { RouteKey } from 'broker/enums';
import MainScrollArea from 'broker/hoc/MainScrollArea';
import { useNavigate } from 'broker/hooks';
import AgenciesTableHeader from './AgenciesTableHeader';
import AgenciesTableItem from './AgenciesTableItem';
import { AgenciesViewProps, AgencyOnClick } from './types';

export default function AgenciesTableView({
  agencies,
  isLoading,
  searchInput,
  handleAgentClicked,
  checkedLabels = [],
  bottomTableContent,
}: AgenciesViewProps) {
  const navigate = useNavigate();

  const openEditModal: AgencyOnClick = ({ id, scrollTo }) => {
    navigate(
      {
        pathname: BROKER_NESTED_ROUTES.AGENCIES.AGENCY,
        search: `${createSearchParams([[UrlParam.Id, id]])}`,
      },
      {
        routeKey: RouteKey.Agency,
        state: { scrollTo },
      },
    );
  };

  const [expandedAgencyId, setExpandedAgencyId] = useState('');

  const onExpandClicked = (agencyId: string) => {
    setExpandedAgencyId((prev) => {
      if (prev === agencyId) {
        return '';
      }
      return agencyId;
    });
  };

  const items =
    agencies.length > 0 ? (
      agencies.map((agency) => (
        <AgenciesTableItem
          isAgencyExpanded={expandedAgencyId === agency.id}
          onExpandClicked={onExpandClicked}
          agency={agency}
          isLoading={isLoading}
          agencies={agencies}
          key={agency.id}
          onClick={openEditModal}
          searchInput={searchInput}
          checkedLabels={checkedLabels}
          handleAgentClicked={handleAgentClicked}
          onOpenNewAgent={() => {
            navigate(
              {
                pathname: BROKER_NESTED_ROUTES.AGENCIES.AGENT,
              },
              {
                routeKey: RouteKey.Agent,
                state: { organizationId: agency.id, organizationName: agency.name },
              },
            );
          }}
        />
      ))
    ) : (
      <DataGridNoResults />
    );

  const skeleton = (
    <Stack gap={2} p={2}>
      {demoData(10).map((row, index) => (
        <Stack gap={2} key={row.id}>
          <BoxSkeleton isLoading sx={{ width: 1, height: 45 }} index={index} />
          <Divider />
        </Stack>
      ))}
    </Stack>
  );
  return (
    <Stack overflow="hidden" flex="1 1 0">
      <MainScrollArea id="agenciesScrollArea" flex={1} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <AgenciesTableHeader />
        {isLoading ? skeleton : items}
        {bottomTableContent}
      </MainScrollArea>
    </Stack>
  );
}
