import { some } from 'lodash';
import { useMemo, useRef } from 'react';
import { Button, Checkbox, darken, Icon, IconButton, Stack } from '@common-components';
import { Add as AddIcon, Edit as EditIcon } from '@icons';
import { OrganizationType } from 'enums';
import { RowsPerPage, usePagination } from 'hooks';
import { messages } from 'i18n';
import { Contact, ExtendedContact, ExtendedUserMarket, UserMarket } from 'types';
import { contactFullName, FilterElementProps } from 'utils';
import LabelList from 'broker/components/common/LabelList';
import TypographyColumn from 'broker/components/common/TypographyColumn';
import DotsStepper from 'broker/components/DotsStepper';
import { AddContactToMarketMetadata } from 'broker/dialogs/AddContactToMarketModal/types';
import { DeleteContactButton } from './DeleteContactButton';
import ProductInfo from './ProductInfo';
import { getContactAndCoverageLinesFilterMatch } from './utils';

interface MarketTableItemExpandProps {
  filteredContacts: ExtendedContact[];
  extendedMarket: ExtendedUserMarket;
  onOpenNewContact: (metaData: AddContactToMarketMetadata) => void;
  searchInput: string;
  selectionMode: boolean;
  selectedContacts?: Contact[];
  handleContactClicked?: (contact: Contact, selectedContacts: Contact[], market: UserMarket) => void;
  coverageLineFilters: FilterElementProps[];
  isAllCoverageLineFilters: boolean;
  isCompact: boolean;
}
export default function MarketTableItemExpand({
  filteredContacts,
  extendedMarket,
  onOpenNewContact,
  searchInput,
  selectionMode,
  selectedContacts,
  handleContactClicked,
  coverageLineFilters,
  isAllCoverageLineFilters,
  isCompact,
}: MarketTableItemExpandProps) {
  const elementRef = useRef<HTMLElement | null>(null);

  const isCarrier = extendedMarket.organizationType === OrganizationType.Carrier;

  const { page, setPage, rowsPerPage } = usePagination({ defaultRowsPerPage: RowsPerPage.Five });

  const contactsSlice = useMemo(
    () => filteredContacts.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage),
    [filteredContacts, page, rowsPerPage],
  );

  const numberOfSteps = Math.ceil(filteredContacts.length / rowsPerPage);
  return (
    <Stack ref={elementRef} bgcolor="blueGrey.50" width={1}>
      {contactsSlice.map((contact) => (
        <Stack
          key={contact.id}
          direction="row"
          width={1}
          height={52}
          pl={4}
          gap={3}
          sx={(theme) => ({
            ':not(:last-child)': {
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
            ':hover': {
              bgcolor: darken(theme.palette.blueGrey['50'] as string, 0.06),
            },
          })}
        >
          {selectionMode && (
            <Stack flex="0 0 40px" overflow="hidden" direction="row" alignItems="center">
              <Checkbox
                disableRipple
                color="secondary"
                checked={some(selectedContacts, { id: contact.id })}
                onChange={() => handleContactClicked!(contact, selectedContacts!, extendedMarket)}
              />
            </Stack>
          )}

          <TypographyColumn
            width={isCompact ? 200 : 220}
            searchInput={searchInput}
            textToHighlight={contactFullName(contact)}
          />

          <Stack flex={1} minWidth={128} direction="row" alignItems="center" overflow="hidden">
            <LabelList
              labels={contact.products.map((product) => ({
                text: product.name,
                tooltipContent: <ProductInfo product={product} />,
              }))}
              searchInput={searchInput}
              selectedLabels={getContactAndCoverageLinesFilterMatch({
                coverageLineFilters,
                contact,
                isAllCoverageLineFilters,
              })}
              addButtonText={messages.buttons.addProducts}
            />
          </Stack>

          <TypographyColumn width={isCompact ? 220 : 250} searchInput={searchInput} textToHighlight={contact.email} />
          {!isCompact && (
            <TypographyColumn width={140} searchInput={searchInput} textToHighlight={contact.phoneNumber || ''} />
          )}
          {!isCompact && (
            <TypographyColumn width={120} searchInput={searchInput} textToHighlight={contact.title || ''} />
          )}

          <Stack flex="0 0 60px" direction="row" alignItems="center">
            <DeleteContactButton contact={contact} />
            <IconButton
              onClick={() => onOpenNewContact({ userMarket: extendedMarket, existingContact: contact })}
              icon={EditIcon}
              size="small"
              variant="text"
              color="secondary"
            />
          </Stack>
        </Stack>
      ))}
      <Stack direction="row" justifyContent="space-between" height={52} gap={1.25}>
        <Stack flex={1} direction="row" alignItems="center">
          <Button
            onClick={() => onOpenNewContact({ userMarket: extendedMarket })}
            startIcon={<Icon component={AddIcon} />}
          >
            {isCarrier ? messages.marketsPage.addUnderWriter : messages.marketsPage.addWholesaler}
          </Button>
          <Button
            onClick={() => onOpenNewContact({ userMarket: extendedMarket, defaultSubmissionBox: true })}
            startIcon={<Icon component={AddIcon} />}
          >
            {messages.marketsPage.addSubmissionBox}
          </Button>
        </Stack>
        {}
        {numberOfSteps > 1 && (
          <Stack margin="0 auto" flex={1} maxWidth="max-content">
            <DotsStepper setActiveStep={setPage} activeStep={page} numberOfSteps={numberOfSteps} />
          </Stack>
        )}
        <Stack flex={1} />
      </Stack>
    </Stack>
  );
}
