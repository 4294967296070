import { useMemo } from 'react';
import { Box, Stack, Typography } from '@common-components';
import { FormMode, QuoteFileIdType } from 'enums';
import { Submission } from 'types';
import { BrokerUrlParams, QuoteFormRoutes } from 'broker/broker-routes';
import { RouteKey } from 'broker/enums';
import { useNavigateToStep } from 'broker/hooks';
import { QuoteFiles } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/components/formSteps/components/QuoteFiles';
import { QuoteFilesInfoTooltip } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/components/formSteps/components/QuoteFilesInfoTooltip';
import MissingLabelAlertBanner, {
  AlertBannerMode,
} from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/components/formSteps/QuoteInsights/MissingLabelAlertBanner';
import { QuoteConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/config';
import { TOOLBOX_TABS } from 'broker/pages/SubmissionWorkspacePage/types';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import { useFileWithMetadata } from './useFileWithMetadata';

interface UploadProps {
  mode: FormMode;
  fileType: QuoteFileIdType;
  currentStep: QuoteFormRoutes;
  submission?: Submission;
  marketName?: string;
  isRequiredLabelMissing?: boolean;
  onContinue: () => void;
  quoteConfig: QuoteConfig;
}

export default function Upload({
  mode,
  fileType,
  marketName,
  currentStep,
  isRequiredLabelMissing,
  onContinue,
  quoteConfig,
}: UploadProps) {
  const requiredLabelAlert = useMemo(() => {
    switch (fileType) {
      case QuoteFileIdType.MarketQuote:
        return AlertBannerMode.MissingMarketQuoteFileLabel;
      case QuoteFileIdType.Binder:
        return AlertBannerMode.MissingMarketBinderFileLabel;
      case QuoteFileIdType.Policy:
        return AlertBannerMode.MissingMarketPolicyFileLabel;
      default:
        return undefined;
    }
  }, [fileType]);

  const navigateToStep = useNavigateToStep();

  const fileWithMetadata = useFileWithMetadata(fileType);
  const navigateToDetails = () => {
    onContinue();
    navigateToStep(
      {
        pathname: `../${QuoteFormRoutes.Details}`,
        search: `?${BrokerUrlParams.ACTIVE_TAB}=${TOOLBOX_TABS.FILES}`,
        ...(fileWithMetadata && {
          search: `?${BrokerUrlParams.SELECTED_FILE}=${fileWithMetadata.id}&${BrokerUrlParams.ACTIVE_TAB}=${TOOLBOX_TABS.FILES}`,
        }),
      },
      { routeKey: RouteKey.Quote, replace: true },
    );
  };

  const {
    filesExplorer: { selectedFiles },
  } = useUiStoreState();

  return (
    <Stack>
      <Box height={1} overflow="hidden" py={1} px={6} sx={{ position: 'relative', overflowY: 'auto' }}>
        <Stack height={1} maxWidth={640} mx="auto">
          {selectedFiles.length > 0 && isRequiredLabelMissing && requiredLabelAlert && (
            <MissingLabelAlertBanner mode={requiredLabelAlert} />
          )}
          <Stack pb={4} pt={2} gap={1}>
            <Typography variant="body1Bold">{quoteConfig.messages.headerTitle}</Typography>
            {fileType === QuoteFileIdType.MarketQuote && (
              <Typography variant="caption">{quoteConfig.messages.headerSubtitle}</Typography>
            )}
          </Stack>
          <QuoteFiles
            submitDisabled={isRequiredLabelMissing}
            onContinueToDetails={navigateToDetails}
            currentStep={currentStep}
            fileType={fileType}
            mode={mode}
            showFilesCounter
          />

          <QuoteFilesInfoTooltip marketName={marketName} fileType={fileType} />
        </Stack>
      </Box>
    </Stack>
  );
}
