import { useCallback, useEffect, useState } from 'react';
import { Theme } from '@common-components';
import { ToolboxNavigationSize } from './components/Toolbox/styles';
import { useGetActiveTab } from './components/Toolbox/useGetActiveTab';
import { useUiStoreState } from './ui-store/uiStoreProvider';

export const toolboxWidth = 464;

export const resizeBarStyle = (isResizing: boolean, toolboxIsOpen: boolean) => ({
  cursor: toolboxIsOpen ? 'col-resize' : 'default',
  position: 'relative',
  zIndex: (theme: Theme) => theme.zIndex.modal - 1,
  width: 4,
  bgcolor: isResizing ? 'blue.400' : 'grey.100',
  borderLeft: 1,
  borderColor: 'divider',
  transition: (theme: Theme) =>
    theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.shortest,
    }),
  ...(toolboxIsOpen && {
    ':hover': {
      bgcolor: isResizing ? 'blue.800' : 'blue.600',
    },
  }),
  ':after': {
    content: isResizing ? '""' : 'none',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});

interface WorkspaceResizeProps {
  isTabless?: boolean;
}

export function useWorkspaceResize({ isTabless = false }: WorkspaceResizeProps) {
  const [sideBarSize, setSideBarSize] = useState({
    regular: toolboxWidth,
    large: 0.5,
  });

  const { toolboxState } = useUiStoreState();

  const handleSetSideBarSize = useCallback(
    (size: number) => {
      setSideBarSize((prevState) => {
        if (toolboxState.isExpanded) {
          return { ...prevState, large: size };
        }
        return { ...prevState, regular: size };
      });
    },
    [toolboxState.isExpanded, setSideBarSize],
  );

  const activeTab = useGetActiveTab();

  useEffect(() => {
    if (toolboxState.isExpanded) {
      handleSetSideBarSize(sideBarSize.large);
    } else {
      handleSetSideBarSize(sideBarSize.regular);
    }
  }, [toolboxState.isExpanded, handleSetSideBarSize, sideBarSize.large, sideBarSize.regular]);

  const sideBarSizeValue = () => {
    if (!activeTab && !isTabless) {
      return ToolboxNavigationSize;
    }

    return toolboxState.isExpanded ? sideBarSize.large : sideBarSize.regular;
  };

  return { sideBarSizeValue };
}
