import { Route, Routes } from 'react-router-dom-latest';

import { RouteDefinitionMap, User } from 'types';
import ProtectedRoute from './ProtectedRoute';

export interface RouterProps {
  me: User | null;
  routes: RouteDefinitionMap;
}

export default function Router({ me, routes }: RouterProps) {
  const renderLeafRoute = (path: string, routesDefinition: RouteDefinitionMap) => (
    <Route key={path} path={path} element={<ProtectedRoute me={me} {...routesDefinition[path]} />} />
  );

  const renderRoute = (path: string, routesDefinition: RouteDefinitionMap) => {
    const { nestedRoute, ...rest } = routesDefinition[path];

    if (!nestedRoute) {
      return renderLeafRoute(path, routesDefinition);
    }

    return (
      <Route key={path} path={path} element={<ProtectedRoute me={me} {...rest} />}>
        {Object.keys(nestedRoute!).map((nestedPath) => renderRoute(nestedPath, nestedRoute!))}
      </Route>
    );
  };

  return <Routes>{Object.keys(routes).map((path) => renderRoute(path, routes))}</Routes>;
}
