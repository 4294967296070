import { FocusEvent, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHeraldIndustryClassificationApi } from 'hooks/api/herald-api';
import { SuggestionProps } from 'components/hookFormComponents/types';
import FormDynamicAutocomplete from 'broker/components/FormDynamicAutocomplete';
import { convertIndustryObjectToOption } from './utils';

interface IndustryAutoCompleteProps {
  fieldProps: { name: string; label: string };
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLElement>) => boolean | undefined;
  suggestion?: Omit<SuggestionProps, 'value'> & { value: any };
  enhancedRequired?: boolean;
}

export default function IndustryAutoComplete({
  fieldProps,
  onBlur,
  suggestion,
  enhancedRequired,
}: IndustryAutoCompleteProps) {
  const { searchIndexEntries } = useHeraldIndustryClassificationApi();

  const { watch } = useFormContext();

  const value = watch(fieldProps.name);

  const selectedOption = value || undefined;
  const getApiOptions = async (filterValue: string) => {
    const response = await searchIndexEntries(filterValue);
    return response.classifications.map((classification) =>
      convertIndustryObjectToOption({
        ...classification,
        heraldId: classification.id,
        naicsCode: classification.naics_2017_6_digit,
      }),
    );
  };

  const formattedSuggestion = useMemo(() => {
    const industryFields = suggestion?.value;

    if (!industryFields?.naicsCode || !industryFields?.description) {
      return undefined;
    }

    return {
      ...suggestion,
      value: `${industryFields.naicsCode} - ${industryFields.description}`,
    };
  }, [suggestion]);

  return (
    <FormDynamicAutocomplete
      selectedOption={selectedOption}
      getApiOptions={getApiOptions}
      {...fieldProps}
      placeholder="Select Industry"
      onBlur={onBlur}
      suggestion={formattedSuggestion}
      enhancedRequired={enhancedRequired}
    />
  );
}
