import { useEffect } from 'react';
import { messages } from 'i18n';
import { InsuranceProduct } from 'types';
import useProductFilter from './useProductFilter';

interface useIsPrimaryFilterProps {
  products: InsuranceProduct[];
  productsLoaded: boolean;
  paginationReset: () => void;
}

const isPrimaryFilterOptions = [
  messages.addProductsPage.additionalFilters.isPrimary.primary,
  messages.addProductsPage.additionalFilters.isPrimary.excess,
];

export function useIsPrimaryFilter({ products, productsLoaded, paginationReset }: useIsPrimaryFilterProps) {
  const preSelectedFilter = messages.addProductsPage.additionalFilters.isPrimary.primary;
  const baseFiltersList = isPrimaryFilterOptions.map((label) => ({
    key: label,
    checked: label === preSelectedFilter,
    label,
  }));

  const isPrimaryFilter = useProductFilter({
    baseFiltersList,
    defaultSelectAll: false,
    paginationReset,
  });

  const setIsPrimaryFilters = isPrimaryFilter.setFilters;

  useEffect(() => {
    if (!productsLoaded || products.length === 0) {
      return;
    }

    const isPrimaryFilterSetter = (isPrimaryList: string[]) => {
      setIsPrimaryFilters((prev) =>
        isPrimaryList.map((label) => ({
          key: label,
          checked: prev.find((item) => item.key === label)?.checked ?? false,
          label,
        })),
      );
    };

    isPrimaryFilterSetter(isPrimaryFilterOptions);
  }, [productsLoaded, products, setIsPrimaryFilters]);

  return isPrimaryFilter;
}
