import { uniq } from 'lodash';
import { useEffect } from 'react';
import { PartialSubmission } from 'types';
import { stringCompare } from 'utils/comparators';
import { HotjarEvents } from 'utils/hotjar-events';
import useSubmissionFilter from './useSubmissionFilter';
import { SubmissionBoxStorageKeys, SubmissionsViewStorageKeys, SubmissionViewMode } from './utils';

interface useInsuredFilterProps {
  submissions: PartialSubmission[];
  areSubmissionsLoaded: boolean;
  paginationReset: () => void;
  mode: SubmissionViewMode;
}

export function useInsuredFilter({ submissions, areSubmissionsLoaded, paginationReset, mode }: useInsuredFilterProps) {
  const insuredFilter = useSubmissionFilter({
    baseFiltersList: [],
    storageKey:
      mode === SubmissionViewMode.General ? SubmissionsViewStorageKeys.insured : SubmissionBoxStorageKeys.insured,
    hotjarEvent: HotjarEvents.SubmissionsInsuredFilter,
    paginationReset,
  });

  const setInsuredFilters = insuredFilter.setFilters;

  // Update the filter options once we have the submissions from the server
  useEffect(() => {
    // We can't create insuredsList until data is loaded so just bail out
    if (!areSubmissionsLoaded || submissions.length === 0) {
      return;
    }

    const insuredSetter = (insuredsList: string[]) => {
      setInsuredFilters((prev) =>
        insuredsList.map((insured) => ({
          key: insured,
          checked: prev.find((line) => line.key === insured)?.checked ?? false,
          label: insured,
        })),
      );
    };

    const insuredsList = uniq(
      submissions.filter(({ insuredName }) => insuredName).map(({ insuredName }) => insuredName!),
    ).sort(stringCompare);

    insuredSetter(insuredsList);
  }, [areSubmissionsLoaded, submissions, setInsuredFilters]);

  return insuredFilter;
}
