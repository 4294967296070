// eslint-disable-next-line no-restricted-imports
import { Tooltip as MuiTooltip } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip/Tooltip';
import { styled, tooltipClasses } from '@common-components';
import { StyledTooltipMaxWidth, StyledTooltipMinWidth } from 'themes';

export const StyledTooltip = styled(
  ({
    className,
    placement,
    children,
    width,
    ...props
  }: MuiTooltipProps & { width?: number; type?: 'light' | 'dark' }) => (
    <MuiTooltip {...props} placement={placement} classes={{ popper: className }}>
      {children}
    </MuiTooltip>
  ),
)(({ theme, width, type }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: type === 'dark' ? theme.palette.purple[700] : theme.palette.purple[50],
    '&::before': {
      borderRadius: theme.spacing(0.1),
      boxShadow: theme.shadows[2],
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    visible: 'none',
    backgroundColor: type === 'dark' ? theme.palette.purple[700] : theme.palette.purple[50],
    boxShadow: theme.shadows[4],
    borderRadius: theme.spacing(0.5),
    minWidth: theme.spacing(StyledTooltipMinWidth),
    maxWidth: theme.spacing(StyledTooltipMaxWidth),
    color: type === 'dark' ? theme.palette.common.white : theme.palette.text.primary,
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    ...(width && { width: `${width}px` }),
    marginTop: '30px!important',
  },
  [`& .${tooltipClasses.popper}`]: {},
}));
