import { Close as CloseIcon } from '@icons';
import IconButton from 'components/IconButton';
import { Box, Stack, Typography } from 'components/mui-index';

const dialogHeaderHeight = 56;
export interface DialogHeaderProps {
  title: string;
  onClose: () => void;
  id: string;
}

export default function DialogHeader({ title, onClose, id }: DialogHeaderProps) {
  return (
    <Stack direction="row" alignItems="flex-end" px={2} height={dialogHeaderHeight} minHeight={dialogHeaderHeight}>
      <Typography id={id} flex={1} noWrap variant="h4" component="h2">
        {title}
      </Typography>
      <Box alignSelf="center">
        <IconButton
          icon={CloseIcon}
          color="secondary"
          edge="end"
          variant="text"
          aria-label="Close Dialog"
          onClick={onClose}
        />
      </Box>
    </Stack>
  );
}
