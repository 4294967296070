import { ReactNode, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from '@common-components';
import { Delete as DeleteIcon } from '@icons';
import ActionDialog from './ActionDialog';

const { red } = colors;

export interface CustomAction {
  title: string;
  iconElement: typeof SvgIcon;
  onClick: () => void;
}

export interface DetailCardProps {
  avatar: string;
  children?: ReactNode;
  onDelete?: () => Promise<void>;
  subheader?: string;
  title?: string;
  customActions?: CustomAction[];
}

export default function DetailCard({ avatar, children, onDelete, subheader, title, customActions }: DetailCardProps) {
  const [open, setOpen] = useState(false);

  const onClick = async () => {
    setOpen(false);
    onDelete?.();
  };

  return (
    <Card>
      <Card sx={{ mb: 1 }}>
        <CardHeader
          avatar={<Avatar sx={{ backgroundColor: red[500] }}>{avatar}</Avatar>}
          title={title}
          subheader={subheader}
        />
      </Card>
      <CardContent>{children}</CardContent>
      <CardActions sx={{ px: 2, gap: 8 }}>
        {onDelete && (
          <Stack direction="column" gap={-2}>
            <Typography sx={{ mb: 1, fontSize: 12, color: 'red' }}>Delete</Typography>
            <IconButton
              aria-label="delete"
              icon={DeleteIcon}
              onClick={() => setOpen(true)}
              color="secondary"
              variant="text"
            />
          </Stack>
        )}
        {customActions?.map((action) => (
          <Stack key={action.title} direction="column" gap={-2}>
            <Typography sx={{ mb: 1, fontSize: 12 }}>{action.title}</Typography>
            <Box sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
              <IconButton
                aria-label="custom-icon"
                onClick={action.onClick}
                color="secondary"
                variant="text"
                icon={action.iconElement}
              />
            </Box>
          </Stack>
        ))}
      </CardActions>
      {onDelete && (
        <ActionDialog
          actionLabel="Yes, Delete"
          cancelLabel="No, Cancel"
          title={`Delete ${title}?`}
          open={open}
          onAction={onClick}
          onClose={() => setOpen(false)}
        />
      )}
    </Card>
  );
}
