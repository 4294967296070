import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import EditSubmissionContent from './EditSubmissionContent';

export default function EditSubmissionNew() {
  return (
    <WithRouteLocationState routeKey={RouteKey.SubmissionEdit} isStateRequired={false}>
      {(locationState) => <EditSubmissionContent locationState={locationState} />}
    </WithRouteLocationState>
  );
}
