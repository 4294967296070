import { useParams } from 'react-router-dom-latest';
import { Grid, Paper, Stack } from '@common-components';
import { QueueOutlined as AddIcon, SyncAlt as ConvertIcon, UploadFile as UploadFileIcon } from '@icons';
import { isMarketOrganization, isUserOrganization, OrganizationType } from 'enums';
import { getOrganizationType } from 'enums/type-mappers/organization-type-config';
import { useBoolean, useMutateOrganization, useRetrieveOrganization } from 'hooks';
import { messages } from 'i18n';
import { AnyObject } from 'types';
import CardTextField from 'components/CardTextField';
import DetailCard, { CustomAction } from 'components/DetailCard';
import InsuredsBulkUploadDialog from 'admin/components/InsuredsBulkUploadDialog';
import OrganizationContactList from 'admin/components/OrganizationContactList';
import TeamList from 'admin/components/TeamList';
import UserList from 'admin/components/UserList';
import TeamCreateDialog from 'admin/dialogs/TeamCreateDialog';
import { ConvertMarketTypeDialog } from './ConvertMarketTypeDialog';
import UserOrganizationConfiguration from './UserOrganizationConfiguration';
import WhitelistedDomainList from './WhitelistedDomainList';

export default function OrganizationPage() {
  const { id: organizationId } = useParams<{ id: string }>();
  const [isInsuredsUploadShown, { on: showInsuredsUpload, off: hideInsuredUpload }] = useBoolean(false);
  const [isConvertDialogShown, { on: showConvertDialog, off: hideConvertDialog }] = useBoolean(false);
  const [isTeamCreationShown, { on: showCreateTeamDialog, off: hideCreateTeamDialog }] = useBoolean(false);

  const { data: organization } = useRetrieveOrganization({ id: organizationId!, enabled: !!organizationId });
  const { updateOrganization } = useMutateOrganization();

  if (!organization) {
    return null;
  }

  // Having a box folder is a good heuristic to know if this organization has any users
  // because we only create a box folder upon the creation of the first user
  // Capitola org type is a special case where it always has users and never has a box folder
  const isOrganizationWithUsers = !!organization.boxFolderId || organization.type === OrganizationType.Capitola;

  const props = {
    organization,
    maxHeight: '35vh',
  } as AnyObject; // this cast is cheating

  async function setField(fieldName: string, value: string | object | null) {
    await updateOrganization.mutateAsync({
      id: organization!.id,
      data: {
        [fieldName]: value,
      },
    });
  }

  function getCustomActions(): CustomAction[] {
    if (organization?.type === OrganizationType.Capitola) {
      return [
        {
          title: messages.admin.organizationPage.uploadInsuredAction,
          iconElement: UploadFileIcon,
          onClick: showInsuredsUpload,
        },
      ];
    }

    const actions: CustomAction[] = [];

    if (isMarketOrganization(organization!.type)) {
      actions.push({
        title: messages.admin.organizationPage.convertMarketTypeAction(organization!),
        iconElement: ConvertIcon,
        onClick: showConvertDialog,
      });
    }

    if (isUserOrganization(organization!.type)) {
      actions.push({
        title: messages.admin.organizationPage.addTeamAction,
        iconElement: AddIcon,
        onClick: showCreateTeamDialog,
      });
    }

    return actions;
  }

  return (
    <>
      <DetailCard avatar="C" title={organization.name} customActions={getCustomActions()}>
        <Stack gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <CardTextField onChange={(value) => setField('name', value)} label="Name" value={organization.name} />
            </Grid>
            <Grid item xs={3}>
              <CardTextField label="Type" value={getOrganizationType(organization.type)} />
            </Grid>
          </Grid>
          {isUserOrganization(organization.type) && (
            <UserOrganizationConfiguration organization={organization} setField={setField} />
          )}
        </Stack>
      </DetailCard>
      {isOrganizationWithUsers && (
        <Stack direction="row" flex={1}>
          <Paper sx={{ m: 1, flex: 1 }}>
            <TeamList {...props} />
          </Paper>

          <Paper sx={{ m: 1, flex: 1 }}>
            <UserList {...props} />
          </Paper>
        </Stack>
      )}
      <Paper sx={{ m: 1, flex: 1 }}>
        <OrganizationContactList organization={organization} />
      </Paper>
      <InsuredsBulkUploadDialog
        organization={organization}
        isOpen={isInsuredsUploadShown}
        onClose={hideInsuredUpload}
      />
      <ConvertMarketTypeDialog
        organization={organization}
        isOpen={isConvertDialogShown}
        onClose={hideConvertDialog}
        onConvert={() =>
          setField(
            'type',
            organization.type === OrganizationType.Broker ? OrganizationType.Carrier : OrganizationType.Broker,
          )
        }
      />
      <TeamCreateDialog organization={organization} open={isTeamCreationShown} onClose={hideCreateTeamDialog} />
      <Stack width="50%" mt={2}>
        {isMarketOrganization(organization.type) && <WhitelistedDomainList organization={organization} />}
      </Stack>
    </>
  );
}
