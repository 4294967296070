import { compact, flatten } from 'lodash';
import { CoverageValuesUpdate, HeraldCategoryType, HeraldUpdateApplication, RiskValuesUpdate } from 'clients/types';
import { IndustryAutoCompleteOption } from 'broker/components/common/IndustryAutoComplete/types';
import {
  DynamicFormQuestionSource,
  FormStateBaseProps,
  heraldIndustryQuestionParameterId,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';
import { removeEndingAfterUnderscore } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/utils/mapping-utils';

export default function mapFormStateToHerald(
  formState: Record<string, FormStateBaseProps | FormStateBaseProps[] | undefined>,
  heraldNormalizedParameter: HeraldNormalizedParameter[],
): Omit<HeraldUpdateApplication, 'products'> {
  const dataToUpdate: Required<Omit<HeraldUpdateApplication, 'products'>> = { coverage_values: [], risk_values: [] };

  const mapStateItemToHeraldParameterUpdate = (
    state: FormStateBaseProps | FormStateBaseProps[],
    normalizedParameter: HeraldNormalizedParameter,
  ): CoverageValuesUpdate[] | RiskValuesUpdate[] => {
    const buildHeraldItemForUpdate = (
      formStateItemValue: FormStateBaseProps,
      index?: number,
    ): RiskValuesUpdate | CoverageValuesUpdate => {
      const instance = Array.isArray(state)
        ? `${removeEndingAfterUnderscore(normalizedParameter.instance!)}_${index! + 1}`
        : undefined;
      let normalizedUpdate = formStateItemValue.main;
      if (normalizedUpdate && normalizedParameter.parameter_id === heraldIndustryQuestionParameterId) {
        const industryAutoCompleteValue = normalizedUpdate as IndustryAutoCompleteOption;
        normalizedUpdate = industryAutoCompleteValue.heraldId;
      }
      return {
        [normalizedParameter.heraldCategory === HeraldCategoryType.RiskValues
          ? 'risk_parameter_id'
          : 'coverage_parameter_id']: normalizedParameter.parameter_id,
        value: typeof normalizedUpdate !== 'undefined' ? normalizedUpdate : null,
        instance,
        [`child_${normalizedParameter.heraldCategory}`]: formStateItemValue.children
          ? flatten(
              compact(
                Object.keys(formStateItemValue.children).map((childParameterId) => {
                  const value = formStateItemValue.children![childParameterId];
                  const childNormalizedParameter = normalizedParameter?.arrayElements
                    ?.flatMap((arrayElement) =>
                      arrayElement.childValues?.find((childValue) => childValue.parameter_id === childParameterId),
                    )
                    .find(Boolean);

                  if (childNormalizedParameter && value) {
                    return mapStateItemToHeraldParameterUpdate(value, childNormalizedParameter) as RiskValuesUpdate[];
                  }
                  return undefined;
                }),
              ),
            )
          : undefined,
      } as RiskValuesUpdate | CoverageValuesUpdate;
    };

    // creates_array
    if (Array.isArray(state)) {
      const formStateItemValues = state as FormStateBaseProps[];
      return formStateItemValues.map((formStateItemValue, index) =>
        buildHeraldItemForUpdate(formStateItemValue, index),
      ) as RiskValuesUpdate[] | CoverageValuesUpdate[];
    }
    const formStateItemValue = state as FormStateBaseProps;
    return [buildHeraldItemForUpdate(formStateItemValue)] as RiskValuesUpdate[] | CoverageValuesUpdate[];
  };

  Object.keys(formState).forEach((heraldParameterId) => {
    const normalizedParameter = heraldNormalizedParameter.find((param) => param.parameter_id === heraldParameterId);
    const formValue = formState[heraldParameterId];
    if (formValue && normalizedParameter?.source === DynamicFormQuestionSource.Herald) {
      const valuesForUpdate = mapStateItemToHeraldParameterUpdate(formValue, normalizedParameter);
      if (normalizedParameter.heraldCategory === HeraldCategoryType.RiskValues) {
        dataToUpdate.risk_values = [...dataToUpdate.risk_values, ...(valuesForUpdate as RiskValuesUpdate[])];
      } else {
        dataToUpdate.coverage_values = [
          ...dataToUpdate.coverage_values,
          ...(valuesForUpdate as CoverageValuesUpdate[]),
        ];
      }
    }
  });
  return dataToUpdate;
}
