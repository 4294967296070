import { get } from 'lodash';
import { FocusEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, CheckboxProps, FormControlLabel, Typography } from '@common-components';

interface FormCheckboxProps extends Omit<CheckboxProps, 'defaultValue' | 'onChange' | 'onBlur'> {
  label: string;
  name: string;
  id: string;
  disabled?: boolean;
  defaultValue?: boolean;
  onChange?: (value: boolean) => void;
  checkedValue?: any;
  onBlur?: (e: FocusEvent<HTMLButtonElement>) => boolean | undefined;
}

export default function FormCheckbox({
  name,
  id,
  label,
  defaultValue = false,
  onChange,
  disabled = false,
  checkedValue,
  onBlur,
  ...checkboxProps
}: FormCheckboxProps) {
  const {
    control,
    formState: { errors },
    watch,
    trigger,
  } = useFormContext();

  const value = watch(name);

  const errorMessage = get(errors, name)?.message;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { onChange: formHookOnChange, onBlur: fieldPropsOnBlur, ...fieldProps } }) => (
        <FormControlLabel
          label={<Typography variant="caption">{label}</Typography>}
          id={id}
          control={
            <Checkbox
              {...fieldProps}
              onChange={(event) => {
                let formValue = event.target.checked;
                if (typeof checkedValue !== 'undefined') {
                  formValue = formValue ? checkedValue : null;
                }
                formHookOnChange(formValue);
                if (onChange) {
                  onChange(event.target.checked);
                }
                if (errorMessage) {
                  trigger(name);
                }
              }}
              disableRipple
              checked={typeof checkedValue === 'undefined' ? value : checkedValue === value}
              disabled={disabled}
              {...checkboxProps}
              onBlur={(e) => {
                let allowPropagation: boolean = true;
                if (onBlur) {
                  const response = onBlur(e);
                  if (response !== undefined) {
                    allowPropagation = response;
                  }
                }
                if (allowPropagation) {
                  fieldPropsOnBlur();
                }
              }}
            />
          }
        />
      )}
    />
  );
}
