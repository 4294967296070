import { CreateNylasUserTokenResponse, NylasAuthenticationUrl, NylasUser } from 'types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export default class NylasUserClient extends CapitolaClient<NylasUser> {
  public get resourceName(): string {
    return 'nylas-user';
  }

  public async getAuthenticationUrl(): Promise<NylasAuthenticationUrl | null> {
    return this.custom({
      httpMethod: HttpMethods.GET,
      path: 'auth-url',
      fetchOptions: { throwOnError: true },
    });
  }

  public async createAccessToken(code: string): Promise<CreateNylasUserTokenResponse | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      path: 'create-token',
      body: {
        code,
      },
      fetchOptions: { throwOnError: true },
    });
  }
}
