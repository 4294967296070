import { Chip } from '@common-components';
import { FilterElementProps } from 'utils';

interface CoveragesFilterItemProps {
  coverage: FilterElementProps;
  handleVisibleLabelChange: (key: string) => void;
}

export default function CoveragesFilterItem({ coverage, handleVisibleLabelChange }: CoveragesFilterItemProps) {
  return (
    <Chip
      size="medium"
      key={coverage.key}
      label={coverage.label}
      onClick={() => {
        handleVisibleLabelChange(coverage.key);
      }}
      color={coverage.checked ? 'primary' : 'secondary'}
      focused={coverage.checked}
      variant={coverage.checked ? 'contained' : 'outlined'}
      rounded
    />
  );
}
