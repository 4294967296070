import { useEffect, useState } from 'react';

import { ExtendedMarketRequest } from 'types';

interface SelectedMarketRequest {
  marketRequest: ExtendedMarketRequest;
  selected: boolean;
}

export type SelectedMarketRequests = Record<string, SelectedMarketRequest>;

interface useSelectionMarketRequestsProps {
  marketRequests: ExtendedMarketRequest[];
  selectedFilter: string | undefined;
  multipleSelection: boolean;
}
export default function useSelectionMarketRequests({
  marketRequests,
  selectedFilter,
  multipleSelection,
}: useSelectionMarketRequestsProps) {
  const setInitialMarketRequests = () =>
    marketRequests.reduce(
      (prev, current) => ({ ...prev, [current.id]: { marketRequest: current, selected: false } }),
      {},
    );

  const [selectedMarketRequests, setSelectedMarketRequests] = useState<SelectedMarketRequests>(
    setInitialMarketRequests(),
  );

  useEffect(() => {
    setSelectedMarketRequests((prev) => {
      const prevMarketIds = Object.keys(prev);
      return marketRequests.reduce((accumulator, marketRequest) => {
        const existingSelectedMarketRequestId = prevMarketIds.find((prevMarketId) => prevMarketId === marketRequest.id);
        return {
          ...accumulator,
          [marketRequest.id]: {
            marketRequest,
            selected: existingSelectedMarketRequestId ? prev[existingSelectedMarketRequestId].selected : false,
          },
        };
      }, {} as SelectedMarketRequests);
    });
  }, [marketRequests]);

  useEffect(() => {
    setSelectedMarketRequests((prev) =>
      Object.keys(prev).reduce(
        (accumulator, currentMarketRequestId) => ({
          ...accumulator,
          [currentMarketRequestId]: { ...prev[currentMarketRequestId], selected: false },
        }),
        {},
      ),
    );
  }, [selectedFilter]);

  const onMarketRequestSelected = (marketRequestId: string) => {
    setSelectedMarketRequests((prev) =>
      Object.keys(prev).reduce((accumulator, currentMarketRequestId) => {
        // const marketRequestIdSelection = prev[marketRequestId]
        let selectionValue: boolean;
        if (currentMarketRequestId === marketRequestId) {
          selectionValue = !prev[currentMarketRequestId].selected;
        } else {
          selectionValue =
            multipleSelection || prev[marketRequestId].selected ? prev[currentMarketRequestId].selected : false;
        }
        return {
          ...accumulator,
          [currentMarketRequestId]: {
            ...prev[currentMarketRequestId],
            selected: selectionValue,
          },
        };
      }, {}),
    );
  };

  const clearSelections = () => {
    setSelectedMarketRequests(setInitialMarketRequests());
  };

  return {
    selectedMarketRequests,
    onMarketRequestSelected,
    clearSelections,
  };
}
