import { Stack } from '@common-components';
import { messages } from 'i18n';
import { CopilotSteps, PartialSubmission } from 'types';
import useCopilotSettings from 'broker/dialogs/SubmissionAutonomousCreation/useCopilotSettings';
import BulletContent from './marketing/BulletContent';

export default function Binding({ submission }: { submission: PartialSubmission }) {
  const copilotSettings = useCopilotSettings({ submission, copilotStep: CopilotSteps.BindersAndPolicies });

  const bindingBulletMessages = messages.autonomousFlow.settingsModal.steps.binding.bullets;
  return (
    <Stack>
      <BulletContent
        prefix={bindingBulletMessages.binderDelayedDaysNotificationThreshold.prefix}
        bold={bindingBulletMessages.binderDelayedDaysNotificationThreshold.bold(
          copilotSettings?.binderDelayedDaysNotificationThreshold || 0,
        )}
        suffix={bindingBulletMessages.binderDelayedDaysNotificationThreshold.suffix}
      />
    </Stack>
  );
}
