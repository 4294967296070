import { isEmpty } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useMutateSubmissionEmail } from 'hooks/api/submissionEmail';
import { useMutateSubmissionInboundEmail } from 'hooks/api/submissionInboundEmail';
import { messages } from 'i18n';
import { SubmissionMarket } from 'types';
import { EmailItemType } from 'broker/components/Emails/types';
import LabelsMenu, { LabelsMenuAnchorType } from 'broker/components/LabelsMenu';
import useSingleFieldOptimisticUpdate from 'broker/hooks/useSingleFieldOptimisticUpdate';
import { EmailListItemAttributes } from './utils';

interface MarketsMenuProps {
  email: EmailListItemAttributes;
  markets: SubmissionMarket[];
}

export default function MarketsMenu({ email, markets }: MarketsMenuProps) {
  const { updateSubmissionEmail } = useMutateSubmissionEmail();
  const { updateSubmissionInboundEmail } = useMutateSubmissionInboundEmail();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const labelsValue = email.labels?.organizationIds;
  const isInboundEmailMessage =
    email.type === EmailItemType.SubmissionInbound || email.type === EmailItemType.SubmissionInboundFromUser;

  const updateOutbound = updateSubmissionEmail.mutateAsync;
  const updateSubmissionEmailLabels = useCallback(
    async (marketOrganizationIds: string[] | null) => {
      await updateOutbound({
        id: email.id,
        data: { labels: marketOrganizationIds ? { organizationIds: marketOrganizationIds } : null },
      });
    },
    [email.id, updateOutbound],
  );

  const updateInbound = updateSubmissionInboundEmail.mutateAsync;
  const updateSubmissionInboundEmailLabels = useCallback(
    async (marketOrganizationIds: string[] | null) => {
      await updateInbound({
        id: email.submissionInboundEmailId!,
        data: { labels: marketOrganizationIds ? { organizationIds: marketOrganizationIds } : null },
      });
    },
    [email.submissionInboundEmailId, updateInbound],
  );

  const { value: selectedMarkets, onChange: onMarketsChange } = useSingleFieldOptimisticUpdate<string[] | null>({
    serverValue: labelsValue,
    apiUpdate: isInboundEmailMessage ? updateSubmissionInboundEmailLabels : updateSubmissionEmailLabels,
  });

  const anchorType = isEmpty(selectedMarkets) ? LabelsMenuAnchorType.AddLabel : LabelsMenuAnchorType.EditLabel;

  const onLabelChanged = async (labels?: string) => {
    setIsMenuOpen(false);
    const newLabels = labels ? labels.split(',') : null;
    await onMarketsChange(newLabels);
  };

  const selectedOptions = useMemo(() => {
    if (isEmpty(selectedMarkets)) {
      return undefined;
    }
    return markets
      .filter((market) => selectedMarkets?.includes(market.marketOrganizationId))
      .map((market) => ({
        label: market.marketName,
        value: market.marketOrganizationId,
      }));
  }, [selectedMarkets, markets]);

  const getOptions = useMemo(
    () =>
      markets.map((market) => ({
        label: market.marketName,
        value: market.marketOrganizationId,
      })),
    [markets],
  );

  return (
    <LabelsMenu
      options={getOptions}
      anchorType={anchorType}
      onLabelChanged={onLabelChanged}
      onClearLabel={onLabelChanged}
      isOpenOverride={isMenuOpen}
      selectedLabels={selectedOptions}
      multiSelect
      showTooltip
      addLabelText={messages.emailLabelsMenu.addLabelText}
      disabled={isEmpty(markets)}
      disabledTooltipContent={messages.emailLabelsMenu.noMarketsAvailable}
    />
  );
}
