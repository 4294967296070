import { isEmpty } from 'lodash';
import { Box, Button, Stack } from '@common-components';
import { KeyboardArrowLeft } from '@icons';
import { useBoolean, useToast } from 'hooks';
import { messages } from 'i18n';
import EmailReminderToggle from 'broker/components/Emails/EmailReminderToggle';
import SendEmailButton from 'broker/components/Emails/SendEmailButton';
import PreviouslySubmittedMarketsConfirmation from 'broker/components/Emails/SendEmailButton/PreviouslySubmittedMarketsConfirmation';
import useEmailEditorActions from './store/useEmailEditorActions';
import useEmailEditorState from './store/useEmailEditorState';

export interface EmailEditorFooterProps {
  proceedButtonText?: string;
  cancelButtonText?: string;
  // By default, onClose is called after email is sent (same as after cancel button is clicked), but this can be overridden with onAfterSent
  // this is where it is safe to navigate away from the email editor
  onAfterSent?: () => void;
  successMessage?: string;
  // By default, the secondary button will close the email editor, but this can be overridden with a custom action
  secondaryButtonText?: string;
  secondaryButtonAction?: (draftBody: string) => Promise<void>;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  onClose: () => void;
  backButtonText?: string;
  backButtonAction?: () => Promise<void>;
}

export default function EmailEditorFooter({
  onClose,
  cancelButtonText = messages.buttons.cancel,
  proceedButtonText,
  onAfterSent,
  successMessage,
  secondaryButtonAction,
  secondaryButtonText,
  backButtonAction,
  backButtonText,
}: EmailEditorFooterProps) {
  const {
    recipients: { recipientGroupsArray },
    attachedFiles: { attachedFiles },
    draftEditing: { isEditingContentDirty, isActiveTemplateInstanceDetached },
    isAnyEmailEmpty,
    preSelectedTemplateType,
    isSubmittingEmail,
    submissionsWorkspace: { submission, markets },
    addTask,
  } = useEmailEditorState();
  const [isSubmitting, { off: stopSubmitting, on: startSubmitting }] = useBoolean(false);
  const [isLoadingSecondaryAction, { off: stopLoadingSecondaryAction, on: startLoadingSecondaryAction }] =
    useBoolean(false);
  const [isLoadingBackAction, { off: stopLoadingBackAction, on: startLoadingBackAction }] = useBoolean(false);
  const isLoading = isSubmitting || isLoadingSecondaryAction || isLoadingBackAction;

  const { sendEmail, toggleAddTask } = useEmailEditorActions();
  const {
    resolvedMessages,
    draftPreview: { body: draftBody },
  } = useEmailEditorState();
  const { showToast } = useToast();

  const missingRecipients =
    isEmpty(recipientGroupsArray) ||
    recipientGroupsArray.some((recipientGroup) => recipientGroup.recipients.length === 0);

  const hasPendingChanges = isEditingContentDirty && !isActiveTemplateInstanceDetached;
  const isSendButtonDisabled = isAnyEmailEmpty || hasPendingChanges || missingRecipients || isSubmitting;

  const recipientEmails = new Set(
    recipientGroupsArray.flatMap((recipientGroup) => recipientGroup.recipients.map((contact) => contact.email)),
  );

  const handleSendEmailClick = async () => {
    startSubmitting();
    const sentEmailsCount = await sendEmail();
    if (sentEmailsCount === 0) {
      stopSubmitting();
      return;
    }
    showToast('success', {
      message: successMessage ?? messages.emailEditor.sentMessagesScreen.messagesSent(sentEmailsCount),
    });

    if (onAfterSent) {
      onAfterSent();
    } else {
      // Use default onClose if no custom onAfterSent is provided
      onClose();
    }

    stopSubmitting();
  };

  // Secondary button is optional, if not provided, the default action is 'Cancel'
  const secondaryButton = secondaryButtonAction ? (
    <Button
      variant="outlined"
      onClick={async () => {
        startLoadingSecondaryAction();
        await secondaryButtonAction(draftBody);
        stopLoadingSecondaryAction();
      }}
      loading={isLoadingSecondaryAction}
    >
      {secondaryButtonText}
    </Button>
  ) : (
    <Button variant="outlined" onClick={onClose}>
      {cancelButtonText}
    </Button>
  );

  const isCustomerEmail = !!(submission?.user?.email && recipientEmails.has(submission.user.email));
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Box flex={1} justifyContent="start">
        {backButtonAction && (
          <Button
            variant="text"
            onClick={async () => {
              startLoadingBackAction();
              await backButtonAction();
              stopLoadingBackAction();
            }}
            disabled={isLoading}
            startIcon={<KeyboardArrowLeft />}
            loading={isLoadingBackAction}
          >
            {backButtonText}
          </Button>
        )}
      </Box>
      <Stack alignItems="center" direction="row" gap={1} justifyContent="flex-end">
        {secondaryButton}
        <SendEmailButton
          isLoading={isSubmittingEmail}
          onSend={handleSendEmailClick}
          isDisabled={isSendButtonDisabled}
          disabledTooltipContent={messages.emailEditor.sendEmailDisabledTooltip}
          recipientGroups={recipientGroupsArray}
          attachedFiles={attachedFiles}
          emailTemplateType={preSelectedTemplateType}
          isCustomerEmail={isCustomerEmail}
          isCopilotEnabled={submission?.isCopilotEnabled}
          CustomConfirmationMessage={() => (
            <PreviouslySubmittedMarketsConfirmation
              markets={markets}
              recipientGroups={recipientGroupsArray}
              emailTemplateType={preSelectedTemplateType}
            />
          )}
          resolvedMessages={resolvedMessages}
          buttonCaption={proceedButtonText}
        />
        <EmailReminderToggle checked={!!addTask} onChange={toggleAddTask} />
      </Stack>
    </Stack>
  );
}
