import { isEmpty, isNil, some } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { Button, LoaderOverlay } from '@common-components';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import { markdownToHtml } from 'utils';
import { EmailEditorProps } from 'broker/components/EmailEditor/store/EmailEditorStoreProvider';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { CreateThreadAction } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/SingleAIFeatureContextProvider';
import { useCreateAIThread } from 'broker/pages/SubmissionWorkspacePage/hooks';
import InnerPageLayout from './InnerPage/InnerPageLayout';

import SubmissionEmailEditor from './SubmissionEmailEditor';

interface SubmissionEmailEditorWithAiProps {
  isDirty?: boolean;
  children: ReactElement;
  emailEditorProps: (bodyContent: string) => Partial<EmailEditorProps>;
  title: string;
  isLoading: boolean;
  isSkipAllowed?: boolean;
  initialBodyContent?: string | null;
  aiThread: {
    createThread: CreateThreadAction;
    creationCondition?: boolean;
    callback?: (response: string) => void;
  };
  contentDecorator: (generatedContent: string) => string;
}

export default function SubmissionEmailEditorWithAI({
  isDirty,
  emailEditorProps,
  children,
  title,
  isLoading,
  initialBodyContent,
  isSkipAllowed,
  contentDecorator,

  aiThread: { createThread, creationCondition, callback },
}: SubmissionEmailEditorWithAiProps) {
  const [loadingSkippedByUser, { on: skipLoading }] = useBoolean(false);
  const [bodyContent, setBodyContent] = useState<string>('');
  const { isLoading: isLoadingAIContent, messages: aiMessages, isRegenerating } = useAIContext();

  // Using this state to prevent the loader from showing for a short time after sending, when the draft is being cleared from the submission
  const [draftUsed, setDraftUsed] = useState<boolean>(false);

  const { emailContentLoadingText, skipEmailGenerationLoading } = messages.general;

  useEffect(() => {
    if (isEmpty(bodyContent) && initialBodyContent) {
      setBodyContent(initialBodyContent);
      setDraftUsed(true);
    }
  }, [initialBodyContent, bodyContent]);

  // This is a nasty hack to keep the component in a loading state until the AI content starts loading
  const [aiLoadingStarted, setAiLoadingStarted] = useState<boolean>(isLoadingAIContent || !isEmpty(aiMessages));
  useEffect(() => {
    if (isLoadingAIContent) {
      // Set to true after AI content started loading
      setAiLoadingStarted(true);
    }
  }, [isLoadingAIContent]);
  // ==========================================================================================

  const shouldWaitForAIContent = () => {
    // AI initial loading still hasn't started
    if (!aiLoadingStarted) {
      // If we know there is a saved draft, then don't wait (since we won't generate AI content)
      return isNil(initialBodyContent);
    }

    // AI is currently in loading state
    if (isLoadingAIContent) {
      const isStreamingFollowUpMessages = some(aiMessages, (message) => !message.isStreaming);

      // We only want the blocking loader overlay when the initial AI content is loading
      return !(isRegenerating || isStreamingFollowUpMessages);
    }

    // AI is not in loading state
    return false;
  };

  const isLocalLoading = isLoading || (!draftUsed && shouldWaitForAIContent());

  const getEmailBody = (generatedContent: string) => contentDecorator(markdownToHtml(generatedContent));

  const handleSkipLoading = () => {
    setBodyContent(getEmailBody(''));
    skipLoading();
  };

  useCreateAIThread(createThread, creationCondition, (result) => {
    const content = getEmailBody(result?.response || '');
    setBodyContent(content);
    callback?.(content);
  });

  if (!loadingSkippedByUser && (isLocalLoading || !bodyContent)) {
    return (
      <InnerPageLayout title={title}>
        {() => (
          <LoaderOverlay
            text={emailContentLoadingText}
            extraContent={
              isSkipAllowed && <Button onClick={() => handleSkipLoading()}>{skipEmailGenerationLoading}</Button>
            }
          />
        )}
      </InnerPageLayout>
    );
  }

  return (
    <SubmissionEmailEditor isDirty={isDirty} emailEditorProps={emailEditorProps(bodyContent)}>
      {children}
    </SubmissionEmailEditor>
  );
}
