import { IconButton } from '@common-components';
import { PushPin, PushPinOutlined } from '@icons';

import { messages } from 'i18n';

export interface PinnerProps {
  isPinned: boolean;
  addPinnedRow: (id: string) => void;
  submissionId: string;
}

function Pinner({ isPinned, addPinnedRow, submissionId }: PinnerProps) {
  return (
    <IconButton
      icon={isPinned ? PushPin : PushPinOutlined}
      active={isPinned}
      color="secondary"
      variant="text"
      size="small"
      aria-label={messages.buttons.delete}
      iconRotation={isPinned ? 0 : 0.15}
      onClick={(e: any) => {
        e.stopPropagation();
        addPinnedRow(submissionId);
      }}
    />
  );
}

export default Pinner;
