import { IconButton } from '@common-components';
import { Delete as DeleteIcon } from '@icons';
import { useBoolean, useInvalidateUserMarketCache, useMutateContact } from 'hooks';
import { messages } from 'i18n';
import { Contact } from 'types';
import { ConfirmationDialog } from 'components/Dialog';

interface DeleteContactButtonProps {
  contact: Contact;
}

export function DeleteContactButton({ contact }: DeleteContactButtonProps) {
  const { submissionWorkspace, buttons } = messages;

  const dialogMessages = submissionWorkspace.dialogs.deleteContact;

  const { updateContact } = useMutateContact();
  const invalidateUserMarketCache = useInvalidateUserMarketCache();
  const [isDeleteContactConfirmationOpen, { on: openDeleteContactConfirmation, off: closeDeleteContactConfirmation }] =
    useBoolean(false);

  const deleteContact = async () => {
    await updateContact.mutateAsync({
      id: contact.id,
      data: { disabled: true },
    });
    await invalidateUserMarketCache();
  };

  return (
    <>
      <IconButton
        onClick={openDeleteContactConfirmation}
        icon={DeleteIcon}
        size="small"
        variant="text"
        color="secondary"
      />
      <ConfirmationDialog
        title={dialogMessages.title}
        message={dialogMessages.body}
        isOpen={isDeleteContactConfirmationOpen}
        onCancel={closeDeleteContactConfirmation}
        onSubmit={deleteContact}
        cancelButton={{ children: buttons.cancel, color: 'secondary' }}
        proceedButton={{
          loading: false,
          color: 'error',
          children: dialogMessages.button,
        }}
      />
    </>
  );
}
