import { ContactRole } from 'enums';
import { messages } from 'i18n';

interface Config {
  text: string;
}

const contactRoleConfig: Record<ContactRole, Config> = {
  [ContactRole.Underwriter]: {
    text: messages.contactRole[ContactRole.Underwriter],
  },
  [ContactRole.SubmissionBox]: {
    text: messages.contactRole[ContactRole.SubmissionBox],
  },
  [ContactRole.Broker]: {
    text: messages.contactRole[ContactRole.Broker],
  },
  [ContactRole.Insured]: {
    text: messages.contactRole[ContactRole.Insured],
  },
  [ContactRole.Retailer]: {
    text: messages.contactRole[ContactRole.Retailer],
  },
};

export default contactRoleConfig;
