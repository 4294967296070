import { Box } from '@common-components';

export default function ScanningAnimation() {
  return (
    <Box
      sx={{
        position: 'absolute',
        overflow: 'hidden',
        top: 50,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1,
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '10px',
          bgcolor: 'green.300',
          boxShadow: (theme) => `0 0 300px 20px ${theme.palette.green[300]}`,
          clipPath: 'inset(0)',
          animation: 'x 2s ease-in-out infinite, y 2s ease-in-out infinite',
        },
        '@keyframes x': {
          from: {
            top: '-10%',
          },
          to: {
            top: '100%',
          },
        },
        '@keyframes y': {
          '0%': {
            clipPath: 'inset(0 0 100% 0)',
          },
          '100%': {
            clipPath: 'inset(-200px 0 0 0)',
          },
        },
      }}
    />
  );
}
