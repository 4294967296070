import * as yup from 'yup';
import { ContactRole } from 'enums';
import { messages } from 'i18n';
import { Contact } from 'types';

const { requiredField, invalidEmail } = messages.formMessages;

export type ContactData = Pick<Contact, 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'role' | 'title'>;

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .when('role', {
      is: ContactRole.SubmissionBox,
      otherwise: yup.string().required(requiredField),
    }),
  lastName: yup
    .string()
    .trim()
    .when('role', {
      is: ContactRole.SubmissionBox,
      otherwise: yup.string().required(requiredField),
    }),
  email: yup.string().trim().email(invalidEmail).required(requiredField),
  phoneNumber: yup.string().nullable(true).trim(),
  title: yup.string().nullable(true).trim(),
  role: yup.string().required(requiredField),
});

export enum ContactFormField {
  Role = 'role',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  Title = 'title',
}

export interface ContactFormDisplayData {
  name: string;
  id: string;
  placeholder: string;
}

function buildDisplayData(field: ContactFormField, placeholder: string): ContactFormDisplayData {
  return {
    name: field,
    id: field,
    placeholder,
  };
}

export const contactFormConfig: Record<ContactFormField, ContactFormDisplayData> = {
  [ContactFormField.Role]: buildDisplayData(ContactFormField.Role, ''),
  [ContactFormField.Email]: buildDisplayData(
    ContactFormField.Email,
    messages.submissionMarketToolbox.contacts.placeholders.email,
  ),
  [ContactFormField.FirstName]: buildDisplayData(
    ContactFormField.FirstName,
    messages.submissionMarketToolbox.contacts.placeholders.firstName,
  ),
  [ContactFormField.LastName]: buildDisplayData(
    ContactFormField.LastName,
    messages.submissionMarketToolbox.contacts.placeholders.lastName,
  ),
  [ContactFormField.PhoneNumber]: buildDisplayData(
    ContactFormField.PhoneNumber,
    messages.submissionMarketToolbox.contacts.placeholders.phoneNumber,
  ),
  [ContactFormField.Title]: buildDisplayData(
    ContactFormField.Title,
    messages.submissionMarketToolbox.contacts.placeholders.title,
  ),
};
