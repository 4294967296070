import { colors, Typography } from '@common-components';

const { teal } = colors;

interface Option {
  text: string;
  highlight: boolean;
}

export default function HighlightedText({
  parts,
  markText = true,
  boldText,
}: {
  parts: Option[];
  markText?: boolean;
  boldText?: boolean;
}) {
  return (
    <>
      {parts.map((item, index) => {
        if (item.highlight) {
          return (
            <Typography
              component="span"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              sx={{ backgroundColor: markText ? teal.A100 : 'inherit', fontWeight: boldText ? 'bold' : 'inherit' }}
            >
              {item.text}
            </Typography>
          );
        }

        return item.text;
      })}
    </>
  );
}
