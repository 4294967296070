import moment from 'moment';
import pluralize from 'pluralize';
import { Button, Stack } from '@common-components';
import { CapitolaClient } from 'clients';
import { useToast } from 'hooks';
// eslint-disable-next-line import/no-restricted-paths
import { useClient } from 'hooks/useClient';
import { logger } from 'utils';

class DbDumpClient extends CapitolaClient<Blob> {
  public get resourceName(): string {
    return 'db-dump';
  }
}

export default function DbDumpsList() {
  const client = useClient(DbDumpClient);
  const { showToast } = useToast();

  async function fetchEntity(entity: string) {
    return client
      .search({ table: entity }, { dataType: 'blob' })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob as unknown as Blob);
        const now = moment().format('DDMMYY-Hmm');

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${entity}-${now}.csv`);

        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(href);
        document.body.removeChild(link);
      })
      .catch((err) => {
        showToast('error', { message: `Downloading ${pluralize(entity)} failed. ${err}` });
        logger.log('error', err);
      });
  }

  return (
    <Stack flexDirection="column" alignItems="flex-start" rowGap={2} padding={5}>
      <Button onClick={() => fetchEntity('submissions')} variant="contained">
        Submissions
      </Button>
      <Button onClick={() => fetchEntity('quotes')} variant="contained">
        Quotes
      </Button>
      <Button onClick={() => fetchEntity('layers')} variant="contained">
        Layers
      </Button>
      <Button onClick={() => fetchEntity('submissionMarkets')} variant="contained">
        Submission Markets
      </Button>
      <Button onClick={() => fetchEntity('contacts')} variant="contained">
        Contacts
      </Button>
      <Button onClick={() => fetchEntity('markets')} variant="contained">
        Markets
      </Button>
      <Button onClick={() => fetchEntity('users')} variant="contained">
        Users
      </Button>
      <Button onClick={() => fetchEntity('userMarkets')} variant="contained">
        User Markets
      </Button>

      <Button onClick={() => fetchEntity('inboundEmailMessage')} variant="contained">
        Inbound email
      </Button>
    </Stack>
  );
}
