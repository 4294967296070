import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import Content from './Content';

export default function InformationRequestedPage() {
  return (
    <WithRouteLocationState routeKey={RouteKey.InformationRequest}>
      {(locationState) => <Content locationState={locationState} />}
    </WithRouteLocationState>
  );
}
