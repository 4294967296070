import { useEffect } from 'react';
import { useGetParam } from 'hooks';
import { BrokerUrlParams } from 'broker/broker-routes';
import { EmailItemType } from 'broker/components/Emails/types';
import { EmailDescriptor, EmailListItemAttributes } from './utils';

export const useAutoSelectEmail = (
  selectEmail: (selectedEmail: EmailDescriptor) => void,
  submissionEmailsList: EmailListItemAttributes[],
  isLoadingEmails: boolean,
) => {
  const selectedEmailUrlParam = useGetParam(BrokerUrlParams.SELECTED_EMAIL);
  useEffect(() => {
    if (!selectedEmailUrlParam || isLoadingEmails) {
      return;
    }

    const findAndSelectEmail = (emailsList: EmailListItemAttributes[]) => {
      const selectedEmail = emailsList.find((email) => email.id === selectedEmailUrlParam);

      // Currently supporting only submission related emails or external inbound emails (e.g. transactional signals)
      selectEmail(selectedEmail ?? { id: selectedEmailUrlParam, type: EmailItemType.SubmissionInbound });
      return true;
    };

    findAndSelectEmail(submissionEmailsList);
  }, [selectEmail, selectedEmailUrlParam, submissionEmailsList, isLoadingEmails]);
};
