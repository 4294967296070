export enum CopilotSteps {
  PreMarketing = 'PreMarketing',
  Marketing = 'Marketing',
  Proposal = 'Proposal',
  BindersAndPolicies = 'BindersAndPolicies',
}

export interface PreMarketingSettings {
  daysBeforeInfoRequest: number;
  daysBeforeInfoFollowUp: number;
  daysBeforeLossRunRequest: number;
  daysBeforeAssigneeNotification: number;
}

export interface MarketingSettings {
  premiumPercentChangeNotificationThreshold: number;
  limitUnreachedDaysNotificationThreshold: number;
}

export interface ProposalSettings {
  unrespondedProposalDaysNotificationThreshold: number;
}

export interface BindersAndPoliciesSettings {
  binderDelayedDaysNotificationThreshold: number;
}

export type SettingsOfStep = {
  [CopilotSteps.PreMarketing]: PreMarketingSettings;
  [CopilotSteps.Marketing]: MarketingSettings;
  [CopilotSteps.Proposal]: ProposalSettings;
  [CopilotSteps.BindersAndPolicies]: BindersAndPoliciesSettings;
};

export type CopilotSettings = SettingsOfStep & {
  activeSteps: CopilotSteps[];
};
