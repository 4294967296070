import { alpha, Theme } from '@common-components';
import { GridFormFieldCommonStyles } from './GridFormFields/styles';

export const LabelStyles = {
  position: 'initial',
  transform: 'none',
  mb: 0.5,
  typography: 'body2',
  color: 'text.primary',
  whiteSpace: 'normal',
} as const;

export const FormHelperTextStyles = {
  ml: 0,
  mr: 0,
} as const;

export const GridAutoCompleteFormHelperTextStyles = {
  position: 'absolute',
  bottom: (theme: Theme) => theme.spacing(-3),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  left: 0,
  right: 0,
} as const;

export const SelectMenuPropsStyles = (isLoading = false) =>
  ({
    PaperProps: {
      sx: (theme: Theme) => ({
        maxHeight: 'auto',
        '& .MuiMenuItem-root.Mui-disabled': isLoading
          ? {
              height: theme.spacing(28),
              opacity: 1,
            }
          : undefined,
      }),
    },
  } as const);

export const SelectStyles = {
  '& legend': { display: 'none' },
  '& fieldset': { top: 0 },
  typography: 'body2',
  '& .Mui-disabled': {
    color: 'inherit',
    WebkitTextFillColor: (theme: Theme) => `${theme.palette.common.black} !important`,
    backgroundColor: (theme: Theme) => theme.palette.grey[100],
  },
} as const;

export const AutocompleteStyles = {
  typography: 'body2',
  '& .Mui-disabled': {
    color: 'inherit',
    WebkitTextFillColor: (theme: Theme) => `${theme.palette.common.black} !important`,
    backgroundColor: (theme: Theme) => theme.palette.grey[100],
  },
} as const;

export const GridAutocompleteStyles = {
  height: 1,
  '& .MuiAutocomplete-inputRoot': {
    ...GridFormFieldCommonStyles,
    '& .MuiAutocomplete-input': {
      pt: '4px',
      pb: '5px',
    },
    '& .MuiAutocomplete-popupIndicator': {
      '& .MuiTouchRipple-root': { display: 'none' },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
} as const;

export const FormWYSIWYGStyles = (
  isError: boolean,
  isFocus: boolean,
  isHover: boolean,
  disabled?: boolean,
  baseBorderColor?: string,
) => {
  const borderWidth = 1;
  let borderColor = baseBorderColor ?? 'grey.400';

  if (isError) {
    borderColor = 'error.main';
  } else if (isFocus) {
    borderColor = 'primary.main';
  } else if (isHover) {
    borderColor = 'common.black';
  }

  const layerStyling = disabled
    ? {
        ':after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: (theme: Theme) => alpha(theme.palette.common.black, 0.05),
          zIndex: (theme: Theme) => theme.zIndex.tooltip - 1,
          pointerEvents: 'none',
        },
      }
    : {};

  return {
    borderStyle: 'solid',
    borderColor,
    borderWidth,
    borderRadius: (theme: Theme) => theme.spacing(0.5),
    position: 'relative',
    ...layerStyling,
  } as const;
};
