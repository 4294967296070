import { retryOnError } from 'clients/base';
import { Jwt, LoggedInUser, OffsetLimitPagination, User } from 'types';
import { CapitolaClient } from './base';

export type UserQuery = OffsetLimitPagination;

export class UserClient extends CapitolaClient<User> {
  public get resourceName(): string {
    return 'user';
  }

  public async retrieveMe(): Promise<LoggedInUser | null> {
    return (await this.retrieve('me', { ...retryOnError })) as LoggedInUser | null;
  }

  public async retrieveTinyJwt(): Promise<Jwt | null> {
    return (await this.retrieve('tiny-jwt', { ...retryOnError })) as Jwt | null;
  }
}
