import { useCallback, useEffect, useMemo, useRef } from 'react';
import { hotjar } from 'react-hotjar';
import { useHotjarInitializedContext } from 'contexts';
import { HotjarEvents } from 'utils/hotjar-events';

export const useHotjar = () => {
  const hotjarInitialized = useHotjarInitializedContext();

  // We need the ref to workaround the closure of event in Sentry initialization
  const hotjarInitializedRef = useRef(hotjarInitialized);
  useEffect(() => {
    hotjarInitializedRef.current = hotjarInitialized;
  }, [hotjarInitialized]);

  const identify = useCallback((identity: string, properties: Record<string, any> = {}) => {
    if (!hotjarInitializedRef.current) {
      return;
    }
    hotjar.identify(identity, properties);
  }, []);

  const event = useCallback((eventToReport: HotjarEvents) => {
    if (!hotjarInitializedRef.current) {
      return;
    }
    hotjar.event(eventToReport);
  }, []);

  return useMemo(() => ({ identify, event }), [event, identify]);
};
