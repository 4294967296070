import { Stack, Tooltip, Typography } from '@common-components';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { truncateMultiLineText } from 'utils';

export default function SubmissionRiskAssessment({ submission }: { submission: PartialSubmission | null }) {
  if (!submission?.riskAssessment) {
    return undefined;
  }

  return (
    <Tooltip tooltipContent={submission.riskAssessment} placement="top">
      <Stack gap={0} bgcolor="blueGrey.50" width="50%" alignSelf="flex-end" py={1} px={1.5}>
        <Typography variant="captionBold">{messages.riskAssessmentNote.title}</Typography>
        <Typography sx={{ ...truncateMultiLineText(2) }} variant="body2">
          {submission.riskAssessment}
        </Typography>
      </Stack>
    </Tooltip>
  );
}
