import { EmailTemplateVariables, MENTION_PREFIX } from '@common/email-variables';
import { stringToHtmlTag } from 'utils';

export const mentionClassName = 'mention';
export const mentionChipClassName = 'mention-chip';

export function sanitizeMentions(html: string): string;
export function sanitizeMentions(div: HTMLDivElement): HTMLDivElement;
export function sanitizeMentions(input: HTMLDivElement | string) {
  const div = typeof input === 'string' ? stringToHtmlTag(input) : input;
  const mentions = div.querySelectorAll(`.${mentionClassName}`);

  mentions.forEach((mention) => {
    const mentionStyle = mention.getAttribute('style');
    const parent = mention.parentElement;

    // No need to put the variable name in a <span> tag if it has no styling.
    if (!mentionStyle) {
      if (parent && mention.textContent) {
        parent.replaceChild(document.createTextNode(mention.textContent), mention);
      }
    } else {
      // Maintain any styling applied to the mention tag.
      const span = document.createElement('span');
      span.textContent = mention.textContent;
      span.setAttribute('style', mentionStyle);

      if (parent) {
        parent.replaceChild(span, mention);
      }
    }
  });

  return typeof input === 'string' ? div.innerHTML : div;
}

export function toMentionTagString(mention: string) {
  // First wrapping span is to allow Tiny to apply styling on the mention.
  // Second wrapping span is to block content editing of the mention tag and apply the mention chip styling.
  // These spans can't be merged to a single span because Tiny will not apply styling on a contenteditable="false" span
  return `<span class="${mentionClassName}"><span contenteditable="false" class="${mentionChipClassName}">${mention}</span></span>`;
}

export function replaceVariablesWithMentionTag(text: string) {
  // To make sure this function is idempotent we first clean previous mention tags
  let result = sanitizeMentions(text);

  Object.values(EmailTemplateVariables).forEach((variableKey) => {
    const variableMention = `${MENTION_PREFIX}${variableKey}`;
    result = result.replaceAll(variableMention, toMentionTagString(variableMention));
  });

  return result;
}
