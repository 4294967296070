import { CommonTemplateVariablesResolver } from '@common/email-variables';
import * as commonTypes from '@common/types';

const websiteBaseUrl = process.env.REACT_APP_WEBSITE_BASE_URL;

// Inject to all common resolvers the base url of the website, to spare this responsibility from all usages
export const commonResolver = Object.entries(CommonTemplateVariablesResolver).reduce((accResolver, entry) => {
  const [variableKey, variableResolver] = entry;

  // @ts-ignore
  // Ignoring the below ts error because we know that the key is a valid key in the CommonTemplateVariablesResolver
  accResolver[variableKey] = (context: commonTypes.EmailTemplateContext) =>
    variableResolver({
      ...context,
      websiteBaseUrl,
    });

  return accResolver;
}, {} as typeof CommonTemplateVariablesResolver);
