import { Grid } from '@common-components';
import ListItemSkeleton from './ListItemSkeleton';

export default function EmailsListSkeleton() {
  return (
    <Grid spacing={3} container p={1}>
      <Grid item xs={12}>
        <ListItemSkeleton index={0} />
      </Grid>
      <Grid item xs={12}>
        <ListItemSkeleton index={0} />
      </Grid>
      <Grid item xs={12}>
        <ListItemSkeleton index={0} />
      </Grid>
      <Grid item xs={12}>
        <ListItemSkeleton index={0} />
      </Grid>
    </Grid>
  );
}
