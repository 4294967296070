import { BoxItem } from 'box-ui-elements/es';
import { IconButton, NewMenu } from '@common-components';
import {
  DeleteOutline as DeleteIcon,
  DownloadOutlined as DownloadIcon,
  LabelOutlined as LabelIcon,
  MoreVert,
  TitleOutlined as RenameIcon,
} from '@icons';
import { FileType } from 'enums';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { ExtendedBoxItem, GetFileSecondaryActions } from 'broker/components/FilesExplorer/types';

interface FileCardMenuActionsProps {
  file: BoxItem;
  getFileSecondaryActions?: GetFileSecondaryActions;
  onDownloadClicked: (item: ExtendedBoxItem) => void;
  onRename: () => void;
  fileType: FileType | undefined;
  onLabelClicked: () => void;
  onDeleteClicked: (item: ExtendedBoxItem) => void;
}

export default function FileCardMenuActions({
  file,
  getFileSecondaryActions,
  onDownloadClicked,
  onRename,
  fileType,
  onLabelClicked,
  onDeleteClicked,
}: FileCardMenuActionsProps) {
  const optionsMenuState = useOptionsMenu();
  const fileSecondaryOptions = getFileSecondaryActions?.(file) || [];

  return (
    <>
      <IconButton edge="end" icon={MoreVert} color="secondary" variant="text" onClick={optionsMenuState.openMenu} />
      <NewMenu
        subMenuPlacement="left"
        optionsMenuState={optionsMenuState}
        menuItems={[
          {
            key: 'file-actions',
            items: [
              {
                label: messages.fileExplorer.menu.rename,
                endAdornment: RenameIcon,
                onClick: onRename,
              },
              {
                label: fileType ? messages.fileExplorer.menu.editLabel : messages.fileExplorer.menu.addLabel,
                endAdornment: LabelIcon,
                onClick: onLabelClicked,
              },
              {
                label: messages.fileExplorer.menu.download,
                endAdornment: DownloadIcon,
                onClick: () => onDownloadClicked(file),
              },
              {
                label: messages.fileExplorer.menu.delete,
                endAdornment: DeleteIcon,
                onClick: () => onDeleteClicked(file),
              },
              {
                label: messages.fileExplorer.menu.more,
                endAdornment: DeleteIcon,
                hidden: !fileSecondaryOptions.length,
                subMenu: [
                  {
                    key: 'file-secondary-actions',
                    items: fileSecondaryOptions,
                  },
                ],
              },
            ],
          },
        ]}
      />
    </>
  );
}
