import { Chip, Divider, Stack } from '@common-components';
import { ActivityType } from 'enums';
import { messages } from 'i18n';
import { ActivityLog } from 'types';
import { ActivityLogCard } from './ActivityLogCard';
import { ActivityTypeDisplayConfig } from './types';

interface ActivitiesForDateProps {
  date: string;
  activities: ActivityLog[];
  config: Partial<Record<ActivityType, ActivityTypeDisplayConfig>>;
}

export const ActivitiesForDate = ({ date, activities, config }: ActivitiesForDateProps) => (
  <>
    <Divider
      sx={{
        position: 'sticky',
        bgcolor: 'common.white',
        top: 0,
        zIndex: 1,
        alignItems: 'flex-start',
        pt: 3,
        pb: 2,
        '&::before, &::after': {
          borderColor: date === messages.submissionActivityLog.today ? 'primary.main' : 'purple',
        },
      }}
    >
      <Chip
        label={date}
        color={date === messages.submissionActivityLog.today ? 'primary' : 'secondary'}
        variant={date === messages.submissionActivityLog.today ? 'outlined' : 'contained'}
        size="small"
        rounded
      />
    </Divider>
    <Stack divider={<Divider />} gap={2}>
      {activities.map((activity) => {
        const displayConfig = config[activity.activityType];
        const key = activity.id || activity.groupKey;
        return displayConfig && <ActivityLogCard key={key} activityLog={activity} displayConfig={displayConfig} />;
      })}
    </Stack>
  </>
);
