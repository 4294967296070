/* A list view (e.g. for admin pages) using `DataGrid`
 */

import { useEffect, useState } from 'react';
import { DataGrid, GridColumns } from '@common-components';

export const DEFAULT_PAGE_SIZE = 30;
const DEFAULT_PAGINATION_MODE = 'server';
export interface GridTableProps<T> {
  columns: GridColumns;
  items: Array<T> | null;
  isLoading: boolean;
  count: number;
  setOffset: (offset: number) => void;
  pageSize: number;
  paginationMode?: 'client' | 'server';
}

export default function DataGridList<T extends {}>({
  columns,
  items,
  count,
  isLoading,
  setOffset,
  pageSize,
  paginationMode = DEFAULT_PAGINATION_MODE,
  ...props
}: GridTableProps<T>) {
  const [rows, setRows] = useState<T[]>([]);
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    if (items) {
      setRows(items);
      setRowCount(count);
    }
  }, [items, count]);

  function onPageChange(pageNumber: number) {
    if (paginationMode !== 'server') {
      return;
    }

    setOffset(pageNumber * pageSize);
  }

  return (
    <DataGrid
      autoHeight
      columns={columns}
      disableSelectionOnClick
      hideFooter={paginationMode === undefined}
      loading={isLoading}
      pageSize={pageSize}
      pagination
      paginationMode={paginationMode}
      rowCount={rowCount}
      rows={rows}
      rowsPerPageOptions={[pageSize]}
      onPageChange={onPageChange}
      {...props}
    />
  );
}
