import { useState } from 'react';
import { useParams } from 'react-router-dom-latest';
import { Divider, Toolbar, Typography } from '@common-components';
import { ContactRole } from 'enums';
import { getContactRole } from 'enums/type-mappers/contact-role-config';
import { useSearchContact } from 'hooks';
import { messages } from 'i18n';
import { Contact } from 'types';
import DataGridList, { DEFAULT_PAGE_SIZE } from 'components/DataGridList';
import SearchField from './SearchField';
import SelectField from './SelectField';

export default function ContactList() {
  const [query, setQuery] = useState<string>();
  const [role, setRole] = useState<string | null>(null);
  const [offset, setOffset] = useState<number>(0);

  const { id: teamId } = useParams<{ id: string }>();

  const pageSize = DEFAULT_PAGE_SIZE;

  const {
    items: contacts,
    count,
    isInitialLoading,
  } = useSearchContact({
    filter: {
      offset,
      limit: pageSize,
      q: query,
      teamId,
      role: role as ContactRole,
    },
  });

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 0.1,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 0.1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.1,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      flex: 0.1,
    },
    {
      field: 'title',
      headerName: 'Job Title',
      flex: 0.1,
    },
    {
      field: 'role',
      headerName: 'Role',
      renderCell: ({ row }: { row: Contact }) => getContactRole(row.role),
      flex: 0.1,
    },
    {
      field: 'organizationName',
      headerName: 'Organization Name',
      flex: 0.1,
    },
  ];

  const unmappedChoices = ContactRole;
  const choices = Object.fromEntries(
    Object.keys(unmappedChoices).map((value) => [value, getContactRole(value as ContactRole)]),
  );

  return (
    <>
      <Toolbar sx={{ p: 1 }}>
        <Typography variant="h6" id="tableTitle" component="div" sx={{ mr: 2 }}>
          {messages.admin.contactsPage.gridTitle}
        </Typography>

        <Divider orientation="vertical" flexItem variant="middle" />

        <SearchField onChange={setQuery} />

        <SelectField choices={choices} label="Role" onChange={setRole} value={role} />
      </Toolbar>
      <DataGridList
        columns={columns}
        items={contacts}
        count={count}
        setOffset={setOffset}
        isLoading={isInitialLoading}
        pageSize={pageSize}
      />
    </>
  );
}
