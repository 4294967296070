/* global google */
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useMutateSubmission } from 'hooks';
import { PartialSubmission } from 'types';
import { mapGoogleMapResultToStructuredAddress } from 'utils';
import {
  SubmissionFormData,
  SubmissionFormFieldsNames,
} from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/Screens/SubmissionStaticForm/StaticForm/types';
import { isFieldFilled } from 'broker/utils';
import { submissionFieldsConfig } from 'broker/utils/submission-fields-config';
import { updateDisabledFields } from './form-utils';
import { submissionFormFieldsToEntityPropMapper } from './submission-form-fields-to-entity-prop-mapper';

export interface AfterSubmitFormOptions {
  submission: PartialSubmission | null;
  isAllRequiredFieldsFilled: boolean;
}

interface SubmissionFormCallbacksProps {
  setDirty: (isDirty: boolean) => void;
  existingSubmission: PartialSubmission;
  afterSubmitForm: (props: AfterSubmitFormOptions) => Promise<void> | void;
  methods: UseFormReturn<SubmissionFormData, object>;
}

export function useSubmissionFormHandler({
  setDirty,
  existingSubmission,
  afterSubmitForm,
  methods,
}: SubmissionFormCallbacksProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setInsuredGoogleResultStructuredAddress = (placeResult?: google.maps.places.PlaceResult) => {
    if (placeResult) {
      methods.setValue('insuredStructuredAddress', mapGoogleMapResultToStructuredAddress(placeResult));
    }
  };

  const { updateSubmission } = useMutateSubmission();

  useEffect(() => {
    // once form is dirty the modal will be dirty until submission is created
    if (methods.formState.isDirty) {
      setDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState.isDirty]);

  const isAllRequiredFieldsFilled = useMemo(
    () =>
      (Object.keys(submissionFormFieldsToEntityPropMapper) as SubmissionFormFieldsNames[])
        .filter(
          (formFieldName) =>
            submissionFieldsConfig[submissionFormFieldsToEntityPropMapper[formFieldName]]
              ?.isStaticSubmissionRequiredField,
        )
        .every((formFieldName) => isFieldFilled(methods.getValues() as SubmissionFormData, formFieldName)),
    [methods],
  );

  // object that is used to reset all form data that is disabled and shouldn't be sent on update request
  const disabledUpdateFieldsReset: { [key in SubmissionFormFieldsNames]?: undefined } = {};
  updateDisabledFields.forEach((field) => {
    disabledUpdateFieldsReset[field] = undefined;
  });

  const handleSubmitSubmission: SubmitHandler<SubmissionFormData> = async (submissionData) => {
    setIsSubmitting(true);
    setDirty(false);
    try {
      const computedFields = {
        limit: !isEmpty(submissionData.limit) ? Number(submissionData.limit) : undefined,
        contactIds: submissionData.customers ? submissionData.customers : null,
        insuredCompanyType: submissionData.insuredCompanyType ? submissionData.insuredCompanyType : null,
        insuredAnnualRevenue: submissionData.insuredAnnualRevenue ? Number(submissionData.insuredAnnualRevenue) : null,
        insuredEmployeeCount: submissionData.insuredEmployeeCount ? Number(submissionData.insuredEmployeeCount) : null,
      };

      const submission = await updateSubmission.mutateAsync({
        id: existingSubmission.id,
        data: {
          ...submissionData,
          ...computedFields,
          dueDate: submissionData.dueDate || undefined,
          needByDate: submissionData.needByDate || undefined,
          insuredId: submissionData.insuredId || undefined,
          insuredStructuredAddress:
            // only if there was a change in the insuredAddress then also update the googleMapResultToStructuredAddress that is set on change by google api
            submissionData.insuredAddress !== existingSubmission.insuredAddress
              ? submissionData.insuredStructuredAddress
              : undefined,
        },
      });

      await afterSubmitForm({
        submission,
        isAllRequiredFieldsFilled,
      });
      setIsSubmitting(false);
      return true;
    } catch (e) {
      setDirty(false);
      setIsSubmitting(false);
      return false;
    }
  };

  return {
    handleSubmitSubmission,
    setInsuredGoogleResultStructuredAddress,
    isSubmitting,
  };
}
