import { omit } from 'lodash';
import { Link, Typography } from '@common-components';
import { ActivityLog, ExtendedMarketRequest } from 'types';
import { useGetQuoteMetadataData } from './utils';

export interface MarketRequestMetaDataProps {
  marketRequest: ExtendedMarketRequest;
  emailActivity?: ActivityLog;
}

export default function MarketRequestMetaData({ marketRequest, emailActivity }: MarketRequestMetaDataProps) {
  const { title, description, subtitle, titleTextAfterLink, linkProps } = useGetQuoteMetadataData(
    marketRequest,
    emailActivity,
  );

  return (
    <>
      <Typography variant="body2" color="text.primary">
        {title}{' '}
        {linkProps && (
          <Link color="primary" sx={{ cursor: 'pointer' }} {...omit(linkProps, 'linkText')}>
            {linkProps.linkText}
          </Link>
        )}
        {linkProps && titleTextAfterLink ? titleTextAfterLink : ''}
      </Typography>
      {description && <Typography variant="caption">{description}</Typography>}
      <Typography mt="auto" order={3} variant="overline" color="text.secondary">
        {subtitle}
      </Typography>
    </>
  );
}
