import { get, isEmpty, startCase } from 'lodash';
import { Link } from '@common-components';
import { messages } from 'i18n';
import { activeTabUrlParamValues, BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { ThreadUploadedFile } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/types';
import { BulletedContent } from './BulletedContent';

export const FilesSection = ({ withFileLinks }: { withFileLinks: boolean }) => {
  const { enrichmentData } = useAIContext();
  const { filesText } = messages.submissionWorkspace.aiGeneratedContentPopup;

  const updateQueryParam = useUpdateQueryParam();

  const files = get(enrichmentData, 'files', []);

  if (isEmpty(files)) {
    return null;
  }

  function openFileInToolbox(fileId: string) {
    updateQueryParam({
      addParams: [
        { queryParam: BrokerUrlParams.SELECTED_FILE, value: fileId },
        { queryParam: BrokerUrlParams.ACTIVE_TAB, value: activeTabUrlParamValues.FILES },
      ],
    });
  }

  const getFileText = (file: ThreadUploadedFile) => {
    let result = '';
    if (file.label) {
      result = `${file.label}: `;
    }
    return (
      <BulletedContent
        level={2}
        content={
          <>
            {result}
            {withFileLinks ? (
              <Link onClick={() => openFileInToolbox(file.id)} sx={{ cursor: 'pointer' }}>
                {file.name}
              </Link>
            ) : (
              file.name
            )}
          </>
        }
      />
    );
  };

  return (
    <BulletedContent level={1} content={`${startCase(filesText)}:`}>
      {files.map(getFileText)}
    </BulletedContent>
  );
};
