import { Theme } from '@common-components';

export const GridFormFieldCommonStyles = {
  height: 1,
  typography: 'body2',
  py: 1.5,
  px: 1,
  '&:before': {
    borderBottomColor: 'divider',
  },
  '&.Mui-disabled': {
    color: 'inherit',
    WebkitTextFillColor: (theme: Theme) => `${theme.palette.common.black} !important`,
    backgroundColor: (theme: Theme) => theme.palette.grey[100],
  },
  '& .Mui-disabled': {
    color: 'inherit',
    WebkitTextFillColor: (theme: Theme) => `${theme.palette.common.black} !important`,
    backgroundColor: (theme: Theme) => theme.palette.grey[100],
  },
} as const;

export const FormHelperTextStyles = {
  position: 'absolute',
  bottom: (theme: Theme) => theme.spacing(-3),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  left: 0,
  right: 0,
} as const;

export const SelectMenuPropsStyles = (isLoading = false) =>
  ({
    PaperProps: {
      sx: (theme: Theme) => ({
        maxHeight: 'auto',
        '& .MuiMenuItem-root.Mui-disabled': isLoading
          ? {
              height: theme.spacing(28),
              opacity: 1,
            }
          : undefined,
      }),
    },
  } as const);

export const GridFormSelectStyles = {
  '& legend': { display: 'none' },
  '& fieldset': { top: 0 },

  '& .MuiSelect-select': {
    pr: 2,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  ...GridFormFieldCommonStyles,
} as const;
