import { useState } from 'react';
import { Box, Divider, GridColDef, Toolbar, Typography } from '@common-components';
import { OrganizationType } from 'enums';
import { useSearchTeam } from 'hooks';
import { messages } from 'i18n';
import { Organization, Team } from 'types';
import CreateButton from 'components/CreateButton';
import DataGridList, { DEFAULT_PAGE_SIZE } from 'components/DataGridList';
import TeamCreateDialog from 'admin/dialogs/TeamCreateDialog';
import SearchField from './SearchField';
import TeamLink from './TeamLink';

export interface TeamListPageProps {
  organization?: Organization;
  maxHeight?: string;
}

export default function TeamList({ organization, maxHeight }: TeamListPageProps) {
  /*
    Allowing team creation if there is no preselected organization
    (allowing the user to select it upon creation)
    and if the preselected organization is not of Carrier type
    (since we currently don't support users for carriers)
    */
  const allowTeamCreation = organization && organization.type !== OrganizationType.Carrier;

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string>();
  const [offset, setOffset] = useState<number>(0);

  const pageSize = DEFAULT_PAGE_SIZE;

  const { items, count, isInitialLoading } = useSearchTeam({
    filter: {
      offset,
      limit: pageSize,
      organizationId: organization?.id,
      q: query,
    },
  });

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: ({ row }: { row: unknown }) => TeamLink({ team: row as Team }),
      flex: 0.1,
    },
    {
      field: 'id',
      headerName: 'Id',
      flex: 0.1,
    },
  ];

  return (
    <>
      <Toolbar sx={{ p: 1 }}>
        <Typography variant="h6" id="tableTitle" component="div" sx={{ mr: 2 }}>
          {messages.admin.organizationPage.teamsGridTitle}
        </Typography>

        <Divider orientation="vertical" flexItem variant="middle" />

        <SearchField onChange={setQuery} />

        {allowTeamCreation && <CreateButton onClick={() => setOpen(true)} />}
      </Toolbar>
      <Box sx={{ maxHeight: maxHeight || 'inherit', overflowY: 'auto' }}>
        <DataGridList
          columns={columns}
          items={items}
          count={count}
          setOffset={setOffset}
          isLoading={isInitialLoading}
          pageSize={pageSize}
        />
        {allowTeamCreation && (
          <TeamCreateDialog organization={organization} open={open} onClose={() => setOpen(false)} />
        )}
      </Box>
    </>
  );
}
