import { isNaN, isNumber, toNumber } from 'lodash';
import { CustomMoneyInputProps } from 'components/hookFormComponents/CustomMoneyInput';

export function afterDecimal(num: number) {
  if (Number.isInteger(num)) {
    return 0;
  }

  return num.toString().split('.')[1].length;
}

export function formatInputNumber(
  value: string | number,
  formatProps?: Omit<CustomMoneyInputProps, 'name' | 'onChange'>,
): string {
  // Convert the input to a number first (if it's a string)
  const numberValue: number = typeof value === 'string' ? parseFloat(value) : value;

  // Determine if the number has decimal places
  const hasDecimals = numberValue % 1 !== 0;

  // Extract prefix and suffix from the formatProps or use defaults
  const { prefix = '$', suffix = '' } = formatProps || {};

  // Format the number using Intl.NumberFormat
  const formattedNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: hasDecimals ? 2 : 0,
  }).format(numberValue);

  // Return the formatted string with prefix and suffix
  return `${prefix}${formattedNumber}${suffix}`;
}

export function isNumeric(value: any): boolean {
  // Check if it's a number or a string representing a valid number
  return (isNumber(value) && !isNaN(value)) || (typeof value === 'string' && !isNaN(toNumber(value)));
}
