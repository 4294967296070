import { Stack } from '@common-components';
import { FormMode } from 'enums';
import { CommissionTextField } from './CommissionTextField';

interface BinderDetailsProps {
  mode: FormMode;
}
export default function BinderDetails({ mode }: BinderDetailsProps) {
  const isDisabled = mode === FormMode.view;
  return (
    <Stack gap={1}>
      <CommissionTextField isDisabled={isDisabled} />
    </Stack>
  );
}
