import { Box, ButtonBase, darken, NewMenu, Stack, Tooltip, Typography } from '@common-components';
import { KeyboardArrowDown } from '@icons';
import { useOptionsMenu } from 'hooks';
import { MenuGroup } from 'components/menu';
import { ProcessingAnimation } from 'components/ProcessingAnimation';

interface MenuBadgeProps {
  title: string;
  subtitle?: string;
  color?: string;
  menuGroups: MenuGroup[];
  tooltipTitle?: string;
  processing?: boolean;
  inactive?: boolean;
}

export default function MenuBadge({
  title,
  subtitle,
  color,
  menuGroups,
  tooltipTitle,
  processing,
  inactive,
}: MenuBadgeProps) {
  const optionsMenuState = useOptionsMenu();
  const inactiveMode = !!processing || inactive;

  return (
    <>
      <Tooltip tooltipContent={tooltipTitle ?? ''}>
        <Stack
          bgcolor={optionsMenuState.isMenuOpen ? darken(color!, 0.1) : color}
          direction="row"
          justifyContent="center"
          alignItems="center"
          component={ButtonBase}
          gap={0.5}
          height={1}
          width={1}
          onClick={inactiveMode ? undefined : optionsMenuState.openMenu}
          sx={{
            cursor: 'pointer',
            '& .MuiTouchRipple-root': { borderRadius: 0.75 },
            '&:hover': {
              bgcolor: darken(color!, 0.06),
            },
          }}
        >
          {processing && <ProcessingAnimation />}
          <Stack alignItems="center">
            <Typography
              variant="captionBold"
              color="text.primary"
              pt={1}
              pb={subtitle ? 0.2 : 1}
              px={1.5}
              sx={{ textTransform: 'uppercase', wordWrap: 'break-word' }}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography color="error.main" variant="caption2Bold">
                {subtitle}
              </Typography>
            )}
          </Stack>
          <Box position="absolute" top={4} right={4}>
            {!inactiveMode && <KeyboardArrowDown sx={{ color: 'text.primary' }} fontSize="small" />}
          </Box>
        </Stack>
      </Tooltip>

      <NewMenu placement="right-top-to-bottom" optionsMenuState={optionsMenuState} menuItems={menuGroups} />
    </>
  );
}
