import { Button, Stack, Typography } from '@common-components';
import { messages } from 'i18n';

interface MarketsHeaderProps {
  teamName: string;
  onOpenDialog: () => void;
}

export default function MarketsHeader({ teamName, onOpenDialog }: MarketsHeaderProps) {
  return (
    <Stack justifyContent="space-between" direction="row">
      <Stack direction="column">
        <Typography variant="h4">{teamName}</Typography>
      </Stack>
      <Stack>
        <Button variant="contained" onClick={onOpenDialog}>
          {messages.myTeamPage.InviteButton}
        </Button>
      </Stack>
    </Stack>
  );
}
