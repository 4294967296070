import { Box, Stack } from '@common-components';
import { AirplanemodeActive } from '@icons';
import { messages } from 'i18n';
import { PartialSubmission, SubmissionMarket, User, UserDetails } from 'types';
import { DialogContent, DialogFooter } from 'components/Dialog';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import { OnAssigneeChange } from 'broker/components/SubmissionsView/SubmissionAssigneeList';
import Disclaimer from 'broker/dialogs/SubmissionAutonomousCreation/Disclaimer';
import { StepsRoutes } from 'broker/dialogs/SubmissionAutonomousCreation/types';
import AutonomousSlider from './AutonomousSlider';
import Header from './Header';
import MainCollapse from './MainCollapse';

export interface AdvancedContentProps
  extends Pick<BaseRoutedDialogContentProps, 'onClose' | 'setDialogIsDirty' | 'closeWithoutPrompt'> {
  activeStep: StepsRoutes;
  submission: PartialSubmission;
  users: User[];
  onAssigneeChange: OnAssigneeChange;
  selectedUser: UserDetails;
  submissionMarkets: SubmissionMarket[];
  submitForm(): Promise<void>;
  value: number;
  setValue: (value: number) => void;
  isAutonomousFlowInProgress: boolean;
}
export default function AdvancedContent(props: AdvancedContentProps) {
  const {
    onClose,
    submission,
    submissionMarkets,
    selectedUser,
    submitForm,
    value,
    setValue,
    isAutonomousFlowInProgress,
  } = props;

  return (
    <>
      <Header {...props} />
      <DialogContent disablePaddings>
        <Disclaimer />
        <Stack direction="row" overflow="hidden">
          <Box flex={1} px={4} py={3}>
            <AutonomousSlider setValue={setValue} value={value} disabled={submission.isCopilotEnabled} />
          </Box>
          <Box flex={2} overflow="auto" p={3}>
            <MainCollapse
              value={value}
              submission={submission}
              submissionMarkets={submissionMarkets}
              selectedUser={selectedUser}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: 'Start Co-Pilot',
          startIcon: <AirplanemodeActive />,
          onClick: submitForm,
          loading: isAutonomousFlowInProgress,
          disabled: submission.isCopilotEnabled,
        }}
        cancelButton={{
          children: submission.isCopilotEnabled ? messages.buttons.close : messages.buttons.cancel,
          onClick: onClose,
        }}
      />
    </>
  );
}
