import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom-latest';
import { Box, BoxProps, ButtonBase } from '@common-components';
import APP_ROUTES from 'app-routes';
import routes from 'routes';
import AppLogo from './AppLogo';
import SideBarMenu from './SideBarMenu';

export default function SideBar({ sx }: BoxProps) {
  const navigate = useNavigate();
  const { isLoading, user } = useAuth0();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden',
        zIndex: 'drawer',
        bgcolor: 'common.white',
        borderRight: 1,
        borderColor: 'divider',
        ...sx,
      }}
    >
      <ButtonBase
        sx={{ mt: 2, mb: 2, p: 1, borderRadius: '50%' }}
        disabled={isLoading}
        onClick={() => {
          if (user) {
            navigate(APP_ROUTES.GENERAL.HOME);
          } else {
            navigate(APP_ROUTES.GENERAL.LANDING);
          }
        }}
      >
        <AppLogo />
      </ButtonBase>

      <SideBarMenu routes={routes} />
    </Box>
  );
}
