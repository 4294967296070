import { useEffect } from 'react';
import { Link, LoaderOverlay, Typography } from '@common-components';
import { NylasAuthTrigger, useRetrieveNylasAuthenticationUrl, useToast } from 'hooks';
import { messages } from 'i18n';

const toastDuration = 10000;

export function NylasAuthenticationPage({ children }: { children: JSX.Element }) {
  const { data, isLoading } = useRetrieveNylasAuthenticationUrl({ trigger: NylasAuthTrigger.NylasAuthenticationPage });
  const { showToast } = useToast();

  useEffect(() => {
    const authUrl = data?.url;
    if (authUrl) {
      const newWindow = window.open(authUrl);

      // Handle pop-up blockers
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        showToast(
          'info',
          {
            message: (
              <Typography>
                {`${messages.nylasAuthentications.popupBlockedMessage} `}
                <Link href={authUrl} target="_blank">
                  {messages.general.here}
                </Link>
              </Typography>
            ),
          },
          { autoHideDuration: toastDuration },
        );
      }
    }
    // showToast is not part of the dependency list since it should not trigger the flow
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return <LoaderOverlay />;
  }

  // Once we fetched the url we can continue rendering the website
  // in parallel to opening the authentication in a new tab
  return children;
}
