import { OpenItemTaxonomy } from 'enums';
import { messages } from 'i18n';

export interface OpenItemConfig {
  informationRequested?: boolean;
  text: string;
}

export type OpenItemsConfig = {
  [item in OpenItemTaxonomy]: OpenItemConfig;
};

const itemsMessages = messages.openItems;

export default function getOpenItems(marketName: string): OpenItemsConfig {
  return {
    [OpenItemTaxonomy.PrimaryCarrierCurrentlyDatedApplications]: {
      text: itemsMessages[OpenItemTaxonomy.PrimaryCarrierCurrentlyDatedApplications],
    },
    [OpenItemTaxonomy.TriggeringMarketApplicationDatedWithin30DaysEffectiveDateCoverage]: {
      text: itemsMessages[OpenItemTaxonomy.TriggeringMarketApplicationDatedWithin30DaysEffectiveDateCoverage](
        marketName,
      ),
    },
    [OpenItemTaxonomy.ConfirmationUnderlyingSubjectivitiesClosedByUnderwriterSubmittedToMarket]: {
      text: itemsMessages[OpenItemTaxonomy.ConfirmationUnderlyingSubjectivitiesClosedByUnderwriterSubmittedToMarket](
        marketName,
      ),
    },
    [OpenItemTaxonomy.CurrentLossRunsPast5Years]: {
      text: itemsMessages[OpenItemTaxonomy.CurrentLossRunsPast5Years],
      informationRequested: true,
    },
    [OpenItemTaxonomy.ConfirmOpenRDPPortsClosedAddressed]: {
      text: itemsMessages[OpenItemTaxonomy.ConfirmOpenRDPPortsClosedAddressed],
      informationRequested: true,
    },
    [OpenItemTaxonomy.RiskControlCallWithMarketCyberRiskControlTeam]: {
      text: itemsMessages[OpenItemTaxonomy.RiskControlCallWithMarketCyberRiskControlTeam](marketName),
      informationRequested: true,
    },
    [OpenItemTaxonomy.DoesApplicantPermitRemoteAccessToComputerSystems]: {
      text: itemsMessages[OpenItemTaxonomy.DoesApplicantPermitRemoteAccessToComputerSystems],
      informationRequested: true,
    },
    [OpenItemTaxonomy.WarrantyForNewLayer]: {
      text: itemsMessages[OpenItemTaxonomy.WarrantyForNewLayer],
    },
    [OpenItemTaxonomy.CopyOfUnderlyingQuoteInsurance]: {
      text: itemsMessages[OpenItemTaxonomy.CopyOfUnderlyingQuoteInsurance],
    },
    [OpenItemTaxonomy.CopyOfUnderlyingBinderInsurance]: {
      text: itemsMessages[OpenItemTaxonomy.CopyOfUnderlyingBinderInsurance],
    },
    [OpenItemTaxonomy.CopyOfUnderlyingPolicyInsurance]: {
      text: itemsMessages[OpenItemTaxonomy.CopyOfUnderlyingPolicyInsurance],
    },
    [OpenItemTaxonomy.FirmHadMicrosoftExchangeServerTheLast365Days]: {
      text: itemsMessages[OpenItemTaxonomy.FirmHadMicrosoftExchangeServerTheLast365Days],
      informationRequested: true,
    },
    [OpenItemTaxonomy.RansomwareApplication]: {
      text: itemsMessages[OpenItemTaxonomy.RansomwareApplication],
      informationRequested: true,
    },
    [OpenItemTaxonomy.ConsummationOfferingTermsSubstantiallySimilarOutlinedCurrentS1Dated]: {
      text: itemsMessages[OpenItemTaxonomy.ConsummationOfferingTermsSubstantiallySimilarOutlinedCurrentS1Dated],
    },
    [OpenItemTaxonomy.FinalCopyS1]: {
      text: itemsMessages[OpenItemTaxonomy.FinalCopyS1],
    },
    [OpenItemTaxonomy.IndividualProducerNameNationalProducerNumberRequiredPrior]: {
      text: itemsMessages[OpenItemTaxonomy.IndividualProducerNameNationalProducerNumberRequiredPrior],
    },
    [OpenItemTaxonomy.ReceiptTerrorismPolicyholderDisclosureStatement]: {
      text: itemsMessages[OpenItemTaxonomy.ReceiptTerrorismPolicyholderDisclosureStatement],
    },
    [OpenItemTaxonomy.IPOCloseWithin5DaysBinding]: {
      text: itemsMessages[OpenItemTaxonomy.IPOCloseWithin5DaysBinding],
    },
    [OpenItemTaxonomy.ProvideMostRecentAuditedFinancialStatements]: {
      text: itemsMessages[OpenItemTaxonomy.ProvideMostRecentAuditedFinancialStatements],
      informationRequested: true,
    },
    [OpenItemTaxonomy.ProvideInterimYearDateProfitLossIncomeStatements]: {
      text: itemsMessages[OpenItemTaxonomy.ProvideInterimYearDateProfitLossIncomeStatements],
      informationRequested: true,
    },
    [OpenItemTaxonomy.DescribeDetailProfessionalServicesWhichCoverageDesired]: {
      text: itemsMessages[OpenItemTaxonomy.DescribeDetailProfessionalServicesWhichCoverageDesired],
      informationRequested: true,
    },
    [OpenItemTaxonomy.OpenItemsOnApplicationPleaseProvideAnswers]: {
      text: itemsMessages[OpenItemTaxonomy.OpenItemsOnApplicationPleaseProvideAnswers],
      informationRequested: true,
    },
    [OpenItemTaxonomy.List5LargestJobsProjectsPast3Years]: {
      text: itemsMessages[OpenItemTaxonomy.List5LargestJobsProjectsPast3Years],
      informationRequested: true,
    },
  };
}
