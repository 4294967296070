import { RefObject, useCallback, useLayoutEffect, useRef, useState } from 'react';

interface UseScrollButtonVisibilityProps {
  ref: RefObject<HTMLElement>;
  sectionRefs: RefObject<Record<string, HTMLDivElement | null>>;
}

export const useScrollButtonVisibility = ({ ref, sectionRefs }: UseScrollButtonVisibilityProps) => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const scrollTimeout = useRef<number | null>(null);

  const updateButtonVisibility = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = ref.current;
    const isScrollable = scrollHeight > clientHeight;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10; // Small tolerance for the bottom

    setShowScrollButton(isScrollable && !isAtBottom);
  }, [ref]);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (!ref.current) {
        return;
      }

      setShowScrollButton(false); // Hide the button when scrolling starts

      if (scrollTimeout.current !== null) {
        clearTimeout(scrollTimeout.current);
      }

      scrollTimeout.current = window.setTimeout(() => {
        updateButtonVisibility();
        scrollTimeout.current = null;
      }, 300);
    };

    const handleResize = () => {
      updateButtonVisibility();
    };

    const element = ref.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
      updateButtonVisibility(); // Initial check
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', handleResize);
      if (scrollTimeout.current !== null) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, [ref, updateButtonVisibility]);

  useLayoutEffect(() => {
    setTimeout(updateButtonVisibility, 100); // Ensure the layout is rendered before checking
  }, [updateButtonVisibility]);

  const handleScrollDown = () => {
    if (!ref.current || !sectionRefs.current) {
      return;
    }

    const element = ref.current;
    const { scrollTop } = element;

    // Get section positions
    const sectionPositions = Object.keys(sectionRefs.current)
      .map((sectionId) => {
        const sectionElement = sectionRefs.current![sectionId];
        if (!sectionElement) {
          return null;
        }

        const sectionTop = sectionElement.offsetTop - element.offsetTop;
        return { id: sectionId, top: sectionTop };
      })
      .filter(Boolean);

    // Find the next section based on current scroll position
    const nextSection = sectionPositions.find((section) => section!.top > scrollTop);

    if (nextSection) {
      // Scroll to the next section
      element.scrollTo({ top: nextSection.top, behavior: 'smooth' });
    } else {
      // If no next section, scroll to the bottom
      const { scrollHeight } = element;
      element.scrollTo({ top: scrollHeight, behavior: 'smooth' });
    }
  };

  return { showScrollButton, handleScrollDown };
};
