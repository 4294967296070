import { useMemo } from 'react';
import { SubmissionEmailLabel } from 'enums';
import useGetSubmissionEmailsWithFileMetadata from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Email/useGetSubmissionEmailsWithFileMetadata';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export const useGetProposalEmailList = () => {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { submissionEmailsList } = useGetSubmissionEmailsWithFileMetadata({
    submissionId: partialSubmission?.id,
  });

  return useMemo(
    () => submissionEmailsList.filter((submissionEmail) => submissionEmail.label === SubmissionEmailLabel.Proposal),
    [submissionEmailsList],
  );
};
