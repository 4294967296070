import { SubmissionMarketRequestStatus } from 'enums';
import { MarketingUpdateStatus } from 'broker/enums';

export const MarketingUpdateStatusMap: Record<SubmissionMarketRequestStatus, MarketingUpdateStatus> = {
  [SubmissionMarketRequestStatus.AwaitingQuote]: MarketingUpdateStatus.InReview,
  [SubmissionMarketRequestStatus.PendingCustomer]: MarketingUpdateStatus.InReview,
  [SubmissionMarketRequestStatus.ReadyToMarket]: MarketingUpdateStatus.InReview,
  [SubmissionMarketRequestStatus.QuoteReady]: MarketingUpdateStatus.Quoted,
  [SubmissionMarketRequestStatus.AwaitingBinder]: MarketingUpdateStatus.Quoted,
  [SubmissionMarketRequestStatus.Bound]: MarketingUpdateStatus.Bound,
  [SubmissionMarketRequestStatus.AwaitingPolicy]: MarketingUpdateStatus.Bound,
  [SubmissionMarketRequestStatus.PolicyIssued]: MarketingUpdateStatus.Bound,
  [SubmissionMarketRequestStatus.Declined]: MarketingUpdateStatus.Declined,
  [SubmissionMarketRequestStatus.QuoteNotAccepted]: MarketingUpdateStatus.Declined,
};
