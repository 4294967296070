import { Box } from '@common-components';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { Proposal } from './Proposal/Proposal';

export default function ProposalTab() {
  const { submission, markets, quotes, layers } = useSubmissionsWorkspace();

  if (!submission) {
    return null;
  }

  return (
    <Box height={1} bgcolor="common.white" display="flex" alignItems="center">
      <Proposal submission={submission} markets={markets} quotes={quotes} layers={layers} />
    </Box>
  );
}
