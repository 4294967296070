import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { Box, Button, Divider, Stack } from '@common-components';
import { useFormProvider } from 'hooks';
import { messages } from 'i18n';
import FormTextField from 'components/hookFormComponents/FormTextField';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

interface NoteFormProps {
  id: string;
  isContentDirty: boolean;
  setIsContentDirty: (nextState: boolean) => void;
  closeWithoutPrompt: () => void;
}

interface NoteDataProps {
  note?: string;
}

export const schema = yup.object().shape({
  note: yup.string().trim().nullable(),
});

export default function NoteForm({
  id,
  isContentDirty,
  setIsContentDirty,
  closeWithoutPrompt,
  note,
}: NoteFormProps & NoteDataProps) {
  const { updateSubmissionMarketRequest } = useSubmissionsWorkspace();

  const { methods } = useFormProvider<NoteDataProps>({
    schema,
    setIsDirty: setIsContentDirty,
    defaultValues: { note },
  });

  const onSubmit: SubmitHandler<NoteDataProps> = async (data) => {
    await updateSubmissionMarketRequest(id, {
      notes: data.note,
    });
    closeWithoutPrompt();
  };

  return (
    <Stack>
      <FormProvider {...methods}>
        <Box p={1} pb={0} width={400}>
          <FormTextField
            placeholder={messages.general.addNote}
            maxRows={6}
            minRows={6}
            fullWidth
            multiline
            autoFocus
            name="note"
            id="note"
            hideBorder
          />
        </Box>
      </FormProvider>
      <Divider />
      <Stack direction="row" p={2} justifyContent="flex-end">
        <Button
          size="small"
          variant="outlined"
          disabled={!isContentDirty || methods.formState.isSubmitting}
          onClick={methods.handleSubmit(onSubmit)}
        >
          {note ? messages.buttons.update : messages.buttons.add}
        </Button>
      </Stack>
    </Stack>
  );
}
