import { debounce } from 'lodash';
import { MutableRefObject, useEffect } from 'react';

type SectionRefs = Record<string, HTMLDivElement | null>;

interface UseScrollSectionsProps {
  scrollableDivRef: MutableRefObject<HTMLDivElement | null>;
  sectionRefs: MutableRefObject<SectionRefs>;
  activeSection: string | undefined;
  setActiveSection: (section: string | undefined) => void;
  threshold?: number;
}

export const useScrollSections = ({
  scrollableDivRef,
  sectionRefs,
  activeSection,
  setActiveSection,
  threshold = 200,
}: UseScrollSectionsProps) => {
  useEffect(() => {
    const container = scrollableDivRef.current;

    if (!container) {
      return undefined;
    } // Simply return if there's no container

    const handleScroll = debounce(() => {
      const containerTop = container.getBoundingClientRect().top;

      const sectionPositions = Object.keys(sectionRefs.current).map((sectionId) => {
        const sectionElement = sectionRefs.current[sectionId];
        if (!sectionElement) {
          return { id: sectionId, top: Infinity };
        }

        const sectionTop = sectionElement.getBoundingClientRect().top - containerTop;
        return { id: sectionId, top: sectionTop };
      });

      const activeSectionId = sectionPositions
        .filter((section) => section.top <= threshold)
        .reduce((last, section) => (section.top > last.top ? section : last), { id: '', top: -Infinity }).id;

      if (activeSectionId && activeSectionId !== activeSection) {
        setActiveSection(activeSectionId);
      }
    }, 50);

    container.addEventListener('scroll', handleScroll);

    // Return the cleanup function to remove the event listener on unmount
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableDivRef.current, activeSection, sectionRefs, setActiveSection, threshold]);
};
