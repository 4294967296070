import { BoxItem } from 'box-ui-elements/es';
import { isEmpty } from 'lodash';
import { Chip, Menu } from '@common-components';
import { AttachFile as AttachFileIcon } from '@icons';
import { useOptionsMenu } from 'hooks';
import { EmailAttachmentsResult } from 'broker/components/Emails/useGetEmail';
import Attachment from './Attachment';

interface AttachmentsProps {
  attachments: EmailAttachmentsResult;
  deletedAttachments: string[];
  onAttachmentClick: (file: BoxItem) => void;
}

export default function Attachments({ attachments, onAttachmentClick, deletedAttachments }: AttachmentsProps) {
  const optionsMenuState = useOptionsMenu();

  // attachments could not be returned by box (deleted)
  if (!attachments.isLoading && isEmpty(attachments.items)) {
    return null;
  }

  return (
    <>
      <Chip
        label="Attachments"
        startIcon={AttachFileIcon}
        endBadgeContent={attachments.items.length}
        variant="outlined"
        color="secondary"
        rounded
        disabled={attachments.isLoading}
        onClick={optionsMenuState.openMenu}
      />
      <Menu
        open={optionsMenuState.isMenuOpen}
        onClose={optionsMenuState.closeMenu}
        anchorEl={optionsMenuState.anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {attachments.items
          .filter((file) => !deletedAttachments.includes(file.id))
          .map((file) => (
            <Attachment
              key={file.id}
              file={file}
              onClick={() => {
                onAttachmentClick(file);
                optionsMenuState.closeMenu();
              }}
            />
          ))}
      </Menu>
    </>
  );
}
