import { ReactNode } from 'react';
import { Card, SxProps, Theme } from '@common-components';
import { useBoolean } from 'hooks';
import { uniteStyles } from 'utils';

interface HoverCardProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
  selected?: boolean;
}

export default function HoverCard({ children, sx, selected }: HoverCardProps) {
  const [isHovered, { off: hoverOn, on: hoverOff }] = useBoolean(false);

  const borderColor = (theme: Theme) => (selected ? theme.palette.blue['300'] : theme.palette.grey['300']);

  return (
    <Card
      variant={isHovered ? 'elevation' : 'outlined'}
      raised={isHovered}
      onMouseOver={hoverOff}
      onMouseOut={hoverOn}
      sx={uniteStyles(
        (theme) => ({
          border: `1px solid ${borderColor(theme)}`,
          borderRadius: 2,
          cursor: 'pointer',
        }),
        sx,
      )}
    >
      {children}
    </Card>
  );
}
