import { Dispatch } from 'react';
import { QuoteFileIdType } from 'enums';
import { useToast } from 'hooks';
import { messages } from 'i18n';
import { dialogTransitionDurationValue } from 'themes';
import { Quote } from 'types';
import { quoteRemovedToast } from 'utils';
import { quoteFileKeyConfig } from 'broker/components/common/QuotesAndPolicies/quotesAndPoliciesConfig';
import { getSelectionQuotes } from 'broker/pages/SubmissionWorkspacePage/dialogs/ChooseQuoteModal/utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

import { ActionTypes, ChooseQuoteModalPayload, ChooseQuoteShortModalPayload, ModalActions } from './types';

export default function useModalActions(dispatch: Dispatch<ActionTypes>) {
  const workspaceActions = useSubmissionsWorkspace();
  const { showDelayedToast } = useToast();

  // Helper functions

  const removeQuoteGenerator = (quoteType: QuoteFileIdType) => async (quote: Quote) => {
    await workspaceActions?.removeQuote(quote.id);

    // noinspection ES6MissingAwait
    showDelayedToast(
      'success',
      {
        message: quoteRemovedToast(quote.marketName!, quoteType),
      },
      dialogTransitionDurationValue,
    );
  };

  const quoteNotAcceptedGenerator = async (quote: Quote) => {
    await workspaceActions?.rejectQuote(quote.id);

    await showDelayedToast(
      'success',
      { message: messages.submissionWorkspace.dialogs.quoteNotAccepted.successMessage(quote.marketName!) },
      dialogTransitionDurationValue,
    );
  };

  // Actions returned

  const chooseQuoteSetIsOpen = (payload: ChooseQuoteModalPayload) => {
    dispatch({ type: 'CHOOSE_QUOTE_SET_IS_OPEN', payload });
  };

  const chooseQuoteSetIsClose = () => {
    dispatch({ type: 'CHOOSE_QUOTE_SET_IS_CLOSE' });
  };

  // Shortcut to open 'remove quote' dialog via chooseQuoteSetIsOpen
  const openRemoveQuoteDialog = (payload: ChooseQuoteShortModalPayload) => {
    const quoteItems = getSelectionQuotes(payload.marketActiveQuotes, payload.quoteType);

    const subtitle =
      quoteItems.length > 1
        ? messages.submissionWorkspace.dialogs.removeQuote.subtitle(quoteFileKeyConfig[payload.quoteType].title)
        : messages.submissionWorkspace.dialogs.removeQuote.singleSubtitle(
            quoteFileKeyConfig[payload.quoteType].title,
            quoteItems[0].marketName!,
          );

    chooseQuoteSetIsOpen({
      ...payload,
      title: messages.submissionWorkspace.dialogs.removeQuote.title(quoteFileKeyConfig[payload.quoteType]?.title),
      subtitle,
      proceedText: messages.submissionWorkspace.dialogs.removeQuote.proceedText(
        quoteFileKeyConfig[payload.quoteType]?.title,
      ),
      onProceed: removeQuoteGenerator(payload.quoteType),
      showWarningIcon: true,
    });
  };

  // Shortcut to open 'reject quote' dialog via chooseQuoteSetIsOpen
  const openQuoteNotAcceptedDialog = (payload: ChooseQuoteShortModalPayload) => {
    const quoteItems = getSelectionQuotes(payload.marketActiveQuotes, payload.quoteType);

    const subtitle =
      quoteItems.length > 1
        ? messages.submissionWorkspace.dialogs.quoteNotAccepted.subtitle
        : messages.submissionWorkspace.dialogs.quoteNotAccepted.singleSubtitle(quoteItems[0].marketName!);

    chooseQuoteSetIsOpen({
      ...payload,
      title: messages.submissionWorkspace.dialogs.quoteNotAccepted.title,
      subtitle,
      proceedText: messages.submissionWorkspace.dialogs.quoteNotAccepted.proceedText,
      onProceed: quoteNotAcceptedGenerator,
      showWarningIcon: true,
    });
  };

  const modalAction: ModalActions = {
    chooseQuoteSetIsClose,
    openRemoveQuoteDialog,
    openQuoteNotAcceptedDialog,
  };

  return modalAction;
}
