import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import Content from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/ConceptualTower/Content';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export default function ConceptualTower() {
  const { submission, quotes, layers } = useSubmissionsWorkspace();

  if (!submission || !quotes || !layers) {
    return null;
  }

  return (
    <WithRouteLocationState routeKey={RouteKey.ConceptualTower} isStateRequired={false}>
      {(locationState) => (
        <Content submission={submission} quotes={quotes} layers={layers} locationState={locationState} />
      )}
    </WithRouteLocationState>
  );
}
