import { jsPDF as JsPDF } from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { messages } from 'i18n';
import theme from 'themes';
import { getTodayDisplayDate } from 'utils';

const columnsConfig = [
  { header: messages.activityLogTable.columns.date, dataKey: 'date' },
  { header: messages.activityLogTable.columns.time, dataKey: 'time' },
  { header: messages.activityLogTable.columns.activity, dataKey: 'activity' },
  { header: messages.activityLogTable.columns.performedBy, dataKey: 'actor' },
];

interface ActivityLogReportRow {
  date: string;
  time: string;
  activity: string;
  actor: string;
}

interface ActivityLogReport {
  title: string;
  subtitle: string;
  rows: ActivityLogReportRow[];
}

export function generateBlobPdf(activityLogReport: ActivityLogReport): Blob {
  const doc = new JsPDF('portrait');
  doc.text(activityLogReport.title, 14, 15);
  doc.setFontSize(10);
  doc.text(getTodayDisplayDate(), 165, 15);
  doc.setFontSize(10);
  doc.text(activityLogReport.subtitle, 14, 23);
  doc.setLineHeightFactor(1.5);

  autoTable(doc, {
    startY: 30,
    body: activityLogReport.rows as unknown as RowInput[],
    columns: columnsConfig,
    rowPageBreak: 'avoid',
    styles: { overflow: 'linebreak', cellPadding: 2 },
    columnStyles: {
      date: { minCellWidth: 25 },
      time: { minCellWidth: 25 },
      actor: { minCellWidth: 40 },
    },
    headStyles: { fillColor: theme.palette.blue[700] },
  });

  return doc.output('blob');
}
