import { Transition } from 'history';
import { useEffect, useRef } from 'react';
import { useNavigationContext } from 'contexts';

interface useBlockNavigationProps {
  shouldBlock: boolean;
  onBlock: (transition: Transition, unBlockNavigation: () => void) => void;
}

export function useBlockNavigation({ shouldBlock, onBlock }: useBlockNavigationProps) {
  const { history } = useNavigationContext();

  // A ref that allows us to remove the route navigation blocker when we no longer need it
  const unblockNavigationRef = useRef<Function>();

  const unblockNavigation = () => unblockNavigationRef.current?.();

  useEffect(() => {
    if (shouldBlock) {
      // call the onBlock function upon navigation attempt
      unblockNavigationRef.current = history.block((transition: Transition) => onBlock(transition, unblockNavigation));
    } else {
      // Release current navigation block if exists
      unblockNavigation();
    }

    // Release the block on unmount of the component
    return unblockNavigation;
  }, [shouldBlock, onBlock, history]);
}
