import { createContext } from 'react';
import { InformationTooltipState, InformationTooltipStoreActions, InformationTooltipType } from './types';

export const InformationTooltipStoreInitState: InformationTooltipState = {
  [InformationTooltipType.ProposalAttachments]: false,
  [InformationTooltipType.EmailAttachments]: false,
  [InformationTooltipType.QuoteFiles]: false,
  [InformationTooltipType.SmartReports]: false,
  [InformationTooltipType.UploadFiles]: false,
};

export const InformationTooltipStateContext = createContext<InformationTooltipState>(InformationTooltipStoreInitState);
export const InformationTooltipActionsContext = createContext<InformationTooltipStoreActions | undefined>(undefined);
