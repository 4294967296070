import { Button, Stack, Typography } from '@common-components';
import { KeyboardArrowRight } from '@icons';
import { messages } from 'i18n';

interface HeraldStepperFooterProps {
  onSubmit: () => void;
  active?: string | undefined;
  setActiveSection?: (section: any) => void;
  list?: string[];
  isSubmitting: boolean;
  submitButtonText?: string;
  isAcknowledgmentFlow?: boolean;
  caption?: string;
}

export default function SubmissionFooter({
  onSubmit,
  isSubmitting,
  submitButtonText,
  isAcknowledgmentFlow,
  caption,
}: HeraldStepperFooterProps) {
  return (
    <Stack direction="row" gap={1} justifyContent="center" alignItems="center" pt={6}>
      <Stack flex={1} alignItems="flex-start">
        {caption && (
          <Typography variant="body2" color="error">
            {caption}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" gap={1} justifyContent="flex-end" alignItems="center">
        <Button
          endIcon={isAcknowledgmentFlow ? <KeyboardArrowRight /> : undefined}
          variant="contained"
          onMouseDown={onSubmit}
          disabled={isSubmitting}
        >
          {submitButtonText ?? messages.general.saveAndLeave}
        </Button>
      </Stack>
    </Stack>
  );
}
