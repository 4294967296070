import { BoxSkeleton, Stack } from '@common-components';

export default function EmailSkeleton() {
  return (
    <Stack direction="column" p={3} width={1} height={1} gap={3}>
      <BoxSkeleton sx={{ width: 0.5, height: 50, m: 0 }} isLoading />
      <BoxSkeleton sx={{ width: 0.8, height: 50, m: 0 }} isLoading />
      <BoxSkeleton sx={{ width: 1, height: 500, m: 0 }} isLoading />
    </Stack>
  );
}
