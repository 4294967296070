import { messages } from 'i18n';
import { BannerMode } from 'broker/components/Banner';
import { AlertBannerMode } from './types';

interface missingLabelAlertBannerConfigProps {
  title: string;
  subtitle: string;
  bannerMode: BannerMode;
}

const alertBannerMessages = messages.addQuotePage.quoteInsights.alertBanner;

export const missingLabelAlertBannerConfig: Record<AlertBannerMode, missingLabelAlertBannerConfigProps> = {
  [AlertBannerMode.MissingMarketQuoteFileLabel]: {
    title: alertBannerMessages.missingMarketQuoteLabel.title,
    subtitle: alertBannerMessages.missingMarketQuoteLabel.subtitle,
    bannerMode: BannerMode.Error,
  },
  [AlertBannerMode.MissingPolicySpecimenFileLabel]: {
    title: alertBannerMessages.missingPolicySpecimenLabel.title,
    subtitle: alertBannerMessages.missingPolicySpecimenLabel.subtitle,
    bannerMode: BannerMode.Warning,
  },
  [AlertBannerMode.MissingMarketBinderFileLabel]: {
    title: alertBannerMessages.missingMarketBinderLabel.title,
    subtitle: alertBannerMessages.missingMarketBinderLabel.subtitle,
    bannerMode: BannerMode.Error,
  },
  [AlertBannerMode.MissingMarketPolicyFileLabel]: {
    title: alertBannerMessages.missingMarketPolicyLabel.title,
    subtitle: alertBannerMessages.missingMarketPolicyLabel.subtitle,
    bannerMode: BannerMode.Error,
  },
};
