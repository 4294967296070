import { isEmpty, keyBy } from 'lodash';
import { Fragment, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@common-components';
import { Close } from '@icons';
import { SubmissionMarketRequestStatus } from 'enums';
import { useBoolean, useToast } from 'hooks';
import { messages } from 'i18n';
import { InformationRequested, InformationRequestType, SubmissionMarketRequest } from 'types';
import InputLabel from 'components/hookFormComponents/InputLabel';
import { informationRequestedConfig } from 'broker/configuration-mappers/information-request-config';
import InnerPageLayout, {
  FooterButtonCallback,
} from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { InformationRequestedLocationState } from './types';

type InformationRequestModel = {
  [item in InformationRequestType]?: {
    checked: boolean;
    text: string;
  };
};

interface ContentProps {
  locationState: InformationRequestedLocationState;
}

export default function Content({ locationState: { selectedSubmissionMarketRequest } }: ContentProps) {
  const workspaceActions = useSubmissionsWorkspace();
  const [isLoading, { on: startLoading, off: stopLoading }] = useBoolean();
  const [otherText, setOtherText] = useState(
    selectedSubmissionMarketRequest.informationRequested?.find((item) => item.type === 'Other')?.details || '',
  );
  const config = useMemo(
    () => informationRequestedConfig(selectedSubmissionMarketRequest),
    [selectedSubmissionMarketRequest],
  );
  const { showToast } = useToast();

  const buildInformationRequestedState = useCallback(() => {
    const preSelectedInformationRequest = keyBy(
      selectedSubmissionMarketRequest.informationRequested,
      (marketInformationRequested) => marketInformationRequested.type,
    );
    return Object.keys(config).reduce<InformationRequestModel>((acc, key) => {
      acc[key as InformationRequestType] = {
        checked: key in preSelectedInformationRequest,
        text: preSelectedInformationRequest[key]?.details ?? '',
      };
      return acc;
    }, {});
  }, [selectedSubmissionMarketRequest, config]);

  const [selectedInformationRequest, setSelectedInformationRequest] = useState(buildInformationRequestedState);

  const handleUpdateButtonClick = async ({ onClose, setIsDirty }: InnerPageProps) => {
    startLoading();
    const checkedInformationRequested = Object.entries(selectedInformationRequest)
      .filter(([, selectedInformationData]) => selectedInformationData.checked)
      .map<InformationRequested>(([selectedInformationType, selectedInformationData]) => ({
        type: selectedInformationType as InformationRequestType,
        details: selectedInformationData.text,
      }));

    if (otherText) {
      checkedInformationRequested.push({ type: 'Other', details: otherText });
    }

    const payload: Partial<SubmissionMarketRequest> = {
      informationRequested: checkedInformationRequested,
      status: SubmissionMarketRequestStatus.PendingCustomer,
    };
    setIsDirty(false);

    await workspaceActions!.updateSubmissionMarketRequest(selectedSubmissionMarketRequest.id, payload);

    showToast('success', {
      message: messages.informationRequestPage.successMessage(selectedSubmissionMarketRequest.marketName),
    });

    onClose();

    stopLoading();
  };

  const [extendedInformationRequired, setExtendedInformationRequired] = useState<Array<InformationRequestType>>([]);

  function shouldShowMoreDetailsButton(
    { checked, text }: { checked: boolean; text: string },
    informationRequestType: InformationRequestType,
  ) {
    return (
      isEmpty(text) &&
      checked &&
      !extendedInformationRequired.includes(informationRequestType) &&
      informationRequestType !== 'Other'
    );
  }

  function shouldShowInformationRequestedDetails(
    { text }: { text: string },
    informationRequestType: InformationRequestType,
  ) {
    return !isEmpty(text) || extendedInformationRequired.includes(informationRequestType);
  }

  const proceedButton: FooterButtonCallback = (innerPageProps: InnerPageProps) => ({
    children: messages.buttons.update,
    onClick: async () => {
      await handleUpdateButtonClick(innerPageProps);
    },
    loading: isLoading,
    disabled: !innerPageProps.isDirty,
  });

  return (
    <InnerPageLayout
      footerButtons={{ proceedButton }}
      title={messages.informationRequestPage.title}
      subTitle={messages.informationRequestPage.subtitle}
      className="cap-information-requested"
    >
      {({ setIsDirty }) => (
        <FormGroup sx={{ gap: 1 }}>
          <>
            <InputLabel
              typographyProps={{ variant: 'body1Bold' }}
              label={messages.informationRequestPage.otherLabel}
              error={false}
              htmlFor="other-input"
            />

            <TextField
              id="other-input"
              fullWidth
              multiline
              minRows={5}
              maxRows={8}
              placeholder={messages.informationRequestPage.otherPlaceholder}
              value={otherText}
              onChange={(e) => {
                setIsDirty(true);
                setOtherText(e.target.value);
              }}
            />
          </>
          <Typography mt={2} variant="body1Bold">
            {messages.informationRequestPage.checkboxListLabel}
          </Typography>
          {Object.entries(config).map(([informationRequestKey, informationRequestText]) => {
            const informationRequestType = informationRequestKey as InformationRequestType;
            const selectedInformationRequestData = selectedInformationRequest[informationRequestType]!;
            return (
              <Fragment key={informationRequestKey}>
                <Box flexDirection="column" display="flex">
                  <FormControlLabel
                    checked={selectedInformationRequestData.checked}
                    control={<Checkbox />}
                    label={informationRequestText}
                    onChange={() => {
                      setIsDirty(true);
                      setSelectedInformationRequest((prev) => ({
                        ...prev,
                        [informationRequestKey]: { checked: !prev[informationRequestType]!.checked, text: '' },
                      }));
                    }}
                    sx={{ alignItems: 'flex-start', '& .MuiFormControlLabel-label': { py: 1 } }}
                  />
                  {shouldShowMoreDetailsButton(selectedInformationRequestData, informationRequestType) && (
                    <Box sx={{ width: 'fit-content', ml: 3.25 }}>
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => setExtendedInformationRequired((prev) => [...prev, informationRequestType])}
                      >
                        Add more details...
                      </Button>
                    </Box>
                  )}
                </Box>
                {shouldShowInformationRequestedDetails(selectedInformationRequestData, informationRequestType) && (
                  <TextField
                    autoFocus
                    multiline
                    value={selectedInformationRequestData.text}
                    onChange={(event) => {
                      setIsDirty(true);
                      setSelectedInformationRequest((prev) => ({
                        ...prev,
                        [informationRequestKey]: { ...prev[informationRequestType]!, text: event.target.value },
                      }));
                    }}
                    InputProps={{
                      endAdornment: informationRequestType !== 'Other' && (
                        <InputAdornment position="end">
                          <IconButton
                            icon={Close}
                            onClick={() => {
                              setExtendedInformationRequired((prev) =>
                                prev.filter((type) => type !== informationRequestType),
                              );
                              selectedInformationRequestData.text = '';
                            }}
                            color="secondary"
                            size="small"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Fragment>
            );
          })}
        </FormGroup>
      )}
    </InnerPageLayout>
  );
}
