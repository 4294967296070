import { GridColDef, Typography } from '@common-components';
import { messages } from 'i18n';

function getColumns(): GridColDef[] {
  return [
    {
      field: 'marketName',
      headerName: messages.expiringTowerPage.columnHeaders.marketName,
      flex: 1,
      renderCell: (cellValue) => <Typography variant="body2">{cellValue.value}</Typography>,
      sortable: false,
    },
    {
      field: 'layer',
      headerName: messages.expiringTowerPage.columnHeaders.layer,
      flex: 2,
      renderCell: (cellValue) => <Typography variant="body2">{cellValue.value}</Typography>,
      sortable: false,
    },
    {
      field: 'aggregateLimit',
      headerName: messages.expiringTowerPage.columnHeaders.aggregateLimit,
      flex: 1,
      renderCell: (cellValue) => <Typography variant="body2">{cellValue.value}</Typography>,
      sortable: false,
    },
    {
      field: 'attachmentPoint',
      headerName: messages.expiringTowerPage.columnHeaders.AttachmentPointRetention,
      flex: 1,
      renderCell: (cellValue) => <Typography variant="body2">{cellValue.value}</Typography>,
      sortable: false,
    },
    {
      field: 'premium',
      headerName: messages.expiringTowerPage.columnHeaders.premium,
      flex: 1,
      renderCell: (cellValue) => <Typography variant="body2">{cellValue.value}</Typography>,
      sortable: false,
    },
  ];
}

export default getColumns;
