export enum EmailTemplateType {
    InformationRequestedRetailer = 'InformationRequestedRetailer',
    BindOrder = 'BindOrder',
    PolicyOrder = 'PolicyOrder',
    BinderCorrection = 'BinderCorrection',
    LayerBinder = 'LayerBinder',
    LayerPolicy = 'LayerPolicy',
    MarketDeclined = 'MarketDeclined',
    MarketingReport = 'MarketingReport',
    RfqGeneral = 'RfqGeneral',
    PrimaryMarketing = 'PrimaryMarketing',
    ExcessMarketing = 'ExcessMarketing',
    PolicyCorrection = 'PolicyCorrection',
    RfqAttachmentPoint = 'RfqAttachmentPoint',
    ConceptualTower = 'ConceptualTower',
    SubmissionUpdate = 'SubmissionUpdate',
    NewBusinessProposal = 'NewBusinessProposal',
    MissingInformationRequest = 'MissingInformationRequest',
    UserNotification = 'UserNotification',
    EscalationPolicy = 'EscalationPolicy',
    MarketEmailFollowup = 'MarketEmailFollowup',
    CustomerEmailFollowup = 'CustomerEmailFollowup',
    CannotPursue = 'CannotPursue',
    PositiveExpectation = 'PositiveExpectation',
    NewQuote = 'NewQuote',
    SubmissionAssigned = 'SubmissionAssigned',
    SubmissionReceived = 'SubmissionReceived',
    Compose = 'Compose',
}

export interface EmailTemplateMetadata {
    isIncumbent?: boolean;
}
