import { Chip, Stack } from '@common-components';
import { WarningAmber } from '@icons';
import { SubmissionMarketRequestStatus, SubmissionMethod } from 'enums';
import { useRetrieveHeraldApplication } from 'hooks';
import { messages } from 'i18n';
import { ExtendedMarketRequest } from 'types';
import { isDynamicApplication } from 'utils';
import { MethodsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/Components/methods-config';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { areHeraldProductsEligibleToCreateSubmission } from 'broker/pages/SubmissionWorkspacePage/utils';
import { useSubmissionMarketRequestActions } from './useSubmissionMarketRequestActions';

interface AvailableSubmitChipsProps {
  marketRequest: ExtendedMarketRequest;
  setIsSubmittingMarketRequest?: (isSubmitting: boolean) => void;
}
export default function AvailableSubmitChips({
  marketRequest,
  setIsSubmittingMarketRequest,
}: AvailableSubmitChipsProps) {
  const { submission } = useSubmissionsWorkspace();

  const isDynamicSubmission = !!(submission && isDynamicApplication(submission));

  const { submitToMarketByPortal, submitProductViaApi } = useSubmissionMarketRequestActions(
    marketRequest,
    setIsSubmittingMarketRequest,
  );

  const { data: heraldApplicationResponse } = useRetrieveHeraldApplication({
    id: submission?.heraldData?.applicationId!,
    enabled: isDynamicSubmission,
  });

  const quoteExit =
    marketRequest.insuranceProduct?.externalProductId &&
    heraldApplicationResponse?.quote_exits?.find(
      (quote) => quote.product_id === marketRequest.insuranceProduct?.externalProductId,
    );

  const showSubmitViaPortal =
    marketRequest.insuranceProduct?.submissionPortal &&
    marketRequest.status === SubmissionMarketRequestStatus.ReadyToMarket;

  const showNotCompletedApiApplication =
    marketRequest.insuranceProduct?.externalProductId &&
    submission &&
    !areHeraldProductsEligibleToCreateSubmission(submission, [marketRequest.insuranceProduct.externalProductId])
      .eligible &&
    isDynamicSubmission;

  const showSubmitViaApi =
    submission &&
    marketRequest.status === SubmissionMarketRequestStatus.ReadyToMarket &&
    marketRequest.insuranceProduct?.externalProductId &&
    marketRequest.submissionMethod !== SubmissionMethod.API &&
    isDynamicSubmission;

  const apiConfig = MethodsConfig[SubmissionMethod.API];
  const portalConfig = MethodsConfig[SubmissionMethod.Portal];

  return (
    <Stack gap={0.5} direction="row">
      {showSubmitViaPortal && <Chip {...portalConfig} label="Submit Via Portal" onClick={submitToMarketByPortal} />}
      {showSubmitViaApi && (
        <Chip
          {...apiConfig}
          label={messages.buttons.submitViaApi}
          textColor={showNotCompletedApiApplication || quoteExit ? 'grey.600' : apiConfig.textColor}
          iconColor={showNotCompletedApiApplication || quoteExit ? 'grey.600' : apiConfig.iconColor}
          disabled={!!showNotCompletedApiApplication || !!quoteExit}
          disabledTooltipContent={
            quoteExit
              ? quoteExit.status_details[0].status_text.applicant_facing_text
              : marketRequest.insuranceProduct?.externalProductId &&
                areHeraldProductsEligibleToCreateSubmission(submission, [
                  marketRequest.insuranceProduct.externalProductId,
                ]).reason
          }
          onClick={submitProductViaApi}
        />
      )}
      {showNotCompletedApiApplication && showSubmitViaApi && (
        <Chip
          label={messages.general.submissionInformationIncomplete}
          color="error.main"
          textColor="error.main"
          iconColor="error.main"
          variant="outlined"
          size="small"
          startIcon={WarningAmber}
        />
      )}
    </Stack>
  );
}
