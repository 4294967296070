import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Divider, Link, Stack, Typography } from '@common-components';
import { EmailMessageContentType, EmailMessageRecipientType } from 'enums';
import { useCurrentUser, useToast } from 'hooks';
import { useMutateEmailMessageBatch } from 'hooks/api/emailMessagesBatch';
import { messages } from 'i18n';
import { EmailMessageCreate } from 'types';
import { htmlToPlain } from 'utils';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { schema, SupportFormData } from './utils';

interface SupportDialogProps {
  isSupportDialogOpen: boolean;
  closeSupportDialog: () => void;
}

export default function SupportDialog({ isSupportDialogOpen, closeSupportDialog }: SupportDialogProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showToast } = useToast();
  const { createEmailMessageBatch } = useMutateEmailMessageBatch();
  const methods = useForm<SupportFormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const disabledSendButton = !methods.getValues().message || !methods.getValues().subject;
  const { title, descriptionParagraph2, contactMethods, recipientName, phoneNumber, getUserContactDetails } =
    messages.SupportModal;
  const { supportMail } = messages.general;
  const { me } = useCurrentUser();

  if (!me) {
    return null;
  }

  const { firstName, lastName, email } = me;
  const organizationName = me.organization.name;

  const onClose = () => {
    closeSupportDialog();
    methods.reset();
  };

  const onSubmit: SubmitHandler<SupportFormData> = async (data) => {
    setIsSubmitting(true);
    const userContactDetails: string = getUserContactDetails(firstName, lastName, email, organizationName);
    const message = userContactDetails + data.message;
    const messageToSend: EmailMessageCreate = {
      subject: data.subject,
      contents: [
        {
          type: EmailMessageContentType.HTML,
          value: message,
        },
        {
          type: EmailMessageContentType.PLAIN,
          value: htmlToPlain(message),
        },
      ],
      recipients: [{ type: EmailMessageRecipientType.To, address: supportMail, name: recipientName }],
    };

    try {
      await createEmailMessageBatch.mutateAsync({
        data: {
          messages: [messageToSend],
        },
      });
      setIsSubmitting(false);
      showToast('success', { message: messages.SupportModal.sendingSuccessfully });
      onClose();
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isSupportDialogOpen} onClose={onClose}>
      <DialogHeader title={title} id="header-support-dialog" onClose={onClose} />
      <DialogContent>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
          {descriptionParagraph2}
          <Link href={`mailto:${supportMail}`}>{supportMail}</Link>
          {contactMethods}
          <Box component="span" color="black">
            {phoneNumber}
          </Box>
        </Typography>
        <Divider />
        <FormProvider {...methods}>
          <Stack mt={3}>
            <FormTextField
              placeholder={email}
              label={messages.SupportModal.yourEmailPlaceHolder}
              name="email"
              id="email"
              disabled
            />
            <FormTextField label="Subject" name="subject" id="subject" />
            <FormTextField label="Message" name="message" id="Message" multiline minRows={5} />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: messages.buttons.send,
          onClick: methods.handleSubmit(onSubmit),
          loading: isSubmitting,
          disabled: disabledSendButton,
        }}
        cancelButton={{ children: messages.buttons.cancel, onClick: onClose }}
      />
    </Dialog>
  );
}
