import { useState } from 'react';
import { useNavigate } from 'react-router-dom-latest';
import { ActionDialog, DialogSelect, DialogTextField, InputLabel, Stack } from '@common-components';
import { isUserOrganization, OrganizationType } from 'enums';
import { getOrganizationType } from 'enums/type-mappers/organization-type-config';
import { useMutateOrganization } from 'hooks';
import { Editor } from 'components/Editor/Editor';
import ADMIN_ROUTES from 'admin/admin-routes';

export interface OrganizationCreateDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function OrganizationCreateDialog({ open, onClose }: OrganizationCreateDialogProps) {
  const navigate = useNavigate();

  const [name, setName] = useState<string | null>(null);
  const [type, setType] = useState<OrganizationType | null>(null);
  const [letterhead, setLetterhead] = useState<string | undefined>(undefined);

  const { createOrganization } = useMutateOrganization();

  const enabled = !!name && !!type;

  const onCreate = async () => {
    if (!type || !name) {
      return;
    }

    onClose();
    const organization = await createOrganization.mutateAsync({
      data: {
        name,
        type,
        letterhead,
      },
    });

    if (organization !== null) {
      const path = ADMIN_ROUTES.getOrganizationById(organization.id);
      navigate(path);
    }
  };

  const organizationTypes = Object.fromEntries(
    Object.keys(OrganizationType).map((value) => [
      getOrganizationType(value as OrganizationType),
      value as OrganizationType,
    ]),
  );

  return (
    <ActionDialog
      actionLabel="Create"
      enabled={enabled}
      open={open}
      onClose={onClose}
      onAction={onCreate}
      title="Create Organization"
    >
      <DialogTextField placeholder="Name" setValue={setName} value={name} autoFocus />
      <DialogSelect
        options={organizationTypes}
        placeholder="Type"
        setValue={setType}
        value={getOrganizationType(type as OrganizationType)}
      />
      {type && isUserOrganization(type) && (
        <>
          <InputLabel sx={{ mt: 2 }}>Company Letterhead</InputLabel>
          <Stack
            height={(theme) => theme.spacing(30)}
            direction="column"
            sx={{
              borderRadius: 2,
              backgroundColor: 'common.white',
              boxShadow: 1,
              overflow: 'auto',
            }}
          >
            <Editor editorContent={letterhead} setEditorContent={setLetterhead} />
          </Stack>
        </>
      )}
    </ActionDialog>
  );
}
