import { BoxItem } from 'box-ui-elements/es';
import { isEmpty } from 'lodash';
import { ReactNode } from 'react';
import { Link, Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { getFileType } from 'utils';
import { FilesListItem } from 'broker/components/common/FilesListItem';
import { useNavigate } from 'broker/hooks';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import { ProposalFileType, proposalFileTypes } from './config';
import { useFileBucketConfig } from './useFileBucketConfig';

interface FilesBucketProps {
  fileType?: ProposalFileType;
  component?: ReactNode;
}

export default function FilesBucket({ fileType, component }: FilesBucketProps) {
  const {
    filesExplorer: { selectedFiles },
  } = useUiStoreState();
  const { setFilesExplorer } = useUiStoreActions();
  const navigate = useNavigate();
  const { filesStep } = messages.submissionWorkspace.tabs.proposal;

  const onRemoveFile = (file: BoxItem) => {
    const remainingFiles = selectedFiles.filter((fileItem) => fileItem.id !== file.id);
    setFilesExplorer({ selectedFiles: remainingFiles });
  };

  const otherFiles = selectedFiles.filter((file) => {
    const ft = getFileType(file);
    return !ft || !proposalFileTypes.includes(ft as ProposalFileType);
  });

  const selectedFilesByFileType = selectedFiles.filter((file) => getFileType(file) === fileType);

  const filesToRender = fileType ? selectedFilesByFileType : otherFiles;

  const filesTypeConfig = useFileBucketConfig();
  const { title, actionTitle, route, navigationOptions } = fileType
    ? filesTypeConfig[fileType]
    : { title: filesStep.additionalAttachments, actionTitle: '', route: '', navigationOptions: undefined };

  const getBucketTitle = () => {
    if (fileType) {
      return (
        <>
          {filesStep.bucketTitlePrefix} <b>{title}</b> {messages.general.or}
          {` `}
        </>
      );
    }
    return filesStep.bucketTitlePrefix;
  };

  return !isEmpty(filesToRender) ? (
    <Stack gap={1}>
      <Typography variant="caption">{title}</Typography>
      <Stack gap={1}>
        {filesToRender.map((file) => (
          <FilesListItem key={file.id} fileItem={file} onRemoveFile={onRemoveFile} />
        ))}
      </Stack>
      {component || (
        <Link
          onClick={() => {
            if (navigationOptions && route) {
              navigate(route, navigationOptions);
            }
          }}
          underline="hover"
          sx={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {actionTitle}
        </Link>
      )}
    </Stack>
  ) : (
    <Stack gap={1}>
      <Typography variant="caption">{title}</Typography>
      <Stack
        p={2}
        border={1}
        borderColor="divider"
        borderRadius={0.5}
        bgcolor="blue.50"
        width={1}
        height={72}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" textAlign="center">
          {getBucketTitle()}
          {component || (
            <Link
              onClick={() => {
                if (navigationOptions && route) {
                  navigate(route, navigationOptions);
                }
              }}
              underline="hover"
              sx={{ cursor: 'pointer', fontWeight: 'bold' }}
            >
              {actionTitle}
            </Link>
          )}
        </Typography>
      </Stack>
    </Stack>
  );
}
