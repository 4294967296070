import { DefaultEmailTemplate, EmailTemplate, OffsetLimitPagination } from 'types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export type EmailTemplateQuery = OffsetLimitPagination;
export class EmailTemplateClient extends CapitolaClient<EmailTemplate> {
  public get resourceName(): string {
    return 'email-template';
  }

  public async getDefaultTemplates(): Promise<{ templates: DefaultEmailTemplate[] } | null> {
    return this.custom({
      httpMethod: HttpMethods.GET,
      path: 'default-templates',
      fetchOptions: { throwOnError: true },
    });
  }
}
