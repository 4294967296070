import { BoxItem } from 'box-ui-elements/es';
import { compact } from 'lodash';
import { useEffect, useState } from 'react';
import { useBoxClient, useSearchUserTeam } from 'hooks';
import { useRetrieveInboundEmailMessage } from 'hooks/api/inboundEmailMessage';
import { useRetrieveSubmissionEmail } from 'hooks/api/submissionEmail';
import { InboundEmailMessage, SubmissionEmail } from 'types';
import { BrokerUrlParams } from 'broker/broker-routes';
import { UnifiedEmail } from 'broker/components/Emails/UnifiedEmail';
import { getFileIsHidden } from 'broker/components/FilesExplorer/utils';
import { useUpdateQueryParam } from 'broker/hooks';
import { normalizeInbound, normalizeInboundFromUser, normalizeOutbound } from './email-normalizers';
import { EmailItemType, isOutboundType } from './types';

interface UseGetEmailProps {
  emailId?: string;
  type: EmailItemType;
  suppressAttachmentsFetching?: boolean;
}

export interface EmailAttachmentsResult {
  // how many items were returned from box (requested - deleted)
  items: BoxItem[];

  isLoading: boolean;
}

function isMessageRetrieved(type: EmailItemType, outboundEmail?: SubmissionEmail, inboundEmail?: InboundEmailMessage) {
  return (isOutboundType(type) && !!outboundEmail) || !!inboundEmail;
}

export function useGetEmail({ emailId, type, suppressAttachmentsFetching }: UseGetEmailProps) {
  const [attachments, setAttachments] = useState<EmailAttachmentsResult>({
    items: [],
    isLoading: !suppressAttachmentsFetching,
  });
  const [unifiedEmail, setUnifiedEmail] = useState<UnifiedEmail>();
  const updateQueryParam = useUpdateQueryParam();

  const {
    data: outboundEmail,
    isInitialLoading: isOutboundLoading,
    isError: isOutboundRetrieveError,
  } = useRetrieveSubmissionEmail({
    id: emailId || '',
    enabled: type === EmailItemType.SubmissionOutbound && !!emailId,
  });

  const {
    data: inboundEmail,
    isInitialLoading: isInboundLoading,
    isError: isInboundRetrieveError,
  } = useRetrieveInboundEmailMessage({
    id: emailId || '',
    enabled:
      [
        EmailItemType.SubmissionInbound,
        EmailItemType.UnassignedInbound,
        EmailItemType.SubmissionInboundFromUser,
      ].includes(type) && !!emailId,
  });

  const { items: users } = useSearchUserTeam();

  const boxClient = useBoxClient();

  const isEmailMessageLoading = isOutboundLoading || isInboundLoading;
  const isEmailLoadingError = isOutboundRetrieveError || isInboundRetrieveError;

  useEffect(() => {
    if (isEmailLoadingError) {
      updateQueryParam({
        removeParams: [BrokerUrlParams.SELECTED_EMAIL],
      });
    }
  }, [isEmailLoadingError, updateQueryParam]);

  useEffect(() => {
    const fetchAttachments = async ({ fileIds }: UnifiedEmail) => {
      const result = await Promise.all(fileIds.map((fileId) => boxClient.getFile(fileId, { includeMetadata: true })));
      const nonHiddenAttachments = compact(result).filter((item) => !getFileIsHidden(item));

      setAttachments({ items: nonHiddenAttachments, isLoading: false });
    };

    if (!isEmailMessageLoading && isMessageRetrieved(type, outboundEmail, inboundEmail)) {
      let normalizedEmail: UnifiedEmail;
      switch (type) {
        case EmailItemType.SubmissionOutbound:
          normalizedEmail = normalizeOutbound(outboundEmail!);
          break;
        case EmailItemType.SubmissionInbound:
        case EmailItemType.UnassignedInbound:
          normalizedEmail = normalizeInbound(inboundEmail!, type);
          break;
        case EmailItemType.SubmissionInboundFromUser:
        default:
          normalizedEmail = normalizeInboundFromUser(
            inboundEmail!,
            users.find((user) => user.email.toLocaleLowerCase() === inboundEmail!.sender?.toLocaleLowerCase()),
          );
          break;
      }

      setUnifiedEmail(normalizedEmail);
      if (!suppressAttachmentsFetching) {
        fetchAttachments(normalizedEmail);
      }
    }
  }, [boxClient, isEmailMessageLoading, outboundEmail, inboundEmail, type, users, suppressAttachmentsFetching]);

  return {
    unifiedEmail,
    attachments,
    isLoading: isEmailMessageLoading,
  };
}
