import { Tooltip, Typography } from '@common-components';
import { CitationType } from 'enums';
import useCitationLabel from 'broker/pages/SubmissionWorkspacePage/hooks/useCitationLabel';

interface AICitationNameProps {
  sourceId: string;
  sourceType: CitationType;
  title: string;
}

export const AICitationName = ({ sourceId, sourceType, title }: AICitationNameProps) => {
  const citationLabel = useCitationLabel(sourceType, sourceId);

  return (
    <Tooltip tooltipContent={title} placement="top-end">
      <Typography ml={0.5} component="span" variant="body2Bold">
        ({citationLabel})
      </Typography>
    </Tooltip>
  );
};
