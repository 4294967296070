import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { Features } from 'utils/features-config';
import { FeatureGate } from 'components/FeatureGate';
import FormEditor from 'components/hookFormComponents/FormEditor';
import { formFieldsConfig } from 'broker/dialogs/SettingsDialog/utils';
import EmailIntegrationToggle from './EmailIntegrationToggle';
import { EnableEmailIntegrationCallout } from './EnableEmailIntegrationCallout';

const { emailSettings } = messages.SettingsDialog;

interface EmailSettingsProps {
  integrationEnabled: boolean;
}

export default function EmailSettings({ integrationEnabled }: EmailSettingsProps) {
  return (
    <Stack gap={2}>
      <Typography variant="subtitle2">{emailSettings.title}</Typography>
      <FormEditor
        label={formFieldsConfig.signature.label}
        name={formFieldsConfig.signature.name}
        sxOverride={{ minHeight: 300 }}
        optional
        withSourceImageInfo
      />
      <FeatureGate feature={Features.EmailIntegration}>
        <Stack gap={1}>
          <Typography variant="body2Bold">{emailSettings.emailIntegration.title}</Typography>
          <Stack border={1} borderRadius={1} borderColor="grey.400" overflow="hidden">
            {!integrationEnabled && <EnableEmailIntegrationCallout />}
            <Stack gap={2} p={2}>
              <Typography variant="body2" color="text.secondary">
                {emailSettings.emailIntegration.description}
              </Typography>
              <EmailIntegrationToggle disabled={!integrationEnabled} />
            </Stack>
          </Stack>
        </Stack>
      </FeatureGate>
    </Stack>
  );
}
