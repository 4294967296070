import { Link } from 'react-router-dom-latest';
import { Launch as LaunchIcon } from '@icons';
import IconButton from './IconButton';
import { InputAdornment } from './mui-index';

export interface LinkAdornmentProps {
  to?: string;
}

export function LinkAdornment({ to }: LinkAdornmentProps) {
  if (to === undefined) {
    return null;
  }

  return (
    <InputAdornment position="end">
      <Link to={to}>
        <IconButton icon={LaunchIcon} variant="text" edge="end" color="secondary" />
      </Link>
    </InputAdornment>
  );
}
