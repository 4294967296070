import { noneOfTheAboveOption } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';

type HeraldParameterDefaultValue = string | string[] | number | number[];

// key:question id , value: default answer
export const heraldParametersDefaultValuesConfig: Record<string, HeraldParameterDefaultValue> = {
  rsk_7ahp_has_domain: 'yes',
  rsk_h8oi_high_risk_industry_type: [noneOfTheAboveOption],
  rsk_a18w_stored_records_type: [noneOfTheAboveOption],
  rsk_da0x_dual_authenticate_banking_detail_update: 'yes',
  rsk_tw5r_dual_authentication: 'For all requests',
  rsk_d6el_secure_backup: 'yes',
  rsk_glo9_secure_backup_frequency: 'Weekly or more',
  rsk_y8ve_secure_backup_offline: 'yes',
  rsk_64p7_data_encryption: [
    'De-identify sensitive data at rest',
    'Encrypt sensitive data at rest (including on laptops, computers, and other portable media devices)',
    'Encrypt sensitive data in emails sent to external parties',
  ],
  rsk_un9n_network_authentication: [
    'All web-based email accounts',
    'All remote access to the network including any remote desktop protocol connections',
    'Local and remote access to privileged user/network administrator accounts',
    'Internal and external access to cloud-based back-ups',
    'Mission critical systems',
  ],
  rsk_9bjm_content_review: 'yes',
  rsk_2pt5_has_parent_entity: 'no',
  rsk_a94l_do_24_12_months: 'no',
  rsk_ryx6_has_experienced_merger_and_acquisition: [noneOfTheAboveOption],
  rsk_wjbi_ml_past_future_material_changes: [noneOfTheAboveOption],
  rsk_ljip_change_in_voting_control_36_months: 'no',
  rsk_hdcl_has_employee_stock_plan: 'no',
  rsk_yh15_has_revenue_from_government: 'no',
  rsk_dc0u_anticipates_or_experienced_layoffs: 'no',
  rsk_gzu6_receives_employee_application: 'yes',
  rsk_e62k_publishes_employee_handbook: 'yes',
  rsk_8rqb_has_hr_department: 'yes',
  rsk_jb26_cyb_has_claims_history: 'no',
  rsk_ggy8_cyb_warranty: 'no',
  rsk_2aep_pl_has_claim_history: 'no',
  rsk_rvyh_pl_has_warranty: 'no',
  rsk_h2x6_has_pl_coverage: 'no',
  rsk_ir30_has_prior_civil_suit: 'no',
  rsk_rh5y_ml_has_claims_history_within_five_years: 'no',
  rsk_7i0r_ml_warranty_claim: 'no',
  rsk_w8j4_prior_eeoc_or_admin_proceeding: 'no',
  rsk_o3q9_content_complaints_past_three_years: 'no',
  rsk_8di2_offers_additional_services: 'no',
  rsk_q9am_has_privacy_claims: 'no',
  rsk_22b1_prior_coverage_changed: 'no',
  rsk_l9ap_seeking_coverage_for_affiliate: 'no',
  rsk_1pab_has_continuing_eduction: 'no',
  rsk_6i3e_has_investigation_from_federal_agency: 'no',
  rsk_0h8t_engages_in_cryptocurrency: 'no',
  rsk_su3n_option_to_invest: 'no',
  rsk_ot4r_benefit_reduction: 'no',
  rsk_c2ui_erisa_compliance: 'no',
  rsk_5iyn_plan_violation: 'no',
  rsk_un8m_delinquent_plan: 'no',
  rsk_7qec_anticipates_or_experienced_financial_restructing: 'no',
  rsk_3e4y_cancelled_nonrenew_ml_policy: 'no',
};
