import { InboundEmailMessage, InboundEmailMessageAssignToSubmission, InboundEmailMessageSearchItem } from 'types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export class InboundEmailMessageClient extends CapitolaClient<InboundEmailMessage, InboundEmailMessageSearchItem> {
  public get resourceName(): string {
    return 'inbound-email-message';
  }

  public async assignToSubmission(body: InboundEmailMessageAssignToSubmission) {
    const { submissionId, inboundEmailMessageId } = body;

    return this.custom<InboundEmailMessage>({
      httpMethod: HttpMethods.PATCH,
      path: `${inboundEmailMessageId}/assign-to-submission`,
      body: {
        submissionId,
      },
      fetchOptions: {
        throwOnError: true,
      },
    });
  }
}
