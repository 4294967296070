import { Chip, Stack } from '@common-components';
import { messages } from 'i18n';
import { PartialSubmission, SubmissionMarket } from 'types';
import { EmailItemType } from 'broker/components/Emails/types';
import { defaultEmailLabelsSelection } from './config';
import InboundEmailLabelsMenu from './InboundEmailLabelsMenu';
import MarketsMenu from './MarketsMenu';
import OutboundEmailLabelsMenu from './OutboundEmailLabelsMenu';
import { EmailListItemAttributes, isCustomerEmail } from './utils';

export interface EmailMenusProps {
  email: EmailListItemAttributes;
  emailType: EmailItemType;
  submission: PartialSubmission;
  markets: SubmissionMarket[];
}

export default function EmailMenus({ email, emailType, submission, markets }: EmailMenusProps) {
  const renderLabelsMenu = () => {
    if (emailType === EmailItemType.SubmissionOutbound) {
      return <OutboundEmailLabelsMenu email={email} selectableLabels={defaultEmailLabelsSelection} />;
    }

    return (
      <InboundEmailLabelsMenu
        submissionId={submission.id}
        email={email}
        selectableLabels={defaultEmailLabelsSelection}
      />
    );
  };

  return (
    <Stack direction="row" gap={0.5} maxWidth={1}>
      {renderLabelsMenu()}
      <MarketsMenu email={email} markets={markets} />
      {submission && isCustomerEmail(submission, email) && (
        <Stack flex={1}>
          <Chip label={messages.emailMenus.agent} size="small" color="secondary" />
        </Stack>
      )}
    </Stack>
  );
}
