import * as yup from 'yup';
import { ContactRole } from 'enums';
import { messages } from 'i18n';

const { requiredField, invalidEmail } = messages.formMessages;

export const schema = yup.object().shape({
  firstName: yup.string().trim().required(requiredField),
  lastName: yup.string().trim().required(requiredField),
  email: yup.string().trim().email(invalidEmail).required(requiredField),
  title: yup.string().trim(),
  phoneNumber: yup.string().trim(),
  organizationId: yup.string().trim().nullable().required(requiredField),
  contactRole: yup.string().trim().required(requiredField),
});

export interface FormData {
  firstName: string;
  lastName: string;
  organizationId: string;
  contactRole: ContactRole;
  email: string;
  phoneNumber: string;
  title: string;
}

export enum FormFieldsNames {
  FirstName = 'firstName',
  LastName = 'lastName',
  OrganizationId = 'organizationId',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  ContactRole = 'contactRole',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Title = 'title',
}
