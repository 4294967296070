import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import useGetSubmissionEmailsWithFileMetadata from './useGetSubmissionEmailsWithFileMetadata';

export const useGetEmailListItem = (emailId: string) => {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { submissionEmailsList } = useGetSubmissionEmailsWithFileMetadata({
    submissionId: partialSubmission?.id,
  });

  return submissionEmailsList.find((submissionEmail) => submissionEmail.id === emailId);
};
