export enum FileClassificationType {
    EAndOApplication = 'E&OApplication',
    RansomwareApplication = 'RansomwareApplication',
    RenewalApplication = 'RenewalApplication',
    ExpiringPolicy = 'ExpiringPolicy',
    LossRuns = 'LossRuns',
    Quote = 'Quote',
    Unclassified = 'Unclassified',
    // file classification was too similar to another classification category
    Inconclusive = 'Inconclusive',
}
