import { Stack } from '@common-components';
import { messages } from 'i18n';
import FormDatePicker from 'components/hookFormComponents/FormDatePicker';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { SuggestionProps } from 'components/hookFormComponents/types';
import { formFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/Screens/SubmissionStaticForm/StaticForm/utils/form-utils';

interface CoverageDetailsProps {
  appliedSuggestions?: Record<string, SuggestionProps>;
}

export default function CoverageDetails({ appliedSuggestions }: CoverageDetailsProps) {
  return (
    <Stack className="cap-submission-details-coverage-details">
      <FormTextField
        {...formFieldsConfig.limit}
        isNumberFormat
        numberFormatProps={{
          min: 1,
        }}
        fullWidth
        placeholder={messages.submissionModal.limitPlaceHolder}
      />

      <FormDatePicker
        {...formFieldsConfig.dueDate}
        fullWidth
        suggestion={appliedSuggestions?.[formFieldsConfig.dueDate.name]}
      />

      <FormDatePicker
        {...formFieldsConfig.needByDate}
        fullWidth
        suggestion={appliedSuggestions?.[formFieldsConfig.needByDate.name]}
      />
    </Stack>
  );
}
