import { OrganizationDomainClient } from 'clients';
import { QueryKeys } from 'enums';
// eslint-disable-next-line import/no-internal-modules
import { useToast } from 'hooks/useToast';
import { messages } from 'i18n';
import { OrganizationDomain, OrganizationDomainCreate } from 'types';
import { UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useQuerySearch } from './query/useQuery';
import { ResponseAsError } from './types';

export function useSearchOrganizationDomain(props?: UseQueryBaseSearchOptions<OrganizationDomain>) {
  const { filter, queryOptions, enabled, sorter } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.OrganizationDomains,
    clientClass: OrganizationDomainClient,
    filter,
    enabled,
    sorter,
    queryOptions: {
      ...queryOptions,
    },
  });
}

export function useMutateOrganizationDomain() {
  const { showToast } = useToast();
  const { destroy: deleteOrganizationDomain, create: createOrganizationDomain } = useMutate<
    OrganizationDomain,
    OrganizationDomain,
    OrganizationDomainCreate,
    OrganizationDomainClient
  >({
    queryKey: QueryKeys.OrganizationDomains,
    clientClass: OrganizationDomainClient,
    createOptions: { throwOnError: true, passthroughStatusCodes: [409] },
    updateOptions: { throwOnError: true, passthroughStatusCodes: [409] },
    onSuccess: (response: OrganizationDomain | null) => {
      const responseAsError = { ...response } as ResponseAsError;

      if (responseAsError.statusCode === 409) {
        // For a 409 error show a more meaningful message and throw so the usage can handle as any other error
        showToast('error', { message: messages.errors.duplicateDomain });
        throw new Error(responseAsError.message);
      }
    },
  });

  return { deleteOrganizationDomain, createOrganizationDomain };
}
