import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CoverageLine } from 'enums';
import { messages } from 'i18n';
import theme from 'themes';
import { getCoverageLinesText, getTimezoneLessDisplayDate, getTodayDisplayDate } from 'utils';
import { MarketingReportRow } from './utils';

const columnsConfig = [
  { header: messages.marketingReportPage.columnHeaders.marketName, dataKey: 'marketName' },
  { header: messages.marketingReportPage.columnHeaders.status, dataKey: 'status' },
  { header: messages.marketingReportPage.columnHeaders.coverageLines, dataKey: 'coverageLines' },
  { header: messages.marketingReportPage.columnHeaders.additionalInfo, dataKey: 'additionalInfo' },
];

export function generateBlobReport(
  rowsData: MarketingReportRow[],
  coverageLines: CoverageLine[],
  insuredName?: string,
  dueDate?: Date,
): Blob {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('landscape');
  doc.text(messages.marketingReportPage.marketingReport, 14, 15);
  doc.setFontSize(10);
  doc.setTextColor('gray');
  doc.text(getTodayDisplayDate(), 60, 15);
  doc.setFontSize(10);

  if (insuredName) {
    doc.text(`${messages.marketingReportPage.insured}:`, 14, 25);
    doc.setTextColor('black');
    doc.text(`${insuredName}`, 46, 25);
  }

  doc.setTextColor('gray');
  doc.text(`${messages.marketingReportPage.coverageLine}:`, 14, 31);
  doc.setTextColor('black');
  doc.text(`${getCoverageLinesText(coverageLines)}`, 46, 31);

  if (dueDate !== undefined) {
    doc.setTextColor('gray');
    doc.text(`${messages.marketingReportPage.dueDate}:`, 14, 37);
    doc.setTextColor('black');
    doc.text(`${getTimezoneLessDisplayDate(dueDate)}`, 46, 37);
  }

  autoTable(doc, {
    theme: 'grid',
    startY: 45,
    body: rowsData as any,
    columns: columnsConfig,
    columnStyles: {
      0: { cellWidth: 45 },
      1: { cellWidth: 45 },
      2: { cellWidth: 45 },
    },
    rowPageBreak: 'avoid',
    styles: { overflow: 'linebreak', cellPadding: 5 },
    headStyles: { fillColor: theme.palette.blue[700] },
    willDrawCell: (data) => {
      const singleRaw = data.row.raw as unknown as MarketingReportRow;
      if (singleRaw.id === singleRaw.marketName) {
        doc.setFillColor(199, 199, 199);
        doc.setFont('helvetica', 'bold');
      }
    },
  });
  return doc.output('blob');
}
