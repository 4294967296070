import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Divider, Stack, Typography } from '@common-components';
import { MailOutline as MailOutlineIcon, Search as SearchIcon } from '@icons';
import { usePagination, useSearchEmailTemplate } from 'hooks';
import { messages } from 'i18n';
import TablePagination from 'broker/components/common/TablePagination';
import EmailsListSkeleton from 'broker/components/skeletons/EmailsListSkeleton';
import { useCurrentSubmissionMarket } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import EmailListItem from './EmailListItem';
import Header from './Header';
import { useAutoSelectEmail } from './useAutoSelectEmail';
import useEmailsFilter from './useEmailsFilter';
import useGetSubmissionEmailsWithFileMetadata from './useGetSubmissionEmailsWithFileMetadata';
import { EmailDescriptor, EmailGroup, getEmailGroups, SortBy } from './utils';

interface EmailsListProps {
  selectedEmailId?: string;
  onEmailSelected: (selectedEmail: EmailDescriptor, auto?: boolean) => void;
  hideEmailActions?: boolean;
}

export default function EmailsList({ selectedEmailId, onEmailSelected, hideEmailActions = false }: EmailsListProps) {
  const { noEmailsYet, noResultsTitle, noResultsSubtitle } = messages.submissionWorkspace.emailsTab;

  const { partialSubmission } = useSubmissionsWorkspace();
  const submissionMarket = useCurrentSubmissionMarket();
  const { submissionEmailsList, isLoading } = useGetSubmissionEmailsWithFileMetadata({
    submissionId: partialSubmission?.id,
  });
  const { items: templates, isLoading: isTemplatesLoading } = useSearchEmailTemplate();

  const { page, setPage, rowsPerPage, setRowsPerPage, paginationReset } = usePagination();

  const [emails, setEmails] = useState(submissionEmailsList);

  const sortBy = useRef(SortBy.Date);

  const [emailGroupsSlice, setEmailGroupsSlice] = useState<EmailGroup[]>(
    getEmailGroups(submissionEmailsList, sortBy.current),
  );

  const selectEmail = useCallback(
    (selectedEmail: EmailDescriptor) => {
      if (selectedEmail.id !== selectedEmailId) {
        onEmailSelected(selectedEmail, true);
      }
    },
    [onEmailSelected, selectedEmailId],
  );

  useAutoSelectEmail(selectEmail, submissionEmailsList, isLoading);

  const {
    filteredEmails,
    search,
    setSearch,
    emailsFilters,
    isAllMarketsFilters,
    handleAllFiltersSelected,
    handleUncheckedFilter,
    handleFilterChange,
  } = useEmailsFilter({
    emails,
    paginationReset,
    isLoading,
    marketOrganizationId: submissionMarket?.marketOrganizationId,
  });

  const applyGrouping = useCallback(() => {
    const flattenedEmailGroups = _.flatten(
      getEmailGroups(filteredEmails, sortBy.current).map((emailGroup) => emailGroup.emails),
    );
    const slicedFlattenedEmailGroups = flattenedEmailGroups.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage);
    setEmailGroupsSlice(getEmailGroups(slicedFlattenedEmailGroups, sortBy.current));
  }, [filteredEmails, page, rowsPerPage, sortBy]);

  useEffect(() => {
    setEmails(submissionEmailsList);
  }, [submissionEmailsList, isLoading]);

  useEffect(() => {
    applyGrouping();
  }, [applyGrouping, filteredEmails, page, rowsPerPage]);

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const handleSortChange = (newSortBy: SortBy) => {
    sortBy.current = newSortBy;
    applyGrouping();
    paginationReset();
  };

  const noItems = () => {
    const Icon = search ? SearchIcon : MailOutlineIcon;
    const title = search ? noResultsTitle : noEmailsYet;
    return (
      <Stack flex={1} alignItems="center" justifyContent="center" gap={2}>
        <Icon sx={{ width: 24, height: 24, color: 'text.disabled' }} />
        <Stack alignItems="center" justifyContent="center">
          <Typography variant="body1Bold" color="text.disabled">
            {title}
          </Typography>
          {search && (
            <Typography variant="caption" color="text.disabled">
              {noResultsSubtitle}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack direction="column" flex={1} sx={{ overflowY: 'hidden', overflowX: 'hidden' }}>
      {isLoading ? (
        <EmailsListSkeleton />
      ) : (
        <>
          <Header
            search={search}
            setSearch={setSearch}
            submissionMarket={submissionMarket}
            hideEmailActions={hideEmailActions}
            marketsFilters={emailsFilters}
            isAllMarketsFilters={isAllMarketsFilters}
            handleFilterChange={(event, key) => handleFilterChange(event.target.checked, key)}
            handleAllFiltersSelected={handleAllFiltersSelected}
            handleUncheckedFilter={handleUncheckedFilter}
            handleSortBy={handleSortChange}
          />
          {emailGroupsSlice.length === 0 ? (
            noItems()
          ) : (
            <>
              <Stack
                flex={1}
                divider={<Divider sx={{ mt: '0px !important' }} />}
                sx={{ overflowY: 'auto', overflowX: 'hidden' }}
              >
                {emailGroupsSlice.map((emailGroup) => (
                  <Stack direction="column" gap={1} key={emailGroup.organizationId}>
                    {sortBy.current === SortBy.Organization && !!emailGroup.organizationName && (
                      <Typography variant="captionBold" color="text.secondary" pl={4} pt={1}>
                        {emailGroup.organizationName}
                      </Typography>
                    )}
                    <Stack direction="column" divider={<Divider variant="middle" />}>
                      {emailGroup.emails.map((email) => (
                        <EmailListItem
                          key={email.id}
                          item={email}
                          onEmailSelected={onEmailSelected}
                          searchWord={search}
                          hideEmailActions={hideEmailActions}
                          templates={templates}
                          isTemplatesLoading={isTemplatesLoading}
                        />
                      ))}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
              <Stack borderTop={1} borderColor="grey.300">
                <TablePagination
                  hideNumbers
                  from={page * rowsPerPage - rowsPerPage + 1}
                  to={page * rowsPerPage}
                  outOf={filteredEmails.length}
                  handleChange={handlePageChange}
                  count={Math.ceil(filteredEmails.length / rowsPerPage)}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  overrideContainerStyle={{ bgcolor: 'common.white' }}
                />
              </Stack>
            </>
          )}
        </>
      )}
    </Stack>
  );
}
