import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Chip, DroppableArea, IconButton, LoaderOverlay, Paper, Stack } from '@common-components';
import { Add, Close } from '@icons';
import { BoxTemplateLabels } from 'enums';
import { usePagination } from 'hooks';
import { useReFetchBoxItems, useSearchBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { softShadow } from 'themes';
import Filter, { FilterButtonTypes } from 'broker/components/common/Filter';
import TablePagination from 'broker/components/common/TablePagination';
import ToolboxItemHeader from 'broker/components/ToolboxItemHeader';
import Content from './components/Content';
import ContainedFileViewerModalWrapper from './components/Dialogs/ContainedFileViewerModalWrapper';
import { defaultFileTypesFilter } from './config';
import { useFileActionsCallbacks, useFilesFilter, useFilterAndSortFiles, useShowUploader } from './hooks';
import { componentId, ExtendedBoxItem, FileExplorerFilter, FilesExplorerProps } from './types';

export default function FilesExplorer(filesExplorerProps: FilesExplorerProps) {
  const {
    hideFilesConfig,
    folderId,
    title,
    subtitle,
    filter,
    visibleFileIds,
    selectionMode,
    selectedFiles,
    isLoadingAdditionalFileMetadata,
    fileListFooterContent,
    viewOnlyMode,
    labelsMetadata,
    onFileSelected,
    setSelectedFiles,
    syncSelectedFiles,
  } = filesExplorerProps;
  const { reFetchFileItems } = useReFetchBoxItems();

  const [isFileActionOccurring, setIsFileActionOccurring] = useState(false);

  const labelsListByMetadata: FileExplorerFilter = useMemo(
    () => ({
      fileType: defaultFileTypesFilter,
      marketId:
        labelsMetadata
          ?.find((label) => label.metadataFieldName === BoxTemplateLabels.MarketId)
          ?.options.map((option) => ({
            value: option.value,
            label: option.label,
          })) ?? [],
    }),
    [labelsMetadata],
  );

  const { items, isLoading: boxItemsLoading } = useSearchBoxItems({ filter: { chunkSize: 20, folderId } });
  const isLoading = boxItemsLoading || isLoadingAdditionalFileMetadata;

  useEffect(
    () => {
      if (selectedFiles && setSelectedFiles) {
        syncSelectedFiles?.(items);
      }
    },
    // This effect syncs the selected files box items, we only want it to run if items were re-fetched (for example file was renamed, label was changed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items],
  );

  const availableFiles = useFilterAndSortFiles({
    allItems: items,
    hideFilesConfig,
    visibleFileIds,
    ...filesExplorerProps,
  });
  const { page, setPage, rowsPerPage, setRowsPerPage, paginationReset } = usePagination();

  const {
    filteredFiles,
    handleFileFilterChange,
    fileFilters,
    isAllFileFilters,
    handleAllFileFilterSelected,
    search,
    setSearch,
    handleUncheckedFileFilter,
  } = useFilesFilter({
    files: availableFiles,
    initFilter: filter,
    filterFileTypes: labelsListByMetadata[BoxTemplateLabels.FileType]!,
    filterMarkets: labelsListByMetadata[BoxTemplateLabels.MarketId]!,
    paginationReset,
  });

  const filesSlice = filteredFiles.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage);
  const doesFolderHasFiles = !isLoading && availableFiles.length > 0;
  const isFolderEmpty = !isLoading && availableFiles.length === 0;

  const { uploaderShown, showUploader, setUploaderVisible, onCloseUploader, filesUploaderRef } = useShowUploader({
    isFolderEmpty,
  });
  const afterFileOperation = async () => {
    setIsFileActionOccurring(true);
    paginationReset();
    await reFetchFileItems();
    setIsFileActionOccurring(false);
  };

  const silentAfterFileOperation = async () => {
    await reFetchFileItems();
  };

  const { onLabelClicked } = useFileActionsCallbacks({
    afterFileOperation,
  });
  const onLabelUpdate = async (
    extendedBoxItem: ExtendedBoxItem,
    labelFieldName: BoxTemplateLabels,
    selectedLabel?: string,
  ) => {
    await onLabelClicked(extendedBoxItem, labelFieldName, selectedLabel);
    silentAfterFileOperation();
  };

  return (
    <DroppableArea disabled={viewOnlyMode} setIsDraggedOver={setUploaderVisible}>
      <Stack
        id={componentId}
        className={componentId}
        sx={{ height: 1, maxHeight: 1, color: 'common.black', overflow: 'hidden', position: 'relative' }}
      >
        {isFileActionOccurring ? <LoaderOverlay text={messages.fileExplorer.loadingFiles} /> : null}
        <ToolboxItemHeader
          title={title || messages.fileExplorer.title}
          subtitle={
            selectionMode ? `${subtitle || messages.fileExplorer.filesSelected(selectedFiles?.length ?? 0)}` : subtitle
          }
          mainActionArea={
            uploaderShown ? (
              <IconButton color="secondary" size="small" icon={Close} onClick={onCloseUploader} />
            ) : (
              <Button
                onClick={showUploader}
                startIcon={<Add fontSize="small" />}
                variant="contained"
                color="secondary"
                size="small"
              >
                {messages.fileExplorer.iconTooltips.addFiles}
              </Button>
            )
          }
          searchProps={{ search, setSearch, searchPlaceholder: messages.fileExplorer.searchPlaceHolder }}
          hideSearchSection={uploaderShown}
          arrangingArea={
            <Filter
              buttonType={FilterButtonTypes.FilterIcon}
              filterIconTooltipText={messages.fileExplorer.iconTooltips.filterByLabels}
              filters={fileFilters}
              handleFilterChange={handleFileFilterChange}
              isAllFilters={isAllFileFilters}
              handleAllSelected={handleAllFileFilterSelected}
              search
            />
          }
        />
        {fileFilters.some((label) => label.checked) && (
          <Stack direction="row" sx={{ p: 2, pt: 0, gap: 0.5, flexWrap: 'wrap', maxHeight: 64, overflowY: 'auto' }}>
            {fileFilters
              .filter((label) => label.checked)
              .map((label) => (
                <Box key={label.key}>
                  <Chip
                    rounded
                    label={label.label}
                    onClick={() => handleUncheckedFileFilter(label.key)}
                    size="medium"
                    endIcon={Close}
                    variant="contained"
                    color="secondary"
                  />
                </Box>
              ))}
          </Stack>
        )}

        <Stack flex={1} gap={0} justifyContent="start" overflow="hidden" sx={{ scrollbarWidth: 'none' }}>
          <Content
            isLoading={isLoading}
            search={search}
            filesSlice={filesSlice}
            doesFolderHasFiles={doesFolderHasFiles}
            uploaderShown={uploaderShown}
            isFileActionOccurring={isFileActionOccurring}
            setIsFileActionOccurring={setIsFileActionOccurring}
            filesUploaderRef={filesUploaderRef}
            ownFilter={labelsListByMetadata}
            afterFileOperation={afterFileOperation}
            silentAfterFileOperation={silentAfterFileOperation}
            showUploader={showUploader}
            onCloseUploader={onCloseUploader}
            onFileSelected={onFileSelected}
            onLabelUpdate={onLabelUpdate}
            labelsMetadata={labelsMetadata}
            viewOnlyMode={viewOnlyMode}
            {...filesExplorerProps}
          />
        </Stack>
        <Stack borderTop={1} borderColor="grey.300">
          {doesFolderHasFiles && !uploaderShown && (
            <TablePagination
              hideNumbers
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              overrideContainerStyle={{ background: 'transparent' }}
              from={page * rowsPerPage - rowsPerPage + 1}
              to={page * rowsPerPage}
              outOf={filteredFiles.length}
              handleChange={(value: number) => {
                setPage(value);
              }}
              count={Math.ceil(filteredFiles.length / rowsPerPage)}
              page={page}
            />
          )}
          {fileListFooterContent && (
            <Stack
              p={2}
              component={Paper}
              boxShadow={softShadow}
              direction="row"
              justifyContent="center"
              alignItems="center"
              bgcolor="transparent"
            >
              {fileListFooterContent}
            </Stack>
          )}
        </Stack>
      </Stack>
      <ContainedFileViewerModalWrapper
        selectionMode={selectionMode}
        viewOnlyMode={viewOnlyMode}
        items={items}
        afterFileOperation={afterFileOperation}
        onFileSelected={onFileSelected}
        selectedFiles={selectedFiles}
        labelsMetadata={labelsMetadata}
        onLabelUpdate={onLabelUpdate}
      />
    </DroppableArea>
  );
}
