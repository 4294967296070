import { Badge, Box, Button, Stack, Typography } from '@common-components';
import { AssistantOutlined as AssistantOutlinedIcon, WarningAmber as WarningAmberIcon } from '@icons';
import { EmailTemplateType, NylasAuthenticationStatus, UserRole } from 'enums';
import { NylasAuthTrigger, useCurrentUser, useHotjar, useRetrieveNylasAuthenticationStatus } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { HotjarEvents } from 'utils/hotjar-events';
import { useNylasAuth } from 'broker/hooks';
import { useRevokeNylasGrant } from 'broker/hooks/useRevokeNylasGrant';
import { useEmailIntegrationBanner } from './useEmailIntegrationBanner';
import { useIsIntegrationEnabled } from './useIsIntegrationEnabled';

interface AuthenticationStatusInfo {
  icon: JSX.Element;
  message: string;
  description: string;
  bgColor: string;
  textColor: string;
}

const { emailIntegrationBanner } = messages.submissionWorkspace;

const authenticationStatusMap: Partial<Record<NylasAuthenticationStatus, AuthenticationStatusInfo>> = {
  [NylasAuthenticationStatus.UnAuthenticated]: {
    icon: <AssistantOutlinedIcon fontSize="small" sx={{ color: 'common.white' }} />,
    message: emailIntegrationBanner.unauthenticated.message,
    description: emailIntegrationBanner.unauthenticated.description,
    bgColor: 'purple.700',
    textColor: 'common.white',
  },
  [NylasAuthenticationStatus.InvalidGrant]: {
    icon: <WarningAmberIcon fontSize="small" sx={{ color: 'typography.contrast' }} />,
    message: emailIntegrationBanner.invalidToken.message,
    description: emailIntegrationBanner.invalidToken.description,
    bgColor: 'warning.main',
    textColor: 'text.primary',
  },
};

export interface EmailIntegrationBannerProps {
  submission: PartialSubmission | null;
  emailTemplateType?: EmailTemplateType;
}

export function EmailIntegrationBanner({ submission, emailTemplateType }: EmailIntegrationBannerProps) {
  const hotjar = useHotjar();
  const { me } = useCurrentUser();

  const isIntegrationEnabled = useIsIntegrationEnabled();
  const { data: nylasUser } = useRetrieveNylasAuthenticationStatus({
    enabled: isIntegrationEnabled,
  });

  const { triggerAuthFlow, isLoadingAuthUrl } = useNylasAuth(NylasAuthTrigger.EmailIntegrationBanner);
  const { revokeNylasGrant, isLoading: isLoadingRevokeGrant } = useRevokeNylasGrant();

  // The integration banner is shown to both backoffice and end-users depending on email type and co-pilot status.
  const { shouldShowIntegrationBanner } = useEmailIntegrationBanner({ submission, emailTemplateType });

  const authStatus = nylasUser?.authenticationStatus;
  const isConnected = authStatus === NylasAuthenticationStatus.Authenticated;

  if (!authStatus || isConnected || !authenticationStatusMap[authStatus] || !shouldShowIntegrationBanner) {
    return null;
  }

  const { bgColor, icon, textColor, message, description } = authenticationStatusMap[authStatus]!;

  const handleDontConnectClick = async () => {
    hotjar.event(HotjarEvents.EmailIntegrationDontConnect);
    await revokeNylasGrant();
  };

  const handleConnectClick = () => {
    hotjar.event(HotjarEvents.EmailIntegrationConnect);
    triggerAuthFlow();
  };

  return (
    <Stack direction="row" bgcolor={bgColor} p={1} justifyContent="space-between">
      <Stack direction="row" gap={1}>
        <Badge>{icon}</Badge>
        <Stack direction="column">
          <Typography color={textColor} variant="body2">
            {message}
          </Typography>
          <Typography color={textColor} variant="caption">
            {description}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap={3}>
        {authStatus === NylasAuthenticationStatus.InvalidGrant && (
          <Box color={textColor}>
            <Button variant="text" color="inherit" loading={isLoadingRevokeGrant} onClick={handleDontConnectClick}>
              {emailIntegrationBanner.dontConnectButton}
            </Button>
          </Box>
        )}
        <Box color={textColor}>
          {me?.role !== UserRole.BackOffice && (
            <Button variant="outlined" color="inherit" loading={isLoadingAuthUrl} onClick={handleConnectClick}>
              {emailIntegrationBanner.connectButton}
            </Button>
          )}{' '}
        </Box>
      </Stack>
    </Stack>
  );
}
