import {
  MenuItem,
  Pagination,
  paginationClasses,
  paginationItemClasses,
  Select,
  selectClasses,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@common-components';
import { RowsPerPage } from 'hooks';
import { messages } from 'i18n';
import { uniteStyles } from 'utils';

interface TablePaginationProps {
  from: number;
  to: number;
  count: number;
  outOf: number;
  page: number;
  handleChange: (value: number) => void;
  hideNumbers?: boolean;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  setPage: (rowsPerPage: number) => void;
  overrideContainerStyle?: SxProps<Theme>;
}

export default function TablePagination({
  from,
  to,
  outOf,
  page,
  setPage,
  count,
  handleChange,
  rowsPerPage,
  setRowsPerPage,
  hideNumbers = false,
  overrideContainerStyle = {},
}: TablePaginationProps) {
  return outOf > rowsPerPage ? (
    <Stack
      p={2}
      direction="row"
      gap={2}
      justifyContent="space-between"
      alignItems="center"
      sx={uniteStyles(
        {
          background: 'transparent',
          ...(hideNumbers && {
            [`& .${paginationClasses.ul} li:not(:first-of-type):not(:last-child)`]: {
              display: 'none',
            },
          }),
          [`& .${paginationItemClasses.previousNext}`]: {
            bgcolor: 'action.selected',
            '&:hover': {
              bgcolor: 'action.hover',
            },
          },
        },
        overrideContainerStyle,
      )}
    >
      <Stack direction="row" alignItems="baseline" gap={1}>
        <Typography noWrap variant="body2">
          {messages.pagination.rowsPerPage}
        </Typography>
        <Select
          disableUnderline
          labelId="rows-per-page"
          id="rows-per-page"
          value={rowsPerPage.toString()}
          label="rows per page"
          onChange={(event: any) => {
            setPage(1);
            setRowsPerPage(Number(event.target.value));
          }}
          variant="standard"
          sx={{
            [`& .${selectClasses.select}:focus`]: {
              backgroundColor: 'transparent',
            },
            [`& .${selectClasses.icon}`]: {
              color: 'common.black',
            },
            fontSize: '0.875rem',
            fontWeight: '600',
          }}
        >
          <MenuItem value={RowsPerPage.Ten}>{RowsPerPage.Ten}</MenuItem>
          <MenuItem value={RowsPerPage.Twenty}>{RowsPerPage.Twenty}</MenuItem>
          <MenuItem value={RowsPerPage.Fifty}>{RowsPerPage.Fifty}</MenuItem>
        </Select>
      </Stack>

      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="body2" noWrap>
          {outOf > 0 ? from : 0} - {to > outOf ? outOf : to} of {outOf}
        </Typography>
        <Pagination
          sx={{ ul: { flexWrap: 'nowrap' } }}
          shape="circular"
          count={count}
          page={page}
          onChange={(_, value) => handleChange(value)}
        />
      </Stack>
    </Stack>
  ) : null;
}
