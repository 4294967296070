import { BoxItem } from 'box-ui-elements/es';
import { ReactElement, useEffect, useState } from 'react';
import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { ConfirmationDialogGuard, ConfirmationDialogGuardAttributes } from 'broker/components/ConfirmationDialogGuard';

interface BrokenAttachmentsGuardProps {
  attachedFiles: BoxItem[];
  onSend: () => void;
  children: (props: ConfirmationDialogGuardAttributes) => ReactElement;
}
export const BrokenAttachmentsGuard = ({ attachedFiles, onSend, children }: BrokenAttachmentsGuardProps) => {
  const [brokenAttachedFiles, setBrokenAttachedFiles] = useState<BoxItem[]>([]);

  useEffect(() => {
    setBrokenAttachedFiles(attachedFiles.filter((file) => file.size === 0));
  }, [attachedFiles]);

  function getMessage() {
    return (
      <Stack gap={1}>
        <Typography variant="body1">
          {messages.emailEditor.sendMessagesGuards.brokenAttachments(brokenAttachedFiles.length)}
        </Typography>
        <ul>
          {brokenAttachedFiles.map((file) => (
            <li key={file.id} style={{ paddingBottom: '8px' }}>
              {file.name}
            </li>
          ))}
        </ul>
      </Stack>
    );
  }

  return (
    <ConfirmationDialogGuard
      title={messages.emailEditor.sendMessagesGuards.brokenAttachmentsTitle}
      message={getMessage()}
      action={onSend}
      showConfirmation={brokenAttachedFiles.length > 0}
      proceedButton={{ children: messages.emailEditor.sendMessagesGuards.sendAnyway }}
    >
      {({ guardedAction }) => children({ guardedAction })}
    </ConfirmationDialogGuard>
  );
};
