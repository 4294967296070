import { ModalProvider } from './dialogs/modalProvider/modalProvider';
import Main from './Main';
import { UiStoreProvider } from './ui-store/uiStoreProvider';

export default function SubmissionWorkspacePage() {
  return (
    <ModalProvider>
      <UiStoreProvider>
        <Main />
      </UiStoreProvider>
    </ModalProvider>
  );
}
