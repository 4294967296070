import { forwardRef, ReactNode, useEffect } from 'react';
import { Box, Stack } from '@common-components';
import VerticalFormStepper from './VerticalFormStepper';
import { MainAreaDimensionsState } from './VerticalFormStepper/types';

interface FormWithStepperProps {
  sectionProps: {
    sectionList: {
      label: string;
      subLabel?: string;
      id: string;
      hasError?: boolean;
      hasRequired?: boolean;
      isCompleted?: boolean;
    }[];
    activeSection?: string;
    setActiveSection: (activeSection?: any) => void; // Ensure proper typing here
  };
  children: ReactNode;
  mainAreaDimensionsState?: MainAreaDimensionsState;
  withPadding?: boolean;
}

// Use forwardRef to properly handle the scrollable container ref
export const FormWithStepper = forwardRef<HTMLDivElement, FormWithStepperProps>(
  (
    {
      sectionProps: { sectionList, activeSection, setActiveSection },
      children,
      mainAreaDimensionsState = MainAreaDimensionsState.Large,
      withPadding,
    },
    ref,
  ) => {
    useEffect(() => {
      // If no active section, default to the first one
      if (!activeSection && sectionList?.length) {
        setActiveSection(sectionList[0].id);
      }
    }, [activeSection, sectionList, setActiveSection]);

    return (
      <Stack direction="row" width={1} flex={1} overflow="hidden" px={withPadding ? 4 : 0} pb={2}>
        {/* Stepper on the side */}
        <VerticalFormStepper
          steps={sectionList}
          activeStep={sectionList.findIndex((section) => section.id === activeSection)}
          onClick={(index) => setActiveSection(sectionList[index].id)}
          mainAreaDimensionsState={mainAreaDimensionsState}
          sx={{ flex: 1 }}
        />

        {/* The scrollable form area */}
        <Stack
          flex={{
            xs: [MainAreaDimensionsState.Medium, MainAreaDimensionsState.Small].includes(mainAreaDimensionsState)
              ? 10
              : 2,
            sm: [MainAreaDimensionsState.Medium, MainAreaDimensionsState.Small].includes(mainAreaDimensionsState)
              ? 10
              : 3,
            xl: 3,
          }}
        >
          {/* Forwarding the ref to this scrollable container */}
          <Stack
            px={2}
            ref={ref}
            onScroll={() => {
              const element = document.getElementsByClassName('pac-container')[0] as HTMLElement;
              if (element) {
                element.style.display = 'none'; // Hide Google address suggestions on scroll
              }
            }}
            sx={{ overflowY: 'auto' }}
            width={1}
            maxWidth={960}
            mx="auto"
            height={1}
          >
            {children}
          </Stack>
        </Stack>
        <Box flex={1} />
      </Stack>
    );
  },
);

export default FormWithStepper;
