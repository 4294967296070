import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { Layer, Quote } from 'types';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { QuoteInfo } from './QuoteInfo';
import { SectionLabel } from './SectionLabel';

interface QuoteDetailsProps {
  quote: Quote;
  layer: Layer;
}

function PrimaryQuoteInfoView({ primaryQuote }: { primaryQuote?: string }) {
  return (
    primaryQuote && (
      <Stack gap={1}>
        <SectionLabel label={messages.quoteDisplayLabels.underlyingPrimaryQuote} />
        <Typography border={1} borderColor="divider" p={2} borderRadius={1} variant="body2">
          {primaryQuote}
        </Typography>
      </Stack>
    )
  );
}

export default function QuoteDetails({ quote, layer }: QuoteDetailsProps) {
  const { quotes } = useSubmissionsWorkspace();
  const primaryQuote = quotes.find((q) => q.id === quote.primaryQuoteId)?.marketName;

  return (
    <Stack overflow="auto" height={1} px={1} py={2} gap={4}>
      <QuoteInfo quote={quote} layer={layer} />
      <PrimaryQuoteInfoView primaryQuote={primaryQuote} />
    </Stack>
  );
}
