import { ReactElement, ReactNode } from 'react';
import { Collapse, Divider, Icon, IconButton, Stack, Tooltip, Typography } from '@common-components';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  SvgIconComponent,
} from '@icons';
import { SubmissionTask, SubmissionTaskStatus } from 'enums';
import { useBoolean, useGetParam } from 'hooks';
import { messages } from 'i18n';
import { truncateMultiLineText } from 'utils';
import { BrokerUrlParams } from 'broker/broker-routes';
import { submissionTaskStatusConfig } from 'broker/configuration-mappers/submission-task-status-config';
import { DropdownMenuItems } from 'broker/pages/SubmissionWorkspacePage/components/common/MarketRequestItem/types';
import StatusBadge from 'broker/pages/SubmissionWorkspacePage/components/StatusBadge';
import { TaskAction } from './types';

type MissingAlertType = {
  status: 'warning' | 'error';
  title: string;
  content: {
    title: string;
    subtitle?: string;
    items: string[];
  }[];
};

interface TaskItemProps {
  task: SubmissionTask;
  status: SubmissionTaskStatus;
  handleStatusChange: (task: SubmissionTask, status: SubmissionTaskStatus) => Promise<void>;
  actions?: TaskAction[];
  content?: (isOpen: boolean) => ReactElement;
  contentTooltip?: string;
  titleStartIcon?: SvgIconComponent;
  missingAlert?: MissingAlertType;
  extraContent?: ReactNode;
}

const calculateTotalItems = (missingAlert: MissingAlertType): number =>
  missingAlert.content.reduce((total, contentItem) => total + contentItem.items.length, 0);

export function TaskItem({
  task,
  status,
  handleStatusChange,
  actions,
  content,
  contentTooltip = '',
  titleStartIcon,
  missingAlert,
  extraContent,
}: TaskItemProps) {
  const activePreMarketingSection = useGetParam(BrokerUrlParams.ACTIVE_PRE_MARKETING_SECTION);
  const [isContentOpen, { toggle: toggleContent }] = useBoolean(activePreMarketingSection === task);
  const totalItems = missingAlert ? calculateTotalItems(missingAlert) : 0;
  const statusesDropdownMenuItems = (disableStatus: SubmissionTaskStatus): DropdownMenuItems[] =>
    Object.values(SubmissionTaskStatus).map((taskStatus) => ({
      label: submissionTaskStatusConfig[taskStatus].text.toUpperCase(),
      color: submissionTaskStatusConfig[taskStatus].color,
      disabled: disableStatus === taskStatus,
      onClick: () => handleStatusChange(task, taskStatus),
    }));

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        gap={3}
        pr={3}
        border={1}
        borderBottom={isContentOpen ? 'none' : 1}
        borderColor="blueGrey.200"
        borderRadius={isContentOpen ? '4px 4px 0 0' : 1}
        boxShadow={
          isContentOpen ? '0px 4px 4px 0px rgba(3, 28, 23, 0.1),0px 1px 12px 0px rgba(3, 28, 23, 0.06)' : 'none'
        }
        zIndex={2}
      >
        <Stack width={180} alignSelf="stretch" alignItems="flex-start">
          <StatusBadge
            title={submissionTaskStatusConfig[status].text}
            color={submissionTaskStatusConfig[status].color}
            dropdownMenuItems={statusesDropdownMenuItems(status)}
          />
        </Stack>
        <Stack gap={1} flex={1} py={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            {titleStartIcon && (
              <Stack>
                <Icon component={titleStartIcon} sx={{ width: 16, height: 16, color: 'text.primary' }} />
              </Stack>
            )}
            <Typography variant="body1Bold" color="text.primary" sx={{ ...truncateMultiLineText(1) }}>
              {messages.submissionWorkspace.preMarketing.tasks[task].title}
            </Typography>
          </Stack>
          <Typography variant="caption" color="text.secondary" sx={{ ...truncateMultiLineText(2) }}>
            {messages.submissionWorkspace.preMarketing.tasks[task].description}
          </Typography>
          {extraContent}

          {missingAlert && totalItems > 0 && (
            <Tooltip
              tooltipContent={
                <Stack alignItems="flex-start" textAlign="start" p={1} gap={1.5} pr={4}>
                  <Typography variant="h5" color="text.primary">
                    {missingAlert.title} ({totalItems})
                  </Typography>
                  <Stack divider={<Divider flexItem />} px={1} gap={2}>
                    {missingAlert.content.map((contentItem) => {
                      if (contentItem.items.length === 0) {
                        return null;
                      }

                      return (
                        <Stack key={contentItem.title} alignItems="flex-start">
                          {missingAlert.content.length > 1 && (
                            <>
                              <Typography variant="captionBold" color="text.primary">
                                {contentItem.title} ({contentItem.items.length})
                              </Typography>
                              {contentItem.subtitle && (
                                <Typography variant="caption" color="text.secondary">
                                  {contentItem.subtitle}
                                </Typography>
                              )}
                            </>
                          )}
                          <Typography mt={1} variant="body2" color="text.primary">
                            {contentItem.items.join(', ')}.
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Stack>
              }
              placement="right"
            >
              <Stack
                position="absolute"
                top={8}
                right={8}
                direction="row"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bgcolor={missingAlert.status === 'warning' ? 'warning.main' : 'error.main'}
                width={20}
                height={20}
                gap={1}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <Typography
                  color={missingAlert.status === 'warning' ? 'common.black' : 'common.white'}
                  variant="captionBold"
                >
                  {totalItems}
                </Typography>
              </Stack>
            </Tooltip>
          )}
        </Stack>
        <Stack direction="row" ml={1} gap={1}>
          {actions?.map((action) => (
            <Stack key={action.task} alignItems="flex-end" justifyContent="center">
              <IconButton
                color="secondary"
                icon={action.icon}
                onClick={action.onClick}
                tooltipContent={action.tooltip}
              />
            </Stack>
          ))}
          {content && (
            <IconButton
              icon={isContentOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
              onClick={toggleContent}
              color="inherit"
              tooltipContent={contentTooltip}
            />
          )}
        </Stack>
      </Stack>
      {content && (
        <Collapse in={isContentOpen} orientation="vertical" mountOnEnter>
          <Stack
            border={1}
            borderColor="blueGrey.200"
            borderRadius="0 0 4px 4px"
            borderTop="none"
            bgcolor="blueGrey.50"
            zIndex={1}
          >
            {content(isContentOpen)}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
}
