import { Container, LoaderOverlay, Stack, Typography } from '@common-components';
import { CheckCircleOutline as CheckCircleOutlineIcon, Dangerous } from '@icons';
import { messages } from 'i18n';
import { layoutShadow } from 'themes';
import { useCreateNylasToken } from './useCreateNylasToken';

interface MessageContent {
  title?: string;
  subtitle?: string;
  icon: JSX.Element;
}

const getMessageContent = (error?: string): MessageContent => {
  if (error) {
    return {
      subtitle: error,
      icon: <Dangerous color="error" sx={{ fontSize: 50 }} />,
    };
  }

  const { title, subtitle } = messages.nylasAuthentications.postAuthentication;

  return {
    title,
    subtitle,
    icon: <CheckCircleOutlineIcon color="secondary" sx={{ fontSize: 50 }} />,
  };
};

export function NylasPostAuthenticationPage() {
  const { isTokenCreated, error } = useCreateNylasToken();

  const renderContent = () => {
    if (!isTokenCreated && !error) {
      return <LoaderOverlay />;
    }

    const { icon, title, subtitle } = getMessageContent(error);

    return (
      <Stack
        alignItems="center"
        gap={5}
        width={499}
        boxShadow={layoutShadow}
        sx={{ backgroundColor: 'common.white', borderRadius: 4, padding: 5 }}
      >
        {icon}
        <Stack gap={2} textAlign="center">
          {title && <Typography variant="h3">{title}</Typography>}
          <Typography variant="body1" color="text.secondary">
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        backgroundColor: 'blueGrey.100',
        zIndex: 'modal',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {renderContent()}
    </Container>
  );
}
