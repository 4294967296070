import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GridSelectionModel } from '@common-components';
import { SubmissionMarketRequestStatus } from 'enums';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { submissionMarketStatusReportConfig } from './market-status-config-for-report';
import { MarketingReportMarketRequest, submissionMarketsToMarketingReportItems } from './utils';

type MarketRequestsByStatus = Record<number, Array<MarketingReportMarketRequest>>;

interface useSelectionModelProps {
  isInformationRequestsReport?: boolean;
}

export const useMarketingReportData = ({ isInformationRequestsReport }: useSelectionModelProps) => {
  const { markets, updateSubmissionMarketRequest } = useSubmissionsWorkspace();
  const [marketRequestsByStatus, setMarketRequestsByStatus] = useState<MarketRequestsByStatus>({});

  const marketRequests: MarketingReportMarketRequest[] = useMemo(
    () =>
      submissionMarketsToMarketingReportItems(markets).filter(
        (marketRequest) =>
          !isInformationRequestsReport || marketRequest.status === SubmissionMarketRequestStatus.PendingCustomer,
      ),
    [isInformationRequestsReport, markets],
  );

  const getPriorityByStatus = useCallback(
    (status: SubmissionMarketRequestStatus) => submissionMarketStatusReportConfig[status].sortPriority,
    [],
  );

  useEffect(() => {
    setMarketRequestsByStatus(
      _.groupBy(marketRequests, ({ status }) => submissionMarketStatusReportConfig[status].sortPriority),
    );
  }, [marketRequests]);

  const saveSelectedStateToServer = async (selectionModels: GridSelectionModel[]) => {
    const selectedStatusMarketRequests: { id: string; isInMarketingReport: boolean }[] = [];
    _.forEach(marketRequestsByStatus, (statusMarketRequests: MarketingReportMarketRequest[]) => {
      _.forEach(statusMarketRequests, (marketRequest) => {
        if (selectionModels[getPriorityByStatus(statusMarketRequests[0].status)].includes(marketRequest.id!)) {
          selectedStatusMarketRequests.push({
            id: marketRequest.id!,
            isInMarketingReport: true,
          });
        } else {
          selectedStatusMarketRequests.push({
            id: marketRequest.id!,
            isInMarketingReport: false,
          });
        }
      });
    });
    const allInitSelectedStatus = marketRequests.map((marketRequest) => ({
      id: marketRequest.id,
      isInMarketingReport: marketRequest.isInMarketingReport,
    }));

    const changedMarketRequests = _.differenceWith(allInitSelectedStatus, selectedStatusMarketRequests, _.isEqual);

    await Promise.all(
      changedMarketRequests.map(async (marketRequest) => {
        await updateSubmissionMarketRequest(marketRequest.id!, {
          isInMarketingReport: !marketRequest.isInMarketingReport,
        });
      }),
    );

    return changedMarketRequests.length;
  };

  return {
    marketRequestsByStatus,
    saveSelectedStateToServer,
    getPriorityByStatus,
  };
};
