import { messages } from 'i18n';
import { ConfirmationDialog } from 'components/Dialog';

interface SettingsDialogProps {
  closeSetUserDialog: () => void;
  isSetUserDialogOpen: boolean;
  onSubmit: () => void;
  usersEmail: string;
}

export default function SetUserDialog({
  closeSetUserDialog,
  isSetUserDialogOpen,
  onSubmit,
  usersEmail,
}: SettingsDialogProps) {
  const { title, header } = messages.setEndUserDialog;
  const { back, yes } = messages.buttons;

  return (
    <ConfirmationDialog
      title={title}
      message={`${header} ${usersEmail}`}
      isOpen={isSetUserDialogOpen}
      onCancel={closeSetUserDialog}
      onSubmit={onSubmit}
      proceedButton={{
        children: yes,
      }}
      cancelButton={{
        children: back,
      }}
    />
  );
}
