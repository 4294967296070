import { Navigate } from 'react-router-dom-latest';
import { BROKER_SITE_ROLES, PORTAL_SITE_ROLES, UserRole } from 'enums';
import { useCurrentUser } from 'hooks';
import { User } from 'types';
import { BROKER_ROUTES } from 'broker/broker-routes';

function getPath({ role }: User) {
  if (role === UserRole.CapitolaBroker) {
    return `${BROKER_ROUTES.SUBMISSION_BOX}`;
  }

  if (PORTAL_SITE_ROLES.includes(role)) {
    return `${BROKER_ROUTES.PORTAL}`;
  }

  if (BROKER_SITE_ROLES.includes(role)) {
    return UserRole.Broker.toLowerCase();
  }
  return role.toLowerCase();
}

export default function DefaultLoggedInRedirect() {
  const { endUser } = useCurrentUser();

  if (endUser === null) {
    // should never happen due to ProtectedRoute
    return null;
  }

  const path = `/${getPath(endUser)}`;

  return <Navigate to={path} />;
}
