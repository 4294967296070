import { useCallback } from 'react';
import { useHotjar } from 'hooks';
import { HotjarEvents } from 'utils/hotjar-events';
import { BrokerUrlParams } from 'broker/broker-routes';
import { ExtendedBoxItem } from 'broker/components/FilesExplorer/types';
import { useFileActions, useUpdateQueryParam } from 'broker/hooks';

interface FileActionProps {
  afterFileOperation: () => Promise<void>;
  selectedFiles?: ExtendedBoxItem[];
  setSelectedFiles?: (boxItems: ExtendedBoxItem[]) => void;
}

export function useFileActionsCallbacks({ afterFileOperation, selectedFiles, setSelectedFiles }: FileActionProps) {
  const hotjar = useHotjar();
  const updateQueryParam = useUpdateQueryParam();
  const { deleteFile, downloadFile, renameFile, editFileLabel } = useFileActions();

  const removeSelectedFileParam = useCallback(() => {
    updateQueryParam({
      removeParams: [BrokerUrlParams.SELECTED_FILE],
    });
  }, [updateQueryParam]);

  const onDeleteClicked = useCallback(
    async (item: ExtendedBoxItem) => {
      await deleteFile(item);
      if (selectedFiles && setSelectedFiles) {
        setSelectedFiles(selectedFiles.filter((selectedFile) => selectedFile.id !== item.id));
      }
      removeSelectedFileParam();
      // don't await files refetch in order to proceed after delete succeeded, it redirects back to files explorer main screen and a loaderOverlay will be displayed until files are re-fetched
      afterFileOperation();
    },
    [afterFileOperation, deleteFile, removeSelectedFileParam, selectedFiles, setSelectedFiles],
  );

  const onRenameSubmit = useCallback(
    async (item: ExtendedBoxItem, newName: string) => {
      await renameFile(item, newName);
      // don't await files refetch in order to proceed after rename succeeded, it redirects back to files explorer main screen and a loaderOverlay will be displayed until files are re-fetched
      afterFileOperation();
    },
    [afterFileOperation, renameFile],
  );

  const onPreviewClicked = useCallback(
    (file: ExtendedBoxItem) => {
      hotjar.event(HotjarEvents.PreviewFile);
      updateQueryParam({
        addParams: [
          {
            queryParam: BrokerUrlParams.SELECTED_FILE,
            value: file.id,
          },
        ],
      });
    },
    [hotjar, updateQueryParam],
  );

  return {
    onDeleteClicked,
    onRenameSubmit,
    onDownloadClicked: downloadFile,
    onPreviewClicked,
    onLabelClicked: editFileLabel,
  };
}
