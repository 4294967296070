import { ReactNode } from 'react';
import { LoaderOverlay, Typography } from '@common-components';
import { HeraldApplicationResponse } from 'clients/types';
import { useRetrieveHeraldApplication, useSearchInsuranceProduct } from 'hooks';
import { messages } from 'i18n';
import { InsuranceProduct } from 'types'; // Common types
import { isDynamicApplication } from 'utils';
import { SelectedProductsMap } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/types';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

interface ProductsWrapperProps {
  children: (props: {
    heraldApplicationResponse?: HeraldApplicationResponse;
    initSelectedProducts: SelectedProductsMap;
    products: InsuranceProduct[];
    isLoading: boolean;
    onProductsSelected?: (products: InsuranceProduct[]) => Promise<void>;
  }) => ReactNode;
}

export function ProductsWrapper({ children }: ProductsWrapperProps) {
  const { submission } = useSubmissionsWorkspace();

  const isDynamic = !!(submission && isDynamicApplication(submission));

  const {
    data: heraldApplicationResponse,
    isLoading: isHeraldLoading,
    error,
  } = useRetrieveHeraldApplication({
    id: submission?.heraldData?.applicationId!,
    enabled: isDynamic,
  });

  const isHerlaldAndLoading = isHeraldLoading && isDynamic;

  const { items: products, isLoading: isLoadingProducts } = useSearchInsuranceProduct({
    filter: {},
  });

  const initApiSelectedProducts = products.filter(
    (product) =>
      product.externalProductId &&
      !heraldApplicationResponse?.quote_exits?.some((quote) => quote.product_id === product.externalProductId) &&
      heraldApplicationResponse?.application.products.includes(product.externalProductId),
  );

  const initApiSelectedProductsMap = initApiSelectedProducts.reduce((acc, product) => {
    acc[product.id] = product;
    return acc;
  }, {} as SelectedProductsMap);

  if (isHerlaldAndLoading || isLoadingProducts) {
    return <LoaderOverlay />;
  }

  if (error || (!heraldApplicationResponse && isDynamic)) {
    return <Typography>{messages.errors.errorLoadingData}</Typography>;
  }

  return children({
    heraldApplicationResponse,
    isLoading: isHerlaldAndLoading,
    initSelectedProducts: initApiSelectedProductsMap,
    products,
  });
}
