import { chain, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { SubmissionMarket } from 'types';
import { AutonomousMarketInfoData } from './AdvancedContent/utils';

export const useAutonomousMarketInfo = (submissionMarkets: SubmissionMarket[]): AutonomousMarketInfoData[] =>
  useMemo(
    () =>
      chain(submissionMarkets)
        .map((market) => ({
          marketId: market.userMarketId,
          isIncumbent: !isEmpty(market.incumbentInfo),
          name: market.marketName,
        }))
        .orderBy(['isIncumbent', 'name'], ['desc', 'asc'])
        .value(),
    [submissionMarkets],
  );
