import * as yup from 'yup';
import { messages } from 'i18n';

const { requiredField } = messages.formMessages;

export interface SettingsFormData {
  firstName: string;
  lastName: string;
  email: string;
  signature?: string;
  title: string;
  note: string;
  phoneNumber: string;
}

export const schema = yup.object().shape({
  firstName: yup.string().trim().required(requiredField),
  lastName: yup.string().trim().required(requiredField),
  email: yup.string().trim().required(requiredField),
  signature: yup.string().trim(),
  userTitle: yup.string().trim(),
  note: yup.string().trim(),
  phoneNumber: yup.string().trim(),
});

export enum FormFieldsNames {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Signature = 'signature',
  PhoneNumber = 'phoneNumber',
  Note = 'note',
  Title = 'title',
}
export interface FormFieldsConfigProps {
  name: FormFieldsNames;
  label: string;
  id: string;
}

type FormFieldsConfig = {
  [formField in FormFieldsNames]: FormFieldsConfigProps;
};

const { labels } = messages.SettingsDialog;
export const formFieldsConfig: FormFieldsConfig = {
  [FormFieldsNames.FirstName]: {
    label: labels.firstName,
    name: FormFieldsNames.FirstName,
    id: FormFieldsNames.FirstName,
  },
  [FormFieldsNames.LastName]: {
    label: labels.lastName,
    name: FormFieldsNames.LastName,
    id: FormFieldsNames.LastName,
  },
  [FormFieldsNames.Email]: {
    label: labels.email,
    name: FormFieldsNames.Email,
    id: FormFieldsNames.Email,
  },
  [FormFieldsNames.PhoneNumber]: {
    label: labels.phoneNumber,
    name: FormFieldsNames.PhoneNumber,
    id: FormFieldsNames.PhoneNumber,
  },
  [FormFieldsNames.Note]: {
    label: labels.note,
    name: FormFieldsNames.Note,
    id: FormFieldsNames.Note,
  },
  [FormFieldsNames.Title]: {
    label: labels.userTitle,
    name: FormFieldsNames.Title,
    id: FormFieldsNames.Title,
  },
  [FormFieldsNames.Signature]: {
    label: labels.signature,
    name: FormFieldsNames.Signature,
    id: FormFieldsNames.Signature,
  },
};
