import { useEffect, useState } from 'react';
import { SubmissionMarket } from 'types';

import { ExpiringTowerReportRow } from './types';
import { getExpiringTowerReportRows } from './utils';

export default function useReportRows(submissionMarkets: SubmissionMarket[]) {
  const [rowsData, setRowsData] = useState<ExpiringTowerReportRow[]>([]);

  useEffect(() => {
    setRowsData(getExpiringTowerReportRows(submissionMarkets));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionMarkets]);

  return { rowsData };
}
