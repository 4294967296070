import { MouseEvent, memo as reactMemo } from 'react';
import {
  Box,
  Chip,
  Collapse,
  darken,
  Divider,
  Highlight,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@common-components';
import { ExpandLess, ExpandMore, Message as MessageIcon, People as PeopleIcon, Speed as SpeedIcon } from '@icons';
import { messages } from 'i18n';
import { contactFullName, getDisplayDate, truncateMultiLineText, userFullName } from 'utils';
import { stringCompare } from 'utils/comparators';
import { HighlightHtml } from 'broker/components/common/HighlightHtml';
import LabelList from 'broker/components/common/LabelList';
import useContactsSorter from 'broker/components/UserMarkets/hooks/useContactsSorter';
import { MarketsItemProps } from 'broker/components/UserMarkets/types';
import { FormFieldsNames } from 'broker/dialogs/UserMarketDialog/utils';
import { columnConfig, UserMarketColumns } from './MarketsTableHeader';
import MarketsTableItemMenu from './MarketsTableItemMenu';
import MarketTableItemExpand from './MarketTableItemExpand';
import { getMarketNameWithType } from './utils';

function MarketsTableItem({
  isMarketExpanded,
  onExpandClicked,
  coverageLineFilters,
  isAllCoverageLineFilters,
  extendedMarket,
  submissionMarkets,
  selectionMode,
  selectedContacts,
  onClick,
  searchInput = '',
  checkedLabels,
  handleContactClicked,
  onOpenNewContact,
  isCompact,
  isIncumbent = false,
}: MarketsItemProps) {
  const submissionMarket = submissionMarkets?.find((marketItem) => marketItem.userMarketId === extendedMarket.id);
  // if the incumbent has more than one quotes in the expiring program then use the first one (agreed with product)
  const isIncumbentPrimary = isIncumbent && submissionMarket?.incumbentInfo[0]?.isPrimary;
  const {
    id,
    marketName,
    contacts,
    categories,
    riskAppetite,
    memo,
    updatedAt,
    updatedByUserDetails,
    organizationType,
    matchReasons,
  } = extendedMarket;

  const sortedContacts = useContactsSorter({
    allContacts: contacts,
    searchInput,
    coverageLineFilters,
    isAllCoverageLineFilters,
  });

  const getMatchReasonText = (matchReason: 'coverageLines' | 'incumbent') =>
    matchReason === 'coverageLines' ? messages.marketsPage.coverageLines : messages.marketsPage.incumbent;

  const preventExpandCollapse = (e?: MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation();
    e?.preventDefault();
  };

  return (
    <Stack divider={<Divider flexItem />}>
      <Stack
        onClick={() => {
          onExpandClicked(id);
        }}
        direction="row"
        px={1.5}
        alignItems="center"
        height={(theme) => theme.spacing(9)}
        width={1}
        gap={3}
        sx={(theme) => ({
          cursor: 'pointer',
          position: isMarketExpanded ? 'sticky' : 'static',
          bgcolor: 'common.white',
          zIndex: 1,
          top: 40,
          ':hover': {
            bgcolor: darken(theme.palette.common.white, 0.01),
          },
        })}
      >
        <Stack flex={columnConfig[UserMarketColumns.ExpandContacts].flex} alignItems="center">
          <IconButton icon={isMarketExpanded ? ExpandLess : ExpandMore} size="small" color="secondary" variant="text" />
        </Stack>
        <Stack
          direction="row"
          flex={columnConfig[UserMarketColumns.MarketName].flex}
          alignItems="center"
          overflow="hidden"
        >
          <Stack gap={0.5}>
            <Tooltip tooltipContent={getMarketNameWithType(marketName, organizationType)}>
              <Typography fontWeight="bold" variant="body2" sx={{ ...truncateMultiLineText(isIncumbent ? 1 : 2) }}>
                <Highlight
                  searchWords={[searchInput]}
                  textToHighlight={getMarketNameWithType(marketName, organizationType)}
                />
              </Typography>
            </Tooltip>

            {isIncumbent && (
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Chip
                  color="secondary"
                  size="small"
                  label={messages.market.status.getIncumbentText(!!isIncumbentPrimary)}
                />
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack
          flex={columnConfig[UserMarketColumns.Contacts].flex}
          alignItems={columnConfig[UserMarketColumns.Contacts].align}
          overflow="hidden"
          py={0.5}
        >
          <Chip
            rounded
            tooltipContent={
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {[...sortedContacts]
                  .sort((a, b) => stringCompare(b.role, a.role))
                  .map((contact) => (
                    <Stack sx={{ lineHeight: 1 }} key={contact.id}>
                      <Chip
                        indicator={contact.passedFilter}
                        tooltipContent={contact.email}
                        label={contactFullName(contact, { showTitle: true })}
                        searchTerm={searchInput}
                        color="secondary"
                      />
                    </Stack>
                  ))}
              </Stack>
            }
            indicator={sortedContacts.some((sortedContact) => sortedContact.passedFilter)}
            label={
              (selectionMode && selectedContacts?.length ? `${selectedContacts?.length.toString()}/` : '') +
              contacts.length.toString()
            }
            color={isMarketExpanded ? 'primary' : 'secondary'}
            focused={isMarketExpanded}
            variant="outlined"
            startIcon={PeopleIcon}
          />
        </Stack>

        {selectionMode && (
          <Stack
            flex={columnConfig[UserMarketColumns.MatchReason].flex}
            direction="row"
            flexWrap="wrap"
            gap={1}
            alignItems={columnConfig[UserMarketColumns.MatchReason].align}
            py={0.5}
          >
            {matchReasons?.map((matchReason) => (
              <Chip
                key={matchReason}
                size="extraSmall"
                label={getMatchReasonText(matchReason)}
                color="green.200"
                variant="contained"
              />
            ))}
          </Stack>
        )}

        <Stack flex={columnConfig[UserMarketColumns.RiskAppetite].flex} alignItems="center">
          <Chip
            label={messages.marketsPage.riskAppetite}
            rounded
            startIcon={SpeedIcon}
            tooltipContent={
              <HighlightHtml
                searchWords={[searchInput]}
                textToHighlight={riskAppetite || messages.marketsPage.riskAppetiteCTA}
              />
            }
            variant="outlined"
            color={riskAppetite ? 'primary' : 'secondary'}
            onClick={() => onClick({ id, scrollTo: FormFieldsNames.RiskAppetite })}
            indicator={!!searchInput && !!riskAppetite?.toLowerCase().includes(searchInput.toLowerCase())}
          />
        </Stack>
        <Stack flex={columnConfig[UserMarketColumns.Memo].flex} alignItems="center">
          <Chip
            label={messages.marketsPage.memo}
            rounded
            startIcon={MessageIcon}
            tooltipContent={
              <HighlightHtml searchWords={[searchInput]} textToHighlight={memo || messages.marketsPage.memoCTA} />
            }
            variant="outlined"
            color={memo ? 'primary' : 'secondary'}
            onClick={() => onClick({ id, scrollTo: FormFieldsNames.Memo })}
            indicator={!!searchInput && !!memo?.toLowerCase().includes(searchInput.toLowerCase())}
          />
        </Stack>
        <Stack flex={columnConfig[UserMarketColumns.Categories].flex} direction="row" overflow="hidden" py={1} gap={1}>
          <LabelList
            variant="contained"
            isRounded
            labels={categories?.map((category) => ({ text: category })) || []}
            onAddLabelClick={() => onClick({ id, scrollTo: FormFieldsNames.Categories })}
            searchInput={searchInput}
            selectedLabels={checkedLabels}
            addButtonText={messages.buttons.addCategory}
          />
        </Stack>

        {!selectionMode && (
          <Stack
            flex={columnConfig[UserMarketColumns.LastUpdate].flex}
            alignItems={columnConfig[UserMarketColumns.LastUpdate].align}
            overflow="hidden"
          >
            <Typography variant="body2" noWrap>
              {getDisplayDate(updatedAt)}
            </Typography>
            {updatedByUserDetails && (
              <Typography variant="caption" color="text.secondary" noWrap>
                {`By ${userFullName(updatedByUserDetails)}`}
              </Typography>
            )}
          </Stack>
        )}
        <Box onClick={preventExpandCollapse}>
          <MarketsTableItemMenu extendedMarket={extendedMarket} onAddContact={onOpenNewContact} onEdit={onClick} />
        </Box>
      </Stack>
      <Collapse in={isMarketExpanded} mountOnEnter unmountOnExit>
        <MarketTableItemExpand
          filteredContacts={sortedContacts}
          extendedMarket={extendedMarket}
          onOpenNewContact={onOpenNewContact}
          searchInput={searchInput}
          selectionMode={!!selectionMode}
          handleContactClicked={handleContactClicked}
          selectedContacts={selectedContacts}
          coverageLineFilters={coverageLineFilters}
          isAllCoverageLineFilters={isAllCoverageLineFilters}
          isCompact={isCompact}
        />
      </Collapse>
    </Stack>
  );
}

export default reactMemo(MarketsTableItem);
