import { Button, ButtonProps } from '@common-components';
import { messages } from 'i18n';
import { BROKER_NESTED_ROUTES } from 'broker/broker-routes';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';

interface AddMarketsMenuButtonProps {
  placement?: 'bottom-left' | 'bottom-right' | 'bottom-center';
  buttonProps?: Omit<ButtonProps, 'children'>;
}

export default function StartMarketingButton({ buttonProps }: AddMarketsMenuButtonProps) {
  const navigate = useNavigate();

  const onStartMarketingClick = () => {
    navigate(BROKER_NESTED_ROUTES.WORKSPACE.ADD_PRODUCTS, {
      routeKey: RouteKey.AddProducts,
      state: undefined,
    });
  };

  return (
    <Button onClick={onStartMarketingClick} {...buttonProps}>
      {messages.submissionWorkspace.marketRequestsWorkspace.addProducts}
    </Button>
  );
}
