import { Stack, Tooltip, Typography } from '@common-components';
import { EmailMessageRecipientType } from 'enums';
import { messages } from 'i18n';
import { truncateMultiLineText } from 'utils';
import { UnifiedEmail } from 'broker/components/Emails/UnifiedEmail';

interface RecipientsListsProps {
  unifiedEmail: UnifiedEmail;
}

export function RecipientsLists({ unifiedEmail }: RecipientsListsProps) {
  const { to, cc } = messages.submissionWorkspace.emailsTab;

  const toRecipients = unifiedEmail.recipients
    .filter((recipient) => recipient.type === EmailMessageRecipientType.To)
    .map((recipient) => recipient.address)
    .join(', ');
  const ccRecipients = unifiedEmail.recipients
    .filter((recipient) => recipient.type === EmailMessageRecipientType.Cc)
    .map((recipient) => recipient.address)
    .join(', ');

  return (
    <Stack mt={1} mb={3} gap={1} px={3}>
      {toRecipients && (
        <Stack direction="row" gap={0.5}>
          <Typography variant="body2" color="typography.nonEssential">
            {`${to}: `}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {toRecipients}
          </Typography>
        </Stack>
      )}
      {ccRecipients && (
        <Tooltip tooltipContent={ccRecipients} placement="bottom-start">
          <Stack direction="row" gap={0.5}>
            <Typography variant="body2" color="typography.nonEssential">
              {`${cc}:`}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ ...truncateMultiLineText(1) }}>
              {ccRecipients}
            </Typography>
          </Stack>
        </Tooltip>
      )}
    </Stack>
  );
}
