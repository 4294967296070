import { alpha, Theme } from '@common-components';

const inputProps = (theme: Theme) => ({
  ...theme.typography.body1,
  boxShadow: theme.shadows[0],
  letterSpacing: '0.5px',
  lineHeight: '1.4375em',
  padding: '8.5px 14px',
  width: '100%',
  '&:focus': {
    borderWidth: 2,
  },
});

const buttonProps = (theme: Theme) =>
  ({
    color: 'primary.main',
    whiteSpace: 'nowrap',
    ...theme.typography.button,
    minWidth: 'max-content',
    padding: '6px 15px',
    borderColor: 'primary.main',
    border: `1px ${theme.palette.primary.main} solid`,
    transition: theme.transitions.create(['background-color', 'box-shadow', 'border-color', 'color'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&:focus': {
      boxShadow: 'none',
      textDecoration: 'none',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.is-disabled': {
      color: theme.palette.action.disabled,
      borderColor: theme.palette.action.disabled,
      opacity: 1,
      '&:hover': {
        backgroundColor: 'initial',
      },
    },
    '&.btn-primary': {
      bgcolor: 'primary.main',
      borderColor: 'transparent',
      color: 'common.white',
      padding: '6px 16px',
      '&.is-disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
        borderColor: 'transparent',
      },
    },

    '&.bce-btn-more-options, &.bdl-Pagination-toggle': {
      border: 'none',
    },
  } as const);

const BoxStyles = (theme: Theme) =>
  ({
    '& .bcpr-PreviewHeader': {
      boxShadow: 1,
      zIndex: 1,
      '& .bcpr-PreviewHeader-content': {
        bgcolor: 'grey.100',
      },
    },

    '& .be-modal': {
      '& .be-modal-dialog-content': {
        ...theme.typography.caption,
        '& .btn': {
          border: `1px ${theme.palette.primary.main} solid`,
        },
      },
      '& input[type=text]': {
        ...inputProps(theme),
      },
    },

    // Excluding share dialog because it has a single button and a select component in the buttons panel
    '& .be-modal:not(.be-modal-share)': {
      '& .be-modal-btns': {
        justifyContent: 'flex-start',
        flexDirection: 'row-reverse',
        mr: -1,
      },
    },

    '& .be-modal-share': {
      // This button needs special handling from the general btn selector due to selectors specificity issues
      '& .btn.btn-primary.be-modal-button-copy': {
        ...buttonProps(theme),
        height: 'auto',
      },
      // Hiding the "share with company" option as it has no meaning in our product's context
      '& .be-share-access-select': {
        'option[value=company]': {
          display: 'none',
        },
      },
    },

    '& .be-header': {
      bgcolor: 'grey.100',
      '& .be-logo': {
        display: 'none',
      },
      '& input[type=search]': {
        ...inputProps(theme),
      },
    },

    '& .be-sub-header': {
      '& .btn': {
        border: 'none',
      },
    },

    '& .bce-footer': {
      justifyContent: 'flex-start',
    },

    '& .bdl-GridViewSlot-content': {
      boxShadow: theme.shadows[0],
      borderRadius: 1,
      overflow: 'hidden',
    },
    '& .bce-item-header-row': {
      boxShadow: theme.shadows[0],
    },
    '& .bcu-footer': {
      bgcolor: 'background.default',
    },
    '& .bce-item-row': {
      '& .bce-more-options': {
        opacity: 1,
        '& .btn': {
          ml: 0,
        },
      },
    },
    '& .btn': {
      ...buttonProps(theme),
    },
  } as const);

export default BoxStyles;
