import {
  ADDITIONAL_TOOLBOX_TABS,
  AdditionalToolboxTabs,
  BASE_TOOLBOX_TABS,
  BaseToolboxTabs,
  ToolboxTabs,
  WORKSPACE_OVERLAY_ROUTES,
  WORKSPACE_PAGES_ROUTES,
  WorkspaceToolboxTabsRoutesValues,
} from 'broker/pages/SubmissionWorkspacePage/types';
import { ActivityLogLevel, ActivityLogProps } from './components/ActivityLog/ActivityLogBase';
import { EmailProps } from './components/Email/Email';
import { FilesProps } from './components/Files/Files';
import { TasksProps } from './components/Tasks/Tasks';
import { TowerProps } from './components/Tower/Tower';

export type WorkspaceToolboxRoutesConfigProps = {
  [BASE_TOOLBOX_TABS.TASKS]?: Partial<TasksProps>;
  [BASE_TOOLBOX_TABS.FILES]?: Partial<FilesProps>;
  [BASE_TOOLBOX_TABS.EMAIL]?: Partial<EmailProps>;
  [BASE_TOOLBOX_TABS.TOWER]?: Partial<TowerProps>;
  [BASE_TOOLBOX_TABS.ACTIVITY_LOG]?: Partial<ActivityLogProps>;
  [ADDITIONAL_TOOLBOX_TABS.SMART_REPORTS]?: Partial<{}>;
  [ADDITIONAL_TOOLBOX_TABS.CONTACTS]?: Partial<{}>;
  [ADDITIONAL_TOOLBOX_TABS.MARKET_INFO]?: Partial<{}>;
};
export interface WorkspaceToolboxRoutesProps {
  defaultTab: ToolboxTabs;
  additionalTabs?: AdditionalToolboxTabs[];
  forceOpen?: boolean;
  config?: WorkspaceToolboxRoutesConfigProps;
  hideBaseTabs?: BaseToolboxTabs[];
}

type WorkspaceToolboxRoutesConfig = Record<WorkspaceToolboxTabsRoutesValues, WorkspaceToolboxRoutesProps>;

export const workspaceToolboxRoutesTabsConfig: WorkspaceToolboxRoutesConfig = {
  [WORKSPACE_PAGES_ROUTES.PRE_MARKETING]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
    forceOpen: true,
    hideBaseTabs: [BASE_TOOLBOX_TABS.TOWER],
    config: {
      [BASE_TOOLBOX_TABS.FILES]: {
        alwaysShowUploader: true,
      },
    },
  },
  [WORKSPACE_PAGES_ROUTES.MARKETING]: {
    defaultTab: BASE_TOOLBOX_TABS.TASKS,
  },
  [WORKSPACE_PAGES_ROUTES.PROPOSAL]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
    forceOpen: true,
    config: {
      [BASE_TOOLBOX_TABS.FILES]: {
        isEmailComposeMode: true,
        isSelectionMode: true,
      },
    },
  },
  [WORKSPACE_PAGES_ROUTES.CUSTOMER]: {
    defaultTab: ADDITIONAL_TOOLBOX_TABS.SMART_REPORTS,
    additionalTabs: [ADDITIONAL_TOOLBOX_TABS.SMART_REPORTS],
    config: {
      [BASE_TOOLBOX_TABS.FILES]: {
        isSelectionMode: true,
      },
    },
  },

  [WORKSPACE_PAGES_ROUTES.SINGLE_MARKET]: {
    additionalTabs: [ADDITIONAL_TOOLBOX_TABS.CONTACTS, ADDITIONAL_TOOLBOX_TABS.MARKET_INFO],
    defaultTab: BASE_TOOLBOX_TABS.ACTIVITY_LOG,
    config: {
      [BASE_TOOLBOX_TABS.FILES]: {
        autoLabel: true,
      },
      [BASE_TOOLBOX_TABS.ACTIVITY_LOG]: {
        activityLogLevel: ActivityLogLevel.SubmissionMarket,
      },
    },
  },
  [WORKSPACE_PAGES_ROUTES.QUOTE]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
    config: {
      [BASE_TOOLBOX_TABS.FILES]: {
        isSelectionMode: true,
        hideQuoteActions: true,
      },
      [BASE_TOOLBOX_TABS.TOWER]: {
        hideQuoteActions: true,
      },
    },
  },
  [WORKSPACE_PAGES_ROUTES.BINDER]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
    config: {
      [BASE_TOOLBOX_TABS.FILES]: {
        isSelectionMode: true,
        hideQuoteActions: true,
      },
      [BASE_TOOLBOX_TABS.TOWER]: {
        hideQuoteActions: true,
      },
    },
  },
  [WORKSPACE_PAGES_ROUTES.POLICY]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
    config: {
      [BASE_TOOLBOX_TABS.FILES]: {
        isSelectionMode: true,
        hideQuoteActions: true,
      },
      [BASE_TOOLBOX_TABS.TOWER]: {
        hideQuoteActions: true,
      },
    },
  },
  [WORKSPACE_PAGES_ROUTES.MARKETING_REPORT]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
  },
  [WORKSPACE_PAGES_ROUTES.CONCEPTUAL_TOWER]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
    config: {
      [BASE_TOOLBOX_TABS.EMAIL]: {
        hideEmailActions: true,
      },
      [BASE_TOOLBOX_TABS.FILES]: {
        isSelectionMode: true,
        isEmailComposeMode: true,
      },
      [BASE_TOOLBOX_TABS.TOWER]: {
        hideConceptualTowerShareButton: true,
      },
    },
  },
  [WORKSPACE_PAGES_ROUTES.EXPIRING_TOWER]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
  },
  [WORKSPACE_PAGES_ROUTES.QUOTE_STATUS_REQUEST]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
    config: {
      [BASE_TOOLBOX_TABS.TASKS]: {
        isDisabled: true,
      },
      [BASE_TOOLBOX_TABS.EMAIL]: {
        hideEmailActions: true,
      },
      [BASE_TOOLBOX_TABS.FILES]: {
        isSelectionMode: true,
        isEmailComposeMode: true,
      },
    },
  },
  [WORKSPACE_OVERLAY_ROUTES.INFORMATION_REQUEST]: {
    defaultTab: BASE_TOOLBOX_TABS.EMAIL,
  },
  [WORKSPACE_OVERLAY_ROUTES.DECLINATION]: {
    defaultTab: BASE_TOOLBOX_TABS.EMAIL,
  },
  [WORKSPACE_PAGES_ROUTES.ADD_MARKETS]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
  },
  [WORKSPACE_PAGES_ROUTES.ADD_PRODUCTS]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
  },
  [WORKSPACE_PAGES_ROUTES.EMAIL_EDITOR]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
    config: {
      [BASE_TOOLBOX_TABS.TASKS]: {
        isDisabled: true,
      },
      [BASE_TOOLBOX_TABS.EMAIL]: {
        hideEmailActions: true,
      },
      [BASE_TOOLBOX_TABS.FILES]: {
        isSelectionMode: true,
        isEmailComposeMode: true,
      },
    },
  },
  [WORKSPACE_PAGES_ROUTES.EMAIL_REPLY]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
    config: {
      [BASE_TOOLBOX_TABS.TASKS]: {
        isDisabled: true,
      },
      [BASE_TOOLBOX_TABS.EMAIL]: {
        hideEmailActions: true,
      },
      [BASE_TOOLBOX_TABS.FILES]: {
        isSelectionMode: true,
        isEmailComposeMode: true,
      },
    },
  },
  [WORKSPACE_PAGES_ROUTES.EDIT_SUBMISSION]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
  },
  [WORKSPACE_PAGES_ROUTES.ACKNOWLEDGMENT_EMAIL]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
    forceOpen: true,
    hideBaseTabs: [BASE_TOOLBOX_TABS.TOWER],
    config: {
      [BASE_TOOLBOX_TABS.FILES]: {
        alwaysShowUploader: true,
        isSelectionMode: true,
      },
    },
  },
  [WORKSPACE_PAGES_ROUTES.MARKETING_UPDATE_EMAIL]: {
    defaultTab: BASE_TOOLBOX_TABS.FILES,
    forceOpen: true,
    hideBaseTabs: [BASE_TOOLBOX_TABS.TOWER],
    config: {
      [BASE_TOOLBOX_TABS.FILES]: {
        isSelectionMode: true,
      },
    },
  },
};
