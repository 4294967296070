import { Typography } from '@common-components';
import { SubmissionStatus } from 'enums';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import { logger } from 'utils';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

interface SubmissionStatusChangeConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  newSubmissionStatus: SubmissionStatus;
}

export function SubmissionStatusChangeDialog({
  isOpen,
  onClose,
  newSubmissionStatus,
}: SubmissionStatusChangeConfirmationDialogProps) {
  const { partialSubmission, updateSubmission } = useSubmissionsWorkspace();
  const [isLoading, { on: startLoading, off: stopLoading }] = useBoolean(false);
  const { header, content, confirmButton, cancelButton } = messages.submissionStatusChangeDialog;

  const onConfirm = async () => {
    startLoading();
    try {
      await updateSubmission(partialSubmission!.id, { status: newSubmissionStatus });
      onClose();
    } catch (error) {
      logger.log('error', error as Error);
    } finally {
      stopLoading();
    }
  };
  return (
    <Dialog open={isOpen} onClose={onClose} alertDialog>
      <DialogHeader id="submission-status-change-dialog-header" title={header} onClose={onClose} />
      <DialogContent>
        <Typography variant="body1" color="textPrimary" sx={{ mb: 1 }}>
          {content(newSubmissionStatus)}
        </Typography>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: confirmButton(newSubmissionStatus),
          onClick: onConfirm,
          loading: isLoading,
        }}
        cancelButton={{ children: cancelButton, onClick: onClose }}
      />
    </Dialog>
  );
}
