import { invert } from 'lodash';
import { ActivityType, EmailTemplateType } from 'enums';

export const emailTemplateTypeToActivityType: Partial<Record<EmailTemplateType, ActivityType>> = {
  [EmailTemplateType.RfqGeneral]: ActivityType.RequestForQuote,
  [EmailTemplateType.RfqAttachmentPoint]: ActivityType.RequestForQuote,
  [EmailTemplateType.PrimaryMarketing]: ActivityType.RequestForQuote,
  [EmailTemplateType.ExcessMarketing]: ActivityType.RequestForQuote,
  [EmailTemplateType.BinderCorrection]: ActivityType.BinderCorrection,
  [EmailTemplateType.PolicyCorrection]: ActivityType.PolicyCorrection,
  [EmailTemplateType.BindOrder]: ActivityType.BindRequest,
  [EmailTemplateType.PolicyOrder]: ActivityType.PolicyRequested,
};

export const activityTypeToEmailTemplateType: Partial<Record<ActivityType, EmailTemplateType>> = invert(
  emailTemplateTypeToActivityType,
);
