import { Quote } from 'types';

export interface QuoteComparisonInsightsLocationState {
  quotes: Quote[];
  expiringPolicyFileId?: string;
}

export enum PdfGenerationStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Error = 'Error',
}
