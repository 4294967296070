import { useFormContext } from 'react-hook-form';
import { Button, MobileStepper } from '@common-components';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@icons';
import { FormMode } from 'enums';
import { QuoteFormRoutes } from 'broker/broker-routes';
import { QuoteConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/config';
import { useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

interface FooterStepperProps {
  activeStep: QuoteFormRoutes;
  mode: FormMode;
  handleChangeStep: (activeStep: QuoteFormRoutes, direction: 'next' | 'prev') => void;
  quoteConfig: QuoteConfig;
}
export default function FooterStepper({ activeStep, handleChangeStep, mode, quoteConfig }: FooterStepperProps) {
  const quoteMethods = useFormContext();
  const { filesExplorer } = useUiStoreState();

  const steps = quoteConfig.formSteps;
  return (
    <MobileStepper
      variant="dots"
      steps={steps.length}
      position="static"
      activeStep={steps.indexOf(activeStep)}
      sx={{ maxWidth: 400, flexGrow: 1, bgcolor: 'transparent', gap: 2 }}
      nextButton={
        <Button
          size="small"
          onClick={() => handleChangeStep(activeStep, 'next')}
          disabled={
            steps.indexOf(activeStep) === steps.length - 1 ||
            (mode !== FormMode.view && (!quoteMethods.formState.isValid || filesExplorer.selectedFiles.length === 0))
          }
        >
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button
          size="small"
          onClick={() => handleChangeStep(activeStep, 'prev')}
          disabled={steps.indexOf(activeStep) === 0}
        >
          <KeyboardArrowLeft />
        </Button>
      }
    />
  );
}
