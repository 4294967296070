import { ChangeEvent, useState } from 'react';
import { MenuItem, TextField } from '@common-components';
import { useBoolean } from 'hooks';
import ConfirmationDialog from 'admin/dialogs/ConfirmationDialog';

export interface CardSelectProps {
  onChange?: (value: string) => void | Promise<void>;
  items: Record<string, string>;
  label: string;
  value: string;
  confirmationDialog?: {
    title: string;
    warning: string;
    confirmLabel: string;
    confirmDisableMessage: string;
    confirmationText: string;
    confirmOnValue: string;
  };
}

export default function CardSelect({ onChange, items, label, value, confirmationDialog }: CardSelectProps) {
  const [saving, setSaving] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const [showConfirmation, { on: openConfirmation, off: closeConfirmation }] = useBoolean(false);

  async function onNewValue(event: ChangeEvent<{ value: unknown }>) {
    const changedValue = event.target.value as string;

    if (confirmationDialog && changedValue === confirmationDialog.confirmOnValue) {
      openConfirmation();
      return;
    }

    // Temporarily disable the select widget to give a visual indication of work
    setSaving(true);

    setNewValue(changedValue);

    if (onChange) {
      await onChange(changedValue);
    }

    // Restore the widget's editability after a short pause to ensure that the
    // visual indicator is visible.
    setTimeout(() => setSaving(false), 200);
  }

  return (
    <>
      <TextField
        disabled={!onChange || saving}
        fullWidth
        label={label}
        margin="normal"
        onChange={onNewValue}
        select
        value={newValue}
        variant="outlined"
      >
        {Object.entries(items).map(([key, itemValue]) => (
          <MenuItem key={key} value={key}>
            {String(itemValue)}
          </MenuItem>
        ))}
      </TextField>
      {confirmationDialog && (
        <ConfirmationDialog
          open={showConfirmation}
          onClose={closeConfirmation}
          onAction={async () => {
            await onChange?.(confirmationDialog.confirmOnValue);
            setNewValue(confirmationDialog.confirmOnValue);
            closeConfirmation();
          }}
          confirmationText={confirmationDialog.confirmationText}
          confirmationMessage={confirmationDialog.confirmDisableMessage}
          title={confirmationDialog.title}
          warningMessage={confirmationDialog.warning}
          confirmButton={confirmationDialog.confirmLabel}
        />
      )}
    </>
  );
}
