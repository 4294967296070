import Filter, { FilterButtonTypes } from 'broker/components/common/Filter';
import { SubmissionFilterAttributes } from './useSubmissionFilter';

interface SubmissionFilterProps {
  title: string;
  filter: SubmissionFilterAttributes;
  showSearch?: boolean;
}
export default function SubmissionFilter({ title, filter, showSearch = false }: SubmissionFilterProps) {
  const { filters, handleFilterChange, handleSelectAll, isAllSelected } = filter;
  return (
    <Filter
      buttonType={FilterButtonTypes.Dropdown}
      title={title}
      filters={filters}
      isAllFilters={isAllSelected}
      handleFilterChange={handleFilterChange}
      handleAllSelected={handleSelectAll}
      search={showSearch}
    />
  );
}
