import { HTMLAttributes } from 'react';
import { Box, Slider, SliderThumb, Stack, Typography } from '@common-components';
import { AirplanemodeActive } from '@icons';
import { messages } from 'i18n';
import { SliderStyled } from './styles';
import { sliderMarks } from './utils';

type CopilotThumbComponentProps = HTMLAttributes<unknown>;

interface AutonomousSliderProps {
  value: number;
  setValue: (value: number) => void;
  disabled: boolean;
}

function CopilotThumbComponent(props: CopilotThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <AirplanemodeActive sx={{ fontSize: 14, color: 'text.primary', transform: 'rotate(180deg)' }} />
    </SliderThumb>
  );
}
export default function AutonomousSlider({ value, setValue }: AutonomousSliderProps) {
  // TODO: Remove this console.log
  return (
    <Stack gap={2}>
      <Typography variant="captionBold" color="textSecondary">
        {messages.autonomousFlow.settingsModal.sliderHeader}
      </Typography>
      <Box
        height={200}
        my={2}
        sx={{
          position: 'relative',
          // Block the slider when the autonomous flow is running
          '&:before': {
            content: '" "',
            position: 'absolute',
            top: -20,
            left: -10,
            right: -10,
            bottom: -20,
            cursor: 'not-allowed',
            zIndex: 1,
          },
        }}
      >
        <Slider
          components={{
            Thumb: CopilotThumbComponent,
          }}
          aria-label="Temperature"
          orientation="vertical"
          valueLabelDisplay="off"
          defaultValue={0}
          value={value}
          onChange={(_, newValue) => {
            setValue(newValue as number);
          }}
          track="inverted"
          marks={sliderMarks}
          step={null}
          sx={SliderStyled}
        />
      </Box>
    </Stack>
  );
}
