import { Box, buttonBaseClasses, Chip } from '@common-components';
import { InfoOutlined } from '@icons';
import { messages } from 'i18n';
import { useAIContext } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import { AIThreadDataInput } from './AIThreadDataInput';

export const AIThreadInputDataButton = ({ withFileLinks }: { withFileLinks: boolean }) => {
  const { enrichmentData } = useAIContext();

  if (!enrichmentData) {
    return null;
  }

  return (
    <Box sx={{ display: 'inline-block', position: 'relative' }}>
      <Box sx={{ [`.${buttonBaseClasses.root}`]: { padding: 0.5 } }}>
        <Chip
          variant="outlined"
          endIcon={InfoOutlined}
          label={messages.submissionWorkspace.aiGeneratedContentPopup.inputData}
          color="secondary"
          size="medium"
          tooltipContent={<AIThreadDataInput withFileLinks={withFileLinks} />}
          tooltipPlacement="bottom"
          disableFocusListener
        />
      </Box>
    </Box>
  );
};
