import { isEmpty } from 'lodash';
import { matchSorter } from 'match-sorter';
import moment from 'moment';
import { useMemo, useState } from 'react';

import { messages } from 'i18n';
import { SearchInputProps, Sorters, userFullName } from 'utils';
import { UserMarketSortTypes } from 'broker/components/UserMarkets/utils';
import { Agency } from './types';
import { AgenciesSortTypes } from './utils';

export const sorters: Sorters<Agency, AgenciesSortTypes> = {
  [UserMarketSortTypes.Updated]: {
    sorter: (a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)),
    label: messages.agenciesPage.sorter.updated,
  },
  [UserMarketSortTypes.Asc]: {
    sorter: (a, b) => a.name.localeCompare(b.name),
    label: messages.agenciesPage.sorter.asc,
  },

  [UserMarketSortTypes.Desc]: {
    sorter: (a, b) => b.name.localeCompare(a.name),
    label: messages.agenciesPage.sorter.desc,
  },
};

export interface UseAgenciesFilterProps extends SearchInputProps {
  sortedAndFilteredItems: Agency[];
  sortType: AgenciesSortTypes;
  setSortType: (value: AgenciesSortTypes) => void;
}

export default function useAgenciesFilter(
  agencies: Agency[],
  paginationReset: () => void,
  defaultSortType = AgenciesSortTypes.Asc,
): UseAgenciesFilterProps {
  const [sortType, setSortType] = useState<AgenciesSortTypes>(defaultSortType);
  const [searchInput, setSearchInput] = useState<string>('');

  const filteredAgencies = useMemo(
    () =>
      [...agencies]
        .sort(sorters[AgenciesSortTypes[sortType]].sorter)

        .filter((agency) => {
          if (searchInput.length < 2) {
            return true;
          }

          const userTerms = [
            agency.name,
            ...agency.domains.map((domain) => domain.domain),
            ...agency.agents.flatMap((agent) => [userFullName(agent), agent.email, agent.phoneNumber, agent.title]),
          ].filter(Boolean);
          const isInUserTerms = userTerms.some((term) => term?.toLowerCase().includes(searchInput.toLowerCase()));
          if (isInUserTerms) {
            return isInUserTerms;
          }
          const matchSorterFilter = matchSorter([agency], searchInput, { keys: ['marketName'] });
          return !isEmpty(matchSorterFilter);
        }),
    [agencies, searchInput, sortType],
  );

  const setSearch = (value: string) => {
    paginationReset();
    setSearchInput(value);
  };

  return {
    search: searchInput,
    setSearch,
    sortType,
    setSortType,
    sortedAndFilteredItems: filteredAgencies,
  };
}
