import { IndustryFields } from '@common/types';
import { IndustryAutoCompleteOption } from './types';

export function convertIndustryObjectToOption(industryFields: IndustryFields): IndustryAutoCompleteOption {
  return {
    value: industryFields.heraldId || '',
    label: `${industryFields.naicsCode} - ${industryFields.description}`,
    heraldId: industryFields.heraldId || '',
    description: industryFields.description,
    naicsCode: industryFields.naicsCode,
  };
}
