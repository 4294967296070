import { messages } from 'i18n';
import { SyncedSystemType } from 'types';

export interface SyncedSystemsConfigProps {
  label: string;
}
export const syncedSystemsConfig: Record<SyncedSystemType, SyncedSystemsConfigProps> = {
  [SyncedSystemType.EmailsStorage]: {
    label: messages.sync.email,
  },
  [SyncedSystemType.FilesStorage]: {
    label: messages.sync.file,
  },
  [SyncedSystemType.Accounting]: {
    label: messages.sync.accounting,
  },
};
