import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom-latest';
import { useLocation } from './useLocation';
import { NavigateOptions, useNavigate } from './useNavigate';

export function useNavigateToOverlay() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return useCallback(
    (navigateOptions: NavigateOptions) => {
      const { routeKey } = navigateOptions;
      searchParams.append('overlay', routeKey);
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
          hash: location.hash,
        },
        {
          routeKey,
          state: navigateOptions.state,
        },
      );
    },
    [location.hash, location.pathname, navigate, searchParams],
  );
}
