import { BrowserHistory } from 'history';
import { createContext, useContext } from 'react';

export const NavigationContext = createContext<{ history: BrowserHistory } | null>(null);

export function useNavigationContext() {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error('useNavigationContext must be used within an initialized Navigation Context');
  }

  return context;
}
