import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    // Debounce the handleOffline function to delay by 5 seconds
    const debouncedHandleOffline = debounce(() => {
      setIsOnline(false);
    }, 5000);

    const handleOnline = () => {
      // Cancel debounced offline action when the user comes online
      debouncedHandleOffline.cancel();
      setIsOnline(true);
    };

    const handleOffline = () => {
      // Invoke the debounced function
      debouncedHandleOffline();
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);

      // Clean up debounce on unmount
      debouncedHandleOffline.cancel();
    };
  }, []);

  return isOnline;
};
