import { ChangeEvent } from 'react';
import { messages } from 'i18n';
import { HotjarEvents } from 'utils/hotjar-events';
// eslint-disable-next-line import/no-internal-modules
import { styled, Switch } from './mui-index';
import Tooltip from './Tooltip';
import { TooltipWrapperProps } from './types';

export interface CustomSwitchProps extends TooltipWrapperProps {
  /** Whether the switch is checked */
  checked: boolean;
  /** The function to call when the switch is toggled */
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /** The icon to display as the On state */
  onIconCode?: string;
  /** The icon to display as the Off state */
  offIconCode?: string;
  /** on Tooltip content */
  onTooltipContent?: string;
  /** off Tooltip content */
  offTooltipContent?: string;
  /** The hotjar event to report when the button is clicked */
  hotjarEvent?: HotjarEvents;
}

// Adjust the styled component definition
const StyledCustomSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'onIconCode' && prop !== 'offIconCode',
})<{ onIconCode: string; offIconCode: string }>(({ theme, onIconCode, offIconCode }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 14,
      height: 14,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="${onIconCode}" /></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="${offIconCode}" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function CustomSwitch({
  onIconCode = 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z',
  offIconCode = 'M21,13H3V11H21V13Z',
  hotjarEvent,
  checked,
  onChange,
  onTooltipContent = messages.general.switchOn,
  offTooltipContent = messages.general.switchOff,
  ...rest
}: CustomSwitchProps) {
  return (
    <Tooltip tooltipContent={checked ? onTooltipContent : offTooltipContent} {...rest}>
      <StyledCustomSwitch checked={checked} onChange={onChange} onIconCode={onIconCode} offIconCode={offIconCode} />
    </Tooltip>
  );
}
