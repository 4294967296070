import { parseTimezoneLessDate } from '@common/utils/date-utils';
import { isUndefined, omitBy } from 'lodash';
import { PromptResultFeature } from 'enums';
import { ExtractedDataFields, SuggestedValueProps } from 'hooks';
import { PolicyExtractedData, QuoteExtractedData } from 'types';
import { detailsFormValidationShape } from './config/detailsFieldsConfig';
import { DetailsFieldsNames } from './enums';

/**
 * Extracts valid suggestions from the extracted data
 * @param extractedData the raw extracted data
 */
export const extractValidQuoteFieldsSuggestions = (extractedData: QuoteExtractedData) => {
  const { premium, triaPremium, expirationDate, aggregateLimit, carrierFees } = extractedData;
  const {
    limit: limitSchema,
    premium: premiumSchema,
    triaPremium: triaPremiumSchema,
    expiresAt: expiresAtSchema,
    carrierFee: carrierFeeSchema,
  } = detailsFormValidationShape;
  const result = {
    [DetailsFieldsNames.Limit]:
      aggregateLimit?.value && limitSchema.isValidSync(aggregateLimit.value)
        ? { suggestion: aggregateLimit, promptResultFeature: PromptResultFeature.QuoteAggregateLimit }
        : undefined,
    [DetailsFieldsNames.Premium]:
      premium?.value && premiumSchema.isValidSync(premium.value)
        ? { suggestion: premium, promptResultFeature: PromptResultFeature.QuotePremium }
        : undefined,
    [DetailsFieldsNames.TriaPremium]:
      triaPremium?.value && triaPremiumSchema.isValidSync(triaPremium.value)
        ? { suggestion: triaPremium, promptResultFeature: PromptResultFeature.QuoteTriaPremium }
        : undefined,
    [DetailsFieldsNames.ExpiresAt]:
      expirationDate?.value && expiresAtSchema.isValidSync(expirationDate.value)
        ? {
            suggestion: {
              ...expirationDate,
              value: parseTimezoneLessDate(expirationDate.value)?.toISOString(),
            },
            promptResultFeature: PromptResultFeature.QuoteExpiresAt,
          }
        : undefined,
    [DetailsFieldsNames.CarrierFee]:
      carrierFees?.value && carrierFeeSchema.isValidSync(carrierFees.value)
        ? { suggestion: carrierFees, promptResultFeature: PromptResultFeature.QuoteCarrierFees }
        : undefined,
  } as Record<string, SuggestedValueProps | undefined>;
  return omitBy(result, isUndefined) as ExtractedDataFields;
};

export const extractValidPolicyFieldsSuggestions = (extractedData: PolicyExtractedData) => {
  const { expirationDate } = extractedData;
  const { policyExpirationDate: policyExpirationDateSchema } = detailsFormValidationShape;
  const result = {
    [DetailsFieldsNames.PolicyExpirationDate]:
      expirationDate?.value && policyExpirationDateSchema.isValidSync(expirationDate.value)
        ? {
            suggestion: {
              ...expirationDate,
              value: parseTimezoneLessDate(expirationDate.value)?.toISOString(),
            },
            promptResultFeature: PromptResultFeature.PolicyExpirationDate,
          }
        : undefined,
  } as Record<string, SuggestedValueProps | undefined>;
  return omitBy(result, isUndefined) as ExtractedDataFields;
};
