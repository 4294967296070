import { first } from 'lodash';
import { useEffect, useState } from 'react';
import { EmailTemplateType } from 'enums';
import { useSearchEmailTemplate } from 'hooks';
import { Submission } from 'types';
import useEmailContentState from 'components/Editor/useEmailContentState';
import { TemplateVariablesResolver } from './template-variables-resolver';

export const useProposalFields = (
  submission: Submission,
  templateVariablesResolver: TemplateVariablesResolver,
  emailTemplateType: EmailTemplateType,
  unResolvedTemplateBody?: string,
) => {
  const { items: emailTemplates } = useSearchEmailTemplate({ filter: { type: emailTemplateType } });
  const [proposalInitialValue, setProposalInitialValue] = useState('');

  const {
    isDirty,
    setIsDirty,
    body: proposalContent,
    setBody: setProposalContent,
    subject: proposalSubject,
    setSubject: setProposalSubject,
  } = useEmailContentState(
    templateVariablesResolver.resolveSubjectVariables(
      submission.proposal?.subject ?? first(emailTemplates)?.subject ?? '',
    ),
    templateVariablesResolver.resolveContentForEditMode(unResolvedTemplateBody ?? first(emailTemplates)?.body ?? ''),
  );

  // In case a submission changes (for example when moving to change selection in marketing report) - we want to re-resolve the content
  useEffect(() => {
    setProposalInitialValue(
      templateVariablesResolver.resolveContentForEditMode(unResolvedTemplateBody ?? first(emailTemplates)?.body ?? ''),
    );
  }, [emailTemplates, setProposalInitialValue, templateVariablesResolver, unResolvedTemplateBody]);

  return {
    proposalContent,
    proposalInitialValue,
    setProposalContent,
    proposalSubject,
    setProposalSubject,
    isDirty,
    setIsDirty,
    emailTemplateType,
  };
};
