import { useEffect, useState } from 'react';
import { Layer, Quote } from 'types';

import { ConceptualTowerReportRow } from './types';
import { getConceptualTowerRowsData } from './utils';

export default function useReportRows(
  quotes: Quote[],
  layers: Layer[],
): { rowsData: ConceptualTowerReportRow[]; boldLastRow: boolean } {
  const [rowsData, setRowsData] = useState<ConceptualTowerReportRow[]>([]);
  const [boldLastRow, setBoldLastRow] = useState(false);

  useEffect(() => {
    const { layersForReport, showTotalLine } = getConceptualTowerRowsData(quotes, layers);
    setRowsData(layersForReport);
    setBoldLastRow(showTotalLine);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotes, layers]);

  return { rowsData, boldLastRow };
}
