export enum OrganizationType {
    Broker = 'Broker',
    Capitola = 'Capitola',
    Carrier = 'Carrier',
    Retailer = 'Retailer',
    Insured = 'Insured',
    Issuer = 'Issuer',
}

export const MarketOrganizationTypes = [OrganizationType.Broker, OrganizationType.Carrier];

export function isMarketOrganization(organizationType?: OrganizationType) {
    if (!organizationType) {
        return false;
    }

    return MarketOrganizationTypes.includes(organizationType);
}

export function isCustomerOrganization(organizationType?: OrganizationType) {
    if (!organizationType) {
        return false;
    }

    return [OrganizationType.Retailer, OrganizationType.Insured].includes(organizationType);
}

export function isUserOrganization(organizationType: OrganizationType): boolean {
    return [OrganizationType.Retailer, OrganizationType.Capitola].includes(organizationType);
}
