import { Box } from '@common-components';
import { InformationTooltipType } from 'contexts/information-tooltip/types';
import { useInformationTooltip } from 'hooks';
import { messages } from 'i18n';
import InformationTooltip from 'broker/components/InformationTooltip/InformationTooltip';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreActions } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

export const SmartReportsInfoTooltip = () => {
  const { shouldShow, hideInformationTooltip } = useInformationTooltip(InformationTooltipType.SmartReports);
  const { submission } = useSubmissionsWorkspace();
  const { setPasteSmartObjectIndicator } = useUiStoreActions();

  if (!shouldShow) {
    return null;
  }

  function handleClose() {
    hideInformationTooltip();
    setPasteSmartObjectIndicator(false);
  }

  return (
    <Box right={0} top={1} position="absolute" sx={{ bgColor: 'primary.main' }}>
      <InformationTooltip
        title={messages.submissionWorkspace.customerSection.smartReportsToolbox.infoTooltip.title}
        message={messages.submissionWorkspace.customerSection.smartReportsToolbox.infoTooltip.message}
        secondaryMessage=""
        isOpen={!submission?.isCopilotEnabled}
        height={125}
        placement="left"
        onClose={handleClose}
      />
    </Box>
  );
};
