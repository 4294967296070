import { useRef } from 'react';
import { useHover } from 'usehooks-ts';
import { Stack } from '@common-components';
import QuoteActions from './components/QuoteActions';
import QuoteDetails, { QuoteDetailsProps } from './components/QuoteDetails';
import { ViewQuoteAction } from './components/ViewQuoteAction';
import useQuoteItemActions from './useQuoteItemActions';

type SelectedQuoteItemProps = QuoteDetailsProps;

export default function SelectedQuoteItem(props: SelectedQuoteItemProps) {
  const { quote, layer, hideQuoteActions } = props;
  const quoteActions = useQuoteItemActions(quote, layer);
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Stack
      ref={hoverRef}
      direction="row"
      p={2}
      justifyContent="space-between"
      gap={2}
      minWidth={0}
      bgcolor="blueGrey.50"
      sx={{
        '&:hover': {
          filter: 'brightness(0.95)',
        },
      }}
    >
      <Stack direction="row">
        {quoteActions && (
          <ViewQuoteAction view={quoteActions?.view} hideQuoteActions={quoteActions?.hideQuoteActions} />
        )}
        <QuoteDetails {...props} />
      </Stack>
      <Stack justifyContent="center" visibility={isHover ? 'visible' : 'hidden'}>
        {quoteActions && <QuoteActions hideQuoteActions={hideQuoteActions} {...quoteActions} />}
      </Stack>
    </Stack>
  );
}
