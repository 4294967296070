import { ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from './mui-index';

export const DroppableArea = ({
  setIsDraggedOver,
  onDrop,
  children,
  disabled,
}: {
  setIsDraggedOver?: (isDraggedOver: boolean) => void;
  onDrop?: (acceptedFiles: File[]) => void;
  children: ReactNode;
  disabled?: boolean;
}) => {
  const { getRootProps } = useDropzone({
    onDragEnter: () => setIsDraggedOver?.(true),
    onDragLeave: () => setIsDraggedOver?.(false),
    onDrop,
    noClick: true,
    disabled,
  });

  return (
    <Box height={1} width={1} {...getRootProps()}>
      {children}
    </Box>
  );
};
