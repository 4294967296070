import { useSearchSubmissionMarket } from 'hooks';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import ContentSkeleton from './AdvancedContent/ContentSkeleton';

import FormWrapper from './FormWrapper';
import { StepsRoutes, SubmissionAutonomousCreationLocationState } from './types';

export interface ModalContentProps extends BaseRoutedDialogContentProps {
  activeStep: StepsRoutes;
  locationState: SubmissionAutonomousCreationLocationState;
}

export default function ModalContent(props: ModalContentProps) {
  const {
    locationState: { submission },
  } = props;

  const { items: submissionMarkets, isLoading: submissionMarketsIsLoading } = useSearchSubmissionMarket({
    filter: {
      submissionId: submission.id,
    },
  });

  const filteredSubmissionMarkets = submission.isCopilotEnabled
    ? submissionMarkets?.filter((submissionMarket) => submissionMarket.copilotEnrolled)
    : submissionMarkets;

  return submissionMarketsIsLoading ? (
    <ContentSkeleton />
  ) : (
    <FormWrapper {...props} submissionMarkets={filteredSubmissionMarkets} />
  );
}
