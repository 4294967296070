import { messages } from 'i18n';
import { ActivityLogList } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/ActivityLog';
import { useShareActivityLog } from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/ActivityLog/useShareActivityLog';
import { useCurrentSubmissionMarket } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { submissionMarketActivityConfig } from './submission-market-activity-type-config';

export const SubmissionMarketActivityLog = () => {
  const { activities } = useSubmissionsWorkspace();
  const { getShareOptions } = useShareActivityLog();

  const submissionMarket = useCurrentSubmissionMarket();

  const filteredActivities = activities.filter(
    (activity) => activity.submissionMarketId && activity.submissionMarketId === submissionMarket?.id,
  );

  return (
    <ActivityLogList
      activities={filteredActivities}
      activityTypeConfig={submissionMarketActivityConfig}
      title={messages.submissionWorkspace.customerSection.submissionMarketActivityLog}
      shareOptions={getShareOptions(filteredActivities, submissionMarket?.marketName)}
    />
  );
};
