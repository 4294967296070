import { AIFeature } from 'enums';
import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import { AIContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/AIContextProvider';
import MarketingUpdateEmailContent from './MarketingUpdateEmailContent';

export default function MarketingUpdateEmail() {
  return (
    <WithRouteLocationState routeKey={RouteKey.MarketingUpdateEmail} isStateRequired>
      {(locationState) => (
        <AIContextProvider feature={AIFeature.MarketingUpdateGeneration}>
          <MarketingUpdateEmailContent locationState={locationState} />
        </AIContextProvider>
      )}
    </WithRouteLocationState>
  );
}
