import { useBoxClient, useToast } from 'hooks';
import { useSearchBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { getFileIsHidden } from 'broker/components/FilesExplorer/utils';

interface UseDownloadFilesProps {
  organizationBoxFolderId: string;
}

export function useDownloadFiles({ organizationBoxFolderId }: UseDownloadFilesProps) {
  const boxClient = useBoxClient();
  const { showToast } = useToast();
  const { items: filesToDownload } = useSearchBoxItems({
    filter: { chunkSize: 20, folderId: organizationBoxFolderId },
  });

  return async (downloadFileName?: string) => {
    try {
      if (filesToDownload.length === 0) {
        return null;
      }
      // Remove hidden files from the root folder
      const nonHiddenFiles = filesToDownload.filter((file) => file.type === 'folder' || !getFileIsHidden(file));
      return await boxClient.createZip(nonHiddenFiles, downloadFileName);
    } catch (error) {
      showToast('error', { message: messages.general.operationFailed });
      return null;
    }
  };
}
