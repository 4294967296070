import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import FormDatePicker from 'components/hookFormComponents/FormDatePicker';
import FormTextField from 'components/hookFormComponents/FormTextField';
import {
  FormStateBaseProp,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';
import { buildFieldName } from './utils/utils';

interface HeraldClaimEventProps {
  heraldParameter: HeraldNormalizedParameter;
  fieldNamePrefix: string;
  onBlur: () => boolean | undefined;
  hideBorder: boolean;
  optional?: boolean;
}

export default function HeraldClaimEvent({
  heraldParameter,
  fieldNamePrefix,
  onBlur,
  hideBorder = false,
  optional,
}: HeraldClaimEventProps) {
  const ownBuildName = (fieldName: string, placeholder: string) =>
    buildFieldName(`${fieldNamePrefix}.${FormStateBaseProp.Main}`, fieldName, placeholder, heraldParameter);

  return (
    <Stack gap={1} mb={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography mb={1} variant="body2Bold" textTransform="capitalize">
          {heraldParameter.fieldLabel}
        </Typography>
        {optional && (
          <Typography variant="body2" color="textSecondary" mr={3}>
            {messages.general.optional}
          </Typography>
        )}
      </Stack>

      <Stack
        sx={
          !hideBorder
            ? {
                border: 1,
                borderColor: 'divider',
                borderRadius: 2,
              }
            : undefined
        }
        p={hideBorder ? 0 : 2}
      >
        <FormTextField enhancedRequired onBlur={onBlur} {...ownBuildName('description', 'Description')} />
        <Stack direction="row" gap={3}>
          <FormDatePicker enhancedRequired onBlur={onBlur} {...ownBuildName('date', 'YYYY-MM-DD')} />
          <FormTextField
            onBlur={onBlur}
            {...ownBuildName('amount', '$10,000')}
            isNumberFormat
            numberFormatProps={{ decimalScale: 0 }}
            enhancedRequired
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
