import { useState } from 'react';

export interface ModalBaseProps {
  isOpened: boolean;
  onClose: () => void;
}

interface ModalState<T> {
  isOpen: boolean;
  shouldRender: boolean;
  modalProps?: T;
}

interface UseConditionallyRenderedModalProps<T> {
  initialModalProps?: T;
  isOpen?: boolean;
}

// A hook to simplify the rendering of non routed dialogs that rely on data to be defined before the dialog is opened.
// Using shouldRender makes sure that when opening the dialog it's expected props are already set,
// thus removing the need to write code that expects undefined values.
// This hook also guides the usage to update the dialog props only the next time the dialog opens,
// without resetting the dialog state on close to avoid flickering in the UI.
export function useConditionallyRenderedModal<T>({
  initialModalProps,
  isOpen = false,
}: UseConditionallyRenderedModalProps<T>) {
  const [modalState, setModalState] = useState<ModalState<T>>({
    isOpen,
    shouldRender: isOpen,
    modalProps: initialModalProps,
  });

  const openModal = (modalProps: T) => {
    setModalState({
      shouldRender: true,
      isOpen: true,
      modalProps,
    });
  };

  const closeModal = () => {
    setModalState((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  return {
    modalState,
    openModal,
    closeModal,
  };
}
