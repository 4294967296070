import { ReactElement } from 'react';
import { InputAdornment, Stack, TextField, Typography } from '@common-components';
import { ViewQuilt as ViewQuiltIcon } from '@icons';
import { messages } from 'i18n';
import { EmailTemplate } from 'types';

export interface EditorHeaderProps {
  selectedTemplate: EmailTemplate;
  subject: string;
  setSubject: (subject: string) => void;
  headerButtons: ReactElement;
}

export function EditorHeader({ selectedTemplate, subject, setSubject, headerButtons }: EditorHeaderProps) {
  return (
    <Stack direction="column" className="cap-template-editor-header">
      <Stack direction="row-reverse" justifyContent="space-between" alignItems="center" sx={{ my: 2 }}>
        {headerButtons}
        <Stack width="auto" pr={(theme) => theme.spacing(9)}>
          <Stack direction="row" gap={1}>
            <ViewQuiltIcon />
            <Typography>{selectedTemplate.name}</Typography>
          </Stack>
          <Typography
            variant="caption"
            marginLeft={(theme) => theme.spacing(4)}
            color={(theme) => theme.palette.grey[700]}
          >
            {messages.templateTypeToDescription[selectedTemplate.type]}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} marginBottom={4} gap={4}>
        <TextField
          sx={{
            '&.MuiInput-underline:before': {
              borderBottomColor: 'divider',
            },
          }}
          id="subject"
          value={subject}
          onChange={(event) => setSubject(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ mr: 2 }}>{`${messages.emailEditor.subject}:`}</InputAdornment>
            ),
          }}
          fullWidth
          multiline
          variant="standard"
        />
      </Stack>
    </Stack>
  );
}
