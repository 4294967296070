import * as yup from 'yup';
import { FormFieldsConfigProps } from 'hooks';
import { messages } from 'i18n';
import { CopilotSteps } from 'types';

export const sliderMarks = [
  {
    value: 0,
    label: messages.autonomousFlow.settingsModal.steps.binding.title,
    step: CopilotSteps.BindersAndPolicies,
    serverSteps: [
      CopilotSteps.PreMarketing,
      CopilotSteps.Marketing,
      CopilotSteps.Proposal,
      CopilotSteps.BindersAndPolicies,
    ],
  },
  {
    value: 33,
    label: messages.autonomousFlow.settingsModal.steps.proposal.title,
    step: CopilotSteps.Proposal,
    serverSteps: [CopilotSteps.PreMarketing, CopilotSteps.Marketing, CopilotSteps.Proposal],
  },
  {
    value: 66,
    label: messages.autonomousFlow.settingsModal.steps.marketing.title,
    step: CopilotSteps.Marketing,
    serverSteps: [CopilotSteps.PreMarketing, CopilotSteps.Marketing],
  },
  {
    value: 100,
    label: messages.autonomousFlow.settingsModal.steps.preMarketing.title,
    step: CopilotSteps.PreMarketing,
    serverSteps: [CopilotSteps.PreMarketing],
  },
];

export function valueToIsCollapseActive(value: number, step: CopilotSteps) {
  const stepNumber = sliderMarks.find((mark) => mark.step === step)?.value || 0;
  return value <= stepNumber;
}

export interface AutonomousMarketFormData {
  markets: AutonomousMarketInfoData[];
}

export interface AutonomousMarketInfoData {
  marketId: string;
  isIncumbent: boolean;
}

export enum AutonomousMarketArrayFieldNames {
  MarketId = 'marketId',
}

export enum AutonomousMarketFieldNames {
  Markets = 'markets',
}
export const autonomousMarketFormFieldsDefaults: AutonomousMarketInfoData = {
  marketId: '',
  isIncumbent: false,
};

type FormFieldsConfig = Record<AutonomousMarketArrayFieldNames, FormFieldsConfigProps<AutonomousMarketArrayFieldNames>>;
type FormConfig = Record<AutonomousMarketFieldNames, FormFieldsConfigProps<AutonomousMarketFieldNames>>;

export const autonomousMarketFormFieldsConfig: FormFieldsConfig = {
  [AutonomousMarketArrayFieldNames.MarketId]: {
    label: '',
    placeholder: messages.autonomousFlow.settingsModal.advanced.formFields.market.placeholder,
    name: AutonomousMarketArrayFieldNames.MarketId,
    id: AutonomousMarketArrayFieldNames.MarketId,
  },
};

export const autonomousMarketFormConfig: FormConfig = {
  [AutonomousMarketFieldNames.Markets]: {
    label: '',
    placeholder: '',
    name: AutonomousMarketFieldNames.Markets,
    id: AutonomousMarketFieldNames.Markets,
  },
};

export const schema = yup.object().shape({
  markets: yup.array().of(
    yup.object().shape({
      [AutonomousMarketArrayFieldNames.MarketId]: yup
        .string()
        .typeError(messages.formMessages.requiredField)
        .default(''),
    }),
  ),
});
